import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

interface DocumentRequestSubmittedFileProps {
  name: string;
  id: string;
  onPreviewFile: (id: string) => void;
}

export const DocumentRequestSubmittedFile = ({ name, id, onPreviewFile }: DocumentRequestSubmittedFileProps) => {
  const handlePreviewFile = () => {
    if (id) {
      onPreviewFile?.(id);
    }
  };

  return (
    <FileContainer>
      <File>{name}</File>
      <DownloadFileButton onClick={handlePreviewFile}>
        <span>View</span>
        <FontAwesomeIcon icon={faFileExport} color="#000" />
      </DownloadFileButton>
    </FileContainer>
  );
};

const FileContainer = styled.div`
  & > * {
    display: inline-block;
  }
`;

const File = styled.div`
  color: ${(props) => props.theme.main.black};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

const DownloadFileButton = styled.button`
  color: ${(props) => props.theme.main.black};
  font-size: 14px;
  font-weight: 400;
  background-color: ${(props) => props.theme.main.white};
  border: none;
  width: fit-content;
  cursor: pointer;

  & > span {
    text-decoration: underline;
    display: inline-block;
    margin-right: 4px;
  }
`;
