import { useNavigate } from 'react-router-dom';
import { BodyWrapper, ColumnBox, TitleWrapper } from '../styles';

import { Body, Button, Heading } from '@frontend/cherry-library';
import { ReactComponent as NeedMoreInfoIcon } from 'assets/images/warning_outline.svg';
import useStore from 'lib/hooks/useStore';

const NeedMoreInfo = () => {
  const navigate = useNavigate();
  const { applyWithPatientFormData } = useStore();

  const returnToDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <ColumnBox>
      <TitleWrapper>
        <NeedMoreInfoIcon width={32} height={32} id="apply-with-patient-need-more-info-icon" />
        <Heading
          text={`${applyWithPatientFormData?.firstName} ${applyWithPatientFormData?.lastName} needs to provide more info`}
          level="3"
        />
      </TitleWrapper>
      <BodyWrapper>
        <Body>
          {applyWithPatientFormData?.firstName} {applyWithPatientFormData?.lastName} needs to provide additional
          information to finish their application. We’ve texted them a link to do this. If approved, you will see them
          in your dashboard after they submit their information.
        </Body>
      </BodyWrapper>
      <BodyWrapper>
        <Body>
          If they have questions or need assistance, they can text us a reply to our text message or call our support
          team at (888) 839-7171.
        </Body>
      </BodyWrapper>
      <Button id="apply-with-patient-moreInfo-okay" onClick={returnToDashboard}>
        Okay
      </Button>
    </ColumnBox>
  );
};
export default NeedMoreInfo;
