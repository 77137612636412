import { useMutation } from '@apollo/client';
import { Ripples } from 'lib/components';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import Confetti from 'react-confetti';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { ButtonPrimary, Loading, PhoneInputIcon } from 'lib/components';
import { confettiColors } from 'lib/constants';
import { SEND_MEMBERSHIP_CUSTOMER_SMS } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { clearMaskFormat, phonePattern } from 'lib/utils';
import { SyntheticInputEvent } from 'react-number-format/types/types';
import { BulkSmsModal } from './BulkSmsModal';

export const MemberSignUp = () => {
  const { organization } = useStore();
  const alert = useAlert();

  const [sendSms, { loading }] = useMutation(SEND_MEMBERSHIP_CUSTOMER_SMS);

  const [applicationSent, setApplicationSent] = useState(false);
  const [confettiActive, setConfettiActive] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
  const [phoneFormatValid, setPhoneFormatValid] = useState<boolean>(true);
  const [linkSending, setLinkSending] = useState<boolean>(false);

  const [bulkSmsModal, setBulkSmsModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClose = () => {
    setBulkSmsModal(false);
  };

  const onPhoneChange = (event) => {
    const rawPhoneValue = event.target.value;

    if (!rawPhoneValue.includes('_')) {
      if (phonePattern.test(rawPhoneValue)) setPhoneFormatValid(true);
      else setPhoneFormatValid(false);
    }

    if (phonePattern.test(rawPhoneValue)) {
      setPhoneNumberValid(true);
      setPhoneFormatValid(true);
    } else {
      setPhoneNumberValid(false);
    }
    const parsedPhoneInput = clearMaskFormat(rawPhoneValue);
    setPhoneNumber(parsedPhoneInput);
  };

  const logInputBlur = (event: SyntheticInputEvent) => {
    const rawPhoneValue = event.target.value;

    if (!rawPhoneValue.includes('_') && phonePattern.test(rawPhoneValue)) setPhoneFormatValid(true);
    else setPhoneFormatValid(false);
  };

  const sendSignUpLink = async () => {
    try {
      const { id, slug } = organization || {};
      setLinkSending(true);
      const { data } = await sendSms({
        variables: {
          input: {
            organizationId: id,
            slug,
            phone: phoneNumber,
          },
        },
      });
      if (data?.sendMembershipsCustomerSms?.success) {
        alert.success('Success: Sign-Up Link Sent!');
        setApplicationSent(true);
        setTimeout(() => {
          setConfettiActive(true);
        }, 300);

        setTimeout(() => {
          setApplicationSent(false);
          setPhoneNumberValid(false);
          setPhoneNumber('');
          setConfettiActive(false);
        }, 3000);
      } else {
        alert.error(data?.sendMembershipsCustomerSms.error || 'Something went wrong. Please try again.');
      }
      setLinkSending(false);
    } catch (err) {
      alert.error('Something went wrong. Please try again.');
      setLinkSending(false);
    }
  };

  const showBulkSmsModal = () => {
    setBulkSmsModal(true);
  };

  const onMouseEnterHandler = () => setShowTooltip(true);
  const onMouseLeaveHandler = () => setShowTooltip(false);

  return (
    <Container>
      <Card>
        <Title>Member Signup</Title>
        <FormContainer>
          <CustomInputMask
            data-testid="application-phone-input"
            className={phoneFormatValid ? 'valid' : 'error'}
            mask="(999) 999-9999"
            value={phoneNumber}
            onChange={onPhoneChange}
            onBlur={logInputBlur}
          >
            {(inputProps) => (
              <PhoneInput
                {...inputProps}
                inputProps={{ ...inputProps, inputMode: 'numeric' }}
                placeholder="(___) ___-____"
                type="tel"
              />
            )}
          </CustomInputMask>
          <AbsoluteIconWrapper>
            <PhoneInputIcon />
          </AbsoluteIconWrapper>

          <Ripples>
            <SendButton data-testid="submit" disabled={!phoneNumberValid || loading} onClick={sendSignUpLink}>
              {linkSending ? <Loading /> : 'Text Sign-Up Link'}
            </SendButton>
          </Ripples>
        </FormContainer>
        <ButtonContainer onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
          <ButtonPrimary data-testid="bulkTextLink" onClick={showBulkSmsModal} text={'Bulk Text Sign-Up Links'} />
          {showTooltip ? (
            <TooltipContainer>
              <LeftTriangle />
              <TooltipTitle>Send sign-up links to many patients at once!</TooltipTitle>
              <TooltipText>
                To send membership sign-up links to multiple patients at once, simply upload a CSV file containing phone
                numbers of your patients. We’ll text each patient a link to sign up for a membership.
              </TooltipText>
            </TooltipContainer>
          ) : null}
        </ButtonContainer>

        <BulkSmsModal open={bulkSmsModal} handleClose={handleClose} />

        <ErrorContainer>{!phoneFormatValid ? 'Please enter a valid US mobile phone number' : ''}</ErrorContainer>
        {applicationSent && confettiActive ? (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={300}
            colors={confettiColors}
            recycle={false}
          />
        ) : null}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 320px;
  margin-bottom: 40px;
`;

const Card = styled.div`
  height: 300px;
  position: relative;
  display: flex;
  background: #ffffff;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.07), 1px 0.751293px 2.12694px rgba(0, 0, 0, 0.035);
  border-radius: 4px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  position: relative;
`;

const ErrorContainer = styled.div`
  position: relative;
  font-size: 10px;
  margin-top: 4px;
  color: #ec3360;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  margin-bottom: 24px;
`;

const PhoneInput = styled.input`
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #ccc;
  border-right: none;
  padding: 8px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

const SendButton = styled.button`
  background-color: ${(props) => props.theme.main.green};
  color: #fff;
  border-radius: 0px 2px 2px 0px;
  border: none;
  width: 100%;
  font-size: 16px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    background-color: #c4c4c4;
  }
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-left: 20px;
  margin-top: 5px;
`;

const CustomInputMask = styled(InputMask)`
  padding-left: 50px;
  font-size: 14px;
  flex: 1;

  &.error {
    border: 1px solid #ff0000;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 16px;
  width: 380px;
  button {
    width: 380px;
  }
`;

const TooltipContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #0e202f;
  border: 1px solid #0e202f;
  border-radius: 4px;
  width: 310px;
  z-index: 10;
  left: 400px;
  top: -85px;
  padding: 12px;
`;

const TooltipTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 12px;
`;

const TooltipText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 12px;
`;

const LeftTriangle = styled.div<{ secondary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0e202f;
  position: absolute;
  left: -10px;
  bottom: calc(38%);
  transform: rotate(270deg);
`;
