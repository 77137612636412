import QRCode from 'easyqrcodejs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import images from 'assets/images';
import { Loading } from 'lib/components';
import { NOTE_LENGTH, PATIENT_URL } from 'lib/constants';
import { formatDate, formatPhoneNumber } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';
import { PDF_PAGE } from 'pages/desktop/Dashboard/views/GenerateEstimate/GenerateEstimatePreview';

interface Props {
  page: number;
  previewData: any;
  paymentOptionsTableData: any;
}

const QR_CODE_CONFIG = {
  logo: images?.cherrySymbolDark,
  version: 5,
  width: 92,
  height: 92,
  autoColor: true,
  PO: '#00c37d',
  PI: '#0e1f2e',
  PO_TL: '#00c37d',
  PI_TL: '#00c37d',
  PO_TR: '#0e1f2e',
  PI_TR: '#00c37d',
  PO_BL: '#00c37d',
  PI_BL: '#0e1f2e',
};

const paymentTableColumns = [
  {
    name: 'Plan Length',
    selector: (row) => row.term,
    maxWidth: '90px',
    id: 'table-term',
  },
  {
    name: 'Down Payment',
    selector: (row) => row.downPayment,
    maxWidth: '50px',
    id: 'table-down-payment',
  },
  {
    name: 'Monthly Payment',
    selector: (row) => row.monthlyPayment,
    maxWidth: '130px',
    id: 'table-monthly-payment',
  },
  {
    name: 'Total Finance Charge (over life of the plan)',
    selector: (row) => row.totalInterest,
    id: 'table-apr',
  },
];

export const GeneratedTreatmentPlanDocument = ({ page, previewData, paymentOptionsTableData }: Props) => {
  const [qrImage, setQrImage] = useState<string>('');
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const getLowestInstallmentAmount = () => {
    let lowestInstallmentAmount = Infinity;
    paymentOptionsTableData?.forEach((productData) => {
      const currentInstallmentAmount = productData?.installmentAmount;
      if (currentInstallmentAmount < lowestInstallmentAmount) {
        lowestInstallmentAmount = currentInstallmentAmount;
      }
    });

    if (lowestInstallmentAmount !== Infinity) {
      return CurrencyUtil.toCurrencyString(lowestInstallmentAmount, false);
    }

    return 0;
  };

  const getPaymentOptionsTableData = () => {
    let lowestInstallmentAmount = Infinity;
    let lowestInstallmentIndex = -1;

    paymentOptionsTableData?.forEach((column, index) => {
      if (column?.installmentAmount < lowestInstallmentAmount) {
        lowestInstallmentAmount = column?.installmentAmount;
        lowestInstallmentIndex = index;
      }
    });

    return (
      paymentOptionsTableData?.map((column, index) => {
        const isLowestInstallment = index === lowestInstallmentIndex;
        const totalInterestText = `${CurrencyUtil.toCurrencyString(column?.financeCharge, false)} ( ${
          column?.promo ? column?.product?.promoApr : column?.product?.apr
        }% APR )${isLowestInstallment ? ' *' : ''}`;

        return {
          term: `${column?.product?.term} months`,
          downPayment: CurrencyUtil.toCurrencyString(column?.downPaymentAmount, false),
          monthlyPayment: `${CurrencyUtil.toCurrencyString(column.installmentAmount, false)} / months`,
          totalInterest: totalInterestText,
        };
      }) || []
    );
  };

  const onQrRendered = useCallback((_, qrImg: string) => {
    setQrImage(qrImg);
  }, []);

  useEffect(() => {
    // tslint:disable-next-line no-unused-declaration
    const pdfQr = new QRCode(qrCodeRef.current, {
      text: `${PATIENT_URL}/?utm_source=merchant&utm_medium=qr_code_treatment_plan&utm_campaign=v1`,
      onRenderingEnd: onQrRendered,
      ...QR_CODE_CONFIG,
    });
  }, []);

  const createFirstPageContent = () => {
    const {
      patientName,
      patientSurname,
      treatmentCoordinator,
      treatmentType,
      treatmentDate,
      selectedLocation,
      additionalNotes,
      amount,
    } = previewData || {};

    const tableData = getPaymentOptionsTableData();

    return (
      <Page id="first-page-pdf">
        <Content>
          <Title>
            Treatment Plan for <CapitalizeText className="cohere-block">{patientName}</CapitalizeText>
          </Title>

          <TopHeader>
            <TopLeftHeader>
              <TopContentContainer>
                <LocationContainer>
                  <BoldText>{selectedLocation?.name}</BoldText>
                  <Text>{selectedLocation?.address}</Text>
                  <Text>{`${selectedLocation?.city} ${selectedLocation?.state} ${selectedLocation?.zip}`}</Text>
                  <Text>{formatPhoneNumber(selectedLocation?.phone)}</Text>
                </LocationContainer>

                <div>
                  <PatientNameContainer>
                    <Text>
                      Prepared For:{' '}
                      <BoldText>
                        <CapitalizeText className="cohere-block">{patientName + ' ' + patientSurname}</CapitalizeText>
                      </BoldText>
                    </Text>

                    <Text>
                      Prepared By:{' '}
                      <BoldText>
                        <CapitalizeText className="cohere-block">{treatmentCoordinator}</CapitalizeText>
                      </BoldText>
                    </Text>

                    <Text>
                      Date Created:{' '}
                      <BoldText>
                        <CapitalizeText className="cohere-block">
                          {formatDate(new Date().toDateString())}.
                        </CapitalizeText>
                      </BoldText>
                    </Text>
                  </PatientNameContainer>
                </div>
              </TopContentContainer>

              <Border />

              <div>
                <TreatmentContainer>
                  <Subtitle>Consultation Summary</Subtitle>
                  {treatmentType ? (
                    <TreatmentTypeContainer>
                      <Text>
                        Treatment Needed:{' '}
                        <BoldText>
                          <CapitalizeText className="cohere-block">{treatmentType}</CapitalizeText>
                        </BoldText>
                      </Text>
                    </TreatmentTypeContainer>
                  ) : null}

                  {treatmentDate ? (
                    <TreatmentDateContainer>
                      <Text>
                        Estimated Treatment Date:{' '}
                        <BoldText>
                          <CapitalizeText className="cohere-block">{formatDate(treatmentDate)}</CapitalizeText>
                        </BoldText>
                      </Text>
                    </TreatmentDateContainer>
                  ) : null}
                </TreatmentContainer>
              </div>
            </TopLeftHeader>
            <TopRightHeader>
              <PaymentSummaryContainer>
                {tableData?.length > 0 ? (
                  <>
                    <PaymentSummaryText>Total Treatment Cost</PaymentSummaryText>
                    <AmountTextXL> {CurrencyUtil.toCurrencyString(amount, false)}</AmountTextXL>
                    <SmallGap />
                    <PaymentSummaryText>Monthly Payments available as low as</PaymentSummaryText>
                    <AmountTextXL>
                      {getLowestInstallmentAmount?.() !== 0 ? (
                        <>
                          {getLowestInstallmentAmount?.()}
                          <span id="month">
                            {' '}
                            / month <Star>*</Star>{' '}
                          </span>
                        </>
                      ) : (
                        <Loading />
                      )}
                    </AmountTextXL>
                    <PaymentSummaryText>as shown in the table below</PaymentSummaryText>
                    <PoweredByText>
                      powered by <CherryLogoPowered src={images?.cherryLogoDark} />
                    </PoweredByText>
                  </>
                ) : (
                  <LoadingContainer>
                    <Loading />
                  </LoadingContainer>
                )}
              </PaymentSummaryContainer>
            </TopRightHeader>
          </TopHeader>

          <AdditionalTextContainer>
            <AdditionalText>
              <SubtitleNotes>Notes</SubtitleNotes>
              {additionalNotes?.replace(/(^[ \t]*\n)/gm, '') || 'No summary.'}
            </AdditionalText>
          </AdditionalTextContainer>

          <Border />

          {additionalNotes?.length <= NOTE_LENGTH && tableData?.length > 0 ? (
            <div>
              <TableHeaderText>Payment Options with Cherry</TableHeaderText>
              <CustomDataTable noHeader={true} columns={paymentTableColumns} data={tableData} />
            </div>
          ) : null}

          <QRContainer>
            <QRRow>
              <QRText>
                <ApplyText>
                  Get treatment today, <GreenText>pay later</GreenText> with Cherry!
                </ApplyText>
                <ScanText>
                  To lock in your payment plan choice, scan the QR code <br />
                  or visit: <b>{`${PATIENT_URL}`}</b>
                </ScanText>
              </QRText>
              <div>{qrImage && <StyledQRImage src={qrImage} />}</div>
            </QRRow>
          </QRContainer>
        </Content>
        <Footer>
          <Border />
          <FooterContent>
            <DisclaimerTextContainer>
              <DisclaimerText>
                Payment options through Cherry Technologies, Inc. are issued by the following lending partners:{' '}
                <a href="https://withcherry.com/lending-partners">https://withcherry.com/lending-partners.</a> <br />
                <b>Iowa only:</b> Borrowers are subject to Iowa state specific underwriting criteria. APR for all Iowa
                borrowers is capped at 20.99%.
              </DisclaimerText>
            </DisclaimerTextContainer>
          </FooterContent>
        </Footer>
      </Page>
    );
  };

  const createSecondPageContent = () => {
    const { additionalNotes } = previewData || {};

    const tableData = getPaymentOptionsTableData();
    return (
      <Page id="second-page-pdf" isHide={additionalNotes?.length < NOTE_LENGTH}>
        <PageTwoContent>
          <Border />

          {additionalNotes?.length > NOTE_LENGTH && tableData?.length > 0 ? (
            <>
              <TableHeaderText>Payment Options with Cherry</TableHeaderText>
              <CustomDataTable noHeader={true} columns={paymentTableColumns} data={tableData} />
            </>
          ) : null}

          <Gap />
          <LargeGap />

          <Row>
            <Column>
              <QRContainer>
                <QRRow>
                  <QRText>
                    <ApplyText>
                      Get treatment today, <GreenText>pay later</GreenText> with Cherry!
                    </ApplyText>
                    <ScanText>
                      To lock in your payment plan choice, scan the QR code or visit: <b>{`${PATIENT_URL}`}</b>
                    </ScanText>
                  </QRText>
                  <div>{qrImage && <StyledQRImage src={qrImage} />}</div>
                </QRRow>
              </QRContainer>
            </Column>
          </Row>
        </PageTwoContent>
        <Footer>
          <Border />
          <FooterContent>
            <DisclaimerTextContainer>
              <DisclaimerText>
                Payment options through Cherry Technologies, Inc. are issued by the following lending partners:{' '}
                <a href="https://withcherry.com/lending-partners">https://withcherry.com/lending-partners.</a> <br />
                <b>Iowa only:</b> Borrowers are subject to Iowa state specific underwriting criteria. APR for all Iowa
                borrowers is capped at 20.99%.
              </DisclaimerText>
            </DisclaimerTextContainer>
          </FooterContent>
        </Footer>
      </Page>
    );
  };

  const firstPageContent = createFirstPageContent();
  const secondPageContent = createSecondPageContent();

  return (
    <PDFContainer>
      {page === PDF_PAGE.FIRST ? firstPageContent : secondPageContent}
      <Hidden>
        {<div ref={qrCodeRef} />}
        {firstPageContent}
        {previewData?.additionalNotes?.length > NOTE_LENGTH ? secondPageContent : null}
      </Hidden>
    </PDFContainer>
  );
};

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const TopLeftHeader = styled.div`
  width: 63%;
  margin-right: 3%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;

const TopContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Hidden = styled.div`
  height: 0;
  overflow: hidden;
`;

const PDFContainer = styled.div`
  width: 550px;
  height: 720px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.main.black};
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 17px;
`;

const PatientNameContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.black};
  margin-bottom: 0;
`;

const SubtitleNotes = styled(Subtitle)`
  margin-top: 8px;
  font-size: 8px;
  font-weight: normal;
  line-height: 12px;
  text-transform: initial;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props) => props.theme.main.black};
`;

const BoldText = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
`;

const Border = styled.div`
  border: 0.788158px solid #e7e9ea;
  margin: 12px 0 12px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AmountTextXL = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const Gap = styled.div`
  margin-bottom: 16px;
`;

const SmallGap = styled.div`
  margin-bottom: 8px;
`;

const LargeGap = styled.div`
  margin-bottom: 24px;
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;

  .rdt_TableRow > div:first-child {
    font-weight: 700 !important;
  }

  .rdt_TableRow > div:nth-child(3) {
    font-weight: 700 !important;
  }

  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 10px;
    white-space: nowrap;
  }

  .rdt_TableHeadRow {
    min-height: 25px;
    padding-left: 8px;
  }

  .rdt_TableCol_Sortable {
    font-style: italic;
    font-weight: 400;
  }

  .rdt_TableRow {
    min-height: 24px;
    padding: 6px 9px;

    &:nth-child(odd) {
      background: #f2f4f5;
    }
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }
`;

const CherryLogoPowered = styled.img`
  width: auto;
  max-height: 12px;
  height: auto;
  margin-left: 3px;
`;

const Star = styled.span`
  color: #879097;
`;

const Footer = styled.div`
  display: block;
  height: 100px;
`;

const Page = styled.div<{ isHide?: boolean }>`
  display: ${(props) => (props?.isHide ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  height: 650px;
  margin-top: 6px;
`;

const PageTwoContent = styled.div`
  height: 650px;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DisclaimerTextContainer = styled.div`
  width: 100%;
  line-height: 10px;
`;

const DisclaimerText = styled.span`
  font-size: 8px;
  line-height: 10px;
  color: ${(props) => props.theme.main.black};

  > a {
    color: ${(props) => props.theme.main.black};
    text-decoration: underline;

    @media print {
      font-weight: 600;
      text-decoration: none;
    }
  }
`;

const TableHeaderText = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  color: ${(props) => props.theme.main.black};
  text-transform: uppercase;
`;

const QRContainer = styled.div`
  flex: 1;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f2fffb;
  margin-top: 20px;
  border-radius: 12px;
`;

const QRRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QRText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  padding: 6px 12px;
`;

const ApplyText = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`;

const ScanText = styled(ApplyText)`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
`;

const TreatmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 0;
`;

const TopRightHeader = styled.div`
  width: 33%;
  display: flex;
`;

const PaymentSummaryContainer = styled.div`
  width: 100%;
  border-radius: 5.97px;
  border: 0.746px solid #e7e9ea;
  background: #f2f4f5;
  padding: 11.939px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

const PaymentSummaryText = styled.div`
  color: #0e202f;
  font-family: Open Sans, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PoweredByText = styled.div`
  display: flex;
  font-size: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const TreatmentTypeContainer = styled.div`
  width: 100%;
`;

const TreatmentDateContainer = styled.div`
  width: 100%;
`;

const AdditionalTextContainer = styled.div`
  flex: 1;
  line-height: 12px;
`;

const AdditionalText = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.theme.main.black};
  white-space: break-spaces;
`;

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const StyledQRImage = styled.img`
  border: 2px solid #00c37d;
  border-radius: 8px;
  width: 75px;
  height: 75px;
`;

const GreenText = styled.span`
  color: #00c37d;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
