import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React from 'react';
import styled from 'styled-components';

import { Icon, Title } from 'lib/components/mobile';

interface ButtonProps {
  label?: string;
  icon?: string;
  onClick?: () => void;
  primary?: boolean;
}
interface SlideModalProps {
  show: boolean;
  hideModal?: () => void;
  title?: string;
  firstButton?: ButtonProps;
  secondButton?: ButtonProps;
  description?: string;
  isPrimary?: boolean;
}

export const SlideModal = ({ show, hideModal, title, description, firstButton, secondButton }: SlideModalProps) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <SlideModalContainer>
          <SlideModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{title}</Title>
            <Icon src={'close'} onClick={hideModal} width={16} height={16} />
          </SlideModalHeader>
          {description && <SlideModalDescription>{description}</SlideModalDescription>}
          {firstButton?.label && (
            <SlideModalButton
              isPrimary={firstButton.primary}
              onClick={firstButton?.onClick}
              data-testid="slide-first-button"
            >
              {firstButton?.icon && <Icon src={firstButton.icon} m={'0px 10px'} width={16} height={16} />}
              {firstButton.label}
            </SlideModalButton>
          )}
          {secondButton?.label && (
            <SlideModalButton
              isPrimary={secondButton.primary}
              onClick={secondButton?.onClick}
              data-testid="slide-second-button"
            >
              {secondButton?.icon && <Icon src={secondButton.icon} m={'0px 10px'} width={16} height={16} />}
              {secondButton.label}
            </SlideModalButton>
          )}
        </SlideModalContainer>
      </Slide>
    </Backdrop>
  );
};

const SlideModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: #ffffff;
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 30px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 300px;
    border-radius: 6px;
  }
`;

const SlideModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SlideModalButton = styled.div<{ isPrimary?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid ${(props) => (props.isPrimary ? props.theme.main.green : '#56636d')};
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => (props.isPrimary ? props.theme.main.white : props.theme.main.midnightBlue)};
  background-color: ${(props) => (props.isPrimary ? props.theme.main.green : '')};
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const SlideModalDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
