import { gql } from '@apollo/client/core';

export const GET_DOCUMENT_REQUEST_LIST = gql`
  query getDocumentRequestList($input: GetDocumentRequestListInput) {
    getDocumentRequestList(input: $input) {
      total
      contents {
        documentRequest {
          id
          documentTitle
          numberOfDocs
          userType
          externalNote
          organizationId
          merchantId
          userId
          documentRequestType
          status
          updatedAt
          createdAt
          relations {
            id
            type
          }
        }
        externalUserName
        email
        phone
      }
    }
  }
`;

export const GET_DOCUMENT_REQUEST_FILES = gql`
  query getDocumentRequestFiles($input: DocumentFileListRequest) {
    getDocumentRequestFiles(input: $input) {
      contents {
        createdAt
        id
        csvId
        mime
        name
        path
        relations {
          id
          type
        }
        status
        type
      }
      total
    }
  }
`;

export const GET_UPLOADED_FILE_FOR_REQUEST = gql`
  query getUploadedFile($input: GetUploadedFileRequest) {
    getUploadedFile(input: $input) {
      createdAt
      id
      csvId
      mime
      name
      path
      relations {
        id
        type
      }
      status
      type
      data
    }
  }
`;
