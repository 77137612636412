import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BackIcon, ButtonPrimary, DashCard } from 'lib/components';
import { CREATE_MEMBERSHIP_PLAN } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { Column, Container } from 'lib/styles';

import { Switch } from '@frontend/cherry-library';
import useSnackbar from 'lib/hooks/useSnackbar';
import { PlanBasics, PlanBenefits, PlanPricing } from '../components';

const CreatePlan = () => {
  const navigate = useNavigate();
  const { organization } = useStore();
  const [createMembershipPlan, { loading }] = useMutation(CREATE_MEMBERSHIP_PLAN);
  const { isSnackbarVisible } = useSnackbar();

  const [planDetail, setPlanDetail] = useState<any>({});
  const [switchValue, setSwitchValue] = useState<boolean>(false);

  const goBack = () => {
    navigate('/membership/plan/builder');
  };

  const handleChange = (key, value) => {
    setPlanDetail((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const switchHandler = (e) => {
    setSwitchValue(e.target.checked);
  };

  const savePlanHandler = async () => {
    const { data } = await createMembershipPlan({
      variables: {
        input: {
          ...planDetail,
          organizationId: organization?.id,
          detailedDescription: 'detailed description',
          status: switchValue ? 'LIVE' : 'DRAFT',
        },
      },
    });
    if (data.createMembershipsPlan.id) {
      // Succcess
      navigate(-1);
    }
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <Column maxWidth="1160px">
        <Header>
          <ButtonPrimary
            data-testid="backToHome"
            onClick={goBack}
            startIcon={<BackIcon color={'#FFFFFF'} />}
            text={'Back to Home'}
          />
        </Header>
        <DashCard>
          <HeaderContainer>
            <Title>Standard</Title>
            <SwitchContainer>
              <SwitchLabel>Draft</SwitchLabel>
              <Switch id={'1'} name={'switch'} handleClick={switchHandler} isChecked={switchValue} />
              <SwitchLabel>Live</SwitchLabel>
            </SwitchContainer>
          </HeaderContainer>

          <PlanBasics data={planDetail} handleChange={handleChange} />
          <PlanPricing isCreate={true} data={planDetail} handleChange={handleChange} />
          <PlanBenefits data={planDetail} handleChange={handleChange} />
          <ButtonContainer>
            <SavePlanContainer>
              <CancelButton onClick={goBack}>Cancel</CancelButton>
              <ButtonPrimary
                data-testid="saveMember"
                onClick={savePlanHandler}
                disabled={loading}
                text={'Create Plan'}
              />
            </SavePlanContainer>
          </ButtonContainer>
        </DashCard>
      </Column>
    </Container>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.span`
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin: 0 6px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #0e202f;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const SavePlanContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  border: 1px solid #0e202f;
  border-radius: 2px;
  color: #0e202f;
  margin-right: 12px;
  border-radius: 4px;
`;

export default CreatePlan;
