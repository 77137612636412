import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { SEND_APPLICATION_LINK } from 'lib/graphql/mutations';

import { Button } from '@frontend/cherry-library';
import { useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useEventBus } from 'lib/services';
import { PreApprovalStepEnums } from 'lib/types';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer } from './PreApprovalSuccess';
import { PreApprovalResult } from './type';

interface Props {
  isNewASuccessScreen: boolean;
  onClose: () => void;
  setActiveStep: (step: PreApprovalStepEnums) => void;
  setConfetti: (active: boolean) => void;
  preApprovalResult?: PreApprovalResult;
  screenName: string;
}

const PreApprovalButtons = ({
  onClose,
  setConfetti,
  setActiveStep,
  preApprovalResult,
  isNewASuccessScreen,
  screenName,
}: Props) => {
  const { emitEvent } = useEventBus();
  const alert = useAlert();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { merchantLogin, merchantLogout } = useMerchantLogin();
  const [sendOrganizationLink, { loading: applicationSendLoading }] = useMutation(SEND_APPLICATION_LINK);
  const [loading, setLoading] = useState(false);

  const { organization, merchants, patientInfo } = useStore();
  const navigate = useNavigate();

  const openApplyWithPatient = () => {
    onClose();
    setActiveStep(PreApprovalStepEnums.INFORMATION);
    navigate('/apply-with-patient');

    const data = {
      application: applicationName,
      borrower: {
        firstName: patientInfo?.firstName,
        lastName: patientInfo?.lastName,
        ssn: !!patientInfo?.ssn,
        phone: patientInfo?.phone?.replace(/[\(\)\s-]/g, ''),
        address: {
          state: patientInfo?.state,
          city: patientInfo?.city,
          streetAddress: patientInfo?.address,
          zip: patientInfo?.zipCode,
        },
      },
      organizationId: organization?.id,
    };

    if (screenName === 'SUCCESS') {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.PREAPPROVED_APPLY_WITH_PATIENT_CLICKED', data);
    } else if (screenName === 'DENIAL') {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.NOT_PREAPPROVED_APPLY_WITH_PATIENT_CLICKED', data);
    }
  };

  const onContinueHandlerLink = async () => {
    const data = {
      application: applicationName,
      borrower: {
        firstName: patientInfo?.firstName,
        lastName: patientInfo?.lastName,
        ssn: !!patientInfo?.ssn,
        phone: patientInfo?.phone?.replace(/[\(\)\s-]/g, ''),
        address: {
          state: patientInfo?.state,
          city: patientInfo?.city,
          streetAddress: patientInfo?.address,
          zip: patientInfo?.zipCode,
        },
      },
      organizationId: organization?.id,
    };

    if (screenName === 'SUCCESS') {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.PREAPPROVED_TEXT_LINK_TO_PATIENT_CLICKED', data);
    } else if (screenName === 'DENIAL') {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.NOT_PREAPPROVED_TEXT_LINK_TO_PATIENT_CLICKED', data);
    }

    if (!!patientInfo?.phone) {
      setLoading(true);
      try {
        const idOrganization = organization?.id || 0;
        const idMerchant = (merchants && merchants?.[0]?.id) || 0;
        await merchantLogin(idMerchant);

        await sendOrganizationLink({
          variables: { input: { idOrganization, phone: patientInfo?.phone?.replace(/[\(\)\s-]/g, '') } },
        });
        setTimeout(() => {
          setConfetti?.(true);
        }, 300);

        setLoading(false);
        alert.success('Application Sent!');
        await merchantLogout();
      } catch (error) {
        setLoading(false);
        await merchantLogout();
        alert.error('An error occurred, please try again later');
      }
    }
  };

  const viewMonthlyPaymentOptions = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.PREAPPROVED_GET_MONTHLY_OPTIONS_CLICKED', {
      application: applicationName,
      borrower: {
        firstName: patientInfo?.firstName,
        lastName: patientInfo?.lastName,
        ssn: !!patientInfo?.ssn,
        phone: patientInfo?.phone?.replace(/[\(\)\s-]/g, ''),
        address: {
          state: patientInfo?.state,
          city: patientInfo?.city,
          streetAddress: patientInfo?.address,
          zip: patientInfo?.zipCode,
        },
      },
      organizationId: organization?.id,
    });

    onClose();
    emitEvent(BusEvent.OPEN_MONTHLY_PAYMENT_OPTIONS, { ...preApprovalResult, isComingFrom: 'PRE_APPROVAL' });
  };

  return (
    <>
      <UpperButtons>
        {!isNewASuccessScreen && screenName === 'SUCCESS' ? (
          <Button
            fullWidth={true}
            onClick={viewMonthlyPaymentOptions}
            data-testid="view-monthly-payment-options-button"
          >
            View Monthly Payment Options
          </Button>
        ) : (
          <Button
            fullWidth={true}
            onClick={openApplyWithPatient}
            loading={loading || applicationSendLoading}
            disabled={loading || applicationSendLoading}
            data-testid="apply-with-patient-button"
          >
            Apply with Patient
          </Button>
        )}
      </UpperButtons>
      <LowerButtons>
        {screenName !== 'DENIAL' ? (
          <ButtonContainer>
            {!isNewASuccessScreen ? (
              <Button
                variant="secondary"
                fullWidth={true}
                onClick={openApplyWithPatient}
                loading={loading || applicationSendLoading}
                disabled={loading || applicationSendLoading}
                data-testid="apply-with-patient-link-button"
              >
                Apply with Patient
              </Button>
            ) : (
              <Button
                variant="secondary"
                fullWidth={true}
                onClick={viewMonthlyPaymentOptions}
                data-testid="view-monthly-payment-options-link-button"
              >
                View Monthly Payment Options
              </Button>
            )}
          </ButtonContainer>
        ) : null}
        <ButtonContainer>
          <Button
            variant="secondary"
            fullWidth={true}
            loading={loading || applicationSendLoading}
            disabled={!patientInfo?.phone || loading || applicationSendLoading}
            onClick={onContinueHandlerLink}
          >
            Text Application to Patient
          </Button>
        </ButtonContainer>
      </LowerButtons>
    </>
  );
};

const UpperButtons = styled.div`
  width: 100%;
  margin: 16px 0;
`;

const LowerButtons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export default PreApprovalButtons;
