import { Dialog as MuiDialog, DialogActions as MuiDialogActions } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { ButtonPrimary, FixedDecimalFormat } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useEventBus } from 'lib/services';

import { MilestoneProgressBar } from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneProgressBar';
import { Milestone } from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneRewardsWidget';
import { CherryDialogTitle } from 'pages/desktop/Settings/components/Dialog';

interface MilestoneDetailModalProps {
  visible: boolean;
  onClose: () => void;
  progressBarValue: number;
  progressBarDaysLeft: number;
  hasReward: boolean;
  milestoneDetail: Milestone | null;
  horizontal?: boolean;
}

const STEP_DESCRIPTION = {
  0: 'Get 1 Patient Approval through Text',
  1: 'Complete a Patient Checkout',
  2: 'Complete 5 Patient Checkouts',
};

const MilestoneDetailModal = ({
  visible,
  onClose,
  progressBarValue,
  progressBarDaysLeft,
  hasReward,
  milestoneDetail,
  horizontal = false,
}: MilestoneDetailModalProps) => {
  const { user, organization } = useStore();
  const { emitEvent } = useEventBus();
  const { firstName } = user || {};
  const { activatedAt } = organization || {};

  const getDescription = () => {
    if (hasReward) {
      return (
        <div>
          <Text>
            We’re here to help you get set up.
            <br />
            <br />
            Complete easy steps during your practice’s first 30 days with Cherry to unlock a welcome gift from us to
            you!
            <br />
            <br />
            Whatever level of gift you unlock will be delivered directly to your email address within 7 business days of{' '}
            {dayjs(activatedAt).add(30, 'day').format('MMM DD, YYYY')}.
            <br />
            <br />
            Happy earning!
          </Text>
        </div>
      );
    } else {
      return (
        <div>
          <Text>
            We’re here to help you get set up.
            <br />
            <br />
            Complete easy steps during your practice’s first 30 days with Cherry to unlock your practice's full
            potential by offering Cherry to your patients!
          </Text>
        </div>
      );
    }
  };

  const howToCheckoutHandler = () => {
    window.open('https://files.withcherry.com/partner/Practice_Portal_-_Patient_Checkout.pdf', '_blank');
    onClose?.();
  };

  const guidedWalkthroughHandler = () => {
    emitEvent(BusEvent.GUIDED_WALKTHROUGH_TRIGGER);
    onClose?.();
  };

  const getTitleAmountWithReward = () => {
    const totalAmount = milestoneDetail?.campaign?.details?.[2]?.state?.cumulativeRewardAmount;

    return (
      <div>
        Earn up to {totalAmount ? <FixedDecimalFormat amount={totalAmount} prefix="$" decimalScale={0} /> : '$50'}!
      </div>
    );
  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle id="milestone-dialog-title" onClose={onClose}>
        {hasReward ? getTitleAmountWithReward() : 'Welcome to Cherry!'}
      </DialogTitle>
      <Content>
        <Row>
          <DescriptionContainer>
            <Row>
              <WaveIcon src={images?.waveHi} />
              <BoldText>{firstName ? `Hi, ${firstName}!` : 'Hi!'}</BoldText>
            </Row>
            <Gap />
            {getDescription()}
          </DescriptionContainer>
          <RemaningDaysContainer>
            <MilestoneProgressBar
              value={progressBarValue}
              daysLeft={progressBarDaysLeft}
              width={'170px'}
              subtitleFontSize="14px"
              progressTopSubtitle="You have"
              progressBottomSubtitle1="days left"
              progressBottomSubtitle2={hasReward ? 'to earn' : 'to achieve'}
            />
          </RemaningDaysContainer>
        </Row>
        {!hasReward && <Gap />}
        <Border />
        {milestoneDetail?.campaign?.details?.map((milestoneStep) => (
          <StepsRow key={milestoneStep?.orderIndex}>
            <StepText>
              <GreenText>Step {milestoneStep?.orderIndex + 1}:</GreenText>
              <Text>{STEP_DESCRIPTION[milestoneStep?.orderIndex]}</Text>
            </StepText>
            {hasReward ? (
              <>
                <GreenText>
                  +<FixedDecimalFormat amount={milestoneStep?.rewardAmount} prefix="$" decimalScale={0} />
                </GreenText>
                <Text>
                  (
                  <FixedDecimalFormat
                    amount={milestoneStep?.state?.cumulativeRewardAmount}
                    prefix="$"
                    decimalScale={0}
                  />{' '}
                  total reward)
                </Text>
              </>
            ) : null}
          </StepsRow>
        ))}
        <Border />
        <Text>Need a refresher for how to complete these steps? Here are some quick tips.</Text>
      </Content>
      <DialogActions hasReward={hasReward}>
        <CloseButton onClick={howToCheckoutHandler} text="How to Checkout a Patient" />
        {!horizontal && <CloseButton onClick={guidedWalkthroughHandler} text="Guided Walkthrough" />}
      </DialogActions>
      {hasReward ? (
        <FooterText>
          Note: Cherry reserves the right to validate authenticity of user profiles and limit rewards at their sole
          discretion.
        </FooterText>
      ) : null}
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 561px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  font-size: 14px;
  margin: 24px 0;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const DescriptionContainer = styled.div`
  width: 50%;
`;

const RemaningDaysContainer = styled.div`
  width: 50%;
`;

const DialogTitle = styled(CherryDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const DialogActions = styled(MuiDialogActions)<{ hasReward: boolean }>`
  & > button {
    flex: 1;
  }
  padding: 0 !important;
  margin-bottom: ${(props) => (props.hasReward ? '24px' : '0')};
`;

const CloseButton = styled(ButtonPrimary)`
  color: #879097 !important;
  border-color: #879097 !important;
  background-color: #ffffff !important;
  margin-right: 16px !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  &:hover,
  &:focus {
    background-color: #879097 !important;
    color: #ffffff !important;
  }
`;

const Text = styled.span`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
`;

const GreenText = styled.div`
  color: ${(props) => props.theme.main.green};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  margin-right: 4px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const StepText = styled.div`
  display: flex;
  align-items: baseline;
  min-width: 55%;
`;

const StepsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Gap = styled.div`
  padding-top: 16px;
`;

const WaveIcon = styled.img`
  margin-right: 16px;
`;

const Border = styled.div`
  width: 100%;
  border: 1px solid #e7e9ea;

  margin: 24px 0;
`;

const BoldText = styled.span`
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
`;

const FooterText = styled.span`
  color: ${(props) => props.theme.main.midnightBlue40};
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default MilestoneDetailModal;
