import { Dialog, Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { CircleCheckIcon } from 'lib/components';
import { CherryDialogTitle, ContinueButton, DialogContainer, SubTitle } from 'pages/desktop/Settings/components/Dialog';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  handleClose: any;
  modalData: any | null;
}

export const CreateMemberSuccessModal = ({ open, handleClose, modalData }: Props) => {
  const navigate = useNavigate();

  const onCloseHandler = () => {
    handleClose();
    navigate(`/membership/${modalData?.id}`);
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Member Created Successfully!
        </CherryDialogTitle>
        <Row>
          <CircleCheckIcon />
        </Row>

        <SubTitle>
          {modalData?.customer?.firstName} {modalData?.customer?.lastName} was created and added to the{' '}
          {modalData?.planName} plan! You can begin logging benefits usage immediately.
        </SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <OkayButton onClick={onCloseHandler}>Okay</OkayButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto 10px;
`;

export const FormLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
  margin-bottom: 0px;
  margin-top: 20px;
`;

const OkayButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;
