import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DropDown, FixedDecimalFormat, SecondaryCancelLoanButton } from 'lib/components';
import CheckoutOptions, {
  SEND_OPTIONS,
} from 'pages/desktop/Dashboard/DentalDashboard/views/CheckoutOptions/CheckoutOptions';
import {
  BottomButtons,
  Center,
  Column,
  DataContainer,
  DropDownBorder,
  FormLabel,
  LoanActionButton,
  Row,
  SecondaryLoanActionButton,
  TextH5,
  TextLink,
  TextSecondary,
} from './Common';

enum PAGES {
  AMOUNT = 'AMOUNT',
  SEND_OPTIONS = 'SEND_OPTIONS',
}
export const CheckoutForm = ({
  isEditMode,
  selectedModalItem,
  options,
  onMerchantSelect,
  closeExpanded,
  currencyInput,
  processEditApplication,
  confirmStart,
  amount,
  cancelStart,
  onAmountChange,
  selectedSendOption,
  setSelectedSendOption,
  setEmail,
}) => {
  const [defaultValue, setDefaultValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(PAGES.AMOUNT);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const handleEmailChange = (value) => {
    setEmailAddress(value);
    setEmail(value);
  };
  const isCheckoutDisabled = () => {
    if (selectedSendOption === SEND_OPTIONS.EMAIL) {
      return !isEmailValid;
    } else {
      return !amount;
    }
  };

  const handleContinue = () => {
    setPage(PAGES.SEND_OPTIONS);
  };

  const setAmountPage = () => {
    setPage(PAGES.AMOUNT);
    if (onAmountChange) {
      onAmountChange(amount);
    }
  };

  const handleCheckoutContinue = () => {
    confirmStart();
  };

  useEffect(() => {
    findPreferedLocation();
  }, []);

  const findPreferedLocation = () => {
    const merchantId = selectedModalItem?.activeLoan?.merchantId || selectedModalItem?.merchantId;
    const findLocation = options?.find((opt) => opt?.value === merchantId);
    if (findLocation) {
      onMerchantSelect(findLocation);
      setDefaultValue(findLocation.value);
    }
    setLoading(false);
  };

  const getCheckoutForm = () => (
    <CheckoutFormContainer>
      <DataContainer>
        <Row>
          <Column flex={1}>
            <TextH5>{isEditMode ? 'Edit Payment Plan' : 'Process Payment Plan'} </TextH5>

            <TextSecondary>
              {isEditMode ? (
                <>
                  Update the approval amount for {selectedModalItem?.borrower?.firstName}. They’ll receive a new link on
                  their phone.
                </>
              ) : (
                <>
                  Send {selectedModalItem?.borrower?.firstName} {selectedModalItem?.borrower?.lastName} a link so they
                  can choose and confirm their payment plan.
                </>
              )}
            </TextSecondary>
          </Column>
        </Row>
        <Row>
          <Column flex={4}>
            <FormLabel>Purchase Amount (including tax)</FormLabel>
            {currencyInput}
          </Column>
        </Row>
        <Row>
          <Column flex={4}>
            <FormLabel>Checkout Location</FormLabel>
            {!loading ? (
              <DropDownBorder>
                <DropDown
                  options={options}
                  onChange={onMerchantSelect}
                  hoveredMode={false}
                  defaultValue={defaultValue}
                />
              </DropDownBorder>
            ) : null}
          </Column>
        </Row>
      </DataContainer>
      <>
        <BottomButtons>
          <Column flex={1}>
            <SecondaryCancelLoanButton
              variant={'filled'}
              onClick={closeExpanded}
              data-tag="checkout"
              className="btn--cancel"
            >
              {!isEditMode ? 'Close' : 'Nevermind'}
            </SecondaryCancelLoanButton>
          </Column>
          <Column flex={2}>
            <SecondaryLoanActionButton
              data-testid="checkout-action-button"
              disabled={!amount}
              onClick={isEditMode ? processEditApplication : handleContinue}
            >
              {isEditMode ? 'Update and Send' : 'Continue'}
            </SecondaryLoanActionButton>
          </Column>
        </BottomButtons>
        {isEditMode ? (
          <Column>
            <TextLink mb={40} data-testid="checkout-cancel-link" onClick={cancelStart}>
              Cancel this plan instead
            </TextLink>
          </Column>
        ) : (
          ''
        )}
      </>
    </CheckoutFormContainer>
  );

  const getCheckoutSendOptions = () => (
    <CheckoutSendOptionsContainer>
      <DataContainer>
        <Row>
          <Column flex={1}>
            <TextH5>{'Process Payment Plan'} </TextH5>

            <TextSecondary>
              <>
                Send {selectedModalItem?.borrower?.firstName} {selectedModalItem?.borrower?.lastName} a link so they can
                choose and confirm their payment plan.
              </>
            </TextSecondary>
          </Column>
        </Row>
        <Row>
          <CheckoutOptions
            selectedOption={selectedSendOption}
            setSelectedSendOption={setSelectedSendOption}
            phoneNumber={selectedModalItem?.borrower?.phone}
            emailValidationCallback={setIsEmailValid}
            handleEmailChange={handleEmailChange}
            emailAddress={emailAddress}
          />
        </Row>
      </DataContainer>
      <BottomButtons>
        <Column flex={1}>
          <SecondaryCancelLoanButton
            variant={'filled'}
            onClick={setAmountPage}
            data-tag="checkout"
            className="btn--cancel"
          >
            Back
          </SecondaryCancelLoanButton>
        </Column>
        <Column flex={2}>
          <CheckoutForAmountButton
            data-testid="checkout-action-button"
            disabled={isCheckoutDisabled()}
            onClick={handleCheckoutContinue}
          >
            <>
              Checkout
              {Boolean(amount) && (
                <span>
                  <span> for </span>
                  <FixedDecimalFormat amount={amount || selectedModalItem?.amount} prefix="$" />
                </span>
              )}
            </>
          </CheckoutForAmountButton>
        </Column>
      </BottomButtons>
    </CheckoutSendOptionsContainer>
  );

  const getTwoStepCheckout = () => (
    <>
      {page === PAGES.AMOUNT && getCheckoutForm()} {page === PAGES.SEND_OPTIONS && getCheckoutSendOptions()}
    </>
  );

  return <Center>{isEditMode ? getCheckoutForm() : getTwoStepCheckout()}</Center>;
};

const CheckoutSendOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CheckoutForAmountButton = styled(LoanActionButton)`
  margin: 0;
  font-weight: 600;
`;

const CheckoutFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
