import { Grid } from '@mui/material';
import { DataLoader, FixedDecimalFormat } from 'lib/components';
import React, { useState } from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { TierName } from 'lib/utils';
import { useNavigate } from 'react-router-dom';

export interface QuickStatsProps {
  loading: boolean;
  from: string;
  amount: any;
  rates?: any;
}

export const QuickStats = (props: QuickStatsProps) => {
  const { loading, amount, rates, from } = props;
  const { organizationTier } = useStore();
  const navigate = useNavigate();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const handleLearnMoreClick = () => {
    navigate('/settings', {
      state: {
        autoFocusToPricingTier: true,
      },
    });
  };

  const popoverHandler = () => {
    const tierName = `${TierName(organizationTier?.organizationTier?.tierId)} Tier`;
    if (organizationTier?.hasTier) {
      return (
        <IconContainer onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <Icon src={'/info_circle_outline.svg'} />
          {popoverOpen && (
            <Popover>
              <PopoverContent>
                <PopoverText>
                  You are in the <b>{tierName}</b>. You can move to a higher tier and lower your practice fees if your
                  transactions meet certain volume thresholds.{' '}
                  <PopoverLinkText onClick={handleLearnMoreClick}>Learn More</PopoverLinkText>
                </PopoverText>
              </PopoverContent>
              <DownCircle />
            </Popover>
          )}
        </IconContainer>
      );
    }
    return null;
  };

  return (
    <Flex>
      <CenteredGrid item={true} sm={/*from === 'dashboard' ? 5 :*/ 5}>
        <HeaderText>Quick Stats</HeaderText>
      </CenteredGrid>
      <Grid item={true} sm={2}>
        <Column>
          <DataLoader loading={loading}>
            <DataItemText>
              <FixedDecimalFormat amount={from === 'dashboard' ? amount?.grossSales : amount?.netSales} prefix={'$'} />
            </DataItemText>
            <DataItemInfoText>
              {from === 'dashboard' ? <PopoverContainer>Gross Sales {popoverHandler()}</PopoverContainer> : 'Net Sales'}
            </DataItemInfoText>
          </DataLoader>
        </Column>
      </Grid>
      <Grid item={true} sm={2}>
        <Column>
          <DataLoader loading={loading}>
            <DataItemText>
              <FixedDecimalFormat amount={amount?.contracts} decimalScale={0} />
            </DataItemText>
            <DataItemInfoText>Contracts</DataItemInfoText>
          </DataLoader>
        </Column>
      </Grid>
      {from === 'dashboard' ? (
        <>
          <Grid item={true} sm={2}>
            <Column>
              <DataLoader loading={loading}>
                <DataItemText>
                  <FixedDecimalFormat amount={rates?.rate} suffix="%" />
                </DataItemText>
                <DataItemInfoText>Approval Rate</DataItemInfoText>
              </DataLoader>
            </Column>
          </Grid>
          <Grid item={true} xs={2}>
            <Column>
              <DataLoader loading={loading}>
                <DataItemText>
                  <FixedDecimalFormat amount={rates?.outstanding || 0} prefix={'$'} />
                </DataItemText>
                <DataItemInfoText>{/* Outstanding */} Approvals</DataItemInfoText>
              </DataLoader>
            </Column>
          </Grid>
        </>
      ) : (
        <>
          <Grid item={true} xs={2}>
            <Column>
              <DataLoader loading={loading}>
                <DataItemText>
                  <FixedDecimalFormat amount={amount?.refunds || 0} prefix={'$'} />
                </DataItemText>
                <DataItemInfoText>Refunds</DataItemInfoText>
              </DataLoader>
            </Column>
          </Grid>
          <Grid item={true} xs={2}>
            <Column>
              <DataLoader loading={loading}>
                <DataItemText>
                  <FixedDecimalFormat amount={amount?.fees || 0} prefix={'$'} />
                </DataItemText>
                <DataItemInfoText>Fees</DataItemInfoText>
              </DataLoader>
            </Column>
          </Grid>
          <Grid item={true} xs={2}>
            <Column>
              <DataLoader loading={loading}>
                <DataItemText>
                  <FixedDecimalFormat amount={amount?.grossSales || 0} prefix={'$'} />
                </DataItemText>
                <DataItemInfoText>Gross Sales</DataItemInfoText>
              </DataLoader>
            </Column>
          </Grid>
        </>
      )}
    </Flex>
  );
};

const HeaderText = styled.div`
  font-size: 20px;
  color: #0e202f;
  margin-top: -5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 24px;
`;
const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
`;

const DataItemText = styled.span`
  color: ${(props) => props.theme.main.textColor};
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  border-right: 1px solid #dadada;
`;

const DataItemInfoText = styled.span`
  font-size: 14px;
  color: #8b8c99;
  font-weight: 400;
  text-align: center;
  border-right: 1px solid #dadada;
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  box-sizing: border-box;
`;

const PopoverContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  padding-top: 3px;
  position: relative;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 5px;
  padding-top: 2px;
  filter: opacity(0.5) drop-shadow(0 0 0 #8b8c99);
`;

const Popover = styled.div`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: 450px;
  z-index: 10;
  left: -9px;
  top: -70px;
`;

const DownCircle = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00c37d;
  position: absolute;
  left: 15px;
`;

const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
`;

const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-align: left;
`;

const PopoverLinkText = styled.span`
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
`;
