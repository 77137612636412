import { Box } from '@mui/material';

import { Loading } from 'lib/components';
import React from 'react';
import styled from 'styled-components';

interface DashComponentProps {
  loading?: boolean;
  dashHeader?: string;
  dashDescription?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const DashComponent = ({ loading, children, dashHeader, dashDescription, ...rest }: DashComponentProps) => {
  return (
    <CustomBox {...rest}>
      {loading ? <Loading size={10} /> : children}
      {/* <Grid container> */}
      <HeaderDash>{dashHeader}</HeaderDash>
      <DescrDash>{dashDescription}</DescrDash>
      {/* </Grid> */}
    </CustomBox>
  );
};

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column !important;
`;

export const Card = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 50px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 50px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 50px 0px rgba(0, 0, 0, 0.3);
  padding: 32px;
  box-sizing: border-box;
  flex-direction: column;
`;

export const HeaderDash = styled.h5`
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.main.textColor};
`;

export const DescrDash = styled.h6`
  font-size: 14px;
  color: ${(props) => props.theme.main.textColor};
  margin-bottom: 16px;
  margin-top: 0px;
  font-weight: 400;
`;

interface DashCardProps {
  transparent?: string;
}

export const DashCard = styled(Card)<DashCardProps>`
  display: flex;
  position: relative;
  margin-bottom: 40px;
  background-color: ${(props) => (props.transparent === 'transparent' ? 'unset' : '#FFFFFF')};
  box-shadow: ${(props) =>
    props.transparent === 'transparent'
      ? 'unset'
      : '0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);'};
  border-radius: ${(props) => (props.transparent === 'transparent' ? '0px' : '4px')};
  padding: ${(props) => (props.transparent === 'transparent' ? '0px' : '24px')};
`;

export const DashHeader = styled.h4`
  font-weight: bold;
  font-size: 20px;
  color: ${(props) => props.theme.main.textColor};
`;

export const DashParagraph = styled.p`
  margin-top: 24px;
  margin-bottom: 16px;
`;
