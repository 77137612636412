export enum SearchAPIOperators {
  EQUALITY = 'EQUALITY',
  GREATER_THAN = 'GREATER_THAN',
  EQUAL_OR_GREATER_THAN = 'EQUAL_OR_GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL_OR_LESS_THAN = 'EQUAL_OR_LESS_THAN',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  IN = 'IN',
}

export interface SearchCriteriaItem {
  fieldName: string;
  fieldValue: unknown;
  searchOperation: SearchAPIOperators;
}
