import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  Box,
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  Icon,
  Subtitle,
  TextField,
  Title,
} from 'lib/components/mobile';
import { useLogin } from 'lib/services';
import { useNavigate } from 'react-router-dom';
import { useSegment } from 'lib/hooks/useSegment';

const MobileLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { fieldOnChange, email, inputOnBlur, password, loginUser, isButtonEnabled, loading, emailValid } = useLogin();
  const { trackIdentify, trackPage, trackSegmentEvent, applicationName } = useSegment();
  const navigate = useNavigate();

  useEffect(() => {
    trackIdentify();
    trackPage('landingPage')
  }, [])

  const showPasswordHandler = () => setShowPassword(!showPassword);

  const onForgotPasswordHandler = () => {
    trackSegmentEvent('Password Reset', {
      application: applicationName,
      stage: 'Begin reset'
    })
    navigate('/forgot-password')
  };

  return (
    <Container pageTitle={'Practice Portal Login'}>
      <ContentContainer>
        <Icon src={'cherry-logo'} />
        <Title m={'10px 0px 8px 0px'}>Sign into your Practice Portal</Title>
        <Subtitle m={'0px 0px 24px 0px'}>Please enter your email and password</Subtitle>
        <Box>
          <TextField
            id="email-input"
            variant="filled"
            data-testid="email"
            label="Email"
            type="email"
            value={email}
            onChange={fieldOnChange}
            onBlur={inputOnBlur}
            name="email"
            error={email && !emailValid}
            helperText={email && !emailValid ? 'Please enter a valid email address' : ''}
          />
          <TextField
            id="filled-basic"
            variant="filled"
            data-testid="password"
            label="Password"
            name="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={fieldOnChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={showPasswordHandler}>
                  {showPassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={isButtonEnabled} loading={loading} type="submit" onClick={loginUser}>
          Login
        </Button>
        <ButtonText onClick={onForgotPasswordHandler}>Forgot Password?</ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default MobileLogin;

const ButtonText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
`;
