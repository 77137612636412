import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { Loading } from 'lib/components';
import { CherryDialogTitle, DialogContainer } from 'pages/desktop/Settings/components/Dialog';
import React, { Suspense } from 'react';
import styled from 'styled-components';

const Accordion = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.Accordion })),
);

const AccordionSection = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.AccordionSection })),
);

interface Props {
  open: boolean;
  onClose: () => void;
  messages?: any;
}

enum MessageTitle {
  'REMINDER' = 'Reminder',
  'CHECKOUT_LINK' = 'Checkout Link',
}

export const OutboundMessageModal = ({ onClose, open, messages }: Props) => {
  const handleRowClicked = (e) => {
    if (e?.target?.dataset?.type !== 'CHECKOUT_LINK') {
      e?.target?.parentNode?.parentNode?.parentNode?.lastChild?.click();
    }
  };
  const decideActiveSection = () => {
    if (messages && messages?.length > 0 && messages?.[0].type !== 'CHECKOUT_LINK') {
      return ['accordion_0'];
    }
    return [];
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={onClose}>
          Outbound Messages
        </CherryDialogTitle>
        <Suspense fallback={<Loading fixed={true} size={40} />}>
          <CherryFaq id="faq--accordion" multiple={true} activeSectionNames={decideActiveSection()}>
            {messages?.map((item: any, index: number) => {
              return (
                <CherryFaqSection
                  key={item?.id}
                  name={`accordion_${index}`}
                  data-testid="faq-container"
                  hasIcon={item?.type !== 'CHECKOUT_LINK'}
                  label={
                    <FaqHeader
                      data-id={item?.id}
                      data-type={item?.type}
                      data-testid="faq-header"
                      onClick={handleRowClicked}
                    >
                      <Icon src={item?.type === 'REMINDER' ? 'icon/notification.svg' : 'icon/link.svg'} />
                      {MessageTitle[item?.type]} - {dayjs(item?.createdAt).format('MMM DD, YYYY')} at{' '}
                      {dayjs(item?.createdAt).format('hh:mm A')}
                    </FaqHeader>
                  }
                >
                  {' '}
                  <div dangerouslySetInnerHTML={{ __html: item.message }} />
                </CherryFaqSection>
              );
            })}
          </CherryFaq>
        </Suspense>
      </DialogContainer>
    </Dialog>
  );
};

const CherryFaq = styled(Accordion)`
  li:not(first-child) {
    border-top: 1px solid #dadada;
  }
  border-bottom: 1px solid #dadada;
  margin-bottom: 24px;
  svg {
    fill: ${(props) => props.theme.main.green};
    margin-right: 16px;
  }
`;

const CherryFaqSection = styled(AccordionSection)<{ hasIcon?: boolean }>`
  button {
    display: ${(props) => (props.hasIcon ? 'inherit' : 'none')};
  }
  button:focus {
    box-shadow: 0 0 0 0;
  }
`;

const FaqHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 10px;
`;
