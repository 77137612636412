import { useApolloClient, useMutation } from '@apollo/client';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as CherryButton, Dropdown } from '@frontend/cherry-library';
import { Chip, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { APP_ENV } from 'config';
import { DashCard, DashComponent } from 'lib/components';
import { UPDATE_MERCHANT_FINDER_SERVICES } from 'lib/graphql/mutations';
import { GET_ALL_SERVICES, GET_MERCHANT_FINDER_DETAIL } from 'lib/graphql/queries';
import { FeatureNames } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';

export const MerchantFinder = () => {
  const { merchants, features, organization } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const alert = useAlert();
  const client = useApolloClient();
  const [updateMerchantFinderServices] = useMutation(UPDATE_MERCHANT_FINDER_SERVICES);
  const [selectedMerchantId, setSelectedMerchantId] = useState<any>(merchants?.[0]?.id);
  const [merchantFinderInformation, setMerchantFinderInformation] = useState<any>('');
  const [allServices, setAllServices] = useState<any[]>([]);
  const [offeredServiceOptions, setOfferedServiceOptions] = useState<any[]>([]);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [userServices, setUserServices] = useState<any[]>([]);
  const [isUserServicesChanged, setIsUserServicesChanged] = useState(false);
  const providerFinderUrl =
    APP_ENV?.toLowerCase() === 'master' ? `https://finder.withcherry.com` : `https://${APP_ENV}-finder.withcherry.com`;

  const options =
    merchants?.map((merchant) => {
      return {
        value: merchant.id,
        label: `<b><span>${merchant?.name}</span> </b><br/>  ${merchant?.address?.address1} - ${merchant?.address.city?.name} `,
      };
    }) || [];

  const selectedUserServicesValueList = userServices.map((userService) => userService?.value);

  useEffect(() => {
    getMerchantServices();
  }, []);

  useEffect(() => {
    fetchMerchantDetail();
  }, [merchants, selectedMerchantId]);

  useEffect(() => {
    setDisabled(userServices.length === 0 ? true : false);
  }, [userServices]);

  const fetchMerchantDetail = async () => {
    try {
      if (selectedMerchantId) {
        const { data } = await client.query({
          query: GET_MERCHANT_FINDER_DETAIL,
          variables: {
            input: {
              id: selectedMerchantId,
            },
          },
        });
        const merchantDetailInfo = await data?.getMerchantDetailRequest;
        setMerchantFinderInformation(merchantDetailInfo || []);

        const userMappedServices: any = [];

        merchantDetailInfo?.services?.map((node) => {
          userMappedServices.push({ value: node?.id, label: node?.name });
        });

        setUserServices(userMappedServices);
      } else {
        throw new Error('Merchant ID Not Found');
      }
    } catch (error) {
      alert.error((error as Error)?.message);
    }
  };

  const getMerchantServices = async () => {
    const { data } = await client.query({
      query: GET_ALL_SERVICES,
    });

    const mappedServices: any = [];
    const services = data?.getAllServices?.contents;

    services?.map((node) => {
      mappedServices.push({ value: node.id, label: node.name });
    });
    setAllServices(mappedServices);
    setOfferedServiceOptions(mappedServices);
  };

  const onSaveChangesClicked = async () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Updated Provider Finder', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      updatedServices: isUserServicesChanged,
    });

    const selectedServiceIds: any = [];
    userServices.map((i) => selectedServiceIds.push(i.value));
    if (userServices.length > 0) {
      const object = {
        serviceIds: selectedServiceIds,
      };

      const { data } = await updateMerchantFinderServices({
        variables: {
          input: {
            merchantId: merchantFinderInformation?.id,
            ...object,
          },
        },
      });

      if (data?.updateMerchantFinderServices?.success) {
        alert.success('Provider Finder listing updated successfully!');
      } else {
        alert.error('An error occurred!');
      }
    } else {
      alert.error('Please choose at least one service');
    }
  };

  const onChange = (e) => {
    setSelectedMerchantId(e?.target?.value);
  };

  const onServiceChange = (event) => {
    const services = event?.target?.value || [];

    if (services.length === 0) {
      const userMappedServices: any = [];
      merchantFinderInformation?.services?.map((node) => {
        userMappedServices.push({ value: node?.id, label: node?.name });
      });

      setUserServices(userMappedServices);
    }

    const filteredServices = allServices?.filter((service) => services.includes(service?.value));
    setUserServices(filteredServices);
    setIsUserServicesChanged(true);
  };

  const onChipClicked = (e) => {
    const filteredArr = userServices.filter((v) => v.value !== e.value);
    setUserServices(filteredArr);
  };

  if (!features?.includes(FeatureNames.PROVIDER_FINDER)) {
    return null;
  }

  return (
    <Column margin="0">
      <DashComponent dashHeader={'Practice Finder Settings'} />
      <DashCard>
        <Grid item={true} style={{ padding: '12px 0' }}>
          <Row fontSize={'14px'}>
            This information will be shown in search results on Cherry's&nbsp;{' '}
            <Link href={providerFinderUrl} target="_blank">
              Practice Finder
            </Link>
            &nbsp;and in other locations as noted.
          </Row>
        </Grid>
        <Row>
          <Grid container={true}>
            <Grid item={true} xs={7} style={{ margin: '16px 0' }}>
              <Dropdown
                options={options}
                onChange={onChange}
                value={selectedMerchantId}
                defaultValue={selectedMerchantId}
              />
            </Grid>
          </Grid>
        </Row>
        <Row>
          <Grid item={true} xs={12}>
            <SubHeader size={'14px'} marginBottom="8px">
              List Treatments and Services
            </SubHeader>
            <Row fontSize={'14px'} marginBottom="16px">
              Select the treatments and services offered at this location.
            </Row>
            <Grid item={true} xs={7} style={{ margin: '16px 0' }}>
              <Dropdown
                multiple={true}
                label="Treatments and Services"
                options={offeredServiceOptions}
                onChange={onServiceChange}
                searchable={true}
                value={selectedUserServicesValueList}
              />
            </Grid>
            <ChipContainer>
              {userServices?.map((item, index) => {
                return (
                  <CustomChip
                    key={`${item?.value}-${index}`}
                    label={item?.label}
                    data-value={item?.value}
                    id={item?.value}
                    clickable={true}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => onChipClicked(item)}
                    icon={
                      <>
                        <FontAwesomeIcon id="check" icon={faCheckCircle} />
                        <FontAwesomeIcon id="delete" icon={faTimesCircle} />
                      </>
                    }
                  />
                );
              })}
            </ChipContainer>
          </Grid>
        </Row>

        <Border />

        <Grid item={true}>
          <SaveButton
            variant="primary"
            data-testid="requestPlanChangeButton"
            disabled={disabled}
            onClick={onSaveChangesClicked}
          >
            Save Changes
          </SaveButton>
        </Grid>
      </DashCard>
    </Column>
  );
};

const ChipContainer = styled.div`
  padding: 16px 0px 0;

  > div {
    margin-bottom: 10px;
  }

  > div {
    margin-right: 8px;
  }
`;

const CustomChip = styled(Chip)`
  background-color: #e6f9f2 !important;
  color: #00c37d !important;
  cursor: pointer !important;
  text-transform: uppercase;
  margin: 0 4px;

  #delete {
    display: none;
  }
  #check {
    display: block;
  }
  :hover {
    #delete {
      display: block;
    }
    #check {
      display: none;
    }
  }
  svg {
    margin-left: 8px;
    color: #00c37d !important;
  }

  & .MuiChip-root {
    height: 25px;
  }

  & .MuiChip-label {
    font-size: 10px;
    padding-left: 6px;
  }

  &:hover {
    background-color: #fdebef !important;
    color: #ec3360 !important;

    svg {
      color: #ec3360 !important;
    }
  }
`;

const Link = styled.a`
  font-weight: 700;
  color: #00c37d;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  margin: 14px 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const SaveButton = styled(CherryButton)`
  width: 200px;
  z-index: -1;
`;
