import { useApolloClient, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATCH_APPLICATION } from 'lib/graphql/mutations';
import { FETCH_APPLICATION_LIST, GET_APPLICATION_LATEST_REMINDER } from 'lib/graphql/queries';
import { FetchApplicationListSearch } from 'lib/graphql/searches';
import {
  DashboardAnalyticsEventNames,
  FeatureNames,
  useAnalytics,
  useMerchantLogin,
  useSentry,
  useViewport,
} from 'lib/hooks';
import { usePagination } from 'lib/hooks/usePagination';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useApplicationListTableColumns } from 'lib/tables';
import { Merchant, StepEnums } from 'lib/types';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { ApplicationListRequestObject, OutboundMessages, Permissions } from 'lib/types/Merchant';
import { checkHasClass, DropdownItemValue, formatPhoneNumber, MIN_CHECKOUT_ISSUE_MINUTES } from 'lib/utils';

interface ApplicationFilterContextValue {
  refreshTable: () => Promise<void>;
  updatedLoan: StepEnums;
  loanUpdated: (e: StepEnums) => void;
  setUpdatedLoan: React.Dispatch<React.SetStateAction<StepEnums>>;
  setExpandableOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setLoanUpdated: (value: boolean) => void;
  setSelectedItem: React.Dispatch<any>;
  tableColumns: any[];
  approvals: any[];
  loading: boolean;
  paginationLoading: boolean;
  pageChange: (pageNumber: number) => void;
  rowsCountChange: (perPage: number, pageNumber: number) => void;
  total: number;
  onTableSort: (column: { selector?: any }, direction: 'asc' | 'desc') => void;
  selectedModalItem: any;
  setSelectedModalItem: (selectedModalItem) => void;
  handleRowClicked: (row: any) => void;
  expandableOpened: boolean;
  selectedItem: any;
  gridRef: React.MutableRefObject<HTMLDivElement>;
  filterRef: React.MutableRefObject<HTMLDivElement>;
  scrollOffset: number;
  intervalLoading: boolean;
  filterWidth: number;
  rowsPerPage: number;
  setIntervalLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateApplicationRequest: () => void;
  getApplications: (opts: {
    limit?: number;
    offset?: number;
    searchKey?: string;
    mobilePagination?: boolean;
  }) => Promise<void>;
  filterHeight: number;
  showSchedule: boolean;
  onScheduleDateSelected: (value: string) => Promise<void>;
  toggleDatePicker: () => void;
  dataTableRef: React.MutableRefObject<HTMLDivElement>;
  getExpiringSoonApplications: () => Promise<void>;
  getExpiringSoonApplicationsCount: () => Promise<number>;
  getReadyToCheckoutApplications: () => any;
  getNewTodayApplications: () => Promise<void>;
  isExpiringTodayEnabled: boolean;
  isNewApplicationEnabled: boolean;
  permissions: Permissions;
  outboundMessages: null;
  closeOutboundMessageModal: () => void;
  textReminderData: {
    message: string;
    applicationId: number;
    outboundMessages: OutboundMessages[];
    borrower: any;
  } | null;
  extendApproval: null;
  closeTextReminderModal: () => void;
  closeExtendApprovalModal: () => void;
  defaultFilter: ApplicationListRequestObject;
  setApplicationRequestObj: React.Dispatch<React.SetStateAction<ApplicationListRequestObject>>;
  applicationRequestObj: ApplicationListRequestObject;
  setQuickFilterLabel: React.Dispatch<React.SetStateAction<string>>;
  quickFilterLabel: string;
  isCheckoutModalOpened: boolean;
  toggleIsCheckoutModalOpened: () => void;
  getMerchantTextReferralApplications: () => any;
}

const ApplicationFilterContext = createContext<ApplicationFilterContextValue | undefined>(undefined);

export const ApplicationFilterProvider = ({ children }) => {
  const client = useApolloClient();
  const { captureException } = useSentry();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const navigate = useNavigate();
  const { merchantLogout, merchantLogin } = useMerchantLogin();
  const [patchApplication] = useMutation(PATCH_APPLICATION);
  const { organization, merchants, locations, features, permissions, setTakeHomeApprovalModalOpen } = useStore();
  const { rowsPerPage, setRowsPerPage, paginationLoading, setPaginationLoading, setPage, page } = usePagination({});
  const gridRef = useRef<HTMLDivElement>(document.createElement('div'));
  const dataTableRef = useRef<HTMLDivElement>(document.createElement('div'));
  const filterRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [selectedModalItem, setSelectedModalItem] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [approvals, setApprovals] = useState<any[]>([]);
  const [schedulingId, setSchedulingId] = useState(0);
  const [intervalLoading, setIntervalLoading] = useState(false);
  const [isNewApplicationEnabled, setIsNewApplicationEnabled] = useState(false);
  const [isExpiringTodayEnabled, setIsExpiringTodayEnabled] = useState(false);
  const [updatedLoan, setUpdatedLoan] = useState<StepEnums>(StepEnums.CHECKOUT_FORM);
  const [isLoanUpdated, setIsLoanUpdated] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [quickFilterLabel, setQuickFilterLabel] = useState('All Applicants');
  const [expandableOpened, setExpandableOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [filterWidth, setFilterWidth] = useState(0);
  const [outboundMessages, setOutboundMessages] = useState(null);
  const [extendApproval, setExtendApproval] = useState(null);
  const [textReminderData, setTextReminderData] = useState<{
    message: string;
    applicationId: number;
    outboundMessages: OutboundMessages[];
    borrower: any;
  } | null>(null);
  const [isCheckoutModalOpened, setIsCheckoutModalOpened] = useState(false);

  const defaultFilter: ApplicationListRequestObject = {
    pagination: {
      limit: 10,
      offset: 0,
      order: 'DESC',
      orderBy: undefined,
    },
    filter: {
      demo: organization?.isDemo,
      validity: 'ACTIVE',
      organizationId: organization?.id,
      readyForCheckout: undefined,
      expireAt: [
        {
          key: 'expireAt',
          value: dayjs().utc().endOf('day').toISOString(),
          operator: GenericSearchOperators.BIGGER_THAN,
        },
      ],
      createdAt: undefined,
      status: ['APPROVED,INCOME_VERIFICATION'],
      merchantIds: undefined,
      scheduleStatus: undefined,
    },
    searchString: undefined,
  };

  const [applicationRequestObj, setApplicationRequestObj] = useState<ApplicationListRequestObject>(defaultFilter);

  const { scrollOffset } = useViewport();

  const merchantLocation = (row: any) => {
    if (row?.merchantId) {
      const findMerchantName = locations?.find((merchant: Merchant) => merchant?.id === row?.merchantId.toString());
      return findMerchantName?.name || '';
    }
    return '';
  };

  const merchantColumn =
    locations && locations?.length > 1
      ? [
          {
            name: 'Location Preference',
            selector: 'location',
            cell: merchantLocation,
            ignoreRowClick: true,
          },
        ]
      : [];

  const tableColumns = useApplicationListTableColumns(expandableOpened, selectedItem, merchantColumn);
  const filterHeight = filterRef.current?.clientHeight;

  const closeOutboundMessageModal = () => setOutboundMessages(null);
  const closeTextReminderModal = () => {
    paginateTable(page);
    setTextReminderData(null);
  };
  const closeExtendApprovalModal = () => {
    setExtendApproval(null);
  };

  useEffect(() => {
    if (isLoanUpdated) {
      updateApplicationRequest();
    }
  }, [isLoanUpdated]);

  useEffect(() => {
    if (organization) {
      updateApplicationRequest();
    }
  }, [applicationRequestObj, organization]);

  useEffect(() => {
    const containerWidth = gridRef?.current?.offsetWidth;
    const tableWidth = dataTableRef?.current?.offsetWidth;
    if (containerWidth && tableWidth) {
      setFilterWidth(containerWidth - tableWidth);
    }
  }, [dataTableRef?.current?.offsetWidth]);

  const handleRowClicked = useCallback((row) => {
    if (row.openItemModal()) row.openItemModal();
  }, []);

  const toggleDatePicker = () => {
    setShowSchedule(!showSchedule);
  };

  const updateApplicationRequest = () => {
    if (applicationRequestObj) {
      getApplications({}).then(() => {
        setIsLoanUpdated(false);
        setIntervalLoading(false);
        document.querySelector(`#column-${applicationRequestObj.pagination.orderBy}`)?.classList.add('sorted');
      });
    }
  };

  const getExpiringSoonApplicationsCount = async (): Promise<number> => {
    try {
      if (organization) {
        setLoading(true);
        const { data } = await client.query({
          query: FETCH_APPLICATION_LIST,
          variables: {
            input: {
              pagination: {
                limit: 1,
                offset: 0,
                order: 'asc',
              },
              search: [
                {
                  key: 'expireAt',
                  operator: GenericSearchOperators.LOWER_THAN,
                  value: dayjs().utc().add(5, 'day').toISOString(),
                },
                {
                  key: 'expireAt',
                  operator: GenericSearchOperators.BIGGER_THAN,
                  value: dayjs().utc().toISOString(),
                },
                { key: 'organizationId', operator: GenericSearchOperators.EQUAL, value: organization?.id },
                { key: 'demo', value: 'false' },
                { key: 'validity', value: 'ACTIVE' },
                {
                  key: 'raw',
                  value:
                    'scheduleStatus±{SCHEDULED,FOLLOW_UP,INTERESTED,NOT_INTERESTED,LEFT_VM,NO_VM,INVALID_NUMBER,NEW}',
                },
                { key: 'statusRaw', value: 'status±{APPROVED,INCOME_VERIFICATION,INITIALIZED,PREAPPROVED}' },
              ],
            },
          },
        });

        return data?.fetchApplicationList?.total ?? -1;
      } else {
        return -1;
      }
    } catch (err) {
      captureException(err);
      return -1;
    }
  };

  const getApplications = async ({
    limit,
    offset,
    searchKey,
    mobilePagination = false,
  }: {
    limit?: number;
    offset?: number;
    searchKey?: string;
    mobilePagination?: boolean;
  }) => {
    try {
      const { pagination, filter, searchString } = applicationRequestObj;
      if (organization) {
        setLoading(true);
        const { data } = await client.query({
          query: FETCH_APPLICATION_LIST,
          variables: {
            input: {
              pagination: {
                limit: limit || pagination.limit,
                offset: offset || pagination.offset,
                order: pagination.order || 'DESC',
                orderBy: pagination.orderBy,
              },
              search: FetchApplicationListSearch(filter, mobilePagination, searchString),
              ...(searchKey ? { searchKey: searchWithKey(searchKey) } : { searchKey: searchWithKey(searchString) }),
            },
          },
        });

        if (data) {
          const response = data?.fetchApplicationList;
          const mappedData = mapApplicationToTable(response?.contents || []);
          const newApprovals = mobilePagination ? [...approvals, ...mappedData] : mappedData;
          setTotal(response?.total);
          setApprovals(newApprovals);
          setPaginationLoading(false);
          setLoading(false);
          return;
        }
        setPaginationLoading(false);
        setLoading(false);
      }
    } catch (e) {
      setPaginationLoading(false);
      setLoading(false);
    }
  };

  // only used for getApplications() result, this is only used in the mobile portal.
  const mapApplicationToTable = useCallback(
    (data) => {
      const approvalsList: any[] = data.map((approval: any, index: number) => {
        let dateDiff = dayjs(dayjs(approval.expireAt).startOf('day')).diff(dayjs().startOf('day'), 'day');

        if (dateDiff === 0) {
          const tempDiff = dayjs(approval.expireAt).diff(dayjs(), 'second');

          if (tempDiff < 0) {
            dateDiff = -1;
          }
        }

        const openItemModal = () => {
          setSelectedModalItem(approval);
        };

        const toggleExtendedCheckoutComponent = (e: any) => {
          // Find parent row to expand feature
          const selectedRow: any = document.getElementById(`row-${approval.id}`);
          const expandableButton = selectedRow?.firstChild?.children[0];

          if (selectedRow.parentNode.querySelector('div.rdt_ExpanderRow')) {
            selectedRow.classList.add('row--active');
            selectedRow.parentNode
              .querySelector('div.rdt_ExpanderRow')
              ?.previousElementSibling.firstChild.children[0].click();
          } else {
            selectedRow.classList.remove('row--active');
          }

          document.querySelectorAll('.row--active').forEach((event) => event.classList.remove('row--active'));
          if (checkHasClass(e.target?.parentNode?.parentNode, 'row--active')) {
            e.target?.parentNode?.parentNode?.classList?.remove('row--active');
            expandableButton.click();
          } else {
            expandableButton.click();
          }
        };

        const openProcessContractModal = (e: any) => {
          trackSegmentEvent('Checkout Initiated', {
            application: applicationName,
            applicationId: approval.id,
            borrowerId: approval.borrowerId,
            checkoutType: 'Initial Checkout',
          });
          trackEvent({
            action: DashboardAnalyticsEventNames.CHECKOUT_CHECKOUT_CLICK,
          });
          setSelectedModalItem(approval);
          // setExpandableOpened(!expandableOpened);
          setUpdatedLoan(StepEnums.CHECKOUT_FORM);
          toggleExtendedCheckoutComponent(e);
        };
        /* tslint:disable:no-empty */
        const openProcessCancelModal = () => {
          trackEvent({
            action: DashboardAnalyticsEventNames.CHECKOUT_CANCEL_CLICK,
          });

          // setIsProcessCancelModalOpen(true);
          setSelectedModalItem(approval);
        };

        /* tslint:disable:no-empty */
        const onApprovalStatusChange = async (option) => {
          /* tslint:disable:no-empty */
          await approvalStatusChange(option, approval);
        };

        /* tslint:disable:no-empty */
        const onDenialStatusChange = async (e) => {
          setSelectedModalItem({
            ...approval,
            firstName: approval?.borrower?.firstName,
            lastName: approval?.borrower?.lastName,
            availableAmount: approval?.balanceAvailable,
            applicationId: approval?.id,
            phone: approval?.borrower?.phone,
          });

          if (e.value === 'ISSUE_ANOTHER_PLAN') {
            const hasPastTenMinutes =
              dayjs().diff(approval?.activeLoan?.latestAt, 'minutes') > MIN_CHECKOUT_ISSUE_MINUTES;
            setUpdatedLoan(hasPastTenMinutes ? StepEnums.CHECKOUT_FORM : StepEnums.ISSUE_ANOTHER_PLAN);
            setIsCheckoutModalOpened(true);
            trackSegmentEvent('Checkout Initiated', {
              application: applicationName,
              applicationId: approval?.id,
              borrowerId: approval?.borrowerId,
              checkoutType: 'Another Checkout',
            });
            trackEvent({
              action: DashboardAnalyticsEventNames.CHECKOUT_ISSUE_ANOTHER_CLICK,
            });
          } else if (e.value === 'VIEW_TRANSACTION') {
            navigate('/transactions', {
              state: {
                displayId: approval?.activeLoan?.displayId,
              },
            });
            trackEvent({
              action: DashboardAnalyticsEventNames.VIEW_TRANSACTION_CLICK,
            });
          } else if (e.value === 'SEND_EXTENSION_LINK') {
            trackSegmentEvent('Sent Approval Extension', {
              application: applicationName,
              applicationId: approval?.id,
              organizationId: approval?.organizationId,
              borrowerId: approval?.borrowerId,
            });
            setExtendApproval(approval);
          } else {
            setUpdatedLoan(StepEnums.CHECKOUT_FORM);
            toggleExtendedCheckoutComponent(e);
            trackEvent({
              action: DashboardAnalyticsEventNames.CHECKOUT_WAITINGFOR_CUSTOMER_CLICK,
            });
          }
        };

        const onChangeReminderChange = async (e) => {
          if (e.value === 'TEXT_REMINDER') {
            await getPreviewApplicationReview(approval);
          } else if (e.value === 'OUTBOUND_MESSAGES') {
            setOutboundMessages(approval.outboundMessages);
          } else if (e.value === 'SEND_EXTENSION_LINK') {
            trackSegmentEvent('Sent Approval Extension', {
              application: applicationName,
              applicationId: approval?.id,
              organizationId: approval?.organizationId,
              borrowerId: approval?.borrowerId,
            });
            setExtendApproval(approval);
          } else if (e?.value === 'CREATE_TREATMENT_PLAN') {
            setSelectedModalItem(approval);
            setTakeHomeApprovalModalOpen(true);
          }
        };

        const openCheckoutModal = () => {
          setIsCheckoutModalOpened(true);
          setSelectedModalItem({
            ...approval,
            firstName: approval?.borrower?.firstName,
            lastName: approval?.borrower?.lastName,
            availableAmount: approval?.balanceAvailable,
            applicationId: approval?.id,
            phone: approval?.borrower?.phone,
          });
        };

        return {
          applicationId: approval.id,
          borrowerId: approval.borrowerId,
          createdAt: approval?.createdAt,
          status: approval?.status,
          borrowerName: approval?.borrower?.firstName + ' ' + approval?.borrower?.lastName,
          amount: approval?.purchaseAmount,
          maxEligible: approval?.maxEligible,
          eligible:
            approval?.balanceAvailable === 0 || approval?.balanceAvailable
              ? approval?.balanceAvailable
              : approval?.maxEligible,
          expiredAt: approval?.expireAt,
          scheduled: approval?.scheduleStatus,
          startedAt: dateDiff,
          phone: formatPhoneNumber(approval?.borrower?.phone || ''),
          phoneNumberRaw: approval?.borrower?.phone || '',
          openItemModal,
          openProcessContractModal,
          onApprovalStatusChange,
          onDenialStatusChange,
          onChangeReminderChange,
          openProcessCancelModal,
          handleRowClicked,
          scheduledAt: approval?.scheduledAt,
          selfCheckout: approval?.selfCheckout,
          flowType: approval?.flowType,
          type: approval?.type,
          activeLoan: approval?.activeLoan,
          merchantId: approval?.merchantId,
          balanceAvailable: approval?.balanceAvailable,
          hasExtendApproval: features?.includes(FeatureNames.APPLICATION_APPROVAL_EXTENSION),
          approvalExtended: approval?.approvalExtended,
          firstName: approval?.borrower?.firstName,
          lastName: approval?.borrower?.lastName,
          activeLoanId: approval?.activeLoanId,
          menuId: approval?.menuId,
          outboundMessages: approval?.outboundMessages,
          readyForCheckout: approval?.readyForCheckout,
          creditLineIncrease: approval?.creditLineIncrease,
          openCheckoutModal,
        };
      });

      const approvalStatusChange = async (option, approval) => {
        trackSegmentEvent('Changed Application Status', {
          application: applicationName,
          applicationId: approval?.id,
          borrowerId: approval?.borrowerId,
          previousStatus: approval?.scheduleStatus,
          newStatus: option?.value,
        });
        switch (option.value) {
          case DropdownItemValue.SCHEDULED:
            toggleDatePicker();
            setSchedulingId(approval.id);
            return '';
          default:
            const approvalStatusResponse = await changeApprovalStatus({
              scheduleStatus: option.value,
              id: approval.id,
            });
            if (approvalStatusResponse?.success) {
              const approvalIndex = approvalsList.findIndex((item) => item.id === approval.id);

              if (approvalIndex >= 0) {
                const newApprovalsList = [...approvalsList];
                newApprovalsList[approvalIndex].scheduled = option.value;
                setApprovals(newApprovalsList);
              }
            }
            return;
        }
      };

      return approvalsList;
    },
    [approvals],
  );

  const getPreviewApplicationReview = async (approval) => {
    const { id, outboundMessages: outboundMessagesData, borrower } = approval;
    setTextReminderData({ message: 'loading', applicationId: id, outboundMessages: outboundMessagesData, borrower });
    const { data } = await client.query({
      query: GET_APPLICATION_LATEST_REMINDER,
      variables: {
        input: {
          applicationId: id,
        },
      },
    });
    if (data?.getApplicationLatestReminder?.message) {
      setTextReminderData({
        message: data.getApplicationLatestReminder.message,
        applicationId: id,
        outboundMessages: outboundMessagesData,
        borrower,
      });
    }
  };

  const changeApprovalStatus = async ({ scheduleStatus, id, ...rest }) => {
    try {
      const idMerchant = (merchants && merchants[0].id) || 0;
      await merchantLogin(idMerchant);

      const { data } = await patchApplication({ variables: { input: { applicationId: id, scheduleStatus } } });
      updateApplicationRequest();
      await merchantLogout();

      return data.patchApplication;
    } catch (err) {
      await merchantLogout();
    }
  };

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (pageNumber * rowsPerPage >= approvals.length) paginateTable(pageNumber);
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
    if (perPage * pageNumber >= approvals.length) paginateTable(pageNumber, perPage);
  };

  const searchWithKey = (searchKey) => {
    if (searchKey && /^\(\d{3}/.test(searchKey)) {
      return searchKey?.replace(/[\(\)\s\-]/g, '')?.trim();
    }
    return searchKey;
  };

  const paginateTable = async (pageNumber: number, perPage?: number) => {
    trackEvent({ action: DashboardAnalyticsEventNames.PAGINATION_USED });
    setPaginationLoading(true);
    setApplicationRequestObj({
      ...applicationRequestObj,
      pagination: {
        limit: perPage || rowsPerPage,
        offset: pageNumber - 1,
        order: 'DESC',
      },
    });
  };

  const onTableSort = (column: { selector?: any }, direction: 'asc' | 'desc') => {
    setLoading(true);
    setApplicationRequestObj({
      ...applicationRequestObj,
      pagination: {
        ...applicationRequestObj.pagination,
        order: direction,
        orderBy: column?.selector,
      },
    });
  };

  const loanUpdated = (e: StepEnums) => {
    setUpdatedLoan(e);
  };

  const setLoanUpdated = (value: boolean) => {
    setIsLoanUpdated(value);
  };

  const onScheduleDateSelected = async (value: string) => {
    try {
      const idMerchant = merchants && merchants[0].id;
      await merchantLogin(idMerchant || 0);

      const { data } = await patchApplication({
        variables: { input: { applicationId: schedulingId, scheduleStatus: 'SCHEDULED', scheduledAt: value } },
      });

      if (data.patchApplication.data.id) {
        const approvalIndex = approvals.findIndex((item) => item.idBorrowerOutcome === schedulingId);
        const newApprovalsList = [...approvals];
        newApprovalsList[approvalIndex].scheduledAt = dayjs(value).toISOString();
        newApprovalsList[approvalIndex].scheduled = 'SCHEDULED';
        setApprovals(newApprovalsList);
        setShowSchedule(false);
      }
      await merchantLogout();
    } catch (err) {
      await merchantLogout();
    }
  };

  const refreshTable = async () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    setIntervalLoading(true);
    if (!intervalLoading) {
      // onTableRefresh && (await onTableRefresh());
      updateApplicationRequest();
      trackSegmentEvent('Refreshed Application Data', {
        application: applicationName,
        organizationId: organization?.id,
        merchantId: idMerchant,
        menuVersion: 'fullWidth',
      });
      trackEvent({
        action: DashboardAnalyticsEventNames.REFRESH_DATA,
      });
    }
  };

  const getNewTodayApplications = async () => {
    if (isNewApplicationEnabled) {
      setIsNewApplicationEnabled(!isNewApplicationEnabled);
      updateApplicationRequest();
    } else {
      setIsNewApplicationEnabled(!isNewApplicationEnabled);
      setIsExpiringTodayEnabled(false);
      setApplicationRequestObj({
        ...applicationRequestObj,
        pagination: {
          limit: 20,
        },
        filter: {
          ...applicationRequestObj.filter,
          createdAt: [
            {
              key: 'createdAt',
              operator: GenericSearchOperators.BIGGER_THAN,
              value: dayjs().startOf('day').toISOString(),
            },
            { key: 'createdAt', operator: GenericSearchOperators.LOWER_THAN, value: dayjs().toISOString() },
          ],
        },
      });
    }
  };

  const getExpiringSoonApplications = async () => {
    if (isExpiringTodayEnabled) {
      setIsExpiringTodayEnabled(!isExpiringTodayEnabled);
      updateApplicationRequest();
    } else {
      setIsExpiringTodayEnabled(!isExpiringTodayEnabled);
      setIsNewApplicationEnabled(false);
      setApplicationRequestObj({
        ...applicationRequestObj,
        pagination: {
          limit: 20,
        },
        filter: {
          ...applicationRequestObj.filter,
          createdAt: [],
          expireAt: [
            {
              key: 'expireAt',
              operator: GenericSearchOperators.LOWER_THAN,
              value: dayjs().add(5, 'day').toISOString(),
            },
          ],
        },
      });
    }
  };

  const getReadyToCheckoutApplications = async () => {
    try {
      if (organization) {
        setLoading(true);
        const { data } = await client.query({
          query: FETCH_APPLICATION_LIST,
          variables: {
            input: {
              pagination: {
                limit: 999,
                offset: 0,
                order: 'desc',
              },
              search: [
                {
                  key: 'createdAt',
                  value: dayjs().subtract(60, 'day').toISOString(),
                  operator: GenericSearchOperators.BIGGER_THAN,
                },
                {
                  key: 'createdAt',
                  value: dayjs().utc().endOf('day').toISOString(),
                  operator: GenericSearchOperators.LOWER_THAN,
                },
                {
                  key: 'expireAt',
                  operator: GenericSearchOperators.BIGGER_THAN,
                  value: dayjs().utc().endOf('day').toISOString(),
                },
                { key: 'organizationId', operator: GenericSearchOperators.EQUAL, value: organization?.id },
                { key: 'demo', value: organization?.isDemo ? 'true' : 'false' },
                { key: 'validity', value: 'ACTIVE' },
                { key: 'raw', value: 'balanceAvailable>{0}' },
                { key: 'statusRaw', value: 'status±{APPROVED}' },
              ],
            },
          },
        });

        return data?.fetchApplicationList?.contents || [];
      } else {
        return [];
      }
    } catch (err) {
      captureException(err);
      return [];
    }
  };

  const getMerchantTextReferralApplications = async () => {
    try {
      const { filter, searchString } = defaultFilter;

      const merchantTextReferralApplicationsFilter = {
        ...filter,
        referralSource: 'merchant_text',
      };
      if (organization) {
        const { data } = await client.query({
          query: FETCH_APPLICATION_LIST,
          variables: {
            input: {
              pagination: {
                limit: 999,
                offset: 0,
                order: 'DESC',
              },
              search: FetchApplicationListSearch(merchantTextReferralApplicationsFilter, false),
              ...{ searchKey: searchWithKey(searchString) },
            },
          },
        });

        if (data) {
          const response = data?.fetchApplicationList;

          return response;
        }
      }
    } catch (err) {
      captureException(err);
    }
  };

  const toggleIsCheckoutModalOpened = () => {
    setIsCheckoutModalOpened((prev) => !prev);
  };
  const contextValues = {
    applicationRequestObj,
    approvals,
    closeExtendApprovalModal,
    closeOutboundMessageModal,
    closeTextReminderModal,
    dataTableRef,
    defaultFilter,
    expandableOpened,
    extendApproval,
    filterHeight,
    filterRef,
    filterWidth,
    getApplications,
    getExpiringSoonApplications,
    getExpiringSoonApplicationsCount,
    getNewTodayApplications,
    getReadyToCheckoutApplications,
    gridRef,
    handleRowClicked,
    intervalLoading,
    isExpiringTodayEnabled,
    isNewApplicationEnabled,
    loading,
    loanUpdated,
    onScheduleDateSelected,
    onTableSort,
    outboundMessages,
    pageChange,
    paginationLoading,
    permissions,
    quickFilterLabel,
    refreshTable,
    rowsCountChange,
    rowsPerPage,
    scrollOffset,
    selectedItem,
    selectedModalItem,
    setApplicationRequestObj,
    setExpandableOpened,
    setIntervalLoading,
    setLoanUpdated,
    setQuickFilterLabel,
    setSelectedItem,
    setUpdatedLoan,
    showSchedule,
    tableColumns,
    textReminderData,
    toggleDatePicker,
    total,
    updateApplicationRequest,
    updatedLoan,
    isCheckoutModalOpened,
    toggleIsCheckoutModalOpened,
    setSelectedModalItem,
    getMerchantTextReferralApplications,
  };

  const contextValue = useMemo(() => contextValues, Object.values(contextValues));

  return <ApplicationFilterContext.Provider value={contextValue}>{children}</ApplicationFilterContext.Provider>;
};

export const useApplicationFilter = () => {
  const contextValue = useContext(ApplicationFilterContext);

  return contextValue as ApplicationFilterContextValue;
};
