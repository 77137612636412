import JwtDecode from 'jwt-decode';

export const isExpiredToken = (token: string = '') => {
  try {
    /* in case the call succeeds at the end of timeout,
      remove the amount of timeout from our current time
      to avoid 401 from server */
    const tokenExpiration = (JwtDecode(token) as any).exp - 40;
    const currentTime = Date.now().valueOf() / 1000;

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};
