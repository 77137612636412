import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import React from 'react';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const CherryTextField = styled((props: any) => (
  <MuiTextField
    inputRef={props?.ref}
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    InputProps={{ disableUnderline: true, endAdornment: props?.endAdornment } as Partial<any>}
    {...props}
  />
))((props) => ({
  '& label': {
    color: '#dadada',
    fontSize: 18,
  },
  '& label.Mui-focused': {
    color: '#56636d',
  },
  '& label.Mui-error': {
    color: '#d32f2f',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiFormLabel-filled': {
    color: '#56636d',
  },
  '& .MuiFilledInput-root': {
    height: props?.multiline ? undefined : 56,
    border: '1px #56636d solid',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `0.25 0 0 0 2px`,
      borderColor: '#56636d',
    },
    '&.Mui-error': {
      borderColor: '#d32f2f',
    },
    '&.MuiInputBase-colorSuccess': {
      backgroundColor: '#f2fffb',
    },
    '&.Mui-disabled': {
      backgroundColor: '#E7E9EA',
    },
    input: {
      fontSize: 18,
    },
  },
  '& .MuiFilledInput-input': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 30px #fff inset',
      WebkitTextFillColor: '#56636d',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: 16,
    fontFamily: 'Open Sans',
  },
  '& .MuiInputLabel-input': {
    '&:focus': {
      outline: 1,
    },
  },
  '& input:invalid + fieldset': {
    borderColor: '#d32f2f',
    borderWidth: 2,
  },
}));
