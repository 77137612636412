import { useApolloClient } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, CircularProgress, Grid, Paper } from '@mui/material';
import images from 'assets/images';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { CalculatorIcon, Card, DropDown } from 'lib/components';
import { CALCULATE_PAYMENT, FETCH_MENU_DETAIL_BY_CODE } from 'lib/graphql/queries';
import { DashboardAnalyticsEventNames, FeatureNames, useAnalytics, useDebounce } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { PAYMENT_PLAN_MONTHS } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';
import { PaymentEstimateModalInitialData } from 'pages/desktop/Dashboard/Dashboard';
import GenerateEstimateHistory from 'pages/desktop/Dashboard/views/GenerateEstimateHistory/GenerateEstimateHistory';
import { ButtonContainer } from 'pages/desktop/Transactions/CommonStyles';
import { useNavigate } from 'react-router-dom';

export enum CREDIT_SCORE {
  OKAY = 'OKAY',
  GOOD = 'GOOD',
  GREAT = 'GREAT',
}

interface Props {
  organizationId: number;
  organization: any;
  key: string;
  hideExplanation?: boolean;
  setGenerateEstimateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setGenerateEstimateModalInitialData?: React.Dispatch<React.SetStateAction<any>>;
  initialData?: PaymentEstimateModalInitialData;
  onChangeCallback?: (values: PaymentEstimateModalInitialData) => void;
}

export interface PurchaseAmountResponse {
  apr?: number;
  financeCharge?: number;
  paymentAmount?: number;
}

interface SectionTitleProps {
  size: number;
  align: string;
  disabled?: boolean;
}

interface EstimatorScoreProps {
  selected: boolean;
}

interface ShareEstimateTextProps {
  fontSize: number;
  disabled?: boolean;
}

interface ShareEstimateSectionProps {
  backgroundColor?: string;
}

interface ErrorProps {
  title: string;
  hasIcon?: boolean;
}

interface EstimatorResultProps {
  paymentResultLoading: boolean;
  validPurchaseAmountRange: boolean;
  purchaseAmountObject: PurchaseAmountResponse | undefined;
  popoverOpen: boolean;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  planLength: number | null;
  setGenerateModalOpen?: () => void;
  hideExplanation?: boolean;
}

const EstimatorError = ({ title, hasIcon = false }: ErrorProps) => {
  if (hasIcon) {
    return (
      <ShareEstimateText fontSize={14}>
        <FlexCol>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" />
          <br />
          {title}
          <br />
        </FlexCol>
      </ShareEstimateText>
    );
  }
  return <ShareEstimateText fontSize={14}>{title}</ShareEstimateText>;
};

const EstimatorResult = ({
  paymentResultLoading,
  validPurchaseAmountRange,
  purchaseAmountObject,
  popoverOpen,
  setPopoverOpen,
  planLength,
  setGenerateModalOpen,
  hideExplanation,
}: EstimatorResultProps) => {
  const handleTooltipOpen = () => setPopoverOpen(true);
  const handleTooltipClose = () => setPopoverOpen(false);

  return (
    <EstimatorResultView>
      {paymentResultLoading ? (
        <ProgressContainer>
          <CustomCircularProgress />
        </ProgressContainer>
      ) : (
        <Grid container={true}>
          <Grid item={true} xs={2} />
          <Grid item={true} xs={8}>
            <SectionTitle size={400} align={'left'} disabled={!purchaseAmountObject?.paymentAmount}>
              Monthly Payment
            </SectionTitle>
          </Grid>
          <Grid item={true} xs={2} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
            <PopoverWrapper>
              {!hideExplanation && purchaseAmountObject?.paymentAmount && (
                <img src={images.infoCircleOutline.default} />
              )}
              {popoverOpen && !hideExplanation && (
                <Popover>
                  <DownCircle />
                  <PopoverContent>
                    <PopoverText>
                      A down payment equal to the monthly payment is due at the time of purchase.
                      <br />
                      <br />
                      In total, the patient will pay one down payment of{' '}
                      {CurrencyUtil.toCurrencyString(purchaseAmountObject?.paymentAmount, false)} at the time of
                      purchase, and {planLength} monthly payments of{' '}
                      {CurrencyUtil.toCurrencyString(purchaseAmountObject?.paymentAmount, false)}.
                    </PopoverText>
                  </PopoverContent>
                </Popover>
              )}
            </PopoverWrapper>
          </Grid>
          <Grid item={true} xs={2} />
          <Grid item={true} xs={10}>
            <SectionBigText style={{ marginBottom: '8px' }} disabled={!purchaseAmountObject?.paymentAmount}>
              {CurrencyUtil.toCurrencyString(purchaseAmountObject?.paymentAmount, false)}
              <span id="month">/ month</span>
            </SectionBigText>
          </Grid>
          <Grid item={true} xs={2} />
          <Grid item={true} xs={10}>
            <ShareEstimateText
              fontSize={12}
              style={{ marginBottom: '0px', textAlign: 'left' }}
              disabled={!purchaseAmountObject?.financeCharge}
            >
              Total Finance Charge
            </ShareEstimateText>
          </Grid>
          <Grid item={true} xs={2} />
          <Grid item={true} xs={10}>
            <PurchaseAmountAndAprWrapper disabled={!purchaseAmountObject?.financeCharge}>
              {CurrencyUtil.toCurrencyString(purchaseAmountObject?.financeCharge, false)}&nbsp; (
              {purchaseAmountObject?.apr}% APR)
            </PurchaseAmountAndAprWrapper>
          </Grid>
          <Grid item={true} xs={2} />
          <Grid item={true} xs={10}>
            {purchaseAmountObject?.paymentAmount && (
              <ShareEstimateText fontSize={10}>
                This is an example only. Patient's exact terms and APR are determined by many factors, including credit
                score.
              </ShareEstimateText>
            )}
          </Grid>
          {!hideExplanation && (
            <>
              <Grid item={true} xs={2} />
              <Grid item={true} xs={10}>
                <EstimateButtonContainer>
                  <InnerButtonContainer
                    data-tag="generateEstimatation"
                    onClick={setGenerateModalOpen}
                    disabled={!purchaseAmountObject?.paymentAmount}
                  >
                    Generate Estimate
                  </InnerButtonContainer>
                </EstimateButtonContainer>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </EstimatorResultView>
  );
};

export const PaymentEstimator = ({
  organizationId,
  organization,
  hideExplanation,
  initialData,
  key,
  onChangeCallback,
}: Props) => {
  const { user, features } = useStore();

  const shouldDisplayHistory = false;

  const planOptions = [
    { value: '3', label: '3 months', disabled: false },
    { value: '6', label: '6 months', disabled: false },
    { value: '12', label: '12 months', disabled: false },
    { value: '18', label: '18 months', disabled: false },
    { value: '24', label: '24 months', disabled: false },
  ];
  const { menuCode } = organization || {};
  const [errorMessageForDentist, setErrorMessageForDentist] = useState<string | null>(null);
  const [validPurchaseAmountRange, setValidPurchaseAmountRange] = useState<boolean>(true);

  const [customerCreditScore, setCustomerCreditScore] = useState<string>(
    initialData?.creditScore?.text || CREDIT_SCORE.GREAT,
  );
  const [customerPaymentPlanSliderValue, setCustomerPaymentPlanSliderValue] = useState<number | null>(
    initialData?.planLength || null,
  );
  const [customerPaymentPurchaseAmount, setCustomerPaymentPurchaseAmount] = useState<number | null>(
    initialData?.amount || null,
  );
  const [purchaseAmountObject, setPurchaseAmountObject] = useState<PurchaseAmountResponse>();
  const [showPaymentEstimatorResult, setShowPaymentEstimatorResult] = useState<boolean>(false);
  const [paymentResultLoading, setPaymentResultLoading] = useState<boolean>(false);
  const [selectedCustomerCreditScore, setSelectedCustomerCreditScore] = useState<any>(
    PAYMENT_PLAN_MONTHS.find((plan) =>
      initialData ? plan?.text === initialData?.creditScore?.text : plan?.text === CREDIT_SCORE.GREAT,
    ),
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [generateEstimateHistoryModalOpen, setGenerateEstimateHistoryModalOpen] = useState<boolean>(false);

  const openGenerateEstimateHistoyModal = () => {
    trackSegmentEvent('View Sent Take-Home Estimates', {
      application: applicationName,
    });
    setGenerateEstimateHistoryModalOpen(true);
  };

  const closeGenerateEstimateHistoyModal = () => {
    trackSegmentEvent('Close - Take Home Estimates History', {
      application: applicationName,
    });
    setGenerateEstimateHistoryModalOpen(false);
  };

  const debouncedValue = useDebounce(customerPaymentPurchaseAmount, 300);
  const { trackEvent } = useAnalytics({});
  const { trackSegmentEvent, applicationName } = useSegment();
  const [maxEligibleAmount, setMaxEligibleAmount] = useState<number>(0);
  const MIN_ELIGIBLE_AMOUNT = 200;

  const client = useApolloClient();
  const morePlanCodes = ['_Dental', '_HL', '_CompetitivePlastics'];
  const hasMorePlan = morePlanCodes.some((code) => menuCode?.includes(code));
  const hasLowaPlan = menuCode?.indexOf('_IA') >= 0;

  const navigate = useNavigate();

  if (hasMorePlan) {
    planOptions.push(
      {
        value: '36',
        label: '36 months ($3,000 min)',
        disabled: false,
      },
      {
        value: '48',
        label: '48 months ($4,000 min)',
        disabled: false,
      },
      {
        value: '60',
        label: '60 months ($5,000 min)',
        disabled: false,
      },
    );
  }

  const isSimplifiedPracticePortalFeatureEnabled = features.includes(FeatureNames.SIMPLIFIED_PARTNER_PORTAL);

  useEffect(() => {
    if (menuCode) {
      getApplicationMenuByCode();
    }
  }, [organization]);

  const getApplicationMenuByCode = async () => {
    const {
      data: { getMenuByCode },
    } = await client.query({
      query: FETCH_MENU_DETAIL_BY_CODE,
      variables: { input: { code: menuCode } },
    });
    setMaxEligibleAmount(getMenuByCode?.maxPurchase);
  };

  const calculatePayment = async (term: number) => {
    try {
      const { data } = await client.query({
        query: CALCULATE_PAYMENT,
        variables: {
          input: {
            amount: Number(customerPaymentPurchaseAmount),
            term,
            creditGrade: customerCreditScore,
            organizationId,
          },
        },
      });

      trackSegmentEvent('Calculator Plan Calculated', {
        application: applicationName,
        component: 'Payment Estimator',
        purchaseAmount: Number(customerPaymentPurchaseAmount),
        planLength: term,
        creditRange: selectedCustomerCreditScore.value,
        creditScore: customerCreditScore,
      });

      if (onChangeCallback) {
        onChangeCallback({
          amount: Number(customerPaymentPurchaseAmount),
          planLength: term,
          creditScore: selectedCustomerCreditScore,
          paymentCalculation: data.calculatePayment,
        });
      }

      setPurchaseAmountObject(data.calculatePayment);
      setShowPaymentEstimatorResult(true);
      setPaymentResultLoading(false);
    } catch (e) {
      setPaymentResultLoading(false);
    }
  };

  useEffect(() => {
    if (validPurchaseAmountRange) {
      if (customerPaymentPurchaseAmount) {
        setPaymentResultLoading(true);
      }
      const term = customerPaymentPlanSliderValue || 12;
      if (Boolean(customerPaymentPurchaseAmount)) {
        calculatePayment(term);
      }
    } else {
      setValidPurchaseAmountRange(false);
    }
  }, [customerCreditScore, customerPaymentPlanSliderValue, debouncedValue]);

  const onPurchaseAmountChange = (nativeEvent) => {
    const value = nativeEvent;

    const isPurchaseAmountValid = CurrencyUtil.valueValidator(value, MIN_ELIGIBLE_AMOUNT, maxEligibleAmount);
    const _errorMessageForDentist = CurrencyUtil.errorMessagesForDentist(customerPaymentPlanSliderValue!, value);
    setCustomerPaymentPurchaseAmount(value ? parseFloat(value) : null);

    if (isPurchaseAmountValid) {
      trackSegmentEvent('Calculator Changed Amount', {
        application: applicationName,
        component: 'Payment Estimator',
        purchaseAmount: value,
      });
      setValidPurchaseAmountRange(true);

      setErrorMessageForDentist(_errorMessageForDentist);
    } else {
      setValidPurchaseAmountRange(false);
      setShowPaymentEstimatorResult(false);
    }
  };

  const onPurchaseAmountBlurred = (nativeEvent: any) => {
    const amount = nativeEvent;
    const isAmountValid = CurrencyUtil.valueValidator(amount, MIN_ELIGIBLE_AMOUNT, maxEligibleAmount);
    if (isAmountValid) setCustomerPaymentPurchaseAmount(amount ? parseFloat(amount) : null);
  };

  const handlePlanOptionsChange = (selectedTerm: { value: string; label: string }) => {
    trackSegmentEvent('Calculator Changed Length', {
      application: applicationName,
      component: 'Payment Estimator',
      planLength: `${selectedTerm.value} Month`,
    });
    const selectedOption = Number(selectedTerm?.value);
    setCustomerPaymentPlanSliderValue(selectedOption);

    const _errorMessageForDentist = CurrencyUtil.errorMessagesForDentist(
      selectedOption,
      customerPaymentPurchaseAmount!,
    );
    setErrorMessageForDentist(_errorMessageForDentist);
  };

  const onCustomerCreditScoreClick = (event: any) => {
    const eventValue = event.currentTarget?.value;
    const selectedCredit = PAYMENT_PLAN_MONTHS.find((node) => node.text === event.currentTarget.value);
    const creditScore = selectedCredit?.text || eventValue;
    const creditRange = selectedCredit?.value;

    trackSegmentEvent('Calculator Changed Credit', {
      application: applicationName,
      component: 'Payment Estimator',
      creditRange,
      creditScore,
    });

    trackEvent({
      action: DashboardAnalyticsEventNames.PAYMENT_ESTIMATOR_CREDIT_SCORE_CHANGE,
      label: creditScore,
    });
    setCustomerCreditScore(creditScore);
    setSelectedCustomerCreditScore(selectedCredit);
  };

  const handleGenerateModalOpen = () => {
    navigate('/payment-estimator', { state: { amount: debouncedValue } });
    trackSegmentEvent('Estimate Generator Launched', {
      application: applicationName,
      component: 'Payment Estimator',
      userId: `u-${user?.id}`,
    });
  };

  const getPatientFinancialSituationDescription = () => {
    let descriptionText;
    switch (customerCreditScore) {
      case CREDIT_SCORE.OKAY:
        descriptionText = (
          <span>
            <BoldText>Okay</BoldText> means a patient may have lower to average credit, or some adverse financial
            history, such as recent missed payments.
          </span>
        );
        break;
      case CREDIT_SCORE.GOOD:
        descriptionText = (
          <span>
            <BoldText>Good</BoldText> means a patient may have average credit, and no adverse financial history, such as
            recent missed payments.
          </span>
        );
        break;
      case CREDIT_SCORE.GREAT:
        descriptionText = (
          <span>
            <BoldText>Great</BoldText> means a patient may have high credit, and no adverse financial history, such as
            recent missed payments.
          </span>
        );
        break;

      default:
        descriptionText = <span />;
    }

    return descriptionText;
  };

  const paymentEstimatorOutcome = () => {
    if (customerCreditScore === CREDIT_SCORE.OKAY && hasLowaPlan) {
      return (
        <EstimatorError
          title={'Due to state-specific laws, we are unable to approve patients with “Okay” credit.'}
          hasIcon={true}
        />
      );
    } else if (!showPaymentEstimatorResult) {
      if (validPurchaseAmountRange) {
        return (
          <>
            <EstimatorError title="Enter a purchase amount, payment plan, and credit score to see estimated payments" />
            <EstimatorResult
              paymentResultLoading={paymentResultLoading}
              validPurchaseAmountRange={validPurchaseAmountRange}
              purchaseAmountObject={purchaseAmountObject}
              popoverOpen={popoverOpen}
              setPopoverOpen={setPopoverOpen}
              planLength={customerPaymentPlanSliderValue}
              setGenerateModalOpen={handleGenerateModalOpen}
              hideExplanation={hideExplanation}
            />
          </>
        );
      } else {
        return (
          <EstimatorError
            hasIcon={true}
            title={`Enter an amount between ${CurrencyUtil.toCurrencyString(
              MIN_ELIGIBLE_AMOUNT,
            )} - ${CurrencyUtil.toCurrencyString(maxEligibleAmount)}.`}
          />
        );
      }
    } else if (errorMessageForDentist) {
      return <EstimatorError title={errorMessageForDentist} hasIcon={true} />;
    } else if (customerCreditScore === CREDIT_SCORE.OKAY && customerPaymentPlanSliderValue! > 36) {
      return (
        <EstimatorError
          title={'The maximum plan length for “Okay” credit scores is 36 months. Select a shorter plan length.'}
          hasIcon={true}
        />
      );
    } else {
      return (
        <EstimatorResult
          paymentResultLoading={paymentResultLoading}
          validPurchaseAmountRange={validPurchaseAmountRange}
          purchaseAmountObject={purchaseAmountObject}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          planLength={customerPaymentPlanSliderValue}
          setGenerateModalOpen={handleGenerateModalOpen}
          hideExplanation={hideExplanation}
        />
      );
    }
  };

  const backgroundColor = hideExplanation ? '#f2f4f5' : '#ffffff';

  return (
    <PaymentEstimatorCard
      isDental={isSimplifiedPracticePortalFeatureEnabled}
      key={key}
      backgroundColor={backgroundColor}
      hideExplanation={hideExplanation}
    >
      <Grid container={true} spacing={3}>
        {!hideExplanation && (
          <PaddingGrid item={true} xs={3}>
            <CenteredFlex>
              <CalculatorIcon />
              <SectionTitle size={700} align={'center'}>
                Payment Estimator
              </SectionTitle>
              <SectionText>Show customers what their monthly payments will look like.</SectionText>
              {shouldDisplayHistory && (
                <>
                  <GreenText onClick={openGenerateEstimateHistoyModal}>View Sent Take-Home Estimates</GreenText>
                  <GenerateEstimateHistory
                    visible={generateEstimateHistoryModalOpen}
                    onClose={closeGenerateEstimateHistoyModal}
                  />
                </>
              )}
            </CenteredFlex>
          </PaddingGrid>
        )}
        <PaddingGrid
          container={true}
          sm={hideExplanation ? 6 : 5}
          padding={hideExplanation ? '12px 2px 12px 20px !important' : '40px 20px !important'}
        >
          <PaddingPaper
            elevation={0}
            backgroundColor={backgroundColor}
            style={{ minHeight: '85px' }}
            hideExplanation={hideExplanation}
          >
            <FormLabel className={`${validPurchaseAmountRange && !errorMessageForDentist ? 'valid' : 'error'}`}>
              Purchase Amount
            </FormLabel>

            <AbsoluteIconWrapper className={validPurchaseAmountRange && !errorMessageForDentist ? 'valid' : 'error'}>
              $
            </AbsoluteIconWrapper>
            <CustomCurrencyInput
              data-testid="payment-estimator-input"
              className={validPurchaseAmountRange && !errorMessageForDentist ? 'valid' : 'error'}
              onValueChange={onPurchaseAmountChange}
              formatValueOnBlur={true}
              onBlur={onPurchaseAmountBlurred}
              allowNegativeValue={false}
              defaultValue={initialData?.amount}
            />
          </PaddingPaper>
          <PaddingPaper elevation={0} backgroundColor={backgroundColor} hideExplanation={hideExplanation}>
            <FormLabel
              className={
                customerCreditScore === CREDIT_SCORE.OKAY && customerPaymentPlanSliderValue! > 36 ? 'error' : 'valid'
              }
            >
              Payment Plan (months)
            </FormLabel>
            <DropdownWrapper
              className={
                customerCreditScore === CREDIT_SCORE.OKAY && customerPaymentPlanSliderValue! > 36 ? 'error' : 'valid'
              }
            >
              <DropDown
                options={planOptions}
                hasCustomFont={false}
                onChange={handlePlanOptionsChange}
                defaultValue={initialData?.planLength?.toString() || '12'}
                hoveredMode={false}
              />
            </DropdownWrapper>
          </PaddingPaper>
          <PaddingPaper elevation={0} backgroundColor={backgroundColor} hideExplanation={hideExplanation}>
            <FormLabel>Patient Financial Situation</FormLabel>
            <PlanButtonGroup
              size="large"
              aria-label="large outlined button group"
              defaultValue={1}
              fullWidth={!hideExplanation}
            >
              {PAYMENT_PLAN_MONTHS.map((node, index) => {
                return (
                  <PaymentButton
                    value={node.text}
                    key={index}
                    onClick={onCustomerCreditScoreClick}
                    className={selectedCustomerCreditScore?.text === node?.text ? 'selected' : ''}
                  >
                    <CenteredFlex>
                      <PlanText selected={selectedCustomerCreditScore?.text === node?.text ? true : false}>
                        {node.text.toLowerCase()} <br />{' '}
                      </PlanText>
                    </CenteredFlex>
                  </PaymentButton>
                );
              })}
            </PlanButtonGroup>
            <DesciptionText>{getPatientFinancialSituationDescription()}</DesciptionText>
          </PaddingPaper>
        </PaddingGrid>
        <CustomGrid item={true} md={hideExplanation ? 6 : 4}>
          <ShareEstimatedPayments backgroundColor={hideExplanation ? '#f2f4f5' : '#e5f9f2'}>
            <CenteredFlex>{paymentEstimatorOutcome()}</CenteredFlex>
          </ShareEstimatedPayments>
        </CustomGrid>
      </Grid>
    </PaymentEstimatorCard>
  );
};

const InnerButtonContainer = styled(ButtonContainer)`
  width: 176px;
  height: 40px;
  display: flex;
  font-family: Open Sans;
  font-weight: 600;
`;
const CustomCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.main.green} !important;
`;

const PaymentButton = styled(Button)`
  cursor: pointer !important;
  &:hover {
    background-color: ${(props) => props.theme.main.green}80 !important;
    span,
    div {
      color: #ffffff !important;
    }
  }
`;

const ProgressContainer = styled.div`
  margin-left: 150px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ec3360;
`;

const CustomCurrencyInput = styled(CurrencyInput)`
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dadada;
  height: 40px;
  padding-left: 30px;
  font-size: 14px;
  color: #0e202f;
  outline: none;
  width: 90%;
  &.error {
    border: 1px solid #ff0000 !important;
  }
`;

const EstimatorResultView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CenteredFlex = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .selected {
    display: none !important;
  }
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-top: 12px;
  margin-left: 15px;
  font-size: 14px;

  &.error {
    color: #ff0000 !important;
  }
`;

const PaddingPaper = styled(Paper)<{ backgroundColor?: string; hideExplanation?: boolean }>`
  width: ${(props) => (props.hideExplanation ? '100%' : '90%')};
  padding-bottom: 20px;
  position: relative;
  .error {
    color: #ff0000;
  }

  background-color: ${(props) => props.backgroundColor} !important;
`;

const CustomGrid = styled(Grid)`
  padding: 20px 40px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid blue;
  }
`;

const PaddingGrid = styled(Grid)<{ padding?: string; backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
  justify-content: center;

  background-color: ${(props) => props.backgroundColor};
`;

const PaymentEstimatorCard = styled(Card)<{ isDental: boolean; backgroundColor: string; hideExplanation?: boolean }>`
  padding: 40px 20px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  width: 100%;
  margin: 0;
  padding: ${(props) => (props.hideExplanation ? '12px 0 0 0' : '0')};
  margin-bottom: 0;
  padding-bottom: 0;
  align-items: center;
  height: ${(props) => (props.isDental ? '100%' : 'fit-content')};

  background-color: ${(props) => props.backgroundColor};

  .fullHeight {
    height: calc(100% + 24px);
  }
`;

const ShareEstimatedPayments = styled.div<ShareEstimateSectionProps>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: none;
  border-radius: 0 10px 10px 0;
`;

const SectionTitle = styled.p<SectionTitleProps>`
  margin-top: 16px;
  font-size: 16px;
  font-weight: ${(props) => props.size};
  margin-bottom: 0;
  text-align: ${(props) => props.align};
  color: ${(props) => (props?.disabled ? props.theme.main.grayColor : props.theme.main.textColor)};
`;

const SectionBigText = styled.p<{ disabled: boolean }>`
  color: ${(props) => (props?.disabled ? props.theme.main.grayColor : props.theme.main.textColor)};
  text-align: left;
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 32px;
  font-weight: 700;

  span#month {
    font-size: 12px;
    font-weight: 400;
  }
`;

const SectionText = styled.p`
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
`;

const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main.textColor};
  text-align: left;
  margin-bottom: 5px;
`;

const PlanText = styled.div<EstimatorScoreProps>`
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans;
  color: ${(props) => (props.selected ? props.theme.main.white : props.theme.main.green)};
  text-transform: capitalize;
`;

const PlanButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  button {
    border: 1px solid #00c37d;
    line-height: 12px;
    width: 100%;
    min-width: 90px !important;
    padding: 0;
  }
  background-color: ${(props) => props.theme.main.white};
  .selected {
    background-color: ${(props) => props.theme.main.green};
    span {
      color: #ffffff !important;
    }
  }
`;

const ShareEstimateText = styled.div<ShareEstimateTextProps>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => (props?.disabled ? props.theme.main.grayColor : props.theme.main.textColor)};
  margin-bottom: 20px;
  width: 80%;
  display: flex;
  align-items: center;
`;

const PurchaseAmountAndAprWrapper = styled.div<{ disabled: boolean }>`
  color: ${(props) => (props?.disabled ? props.theme.main.grayColor : '#0e202f')};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: left;
`;

const DropdownWrapper = styled.div`
  border: 1px solid #dadada !important;
  display: inline-block;
  width: 100%;

  background-color: #ffffff;

  &.error {
    border-color: #ff0000 !important;

    span,
    svg {
      color: #ff0000 !important;
    }
  }

  * {
    font-size: 12px !important;
  }
`;

const EstimateButtonContainer = styled.div`
  margin-bottom: 16px;
`;

export const PopoverWrapper = styled.div`
  position: relative;
`;

export const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
  display: flex;
  justify-content: center;
`;

export const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

export const Popover = styled.div<{ secondary?: boolean; ternary?: boolean; left?: number }>`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: 210px;
  z-index: 10;
  left: -225px;
  top: -70px;
`;

export const DownCircle = styled.div<{ secondary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #00c37d;
  position: absolute;
  right: calc(100% - 220px);
  bottom: calc(40%);
  transform: rotate(90deg);
`;

const DesciptionText = styled.span`
  font-weight: 400;
  line-height: 14px;
  font-size: 10px;

  color: #0e202f;

  margin-top: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 28px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const GreenText = styled.div`
  margin-top: 16px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
`;
