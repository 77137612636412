import styled from 'styled-components';

export const Container = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  * {
    display: inline-block;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

export const OutlinedButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.main.midnightBlue};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  background-color: ${(props) => props.theme.main.white};
  margin-top: 16px;
  font-family: 'Open Sans';
`;

export const RecurringPaymentTypeSelectContainer = styled.div`
  margin-bottom: 21px;
`;

export const PopperContent = styled.div`
  text-align: left;
`;
