import { Grid } from '@mui/material';
import React from 'react';

import { CancelLoanButton } from 'lib/components';
import { Center, ResultContainer, TextH5, TextSecondary } from './Common';

export const CheckoutSuccessCancel = ({ borrowerName, cancelLoanRef, closeExpanded }) => {
  return (
    <Center>
      <ResultContainer>
        <TextH5>Plan Canceled</TextH5>
        <TextSecondary>
          {borrowerName}'s plan has been canceled and approval has been reinstated. Any links sent previously have been
          deactivated
        </TextSecondary>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4} />
          <Grid item={true} xs={4}>
            <div>
              <CancelLoanButton
                data-testid="dismiss"
                ref={cancelLoanRef}
                onClick={closeExpanded}
                variant={'contained'}
                data-tag="cancel-confirm"
              >
                Close
              </CancelLoanButton>
            </div>
          </Grid>
        </Grid>
      </ResultContainer>
    </Center>
  );
};
