import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { CREATE_USER } from 'lib/graphql/mutations';

import { FormControl, Grid } from '@mui/material';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { UserManagementStepEnums } from 'lib/types';
import { emailPattern } from 'lib/utils';

import { Button as CherryButton, EmailInput, TextInput } from '@frontend/cherry-library';
import { CherryDialogTitle, DialogContainer, DialogContent, SubTitle } from '../Dialog';

interface Props {
  setActiveStep: (step: UserManagementStepEnums) => void;
  setUserForCreation: (user: any) => void;
  handleClose: () => void;
  selectedOrganizationMember: any;
  userForCreation: any;
  organizationUsers: any;
  merchantUsers: any;
}

export const AddTeamMember = ({
  setActiveStep,
  handleClose,
  selectedOrganizationMember,
  userForCreation,
  setUserForCreation,
  organizationUsers,
  merchantUsers,
}: Props) => {
  const alert = useAlert();
  const permission = usePermission();
  const { user } = useStore();
  const [createUser] = useMutation(CREATE_USER);

  const [state, setState] = useState<any>({});
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOrganizationMember?.user) {
      setState({
        firstName: selectedOrganizationMember?.user?.firstName,
        lastName: selectedOrganizationMember?.user?.lastName,
        email: selectedOrganizationMember?.user?.email,
        employeeId: selectedOrganizationMember?.user?.employeeId,
      });
      setEditMode(true);
    } else {
      setState(userForCreation);
    }
  }, []);

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    let value = nativeEvent.target.value;

    if (key === 'email') {
      value = nativeEvent.target?.value?.toLowerCase();
    }
    setState((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const createUserRequest = async () => {
    try {
      const allUsers: any = [...organizationUsers, ...merchantUsers];
      const isUserExist = allUsers?.find((us) => us?.user?.email === state?.email);
      const isSelfUser = state?.email === user?.email;

      if (isSelfUser) {
        const { data } = await createUser({ variables: { input: { ...state } } });
        if (data?.createUserRequest?.error) {
          alert.error(data?.createUserRequest?.message);
        } else {
          setUserForCreation(state);
          handleClose();
        }
      } else if (!selectedOrganizationMember.user && isUserExist) {
        alert.error('User already exist');
      } else {
        setUserForCreation(state);
        return setActiveStep(UserManagementStepEnums.ASSIGN_ROLE);
      }
    } catch (error) {
      alert.error('An error occurred');
    }
  };

  const buttonDisable = !state?.firstName || !state?.lastName || !emailPattern.test(state.email);

  return (
    <DialogContainer>
      {!permission(FeatureNames.ADD_USER, ScreenNames.SETTINGS) ? (
        ''
      ) : (
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {editMode ? `Update Team Member` : `Add Team Member`}
        </CherryDialogTitle>
      )}
      <SubTitle>Tell us about this team member.</SubTitle>
      <DialogContent>
        <StaticDialogContentWrapper>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <FormControl fullWidth={true} variant="outlined">
                <TextInput
                  inputSize="medium"
                  type="text"
                  label="First Name"
                  id="user-management-first-name"
                  data-testid="user-management-first-name"
                  inputProps={{
                    'data-inputKey': 'firstName',
                  }}
                  onChange={handleChange}
                  value={state?.firstName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl fullWidth={true} variant="outlined">
                <TextInput
                  inputSize="medium"
                  type="text"
                  label="Last Name"
                  id="user-management-last-name"
                  data-testid="user-management-last-name"
                  inputProps={{
                    'data-inputKey': 'lastName',
                  }}
                  onChange={handleChange}
                  value={state?.lastName}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl fullWidth={true} variant="outlined">
                <EmailInput
                  inputSize="medium"
                  type="email"
                  label="Email"
                  id="user-management-email"
                  data-testid="user-management-email"
                  inputProps={{
                    'data-inputKey': 'email',
                  }}
                  onChange={handleChange}
                  value={state?.email}
                  disabled={!!selectedOrganizationMember?.user?.email}
                  hideIcon={true}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl fullWidth={true} variant="outlined">
                <TextInput
                  inputSize="medium"
                  type="email"
                  label="Employee Id (Optional)"
                  inputProps={{
                    'data-inputKey': 'employeeId',
                  }}
                  id="user-management-employee"
                  data-testid="user-management-email"
                  onChange={handleChange}
                  value={state?.employeeId}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StaticDialogContentWrapper>
      </DialogContent>
      <StyledCherryButton
        fullWidth={true}
        variant="primary"
        onClick={createUserRequest}
        disabled={buttonDisable}
        data-testid="member-continue-button"
      >
        Continue
      </StyledCherryButton>
    </DialogContainer>
  );
};

const StaticDialogContentWrapper = styled.div`
  width: 560px;
`;

const StyledCherryButton = styled(CherryButton)`
  margin-top: 24px;
`;
