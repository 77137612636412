import { ApplicationFilterProvider } from 'lib/services';
import {
  ApprovalApplications,
  AuthChecker,
  CheckoutWithPatient,
  CheckoutWithPatientSuccess,
  Dashboard,
  DocumentRequests,
  EmailTemplates,
  ExpiredApprovals,
  ForgotPassword,
  Help,
  Login,
  Marketing,
  MemberDetail,
  MembershipHome,
  MembershipSettings,
  Oauth,
  OrganizationGroupStructure,
  OrganizationSelect,
  OtherResources,
  PatientBreakdown,
  PaymentEstimator,
  Referral,
  Reporting,
  Settings,
  ShopEditor,
  ShopHome,
  SocialMedia,
  Statements,
  TextMessages,
  Training,
  Transactions,
  TransactionsConversion,
  WebResources,
} from 'pages';
import ApplyWithPatientPage from 'pages/desktop/ApplyWithPatient/ApplyWithPatientPage';
import { CreateMemberInfo } from 'pages/desktop/Membership/components/MemberDetail';
import PlanBuilder from 'pages/desktop/PlanBuilder/PlanBuilder';
import CreatePlan from 'pages/desktop/PlanBuilder/views/CreatePlan';
import PlanDetail from 'pages/desktop/PlanBuilder/views/PlanDetail';
import PointOfSale from 'pages/desktop/PointOfSale';
import PointOfSaleSettings from 'pages/desktop/PointOfSaleSettings';
import SettingsSections from 'pages/desktop/Settings/SettingsSections';
import CreateShopProduct from 'pages/desktop/ShopEditor/views/CreateProduct';
import ProductDetail from 'pages/desktop/ShopEditor/views/ProductDetail';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

export const DesktopRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="oauth/:id" element={<Oauth />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/organization-select" element={<OrganizationSelect />} />
      <Route path="/" element={<AuthChecker />}>
        <Route
          path="/dashboard"
          element={
            <ApplicationFilterProvider>
              <Dashboard />
            </ApplicationFilterProvider>
          }
        />
        <Route path="/statements" element={<Statements />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transactions/:id" element={<Transactions />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/account-details" element={<SettingsSections />} />
        <Route path="/settings/pricing-settings" element={<SettingsSections />} />
        <Route path="/settings/user-management" element={<SettingsSections />} />
        <Route path="/settings/bank-settings" element={<SettingsSections />} />
        <Route path="/settings/business-information" element={<SettingsSections />} />
        <Route path="/settings/practice-finder" element={<SettingsSections />} />
        <Route path="/settings/location-management" element={<SettingsSections />} />
        <Route path="/settings/api-keys" element={<SettingsSections />} />

        <Route path="/training" element={<Training />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/organization-group-structure" element={<OrganizationGroupStructure />} />
        <Route path="/reporting/approvals-applications" element={<ApprovalApplications />} />
        <Route path="/reporting/expired-approvals" element={<ExpiredApprovals />} />
        <Route path="/reporting/transactions" element={<TransactionsConversion />} />
        <Route path="/reporting/patient-breakdown" element={<PatientBreakdown />} />
        <Route path="/help" element={<Help />} />
        <Route path="/marketing/email-templates" element={<EmailTemplates />} />
        <Route path="/marketing/social-media" element={<SocialMedia />} />
        <Route path="/marketing/text-messages" element={<TextMessages />} />
        <Route path="/marketing/web-resources" element={<WebResources />} />
        <Route path="/marketing/other-resources" element={<OtherResources />} />
        <Route path="/document-requests" element={<DocumentRequests />} />
        <Route path="/membership/home" element={<MembershipHome />} />
        <Route path="/membership/:id" element={<MemberDetail />} />
        <Route path="/membership/create" element={<CreateMemberInfo />} />
        <Route path="/membership/plan/builder" element={<PlanBuilder />} />
        <Route path="/membership/plan/:id" element={<PlanDetail />} />
        <Route path="/membership/plan/create" element={<CreatePlan />} />
        <Route path="/membership/settings" element={<MembershipSettings />} />
        <Route path="/shop/home" element={<ShopHome />} />
        <Route path="/shop/editor" element={<ShopEditor />} />
        <Route path="/shop/editor/create" element={<CreateShopProduct />} />
        <Route path="/shop/editor/product/:id" element={<ProductDetail />} />
        <Route path="/point-of-sale/home" element={<PointOfSale />} />
        <Route path="/point-of-sale/settings" element={<PointOfSaleSettings />} />
        <Route path="/checkout/:id" element={<CheckoutWithPatient />} />
        <Route path="/checkout/:id/success" element={<CheckoutWithPatientSuccess />} />
        <Route path="/apply-with-patient" element={<ApplyWithPatientPage />} />
        <Route path="/payment-estimator" element={<PaymentEstimator />} />
      </Route>
    </Routes>
  );
};
