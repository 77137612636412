import styled from 'styled-components';

export const Subtitle = styled.span<{ m?: any; p?: any }>`
  font-family: 'Open Sans', sans-serif;
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #56636d;
`;
