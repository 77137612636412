import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styled from 'styled-components';

import { theme } from 'config/theme';

interface Props {
  daysLeft: number;
  value: number;
  width: string;
  subtitleFontSize: string;
  progressTopSubtitle?: string;
  progressBottomSubtitle1: string;
  progressBottomSubtitle2?: string;
}
export const MilestoneProgressBar = ({
  daysLeft,
  value,
  width,
  subtitleFontSize,
  progressTopSubtitle,
  progressBottomSubtitle1,
  progressBottomSubtitle2,
}: Props) => {
  return (
    <ProgressWrapper width={width}>
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={9}
        styles={{
          trail: { stroke: theme.main.midnightBlue10 },
          path: { stroke: theme.main.green },
          background: { fill: theme.main.white },
        }}
        background={true}
      >
        <ProgressContent>
          {progressTopSubtitle && (
            <ProgressSubtitle fontSize={subtitleFontSize}>{progressTopSubtitle}</ProgressSubtitle>
          )}
          <ProgressTitle>{daysLeft}</ProgressTitle>
          <ProgressSubtitle fontSize={subtitleFontSize}>{progressBottomSubtitle1}</ProgressSubtitle>
          {progressBottomSubtitle2 && (
            <ProgressSubtitle fontSize={subtitleFontSize}>{progressBottomSubtitle2}</ProgressSubtitle>
          )}
        </ProgressContent>
      </CircularProgressbarWithChildren>
    </ProgressWrapper>
  );
};

const ProgressWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  position: absolute;
  right: 30px;
  margin-top: -69px;
`;

const ProgressContent = styled.div`
  margin-top: -16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressTitle = styled.div`
  font-size: 36px;
  line-height: 1;
`;

const ProgressSubtitle = styled.div<{ fontSize: string }>`
  text-align: center;
  font-size: ${(props) => props.fontSize};
`;
