import { useEffect } from 'react';
import { useSegment } from 'lib/hooks/useSegment';
import { UserManagement } from './UserManagement';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import useStore from 'lib/hooks/useStore';

const UserManagementSettings = () => {
  const { user } = useStore();
  const permission = usePermission();
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_USER_MANAGEMENT_PAGE_LOAD`);
  }, []);

  return permission(FeatureNames.EDIT_USER, ScreenNames.SETTINGS) && user?.userType !== 'MERCHANT_USER' ? (
    <UserManagement />
  ) : (
    <UnauthorizedSettings title="User Management" />
  );
};

export default UserManagementSettings;
