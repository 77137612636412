import { Grid } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { FeatureNames } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

import { Button as CherryButton } from '@frontend/cherry-library';
import { PaymentEstimatorCard } from 'lib/components/PaymentEstimator/PaymentEstimatorCard';
import { SendApplicationLinkCard } from 'lib/components/SendApplicationLink/SendApplicationLinkCard';
import { useSegment } from 'lib/hooks/useSegment';
import { DownCircle, Popover, PopoverContent, PopoverText, PopoverWrapper } from 'pages/desktop/Dashboard/views';
import { PreApprovalModal } from 'pages/desktop/Dashboard/views/PreApproval/PreApprovalModal';
import { MilestoneRewardsWidget } from 'pages/desktop/Dashboard/views/Widgets';
import { PatientTracker } from './views';
import { useNavigate } from 'react-router-dom';

export const DentalDashboard = () => {
  const { trackSegmentEvent } = useSegment();
  const { setPatientInfo, setSsnErrorMessage, features } = useStore();
  const navigate = useNavigate();

  const preApprovalTooltipInitialOpen = !StorageService.getPreapprovalTooltipClosed();

  const isPreApprovalFeatureEnabled = features.includes(FeatureNames.PREAPPROVAL) || false;
  const isApplyWithPatientFeatureEnabled = features.includes(FeatureNames.APPLY_WITH_PATIENT) || false;

  const enabledCardsCount = [isPreApprovalFeatureEnabled, isApplyWithPatientFeatureEnabled].filter(Boolean).length + 2;
  const gridItemSize = 12 / enabledCardsCount;
  const lastCardSize = enabledCardsCount === 3 ? 12 : 6;

  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [createTreatmentModalOpen, setCreateTreatmentModalOpen] = useState(false);

  const handleTriggerRefetch = () => {
    setTriggerRefetch((prev) => !prev);
  };

  const [preApprovalModalVisible, setPreApprovalModalVisible] = useState(false);
  const handlePreApprovalClick = () => {
    setTimeout(() => {
      setPatientInfo(null);
      setSsnErrorMessage(null);
    }, 250);
    setPreApprovalModalVisible(!preApprovalModalVisible);

    if (!preApprovalModalVisible) {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_VIEWED');
    }
  };

  const [popoverOpen, setPopoverOpen] = useState(preApprovalTooltipInitialOpen);
  const handleTooltipToggle = () => {
    if (preApprovalTooltipInitialOpen) {
      return;
    }

    setPopoverOpen(!popoverOpen);

    if (popoverOpen) {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.INFO_TOOLTIP_HOVER_VIEWED');
    }
  };

  const handlePreApprovalTooltipCloseButton = () => {
    StorageService.setPreapprovalTooltipClosed(true);
    setPopoverOpen(false);
  };

  const [applyWithPatientPopoverOpen, setApplyWithPatientPopoverOpen] = useState(false);
  const handleApplyWithPatientToggle = () => {
    setApplyWithPatientPopoverOpen(!applyWithPatientPopoverOpen);

    if (applyWithPatientPopoverOpen) {
      trackSegmentEvent('PRACTICE_PORTAL.APPLY_WITH_PATIENT.INFO_TOOLTIP_HOVER_VIEWED');
    }
  };

  const handleApplyWithPatientClick = () => {
    navigate('/apply-with-patient');
  };

  return (
    <>
      <Grid container={true} spacing={2} mb={5}>
        <Grid item={true} xs={12}>
          <MilestoneRewardsWidget horizontal={true} />
        </Grid>

        <Grid item={true} lg={gridItemSize} md={6} sm={6} xs={12}>
          <SendApplicationLinkCard />
        </Grid>

        {isApplyWithPatientFeatureEnabled && (
          <Grid item={true} lg={gridItemSize} md={6} sm={6} xs={12}>
            <StyledCard>
              <StyledPopoverWrapper
                onMouseEnter={handleApplyWithPatientToggle}
                onMouseLeave={handleApplyWithPatientToggle}
              >
                <Image src={images.infoCircleOutline.default} />
                {applyWithPatientPopoverOpen && (
                  <StyledPopover>
                    <StyledDownCircle />
                    <StyledPopoverContent>
                      <StyledPopoverText>
                        Help a patient apply directly from this screen and increase same-day treatment acceptance.
                      </StyledPopoverText>
                    </StyledPopoverContent>
                  </StyledPopover>
                )}
              </StyledPopoverWrapper>
              <CardTitle>Apply With Patient</CardTitle>
              <CardSubtitle>Fill out an application with a patient in-person</CardSubtitle>
              <CherryButton variant="primary" fullWidth={true} onClick={handleApplyWithPatientClick} size="medium">
                Apply with Patient
              </CherryButton>
            </StyledCard>
          </Grid>
        )}

        {isPreApprovalFeatureEnabled && (
          <Grid item={true} lg={gridItemSize} md={6} sm={6} xs={12} style={{ overflow: 'visible' }}>
            <StyledCard>
              <StyledPopoverWrapper onMouseEnter={handleTooltipToggle} onMouseLeave={handleTooltipToggle}>
                <Image src={images.infoCircleOutline.default} />
                {popoverOpen && (
                  <StyledPopover>
                    <StyledDownCircle />
                    <StyledPreApprovalPopoverContent>
                      <StyledPopoverText>
                        Before speaking to your next patient, see if they’ll get approved using just their name, phone
                        number, and address.
                      </StyledPopoverText>
                      {preApprovalTooltipInitialOpen && (
                        <CloseIcon src={images.close.default} onClick={handlePreApprovalTooltipCloseButton} />
                      )}
                    </StyledPreApprovalPopoverContent>
                  </StyledPopover>
                )}
              </StyledPopoverWrapper>
              <CardTitle>Pre-Approve Patient</CardTitle>
              <CardSubtitle>Check if a patient will be approved before they apply</CardSubtitle>
              <CherryButton variant="primary" fullWidth={true} onClick={handlePreApprovalClick} size="medium">
                Pre-Approve Patient
              </CherryButton>
            </StyledCard>
          </Grid>
        )}

        <Grid item={true} lg={gridItemSize} md={lastCardSize} sm={lastCardSize} xs={12}>
          <PaymentEstimatorCard />
        </Grid>
      </Grid>
      <PatientTracker
        createTreatmentModalOpen={createTreatmentModalOpen}
        setCreateTreatmentModalOpen={setCreateTreatmentModalOpen}
        triggerRefetch={triggerRefetch}
      />
      <PreApprovalModal open={preApprovalModalVisible} onClose={handlePreApprovalClick} />
    </>
  );
};

export const StyledCard = styled.div`
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02) !important;
  border-radius: 8px !important;
  background: ${(props) => props?.theme?.main?.white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 202px;
  box-sizing: border-box;
  overflow: visible !important;
`;

export const CardTitle = styled.span`
  color: ${(props) => props?.theme?.main?.textColor};
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
  margin-bottom: 16px;

  @media (min-width: 1200px) and (max-width: 1380px) {
    font-size: 16px;
  }
`;

export const CardSubtitle = styled.span`
  color: ${(props) => props?.theme?.main?.textColor};
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: auto;

  @media (min-width: 1200px) and (max-width: 1380px) {
    font-size: 14px;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: -4px;
  right: 0;
  height: 24px;
  width: 24px;
`;

export const StyledPopover = styled(Popover)`
  top: -124px;
  right: -24px;
  left: unset;
  bottom: auto;
  width: 320px;
  min-height: 98px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  width: 100%;
`;

export const StyledDownCircle = styled(DownCircle)`
  top: unset;
  right: 12px;
  bottom: -12px;
  transform: rotate(180deg);
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid ${(props) => props?.theme?.main?.green};
`;

export const StyledPopoverText = styled(PopoverText)`
  font-size: 14px;
`;

export const StyledPopoverContent = styled(PopoverContent)`
  padding: 16px;
`;

const StyledPreApprovalPopoverContent = styled(PopoverContent)`
  padding: 16px;
  display: flex;
  flex-direction: row;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
