import { PageHeader } from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Container, Row } from 'lib/styles';
import React from 'react';
import { LinkTree, WebInstructions, WidgetSettings } from './views';

const WebResources = ({}) => {
  const { features } = useStore();
  const permission = usePermission();
  const { isSnackbarVisible } = useSnackbar();

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Web Resources" />
      <Row>
        <WebInstructions title={'Cherry Webpage Instructions'} />
      </Row>
      <Row>
        <WidgetSettings title={'Your Cherry Webpage'} />
      </Row>
      {features.includes(FeatureNames.CHERRY_LINKTREE) && permission(FeatureNames.LINK_TREE, ScreenNames.MARKETING) && (
        <Row>
          <LinkTree title={'Your Cherry Links'} />
        </Row>
      )}
    </Container>
  );
};

export default WebResources;
