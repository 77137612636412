import { Loading } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import AddCard from 'pages/desktop/CheckoutWithPatient/AddCard';
import PaymentMethodCard from 'pages/desktop/CheckoutWithPatient/PaymentMethodCard';
import { usePaymentMethods } from 'pages/desktop/CheckoutWithPatient/PaymentMethodContext/PaymentMethodContext';
import { AccountType, RecurringPaymentProps, TypeEnum } from 'pages/desktop/CheckoutWithPatient/RecurringPayment/types';
import { OutlinedButton } from 'pages/desktop/CheckoutWithPatient/SetupPayment/styles';
import { PaymentViewState } from 'pages/desktop/CheckoutWithPatient/SetupPayment/types';
import { filterCardsByCreatedAt, filterDebitOrAchMethods } from 'pages/desktop/CheckoutWithPatient/SetupPayment/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const RecurringPayment = ({
  borrowerId,
  applicationId,
  onMethodSelect,
  selectedRecurringPaymentMethod,
}: RecurringPaymentProps) => {
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();
  const { paymentMethods, handlePaymentMethods, loading } = usePaymentMethods();

  const [isPreferredType, setIsPreferredType] = useState('');
  const [paymentViewState, setPaymentViewState] = useState<PaymentViewState | null>(null);

  const debitOrAchMethods = filterDebitOrAchMethods(paymentMethods);

  useEffect(() => {
    if (debitOrAchMethods?.length > 0 || creditCardMethodContents()?.length > 0) {
      setPaymentViewState(PaymentViewState.PaymentList);
    } else {
      setPaymentViewState(PaymentViewState.AddPaymentMethod);
    }
  }, [paymentMethods]);

  const selectPreferredPaymentMethod = () => {
    let preferredInstallmentMethod;

    if (checkingACHMethodContents()?.length > 0) {
      const checkingAchMethod = checkingACHMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = checkingAchMethod?.[0];
      setIsPreferredType('checking');
    } else if (debitCardMethodContents()?.length > 0) {
      const debitCardMethod = debitCardMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = debitCardMethod?.[0];
      setIsPreferredType('debit');
    } else if (savingACHMethodContents()?.length > 0) {
      const savingAchMethod = savingACHMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = savingAchMethod?.[0];
      setIsPreferredType('savings');
    } else {
      const creditCardMethod = creditCardMethodContents()?.sort((a, b) => b.id - a.id);
      onMethodSelect(creditCardMethod?.[0]);
      preferredInstallmentMethod = creditCardMethod?.[0];
    }

    if (!selectedRecurringPaymentMethod) {
      onMethodSelect(preferredInstallmentMethod);
    } else if (
      debitOrAchMethods?.length > 0 &&
      selectedRecurringPaymentMethod.type === 'CARD' &&
      selectedRecurringPaymentMethod?.storedCard?.type !== 'DEBIT'
    ) {
      onMethodSelect(preferredInstallmentMethod);
    }
  };

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      selectPreferredPaymentMethod();
    }
  }, [paymentMethods]);

  const checkingACHMethodContents = () => {
    return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.CHECKING);
  };

  const savingACHMethodContents = () => {
    return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.SAVINGS);
  };

  const debitCardMethodContents = () => {
    return paymentMethods?.filter(
      (content) =>
        content.type === 'CARD' && content?.storedCard?.type === 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };

  const bankRCCMethodContents = () => {
    return paymentMethods?.filter((content) => content?.type === TypeEnum.RCC);
  };

  const creditCardMethodContents = () => {
    return paymentMethods?.filter(
      (content) =>
        content.type === 'CARD' && content?.storedCard?.type !== 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };

  const openAddPaymentMethodView = () => {
    setPaymentViewState(PaymentViewState.AddPaymentMethod);
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RECURRING_PAYMENT_ADD_PAYMENT_METHOD_CLICKED', {
      loanId: id,
      borrowerId,
    });
  };

  const openPaymentMethodListView = () => {
    setPaymentViewState(PaymentViewState.PaymentList);
  };

  const onCardConnect = async () => {
    await handlePaymentMethods(borrowerId);
    setPaymentViewState(PaymentViewState.PaymentList);
  };

  if (loading) {
    return <Loading size={20} />;
  }

  if (paymentViewState === PaymentViewState.PaymentList) {
    return (
      <>
        {debitOrAchMethods?.length > 0 ? (
          <>
            {checkingACHMethodContents()?.map((paymentMethod, index) => (
              <PaymentMethodCard
                key={`CHECKING-ACH-${index}-${paymentMethod.id}`}
                paymentMethod={paymentMethod}
                selectPaymentMethod={onMethodSelect}
                selectedPaymentMethod={selectedRecurringPaymentMethod}
                isPreferred={isPreferredType === 'checking'}
              />
            ))}

            {debitCardMethodContents()?.map((paymentMethod, index) => (
              <PaymentMethodCard
                key={`DEBIT-CARD-${index}-${paymentMethod.id}`}
                paymentMethod={paymentMethod}
                selectPaymentMethod={onMethodSelect}
                selectedPaymentMethod={selectedRecurringPaymentMethod}
                isPreferred={isPreferredType === 'debit'}
              />
            ))}

            {savingACHMethodContents()?.map((paymentMethod, index) => (
              <PaymentMethodCard
                key={`SAVING-ACH-${index}-${paymentMethod.id}`}
                paymentMethod={paymentMethod}
                selectPaymentMethod={onMethodSelect}
                selectedPaymentMethod={selectedRecurringPaymentMethod}
                isPreferred={isPreferredType === 'savings'}
              />
            ))}

            {bankRCCMethodContents()?.map((paymentMethod, index) => (
              <PaymentMethodCard
                key={`RCC-${index}-${paymentMethod.id}`}
                paymentMethod={paymentMethod}
                selectPaymentMethod={onMethodSelect}
                selectedPaymentMethod={selectedRecurringPaymentMethod}
                isPreferred={false}
              />
            ))}
          </>
        ) : (
          <>
            {creditCardMethodContents()?.map(
              (paymentMethod, index) =>
                paymentMethod?.storedCard?.type !== 'PREPAID' && (
                  <PaymentMethodCard
                    key={`CREDIT-CARD-${index}-${paymentMethod.id}`}
                    paymentMethod={paymentMethod}
                    selectPaymentMethod={onMethodSelect}
                    selectedPaymentMethod={selectedRecurringPaymentMethod}
                    isPreferred={false}
                  />
                ),
            )}
          </>
        )}
        <OutlinedButton onClick={openAddPaymentMethodView}>Add Payment Method</OutlinedButton>
      </>
    );
  }

  if (paymentViewState === PaymentViewState.AddPaymentMethod) {
    return <AddCard id={borrowerId || applicationId} onCancel={openPaymentMethodListView} onConnect={onCardConnect} />;
  }

  return null;
};

export default RecurringPayment;
