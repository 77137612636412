import { faCopy, faDesktop, faExternalLinkAlt, faMobileAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Grid, IconButton } from '@mui/material';
import { ButtonPrimary, CherryColorPicker, CloseIcon, DashCard, DropDown, HelpIcon, Loading } from 'lib/components';
import { AlertDialog, CherryDialogContent } from 'lib/components/Dialogue';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { useApolloClient, useMutation } from '@apollo/client';
import { APP_ENV } from 'config';
import { PATCH_WIDGET_SETTINGS } from 'lib/graphql/mutations';
import { GET_WIDGET_SETTINGS } from 'lib/graphql/queries';

export const WidgetSettings = () => {
  const client = useApolloClient();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const alert = useAlert();

  const [settingsLoading, setSettingsLoading] = useState(false);
  const [color, setColor] = useState<string>('#00c37d');
  const [colorSecondary, setColorSecondary] = useState<string>('#00c37d');
  const [fontFamily, setFontFamily] = useState<string>('Open Sans');
  const [open, setOpen] = useState<boolean>(false);
  const [isCustomized, setIsCustomized] = useState<boolean>(false);

  const [patchWidgetSettings, { loading }] = useMutation(PATCH_WIDGET_SETTINGS);

  const selectedColor = (e) => {
    setColor(e);
    setIsCustomized(true);
  };

  const selectedColorSecondary = (e) => {
    setColorSecondary(e);
    setIsCustomized(true);
  };

  const onFontTypeSelect = (e) => {
    setFontFamily(e?.value);
    setIsCustomized(true);
  };

  const fontFamilies = [
    { value: 'Open Sans', label: 'Default: Open Sans' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Playfair', label: 'Playfair' },
    { value: 'Oswald', label: 'Oswald' },
    { value: 'Poppins', label: 'Poppins' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Source Sans Pro', label: 'Source Sans Pro' },
    { value: 'Raleway', label: 'Raleway' },
    { value: 'Slabo 27px', label: 'Slabo' },
    { value: 'PT Sans', label: 'PT Sans' },
  ];

  const generateScriptTemplate = () => {
    return `<!-- CHERRY WIDGET BEGIN --> <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"rel="stylesheet"/>
    <script>
        (function (w, d, s, o, f, js, fjs) {
            w[o] =
                w[o] ||
                function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
        })(window, document, "script", "_mw", ' https://files.withcherry.com/widgets${
          APP_ENV === 'dev' ? '/pre' : ''
        }/membership-widget.js');
        _mw(
            "init",
            {
                debug: false,
                variables: {
                    slug: '${organization?.slug}',
                    name: "${organization?.name}",
                },
                styles: {
                    primaryColor: '${color}',
                    secondaryColor: '${color}10',
                    fontFamily: '${fontFamily}',
                },
            },
            ["membership"]
        );
    </script><div id="membership"></div><!-- CHERRY WIDGET END -->`;
  };

  // Save Widget
  const saveWidgetSettings = async () => {
    try {
      const { id, slug } = organization || {};
      const { data } = await patchWidgetSettings({
        variables: {
          input: {
            organizationId: id,
            color,
            secondaryColor: colorSecondary,
            font: fontFamily,
          },
        },
      });
      if (data?.patchWidgetSettings?.id) {
        alert.success('Success: Saved!');
      } else {
        alert.error('Something went wrong. Please try again.');
      }
    } catch (err) {
      alert.error('Something went wrong. Please try again.');
    }
  };

  const onCopyScriptClicked = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    const result = generateScriptTemplate();
    navigator.clipboard.writeText(result);
    alert.success('Snippet was copied to your clipboard');
    trackSegmentEvent('Copied Web Widget', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      wasCustomized: isCustomized,
    });
  };

  const AlertContent = (variables: any) => {
    const iframeSrc = `/membership-widgets.html?env=${variables?.variables?.env}&slug=${variables?.variables?.slug}&name=${variables?.variables?.name}&primaryColor=${variables?.variables?.primaryColor}&fontFamily=${variables?.variables?.fontFamily}`.toString();
    const [mobileView, setMobileView] = useState<boolean>(false);

    const onDesktopClicked = () => setMobileView(false);

    const onMobileClicked = () => setMobileView(true);

    return (
      <>
        <Flex>
          <CustomButtonGroup variant="contained" disableElevation={true}>
            <Button onClick={onDesktopClicked} className={mobileView ? '' : 'active-viewport'}>
              <FontAwesomeIcon icon={faDesktop} /> &nbsp; &nbsp; Desktop View
            </Button>
            <Button onClick={onMobileClicked} className={!mobileView ? '' : 'active-viewport'}>
              <FontAwesomeIcon icon={faMobileAlt} /> &nbsp; &nbsp; Mobile View{' '}
            </Button>
          </CustomButtonGroup>
          <IconButton aria-label="close" id="close--button" onClick={handleDisagreeClick}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <CustomCherryDialogContent>
          <iframe
            src={iframeSrc}
            width={'900px'}
            height={'600px'}
            frameBorder="0"
            className={mobileView ? 'force-mobile' : ''}
          />
        </CustomCherryDialogContent>
      </>
    );
  };

  const onPreviewClick = () => setOpen(true);
  const handleDisagreeClick = () => setOpen(false);

  const handleAgreeClicked = () => {
    window.open(
      `/membership-widgets.html?env=${APP_ENV}&slug=${organization?.slug}&name=${
        organization?.name
      }&primaryColor=${color?.replace('#', '')}&fontFamily=${fontFamily}`.toString(),
      '_blank',
    );
  };

  const fetchWidgetSettings = async () => {
    try {
      setSettingsLoading(true);
      const {
        data: { getWidgetSettings },
      } = await client.query({
        query: GET_WIDGET_SETTINGS,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });

      if (getWidgetSettings) {
        setSettingsLoading(false);
      }

      if (getWidgetSettings?.color) {
        setColor(getWidgetSettings?.color);
      }
      if (getWidgetSettings?.secondaryColor) {
        setColorSecondary(getWidgetSettings?.secondaryColor);
      }

      if (getWidgetSettings?.font) {
        setFontFamily(getWidgetSettings?.font);
      }
    } catch (e) {
      setSettingsLoading(false);
    }
  };

  useEffect(() => {
    fetchWidgetSettings?.();
  }, []);

  if (settingsLoading) {
    return (
      <Column>
        <Loading size={20} />
      </Column>
    );
  }

  return (
    <Column>
      <DashCard>
        <SubHeader size={'16px'} style={{ marginBottom: '8px' }}>
          Website Code Snippet
        </SubHeader>

        <Row style={{ fontSize: '14px', paddingBottom: '24px' }}>
          Copy-paste this into your website to let patients browse and buy memberships.
        </Row>

        <Row>
          <Grid container={true}>
            <Grid container={true} xs={5} style={{ alignItems: 'center', marginLeft: '2px' }} spacing={3}>
              <Grid xs={8} style={{ margin: '0px' }}>
                <SettingsTypography> Choose a Brand Color</SettingsTypography>
                <CherryColorPicker selectedColor={selectedColor} defaultColor={color} />
              </Grid>
              <Grid xs={3} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                <RoundedColor color={color} />
              </Grid>
            </Grid>

            <Grid container={true} xs={5} style={{ alignItems: 'center', marginLeft: '2px' }} spacing={3}>
              <Grid xs={8} style={{ margin: '0px' }}>
                <SettingsTypography> Choose a Secondary Color</SettingsTypography>
                <CherryColorPicker selectedColor={selectedColorSecondary} defaultColor={colorSecondary} />
              </Grid>
              <Grid xs={3} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                <RoundedColor color={colorSecondary} />
              </Grid>
            </Grid>

            <Grid container={true} xs={5} style={{ alignItems: 'center', marginTop: '24px' }}>
              <Grid item={true} xs={6}>
                <SettingsTypography>Font</SettingsTypography>
                <DropdownWrapper>
                  <DropDown
                    options={fontFamilies}
                    hasCustomFont={true}
                    onChange={onFontTypeSelect}
                    hoveredMode={false}
                    defaultValue={fontFamily}
                  />
                </DropdownWrapper>
              </Grid>
            </Grid>

            <CodeRow>{generateScriptTemplate()}</CodeRow>

            <Row style={{ marginTop: '24px' }}>
              <ButtonPrimary
                onClick={saveWidgetSettings}
                loading={loading}
                disabled={loading}
                text={'Save Widget Settings'}
                data-testid="saveWidgetSettings"
                endIcon={<FontAwesomeIcon style={{ width: '14px' }} icon={faSave} />}
                // @ts-ignore
                style={{ marginRight: '14px' }}
              />

              <ButtonPrimary
                onClick={onCopyScriptClicked}
                text={'Copy Script'}
                loading={loading}
                disabled={loading}
                data-testid="saveSettingsButton"
                endIcon={<FontAwesomeIcon style={{ width: '14px' }} icon={faCopy} />}
              />

              <CustomButtonPrimary
                data-testid="previewMyWidgetsButton"
                text="Preview My Widgets"
                loading={loading}
                disabled={loading}
                endIcon={<FontAwesomeIcon style={{ width: '14px' }} icon={faExternalLinkAlt} />}
                onClick={onPreviewClick}
              />

              <CustomAlertDialog
                open={open}
                agreeClicked={handleAgreeClicked}
                onDisagreeClicked={handleDisagreeClick}
                children={
                  <AlertContent
                    variables={{
                      env: APP_ENV,
                      slug: organization?.slug,
                      name: organization?.name,
                      primaryColor: color?.replace('#', ''),
                      fontFamily,
                    }}
                  />
                }
                agreeText={'Open In New Tab'}
                declineText={'Close'}
              />
            </Row>
          </Grid>
        </Row>

        <Row>
          <Grid
            container={true}
            style={{ backgroundColor: '#F2F4F5', borderRadius: '4px', padding: '8px', marginTop: '24px' }}
          >
            <Grid item={true} xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <HelpIcon active={true} color={'#0E202F'} />
            </Grid>
            <Grid item={true} xs={11} style={{ fontSize: '14px' }}>
              If you need help or want more customization, we offer a free website implementation service. Submit a
              request for help from a&nbsp;
              <a
                style={{ color: '#00C37D' }}
                href="https://withcherry.typeform.com/website-request?typeform-source=partnerportal "
                target="_blank"
              >
                Cherry Web Developer
              </a>
              .
            </Grid>
          </Grid>
        </Row>
      </DashCard>
    </Column>
  );
};

const CustomAlertDialog = styled(AlertDialog)`
  width: 960px !important;
`;

const CustomButtonPrimary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #879097 !important;
  color: #879097 !important;
  border-radius: 4px !important;
  margin-left: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

interface RoundedColorProps {
  color: string;
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  #close--button {
    margin-right: 25px;
  }
`;

const CodeRow = styled(Row)`
  font-size: 12px;
  background: #f2f4f5;
  padding: 16px;
  border-radius: 4px;
  margin: 24px 0px;
  word-break: break-word;
`;

const DropdownWrapper = styled.div`
  border: 1px solid #dadada !important;
  display: inline-block;
  width: 100%;

  * {
    font-size: 12px !important;
  }
`;
const RoundedColor = styled.div<RoundedColorProps>`
  width: 25px;
  background-color: ${(props) => props.color};
  height: 25px;
  border-radius: 25px;
  margin-top: 20px;
`;

const SettingsTypography = styled.div`
  font-weight: 400;
  color: #0e202f;
  font-size: 12px;
  margin-bottom: 4px;
`;

const CustomCherryDialogContent = styled(CherryDialogContent)`
  overflow-x: hidden;

  .force-mobile {
    width: 450px;
    max-width: 750px;
    height: 1334px;
    margin: 0 auto;
    border-style: none;
    border-color: inherit;
    border-width: 0px;
    display: block;
  }
`;

const CustomButtonGroup = styled(ButtonGroup)`
  padding: 8px 24px !important;
  button {
    background-color: #dadada50 !important;

    &.active-viewport {
      background-color: rgb(0, 195, 125) !important;
      color: #ffffff !important;
    }

    span {
      color: #ffffff !important;
    }
  }
`;
