import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  MobileApplicationCancel,
  MobileApplicationCheckout,
  MobileApplicationCheckoutFinished,
  MobileApplicationDetail,
  MobileApplicationPendingIncome,
  MobileApplicationUpdate,
  MobileAuthChecker,
  MobileCherryLink,
  MobileDashboard,
  MobileDocumentRequests,
  MobileFileUploadPage,
  MobileForgotPassword,
  MobileHelpSupport,
  MobileLogin,
  MobileOrganizationSelection,
  MobilePreApprovalInfo,
  MobileSendApplicationLink,
  MobileSettings,
  MobileTransactionDetail,
  MobileTransactionPartialRefund,
  MobileTransactions,
  MobileTransactionVoidDetail,
  MobileTransactionVoidReview,
  MobileTransactionVoidSuccess,
  Oauth,
} from 'pages';
import MobileTransactionCancelRefund from 'pages/mobile/MobileTransactionCancelRefund';
import MobileTransactionFullRefund from 'pages/mobile/MobileTransactionFullRefund';
import MobileTransactionFullRefundReview from 'pages/mobile/MobileTransactionFullRefundReview';
import MobileTransactionFullRefundSuccess from 'pages/mobile/MobileTransactionFullRefundSuccess';
import MobileTransactionPartialRefundReview from 'pages/mobile/MobileTransactionPartialRefundReview';
import MobileTransactionPartialRefundSuccess from 'pages/mobile/MobileTransactionPartialRefundSuccess';
import { ApplicationFilterProvider } from 'lib/services';

export const MobileRoutes = () => {
  return (
    <Routes>
      <Route path="oauth/:id" element={<Oauth />} />
      <Route path="/login" element={<MobileLogin />} />
      <Route path="/forgot-password" element={<MobileForgotPassword />} />
      <Route path="/organization-select" element={<MobileOrganizationSelection />} />
      <Route path="/" element={<MobileAuthChecker />}>
        <Route
          path="/dashboard"
          element={
            <ApplicationFilterProvider>
              <MobileDashboard />
            </ApplicationFilterProvider>
          }
        />
        <Route path="/send-application" element={<MobileSendApplicationLink />} />
        <Route path="/application/checkout" element={<MobileApplicationCheckout />} />
        <Route path="/application/pending-income" element={<MobileApplicationPendingIncome />} />
        <Route path="/application/detail" element={<MobileApplicationDetail />} />
        <Route path="/application/update" element={<MobileApplicationUpdate />} />
        <Route path="/application/cancel" element={<MobileApplicationCancel />} />
        <Route path="/application/checkout-finished" element={<MobileApplicationCheckoutFinished />} />
        <Route path="/application/pre-approval" element={<MobilePreApprovalInfo />} />
        <Route path="/transactions" element={<MobileTransactions />} />
        <Route path="/transactions/detail" element={<MobileTransactionDetail />} />
        <Route path="/transactions/detail/partial-refund" element={<MobileTransactionPartialRefund />} />
        <Route path="/transactions/detail/partial-refund/review" element={<MobileTransactionPartialRefundReview />} />
        <Route path="/transactions/detail/partial-refund/success" element={<MobileTransactionPartialRefundSuccess />} />
        <Route path="/transactions/detail/full-refund" element={<MobileTransactionFullRefund />} />
        <Route path="/transactions/detail/full-refund/review" element={<MobileTransactionFullRefundReview />} />
        <Route path="/transactions/detail/full-refund/success" element={<MobileTransactionFullRefundSuccess />} />
        <Route path="/transactions/detail/void" element={<MobileTransactionVoidDetail />} />
        <Route path="/transactions/detail/void/review" element={<MobileTransactionVoidReview />} />
        <Route path="/transactions/detail/void/success" element={<MobileTransactionVoidSuccess />} />
        <Route path="/transactions/detail/cancel-refund" element={<MobileTransactionCancelRefund />} />
        <Route path="/share-your-link" element={<MobileCherryLink />} />
        <Route path="/help" element={<MobileHelpSupport />} />
        <Route path="/settings" element={<MobileSettings />} />
        <Route path="/document-requests" element={<MobileDocumentRequests />} />
        <Route path="/document-requests/file-upload" element={<MobileFileUploadPage />} />
      </Route>
    </Routes>
  );
};
