import { gql } from '@apollo/client/core';

export const FETCH_ORGANIZATION = gql`
  query fetchUserOrganizations {
    fetchUserOrganizations {
      data {
        id
        name
        active
        slug
        isDemo
        outcomeThreshold
        permissions
        roles
      }
      success
      code
      message
    }
  }
`;

export const FETCH_ORGANIZATION_MERCHANTS = gql`
  query fetchOrganizationMerchants($input: OrganizationMerchantsInput) {
    fetchOrganizationMerchants(input: $input) {
      data {
        id
        name
        permissions
        roles
        address {
          address1
          zip
          city {
            name
            state {
              code
            }
          }
        }
      }
      success
      message
    }
  }
`;

export const FETCH_ORGANIZATION_MERCHANT_USERS = gql`
  query fetchOrganizationMerchants($input: OrganizationMerchantsInput) {
    fetchOrganizationMerchants(input: $input) {
      data {
        id
        name
        permissions
        roles
        merchantUsers {
          id
          createdAt
          role
          merchant {
            name
            id
          }
          user {
            lastName
            phone
            id
            firstName
            employeeId
            email
          }
        }
        address {
          address1
          zip
          city {
            name
            state {
              code
            }
          }
        }
      }
      success
      message
    }
  }
`;

export const FETCH_ORGANIZATION_MERCHANT_GROUPS = gql`
  query fetchOrganizationMerchantGroups($input: FetchMerchantGroups) {
    fetchOrganizationMerchantGroups(input: $input) {
      id
      name
      type
      childs
      merchantGroupUsers {
        id
        user {
          email
          firstName
          lastName
          roles
          id
          phone
          employeeId
          status
        }
        role
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_USER_MERCHANTS = gql`
  query fetchUserMerchants {
    getUserMerchants {
      data {
        id
        name
        address {
          address1
          zip
          city {
            name
            state {
              code
            }
          }
        }
      }
      success
      message
    }
  }
`;

export const FETCH_ORGANIZATION_DETAIL = gql`
  query fetchOrganizationDetail($input: OrganizationDetailInput) {
    fetchOrganizationDetail(input: $input) {
      success
      code
      organization {
        code
        id
        createdAt
        activatedAt
        name
        outcomeThreshold
        status
        active
        isDemo
        slug
        refundFee
        menuCode
        promoPeriod
        industry
        groupId
        bankPartner
      }
      features {
        status
        type
      }
      locations {
        id
        name
        address {
          address1
          zip
          city {
            name
            state {
              code
            }
          }
        }
        organization {
          active
        }
        poc {
          firstName
          lastName
        }
      }
    }
  }
`;

export const FETCH_ORGANIZATION_TIER_AND_AUTH = gql`
  query getOrganizationTier($input: OrganizationTierInput) {
    getOrganizationTier(input: $input) {
      hasTier
      organizationTier {
        id
        organizationId
        tierId
        adjustmentFactor
        creationMethod
        createdBy
        updatedBy
        status
        createdAt
        updatedAt
      }
      tierDetail {
        lastTierUpdate
        nexTierUpdate
        currentVolume
        volumeRequiredToUpgrade
      }
      menuTiers {
        id
        label
        minimumVolume
        maximumVolume
        tierType {
          startingPeriodLength
        }
      }
    }
    getUserAuthInfo {
      code
      features
      merchant {
        id
        name
      }
      message
      organization {
        id
        name
      }
      success
      tokenType
      user {
        id
        email
        firstName
        lastName
        roles
        phone
        prequal
      }
    }
  }
`;

export const GET_SENT_ESTIMATE_SENT_HISTORY = gql`
  query getSentEsimateHistory($input: SentEstimateHistoryInput) {
    getSentEsimateHistory(input: $input) {
      total
      contents {
        patientName
        phone
        email
        createdAt
      }
    }
  }
`;
