import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyUtil from 'lib/utils/currency';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import Input from 'react-currency-input-field';
import styled from 'styled-components';

interface CurrencyInputProps {
  maxAmount: number;
  minAmount: number;
  onChange: (value: any) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  defaultValue: any;
  clickedButton?: any;
  onEditModeChange?: (value: boolean) => void;
  hasEditMode?: boolean;
  fullWidth?: boolean;
  width?: string;
  precision?: number;
  name?: string;
  label?: string;
  customerName?: string;
}

export const CurrencyInput = ({
  maxAmount,
  minAmount,
  onChange,
  onClick,
  onFocus,
  onBlur,
  defaultValue,
  clickedButton,
  onEditModeChange,
  hasEditMode = false,
  width = '320px',
  precision = 2,
  fullWidth = false,
  name,
  label,
  customerName,
}: CurrencyInputProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [className, setClassName] = useState('');
  const [value, setValue] = useState<string | number>(defaultValue);
  const [isEditing, setIsEditing] = useState<boolean>(hasEditMode);

  const alert = useAlert();

  const toggleInputStatus = () => {
    setIsEditing(true);
    onEditModeChange && onEditModeChange(true);
  };

  const cancelAmountEditing = () => {
    setValue(defaultValue);
    setIsEditing(false);
    onEditModeChange && onEditModeChange(false);
  };

  const getCustomerName = () => {
    if (!customerName) {
      return 'the';
    }

    if (typeof customerName === 'string') {
      return customerName.endsWith('s') ? `${customerName}'` : `${customerName}'s`;
    } else {
      return customerName;
    }
  };

  const approveAmountEditing = () => {
    if (Number(value) > maxAmount) {
      alert.error(`Purchase amount must be lower than $${maxAmount}`);

      return;
    } else if (Number(value) < minAmount) {
      alert.error(`Purchase amount must be greater than $${minAmount}`);

      return;
    }
    setIsEditing(false);
    onEditModeChange && onEditModeChange(false);
    onChange && onChange(value);
  };

  const validateValue = (amount: string | undefined): void => {
    if (!amount) {
      setErrorMessage('');
      setClassName('');
      setValue('');
      onChange('');
      return;
    }

    if (Number(amount) !== 0) {
      // trim leading zeros
      amount = amount.replace(/^0+/, '');
    }

    setClassName('is-valid');
    setErrorMessage('');
    setValue(amount);

    if (Number(amount) > maxAmount) {
      setErrorMessage(
        `Purchase amount cannot exceed ${getCustomerName()} available balance of ${CurrencyUtil.toCurrencyString(
          maxAmount,
        )}`,
      );
      setClassName('invalid');
    }

    if (Number(amount) < minAmount) {
      setErrorMessage(`Minimum purchase amount is ${minAmount}`);
      setClassName('invalid');
    }

    onChange && onChange(amount);
  };

  return (
    <>
      {label && <FormLabel className={className}>{label}</FormLabel>}
      <AmountContainer>
        <StyledInput
          width={width}
          data-testid="currency-input"
          id="validationCustom01"
          name={name ?? 'input-1'}
          defaultValue={defaultValue}
          className={`form-control ${className}`}
          value={value}
          onValueChange={validateValue}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          prefix="$"
          decimalScale={precision}
          step={1}
          disabled={!isEditing}
          fullWidth={fullWidth}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        {!hasEditMode &&
          !Boolean(clickedButton) &&
          (isEditing ? (
            <IconContainer>
              <Icon icon={faCheck} color="#00c37d" onClick={approveAmountEditing} />
              <Icon icon={faTimes} color="red" onClick={cancelAmountEditing} />
            </IconContainer>
          ) : (
            <EditLink onClick={toggleInputStatus}>Edit Amount</EditLink>
          ))}
      </AmountContainer>
    </>
  );
};

const AmountContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`;

const Icon = styled(FontAwesomeIcon)`
  padding: 0 10px;
`;

const EditLink = styled.span`
  text-decoration: underline;
  margin-left: 16px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: inline-flex;
  margin-left: 16px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  width: 100%;
`;

const StyledInput = styled(Input)<{ fullWidth?: boolean; width?: string }>`
  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 2px;
  width: ${(props) => (props.fullWidth ? '100%' : props.width ? props?.width : '320px')};

  &.invalid,
  &.invalid input:focus {
    outline: none !important;
    border: 1px solid red;
    color: red;
  }
`;

const FormLabel = styled.p`
  font-size: 14px;
  color: #0e202f;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;
