import { useMutation } from '@apollo/client';
import {
  Button,
  CurrencyInput,
  DateInput,
  EmailInput,
  Heading,
  PhoneNumberInput,
  TextInput,
} from '@frontend/cherry-library';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { APP_ENV } from 'config';
import { CherryTextField, DropDown, ModalV2, SsnInput } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { isOrganizationDental, US_STATES } from 'lib/constants';
import {
  CHECK_ELIGIBILITY_FOR_APPLY_WITH_PATIENT,
  POST_APPLY_WITH_PATIENT_CONSENT,
  SEND_APPLICATION_LINK,
} from 'lib/graphql/mutations';
import { FeatureNames } from 'lib/hooks';
import useAddressAutoComplete, { AutoCompleteAddress } from 'lib/hooks/useAddressAutoComplete';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { emailPattern, numericPattern, phonePattern, validateBirthDate } from 'lib/utils';
import {
  ApplicationButtonContainer,
  ColumnButtonContainer,
  DropDownBorder,
  ExistingBorrowerModal,
  FormContainer,
  InnerFormContainer,
  SplitRow,
} from 'pages/desktop/ApplyWithPatient/styles';
import { FormData, ISelectedAddress, Location } from 'pages/desktop/ApplyWithPatient/types';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface FormProps {
  setStep: (string) => void;
  setAlreadyExistingBorrower: (boolean) => void;
}

const Form = ({ setStep, setAlreadyExistingBorrower }: FormProps) => {
  const alert = useAlert();
  const { segmentEventHandler } = useSegment();
  const MAX_ELIGIBLE_REQUEST_AMOUNT = 35000;
  const {
    getValues,
    control,
    formState: { isValid } = {},
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  });
  const getFormData = (): FormData => getValues();
  const [postApplyWithPatientConsent] = useMutation(POST_APPLY_WITH_PATIENT_CONSENT);
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const [checkEligibilityForApplyWithPatient] = useMutation(CHECK_ELIGIBILITY_FOR_APPLY_WITH_PATIENT);

  const {
    features,
    organization,
    merchants,
    setApplyWithPatientFormData,
    setSubmittedApplyWithPatientData,
    setSelectedMerchantId,
  } = useStore();
  const [autocompleteData, setAutocompleteData] = useState<AutoCompleteAddress[]>([]);
  const hasHighLineFlow = features?.includes(FeatureNames.DEFAULT_HIGH_LINE_FLOW);
  const isLast4ssnEnabled =
    organization?.bankPartner === 'LEAD' || features?.includes(FeatureNames.RETAIL_INSTALLMENT_CONTRACT);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [borrowerExist, setBorrowerExist] = useState<boolean>(false);
  const [requestAmountError, setRequestAmountError] = useState<boolean>(false);
  const { getSuggestions } = useAddressAutoComplete();

  const navigate = useNavigate();

  const cancelApplication = () => {
    navigate('/dashboard');
  };

  const postApplicationForm = async () => {
    try {
      const { data } = await postApplyWithPatientConsent({
        variables: {
          input: {
            organizationId: organization?.id,
            merchantId: selectedLocation?.value,
            firstName: getFormData()?.firstName,
            lastName: getFormData()?.lastName,
            dob: getFormData()?.dob,
            email: getFormData()?.email,
            phone: getFormData()?.phone,
            last4ssn: getFormData()?.last4ssn,
            statedIncome: getFormData()?.statedIncome,
            housingPayment: getFormData()?.housingPayment,
            requestedAmount: getFormData()?.requestedAmount,
            address: {
              aptUnitAddress: getFormData()?.aptUnitAddress,
              streetAddress: getFormData()?.streetAddress,
              city: getFormData()?.city,
              state: getFormData()?.state,
              zip: getFormData()?.zip,
            },
          },
        },
      });
      setSubmittedApplyWithPatientData(getFormData());
      setSelectedMerchantId(selectedLocation?.value);
      return data?.postApplyWithPatientConsent;
    } catch (err) {
      console.log(err);
    }
  };

  const textPatientForReview = async () => {
    const resp = await postApplicationForm();
    if (resp?.status === 'INITIALIZED') {
      await setApplyWithPatientFormData(resp);
      setStep('loading');
    }
  };

  const setAddressValues = (selectedAddress: ISelectedAddress) => {
    const { street, city, state, zip } = selectedAddress || {};

    if (selectedAddress) {
      setValue('streetAddress', street, { shouldValidate: true });
      setValue('city', city, { shouldValidate: true });
      setValue('state', state, { shouldValidate: true });
      setValue('zip', zip, { shouldValidate: true });
    }
  };

  const trim = (value: string) => !!value.trim();

  const handlePhoneInputBlur = async () => {
    segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_PHONE_FILLED`, {
      withInput: true,
      isFilledEvent: true,
    });
    if (!organization?.isDemo && APP_ENV !== 'sandbox') {
      const unformattedPhone = getFormData()?.phone?.replace(/[^\d]/g, '');
      try {
        if (unformattedPhone?.length === 10) {
          const { data } = await checkEligibilityForApplyWithPatient({
            variables: { input: { phone: unformattedPhone } },
          });
          if (!data?.checkEligibilityForApplyWithPatient?.isEligible) {
            setBorrowerExist(true);
            setAlreadyExistingBorrower(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const textPatient = async () => {
    const unformattedPhone = getFormData()?.phone?.replace(/[^\d]/g, '');

    await sendOrganizationLink({
      variables: { input: { idOrganization: organization?.idOrganization, phone: unformattedPhone } },
    });
    alert.success('Application link has been sent successfully.');
  };
  function currencyStringToNumber(currencyString) {
    const numericString = currencyString.replace(/[^0-9-]+/g, '');
    return Number(numericString);
  }

  const handleRequestAmountBlur = (event) => {
    segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_TREATMENT_COST_FILLED`, {
      withInput: true,
      isFilledEvent: true,
    });
    const amount = currencyStringToNumber(event?.target?.value);
    if (amount > MAX_ELIGIBLE_REQUEST_AMOUNT) {
      setRequestAmountError(true);
    } else {
      setRequestAmountError(false);
    }
  };
  // end of functions

  useEffect(() => {
    return () => {
      clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationOptions = merchants?.map((location) => ({
    value: location?.id,
    label: `<b><span>${location?.name}</span> </b><br/>  ${location?.address?.address1} - ${location?.address?.city?.name} `,
    emailLabel: `${location?.name} - ${location?.address?.city?.name} `,
  }));

  useEffect(() => {
    if (!selectedLocation?.value && merchants?.length === 1) {
      const singleOption = {
        value: merchants[0]?.id,
        label: merchants[0]?.name,
        emailLabel: `${merchants[0]?.name} - ${merchants[0]?.address?.city?.name}`,
      };
      setSelectedLocation(singleOption);
    }
  }, [merchants]);

  const handleAddressChange = (event, newValue) => {
    setAddressValues(newValue);
  };

  return (
    <FormContainer>
      <ModalV2 customFooter={null} open={borrowerExist} title="Patient Already Has a Cherry Account" width={800}>
        <ExistingBorrowerModal>
          Because this patient already has an account with Cherry, they will need to apply on their own.
          <ColumnButtonContainer>
            <Button fullWidth={true} onClick={textPatient} id="existing-patient-text-button">
              Text Application Link to {getFormData().phone}
            </Button>
            <Button
              fullWidth={true}
              onClick={cancelApplication}
              id="existing-patient-return-button"
              variant="secondary"
            >
              Return to Dashboard
            </Button>
          </ColumnButtonContainer>
        </ExistingBorrowerModal>
      </ModalV2>
      {/* SECTION 1: Where is the patient applying? */}
      {locationOptions && locationOptions?.length > 1 && (
        <InnerFormContainer>
          <Heading level="3" text="Where is the patient applying?" />
          <DropDownBorder>
            <DropDown
              ignoreInitialFirstItem={true}
              placeholder="Please select a location"
              options={locationOptions}
              defaultValue={selectedLocation?.value}
              onChange={setSelectedLocation}
              hoveredMode={false}
            />
          </DropDownBorder>
        </InnerFormContainer>
      )}
      {/* SECTION : Patient Details */}
      <InnerFormContainer>
        <Heading level="3" text="Patient Details" />
        <SplitRow>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true, validate: trim }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextInput
                className="w-full"
                id="apply-with-patient-first-name"
                name="firstName"
                inputProps={{
                  'data-testid': 'firsName',
                  'data-neuro-label': 'firstName',
                }}
                type="text"
                label="Patient First Name"
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_FIRST_NAME_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_FIRST_NAME_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true, validate: trim }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextInput
                className="w-full"
                id="apply-with-patient-last-name"
                name="lastName"
                inputProps={{
                  'data-testid': 'lastName',
                  'data-neuro-label': 'lastName',
                }}
                type="text"
                label="Patient Last Name"
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_LAST_NAME_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_LAST_NAME_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
              />
            )}
          />
        </SplitRow>
        <Controller
          name="dob"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            maxLength: 10,
            validate: validateBirthDate,
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DateInput
              id="apply-with-patient-dob"
              name="dob"
              inputProps={{
                'data-testid': 'dob',
                'data-neuro-label': 'dob',
              }}
              type="tel"
              label="Patient Date of Birth (mm/dd/yyyy)"
              value={value}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_BIRTH_DATE_SELECTED`)}
              onChange={onChange}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_BIRTH_DATE_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              error={!!error}
              helperText={error?.message}
              autoComplete="off"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          defaultValue={''}
          rules={{ required: true, pattern: phonePattern }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PhoneNumberInput
              inputProps={{
                'data-testid': 'phone',
                'data-neuro-label': 'phone',
              }}
              label="Phone"
              hideIcon={true}
              id="apply-with-patient-phone"
              name="phone"
              data-testid="phone"
              data-neuro-label="phone"
              type="tel"
              value={value}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_PHONE_SELECTED`)}
              onChange={onChange}
              onBlur={handlePhoneInputBlur}
              error={!!error}
              helperText={error ? error?.message : null}
              autoComplete="off"
            />
          )}
        />
        <SmallText margin="-4px 0 8px 0">
          <Icon src="info_circle_outline" width={15} height={15} m="0 3px -3px 0" />
          This phone number must be able to receive SMS messages
        </SmallText>
        {hasHighLineFlow && (
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid e-mail address (ex:name@mail.com)',
              },
            }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <EmailInput
                hideIcon={true}
                id="email-input"
                name="email"
                inputProps={{
                  'data-testid': 'email',
                  'data-neuro-label': 'email',
                }}
                label="Patient Email"
                type="email"
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_EMAIL_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_EMAIL_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                errorText={error?.message}
                autoComplete="off"
              />
            )}
          />
        )}
        <Controller
          name="last4ssn"
          control={control}
          rules={{
            required: true,
            minLength: isLast4ssnEnabled ? 4 : 11,
            maxLength: 11,
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <CherryTextField
                id="apply-with-patient-last4ssn"
                variant="filled"
                data-testid="last4ssn"
                name={isLast4ssnEnabled ? 'last4ssn' : 'ssn'}
                data-neuro-label="last4ssn"
                type="tel"
                label={isLast4ssnEnabled ? 'Last four digits of SSN or ITIN' : 'Social Security Number or ITIN'}
                InputProps={{
                  inputComponent: SsnInput,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_SSN_SELECTED`)}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_SSN_FILLED`, {
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
              />
            );
          }}
        />
        <Controller
          name="ssnAgain"
          control={control}
          rules={{
            required: true,
            minLength: isLast4ssnEnabled ? 4 : 11,
            maxLength: 11,
            validate: (val) => {
              if (val !== getFormData()?.last4ssn) {
                return 'Social Security Numbers should match!';
              }
              return true;
            },
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <CherryTextField
                autoComplete="off"
                id="apply-with-patient-ssn-confirm"
                variant="filled"
                name={isLast4ssnEnabled ? 'last4ssn' : 'ssn'}
                data-testid="ssnAgain"
                data-neuro-label="ssnAgain"
                type="tel"
                label={
                  isLast4ssnEnabled
                    ? 'Confirm Last four digits of SSN or ITIN'
                    : 'Confirm Social Security Number or ITIN'
                }
                InputProps={{
                  inputComponent: SsnInput,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_CONFIRM_SSN_SELECTED`)}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_CONFIRM_SSN_FILLED`, {
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error?.message}
              />
            );
          }}
        />
      </InnerFormContainer>

      {/* SECTION 3: Patient Address */}
      <InnerFormContainer>
        <Heading level="3" text="Patient Address" />
        <Controller
          name="streetAddress"
          control={control}
          rules={{ required: true, validate: trim }}
          defaultValue={''}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              options={autocompleteData}
              value={value}
              onChange={handleAddressChange}
              id="disable-clearable"
              disableClearable={true}
              // tslint:disable-next-line: jsx-no-lambda
              renderInput={(params) => {
                delete params.inputProps.className;
                return (
                  <CherryTextField
                    {...params}
                    id="apply-with-patient-street-address"
                    variant="filled"
                    name="streetAddress"
                    data-testid="streetAddress"
                    label="Street Address"
                    value={value}
                    onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_STREET_ADDRESS_SELECTED`)}
                    onChange={(e) => {
                      getSuggestions(e?.target?.value, setAutocompleteData);
                      onChange(e?.target?.value);
                    }}
                    onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_STREET_ADDRESS_FILLED`, {
                      withInput: true,
                      isFilledEvent: true,
                    })}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  />
                );
              }}
            />
          )}
        />
        <Controller
          name="aptUnitAddress"
          control={control}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextInput
              name="aptUnitAddress"
              id="apply-with-patient-apt-unit"
              data-testid="aptUnitAddress"
              label="Apt / Unit (Optional)"
              value={value}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_APT_UNIT_SELECTED`)}
              onChange={onChange}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_APT_UNIT_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              error={!!error}
              helperText={error ? error?.message : null}
              autoComplete="off"
            />
          )}
        />

        <SplitRow>
          <Controller
            name="city"
            control={control}
            rules={{ required: true, validate: trim }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextInput
                id="apply-with-patient-city-address"
                name="city"
                data-testid="city"
                data-name="city"
                label="City"
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_CITY_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_CITY_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error ? error?.message : null}
                autoComplete="off"
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CherryTextField
                id="state"
                variant="filled"
                name="state"
                data-testid="state"
                data-name="state"
                label="State"
                select={true}
                value={value}
                style={{ width: '100%', height: '48px' }}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_STATE_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_STATE_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error ? error?.message : null}
              >
                {US_STATES.map((option) => (
                  <MenuItem data-testid="stateItem" key={option?.value} value={option?.value}>
                    {option?.name}
                  </MenuItem>
                ))}
              </CherryTextField>
            )}
          />
          <Controller
            name="zip"
            control={control}
            rules={{ required: true, maxLength: 5, minLength: 5 }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextInput
                id="apply-with-patient-zip-code"
                name="zip"
                data-testid="zip"
                data-name="zip"
                type="tel"
                inputProps={{
                  maxLength: 5,
                }}
                label="ZIP Code"
                value={value}
                onKeyPress={(event) => {
                  if (!numericPattern.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_ZIP_CODE_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_ZIP_CODE_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error ? error?.message : null}
                autoComplete="off"
              />
            )}
          />
        </SplitRow>
      </InnerFormContainer>

      {/* SECTION 4: Patient Finances */}
      {hasHighLineFlow && (
        <InnerFormContainer>
          <Heading level="3" text="Patient Finances" />
          <Controller
            name="statedIncome"
            control={control}
            defaultValue=""
            rules={{ required: true, min: 1, max: 1000000 }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CurrencyInput
                label={
                  isOrganizationDental(organization?.industry) ? 'Annual Household Income' : 'Annual Individual Income'
                }
                inputProps={{
                  'data-testid': 'statedIncome',
                  'data-neuro-label': 'statedIncome',
                }}
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_INCOME_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_INCOME_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                error={!!error}
                helperText={error ? error?.message : null}
                autoComplete="off"
              />
            )}
          />
          <SmallText margin="-4px 0 8px 0">
            <Icon src="info_circle_outline" width={15} height={15} m="0 3px -3px 0" />
            {isOrganizationDental(organization?.industry) ? (
              <>
                This is their annual household income <b>before</b> taxes. This includes income from other people in
                their household.
              </>
            ) : (
              <>
                This is their annual individual income <b>before</b> taxes. This does not include income from other
                people in their household.
              </>
            )}
          </SmallText>

          <Controller
            name="housingPayment"
            control={control}
            defaultValue=""
            rules={{ required: true, min: 1, max: 1000000 }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CurrencyInput
                id="housingPayment"
                label="Monthly Housing Cost"
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_HOUSING_COST_SELECTED`)}
                onChange={onChange}
                onBlur={segmentEventHandler(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MODAL_HOUSING_COST_FILLED`, {
                  withInput: true,
                  isFilledEvent: true,
                })}
                inputProps={{
                  'data-testid': 'housingPayment',
                  'data-neuro-label': 'housingPayment',
                }}
                value={value}
                error={!!error}
                helperText={error ? error?.message : null}
                autoComplete="off"
              />
            )}
          />
          <SmallText margin="-4px 0 8px 0">
            <Icon src="info_circle_outline" width={15} height={15} m="0 3px -3px 0" />
            This is their monthly rent or mortgage payment.
          </SmallText>

          <Controller
            name="requestedAmount"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              min: 0,
              max: MAX_ELIGIBLE_REQUEST_AMOUNT,
            }}
            // tslint:disable-next-line: jsx-no-lambda
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CurrencyInput
                id="requestedAmount"
                name="requestedAmount"
                inputProps={{
                  'data-testid': 'requestedAmount',
                  'data-neuro-label': 'requestedAmount',
                }}
                type="tel"
                label="Estimated Treatment Cost"
                value={value}
                onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_TREATMENT_COST_SELECTED`)}
                onChange={onChange}
                onBlur={handleRequestAmountBlur}
                error={requestAmountError}
                errorText={
                  requestAmountError
                    ? 'Cherry’s maximum approval amount with your practice is $35,000.00. Please input an amount less than or equal to the maximum.'
                    : undefined
                }
                autoComplete="off"
              />
            )}
          />
          <SmallText margin="-4px 0 8px 0">
            <Icon src="info_circle_outline" width={15} height={15} m="0 3px -3px 0" />
            We will try to approve the patient for this amount so that all treatment is covered.
          </SmallText>
        </InnerFormContainer>
      )}

      <ApplicationButtonContainer>
        <Button variant="secondary" onClick={cancelApplication}>
          Cancel Application
        </Button>
        <Button disabled={!isValid} onClick={textPatientForReview}>
          Text Patient Link to Review & Confirm
        </Button>
      </ApplicationButtonContainer>
    </FormContainer>
  );
};

const SmallText = styled.div<{ margin?: string; isGray?: boolean }>`
  margin: ${(props) => props?.margin || '24px 0 12px 0'};
  color: ${(props) => (props.isGray ? props.theme.main.grayColor : props.theme.main.midnightBlue)};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export default Form;
