import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components/mobile';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import {
  FormProps,
  GenerateTreatmentPlanForm,
} from 'pages/desktop/Dashboard/views/TakeHomeApproval/GenerateTreatmentPlanForm';
import { GenerateTreatmentPlanPreview } from 'pages/desktop/Dashboard/views/TakeHomeApproval/GenerateTreatmentPlanPreview';
import { CherryDialogTitle } from 'pages/desktop/Settings/components/Dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  balanceAvailable: number;
  selectedFirstName: string;
  selectedLastName: string;
  selectedPhone: string;
  selectedApplicationId: string;
}

enum Steps {
  FORM = 'FORM',
  PREVIEW = 'PREVIEW',
}

export const TakeHomeApprovalModal = ({
  onClose,
  open,
  balanceAvailable,
  selectedFirstName,
  selectedLastName,
  selectedPhone,
  selectedApplicationId,
}: Props) => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { user } = useStore();

  const [activeStep, setActiveStep] = useState<Steps>(Steps.FORM);
  const [formData, setFormData] = useState<FormProps | null>(null);

  useEffect(() => {
    return () => {
      setActiveStep(Steps.FORM);
      setFormData(null);
    };
  }, [open]);

  const saveAndContinue = (values: FormProps) => {
    setFormData(values);
    setActiveStep(Steps.PREVIEW);
  };

  const setActiveStepAsForm = () => {
    setActiveStep(Steps.FORM);
  };

  const handleClose = () => {
    onClose();
  };

  const preparePDFData = () => {
    const {
      selectedLocation,
      patientName,
      patientSurname,
      patientPhone,
      patientEmail,
      additionalNotes,
      treatmentCoordinator,
      treatmentType,
      treatmentDate,
      amount,
      loanId,
    } = formData || {};

    trackSegmentEvent('Treatment Plan Form Data', {
      application: applicationName,
      component: 'Treatment Plan',
      merchantId: selectedLocation?.value,
      userId: `u-${user?.id}`,
      applicationId: selectedApplicationId,
      ...formData,
    });

    return {
      selectedLocation,
      patientName,
      patientSurname,
      patientPhone,
      patientEmail,
      additionalNotes,
      treatmentCoordinator,
      treatmentType,
      treatmentDate,
      amount,
      loanId,
    };
  };

  return (
    <CherryDialog
      width={activeStep === Steps.FORM ? '705px' : '1100px'}
      maxWidth={false}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {activeStep === Steps.FORM ? null : (
            <BackContainer onClick={setActiveStepAsForm}>
              <Icon src="short_left" width={24} height={24} />
            </BackContainer>
          )}
          {activeStep === Steps.FORM ? 'Create Treatment Plan' : 'Send Treatment Plan'}
        </CherryDialogTitle>

        {activeStep === Steps.FORM ? (
          <GenerateTreatmentPlanForm
            proceedToPreviewStep={saveAndContinue}
            onClose={onClose}
            initialFormData={formData}
            selectedFirstName={selectedFirstName}
            selectedLastName={selectedLastName}
            selectedPhone={selectedPhone}
            balanceAvailable={balanceAvailable}
            selectedApplicationId={selectedApplicationId}
          />
        ) : (
          <GenerateTreatmentPlanPreview
            selectedApplicationId={selectedApplicationId}
            previewData={preparePDFData()}
            goBackToFormStep={setActiveStepAsForm}
          />
        )}
      </DialogContainer>
    </CherryDialog>
  );
};

const CherryDialog = styled(Dialog)<{ width: string }>`
  .MuiDialog-paperScrollPaper {
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
  }
`;

const BackContainer = styled.div`
  width: 10px;
  position: absolute;
  cursor: pointer;
`;

const DialogContainer = styled.div`
  padding: 24px;
  min-width: 420px;
`;

export const DropDownBorder = styled.div`
  width: 100%;
  margin: 24px 0;
  border: 1px solid ${(props) => props.theme.main.dropDownBorder};
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;

  li {
    font-size: 14px;
  }

  svg {
    color: #00c37c;
  }
`;
