import { FormControl, Grid, InputAdornment } from '@mui/material';
import React, { BaseSyntheticEvent, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { Loading, TableLoader } from 'lib/components';
import { Container, ContentContainer, Icon, TextField, Title } from 'lib/components/mobile';
import { CalendarIcon, ExpiringIcon, SearchIcon } from 'lib/components/mobile/SidebarIcons';
import useSnackbar from 'lib/hooks/useSnackbar';
import { useMobileApplicationListTable } from 'lib/tables/MobileApplicationListTable';
import { MobileDataTableStyle } from 'lib/utils';
import { useApplicationFilter } from 'lib/services';

const MobileDashboard = () => {
  const [page, setPage] = useState(0);
  const {
    approvals,
    loading,
    paginationLoading,
    getNewTodayApplications,
    getExpiringSoonApplications,
    getApplications,
    total = 0,
    isExpiringTodayEnabled,
    isNewApplicationEnabled,
  } = useApplicationFilter();
  const { columns } = useMobileApplicationListTable();
  const { isSnackbarVisible } = useSnackbar();

  const onSearchTextChange = async (e: BaseSyntheticEvent) => {
    const searchKey = e?.target?.value || '';
    if (searchKey?.length > 2) {
      return await getApplications({ searchKey, limit: 20, offset: 0, mobilePagination: false });
    } else {
      return await getApplications({ limit: 20, offset: 0, mobilePagination: false });
    }
  };

  const increasePageCount = async () => {
    if (total / 20 > page) {
      setPage(page + 1);
      await getApplications({ limit: 20, mobilePagination: true, offset: page + 1 });
    }
  };

  return (
    <Container pageTitle="Applicants">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title>Common Searches</Title>
        <SearchBoxContainer>
          <SearchBoxItem isActive={isNewApplicationEnabled} onClick={getNewTodayApplications}>
            <CalendarIcon isNewApplicationEnabled={isNewApplicationEnabled} />
            <SearchBoxText isActive={isNewApplicationEnabled}>New Today</SearchBoxText>
          </SearchBoxItem>
          <SearchBoxItem isActive={isExpiringTodayEnabled} onClick={getExpiringSoonApplications}>
            <ExpiringIcon isExpiringTodayEnabled={isExpiringTodayEnabled} />
            <SearchBoxText isActive={isExpiringTodayEnabled}>Expiring Soon</SearchBoxText>
          </SearchBoxItem>
        </SearchBoxContainer>
        <Title>All Applicants</Title>
        <FormControlQuery variant="standard">
          <TextField
            style={{ marginTop: '16px', marginBottom: '16px' }}
            placeholder="Search by Customer Name, Phone"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={onSearchTextChange}
          />
        </FormControlQuery>
        <Grid>
          <DataTable
            className="data-table"
            customStyles={MobileDataTableStyle}
            pointerOnHover={true}
            keyField={'applicationId'}
            noHeader={true}
            columns={columns}
            data={approvals}
            pagination={false}
            paginationServer={false}
            progressComponent={<TableLoader count={4} />}
            expandOnRowClicked={true}
            style={{ overflowX: 'hidden' }}
          />
        </Grid>
        {loading && (
          <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Loading />
          </Grid>
        )}
        {!loading && !paginationLoading && Boolean(total) && total > approvals?.length && (
          <Grid marginTop={3} marginBottom={12} alignItems={'center'} display={'flex'} justifyContent={'center'}>
            <ShowMoreButton onClick={increasePageCount}>
              Show More <Icon src={'down-caret'} width={6} height={6} m={'0 0 0 12px'} />
            </ShowMoreButton>
          </Grid>
        )}
      </ContentContainer>
    </Container>
  );
};
export default MobileDashboard;

const FormControlQuery = styled(FormControl)`
  width: 100%;
  fieldset {
    border: 1px solid #879097;
    border-radius: 4px;
  }
`;

const SearchBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  filter: drop-shadow(0px 6px 9px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035));
  margin-top: 8px;
  margin-bottom: 40px;
`;

const SearchBoxItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;
  width: 120px;
  height: 80px;
  background: ${({ theme, isActive }) => (isActive ? theme.main.green : theme.main.white)};
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 8px;
`;

const SearchBoxText = styled.p<{ isActive: boolean }>`
  max-width: 60px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${({ theme, isActive }) => (isActive ? theme.main.white : theme.main.midnightBlue)};
  text-align: center;
  margin: 0px;
`;

const ShowMoreButton = styled.button`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #00c37d;
  border: none;
  background-color: transparent;
`;
