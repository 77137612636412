import { Grid } from '@mui/material';
import React from 'react';

import { CancelLoanButton, SecondaryButton } from 'lib/components';
import { Center, ResultContainer, TextH5, TextSecondary } from './Common';

export const CheckoutCancel = ({ updatedLoan, closeExpanded, processCancelRequest }) => {
  return (
    <Center>
      <ResultContainer>
        <TextH5>Are you sure you want to cancel this payment plan?</TextH5>
        <TextSecondary>
          {updatedLoan?.borrower?.firstName}'s approval will be reinstated and you can issue a new payment plan at a
          later time.
        </TextSecondary>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4}>
            <div>
              <CancelLoanButton
                onClick={closeExpanded}
                variant={'contained'}
                data-tag="confirm-cancel"
                color={'#ec3360'}
              >
                Close
              </CancelLoanButton>
            </div>
          </Grid>
          <Grid item={true} xs={8}>
            <SecondaryButton data-testid="checkout-cancel-link-apply" onClick={processCancelRequest} variant="filled">
              Yes, cancel this plan
            </SecondaryButton>
          </Grid>
        </Grid>
      </ResultContainer>
    </Center>
  );
};
