import React, { useState } from 'react';

import {
  DownCircle,
  PopoverContainer,
  PopoverContent,
  PopoverText,
  StatusContainer,
  StatusText,
  StatusTextContainer,
} from './';
import { Icon } from 'lib/components/mobile';

interface Props {
  row: any;
  type: string;
  label: string;
  backgroundColor: string;
  hoverText: string;
  ternary?: boolean;
  secondary?: boolean;
  left?: number;
  circleLeft?: string;
  iconName?: string;
  iconWidth?: number;
  iconHeight?: number;
}

const Popover = ({
  row,
  type,
  label,
  backgroundColor,
  hoverText,
  ternary,
  secondary,
  left,
  iconName,
  iconWidth,
  iconHeight,
  circleLeft,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState<string | null>(null);

  const handlePopoverOpen = (event) => {
    setPopoverId(event.currentTarget.id);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverOpen(false);
  };

  return (
    <StatusContainer>
      <StatusTextContainer
        id={`${type}_${row.applicationId}`}
        backgroundColor={backgroundColor}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <StatusText>
          {label}
          {!!iconName && (
            <Icon
              src={iconName}
              width={iconWidth ? iconWidth : 15}
              height={iconHeight ? iconHeight : 15}
              m="0 0 0 5px"
            />
          )}
        </StatusText>
      </StatusTextContainer>
      {popoverOpen && popoverId === `${type}_${row.applicationId}` && (
        <PopoverContainer ternary={ternary} secondary={secondary} left={left}>
          <PopoverContent>
            <PopoverText>{hoverText}</PopoverText>
          </PopoverContent>
          <DownCircle circleLeft={circleLeft} secondary={true} />
        </PopoverContainer>
      )}
    </StatusContainer>
  );
};

export default Popover;
