export enum GenericSearchOperators {
  BIGGER_THAN = '>',
  LOWER_THAN = '<',
  EQUAL = ':',
  IN = '±',
  NOT_IN = '!±',
  LIKE = '~',
  EQUAL_OR_GREATER_THAN = '≥',
  EQUAL_OR_LESS_THAN = '≤',
}
