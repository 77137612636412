import { Button, Heading } from '@frontend/cherry-library';
import { ReactComponent as ExpandIcon } from 'assets/images/search_small_plus.svg';
import { ModalV2 } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import { useState } from 'react';
import { ExpandPdfModal, Pdf, PdfContainer, PdfModalWrapper } from '../styles';
import { PdfProps } from '../types';
import { TreatmentPlanPdf } from './TreatmentPlanPdf';

const EstimatePreview = ({ patient, plan, isTableLoading }: PdfProps) => {
  const [showExpanded, setShowExpanded] = useState<boolean>(false);
  const { segmentEventHandler } = useSegment();

  const handleToggleModal = () => {
    if (!showExpanded) {
      segmentEventHandler('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.EXPAND_BUTTON.CLICKED');
    }
    setShowExpanded(!showExpanded);
  };
  return (
    <PdfContainer>
      <ModalV2
        showClose={true}
        onClose={handleToggleModal}
        onCancel={handleToggleModal}
        customFooter={null}
        open={showExpanded}
        title="Preview Estimate"
        width={650}
        hideCancelButton={true}
      >
        <PdfModalWrapper>
          <ExpandPdfModal>
            <Pdf isModal={true}>
              <TreatmentPlanPdf isModal={true} isTableLoading={isTableLoading} patient={patient} plan={plan} />
            </Pdf>
          </ExpandPdfModal>
          <Button fullWidth={true} onClick={handleToggleModal}>
            Close Preview
          </Button>
        </PdfModalWrapper>
      </ModalV2>
      <Heading level="5" text="Preview Estimate" />
      <Pdf isModal={false} onClick={handleToggleModal}>
        <TreatmentPlanPdf isTableLoading={isTableLoading} patient={patient} plan={plan} />
      </Pdf>
      <Button fullWidth={true} onClick={handleToggleModal} variant="secondary" leftIcon={ExpandIcon}>
        Expand Preview
      </Button>
    </PdfContainer>
  );
};

export default EstimatePreview;
