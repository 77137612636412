import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface Props extends NumericFormatProps {
  amount: number | undefined;
  fixedDecimalScale?: boolean;
}

export const FixedDecimalFormat = ({ amount, fixedDecimalScale = true, ...rest }: Props) => (
  <NumericFormat
    value={amount || 0}
    decimalScale={2}
    displayType={'text'}
    thousandSeparator={true}
    fixedDecimalScale={fixedDecimalScale}
    {...rest}
  />
);
