import React from 'react';

import { ApolloProvider } from '@apollo/client';
import Cohere from 'cohere-js';
import { positions, Provider as AlertProvider } from 'react-alert';
import ReactGA from 'react-ga';
import styled, { ThemeProvider } from 'styled-components';

import { AlertTemplate } from 'lib/components';
import ApolloClient from 'lib/graphql/client';
import { EventBusProvider } from 'lib/services';
import { MonthlyPaymentOptionsProvider } from 'lib/services/useMonthlyPaymentOptions';
import { NotificationCenterProvider } from 'lib/services/useNotificationCenter';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import Router from 'routes/Route';
import { APP_ENV } from './config';
import { theme } from './config/theme';
import { browserIsMobile } from './lib/utils/BrowserIsMobile';

if (['master'].includes(APP_ENV as string)) {
  Cohere.init('oO81KUXNVQxo0_axcBZtIFk0');
}

ReactGA.initialize('UA-144188899-4');

const alertOptions = {
  timeout: 5000,
  position: browserIsMobile() ? positions.TOP_CENTER : positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 99999,
    marginBottom: browserIsMobile() ? '0px' : '50px',
    marginTop: browserIsMobile() ? '50px' : '0px',
    pointerEvents: 'initial',
    borderRadius: '4px',
  },
};

function App() {
  return (
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      Components={{ success: StyledMaterialDesignContent }}
    >
      <EventBusProvider>
        <BrowserRouter>
          <NotificationCenterProvider>
            <ApolloProvider client={ApolloClient}>
              <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                  <MonthlyPaymentOptionsProvider>
                    <Router />
                  </MonthlyPaymentOptionsProvider>
                </AlertProvider>
              </ThemeProvider>
            </ApolloProvider>
          </NotificationCenterProvider>
        </BrowserRouter>
      </EventBusProvider>
    </SnackbarProvider>
  );
}

export default App;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.main.green,
  },
}));
