export enum StepEnums {
  LOADING = 'LOADING',
  CHECKOUT_FORM = 'CHECKOUT_FORM',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  CHECKOUT_EDIT_SUCCESS = 'CHECKOUT_EDIT_SUCCESS',
  CHECKOUT_CANCEL = 'CHECKOUT_CANCEL',
  CHECKOUT_SUCCESS_CANCEL = 'CHECKOUT_SUCCESS_CANCEL',
  ISSUE_ANOTHER_PLAN = 'ISSUE_ANOTHER_PLAN',
}

export enum RefundStepEnums {
  LOADING = 'LOADING',
  ISSUE_REFUND = 'ISSUE_REFUND',
  ISSUE_PARTIAL_REFUND = 'ISSUE_PARTIAL_REFUND',
  ISSUE_REFUND_APPROVE = 'ISSUE_REFUND_APPROVE',
  CANCEL_REFUND = 'CANCEL_REFUND',
  VOID_TRANSACTION = 'VOID_TRANSACTION',
}

export enum UserManagementStepEnums {
  LOADING = 'LOADING',
  ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER',
  ASSIGN_ROLE = 'ASSIGN_ROLE',
  LOCATION_ACCESS = 'LOCATION_ACCESS',
}

export enum LocationManagementStepEnums {
  LOADING = 'LOADING',
  POINT_OF_CONTACT = 'POINT_OF_CONTACT',
  ADD_USER = 'ADD_USER',
  ADD_PHONE_NUMBER = 'ADD_PHONE_NUMBER',
  LOCATION_ADDRESS = 'LOCATION_ADDRESS',
  SELECT_BANK = 'SELECT_BANK',
  ADD_BANK = 'ADD_BANK',
  REVIEW = 'REVIEW',
  SUCCESS = 'SUCCESS',
}

export enum PreApprovalStepEnums {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  DENIAL = 'DENIAL',
  INFORMATION = 'INFORMATION',
  SENT = 'SENT',
  APPLICATION_ALREADY_EXIST = 'APPLICATION_ALREADY_EXIST',
}

export enum CsvDownloadEnums {
  CONFIRM_DOWNLOAD = 'CONFIRM_DOWNLOAD',
  EXCEED_LIMIT = 'EXCEED_LIMIT',
  FILE_READY = 'FILE_READY',
  LOADING = 'LOADING',
}

export enum BulkSmsEnums {
  ADD_RECIPIENTS = 'ADD_RECIPIENTS',
  CSV_UPLOAD_SUCCESS = 'CSV_UPLOAD_SUCCESS',
  BULK_SMS_SUCCESS = 'BULK_SMS_SUCCESS',
}

export interface TransactionCSVItem {
  loanId: string;
  createdAt: string;
  borrowerName: string;
  phone: string;
  amount: string;
  refunds: any[];
  fee: string;
  merchantFund: string;
  disbursement: string;
  transactionType: string;
  merchantId: any;
  product: any;
}

export interface CustomerPlansCSVItem {
  planId: number;
  name: string;
  planType: string;
  memberSince: string;
  paymentStatus: string | undefined;
}

export type Truthy = boolean | string;

export interface DropdownOption {
  label: string;
  value: string;
  [key: string]: any;
}

export interface DisbursementRowProps {
  disbursement: string;
  subStatus?: string;
}

export const FinancingStatusMap = {
  APPROVED: 'Approved',
  DENIED: 'Denied',
  REVIEW: 'Under Cherry Review',
  AWAITING_APPROVAL_BOOST: 'Patient Info Required',
  AWAITING_DEFAULT_HIGH_LINE: 'Patient Info Required',
  AWAITING_DOCS: 'Patient Info Required',
  AWAITING_FRAUD: 'Patient Info Required',
  AWAITING_KYC: 'Patient Info Required',
  FROZEN: 'Patient Info Required',
  INCOME_VERIFICATION: 'Patient Info Required',
  INITIALIZED: 'Patient Info Required',
  PREAPPROVED: 'Pre-Approved',
};
