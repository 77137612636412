import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput, theme } from '@frontend/cherry-library';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
interface Props {
  selectedColor: any;
  defaultColor: string;
  disabled?: boolean;
}

export const CherryColorPicker = ({ selectedColor, defaultColor, disabled = false }: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<any>({ hex: defaultColor });

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleChange = (e) => {
    setColor(e?.hex);
    selectedColor(e?.hex);
  };

  const handleClick = () => {
    if (disabled) return;
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <PickerContainer>
      <InputWrapper onClick={handleClick} style={{ cursor: 'pointer' }}>
        <TextInput
          disabled={disabled}
          type="text"
          value={defaultColor ? defaultColor : color}
          rightElement={<InputIcon icon={faChevronDown} size="sm" color={theme.main.midnightBlue} />}
        />
      </InputWrapper>
      {displayColorPicker && (
        <InlinePickerContainer>
          <OverlayContainer onClick={handleClose} />
          <CustomSketchPicker color={color} onChange={handleChange} alpha={false} />
        </InlinePickerContainer>
      )}
    </PickerContainer>
  );
};

const CustomSketchPicker = styled(SketchPicker)`
  &.sketch-picker div:nth-child(4) {
    display: none !important;
  }
`;

const PickerContainer = styled.div`
  position: relative;
`;

const InlinePickerContainer = styled.div`
  position: absolute;
  z-index: 2;
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const InputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
