import { gql } from '@apollo/client/core';

export const SYNC_APPOINTMENTS = gql`
  mutation syncAppointments($input: AppointmentSyncStatusRequest) {
    syncAppointments(input: $input) {
      syncedBy
      syncedAt
    }
  }
`;
