import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { CloseIcon, PageHeader, Refund, TableLoader } from 'lib/components';
import {
  AlertDialog,
  CherryDialogActions,
  CherryDialogContent,
  CherryDialogContentText,
  CherryDialogTitle,
  CustomActivateButton,
} from 'lib/components/Dialogue';

import useSnackbar from 'lib/hooks/useSnackbar';
import { NotificationEvent, useLastClicked, useNotificationCenter, useTransactions } from 'lib/services';
import { useParams } from 'react-router-dom';
import {
  CenterAligner,
  CherryDialogCloseIconContainer,
  MaterialRequestContainer,
  Row,
  TableColumn,
  TableRow,
} from './CommonStyles';
import { TableSearchHeader } from './views/TableSearchHeader';

const Transactions = () => {
  const { isSnackbarVisible } = useSnackbar();
  const { id = '' } = useParams();
  const { onNotification } = useNotificationCenter();
  const { isLastClickedParent } = useLastClicked();

  const {
    setSelectedItem,
    refundCancelButtonHandler,
    refundApproveButtonHandler,
    fullRefundLoan,
    voidTransactionHandler,
    setExpandableOpened,
    openDialogType,
    closeAlertDialog,
    total,
    sortColumn,
    sortDirection,
    searchInput,
    setSearchInput,
    disbursementTypes,
    selectedDateTransactions,
    searchInputOnChange,
    searchInputBlur,
    onDisbursementTypeSelected,
    onDateChangeTransactions,
    checkedItems,
    dataTableRef,
    tableColumns,
    transactions,
    pageChange,
    rowsCountChange,
    loading,
    paginationLoading,
    onTableSort,
    openDialog,
    openAlertDialog,
    fetchTransactions,
  } = useTransactions();

  useEffect(() => {
    if (id) {
      setSearchInput(id);
    }

    onNotification(NotificationEvent.REFRESH_TRANSACTIONS, (data) => {
      if (data?.buttonClicked || !isLastClickedParent('transactionsTable')) {
        fetchTransactions();
      }
    });
  }, []);

  const ExpandedComponent = React.useMemo(
    () => ({ data }: any) => {
      setSelectedItem(data);
      return (
        <Refund
          refundApproveButtonHandler={refundApproveButtonHandler}
          refundCancelButtonHandler={refundCancelButtonHandler}
          voidTransactionHandler={voidTransactionHandler}
          fullRefundLoan={fullRefundLoan}
          item={data}
          setExpandableOpened={setExpandableOpened}
        />
      );
    },
    [],
  );

  const RefundEligibleModal = () => {
    const eligibleTitle = 'Refund Ineligible';
    const eligibleDescription = 'This transaction is past the 60-day refund window';
    const processTitle = openDialogType === 'initial-processing' ? 'Transaction Processing' : 'Refund is in Progress';
    const processDescription =
      openDialogType === 'initial-processing'
        ? 'This transaction has not processed and is not eligible for a refund. Please allow 24 hours for funds to clear before issuing a refund.'
        : 'This refund has not processed yet.  Please allow 24 hours for funds to clear before issuing a refund.';

    return (
      <MaterialRequestContainer>
        <CherryDialogTitle>
          <b>{openDialogType === 'ineligible' ? eligibleTitle : processTitle}</b>{' '}
          <CherryDialogCloseIconContainer onClick={closeAlertDialog}>
            <CloseIcon />
          </CherryDialogCloseIconContainer>
        </CherryDialogTitle>
        <CherryDialogContent style={{ overflow: 'unset' }}>
          <CherryDialogContentText>
            <CenterAligner style={{ margin: '24px 0', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              {openDialogType === 'ineligible' ? eligibleDescription : processDescription}
            </CenterAligner>
          </CherryDialogContentText>
        </CherryDialogContent>
        <CherryDialogActions>
          <CustomActivateButton onClick={closeAlertDialog} text={'Okay'} />
        </CherryDialogActions>
      </MaterialRequestContainer>
    );
  };

  return (
    <TransactionContainer isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Sales and Transactions" />
      <Row>
        <TableSearchHeader
          total={total}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          searchInput={searchInput}
          disbursementTypes={disbursementTypes}
          selectedDateTransactions={selectedDateTransactions}
          searchInputOnChange={searchInputOnChange}
          searchInputBlur={searchInputBlur}
          onDisbursementTypeSelected={onDisbursementTypeSelected}
          onDateChangeTransactions={onDateChangeTransactions}
          checkedItems={checkedItems}
        />
        <TransactionRow id="transactionsTable">
          <TableColumn
            ref={dataTableRef}
            style={{ padding: '0px', marginBottom: 30, marginTop: !transactions?.length ? 12 : 0 }}
          >
            <DataTable
              className="data-table"
              noHeader={true}
              columns={tableColumns}
              data={transactions}
              keyField={'id'}
              defaultSortFieldId="createdAt"
              defaultSortAsc={false}
              pagination={true}
              paginationServer={true}
              paginationTotalRows={total}
              onChangePage={pageChange}
              onChangeRowsPerPage={rowsCountChange}
              progressPending={loading || paginationLoading}
              progressComponent={<TableLoader count={4} />}
              onSort={onTableSort}
              expandableRows={true}
              expandableRowsComponent={ExpandedComponent}
            />
          </TableColumn>
        </TransactionRow>
        <AlertDialog
          open={openDialog}
          agreeClicked={openAlertDialog}
          onDisagreeClicked={closeAlertDialog}
          showActions={false}
          children={<RefundEligibleModal />}
        />
      </Row>
    </TransactionContainer>
  );
};

const TransactionRow = styled(TableRow)`
  margin-bottom: 64px;

  .data-table {
    overflow: inherit;
  }
`;

const TransactionContainer = styled.div<{ isSnackbarVisible: boolean }>`
  margin-top: ${(props) => (props.isSnackbarVisible ? '190px' : '150px')};
  padding: 0 20px;
  padding-bottom: 150px;
`;

export default Transactions;
