import { Button } from '@frontend/cherry-library';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { Column, Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

export const MerchantSupport = () => {
  const { trackEvent } = useAnalytics();

  const onChatClicked = () => {
    // const chatButton = document.querySelector('#chat--widget') as HTMLElement;
    // @ts-ignore
    window.icPatronChat?.show();
    // chatButton.click();
    trackEvent({
      action: TrainingAnalyticsEventNames.CTA_BUTTONS,
      label: 'CHAT',
    });
  };

  return (
    <>
      <SupportTitle>General Inquiries</SupportTitle>
      <Row fontSize={'14px'}>General inquiries and support questions should be directed to Merchant Support.</Row>
      <br />
      <Row>
        <Column>
          <InfoContainer>
            <Logo src={'cherry_logo_rounded.svg'} />
            <ContentContainer>
              <ContentTitle>Merchant Support</ContentTitle>
              <ContentDescription href="tel:8888397171">(888) 839-7171</ContentDescription>
              <ContentDescription href="mailto:support@withcherry.com">support@withcherry.com</ContentDescription>

              <ContentTitle>Support Hours</ContentTitle>
              <ContentDescription>Monday - Friday: 9AM - 9PM EST</ContentDescription>
              <ContentDescription>Saturday: 9AM - 6PM EST</ContentDescription>
              <ContentDescription>Sunday: Closed</ContentDescription>
            </ContentContainer>
          </InfoContainer>
        </Column>
        <StyledColumn>
          <Button data-testid="scheduleMeetingButton" onClick={onChatClicked}>
            Chat Online
          </Button>
        </StyledColumn>
      </Row>
    </>
  );
};

const StyledColumn = styled(Column)`
  align-items: flex-end;

  button {
    width: 140px;
    align-self: flex-end;
  }
`;

const SupportTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.textColor};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

const ContentTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  margin-bottom: 3px;
  margin-top: 8px;
`;

const ContentDescription = styled.a`
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
  margin-bottom: 3px;
`;

const Logo = styled.img``;
