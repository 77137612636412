import dayjs from 'dayjs';
import { PaymentMethod } from './types';

export const filterPaymentMethods = (
  paymentMethods: PaymentMethod[],
  hasCreditCardPaymentActive: boolean,
  hasAchPayment = false,
) => {
  return paymentMethods?.filter((pm) => {
    if (pm?.type === 'CARD') {
      if (hasCreditCardPaymentActive) {
        return true;
      } else {
        return pm?.storedCard?.type === 'DEBIT' || pm?.storedCard?.network === 'AMEX';
      }
    } else {
      return hasAchPayment;
    }
  });
};

export const filterCardsByCreatedAt = (card) => {
  const cherryPayCreatedAt = '2022-11-17 06:00:00';
  const cardDate = dayjs(card?.createdAt);
  const filterDate = dayjs(cherryPayCreatedAt);

  if (cardDate) {
    return cardDate.isAfter(filterDate);
  }
  return true;
};

export const debitCardMethodContents = (cardList) => {
  return cardList?.filter(
    (content) =>
      content.type === 'CARD' && content.storedCard.type === 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
  );
};

export const filterDebitOrAchMethods = (paymentMethods: PaymentMethod[]) => {
  return paymentMethods?.filter((pm) => {
    if (pm?.type === 'CARD') {
      return pm?.storedCard?.type === 'DEBIT';
    } else {
      return true;
    }
  });
};

export const calculatePaymentFeeAmount = (fees, storedCardType, amount) => {
  try {
    const paymentFee = fees?.[storedCardType];
    const paymentFeeFixed = paymentFee?.fixed || 0;
    if (paymentFee?.percent) {
      return paymentFeeFixed + amount * (paymentFee.percent / 100);
    }
    return 0;
  } catch (err) {
    return 0;
  }
};
