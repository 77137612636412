import { useApolloClient, useMutation } from '@apollo/client';
import { Switch } from '@frontend/cherry-library';
import { DashCard } from 'lib/components';
import { PATCH_ORGANIZATION_ACCOUNT_INFORMATION } from 'lib/graphql/mutations';
import { GET_ORGANIZATION_ACCOUNT_INFORMATION } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column } from 'lib/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const ProcessingFeeSettings = () => {
  const client = useApolloClient();
  const [patchAccountInfo, { loading }] = useMutation(PATCH_ORGANIZATION_ACCOUNT_INFORMATION, {
    onCompleted: () => {
      getAccountInformation();
    },
  });
  const { organization } = useStore();

  const [organizationAccountId, setOrganizationAccountId] = useState<string | number>();
  const [isEnabled, setIsEnabled] = useState(false);

  const getAccountInformation = async () => {
    const { data: { fetchOrganizationAccountInformation } = {} } = await client.query({
      query: GET_ORGANIZATION_ACCOUNT_INFORMATION,
      variables: {
        input: {
          organizationId: organization?.id,
        },
      },
    });

    setIsEnabled(fetchOrganizationAccountInformation.applyFee);
    setOrganizationAccountId(fetchOrganizationAccountInformation.id);
  };

  useEffect(() => {
    getAccountInformation();
  }, []);

  const handleSwitchToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await patchAccountInfo({
      variables: {
        input: {
          organizationId: organization?.id,
          organizationAccountId,
          applyFee: e.target.checked,
        },
      },
    });
    setIsEnabled(e.target.checked);
  };

  return (
    <Column>
      <DashCard>
        <Title>Pass through card processing fees to patients?</Title>
        <Body>
          <div>
            <Content isEnabled={isEnabled}>
              Pass Through Card Fees - <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>
            </Content>
            <Description>
              If enabled, patients pay a 2.9% card processing fee plus 30 cents per transaction.
            </Description>
          </div>
          <Switch disabled={loading} isChecked={isEnabled} handleClick={handleSwitchToggle} />
        </Body>
      </DashCard>
    </Column>
  );
};

const Title = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div<{ isEnabled: boolean }>`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  > span {
    font-weight: 700;
    color: ${(props) => (props.isEnabled ? props.theme.main.green : props.theme.main.red)};
  }
`;

const Description = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
