import { queryElements, setDisplayStyle } from 'lib/utils/DocumentStyleUtils';

const CHAT_DIV_WRAP_ID = '#chat-div-wrap';
const CUSTOMER_CHAT_CLASS = '.ie-div-position-customer-chat';

const useChatButton = () => {
  const showChatButton = () => {
    const chatWrapElements = queryElements(CHAT_DIV_WRAP_ID);
    const chatButtonElements = queryElements(CUSTOMER_CHAT_CLASS);

    setDisplayStyle(chatWrapElements, 'block');
    setDisplayStyle(chatButtonElements, 'block');
  };

  const hideChatButton = () => {
    const chatWrapElements = queryElements(CHAT_DIV_WRAP_ID);
    const chatButtonElements = queryElements(CUSTOMER_CHAT_CLASS);

    setDisplayStyle(chatWrapElements, 'none');
    setDisplayStyle(chatButtonElements, 'none');
  };

  return { showChatButton, hideChatButton };
};

export default useChatButton;
