import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Icon as CherryIcon, TextInput } from '@frontend/cherry-library';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { CherryRangePicker, DropDown, DropDownWithCheckbox, InputWithIcon } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { initialStageData, initialStatusData, readyForCheckoutStageCheckboxData } from 'lib/constants/applications';
import {
  DashboardAnalyticsEventNames,
  FeatureNames,
  ScreenNames,
  useAnalytics,
  useDebounce,
  usePermission,
} from 'lib/hooks';
import useDidMountEffect from 'lib/hooks/useDidMountEffect';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useApplicationFilter, useEventBus } from 'lib/services';
import { DropdownOption, Merchant } from 'lib/types';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { ApplicationFilterProp, ApplicationStageFilter, ApplicationStatusFilter } from 'lib/types/Merchant';
import { clone } from 'lib/utils';
import { ApplicationStatusFilterOptions, DropdownItemValue, LastActivityOptions } from 'lib/utils/Items';

const DEFAULT_FILTER = {
  selectedLocation: undefined,
  quickSelection: 'ALL',
  stage: initialStageData,
  status: initialStatusData,
  searchString: undefined,
  dateFilter: undefined,
};

enum SortBy {
  MOST_RECENT = 'MOST_RECENT',
  OLDEST = 'OLDEST',
  LOWEST_BALANCE = 'LOWEST_BALANCE',
  HIGHEST_BALANCE = 'HIGHEST_BALANCE',
}

const defaultFilterLabel = 'All Applicants';

export const ApplicationHorizontalFilter = () => {
  const { trackEvent } = useAnalytics();
  const { onEvent } = useEventBus();
  const { setApplicationRequestObj, applicationRequestObj, onTableSort, setQuickFilterLabel } = useApplicationFilter();
  const permission = usePermission();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { locations, organization, merchants } = useStore();

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [defaultSortBy, setDefaultSortBy] = useState(SortBy.MOST_RECENT);
  const [defaultApplicationStatus, setDefaultApplicationStatus] = useState('ALL');
  const [filteredStringRange, setFilteredStringRange] = useState(`All Time`);
  const [location, setLocation] = useState<DropdownOption[]>([]);
  const [selectedFilters, setSelectedFilter] = useState<ApplicationFilterProp>(DEFAULT_FILTER);
  const [search, setSearch] = useState<string>();
  const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
  const searchInputRef = useRef<any>();
  const debouncedValue = useDebounce(search, 500);

  const activityOptions: DropdownOption[] = clone(LastActivityOptions);
  const { emitEvent } = useEventBus();
  const prepareFilter = () => {
    const { selectedLocation, status, quickSelection, stage } = selectedFilters;
    const selectedStatus = Object.keys(status).filter((key) => status[key].checked && key !== 'READY_FOR_CHECKOUT');
    const selectedStage = Object.keys(stage).filter((key) => stage[key].checked);
    setApplicationRequestObj({
      ...applicationRequestObj,
      filter: {
        ...applicationRequestObj.filter,
        ...expireDate(quickSelection),
        createdAt: selectedFilters?.dateFilter
          ? [
              {
                key: 'createdAt',
                value: dayjs(selectedFilters?.dateFilter?.from).startOf('day').toISOString() || '',
                operator: GenericSearchOperators.BIGGER_THAN,
              },
              {
                key: 'createdAt',
                value: dayjs(selectedFilters?.dateFilter?.to).endOf('day').toISOString() || '',
                operator: GenericSearchOperators.LOWER_THAN,
              },
            ]
          : undefined,
        merchantIds: selectedLocation,
        status: selectedStage,
        readyForCheckout: quickSelection === DropdownItemValue.READY_FOR_CHECKOUT,
        selfCheckout: quickSelection === DropdownItemValue.CHECKOUT_IN_PROGRESS,
        hasActiveLoan: quickSelection === DropdownItemValue.TRANSACTION_COMPLETE,
        scheduleStatus: selectedStatus,
      },
      searchString: debouncedValue,
    });
    trackSegmentEvent('Applicant Search', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      menuVersion: 'fullWidth',
    });
  };
  const resetFilters = () => {
    emitEvent(BusEvent.FILTER_RESET);
  };

  const expireDate = (selection) => {
    switch (selection) {
      case DropdownItemValue.EXPIRING_SOON:
        return {
          expireAt: [
            {
              key: 'expireAt',
              operator: GenericSearchOperators.LOWER_THAN,
              value: dayjs().utc().add(5, 'day').toISOString(),
            },
            {
              key: 'expireAt',
              operator: GenericSearchOperators.BIGGER_THAN,
              value: dayjs().utc().toISOString(),
            },
          ],
        };
      case DropdownItemValue.EXPIRED:
        return {
          expireAt: [
            {
              key: 'expireAt',
              value: dayjs().utc().toISOString(),
              operator: GenericSearchOperators.LOWER_THAN,
            },
          ],
        };
      default:
        return {
          expireAt: [
            {
              key: 'expireAt',
              value: dayjs().utc().endOf('day').toISOString(),
              operator: GenericSearchOperators.BIGGER_THAN,
            },
          ],
        };
    }
  };

  const toggleRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };

  const onResetFiltersClick = () => {
    trackEvent({
      action: DashboardAnalyticsEventNames.RESET_FILTERS_CLICK,
    });

    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.value = '';
      setFilteredStringRange('All Time');
    }
    setSelectedFilter(DEFAULT_FILTER);
    setDefaultSortBy(SortBy.MOST_RECENT);
    setDefaultApplicationStatus(DropdownItemValue.ALL);
    setQuickFilterLabel(defaultFilterLabel);
    setSearch(undefined);
    prepareFilter();
  };

  const logInputBlur = () => {
    trackEvent({
      action: DashboardAnalyticsEventNames.SEARCH_CONTRACTS,
    });
  };

  const onSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (nativeEvent) => {
    const currentTarget = nativeEvent?.currentTarget;
    if (currentTarget?.value?.length > 2 || currentTarget?.value === '') {
      setSearch(currentTarget.value);
    }
  };

  const setStageStatus = (quickViewEnum: DropdownItemValue) => {
    switch (quickViewEnum) {
      case DropdownItemValue.ALL:
      case DropdownItemValue.EXPIRED:
      case DropdownItemValue.EXPIRING_SOON:
      case DropdownItemValue.CHECKOUT_IN_PROGRESS:
        setSelectedFilter({
          ...selectedFilters,
          quickSelection: quickViewEnum,
          status: initialStatusData,
          stage: initialStageData,
        });
        break;

      case DropdownItemValue.READY_FOR_CHECKOUT:
      case DropdownItemValue.TRANSACTION_COMPLETE:
        setSelectedFilter({
          ...selectedFilters,
          quickSelection: quickViewEnum,
          status: initialStatusData,
          stage: readyForCheckoutStageCheckboxData,
        });
        break;
    }
  };

  const handleSortChange = (e) => {
    const value = e.value;
    setDefaultSortBy(value);

    switch (value) {
      case SortBy.MOST_RECENT:
        onTableSort({ selector: 'createdAt' }, 'desc');
        break;

      case SortBy.OLDEST:
        onTableSort({ selector: 'createdAt' }, 'asc');
        break;

      case SortBy.HIGHEST_BALANCE:
        onTableSort({ selector: 'balanceAvailable' }, 'desc');
        break;

      case SortBy.LOWEST_BALANCE:
        onTableSort({ selector: 'balanceAvailable' }, 'asc');
        break;
    }
  };

  const onApplicationStatusChange = (e) => {
    const value = e.value;
    const isStage = !!ApplicationStatusFilterOptions.find((elm) => elm.value === value)?.stateName;

    setDefaultApplicationStatus(value);
    setQuickFilterLabel(e?.label ?? defaultFilterLabel);

    const stageClone = JSON.parse(JSON.stringify(selectedFilters.stage)) as ApplicationStageFilter; // shadow copy
    Object.values(stageClone).forEach((elm) => (elm.checked = false));

    if (isStage) {
      setSelectedFilter({
        ...selectedFilters,
        stage: { ...stageClone, [value]: { checked: true } },
      });
    } else {
      setStageStatus(value);
    }

    trackSegmentEvent('Applicant Filter', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      filterType: 'Application Stage',
      filterName: value,
      filterStatus: true,
      menuVersion: 'fullWidth',
    });
  };

  const onActivitiesChange = (checkedActivities: (string | DropdownOption)[]) => {
    let activityList: string[] = [];

    // unify the output of the DropdownWithCheckbox component.
    // Since it gives options if 'all' is selected or gives string array if others are selected.
    if (checkedActivities.every((elm) => typeof elm === 'string')) {
      activityList = checkedActivities as string[];
    } else {
      activityList = checkedActivities.map((elm) => (elm as DropdownOption)?.value);
    }

    const status = {} as ApplicationStatusFilter;

    // fill with defaults
    activityOptions.forEach((elm) => {
      status[elm.value] = { checked: false };
    });

    // set selecteds
    activityList.forEach((elm) => {
      status[elm] = { checked: true };
    });

    setSelectedFilter({
      ...selectedFilters,
      status,
    });

    trackSegmentEvent('Applicant Filter', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      filterType: 'Status',
      filterName: activityList.join(','),
      filterStatus: true,
      menuVersion: 'fullWidth',
    });
  };

  const handleRangeSelection = (range: any) => {
    setSelectedFilter({
      ...selectedFilters,
      dateFilter: {
        from: dayjs(range.selection.startDate).startOf('day').toDate().toISOString(),
        to: dayjs(range.selection.endDate).endOf('day').toDate().toISOString(),
      },
    });
    trackSegmentEvent('Applicant Filter', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      filterType: 'Date Filter',
      startDate: dayjs(range.selection.startDate).startOf('day').toDate().toISOString(),
      endDate: dayjs(range.selection.endDate).endOf('day').toDate().toISOString(),
      menuVersion: 'fullWidth',
    });
  };

  const onTextStringUpdated = (inputDateRangeText: any) => {
    setFilteredStringRange(inputDateRangeText);
    trackEvent({
      action: DashboardAnalyticsEventNames.SALE_CLICK,
      label: inputDateRangeText,
    });
  };

  const locationSelectionHandler = (data) => {
    const findItems = location?.filter((loc) => data.includes(loc.value));
    setSelectedFilter({
      ...selectedFilters,
      selectedLocation: findItems,
    });

    trackSegmentEvent('Applicant Filter', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      filterType: 'Status',
      filterName: findItems.map((elm) => elm.value).join(','),
      filterStatus: true,
      menuVersion: 'fullWidth',
    });
  };

  useEffect(() => {
    if (locations?.length) {
      const formattedLocations: DropdownOption[] = locations?.map((merchant: Merchant) => {
        return { label: merchant.name, value: merchant.id?.toString() };
      });
      setLocation(formattedLocations);
    }
  }, [locations]);

  useDidMountEffect(() => {
    if (selectedFilters) {
      prepareFilter();
    }
  }, [selectedFilters, debouncedValue]);

  useEffect(() => {
    // register the event bus
    onEvent(BusEvent.FILTER_RESET, () => {
      onResetFiltersClick();
    });

    onEvent(BusEvent.FILTER_EXPIRING_SOON, () => {
      setStageStatus(DropdownItemValue.EXPIRING_SOON);
      setDefaultApplicationStatus(DropdownItemValue.EXPIRING_SOON);
    });

    onEvent(BusEvent.APPLY_INITIAL_FILTER, (initialFilter) => {
      setSearch(initialFilter);

      if (searchInputRef?.current?.outerHTML?.includes('input')) {
        searchInputRef.current.value = initialFilter;
      }
    });
  }, []);

  return (
    <Container>
      <SearchWrapper>
        <TextInput
          inputSize="small"
          onChange={onSearchInputChange}
          onBlur={logInputBlur}
          ref={searchInputRef}
          label="Search by Customer Name, Phone"
          leftElement={<CherryIcon src={SearchIcon} />}
        />
      </SearchWrapper>

      <FilterRow>
        <FilterItem>
          <FilterTitle>Sort By</FilterTitle>
          <DropDownContainer>
            <DropDown
              options={[
                { value: SortBy.MOST_RECENT, label: 'Most Recent' },
                { value: SortBy.OLDEST, label: 'Oldest' },
                { value: SortBy.LOWEST_BALANCE, label: 'Lowest Balance' },
                { value: SortBy.HIGHEST_BALANCE, label: 'Highest Balance' },
              ]}
              onChange={handleSortChange}
              defaultValue={defaultSortBy}
              hoveredMode={false}
              iconName="sort-by"
            />
          </DropDownContainer>
        </FilterItem>

        {!!permission(FeatureNames.SET_STATUS, ScreenNames.DASHBOARD) && (
          <FilterItem>
            <FilterTitle>Application Status</FilterTitle>
            <DropDownContainer>
              <DropDown
                options={ApplicationStatusFilterOptions}
                onChange={onApplicationStatusChange}
                defaultValue={defaultApplicationStatus}
                hoveredMode={false}
                iconName="application-status"
              />
            </DropDownContainer>
          </FilterItem>
        )}

        {locations && locations?.length > 1 ? (
          <FilterItem>
            <FilterTitle>Location Preference</FilterTitle>
            <DropDownContainer>
              <DropDownWithCheckbox
                searchablePlaceholder="Search By Location Name"
                selectAllLabel="All Locations"
                options={location}
                placeholder="All Locations"
                checkedAllPlaceholder="All Locations"
                iconName="location-preference"
                checkedPlaceholder="Location"
                hoveredMode={false}
                textColor="#0E202F"
                onChange={locationSelectionHandler}
                checkedItems={selectedFilters.selectedLocation || location}
                preventCloseOnClick={true}
                searchable={true}
                selectAll={true}
              />
            </DropDownContainer>
          </FilterItem>
        ) : null}

        <FilterItem>
          <FilterTitle>Application Date</FilterTitle>
          <CherryPickerWrapper>
            <InputWithIcon
              style={{ color: '#0E202F', paddingLeft: 32 }}
              onClick={toggleRangePicker}
              defaultValue={filteredStringRange}
              caret="transparent"
              margin="0px 0px 0px 0px"
              textAlign="left"
              cursor="pointer"
              value={filteredStringRange}
            />
            <Icon
              onClick={toggleRangePicker}
              src="application-date"
              left="10px"
              hover={true}
              width={16}
              height={16}
              m="0px 6px 0px 0px"
            />
            <CherryRangePicker
              show={showRangePicker}
              onRangeSelected={handleRangeSelection}
              updateTextString={onTextStringUpdated}
              toggleRangePicker={toggleRangePicker}
            />
          </CherryPickerWrapper>
        </FilterItem>

        <FilterItem>
          <FilterTitle>Last Activity</FilterTitle>
          <DropDownContainer>
            <DropDownWithCheckbox
              searchablePlaceholder="Search..."
              selectAllLabel="All Activity"
              options={activityOptions}
              placeholder="All Activity"
              checkedAllPlaceholder="All Activity"
              iconName="last-activity"
              checkedPlaceholder="Activity"
              hoveredMode={false}
              textColor="#0E202F"
              onChange={onActivitiesChange}
              checkedItems={Object.entries(selectedFilters.status)
                .filter(([key, values]) => values?.checked)
                .map(([key, values]) => ({
                  value: key,
                  label: activityOptions.find((elm) => elm.value === key)?.label,
                }))}
              preventCloseOnClick={true}
              searchable={true}
              selectAll={true}
            />
          </DropDownContainer>
        </FilterItem>
        <FilterItem>
          <ClearFilterButton onClick={resetFilters}>Clear Filters</ClearFilterButton>
        </FilterItem>
      </FilterRow>
    </Container>
  );
};

const ClearFilterButton = styled.div`
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  margin-top: 29px;
  margin-left: 45px;
  width: 80px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
const CherryPickerWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  border: 1px solid;
  justify-content: center;
  margin-bottom: 20px;
  // margin-top: 3px;
  position: relative;
  border: 1px solid #dadada;
`;

const SearchWrapper = styled.div`
  position: relative;
  margin: 12px 0px;
  width: 100%;
  position: relative;
`;

export const DropDownContainer = styled.div`
  height: 43px;
  width: 100%;
  margin-bottom: 21px;
  background-color: white;
  border: 1px solid #dadada;
  box-sizing: border-box;

  .dropdown-right {
    // height: 46px;

    span {
      font-size: 14px;
    }
  }
  .dropdown-content {
    // width: 80%;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;

const FilterItem = styled.div`
  display: block;
`;

const FilterTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;

const FilterRow = styled.div`
  --columnSize: calc(20% - 32px);

  display: grid;
  grid-template-columns: var(--columnSize) var(--columnSize) var(--columnSize) var(--columnSize) var(--columnSize) var(
      --columnSize
    );
  column-gap: 6px;
`;

const Container = styled.div`
  margin-top: 27px;
`;
