import { Dialog as MuiDialog, DialogActions as MuiDialogActions } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { ButtonPrimary } from 'lib/components';
import { RewardNotification } from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneRewardsWidget';
import { CherryDialogTitle } from 'pages/desktop/Settings/components/Dialog';

interface MilestoneCongratsModalProps {
  visible: boolean;
  onClose: () => void;
  handleDetailButton: () => void;
  hasReward: boolean;
  activatedAt?: string;
  completedStep?: string;
}

const MilestoneCongratsModal = ({
  visible,
  onClose,
  handleDetailButton,
  hasReward,
  activatedAt,
  completedStep,
}: MilestoneCongratsModalProps) => {
  const getCongratsModalContent = (step) => {
    const CONGRATS_MODAL_CONTENT_REWARD = {
      [RewardNotification.STEP_1]: {
        title: 'You unlocked a reward!',
        description: (
          <ModalText>
            Great work! You got your first patient approval, unlocking a welcome gift of <BoldText>$10.</BoldText>
            <br />
            There are more gifts ahead, so keep going!
            <br />
            <br /> View your rewards panel in the Applications dashboard to learn how to earn another gift.
          </ModalText>
        ),
      },
      [RewardNotification.STEP_2]: {
        title: 'You unlocked a reward!',
        description: (
          <ModalText>
            Great work! You completed your first patient checkout, unlocking a welcome gift of <BoldText>$20.</BoldText>
            Keep up the great work – you’re on fire! <br />
            <br />
            View your rewards panel in the Applications dashboard to see how to earn an even bigger gift.
          </ModalText>
        ),
      },
      [RewardNotification.STEP_3]: {
        title: 'You unlocked a reward!',
        description: (
          <ModalText>
            Wow! You completed 5 patient checkouts, unlocking a welcome gift of <BoldText>$50.</BoldText>
            <br />
            <br />
            You’re crushing your goals already. We are so proud! <br />
            <br />
            Keep an eye on your inbox – your gift will be delivered directly to your email address within 7 business
            days of {dayjs(activatedAt).add(30, 'day').format('MMM DD, YYYY')}.
          </ModalText>
        ),
      },
    };

    const CONGRATS_MODAL_CONTENT_NO_REWARD = {
      [RewardNotification.STEP_1]: {
        title: 'You got your first approval!',
        description: (
          <ModalText>
            Great work! You got your <BoldText>first patient approval.</BoldText>
            <br /> <br />
            The next step is to convert that approval into a transaction! Click "learn more" to view the next steps to
            success and resources to help you achieve them.
          </ModalText>
        ),
      },
      [RewardNotification.STEP_2]: {
        title: 'You completed your first checkout!',
        description: (
          <ModalText>
            Great work! You completed your <BoldText>first patient checkout.</BoldText>
            <br />
            <br /> Keep up the great work – you're on fire!
            <br />
            <br /> Earn your <BoldText>Cherry Expert</BoldText> certification by completing 5+ checkouts in your first
            30 days.
          </ModalText>
        ),
      },
      [RewardNotification.STEP_3]: {
        title: `You're a Cherry Expert!`,
        description: (
          <ModalText>
            Wow! You completed <BoldText>5 patient checkouts in your first month with Cherry!</BoldText> You’re crushing
            your goals already. <br /> <br />
            You're off to a strong start, but if there's anything we can do to help grow your business, please visit the
            "Help & Support" tab or contact your dedicated Success and Development Manager!
          </ModalText>
        ),
      },
    };

    return hasReward ? CONGRATS_MODAL_CONTENT_REWARD[step] : CONGRATS_MODAL_CONTENT_NO_REWARD[step];
  };

  const data = getCongratsModalContent(completedStep);

  return data ? (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle id="milestone-dialog-title" onClose={onClose}>
        {data.title}
      </DialogTitle>
      <Content>
        <PartyIcon src={images?.partyIcon} />
        <DescriptionContainer> {data.description}</DescriptionContainer>
      </Content>
      <DialogActions>
        <CloseButton onClick={handleDetailButton} text="Learn More" />
      </DialogActions>
    </Dialog>
  ) : null;
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 393px;
    height: 421px;
    padding: 24px 24px 40px 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  font-size: 14px;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const DialogTitle = styled(CherryDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const DialogActions = styled(MuiDialogActions)`
  & > button {
    width: 122px;
  }
  padding: 0 !important;
  margin: auto;
`;

const CloseButton = styled(ButtonPrimary)`
  color: #879097 !important;
  border-color: #879097 !important;
  background-color: #ffffff !important;
  margin-right: 16px !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  &:hover,
  &:focus {
    background-color: #879097 !important;
    color: #ffffff !important;
  }
`;

const DescriptionContainer = styled.div`
  margin-top: 24px;
  max-width: 325px;
`;

const PartyIcon = styled.img`
  width: 80px;
  height: 80px;
`;

const ModalText = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BoldText = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export default MilestoneCongratsModal;
