import { Button as CherryButton, Dropdown, Switch } from '@frontend/cherry-library';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { useApolloClient, useMutation } from '@apollo/client';
import { faSearch, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { APP_PREQUAL_URL } from 'config';
import { theme } from 'config/theme';
import { InputWithIcon, TableLoader } from 'lib/components';
import { SEND_ESTIMATE_EMAIL, SEND_ESTIMATE_SMS } from 'lib/graphql/mutations';
import { CREATE_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { STAGE_OPTIONS, STATUS_OPTIONS, usePatientTracker } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { NotificationEvent, useLastClicked, useNotificationCenter } from 'lib/services';
import { Row } from 'lib/styles';
import { useDentalPatientTrackerListTable } from 'lib/tables';
import { Merchant } from 'lib/types';
import { DataTableStyle, PatientTrackerCheckoutReadyDataTableStyle } from 'lib/utils';
import { useAlert } from 'react-alert';
import { DentalCheckoutModal } from '../DentalCheckoutModal';
import { DentalEditCancelPlanModal } from '../DentalEditCancelPlanModal';
import CreateTreatmentModal, { TreatmentPlan } from './DentalCreateTreatmentModal';
import ResendCheckoutLinkModal from 'pages/desktop/Dashboard/DentalDashboard/ResendCheckoutLinkModal/ResendCheckoutLinkModal';
import { ExtendApprovalModal } from 'pages/desktop/Dashboard/views/ExtendApprovalModal';

interface Props {
  createTreatmentModalOpen?: boolean;
  setCreateTreatmentModalOpen: Dispatch<SetStateAction<boolean>>;
  triggerRefetch: boolean;
}

export const PatientTracker = ({
  createTreatmentModalOpen = false,
  setCreateTreatmentModalOpen,
  triggerRefetch,
}: Props) => {
  const client = useApolloClient();
  const { organization, merchants, locations } = useStore();
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const alert = useAlert();
  const { onNotification } = useNotificationCenter();
  const { isLastClickedParent } = useLastClicked();

  const {
    page,
    total,
    data: tableData,
    tableLoading,
    onSearchInputChange,
    searchInputRef,
    onTableSort,
    pageChange,
    rowsCountChange,
    fetchTreatments,
    selectedStages,
    selectedStatus,
    onSelectedStatusChange,
    onSelectedStagesChange,
    selectedLocations,
    onSelectedLocationsChange,
    resetFilters,
    onShowArchivedToggle,
    isShowArchived,
    isCheckoutModalOpened,
    toggleIsCheckoutModalOpened,
    checkoutItem,
    checkoutReadyData,
    checkoutReadyTotal,
    checkoutReadyRowsCountChange,
    checkoutReadyPageChange,
    onCheckoutReadyTableSort,
    checkoutReadyTableLoading,
    isEditCancelPlanModalOpened,
    toggleIsEditCancelPlanModalOpened,
    fetchCheckoutReadyTreatments,
    resendCheckoutLinkModal,
    setResendCheckoutLinkModal,
    extendApprovalPatient,
    closeExtendApprovalModal,
  } = usePatientTracker({ triggerRefetch });

  const [sendEstimateEmail] = useMutation(SEND_ESTIMATE_EMAIL);
  const [sendEstimateSms] = useMutation(SEND_ESTIMATE_SMS);
  const [createTreatmentPlan] = useMutation(CREATE_TREATMENT_PLAN);

  const [isCreateTreatmentModalVisible, setIsCreateTreatmentModalVisible] = useState(false);
  const [isAddPatient, setIsAddPatient] = useState(false);

  const locationOptions: Array<{ label: string; value: string }> =
    locations?.map((merchant: Merchant) => {
      return { label: merchant.name, value: merchant.id?.toString() };
    }) || [];

  useEffect(() => {
    setIsCreateTreatmentModalVisible(createTreatmentModalOpen);
  }, [createTreatmentModalOpen]);

  const handleRowClicked = () => {
    // ts-ignore
    console.log('TEST');
  };

  const handleAddPatientClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.ADD_PATIENT.MODAL_VIEWED');
    setIsAddPatient(true);
    setIsCreateTreatmentModalVisible(true);
  };

  const handleCloseCreateTreatmentModal = () => {
    setIsCreateTreatmentModalVisible(false);
    setCreateTreatmentModalOpen(false);
    setIsAddPatient(false);
  };

  const getPreviewParams = (paymentCalculationData: any, values: any) => ({
    paymentCalculation: paymentCalculationData.calculatePayment,
    amount: Number(values.treatmentCost),
    additionalNotes: values.consultationSummary,
    patientName: (values.firstName || values.customer.firstName) + ' ' + (values.lastName || values.customer.lastName),
    selectedLocation: {
      value: merchants?.[0].id,
      emailLabel: `${merchants?.[0]?.name} - ${merchants?.[0]?.address?.city} `,
    },
    planLength: 12,
    creditScore: { text: 'GREAT', value: 'GREAT' },
    treatmentCoordinator: '-',
    data: values,
  });

  const sendEmail = async (email: string, paymentCalculationData: any, values: any) => {
    try {
      const previewParams = getPreviewParams(paymentCalculationData, values);

      let applyLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice&utm_medium=email_estimate`;
      const merchantId = previewParams?.selectedLocation?.value;
      if (merchantId) {
        applyLink += '&m=' + merchantId;
      }

      const { data } = await sendEstimateEmail({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              email,
              amount: previewParams?.amount,
              merchantName: previewParams?.selectedLocation?.emailLabel,
              financialSituation: previewParams?.creditScore?.text,
              paymentAmount: previewParams?.paymentCalculation?.paymentAmount,
              term: previewParams?.planLength,
              financeCharge: previewParams?.paymentCalculation?.financeCharge,
              apr: previewParams?.paymentCalculation?.apr,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes,
              applyLink,
            },
          },
        },
      });

      if (!data?.sendEstimateEmail?.success) {
        alert.error(data?.sendEstimateEmail?.message);
      } else {
        alert.success('Sent over email successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const sendSms = async (phone: string, paymentCalculationData: any, values: any) => {
    try {
      const previewParams = getPreviewParams(paymentCalculationData, values);

      const { data } = await sendEstimateSms({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              phone,
              merchantId: Number(previewParams?.selectedLocation?.value),
              amount: previewParams?.amount,
              creditGradeType: previewParams?.creditScore?.text,
              term: previewParams?.planLength,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes ?? '',
            },
          },
        },
      });

      if (!data?.sendEstimateSms?.success) {
        alert.error(data?.sendEstimateSms?.message);
      } else {
        alert.success('Sent over text successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleOtherPatientsSyncButtonClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.SYNC_CLICKED');
    fetchTreatments();
  };

  const handleOtherPatientsTableSortClick = (column, direction) => {
    onTableSort(column, direction);

    const COLUMN_NAME = column?.name?.toUpperCase()?.replace(/ /g, '_');
    if (COLUMN_NAME) {
      trackSegmentEvent(`PRACTICE_PORTAL.ALL_OTHER_PATIENTS.SORT_BY_${COLUMN_NAME}_CLICKED`);
    }
  };

  const createTreatmentPlanRequest = async (values: TreatmentPlan) => {
    try {
      const { data } = await createTreatmentPlan({
        variables: {
          input: {
            organizationId: organization?.id,
            customerFirstName: values.firstName,
            customerLastName: values.lastName,
            customerPhone: values.phone || undefined,
            customerEmail: values.email || undefined,
            treatmentCost: Number(values.treatmentCost),
            stage: !isAddPatient && (values.sendEmail || values.sendText) ? 'PLAN_SENT' : 'PATIENT_CREATED',
            consultationSummary: values.consultationSummary,
          },
        },
      });

      if (!data?.createTreatmentPlan?.id) {
        alert.error(isAddPatient ? 'Error when adding patient' : 'Error when creating treatment plan');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleAddPatientSubmit = async (values: TreatmentPlan) => {
    try {
      await createTreatmentPlanRequest(values);
      await fetchTreatments();
    } catch (error) {
      alert.error('Something went wrong');
    } finally {
      handleCloseCreateTreatmentModal();
    }
  };

  const handlePageChange = (selectedPage: number) => {
    if (page > selectedPage) {
      if (Math.abs(selectedPage - page) === 1) {
        trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.PAGE_LEFT_CLICKED');
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.PAGE_SUPER_LEFT_CLICKED');
      }
    } else {
      if (Math.abs(selectedPage - page) === 1) {
        trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.PAGE_RIGHT_CLICKED');
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.PAGE_SUPER_RIGHT_CLICKED');
      }
    }

    pageChange(selectedPage);
  };

  const handleRowsPerPageChange = (perPage: number, pageNumber: number) => {
    trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.ROWS_PER_PAGE_SELECTED', {
      data: {
        value: perPage,
      },
    });
    rowsCountChange(perPage, pageNumber);
  };

  const handleCheckoutReadyPageChange = (selectedPage: number) => {
    if (page > selectedPage) {
      if (Math.abs(selectedPage - page) === 1) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.PAGE_LEFT_CLICKED');
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.PAGE_SUPER_LEFT_CLICKED');
      }
    } else {
      if (Math.abs(selectedPage - page) === 1) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.PAGE_RIGHT_CLICKED');
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.PAGE_SUPER_RIGHT_CLICKED');
      }
    }

    checkoutReadyPageChange(selectedPage);
  };

  const handleCheckoutReadyRowsPerPageChange = (perPage: number, pageNumber: number) => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.ROWS_PER_PAGE_SELECTED', {
      data: {
        value: perPage,
      },
    });
    checkoutReadyRowsCountChange(perPage, pageNumber);
  };

  const { checkoutReadyPatientTable, allPatientsTable, loading } = useDentalPatientTrackerListTable({
    sendEmail,
    sendSms,
  });

  useEffect(() => {
    onNotification(NotificationEvent.REFRESH_CHECKOUT_READY_PATIENTS_LIST, (data) => {
      if (data?.buttonClicked || !isLastClickedParent('checkoutReadyPatientTable')) {
        fetchCheckoutReadyTreatments();
      }
    });

    onNotification(NotificationEvent.REFRESH_ALL_OTHER_PATIENTS_LIST, (data) => {
      if (data?.buttonClicked || !isLastClickedParent('allPatientsTable')) {
        fetchTreatments();
      }
    });
  }, []);

  const handleStageOptionsClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.STAGES_DROPDOWN_CLICKED');
  };

  const handleLocationOptionsClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.LOCATIONS_DROPDOWN_CLICKED');
  };

  const handleSelectedStagesChange = (event: SelectChangeEvent<unknown>) => {
    const stages = (event?.target?.value as string[]) || [];
    onSelectedStagesChange(stages);
  };

  const handleSelectedStatusChange = (event: SelectChangeEvent<unknown>) => {
    const status: string = String(event?.target?.value);
    onSelectedStatusChange(status);
  };

  const handleStatusOptionsClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.STAGES_DROPDOWN_CLICKED');
  };

  const handleSelectedLocationsChange = (event: SelectChangeEvent<unknown>) => {
    const selectedLocationList = (event?.target?.value as string[]) || [];
    onSelectedLocationsChange(selectedLocationList);
  };

  const handleCloseResendCheckoutModal = () => {
    setResendCheckoutLinkModal({
      show: false,
      success: false,
      notSupported: false,
      patient: { firstName: '', lastName: '' },
      isEmail: false,
    });
  };

  return (
    <Container>
      <PatientTrackerCTAArea>
        <SearchWrapper>
          <InputWithIcon
            name="searchString"
            onBlur={segmentEventHandler('PRACTICE_PORTAL.SEARCH_BAR_FILLED', {
              withInput: true,
              isFilledEvent: true,
            })}
            onClick={segmentEventHandler('PRACTICE_PORTAL.SEARCH_BAR_SELECTED')}
            onChange={onSearchInputChange}
            ref={searchInputRef}
            placeholder="Search patient name or phone"
            textAlign="left"
            border={true}
            darkPlaceholder={true}
          />
          <InputIconWrapper>
            <FontAwesomeIcon icon={faSearch} color={theme.main.white} />
          </InputIconWrapper>
        </SearchWrapper>
        <MonthlyPaymentButtonContainer>
          <CherryButton variant="secondary" size="small" onClick={handleAddPatientClick}>
            Add Patient
          </CherryButton>
        </MonthlyPaymentButtonContainer>
        <CreateTreatmentModal
          open={isCreateTreatmentModalVisible}
          onClose={handleCloseCreateTreatmentModal}
          onSubmit={handleAddPatientSubmit}
          consultationSummaryCharLimit={800}
        />
        <DentalCheckoutModal
          selectedItemData={checkoutItem}
          open={isCheckoutModalOpened}
          onClose={toggleIsCheckoutModalOpened}
          onSubmit={toggleIsCheckoutModalOpened}
          isPatientTracker={true}
        />
        <DentalEditCancelPlanModal
          open={isEditCancelPlanModalOpened}
          onClose={toggleIsEditCancelPlanModalOpened}
          onSubmit={toggleIsEditCancelPlanModalOpened}
          selectedItemData={checkoutItem}
        />
        <ResendCheckoutLinkModal
          open={resendCheckoutLinkModal?.show}
          handleClose={handleCloseResendCheckoutModal}
          firstName={resendCheckoutLinkModal?.patient?.firstName}
          lastName={resendCheckoutLinkModal?.patient?.lastName}
          isSuccess={resendCheckoutLinkModal?.success}
          openEditCancelModal={toggleIsEditCancelPlanModalOpened}
          isEmail={resendCheckoutLinkModal?.isEmail}
        />
        <ExtendApprovalModal
          onClose={closeExtendApprovalModal}
          open={Boolean(extendApprovalPatient)}
          reminderData={extendApprovalPatient}
        />
      </PatientTrackerCTAArea>
      <FiltersContainer>
        <DropDownContainer>
          <Dropdown
            options={STATUS_OPTIONS}
            label={'Application Status'}
            onClick={handleStatusOptionsClick}
            onChange={handleSelectedStatusChange}
            value={selectedStatus}
          />
        </DropDownContainer>
        <DropDownContainer>
          <Dropdown
            multiple={true}
            options={STAGE_OPTIONS}
            label={'All Stages'}
            onClick={handleStageOptionsClick}
            onChange={handleSelectedStagesChange}
            value={selectedStages?.map((stage) => stage?.value)}
          />
        </DropDownContainer>
        {locations && locations.length > 1 && (
          <DropDownContainer>
            <Dropdown
              label="Location Preference"
              multiple={true}
              searchablePlaceholder="Search By Location Name"
              options={locationOptions}
              onClick={handleLocationOptionsClick}
              onChange={handleSelectedLocationsChange}
              value={selectedLocations?.map((location) => location?.value)}
              searchable={true}
            />
          </DropDownContainer>
        )}
        <ClearFilterButton onClick={resetFilters}>Clear Filters</ClearFilterButton>
        <ArchiveToggle>
          <Switch handleClick={onShowArchivedToggle} isChecked={isShowArchived} />
          <SwitchLabel>Show Archived</SwitchLabel>
        </ArchiveToggle>
      </FiltersContainer>
      <PatientTrackerHeader>
        Checkout-Ready Patients{' '}
        <SyncContainer
          id="sync-button-checkout-ready"
          onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT_READY.SYNC_CLICKED', {
            cb: fetchCheckoutReadyTreatments,
          })}
        >
          <FaIcon icon={faSync} loading={loading || checkoutReadyTableLoading} /> Sync
        </SyncContainer>
      </PatientTrackerHeader>
      <TableRow id="checkoutReadyPatientTable">
        <TableColumn>
          <DataTable
            className="data-table"
            customStyles={PatientTrackerCheckoutReadyDataTableStyle}
            pointerOnHover={true}
            keyField="id"
            noHeader={true}
            columns={checkoutReadyPatientTable}
            data={checkoutReadyData}
            pagination={true}
            paginationServer={true}
            progressPending={loading || checkoutReadyTableLoading}
            progressComponent={<TableLoader count={4} />}
            onChangePage={handleCheckoutReadyPageChange}
            onChangeRowsPerPage={handleCheckoutReadyRowsPerPageChange}
            paginationTotalRows={checkoutReadyTotal}
            onSort={onCheckoutReadyTableSort}
            sortServer={true}
            onRowClicked={handleRowClicked}
            expandOnRowClicked={true}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
            conditionalRowStyles={[
              {
                when: (row) => row.status === 'ARCHIVED',
                style: {
                  backgroundColor: 'rgba(242, 244, 245, 1)',
                },
              },
            ]}
          />
        </TableColumn>
      </TableRow>
      <PatientTrackerHeader>
        All Other Patients{' '}
        <SyncContainer id="sync-button-all-other-patients" onClick={handleOtherPatientsSyncButtonClick}>
          <FaIcon icon={faSync} loading={loading || tableLoading} /> Sync
        </SyncContainer>
      </PatientTrackerHeader>
      <TableRow id="allPatientsTable">
        <TableColumn>
          <DataTable
            className="data-table"
            customStyles={DataTableStyle}
            pointerOnHover={true}
            keyField="id"
            noHeader={true}
            columns={allPatientsTable}
            data={tableData}
            pagination={true}
            paginationServer={true}
            progressPending={loading || tableLoading}
            progressComponent={<TableLoader count={4} />}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            paginationTotalRows={total}
            onSort={handleOtherPatientsTableSortClick}
            sortServer={true}
            onRowClicked={handleRowClicked}
            expandOnRowClicked={true}
            conditionalRowStyles={[
              {
                when: (row) => row.status === 'ARCHIVED',
                style: {
                  backgroundColor: 'rgba(242, 244, 245, 1)',
                },
              },
            ]}
          />
        </TableColumn>
      </TableRow>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 300px;
`;

const SwitchLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: black;
  margin-left: 6px;
`;

const ClearFilterButton = styled.div`
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  width: 120px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const CherryDialog = styled(Dialog)<{ width: string }>`
  .MuiDialog-paperScrollPaper {
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
  }
`;

const PatientTrackerHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 12px;
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
`;

const TableRow = styled(Row)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;

  .data-table {
    overflow: inherit;
  }
`;

interface TableColumnProps {
  flex?: number;
}

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow > div:first-child {
    display: none !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    > div:first-child {
      display: none;
    }

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const PatientTrackerCTAArea = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 12px 0px;
  position: relative;
  margin-right: 16px;

  box-shadow: 0px 1.2365612983703613px 2.301378011703491px 0px rgba(0, 0, 0, 0.0217),
    0px 3.12735652923584px 5.820357799530029px 0px rgba(0, 0, 0, 0.0311),
    0px 6.379513740539551px 11.872983932495117px 0px rgba(0, 0, 0, 0.0389),
    0px 13.140592575073242px 24.45610237121582px 0px rgba(0, 0, 0, 0.0483), 0px 36px 67px 0px rgba(0, 0, 0, 0.07) !important;
`;

const InputIconWrapper = styled.div`
  background-color: #00c37d;
  width: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FaIcon = styled(FontAwesomeIcon)<{ loading: boolean; color?: string }>`
  color: ${(props) => props.theme.main.green};
  animation-name: ${(props) => (props.loading ? 'spin' : '')};
  animation-duration: 687ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const SyncContainer = styled.div`
  font-size: 14px;
  color: #00c37c;
  margin-left: 16px;
  cursor: pointer;
`;

export const DropDownContainer = styled.div`
  width: 20%;
  background-color: white;
  box-sizing: border-box;

  .dropdown-right {
    height: 46px;
  }
  .dropdown-content {
    width: 100%;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArchiveToggle = styled.div`
  display: flex;
  align-items: center;
`;

const MonthlyPaymentButtonContainer = styled.div`
  max-height: 130px;
  display: flex;
`;
