import { gql } from '@apollo/client/core';

export const GET_EXCHANGE_TOKEN = gql`
  mutation getExchangeToken($input: ExchangeTokenInput) {
    getExchangeToken(input: $input) {
      status
      data {
        bankConnectionId
        applicationId
        organizationId
        userId
        merchantId
        name
        outcomeThreshold
      }
      status
      type
      token
    }
  }
`;
