import { useApolloClient } from '@apollo/client';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { MARKETING_MATERIAL_TYPES } from 'lib/constants';
import { GET_MARKETING_MATERIAL_HISTORY } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Row } from 'lib/styles';
import MaterialModal from 'pages/desktop/OtherResourcesTemplates/components/MaterialModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

interface OrderHistoryModalProps {
  visible: boolean;
  onClose: () => void;
}

interface OrderHistoryItem {
  userName: string;
  orderTime: string;
  documentType: MARKETING_MATERIAL_TYPES;
  quantity: number;
}
interface OrderHistoryResponse {
  marketingItemsModelList: [OrderHistoryItem];
  limit: number;
  page: number;
  totalRecordCount: number;
  totalPageCount: number;
}

const getDocumentName = (documentType: MARKETING_MATERIAL_TYPES) => {
  switch (documentType) {
    case MARKETING_MATERIAL_TYPES.BROCHURE:
      return 'Trifold Brochure';
    case MARKETING_MATERIAL_TYPES.ONE_PAGER:
      return 'Patient One Pager';
    case MARKETING_MATERIAL_TYPES.QR_CARD:
      return 'QR Card with Arclyc Stand';
    case MARKETING_MATERIAL_TYPES.FULL_KIT:
      return 'Full Marketing Box';
  }
};

const getOrderInfo = (documentType: MARKETING_MATERIAL_TYPES, quantity: number) => {
  const documentName = getDocumentName(documentType);
  return `${documentName} (${quantity})`;
};

const OrderHistoryModal = ({ visible, onClose }: OrderHistoryModalProps) => {
  const client = useApolloClient();
  const { user, organization, merchants } = useStore();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [orderHistoryItems, setOrderHistoryItems] = useState<OrderHistoryResponse>();

  const isMerchant = useMemo(() => user.userType === 'MERCHANT_USER', [user.userType]);
  const idKeyForRequestParams = useMemo(() => (isMerchant ? 'merchantId' : 'organizationId'), [isMerchant]);
  const idValueForRequestParams = useMemo(() => (isMerchant ? merchants?.[0].id : organization?.id), [
    isMerchant,
    merchants,
    organization,
  ]);

  useEffect(() => {
    getOrderHistory();
  }, [page]);

  const getOrderHistory = useCallback(async () => {
    const {
      data: { getMarketingMaterialHistory },
    } = await client.query({
      query: GET_MARKETING_MATERIAL_HISTORY,
      variables: { input: { limit: 5, page: page + 1, [idKeyForRequestParams]: idValueForRequestParams } },
    });
    setOrderHistoryItems(getMarketingMaterialHistory);
  }, [idKeyForRequestParams, idValueForRequestParams, page]);

  const tableRows = useMemo(() => {
    return orderHistoryItems?.marketingItemsModelList?.map((model: OrderHistoryItem, index: number) => (
      <TableRow key={index}>
        <TableCell>
          <div>{model.userName}</div>
          <Row fontSize="12px">{model.orderTime}</Row>
        </TableCell>
        <TableCell>
          <div>{getOrderInfo(model.documentType, model.quantity)}</div>
        </TableCell>
      </TableRow>
    ));
  }, [orderHistoryItems]);

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(e.target.value));
  };

  const handlePageChange = (_, pageValue: number) => {
    setPage(pageValue);
  };

  return (
    <MaterialModal
      open={visible}
      onSubmit={onClose}
      onCancel={onClose}
      title="Your Order History"
      submitButtonLabel="Okay"
      hideCancelButton={true}
    >
      <TableContainer>
        <Table data-testid="order-history-table">
          <TableHead>
            <TableRow>
              <TableCell>Order Placed</TableCell>
              <TableCell>Order Contents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={orderHistoryItems?.totalRecordCount ?? 0}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        onPageChange={handlePageChange}
      />
    </MaterialModal>
  );
};

const Table = styled(MuiTable)`
  .MuiTableCell-root {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.main.midnightBlue};
  }

  .MuiTableCell-head {
    font-weight: 700;
  }

  .MuiTablePagination-caption {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

export default OrderHistoryModal;
