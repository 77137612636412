import { gql } from '@apollo/client/core';

export const CREATE_LOAN = gql`
  mutation createLoan($input: CreateLoanRequest) {
    createLoan(input: $input) {
      data {
        id
      }
      code
      message
      success
    }
  }
`;

export const PATCH_LOAN = gql`
  mutation patchLoan($input: PatchLoanRequest) {
    patchLoan(input: $input) {
      id
    }
  }
`;

export const SEND_CHECKOUT_EMAIL = gql`
  mutation sendCheckoutEmail($input: SendCheckoutEmailRequest) {
    sendCheckoutEmail(input: $input) {
      success
    }
  }
`;
