import React, { useState } from 'react';
import styled from 'styled-components';

import { ButtonPrimary, DropDown, FixedDecimalFormat, NoteIcon } from 'lib/components';

import { useApolloClient, useMutation } from '@apollo/client';
import { faCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Popover, TextField } from '@mui/material';
import { theme } from 'config/theme';
import { AmountField } from 'lib/components/mobile';
import { SEND_APPLICATION_LINK } from 'lib/graphql/mutations';
import { CALCULATE_PAYMENT } from 'lib/graphql/queries';
import { STATUSES } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';
import { useAlert } from 'react-alert';
import { formatPhoneNumber, formatTextCapitalize, TreatmentStageOptions } from '../utils';
import { BusEvent, useEventBus } from 'lib/services';

interface Props {
  sendSms: (phone: string, paymentCalculationData: any, values: any) => void;
  sendEmail: (email: string, paymentCalculationData: any, values: any) => void;
}

const STAGE_TO_COLOR = {
  PATIENT_CREATED: '#fff',
  PLAN_SENT: 'rgb(231, 233, 234)',
  TREATMENT_SCHEDULED: 'rgba(249, 236, 214, 1)',
  TREATMENT_IN_PROGRESS: 'rgba(230, 241, 249, 1)',
  TREATMENT_COMPLETED: 'rgba(230, 249, 242, 1)',
  PATIENT_DECLINED: 'rgba(253, 235, 239, 1)',
  UNRESPONSIVE: 'rgba(231, 233, 234, 1)',
};

const ACTION_OPTIONS = (row) => [
  {
    value: 'VIEW_TREATMENT_PLAN',
    label: 'View Treatment Plan',
    segmentEventName: 'VIEW_MONTHLY_OPTIONS',
    isHidden: row.status === STATUSES.ARCHIVED,
  },
  {
    value: 'TEXT_PLAN',
    label: 'Text Plan to Patient',
    segmentEventName: 'TEXT_PLAN_TO_PATIENT',
    isHidden: !row.customer.phone || row.status === STATUSES.ARCHIVED,
  },
  {
    value: 'EMAIL_PLAN',
    label: 'Email Plan to Patient',
    segmentEventName: 'EMAIL_PLAN_TO_PATIENT',
    isHidden: !row.customer.email || row.status === STATUSES.ARCHIVED,
  },
  {
    value: 'TEXT_APPLICATION_LINK',
    label: 'Text Cherry Application Link',
    segmentEventName: 'TEXT_APPLICATION_LINK',
    isHidden: !row.customer.phone || row.status === STATUSES.ARCHIVED,
  },
  {
    value: 'ARCHIVE',
    label: 'Archive',
    color: '#EC3360',
    segmentEventName: 'ARCHIVE',

    isHidden: row.status === STATUSES.ARCHIVED,
  },
  {
    value: 'UNARCHIVE',
    label: 'Unarchive',
    segmentEventName: 'UNARCHIVE',
    isHidden: row.status === STATUSES.ACTIVE,
  },
];

const EditNote = ({ initialNote, onEditNote }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [note, setNote] = useState(initialNote);

  const handleEditNoteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleSave = () => {
    onEditNote(note);
  };

  return (
    <div>
      <div onClick={handleEditNoteClick}>
        <NoteIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        marginThreshold={50}
        className="popover-container"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent>
          <NoteContainer>
            <PopoverTitle>Notes</PopoverTitle>
            <FormControl fullWidth={true} margin="dense">
              <StyledTextField
                size="small"
                type="textarea"
                inputProps={{
                  'data-inputkey': 'note',
                }}
                onChange={handleChange}
                value={note}
                variant="outlined"
                minRows={6}
                multiline={true}
              />
            </FormControl>
          </NoteContainer>
          <ButtonsContainer>
            <ButtonPrimary text="Save" onClick={handleSave} />
          </ButtonsContainer>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const TreatmentAmount = ({ row }) => {
  const [isEditable, setIsEditable] = useState(true);
  const [amount, setAmount] = useState(row.treatmentCost);

  const toggleIsEditable = () => {
    setIsEditable(!isEditable);
  };

  const onAmountChange = (val) => setAmount(val);

  const handleChangeTreatmentAmount = () => {
    row?.changeTreatmentAmount && row?.changeTreatmentAmount(Number(CurrencyUtil.rawValue(amount)));
  };

  const isRowActive = row.status === STATUSES.ACTIVE;

  return isEditable ? (
    <RemainingBalanceTop>
      <FixedDecimalFormat data-tag="allowRowEvents" amount={row.treatmentCost} prefix={'$'} />
      {isRowActive ? (
        <IconSpace>
          <FontAwesomeIcon icon={faPen} onClick={toggleIsEditable} />
        </IconSpace>
      ) : null}
    </RemainingBalanceTop>
  ) : (
    <Flex>
      <AmountField value={row.treatmentCost} onAccept={onAmountChange} overwrite={false} />
      <IconSpace>
        <FontAwesomeIcon icon={faCheck} onClick={handleChangeTreatmentAmount} color={theme.main.green} size="2x" />
      </IconSpace>
      <IconSpace>
        <FontAwesomeIcon icon={faTimes} onClick={toggleIsEditable} color={theme.main.red} size="2x" />
      </IconSpace>
    </Flex>
  );
};

const PaidAmount = ({ row }) => {
  const [isEditable, setIsEditable] = useState(true);
  const [amount, setAmount] = useState(row.paidAmount);

  const toggleIsEditable = () => {
    setIsEditable(!isEditable);
  };

  const onAmountChange = (val) => setAmount(val);

  const handleChangeTreatmentAmount = () => {
    row?.changeTreatmentAmount && row?.changePaidAmount(Number(CurrencyUtil.rawValue(amount)));
  };

  const isPaidInFull = row.treatmentCost !== 0 && row.paidAmount === row.treatmentCost;
  const isRowActive = row.status === STATUSES.ACTIVE;

  return isEditable ? (
    <RemainingBalanceTop>
      {isPaidInFull ? (
        <PaidInFull>PAID IN FULL</PaidInFull>
      ) : (
        <FixedDecimalFormat data-tag="allowRowEvents" amount={row.paidAmount} prefix={'$'} />
      )}
      {isRowActive ? (
        <IconSpace>
          <FontAwesomeIcon icon={faPen} onClick={toggleIsEditable} />
        </IconSpace>
      ) : null}
    </RemainingBalanceTop>
  ) : (
    <Flex>
      <AmountField value={row.paidAmount} onAccept={onAmountChange} overwrite={false} />
      <IconSpace>
        <FontAwesomeIcon icon={faCheck} onClick={handleChangeTreatmentAmount} color={theme.main.green} size="2x" />
      </IconSpace>
      <IconSpace>
        <FontAwesomeIcon icon={faTimes} onClick={toggleIsEditable} color={theme.main.red} size="2x" />
      </IconSpace>
    </Flex>
  );
};

export const usePatientTrackerListTable = ({ sendEmail, sendSms }: Props) => {
  const client = useApolloClient();
  const { organization } = useStore();
  const alert = useAlert();
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const { emitEvent } = useEventBus();

  const getPaymentCalculationData = async (values) => {
    const { data } = await client.query({
      query: CALCULATE_PAYMENT,
      variables: {
        input: {
          amount: Number(values.treatmentCost),
          term: 12,
          creditGrade: 'GREAT',
          organizationId: organization?.id,
        },
      },
    });

    return data;
  };

  const sendApplicationLink = async (row) => {
    try {
      const { data } = await sendOrganizationLink({
        variables: { input: { idOrganization: organization?.id, phone: row.customer.phone } },
      });

      if (data?.sendOrganizationLink?.success) {
        if (row.financingStatus === 'APPLICATION_NOT_SENT') {
          await row.onFinancingChange({ value: 'APPLICATION_NOT_STARTED' });
        }

        alert.success('Sent over application link successfully');
      } else {
        alert.error('An error occurred. Please try again.');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleActionClick = async (data, row) => {
    let paymentCalculationData;
    switch (data.value) {
      case 'VIEW_TREATMENT_PLAN':
        paymentCalculationData = await getPaymentCalculationData(row);
        emitEvent(BusEvent.OPEN_MONTHLY_PAYMENT_OPTIONS, row);
        break;
      case 'TEXT_PLAN':
        paymentCalculationData = await getPaymentCalculationData(row);
        sendSms(row.customer.phone, paymentCalculationData, row);
        break;
      case 'EMAIL_PLAN':
        paymentCalculationData = await getPaymentCalculationData(row);
        sendEmail(row.customer.email, paymentCalculationData, row);
        break;
      case 'TEXT_APPLICATION_LINK':
        sendApplicationLink(row);
        break;
      case 'ARCHIVE':
        row.changeStatus('ARCHIVED');
        break;
      case 'UNARCHIVE':
        row.changeStatus('ACTIVE');
        break;
      default:
        break;
    }
  };

  const dropdownRow = (row) => {
    const NewDropDown = DropDown;
    const options = ACTION_OPTIONS(row).filter((option) => !option.isHidden);
    return (
      <DropdownContainer>
        <NewDropDown
          id={`drop-${row.id}`}
          options={options}
          // tslint:disable-next-line: jsx-no-lambda
          onChange={(data) => handleActionClick(data, row)}
          showAllItem={true}
          customSelector={true}
          hoveredMode={false}
        />
      </DropdownContainer>
    );
  };

  const getCTARow = (row) => {
    return (
      <DropdownContainer color={STAGE_TO_COLOR[row.stage]}>
        <DropDown
          hoveredMode={false}
          id={`drop-${row.id}-stage`}
          options={TreatmentStageOptions}
          textColor={'#0E202F'}
          onChange={row.onStageChange}
          defaultValue={row.stage}
          showAllItem={true}
          fontSize="12px"
          bypassSchedule={true}
          disabled={row.status !== STATUSES.ACTIVE}
        />
      </DropdownContainer>
    );
  };

  const getFinancingRow = (row) => {
    if (row.financingStatus === 'FINANCED' || row.financingStatus === 'APPROVED') {
      return (
        <div>
          {/* {row.financingStatus === 'FINANCED' ? (
            <div>
              {'Financed: '}
              <span>
                <FixedDecimalFormat amount={row.financedAmount} prefix={'$'} />
              </span>
            </div>
          ) : null} */}
          <div>
            {'Available: '}
            <span>
              <FixedDecimalFormat amount={row.application?.balanceAvailable} prefix={'$'} />
            </span>
          </div>
        </div>
      );
    }

    return formatTextCapitalize(row.financingStatus);
  };

  const getNoteEditRow = (row) => <EditNote initialNote={row.note} onEditNote={row.onNoteChange} />;

  return [
    {},
    {
      name: 'PATIENT',
      selector: (row) => row.customer.firstName,
      cell: (row) => (
        <TextContainer>
          {row.status === 'ARCHIVED' ? <b>Archived</b> : null}
          <Text>
            {row?.customer?.firstName} {row?.customer?.lastName}
          </Text>
          <SmallText>{formatPhoneNumber(row?.customer?.phone)}</SmallText>
        </TextContainer>
      ),
      maxWidth: '10%',
      minWidth: '10%',
      ignoreRowClick: true,
    },
    {
      name: 'TREATMENT COST',
      selector: (row) => row.treatmentCost,
      sortable: false,
      cell: (row) => {
        return <TreatmentAmount row={row} />;
      },
      maxWidth: '14%',
      minWidth: '14%',
      ignoreRowClick: true,
    },
    {
      name: 'PAID AMOUNT',
      selector: (row) => row.paidAmount,
      sortable: false,
      cell: (row) => {
        return <PaidAmount row={row} />;
      },
      maxWidth: '14%',
      minWidth: '14%',
      ignoreRowClick: true,
    },
    {
      name: 'UNPAID AMOUNT',
      selector: (row) => row.paidAmount,
      sortable: false,
      cell: (row) => {
        return (
          <FixedDecimalFormat data-tag="allowRowEvents" amount={row.treatmentCost - row.paidAmount} prefix={'$'} />
        );
      },
      maxWidth: '10%',
      minWidth: '10%',
      ignoreRowClick: true,
    },
    {
      name: 'STAGE',
      selector: (row) => row.stage,
      sortable: false,
      cell: (row) => {
        return getCTARow(row);
      },
      minWidth: '19%',
      maxWidth: '19%',
      ignoreRowClick: true,
    },
    {
      name: 'FINANCING',
      selector: (row) => row.financingStatus,
      sortable: false,
      cell: (row) => {
        return getFinancingRow(row);
      },
      minWidth: '15%',
      maxWidth: '15%',
      ignoreRowClick: true,
    },
    {
      name: 'Notes',
      selector: (row) => row.notes,
      minWidth: '8%',
      maxWidth: '8%',
      cell: (row) => {
        return <div>{row?.note?.substring(0, 60) + (row?.note.length > 60 ? '...' : '')}</div>;
      },
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.notes,
      cell: getNoteEditRow,
      minWidth: '5%',
      maxWidth: '5%',
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: dropdownRow,
      minWidth: '5%',
      maxWidth: '5%',
      ignoreRowClick: true,
    },
  ];
};

const Text = styled.p`
  margin: 0;
  line-height: 16px;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
`;

const TextContainer = styled.div`
  padding: 15px 8px;
  margin: 4px 0;
`;

const DropdownContainer = styled.div<{ color?: string }>`
  .dropdown-right {
    min-width: 100px;
    padding: 10px 12px;
    border-radius: 16px;
    white-space: nowrap;
    background-color: ${(props) => props.color};
    border: ${(props) => props.color === '#fff' && '1px solid rgba(231, 233, 234, 1)'};
    svg {
      color: ${(props) => props.theme.main.green};
      margin-left: 10px;
    }
  }
  .dropdown-left {
    padding: 0px !important;

    .dropdown-content {
      left: -100px;
    }
  }
`;

const RemainingBalanceTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const PopoverTitle = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const PopoverContent = styled.div`
  width: 420px;
`;

const NoteContainer = styled.div`
  padding: 24px;
`;

const ButtonsContainer = styled.div`
  height: 64px;
  padding: 16px;
  background-color: rgba(242, 244, 245, 1);
  display: flex;
  align-items: center;
  justify-content: end;
`;

const IconSpace = styled.div`
  margin-left: 4px;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PaidInFull = styled.div`
  padding: 4px 6px 5px 6px;
  border-radius: 16px;
  gap: 4px;
  background: rgba(230, 249, 242, 1);

  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-align: center;
  color: rgba(0, 195, 125, 1);
`;
