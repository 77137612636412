import { GeneralAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import StorageService from 'lib/services/Storage';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent } = useSegment();
  const navigate = useNavigate();

  const logout = () => {
    StorageService.clearUserData();
    trackEvent({
      action: GeneralAnalyticsEventNames.MENU_LOGOUT,
    });
    trackSegmentEvent('PRACTICE_PORTAL.LOGOUT');
    navigate('/login');
  };

  return { logout };
};
