import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';

import { PATCH_LOAN } from 'lib/graphql/mutations';
import { useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { StepEnums } from 'lib/types';

interface UseLoanCancelParams {
  setActiveStep?: (val: any) => void;
  loanUpdated?: (val: any) => void;
  closeExpanded?: () => void;
  applicationInProcess?: any;
}

export const useLoanCancel = ({
  setActiveStep,
  loanUpdated,
  closeExpanded,
  applicationInProcess,
}: UseLoanCancelParams) => {
  const { merchantLogout, merchantLogin } = useMerchantLogin();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { merchants } = useStore();
  const alert = useAlert();
  const [patchLoan] = useMutation(PATCH_LOAN);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedMerchantId = merchants?.[0]?.id;

  const cancelLoan = useCallback(
    async (type: string, activeLoanId: number = applicationInProcess?.activeLoanId) => {
      setActiveStep && setActiveStep(StepEnums.LOADING);
      setLoading(true);
      try {
        trackSegmentEvent('Checkout Cancelled', {
          application: applicationName,
          applicationId: applicationInProcess?.applicationId,
          borrowerId: applicationInProcess?.borrowerId,
          merchantId: merchants?.length === 1 ? selectedMerchantId : null,
          checkoutType: 'Link cancelled',
        });
        selectedMerchantId && (await merchantLogin(selectedMerchantId));

        const { data } = await patchLoan({
          variables: {
            input: {
              loanId: activeLoanId,
              status: 'CANCELLED',
            },
          },
        });
        merchantLogout();
        if (data.patchLoan.id) {
          setSuccess(true);
          setLoading(false);
          if (type !== 'EDIT') {
            loanUpdated && loanUpdated(StepEnums.CHECKOUT_SUCCESS_CANCEL);
            setActiveStep && setActiveStep(StepEnums.CHECKOUT_SUCCESS_CANCEL);
          }
        } else {
          throw Error();
        }
      } catch (e: any) {
        setSuccess(false);
        setLoading(false);
        merchantLogout();
        if (e?.response?.data?.message === 'loan.status.invalid') {
          alert.error('The information for this contract was no longer current, your page was refreshed');
          return;
        } else {
          alert.error(`An error occurred please try again`);
          closeExpanded && closeExpanded();
          return;
        }
      }
    },
    [StepEnums.CHECKOUT_FORM, StepEnums.CHECKOUT_SUCCESS_CANCEL],
  );

  return { cancelLoan, success, loading };
};
