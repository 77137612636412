import images from 'assets/images';
import React from 'react';
import styled from 'styled-components';

export const ResponsiveNotSupported = () => (
  <CenterItem>
    <Container>
      <CherryLogo src={images.cherryLogo.default} />
      <CenterTitle>Whoops!</CenterTitle>
      <Svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M98.5469 9.9375H7.45312C5.91622 9.9392 4.44275 10.5505 3.35599 11.6372C2.26923 12.724 1.65795 14.1975 1.65625 15.7344V77.0156C1.65795 78.5525 2.26923 80.026 3.35599 81.1128C4.44275 82.1995 5.91622 82.8108 7.45312 82.8125H41.0419L40.0481 87.7812H33.125C32.6857 87.7812 32.2645 87.9557 31.9539 88.2663C31.6432 88.577 31.4688 88.9982 31.4688 89.4375V94.4062C31.4688 94.8455 31.6432 95.2668 31.9539 95.5774C32.2645 95.888 32.6857 96.0625 33.125 96.0625H72.875C73.3143 96.0625 73.7355 95.888 74.0462 95.5774C74.3568 95.2668 74.5312 94.8455 74.5312 94.4062V89.4375C74.5312 88.9982 74.3568 88.577 74.0462 88.2663C73.7355 87.9557 73.3143 87.7812 72.875 87.7812H65.9519L64.9581 82.8125H98.5469C100.084 82.8108 101.557 82.1995 102.644 81.1128C103.731 80.026 104.342 78.5525 104.344 77.0156V15.7344C104.342 14.1975 103.731 12.724 102.644 11.6372C101.557 10.5505 100.084 9.9392 98.5469 9.9375ZM71.2188 91.0938V92.75H34.7812V91.0938H71.2188ZM43.4269 87.7812L44.4206 82.8125H61.5794L62.5731 87.7812H43.4269ZM101.031 77.0156C101.031 77.6744 100.769 78.3059 100.303 78.7717C99.8372 79.2375 99.2056 79.4995 98.5469 79.5H7.45312C6.7944 79.4995 6.1628 79.2375 5.69701 78.7717C5.23122 78.3059 4.9693 77.6744 4.96875 77.0156V72.875H101.031V77.0156ZM101.031 69.5625H4.96875V15.7344C4.9693 15.0756 5.23122 14.4441 5.69701 13.9783C6.1628 13.5125 6.7944 13.2505 7.45312 13.25H98.5469C99.2056 13.2505 99.8372 13.5125 100.303 13.9783C100.769 14.4441 101.031 15.0756 101.031 15.7344V69.5625Z"
          fill="black"
        />
        <path
          d="M54.6562 74.5312H51.3438C50.9045 74.5312 50.4832 74.7057 50.1726 75.0164C49.862 75.327 49.6875 75.7482 49.6875 76.1875C49.6875 76.6268 49.862 77.048 50.1726 77.3586C50.4832 77.6693 50.9045 77.8438 51.3438 77.8438H54.6562C55.0955 77.8438 55.5168 77.6693 55.8274 77.3586C56.138 77.048 56.3125 76.6268 56.3125 76.1875C56.3125 75.7482 56.138 75.327 55.8274 75.0164C55.5168 74.7057 55.0955 74.5312 54.6562 74.5312Z"
          fill="black"
        />
      </Svg>
      <CenterText>We’re sorry, this site is optimized for desktop access.</CenterText>
      <CenterText>
        If you are on an iOS device you can download the Cherry Merchant App{' '}
        <AppStoreLink target="_blank" href="https://apps.apple.com/us/app/cherry-point-of-sale/id1472247158">
          here.
        </AppStoreLink>
      </CenterText>
    </Container>
  </CenterItem>
);

const CherryLogo = styled.img`
  width: 158px;
  height: auto;
`;

const CenterItem = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #0e1f2e;
  box-sizing: border-box;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 10px 40px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
`;

const CenterTitle = styled.p`
  text-align: center;
  color: #0e202f;
  font-size: 18px;
  line-height: 21.09px;
  margin: 16px 0;
`;

const CenterText = styled.p`
  text-align: center;
  color: #0e202f;
  font-size: 14px;
  line-height: 16.41px;
  margin: 16px 0 0 0;
`;

const AppStoreLink = styled.a`
  color: #0e202f;

  &:active,
  &:visited,
  &:focus {
    color: #0e202f;
  }
`;

const Svg = styled.svg`
  margin: 30px 0 30px 0;
`;
