import { Steps } from 'intro.js-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import StorageService from 'lib/services/Storage';

import 'intro.js/introjs.css';
import '../Tour.css';

export const CherrySteps = () => {
  const [hints, setHints] = useState<any>([]);
  const onboardingStatus: boolean = JSON.parse(StorageService.getOnboardingStatus() || 'false');
  const onExit = () => StorageService.setOnboardingStatus(true);

  useEffect(() => {
    setTimeout(() => {
      const steps = [
        {
          element: '#text--application',
          position: 'right',
          intro:
            'Send an application to your patient via text instantly by simply inputing their mobile phone number. Applying with Cherry won’t affect your patients’ credit scores.',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '#payment--estimator',
          intro:
            'Give your patients a preview of what their payments could be either in-office or over the phone. Input the approximate purchase amount, choose the monthly duration and select credit score band.',
          position: 'right',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '#applications--listing >div:first-child',
          position: 'left',
          intro:
            'All of your approvals show up here. Approvals are good for up to 30 days. Patients can come back to use their remaining balance if the full approval amount is not redeemed.',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.rdt_TableRow#row-0 > div:nth-child(7)',
          intro:
            'When your patient is ready. Send them a checkout link. Patients will be able to select and confirm their payment plan on their own mobile device.',
          position: 'right',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.rdt_TableRow#row-0 > div:nth-child(6)',
          intro:
            'As approvals roll in, you and your staff can manage these approvals by updating the status of these leads. Is a patient coming in next week? Set a date here so everyone in your office can be on the same page.',
          position: 'left',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },

        {
          element: '#aside--menu > #MENU_TRAINING',
          position: 'right',
          intro:
            'Do you need a refresher or did you just hire new staff? Select this tab for all your training needs. ',
          tooltipClass: 'asideCherryTooltip',
          highlightClass: 'asideMenu--tooltip',
        },
        {
          element: '#aside--menu > #MENU_MARKETING',
          position: 'right',
          intro: 'Need help marketing your services or Cherry? We have templates, guides, and other resources here.',
          tooltipClass: 'asideCherryTooltip lastItem',
          highlightClass: 'asideMenu--tooltip',
        },
      ];
      setHints(steps);
    }, 3000);
  }, [onboardingStatus]);

  const onStepChange = (activeStep: number) => {
    if (activeStep === 3)
      document.querySelector('.rdt_TableRow#row-0 > div:nth-child(7)')?.classList.add('guide--active');
    else document.querySelector('.rdt_TableRow#row-0 > div:nth-child(7)')?.classList.remove('guide--active');
  };

  return (
    <StyledSteps
      key="cherry-steps"
      enabled={!onboardingStatus}
      onChange={onStepChange}
      options={{
        showStepNumbers: false,
        keyboardNavigation: false,
        overlayOpacity: 0.8,
        scrollToElement: true,
        scrollTo: 'element',
        hideNext: true,
        nextToDone: false,
        disableInteraction: true,
        exitOnOverlayClick: false,
        skipLabel: 'x',
        doneLabel: 'Done',
      }}
      steps={hints}
      initialStep={0}
      onExit={onExit}
    />
  );
};

const StyledSteps = styled(Steps)`
  background-color: #ff0000 !important;
  .introjs-tooltipbuttons {
    display: none !important;
  }
`;
