import { useState } from 'react';

import { CurrencyInput } from '@frontend/cherry-library';

import images from 'assets/images';
import { useSegment } from 'lib/hooks/useSegment';

import { usePaymentEstimator } from 'lib/hooks/usePaymentEstimator';
import {
  CardSubtitle,
  CardTitle,
  Image,
  StyledDownCircle,
  StyledPopover,
  StyledPopoverContent,
  StyledPopoverText,
  StyledPopoverWrapper,
} from 'pages/desktop/Dashboard/DentalDashboard';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const PaymentEstimatorCard = () => {
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const navigate = useNavigate();

  const {
    treatmentAmount,
    validPurchaseAmountRange,
    purchaseAmountErrorMsg,
    onPurchaseAmountChange,
  } = usePaymentEstimator();

  const [paymentEstimatorPopoverOpen, setPaymentEstimatorPopoverOpen] = useState(false);

  const handlePaymentEstimatorToggle = () => {
    setPaymentEstimatorPopoverOpen(!paymentEstimatorPopoverOpen);

    if (paymentEstimatorPopoverOpen) {
      trackSegmentEvent('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_CARD.INFO_TOOLTIP_HOVER_VIEWED');
    }
  };

  const navigateWithValue = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_CARD.CLICKED');
    trackSegmentEvent('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.VIEWED');
    navigate('/payment-estimator', { state: { amount: treatmentAmount } });
  };

  return (
    <StyledPaymentEstimatorCard hasError={!validPurchaseAmountRange}>
      <StyledPopoverWrapper onMouseEnter={handlePaymentEstimatorToggle} onMouseLeave={handlePaymentEstimatorToggle}>
        <Image src={images.infoCircleOutline.default} />
        {paymentEstimatorPopoverOpen && (
          <StyledPopover>
            <StyledDownCircle />
            <StyledPopoverContent>
              <StyledPopoverText>
                Use the payment estimator as a consultation or marketing tool to express treatment costs in terms of
                monthly payments.
              </StyledPopoverText>
            </StyledPopoverContent>
          </StyledPopover>
        )}
      </StyledPopoverWrapper>
      <CardTitle>Payment Estimator</CardTitle>
      <CardSubtitle>Estimate and share monthly payment options with a patient</CardSubtitle>
      <CurrencyInput
        inputProps={{
          'data-testid': 'payment-estimator-card-input',
        }}
        name="treatmentAmount"
        label="Purchase Amount"
        onChange={onPurchaseAmountChange}
        actionButton={{
          text: 'Estimate',
          onClick: () => {
            navigateWithValue();
          },
        }}
        onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_CARD.PURCHASE_AMOUNT_FILLED`)}
        onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_CARD.PURCHASE_AMOUNT_SELECTED`)}
        value={treatmentAmount}
        errorText={purchaseAmountErrorMsg ?? undefined}
        error={!validPurchaseAmountRange}
      />
    </StyledPaymentEstimatorCard>
  );
};

export const StyledPaymentEstimatorCard = styled.div<{ hasError: boolean }>`
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02) !important;
  border-radius: 8px !important;
  background: ${(props) => props?.theme?.main?.white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 202px;
  box-sizing: border-box;
  overflow: visible !important;
  ${(props) => props.hasError && `padding-bottom: 8px;`}
`;
