import {
  MARKETING_MATERIAL_IDS,
  MARKETING_MATERIAL_QUANTITIES,
  MARKETING_MATERIAL_REQUEST_REASON,
  MARKETING_MATERIAL_TYPES,
} from 'lib/constants';

export const getRequestReasonLabel = (reasonType: MARKETING_MATERIAL_REQUEST_REASON) => {
  switch (reasonType) {
    case MARKETING_MATERIAL_REQUEST_REASON.OUT_OF_ITEM:
      return 'Ran out of this item';
    case MARKETING_MATERIAL_REQUEST_REASON.DAMAGED:
      return 'Item is damaged';
    case MARKETING_MATERIAL_REQUEST_REASON.NEVER_RECEIVED:
      return 'Never received this item';
    case MARKETING_MATERIAL_REQUEST_REASON.OTHER:
      return 'Other';
  }
};

export const REQUEST_REASONS = Object.values(MARKETING_MATERIAL_REQUEST_REASON).map((reason) => ({
  value: reason,
  label: getRequestReasonLabel(reason),
}));

export const getMaterialTypeById = (id: number) => {
  switch (id) {
    case MARKETING_MATERIAL_IDS.TRIFOLD_BROCHURE:
      return MARKETING_MATERIAL_TYPES.BROCHURE;
    case MARKETING_MATERIAL_IDS.PATIENT_ONE_PAGER:
      return MARKETING_MATERIAL_TYPES.ONE_PAGER;
    case MARKETING_MATERIAL_IDS.QR_CARD:
      return MARKETING_MATERIAL_TYPES.QR_CARD;
    case MARKETING_MATERIAL_IDS.QR_CODE:
      return MARKETING_MATERIAL_TYPES.QR_CODE;
    case MARKETING_MATERIAL_IDS.FULL_KIT:
      return MARKETING_MATERIAL_TYPES.FULL_KIT;
    default:
      return;
  }
};

export const getQuantities = (id: number) => {
  const type = getMaterialTypeById(id);

  if (type) {
    return MARKETING_MATERIAL_QUANTITIES[type].map((value) => ({ value: String(value), label: String(value) }));
  }

  return [];
};
