import { useApolloClient } from '@apollo/client';

import { GET_PRODUCT_CALCULATIONS, GET_PRODUCT_CALCULATIONSV2 } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';

interface Payments {
  date: string;
  amount: number;
  fallback: number;
}

interface Product {
  applicationId: number;
  apr: number;
  chosenDownPaymentAmount: number;
  createdAt: string;
  creditLineMaxEligible: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  downPayment: any;
  downPaymentType: string;
  fallbackApr: number;
  id: number;
  maxEligible: number;
  mdf: number;
  menuId: string;
  minEligible: number;
  moduleString: string;
  parentId?: number;
  periodLength: string;
  promoApr: number;
  promoMdf: number;
  promoTerm: number;
  status: string;
  term: number;
  tierId: number;
}

interface VariableDownPayment {
  hasOptionToModify: boolean;
  minEligible: number;
  maxEligible: number;
  changeRange: number;
  operandRange: number;
}

interface CalculationData {
  downPaymentAmount: number;
  financeCharge: number;
  grossAmount: number;
  installmentAmount: number;
  loanAmount: number;
  merchantFund: number;
  merchantRevenue: number;
  promo: boolean;
  totalAmount: number;
  paymentDates: string[];
  payments: Payments[];
  product: Product;
  variableDownPayment: VariableDownPayment;
}

const SortWithProductTerm = (data: CalculationData[] | null) => {
  return data?.slice()?.sort((a, b) => {
    return a?.product?.term - b?.product?.term;
  });
};

export const useProductCalculation = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();

  const getProductCalculation = async (applicationId: string | number, amount: number | undefined) => {
    if (applicationId && amount) {
      const { data } = await client.query({
        query: GET_PRODUCT_CALCULATIONS,
        variables: {
          input: {
            applicationId,
            amount,
          },
        },
      });

      if (data?.getProductCalculations?.data?.length && Array.isArray(data?.getProductCalculations?.data)) {
        try {
          const cloneData = [...data?.getProductCalculations?.data];
          const sortedData = SortWithProductTerm(cloneData);

          return sortedData;
        } catch (err) {
          captureException('Product Calculation Sorting Error');
          return data?.getProductCalculations?.data;
        }
      }
      return [];
    } else {
      return [];
    }
  };

  const getProductCalculationV2 = async (applicationId: string | number, amount: number | undefined) => {
    if (applicationId && amount) {
      const { data } = await client.query({
        query: GET_PRODUCT_CALCULATIONSV2,
        variables: {
          input: {
            applicationId,
            amount,
          },
        },
      });

      if (data?.getProductCalculationsV2?.data?.length && Array.isArray(data?.getProductCalculationsV2?.data)) {
        try {
          const cloneData = [...data?.getProductCalculationsV2?.data];
          const sortedData = SortWithProductTerm(cloneData);

          return sortedData;
        } catch (err) {
          captureException('Product Calculation Sorting Error');
          return data?.getProductCalculationsV2?.data;
        }
      }
      return [];
    } else {
      return [];
    }
  };

  return { getProductCalculation, getProductCalculationV2 };
};
