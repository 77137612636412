import { Collapse } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { APPS, LogoutIcon } from 'lib/components';
import { ChevronDown, ChevronLeft, ChevronRight } from 'lib/components';
import { useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useLogout } from 'lib/services';
import { ADMIN_REPORTING_MENU_ITEMS, MENU_ITEMS, POINT_OF_SALE_MENU_ITEMS } from 'lib/utils';

interface SidebarProps {
  opened: boolean;
  toggleFunc: () => void;
  forceOpenSidebar: () => void;
  isSnackbarVisible: boolean;
}

interface ContainerProps {
  opened: boolean;
}
interface ContainerPropsDemo extends ContainerProps {
  isDemo: boolean | undefined;
}
interface LinkTextProps {
  opened: boolean;
  active?: boolean;
}
interface MenuItemProps {
  active: boolean;
  analyticevent?: string;
  isDemo?: boolean;
  opened?: boolean;
  collapsed?: boolean;
}

export const Sidebar = ({ opened, toggleFunc, forceOpenSidebar, isSnackbarVisible }: SidebarProps) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { permissions = {}, isAdminReporting, clearStore, merchants, organization } = useStore();
  const { logout: handleLogout } = useLogout();

  const [collapsed, setCollapsed] = useState(false);

  const logout = () => {
    clearStore();
    handleLogout();
  };

  const forceOpenSidebarHandler = (event) => {
    forceOpenSidebar();
    logRouteClick(event);
  };

  const logRouteClick = (event) => {
    try {
      const eventName = event.currentTarget.getAttribute('data-event');
      const segmentEventName = event.currentTarget.getAttribute('data-segmentEvent');
      const MARKETING_MENU_ITEMS = MENU_ITEMS.find((menuItem) => menuItem.level === 'MARKETING')?.children;

      const findCurrentPageName =
        MENU_ITEMS.find((menuItem) => menuItem.pathname === pathname)?.name ||
        MARKETING_MENU_ITEMS?.find((marketingMenuItem) => marketingMenuItem.pathname === pathname)?.segmentName;

      const findPushedPageName =
        MENU_ITEMS.find((item) => item.dataEvent === eventName)?.name ||
        MARKETING_MENU_ITEMS?.find((marketingMenuItem) => marketingMenuItem.dataEvent === eventName)?.segmentName;

      trackSegmentEvent('Sidebar Navigation', {
        application: applicationName,
        sourcePage: findCurrentPageName,
        destinationPage: findPushedPageName,
      });
      trackSegmentEvent(segmentEventName, {
        merchantId: merchants?.[0]?.id,
        industry: organization?.industry,
        segment: findPushedPageName,
      });
      trackEvent({
        action: eventName,
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const collapseClicked = () => {
    setCollapsed(!collapsed);
  };

  const toggleSidebarHandler = () => {
    collapsed && setCollapsed(false);
    toggleFunc();
  };

  const getCurrentApp = () => {
    if (location.pathname.includes('membership')) {
      return APPS.MEMBERSHIP;
    }
    if (location.pathname.includes('shop')) {
      return APPS.SHOP;
    }
    if (location.pathname.includes('point-of-sale')) {
      return APPS.POINT_OF_SALE;
    }

    return APPS.MAIN;
  };

  const getMenuItems = () => {
    switch (getCurrentApp()) {
      case APPS.MAIN:
        return MENU_ITEMS;
      case APPS.POINT_OF_SALE:
        return POINT_OF_SALE_MENU_ITEMS;
      default:
        return MENU_ITEMS;
    }
  };

  return (
    <Container opened={opened} isDemo={isSnackbarVisible}>
      <MenuWrapper>
        <Menu id="aside--menu">
          <ToggleContainer opened={opened} onClick={toggleSidebarHandler}>
            {opened ? <ChevronLeft /> : <ChevronRight />}
          </ToggleContainer>

          {Boolean(isAdminReporting)
            ? ADMIN_REPORTING_MENU_ITEMS.map((item) => (
                <MenuItem
                  data-testid="menu-item"
                  key={item.id}
                  id={item.dataEvent}
                  active={pathname === item.pathname}
                  data-event={item.dataEvent}
                  onClick={logRouteClick}
                  opened={opened}
                >
                  <MenuLink opened={opened} to={item.pathname}>
                    <Label> {item.name}</Label>
                    {item.icon && <item.icon active={pathname === item.pathname} />}
                    <LinkText active={pathname === item.pathname} opened={opened}>
                      {opened ? item.name : ''}
                    </LinkText>
                  </MenuLink>
                </MenuItem>
              ))
            : getMenuItems().map((node: any) => {
                const items: any = [];

                if (Object.keys(permissions).includes(node.level)) {
                  if (node.children) {
                    items.push(
                      <>
                        <MenuItem
                          data-testid="menu-item"
                          key={node.id}
                          id={node.dataEvent}
                          active={pathname === node.pathname}
                          data-event={node.dataEvent}
                          data-segmentEvent={node.segmentEvent}
                          onClick={forceOpenSidebarHandler}
                          opened={opened}
                          collapsed={collapsed}
                        >
                          <CollapsedLink opened={opened} onClick={collapseClicked}>
                            <Label> {node.name} </Label>
                            {node.icon && <node.icon active={pathname === node.pathname} />}
                            <LinkText active={pathname === node.pathname} opened={opened}>
                              {node.name}
                            </LinkText>
                          </CollapsedLink>
                          <StyledChevronDown onClick={collapseClicked} />
                        </MenuItem>
                        <Collapse in={collapsed} timeout="auto" unmountOnExit={true}>
                          <CollapseContainer>
                            {node.children.map((child, key) => (
                              <CollapseText
                                key={key}
                                onClick={logRouteClick}
                                data-testid="children-item"
                                data-event={child.dataEvent}
                                data-segmentEvent={node.segmentEvent}
                                to={child.pathname}
                                active={pathname === child.pathname}
                                opened={opened}
                              >
                                {child.name}
                              </CollapseText>
                            ))}
                          </CollapseContainer>
                        </Collapse>
                      </>,
                    );
                  } else {
                    items.push(
                      <MenuItem
                        data-testid="menu-item"
                        key={node.id}
                        id={node.dataEvent}
                        active={pathname === node.pathname}
                        data-event={node.dataEvent}
                        data-segmentEvent={node.segmentEvent}
                        onClick={logRouteClick}
                        opened={opened}
                      >
                        <MenuLink opened={opened} to={node.pathname}>
                          <Label> {node.name}</Label>
                          {node.icon && <node.icon active={pathname === node.pathname} />}
                          <LinkText active={pathname === node.pathname} opened={opened}>
                            {opened ? node.name : ''}
                          </LinkText>
                        </MenuLink>
                      </MenuItem>,
                    );
                  }
                }

                return items;
              })}
        </Menu>
        <Menu isDemo={isSnackbarVisible}>
          <MenuItem
            data-testid="logout"
            key={99}
            active={false}
            analyticevent={'Logout'}
            onClick={logout}
            isDemo={isSnackbarVisible}
            opened={opened}
          >
            <MenuLink opened={opened} to={'logout'}>
              <LogoutIcon />
              <LinkText opened={opened} active={false}>
                Log Out
              </LinkText>
            </MenuLink>
          </MenuItem>
        </Menu>
      </MenuWrapper>
    </Container>
  );
};

const Container = styled.div<ContainerPropsDemo>`
  background-color: #0d202f;
  width: ${(props) => (props.opened ? 240 : 80)}px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  position: fixed;
  top: ${(props) => (props.isDemo ? 132 : 92)}px;
  left: 0;
  height: calc(100vh - 92px);
  transition: width 0.4s;
  transition-timing-function: ease-in-out;
  z-index: 10;
  @media print {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (min-height: 900px) {
    &::-webkit-scrollbar {
      display: none;
    }
    &: {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
`;

const ToggleContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${(props) => (props.opened ? 'flex-end' : 'center')};
  margin: 18px 20px 34px;
  cursor: pointer;
`;

const Menu = styled.ul<{ isDemo?: boolean }>`
  margin-left: 0;
  width: 100%;
  padding: ${(props) => (props.isDemo ? '20px 5px' : '0px 0px')};
`;

const MenuItem = styled.li<MenuItemProps>`
  list-style-type: none;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: ${(props) => (props.isDemo ? '8px 8px 8px 24px' : '8px 8px 8px 24px')};
  padding: 8px 8px 8px 24px;
  margin-bottom: 24px;
  margin-right: ${(props) => (props.opened ? '0px' : '16px')};
  margin-left: ${(props) => (props.opened ? '0' : '14px')};
  box-sizing: border-box;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  // &:hover {
  //   svg {
  //     filter: brightness(0) invert(1);
  //   }
  // }

  ${(props) =>
    props.active &&
    `
    background-color: rgba(0,195,124,0.2);
    border-radius: 4px;
  `}
`;

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 8px 8px 24px;
`;

const Label = styled.div`
  display: none;
  padding: 5px;
  background-color: ${(props) => props.theme.main.green};
  border-radius: 4px;
  position: absolute;
  left: 80px;
  z-index: 5;
  color: white;

  &:before {
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.theme.main.green};
    left: -5px;
    top: 10px;
    position: absolute;
    transform: rotateZ(45deg);
    content: '';
  }
`;

const MenuLink = styled(Link)<ContainerProps>`
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
  text-decoration: none;
  margin-left: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: initial;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${Label} {
    display: ${(props) => !props.opened && 'block'};
    z-index: 999999;
    margin-left: 5px;
  }
`;

const CollapsedLink = styled.div<ContainerProps>`
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
  text-decoration: none;
  margin-left: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: intial;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover ${Label} {
    display: ${(props) => !props.opened && 'block'};
    z-index: 999999;
    margin-left: 5px;
  }
`;

const LinkText = styled.p<LinkTextProps>`
  width: ${(props) => (props.opened ? 'calc(100%)' : 0)};
  margin: 0 0 0 26px;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  transition-delay: ${(props) => (props.opened ? '0.4s' : '0s')};
  transition-timing-function: ease-in-out;
  transition-property: opacity, width;
  transition-duration: 0.4s;
  color: ${(props) => (props.active ? props.theme.main.green : props.theme.main.white)};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  font-size: 16px;
  line-height: 22px;
`;

const CollapseText = styled(Link)<LinkTextProps>`
  width: ${(props) => (props.opened ? 'calc(100%)' : 0)};
  margin-left: 110px;
  color: ${(props) => (props.active ? props.theme.main.green : props.theme.main.white)};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  text-decoration: none;
  margin-bottom: 20px;
`;

const StyledChevronDown = styled(ChevronDown)`
  cursor: pointer;
`;
