import styled from 'styled-components';

export const SubDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

export const Subtitle = styled.div<{ margin?: string; fontSize?: string; fontWeight?: number }>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  margin: ${(props) => (props.margin ? props.margin : '24px 0')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props?.theme?.main?.textColor};

  img {
    margin-left: 16px;
  }
`;
