import { DashCard, Loading } from 'lib/components';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import React, { Suspense, useRef } from 'react';
import styled from 'styled-components';

const Accordion = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.Accordion })),
);

const AccordionSection = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.AccordionSection })),
);

export const CheatSheetFaq = ({ faqList }) => {
  const { trackEvent } = useAnalytics();
  const activeSectionRef = useRef<any>('');

  const handleRowClicked = (e) => {
    e.target.parentNode.parentNode.parentNode.lastChild.click();
    trackEvent({
      action: TrainingAnalyticsEventNames.FAQ_CLICK,
      label: e.target.dataset.id,
    });
  };
  return (
    <DashCard>
      <Suspense fallback={<Loading fixed={true} size={40} />}>
        <CherryFaq id="faq--accordion" multiple={true}>
          {faqList.map((item) => {
            return (
              <CherryFaqSection
                ref={activeSectionRef}
                key={item.id}
                data-testid="faq-container"
                label={
                  <FaqHeader data-id={item.id} data-testid="faq-header" onClick={handleRowClicked}>
                    {item.title}
                  </FaqHeader>
                }
              >
                {' '}
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </CherryFaqSection>
            );
          })}
        </CherryFaq>
      </Suspense>
    </DashCard>
  );
};

export const CherryFaq = styled(Accordion)`
  li:not(first-child) {
    border-top: 1px solid #dadada;
  }
  border-bottom: 1px solid #dadada;
  margin-bottom: 24px;
  svg {
    fill: ${(props) => props.theme.main.green};
    margin-right: 16px;
  }
`;

export const CherryFaqSection = styled(AccordionSection)`
  button:focus {
    box-shadow: 0 0 0 0;
  }
`;

export const FaqHeader = styled.span`
  font-weight: 700;
  font-size: 14px;
  display: block;
  width: 100%;
  cursor: pointer;
`;
