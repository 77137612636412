import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  border-radius: 2px;
  border: 1px solid #dadada;
  margin-bottom: 20px;
  padding: 10px 15px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;
`;

interface InputWithIconProps {
  caret?: string;
  margin?: string;
  textAlign?: string;
  paddingLeft?: string;
  border?: boolean;
  cursor?: string;
  width?: string;
  zIndex?: number;
  darkPlaceholder?: boolean;
}

export const InputWithIcon = styled(Input)<InputWithIconProps>`
  right: 0;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 42px;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '30px')};
  border-radius: 2px;
  cursor: ${(props) => (props.cursor ? props.cursor : 'unset')};
  border: ${(props) => (props.border ? '1px solid #DADADA' : 'none')};
  text-align: ${(props) => props.textAlign};
  z-index: ${(props) => props.zIndex};
  margin: ${(props) => (props.margin ? props.margin : '0px')} !important;
  caret-color: ${(props) => (props.caret === 'transparent' ? 'transparent' : '#000000')};

  ${(props) =>
    props.darkPlaceholder &&
    `
  ::placeholder {
    color: #0e202f;
  }
  `}
`;

interface InputIconProps {
  color?: string;
  margin?: string;
  cursor?: string;
  right?: string;
  left?: string;
  width?: string;
}

export const InputIcon = styled(FontAwesomeIcon)<InputIconProps>`
  position: absolute;
  margin: ${(props) => (props.margin ? props.margin : 'unset')};
  right: ${(props) => (props.right ? props.right : 'unset')};
  color: ${(props) => (props.color === 'green' ? props.theme.main.green : 'unset')};
  cursor: ${(props) => (props.cursor ? 'pointer' : 'unset')};
  left: ${(props) => (props.left ? props.left : 'unset')};
  width: ${(props) => (props.width ? props.width : 'unset')};
`;

export const PickerWrapper = styled.div`
  width: 100%;
  align-items: center;
  margin-top: 3px;
  display: flex;
  justify-content: center;
`;
