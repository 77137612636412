import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import { SEND_TAKE_HOME_EMAIL, SEND_TAKE_HOME_SMS } from 'lib/graphql/mutations';
import { GET_MERCHANT_DETAIL } from 'lib/graphql/queries';

import images from 'assets/images';
import { CircleCheckIcon, Loading } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { NOTE_LENGTH } from 'lib/constants';
import client from 'lib/graphql/client';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { PaymentEstimateModalInitialData } from 'pages/desktop/Dashboard/Dashboard';
import { EmailInput } from 'pages/desktop/Dashboard/views/TakeHomeApproval/EmailInput';
import { GeneratedTreatmentPlanDocument } from 'pages/desktop/Dashboard/views/TakeHomeApproval/GeneratedTreatmentPlanDocument';
import { PhoneNumberInput } from 'pages/desktop/Dashboard/views/TakeHomeApproval/PhoneNumberInput';
import { ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import { MerchantDetail } from 'pages/desktop/Settings/components/LocationManagement/type';

interface SelectedLocation {
  value: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  emailLabel: string;
}

export interface PreviewData extends PaymentEstimateModalInitialData {
  patientName?: string;
  treatmentCoordinator?: string;
  additionalNotes?: string;
  selectedLocation?: SelectedLocation;
  patientPhone?: string;
  patientEmail?: string;
}

interface Props {
  goBackToFormStep?: () => void;
  previewData: any;
  hideSendPatientSection?: boolean;
  selectedApplicationId: string;
}

export enum PDF_PAGE {
  FIRST = 0,
  SECOND = 1,
}

const isChrome = !!window['chrome'];

export const GenerateTreatmentPlanPreview = ({
  previewData,
  hideSendPatientSection = false,
  selectedApplicationId,
}: Props) => {
  const { flags } = useFlags();
  const { merchants, organization, user } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { getProductCalculation } = useProductCalculation();

  const [sendTakeHomeEmail, { loading: loadingEmail }] = useMutation(SEND_TAKE_HOME_EMAIL);
  const [sendTakeHomeSms, { loading: loadingSms }] = useMutation(SEND_TAKE_HOME_SMS);

  const [page, setPage] = useState<number>(PDF_PAGE.FIRST);
  const [merchantDetail, setMerchantDetail] = useState<MerchantDetail | null>(null);
  const [paymentOptionsTableData, setPaymentOptionsTableData] = useState<any>(null);

  const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState<boolean>(false);
  const [sent, setSent] = useState(false);

  const alert = useAlert();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: `@media all {
      .pagebreak {
        display: none;
        }
    }
    
    @media print {
      html,
      body {
        height: ${isChrome ? 'initial!important;' : '100%'};
        overflow: initial!important;
        zoom: 112%;
        -webkit-print-color-adjust: exact;
      }
    }

    @media page {
      size: A4;
    }
    
    @media print {
      .pagebreak {
          margin-top: 32px;
          display: block;
          page-break-before: always;
        }
    }`,
    content: () => componentRef.current,
    onAfterPrint: () => {
      trackSegmentEvent('Treatment Plan Printed', {
        application: applicationName,
        component: 'Take Home Approval',
        userId: `u-${user?.id}`,
      });
      setPdfGenerationInProgress(false);
    },
    onBeforePrint: () => setPdfGenerationInProgress(true),
  });

  useEffect(() => {
    fetchMerchantDetail?.();
    fetchAllPaymentOptions?.();
  }, []);

  const onBlurSms = (val?: string) => {
    trackSegmentEvent('Entered Phone Number', {
      application: applicationName,
      component: 'Take Home Approval',
      userId: `u-${user?.id}`,
      phoneNumber: val,
    });
  };

  const onBlurEmail = (val?: string) => {
    trackSegmentEvent('Entered Email Address', {
      application: applicationName,
      component: 'Take Home Approval',
      userId: `u-${user?.id}`,
      emailAddress: val,
    });
  };

  const onClickOkay = () => {
    setSent(false);
  };

  const onClickSendSms = async (phone?: string) => {
    try {
      trackSegmentEvent('Texted Quote', {
        application: applicationName,
        component: 'Take Home Approval',
        userId: `u-${user?.id}`,
        phoneNumber: phone || previewData?.patientPhone,
        loanId: previewData?.loanId,
      });

      const { data } = await sendTakeHomeSms({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              loanId: previewData?.loanId,
              merchantId: Number(previewData?.selectedLocation?.value),
              amount: previewData?.amount,
              applicationId: selectedApplicationId,
              phone: phone || previewData?.patientPhone,
              patientName: previewData?.patientName,
              coordinatorName: previewData?.treatmentCoordinator,
              consultationSummary: previewData?.additionalNotes,
            },
          },
        },
      });

      if (!data?.sendTakeHomeSms?.success) {
        alert.error(data?.sendTakeHomeSms?.message);
      } else {
        setSent(true);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const onClickSendEmail = async (email?: string) => {
    try {
      trackSegmentEvent('Emailed Quote', {
        application: applicationName,
        component: 'Take Home Approval',
        userId: `u-${user?.id}`,
        emailAddress: email,
        loanId: previewData?.loanId,
      });

      const { data } = await sendTakeHomeEmail({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              email,
              loanId: previewData?.loanId,
              amount: previewData?.amount,
              applicationId: selectedApplicationId,
              patientName: previewData?.patientName,
              coordinatorName: previewData?.treatmentCoordinator,
              consultationSummary: previewData?.additionalNotes,
              merchantName: previewData?.selectedLocation?.emailLabel,
            },
          },
        },
      });

      if (!data?.sendTakeHomeEmail?.success) {
        alert.error(data?.sendTakeHomeEmail?.message);
      } else {
        setSent(true);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const fetchAllPaymentOptions = async () => {
    try {
      if (selectedApplicationId) {
        const response = await getProductCalculation(selectedApplicationId, previewData?.amount!);
        sortAndSetTableData(response);
      }
    } catch {
      sortAndSetTableData([]);
    }
  };

  const sortAndSetTableData = (data) => {
    setPaymentOptionsTableData(data);
  };

  const fetchMerchantDetail = async () => {
    try {
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds: previewData?.selectedLocation?.value,
          },
        },
      });

      if (getMerchantDetail?.data?.[0]) {
        setMerchantDetail(getMerchantDetail.data[0]);
      }
    } catch (error) {
      alert.error('Merchant detail fetch error');
    }
  };

  const getDocumentData = () => {
    const selectedLocationDetail = merchants?.find((location) => location?.id === previewData?.selectedLocation?.value);

    return {
      ...previewData,
      selectedLocation: {
        name: selectedLocationDetail?.name,
        address: selectedLocationDetail?.address.address1,
        city: selectedLocationDetail?.address.city.name,
        state: selectedLocationDetail?.address.city.state?.code,
        zip: selectedLocationDetail?.address.zip,
        phone: merchantDetail?.phone,
      },
    };
  };
  const setFirstPage = () => {
    setPage(PDF_PAGE.FIRST);
  };

  const setSecondPage = () => {
    if (previewData?.additionalNotes && previewData?.additionalNotes?.length > NOTE_LENGTH) {
      setPage(PDF_PAGE.SECOND);
    }
  };

  const Print = (
    <Hidden>
      <PrintContent id="print-data" ref={componentRef}>
        <GeneratedTreatmentPlanDocument
          page={PDF_PAGE.FIRST}
          previewData={getDocumentData()}
          paymentOptionsTableData={paymentOptionsTableData}
        />
        {previewData?.additionalNotes && previewData?.additionalNotes?.length > NOTE_LENGTH ? (
          <>
            <div className="pagebreak" />
            <GeneratedTreatmentPlanDocument
              page={PDF_PAGE.SECOND}
              previewData={getDocumentData()}
              paymentOptionsTableData={paymentOptionsTableData}
            />
          </>
        ) : null}
      </PrintContent>
    </Hidden>
  );

  return (
    <>
      <PageHandler>
        <HiddenInput ref={(input) => input && input.focus()} />
        <IconButton onClick={setFirstPage} disabled={page === 0}>
          <img src={images.chevronLeft.default} alt="chevronLeft" />
        </IconButton>
        <PageCounter>
          Page {page + 1} of{' '}
          {previewData?.additionalNotes && previewData?.additionalNotes?.length > NOTE_LENGTH ? '2' : '1'}
        </PageCounter>
        <IconButton
          onClick={setSecondPage}
          disabled={(previewData?.additionalNotes && previewData?.additionalNotes?.length < NOTE_LENGTH) || page === 1}
        >
          <ChevronRight src={images.chevronLeft.default} />
        </IconButton>
      </PageHandler>

      <DialogContainer>
        <DialogContent>
          <GeneratedTreatmentPlanDocument
            page={page}
            previewData={getDocumentData()}
            paymentOptionsTableData={paymentOptionsTableData}
          />
        </DialogContent>

        {pdfGenerationInProgress ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <>
            {sent ? (
              <Result>
                <ResultContainer>
                  <CircleCheckIcon className="m-auto my-6" />
                  <div>Treatment plan was sent to {previewData?.patientName}</div>
                </ResultContainer>

                <ButtonContainer>
                  <PrintButton onClick={onClickOkay}>Okay</PrintButton>
                </ButtonContainer>
              </Result>
            ) : (
              <div>
                <ButtonContainer>
                  <ButtonGap />
                  <PrintButton onClick={handlePrint}>
                    <Icon m={'0 6px 0 0'} src={'print'} width={16} height={16} hover={true} />
                    Print Treatment Estimate
                  </PrintButton>
                </ButtonContainer>

                {!hideSendPatientSection && (
                  <>
                    <TextButtonContainer>Or send directly to patient:</TextButtonContainer>

                    <ButtonContainer>
                      <PhoneNumberInput
                        defaultValue={previewData?.patientPhone}
                        onClickSend={onClickSendSms}
                        onBlur={onBlurSms}
                        loading={loadingSms}
                      />
                    </ButtonContainer>

                    <ButtonContainer>
                      <EmailInput
                        defaultValue={previewData?.patientEmail}
                        onClickSend={onClickSendEmail}
                        onBlur={onBlurEmail}
                        loading={loadingEmail}
                      />
                    </ButtonContainer>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </DialogContainer>

      {paymentOptionsTableData && paymentOptionsTableData?.length > 0 ? Print : null}
    </>
  );
};

const HiddenInput = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
`;

const ResultContainer = styled.div`
  display: grid;
  text-align: center;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  margin-top: 12px;
  margin-left: 12px;

  height: 740px;
  width: 750px;
  box-shadow: 0 28.3737px 52.8066px rgba(0, 0, 0, 0.07), 0 10.3569px 19.2753px rgba(0, 0, 0, 0.0482987),
    0 5.02807px 9.35779px rgba(0, 0, 0, 0.0389404), 0 2.46485px 4.58736px rgba(0, 0, 0, 0.0310596),
    0 0.974606px 1.81385px rgba(0, 0, 0, 0.0217013);
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const TextButtonContainer = styled.div`
  justify-content: center;
  margin-top: 24px;
  display: flex;
`;

const IconButton = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;

const PageHandler = styled.div`
  display: flex;
  justify-content: center;
  max-width: 750px;
  margin-top: 24px;
`;

const ChevronRight = styled.img`
  transform: rotate(180deg);
`;

const PageCounter = styled.div`
  margin: 0 32px;
  font-size: 16px;
`;

const Hidden = styled.div`
  height: 0;
  overflow: hidden;
`;

const PrintContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${isChrome ? '24px' : '0'};
  margin-top: ${isChrome ? '24px' : '0'};
  height: 100%;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  flex: 1;
`;

const PrintButton = styled(ContinueButton)`
  width: 100%;
`;
