import { useMutation } from '@apollo/client';
import { Dialog, FormControl, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { Button } from '@frontend/cherry-library';
import { CREATE_LINK_TREE_LINK } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { CherryDialogTitle, DialogContainer, DialogContent, SubTitle } from 'pages/desktop/Settings/components/Dialog';

interface IProps {
  open: boolean;
  handleClose: () => void;
  onAddLinkFinish: () => void;
}

const isValidUrl = (string: string) => {
  try {
    // tslint:disable-next-line: no-unused-vars eslint-disable-next-line no-unused-expression
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const AddLinkDialog = ({ open, handleClose, onAddLinkFinish }: IProps) => {
  const { organization } = useStore();
  const alert = useAlert();
  const [createLink] = useMutation(CREATE_LINK_TREE_LINK);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);

  const isButtonDisabled = !title || !link || !isUrlValid || loading;

  const handleNameChange = (e) => {
    setTitle(e.target.value);
  };

  const handleUrlChange = (e) => {
    const { value } = e.target;
    setIsUrlValid(isValidUrl(value));
    setLink(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        data: { createLinkTreeLink },
      } = await createLink({
        variables: {
          input: {
            organizationId: organization?.id,
            title,
            link,
          },
        },
      });
      if (createLinkTreeLink.success) {
        onAddLinkFinish();
      }
    } catch (error) {
      alert.error('Create CherryTree link error');
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add a link
        </CherryDialogTitle>
        <SubTitle>Add a link to display on your Cherry Links page.</SubTitle>
        <DialogContent>
          <FormContainer>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12} spacing={2}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormTitle hasError={false}>Link Name</FormTitle>
                  <TextField
                    size="small"
                    type="text"
                    data-testid="add-link-name"
                    onChange={handleNameChange}
                    value={title}
                    variant="outlined"
                    placeholder="Link Name"
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} spacing={2}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormTitle hasError={!isUrlValid}>URL</FormTitle>
                  <TextField
                    size="small"
                    type="text"
                    data-testid="add-link-url"
                    onChange={handleUrlChange}
                    value={link}
                    variant="outlined"
                    error={!isUrlValid}
                    helperText={!isUrlValid && 'Please enter a valid url'}
                    placeholder="URL"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container={true} spacing={1} style={{ marginTop: '16px' }}>
              <Grid item={true} xs={6}>
                <Button fullWidth={true} variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item={true} xs={6}>
                <Button fullWidth={true} disabled={isButtonDisabled} onClick={handleSubmit}>
                  Add Link
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
};

const FormTitle = styled.label<{ hasError?: boolean }>`
  margin: 4px 0 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.hasError ? props.theme.main.errorColor : props.theme.main.textColor)} !important;
  font-weight: 400;
`;

const FormContainer = styled.div`
  .MuiFormHelperText-contained {
    margin-left: 0;
  }
`;
