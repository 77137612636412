import { gql } from '@apollo/client/core';

export const FETCH_MILESTONES = gql`
  query fetchMilestones($input: FetchMilestonesRequest) {
    fetchMilestones(input: $input) {
      data {
        id
        endAt
        rewardNotification
        rewardProgression
        rewardStatus
        rewardGranted
        campaign {
          id
          description
          rewardOption
          startAt
          details {
            id
            campaignId
            name
            description
            orderIndex
            rewardAmount
            state {
              status
              cumulativeRewardAmount
            }
          }
        }
      }
    }
  }
`;
