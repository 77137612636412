import { DataLoader, FixedDecimalFormat } from 'lib/components';
import React from 'react';
import styled from 'styled-components';

interface SalesReport {
  approvalRate?: number;
  contracts?: number;
  grossSales?: number;
  netSales?: number;
  fees?: number;
  refunds?: number;
}

interface ApprovalRate {
  rate: number;
  outstanding: number;
}
interface Props {
  setTableRefresh?: (refresh: boolean) => void;
  blurred?: boolean;
  tableRefresh?: boolean;
  loading: boolean;
  salesInfo: SalesReport;
  approvalInfo?: ApprovalRate;
}

export const QuickStats = ({ salesInfo, loading, approvalInfo }: Props) => {
  return (
    <CenteredDataCard>
      <ApplicationContainer>
        <HeaderDateContainer>
          <Header>Applications</Header>
        </HeaderDateContainer>
        <StatsContainer>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Gross Sales</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={salesInfo?.grossSales} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Contracts</StatHeader>
              <StatDescription>{salesInfo?.contracts}</StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Approval Rate</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={approvalInfo?.rate} suffix="%" />
              </StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Approvals</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={approvalInfo?.outstanding || 0} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
        </StatsContainer>
      </ApplicationContainer>
      <TransactionContainer>
        <Header>Transactions</Header>
        <StatsContainer>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Gross Sales</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={salesInfo?.grossSales} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Net Sales</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={salesInfo?.netSales} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Refunds</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={salesInfo?.refunds} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
          <Stat>
            <DataLoader loading={loading}>
              <StatHeader>Fees</StatHeader>
              <StatDescription>
                <FixedDecimalFormat amount={salesInfo?.fees} prefix={'$'} />
              </StatDescription>
            </DataLoader>
          </Stat>
        </StatsContainer>
      </TransactionContainer>
    </CenteredDataCard>
  );
};

const CenteredDataCard = styled.div`
  padding: 16px;
  gap: 24px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ApplicationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dadada;
  padding-bottom: 24px;
`;

const TransactionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Header = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

const StatHeader = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const StatDescription = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
