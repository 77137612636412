import { DialogContent as MuiDialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled as muiStyled } from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';

import { CloseIcon, PrimaryButton } from 'lib/components';

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #dadada;
`;

const Title = styled.div`
  flex: 1;
  font-weight: 700;
  line-height: 27.24px;
  font-size: 20px;
  text-align: center;
`;

const IconPlaceholder = styled.div`
  width: 40px;
  height: 40px;
`;

const Gap = styled.div`
  width: 40px;
  height: 40px;
`;

const CloseButton = muiStyled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const DialogTitleContainer = ({ id, children, onClose }) => {
  return (
    <TitleContainer>
      {Boolean(onClose) ? <IconPlaceholder /> : null}
      <Title>{children}</Title>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      ) : (
        <Gap />
      )}
    </TitleContainer>
  );
};

export const DialogTitle = muiStyled(DialogTitleContainer)``;

export const DialogContent = muiStyled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflow: 'hidden',
}));

export const DialogContentInherit = muiStyled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflow: 'inherit',
}));

export const CherryDialogTitle = styled(DialogTitle)`
  border-bottom: 1px solid #dadada;
  color: #0e202f;
  text-align: center;
  line-height: 27.24px;
  font-weight: bold;
`;

export const DialogContainer = styled.div`
  padding: 24px;
  min-width: 420px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e202f;
  margin: 24px 0;
`;

export const ContinueButton = styled(PrimaryButton)`
  font-size: 16px;
  margin-top: 24px;
  box-shadow: none;
  padding: 11px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #00c37c;
  font-family: 'Open Sans';

  &:disabled {
    color: #9fa6ac;
    background-color: #dadada;
    border: 1px solid #dadada;
  }

  &:active {
    opacity: 0.7;
  }
`;

export const SecondaryContinueButton = styled(ContinueButton)`
  font-weight: 600;
`;

export const BackButton = styled(ContinueButton)`
  background-color: #ffffff;
  color: #00c37c;
  border: 1px solid #00c37c;
`;

export const SecondaryBackButton = styled(BackButton)`
  font-weight: 600;
`;
