import React from 'react';
import styled from 'styled-components';

import { ExpandableInfoText, ExpandableWithTitle } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import { calculateMerchantFee } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';

interface RefundVoidPricingInfoProp {
  transactionInProcess: any;
  amount: number;
  type?: 'VOID';
}

export const RefundVoidPricingInfo = ({ transactionInProcess, amount, type }: RefundVoidPricingInfoProp) => {
  const { organization } = useStore();
  const refundFee: any = type === 'VOID' ? 0 : organization?.refundFee;
  const purchaseAmountBefore = transactionInProcess.amount;
  const purchaseAmountAfter = transactionInProcess.amount - amount;

  const merchantFeeBefore = transactionInProcess?.merchantRevenue;
  const merchantFeeChange = calculateMerchantFee(amount, transactionInProcess);
  const merchantFeeAfter = transactionInProcess?.merchantRevenue - merchantFeeChange;

  const netSalesFeeBefore = transactionInProcess.amount - transactionInProcess.merchantRevenue;
  const netSalesFeeAfter = purchaseAmountAfter - merchantFeeAfter - refundFee;

  return (
    <>
      <ExpandableWithTitle title={'Before'} expanded={true} color={'#f2f4f5'}>
        <>
          <ExpandableInfoText
            name={'Purchase Amount'}
            value={CurrencyUtil.toCurrencyString(purchaseAmountBefore, true)}
          />
          <ExpandableInfoText
            name={'Merchant Fee'}
            value={`${CurrencyUtil.toCurrencyString(merchantFeeBefore, true)}`}
          />
          <ExpandableInfoText name={'Refund Fee'} value={'n/a'} />
          <NetContainter>
            <ExpandableInfoText name={'Net Sales'} value={CurrencyUtil.toCurrencyString(netSalesFeeBefore, true)} />
          </NetContainter>
        </>
      </ExpandableWithTitle>
      <ExpandableWithTitle title={'After'} expanded={true}>
        <>
          <ExpandableInfoText
            name={'Purchase Amount'}
            value={CurrencyUtil.toCurrencyString(purchaseAmountAfter, true)}
          />
          <ExpandableInfoText name={'Merchant Fee'} value={CurrencyUtil.toCurrencyString(merchantFeeAfter, true)} />
          <ExpandableInfoText
            name={'Refund Fee'}
            value={type === 'VOID' ? 'n/a' : CurrencyUtil.toCurrencyString(refundFee, true)}
          />
          <NetContainter>
            <ExpandableInfoText name={'Net Sales'} value={CurrencyUtil.toCurrencyString(netSalesFeeAfter, true)} />
          </NetContainter>
        </>
      </ExpandableWithTitle>
    </>
  );
};

const NetContainter = styled.div`
  border-top: 1px solid #879097;
  padding-top: 10px;
  p {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
`;
