import React from 'react';

import { Button as CherryButton } from '@frontend/cherry-library';
import { DashCard, DashComponent, PageHeader } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Column, Container, Row } from 'lib/styles';

const SocialMedia = () => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const { isSnackbarVisible } = useSnackbar();

  const openModal = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Viewed Social Media Templates', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
    });
    window.open('https://withcherry.com/sharing-cherry/sharing-on-social-media/', '_blank');
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Marketing" />

      <Row>
        <Column>
          <DashComponent dashHeader={'Social Media Templates'} />
          <DashCard>
            <Row fontSize={'14px'}>
              Social media is a fantastic place to let your customers know about the financing options available. Browse
              our library of ready-to-share social media templates.
            </Row>
            <br />
            <Row>
              <CherryButton variant="primary" data-testid="scheduleMeetingButton" onClick={openModal}>
                Social Templates →
              </CherryButton>
            </Row>
          </DashCard>
        </Column>
      </Row>
    </Container>
  );
};

export default SocialMedia;
