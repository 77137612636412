import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components/mobile';
import { useOnClickOutside } from 'lib/hooks';
import { Truthy } from 'lib/types';

interface RenderTree {
  id: string;
  name: string;
  type: string;
  childs?: readonly RenderTree[];
}

interface Props {
  options: RenderTree[] | undefined;
  textColor?: string;
  defaultValue?: string;
  id?: string;
  customSelector?: boolean;
  onChange?: (item: any) => void;
  hoveredMode?: boolean;
  preventCloseOnClick?: Truthy;
  checkedItems?: any | undefined;
  placeholder?: string;
  checkedPlaceholder?: string;
  checkedAllPlaceholder?: string;
  searchable?: Truthy;
  searchablePlaceholder?: string;
  selectAllLabel?: string;
  selectAll?: Truthy;
  minWidth?: string;
  iconName?: string;
}

interface DropDownContentProps {
  show?: boolean;
  hoveredMode?: boolean;
  minWidth?: string;
}

export const DropDownWithRadioButton = React.forwardRef((props: Props, ref) => {
  const {
    options = [],
    textColor = '#000',
    onChange,
    id,
    customSelector,
    hoveredMode = true,
    placeholder,
    checkedItems = {},
    selectAll,
    selectAllLabel,
    minWidth,
    iconName = '',
    preventCloseOnClick,
  } = props;
  const [expand, setExpand] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<any>({ value: 'selectAll', label: 'All Accounts' });
  const [searchValue] = useState('');

  const clickOutsideRef: any = useRef();
  const closeDropdown = () => (expand ? setExpand(false) : '');
  useOnClickOutside(clickOutsideRef, closeDropdown);

  useEffect(() => {
    setSelectedOption(checkedItems);
  }, [checkedItems]);

  const onDropdownClick = () => {
    if (!hoveredMode) setExpand(!expand);
  };
  const onHoverToggle = () => {
    if (hoveredMode) setExpand(!expand);
  };

  const handleDropdownSelect = (clickedOption) => {
    const value = clickedOption.value;
    const label = clickedOption.name;
    const type = clickedOption.type;
    const checked = selectedOption?.value === value && selectedOption?.type === type;
    const selectedObject = { value, label, type };

    if (!checked) {
      setSelectedOption(selectedObject);
      onChange && onChange(selectedObject);
    } else {
      setSelectedOption({ value: 'selectAll', label: 'All Accounts' });
      onChange && onChange({ value: 'selectAll', label: 'All Accounts' });
    }
  };

  const preventDropdownClose = (e) => {
    if (!!preventCloseOnClick) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePlaceholderText = () => {
    if (selectedOption?.value) {
      return selectedOption?.label;
    } else {
      return placeholder || '';
    }
  };

  const selectAllHandler = () => {
    setSelectedOption({ value: 'selectAll', label: 'All Accounts' });
    onChange && onChange({ value: 'selectAll', label: 'All Accounts' });
  };

  const renderTree = (treeOptions: RenderTree) =>
    Array.isArray(treeOptions?.childs) ? (
      <>
        <InlineCheckboxContainer
          key={treeOptions.name}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() =>
            handleDropdownSelect({ value: treeOptions?.id, name: treeOptions?.name, type: treeOptions?.type })
          }
        >
          <CherryRadioButton
            name={treeOptions.name}
            value={treeOptions.id}
            checked={selectedOption?.value === treeOptions.id}
          />
          <TextContainer>{treeOptions.name}</TextContainer>
        </InlineCheckboxContainer>
        <Space>{treeOptions.childs.map((node) => renderTree(node))}</Space>
      </>
    ) : null;

  return useMemo(
    () => (
      <DropDownLi
        ref={clickOutsideRef}
        key={id}
        id={id}
        onClick={onDropdownClick}
        onMouseEnter={onHoverToggle}
        onMouseLeave={onHoverToggle}
      >
        <Dropbtn className={customSelector ? 'dropdown-left' : 'dropdown-right'}>
          <Span onClick={onDropdownClick} color={textColor}>
            {iconName ? <Icon src={iconName} hover={true} width={16} height={16} m="1px 6px 0px 0px" /> : null}
            {handlePlaceholderText()}
          </Span>
          {options && options?.length > 0 && <FontAwesomeIcon icon={faChevronDown} color={'#00c37d'} size="sm" />}
        </Dropbtn>
        <DropDownContent
          className={customSelector ? 'dropdown-content-left' : 'dropdown-content'}
          show={expand}
          hoveredMode={hoveredMode}
          minWidth={minWidth}
          onClick={preventDropdownClose}
        >
          {!!selectAll && (
            <CheckboxContainer onClick={selectAllHandler}>
              <CherryRadioButton
                name={selectAllLabel}
                value={'selectAll'}
                checked={selectedOption?.value === 'selectAll'}
              />
              <TextContainer>{selectAllLabel}</TextContainer>
            </CheckboxContainer>
          )}
          {options &&
            options?.map((option: RenderTree, index: number) => {
              const dropOption = JSON.parse(JSON.stringify(option));

              return renderTree(dropOption);
            })}
        </DropDownContent>
      </DropDownLi>
    ),

    [expand, options, checkedItems, searchValue],
  );
});

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
  height: 40px;
  display: inline-flex;
  color: black;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
`;

const CherryRadioButton = styled(Radio)`
  svg {
    color: ${(props) => (props?.checked ? props?.theme.main.green : '#879097')} !important;
  }

  &:hover {
    background-color: #00c37c15 !important;
  }
`;

const DropDownContent = styled.div<DropDownContentProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: ${({ minWidth }) => minWidth || '160px'};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 24px;
  left: 0;
  cursor: pointer;
  border-radius: 4px;
  max-height: 325px;
  overflow-x: scroll;

  &.dropdown-content-left {
    left: -100px;
  }
`;

const DropDownLi = styled(StyledLi)<DropDownContentProps>`
  display: inline-block;
  position: relative;
  width: 100%;

  ${(props) =>
    props.hoveredMode
      ? `&:hover ${DropDownContent} {
      display: block;
      cursor: pointer;
    }`
      : ''}
`;

const Span = styled.span<{ color: string }>`
  margin-right: 4px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  text-align: left;
`;

const CheckboxContainer = styled.div`
  margin-left: -9px;
  display: flex;
`;

const InlineCheckboxContainer = styled(CheckboxContainer)`
  margin-left: 8px;
`;

const TextContainer = styled.div`
  padding-top: 10px;
`;

const Space = styled.div`
  margin-left: 8px;
`;
