import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonContainer, Container, ContentContainer, Title } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';

const MobileTransactionPartialRefundSuccess = () => {
  const navigate = useNavigate();
  const { transactionInProcess } = useStore();

  const navigateToTransactionList = () => navigate('/transactions');

  return (
    <Container pageTitle="Partial Refund">
      <DetailContainer>
        <Title>Success!</Title>
        <p>
          {transactionInProcess.borrowerName} has received a partial refund of{' '}
          <b>{CurrencyUtil.toCurrencyString(transactionInProcess.partialRefundAmount, true)}</b>.
        </p>
        <p>
          We will prorate and reimburse your processing fees. It may take up to 3-5 business days to show in your bank
          account.
        </p>
        <p>Transaction details have been updated to reflect this refund.</p>
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={navigateToTransactionList}>Okay</Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionPartialRefundSuccess;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;
