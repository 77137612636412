import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { TableLoader } from 'lib/components';
import { CheckoutButton } from 'lib/components/ApplicationStatutes/components';
import { formatDateWithTime, formatTextCapitalize, membershipBenefitTableStyle } from 'lib/utils';
import { MarkBenefitModal } from 'pages/desktop/Membership/components/MemberDetail/MarkBenefitModal';
import { BenefitUsageType } from 'pages/desktop/Membership/type';

interface TableColumnProps {
  flex?: number;
}

interface Props {
  data: any;
  getCustomerPlanDetail: any;
}

export const BenefitUsage = ({ data, getCustomerPlanDetail }: Props) => {
  const [openBenefitModal, setOpenBenefitModal] = useState<boolean>(false);
  const [benefitModalData, setBenefitModalData] = useState<BenefitUsageType | null>(null);
  const [loading] = useState<boolean>(false);

  const tableColumns = [
    {
      name: 'Benefit Title',
      selector: (row) => row.name,
      minWidth: '230px',
      maxWidth: '230px',
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      minWidth: '180px',
      maxWidth: '180px',
      sortable: true,
      cell: (row) => <>{formatTextCapitalize(row.type)}</>,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: 'Frequency',
      selector: (row) => row.frequency,
      sortable: true,
      cell: (row) => <>{formatTextCapitalize(row.frequency)}</>,
    },
    {
      name: 'Last Use',
      selector: (row) => row.updatedAt,
      minWidth: '180px',
      maxWidth: '180px',
      sortable: true,
      cell: (row) => <>{row.usage === 0 ? 'n/a' : formatDateWithTime(row.updatedAt)}</>,
    },
    {
      name: 'Usage',
      selector: (row) => row.usage,
      sortable: true,
      cell: (row) => <>{row.usage} Used</>,
    },
    {
      name: '',
      selector: (row) => row.detail,
      right: true,
      left: false,
      width: '180px',
      cell: (row) => (
        <CheckoutButton
          data-testid="checkout-button"
          data-tag="allowRowEvents"
          className="btn--checkout"
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => onMarkUseClicked(row)}
        >
          Mark Use
        </CheckoutButton>
      ),
      ignoreRowClick: true,
    },
  ];

  const hideBenefitModal = (value) => {
    setOpenBenefitModal(false);
    if (value) {
      getCustomerPlanDetail();
    }
  };

  const onMarkUseClicked = (row) => {
    setOpenBenefitModal(true);
    setBenefitModalData({ ...row, planId: data?.id });
  };

  return (
    <Container>
      <InfoHeader>
        <HeaderTitle>Benefit Usage</HeaderTitle>
      </InfoHeader>
      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            noHeader={true}
            customStyles={membershipBenefitTableStyle}
            columns={tableColumns}
            data={data?.customerBenefits || []}
            pagination={true}
            progressPending={loading}
            progressComponent={<TableLoader count={4} />}
          />
        </TableColumn>
      </TableRow>
      <MarkBenefitModal open={openBenefitModal} handleClose={hideBenefitModal} modalData={benefitModalData} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  width: 100%;
  margin-bottom: 40px;
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
  svg {
    margin-left: 5px;
  }
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #0e202f;
`;

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`;

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(135, 144, 151, 0.5);

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableHeadRow div {
    font-size: 12px;
    margin-bottom: -10px;
  }
  .rdt_TableRow {
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0e202f;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;
