import React from 'react';
import styled from 'styled-components';

import { Dialog } from '@mui/material';

import {
  CherryDialogTitle,
  DialogContainer,
  SecondaryBackButton,
  SecondaryContinueButton,
} from 'pages/desktop/Settings/components/Dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  content?: React.ReactNode;
  closeButtonText?: string;
  confirmButtonText?: string;
}

export const ConfirmationDialog = ({
  onClose,
  onConfirm,
  open,
  title,
  content,
  closeButtonText,
  confirmButtonText,
}: Props) => {
  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </CherryDialogTitle>
        <DialogContent>
          <Content>{content}</Content>
        </DialogContent>
        <ButtonContainer>
          <BackToEditorButton onClick={onClose}>{closeButtonText ?? 'Cancel'}</BackToEditorButton>
          <ButtonGap />
          <CancelButton onClick={onConfirm}>{confirmButtonText ?? 'Okay'}</CancelButton>
        </ButtonContainer>
      </DialogContainer>
    </Dialog>
  );
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

const Content = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #0e202f;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const CancelButton = styled(SecondaryContinueButton)`
  background-color: #ec3360;
  border: 1px solid #ec3360;
`;

const BackToEditorButton = styled(SecondaryBackButton)`
  border: 1px solid #0e202f;
  color: #0e202f;
`;
