import { MODAL_STATUS } from 'pages/desktop/Settings/components/BankInformation/constants';
import React from 'react';
import styled from 'styled-components';

interface InfoDialogSubtitleProps {
  modalStatus: MODAL_STATUS | null;
  patchLoading: boolean;
}

const InfoDialogSubtitle = ({ patchLoading, modalStatus }: InfoDialogSubtitleProps) => {
  return (
    <SubTitle>
      {patchLoading && `Transmitting and verifying information...`}
      {modalStatus === MODAL_STATUS.SUCCESS && `Bank successfully added!`}
      {modalStatus === MODAL_STATUS.FAILED && `Please review your bank credentials, and try again.`}
      {modalStatus === MODAL_STATUS.REVIEW &&
        `Our underwriting team is reviewing your changes. We’ll be in touch if we need additional information.`}
      {!patchLoading &&
        !modalStatus &&
        `Your credentials are securely stored using the same 256-bit encryption as financial institutions.`}
      {!patchLoading &&
        modalStatus === MODAL_STATUS.SUMMARY &&
        `Please review the bank account updates below and make sure all details are correct. Errors may cause delays in updating the account funds are transferred into.`}
    </SubTitle>
  );
};

export default InfoDialogSubtitle;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.midnightBlue};
  margin: 24px 0;
`;
