import { theme } from 'config/theme';
import styled from 'styled-components';

import { ContinueButton } from 'pages/desktop/Settings/components/Dialog';

export const ReviewStepContainer = styled.div`
  margin-top: 24px;
`;

export const SecondaryContinueButton = styled(ContinueButton)`
  font-weight: 600;
  width: 300px;

  margin-left: 16px;
`;

export const BackButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.white};
  color: ${(props) => props.theme.main.midnightBlue};
  border: 1px solid ${(props) => props.theme.main.midnightBlue};
  width: 75px;
`;

export const SecondaryBackButton = styled(BackButton)`
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewText = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 24px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: ${theme.main.aliceBlue};
  padding: 24px;
`;

export const ReturnHomeButton = styled(BackButton)`
  background-color: ${(props) => props.theme.main.aliceBlue};
  min-width: 250px;
  font-weight: 600;
`;

export const ErrorText = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 16px 0;
`;
