import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import images from 'assets/images';
import { ApplicationLink, ApplicationLinkIcon, ApplicationSwitchButton, Banner, TierBadge } from 'lib/components';
import { Notification } from 'lib/components/Notification';
import { GeneralAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';

import { Icon } from 'lib/components/mobile';
import { useSegment } from 'lib/hooks/useSegment';
import { TierName } from 'lib/utils';

interface HeaderProps {
  isSidebarOpen: boolean;
  isSnackbarVisible: boolean;
}
interface ContainerProps {
  isSidebarOpen: boolean;
  isDemo: boolean;
}

export const Header = ({ isSidebarOpen, isSnackbarVisible }: HeaderProps) => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent } = useSegment();
  const navigate = useNavigate();
  const permission = usePermission();

  const { organization, organizationTier, features, isAdminReporting, selectedOrganizationGroup } = useStore();

  const [applicationLinkAnchorEl, setApplicationlinkAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(applicationLinkAnchorEl);

  const hasFeature =
    permission(FeatureNames.PAYMENT_ESTIMATOR, ScreenNames.SETTINGS) &&
    features?.includes(FeatureNames.SIMPLIFIED_PARTNER_PORTAL);

  const switcherPermissions = ['MEMBERSHIP', 'POINT_OF_SALE', 'ESHOP'];

  const logLogoClicked = () => {
    trackEvent({
      action: GeneralAnalyticsEventNames.HEADER_LOGO_CLICK,
    });
    navigate(isAdminReporting ? '/reporting' : '/dashboard');
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    trackSegmentEvent('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_VIEWED');
    setApplicationlinkAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    trackSegmentEvent('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_CLOSE_CLICKED');
    setApplicationlinkAnchorEl(null);
  };

  const openPaymentEstimatorModal = () => {
    navigate('/payment-estimator');
    trackSegmentEvent('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.VIEWED');
    trackSegmentEvent('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_HEADER_NAV.CLICKED');
  };

  return (
    <Container isSidebarOpen={isSidebarOpen} isDemo={isSnackbarVisible}>
      <Banner />
      <TitleRow>
        <CherryLogo src={images.cherryLogo.default} onClick={logLogoClicked} />
      </TitleRow>
      <ActionsPaneContainer>
        {!permission(FeatureNames.APPLICATION_LINK, ScreenNames.SETTINGS) ? (
          ''
        ) : (
          <>
            <ApplicationLinkContainer onClick={handleClick} id="application--link">
              Application Link
              <IconButton>
                <ApplicationLinkIcon />
              </IconButton>
            </ApplicationLinkContainer>

            <CopyItemMenu
              anchorEl={applicationLinkAnchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <ApplicationMenuItem disableRipple={true}>
                <ApplicationLink isInHeader={true} handleClose={handleClose} />
              </ApplicationMenuItem>
            </CopyItemMenu>
          </>
        )}
        {hasFeature && (
          <PaymentEstimatorWrapper>
            <PaymentEstimatorButton onClick={openPaymentEstimatorModal}>
              Payment Estimator <Icon src="estimator" hover={true} width={16} height={16} m={'1px 6px 0px 0px'} />
            </PaymentEstimatorButton>
          </PaymentEstimatorWrapper>
        )}

        <Notification isTierBadgeVisible={!!organizationTier?.hasTier} />
        <MerchantLabelContainer>
          <MerchantLabel>{isAdminReporting ? selectedOrganizationGroup?.name : organization?.name}</MerchantLabel>
        </MerchantLabelContainer>
        {organizationTier?.hasTier && (
          <TierContainer>
            <TierBadge type={TierName(organizationTier?.organizationTier?.tierId)?.toLowerCase()} />
          </TierContainer>
        )}

        {features.some((feature) => switcherPermissions.includes(feature)) ? <ApplicationSwitchButton /> : null}
      </ActionsPaneContainer>
    </Container>
  );
};

const MerchantLabelContainer = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid #dadada;
  color: #000000;
  margin-left: 12px;
  border-radius: 5px;
  justify-content: center;
`;

const MerchantLabel = styled.span`
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: ${(props) => props.theme.main.white};
  position: fixed;
  // width: calc(100% - ${(props) => (props.isSidebarOpen ? 250 : 80)}px);
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: width 0.4s;
  transition-timing-function: ease-in-out;
  top: ${(props) => (props.isDemo ? 42 : 0)}px;
  height: 92px;
  box-shadow: 0 0 5px #ddd;
  p {
    color: #000000;
  }
  @media print {
    display: none;
  }
`;

const ActionsPaneContainer = styled.div`
  display: flex;
  flex-direction: row;
  * > {
    margin: 10px;
  }
  height: 40px;

  @media print {
    display: none;
  }
`;

const ApplicationLinkContainer = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const CherryLogo = styled.img`
  width: 145px;
  height: auto;
`;

const CopyItemMenu = styled(Menu)`
  margin-top: 50px;
  height: 600px !important;
  .MuiMenuItem-root {
    width: min-content;
    overflow: inherit;
  }
  .MuiPopover-paper {
    overflow-y: inherit;
    overflow-x: inherit;
  }
`;

const TitleRow = styled.div`
  display: inline-flex;
  align-items: center;
  color: #000000;
  cursor: pointer;

  svg {
    margin-right: 12px;
    fill: ${(props) => props.theme.main.darkBlue};
  }
`;

const ApplicationMenuItem = styled(MenuItem)`
  min-width: 650px !important;
  background-color: unset !important;
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;

const PaymentEstimatorButton = styled.button`
  display: flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #dadada;
  margin-left: 16px;
  background-color: transparent;
  color: #0e202f;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

const PaymentEstimatorWrapper = styled.div`
  position: relative;
`;
