import { Radio, RadioGroup } from '@mui/material';
import styled from 'styled-components';

export const CherryRadio = styled(Radio)`
  color: ${(props) => (props.disabled ? props.theme.main.white10 : props.theme.main.cherryCheckboxGreen)} !important;
`;

export const CustomRadioGroup = styled(RadioGroup)`
  flex-direction: row !important;

  .MuiButtonBase-root {
    color: ${(props) => props.theme.main.midnightBlue50} !important;
  }

  .Mui-checked {
    color: ${(props) => props.theme.main.green} !important;
  }

  .MuiTypography-root {
    color: ${(props) => props.theme.main.midnightBlue};
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  label {
    width: 100%;
  }
`;
