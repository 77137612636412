import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { FixedDecimalFormat } from 'lib/components';
import { RightCaretIcon } from 'lib/components/mobile/SidebarIcons';
import useStore from 'lib/hooks/useStore';
import { useVoid } from 'lib/services';
import { formatPhoneNumber } from 'lib/utils';
import { useNavigate } from 'react-router-dom';

export interface TransactionTableItem {
  loanId: string;
  displayId: string;
  createdAt: string;
  borrowerName: string;
  phone: string;
  store: string;
  download: React.ReactNode;
  status: string;
  disbursement: string;
  amount: string;
  merchantFund: string;
  fundedAt: string;
  merchantRevenue: number;
  merchantId: number;
  fee: number;
  product: any;
  promoUsed?: boolean;
  plans: any;
  salesPerson: any;
  id: number;
  toggleExpandable: any;
  openDialog: any;
  fundStatus: string;
  subStatus: string;
  refundAmount: number;
  fund: any;
  servicingBalance: number;
  purchaseAmount: number;
  refunds: any;
}

export const useMobileTransactionsTable = () => {
  const { setTransactionInProcess } = useStore();
  const navigate = useNavigate();

  const { voidTransactionAllowed } = useVoid({});

  const handleRowClicked = (row) => {
    setTransactionInProcess(row);
    if (voidTransactionAllowed(row)) {
      return navigate(`/transactions/detail/void`);
    }
    return navigate(`/transactions/detail`);
  };

  const decideDisbursementStatus = (row: any) => {
    const rowClick = () => handleRowClicked(row);
    if (row?.disbursement === 'FUNDING' || row.disbursement === 'Completed') {
      return (
        <DisbursementText onClick={rowClick} isGreen={row.disbursement === 'Completed'}>
          Completed <RightCaretIcon />
        </DisbursementText>
      );
    } else {
      return (
        <DisbursementText isGreen={false}>
          {row.disbursement}
          <RightCaretIcon />
        </DisbursementText>
      );
    }
  };

  const columns = [
    {
      name: 'CUSTOMER',
      selector: (row) => row.borrowerName,
      cell: (row) => {
        const rowClick = () => handleRowClicked(row);
        return (
          <CustomerInfoContainer onClick={rowClick}>
            <CustomerInfo>{row.borrowerName}</CustomerInfo>
            <CustomerSubInfo>{formatPhoneNumber(row.phone)}</CustomerSubInfo>
            <CustomerSubInfo>{dayjs(row.fundedAt).format('MMM DD, YYYY')}</CustomerSubInfo>
          </CustomerInfoContainer>
        );
      },
    },
    {
      name: 'NET SALES',
      selector: (row) => row.merchantFund,
      sortable: true,
      cell: (row) => {
        const rowClick = () => handleRowClicked(row);
        return row.merchantFund ? (
          <FixedDecimalFormat onClick={rowClick} amount={row.merchantFund > 0 ? row.merchantFund : 0} prefix={'$'} />
        ) : null;
      },
    },
    {
      name: 'DISBURSEMENT',
      selector: (row) => row.disbursement,
      cell: (row) => decideDisbursementStatus(row),
    },
  ];

  return { columns, handleRowClicked };
};

const CustomerInfo = styled.p<{ fontSize?: string }>`
  margin: 0 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
`;

const CustomerSubInfo = styled.p<{ fontSize?: string }>`
  margin: 0 0;
  font-size: 10px;
  line-height: 13px;
`;

const DisbursementText = styled.div<{ isGreen: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => (props.isGreen ? props.theme.main.green : props.theme.main.textColor)};
`;

const CustomerInfoContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`;
