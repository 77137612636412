import { DialogProps as MuiDialogProps } from '@mui/material';
import { Attention, CircleCheckIcon } from 'lib/components';
import { APPOINTMENT_FAIL_MODAL } from 'lib/constants';
import GenericDialogModel from 'lib/modals/GenericDialogModel';
import React from 'react';
import styled from 'styled-components';

interface ResponseModalProps extends MuiDialogProps {
  cancelClicked: () => void;
  open: boolean;
  patientName: string;
  patientPhone: string;
  failModal: FailModal;
}
interface FailModal {
  failModalOpen: boolean;
  failModalInvalid: boolean;
}
const ResponseModal = ({ open, cancelClicked, failModal, patientName, patientPhone }: ResponseModalProps) => {
  const title = failModal.failModalOpen
    ? failModal.failModalInvalid
      ? APPOINTMENT_FAIL_MODAL.INVALID_PHONE
      : APPOINTMENT_FAIL_MODAL.MISSING_PHONE
    : APPOINTMENT_FAIL_MODAL.SUCCESS;

  const failModalUpperText = failModal.failModalInvalid
    ? APPOINTMENT_FAIL_MODAL.INVALID_TEXT_SHORT
    : APPOINTMENT_FAIL_MODAL.MISSING_PHONE_SHORT;

  return (
    <GenericDialogModel
      open={open}
      title={title}
      mainButtonText="Okay"
      onMainButtonClick={cancelClicked}
      onButtonLeftClick={cancelClicked}
    >
      {failModal.failModalOpen ? (
        <>
          {' '}
          <Attention />
          <UpperText>{failModalUpperText}</UpperText>
          <Text>{APPOINTMENT_FAIL_MODAL.FAIL_TEXT}</Text>
        </>
      ) : (
        <>
          <CircleCheckIcon />
          <Text>{`A Cherry application was sent to ${patientName} at ${patientPhone} `}</Text>
        </>
      )}
    </GenericDialogModel>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
`;

const UpperText = styled.div`
  margin-top: 12px;
`;

export default ResponseModal;
