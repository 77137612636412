import React from 'react';

import { CancelLoanButton } from 'lib/components';
import { Center, LoadingText, ResultContainer, TextH5 } from './Common';
import { SEND_OPTIONS } from 'pages/desktop/Dashboard/DentalDashboard/views/CheckoutOptions/CheckoutOptions';

export const CheckoutSuccess = ({
  borrowerName,
  cancelLoanRef,
  closeExpanded,
  selectedSendOption = SEND_OPTIONS.SMS,
}) => {
  return (
    <Center>
      <ResultContainer>
        <TextH5>Checkout Link Sent</TextH5>
        <LoadingText>
          {selectedSendOption === SEND_OPTIONS.SMS
            ? `${borrowerName} has received a link on their phone. You’ll get notified once they’ve chosen a plan.`
            : `${borrowerName} has received a link in their email. You’ll get notified once they’ve chosen a plan.`}
          {/* <FixedDecimalFormat amount={Number(amount)} prefix="$" /> */}
        </LoadingText>
        <CancelLoanButton
          variant={'filled'}
          disabled={false}
          data-tag="checkout-success"
          data-testid="checkout-success-button"
          ref={cancelLoanRef}
          onClick={closeExpanded}
          style={{ width: '90%' }}
        >
          Close
        </CancelLoanButton>
      </ResultContainer>
    </Center>
  );
};
