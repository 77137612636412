import { formatDate, ROLE_TYPES } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

export const useUserManagementTableColumns = (columns) => {
  const formatUserManagementLocation = (location: number) => {
    const locationText = location > 1 ? 'locations' : 'location';

    return (
      <>
        {location} {locationText}
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Team Member',
      selector: 'name',
      cell: (row) => (
        <NameAndDateWrapper>
          <NameWrapper>
            {row?.user?.firstName} {row?.user?.lastName}
          </NameWrapper>
          <DateWrapper>Created: {formatDate(row?.createdAt)}</DateWrapper>
        </NameAndDateWrapper>
      ),
      minWidth: '180px',
      maxWidth: '200px',
      ignoreRowClick: true,
    },
    {
      name: 'Email',
      selector: 'email',
      cell: (row) => (row?.user?.email ? row?.user?.email : 'Never'),
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: 'Role',
      selector: 'role',
      cell: (row) => ROLE_TYPES.find((role) => role.value === row?.role)?.label,
      minWidth: '150px',
      maxWidth: '200px',
    },
    {
      name: 'Location',
      selector: 'location',
      cell: (row) => (row.location ? formatUserManagementLocation(row?.location) : '-'),
      minWidth: '180px',
      maxWidth: '200px',
    },
    ...columns,
  ];

  return { tableColumns };
};

const NameAndDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  padding-bottom: 8px;
`;

const DateWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 13.62px;
`;
