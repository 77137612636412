import styled from 'styled-components';

export const Text = styled.p`
  margin: 0;
  line-height: 16px;
`;

export const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
`;

export const TextContainer = styled.div`
  margin: 4px 0;
`;

export const AppliedOnContainer = styled.div`
  position: relative;
`;

export const CheckoutButton = styled.button<{ light?: boolean; appointmentPage?: boolean }>`
  background-color: ${(props) => (props.appointmentPage ? props.theme.main.green : props.theme.main.white)};
  color: ${(props) =>
    props.appointmentPage
      ? props.theme.main.white
      : props.light
      ? props.theme.main.neutralGray
      : props.theme.main.sealGray};
  border: ${(props) =>
    props.appointmentPage
      ? 'none'
      : `1px solid ${props.light ? props.theme.main.neutralGray : props.theme.main.sealGray}`};
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  font-family: Open Sans;
  border-radius: 4px;
  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  .dropdown-right {
    min-width: 100px;
    padding-left: 0 !important;
    svg {
      color: ${(props) => props.theme.main.green};
    }
  }
  .dropdown-left {
    padding-left: -100px !important;

    .dropdown-content {
      left: -100px;
    }
  }
`;

export const StatusContainer = styled.div`
  position: relative;
`;

export const StatusTextContainer = styled.div<{ backgroundColor: string }>`
  padding: 9px 10px;
  gap: 4px;
  background: ${(props) => props.backgroundColor};
  border-radius: 16px;
  cursor: pointer;
`;

export const StatusText = styled.span`
  color: #0e202f;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  width: max-content;
`;

export const TextCherryGreen = styled.p`
  color: ${(props) => props.theme.main.green};
  min-width: 100px;
  text-align: end;
`;

export const Gap = styled.div`
  width: 15px;
`;

export const PopoverContainer = styled.div<{ secondary?: boolean; ternary?: boolean; left?: number }>`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: ${(props) => (props.secondary || props.ternary ? '240px' : '150px')};
  z-index: 10;
  left: ${(props) => (props.secondary ? '-125px' : props.ternary ? `-${props.left}px` : '-35px')};
  top: ${(props) => (props.secondary ? '-110px' : props.ternary ? '-80px' : '-60px')};
`;

export const DownCircle = styled.div<{ circleLeft?: string; secondary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00c37d;
  position: absolute;
  left: ${(props) => (props?.circleLeft ? props?.circleLeft : props.secondary ? '200px' : '65px')};
`;

export const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
  display: flex;
  justify-content: center;
`;

export const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;
