import { formatPhoneNumber, Heading } from '@frontend/cherry-library';
import useStore from 'lib/hooks/useStore';
import {
  AccountDetailsRow,
  AccountDetailsTable,
  AccountDetailsText,
  AccountDetailsTitle,
  SettingSubPageContainer,
} from '../styles';
import UserInformationLocationList from './UserInformationLocationList';
import { useEffect } from 'react';
import { useSegment } from 'lib/hooks/useSegment';

const AccountDetails = () => {
  const { user } = useStore();
  const { firstName, lastName, email, phone } = user || {};
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_ACCOUNT_DETAILS_PAGE_LOAD`);
  }, []);

  return (
    <SettingSubPageContainer>
      <Heading text="Account Details" level="4" />
      <AccountDetailsTable>
        <AccountDetailsRow>
          <AccountDetailsTitle>Name</AccountDetailsTitle>
          <AccountDetailsText>
            {firstName} {lastName}
          </AccountDetailsText>
        </AccountDetailsRow>
        <AccountDetailsRow>
          <AccountDetailsTitle>Email</AccountDetailsTitle>
          <AccountDetailsText>{email}</AccountDetailsText>
        </AccountDetailsRow>
        <AccountDetailsRow>
          <AccountDetailsTitle>Phone</AccountDetailsTitle>
          <AccountDetailsText>{formatPhoneNumber(phone)}</AccountDetailsText>
        </AccountDetailsRow>
        <UserInformationLocationList />
      </AccountDetailsTable>
    </SettingSubPageContainer>
  );
};

export default AccountDetails;
