import { Radio } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { InputWithIcon } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { useSegment } from 'lib/hooks/useSegment';
import { emailPattern, formatPhoneNumber } from 'lib/utils';
import React, { useState } from 'react';
import styled from 'styled-components';

export enum SEND_OPTIONS {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  CHECKOUT_WITH_PATIENT = 'CHECKOUT_WITH_PATIENT',
}

const CheckoutOptions = ({
  phoneNumber,
  emailValidationCallback,
  setSelectedSendOption,
  selectedOption,
  handleEmailChange,
  emailAddress,
}) => {
  const { segmentEventHandler } = useSegment();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [sendOption, setSendOption] = useState<string>(selectedOption);

  const handleRadioClick = (event) => {
    setSendOption(event.target.value);
    setSelectedSendOption(event.target.value);
  };

  const setSms = () => {
    setSelectedSendOption(SEND_OPTIONS.SMS);
    setSendOption(SEND_OPTIONS.SMS);
  };
  const setEmail = () => {
    setSelectedSendOption(SEND_OPTIONS.EMAIL);
    setSendOption(SEND_OPTIONS.EMAIL);
  };

  const onEmailChange = (nativeEvent: any) => {
    const value = nativeEvent.target.value;
    setIsEmailValid(!value || emailPattern?.test(value));
    handleEmailChange(value?.toLowerCase());
    emailValidationCallback(value?.length !== 0 && emailPattern?.test(value));
  };

  const handleCheckoutWithPatientClick = () => {
    setSelectedSendOption(SEND_OPTIONS.CHECKOUT_WITH_PATIENT);
    setSendOption(SEND_OPTIONS.CHECKOUT_WITH_PATIENT);
  };

  const RadioButtons = (
    <>
      <CheckboxContainer
        selected={sendOption === SEND_OPTIONS.SMS}
        onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_TEXT_CHECKOUT_LINK_RADIO_BUTTON_CLICKED', {
          cb: setSms,
        })}
      >
        <Radio
          onChange={handleRadioClick}
          name="sms"
          value={SEND_OPTIONS.SMS}
          checked={sendOption === SEND_OPTIONS.SMS}
          label={`Text checkout link to ${formatPhoneNumber(phoneNumber)}`}
        />
      </CheckboxContainer>
      <CheckboxContainer
        selected={sendOption === SEND_OPTIONS.EMAIL}
        onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_EMAIL_CHECKOUT_LINK_RADIO_BUTTON_CLICKED', {
          cb: setEmail,
        })}
      >
        <Radio
          onChange={handleRadioClick}
          name="email"
          value={SEND_OPTIONS.EMAIL}
          checked={sendOption === SEND_OPTIONS.EMAIL}
          label="Email checkout link"
        />
      </CheckboxContainer>
      {sendOption === SEND_OPTIONS.EMAIL && (
        <ContactInputBorder className={isEmailValid ? 'valid' : 'error'}>
          <InputWithIcon
            className="cohere-block"
            type="text"
            name="email"
            onBlur={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_EMAIL_INPUT_FILLED', {
              withInput: true,
              isFilledEvent: true,
            })}
            onFocus={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_EMAIL_INPUT_SELECTED')}
            onChange={onEmailChange}
            value={emailAddress}
            textAlign="left"
            border={false}
            placeholder="Patient Email"
          />
        </ContactInputBorder>
      )}

      <CheckboxContainer
        selected={sendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT}
        onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_ON_DEVICE_CHECKOUT_RADIO_BUTTON_CLICKED', {
          cb: handleCheckoutWithPatientClick,
        })}
      >
        <Radio
          onChange={handleRadioClick}
          name="checkoutWithPatient"
          value={SEND_OPTIONS.CHECKOUT_WITH_PATIENT}
          checked={sendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT}
          label="Process checkout on this device"
        />
      </CheckboxContainer>
      {sendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT && (
        <Grid container={true} alignItems="center" mt={2} wrap="nowrap" spacing={1}>
          <Grid item={true}>
            <Icon src="info_circle_outline" width={24} height={24} />
          </Grid>
          <Grid item={true}>
            <div>
              The patient must be shown all screens on your device while completing the checkout. Only use this option
              if the patient is here with you in person.
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
  return <div>{RadioButtons}</div>;
};

const CheckboxContainer = styled.div<{ selected?: boolean }>`
  margin-top: 8px;
  border-radius: 4px;
  border: ${(props) => (props.selected ? '2px solid #00c37d' : '1px solid #56636D')};
  background: #fff;
  cursor: pointer;

  label {
    width: 100%;
  }
`;

export const ContactInputBorder = styled.div`
  width: 100%;
  min-width: 360px;
  background: #ffffff;
  position: relative;
  border-radius: 2px;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  height: 46px;
  input {
    border: unset;
  }

  margin-top: 8px;

  &.error {
    border: 1px solid #ff0000;
  }

  #fade-button {
    width: 100%;

    &:hover {
      background-color: #ffffff !important;
    }
  }
`;

export default CheckoutOptions;
