import { DialogProps as MuiDialogProps } from '@mui/material';
import { APP_PREQUAL_URL } from 'config';
import useStore from 'lib/hooks/useStore';
import GenericDialogModel from 'lib/modals/GenericDialogModel';
import { formatPhoneNumber } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

interface ComfirmModalProps extends MuiDialogProps {
  cancelClicked: () => void;
  open: boolean;
  sendApplication: () => void;
  sendReminderModal: boolean;
  patientName: string;
  patientPhone: string;
}

const SendConfirmModal = ({
  open,
  sendReminderModal,
  cancelClicked,
  sendApplication,
  patientName,
  patientPhone,
}: ComfirmModalProps) => {
  const modalObj = {
    title: 'Send Application',
    mainButtonText: 'Send Application',
    onMainButtonClick: sendApplication,
    cancelClicked,
  };

  const { organization } = useStore();
  const applicationLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=partnerportal`;

  return (
    <GenericDialogModel
      open={open}
      title={modalObj.title}
      buttonLeftText="Cancel"
      onButtonLeftClick={cancelClicked}
      mainButtonText={modalObj.mainButtonText}
      onMainButtonClick={modalObj.onMainButtonClick}
    >
      {!sendReminderModal ? (
        <Text>{`Are you sure you want to send a Cherry application to ${patientName} at ${formatPhoneNumber(
          patientPhone,
        )}?`}</Text>
      ) : (
        <>
          <BoldText> Message To Send</BoldText>
          <Text>
            {`Hi, ${patientName}, this is a reminder from Cherry to complete your application with ${organization?.name}: ${applicationLink}`}
          </Text>
          <StopText>Reply "STOP" to unsubscribe. </StopText>
        </>
      )}
    </GenericDialogModel>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

const StopText = styled.div`
  margin-top: 12px;
`;
const BoldText = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
`;
export default SendConfirmModal;
