import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button as CherryButton } from '@frontend/cherry-library';
import { ReactComponent as AddUserIcon } from 'assets/images/add-user-icon.svg';

import { Loading } from 'lib/components';
import { UserManagementStepEnums } from 'lib/types';

import { AddTeamMember } from './AddTeamMember';
import { AssignRole } from './AssignRole';
import { LocationAccess } from './LocationAccess';

interface Props {
  setOpenFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  openFirstStep: boolean;
  selectedOrganizationMember: any;
  setSelectedOrganizationMember: any;
  setHandleClose: any;
  organizationUsers: any;
  merchantUsers: any;
}

export const UserManagementStepControlDialog = ({
  setOpenFirstStep,
  openFirstStep,
  selectedOrganizationMember,
  setSelectedOrganizationMember,
  setHandleClose,
  organizationUsers,
  merchantUsers,
}: Props) => {
  const [activeStep, setActiveStep] = useState<UserManagementStepEnums>(UserManagementStepEnums.ADD_TEAM_MEMBER);
  const [selectedRole, setSelectedRole] = useState('');
  const [userForCreation, setUserForCreation] = useState({});

  // Modal Triggers
  const handleClickOpen = () => {
    setOpenFirstStep(true);
  };

  const handleClose = () => {
    setOpenFirstStep(false);
    setUserForCreation({});
    setSelectedOrganizationMember({});
    setHandleClose();

    setTimeout(() => {
      setActiveStep(UserManagementStepEnums.ADD_TEAM_MEMBER);
    }, 250);
  };

  // Steps Handler
  const onStepChange = (step: UserManagementStepEnums) => {
    setActiveStep(step);
  };

  const onSetRole = (value: string) => {
    setSelectedRole(value);
  };

  const onUserForCreation = (user) => {
    setUserForCreation(user);
  };

  const renderConditionalStep = () => {
    switch (activeStep) {
      case UserManagementStepEnums.LOADING:
        return (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        );
      case UserManagementStepEnums.ADD_TEAM_MEMBER:
        return (
          <AddTeamMember
            setActiveStep={onStepChange}
            setUserForCreation={onUserForCreation}
            userForCreation={userForCreation}
            handleClose={handleClose}
            selectedOrganizationMember={selectedOrganizationMember}
            organizationUsers={organizationUsers}
            merchantUsers={merchantUsers}
          />
        );
      case UserManagementStepEnums.ASSIGN_ROLE:
        return (
          <AssignRole
            setActiveStep={onStepChange}
            setSelectedRole={onSetRole}
            handleClose={handleClose}
            userForCreation={userForCreation}
            selectedOrganizationMember={selectedOrganizationMember}
            organizationUsers={organizationUsers}
            merchantUsers={merchantUsers}
          />
        );
      case UserManagementStepEnums.LOCATION_ACCESS:
        return (
          <LocationAccess
            setActiveStep={onStepChange}
            handleClose={handleClose}
            selectedRole={selectedRole}
            userForCreation={userForCreation}
            selectedOrganizationMember={selectedOrganizationMember}
            organizationUsers={organizationUsers}
            merchantUsers={merchantUsers}
          />
        );
      default:
        return (
          <AddTeamMember
            setActiveStep={onStepChange}
            setUserForCreation={onUserForCreation}
            userForCreation={userForCreation}
            handleClose={handleClose}
            selectedOrganizationMember={selectedOrganizationMember}
            organizationUsers={organizationUsers}
            merchantUsers={merchantUsers}
          />
        );
    }
  };

  return (
    <>
      <CherryButtonContainer>
        <CherryButton data-testid="add-team-member" leftIcon={AddUserIcon} disabled={false} onClick={handleClickOpen}>
          Add Team Member
        </CherryButton>
      </CherryButtonContainer>
      <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={openFirstStep}>
        {renderConditionalStep()}
      </Dialog>
    </>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const CherryButtonContainer = styled.div`
  width: 230px;
  margin-top: 16px;
`;
