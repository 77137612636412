import images from 'assets/images';
import { ButtonPrimary, DashCard, PageHeader } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Container } from 'lib/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import PointOfSaleLinks from './components/PointOfSaleLinks';
import SendLinkModal from './components/SendLinkModal';

const PointOfSale = () => {
  const { user } = useStore();
  const [isSendLinkModalVisible, setIsSendLinkModalVisible] = useState(false);

  const handleSendLink = () => {
    setIsSendLinkModalVisible(true);
  };

  const handleSendLinkModalClose = () => {
    setIsSendLinkModalVisible(false);
  };

  return (
    <Container>
      <PageHeader title={`Welcome, ${user?.firstName}`} />
      <SendLinkModal open={isSendLinkModalVisible} onClose={handleSendLinkModalClose} />

      <DashCard>
        <Header>
          <Logo src={images?.pointOfSaleLogo} />
          <div>
            <SubTitle>Point of Sale Links</SubTitle>
            <p>
              Let patients pay using a combination of a Cherry payment plan and a credit/debit card, all with one
              easy-to-use link.
            </p>
            <ButtonPrimary text="Send Point of Sale Link" onClick={handleSendLink} />
          </div>
        </Header>
      </DashCard>
      <PointOfSaleLinks shouldRefetch={!isSendLinkModalVisible} />
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  gap: 24px;
`;

const Logo = styled.img`
  width: 200px;
`;

const SubTitle = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

export default PointOfSale;
