import { FeatureNames, useSentry } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useCheckoutWithPatient } from 'lib/services';
import { PaymentMethod } from 'pages/desktop/CheckoutWithPatient/SetupPayment/types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { filterCardsByCreatedAt, filterPaymentMethods } from '../SetupPayment/utils';

interface ContextState {
  paymentMethods: PaymentMethod[];
  loading: boolean;
  isPatientInReviewMode: boolean;
  cardList: PaymentMethod[];
  downPaymentMethod: PaymentMethod | null;
  recurringPaymentMethod: PaymentMethod | null;
  setIsPatientInReviewMode: (inReviewMode: boolean) => void;
  handlePaymentMethods: (borrowerId: number) => Promise<void>;
  debitCardMethodContents: () => PaymentMethod[];
  creditCardMethodContents: () => PaymentMethod[];
  setDownPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setRecurringPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const PaymentMethodsContext = createContext<ContextState | undefined>(undefined);

export const PaymentMethodsProvider = ({ children }) => {
  const { getPaymentMethods } = useCheckoutWithPatient();
  const { captureException } = useSentry();
  const alert = useAlert();
  const { features } = useStore();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPatientInReviewMode, setIsPatientInReviewMode] = useState(false);
  const [cardList, setCardList] = useState<PaymentMethod[]>([]);
  const [downPaymentMethod, setDownPaymentMethod] = useState<PaymentMethod | null>(null);
  const [recurringPaymentMethod, setRecurringPaymentMethod] = useState<PaymentMethod | null>(null);

  const hasCreditCardPaymentActive = features?.includes(FeatureNames.CREDIT_CARD_DOWN_PAYMENT);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      const list = filterPaymentMethods(
        paymentMethods?.filter((payment: PaymentMethod) => payment?.type === 'CARD'),
        hasCreditCardPaymentActive,
      );

      setCardList(list);
    }
  }, [paymentMethods]);

  const handlePaymentMethods = useCallback(async (borrowerId) => {
    try {
      setLoading(true);
      if (borrowerId) {
        const paymentMethodsResponse = await getPaymentMethods(borrowerId);
        if (paymentMethodsResponse?.contents?.length > 0) {
          setPaymentMethods(paymentMethodsResponse.contents);
        }
      }
    } catch (error) {
      alert.error('Error occurred while fetching payment methods.');
      captureException('Get Payment Methods Error', {
        error,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const debitCardMethodContents = useCallback(() => {
    return cardList?.filter(
      (content) =>
        content.type === 'CARD' && content.storedCard.type === 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  }, [cardList]);

  const creditCardMethodContents = useCallback(() => {
    return cardList?.filter(
      (content) =>
        content.type === 'CARD' && content.storedCard.type !== 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  }, [cardList]);

  return (
    <PaymentMethodsContext.Provider
      value={{
        paymentMethods,
        handlePaymentMethods,
        loading,
        isPatientInReviewMode,
        downPaymentMethod,
        recurringPaymentMethod,
        setIsPatientInReviewMode,
        cardList,
        debitCardMethodContents,
        creditCardMethodContents,
        setDownPaymentMethod,
        setRecurringPaymentMethod,
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};

export const usePaymentMethods = () => {
  const context = useContext(PaymentMethodsContext);
  if (context === undefined) {
    throw new Error('usePaymentMethods must be used within a PaymentMethodsProvider');
  }
  return context;
};
