import client from 'lib/graphql/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BackIcon, Loading } from 'lib/components';
import { ButtonPrimary } from 'lib/components';
import { GET_CUSTOMER_MEMBERSHIP_PLAN_DETAIL, GET_MEMBERSHIPS_CUSTOMER_PAYMENT_METHOD } from 'lib/graphql/queries';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Column, Container, Row } from 'lib/styles';
import { PaymentMethod } from 'pages/desktop/Membership/type';
import { useNavigate, useParams } from 'react-router-dom';
import { BenefitUsage, MemberInfo } from '../components/MemberDetail';

export const MemberDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isSnackbarVisible } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [customerMembershipDetail, setCustomerMembershipDetail] = useState(null);
  const [customerMembershipPaymentMethod, setCustomerMembershipPaymentMethod] = useState<PaymentMethod | undefined>();

  useEffect(() => {
    getCustomerMembershipPlanDetail();
  }, []);

  const goBack = () => {
    navigate('/membership/home');
  };

  const getCustomerMembershipPlanDetail = async () => {
    try {
      setLoading(true);
      const { data: { fetchCustomerMembershipsPlanDetail } = {} } = await client.query({
        query: GET_CUSTOMER_MEMBERSHIP_PLAN_DETAIL,
        variables: {
          input: {
            planId: id,
          },
        },
      });
      setLoading(false);
      if (fetchCustomerMembershipsPlanDetail?.id) {
        if (fetchCustomerMembershipsPlanDetail?.paymentMethodId) {
          getCustomerMembershipPaymentMethod(fetchCustomerMembershipsPlanDetail?.paymentMethodId);
        }
        setCustomerMembershipDetail(fetchCustomerMembershipsPlanDetail);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCustomerMembershipPaymentMethod = async (paymentMethodId: number) => {
    try {
      setLoading(true);
      const { data: { fetchMembershipsCustomerPaymentMethod } = {} } = await client.query({
        query: GET_MEMBERSHIPS_CUSTOMER_PAYMENT_METHOD,
        variables: {
          input: {
            paymentMethodId,
          },
        },
      });
      setLoading(false);
      if (fetchMembershipsCustomerPaymentMethod?.id) {
        setCustomerMembershipPaymentMethod(fetchMembershipsCustomerPaymentMethod);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <Column maxWidth="1160px">
        <Row>
          <ButtonPrimary
            data-testid="backToHome"
            onClick={goBack}
            startIcon={<BackIcon color={'#FFFFFF'} />}
            text={'Back to Home'}
          />
        </Row>
        <Row>
          <MemberInfo
            customerPlanData={customerMembershipDetail}
            customerPaymentData={customerMembershipPaymentMethod}
            fetchPlanDetail={getCustomerMembershipPlanDetail}
          />
        </Row>
        <Row>
          <BenefitUsage data={customerMembershipDetail} getCustomerPlanDetail={getCustomerMembershipPlanDetail} />
        </Row>
      </Column>
      {loading && (
        <LoadingContainer>
          <Loading size={25} />
        </LoadingContainer>
      )}
    </Container>
  );
};

const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;
