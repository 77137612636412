import { gql } from '@apollo/client/core';

export const FETCH_APPOINTMENT_LIST = gql`
  query FetchAppointmentList($input: AppointmentListingRequest) {
    fetchAppointmentList(input: $input) {
      success
      message
      code
      contents {
        id
        customer {
          firstName
          lastName
          phone
        }
        startAt
        endAt
        applicationId
        merchantId
        organizationId
        createdAt
        updatedAt
        application {
          activeLoanId
          balanceAvailable
          borrowerId
          borrower {
            firstName
            lastName
            phone
          }
          activeLoans {
            displayId
            purchaseAmount
            merchantRevenue
            merchantFund
            amount
            status
            subStatus
            createdAt
            fundedAt
          }
          channel
          createdAt
          demo
          displayId
          expireAt
          id
          merchantId
          maxEligible
          menuId
          organizationId
          partner
          referralSource
          approvalExtended
          productsManuallyEdited
          purchaseAmount
          scheduleStatus
          scheduledAt
          selfCheckout
          status
          updatedAt
          readyForCheckout
          validity
          creditLineIncrease
          activeLoans {
            displayId
            purchaseAmount
            merchantFund
            amount
            status
          }
          outboundMessages {
            message
            id
            type
            applicationStatus
            createdAt
          }
        }
      }
      total
    }
  }
`;

export const GET_APPOINTMENT_SYNC_STATUS = gql`
  query getAppointmentSyncStatus($input: AppointmentSyncRequest) {
    getAppointmentSyncStatus(input: $input) {
      syncedBy
      syncedAt
    }
  }
`;
