import styled from 'styled-components';

import { PickerWrapper } from 'lib/components';

interface ButtonInput {
  margin?: boolean;
}

export const Container = styled.div<{ isSnackbarVisible: boolean }>`
  margin-top: ${(props) => (props.isSnackbarVisible ? '190px' : '150px')};
  padding: 0 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const BorderContainer = styled.div<{ hasError?: boolean }>`
  flex: 1;
  background: #ffffff;
  position: relative;
  border-radius: 2px;
  border: 1px solid ${(props) => (props?.hasError ? '#ff0000' : '#dadada')};
  display: flex;
  align-items: center;
  height: 46px;

  input {
    border: unset;
    font-family: 'Open Sans', sans-serif !important;

    ::placeholder {
      font-family: 'Open Sans', sans-serif !important;
    }
  }

  #fade-button {
    width: 100%;

    &:hover {
      background-color: #ffffff !important;
    }
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  overflow: visible !important;

  > div {
    margin: 0 5px;
  }
`;

export const TableColumn = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin: auto 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableBody > div:first-child {
    margin: 0 !important;
  }

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow > div:first-child {
    display: none !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;
    border-radius: 0 0 0 0;
    border-bottom: 0;
    height: 71px;

    > div {
      background-color: #ffffff;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    > div:first-child {
      display: none;
    }

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableRow {
    box-shadow: 0 6px 9px rgb(0 0 0 / 7%), 0 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0 0;
    cursor: initial;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    &:hover #btn--download {
      display: block !important;
    }
  }

  #column-refunds:active,
  #column-refunds:hover,
  #column-refunds:visited,
  #column-refunds:focus-within,
  #column-refunds:focus-visible,
  #column-borrowerName:active,
  #column-borrowerName:hover,
  #column-borrowerName:visited,
  #column-borrowerName:focus-within,
  #column-borrowerName:focus-visible,
  #column-disbursement,
  #column-salesperson {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 250px;
    box-shadow: 0 36px 67px rgba(0, 0, 0, 0.07), 0 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
`;

export const Card = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 50px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 50px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 50px 0 rgba(0, 0, 0, 0.3);
  padding: 32px;
  box-sizing: border-box;
  flex-direction: column;
`;

export const DataCard = styled(Card)`
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.07), 0 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  display: flex;
  min-height: 64px;
  max-height: 64px;
  position: relative;
`;

export const CenteredDataCard = styled(DataCard)`
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: inherit;
  margin-bottom: 60px;
  margin-left: 5px;
`;

export const ButtonContainer = styled.button<ButtonInput>`
  background-color: ${(props) => (props?.disabled ? props?.theme?.main?.grayColor : props.theme.main.green)};
  border: ${(props) => `1px solid ${props?.disabled ? props?.theme?.main?.grayColor : props.theme.main.green}`};
  margin-left: ${(props) => (props.margin ? '16px' : '')};
  height: 48px;
  margin-right: 5px;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 2px;
  &:focus,
  &:hover {
    outline: none;
    background-color: ${(props) => (props?.disabled ? props?.theme?.main?.grayColor : '#00a569')};
  }
`;

export const DropDownContainer = styled.div`
  width: 200px;
  background-color: white;
  box-sizing: border-box;
  .dropdown-right {
    height: 46px;
  }
  .dropdown-content {
    width: 100%;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;

export const MaterialRequestContainer = styled.div`
  min-width: 480px;
  button {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .MuiDialogContent-root {
    padding: 0px;
  }
`;

export const CenterAligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export const CherryDialogCloseIconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
`;

export const StyledPickerWrapper = styled(PickerWrapper)`
  background-color: #ffffff;
  height: 46px;
  max-width: 200px;
  position: relative;
  border: 1px solid #dadada;
  margin: 0;

  > input {
    margin-right: 30px !important;
    margin-top: 0 !important;
  }

  > input + svg {
    margin-top: 0 !important;
  }
`;
