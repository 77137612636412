import dayjs from 'dayjs';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useApplicationFilter, useEventBus } from 'lib/services';
import StorageService from 'lib/services/Storage';

import 'pages/desktop/Dashboard/views/ApplicationLinkGuide/application-guide.css';

interface HintSteps {
  element?: string;
  position: string;
  intro: JSX.Element;
  tooltipClass: string;
  highlightClass: string;
}
export const ApplicationLinkGuide = () => {
  const { user, organization } = useStore();
  const { onEvent } = useEventBus();
  const { getMerchantTextReferralApplications } = useApplicationFilter();
  const [hints, setHints] = useState<HintSteps[]>([]);
  const [shouldDisplayOnboarding, setShouldDisplayOnboarding] = useState<boolean>(false);

  const storedStatus: boolean = JSON.parse(StorageService.getApplicationGuideStatus() || 'false');

  useEffect(() => {
    if (!storedStatus) {
      const days = dayjs().diff(organization?.activatedAt, 'days');
      const daysAfterActivation = days ? Number(days) : 0;

      getMerchantTextReferralApplications().then((response) => {
        if (response?.total < 1 && daysAfterActivation > 3) {
          setShouldDisplayOnboarding(true);
        }
      });
    }

    onEvent(BusEvent.GUIDED_WALKTHROUGH_TRIGGER, () => {
      setShouldDisplayOnboarding(true);
    });
  }, []);

  const onExit = () => {
    setShouldDisplayOnboarding(false);
    StorageService.setApplicationGuideStatus(true);
  };

  useEffect(() => {
    setTimeout(() => {
      const steps = [
        {
          position: 'right',
          intro: (
            <span>
              <FirstStepTitleContainer>
                <FirstStepTitle>
                  {user?.firstName ? `Welcome Back, ${user?.firstName}!` : 'Welcome Back!'}
                </FirstStepTitle>
              </FirstStepTitleContainer>
              <Gap />
              <FirstStepRow>
                <FirstStepBody>
                  Here are some tips to help you navigate your Practice Portal and help your patients get the treatments
                  they need!
                </FirstStepBody>
              </FirstStepRow>
              <FirstStepRow>
                <FirstStepBody>Click "Next" to learn how to use the portal like a pro! </FirstStepBody>
              </FirstStepRow>
            </span>
          ),
          tooltipClass: 'firstStep',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '#text--application',
          position: 'right',
          intro: (
            <span>
              <TitleContainer>
                <IconContainer>
                  <StrokeIcon src={images?.strokeIcon} />
                </IconContainer>
                <Title>Start Reaching Patients!</Title>
              </TitleContainer>
              <Row>
                <Paragraph>
                  Type in a <BoldText>patient’s phone number</BoldText> and click <GreenText>Send</GreenText> to send
                  them <BoldText>your unique application link.</BoldText>
                </Paragraph>
              </Row>
              <Row>
                <Paragraph>
                  They can apply directly from their phone to see if they’re approved to use Cherry for your services!
                </Paragraph>
              </Row>
            </span>
          ),
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '#application--link',
          intro: (
            <span>
              <TitleContainer>
                <IconContainer>
                  <StrokeIcon src={images?.strokeIcon} />
                </IconContainer>
                <Title>Your Application Link</Title>
              </TitleContainer>
              <Paragraph>
                You can also find <BoldText>your unique application link</BoldText> here. Quickly{' '}
                <GreenText>copy and paste</GreenText> the link to send it to patients with ease.
              </Paragraph>
            </span>
          ),
          position: 'right',
          tooltipClass: 'cherryTooltip',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '#payment--estimator',
          position: 'left',
          intro: (
            <span>
              <TitleContainer>
                <IconContainer>
                  <StrokeIcon src={images?.strokeIcon} />
                </IconContainer>
                <Title>Provide Real-Time Estimates</Title>
              </TitleContainer>

              <Row>
                <Paragraph>
                  Use the <GreenText>Payment Estimator</GreenText> to compare pricing plans for your services.
                </Paragraph>
              </Row>
              <Row>
                <Paragraph>
                  Then <GreenText>generate the estimate</GreenText> to create a customized treatment plan PDF that you
                  can give directly to your patient.
                </Paragraph>
              </Row>
            </span>
          ),
          tooltipClass: 'cherryTooltip lastItem',
          highlightClass: 'myHighlightClass',
        },
      ];
      setHints(steps);
    }, 3000);
  }, [shouldDisplayOnboarding]);

  const onStepChange = (activeStep: number) => {
    if (activeStep === 2) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <StyledSteps
      key="application-steps"
      enabled={shouldDisplayOnboarding}
      onChange={onStepChange}
      options={{
        showStepNumbers: false,
        keyboardNavigation: false,
        overlayOpacity: 0.8,
        scrollToElement: true,
        scrollTo: 'element',
        hideNext: true,
        nextToDone: false,
        disableInteraction: true,
        exitOnOverlayClick: false,
        skipLabel: 'x',
        doneLabel: 'Done',
      }}
      steps={hints}
      initialStep={0}
      onExit={onExit}
    />
  );
};

const StyledSteps = styled(Steps)`
  background-color: #ff0000 !important;
  .introjs-tooltipbuttons {
    display: none !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  padding-bottom: 18px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  padding-bottom: 18px;
`;

const Paragraph = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #56636d;

  padding-bottom: 16px;
`;

const GreenText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #00c37d;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
`;

const StrokeIcon = styled.img`
  width: 16px;
  height: 19px;
`;

const IconContainer = styled.div`
  margin-right: 12px;
`;

const FirstStepRow = styled.div`
  display: flex;
  justify-content: center;
`;

const FirstStepTitleContainer = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  padding-bottom: 24px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const FirstStepTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
`;

const Gap = styled.div`
  margin-bottom: 24px;
`;

const FirstStepBody = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;

  padding-bottom: 16px;
`;
