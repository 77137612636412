import React from 'react';
import styled from 'styled-components';

import { APP_PREQUAL_URL } from 'config';
import { Button, Container } from 'lib/components/mobile';
import { useSentry } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useAlert } from 'react-alert';

const MobileCherryLink = () => {
  const { organization } = useStore();
  const { captureMessage } = useSentry();
  const alert = useAlert();

  const applicationLink = `${APP_PREQUAL_URL}/${organization?.slug}/?utm_source=mobilepracticeportal`;

  const onShare = () => {
    try {
      const shareData = {
        title: 'Unique Cherry Application Link',
        url: applicationLink,
      };
      if (navigator?.share) {
        navigator.share(shareData);
      } else {
        alert.success('Application Link Copied to your clipboard');
        navigator.clipboard.writeText(applicationLink);
      }
    } catch (err: any) {
      captureMessage(`MobileCherryLink => onShare ${err.message}`);
    }
  };

  return (
    <Container pageTitle="Your Cherry Link">
      <CherryLinkContainer>
        <Information>Share your application link with customers for exclusive approvals at your practice.</Information>
        <ShareButton>
          <Button onClick={onShare}>Share My Link</Button>
        </ShareButton>
      </CherryLinkContainer>
    </Container>
  );
};

export default MobileCherryLink;

const CherryLinkContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const Information = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const ShareButton = styled.div`
  margin-top: 16px;
`;
