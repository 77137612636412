import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@frontend/cherry-library';
import { CherryLink, DashCard, DashComponent } from 'lib/components';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import { handleCopyToClipboard } from 'lib/utils';
import React from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

export const MessagingTemplates = ({ messagingPdfUrl, emailSnippets, title }) => {
  const PREQUAL_URL = process.env.REACT_APP_PREQUAL_URL;
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const alert = useAlert();
  const { organization, merchants } = useStore();

  const handleCopy = (e) => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    const description = e?.currentTarget?.getAttribute?.('data-item');
    const itemTitle = e?.currentTarget?.getAttribute?.('data-title');

    navigator.clipboard.writeText(description);
    handleCopyToClipboard(e, `${description} ${PREQUAL_URL}/${organization?.slug}`);
    alert.success('Snippet was copied to your clipboard');

    trackEvent({
      action: MarketingAnalyticsEventNames.COPY_CLIPBOARD,
      label: e.target.dataset?.item?.toString(),
    });
    trackSegmentEvent('Copied Text Template', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      templateName: itemTitle,
    });
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <SubHeader size={'16px'}>Text Message Snippets</SubHeader>
        <EmailSnippetList>
          {emailSnippets.map((item) => {
            return (
              <Snippet key={item.id}>
                <Flex style={{ width: '90%' }}>
                  {' '}
                  <Row style={{ fontSize: '14px', fontWeight: 'bold' }}> {item.title} </Row>
                  <Row style={{ fontSize: '14px', marginTop: '8px' }}>
                    {' '}
                    {item.description}
                    <CherryLink href={`${PREQUAL_URL}/${organization?.slug}`} target="_blank">
                      {PREQUAL_URL}/{organization?.slug}
                    </CherryLink>
                  </Row>
                </Flex>
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={handleCopy}
                  data-item={item.description}
                  data-title={item.title}
                  data-testid="copyButton"
                >
                  <FontAwesomeIcon icon={faClone} /> &nbsp;Copy{' '}
                </Button>
              </Snippet>
            );
          })}
        </EmailSnippetList>
      </DashCard>
    </Column>
  );
};

const Flex = styled.div``;

const EmailSnippetList = styled.ul`
  padding: 0px;

  > li:last-child {
    border-bottom: 1px solid #dadada;
  }
`;
const Snippet = styled.li`
  list-style: none;
  border-top: 1px solid #dadada;
  display: flex;
  gap: 8px;
  padding: 16px 0px;

  button {
    height: fit-content;
    margin: auto 0;
  }
`;
