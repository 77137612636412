import { cloneDeep } from '@apollo/client/utilities';

export const formatPayload = (data) => {
  let tempProduct = cloneDeep(data);
  tempProduct.fileKeys = tempProduct?.files?.map((file) => file.key);
  tempProduct.imageKey = tempProduct?.image?.key;

  if (tempProduct.membershipPlanId) tempProduct.membershipPlanId = parseInt(tempProduct.membershipPlanId, 10);
  if (tempProduct.numberOfMonths) tempProduct.numberOfMonths = parseInt(tempProduct.numberOfMonths, 10);
  if (tempProduct.price) tempProduct.price = parseFloat(tempProduct.price);
  if (tempProduct.compareAtPrice) tempProduct.compareAtPrice = parseFloat(tempProduct.compareAtPrice);
  if (tempProduct.monthlyPayment) tempProduct.monthlyPayment = parseFloat(tempProduct.monthlyPayment);
  if (tempProduct.apr) tempProduct.apr = parseFloat(tempProduct.apr);

  tempProduct.fulfillmentOptions = tempProduct?.fulfillmentOptions?.map((fulfillmentOption) => ({
    ...fulfillmentOption,
    shippingPrice: fulfillmentOption.shippingPrice ? parseFloat(fulfillmentOption.shippingPrice) : null,
  }));

  delete tempProduct?.files;
  delete tempProduct?.image;
  delete tempProduct?.offeringImage;

  return tempProduct;
};
