import { useMutation } from '@apollo/client';
import { Button as CherryButton } from '@frontend/cherry-library';
import dayjs from 'dayjs';
import { DashCard } from 'lib/components';
import Popper from 'lib/components/Popper';
import { MARKETING_MATERIAL_IDS } from 'lib/constants';
import { ORDER_MARKETING_MATERIAL } from 'lib/graphql/mutations';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Row, SubHeader } from 'lib/styles';
import { getMaterialTypeById } from 'lib/utils';
import MarketingSupplyItem from 'pages/desktop/OtherResourcesTemplates/components/MarketingSupplyItem';
import OrderConfirmedModal from 'pages/desktop/OtherResourcesTemplates/components/OrderConfirmedModal';
import RequestMaterialModal, {
  RequestMaterial,
} from 'pages/desktop/OtherResourcesTemplates/components/RequestMaterialModal';
import { MarketingMaterialItem } from 'pages/desktop/OtherResourcesTemplates/views/DigitalFiles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

interface MarketingSuppliesProps {
  marketingMaterialList: MarketingMaterialItem[];
}

const ORDER_BUTTON_TEXTS = {
  [MARKETING_MATERIAL_IDS.TRIFOLD_BROCHURE]: 'Order Brochures',
  [MARKETING_MATERIAL_IDS.PATIENT_ONE_PAGER]: 'Order One Pagers',
  [MARKETING_MATERIAL_IDS.QR_CARD]: 'Order QR Cards',
  [MARKETING_MATERIAL_IDS.FULL_KIT]: 'Order Full Kit',
};

const MARKETING_MATERIAL_DAY = 30;

const MarketingSupplies = ({ marketingMaterialList }: MarketingSuppliesProps) => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const [isOrderDisabled, setIsOrderDisabled] = useState<boolean>(false);
  const [showDateTooltip, setShowDateTooltip] = useState<boolean>(false);
  const [selectedMaterial, setSelectedMaterial] = useState<MarketingMaterialItem | null>();
  const [isOrderSuccessModalVisible, setIsOrderSuccessModalVisible] = useState<boolean>(false);
  const orderFullKitButtonRef = useRef<HTMLDivElement>(null);

  const isSupplyAvailable = true;

  const organizationMaterialValidDate = useMemo(() => {
    if (isOrderDisabled) {
      return dayjs(organization?.createdAt).add(MARKETING_MATERIAL_DAY, 'day').format('MMM DD, YYYY');
    } else {
      return '';
    }
  }, [organization?.createdAt, isOrderDisabled]);

  const isEnoughDaysPassed = useMemo(() => {
    if (organization?.createdAt) {
      const days = dayjs().diff(organization?.createdAt, 'days');
      const daysAfterCreateDate = days ? Number(days) : 0;
      return daysAfterCreateDate > MARKETING_MATERIAL_DAY;
    }

    return false;
  }, [organization]);

  useEffect(() => {
    setIsOrderDisabled(!isEnoughDaysPassed);
  }, [isEnoughDaysPassed]);

  const [orderMarketingMaterial] = useMutation(ORDER_MARKETING_MATERIAL);

  const handleOrderFullKit = () => {
    setSelectedMaterial({
      id: MARKETING_MATERIAL_IDS.FULL_KIT,
      title: 'Full Kit',
      image: marketingMaterialList?.[0].image,
      description: '',
      documentType: getMaterialTypeById(MARKETING_MATERIAL_IDS.FULL_KIT),
    });
    trackEvent({
      action: MarketingAnalyticsEventNames.REQUEST_MATERIALS_CLICK,
    });
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Began Marketing Material Order', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      materialType: 'Full Kit',
    });
  };

  const handleOrderItem = useCallback(
    (id: number) => {
      const selectedItem = marketingMaterialList.find((material) => material.id === id);

      if (selectedItem) {
        setSelectedMaterial(selectedItem);

        const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
        trackSegmentEvent('Began Marketing Material Order', {
          application: applicationName,
          organizationId: organization?.id,
          merchantId: idMerchant,
          materialType: selectedItem.title,
        });
      }
    },
    [marketingMaterialList],
  );

  const marketingSupplies = useMemo(() => {
    return marketingMaterialList?.map((marketingMaterialItem) => (
      <MarketingSupplyItemContainer key={`${marketingMaterialItem.id}-${marketingMaterialItem.title}`}>
        <MarketingSupplyItem
          {...marketingMaterialItem}
          orderButtonText={ORDER_BUTTON_TEXTS[marketingMaterialItem.id]}
          onOrderButtonClick={handleOrderItem}
          popularItem={true}
          disabled={!isSupplyAvailable}
          organizationMaterialValidDate={organizationMaterialValidDate}
        />
      </MarketingSupplyItemContainer>
    ));
  }, [isOrderDisabled, marketingMaterialList, handleOrderItem]);

  const handleRequestMaterialModalClose = () => {
    setSelectedMaterial(null);
  };

  const closeOrderSuccessModal = () => {
    setIsOrderSuccessModalVisible(false);
  };

  const handleMaterialRequest = async (material: RequestMaterial, merchantId?: number) => {
    const orderConfig: { merchantId?: number; organizationId?: number } = {};

    if (merchantId) {
      orderConfig.merchantId = merchantId;
    } else {
      orderConfig.organizationId = organization?.id;
    }

    const { data } = await orderMarketingMaterial({
      variables: {
        input: {
          ...material,
          ...orderConfig,
        },
      },
    });

    if (data?.orderMarketingMaterial?.success) {
      setSelectedMaterial(null);
      setIsOrderSuccessModalVisible(true);
    }
  };

  const handleFullKitButtonMouseEnter = useCallback(() => {
    if (!isEnoughDaysPassed && !showDateTooltip) {
      setShowDateTooltip(true);
    }
  }, [isEnoughDaysPassed, showDateTooltip]);

  const handleFullKitButtonMouseLeave = useCallback(() => {
    if (!isEnoughDaysPassed && showDateTooltip) {
      setShowDateTooltip(false);
    }
  }, [isEnoughDaysPassed, showDateTooltip]);

  return (
    <>
      <section>
        <DashCard>
          <Title>Need New Supplies?</Title>
          <Row fontSize="14px" marginBottom="16px">
            If you need more marketing materials, you can order new supplies. Shipping typically takes 8-10 business
            days. Digital downloads are also available for immediate use.
          </Row>

          <MarketingSuppliesList>{marketingSupplies}</MarketingSuppliesList>
          <FullKitSection>
            <Title>Need a Full Kit?</Title>
            <Row fontSize="14px">
              If you need a full set of supplies, you can order the same kit you received when you first joined Cherry.
              A full kit includes the following:
              <MaterialList>
                <li>Trifold Brochure (x50)</li>
                <li>Cherry Patient One-Pager (x50)</li>
                <li>QR Card with Acrylic Stands (x2)</li>
                <li>Talking Points Script (x1)</li>
              </MaterialList>
            </Row>
            {orderFullKitButtonRef?.current ? (
              <Popper anchorEl={orderFullKitButtonRef?.current} id="" open={!isSupplyAvailable} placement="top-start">
                We automatically send this kit to new practices. You can order another kit starting on{' '}
                {organizationMaterialValidDate}
              </Popper>
            ) : null}
            <OrderFullKitButtonContainer
              ref={orderFullKitButtonRef}
              onMouseEnter={handleFullKitButtonMouseEnter}
              onMouseLeave={handleFullKitButtonMouseLeave}
            >
              <CherryButton
                variant="primary"
                disabled={!isSupplyAvailable}
                data-testid="order-full-kit-button"
                onClick={handleOrderFullKit}
              >
                Order Full Kit
              </CherryButton>
            </OrderFullKitButtonContainer>
          </FullKitSection>
        </DashCard>
      </section>
      {selectedMaterial ? (
        <RequestMaterialModal
          selectedMaterial={selectedMaterial}
          onClose={handleRequestMaterialModalClose}
          onRequest={handleMaterialRequest}
        />
      ) : null}
      <OrderConfirmedModal visible={isOrderSuccessModalVisible} onClose={closeOrderSuccessModal} />
    </>
  );
};

const FullKitSection = styled.div``;

const Title = styled(SubHeader)`
  font-size: 16px;
  margin-bottom: 8px;
`;

const MaterialList = styled.ul`
  padding: 0 24px;
`;

const OrderFullKitButtonContainer = styled.div`
  width: fit-content;
`;

const MarketingSuppliesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarketingSupplyItemContainer = styled.div`
  padding: 24px 0;
  border-top: 1px solid ${(props) => props.theme.main.neutralGray};

  &:last-of-type {
    border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  }
`;

export default MarketingSupplies;
