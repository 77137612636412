import { useMutation } from '@apollo/client';
import { FormControl, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { Input } from 'lib/components';
import { LocationManagementStepEnums } from 'lib/types';
import {
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContentInherit,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';

import { CREATE_USER } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { clearMaskFormat } from 'lib/utils';
import { CreateMerchant } from './type';

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onChangeHandler: (key, value) => void;
  merchant: CreateMerchant;
}

export const AddPhoneNumber = ({ handleClose, onStepChange, onChangeHandler, merchant }: Props) => {
  const [createUser] = useMutation(CREATE_USER);
  const alert = useAlert();
  const { setNewUserId } = useStore();

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const onContinueHandler = async () => {
    try {
      const updateUserObject = JSON.parse(JSON.stringify(merchant.poc));
      delete updateUserObject?.['role'];

      setLoading(true);
      const { data } = await createUser({ variables: { input: { ...updateUserObject } } });
      setLoading(false);
      if (data?.createUserRequest?.error) {
        alert.error(data?.createUserRequest?.message);
      } else {
        setNewUserId(data.createUserRequest?.user?.id);
        onStepChange(LocationManagementStepEnums.LOCATION_ADDRESS);
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const handleChange = (nativeEvent: any) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    const formattedPhone = clearMaskFormat(value);
    setIsPhoneValid(formattedPhone?.length === 10);

    const pocObject = merchant.poc;
    const newPocObject = { ...pocObject, phone: formattedPhone };

    onChangeHandler('poc', newPocObject);
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Add Phone Number
      </CherryDialogTitle>
      <SubTitle>
        The selected point of contact does not have a phone number on file. Please enter in their phone number.
      </SubTitle>
      <DialogContentInherit>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <CustomInputMask
                id="location-management-phone-mask"
                data-testid="application-phone-input"
                mask="(999) 999-9999"
                name="phone"
                onChange={handleChange}
              >
                {(inputProps) => (
                  <CustomInput
                    id="location-management-phone"
                    {...inputProps}
                    placeholder="Phone"
                    data-inputkey="phone"
                  />
                )}
              </CustomInputMask>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContentInherit>

      <ContinueButton
        onClick={onContinueHandler}
        disabled={!isPhoneValid || loading}
        loading={loading}
        data-testid="submit"
      >
        Continue
      </ContinueButton>
    </DialogContainer>
  );
};

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-family: 'Open Sans';
  border-color: ${(props) => props.error && '#EC3360'};
  margin-bottom: 0px;
  -webkit-font-smoothing: unset;
`;

const CustomInputMask = styled(InputMask)`
  font-size: 16px;
  margin-bottom: 0px;
  border-color: rgb(205 204 204);
  border-radius: 4px;
  &.error {
    border: 1px solid #ff0000;
  }
  ::placeholder {
    color: #a2a2a2;
  }
`;
