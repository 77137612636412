import { FeatureNames } from 'lib/hooks';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import useStore from 'lib/hooks/useStore';

export const useShouldDisplayTextInput = () => {
  const { features } = useStore();
  const { flags } = useFlags();

  return (
    !features?.includes(FeatureNames.APPLY_WITH_PATIENT) &&
    !features?.includes(FeatureNames.MILESTONE) &&
    !features?.includes(FeatureNames.MILESTONE_REWARD)
  );
};
