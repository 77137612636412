import {
  Body,
  Button,
  Dropdown,
  EmailInput,
  emailPattern,
  Heading,
  PhoneNumberInput,
  phonePattern,
  TextArea,
  TextInput,
} from '@frontend/cherry-library';
import { ReactComponent as PrintIcon } from 'assets/images/print.svg';
import { ReactComponent as SendIcon } from 'assets/images/send-icon.svg';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import {
  DescriptionWrapper,
  FormWrapper,
  HeadingContainer,
  PatientInfoForm,
  ShareEstimateContainer,
  SplitRow,
} from '../styles';
import { PdfLocation } from '../types';
import { usePaymentEstimatorPage } from '../usePaymentEstimatorPage';
import { unformatPhone } from '../utils';

const ShareEstimate = ({ selectedPlan, enteredAmount, setEnteredPatientInfo, setActionTookPlace }) => {
  const { merchants } = useStore();
  const { segmentEventHandler } = useSegment();

  const {
    sendPlanViaSMS,
    sendPlanViaEmail,
    sendSMSinProgress,
    sendEmailInProgress,
    generateTreatmentPlan,
    fetchMerchantPhone,
    selectedMerchantPhone,
    isSendSuccessfull,
  } = usePaymentEstimatorPage();

  const [patientPhone, setPatientPhone] = useState('');
  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [selectedMerchant, setSelectedMerchant] = useState<PdfLocation>();

  const orgMerchants = merchants;

  const isChrome = !!window['chrome'];

  const { control, getValues } = useForm({
    mode: 'onChange',
  });

  const handleCreateTreatmentPlan = () => {
    const data = {
      firstName: patientFirstName,
      lastName: patientLastName,
      phone: patientPhone,
      email: getValues('send-email'),
      treatmentCost: enteredAmount,
      notes,
      paymentOptionsMerchantId: selectedLocation,
    };
    generateTreatmentPlan(data);
  };
  const handleSendClickViaSMS = () => {
    handleCreateTreatmentPlan();

    sendPlanViaSMS(
      {
        firstName: patientFirstName,
        lastName: patientLastName,
        phone: unformatPhone(patientPhone),
        notes,
      },
      {
        apr: selectedPlan?.apr,
        term: selectedPlan?.term,
        financeCharge: selectedPlan?.financeCharge,
        paymentAmount: parseFloat(enteredAmount),
      },
      selectedMerchant?.id || '',
    );
  };
  const handleSendClickViaEmail = () => {
    handleCreateTreatmentPlan();

    sendPlanViaEmail(
      {
        firstName: patientFirstName,
        lastName: patientLastName,
        phone: unformatPhone(patientPhone),
        notes,
        email: getValues('send-email'),
        treatmentCost: enteredAmount,
      },
      {
        apr: selectedPlan?.apr,
        term: selectedPlan?.term,
        financeCharge: selectedPlan?.financeCharge,
        paymentAmount: selectedPlan?.paymentAmount,
      },
      selectedMerchant?.name || '',
    );
  };

  const handlePhoneNumberChange = (event) => {
    setPatientPhone(event?.target?.value);
  };

  const onDropdownSelect = (e) => {
    fetchMerchantPhone(e?.target?.value);
    setSelectedLocation(e?.target?.value);
    setSelectedMerchant(orgMerchants?.find((merchant) => merchant.id === e?.target?.value));
  };

  const handlePrintEstimate = () => {
    setActionTookPlace(true);
    segmentEventHandler('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SHARE_TREATMENT_PLAN.PRINT_CLICKED');
    handleCreateTreatmentPlan();
    handlePrint();
  };

  const handlePrint = useReactToPrint({
    pageStyle: `
    
    @media print {
      html,
      body { 
        height: ${isChrome ? 'initial!important;' : '100%'};
        overflow: initial!important; 
      }
    }

    @media page {
      size: A4;
    }
    
    `,
    content: () => document?.getElementById('payment-estimator-print-pdf'),
  });

  const handleFirstNameChange = (e) => {
    setPatientFirstName(e?.target?.value);
  };

  const handleLastNameChange = (e) => {
    setPatientLastName(e?.target?.value);
  };
  const handleNotesChange = (e) => {
    setNotes(e?.target?.value);
  };

  useEffect(() => {
    if (!selectedLocation && orgMerchants?.length === 1) {
      const singleMerchant = orgMerchants[0]?.id;
      fetchMerchantPhone(singleMerchant);
      setSelectedLocation(singleMerchant);
      setSelectedMerchant(orgMerchants[0]);
    }
  }, [orgMerchants]);

  const locationOptions =
    orgMerchants?.map((location) => ({
      value: location?.id,
      label: `<b><span>${location?.name}</span> </b><br/>  ${location?.address?.address1} - ${location?.address?.city?.name} `,
      emailLabel: `${location?.name} - ${location?.address?.city?.name} `,
    })) || [];

  const isPhoneNumberValid = (phone: string) => {
    if (phone && phone !== '') {
      const isPhoneValid = phonePattern.test(phone);
      return isPhoneValid;
    }
    return true;
  };

  useEffect(() => {
    setEnteredPatientInfo({
      name: patientFirstName,
      surname: patientLastName,
      phone: patientPhone,
      location: orgMerchants?.find((merchant) => merchant.id === selectedLocation),
      locationPhone: selectedMerchantPhone,
      notes,
      amount: enteredAmount,
    });
  }, [patientFirstName, patientLastName, patientPhone, notes, selectedLocation, enteredAmount]);

  useEffect(() => {
    if (isSendSuccessfull) {
      setActionTookPlace(true);
    }
  }, [isSendSuccessfull]);

  return (
    <ShareEstimateContainer>
      <DescriptionWrapper>
        <HeadingContainer>
          <SendIcon width={24} height={24} id="payment-estimator-calculator-icon" />
          <Heading level="4" text="Share Estimate" />
        </HeadingContainer>
        <Body size="default">Compile your patient’s personalized estimate into a ready-to-share PDF.</Body>
      </DescriptionWrapper>
      <FormWrapper>
        {orgMerchants && orgMerchants.length > 1 && (
          <PatientInfoForm>
            <Heading level="5" text="Select Location" />
            <Dropdown
              size="large"
              options={locationOptions}
              onChange={onDropdownSelect}
              onClick={segmentEventHandler('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.LOCATION_DROPDOWN_SELECTED')}
              defaultValue={selectedLocation}
              value={selectedLocation}
              label={selectedLocation === '' ? 'Select a Location' : ''}
            />
          </PatientInfoForm>
        )}
        <PatientInfoForm>
          <Heading level="5" text="Patient Information" />
          <SplitRow>
            <TextInput
              className="w-full"
              id="apply-with-patient-first-name"
              name="firstName"
              inputProps={{
                'data-testid': 'firsName',
                'data-neuro-label': 'firstName',
              }}
              type="text"
              label="Patient First Name"
              value={patientFirstName}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.FIRST_NAME_SELECTED`)}
              onChange={handleFirstNameChange}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.FIRST_NAME_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              autoComplete="off"
            />

            <TextInput
              className="w-full"
              id="apply-with-patient-last-name"
              name="lastName"
              inputProps={{
                'data-testid': 'lastName',
                'data-neuro-label': 'lastName',
              }}
              type="text"
              label="Patient Last Name"
              value={patientLastName}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.LAST_NAME_SELECTED`)}
              onChange={handleLastNameChange}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.LAST_NAME_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              autoComplete="off"
            />
          </SplitRow>

          <PhoneNumberInput
            inputProps={{
              'data-testid': 'phone',
              'data-neuro-label': 'phone',
            }}
            label="Phone Number"
            hideIcon={true}
            id="apply-with-patient-phone"
            name="phone"
            data-testid="phone"
            data-neuro-label="phone"
            type="tel"
            value={patientPhone}
            onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.PHONE_SELECTED`)}
            onChange={handlePhoneNumberChange}
            onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.PHONE_FILLED`, {
              withInput: true,
              isFilledEvent: true,
            })}
            error={!isPhoneNumberValid(patientPhone)}
            errorText={'Please enter a valid US mobile phone number'}
            autoComplete="off"
          />

          <TextArea
            onChange={handleNotesChange}
            value={notes}
            inputSize="medium"
            placeholder="Notes (optional)"
            maxLength={500}
            onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.NOTES_SELECTED`)}
            onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.NOTES_FILLED`, {
              withInput: true,
              isFilledEvent: true,
            })}
          />
        </PatientInfoForm>
        <PatientInfoForm>
          <SplitRow>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              id="payment-estimator-phone-input"
              name="send-phone"
              onChange={handlePhoneNumberChange}
              value={patientPhone}
              error={!isPhoneNumberValid(patientPhone)}
              errorText={'Please enter a valid US mobile phone number'}
              onClick={segmentEventHandler(
                'PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SHARE_TREATMENT_PLAN.SEND_SMS_CLICKED',
              )}
              onBlur={segmentEventHandler(
                'PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SHARE_TREATMENT_PLAN.PHONE_INPUT_FILLED',
                {
                  withInput: true,
                  isFilledEvent: true,
                },
              )}
              actionButton={{
                text: 'Send',
                onClick: handleSendClickViaSMS,
                disabled:
                  !(
                    patientPhone &&
                    isPhoneNumberValid(patientPhone) &&
                    selectedPlan?.term &&
                    patientFirstName &&
                    patientLastName
                  ) || sendSMSinProgress,
              }}
              inputProps={{
                'data-testid': 'estimator-phone-input',
              }}
            />

            <Controller
              name="send-email"
              control={control}
              rules={{
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email address',
                },
              }}
              // tslint:disable-next-line: jsx-no-lambda
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EmailInput
                  name="email"
                  data-testid="email-input"
                  error={!!error}
                  errorText={error?.message}
                  value={value}
                  onClick={segmentEventHandler(
                    'PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SHARE_TREATMENT_PLAN.SEND_EMAIL_CLICKED',
                  )}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    segmentEventHandler(
                      'PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SHARE_TREATMENT_PLAN.EMAIL_INPUT_FILLED',
                      {
                        withInput: true,
                        isFilledEvent: true,
                      },
                    );
                  }}
                  actionButton={{
                    text: 'Send',
                    onClick: handleSendClickViaEmail,
                    disabled:
                      !(
                        value &&
                        !error &&
                        selectedPlan?.term &&
                        patientFirstName &&
                        patientLastName &&
                        patientPhone &&
                        isPhoneNumberValid(patientPhone)
                      ) || sendEmailInProgress,
                  }}
                  inputProps={{
                    'data-testid': 'email-input',
                  }}
                />
              )}
            />
          </SplitRow>
          <Button
            fullWidth={true}
            disabled={
              !(
                patientPhone &&
                isPhoneNumberValid(patientPhone) &&
                selectedPlan?.term &&
                patientFirstName &&
                patientLastName
              )
            }
            onClick={handlePrintEstimate}
            leftIcon={PrintIcon}
          >
            Print
          </Button>
        </PatientInfoForm>
      </FormWrapper>
    </ShareEstimateContainer>
  );
};

export default ShareEstimate;
