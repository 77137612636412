import { Button as CherryButton } from '@frontend/cherry-library';
import { Dropdown } from '@frontend/cherry-library';
import { Grid, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { useApolloClient } from '@apollo/client';
import { APP_PREQUAL_URL } from 'config';
import { Loading } from 'lib/components';
import { APPLICATION_LINK_PLATFORMS } from 'lib/constants';
import { FETCH_ORGANIZATION_GROUP_TREE } from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useAlert } from 'react-alert';

interface Props {
  isInHeader?: boolean;
  handleClose?: () => void;
}

const ApplicationLinkDropdown = ({ label, options, isInHeader, handleChange, handleClick }) => {
  const gridItemProps = {
    item: true,
    xs: isInHeader ? 12 : 7,
  };

  return (
    <LinkGridContainer {...gridItemProps}>
      <Dropdown label={label} options={options} onClick={handleClick} onChange={handleChange} />
    </LinkGridContainer>
  );
};

export const ApplicationLink = ({ isInHeader, handleClose }: Props) => {
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const client = useApolloClient();
  const { organization, merchants = [], isAdminReporting, selectedOrganizationGroup } = useStore();
  const [loading, setLoading] = useState(false);

  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState(
    merchants?.length && merchants.length === 1 ? merchants[0].id : null,
  );
  const alert = useAlert();
  const [applicationLink, setApplicationLink] = useState(
    `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=partnerportal`,
  );

  const fetchOrganizationGroupTree = async () => {
    setLoading(true);
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_GROUP_TREE,
      variables: { input: { organizationGroupId: selectedOrganizationGroup?.id } },
    });
    const organizationGroupTree = data?.fetchOrganizationGroupTree;
    if (organizationGroupTree?.id) {
      setApplicationLink(`${APP_PREQUAL_URL}/${organizationGroupTree?.slug}?utm_source=partnerportal`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isAdminReporting && selectedOrganizationGroup?.id) {
      fetchOrganizationGroupTree();
    }
  }, [selectedOrganizationGroup]);

  const locationOptions: any = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: `${organization?.name} - ${merchant?.name}`,
    };
  });

  const locationChange = (event: SelectChangeEvent<unknown>) => {
    const selection = event?.target?.value;

    trackSegmentEvent('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_LOCATION_SELECTION_CLICKED', {
      location: 'Top Bar',
    });
    setSelectedLocation(selection);
  };

  const platformChange = (event: SelectChangeEvent<unknown>) => {
    const destination = event?.target?.value as string;

    trackSegmentEvent('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_DESTINATION_SELECTION_CLICKED', {
      destination,
    });
    setSelectedPlatform(destination);
  };

  useEffect(() => {
    let link = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice`;
    if (selectedPlatform && selectedPlatform !== 'other') {
      link = link + `&utm_medium=${selectedPlatform}`;
    }
    if (selectedLocation) {
      link = link + `&m=${selectedLocation}`;
    }
    setApplicationLink(link);
  }, [selectedPlatform, selectedLocation]);

  const handleCopy = () => {
    trackSegmentEvent('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_COPY_LINK_CLICKED', {
      link: applicationLink,
      location: selectedLocation,
      destionation: selectedPlatform,
    });
    navigator.clipboard.writeText(applicationLink);
    alert.success('Application Link was copied to your clipboard');
    handleClose && handleClose();
  };

  const shouldShowSelectLocation = () => {
    return merchants?.length && merchants?.length > 1 && !isAdminReporting;
  };

  if (loading) {
    return (
      <Center>
        <Loading size={24} />
      </Center>
    );
  }

  return (
    <>
      {isInHeader && <GridStyles />}
      <ApplicationLinkContainer>
        <GridContainer container={true} spacing={3}>
          {shouldShowSelectLocation() && (
            <ApplicationLinkDropdown
              label="Select Location"
              options={locationOptions}
              isInHeader={isInHeader}
              handleChange={locationChange}
              handleClick={segmentEventHandler('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_LOCATION_DROPDOWN_SELECTED')}
            />
          )}
          <ApplicationLinkDropdown
            label="Where are you sharing your link?"
            options={APPLICATION_LINK_PLATFORMS}
            isInHeader={isInHeader}
            handleChange={platformChange}
            handleClick={segmentEventHandler('PRACTICE_PORTAL.APPLICATION_LINK.MODAL_DESTINATION_DROPDOWN_SELECTED')}
          />
          <LinkGridContainer item={true} xs={12}>
            <Description>Send this unique link to customers for exclusive approvals at your practice.</Description>
          </LinkGridContainer>
          <ApplicationLinkGrid item={true} xs={12}>
            <LinkContainer>
              <ApplicationLinkTypography inHeader={isInHeader} disable={!selectedLocation || !selectedPlatform}>
                {applicationLink}
              </ApplicationLinkTypography>
            </LinkContainer>
          </ApplicationLinkGrid>
          <GridButtonContainer item={true} xs={12}>
            <CopyLinkButtonContainer inHeader={isInHeader}>
              <CherryButton fullWidth={isInHeader} variant="primary" onClick={handleCopy} data-testid="copy-button">
                Copy Link
              </CherryButton>
            </CopyLinkButtonContainer>
          </GridButtonContainer>
        </GridContainer>
      </ApplicationLinkContainer>
    </>
  );
};

const GridStyles = createGlobalStyle`
  .MuiPopover-root.MuiMenu-root[role='presentation'] {
    margin-top: 0;
  }
`;

const ApplicationLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const GridContainer = styled(Grid)`
  margin: -12px !important;
`;

const ApplicationLinkTypography = styled.div<{ inHeader?: boolean; disable?: boolean }>`
  font-size: 14px !important;
  overflow-x: auto;
  white-space: nowrap;
  text-decoration: underline !important;
  color: ${(props) => (props.disable ? props.theme.main.grayColor : props.theme.main.midnightBlue)};
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  max-width: ${(props) => (props.inHeader ? '600px' : 'auto')};
  font-family: Courier !important;
`;

const Description = styled.span`
  font-weight: 400;
  margin-top: 0px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const LinkContainer = styled.div`
  padding: 8px 16px;
  gap: 8px;
  background: #f2f4f5;
  border-radius: 4px;
`;

const CopyLinkButtonContainer = styled.div<{ inHeader?: boolean; disable?: boolean }>`
  width: ${(props) => (props.inHeader ? '100%' : 'fit-content')};
`;

const Center = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const LinkGridContainer = styled(Grid)`
  padding: 8px 12px !important;
`;

const ApplicationLinkGrid = styled(Grid)`
  padding: 0 12px !important;
`;

const GridButtonContainer = styled(Grid)`
  padding: 16px 12px !important;
`;
