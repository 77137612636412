import { gql } from '@apollo/client/core';

export const FETCH_FILES = gql`
  query getFileList($input: FileListRequest) {
    getFileList(input: $input) {
      contents {
        createdAt
        mime
        status
        id
        csvId
        name
      }
      total
    }
  }
`;

export const GET_FILE = gql`
  query getFile($input: FileDetailRequest) {
    getFile(input: $input) {
      success
      message
      code
      data {
        name
        mime
        data
      }
    }
  }
`;
