import { DialogProps as MuiDialogProps } from '@mui/material';
import { Loading } from 'lib/components';
import useDocumentRequest from 'lib/hooks/useDocumentRequest';
import { convertNewlinesToBr, getBase64 } from 'lib/utils';
import GenericFileModal from 'pages/desktop/DocumentRequests/components/GenericFileModal/GenericFileModal';
import ConfirmSubmissionModal from 'pages/desktop/DocumentRequests/views/ConfirmSubmissionModal/ConfirmSubmissionModal';
import ErrorModal, { ErrorType } from 'pages/desktop/DocumentRequests/views/ErrorModal/ErrorModal';
import FileList from 'pages/desktop/DocumentRequests/views/FileList/FileList';
import RemoveFileModal from 'pages/desktop/DocumentRequests/views/RemoveFileModal/RemoveFileModal';
import React, { useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

export interface File {
  fileName: string;
  data: string;
  mime: string;
}

interface FileUploadModalProps extends MuiDialogProps {
  documentTitle: string;
  externalNote?: string;
  documentRequestId: string;
  userId: number;
  idOrganization?: number;
  idMerchant?: number;
  requiredNumberOfDocs: number;
  relations: Array<{ id: number; type: string }>;
  onCloseModal: () => void;
  userType?: string;
}

interface ModalConfig {
  open: boolean;
}

interface ErrorModalConfig extends ModalConfig {
  type: ErrorType | null;
}

interface RemoveFileModalConfig extends ModalConfig {
  removedFileIndex: number | null;
}

interface ConfirmSubmissionModalConfig extends ModalConfig {
  requiredNumberOfDocs: number;
}

const defaultErrorModalState = { open: false, type: null };
const defaultRemoveFileModalState = { open: false, removedFileIndex: null };

const FileUploadModal = ({
  userId,
  documentRequestId,
  requiredNumberOfDocs,
  documentTitle,
  externalNote,
  onCloseModal,
  idMerchant,
  idOrganization,
  relations,
  userType,
  ...rest
}: FileUploadModalProps) => {
  const alert = useAlert();
  const { uploadFilesForDocumentRequest, submitFilesToDocumentRequest } = useDocumentRequest();

  const fileInput = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<File[]>([]);
  const [errorModal, setErrorModal] = useState<ErrorModalConfig>(defaultErrorModalState);
  const [removeFileModal, setRemoveFileModal] = useState<RemoveFileModalConfig>(defaultRemoveFileModalState);
  const [confirmSubmissionModalConfig, setConfirmSubmissionModalConfig] = useState<ConfirmSubmissionModalConfig>({
    open: false,
    requiredNumberOfDocs,
  });
  const [loading, setLoading] = useState(false);

  const isFileListVisible = fileList?.length > 0;

  const selectFile = () => {
    if (fileList.length === requiredNumberOfDocs) {
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_LIMIT_REACHED',
      });
    } else {
      fileInput?.current?.click();
    }
  };

  const resetFileInput = () => {
    if (fileInput?.current) {
      fileInput.current.value = '';
    }
  };

  const onSelectFile = (event) => {
    if (event?.target?.files?.[0]) {
      const file = event.target.files[0];

      // 20MB check
      if (file?.size > 20000000) {
        setErrorModal({
          open: true,
          type: 'FILE_TOO_LARGE',
        });
        resetFileInput();
      } else {
        getBase64(file)
          .then((result) => {
            const { type } = file || {};

            if (
              type === 'application/pdf' ||
              type === 'image/jpeg' ||
              type === 'image/jpg' ||
              type === 'image/png' ||
              type === 'image/heic'
            ) {
              addFile({ name: file.name, mime: file?.type, data: result as string, size: file?.size });
            } else {
              setErrorModal({
                open: true,
                type: 'UNSUPPORTED_FILE_TYPE',
              });
            }
          })
          .catch((err) => {
            alert.info('Attachment failed, please try again later.');
          });
      }
    } else {
      alert.info('Attachment failed, please try again later.');
    }
  };

  const addFile = async ({ name = Date.now().toString() + '.jpg', mime = 'image/jpeg', data, size }) => {
    try {
      const newFiles = [...fileList, { fileName: name, data, mime, size }];
      setFileList(newFiles);
      resetFileInput();
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_FAILED',
      });
    }
  };

  const uploadFiles = async () => {
    try {
      setLoading(true);

      const typeUser = userType || 'ORGANIZATION';
      const id = typeUser && typeUser === 'MERCHANT' ? idMerchant : idOrganization;

      if (!!id) {
        const response = await uploadFilesForDocumentRequest(fileList, documentRequestId, id, typeUser, relations);
        setLoading(false);

        if (response) {
          submitFiles();
        } else {
          setErrorModal({
            open: true,
            type: 'ATTACHMENT_FAILED',
          });
        }
      } else {
        setErrorModal({
          open: true,
          type: 'ATTACHMENT_FAILED',
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_FAILED',
      });
    }
  };

  const openRemoveFileDialog = (index) => {
    if (Number(index) >= 0) {
      setRemoveFileModal({
        open: true,
        removedFileIndex: index,
      });
    }
  };

  const removeFile = (index) => {
    if (Number(index) >= 0) {
      const newFiles = [...fileList];
      if (typeof index === 'number') {
        newFiles.splice(index, 1);
      }

      setFileList(newFiles);
      setRemoveFileModal(defaultRemoveFileModalState);
    }
  };

  const resetErrorModalState = () => {
    setErrorModal(defaultErrorModalState);
  };

  const resetRemovFileModal = () => {
    setRemoveFileModal(defaultRemoveFileModalState);
  };

  const openConfirmSubmissionModal = () => {
    setConfirmSubmissionModalConfig({
      open: true,
      requiredNumberOfDocs,
    });
  };

  const handleAddMoreDocuments = () => {
    selectFile();
    setConfirmSubmissionModalConfig({
      open: false,
      requiredNumberOfDocs,
    });
  };

  const handleSubmitAnyway = () => {
    setConfirmSubmissionModalConfig({
      open: false,
      requiredNumberOfDocs,
    });
    uploadFiles();
  };

  const handleSubmit = () => {
    if (fileList?.length === requiredNumberOfDocs) {
      uploadFiles();
    } else {
      openConfirmSubmissionModal();
    }
  };

  const submitFiles = async () => {
    setLoading(true);
    await submitFilesToDocumentRequest(documentRequestId);
    setLoading(false);
    onCloseModal();
  };

  const getFormattedExternalNote = { __html: convertNewlinesToBr(externalNote) || '' };

  return (
    <>
      <GenericFileModal
        {...rest}
        hidden={errorModal.open || removeFileModal.open || confirmSubmissionModalConfig.open}
        title="Upload Documents"
        mainButtonText={isFileListVisible ? 'Submit' : 'Upload Documents'}
        onMainButtonClick={isFileListVisible ? handleSubmit : selectFile}
        buttonLeftText={isFileListVisible ? 'Add Another File' : null}
        onButtonLeftClick={selectFile}
        onClose={onCloseModal}
        loading={loading}
      >
        {!isFileListVisible ? <Title>{documentTitle}</Title> : null}
        {externalNote ? <Label dangerouslySetInnerHTML={getFormattedExternalNote} /> : null}
        {isFileListVisible ? <FileList fileList={fileList} onRemove={openRemoveFileDialog} /> : null}
        {loading ? <LoadingSpinner size={10} /> : null}
        {isFileListVisible && fileList.length < requiredNumberOfDocs ? (
          <Footnote>Please provide at least {requiredNumberOfDocs} documents.</Footnote>
        ) : null}

        <DesktopInput
          accept="image/*,.pdf,.heic"
          type="file"
          data-testid="upload"
          onChange={onSelectFile}
          ref={fileInput}
        />
      </GenericFileModal>
      {!!errorModal.type && errorModal.open ? (
        <ErrorModal open={errorModal.open} type={errorModal.type} onCloseModal={resetErrorModalState} />
      ) : null}
      {typeof removeFileModal.removedFileIndex === 'number' && removeFileModal.removedFileIndex >= 0 ? (
        <RemoveFileModal
          open={removeFileModal.open}
          fileIndex={removeFileModal.removedFileIndex}
          onCloseModal={resetRemovFileModal}
          onRemove={removeFile}
        />
      ) : null}
      <ConfirmSubmissionModal
        open={confirmSubmissionModalConfig.open}
        requiredNumberOfDocuments={confirmSubmissionModalConfig.requiredNumberOfDocs}
        addDocument={handleAddMoreDocuments}
        submitDocuments={handleSubmitAnyway}
        currentNumberOfDocuments={fileList?.length}
      />
    </>
  );
};

const Title = styled.h4`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

const Label = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
`;

const DesktopInput = styled.input`
  display: none;
`;

const LoadingSpinner = styled(Loading)`
  margin-top: 16px;
`;

const Footnote = styled.div`
  margin-top: 24px;
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

export default FileUploadModal;
