import { faDesktop, faExternalLinkAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from '@frontend/cherry-library';
import { Button as MuiButton, ButtonGroup, Grid, IconButton } from '@mui/material';
import { CherryColorPicker, CloseIcon, DashCard, DashComponent, HelpIcon } from 'lib/components';
import { AlertDialog, CherryDialogContent } from 'lib/components/Dialogue';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

export const WidgetSettings = ({ title }) => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const alert = useAlert();
  const [color, setColor] = useState<any>('#00c37d');
  const [fontFamily, setFontFamily] = useState<string>('Open Sans');
  const [open, setOpen] = useState<boolean>(false);
  const [isCustomized, setIsCustomized] = useState<boolean>(false);

  const selectedColor = (e) => {
    setColor(e);
    setIsCustomized(true);
  };

  const onFontTypeSelect = (e) => {
    setFontFamily(e.target.value);
    setIsCustomized(true);
  };

  const fontFamilies = [
    { value: 'Open Sans', label: 'Default: Open Sans' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Playfair', label: 'Playfair' },
    { value: 'Oswald', label: 'Oswald' },
    { value: 'Poppins', label: 'Poppins' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Source Sans Pro', label: 'Source Sans Pro' },
    { value: 'Raleway', label: 'Raleway' },
    { value: 'Slabo 27px', label: 'Slabo' },
    { value: 'PT Sans', label: 'PT Sans' },
  ];

  const onSaveWidgetsClicked = () => {
    generateScriptTemplate();
  };

  const generateScriptTemplate = () => {
    return `<!-- CHERRY WIDGET BEGIN --> <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"rel="stylesheet"/>
    <script>
        (function (w, d, s, o, f, js, fjs) {
            w[o] =
                w[o] ||
                function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
        })(window, document, "script", "_hw", ' https://files.withcherry.com/widgets/widget.js');
        _hw(
            "init",
            {
                debug: false,
                variables: {
                    slug: '${organization?.slug}',
                    name: "${organization?.name}",
                },
                styles: {
                    primaryColor: '${color}',
                    secondaryColor: '${color}10',
                    fontFamily: '${fontFamily}',
                },
            },
            ["all", "hero", "howitworks", "testimony", "faq", "calculator"]
        );
    </script><div id="all"></div><!-- CHERRY WIDGET END -->`;
  };

  const onCopyScriptClicked = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    const result = generateScriptTemplate();
    navigator.clipboard.writeText(result);
    alert.success('Snippet was copied to your clipboard');
    trackSegmentEvent('Copied Web Widget', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      wasCustomized: isCustomized,
    });
  };

  const AlertContent = (variables: any) => {
    const iframeSrc = `/widgets.html?slug=${variables?.variables?.slug}&name=${variables?.variables?.name}&primaryColor=${variables?.variables?.primaryColor}&fontFamily=${variables?.variables?.fontFamily}`.toString();
    const [mobileView, setMobileView] = useState<boolean>(false);

    const onDesktopClicked = () => setMobileView(false);

    const onMobileClicked = () => setMobileView(true);

    return (
      <>
        <Flex>
          <CustomButtonGroup variant="contained" disableElevation={true}>
            <MuiButton onClick={onDesktopClicked} className={mobileView ? '' : 'active-viewport'}>
              <FontAwesomeIcon icon={faDesktop} /> &nbsp; &nbsp; Desktop View
            </MuiButton>
            <MuiButton onClick={onMobileClicked} className={!mobileView ? '' : 'active-viewport'}>
              <FontAwesomeIcon icon={faMobileAlt} /> &nbsp; &nbsp; Mobile View{' '}
            </MuiButton>
          </CustomButtonGroup>
          <IconButton aria-label="close" id="close--button" onClick={handleDisagreeClick}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <CustomCherryDialogContent>
          <iframe
            src={iframeSrc}
            width={'900px'}
            height={'600px'}
            frameBorder="0"
            className={mobileView ? 'force-mobile' : ''}
          />
        </CustomCherryDialogContent>
      </>
    );
  };

  const onPreviewClick = () => setOpen(true);
  const handleDisagreeClick = () => setOpen(false);

  const handleAgreeClicked = () => {
    window.open(
      `/widgets.html?slug=${organization?.slug}&name=${organization?.name}&primaryColor=${color?.replace(
        '#',
        '',
      )}&fontFamily=${fontFamily}`.toString(),
      '_blank',
    );
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <SubHeader size={'16px'} style={{ marginBottom: '8px' }}>
          Brand Settings
        </SubHeader>

        <Row style={{ fontSize: '14px', paddingBottom: '24px' }}>
          This ensures that your Cherry webpage fits in with your brand and the look and feel of the rest of your
          website. These settings will be applied to all sections of your Cherry Webpage.
        </Row>
        <Row>
          <Grid container={true}>
            <Row>
              <Grid container={true} style={{ marginBottom: '12px' }} spacing={3}>
                <Grid item={true} xs={3} style={{ margin: '0px' }}>
                  <SettingsTypography> Choose a Brand Color</SettingsTypography>
                  <CherryColorPicker selectedColor={selectedColor} defaultColor={color} />
                </Grid>
                <Grid item={true} xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <RoundedColor color={color} />
                </Grid>
              </Grid>
            </Row>
            <Row>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                  <SettingsTypography>Font</SettingsTypography>
                  <Dropdown
                    options={fontFamilies}
                    onChange={onFontTypeSelect}
                    value={fontFamily}
                    hasCustomFont={true}
                  />
                </Grid>
              </Grid>
            </Row>
            <Row style={{ marginTop: '24px', gap: '8px' }}>
              <Button variant="secondary" data-testid="previewMyWidgetsButton" onClick={onPreviewClick}>
                Preview My Widgets &nbsp;
                <FontAwesomeIcon style={{ width: '14px' }} icon={faExternalLinkAlt} />
              </Button>

              <CustomAlertDialog
                open={open}
                agreeClicked={handleAgreeClicked}
                onDisagreeClicked={handleDisagreeClick}
                children={
                  <AlertContent
                    variables={{
                      slug: organization?.slug,
                      name: organization?.name,
                      primaryColor: color?.replace('#', ''),
                      fontFamily,
                    }}
                  />
                }
                agreeText={'Open In New Tab'}
                declineText={'Close'}
              />

              <Button onClick={onSaveWidgetsClicked} data-testid="saveSettingsButton">
                Save Settings
              </Button>
            </Row>
          </Grid>
        </Row>

        <Grid container={true} style={{ borderTop: '1px solid #DADADA', padding: '24px 0', margin: '24px 0' }}>
          <SubHeader size={'16px'} style={{ marginBottom: '8px' }}>
            Your Script
          </SubHeader>
          <Row style={{ fontSize: '14px', paddingBottom: '24px' }}>
            Copy your script and paste it into your website to generate your Cherry webpage. This script incorporates
            all settings from this page.
          </Row>
          <CodeRow>{generateScriptTemplate()}</CodeRow>
          <Grid item={true} xs={12}>
            <Button onClick={onCopyScriptClicked} data-testid="getGuideButton">
              Copy Script
            </Button>
          </Grid>
        </Grid>
        <Row>
          <Grid container={true} style={{ backgroundColor: '#F2F4F5', borderRadius: '4px', padding: '8px' }}>
            <Grid item={true} xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <HelpIcon active={true} color={'#0E202F'} />
            </Grid>
            <Grid item={true} xs={11} style={{ fontSize: '14px' }}>
              If you need help or want more customization, we offer a free website implementation service. Submit a
              request for help from a&nbsp;
              <a
                style={{ color: '#00C37D' }}
                href="https://withcherry.typeform.com/website-request?typeform-source=partnerportal "
                target="_blank"
              >
                Cherry Web Developer
              </a>
              .
            </Grid>
          </Grid>
        </Row>
      </DashCard>
    </Column>
  );
};

const CustomAlertDialog = styled(AlertDialog)`
  width: 960px !important;
`;

interface RoundedColorProps {
  color: string;
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  #close--button {
    margin-right: 25px;
  }
`;

const CodeRow = styled(Row)`
  font-size: 12px;
  background: #f2f4f5;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 24px;
  word-break: break-word;
`;

const RoundedColor = styled.div<RoundedColorProps>`
  width: 25px;
  background-color: ${(props) => props.color};
  height: 25px;
  border-radius: 25px;
  margin-top: 20px;
`;

const SettingsTypography = styled.div`
  font-weight: 400;
  color: #0e202f;
  font-size: 12px;
  margin-bottom: 4px;
`;

const CustomCherryDialogContent = styled(CherryDialogContent)`
  overflow-x: hidden;

  .force-mobile {
    width: 450px;
    max-width: 750px;
    height: 1334px;
    margin: 0 auto;
    border-style: none;
    border-color: inherit;
    border-width: 0px;
    display: block;
  }
`;

const CustomButtonGroup = styled(ButtonGroup)`
  padding: 8px 24px !important;
  button {
    background-color: #dadada50 !important;

    &.active-viewport {
      background-color: rgb(0, 195, 125) !important;
      color: #ffffff !important;
    }

    span {
      color: #ffffff !important;
    }
  }
`;
