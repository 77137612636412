import { APP_ENV, SANDBOX_URL } from 'config';
import { MODE_TEXTS } from 'lib/constants';
import useAgent from 'lib/hooks/useAgent';
import useStore from 'lib/hooks/useStore';
import { useCallback, useEffect, useState } from 'react';
import { useFlags } from './FeatureManagement/FlagsContext';

const useSnackbar = () => {
  const { isAgent } = useAgent();
  const { organization, activateGuestMode } = useStore();
  const { flags } = useFlags();

  const [activeModes, setActiveModes] = useState<Array<string>>([]);
  const [isSnackbarHidden, setIsSnackbarHidden] = useState(false);

  useEffect(() => {
    if ((organization?.isDemo || APP_ENV === 'sandbox') && !activeModes?.includes(MODE_TEXTS.DEMO_MODE)) {
      const isSandboxNavigationEnabled = flags.DEMO.sandboxNavigationEnabled.isEnabled();
      if (APP_ENV !== 'sandbox' && isSandboxNavigationEnabled) {
        if (typeof SANDBOX_URL === 'string') {
          window.location.href = SANDBOX_URL;
        }
      }
      setActiveModes([...activeModes, MODE_TEXTS.DEMO_MODE]);
    }

    if (isAgent && activateGuestMode && !activeModes?.includes(MODE_TEXTS.GUEST_MODE)) {
      setActiveModes([...activeModes, MODE_TEXTS.GUEST_MODE]);
    }
  }, [organization?.isDemo, isAgent, activateGuestMode]);

  const showSnackbar = useCallback(() => setIsSnackbarHidden(false), []);
  const hideSnackbar = useCallback(() => setIsSnackbarHidden(true), []);

  const isDemoSnackbarShown = !isSnackbarHidden && !!organization?.isDemo;

  return {
    activeModesText: activeModes?.join(' - ') || '',
    isSnackbarVisible: isDemoSnackbarShown || APP_ENV === 'sandbox' || !!isAgent,
    isDemonstrationMode: !!organization?.isDemo,
    showSnackbar,
    hideSnackbar,
  };
};

export default useSnackbar;
