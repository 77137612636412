import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertComponentPropsWithStyle } from 'react-alert';
import styled from 'styled-components';

import { faCheckCircle, faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertTemplate as MobileAlertTemplate } from 'lib/components/mobile';
import { browserIsMobile } from 'lib/utils/BrowserIsMobile';

export const AlertTemplate = ({ style, message, options, close }: AlertComponentPropsWithStyle) => {
  const isMobile = browserIsMobile();

  return isMobile ? (
    <MobileAlertTemplate id="mobile-alert" style={style} message={message} options={options} close={close} />
  ) : (
    <Container status={options.type} data-testid="alert">
      <IconWrapper>
        {options.type === 'info' ? alertIcon.info : options.type === 'success' ? alertIcon.success : alertIcon.error}
      </IconWrapper>
      <AlertInformation>
        <AlertTextHeader>{options.type}</AlertTextHeader>
        <AlertTextDescription>{message}</AlertTextDescription>
      </AlertInformation>
      <Button onClick={close}>
        <FontAwesomeIcon icon={faTimes} color="white" />
      </Button>
    </Container>
  );
};

const alertBackground = {
  info: '#11cdef',
  success: '#00C37D',
  error: '#EC3360',
};

const alertIcon = {
  info: <FontAwesomeIcon icon={faInfoCircle} />,
  success: <FontAwesomeIcon icon={faCheckCircle} />,
  error: <FontAwesomeIcon icon={faTimesCircle} />,
};

interface ContainerProps {
  status: 'info' | 'success' | 'error' | undefined;
}

const AlertTextHeader = styled.span`
  font-weight: 700;
  color: #ffffff;
  text-transform: capitalize;
`;

const AlertTextDescription = styled.span`
  flex: 2;
  margin-top: 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;

const AlertInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => (props.status ? alertBackground[props.status] : 'white')};
  font-size: 14px;
  padding: 10px;
  //right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 2px;
  min-width: 300px;
  box-sizing: border-box;
  margin-top: 20px;
  color: white;
  box-shadow: 0 36px 67px rgba(0, 0, 0, 0.07), 0 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
`;

const Button = styled.button`
  margin-left: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
