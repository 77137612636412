import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@frontend/cherry-library';
import dayjs from 'dayjs';
import { ButtonPrimary, DocumentRequestSubmittedFile, WarningOutlineIcon } from 'lib/components';
import useDocumentRequest from 'lib/hooks/useDocumentRequest';
import { convertNewlinesToBr } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export interface DocumentRequest {
  documentTitle: string;
  externalNote?: string;
  requestDate?: string;
  submittedBy?: string;
  submitDate?: string;
  userId: number;
  id: string;
}

type DocumentRequestStatus = 'submitted' | 'needed';

interface DocumentRequestItemProps {
  documentRequest: DocumentRequest;
  status: DocumentRequestStatus;
  uploadDocument?: (id: string) => void;
  isMobile: boolean;
}

const DocumentRequestItem = ({
  documentRequest,
  status,
  uploadDocument,
  isMobile = false,
}: DocumentRequestItemProps) => {
  const { fetchFileList, getFilForPreview } = useDocumentRequest();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [submittedFileList, setSubmittedFileList] = useState([]);

  const isSubmitted = status === 'submitted';
  const isNeeded = status === 'needed';

  useEffect(() => {
    if (status === 'submitted') {
      getFileList();
    }
  }, [status]);

  const handleSubmitDocumentButton = () => {
    uploadDocument?.(documentRequest.id);
  };

  const handleToggleButton = () => {
    setIsExpanded(!isExpanded);
  };

  const getFileList = async () => {
    const fileList = await fetchFileList(documentRequest.id);
    setSubmittedFileList(fileList);
  };

  const handlePreviewFile = async (id) => {
    const uploadedFile = await getFilForPreview(documentRequest.id, id);
    const { data, mime: fileMime, name } = uploadedFile;
    const a = document.createElement('a');
    a.href = `data:image/png;base64,${data}`;
    const mime = fileMime.split('/')[1];
    a.download = `${name}.${mime}`;
    a.click();
  };

  const getFormattedExternalNote = {
    __html: convertNewlinesToBr(documentRequest?.externalNote) || '',
  };

  const getCorrectButton = () => {
    if (isMobile) {
      return <SubmitDocumentButton onClick={handleSubmitDocumentButton} text="Submit Document" />;
    } else {
      return <Button onClick={handleSubmitDocumentButton}>Submit Document</Button>;
    }
  };

  return (
    <DocumentRequestItemContainer isMobile={isMobile}>
      <StatusContainer isMobile={isMobile}>
        <IconWrapper>
          {isNeeded ? <WarningOutlineIcon /> : null}
          {isSubmitted ? <FontAwesomeIcon icon={faCheck} color={colors.green} /> : null}
        </IconWrapper>
        {isNeeded ? <StatusLabel status={status}>Document Needed</StatusLabel> : null}
        {isSubmitted ? <StatusLabel status={status}>Submitted</StatusLabel> : null}
      </StatusContainer>
      <Body>
        <Content>
          {isSubmitted ? (
            <SubmittedContentHeader>
              <Title>{documentRequest.documentTitle}</Title>
              <ToggleButton onClick={handleToggleButton} isExpanded={isExpanded}>
                <FontAwesomeIcon icon={faChevronDown} />
              </ToggleButton>
            </SubmittedContentHeader>
          ) : null}
          {isNeeded ? (
            <NeededContent isMobile={isMobile}>
              <NeededContentHeader isMobile={isMobile}>
                <div>
                  <Title>{documentRequest.documentTitle}</Title>
                  <Text>
                    <ItalicText>Request Received: </ItalicText>{' '}
                    {dayjs(documentRequest.requestDate).format('MMMM DD, YYYY')}
                  </Text>
                  <Text dangerouslySetInnerHTML={getFormattedExternalNote} />
                </div>
              </NeededContentHeader>
              {isNeeded ? getCorrectButton() : null}
            </NeededContent>
          ) : null}
          {isSubmitted && isExpanded ? (
            <ExpandedContent>
              <FileList>
                {submittedFileList?.map(({ name, id }, index) => (
                  <DocumentRequestSubmittedFile key={index} id={id} name={name} onPreviewFile={handlePreviewFile} />
                ))}
              </FileList>
              <SubmitInfo>
                Submitted By: {documentRequest.submittedBy} on{' '}
                {dayjs(documentRequest.submitDate).format('MMMM DD, YYYY')}
              </SubmitInfo>
            </ExpandedContent>
          ) : null}
        </Content>
      </Body>
    </DocumentRequestItemContainer>
  );
};

const DocumentRequestItemContainer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => !props.isMobile && '800px'};
  padding: 24px;
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 0.7512931227684021px 1.1269396543502808px 0px rgba(0, 0, 0, 0.04), 0px 6px 9px 0px rgba(0, 0, 0, 0.07);
`;

const StatusContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.isMobile ? '12px' : '24px')};
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  transform: scale(0.9);
`;

const StatusLabel = styled.div<{ status: DocumentRequestStatus }>`
  color: ${(props) => (props.status === 'submitted' ? props.theme.main.green : props.theme.main.red)};
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 14px;
  font-weight: 700;
`;

const Text = styled.span`
  display: block;
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 14px;
  font-weight: 400;
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const ToggleButton = styled.button<{ isExpanded: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  transform: ${(props) => (props.isExpanded ? `rotate(180deg)` : `rotate(0)`)};
  transition: all 0.3s;
`;

const SubmittedContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NeededContentHeader = styled.div<{ isMobile: boolean }>`
  max-width: ${(props) => !props.isMobile && '540px'};
  display: ${(props) => !props.isMobile && 'flex'};
  align-items: ${(props) => !props.isMobile && 'center'};
  justify-content: ${(props) => !props.isMobile && 'space-between'};
  margin-bottom: ${(props) => props.isMobile && '16px'};
`;

const NeededContent = styled.div<{ isMobile: boolean }>`
  display: ${(props) => !props.isMobile && 'flex'};
  justify-content: ${(props) => !props.isMobile && 'space-between'};
  align-items: ${(props) => !props.isMobile && 'center'};
`;

const ExpandedContent = styled.div`
  margin-top: 27px;
`;

const SubmitInfo = styled.div`
  color: ${(props) => props.theme.main.sealGray};
  font-size: 14px;
  font-weight: 400;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8.5px;
  margin-bottom: 8.5px;
`;

const SubmitDocumentButton = styled(ButtonPrimary)`
  width: 100%;
  height: fit-content;
  border-radius: 4px !important;

  &:disabled {
    color: #9fa6ac !important;
    background-color: ${(props) => props.theme.main.neutralGray} !important;
    border: 1px solid ${(props) => props.theme.main.neutralGray} !important;
  }
`;
export default DocumentRequestItem;
