import React, { createContext, useContext, useState } from 'react';

type CallbackFunction = (data?: any) => void;

interface EventBusFunctions {
  /**
   * Emits an event with the given event name (and an optional data).
   */
  emitEvent: (eventName: BusEvent, data?: any) => void;

  /**
   * Kills all the events with the given event name.
   */
  killEvents: (eventName: BusEvent) => void;

  /**
   * Subscribes an event with the given name.
   *
   * * If any event with the same name is emitted, the callback function will be triggered.
   * * This function returns the unique id number of the event that can be used to remove later.
   *
   */
  onEvent: (eventName: BusEvent, cb: CallbackFunction) => number;

  /**
   * Removes the event with the given id.
   */
  removeEvent: (eventId: number) => void;
}

const emptyFunction = () => 0;

const EventBusContext = createContext<EventBusFunctions>({
  emitEvent: emptyFunction,
  killEvents: emptyFunction,
  onEvent: emptyFunction,
  removeEvent: emptyFunction,
});

export const EventBusProvider = ({ children }) => {
  const [eventList, setEventList] = useState<{ cb: CallbackFunction; eventName: BusEvent }[]>([]);

  const onEvent = (eventName: BusEvent, cb: CallbackFunction): number => {
    const index = eventList.push({ eventName, cb });
    setEventList(eventList);
    return index;
  };

  const emitEvent = (eventName: BusEvent, data?: any) => {
    const events = eventList.filter((elm) => elm.eventName === eventName);
    events.forEach((elm) => elm.cb?.(data));
  };

  const removeEvent = (eventId: number) => {
    eventList[eventId].cb = () => undefined;
    setEventList(eventList);
  };

  const killEvents = (eventName: BusEvent) => {
    const events = eventList.filter((elm) => elm.eventName === eventName);
    events.forEach((elm) => (elm.cb = () => undefined));
  };

  const contextValue = {
    emitEvent,
    killEvents,
    onEvent,
    removeEvent,
  };

  return <EventBusContext.Provider value={contextValue}>{children}</EventBusContext.Provider>;
};

export const useEventBus = (): EventBusFunctions => {
  return useContext(EventBusContext);
};

export enum BusEvent {
  FILTER_EXPIRING_SOON,
  FILTER_RESET,
  GUIDED_WALKTHROUGH_TRIGGER,
  OPEN_SEND_SMS_MODAL,
  OPEN_MONTHLY_PAYMENT_OPTIONS,
  DASHBOARD_SET_APPLICATIONS_TAB,
  APPLY_INITIAL_FILTER,
  APPLY_WITH_PATIENT,
  APPLY_WITH_PATIENT_PREFILL,
  FETCH_TREATMENT_PLANS,
}
