import { Body, Button, Heading } from '@frontend/cherry-library';
import { BodyWrapper, ButtonContainer, ColumnBox, LoadingBox } from '../styles';

import { useMutation } from '@apollo/client';
import client from 'lib/graphql/client';
import { GET_EXCHANGE_TOKEN, RESEND_APPLY_WITH_PATIENT_LINK } from 'lib/graphql/mutations';
import { GET_APPLY_WITH_PATIENT_CONSENT, GET_APPLY_WITH_PATIENT_CONSUME } from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { useEffect, useState } from 'react';
import { ApplicationFlowStatuses } from '../types';
import { useCreateApplication } from '../useCreateApplication';
import CustomMuiCircularProgress from './CustomCircularProgress';

interface LoadingProps {
  setStep: (string) => void;
}
const Loading = ({ setStep }: LoadingProps) => {
  const { trackSegmentEvent } = useSegment();

  const [resendButtonLoading, setResendButtonLoading] = useState(false);
  const [checkStatus, setCheckStatus] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const { applyWithPatientFormData, setApplyWithPatientFormData } = useStore();
  const finalizedStatus = ['APPROVED', 'DENIED'];

  // mutations
  const [getExchangeToken] = useMutation(GET_EXCHANGE_TOKEN);
  const [resendApplyWithPatientConsentLink] = useMutation(RESEND_APPLY_WITH_PATIENT_LINK);

  const {
    createApplication,
    handleHighlineFlow,
    triggerMagicLinkSMS,
    resetError,
    clearState,
    createdApplicationStatus,
    createApplicationError,
  } = useCreateApplication({
    applicationFormData: applyWithPatientFormData || {},
  });

  const resendLink = async () => {
    setResendButtonLoading(true);

    await resendApplyWithPatientConsentLink({
      variables: {
        input: {
          id: applyWithPatientFormData?.id,
        },
      },
    });
    setResendButtonLoading(false);
  };

  // Controls patient's action on WPQ and co
  async function getApplyWithPatientStatus() {
    try {
      const { data } = await client.query({
        query: GET_APPLY_WITH_PATIENT_CONSENT,
        variables: { input: { id: applyWithPatientFormData?.id } },
      });
      if (finalizedStatus?.includes(data?.getApplyWithPatientConsent?.status)) {
        setCheckStatus(false);
        if (data?.getApplyWithPatientConsent?.status === 'APPROVED') {
          setIsApproved(true);
          await setApplyWithPatientFormData(data?.getApplyWithPatientConsent);
          getApplyWithPatientExchangeToken(data?.getApplyWithPatientConsent?.id);
        } else if (data?.getApplyWithPatientConsent?.status === 'DENIED') {
          // patient marked their info as incorrect
          setStep('incorrect');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getApplyWithPatientExchangeToken(id) {
    try {
      const { data } = await client.query({
        query: GET_APPLY_WITH_PATIENT_CONSUME,
        variables: { input: { id } },
      });
      const tokenId = data?.getApplyWithPatientConsume?.exchangeTokenId;
      if (tokenId) {
        getApplicationToken(tokenId);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Collects WPQ Application token for create application requests
  async function getApplicationToken(uuid: string) {
    try {
      const { data } = await getExchangeToken({
        variables: { input: { token: uuid } },
      });
      // TO-DO: add if expired check or any error
      await StorageService.setWPQAuthData({ wpqToken: data?.getExchangeToken?.token });
      createApplication(applyWithPatientFormData?.borrowerId);
    } catch (err) {
      console.error(err);
    }
  }
  const getSafeValues = () => {
    const values = applyWithPatientFormData;
    return { ...values, ssn: !!values?.last4ssn, ssnAgain: !!values?.last4ssn };
  };

  useEffect(() => {
    if (checkStatus) {
      const interval = setInterval(getApplyWithPatientStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [checkStatus]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (createdApplicationStatus?.status) {
      if (createdApplicationStatus?.status === ApplicationFlowStatuses.APPROVED) {
        setStep('approval');
        trackSegmentEvent(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.APPROVAL_SCREEN_VIEWED`, {
          formValues: getSafeValues(),
        });
      } else if (
        createdApplicationStatus?.status === ApplicationFlowStatuses.DENIED ||
        createdApplicationStatus?.status === ApplicationFlowStatuses.BLACKLIST ||
        createdApplicationStatus?.status === ApplicationFlowStatuses.COMPLETE_DEMO
      ) {
        setStep('denial');
        trackSegmentEvent(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.DENIAL_SCREEN_VIEWED`, {
          formValues: getSafeValues(),
        });
      } else if (createdApplicationStatus?.status === ApplicationFlowStatuses.AWAITING_DEFAULT_HIGH_LINE) {
        handleHighlineFlow?.(createdApplicationStatus?.applicationId);
      } else {
        triggerMagicLinkSMS?.(createdApplicationStatus?.borrowerId, createdApplicationStatus?.applicationId);
        setStep('moreInfo');
        trackSegmentEvent(`PRACTICE_PORTAL.APPLY_WITH_PATIENT.MORE_INFO_NEEDED_SCREEN_VIEWED`, {
          formValues: getSafeValues(),
        });
      }
    } else if (createApplicationError) {
      resetError();
    }
  }, [createdApplicationStatus, createApplicationError]);

  return (
    <ColumnBox>
      <BodyWrapper>
        <Heading
          text={!isApproved ? 'Patient is reviewing their information...' : 'Application Processing...'}
          level="4"
        />
      </BodyWrapper>
      <LoadingBox>
        <Body>
          {!isApproved
            ? `We texted a link to ${applyWithPatientFormData?.phone} for the patient to review and confirm their information,
          please wait for them to finish.`
            : 'Your patient has confirmed their information. Please wait while we finish processing their application.'}
        </Body>
        <CustomMuiCircularProgress />
        <Heading text="Don’t close this page until you see a confirmation." level="5" />
        {!isApproved && (
          <ButtonContainer>
            <Button
              id="apply-with-patient-resend-link"
              loading={resendButtonLoading}
              onClick={resendLink}
              variant="secondary"
              size="small"
            >
              Something went wrong? Re-Send Link
            </Button>
          </ButtonContainer>
        )}
      </LoadingBox>
    </ColumnBox>
  );
};
export default Loading;
