import { Grid } from '@mui/material';
import React from 'react';

import { PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Container, Row } from 'lib/styles';
import { Charts, InfoCards, MemberSignUp, PlanMembers } from './components/Home';

const MembershipHome = () => {
  const { user } = useStore();
  const { isSnackbarVisible } = useSnackbar();

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title={`Welcome, ${user?.firstName}`} />
      <Grid container={true} spacing={3} style={{ marginBottom: '24px' }}>
        <Grid item={true} xs={6}>
          <MemberSignUp />
        </Grid>
        <Grid item={true} xs={6}>
          <InfoCards />
        </Grid>
      </Grid>

      <Row>
        <Charts />
      </Row>
      <Row>
        <PlanMembers />
      </Row>
    </Container>
  );
};

export default MembershipHome;
