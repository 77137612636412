import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { DashCard, FixedDecimalFormat, Loading, PageHeader, Prompt } from 'lib/components';
import { PATCH_LOAN } from 'lib/graphql/mutations';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import { useCheckoutWithPatient } from 'lib/services';
import StorageService from 'lib/services/Storage';
import { Application } from 'lib/types';
import { useAlert } from 'react-alert';
import { DentalEditCancelPlanModal } from '../Dashboard/DentalDashboard/views/DentalEditCancelPlanModal';
import PatientInformation from './PatientInformation';
import { PaymentMethodsProvider } from './PaymentMethodContext/PaymentMethodContext';
import { ProductCalculation } from './PlanCard/types';
import PlanSelectStep from './PlanSelectStep';
import ReviewStep from './ReviewStep/ReviewStep';
import SetupPayment from './SetupPayment';
import { GreenBox, LoadingContainer, RedOutlineButton, StyledContainer } from './styles';
import { Borrower, Loan } from './types';

const CheckoutWithPatient = () => {
  const { getBorrower, getApplication, setTokenForCheckout, getLoan } = useCheckoutWithPatient();
  const location: any = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { trackSegmentEvent } = useSegment();
  const { checkoutToken, amount } = location?.state || {};

  const [patchLoan] = useMutation(PATCH_LOAN);

  const [loanDetail, setLoanDetail] = useState<Loan>();
  const [borrowerDetail, setBorrowerDetail] = useState<Borrower>();
  const [applicationDetail, setApplicationDetail] = useState<Application>();
  const [productCalculations, setProductCalculations] = useState<ProductCalculation[]>();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [isCheckoutProcessing, setIsCheckoutProcessing] = useState<boolean>(false);
  const [isCheckoutError, setIsCheckoutError] = useState<boolean>(false);
  const [isCheckoutCompleted, setIsCheckoutCompleted] = useState<boolean>(false);

  const [isEditCancelLoanModalVisible, setIsEditCancelLoanModalVisible] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const alert = useAlert();

  const editCancelLoanModalData = {
    loans: [loanDetail],
    customer: borrowerDetail,
    application: applicationDetail,
    applicationId: applicationDetail?.id,
  };

  const { getProductCalculationV2 } = useProductCalculation();

  const fetchInitialData = async (isInitialRender: boolean = false) => {
    try {
      if (isInitialRender) {
        setInitialLoading(true);
      }

      const hasTokenSet = await setTokenForCheckout(checkoutToken);
      if (hasTokenSet?.success) {
        if (id) {
          const loan = await getLoan(Number(id));
          setLoanDetail(loan);

          const borrower = await getBorrower(loan?.borrowerId);
          setBorrowerDetail(borrower);

          const application = await getApplication(loan?.applicationId);
          setApplicationDetail(application);

          const productCalculationsRes = await getProductCalculationV2(loan?.applicationId, Number(amount));
          setProductCalculations(productCalculationsRes);
        }
      }
      setInitialLoading(false);
    } catch (err) {
      setInitialLoading(true);

      alert.info('Data fetch error. Please try again later.');
    }
  };

  const resetState = () => {
    setIsEditCancelLoanModalVisible(false);
    StorageService.setWPQAuthData({ wpqToken: undefined });
  };

  useEffect(() => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT_PAGE_LOAD', {
      loanId: id,
      borrowerId: borrowerDetail?.id,
    });
  }, []);

  useEffect(() => {
    fetchInitialData(true);

    return resetState;
  }, [id]);

  useEffect(() => {
    fetchInitialData();

    return resetState;
  }, [stepIndex]);

  const openEditCancelLoanModal = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.EDIT_CANCEL_PLAN_CLICKED', {
      loanId: id,
      borrowerId: borrowerDetail?.id,
    });
    setIsEditCancelLoanModalVisible(true);
  };

  const closeEditCancelLoanModal = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.EDIT_CANCEL_MODAL_CLOSED', {
      loanId: id,
      borrowerId: borrowerDetail?.id,
    });
    setIsEditCancelLoanModalVisible(false);
  };

  const handleCancelLoan = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.EDIT_CANCEL_MODAL_CANCEL_BUTTON_CLICKED', {
      loanId: id,
      borrowerId: borrowerDetail?.id,
    });
    navigate('/dashboard', { state: { force: true } });
  };

  const handleEditLoan = (loanId: string | number, purchaseAmount: string | number) => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.EDIT_CANCEL_MODAL_UPDATE_PURCHASE_CLICKED', {
      loanId: id,
      borrowerId: borrowerDetail?.id,
      purchaseAmount,
    });
    navigate(`/checkout/${loanId}`, {
      state: { amount: purchaseAmount, checkoutToken, force: true },
    });
  };

  const onCheckoutSuccess = () => {
    setIsCheckoutCompleted(true);
    navigate('success', { state: { force: true } });
  };

  const onCheckoutError = () => {
    setIsCheckoutError(true);
  };

  const handleConfirmNavigateAway = async () => {
    await patchLoan({
      variables: {
        input: {
          loanId: loanDetail?.id,
          status: 'CANCELLED',
        },
      },
    });
  };

  const shouldBlockNavigation = !(isCheckoutCompleted || isCheckoutError);

  return (
    <PaymentMethodsProvider>
      <StyledContainer>
        <Prompt shouldBlockNavigation={shouldBlockNavigation} onConfirmNavigation={handleConfirmNavigateAway} />
        <PageHeader title="Checkout With Patient" />
        <DashCard>
          <>
            <Grid container={true} justifyContent="space-between">
              <Grid item={true}>
                <GreenBox>
                  {`PURCHASE AMOUNT: `}
                  {loanDetail && <FixedDecimalFormat amount={loanDetail?.purchaseAmount} prefix={'$'} />}
                </GreenBox>
              </Grid>
              <Grid item={true}>
                <RedOutlineButton onClick={openEditCancelLoanModal}>Edit or Cancel Purchase</RedOutlineButton>
              </Grid>
            </Grid>
            {initialLoading ? (
              <LoadingContainer>
                <Loading size={24} />
              </LoadingContainer>
            ) : (
              <PatientInformation availableBalance={applicationDetail?.balanceAvailable} borrower={borrowerDetail} />
            )}

            <DentalEditCancelPlanModal
              open={isEditCancelLoanModalVisible}
              onClose={closeEditCancelLoanModal}
              onCancelLoan={handleCancelLoan}
              onEditLoan={handleEditLoan}
              selectedItemData={editCancelLoanModalData}
              isFromCheckoutWithPatient={true}
            />
          </>
        </DashCard>

        {productCalculations && loanDetail && (
          <PlanSelectStep
            loan={loanDetail}
            loanId={id}
            setLoan={setLoanDetail}
            productCalculations={productCalculations}
            setProductCalculations={setProductCalculations}
            setStep={setStepIndex}
            currentStep={stepIndex}
            isCheckoutProcessing={isCheckoutProcessing}
          />
        )}
        <SetupPayment
          borrowerId={borrowerDetail?.id}
          applicationId={applicationDetail?.id}
          loan={loanDetail}
          currentStep={stepIndex}
          setStep={setStepIndex}
          isCheckoutProcessing={isCheckoutProcessing}
        />
        <ReviewStep
          onSuccess={onCheckoutSuccess}
          onError={onCheckoutError}
          onCheckoutLinkSent={setIsCheckoutProcessing}
          checkoutData={{
            borrower: borrowerDetail,
            applicationDetail,
            loan: {
              id: loanDetail?.id || 0,
              productId: loanDetail?.productId,
              downPaymentPaymentMethodId: loanDetail?.downPaymentPaymentMethodId,
              installmentPaymentMethodId: loanDetail?.installmentPaymentMethodId,
            },
          }}
          currentStep={stepIndex}
          setStep={setStepIndex}
        />
      </StyledContainer>
    </PaymentMethodsProvider>
  );
};

const CheckoutWithPatientParamRemountWrapper = () => {
  const { id } = useParams();
  return <CheckoutWithPatient key={id} />;
};

export default CheckoutWithPatientParamRemountWrapper;
