import { Dropdown } from '@frontend/cherry-library';
import client from 'lib/graphql/client';
import { FETCH_ORGANIZATION_MERCHANTS } from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Row } from 'lib/styles';
import { Merchant } from 'lib/types';
import { getQuantities, getRequestReasonLabel, REQUEST_REASONS } from 'lib/utils';
import AddCustomAddressModal from 'pages/desktop/OtherResourcesTemplates/components/AddCustomAddressModal';
import MaterialModal from 'pages/desktop/OtherResourcesTemplates/components/MaterialModal';
import { MarketingMaterialItem } from 'pages/desktop/OtherResourcesTemplates/views/DigitalFiles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

export interface RequestMaterial {
  documentType: string;
  quantity: number;
  reason: string;
  addressOne: string;
  addressTwo: string;
  zipcode: string;
  city: string;
  state: string;
  userId: number;
}

export interface Address {
  address: {
    name?: string;
    address1: string;
    address2?: string;
    city: { name: string; state: { code: string } };
    zip: string;
  };
  value?: number;
  label?: string;
}

interface RequestMaterialModalProps {
  selectedMaterial: MarketingMaterialItem;
  onClose: () => void;
  onRequest: (material: RequestMaterial, merchantId?: number) => void;
}

const RequestMaterialModal = ({ selectedMaterial, onClose, onRequest }: RequestMaterialModalProps) => {
  const alert = useAlert();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, user, merchants } = useStore();
  const [selectedQuantity, setSelectedQuantity] = useState<string>();
  const [selectedRequestReason, setSelectedRequestReason] = useState<string>();
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [organizationMerchants, setOrganizationMerchants] = useState<Array<Merchant>>([]);
  const [isCustomAddressModalVisible, setIsCustomAddressModalVisible] = useState<boolean>(false);
  const [isCustomAddress, setIsCustomAddress] = useState<boolean>(false);

  const quantities = useMemo(() => getQuantities(selectedMaterial.id), [selectedMaterial.id]);
  const customAddress = useMemo(() => (isCustomAddress ? selectedAddress : null), [isCustomAddress, selectedAddress]);
  const isDemo = useMemo(() => Boolean(organization?.isDemo), [organization?.isDemo]);

  useEffect(() => {
    setSelectedQuantity(quantities?.[0].value);
  }, [quantities]);

  const isSubmitButtonEnabled = useMemo(() => !!selectedQuantity && !!selectedRequestReason && selectedAddress, [
    selectedQuantity,
    selectedRequestReason,
    selectedAddress,
  ]);

  const isQuantityDropdownVisible = useMemo(() => quantities?.length > 1, [quantities]);

  const handleSelectedAddressChanged = (event) => {
    const address = locations?.find((location) => location?.value === event?.target?.value);
    setSelectedAddress(address);
    setIsCustomAddress(false);
  };

  const locations = useMemo(() => {
    return organizationMerchants.map((merchant) => ({
      value: merchant.id,
      label: merchant.name,
      address: merchant.address,
    }));
  }, [organizationMerchants]);

  const setMerchantsWithAddress = useCallback(async () => {
    const {
      data: { fetchOrganizationMerchants },
    } = await client.query({
      query: FETCH_ORGANIZATION_MERCHANTS,
      fetchPolicy: 'no-cache',
      variables: { input: { idOrganization: organization?.id } },
    });

    if (fetchOrganizationMerchants?.data) {
      setOrganizationMerchants(fetchOrganizationMerchants?.data || []);
    }
  }, [organization]);

  useEffect(() => {
    setMerchantsWithAddress();
  }, [setMerchantsWithAddress]);

  const handleSubmitOrder = () => {
    if (isDemo) {
      showOrderErrorForDemoMode();
    } else {
      const requestParams = createRequestParamsForSubmit();

      if (requestParams) {
        onRequest(requestParams, selectedAddress?.value);
        const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
        trackSegmentEvent('Completed Marketing Material Order', {
          application: applicationName,
          organizationId: organization?.id,
          merchantId: idMerchant,
          materialType: selectedMaterial.title,
          wasCustomAddress: isCustomAddress,
          addressMerchantId: isCustomAddress ? null : selectedAddress?.value,
        });
      }
    }
  };

  const openCustomAddressModal = () => {
    setIsCustomAddressModalVisible(true);
  };

  const closeCustomAddressModal = useCallback(() => {
    setIsCustomAddressModalVisible(false);
  }, []);

  const addCustomAddress = useCallback(
    (address) => {
      const addressObj = {
        address: {
          address1: address.address1,
          address2: address.address2,
          city: {
            name: address.city,
            state: {
              code: address.state,
            },
          },
          zip: address.zip,
        },
        label: address.label,
      };
      setSelectedAddress(addressObj);
      setIsCustomAddress(true);

      if (isCustomAddressModalVisible) {
        closeCustomAddressModal();
      }
    },
    [closeCustomAddressModal, isCustomAddressModalVisible],
  );

  const handleSelectedRequestReason = (event) => {
    const reason = getRequestReasonLabel(event?.target?.value);
    setSelectedRequestReason(reason);
  };

  const handleSelectedQuantity = (event) => {
    setSelectedQuantity(event?.target?.value);
  };

  const showOrderErrorForDemoMode = () => {
    alert.error('Marketing supply orders are not allowed in demo mode.');
  };

  const createRequestParamsForSubmit = (): RequestMaterial | null => {
    if (selectedMaterial?.documentType && selectedAddress?.address && selectedQuantity && selectedRequestReason) {
      return {
        documentType: selectedMaterial.documentType,
        quantity: Number(selectedQuantity),
        reason: selectedRequestReason,
        addressOne: selectedAddress.address.address1,
        addressTwo: selectedAddress.address.address2 || '',
        zipcode: selectedAddress.address.zip,
        city: selectedAddress.address.city?.name,
        state: selectedAddress.address.city?.state?.code,
        userId: Number(user.id),
      };
    } else {
      return null;
    }
  };

  const addressName = useMemo(() => {
    if (!isCustomAddress && user) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return selectedAddress?.label;
    }
  }, [isCustomAddress, user, selectedAddress]);

  return (
    <>
      <MaterialModal
        open={!!selectedMaterial}
        title="Order Marketing Supplies"
        hidden={isCustomAddressModalVisible}
        isSubmitDisabled={!isSubmitButtonEnabled}
        submitButtonLabel="Submit Order"
        onSubmit={handleSubmitOrder}
        onCancel={onClose}
      >
        <MaterialAndQuantityWrapper>
          <MaterialInfo>
            <MaterialImage src={selectedMaterial.image} alt={selectedMaterial.title} />
            <Title>{selectedMaterial.title}</Title>
          </MaterialInfo>
          {isQuantityDropdownVisible && (
            <QuantityContainer>
              <Dropdown
                label="Quantity"
                options={quantities}
                onChange={handleSelectedQuantity}
                value={selectedQuantity}
              />
            </QuantityContainer>
          )}
        </MaterialAndQuantityWrapper>
        <Row marginBottom="24px">
          <DropdownText>Why are you ordering this item?</DropdownText>
          <DropDownContainer data-testid="request-reasons-container">
            <Dropdown label="Select a Reason" options={REQUEST_REASONS} onChange={handleSelectedRequestReason} />
          </DropDownContainer>
        </Row>
        <Row>
          <DropdownText>
            Where should we send these supplies? Orders typically arrive within 8-10 business days.
          </DropdownText>
          <DropDownContainer data-testid="select-address-container">
            <Dropdown
              options={locations}
              onChange={handleSelectedAddressChanged}
              label={isCustomAddress ? 'Custom Address' : 'Select an Option'}
            />
          </DropDownContainer>
        </Row>
        {selectedAddress?.address ? (
          <DeliveryAddressWrapper>
            <Title>Delivery Address</Title>
            <div>{addressName}</div>
            {selectedAddress?.address ? (
              <>
                <div>
                  {selectedAddress.address.address1} {selectedAddress.address.address2}
                </div>
                <div>{`${selectedAddress.address.city?.name}, ${selectedAddress.address.city?.state?.code} ${selectedAddress.address.zip}`}</div>
              </>
            ) : null}
          </DeliveryAddressWrapper>
        ) : null}
        <LinkButton onClick={openCustomAddressModal}>Use a Different Address</LinkButton>
      </MaterialModal>
      {isCustomAddressModalVisible ? (
        <AddCustomAddressModal
          customAddress={customAddress}
          visible={isCustomAddressModalVisible}
          onSave={addCustomAddress}
          onCancel={closeCustomAddressModal}
        />
      ) : null}
    </>
  );
};

const DropdownText = styled(Row)`
  font-size: 14px;
  margin-bottom: 8px;
`;

const DeliveryAddressWrapper = styled.div`
  text-align: center;
  font-size: 14px;
  margin: 8px 0 16px;
`;

const MaterialAndQuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const MaterialInfo = styled(Row)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const MaterialImage = styled.img`
  max-width: 80px;
  max-height: 80px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const QuantityContainer = styled.div`
  width: 160px;
`;

const LinkButton = styled.a`
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
`;

export const DropDownContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  background-color: white;
  box-sizing: border-box;
  .dropdown-right {
    height: 46px;
  }
  .dropdown-content {
    // width: 80%;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;

export default RequestMaterialModal;
