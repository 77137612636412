import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import images from 'assets/images';
import { ApplicationLink, ApplicationLinkIcon, ApplicationSwitchButton, TierBadge } from 'lib/components';
import { Notification } from 'lib/components/Notification';

import { APP_SHOP_URL } from 'config';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { TierName } from 'lib/utils';

interface HeaderProps {
  isSidebarOpen: boolean;
  isSnackbarVisible: boolean;
}
interface ContainerProps {
  isSidebarOpen: boolean;
  isDemo: boolean;
}

export const ShopHeader = ({ isSidebarOpen, isSnackbarVisible }: HeaderProps) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const permission = usePermission();
  const { organization, organizationTier } = useStore();

  const [applicationLinkAnchorEl, setApplicationlinkAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(applicationLinkAnchorEl);

  const logLogoClicked = () => {
    navigate('/shop/home');
  };

  const handleClick = () => {
    navigator.clipboard.writeText(`${APP_SHOP_URL}/shop/${organization?.slug}`);
    alert.success('Sign-Up link was copied to your clipboard');
  };

  const handleClose = () => setApplicationlinkAnchorEl(null);

  return (
    <Container isSidebarOpen={isSidebarOpen} isDemo={isSnackbarVisible}>
      <TitleRow>
        <CherryLogo src={images.cherryLogo.default} onClick={logLogoClicked} />
        <CherryLogoTitle>| Shop</CherryLogoTitle>
      </TitleRow>
      <ActionsPaneContainer>
        {!permission(FeatureNames.APPLICATION_LINK, ScreenNames.SETTINGS) ? (
          ''
        ) : (
          <>
            <ApplicationLinkContainer onClick={handleClick}>
              Your Shop Link
              <IconButton>
                <ApplicationLinkIcon />
              </IconButton>
            </ApplicationLinkContainer>

            <CopyItemMenu
              anchorEl={applicationLinkAnchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <ApplicationMenuItem disableRipple={true}>
                <ApplicationLink isInHeader={true} handleClose={handleClose} />
              </ApplicationMenuItem>
            </CopyItemMenu>

            <Notification isTierBadgeVisible={!!organizationTier?.hasTier} />
            <MerchantLabelContainer>
              <MerchantLabel>{organization?.name}</MerchantLabel>
            </MerchantLabelContainer>
            {organizationTier?.hasTier && (
              <TierContainer>
                <TierBadge type={TierName(organizationTier?.organizationTier?.tierId)?.toLowerCase()} />
              </TierContainer>
            )}
            <ApplicationSwitchButton />
          </>
        )}
      </ActionsPaneContainer>
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.main.white};
  padding: 10px 40px;
  position: fixed;
  // width: calc(100% - ${(props) => (props.isSidebarOpen ? 250 : 80)}px);
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: width 0.4s;
  transition-timing-function: ease-in-out;
  top: ${(props) => (props.isDemo ? 42 : 0)}px;
  height: 92px;
  box-shadow: 0 0 5px #ddd;
  p {
    color: #000000;
  }
  @media print {
    display: none;
  }
`;

const CherryLogoTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
`;

const ActionsPaneContainer = styled.div`
  display: flex;
  flex-direction: row;
  * > {
    margin: 10px;
  }
  height: 40px;

  @media print {
    display: none;
  }
`;

const ApplicationLinkContainer = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const CherryLogo = styled.img`
  width: 145px;
  height: auto;
`;

const CopyItemMenu = styled(Menu)`
  margin-top: 50px;
  height: 600px !important;
  .MuiMenuItem-root {
    width: min-content;
    overflow: inherit;
  }
  .MuiPopover-paper {
    overflow-y: inherit;
    overflow-x: inherit;
  }
`;

const TitleRow = styled.div`
  display: inline-flex;
  align-items: center;
  color: #000000;
  cursor: pointer;

  svg {
    margin-right: 12px;
    fill: ${(props) => props.theme.main.darkBlue};
  }
`;

const ApplicationMenuItem = styled(MenuItem)`
  min-width: 650px !important;
  background-color: unset !important;
`;

const MerchantLabelContainer = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid #dadada;
  color: #000000;
  margin-left: 12px;
  border-radius: 5px;
  justify-content: center;
`;

const MerchantLabel = styled.span`
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;
