import React from 'react';

import ModalV2 from 'lib/components/ModalV2/ModalV2';
import styled from 'styled-components';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CancelLinkModal = ({ open, onSubmit, onClose }: Props) => {
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
    handleClose();
  };

  return (
    <ModalV2
      open={open}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      title="Cancel Link?"
      submitButtonLabel="Cancel Sale"
      cancelButtonlabel="Nevermind"
      width={480}
      type="warning"
    >
      <Body>
        Are you sure you want to cancel this link? <p> You can send another point of sale link afterwards </p>
      </Body>
    </ModalV2>
  );
};

const Body = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

export default CancelLinkModal;
