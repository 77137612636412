import { Menu, MenuItem } from '@mui/material';

import React from 'react';
import styled from 'styled-components';

import { FeatureNames } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationFrameIcon,
  AppMembershipIcon,
  AppPaymentPlansIcon,
  AppPointOfSaleIcon,
  AppShopIcon,
} from '../SidebarIcons';

interface Props {
  selectedApplication?: string;
}

export const ApplicationSwitchButton = ({ selectedApplication }: Props) => {
  const navigate = useNavigate();
  const { features } = useStore();

  const [applicationSwitchAnchorEl, setApplicationSwitchAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(applicationSwitchAnchorEl);
  const handleClose = () => setApplicationSwitchAnchorEl(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setApplicationSwitchAnchorEl(event.currentTarget);
  };

  const navigateToPaymentPlan = () => {
    navigate('/dashboard');
  };

  const navigateToMembership = () => {
    navigate('/membership/home');
  };

  const navigateToShop = () => {
    navigate('/shop/editor');
  };

  const navigateToPointOfSale = () => {
    navigate('/point-of-sale/home');
  };

  return (
    <>
      <ApplicationFrameContainer onClick={handleClick}>
        <ApplicationFrameIcon />
      </ApplicationFrameContainer>
      <ApplicationSwitchMenu
        anchorEl={applicationSwitchAnchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ApplicationSwitchItem disableRipple={true}>
          <Title>Cherry Apps</Title>
        </ApplicationSwitchItem>
        <ApplicationSwitchItem disableRipple={true} onClick={navigateToPaymentPlan}>
          <AppPaymentPlansIcon />
          <Subtitle>Cherry Payment Plans</Subtitle>
        </ApplicationSwitchItem>
        {features?.includes(FeatureNames.MEMBERSHIP) ? (
          <ApplicationSwitchItem disableRipple={true} onClick={navigateToMembership}>
            <AppMembershipIcon />
            <Subtitle>Cherry Memberships</Subtitle>
          </ApplicationSwitchItem>
        ) : null}

        {features?.includes(FeatureNames.ESHOP) ? (
          <ApplicationSwitchItem disableRipple={true} onClick={navigateToShop}>
            <AppShopIcon />
            <Subtitle>Cherry Shop</Subtitle>
          </ApplicationSwitchItem>
        ) : null}

        {features?.includes(FeatureNames.POINT_OF_SALE) ? (
          <ApplicationSwitchItem disableRipple={true} onClick={navigateToPointOfSale}>
            <AppPointOfSaleIcon />
            <Subtitle>Cherry Point of Sale</Subtitle>
          </ApplicationSwitchItem>
        ) : null}
      </ApplicationSwitchMenu>
    </>
  );
};

const ApplicationFrameContainer = styled.div`
  border: 1px solid #dadada;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const ApplicationSwitchMenu = styled(Menu)`
  margin-top: 50px;
  height: 600px !important;
  .MuiMenuItem-root {
    width: min-content;
    overflow: inherit;
  }
  .MuiPopover-paper {
    overflow-y: inherit;
    overflow-x: inherit;
  }
`;

const ApplicationSwitchItem = styled(MenuItem)`
  min-width: 350px !important;
  background-color: unset !important;
  margin-bottom: 8px !important;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: black;
  margin-top: 12px;
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: black;
  margin-left: 12px;
`;
