import { useApolloClient, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import Rox from 'rox-browser';

import { LOGIN_MUTATION } from '../graphql/mutations';
import { FETCH_ORGANIZATION, FETCH_ORGANIZATION_MERCHANTS } from '../graphql/queries';
import { LoginAnalyticsEventNames, useAnalytics } from '../hooks';
import useStore from '../hooks/useStore';
import { Routes } from '../types';
import { emailPattern } from '../utils';
import NavigateService from './Navigate';
import StorageService from './Storage';

export const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();
  const [userLogin] = useMutation(LOGIN_MUTATION);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailValid, setEmailValid] = useState(false);

  const { trackEvent } = useAnalytics({ isScreen: true });

  const {
    setSelectedOrganization,
    setOrganizationMerchants,
    setUser,
    setSelectedMerchant,
    setIsComingFromLogin,
  } = useStore();
  const alert = useAlert();
  const navigate = useNavigate();
  NavigateService.initNavigate(navigate);

  const trackLoginErrorEvent = () => {
    trackEvent({
      action: LoginAnalyticsEventNames.ERROR,
    });
  };

  const loginUser = async () => {
    try {
      trackEvent({
        action: LoginAnalyticsEventNames.USER,
      });
      setLoading(true);
      const res = await userLogin({ variables: { input: { email, password, scope: 'PARTNER' } } });
      if (res.data?.userLogin?.success) {
        const userData = res.data.userLogin;
        StorageService.setUserData(userData);
        StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
        const { user } = userData;
        setUser(user);
        Sentry.setUser({ email: user.email, id: user.id });

        const {
          data: { fetchUserOrganizations },
        } = await client.query({ query: FETCH_ORGANIZATION, variables: { input: {} } });

        setIsComingFromLogin(true);
        if (fetchUserOrganizations.data.length > 1) {
          navigate(Routes.ORGANIZATION_SELECT, {
            state: {
              organizations: fetchUserOrganizations.data,
            },
          });
        } else if (fetchUserOrganizations?.data?.length === 1) {
          const selectedOrganization = fetchUserOrganizations.data[0];
          setSelectedOrganization(selectedOrganization);
          Rox.setCustomNumberProperty('organization_id', Number(selectedOrganization?.id));
          Rox.setCustomStringProperty('organization_id_str', selectedOrganization?.id?.toString());
          const {
            data: { fetchOrganizationMerchants },
          } = await client.query({
            query: FETCH_ORGANIZATION_MERCHANTS,
            fetchPolicy: 'no-cache',
            variables: { input: { idOrganization: fetchUserOrganizations.data[0].id } },
          });
          const organizationMerchantList = fetchOrganizationMerchants.data;
          setOrganizationMerchants(fetchOrganizationMerchants.data);
          if (organizationMerchantList && organizationMerchantList.length > 0) {
            setSelectedMerchant(fetchOrganizationMerchants.data[0]);
          }
          NavigateService.navigate('/');
        } else {
          alert.error('This account is not authorized to access this portal');
          trackLoginErrorEvent();
        }
        setLoading(false);
      } else {
        if (res?.data?.userLogin?.code === 'organization.not.authorized') {
          try {
            const userRes = await userLogin({ variables: { input: { email, password, scope: 'MERCHANT_AUTH' } } });
            const userData = userRes.data.userLogin;
            if (userData.code === 'merchant.not.authorized') {
              alert.error('This account is not authorized to access this portal');
              trackLoginErrorEvent();
            } else {
              setSelectedOrganization(userData.organization);
              Rox.setCustomNumberProperty('organization_id', Number(userData?.organization?.id));
              Rox.setCustomStringProperty('organization_id_str', userData?.organization?.id?.toString());

              StorageService.setUserData({ ...userData, userType: 'MERCHANT_USER', authToken: userData.token });
              localStorage.setItem('@userType', 'MERCHANT_USER');
              setUser({ ...userData.user, authToken: userData.token, userType: 'MERCHANT_USER' });
              StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });

              const {
                data: { fetchOrganizationMerchants },
              } = await client.query({
                query: FETCH_ORGANIZATION_MERCHANTS,
                variables: { input: { idOrganization: userData?.organization?.id } },
              });
              const merchants = fetchOrganizationMerchants.data;
              if (merchants && merchants.length > 0) {
                setOrganizationMerchants(merchants);
              }

              NavigateService.navigate('/dashboard');
            }
          } catch (e: any) {
            if (e?.response?.data?.code === 'merchant.not.authorized') {
              alert.error('This account is not authorized to access this portal');
              trackLoginErrorEvent();
            }
          }
        } else {
          alert.error(
            'We could not recognize this username and/or password, please verify your login information and try again',
          );
          trackLoginErrorEvent();
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const fieldOnChange = (nativeEvent: any) => {
    const target = nativeEvent.target;
    if (target.name === 'email') {
      setEmailValid(emailPattern?.test(target.value));

      setEmail(target.value);
    } else {
      setPassword(target.value);
    }
  };

  const isButtonEnabled = !(email && emailValid) || !password || loading;

  const inputOnBlur = (event: any) => {
    const fieldName = event.currentTarget.name;
    trackEvent({
      action: fieldName === 'email' ? LoginAnalyticsEventNames.EMAIL_BLUR : LoginAnalyticsEventNames.PASS_BLUR,
    });
  };

  const trackForgotPasswordClick = () => trackEvent({ action: LoginAnalyticsEventNames.FP_CLICK });

  return {
    loginUser,
    loading,
    setEmail,
    setPassword,
    email,
    password,
    fieldOnChange,
    isButtonEnabled,
    inputOnBlur,
    trackForgotPasswordClick,
    emailValid,
  };
};
