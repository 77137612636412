import { Loading } from 'lib/components';
import { ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import styled from 'styled-components';

export const WaveIcon = styled.img`
  margin-right: 16px;
`;

export const SuccessPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid ${(props) => props.theme.main.sealGray};
  margin: 24px 0;
`;

export const BackToMainPageButton = styled(ContinueButton)`
  font-weight: 600;
  width: 300px;
`;

export const DownloadAgreementSection = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadText = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-right: 8px;
`;

export const BoldText = styled.div`
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckoutSummary = styled.div``;

export const LoadingSpinner = styled(Loading)`
  margin: 16px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
