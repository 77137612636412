import { Grid } from '@mui/material';
import React from 'react';

import { CancelLoanButton, SecondaryButton } from 'lib/components';
import { Container, RefundContainer, RefundTitle, Text } from './Common';

const VoidTransaction = ({ item, voidTransactionHandler }) => {
  const voidTransactionButtonHandler = () => {
    voidTransactionHandler(item);
  };
  return (
    <Container>
      <RefundContainer>
        <RefundTitle>Are you sure you want to void transaction?</RefundTitle>
        <Text>
          You can void a transaction within the first <b>2 hours</b> of a completed transaction. This is an easy way to
          correct mistakes, such as an incorrect purchase amount.
        </Text>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4}>
            <div>
              <CancelLoanButton
                onClick={item?.toggleExpandable}
                data-testid="void-cancel-button"
                variant={'contained'}
                data-tag="confirm-cancel"
                color={'#ec3360'}
              >
                Cancel
              </CancelLoanButton>
            </div>
          </Grid>
          <Grid item={true} xs={8}>
            <SecondaryButton data-testid="void-apply-button" onClick={voidTransactionButtonHandler} variant="filled">
              Void transaction
            </SecondaryButton>
          </Grid>
        </Grid>
      </RefundContainer>
    </Container>
  );
};

export default VoidTransaction;
