import { InputAdornment } from '@mui/material';
import React from 'react';

import { CherryTextField, NumberFormatInput } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { ButtonContainer, ButtonText, DownPaymentContainer, InputContainer } from './styles';

interface CustomizeDownPaymentProps {
  amount: number | null;
  checkKeyDown: (e) => void;
  handleClickIncrease: () => void;
  handleClickDecrease: () => void;
  handleClickReset: () => void;
  onChange: (e) => void;
  error: string;
  minEligible: number;
  maxEligible: number;
  loading: boolean;
}

const CustomizeDownPayment = ({
  amount,
  checkKeyDown,
  handleClickIncrease,
  handleClickDecrease,
  handleClickReset,
  onChange,
  error,
  minEligible,
  maxEligible,
  loading,
}: CustomizeDownPaymentProps) => {
  return (
    <div>
      <DownPaymentContainer>
        <ButtonContainer
          disabled={loading || amount === minEligible || !!error}
          onClick={handleClickDecrease}
          data-testid={'custom-down-payment-button-minus'}
        >
          <Icon src={loading || amount === minEligible || !!error ? 'minus_circle_gray' : 'minus_circle'} />
          Pay Less
        </ButtonContainer>
        <InputContainer>
          <CherryTextField
            id="custom-down-payment-input"
            data-testid={'custom-down-payment-input'}
            variant="filled"
            label="Today's Down Payment"
            type="tel"
            value={amount?.toFixed(2)}
            onChange={onChange}
            onKeyDown={checkKeyDown}
            error={!!error}
            helperText={error ? error : null}
            sx={{
              '& .MuiFormHelperText-root': {
                textAlign: 'justify',
                margin: '2px',
              },
              '& .MuiFilledInput-root': {
                paddingLeft: 0,
              },

              input: {
                fontWeight: 'bold',
              },
              label: {
                color: '#56636D',
                marginLeft: '16px',
              },
            }}
            InputProps={{
              inputComponent: NumberFormatInput,
              readOnly: false,
              disableUnderline: true,
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 54px',
                    '& .MuiTypography-root': {
                      color: '#000',
                      fontWeight: 'bold',
                    },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
        </InputContainer>
        <ButtonContainer
          disabled={loading || amount === maxEligible || !!error}
          data-testid={'custom-down-payment-button-plus'}
          onClick={handleClickIncrease}
        >
          <Icon src={loading || amount === maxEligible || !!error ? 'plus_circle_gray' : 'plus_circle'} />
          Pay More
        </ButtonContainer>
      </DownPaymentContainer>
      <ButtonText onClick={handleClickReset}>Reset Default Amount</ButtonText>
    </div>
  );
};

export default CustomizeDownPayment;
