import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { PhoneInputIcon, SendIcon } from 'lib/components';
import { Container, ContentContainer, PhoneInput, Subtitle, TextField } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import { useSendApplicationLink } from 'lib/services';
import styled from 'styled-components';

export const MobileSendApplicationLink = () => {
  const {
    onPhoneChange,
    logInputBlur,
    phoneNumberValid,
    phoneNumber,
    sendApplication,
    applicationSending,
  } = useSendApplicationLink();
  const { isSnackbarVisible } = useSnackbar();

  return (
    <Container pageTitle={'Send Application'}>
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Subtitle m={'0 0 8px 0'}>Send an application to customer via text</Subtitle>
        <TextField
          name="phone"
          onChange={onPhoneChange}
          onBlur={logInputBlur}
          placeholder={'(123) 456-7890'}
          data-testid="mobile-application-phone-input"
          type="tel"
          value={phoneNumber}
          InputProps={{
            inputComponent: PhoneInput as any,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <PhoneInputIcon width={24} height={24} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconWrapper
                  isActive={phoneNumberValid && !applicationSending}
                  onClick={sendApplication}
                  data-testid="mobile-application-sent-button"
                >
                  <SendIcon />
                </IconWrapper>
              </InputAdornment>
            ),
          }}
        />
      </ContentContainer>
    </Container>
  );
};

export default MobileSendApplicationLink;

const IconWrapper = styled.div<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? props.theme.main.green : '#DADADA')};
  width: 56px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -14px;
`;
