import dayjs from 'dayjs';

export const calculateAppliedOn = (apliedAt: string) => {
  try {
    const appliedDate = new Date(apliedAt);
    const todayDate = new Date();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    if (todayDate.toDateString() === appliedDate.toDateString()) {
      return 'Today';
    } else if (yesterdayDate.toDateString() === appliedDate.toDateString()) {
      return 'Yesterday';
    } else {
      return dayjs(apliedAt).format('MMM DD, YYYY');
    }
  } catch (err) {
    return dayjs(apliedAt).format('MMM DD, YYYY');
  }
};
