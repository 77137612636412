import { Icon, Label } from '@frontend/cherry-library';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle-outline.svg';
import {
  ComplianceInfoContainer,
  ComplianceInfoTitleContainer,
  ComplianceList,
} from 'pages/desktop/ApplyWithPatient/styles';
interface Props {
  maxWidth: number;
}

const ComplianceDisclosure = ({ maxWidth }: Props) => {
  return (
    <ComplianceInfoContainer maxWidth={maxWidth}>
      <ComplianceInfoTitleContainer>
        <Icon src={InfoIcon} height={20} width={20} />
        <Label size="large" fontWeight={700}>
          Compliance and Fair Lending
        </Label>
      </ComplianceInfoTitleContainer>
      <ComplianceList>
        <li>
          Please ensure the patient understands that they are providing information for a loan application with Cherry.
        </li>
        <li>
          <Label size="large" fontWeight={700}>
            Do{' '}
          </Label>
          ask the borrower to provide the information on the application and ensure they review and confirm the entered
          information.
        </li>
      </ComplianceList>
    </ComplianceInfoContainer>
  );
};

export default ComplianceDisclosure;
