import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { DropDown, FixedDecimalFormat } from 'lib/components';
import { TransactionsAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useAgent from 'lib/hooks/useAgent';
import useStore from 'lib/hooks/useStore';
import { usePartialRefund, useVoid } from 'lib/services';
import { useGetLoanFile } from 'lib/services/useGetLoanFile';
import { DisbursementRowProps, RefundStepEnums } from 'lib/types';
import {
  arrayToCsv,
  downloadBlobByContentType,
  formatPhoneNumber,
  MIN_ELIGIBLE_DAY,
  MIN_REFUND_AMOUNT,
  transactionsCsvDownloadParser,
  TransactionsCSVHeaders,
} from 'lib/utils';

export interface TransactionTableItem {
  loanId: string;
  displayId: string;
  createdAt: string;
  borrowerName: string;
  phone: string;
  store: string;
  download: React.ReactNode;
  status: string;
  disbursement: string;
  transactionType: string;
  amount: string;
  merchantFund: string;
  fundedAt: string;
  merchantRevenue: number;
  merchantId: number;
  fee: number;
  product: any;
  promoUsed?: boolean;
  plans: any;
  salesPerson: any;
  id: number;
  toggleExpandable: any;
  openDialog: any;
  fundStatus: string;
  subStatus: string;
  refundAmount: number;
  fund: any;
  servicingBalance: number;
  purchaseAmount: number;
  refunds: any;
  loanRebate: any;
  tierLabel: string;
}

export const useTransactionsTable = (expandableOpened: boolean, selectedItem: any, column: any) => {
  const { features, setRefundIssueType, merchants } = useStore();
  const { trackEvent } = useAnalytics();
  const permission = usePermission();
  const { checkRefundEligibility, chechRefundStatus } = usePartialRefund({ transaction: selectedItem });
  const { voidTransactionAllowed } = useVoid({ transactionInProcess: selectedItem });
  const { isAgent } = useAgent();

  const { getLoanFile } = useGetLoanFile();

  const options = (row: any) => {
    const optionData: any = [];
    const { cancelAllowed, refundAllowed, partialRefundDisabled, refundAllowBalance } = chechRefundStatus(row);
    if (permission(FeatureNames.DOWNLOAD_CONTRACT, ScreenNames.TRANSACTIONS))
      optionData.push({
        label: 'Download Agreement',
        key: 'download_agreement',
        className: 'download_agreement',
        value: row.id,
        color: '#0E202F',
      });

    if (permission('DOWNLOAD_CONTRACT', 'TRANSACTIONS'))
      optionData.push({
        label: 'Download Transaction',
        key: 'download_transaction',
        className: 'download_transaction',
        value: row.id,
        color: '#0E202F',
      });

    if (features.find((i) => i === 'REFUND')) {
      if (cancelAllowed && permission(FeatureNames.ISSUE_REFUND, ScreenNames.TRANSACTIONS)) {
        optionData.push({
          label: 'Cancel Refund',
          key: 'cancel',
          className: 'cancel',
          value: row.id,
          color: '#EC3360',
        });
      }
      if (
        refundAllowed &&
        !voidTransactionAllowed(row) &&
        refundAllowBalance > MIN_REFUND_AMOUNT &&
        permission(FeatureNames.ISSUE_REFUND, ScreenNames.TRANSACTIONS)
      ) {
        !partialRefundDisabled &&
          optionData.push({
            label: `Issue Partial Refund`,
            key: 'refundPartial',
            value: row.id,
            color: '#0E202F',
            className: 'issue-partial-refund',
          });
        optionData.push({
          label: `Issue Full Refund`,
          key: 'refund',
          value: row.id,
          color: '#0E202F',
          className: 'issue-refund',
        });
      }
      if (voidTransactionAllowed(row) && permission(FeatureNames.VOID_TRANSACTIONS, ScreenNames.TRANSACTIONS)) {
        optionData.push({
          label: `Void Transaction`,
          key: 'void',
          value: row.id,
          color: '#0E202F',
          className: 'void-transaction',
          disabled: !!isAgent,
        });
      }
    }

    return optionData;
  };

  const onDropdownChange = (data, row) => {
    const { findFundProcessing, initialRefundProcessing, isFundProcessing, initialFundProcessing, isFundNotHolded } =
      checkRefundEligibility(row);
    const { refundAllowedDay } = chechRefundStatus(row);
    if (data.key === 'download_transaction') {
      downloadTransactionCSV(row);
    } else if (data.key === 'download_agreement') {
      getLoanFile(data.value);
    } else if (initialFundProcessing || (isFundNotHolded && isFundProcessing && findFundProcessing)) {
      if (!initialRefundProcessing && (findFundProcessing || initialFundProcessing)) {
        row.openDialog('initial-processing');
      } else {
        row.openDialog('processing');
      }
    } else if (refundAllowedDay > MIN_ELIGIBLE_DAY) {
      row.openDialog('ineligible');
    } else {
      if (data.key === 'refundPartial') {
        setRefundIssueType(RefundStepEnums.ISSUE_PARTIAL_REFUND);
      } else {
        setRefundIssueType(RefundStepEnums.ISSUE_REFUND);
      }
      row.toggleExpandable();
    }
  };

  const dropdownRow = (row) => {
    const NewDropDown = DropDown;
    return (
      <DropdownContainer>
        {!(expandableOpened && selectedItem?.id === row.id) && options(row)?.length > 0 ? (
          <NewDropDown
            id={`drop-${row.id}`}
            options={options(row)}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={(data) => onDropdownChange(data, row)}
            showAllItem={true}
            customSelector={true}
            hoveredMode={false}
          />
        ) : (
          <Gap />
        )}
      </DropdownContainer>
    );
  };

  const downloadTransactionCSV = async (row: any) => {
    const parsedData = transactionsCsvDownloadParser(row, merchants);
    const csv = arrayToCsv([TransactionsCSVHeaders, parsedData]);
    const fileName = `cherry_transaction_${row?.loanId}_${dayjs().format('MM_DD_YYYY_HH_mm')}`;

    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
    trackEvent({
      action: TransactionsAnalyticsEventNames.DOWNLOAD_CLICK,
    });
  };

  const refundActive = (row: any) =>
    row?.status === 'REFUNDED' && (row?.subStatus === 'COMPLETED' || row?.subStatus === 'PROCESING');
  const refundProcessing = (row: any) =>
    (row?.status === 'REFUNDED' && row?.subStatus === 'PROCESSING') ||
    (row?.fund?.filter((fund) => fund?.directionType === 'MERCHANT_DEBIT')?.length > 0 &&
      !['COMPLETED', 'CANCELLED'].includes(row?.fundStatus));

  const hasMultipleRefunds = (row: any) => {
    const fundIds = row?.refunds?.map((ref) => ref?.fundId);
    return row?.fund?.filter((fund) => fundIds.includes(fund?.id));
  };

  const findRefundItem = (row, id) => {
    return row?.refunds.find((ref) => ref?.fundId === id);
  };

  const fundProcessing = (fund) => fund?.status !== 'COMPLETED';

  const PlaceholderContainer = ({ row }) => {
    const findProcessing = hasMultipleRefunds(row)?.find((f) => fundProcessing(f));

    return (
      <RefundLabelContainer>
        <RefundLabelTitle>{hasMultipleRefunds(row)?.length} Refunds</RefundLabelTitle>
        <RefundLabelDesc isGreen={!findProcessing}>{findProcessing ? 'Processing' : 'Completed'}</RefundLabelDesc>
      </RefundLabelContainer>
    );
  };

  const OptionsContainer = ({ row }) => {
    return (
      <RefundOptionsContainer>
        <RefundOptionsTitle>Refund History</RefundOptionsTitle>
        {hasMultipleRefunds(row)?.map((fund, index) => (
          <RefundOptionsTextContainer key={index}>
            <RefundOptionsTextItem>
              <RefundOptionsText>
                <FixedDecimalFormat amount={findRefundItem(row, fund.id)?.amount} prefix={'$'} />
              </RefundOptionsText>
              <RefundOptionsText fontSize="11">{dayjs(fund.updatedAt).format('MMM DD, YYYY')}</RefundOptionsText>
            </RefundOptionsTextItem>
            <RefundOptionsTextItem>
              <RefundOptionsText isGreen={!fundProcessing(fund)}>
                {fundProcessing(fund) ? 'Processing' : 'Completed'}
              </RefundOptionsText>
            </RefundOptionsTextItem>
          </RefundOptionsTextContainer>
        ))}
      </RefundOptionsContainer>
    );
  };

  const renderMultipleRefunds = (row: any) => {
    return (
      <DropDown
        id={`dropdown-multiple-refund`}
        options={undefined}
        placeholderComponent={<PlaceholderContainer row={row} />}
        optionsComponent={<OptionsContainer row={row} />}
        showAllItem={true}
        customSelector={true}
        hoveredMode={false}
      />
    );
  };

  const decideDisbursementStatus = (row: DisbursementRowProps): JSX.Element | string => {
    if (row?.disbursement === 'FUNDING' || row?.disbursement === 'Completed') {
      return <DisbursementText isGreen={row?.disbursement === 'Completed'}>Completed</DisbursementText>;
    } else if (row?.subStatus === 'AWAITING_SUBMISSION') {
      return <DisbursementText isGreen={false}>Processing</DisbursementText>;
    } else {
      return row?.disbursement;
    }
  };

  const merchantRevenue = (row: any) => {
    if (row?.fee) {
      return (
        <MerchantRevenue>
          <MerchantRevenueContainer>
            <FixedDecimalFormat amount={row.fee} prefix={'$'} />
          </MerchantRevenueContainer>
        </MerchantRevenue>
      );
    }
    return null;
  };

  const tableColumns = [
    {
      name: 'Contract ID',
      selectorString: 'displayId',
      selector: (row) => row.displayId,
      sortable: true,
      cell: (row) => <DisplayDiv title={row?.displayId}>{row?.displayId}</DisplayDiv>,
    },
    {
      name: 'Date',
      selectorString: 'fundedAt',
      selector: (row) => row.fundedAt,
      sortable: true,
      cell: (row) => dayjs.utc(row.fundedAt).local().format('MMM D, YYYY h:mm A'),
    },
    {
      name: 'Customer',
      selectorString: 'borrowerName',
      selector: (row) => row.borrowerName,
      cell: (row) => (
        <div>
          <CustomerInfo>{row.borrowerName}</CustomerInfo>
          <CustomerSubInfo>{formatPhoneNumber(row.phone)}</CustomerSubInfo>
        </div>
      ),
    },
    {
      name: 'Gross Sales',
      selectorString: 'amount',
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <FixedDecimalFormat amount={row.purchaseAmount} prefix={'$'} />,
    },
    {
      name: 'Refunds',
      selectorString: 'refunds',
      selector: (row) => row.refunds,
      cell: (row) => (
        <div>
          {row?.status !== 'REFUNDED' && hasMultipleRefunds(row)?.length > 1 ? (
            renderMultipleRefunds(row)
          ) : (
            <>
              {hasMultipleRefunds(row)?.find((f) => fundProcessing(f)) ? (
                <FixedDecimalFormat amount={findRefundItem(row, row?.fund?.[0].id)?.amount} prefix={'$'} />
              ) : row.refundAmount && row?.status !== 'AWAITING_FUNDING' ? (
                <FixedDecimalFormat amount={row?.refundAmount} prefix={'$'} />
              ) : (
                'n/a'
              )}
              {refundProcessing(row) && <RefundStatusSpan>Refund Processing</RefundStatusSpan>}
              {/* {(refundActive(row) || (fundActive(row) && hasMultipleRefunds(row.fund)?.length === 1)) && ( */}
              {(refundActive(row) || hasMultipleRefunds(row)?.length === 1) && (
                <RefundStatusSpan>{dayjs(row?.fund?.[0]?.updatedAt).format('MMM DD, YYYY')}</RefundStatusSpan>
              )}
            </>
          )}
        </div>
      ),
      // ) : '',
    },
    {
      name: 'Fees',
      selectorString: 'merchantRevenue',
      selector: (row) => row.merchantRevenue,
      sortable: true,
      cell: (row) => merchantRevenue(row),
    },
    {
      name: 'Net Sales',
      selectorString: 'merchantFund',
      selector: (row) => row.merchantFund,
      sortable: true,
      cell: (row) =>
        row.merchantFund ? (
          <FixedDecimalFormat amount={row.merchantFund > 0 ? row.merchantFund : 0} prefix={'$'} />
        ) : null,
    },
    {
      name: 'Disbursement',
      selectorString: 'disbursement',
      selector: (row) => row.disbursement,
      cell: (row) => decideDisbursementStatus(row),
    },
    {
      name: 'Term',
      selectorString: 'product.term',
      selector: (row) => row.product.term,
      sortable: false,
      cell: (row) => row.product?.term + ' Months',
    },
    ...column,
    {
      name: '',
      selectorString: 'download',
      selector: (row) => row.download,
      right: true,
      left: false,
      cell: (row) => dropdownRow(row),
    },
  ];

  return { tableColumns };
};

const CustomerInfo = styled.p<{ fontSize?: string }>`
  margin: 0 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
`;

const CustomerSubInfo = styled.p<{ fontSize?: string }>`
  margin: 0 0;
  font-size: 10px;
  line-height: 13px;
`;

const DropdownContainer = styled.div`
  .dropdown-right {
    min-width: 100px;
    padding-left: 0 !important;
    svg {
      color: ${(props) => props.theme.main.green};
    }
  }
  .dropdown-left {
    padding-left: -100px !important;

    .dropdown-content {
      left: -100px;
    }
  }
`;

const RefundStatusSpan = styled.span`
  display: block;
  font-size: 10px;
  line-height: 13px;
`;

const DisbursementText = styled.span<{ isGreen: boolean }>`
  color: ${(props) => (props.isGreen ? props.theme.main.green : props.theme.main.textColor)};
`;

const RefundLabelContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RefundLabelTitle = styled.span`
  text-decoration: underline;
  font-size: 12px;
  line-height: 20px;
`;

const RefundLabelDesc = styled.span<{ isGreen?: boolean }>`
  font-size: 10px;
  line-height: 20px;
  color: ${(props) => (props.isGreen ? props.theme.main.green : props.theme.main.textColor)};
`;

const RefundOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  width: 250px;
`;

const RefundOptionsTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
`;

const RefundOptionsTextContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

const RefundOptionsTextItem = styled.span`
  display: flex;
  flex-direction: column;
`;

const RefundOptionsText = styled.span<{ isGreen?: boolean; fontSize?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '13')}px;
  font-weight: 400;
  line-height: 21px;
  text-transform: capitalize;
  color: ${(props) => (props.isGreen ? props.theme.main.green : props.theme.main.textColor)};
`;

const DisplayDiv = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img {
    margin-left: 8px;
  }
`;

const Gap = styled.div`
  width: 15px;
`;

const MerchantRevenue = styled.div`
  position: relative;
`;

const MerchantRevenueContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
