import React from 'react';

import { CancelLoanButton } from 'lib/components';
import { Center, LoadingText, ResultContainer, TextH5 } from './Common';

export const CheckoutEditSuccess = ({ borrowerName, closeExpanded, cancelLoanRef }) => {
  return (
    <Center>
      <ResultContainer>
        <TextH5>Plan Updated</TextH5>
        <LoadingText>
          {borrowerName} has received an updated link. You’ll get notified once they’ve chosen a plan and entered
          payment info.
        </LoadingText>
        <CancelLoanButton
          data-testid="dismiss"
          variant={'filled'}
          data-tag="update-success"
          onClick={closeExpanded}
          style={{ width: '90%' }}
          ref={cancelLoanRef}
        >
          Close
        </CancelLoanButton>
      </ResultContainer>
    </Center>
  );
};
