import { DashCard, FixedDecimalFormat, PageHeader, TableLoader } from 'lib/components';
import client from 'lib/graphql/client';
import { FETCH_POINT_OF_SALE_SETTLEMENTS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Container } from 'lib/styles';
import { Column } from 'lib/styles';
import { formatDate } from 'lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

interface Settlement {
  id: number;
  date: string;
  amount: number;
}

const PointOfSaleSettings = () => {
  const { organization } = useStore();

  const [settlementData, setSettlemenetData] = useState<Settlement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(false);

  const bottomBoundaryRef = useRef(null);

  const getSettlements = async () => {
    try {
      setLoading(true);
      const { data: { fetchPointOfSaleSettlements } = {} } = await client.query({
        query: FETCH_POINT_OF_SALE_SETTLEMENTS,
        variables: {
          input: {
            pagination: {
              limit: 10,
              startingAfter: settlementData.length > 0 ? settlementData?.[settlementData.length - 1]?.id : undefined,
            },
            organizationId: organization?.id,
          },
        },
      });
      if (fetchPointOfSaleSettlements.data.length > 0) {
        setSettlemenetData((prev) => [...prev, ...fetchPointOfSaleSettlements?.data]);
        setHasMore(fetchPointOfSaleSettlements?.hasMore);
        return;
      }
      setSettlemenetData([]);
      setHasMore(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setShouldFetch(false);
    }
  };

  useEffect(() => {
    if (!bottomBoundaryRef.current) return;

    if (!hasMore || settlementData.length === 0) return;

    const observer = new IntersectionObserver(() => {
      setShouldFetch(true);
    });

    observer.observe(bottomBoundaryRef.current!);

    return () => {
      if (bottomBoundaryRef.current) observer.unobserve(bottomBoundaryRef.current!);
    };
  }, [bottomBoundaryRef, hasMore]);

  const columns = [
    {
      name: 'Date',
      selector: (row) => row.arrivalDate,
      width: '160px',
      cell: (row) => (row.arrivalDate ? formatDate(row.arrivalDate) : ''),
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      cell: (row) => (row.amount ? <FixedDecimalFormat amount={row.amount} prefix={'$'} /> : ''),
    },
    // {
    //   name: '',
    //   selector: 'download',
    //   width: '155px',
    //   cell: (row) => (
    //     <CustomSecondaryButton
    //       variant={'contained'}
    //       // tslint:disable-next-line: jsx-no-lambda
    //       onClick={() => viewDetailHandler(row)}
    //       data-testid="downloadButton"
    //     >
    //       View Details
    //     </CustomSecondaryButton>
    //   ),
    // },
  ];

  useEffect(() => {
    if (!shouldFetch) return;
    getSettlements();
  }, [shouldFetch]);

  // const viewDetailHandler = (row: any) => {
  //   // TODO
  //   // tslint:disable-next-line: no-console
  //   console.log('row', row);
  // };

  return (
    <Container>
      <PageHeader title="Settings" />

      <Row>
        <Column>
          <DashCard>
            <DashTitle>Settlements</DashTitle>
            <>
              <CustomDataTable
                noHeader={true}
                columns={columns}
                data={settlementData}
                // onChangePage={pageChange}
                // onChangeRowsPerPage={rowsCountChange}

                fixedHeaderScrollHeight="300px"
                // pagination={true}
                // paginationServer
                // paginationTotalRows={total}
              />
              {loading && <TableLoader count={4} />}
              <div ref={bottomBoundaryRef} />
            </>
          </DashCard>
        </Column>
      </Row>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const CustomDataTable = styled(DataTable)`
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }
`;

const DashTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: black;
  padding-left: 15px;
  margin-bottom: 8px;
`;

export default PointOfSaleSettings;
