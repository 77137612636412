import React from 'react';
import { PaymentEstimateModalInitialData } from 'pages/desktop/Dashboard/Dashboard';
export interface PreviewData extends PaymentEstimateModalInitialData {
  patientName?: string;
  treatmentCoordinator?: string;
  additionalNotes?: string;
  selectedLocation?: any;
  sendToPatientDetails?: { emailAddress?: string; phoneNumber?: string };
  data?: any;
}

export enum PDF_PAGE {
  FIRST = 0,
  SECOND = 1,
}
