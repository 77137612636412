import { useApolloClient, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { DashCard, DashComponent, EditIcon, TableLoader, TrashIcon } from 'lib/components';
import { DELETE_MERCHANT_USER, DELETE_ORGANIZATION_USER, GET_ORGANIZATION_USERS } from 'lib/graphql/mutations';
import { FETCH_ORGANIZATION_GROUP_USERS, FETCH_ORGANIZATION_MERCHANT_USERS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import { useUserManagementTableColumns } from 'lib/tables';
import { settingsTableStyle } from 'lib/utils';

import { PocUser } from 'lib/types';
import { UserManagementRemoveDialog } from 'pages/desktop/Settings/components/UserManagement/UserManagementRemoveDialog';
import { UserManagementStepControlDialog } from 'pages/desktop/Settings/components/UserManagement/UserManagementStepControlDialog';

export const UserManagement = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const permission = usePermission();

  const {
    organization,
    merchants,
    user,
    setOrganizationMerchants,
    setPocUsers,
    newUserId,
    selectedOrganizationGroup,
    isAdminReporting,
  } = useStore();
  const idOrganization = organization?.id || 0;

  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
  const [organizationGroupUsers, setOrganizationGroupUsers] = useState<any>([]);
  const [organizationData, setOrganizationData] = useState<any>([]);
  const [merchantData, setMerchantData] = useState<any>([]);
  const [mergedUserTableData, setMergedUserTableData] = useState<any>([]);
  const [selectedOrganizationMember, setSelectedOrganizationMember] = useState<any>({});
  const [openFirstStep, setOpenFirstStep] = useState<boolean>(false);

  // GQL mutation
  const [getOrganizationUsers] = useMutation(GET_ORGANIZATION_USERS);
  const [removeOrganizationUser] = useMutation(DELETE_ORGANIZATION_USER);
  const [deleteMerchantUser] = useMutation(DELETE_MERCHANT_USER);

  let _merchantUsers: any = [];
  let _merchantGroupUsers: any = [];

  useEffect(() => {
    fetchOrganizationUsers();
    fetchMerchantUsers();
  }, [newUserId]);

  useEffect(() => {
    if (isAdminReporting) {
      fetchOrganizationGroupUsersRequest();
    }
  }, []);

  const mergeMerchantGroupUsers = (childs) => {
    childs.forEach((child) => {
      if (child.type === 'MERCHANT_GROUP') {
        _merchantGroupUsers = [
          ..._merchantGroupUsers,
          ...child.merchantGroupUsers.map((u) => ({ ...u, merchantGroupId: child?.id?.toString() })),
        ];
      }
      mergeMerchantGroupUsers(child.childs);
    });
  };

  useEffect(() => {
    if (merchants && merchants.length > 0) {
      merchants?.forEach((merchant) => {
        if (merchant?.merchantUsers) _merchantUsers = [..._merchantUsers, ...merchant?.merchantUsers];
      });
    }
    setMerchantData(findUniqueUserMerchants(_merchantUsers));
  }, [merchants]);

  useEffect(() => {
    if (isAdminReporting) {
      setMergedUserTableData(organizationGroupUsers);
      return;
    }
    const finalData = [...organizationData, ...merchantData];
    const uniquesUsers = findUniqueUsers(finalData);
    const sortedArray = uniquesUsers.sort((prev: any, next: any) =>
      dayjs(next?.createdAt).diff(dayjs(prev?.createdAt)),
    );
    setPocUsers(sortedArray);
    setMergedUserTableData(sortedArray);
  }, [organizationData, merchantData, organizationGroupUsers]);

  const traverseAndFillIds = (commonUserMerchants, item, merchantIds) => {
    const merchantUser = _merchantUsers?.find((u) => u?.user?.email === item?.user?.email);
    if (!merchantUser) return;
    const merchantsOfUser = commonUserMerchants?.map((commonMerchant) => commonMerchant.merchant.id);
    merchantIds.push(...merchantsOfUser);
  };

  const findUniqueUserMerchants = (data) => {
    if (data) {
      const uniqueData: any = [];
      data.forEach((item: any) => {
        const commonUserMerchants = data.filter((it) => it?.user?.email === item?.user?.email);

        const merchantIds: string[] = [];
        traverseAndFillIds(commonUserMerchants, item, merchantIds);

        item.location = commonUserMerchants?.length;
        item.email = item?.user?.email;
        item.merchantIds = merchantIds;
        item.merchantGroupIds = [];
        if (!uniqueData.find((unique: any) => unique.email === item?.user?.email)) {
          uniqueData.push(item);
        }
      });

      return uniqueData;
    }
    return [];
  };

  const findUniqueUsers = (data) => {
    if (data) {
      const uniqueUsers: PocUser[] = [];
      data.forEach((datum) => {
        const findUserIsExist = uniqueUsers?.find((unique: PocUser) => unique?.user?.email === datum?.user?.email);
        if (findUserIsExist) {
          findUserIsExist.merchantIds = datum.merchantIds;
        } else {
          uniqueUsers.push(datum);
        }
      });
      return uniqueUsers;
    }
    return [];
  };

  const getActionItemsRow = (row) => {
    const handleRemoveDialog = () => {
      setSelectedOrganizationMember(row);
      setOpenRemoveDialog(true);
    };

    const handleEditDialog = () => {
      setSelectedOrganizationMember(row);
      setOpenFirstStep(true);
    };

    const isUserExist = mergedUserTableData?.find((us) => us?.user?.email === user?.email);
    const isUserOneself = row?.user?.id === user?.id;
    const hideColumn = isUserExist?.role === 'MANAGER' && row?.role === 'ADMIN';

    return (
      <>
        {!permission(FeatureNames.DELETE_USER, ScreenNames.SETTINGS) || isUserOneself || hideColumn ? (
          ''
        ) : (
          <IconContainer onClick={handleRemoveDialog} data-testid="user-delete-button">
            <TrashIcon width={18} height={18} />
          </IconContainer>
        )}
        {!permission(FeatureNames.EDIT_USER, ScreenNames.SETTINGS) || hideColumn ? (
          ''
        ) : (
          <IconContainer scale={true} onClick={handleEditDialog}>
            <EditIcon />
          </IconContainer>
        )}
      </>
    );
  };

  const { tableColumns } = useUserManagementTableColumns([
    {
      name: '',
      cell: getActionItemsRow,
      minWidth: '60px',
      right: true,
    },
  ]);

  const closeUserManagementRemoveDialog = async () => {
    setSelectedOrganizationMember({});
    setOpenRemoveDialog(false);
  };

  // FETCH ORGANIZATION GROUP USER
  const fetchOrganizationGroupUsersRequest = async () => {
    setLoading(true);
    try {
      const {
        data: { fetchOrganizationGroupUsers },
      } = await client.query({
        query: FETCH_ORGANIZATION_GROUP_USERS,
        variables: {
          input: {
            search: [
              {
                key: 'organizationGroupId',
                value: selectedOrganizationGroup?.id,
              },
            ],
          },
        },
      });

      if (fetchOrganizationGroupUsers.contents) {
        setOrganizationGroupUsers(
          fetchOrganizationGroupUsers?.contents.map((orgGroupUser) => ({
            ...orgGroupUser,
            user: orgGroupUser.userInfo,
          })),
        );
      } else {
        setOrganizationGroupUsers([]);
      }
    } catch (error) {
      alert.error('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // FETCH ORGANIZATION USER
  const fetchOrganizationUsers = async () => {
    setLoading(true);
    try {
      const { data } = await getOrganizationUsers({ variables: { input: { idOrganization } } });

      if (data.fetchOrganizationUsers.success) {
        data?.fetchOrganizationUsers?.data.forEach((orgUser) => {
          orgUser.location = merchants?.length;
          orgUser.merchantIds = merchants?.map((merch) => merch.id) || [];
        });

        setOrganizationData(data?.fetchOrganizationUsers?.data);
        setLoading(false);
      } else {
        setOrganizationData([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  // FETCH MERCHANT USERS
  const fetchMerchantUsers = async () => {
    setLoading(true);

    try {
      const { data: { fetchOrganizationMerchants } = {} } = await client.query({
        query: FETCH_ORGANIZATION_MERCHANT_USERS,
        fetchPolicy: 'no-cache',
        variables: { input: { idOrganization: organization?.id } },
      });

      setOrganizationMerchants(fetchOrganizationMerchants?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const deleteOrganizationUserRequest = async () => {
    const userId = selectedOrganizationMember?.user.id;

    try {
      const { data } = await removeOrganizationUser({
        variables: { input: { userId, organizationId: idOrganization } },
      });

      if (data?.removeOrganizationUser?.success) {
        setLoading(false);
        alert.success('Team member removed from the organization');
        fetchOrganizationUsers();
      } else {
        alert.error(data?.removeOrganizationUser?.message || 'Error occurred');
      }

      closeUserManagementRemoveDialog();
    } catch (error) {
      setLoading(false);
      closeUserManagementRemoveDialog();
      alert.error('An error occurred');
    }
  };

  const deleteMerchantUserRequest = async () => {
    const userId = selectedOrganizationMember?.user.id;
    const merchantIds = selectedOrganizationMember.merchantIds;

    try {
      const {
        data: { deleteMerchantUser: deleteMerchantUserResult },
      } = await deleteMerchantUser({ variables: { input: { userId, idMerchants: merchantIds } } });

      if (deleteMerchantUserResult?.success) {
        setLoading(false);
        alert.success('Team member removed from the merchant');
        fetchMerchantUsers();
      } else {
        alert.error(deleteMerchantUserResult?.message || 'An Error Occurred');
        fetchMerchantUsers();
      }

      closeUserManagementRemoveDialog();
    } catch (error) {
      setLoading(false);
      closeUserManagementRemoveDialog();
      alert.error('An error occurred');
    }
  };

  const setHandleClose = () => {
    fetchMerchantUsers();
    fetchOrganizationUsers();
    fetchOrganizationGroupUsersRequest();
  };

  // DELETE USER
  const deleteUser = async () => {
    setLoading(true);
    if (selectedOrganizationMember?.id) {
      deleteOrganizationUserRequest();
    }
    if (selectedOrganizationMember?.merchantIds) {
      deleteMerchantUserRequest();
    }
  };

  return (
    <Column>
      <DashComponent dashHeader={'User Management'} />
      <DashCard>
        <Row>
          <Column margin={'auto'}>
            <UserManagementRemoveDialog
              selectedOrganizationMember={selectedOrganizationMember}
              open={openRemoveDialog}
              handleConfirm={deleteUser}
              handleClose={closeUserManagementRemoveDialog}
            />
            <UserManagementStepControlDialog
              setSelectedOrganizationMember={setSelectedOrganizationMember}
              selectedOrganizationMember={selectedOrganizationMember}
              openFirstStep={openFirstStep}
              setOpenFirstStep={setOpenFirstStep}
              setHandleClose={setHandleClose}
              organizationUsers={organizationData}
              merchantUsers={merchantData}
            />
          </Column>
        </Row>
        <GridContainer container={true} spacing={3}>
          <Grid container={true}>
            <CustomDataTable
              noHeader={true}
              customStyles={settingsTableStyle}
              columns={tableColumns}
              data={mergedUserTableData}
              pagination={true}
              progressPending={loading}
              progressComponent={<TableLoader count={4} />}
            />
          </Grid>
        </GridContainer>
      </DashCard>
    </Column>
  );
};

const IconContainer = styled.div<{ scale?: boolean }>`
  cursor: pointer;
  padding-right: 10px;

  & > svg {
    transform: ${(props) => (props.scale ? 'scale(0.8)' : ' scale(1)')};
    path {
      fill: #868f97;
    }

    &:hover {
      path {
        fill: #ec3360;
      }
    }
  }
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 40px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
`;

const GridContainer = styled(Grid)`
  margin-left: -12px !important;
`;
