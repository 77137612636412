import ComplianceDisclosure from 'pages/desktop/ApplyWithPatient/Components/Application/ComplianceInfoBox';
import Form from 'pages/desktop/ApplyWithPatient/Components/Application/Form';
import { ApplicationContainer } from 'pages/desktop/ApplyWithPatient/styles';
import { useEffect, useState } from 'react';

interface ApplicationProps {
  setStep: (string) => void;
  setAlreadyExistingBorrower: (boolean) => void;
}

const Application = ({ setStep, setAlreadyExistingBorrower }: ApplicationProps) => {
  const MAX_SCREEN_WIDTH_FOR_ROW_VIEW = 1000; // Below this threshold, boxes are stacked on top. Above the threshold ,they stack side by side
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ApplicationContainer maxWidth={MAX_SCREEN_WIDTH_FOR_ROW_VIEW}>
      {screenWidth <= MAX_SCREEN_WIDTH_FOR_ROW_VIEW && (
        <ComplianceDisclosure maxWidth={MAX_SCREEN_WIDTH_FOR_ROW_VIEW} />
      )}
      <Form setAlreadyExistingBorrower={setAlreadyExistingBorrower} setStep={setStep} />
      {screenWidth > MAX_SCREEN_WIDTH_FOR_ROW_VIEW && <ComplianceDisclosure maxWidth={MAX_SCREEN_WIDTH_FOR_ROW_VIEW} />}
    </ApplicationContainer>
  );
};
export default Application;
