import { FormControl } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '@frontend/cherry-library';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { formatPhoneNumber } from 'lib/utils';

export interface SendToPatientProps {
  phoneNumber?: string;
  emailAddress?: string;
  isEmailValid?: boolean;
  isPhoneValid?: boolean;
  shouldSendSms: boolean;
  shouldSendEmail: boolean;
  shouldPrint: boolean;
  setShouldSendSms: (value: boolean) => void;
  setShouldSendEmail: (value: boolean) => void;
  setShouldPrint: (value: boolean) => void;
}

interface Props {
  sendToPatientDetails: SendToPatientProps;
}

const SendToPatient = ({ sendToPatientDetails }: Props) => {
  const {
    phoneNumber,
    emailAddress,
    shouldSendSms,
    shouldSendEmail,
    shouldPrint,
    setShouldSendSms,
    setShouldSendEmail,
    setShouldPrint,
  } = sendToPatientDetails || {};
  const { flags } = useFlags();
  const { user } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const handleSendSMS = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    const checked = currentTarget.checked;

    setShouldSendSms(checked);
    trackSegmentEvent('Toggled Send Sms', {
      application: applicationName,
      component: 'Estimate Generator',
      userId: `u-${user?.id}`,
      value: checked,
      phone: phoneNumber,
    });
  };

  const handleSendEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    const checked = currentTarget.checked;

    setShouldSendEmail(checked);
    trackSegmentEvent('Toggled Send Email', {
      application: applicationName,
      component: 'Estimate Generator',
      userId: `u-${user?.id}`,
      value: checked,
      email: emailAddress,
    });
  };

  const handlePrint = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    const checked = currentTarget.checked;

    setShouldPrint(checked);
    trackSegmentEvent('Toggled Print', {
      application: applicationName,
      component: 'Estimate Generator',
      userId: `u-${user?.id}`,
      value: checked,
      email: emailAddress,
      phone: phoneNumber,
    });
  };

  return sendToPatientDetails ? (
    <SendToPatientContainer>
      <SectionTitle>How would you like to share this?</SectionTitle>

      <FormControl margin="none" fullWidth={true}>
        <Checkbox
          disabled={phoneNumber === ''}
          name="sendText"
          value={shouldSendSms}
          checked={shouldSendSms}
          onChange={handleSendSMS}
          label={`Text ${formatPhoneNumber(phoneNumber) || ''}`}
        />
      </FormControl>

      <FormControl margin="none" fullWidth={true}>
        <Checkbox
          disabled={emailAddress === ''}
          name="sendEmail"
          value={shouldSendEmail}
          checked={shouldSendEmail}
          onChange={handleSendEmail}
          label={`Email ${emailAddress}`}
        />
      </FormControl>

      <FormControl margin="none" fullWidth={true}>
        <Checkbox
          name="print"
          value={shouldPrint}
          checked={shouldPrint}
          onChange={handlePrint}
          label={'Print (Generate PDF)'}
        />
      </FormControl>
    </SendToPatientContainer>
  ) : null;
};

const SendToPatientContainer = styled.div`
  margin-top: 24px;
  margin-left: 48px;

  .MuiTypography-body1 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif !important;
  }
`;

const SectionTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export default SendToPatient;
