import { gql } from '@apollo/client/core';

export const POST_MERCHANT_LEADS = gql`
  mutation postMerchantLead($input: PostMerchantLeadInput) {
    postMerchantLead(input: $input) {
      id
    }
  }
`;

export const PATCH_MERCHANT = gql`
  mutation patchMerchant($input: PatchMerchantInput) {
    patchMerchant(input: $input) {
      id
      bankingInfo {
        accountStatus
      }
    }
  }
`;
