import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import images from 'assets/images';
import { Banner } from 'lib/components/Banner';
import { HelpMeModal } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import StorageService from 'lib/services/Storage';

interface Props {
  pathname: string;
  pageTitle: string;
  backUrl: string;
}

const BackButtonNotVisibleScreens = [
  '/',
  '/result',
  '/personal-info',
  '/application-status',
  '/login',
  '/forgot-password',
  '/provide-more-info',
  '/submitted',
  '/application-approved',
  '/application-declined',
  '/reset-password',
  '/dashboard',
  '/transactions',
  '/transactions/detail/void/success',
  '/transactions/detail/cancel-refund',
  '/transactions/detail/full-refund/success',
  '/transactions/detail/partial-refund/success',
  '/settings',
  '/share-your-link',
  '/send-application',
  '/organization-select',
  '/help',
];

const LogoVisibleScreens = [
  '/dashboard',
  '/transactions',
  '/application-approved',
  '/application-declined',
  '/application-approved',
  '/application-declined',
  '/transactions/detail/void/success',
  '/transactions/detail/full-refund/success',
  '/transactions/detail/partial-refund/success',
  '/settings',
  '/share-your-link',
  '/send-application',
  '/help',
];

const HelpButtonVisibleScreens = ['/login', '/organization-select', '/forgot-password'];

export const Header = ({ pathname, pageTitle, backUrl }: Props) => {
  const navigate = useNavigate();
  const { activeModesText, isSnackbarVisible } = useSnackbar();

  const [helpMe, setHelpMe] = useState(false);

  const goBackHandler = () => {
    if (backUrl) {
      return navigate(backUrl);
    }
    navigate(-1);
  };

  const backButtonVisibility = !BackButtonNotVisibleScreens.includes(pathname);
  const cherryIconVisibility = LogoVisibleScreens.includes(pathname);
  const helpButtonVisibility = HelpButtonVisibleScreens.includes(pathname);

  const helpMeToggle = () => {
    setHelpMe(!helpMe);
  };

  const closeHelpMe = () => {
    setHelpMe(false);
  };

  const onEmailPressed = (e) => {
    window.location.href = 'mailto:support@withcherry.com';
    e.preventDefault();
  };

  const onCallPressed = (e) => {
    window.open('tel:8888397171', '_self');
    e.preventDefault();
  };

  const logoutPressed = () => {
    StorageService.clearUserData();
    navigate('/login');
  };

  return (
    <Container isDemo={isSnackbarVisible}>
      {isSnackbarVisible && <DemoContainer>{activeModesText}</DemoContainer>}
      <InnerContainer isDemo={isSnackbarVisible}>
        <Banner />
        <HeaderContainer>
          <IconContainer>
            {cherryIconVisibility && <CherryLogo src={images?.cherryIconDark} />}
            {backButtonVisibility && (
              <IconButton aria-label="back" color="inherit" onClick={goBackHandler}>
                <BackIcon src={'/icon/short_left.svg'} />
                {/* //TODO :BAZI SAYFALARDA CHERRY ICON BAZILARINDA BACK VAR HANDLE EDELIM */}
              </IconButton>
            )}
          </IconContainer>
          <HeaderTitle>{pageTitle}</HeaderTitle>
          <IconContainer onClick={helpMeToggle}>
            {helpButtonVisibility && (
              <IconButton aria-label="back" color="inherit">
                <HelpIcon src={'/icon/help_circle.svg'} />
              </IconButton>
            )}
          </IconContainer>
        </HeaderContainer>
        <HelpMeModal
          show={helpMe}
          hideModal={closeHelpMe}
          emailPressed={onEmailPressed}
          callPressed={onCallPressed}
          logoutPressed={logoutPressed}
        />
      </InnerContainer>
    </Container>
  );
};
const Container = styled.div<{ isDemo?: boolean }>`
  width: 100%;
  z-index: 100;
  height: ${(props) => (props?.isDemo ? '108px' : '64px')};
`;

const InnerContainer = styled.div<{ isDemo?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.main.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0px -1px 0px #dadada;
  width: 100%;
  height: 80px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin-top: ${(props) => (props?.isDemo ? '42px' : '0px')};
    height: 64px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 48px));
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 25px;
  justify-content: center;
  align-items: center;
`;

const HelpIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 25px;
    height: 25px;
  }
`;

const BackIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 23px;
    height: 23px;
  }
`;

const CherryLogo = styled.img`
  width: 26px;
  height: 26px;
`;

const DemoContainer = styled.div`
  text-align: center;
  background-color: #e6cb72;
  padding: 10px 0;
  font-weight: bold;
  position: fixed;
  z-index: 9999999;
  width: 100%;
`;
