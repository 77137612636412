import { useApolloClient, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { Button as CherryButton } from '@frontend/cherry-library';
import { CREATE_MINIMUM_APPROVAL, UPDATE_MINIMUM_APPROVAL } from 'lib/graphql/mutations';
import { FETCH_ORGANIZATION_MINIMUM_APPROVAL_AMOUNT } from 'lib/graphql/queries';

import { CurrencyInput } from '@frontend/cherry-library';
import { DashCard, DashComponent, FixedDecimalFormat, Loading } from 'lib/components';
import { AlertDialog, CherryDialogContent, CherryDialogContentText, CherryDialogTitle } from 'lib/components/Dialogue';
import { Icon } from 'lib/components/mobile';
import { FeatureNames } from 'lib/hooks';
import useAgent from 'lib/hooks/useAgent';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import { formatDate } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';

export const MinimumApprovalAmountLimit = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const { features, organization } = useStore();
  const { isAgent } = useAgent();
  const [createMinimumApproval, { loading: createLoading }] = useMutation(CREATE_MINIMUM_APPROVAL);
  const [updateMinimumApproval, { loading: updateLoading }] = useMutation(UPDATE_MINIMUM_APPROVAL);
  const [loading, setLoading] = useState<boolean>(false);
  const [minimumApprovalAmount, setMinimumApprovalAmount] = useState<number | null>(null);

  const [isApprovalLocked, setIsApprovalLocked] = useState<boolean>(false);
  const [approvalAmount, setApprovalAmount] = useState<number | null>(1000);
  const [isEditApprovalAmount, setIsEditApprovalAmount] = useState<boolean>(false);
  const [approvalAmountError, setApprovalAmountError] = useState<null | string>(null);
  const [approvalDate, setApprovalDate] = useState<string | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const handleApprovalAmountEdit = () => {
    setIsEditApprovalAmount(true);
  };

  const handleApprovalAmountCancel = () => {
    getOrganizationMinimumApprovalAmount?.();
    setIsEditApprovalAmount(false);
  };

  const onApprovalAmountChange = (event) => {
    if (minimumApprovalAmount) {
      if (Number(event?.target?.value) <= minimumApprovalAmount) {
        setApprovalAmount(event?.target?.value);
        setApprovalAmountError(null);
      } else {
        setApprovalAmountError(
          `Min value $0 and Max value ${CurrencyUtil.toCurrencyString(Number(minimumApprovalAmount))}`,
        );
      }
    }
  };

  const handleApprovalAmountSave = () => {
    setOpen(true);
  };

  const onAgreeClicked = async () => {
    if (isEditApprovalAmount && approvalAmount) {
      if (!approvalDate) {
        postMinimumApproval?.();
      } else {
        patchMinimumApproval?.();
      }
    } else {
      setOpen(false);
    }
  };

  const onDisagreeClicked = async () => {
    setOpen(false);
  };

  const getOrganizationMinimumApprovalAmount = async () => {
    setLoading(true);
    try {
      const {
        data: { fetchOrganizationMinimumApprovalAmount },
      } = await client.query({
        query: FETCH_ORGANIZATION_MINIMUM_APPROVAL_AMOUNT,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });
      if (fetchOrganizationMinimumApprovalAmount) {
        const { amountCeiling, isUpdatable, updatableAt, amount } = fetchOrganizationMinimumApprovalAmount || {};
        setMinimumApprovalAmount(amountCeiling);
        setApprovalAmount(amount ?? 0);
        setIsApprovalLocked(isUpdatable !== null ? !isUpdatable : false);
        setApprovalDate(updatableAt);
      } else {
        throw new Error();
      }
    } catch (e) {
      alert.error('Organization Minimum Approval Amount Fetch Error');
    }
    setLoading(false);
  };

  const postMinimumApproval = async () => {
    try {
      const { data } = await createMinimumApproval({
        variables: {
          input: {
            organizationId: organization?.id,
            amount: parseFloat(approvalAmount!?.toString()),
          },
        },
      });
      if (data?.createMinimumApproval?.id) {
        onDisagreeClicked?.();
        handleApprovalAmountCancel?.();
      } else {
        throw new Error();
      }
    } catch (error) {
      onDisagreeClicked?.();
      handleApprovalAmountCancel?.();
      alert.error('Minimum Approval Create Error');
    }
  };

  const patchMinimumApproval = async () => {
    try {
      const { data } = await updateMinimumApproval({
        variables: {
          input: {
            organizationId: organization?.id,
            amount: parseFloat(approvalAmount!?.toString()),
          },
        },
      });
      if (data?.updateMinimumApproval?.id) {
        onDisagreeClicked?.();
        handleApprovalAmountCancel?.();
      } else {
        throw new Error();
      }
    } catch (error) {
      onDisagreeClicked?.();
      handleApprovalAmountCancel?.();
      alert.error('Minimum Approval Update Error');
    }
  };

  useEffect(() => {
    getOrganizationMinimumApprovalAmount?.();
  }, []);

  const isMinApprovalFeatureActive = features?.includes(FeatureNames.MINIMUM_APPROVAL_AMOUNT);
  if (!isMinApprovalFeatureActive) {
    return null;
  }

  const ConfirmText = () => {
    return (
      <>
        <CherryDialogTitle id="approval-amount-alert-dialog-title">Save Changes</CherryDialogTitle>
        <CherryDialogContent>
          <CherryDialogContentText id="approval-amount-alert-dialog-description">
            <DialogContentContainer>
              <TextContainer>
                Minimum approval amount is an organization-level setting that applies to all of your locations. This
                change will only affect applications moving forward and not any existing applications.
              </TextContainer>
              <LockContainer>
                <Icon src={'warning-bold'} width={16} height={16} />
                <div>
                  <label>
                    Adjusting minimum approval amount will be locked for <strong>7 days</strong> due to fair lending
                    compliance reasons. You will not be able to make any changes during this time.
                  </label>
                </div>
              </LockContainer>
            </DialogContentContainer>
          </CherryDialogContentText>
        </CherryDialogContent>
      </>
    );
  };

  return (
    <Column margin="0" id="settings-minimum-approval-amount-component">
      <DashComponent dashHeader={'Minimum Approval Amount'} />
      <DashCard>
        {loading ? <Loading size={50} /> : null}

        <Grid container={true} spacing={3}>
          <Grid item={true} style={{ width: '100%', opacity: (!isAgent && isApprovalLocked) || loading ? 0.5 : 1 }}>
            <Row fontSize={'14px'} fontWeight={'700'} marginBottom="8px">
              Minimum Approval Amount
            </Row>
            <Row fontSize={'14px'} marginBottom="16px">
              Patients will be denied if their approval amount is lower than the minimum amount you’ve set.
            </Row>
            <Row fontSize={'14px'} fontWeight={'700'} marginBottom="8px">
              Your Minimum Approval Amount:
              {!isEditApprovalAmount ? (
                <>
                  {approvalAmount && approvalAmount > 0 ? (
                    <ColorText isLocked={(!isAgent && isApprovalLocked) || loading}>
                      <FixedDecimalFormat amount={approvalAmount || 0} prefix={'$'} />
                    </ColorText>
                  ) : (
                    <NotSetText>&nbsp;None Set</NotSetText>
                  )}
                </>
              ) : (
                <Row marginTop="8px">
                  <FormControl fullWidth={true}>
                    <CurrencyInput
                      id="minimum-approval-amount-input"
                      onChange={onApprovalAmountChange}
                      error={!!approvalAmountError}
                      errorText={approvalAmountError || undefined}
                    />
                  </FormControl>
                </Row>
              )}
            </Row>
          </Grid>

          {!isAgent && isApprovalLocked ? (
            <Grid item={true} style={{ padding: '0 12px', width: '100%' }}>
              <Row fontSize={'14px'} marginBottom="8px">
                <LockContainer>
                  <Icon src={'lock-secure'} width={16} height={16} />
                  <div>
                    <span>Approval Changes Locked</span>
                    <label>
                      Due to fair lending compliance reasons, these changes are locked until{' '}
                      {approvalDate ? formatDate(approvalDate) : null}.
                    </label>
                  </div>
                </LockContainer>
              </Row>
            </Grid>
          ) : (
            <Grid item={true} style={{ padding: '0 12px' }}>
              <Row fontSize={'14px'} marginBottom="8px">
                {isEditApprovalAmount ? (
                  <>
                    <CloseButton variant="secondary" onClick={handleApprovalAmountCancel} data-testid="cancel">
                      Cancel
                    </CloseButton>
                    <SaveButton
                      variant="primary"
                      disabled={!!approvalAmountError || !approvalAmount || loading}
                      data-testid="save-changed"
                      onClick={handleApprovalAmountSave}
                    >
                      Save Changes
                    </SaveButton>
                  </>
                ) : (
                  <EditApprovalButton
                    variant="primary"
                    disabled={isEditApprovalAmount || loading}
                    onClick={handleApprovalAmountEdit}
                    data-testid="edit-minimum-approval"
                  >
                    Edit Minimum Approval
                  </EditApprovalButton>
                )}
              </Row>
            </Grid>
          )}
        </Grid>
      </DashCard>

      <AlertDialog
        maxWidth={'sm'}
        open={open}
        agreeClicked={onAgreeClicked}
        onDisagreeClicked={onDisagreeClicked}
        children={<ConfirmText />}
        agreeText={'Save Changes'}
        declineText={'Cancel'}
        isLoading={createLoading || updateLoading}
      />
    </Column>
  );
};

const ColorText = styled.span<{ isLocked: boolean }>`
  color: ${(props) => (props?.isLocked ? '#000' : props.theme.main.green)};
  margin-left: 8px;
`;

const LockContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #879097;
  background: #f2f4f5;
  gap: 8px;

  > div {
    display: flex;
    flex-direction: column;

    > span,
    > label {
      color: #0e202f;
      font-size: 14px;
      font-style: italic;
      line-height: normal;
      width: 100%;
      font-weight: 700;
    }

    > label {
      font-weight: 400;
    }
  }
`;

const DialogContentContainer = styled.div`
  margin-top: 24px;
`;

const TextContainer = styled.div`
  text-align: center;
  padding-bottom: 24px;
`;

const NotSetText = styled.span`
  font-weight: 400;
`;

const EditApprovalButton = styled(CherryButton)`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const CloseButton = styled(CherryButton)`
  width: 100px;
  margin-right: 8px;
`;

const SaveButton = styled(CherryButton)`
  width: 200px;
`;
