import { ButtonProps, useButton } from '@mui/base';

import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { theme } from 'config/theme';
import { Icon } from 'lib/components/mobile';
import React from 'react';
import styled from 'styled-components';

const CustomCircularProgress = styled(CircularProgress)`
  margin-left: 10px;
  position: absolute;
  right: 50px;
  margin-top: 3px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  padding-top: 1px;
`;

const CustomButtonRoot = styled<ButtonProps | any>('button')`
  display: flex;

  position: relative;
  background-color: ${(props) => (props.color ? props.color : props.secondary ? theme.main.white : theme.main.green)};
  width: ${(props) => (props?.block ? '100%' : '250px')};
  padding: 15px;
  border-radius: 4px;
  color: ${(props) => (props.secondary ? theme.main.midnightBlue : theme.main.white)};
  border-color: ${(props) => (props.secondary ? '#56636D !important' : `${theme.main.midnightBlue} !important`)};
  font-weight: 600;
  font-family: 'Open Sans', sans-serif !important;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '18px')};
  transition: all 200ms ease;
  cursor: pointer;
  border: ${(props) =>
    props.secondary
      ? '1px solid #56636D !important'
      : `1px solid ${props?.color ? props.color : theme.main.green} !important`};
  margin-bottom: 10px;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'inherit')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};

  &:hover {
    // background-color: #0059b2;
  }

  &.active {
    // background-color: #004386;
  }

  &.focusVisible {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.disabled {
    background-color: #dadada;
    opacity: 0.8;
    cursor: unset;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
    color: #9fa6ac;
    border: 1px solid #dedede !important;
  }
`;

export const Button = React.forwardRef(function CustomButton(props: ButtonProps | any, ref: React.ForwardedRef<any>) {
  const {
    children,
    loading,
    color,
    block = true,
    secondary = false,
    icon = false,
    endIcon = null,
    alignItems = null,
    justifyContent = null,
    fontSize,
  } = props;
  const { active, focusVisible, getRootProps } = useButton({
    ...props,
    ref,
    component: CustomButtonRoot,
  });

  return (
    <CustomButtonRoot
      {...getRootProps()}
      className={clsx({
        active,
        focusVisible,
        disabled: props.disabled,
      })}
      type="submit"
      data-testid="submit"
      color={color}
      block={block}
      secondary={secondary}
      justifyContent={justifyContent}
      alignItems={alignItems}
      fontSize={fontSize}
    >
      {icon && (
        <IconContainer>
          <Icon width={18} height={18} src={icon} />
        </IconContainer>
      )}
      {children}
      {loading && <CustomCircularProgress size={20} color={'inherit'} />}
      {endIcon && (
        <IconContainer>
          <Icon width={9} height={9} src={endIcon} />
        </IconContainer>
      )}
    </CustomButtonRoot>
  );
});
