import { Button } from '@frontend/cherry-library';
import { CHART_ENUM } from 'lib/constants';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../mobile';

interface Props {
  header: string;
  children: React.ReactNode;
  id: CHART_ENUM;
  onDetailClick: (id: CHART_ENUM) => void;
  buttonText?: string;
  buttonIcon?: string;
  width?: string;
  centered?: boolean;
}
export const SummaryChartContainer = ({
  header,
  children,
  id,
  onDetailClick,
  buttonText = 'Detail View',
  buttonIcon = 'chart',
  width,
  centered = false,
}: Props) => {
  const handleDetailClick = () => {
    onDetailClick(id);
  };

  return (
    <ChartContainer key={id} width={width}>
      <ChartHeader>
        <Title>{header}</Title>
        <Button variant="secondary" size="small" onClick={handleDetailClick}>
          <span>{buttonText} &nbsp;</span>
          <Icon width={18} height={18} src={buttonIcon} hover={true} />
        </Button>
      </ChartHeader>
      <Chart centered={centered}>{children}</Chart>
    </ChartContainer>
  );
};

const ChartContainer = styled.div<{ width: string | undefined }>`
  padding: 16px 0px;
  width: ${(props) => props.width || '49%'};
  gap: 16px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const ChartHeader = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
`;

const Chart = styled.div<{ centered: boolean }>`
  margin-top: 18px;
  ${(props) =>
    props.centered &&
    `
  display: flex;
  justify-content: center;
  `}
`;
