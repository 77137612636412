import { gql } from '@apollo/client/core';

export const SEND_APPLICATION_LINK = gql`
  mutation sendOrganizationLink($input: OrganizationSendLinkInput) {
    sendOrganizationLink(input: $input) {
      code
      message
      success
    }
  }
`;

export const SEND_APPLY_CHECKOUT_LINK = gql`
  mutation sendApplyCheckoutLink($input: SendApplyCheckoutLinkInput) {
    sendApplyCheckoutLink(input: $input) {
      code
      message
      success
    }
  }
`;

export const SEND_APPLICATION_LINK_EMAIL = gql`
  mutation sendOrganizationLinkEmail($input: OrganizationSendLinkEmailInput) {
    sendOrganizationLinkEmail(input: $input) {
      code
      message
      success
    }
  }
`;

export const SEND_INCREASE_APPROVAL_AMOUNT_LINK = gql`
  mutation sendIncreaseApprovalAmountLink($input: SendIncreaseApprovalAmountLinkRequest) {
    sendIncreaseApprovalAmountLink(input: $input) {
      success
    }
  }
`;
