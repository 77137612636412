import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { createRawSearch } from 'lib/graphql/searches/rawSearch';

export const PointOfSaleLinksSearch = (organization: any, value?: string, filterValue?: string) => {
  const searchParts = [
    {
      key: 'organizationId',
      value: organization.id,
      operator: GenericSearchOperators.EQUAL,
    },
  ];

  if (value) {
    searchParts.push({
      key: 'raw',
      value: createRawSearch(value),
      operator: GenericSearchOperators.EQUAL,
    });
  }

  if (filterValue) {
    searchParts.push({
      key: 'status',
      value: filterValue === 'all' ? '' : filterValue,
      operator: GenericSearchOperators.EQUAL,
    });
  }
  return searchParts;
};
