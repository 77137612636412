import { Body, Button, Heading } from '@frontend/cherry-library';
import { ReactComponent as IncorrectInfoIcon } from 'assets/images/warning_outline.svg';
import useStore from 'lib/hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { BodyWrapper, ButtonWrapper, ColumnBox, TitleWrapper } from '../styles';

interface IncorrectInforops {
  setStep: (string) => void;
}
const IncorrectInfo = ({ setStep }: IncorrectInforops) => {
  const navigate = useNavigate();
  const { applyWithPatientFormData } = useStore();

  const returnToDashboard = () => {
    navigate('/dashboard');
  };

  const returnToApplication = () => {
    setStep('application');
  };
  return (
    <ColumnBox>
      <TitleWrapper>
        <IncorrectInfoIcon width={32} height={32} id="apply-with-patient-denial-icon" />
        <Heading text="Entered information was incorrect" level="3" />
      </TitleWrapper>
      <BodyWrapper>
        <Body>
          {applyWithPatientFormData?.firstName} {applyWithPatientFormData?.lastName} told us that the information you
          entered for their application was incorrect. As a result, no information was submitted.
        </Body>
      </BodyWrapper>
      <BodyWrapper>
        <Body>
          If the patient wants to try applying on your desktop again, you can start another application for them below.
        </Body>
      </BodyWrapper>
      <ButtonWrapper>
        <Button variant="secondary" id="apply-with-patient-incorrectInfo-returHome" onClick={returnToDashboard}>
          Return Home
        </Button>
        <Button id="apply-with-patient-incorrectInfo-restart" onClick={returnToApplication}>
          Restart Application
        </Button>
      </ButtonWrapper>
    </ColumnBox>
  );
};
export default IncorrectInfo;
