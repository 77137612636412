import Cookies from 'js-cookie';
import { Icon } from 'lib/components/mobile';
import { BusEvent, useApplicationFilter, useEventBus } from 'lib/services';
import {
  ActionButton,
  ActionButtons,
  Card,
  CardContent,
  CardDescription,
  CardLogo,
  CardTitle,
  DarkButton,
} from 'pages/desktop/Dashboard/views/CommonComponents';
import React, { useEffect, useState } from 'react';

const expiringSoonCookieName = 'hideExpiringSoonCard';

export const ExpiringSoonWidget = () => {
  const { emitEvent } = useEventBus();
  const { getExpiringSoonApplicationsCount } = useApplicationFilter();

  const [expiringSoonCount, setExpiringSoonCount] = useState(0);
  const [hiddenExpiringSoonCard, setHiddenExpiringSoonCard] = useState(false);

  const isVisible = () => expiringSoonCount > 0 && !hiddenExpiringSoonCard;

  const searchExpiringSoon = () => {
    emitEvent(BusEvent.FILTER_EXPIRING_SOON);
  };

  const hideExpiringSoonCard = () => {
    Cookies.set(expiringSoonCookieName, 'true', { expires: 7 }); // expires in 7 days
    setHiddenExpiringSoonCard(true);
  };

  useEffect(() => {
    getExpiringSoonApplicationsCount().then((count) => {
      setExpiringSoonCount(count);
    });

    const hideExpiringSoonCardCookie = !!Cookies.get(expiringSoonCookieName);
    setHiddenExpiringSoonCard(hideExpiringSoonCardCookie);
  }, []);

  return (
    <>
      {isVisible() ? (
        <Card>
          <ActionButtons>
            <ActionButton onClick={hideExpiringSoonCard}>x</ActionButton>
          </ActionButtons>
          <CardContent>
            <CardLogo>
              <Icon src="attention" />
            </CardLogo>

            {expiringSoonCount === 1 ? (
              <CardTitle>1 patient application is expiring soon.</CardTitle>
            ) : (
              <CardTitle>{expiringSoonCount} patient applications are expiring soon.</CardTitle>
            )}
            <CardDescription>
              Applications expire after 30 days, unless your patient requests an extension. View expiring applications
              so you can follow up accordingly.
            </CardDescription>
            <div>
              <DarkButton onClick={searchExpiringSoon}>View Expiring Soon</DarkButton>
            </div>
          </CardContent>
        </Card>
      ) : (
        ''
      )}
    </>
  );
};
