import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import client from 'lib/graphql/client';
import { DashboardAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import {
  ApplicationList,
  AppointmentList,
  PatientTracker,
  PaymentEstimator,
  PurchaseAmountResponse,
  SendApplicationLink,
} from './views';

import { faCalendar, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ConfirmationDialog } from 'lib/components';
import AppointmentReminderPopover from 'lib/components/AppointmentTableComponents/AppointmentReminderPopover';
import { SCHEDULE_VIEW } from 'lib/constants';
import { GET_APPOINTMENT_SYNC_STATUS } from 'lib/graphql/queries/Appointments';
import { useSegment } from 'lib/hooks/useSegment';
import useSnackbar from 'lib/hooks/useSnackbar';
import {
  BusEvent,
  NotificationEvent,
  useApplicationFilter,
  useEventBus,
  useLastClicked,
  useNotificationCenter,
} from 'lib/services';
import { useAppointmentList } from 'lib/tables/AppointmentTable';
import { ApplicationLinkGuide } from 'pages/desktop/Dashboard/views/ApplicationLinkGuide/ApplicationLinkGuide';
import { CompleteApplication } from 'pages/desktop/Dashboard/views/CompleteApplication';
import { DentalDashboard } from './DentalDashboard';
import { CherrySteps } from './views';
import SyncButtons from './views/SyncButtons';
import { TakeHomeApproval } from './views/TakeHomeApproval';
import { ExpiringSoonWidget, MilestoneRewardsWidget } from './views/Widgets';

export interface PaymentEstimateModalInitialData {
  amount: number;
  planLength: number;
  creditScore: {
    text: string;
    value: string;
  };
  paymentCalculation?: PurchaseAmountResponse;
}

interface Props {
  onClick?: () => void;
}

interface EstimatorScoreProps {
  selected: boolean;
}

export const DASHBOARD_TOGGLE = [
  {
    id: 1,
    text: SCHEDULE_VIEW.Applications,
  },
  {
    id: 2,
    text: SCHEDULE_VIEW.Appointments,
  },
];
interface SyncStatus {
  syncedAt: string;
  syncedBy: string;
}

const Dashboard = ({ onClick }: Props) => {
  const { trackEvent } = useAnalytics({ isScreen: true });
  const { trackSegmentEvent, applicationName } = useSegment();
  const permission = usePermission();
  const { isSnackbarVisible } = useSnackbar();
  const { onEvent } = useEventBus();
  const { onNotifications } = useNotificationCenter();
  const { isLastClickedParent } = useLastClicked();

  const isTakeHomeApprovalEnabled = false;

  const { organization, user, features } = useStore();
  const [blurred, setBlurred] = useState(false);
  const [generateEstimateModalOpen, setGenerateEstimateModalOpen] = useState<boolean>(false);

  const [generateEstimateConfirmationOpen, setGenerateEstimateConfirmationOpen] = useState<boolean>(false);
  const [generateEstimateModalInitialValues, setGenerateEstimateModalInitialValues] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<string>(SCHEDULE_VIEW.Applications);
  const scheduleFlag = features?.includes(FeatureNames.SCHEDULE_VIEW);

  const { refreshTable, intervalLoading } = useApplicationFilter();
  const idOrganization = organization?.id || 0;
  const { refreshAppointments, syncStatusLoading } = useAppointmentList();
  const applicationTab = selectedTab === SCHEDULE_VIEW.Applications || !scheduleFlag;
  const [syncStatus, setSyncStatus] = useState<SyncStatus>({ syncedAt: '', syncedBy: '' });

  const isSimplifiedPracticePortalFeatureEnabled = features.includes(FeatureNames.SIMPLIFIED_PARTNER_PORTAL);
  const userId = user?.id ? `u-${user?.id}` : undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAppointmentSyncStatus();

    onEvent(BusEvent.DASHBOARD_SET_APPLICATIONS_TAB, () => {
      if (selectedTab !== SCHEDULE_VIEW.Applications) {
        setSelectedTab(SCHEDULE_VIEW.Applications);
      }
    });

    if (applicationTab) {
      onNotifications(
        [NotificationEvent.REFRESH_ALL_OTHER_PATIENTS_LIST, NotificationEvent.REFRESH_CHECKOUT_READY_PATIENTS_LIST],
        (data) => {
          if (data?.buttonClicked || !isLastClickedParent('applicationListTable')) {
            refreshTable();
          }
        },
      );
    }
  }, []);

  const getUserName = () => {
    return user?.firstName || '';
  };

  const getAppointmentSyncStatus = async () => {
    const { data } = await client.query({
      query: GET_APPOINTMENT_SYNC_STATUS,
      variables: {
        input: { organizationId: organization?.id },
      },
    });
    setSyncStatus(data?.getAppointmentSyncStatus);
    return data?.getAppointmentSyncStatus;
  };

  const onTabChange = (event) => {
    const tabName = event?.currentTarget?.value;
    setSelectedTab(tabName || SCHEDULE_VIEW.Applications);

    const eventName =
      tabName === SCHEDULE_VIEW.Appointments ? SCHEDULE_VIEW.View_Appointments : SCHEDULE_VIEW.View_Applications;
    trackSegmentEvent(eventName, {
      application: applicationName,
      userId,
    });
  };

  const handleRefresh = async () => {
    if (applicationTab) {
      await refreshTable();
    } else {
      trackSegmentEvent('Appointments Synced', {
        application: applicationName,
        userId,
      });

      await refreshAppointments();
      await getAppointmentSyncStatus();
    }
  };

  const disableEstimatorBlur = () => {
    setBlurred(false);
    trackEvent({
      action: DashboardAnalyticsEventNames.PAYMENT_ESTIMATOR_BLUR,
      label: 'DISABLED',
    });
  };

  const enableEstimatorBlur = () => {
    setBlurred(true);
    trackEvent({
      action: DashboardAnalyticsEventNames.PAYMENT_ESTIMATOR_BLUR,
      label: 'ENABLED',
    });
  };

  const openGenerateEstimateModal = () => {
    setGenerateEstimateModalOpen(true);
  };

  const onCloseGenerateEstimateModal = () => {
    setGenerateEstimateConfirmationOpen(true);
  };

  const closeGenerateEstimateConfirmationModal = () => {
    setGenerateEstimateConfirmationOpen(false);
  };

  const onConfirmClose = () => {
    setGenerateEstimateModalInitialValues(null);
    setGenerateEstimateModalOpen(false);
    closeGenerateEstimateConfirmationModal();
  };

  const setGenerateEstimateModalInitialData = (values: PaymentEstimateModalInitialData) =>
    setGenerateEstimateModalInitialValues(values);

  const shouldDisplayApplicationLinkWidget = permission(FeatureNames.TEXT_APPLICATION_LINK, ScreenNames.DASHBOARD);
  const shouldDisplayApplyWithPatient = features?.includes(FeatureNames.APPLY_WITH_PATIENT);
  const shouldDisplayPatientTracker = features?.includes(FeatureNames.PATIENT_TRACKER);

  return (
    <Container onClick={onClick} isSnackbarVisible={isSnackbarVisible}>
      <CherrySteps />
      <ApplicationLinkGuide />
      <Row>
        <Column>
          <WelcomeToUser>Welcome, {getUserName()}</WelcomeToUser>
        </Column>
      </Row>

      {isSimplifiedPracticePortalFeatureEnabled ? (
        <DentalDashboard />
      ) : (
        <>
          {!shouldDisplayPatientTracker && isTakeHomeApprovalEnabled ? <TakeHomeApproval /> : null}

          <Grid container={true} spacing={3}>
            <Grid item={true} xs={3} id="text--application">
              {shouldDisplayApplicationLinkWidget && (
                <SendApplicationLink isFullHeight={!shouldDisplayApplyWithPatient} />
              )}
              {shouldDisplayApplyWithPatient && (
                <CompleteApplication isFullHeight={!shouldDisplayApplicationLinkWidget} />
              )}
            </Grid>

            <Grid item={true} sm={9} id="payment--estimator">
              <OverlayBlur toggle={!blurred} onClick={disableEstimatorBlur} />
              <BlurredColumn onClick={enableEstimatorBlur} toggle={blurred}>
                <PaymentEstimator
                  key="app-list-estimator"
                  organizationId={idOrganization}
                  organization={organization}
                  setGenerateEstimateModalOpen={openGenerateEstimateModal}
                  setGenerateEstimateModalInitialData={setGenerateEstimateModalInitialData}
                />
                <ConfirmationDialog
                  open={generateEstimateConfirmationOpen}
                  onClose={closeGenerateEstimateConfirmationModal}
                  onConfirm={onConfirmClose}
                  title="Close Estimate Editor?"
                  content={
                    <>
                      <span>Closing this window will delete your Estimate draft.</span>
                      <span> Are you sure you're ready to close the Estimate Editor?</span>
                    </>
                  }
                  closeButtonText="Back to Editor"
                  confirmButtonText="Yes, Close Editor"
                />
                {blurred && (
                  <SensitiveInfoContainer onClick={disableEstimatorBlur} toggle={!blurred}>
                    <span>
                      We’ve blurred out sensitive info so you can show the payment estimator to your patients. Click
                      outside to return to normal.
                    </span>
                  </SensitiveInfoContainer>
                )}
              </BlurredColumn>
            </Grid>
          </Grid>

          {shouldDisplayPatientTracker && <PatientTracker />}

          <ToggleContainer>
            {scheduleFlag ? (
              <>
                <ViewToggle aria-label="small outlined button group" defaultValue={1}>
                  {DASHBOARD_TOGGLE.map((node, index) => {
                    return (
                      <ViewButton
                        value={node.text}
                        key={index}
                        onClick={onTabChange}
                        className={selectedTab === node.text ? 'selected' : ''}
                      >
                        <FontAwesomeIcon
                          icon={SCHEDULE_VIEW.Appointments === node.text ? faCalendar : faList}
                          flip={'horizontal'}
                        />
                        <ViewText selected={selectedTab === node.text}>{node.text}</ViewText>
                      </ViewButton>
                    );
                  })}
                </ViewToggle>
                <AppointmentReminderPopover />
              </>
            ) : (
              <HeaderText> Applications </HeaderText>
            )}
            <SyncButtons
              refreshTable={handleRefresh}
              loading={applicationTab ? intervalLoading : syncStatusLoading}
              applicationTab={applicationTab}
              syncStatus={syncStatus}
            />
          </ToggleContainer>
          {scheduleFlag && (
            <HeaderTextSchedule>
              {selectedTab === SCHEDULE_VIEW.Applications ? 'Applications' : 'Appointments'}
            </HeaderTextSchedule>
          )}

          <TableRow id="applicationListTable">
            <TableColumn>
              {selectedTab === SCHEDULE_VIEW.Applications || !scheduleFlag ? (
                <ApplicationList />
              ) : (
                <AppointmentList appointmentSync={syncStatus} />
              )}
              <WidgetContainer>
                <ExpiringSoonWidget />
                <MilestoneRewardsWidget />
              </WidgetContainer>
            </TableColumn>
          </TableRow>
        </>
      )}
    </Container>
  );
};

export default Dashboard;

interface BlurredColumnProps {
  toggle: boolean;
  active?: boolean;
  onClick?: () => void;
}

const OverlayBlur = styled.div<BlurredColumnProps>`
  ${(props) => {
    if (!props.toggle) {
      return `
      position:fixed;
      top: 0;
      left: 0;
      width: 100vw;
      cursor:pointer;
      height: 100vh;
      background: rgb(55, 255, 255, 0.5,0.5); 
      z-index: 8; 
      display:block;
      -webkit-transition: opacity 0.3s linear,z-index 0.3s linear;
    transition: opacity 0.3s linear,z-index 0.3s linear;
    visibility: visible;
    opacity: 1;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(10px);
    `;
    } else {
      return `
      z-index:1;
      display:none;
    `;
    }
  }}
`;

const BlurredColumn = styled.div<BlurredColumnProps>`
  height: 100%;
  ${(props) => {
    if (props.toggle) {
      return `
        z-index:8;
        position:relative;
        display:block;
        cursor:pointer;
    `;
    } else {
      return `
        z-index:1;
        cursor:pointer;
      `;
    }
  }}
`;

const SensitiveInfoContainer = styled.div<BlurredColumnProps>`
  position: absolute;
  margin-top: 20px;
  right: 150px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0px #000000;
  background-color: #ffffff;
  margin-right: 20px;

  span {
    margin-right: 20px;
  }
`;
const ViewText = styled.div<EstimatorScoreProps>`
  font-size: 18px;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: ${(props) => (props.selected ? props.theme.main.midnightBlue : props.theme.main.lightGrey)};
  text-transform: capitalize;
  margin-left: 8px;
`;
const ViewToggle = styled(ButtonGroup)`
  min-width: 450px;
  max-width: 40%;
  height: 48px;
  button {
    border: 1px solid #b7bcc1;
    width: 100%;
    white-space: nowrap;
  }
  background-color: ${(props) => props.theme.main.white};
  .selected {
    background-color: ${(props) => props.theme.main.softMint};
    span {
      color: ${(props) => props.theme.main.green};
    }
  }
`;
const ViewButton = styled(Button)`
  cursor: pointer !important;
`;

const Container = styled.div<{ isSnackbarVisible: boolean }>`
  margin-top: ${(props) => (props.isSnackbarVisible ? '190px' : '150px')};
  padding: 0 35px;
  font-size: 12px;
  margin-bottom: 150px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  box-sizing: border-box;
`;

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 27%;
  max-width: 350px;
  top: 24px;
  position: relative;
  margin-left: 27px;
`;
const WelcomeToUser = styled.h1`
  color: #0e1f2e;
  margin-top: 0;
  font-size: 28px;
  margin-bottom: 10px;
`;
const HeaderText = styled.h2`
  font-size: ${(props) => props.theme.constants.l};
  color: ${(props) => props.theme.main.textColor};
`;

const HeaderTextSchedule = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-top: 24px;
`;

const TableRow = styled(Row)`
  width: 100%;
  box-sizing: border-box;
`;

const TableColumn = styled(Column)`
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  margin-top: 24px;
`;
