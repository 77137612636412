import { gql } from '@apollo/client/core';

export const CALCULATE_PAYMENT = gql`
  query calculatePayment($input: CalculatePaymentRequest) {
    calculatePayment(input: $input) {
      apr
      financeCharge
      paymentAmount
    }
  }
`;

export const GET_PAYMENT_OPTIONS_BY_CREDIT_GRADES = gql`
  query getPaymentOptionsByCreditGrades($input: PaymentOptionsByCreditGradesRequest) {
    getPaymentOptionsByCreditGrades(input: $input) {
      data {
        creditGrade
        estimates {
          apr
          term
          financeCharge
          paymentAmount
        }
      }
    }
  }
`;

export const FETCH_TRANSACTION_LIMITS_FOR_PAYMENT_ESTIMATE = gql`
  query fetchTransactionLimits($input: FetchTransactionLimitInput) {
    fetchTransactionLimits(input: $input) {
      zeroAprTerm
      maxTerm
    }
  }
`;

export const GET_PAYMENT_ESTIMATOR_CALCULATION = gql`
  query GetPaymentEstimatorCalculation($input: GetPaymentEstimatorCalculationInput) {
    getPaymentEstimatorCalculation(input: $input) {
      apr
      financeCharge
      paymentAmount
      term
    }
  }
`;
