import { DialogProps as MuiDialogProps } from '@mui/material';
import GenericFileModal from 'pages/desktop/DocumentRequests/components/GenericFileModal/GenericFileModal';
import React from 'react';
import styled from 'styled-components';

interface ConfirmSubmissionModalProps extends MuiDialogProps {
  addDocument: () => void;
  submitDocuments: () => void;
  requiredNumberOfDocuments: number;
  currentNumberOfDocuments: number;
}

const ConfirmSubmissionModal = ({
  requiredNumberOfDocuments,
  currentNumberOfDocuments,
  submitDocuments,
  addDocument,
  ...rest
}: ConfirmSubmissionModalProps) => {
  return (
    <GenericFileModal
      {...rest}
      title="Confirm Submission"
      buttonLeftText="Add More Files"
      onButtonLeftClick={addDocument}
      mainButtonText="Submit Anyway"
      onMainButtonClick={submitDocuments}
    >
      <Text>
        {requiredNumberOfDocuments} documents were requested. You have only attached {currentNumberOfDocuments}.
      </Text>
      <Text>Are you sure you want to submit?</Text>
    </GenericFileModal>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export default ConfirmSubmissionModal;
