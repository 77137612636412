import { Ripples } from 'lib/components';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { Loading, PhoneInputIcon } from 'lib/components';
import { useSendApplicationLink } from 'lib/services';

export interface PhoneNumberInputProps {
  onBlur?: (value?: string) => void;
  onClickSend?: (value?: string) => void;
  loading?: boolean;
  defaultValue?: string;
}

export const PhoneNumberInput = ({ onBlur, onClickSend, loading, defaultValue }: PhoneNumberInputProps) => {
  const { phoneFormatValid, phoneNumber, logInputBlur, onPhoneChange, phoneNumberValid } = useSendApplicationLink();

  const sendHandler = () => {
    onClickSend?.(phoneNumber);
  };

  const blurHandler = (ev) => {
    logInputBlur(ev);
    onBlur?.(phoneNumber);
  };

  return (
    <Wrapper>
      <FormContainer>
        <CustomInputMask
          data-testid="phone-number-input"
          className={phoneFormatValid ? 'valid' : 'error'}
          mask="(999) 999-9999"
          value={phoneNumber || defaultValue}
          onChange={onPhoneChange}
          onBlur={blurHandler}
        >
          {(inputProps) => (
            <PhoneInput
              {...inputProps}
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              placeholder="(___) ___-____"
              type="tel"
            />
          )}
        </CustomInputMask>
        <AbsoluteIconWrapper>
          <PhoneInputIcon />
        </AbsoluteIconWrapper>

        <Ripples>
          <SendButton
            data-testid="submit"
            disabled={(phoneNumber && !phoneNumberValid) || !defaultValue || loading}
            onClick={sendHandler}
          >
            {loading ? <CustomLoading /> : <SendText>Send Text</SendText>}
          </SendButton>
        </Ripples>
      </FormContainer>

      <ErrorContainer>{!phoneFormatValid ? 'Please enter a valid US mobile phone number' : ''}</ErrorContainer>
    </Wrapper>
  );
};

const CustomLoading = styled(Loading)`
  width: 40px;
`;

const SendText = styled.div`
  padding: 0 5.5px;
`;

const Wrapper = styled.div`
  display: grid;
  margin-left: 24px;
  margin-top: 12px;
  font-family: 'Open Sans', sans-serif;
`;

const ErrorContainer = styled.div`
  position: relative;
  font-size: 10px;
  margin-top: 4px;
  color: #ec3360;
`;

const FormContainer = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (!props.isFullHeight ? '8px' : '32px')};
  position: relative;
  width: 328px;
`;

const PhoneInput = styled.input`
  width: 100%;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #ccc;
  border-right: none;
  padding: 8px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

const SendButton = styled.button`
  white-space: nowrap;
  background-color: ${(props) => props.theme.main.green};
  color: #fff;
  border-radius: 0px 2px 2px 0px;
  border: none;
  width: 100%;
  font-size: 16px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    background-color: #c4c4c4;
  }
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-left: -88%;
  margin-top: 5px;
`;

const CustomInputMask = styled(InputMask)`
  padding-left: 30px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;

  &.error {
    border: 1px solid #ff0000;
  }
`;
