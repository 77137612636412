import { useApolloClient } from '@apollo/client';
import { Grid } from '@mui/material';
import { DashCard, DashComponent, TableLoader } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { GET_MERCHANT_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import { useBankInformationTableColumns } from 'lib/tables';
import { MerchantGroup } from 'lib/types';
import { settingsTableStyle } from 'lib/utils';

import { BankInformationDialog } from '../components/BankInformationDialog';

export enum MERCHANT_GROUP_TYPES {
  MERCHANT = 'MERCHANT',
  MERCHANT_GROUP = 'MERCHANT_GROUP',
}

const getMerchantsFromTree = (tree: MerchantGroup) => {
  let result: any[] = [];
  if (tree.type === MERCHANT_GROUP_TYPES.MERCHANT) {
    result = [...result, tree];
  }
  if (tree.childs.length) {
    tree.childs.forEach((child) => {
      result = [...result, ...getMerchantsFromTree(child)];
    });
  }
  return result;
};

export const BankInformation = () => {
  const alert = useAlert();
  const client = useApolloClient();

  const [openBankInfoDialog, setOpenBankInfoDialog] = useState<boolean>(false);

  const { merchants } = useStore();
  const [selectedMerchant, setSelectedMerchant] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [merchantDetailList, setMerchantDetailList] = useState<any>([]);

  useEffect(() => {
    fetchMerchantDetail();
  }, []);

  const fetchMerchantDetail = async () => {
    try {
      setLoading(true);
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds: merchants?.map((m) => m.id),
          },
        },
      });

      if (getMerchantDetail?.data?.length) {
        setMerchantDetailList([...getMerchantDetail.data]);
      } else {
        setMerchantDetailList([]);
      }
    } catch (error) {
      alert.error('Merchant detail fetch error');
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (row) => {
    handleOpenInfoDialog();
    setSelectedMerchant(row);
  };

  const { tableColumns } = useBankInformationTableColumns({ handleSelected });

  const handleCloseInfoDialog = () => {
    setOpenBankInfoDialog(false);
  };

  const handleOpenInfoDialog = () => {
    setOpenBankInfoDialog(true);
  };

  return (
    <Column margin="0">
      <DashComponent dashHeader={'Bank Information'} />
      <DashCard>
        <Grid item={true} id="#banking">
          <Row fontSize={'14px'}>
            Banking information is used to deposit funds from sales at each location within 1-2 business days via an ACH
            transfer. Updating bank information may cause additional delays in deposits.
          </Row>
        </Grid>
        <FlexTop />
        <CustomDataTable
          noHeader={true}
          customStyles={settingsTableStyle}
          columns={tableColumns}
          data={merchantDetailList}
          pagination={false}
          progressPending={loading}
          progressComponent={<TableLoader count={4} />}
        />
      </DashCard>

      <BankInformationDialog
        selectedMerchant={selectedMerchant}
        open={openBankInfoDialog}
        handleClose={handleCloseInfoDialog}
        fetchMerchantDetail={fetchMerchantDetail}
      />
    </Column>
  );
};

const FlexTop = styled.div`
  margin-top: 8px;
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
`;
