export const toPhoneString = (num: string) => {
  return `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6, 10)}`;
};

export const formatPhoneNumber = (phone: string = '') => {
  if (phone) {
    phone = phone?.replace(/[^\d]/g, '');

    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return '';
};

export const clearMaskFormat = (phone: string = '') => {
  if (phone) {
    return phone?.replace(/\D/g, '');
  }

  return phone;
};
