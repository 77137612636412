import { Button, formatAmount, theme } from '@frontend/cherry-library';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ButtonPrimary, DropDown, FixedDecimalFormat, Loading, NoteIcon } from 'lib/components';
import { default as PopoverComp } from 'lib/components/Popover/Popover';

import { useApolloClient, useMutation } from '@apollo/client';
import { FormControl, Popover, TextField } from '@mui/material';
import { ReactComponent as WarningIcon } from 'assets/images/info-circle-outline.svg';
import { ReactComponent as AttentionIcon } from 'assets/images/warning_outline.svg';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ApplicationStatus } from 'lib/components/ApplicationStatutes/CustomStatus';
import { SEND_INCREASE_APPROVAL_AMOUNT_LINK } from 'lib/graphql/mutations';
import { CALCULATE_PAYMENT } from 'lib/graphql/queries';
import { FeatureNames, STATUSES } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useEventBus } from 'lib/services';
import { Row } from 'lib/styles';
import { FinancingStatusMap, Merchant } from 'lib/types';
import { formatPhoneNumber, formatTextCapitalize, isWithin24Hours, TreatmentStageOptions } from 'lib/utils';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  sendSms: (phone: string, paymentCalculationData: any, values: any) => void;
  sendEmail: (email: string, paymentCalculationData: any, values: any) => void;
}

const STAGE_TO_COLOR = {
  PATIENT_CREATED: '#fff',
  PLAN_SENT: 'rgb(231, 233, 234)',
  TREATMENT_SCHEDULED: 'rgba(249, 236, 214, 1)',
  TREATMENT_IN_PROGRESS: 'rgba(230, 241, 249, 1)',
  TREATMENT_COMPLETED: 'rgba(230, 249, 242, 1)',
  PATIENT_DECLINED: 'rgba(253, 235, 239, 1)',
  UNRESPONSIVE: 'rgba(231, 233, 234, 1)',
};

const approvedStatus = ['APPROVED'];

const HAS_PENDING_LOAN_ACTIONS = (row) => [
  {
    value: 'RESEND_CHECKOUT_LINK',
    label: 'Resend Checkout Link',
    segmentEventName: 'RESEND_CHECKOUT_LINK',
    isHidden: row?.status === STATUSES.ARCHIVED,
  },
  {
    value: 'EDIT_CANCEL_PLAN',
    label: 'Edit/Cancel Plan',
    segmentEventName: 'EDIT_CANCEL_PLAN',
    isHidden: row?.status === STATUSES.ARCHIVED,
  },
];

interface EditNoteProps {
  initialNote: string;
  onEditNote: (note: string) => void;
  segmentData?: { featureName: string; payload: Record<string, any> };
}

const EditNote = ({ initialNote, onEditNote, segmentData }: EditNoteProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [note, setNote] = useState(initialNote);
  const { trackSegmentEvent } = useSegment();

  const { featureName: SEGMENT_FEATURE_NAME, payload: segmentPayload } = segmentData || {};

  const handleEditNoteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
    if (SEGMENT_FEATURE_NAME) {
      trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.NOTES_BUTTON_CLICKED`, segmentPayload);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleSave = () => {
    onEditNote(note);
    if (SEGMENT_FEATURE_NAME) {
      trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.NOTES_SAVE_CLICKED`, { ...segmentPayload, note });
    }
  };

  const handleOnClick = () =>
    trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.NOTES_INPUT_CLICKED`, segmentPayload);

  const handleOnBlur = (event) =>
    event?.target?.value && SEGMENT_FEATURE_NAME
      ? trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.NOTES_INPUT_FILLED`, {
          ...segmentPayload,
          note: event?.target.value,
        })
      : undefined;

  return (
    <div>
      <div onClick={handleEditNoteClick}>
        <NoteIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        marginThreshold={50}
        className="popover-container"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent>
          <NoteContainer>
            <PopoverTitle>Notes</PopoverTitle>
            <FormControl fullWidth={true} margin="dense">
              <StyledTextField
                size="small"
                type="textarea"
                inputProps={{
                  'data-inputkey': 'note',
                }}
                onClick={handleOnClick}
                onBlur={handleOnBlur}
                onChange={handleChange}
                value={note}
                variant="outlined"
                minRows={6}
                multiline={true}
              />
            </FormControl>
          </NoteContainer>
          <ButtonsContainer>
            <ButtonPrimary text="Save" onClick={handleSave} />
          </ButtonsContainer>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const useDentalPatientTrackerListTable = ({ sendEmail, sendSms }: Props) => {
  const client = useApolloClient();
  const { emitEvent } = useEventBus();
  const { organization, locations, features } = useStore();
  const alert = useAlert();
  const navigate = useNavigate();
  const [sendIncreaseApprovalAmountLink] = useMutation(SEND_INCREASE_APPROVAL_AMOUNT_LINK);
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const [loading, setLoading] = useState(false);
  const [increaseApprovalLoading, setIncreaseApprovalLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const isApprovalExtensionAvailable = features.includes(FeatureNames.APPLICATION_APPROVAL_EXTENSION);

  const ACTION_OPTIONS = (row) => [
    {
      value: 'TEXT_EXTENSION_LINK',
      label: 'Text Extension Link',
      segmentEventName: 'TEXT_EXTENSION_LINK',
      isHidden:
        !isApprovalExtensionAvailable || !row?.isApplicationApprovedAndAvailable || row?.status === STATUSES.ARCHIVED,
    },
    {
      value: 'VIEW_TRANSACTION',
      label: 'View Transaction',
      segmentEventName: 'VIEW_TRANSACTION',
      isHidden: !row?.hasCompletedLoan,
    },
    {
      value: 'CHECKOUT_AGAIN',
      label: 'Checkout Again',
      segmentEventName: 'CHECKOUT_AGAIN',
      isHidden: !row?.hasCompletedLoan,
    },
    {
      value: 'VIEW_TREATMENT_PLAN',
      label: 'View Monthly Payment Options',
      segmentEventName: 'VIEW_MONTHLY_OPTIONS',
      isHidden: row?.treatmentCost === 0 || !row?.customer?.selectedLocation || row?.status === STATUSES.ARCHIVED,
    },
    {
      value: 'GET_TREATMENT_PLAN',
      label: 'Get Monthly Payment Options',
      segmentEventName: 'GET_OPTIONS',
      isHidden: row?.status === STATUSES.ARCHIVED,
    },
    {
      value: 'TEXT_APPLICATION_LINK',
      label: 'Text Cherry Application Link',
      segmentEventName: 'TEXT_APPLICATION_LINK',
      isHidden:
        !row?.customer?.phone ||
        row?.status === STATUSES.ARCHIVED ||
        approvedStatus?.includes(row?.application?.status),
    },
    {
      value: 'ARCHIVE',
      label: 'Archive',
      color: theme.main.cherryRed,
      segmentEventName: 'ARCHIVE',
      isHidden: row?.status === STATUSES.ARCHIVED,
    },
    {
      value: 'UNARCHIVE',
      label: 'Unarchive',
      segmentEventName: 'UNARCHIVE',
      isHidden: row?.status === STATUSES.ACTIVE,
    },
  ];

  const getPaymentCalculationData = async (values) => {
    const { data } = await client.query({
      query: CALCULATE_PAYMENT,
      variables: {
        input: {
          amount: Number(values.treatmentCost),
          term: 12,
          creditGrade: 'GREAT',
          organizationId: organization?.id,
        },
      },
    });

    return data;
  };

  const handleActionClick = async (data, row) => {
    let paymentCalculationData;
    switch (data.value) {
      case 'GET_TREATMENT_PLAN':
      case 'VIEW_TREATMENT_PLAN':
        emitEvent(BusEvent.OPEN_MONTHLY_PAYMENT_OPTIONS, row);
        break;
      case 'TEXT_PLAN':
        setLoading(true);
        paymentCalculationData = await getPaymentCalculationData(row);
        await sendSms(row.customer?.phone, paymentCalculationData, row);
        setLoading(false);
        break;
      case 'EMAIL_PLAN':
        setLoading(true);
        paymentCalculationData = await getPaymentCalculationData(row);
        await sendEmail(row.customer?.email, paymentCalculationData, row);
        setLoading(false);
        break;
      case 'CHECKOUT_AGAIN':
        row.openCheckoutModal();
        break;
      case 'TEXT_APPLICATION_LINK':
        setLoading(true);
        await row.sendApplicationLink();
        setLoading(false);
        break;
      case 'ARCHIVE':
        row.changeStatus('ARCHIVED');
        break;
      case 'UNARCHIVE':
        row.changeStatus('ACTIVE');
        break;
      case 'EDIT_CANCEL_PLAN':
        row.openEditCancelPlan();
        break;
      case 'RESEND_CHECKOUT_LINK':
        row.resendCheckoutLink();
        break;
      case 'VIEW_TRANSACTION':
        navigate('/transactions', {
          state: {
            displayId: row.application?.activeLoan?.displayId,
          },
        });
        break;
      case 'TEXT_EXTENSION_LINK':
        row.openExtendApprovalModal();
        break;
      default:
        break;
    }
  };

  const dropdownRow = (row, featureName) => {
    const NewDropDown = DropDown;
    const options = row?.hasPendingLoan
      ? HAS_PENDING_LOAN_ACTIONS(row)?.filter((option) => !option.isHidden)
      : ACTION_OPTIONS(row).filter((option) => !option.isHidden);

    const segmentPayload = {
      applicationId: row.applicationId,
      borrowerId: row.id,
    };

    const handleOptionsSegmentEvent = () => {
      trackSegmentEvent(`PRACTICE_PORTAL.${featureName}.THREE_DOTS_CLICKED`, segmentPayload);
    };

    const handleDropdownSelection = (data) => {
      trackSegmentEvent(`PRACTICE_PORTAL.${featureName}.THREE_DOTS_${data.segmentEventName}_CLICKED`, segmentPayload);
      handleActionClick(data, row);
    };

    return (
      <DropdownContainer onClick={handleOptionsSegmentEvent}>
        <NewDropDown
          id={`drop-${row.id}`}
          options={options}
          onChange={handleDropdownSelection}
          showAllItem={true}
          customSelector={true}
          hoveredMode={false}
          isExpiringSoon={
            row?.isExpiringSoon &&
            isApprovalExtensionAvailable &&
            !row?.hasPendingLoan &&
            row?.status !== STATUSES.ARCHIVED
          }
        />
      </DropdownContainer>
    );
  };

  const getCTARow = (row) => {
    return (
      <DropdownContainer color={STAGE_TO_COLOR[row.stage]}>
        <DropDown
          hoveredMode={false}
          id={`drop-${row.id}-stage`}
          options={TreatmentStageOptions}
          textColor={'#0E202F'}
          onChange={row.onStageChange}
          defaultValue={row.stage}
          showAllItem={true}
          fontSize="12px"
          bypassSchedule={true}
          disabled={row.status !== STATUSES.ACTIVE}
        />
      </DropdownContainer>
    );
  };

  const getFinancingRow = (row, isCheckoutReady: boolean) => {
    if (row.isApplicationExpired) {
      return 'Expired';
    } else if (
      row?.financingStatus === 'PREAPPROVED' &&
      row?.preApproval?.minimumAmount &&
      row?.preApproval?.maximumAmount
    ) {
      return (
        <PreApprovalAmount>
          {'Pre-Approved: '}
          <span>
            <FixedDecimalFormat amount={Number(row?.preApproval?.minimumAmount)} prefix={'$'} />
            {` - `}
            <FixedDecimalFormat amount={Number(row?.preApproval?.maximumAmount)} prefix={'$'} />
          </span>
        </PreApprovalAmount>
      );
    } else if (row.financingStatus === 'FINANCED' || row.financingStatus === 'APPROVED') {
      const handleSendIncreaseApprovalAmountLink = async () => {
        try {
          trackSegmentEvent(
            `PRACTICE_PORTAL.${
              isCheckoutReady ? 'CHECKOUT_READY' : 'ALL_OTHER_PATIENTS'
            }.REQUEST_INCREASE_LINK_CLICKED`,
            {
              borrowerId: row?.application?.borrowerId,
              applicationId: row?.applicationId,
              ...row?.customer,
              treatmentStage: row?.stage,
              notes: row?.note,
              expirationDate: dayjs(row.application.expireAt).format('M/D/YY [a]t hh:mm A'),
              createdDate: dayjs(row.application.createdAt).format('M/D/YY [a]t hh:mm A'),
            },
          );
          setIncreaseApprovalLoading(true);
          setSelectedRow(row.id);
          const { data } = await sendIncreaseApprovalAmountLink({
            variables: {
              input: {
                applicationId: row.applicationId,
              },
            },
          });

          if (data.sendIncreaseApprovalAmountLink.success) {
            alert.success('Patient texted successfully');
          }
        } catch (error) {
          alert.error('Something went wrong, try again');
        } finally {
          setIncreaseApprovalLoading(false);
        }
      };

      return (
        <div>
          <AvailableAmount hasPendingLoan={row?.hasPendingLoan}>
            {'Available: '}
            <span>
              <FixedDecimalFormat amount={row.application?.balanceAvailable} prefix={'$'} />
            </span>
          </AvailableAmount>
          <Row justifyContent="space-between">
            {row.application?.creditLineIncrease === 'ELIGIBLE' && (
              <>
                <CherryLink onClick={handleSendIncreaseApprovalAmountLink}>Request an Increase</CherryLink>
                {increaseApprovalLoading && selectedRow === row.id ? <Loading /> : null}
              </>
            )}
          </Row>
        </div>
      );
    }

    const isPreApproveDenied = row.preApproval?.status === 'DENIED';

    return (
      <div>
        <div>{FinancingStatusMap[row?.financingStatus] || formatTextCapitalize(row?.financingStatus)}</div>
        <div>{isPreApproveDenied ? 'Unable to Pre-Approve' : null}</div>
      </div>
    );
  };

  const getNoteEditRow = (row, featureName) => (
    <EditNote
      initialNote={row.note}
      onEditNote={row.onNoteChange}
      segmentData={
        featureName
          ? {
              featureName,
              payload: { applicationId: row.applicationId, borrowerId: row.id },
            }
          : undefined
      }
    />
  );

  const hasMultipleLocations = locations && locations.length > 1;

  const PatientColumn = {
    name: 'PATIENT',
    selector: (row) => row.customer.name,
    selectorString: 'customer.name',
    cell: (row) => (
      <TextContainer>
        {row.status === 'ARCHIVED' ? <b>Archived</b> : null}
        <Text>
          {row?.customer?.firstName} {row?.customer?.lastName}
        </Text>
        <SmallText>{formatPhoneNumber(row?.customer?.phone)}</SmallText>
      </TextContainer>
    ),
    maxWidth: '14%',
    minWidth: '14%',
    ignoreRowClick: true,
    sortable: true,
  };

  const renderExpirationText = (remainingDays, isExpiringSoon) => {
    const dayText = remainingDays === 1 ? 'day' : 'days';
    if (remainingDays > 0) {
      return (
        <ExpiresText isExpiringSoon={isExpiringSoon}>
          Expires: {remainingDays} {dayText}
        </ExpiresText>
      );
    } else if (remainingDays === 0) {
      return <ExpiresText isExpiringSoon={isExpiringSoon}>Expires today</ExpiresText>;
    }
    return null;
  };

  const CreatedColumn = {
    name: 'CREATED',
    selector: (row) => row.application.createdAt,
    selectorString: 'application.createdAt',
    sortable: true,
    cell: (row) => {
      const remainingDays = row?.daysUntilExpiration;
      const isExpiringSoon = row?.isExpiringSoon;
      const expireAt = row?.application?.expireAt;

      return (
        <div>
          <CreatedAtDateText>
            {dayjs(row?.application?.createdAt || row.createdAt).format('MMM DD YYYY')}
          </CreatedAtDateText>
          {expireAt && renderExpirationText(remainingDays, isExpiringSoon)}
        </div>
      );
    },
    maxWidth: hasMultipleLocations ? '10%' : '15%',
    minWidth: hasMultipleLocations ? '10%' : '15%',
    ignoreRowClick: true,
  };

  const LocationPreferenceColumn = {
    name: 'LOCATION PREFERENCE',
    selector: (row) => row.merchant.name,
    selectorString: 'merchant.name',
    cell: (row) => {
      const findMerchantName = locations?.find(
        (merchant: Merchant) => merchant?.id === row?.application?.merchantId?.toString(),
      );
      return findMerchantName?.name || '';
    },
    maxWidth: '15%',
    minWidth: '15%',
    ignoreRowClick: true,
    sortable: true,
  };

  const TreatmentStageColumn = {
    name: 'TREATMENT STAGE',
    selector: (row) => row.stage,
    selectorString: 'stage',
    sortable: true,
    cell: (row) => {
      return getCTARow(row);
    },
    minWidth: hasMultipleLocations ? '15%' : '18%',
    maxWidth: hasMultipleLocations ? '15%' : '18%',
    ignoreRowClick: true,
  };

  const NotesColumn = {
    name: 'Notes',
    selector: (row) => row.note,
    selectorString: 'note',
    minWidth: '10%',
    maxWidth: '10%',
    cell: (row) => {
      return <div>{row?.note?.substring(0, 60) + (row?.note.length > 60 ? '...' : '')}</div>;
    },
    ignoreRowClick: true,
    sortable: true,
  };

  const checkoutReadyPatientTable = [
    {},
    PatientColumn,
    CreatedColumn,
    ...(hasMultipleLocations ? [LocationPreferenceColumn] : []),
    {
      name: 'APPLICATION STATUS',
      selector: (row) => row.application.balanceAvailable,
      selectorString: 'application.balanceAvailable',
      sortable: true,
      cell: (row) => {
        return getFinancingRow(row, true);
      },
      minWidth: hasMultipleLocations ? '15%' : '18%',
      maxWidth: hasMultipleLocations ? '15%' : '18%',
      ignoreRowClick: true,
    },
    TreatmentStageColumn,
    NotesColumn,
    {
      name: '',
      selector: (row) => row.notes,
      cell: (row) => getNoteEditRow(row, 'CHECKOUT_READY'),
      minWidth: hasMultipleLocations ? '3%' : '5%',
      maxWidth: hasMultipleLocations ? '3%' : '5%',
      ignoreRowClick: true,
    },

    {
      name: '',
      selector: (row) => row.checkout,
      cell: (row) => {
        if (row?.hasPendingLoan) {
          return (
            <ApplicationStatus
              icon={<AttentionIcon height={16} width={16} />}
              size="small"
              tooltipBackground={theme.main.midnightBlue}
              label="Checkout in Progress"
              background="#E6F1F9"
              tooltipText={
                <div>
                  <b>{formatAmount(row.hasPendingLoan?.purchaseAmount)} Checkout In Progress</b>
                  <br />
                  <br />
                  <span>
                    A checkout link has been sent to the patient. They need to pick a payment plan and make a down
                    payment. To resend the checkout link, or to edit or cancel this checkout, click the 3-dot menu [...]
                    to the right.
                  </span>
                </div>
              }
            />
          );
        }
        if (row?.hasCompletedLoan) {
          return !row?.hasCompletedLoan?.fundedAt || isWithin24Hours(row?.hasCompletedLoan?.fundedAt) ? (
            <ApplicationStatus
              icon={<WarningIcon height={16} width={16} />}
              size="medium"
              tooltipBackground={theme.main.midnightBlue}
              label="Transaction Complete"
              background={theme.main.kellyGreen10}
              tooltipText={
                <div>
                  <b>Transaction Complete</b>
                  <br />
                  <br />
                  <span>
                    {row?.customer?.firstName} has completed their checkout. You can view the transaction details or
                    issue another checkout from the menu to the right.
                  </span>
                </div>
              }
            />
          ) : (
            <ActionButtonContainer>
              <Button
                variant="secondary"
                fullWidth={true}
                onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT_READY.NOTES_CHECKOUT_AGAIN_CLICKED', {
                  cb: row.openCheckoutModal,
                })}
              >
                Checkout Again
              </Button>
            </ActionButtonContainer>
          );
        }

        return (
          <ActionButtonContainer>
            <Button
              fullWidth={true}
              onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT_READY.NOTES_CHECKOUT_CLICKED', {
                cb: row.openCheckoutModal,
              })}
            >
              Checkout
            </Button>
          </ActionButtonContainer>
        );
      },
      minWidth: '15%',
      maxWidth: '15%',
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: (row) => dropdownRow(row, 'CHECKOUT_READY'),
      minWidth: hasMultipleLocations ? '3%' : '5%',
      maxWidth: hasMultipleLocations ? '3%' : '5%',
      ignoreRowClick: true,
      right: true,
    },
  ];

  const allPatientsTable = [
    {},
    PatientColumn,
    CreatedColumn,
    ...(hasMultipleLocations ? [LocationPreferenceColumn] : []),
    {
      name: 'APPLICATION STATUS',
      selector: (row) => row.financingStatus,
      cell: (row) => {
        return getFinancingRow(row, false);
      },
      minWidth: hasMultipleLocations ? '15%' : '18%',
      maxWidth: hasMultipleLocations ? '15%' : '18%',
      ignoreRowClick: true,
    },
    TreatmentStageColumn,
    NotesColumn,
    {
      name: '',
      selector: (row) => row.notes,
      cell: (row) => getNoteEditRow(row, 'ALL_OTHER_PATIENTS'),
      minWidth: hasMultipleLocations ? '3%' : '5%',
      maxWidth: hasMultipleLocations ? '3%' : '5%',
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.textApplication,
      cell: (row) => {
        if (row.isApplicationExpired && row?.customer?.phone && row?.status !== STATUSES.ARCHIVED) {
          return (
            <ActionButtonContainer>
              <Button
                variant="secondary"
                fullWidth={true}
                onClick={segmentEventHandler('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.TEXT_APPLICATION_CLICKED', {
                  cb: row.sendApplicationLink,
                })}
              >
                Text Application
              </Button>
            </ActionButtonContainer>
          );
        }
      },
      minWidth: '15%',
      maxWidth: '15%',
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: (row) => dropdownRow(row, 'ALL_OTHER_PATIENTS'),
      minWidth: hasMultipleLocations ? '3%' : '5%',
      maxWidth: hasMultipleLocations ? '3%' : '5%',
      ignoreRowClick: true,
      right: true,
    },
  ];

  return { checkoutReadyPatientTable, allPatientsTable, loading };
};

export const CherryLink = styled.a`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
`;

const ExpireTooltip = styled.div`
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  line-height: 16px;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
`;

const TextContainer = styled.div`
  padding: 15px 8px;
  margin: 4px 0;
`;

const DropdownContainer = styled.div<{ color?: string }>`
  .dropdown-right {
    min-width: 100px;
    padding: 10px 12px;
    border-radius: 16px;
    white-space: nowrap;
    background-color: ${(props) => props.color};
    border: ${(props) => props.color === '#fff' && '1px solid rgba(231, 233, 234, 1)'};
    svg {
      color: ${(props) => props.theme.main.green};
      margin-left: 10px;
    }
  }
  .dropdown-left {
    padding: 0px !important;

    .dropdown-content {
      left: -100px;
    }
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const PopoverTitle = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const PopoverContent = styled.div`
  width: 420px;
`;

const NoteContainer = styled.div`
  padding: 24px;
`;

const ButtonsContainer = styled.div`
  height: 64px;
  padding: 16px;
  background-color: rgba(242, 244, 245, 1);
  display: flex;
  align-items: center;
  justify-content: end;
`;

const CreatedAtDateText = styled.span`
  display: block;
  color: var(--Midnight-Blue, #0e202f);
  /* Table / Entry */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ExpiresText = styled.span<{ isExpiringSoon: boolean }>`
  font-size: 10px;
  font-weight: 700;
  color: ${(props) => (props.isExpiringSoon ? theme.main.cherryRed : 'inherit')};
`;

const AvailableAmount = styled.div<{ hasPendingLoan: boolean }>`
  color: ${(props) => props?.theme?.main.textColor};
  display: flex;
  gap: 4px;
`;

const PreApprovalAmount = styled.div`
  color: ${(props) => props?.theme?.main.textColor};
  display: flex;
  flex-direction: column;
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  margin: 16px 0;
`;
