import { useMutation } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { Button as CherryButton, TextInput } from '@frontend/cherry-library';
import { FormControl, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { ReactComponent as KeyIcon } from 'assets/images/key-icon.svg';
import { DashCard, DashComponent, DashParagraph, TableLoader } from 'lib/components';
import { AlertDialog, CherryDialogContent, CherryDialogContentText, CherryDialogTitle } from 'lib/components/Dialogue';
import {
  DELETE_PARTNER_API_KEY,
  FETCH_PARTNER_API_KEYS,
  GENERATE_PARTNER_API_KEY,
  PATCH_PARTNER_API_KEY,
} from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useAgent from 'lib/hooks/useAgent';
import { Column, Row } from 'lib/styles';
import { ApiKeyTableItem, GenerateApiKeyResponse, useApiListTableColumns } from 'lib/tables/ApiListTable';
import { handleCopyToClipboard, settingsTableStyle } from 'lib/utils';

interface Props {
  organizationId: number;
}

export const ApiList = ({ organizationId }: Props) => {
  const permission = usePermission();
  const { captureMessage } = useSentry();
  const { isAgent } = useAgent();

  const [loading, setLoading] = useState<boolean>(false);
  const [apiKeys, setApiKeys] = useState<ApiKeyTableItem[][]>([]);
  const [newApiKey, setNewApiKey] = useState<boolean>(false);
  const [generatedKey, setGeneratedKey] = useState<GenerateApiKeyResponse>();
  const [generatedKeyName, setGeneratedKeyName] = useState<string>('');
  const [activateButton, setActivateButton] = useState<boolean>(false);
  const [secretKey, setSecretKey] = useState<string>('');
  const [deleteRowId, setDeleteRowId] = useState<string>('');
  const [clicked, setClicked] = useState<boolean>(false);
  const [open, setOpen] = useState<any>(false);
  const { tableColumns } = useApiListTableColumns();

  const client = useApolloClient();
  const [generateApiKey] = useMutation(GENERATE_PARTNER_API_KEY);
  const [patchApiKey] = useMutation(PATCH_PARTNER_API_KEY);
  const [deleteApiKey] = useMutation(DELETE_PARTNER_API_KEY);

  const alert = useAlert();

  useEffect(() => {
    if (organizationId) {
      setLoading(true);
      fetchApiKeys();
    }
  }, []);

  const mapApiKeyListToTableData = useCallback((data) => {
    const apiKeyList: any[] = data.map(
      (key: any) => {
        const deleteSelectedItem = async (e) => {
          setDeleteRowId(key.id);
          setOpen(true);
        };

        return {
          id: key.id,
          name: key.name,
          createdAt: key.createdAt,
          last4: key.last4,
          lastAccessedAt: key.lastAccessedAt,
          status: key.status,
          updatedAt: key.updatedAt,
          deleteSelectedItem,
        };
      },
      [clicked],
    );

    return apiKeyList.reverse();
  }, []);

  const fetchApiKeys = useCallback(async () => {
    try {
      const { data: { getApiKeys } = {} } = await client.query({
        query: FETCH_PARTNER_API_KEYS,
        variables: { input: { organizationId } },
      });
      if (getApiKeys.data) {
        const mappedData = mapApiKeyListToTableData(getApiKeys.data);
        setApiKeys(mappedData);
        setLoading(false);
        setOpen(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const deleteSelectedApiKey = async () => {
    try {
      setLoading(true);
      await deleteApiKey({ variables: { input: { organizationId, accessKeyId: deleteRowId } } });
      // setConfirmDelete(false);
      setOpen(false);
      setLoading(false);
      fetchApiKeys();
    } catch (e) {
      setLoading(false);
    }
  };

  const generateNewAPIKey = useCallback(async () => {
    try {
      if (!isAgent) {
        const {
          data: { generateApiKey: response },
        } = await generateApiKey({ variables: { input: { organizationId, name: '' } } });
        if (response) {
          setGeneratedKey(response);
          setSecretKey(response.secret);
          setNewApiKey(true);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const copySecretKey = (e) => {
    try {
      e.preventDefault();
      navigator.clipboard.writeText(secretKey);
      handleCopyToClipboard(e, secretKey);
      alert.success('Secret key Copied to your clipboard');
    } catch (err: any) {
      captureMessage(`ApiList => Copy Secret Key ${err.message}`);
    }
  };

  const patchAccessKey = async (e) => {
    copySecretKey(e);
    setLoading(true);
    await patchApiKey({
      variables: {
        input: { organizationId, accessKeyId: generatedKey?.apiKey.id, name: generatedKeyName, status: 'ACTIVE' },
      },
    });
    fetchApiKeys();
    setNewApiKey(false);
    setLoading(false);
    setActivateButton(false);
  };

  const onApiKeyNameChange = (nativeEvent: any) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;
    setGeneratedKeyName(value);
    if (value !== '') {
      setActivateButton(true);
    } else {
      setActivateButton(false);
    }
  };

  const onAgreeClicked = async () => {
    deleteSelectedApiKey();
  };

  const onDisagreeClicked = async () => {
    setOpen(false);
    setClicked(!clicked);
  };

  const handleClose = () => setNewApiKey(false);

  const ConfirmText = () => {
    return (
      <>
        <CherryDialogTitle id="alert-dialog-title">Confirm Delete</CherryDialogTitle>
        <CherryDialogContent>
          <CherryDialogContentText id="alert-dialog-description">
            Do you want to delete the Api Key?
          </CherryDialogContentText>
        </CherryDialogContent>
      </>
    );
  };

  return !permission(FeatureNames.GENERATE_API_KEY, ScreenNames.SETTINGS) ? (
    <></>
  ) : (
    <Column margin="0">
      <DashComponent dashHeader={'API Keys'} />
      <DashCard>
        <Row>
          <Row fontSize={'14px'}>
            Cherry API keys are powerful! They grant access to every piece of data in your account. Keep them
            confidential. Click the button to generate an API key.
          </Row>
          <Column margin={'auto'}>
            <AlertDialog
              open={open}
              agreeClicked={onAgreeClicked}
              onDisagreeClicked={onDisagreeClicked}
              children={<ConfirmText />}
              agreeText={'Yes'}
              declineText={'No'}
            />
            <CherryButtonContainer>
              <CherryButton
                variant="primary"
                leftIcon={KeyIcon}
                disabled={newApiKey || !!isAgent}
                onClick={generateNewAPIKey}
                data-testid="generateKeyButton"
              >
                Generate API Key
              </CherryButton>
            </CherryButtonContainer>
          </Column>
        </Row>
        {newApiKey ? (
          <SectionPane>
            <DashParagraph>
              Successfully generated! Now, give your key a name to activate it. This is the one and only time these
              credentials will be available for viewing. Please store this in a safe place.
            </DashParagraph>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth={true} variant="outlined">
                  <TextInput
                    data-testid="api-input"
                    inputSize="small"
                    type="text"
                    label="Enter Name"
                    showPlaceholderAnimation={false}
                    id="outlined-basic"
                    onChange={onApiKeyNameChange}
                  />
                </FormControl>
              </Grid>
              <CenteredGrid item={true} xs={5}>
                {generatedKey?.secret}
              </CenteredGrid>
              <CenteredGrid container={true} spacing={1} xs={5}>
                <Grid item={true} xs={4}>
                  <CherryButton fullWidth={true} size="small" variant="secondary" onClick={handleClose}>
                    Cancel
                  </CherryButton>
                </Grid>
                <Grid item={true} xs={7} data-testid="api-list">
                  <CherryButton
                    fullWidth={true}
                    size="small"
                    data-testid="activateButton"
                    disabled={!activateButton}
                    onClick={patchAccessKey}
                  >
                    Copy & Activate
                  </CherryButton>
                </Grid>
              </CenteredGrid>
            </Grid>
          </SectionPane>
        ) : (
          ''
        )}
        <Grid container={true} spacing={1} data-testid={'api-list-table'}>
          <Grid container={true}>
            <CustomDataTable
              noHeader={true}
              customStyles={settingsTableStyle}
              columns={tableColumns}
              data={apiKeys}
              pagination={true}
              progressPending={loading}
              progressComponent={<TableLoader count={4} />}
              style={{ overflow: 'inherit', marginTop: '40px' }}
            />
          </Grid>
        </Grid>
      </DashCard>
    </Column>
  );
};

export const CustomDataTable = styled(DataTable)`
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
  padding: 0px 8px;
`;

const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionPane = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const CherryButtonContainer = styled.div`
  width: 230px;
  margin-top: 16px;
`;
