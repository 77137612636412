import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { Loading, SecondaryButton, TableLoader } from 'lib/components';
import { SEND_APPLICATION_LINK } from 'lib/graphql/mutations';
import { estimateHistoryTableStyle, formatDateWithType, formatPhoneNumber } from 'lib/utils';

import { DashboardAnalyticsEventNames, useAnalytics, useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useSentEstimateHistory } from 'lib/services/useSentEstimateHistory';

interface Props {
  onApplicationResendSuccess: (phoneNumber: string) => void;
}

interface EstimateHistory {
  patientName: string;
  phone: string;
  email: string;
  createdAt: string;
}

const GenerateEstimateHistoryTable = ({ onApplicationResendSuccess }: Props) => {
  const { trackEvent } = useAnalytics();
  const { organization, merchants } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const alert = useAlert();
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const { fetchSentEstimateHistory } = useSentEstimateHistory();
  const { merchantLogin } = useMerchantLogin();

  const [remindApplicationLoading, setRemindApplicationLoading] = useState('');

  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableData, setTableData] = useState<[EstimateHistory] | null>(null);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    fetchSentHistory();
  }, [page, rowsPerPage]);

  const fetchSentHistory = async () => {
    setTableDataLoading(true);
    const data = await fetchSentEstimateHistory({
      rowsPerPage,
      page,
      id: organization?.id,
    });

    if (data) {
      setTotal(data.total);
      setTableData(data.contents);
      setTableDataLoading(false);
    } else {
      setTableDataLoading(false);
    }
  };

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
  };

  const remindApplication = async (phoneNumber: string, index) => {
    setRemindApplicationLoading(index);

    try {
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK,
      });

      const idMerchant = merchants?.[0]?.id || 0;
      const idOrganization = organization?.id || 0;
      await merchantLogin(idMerchant);

      const { data } = await sendOrganizationLink({ variables: { input: { idOrganization, phone: phoneNumber } } });

      if (data?.sendOrganizationLink?.success) {
        setRemindApplicationLoading('');
        onApplicationResendSuccess(phoneNumber);
        trackSegmentEvent('Send Application - Take Home Estimates History', {
          application: applicationName,
        });
      } else {
        alert.error('An error occurred. Please try again.');
        setRemindApplicationLoading('');
      }
    } catch (e) {
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK_ERR,
      });
    }
  };

  const tableColumns = [
    {
      name: 'Sent To',
      selector: (row) => row.phone,
      cell: (row) => (
        <SentToRow>
          <NameWrapper>{row?.patientName ? `${row?.patientName} ` : 'No Name'}</NameWrapper>
          <PhoneWrapper>{row?.phone ? formatPhoneNumber(row?.phone) : 'No Phone'}</PhoneWrapper>
        </SentToRow>
      ),
      minWidth: '180px',
      maxWidth: '200px',
      ignoreRowClick: true,
    },
    {
      name: 'Sent Date',
      selector: (row) => row.createdAt,
      cell: (row) => formatDateWithType(row?.createdAt, 'MMM DD, YYYY hh:mm A'),
      minWidth: '180px',
      maxWidth: '200px',
    },
  ];

  const getActionButton = (phoneNumber, index) => {
    const handleRemindButtonClick = () => {
      remindApplication(phoneNumber, index);
    };

    return (
      phoneNumber && (
        <ViewButton variant="filled" onClick={handleRemindButtonClick}>
          {remindApplicationLoading === index ? <LoadingSpinner size={10} /> : 'Send Application'}
        </ViewButton>
      )
    );
  };

  const actionsColumn: any = {
    name: '',
    cell: (row) => getActionButton(row?.phone, row?.index),
    right: true,
  };

  tableColumns.push(actionsColumn);

  const mappedTableData = tableData
    ? tableData.map((tableRow, index) => ({
        ...tableRow,
        index,
      }))
    : [];

  return (
    <Grid container={true} spacing={3}>
      <Grid container={true}>
        <CustomDataTable
          noHeader={true}
          columns={tableColumns}
          data={mappedTableData}
          pagination={true}
          customStyles={estimateHistoryTableStyle}
          paginationServer={true}
          onChangePage={pageChange}
          onChangeRowsPerPage={rowsCountChange}
          paginationTotalRows={total}
          progressPending={tableDataLoading}
          progressComponent={<TableLoader count={4} />}
        />
      </Grid>
    </Grid>
  );
};

export default GenerateEstimateHistoryTable;

const PhoneWrapper = styled.span`
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 8px;
  font-weight: 400;
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
`;

const ViewButton = styled(SecondaryButton)`
  width: 142px;
  background-color: #00c37d;
  border: 1px solid #00c37d;
  font-weight: 600;
  &:hover,
  &:focus {
    outline: none;
    background-color: #00a569;
  }
`;

const SentToRow = styled.span`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.span`
  font-size: 14px;
  line-height: 19.07px;
  padding-bottom: 8px;
  font-weight: 400;
`;

const LoadingSpinner = styled(Loading)`
  margin-left: 52px;
`;
