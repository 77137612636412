import { theme } from 'config/theme';
import React from 'react';
import { CartesianGrid, XAxis, Tooltip, YAxis, Legend, ResponsiveContainer, Bar, BarChart, Label } from 'recharts';
import styled from 'styled-components';
import { FixedDecimalFormat } from '../FixedDecimalFormat';

interface Props {
  data: any;
  height?: number | string;
  firstDataLabel?: string;
  secondDataLabel?: string;
  firstToolTipLabel?: string;
  secondToolTipLabel?: string;
  dataType?: string;
  chartType?: string;
  labelTitle?: string;
}

export const StackBarChart = ({
  data = [],
  firstDataLabel = undefined,
  secondDataLabel = undefined,
  height = 270,
  firstToolTipLabel,
  secondToolTipLabel,
  dataType,
  chartType,
  labelTitle,
}: Props) => {
  const CustomTooltip = ({ active, payload, label, firstToolLabel, secondToolLabel, type }) => {
    if (active && payload && payload.length) {
      return (
        <ToolTipContainer>
          <p>{`${label}`}</p>
          <ToolTipSpan color={'#00C37D'}>
            {firstToolLabel || payload?.[0]?.dataKey} :{' '}
            {type === 'money' ? <FixedDecimalFormat amount={payload[0].value} prefix="$" /> : payload[0].value}
          </ToolTipSpan>
          <br />
          <ToolTipSpan color={'#0e202f'}>
            {secondToolLabel || payload?.[1]?.dataKey} :{' '}
            {type === 'money' ? <FixedDecimalFormat amount={payload[1].value} prefix="$" /> : payload[1].value}
          </ToolTipSpan>
        </ToolTipContainer>
      );
    }
    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;
    const toolTipArray = [firstToolTipLabel, secondToolTipLabel];
    return (
      <LegendContainer>
        {payload.map((entry, index) => (
          <span key={`item-${index}`}>
            <div
              style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            ></div>
            {toolTipArray[index]}
          </span>
        ))}
      </LegendContainer>
    );
  };

  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <BarChart width={500} height={300} data={data} margin={{ right: 15, left: labelTitle ? 20 : -20, top: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" stroke="#0E202F" fontSize={12} />
        <YAxis stroke="#0E202F" fontSize={12}>
          {Boolean(labelTitle) && (
            <Label value={labelTitle} angle={-90} fill={theme.main.midnightBlue} position={'insideLeft'} />
          )}
        </YAxis>
        {chartType === 'detail' && <Legend content={renderLegend} />}
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
              firstToolLabel={firstToolTipLabel}
              secondToolLabel={secondToolTipLabel}
              type={dataType}
            />
          )}
        />
        <Bar dataKey={firstDataLabel || 'data1'} stackId="a" fill="#00C37D" />
        <Bar dataKey={secondDataLabel || 'data2'} stackId="a" fill="#DADADA" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const ToolTipContainer = styled.div`
  margin: 0px;
  padding: 1px 10px 10px 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

const ToolTipSpan = styled.span<{ color: string }>`
  width: 100%;
  color: ${(props) => props.color};
`;

const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
`;
