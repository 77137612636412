export const theme = {
  main: {
    darkBlue: '#0e1f2e',
    midnightBlue: '#0E202F',
    midnightBlue10: '#E7E9EA',
    midnightBlue40: '#9FA6AC',
    midnightBlue50: '#879097',
    white: '#ffffff',
    white10: '#eeeeee',
    black: '#000000',
    green: '#00c37d',
    green5: '#F2FFFB',
    green10: '#E6F9F2',
    disabledBtn: '#777',
    textColor: '#0E202F',
    borderColor: '#979797',
    grayColor: '#868F97',
    neutralGray: '#DADADA',
    sealGray: '#879097',
    red: '#EC3360',
    neutralRed: '#ff0000',
    aquadazzle: '#056e48',
    softMint: '#e6f9f2',
    aliceBlue: '#f2f4f5',
    warning: '#EBC075',
    lightGrey: '#B7BCC1',
    dropDownBorder: '#c9c9c9',
    softCherryRed: '#fbf3f3',
    cherryCheckboxGreen: '#00c37c',
    placeholderColor: '#d8d8d8',
    midnightBlue70: '#56636D',
  },
  constants: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXl: '680px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};
