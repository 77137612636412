import useStore from './useStore';

export enum FeatureNames {
  // Please insert new ones in alphabetical order
  ACH_PREFERRED = 'ACH_PREFERRED',
  ADD_BANKING_INFO = 'ADD_BANKING_INFO',
  ADD_USER = 'ADD_USER',
  APPLICATION_APPROVAL_EXTENSION = 'APPLICATION_APPROVAL_EXTENSION',
  APPLICATION_LINK = 'APPLICATION_LINK',
  APPLY_CHECKOUT_COMBO = 'APPLY_CHECKOUT_COMBO',
  APPLY_WITH_PATIENT = 'APPLY_WITH_PATIENT',
  APPROVAL_BOOST = 'APPROVAL_BOOST',
  AUTO_FUNDING = 'AUTO_FUNDING',
  BANK_INFORMATION = 'BANK_INFORMATION',
  BORROWER_LANGUAGE = 'BORROWER_LANGUAGE',
  CHECKOUT = 'CHECKOUT',
  CHERRY_LINKTREE = 'CHERRY_LINKTREE',
  COMMUNICATION = 'COMMUNICATION',
  CORE_LINE_ADJUSTMENT = 'CORE_LINE_ADJUSTMENT',
  CREATE_ADMIN = 'CREATE_ADMIN',
  CREDIT_CARD_DOWN_PAYMENT = 'CREDIT_CARD_DOWN_PAYMENT',
  DEFAULT_HIGH_LINE_FLOW = 'DEFAULT_HIGH_LINE_FLOW',
  DELETE_BANKING_INFO = 'DELETE_BANKING_INFO',
  DELETE_USER = 'DELETE_USER',
  DOWNLOAD_CONTRACT = 'DOWNLOAD_CONTRACT',
  DOWNLOAD_CSV = 'DOWNLOAD_CSV',
  EDIT_BANKING_INFO = 'EDIT_BANKING_INFO',
  EDIT_USER = 'EDIT_USER',
  ESHOP = 'ESHOP',
  GENERATE_API_KEY = 'GENERATE_API_KEY',
  ISSUE_REFUND = 'ISSUE_REFUND',
  LINK_TREE = 'LINK_TREE',
  MEMBERSHIP = 'MEMBERSHIP',
  MERCHANT_FINDER = 'MERCHANT_FINDER',
  MILESTONE = 'MILESTONE',
  MILESTONE_REWARD = 'MILESTONE_REWARD',
  MINIMUM_APPROVAL_AMOUNT = 'MINIMUM_APPROVAL_AMOUNT',
  MINIMUM_PROMO_TRANSACTIONS = 'MINIMUM_PROMO_TRANSACTIONS',
  ONLINE_OPTIONS = 'ONLINE_OPTIONS',
  OUTBOUND_MESSAGE = 'OUTBOUND_MESSAGE',
  OVERALL_APPROVAL_RATE = 'OVERALL_APPROVAL_RATE',
  PARTNER_API_ACCESS = 'PARTNER_API_ACCESS',
  PATIENT_TRACKER = 'PATIENT_TRACKER',
  PAYMENT_ESTIMATOR = 'PAYMENT_ESTIMATOR',
  POINT_OF_SALE = 'POINT_OF_SALE',
  PREFERRED_PROVIDER_LISTING = 'PREFERRED_PROVIDER_LISTING',
  PRICING_PLAN = 'PRICING_PLAN',
  PRODUCT_VIDEOS = 'PRODUCT_VIDEOS',
  PROMO_APR = 'PROMO_APR',
  PROVIDER_FINDER = 'PROVIDER_FINDER',
  REFUND = 'REFUND',
  RETAIL_INSTALLMENT_CONTRACT = 'RETAIL_INSTALLMENT_CONTRACT',
  SCHEDULE_VIEW = 'SCHEDULE_VIEW',
  SELF_CHECKOUT = 'SELF_CHECKOUT',
  SEND_REMINDER_EMAILS = 'SEND_REMINDER_EMAILS',
  SET_STATUS = 'SET_STATUS',
  SHOW_INCOMPLETE = 'SHOW_INCOMPLETE',
  SIMPLIFIED_PARTNER_PORTAL = 'SIMPLIFIED_PARTNER_PORTAL',
  STATEMENTS = 'STATEMENTS',
  TEXT_APPLICATION_LINK = 'TEXT_APPLICATION_LINK',
  VIEW_QUICK_STATS = 'VIEW_QUICK_STATS',
  VOID_TRANSACTIONS = 'VOID_TRANSACTIONS',
  WALKTHROUGH = 'WALKTHROUGH',
  WPQ_V2 = 'WPQ_V2',
  PREAPPROVAL = 'PREAPPROVAL',
  PREFILL = 'PREFILL',
  // Please insert new ones in alphabetical order
}

export enum ScreenNames {
  DASHBOARD = 'DASHBOARD',
  MARKETING = 'MARKETING',
  REFERRAL = 'REFERRAL',
  REPORTING = 'REPORTING',
  SETTINGS = 'SETTINGS',
  STATEMENTS = 'STATEMENTS',
  TRAINING = 'TRAINING',
  TRANSACTIONS = 'TRANSACTIONS',
}

export const usePermission = () => {
  const { permissions } = useStore();
  const hasPermission = (keyName, field) => {
    return permissions?.[field]?.includes(keyName);
  };

  return hasPermission;
};
