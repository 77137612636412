import client from 'lib/graphql/client';
import { FETCH_FILES } from 'lib/graphql/queries';
import { downloadFile } from 'lib/utils';

export const useGetLoanFile = () => {
  const getLoanFile = async (loanId: number) => {
    const {
      data: { getFileList },
    } = await client.query({
      query: FETCH_FILES,
      variables: {
        input: {
          pagination: {
            limit: 1,
          },
          search: [
            {
              key: 'relationType',
              value: 'LOAN',
            },
            {
              key: 'type',
              value: 'AGREEMENT',
            },
            {
              key: 'relationId',
              value: loanId,
            },
          ],
        },
      },
    });

    if (getFileList?.contents?.length) {
      await downloadFile(getFileList?.contents?.[0]?.id);
    }
  };

  return { getLoanFile };
};
