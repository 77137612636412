import client from 'lib/graphql/client';
import { GET_APPLICATION_LINK_SENT_EMAIL_HISTORY, GET_APPLICATION_LINK_SENT_HISTORY } from 'lib/graphql/queries';
import { useAlert } from 'react-alert';

interface Request {
  rowsPerPage: number;
  page: number;
  idOrganization?: number;
}

export const useApplicationLinkHistory = () => {
  const alert = useAlert();

  const getApplicationLinkHistory = async (params: Request) => {
    try {
      const { data: { getOrganizationLinkSentHistory } = {} } = await client.query({
        query: GET_APPLICATION_LINK_SENT_HISTORY,
        variables: {
          input: {
            pagination: {
              limit: params.rowsPerPage,
              offset: params.page - 1,
              order: 'desc',
              orderBy: 'createdAt',
            },
            idOrganization: params.idOrganization,
          },
        },
      });

      if (getOrganizationLinkSentHistory.contents?.length > 0) {
        return { contents: getOrganizationLinkSentHistory?.contents, total: getOrganizationLinkSentHistory.total };
      }
    } catch (err) {
      alert.error('An error occurred please try again');
    }
  };

  const getApplicationLinkEmailHistory = async (params: Request) => {
    try {
      const { data: { getOrganizationLinkEmailSentHistory } = {} } = await client.query({
        query: GET_APPLICATION_LINK_SENT_EMAIL_HISTORY,
        variables: {
          input: {
            pagination: {
              limit: params.rowsPerPage,
              offset: params.page - 1,
              order: 'desc',
              orderBy: 'createdAt',
            },
            idOrganization: params.idOrganization,
          },
        },
      });

      if (getOrganizationLinkEmailSentHistory.contents?.length > 0) {
        return {
          contents: getOrganizationLinkEmailSentHistory?.contents,
          total: getOrganizationLinkEmailSentHistory.total,
        };
      }
    } catch (err) {
      alert.error('An error occurred please try again');
    }
  };

  return { getApplicationLinkHistory, getApplicationLinkEmailHistory };
};
