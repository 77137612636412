import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import { FeatureFlagsContextProvider } from 'lib/hooks/FeatureManagement/FlagsContext';
import './i18n';

Sentry.init({
  dsn: 'https://a5a2fe822332423f9487dc910b37a2e4@o571338.ingest.sentry.io/5720935',
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: [/Network Error/gm],
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <FeatureFlagsContextProvider>
        <App />
      </FeatureFlagsContextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
