import { DropDown } from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import React from 'react';

import { DropdownContainer } from './components';
import { getOutboundMessagesItems } from './utils';
import Popover from './components/Popover';

export const Expired = ({ row }) => {
  const permission = usePermission();
  const NewDropDown = DropDown;

  return (
    <>
      <Popover
        row={row}
        ternary={true}
        left={165}
        backgroundColor="#FDEBEF"
        type="expired"
        label="Expired"
        iconName="info_circle_outline"
        hoverText="This patient’s approval has expired. Send them a new application link for them to apply again."
      />
      {!permission(FeatureNames.OUTBOUND_MESSAGE, ScreenNames.DASHBOARD) ? null : getOutboundMessagesItems(row)
          ?.length > 0 ? (
        <DropdownContainer>
          <NewDropDown
            data-testid="checkout-option"
            id={`drop-${row.applicationId}`}
            options={getOutboundMessagesItems(row)}
            textColor={'#0E202F'}
            onChange={row.onChangeReminderChange}
            defaultValue={row.scheduled}
            showAllItem={true}
            hoveredMode={false}
            customSelector={true}
          />
        </DropdownContainer>
      ) : null}
    </>
  );
};
