import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { ApplicationIcon, CommunicationIcon, MoreIcon, ReceiptIcon } from 'lib/components/mobile/SidebarIcons';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';

interface NavItemTextProps {
  active?: boolean;
}

interface NavItemProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

interface Route {
  path: string;
  text: string;
  icon: (boolean) => JSX.Element;
  level: string;
}

interface Props {
  setSidebarStatus: (boolean) => void;
}

const navigateArray: Route[] = [
  {
    path: '/dashboard',
    text: 'Applicants',
    icon: (active) => <ApplicationIcon active={active} />,
    level: 'DASHBOARD',
  },
  {
    path: '/send-application',
    text: 'Send New',
    icon: (active) => <CommunicationIcon active={active} />,
    level: 'DASHBOARD',
  },
  {
    path: '/transactions',
    text: 'Sales',
    icon: (active) => <ReceiptIcon active={active} />,
    level: 'TRANSACTIONS',
  },
];

const NavbarNotVisibleScreens = [
  '/login',
  '/organization-select',
  '/forgot-password',
  '/application/pending-income',
  '/application/checkout',
  '/application/update',
  '/application/cancel',
  '/application/detail',
  '/application/checkout-finished',
  '/transactions/detail',
  '/transactions/detail/partial-refund',
  '/transactions/detail/partial-refund/review',
  '/transactions/detail/partial-refund/success',
  '/transactions/detail/full-refund',
  '/transactions/detail/full-refund/success',
  '/transactions/detail/cancel-refund',
  '/transactions/detail/full-refund/review',
  '/transactions/detail/void',
  '/transactions/detail/void/review',
  '/transactions/detail/void/success',
  '/application/pre-approval',
];

const MoreActiveScreens = ['/settings', '/share-your-link', '/help'];

export const Navbar = ({ setSidebarStatus }: Props) => {
  const { permissions } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const permission = usePermission();

  const [navbarVisibility, setNavbarVisibility] = useState(false);
  const navigateToRelatedMenu = (event): void => {
    const url = event.target.getAttribute('data-nav-url');
    if (url) {
      navigate(url);
    } else {
      setSidebarStatus(true);
    }
  };

  useEffect(() => {
    setNavbarVisibility(!NavbarNotVisibleScreens.includes(pathname));
  }, [pathname]);

  const isMoreActive = MoreActiveScreens.includes(pathname);

  return (
    <>
      {navbarVisibility && (
        <NavbarContainer>
          {navigateArray.map((item, index) => {
            if (
              !Object.keys(permissions).includes(item.level || '') ||
              (item.level === 'TEXT_APPLICATION_LINK' &&
                !permission(FeatureNames.TEXT_APPLICATION_LINK, ScreenNames.DASHBOARD))
            ) {
              return null;
            }

            if (Object.keys(permissions).includes(item.level)) {
              return (
                <NavItem
                  key={`${item}-${index}`}
                  data-nav-url={item.path}
                  onClick={navigateToRelatedMenu}
                  data-testid="mobile-nav-item"
                >
                  {item.icon(pathname === item.path)}
                  <NavItemText active={pathname === item.path}>{item.text}</NavItemText>
                </NavItem>
              );
            }

            return null;
          })}
          <NavItem onClick={navigateToRelatedMenu}>
            <MoreIcon active={isMoreActive} />
            <NavItemText active={isMoreActive}>More</NavItemText>
          </NavItem>
        </NavbarContainer>
      )}
    </>
  );
};

const NavbarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 16px;
  position: fixed;
  z-index: 10;
  gap: 8px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -6px 9px rgba(0, 0, 0, 0.07), 0px -1.25px 1.12694px rgba(0, 0, 0, 0.035);
`;

const NavItem = styled.div<NavItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const NavItemText = styled.span<NavItemTextProps>`
  font-weight: 600;
  pointer-events: none;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => (props.active ? theme.main.green : theme.main.midnightBlue)};
  margin-top: 4px;
`;
