import React from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberFormatInput = (props) => {
  const { inputRef, onChange, ...other } = props;

  const onNumberChanged = (values) => {
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onNumberChanged}
      thousandSeparator={','}
      decimalScale={2}
      decimalSeparator={'.'}
      allowNegative={false}
    />
  );
};
