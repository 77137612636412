import { Loan } from '../types';

export interface SetupPaymentProps {
  borrowerId: number | undefined;
  applicationId: number | undefined;
  loan: Loan | undefined;
  currentStep: number;
  setStep: (step: number) => void;
  isCheckoutProcessing: boolean;
}

export interface CardPaymentMethodInfo {
  cardType: string;
  expireMonth: number;
  expireYear: number;
  firstName: string;
  last4: string;
  lastName: string;
  network: string;
  zip: string;
  type: string;
}

export interface PaymentMethod {
  accountNumber: string;
  bankName: string;
  createdAt: string;
  idPaymentMethod: number;
  recurringMethod: string;
  routingNumber: string;
  storedCard: CardPaymentMethodInfo;
  id: number;
  type: string;
  achAccount?: any;
  rccAccount: any;
}

export enum PaymentViewState {
  PaymentList = 'PAYMENT_LIST',
  AddPaymentMethod = 'ADD_PAYMENT_METHOD',
}

export enum RecurringPaymentType {
  DownPaymentMethod = 'DOWN_PAYMENT_METHOD',
  Other = 'OTHER_METHOD',
}
