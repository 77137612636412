import { theme } from 'config/theme';
import { Icon } from 'lib/components/mobile';
import styled from 'styled-components';

export const PreferredContainer = styled.div`
  font-size: 10px;
  line-height: 10.89px;
  color: ${(props) => props.theme.main.green};
  background: #e6f9f2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 4px 6px 5px;
  gap: 4px;
  border-radius: 16px;
`;

export const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${theme.main.grayColor};
  border-radius: 4px;
  padding: 12px 18px;
  box-sizing: border-box;
  margin-bottom: 8px;

  ${({ isActive }) =>
    isActive &&
    `
    border: 2px solid ${theme.main.green};
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  flex: 1;
`;

export const Title = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.isActive ? theme.main.textColor : theme.main.grayColor)};
`;

export const Subtitle = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 10px;
  line-height: 13px;
  color: ${(props) => (props.isActive ? theme.main.textColor : theme.main.grayColor)};
`;

export const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.main.green};
`;
