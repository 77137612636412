import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AttentionIcon } from 'lib/components';

export const BankingIssue = () => {
  return (
    <Container>
      <AttentionIcon />
      <TextContainer>
        You have a banking issue: <IssueLink to={'/settings#banking'}> let’s fix it</IssueLink>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 93px;
  width: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6cb72;
  padding: 8px 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #0e202f;
  font-size: 14px;
  margin-left: 10px;
`;

const IssueLink = styled(Link)`
  padding-left: 3px;
  color: #0e202f;
  text-decoration: underline;
`;
