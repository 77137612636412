import { Grid } from '@mui/material';
import React from 'react';

import { PageHeader } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Container, Row } from 'lib/styles';
import styled from 'styled-components';
import { Charts, InfoCards } from './components/Home';
import { Leads } from './components/Home';

const ShopHome = () => {
  const { user } = useStore();

  return (
    <Container>
      <PageHeader title={`Welcome, ${user?.firstName}`} />

      <SectionTitle>Analytics: Month to Date</SectionTitle>
      <Grid container={true} spacing={3} style={{ marginBottom: '24px' }}>
        <Grid item={true} xs={12}>
          <InfoCards />
        </Grid>
      </Grid>

      <SectionTitle>Analytics: Sales & Engagement</SectionTitle>
      <Row>
        <Charts />
      </Row>

      <Row>
        <Leads />
      </Row>
    </Container>
  );
};

const SectionTitle = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

export default ShopHome;
