import * as Sentry from '@sentry/react';
import { Merchant, Organization } from 'lib/types';
import { getDimensions } from 'lib/utils';
import { useFlags } from './FeatureManagement/FlagsContext';
import useStore from './useStore';

const applicationName = 'practicePortal';

interface IdentifyData {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  createdAt?: string | number;
  id?: string;
  merchantId?: string;
  organizationId?: string;
  userRole?: string;
}

interface SegmentEventHandlerOptions {
  data?: any;
  withInput?: boolean;
  isFilledEvent?: boolean;
  cb?: (e) => void;
}

const removeEmptyFields = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyFields(v) : v]),
  );
};

export const useSegment = () => {
  const { features, organization: _organization, user: _user } = useStore();
  const { flags } = useFlags();

  const trackIdentify = (
    user?: IdentifyData,
    organization?: Organization | null,
    merchants?: Merchant[] | null,
    role?: string[],
  ) => {
    try {
      if (user || organization || merchants) {
        const { id } = organization || {};
        const merchantId = merchants?.length === 1 ? merchants[0].id : undefined;
        const userId = user?.id ? `u-${user?.id}` : undefined;

        const params = {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          createdAt: user?.createdAt,
          merchantId,
          organizationId: id,
          userRole: role ? role?.[0] : undefined,
          industry: organization?.industry || undefined,
          ...getDimensions(),
        };

        // @ts-ignore analytics
        window.analytics.identify(userId, removeEmptyFields(params));
      } else {
        // @ts-ignore analytics
        window.analytics.identify();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackAlias = (id: string) => {
    try {
      // @ts-ignore analytics
      window.analytics.alias({
        userId: id,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackPage = (pageName: string, data?) => {
    try {
      // @ts-ignore analytics
      window.analytics.page(pageName, {
        application: applicationName,
        ...data,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackGroup = (groupId: string, traits) => {
    try {
      // @ts-ignore analytics
      window.analytics.group(groupId, traits);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackSegmentEvent = (eventName: string, data: Record<string, any> = {}) => {
    const experimentFlags = {
      ...Object.keys(flags.FEATURES).map((key) => ({ [key]: flags.FEATURES[key].isEnabled() })),
      ...Object.keys(flags.SCREEN).map((key) => ({ [key]: flags.SCREEN[key].isEnabled() })),
    };

    try {
      const payload = {
        application: applicationName,
        user: _user,
        ...getDimensions(),
        organization: _organization ? { id: _organization?.id, industry: _organization?.industry } : undefined,
        flags: {
          featureFlags: features.map((feature) => ({ [feature]: true })),
          experimentFlags,
        },
        ...data,
      };

      // @ts-ignore analytics
      window.analytics.track(eventName, payload);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const segmentEventHandler = (
    eventName: string,
    { data = {}, withInput = false, isFilledEvent = false, cb }: SegmentEventHandlerOptions = {},
  ) => {
    return (ev?) => {
      const value = ev?.target?.value as string;
      const fieldName = (ev?.target?.name || ev?.target?.dataset?.inputkey) as string;

      if (isFilledEvent && !value) return;

      if (cb) {
        cb?.(ev);
      }

      const payload = withInput ? { [fieldName]: value, ...data } : { ...data };

      trackSegmentEvent(eventName, payload);
    };
  };

  return {
    trackPage,
    trackSegmentEvent,
    trackIdentify,
    trackAlias,
    trackGroup,
    applicationName,
    segmentEventHandler,
  };
};
