import { gql } from '@apollo/client/core';

export const CREATE_TREATMENT_PLAN = gql`
  mutation CreateTreatmentPlan($input: CreateTreatmentPlanRequest) {
    createTreatmentPlan(input: $input) {
      id
    }
  }
`;

export const PATCH_TREATMENT_PLAN = gql`
  mutation patchTreatmentPlan($input: PatchTreatmentPlanRequest) {
    patchTreatmentPlan(input: $input) {
      id
    }
  }
`;
