import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { MerchantFinder } from './MerchantFinder';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const PracticeFinderSettings = () => {
  const permission = usePermission();
  const { user, organization, features } = useStore();
  const { trackSegmentEvent } = useSegment();
  const isAuthorized =
    organization?.isDemo || !features?.includes(FeatureNames?.PROVIDER_FINDER)
      ? null
      : permission(FeatureNames.MERCHANT_FINDER, ScreenNames.SETTINGS) && user?.userType !== 'MERCHANT_USER';

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_PRACTICE_FINDER_PAGE_LOAD`);
  }, []);

  return isAuthorized ? <MerchantFinder /> : <UnauthorizedSettings title="Practice Finder Settings" />;
};

export default PracticeFinderSettings;
