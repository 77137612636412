import { gql } from '@apollo/client/core';

export const CONFIRM_APPLICATION = gql`
  mutation confirmApplication($input: ConfirmApplicationInput) {
    confirmApplication(input: $input) {
      code
      message
      success
    }
  }
`;

export const PATCH_APPLICATION = gql`
  mutation patchApplication($input: PatchApplicationRequest) {
    patchApplication(input: $input) {
      code
      message
      success
      data {
        id
      }
    }
  }
`;

export const POST_PREAPPROVAL = gql`
  mutation postPreApproval($input: PostPreApprovalRequest) {
    postPreApproval(input: $input) {
      minimumAmount
      maximumAmount
      borrowerFirstName
      borrowerLastName
      createdAt
      id
      organizationId
      status
      error
      message
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation patchApplication($input: UpdateApplicationInput) {
    updateApplication(input: $input) {
      code
      message
      success
      loan {
        borrower {
          firstName
        }
        plan {
          downPayment
          schedules {
            type
            status
            amount
          }
        }
        paymentMethodDownPayment {
          storedCard {
            last4
          }
        }
      }
    }
  }
`;

export const CANCEL_APPLICATION = gql`
  mutation cancelApplication($input: CancelApplicationInput) {
    cancelApplication(input: $input) {
      code
      message
      success
    }
  }
`;

export const UPDATE_BORROWER_OUTCOME_STATUS = gql`
  mutation updateBorrowerOutcomeStatus($input: UpdateBorrowerOutcomeStatusInput) {
    updateBorrowerOutcomeStatus(input: $input) {
      success
      code
      message
    }
  }
`;

export const SEND_TEXT_REMINDERS = gql`
  mutation sendTextReminders($input: ApplicationInput) {
    sendTextReminders(input: $input) {
      success
      code
      message
    }
  }
`;

export const SEND_EXTEND_APPROVAL_MESSAGE = gql`
  mutation sendExtendApprovalMessage($input: SendExtendApprovalMessageInput) {
    sendExtendApprovalMessage(input: $input) {
      success
    }
  }
`;

export const POST_APPLY_WITH_PATIENT_CONSENT = gql`
  mutation PostApplyWithPatientConsent($input: ApplyWithPatientForm) {
    postApplyWithPatientConsent(input: $input) {
      organizationId
      merchantId
      firstName
      lastName
      dob
      email
      phone
      last4ssn
      statedIncome
      housingPayment
      requestedAmount
      address {
        aptUnitAddress
        streetAddress
        city
        state
        zip
      }
      status
      borrowerId
      id
    }
  }
`;

export const RESEND_APPLY_WITH_PATIENT_LINK = gql`
  mutation ResendApplyWithPatientConsentLink($input: ApplyWithPatientConsentId) {
    resendApplyWithPatientConsentLink(input: $input) {
      code
      message
      success
    }
  }
`;

export const CHECK_ELIGIBILITY_FOR_APPLY_WITH_PATIENT = gql`
  mutation CheckEligibilityForApplyWithPatient($input: EligibilityCheckInput) {
    checkEligibilityForApplyWithPatient(input: $input) {
      isEligible
    }
  }
`;
