import styled from 'styled-components';

export const ContentContainer = styled.div<{
  justify?: string;
  noMargin?: boolean;
  noPadding?: boolean;
  isSnackbarVisible?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  padding: ${(props) => (props.noPadding ? '0px' : '0 24px 20px 24px')};
  margin-top: ${(props) => (props.noMargin ? '0px' : '30px')};
  background-color: white;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    // overflow-x: scroll;
  }
`;
