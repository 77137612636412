import { Body, Button, theme } from '@frontend/cherry-library';
import { MODAL_STATUS, ModalV2 } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';
import styled from 'styled-components';

interface SuccessModalProps {
  open: boolean;
  handleClose: () => void;
  firstName: string;
  lastName: string;
  trackSegmentEvent: (string) => void;
}

interface NotSupportedModalProps {
  open: boolean;
  handleClose: () => void;
  openEditCancelModal: () => void;
  isEmail: boolean;
  trackSegmentEvent: (string) => void;
}

const SuccessModal = ({ open, handleClose, firstName, lastName, trackSegmentEvent }: SuccessModalProps) => {
  useEffect(() => {
    trackSegmentEvent('PRACTICE_PORTAL.RESEND_CHECKOUT_LINK.SUCCESS_MODAL.VIEWED');
  }, []);

  return (
    <ModalV2
      open={open}
      title="Success!"
      width={450}
      showClose={true}
      onCancel={handleClose}
      status={MODAL_STATUS.SUCCESS}
      customFooter={
        <ButtonContainer>
          <Button variant="primary" fullWidth={true} onClick={handleClose} data-tag="checkout">
            Okay
          </Button>
        </ButtonContainer>
      }
      successContent={`${firstName} ${lastName} has received a link on their phone. You’ll get notified once they’ve chosen a plan.`}
      onClose={handleClose}
    />
  );
};

const NotSupportedModal = ({
  open,
  handleClose,
  openEditCancelModal,
  isEmail,
  trackSegmentEvent,
}: NotSupportedModalProps) => {
  const handleEditCancelClick = () => {
    openEditCancelModal();
    handleClose();
  };

  const emailNotSupportedText = 'checkout link was sent via email';
  const checkoutOnDevicelNotSupportedText = 'checkout was on device';

  useEffect(() => {
    trackSegmentEvent('PRACTICE_PORTAL.RESEND_CHECKOUT_LINK.NOT_SUPPORTED_MODAL.VIEWED');
  }, []);

  return (
    <ModalV2
      open={open}
      title="Resend Link Not Supported"
      width={450}
      showClose={true}
      onCancel={handleClose}
      customFooter={
        <NotSupportedButtonContainer>
          <Button variant="primary" fullWidth={true} onClick={handleClose}>
            Got it
          </Button>
          <Button variant="secondary" fullWidth={true} onClick={handleEditCancelClick}>
            Edit / Cancel Plan
          </Button>
        </NotSupportedButtonContainer>
      }
      onClose={handleClose}
    >
      <ModalContainer>
        <Body>
          Unfortunately, resending checkout links doesn’t work when the original{' '}
          {isEmail ? emailNotSupportedText : checkoutOnDevicelNotSupportedText}.
        </Body>
        <Body>To send a new link, please edit or cancel the plan.</Body>
      </ModalContainer>
    </ModalV2>
  );
};

const ResendCheckoutLinkModal = ({
  open,
  handleClose,
  firstName,
  lastName,
  isSuccess,
  openEditCancelModal,
  isEmail,
}) => {
  const { trackSegmentEvent } = useSegment();

  return isSuccess ? (
    <SuccessModal
      open={open}
      handleClose={handleClose}
      firstName={firstName}
      lastName={lastName}
      trackSegmentEvent={trackSegmentEvent}
    />
  ) : (
    <NotSupportedModal
      open={open}
      handleClose={handleClose}
      openEditCancelModal={openEditCancelModal}
      isEmail={isEmail}
      trackSegmentEvent={trackSegmentEvent}
    />
  );
};

export default ResendCheckoutLinkModal;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;
const NotSupportedButtonContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
  text-align: center;
  p {
    color: ${theme.main.midnightBlue};
  }
`;
