import Vimeo from '@u-wave/react-vimeo';
import { Modal } from 'lib/components';
import { Loading } from 'lib/components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const ProductVideoModal = ({ show, vimeoId, closeModal, fullModal }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <CustomModal
      open={show}
      fullCustomModal={fullModal}
      closeOnDocumentClick={true}
      title=""
      titleAlignment="left"
      onClose={closeModal}
      data-testid="video-modal"
    >
      {loading ? (
        <Center>
          <Loading size={20} id={'loader'} />
        </Center>
      ) : (
        <Vimeo video={vimeoId} autoplay={true} height={'auto'} width={'580px'} />
      )}
    </CustomModal>
  );
};

const CustomModal = styled(Modal)<{ fullCustomModal?: boolean }>`
  width: unset !important;

  div {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

const Center = styled.div`
  #loader {
    margin-left: 300px !important;
  }
`;
