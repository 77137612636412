import { gql } from '@apollo/client/core';

export const GET_SHOP_PRODUCTS = gql`
  query fetchShopProducts($input: FetchShopProductsInput) {
    fetchShopProducts(input: $input) {
      total
      contents {
        id
        organizationId
        title
        price
        compareAtPrice
        monthlyPayment
        numberOfMonths
        apr
        membershipPlanId
        paymentSchedule
        description
        type
        status
        files {
          key
          link
        }
        image {
          key
          link
        }
        labels
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const GET_SHOP_PRODUCT_DETAIL = gql`
  query fetchShopProductDetail($input: FetchShopProductDetailInput) {
    fetchShopProductDetail(input: $input) {
      success
      data {
        id
        organizationId
        title
        price
        monthlyPayment
        numberOfMonths
        apr
        membershipPlanId
        compareAtPrice
        paymentSchedule
        description
        type
        status
        files {
          key
          link
        }
        image {
          key
          link
        }
        labels
        fulfillmentOptions {
          id
          method
          displayName
          description
          shippingPrice
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const GET_SHOP_LEADS = gql`
  query fetchShopLeads($input: FetchShopLeadsInput) {
    fetchShopLeads(input: $input) {
      total
      contents {
        id
        organizationId
        productDetails {
          title
          id
        }
        firstName
        lastName
        phone
        email
        dateOfBirth
        quantity
        preferredDate
        preferredTime
        explanation
        payOverTime
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
