import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { Loading } from 'lib/components';
import { ClockIcon } from 'lib/components/mobile/SidebarIcons';
import { useCheckoutWithPatient } from 'lib/services';

import { useSegment } from 'lib/hooks/useSegment';
import KeepWaitingModal from './KeepWaitingModal';
import { SecondaryBackButton } from './styles';

const FETCH_LOAN_STATUS_TIMEOUT = 5000;
const FIRST_FETCH_TIMEOUT = 20000;
const ONE_MINUTE_TIMEOUT = 60000;

const CHECKOUT_COMPLETED_LOAN_STATUS = 'AWAITING_FUNDING';
const CHECKOUT_CANCELLED_STATUS = 'CANCELLED';

interface Props {
  phone: string;
  resendConfirmationLink: () => void;
  loanId: number;
  borrowerId: number;
  handleCheckoutErrors: (error: boolean) => void;
  error: boolean;
  onSuccess: () => void;
}
const CheckoutStatusTracker = ({
  phone,
  resendConfirmationLink,
  loanId,
  handleCheckoutErrors,
  error,
  borrowerId,
  onSuccess,
}: Props) => {
  const { getLoan } = useCheckoutWithPatient();
  const { trackSegmentEvent } = useSegment();

  const stillWaitingTimerRef = useRef<any>(null);
  const pollingTimerRef = useRef<any>(null);

  const [displayStillWaitingText, setDisplayStillWaitingText] = useState<boolean>(false);
  const [displayKeepWaitingModal, setDisplayKeepWaitingModal] = useState<boolean>(false);
  const [resendButtonVisible, setResendButtonVisible] = useState<boolean>(false);

  const segmentEventData = {
    loanId,
    borrowerId,
  };

  useEffect(() => {
    stillWaitingTimerRef.current = displayTextAfterOneMinute();
    pollingTimerRef.current = startPollingAfter20Seconds();

    return () => {
      clearState();
    };
  }, []);

  const clearState = () => {
    setDisplayStillWaitingText(false);

    clearTimeout(stillWaitingTimerRef.current);
    stillWaitingTimerRef.current = undefined;

    clearTimeout(pollingTimerRef.current);
    pollingTimerRef.current = undefined;
  };

  const pollCheckoutStatus = async () => {
    try {
      const loan = await getLoan(loanId);

      if (loan?.status) {
        if (loan.status === CHECKOUT_COMPLETED_LOAN_STATUS) {
          if (error) {
            handleCheckoutErrors(false);
          }
          onSuccess();
        } else if (loan.status === CHECKOUT_CANCELLED_STATUS) {
          handleCheckoutErrors(true);
        } else {
          if (pollingTimerRef?.current) {
            setTimeout(pollCheckoutStatus, FETCH_LOAN_STATUS_TIMEOUT);
          }
          if (error) {
            handleCheckoutErrors(false);
          }
        }
      } else {
        handleCheckoutErrors(true);
      }
    } catch (error) {
      setTimeout(pollCheckoutStatus, FETCH_LOAN_STATUS_TIMEOUT);
      handleCheckoutErrors(true);
    }
  };

  const displayTextAfterOneMinute = () =>
    setTimeout(() => {
      setDisplayStillWaitingText(true);
      setResendButtonVisible(true);
    }, ONE_MINUTE_TIMEOUT);

  const startPollingAfter20Seconds = () =>
    setTimeout(() => {
      pollCheckoutStatus();
    }, FIRST_FETCH_TIMEOUT);

  const openKeepWaitingModal = () => {
    setDisplayKeepWaitingModal(true);
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.PATIENT_STILL_REVIEWING_BUTTON_CLICKED', segmentEventData);
  };

  const closeKeepWaitingModal = () => {
    trackSegmentEvent(
      'PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.PATIENT_STILL_REVIEWING_MODAL_KEEP_WAITING_CLICKED',
      segmentEventData,
    );
    setDisplayKeepWaitingModal(false);
  };

  const handleResend = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RESEND_LINK_TO_PATIENT_CLICKED', segmentEventData);
    resendConfirmationLink();
    setResendButtonVisible(false);

    setTimeout(() => {
      setResendButtonVisible(true);
    }, ONE_MINUTE_TIMEOUT);
  };

  return (
    <InfoContainer>
      <ReviewText>
        We texted the loan agreement to <b>{phone}</b>. Waiting for them to sign their loan agreement.
      </ReviewText>
      <LoadingContainer>
        <LoadingSpinner size={16} />
      </LoadingContainer>
      <BoldText>Don’t close this page until you see a confirmation.</BoldText>
      {resendButtonVisible && (
        <ResendButton className="mt-2" onClick={handleResend}>
          Something went wrong? Re-send loan agreement
        </ResendButton>
      )}
      {displayStillWaitingText && (
        <Row onClick={openKeepWaitingModal}>
          <ClockIcon />
          <GreenText>Patient still reviewing?</GreenText>
        </Row>
      )}
      <KeepWaitingModal
        open={displayKeepWaitingModal}
        handleClose={closeKeepWaitingModal}
        segmentEventData={segmentEventData}
      />
    </InfoContainer>
  );
};

const ReviewText = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
`;

const BoldText = styled(ReviewText)`
  font-weight: 700;
  margin: 0 0 16px 0;
`;

const ResendButton = styled(SecondaryBackButton)`
  min-width: 410px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${theme.main.aliceBlue};
  padding: 24px;
`;

const LoadingSpinner = styled(Loading)`
  margin: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GreenText = styled(ReviewText)`
  color: ${theme.main.green};
  text-decoration: underline;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 26px;
  cursor: pointer;
`;

export default CheckoutStatusTracker;
