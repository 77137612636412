import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { ButtonPrimary, TableDeleteIcon, TableLoader, UserPlusIcon } from 'lib/components';
import { CheckoutButton } from 'lib/components/ApplicationStatutes/components';
import { formatTextCapitalize, membershipBenefitTableStyle } from 'lib/utils';
import { AddEditBenefitModal } from './AddEditBenefitModal';

interface TableColumnProps {
  flex?: number;
}

interface Props {
  data: any;
  handleChange: any;
}

export const PlanBenefits = ({ data = {}, handleChange }: Props) => {
  const [openBenefitModal, setOpenBenefitModal] = useState<boolean>(false);
  const [benefitModalData, setBenefitModalData] = useState<any>(null);
  const [benefitModalType, setBenefitModalType] = useState<string>('create');

  const benefitChangeHandler = (benefitData) => {
    const benefits = [...(data.benefits || [])];

    if (benefitModalType === 'create') {
      benefits.push(benefitData);
      handleChange('benefits', benefits);
      setBenefitModalData(null);
    } else {
      const findBenefitIndex = benefits.findIndex((item) => item.id === benefitData.id);
      if (findBenefitIndex > -1) {
        benefits[findBenefitIndex] = benefitData;
        handleChange('benefits', benefits);
        setBenefitModalData(null);
      }
    }
  };

  const editBenefit = (row) => editBenefitHandler(row);
  const deleteBenefit = (row) => deletePlanBenefit(row);

  const editBenefitCell = () => (
    <CheckoutButton
      data-testid="checkout-button"
      data-tag="allowRowEvents"
      className="btn--checkout"
      onClick={editBenefit}
    >
      Edit Benefit
    </CheckoutButton>
  );

  const detailCell = () => (
    <DeleteBenefitContainer onClick={deleteBenefit}>
      <TableDeleteIcon />
    </DeleteBenefitContainer>
  );

  const tableColumns = [
    {
      name: 'Benefit Title',
      selector: (row) => row.name,
      minWidth: '230px',
      maxWidth: '230px',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      cell: (row) => <>{formatTextCapitalize(row.type)}</>,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
    },
    {
      name: 'Frequency',
      selector: (row) => row.frequency,
      cell: (row) => <>{formatTextCapitalize(row.frequency)}</>,
    },

    {
      name: '',
      selector: (row) => row.editBenefit,
      right: true,
      left: false,
      width: '221px',
      cell: editBenefitCell,
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.detail,
      right: true,
      left: false,
      width: '221px',
      cell: detailCell,
      ignoreRowClick: true,
    },
  ];

  const editBenefitHandler = (row: any) => {
    setBenefitModalData(row);
    setBenefitModalType('edit');
    setOpenBenefitModal(true);
  };

  const deletePlanBenefit = (row) => {
    const benefits = [...(data.benefits || [])];
    const filteredBenefits = benefits.filter((item) => !(item.name === row.name && item.type === row.type));
    handleChange('benefits', filteredBenefits);
  };

  const addBenefitHandler = () => {
    setBenefitModalType('create');
    setOpenBenefitModal(true);
  };

  const hideBenefitModal = () => setOpenBenefitModal(false);

  return (
    <>
      <Header>
        <SubTitle>Plan Benefits</SubTitle>
        <ButtonPrimary
          data-testid="addBenefit"
          onClick={addBenefitHandler}
          startIcon={<UserPlusIcon />}
          text={'Add a Benefit'}
        />
      </Header>

      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            noHeader={true}
            customStyles={membershipBenefitTableStyle}
            columns={tableColumns}
            data={data?.benefits || []}
            pagination={true}
            progressPending={false}
            progressComponent={<TableLoader count={4} />}
          />
        </TableColumn>
      </TableRow>

      <AddEditBenefitModal
        open={openBenefitModal}
        handleClose={hideBenefitModal}
        benefitChangeHandler={benefitChangeHandler}
        data={benefitModalData}
      />
    </>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`;

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`;

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(135, 144, 151, 0.5);

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableHeadRow div {
    font-size: 12px;
    margin-bottom: -10px;
  }
  .rdt_TableRow {
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0e202f;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const DeleteBenefitContainer = styled.div`
  cursor: pointer;
`;
