import { PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Container, Row } from 'lib/styles';
import React from 'react';
import { ProcessingFeeSettings, QrCodeSettings, WidgetSettings } from './views';

const MembershipSettings = () => {
  const { isSnackbarVisible } = useSnackbar();

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Settings" />
      <Row>
        <QrCodeSettings />
      </Row>
      <Row>
        <WidgetSettings />
      </Row>
      <Row>
        <ProcessingFeeSettings />
      </Row>
    </Container>
  );
};

export default MembershipSettings;
