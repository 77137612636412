import { GenericSearchOperators } from 'lib/types/GenericSearch';

export const PaymentMethodSearch = () => {
  return [
    {
      key: 'status',
      value: 'ACTIVE',
      operator: GenericSearchOperators.EQUAL,
    },
  ];
};
