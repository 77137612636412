import { TransactionTableItem } from 'lib/tables';
import { getDisbursement } from 'lib/utils';

export const useLoanSearch = () => {
  const mapTransactionToTableData = (data, openDialog?) => {
    const transactionsList: TransactionTableItem[] = [];

    if (data) {
      data.map((transaction: any) => {
        const toggleExpandable = (e: any) => {
          document.querySelectorAll('.row--active').forEach((event) => event.classList.remove('row--active'));

          const selectedRow: any = document.getElementById(`row-${transaction.id}`);
          const expandableButton = selectedRow.firstChild.children[0];

          selectedRow.classList.add('row--active');
          if (selectedRow.parentNode.querySelector('div.rdt_ExpanderRow')) {
            selectedRow.parentNode
              .querySelector('div.rdt_ExpanderRow')
              ?.previousElementSibling.firstChild.children[0].click();
          }
          //  else {
          //   selectedRow.classList.remove('row--active');
          // }
          expandableButton.click();
        };

        const item: TransactionTableItem = {
          loanId: transaction?.contractId,
          displayId: transaction?.contractId,
          createdAt: transaction?.fundedAt,
          borrowerName: transaction?.borrowerName,
          phone: transaction?.borrowerPhone,
          store: transaction?.merchant?.name,
          salesPerson: transaction?.user,
          download: `${process.env.REACT_APP_AGREEMENT_BASE_URL}/${transaction?.idLoan}/agreement.pdf`,
          status: transaction.status,
          subStatus: transaction.subStatus,
          disbursement: getDisbursement(transaction) || 'N/A',
          amount: transaction?.grossAmount,
          refundAmount: transaction.refundAmount,
          merchantFund: transaction?.merchantFund,
          merchantRevenue: transaction?.merchantRevenue,
          merchantId: transaction?.merchantId,
          purchaseAmount: transaction?.purchaseAmount,
          product: transaction?.product,
          plans: transaction?.plans,
          promoUsed: transaction?.promoUsed,
          fundedAt: transaction?.fundedAt,
          fee: transaction?.merchantRevenue,
          id: transaction.id,
          toggleExpandable,
          openDialog,
          fundStatus: transaction?.funds?.filter((fund) => fund.directionType === 'MERCHANT_DEBIT')?.[0]?.status,
          fund: transaction?.funds, // ?.find((fund) => fund.status === 'HOLD'),
          servicingBalance: transaction?.plans?.[0]?.balance,
          refunds: transaction?.refunds,
          loanRebate: transaction?.loanRebate,
          tierLabel: transaction?.tierLabel,
          transactionType: transaction?.transactionType,
        };
        transactionsList.push(item);
      });
    }
    return transactionsList;
  };

  return { mapTransactionToTableData };
};
