import { TrashIcon } from 'lib/components/SidebarIcons';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

export interface File {
  fileName: string;
}

interface FileListProps {
  fileList: File[];
  onRemove: (index: number) => void;
}

const FileList = ({ fileList, onRemove }: FileListProps) => {
  return (
    <FileListContainer>
      {fileList?.map(({ fileName }, index) => (
        <FileListItem key={fileName}>
          <div>{fileName}</div>
          <TrashButton onClick={onRemove.bind(null, index)}>
            <TrashIcon color={colors.cherryRed} width={20} height={20} />
          </TrashButton>
        </FileListItem>
      ))}
    </FileListContainer>
  );
};

const FileListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FileListItem = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.main.aliceBlue};
  color: ${(props) => props.theme.main.black};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

const TrashButton = styled.button`
  border: 0;
  background-color: none;
  cursor: pointer;
`;

export default FileList;
