import { CustomerPlansCSVItem, Merchant, TransactionCSVItem } from 'lib/types';
import CurrencyUtil from './currency';
import { formatDateWithTime } from './DateUtil';
import { formatPhoneNumber } from './PhoneUtil';

export const parseStatementName = (url: string) => {
  const splittedUrl = url.split('/');
  const name = splittedUrl[splittedUrl.length - 1];

  const splittedName = name.split('.');
  const statementName = splittedName[0];

  return statementName;
};

export const firstNameFormatter = (firstName: string = '') =>
  firstName && firstName.slice(-1) === 's' ? firstName + `'` : firstName + `'s`;

export const checkHasClass = (el: any, cls: string) => {
  if (el) {
    return [...el.classList].includes(cls);
  } else {
    return false;
  }
};

export const clearSpecialChars = (text) => {
  if (text) {
    return text?.replace(/[^a-zA-Z0-9 ]/g, ' ');
  }
  return '';
};

export const handleCopyToClipboard = (e: any, copyItem: string) => {
  e.preventDefault();
  navigator.clipboard.writeText(copyItem);
};

export const textReplacer = (text: string, from: string, to: string) => {
  return text?.replaceAll(from, to);
};

export const removeFromArray = (arr: any, item: any) => {
  return arr?.filter((f) => f !== item);
};

export const transactionsCsvDownloadParser = (row: TransactionCSVItem, merchants: Merchant[] | null) => {
  const findLocationName = (merchantId: string) => {
    try {
      if (merchantId) {
        const findMerchantName = merchants?.find((merchant: Merchant) => merchant?.id === merchantId?.toString());
        return findMerchantName?.name || '';
      }
      return '';
    } catch (err) {
      return '';
    }
  };

  const preparedObject = [
    row?.loanId,
    formatDateWithTime(row?.createdAt),
    row?.borrowerName,
    formatPhoneNumber(row?.phone),
    CurrencyUtil.toCurrencyString(Number(row?.amount)),
    row?.refunds ? CurrencyUtil.toCurrencyString(row?.refunds?.reduce((pre, curr) => pre + curr?.amount, 0)) : 'n/a',
    CurrencyUtil.toCurrencyString(Number(row?.fee)),
    Number(row?.merchantFund) < 0 ? '$0.00' : CurrencyUtil.toCurrencyString(Number(row?.merchantFund)),
    row?.disbursement,
    row?.product?.term + ' Months',
    findLocationName(row.merchantId),
  ];

  if (merchants && merchants.length > 1) {
    preparedObject.push(findLocationName(row.merchantId));
  }

  return preparedObject;
};

export const customerPlansCsvDownloadParser = (row: CustomerPlansCSVItem) => {
  const preparedObject = [
    row?.planId,
    row?.name,
    row?.planType,
    formatDateWithTime(row?.memberSince),
    row?.paymentStatus,
  ];

  return preparedObject;
};

export const chartDataParser = (chartArray: any, chartCSVHeader: any) => {
  const finalArray: any = [];
  chartArray.map((item) => {
    const tempData: any = [];
    chartCSVHeader.map((header) => {
      tempData.push(item[header]);
    });
    finalArray.push(tempData);
  });
  return finalArray;
};

export const firstCharFormatter = (value: string | undefined) => {
  try {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return '';
  } catch {
    return value;
  }
};

export const formatTextCapitalize = (value: string | undefined) => {
  try {
    if (value) {
      let result = '';
      const splittedValue = value.split('_');
      splittedValue.map((item) => {
        result += firstCharFormatter(item) + ' ';
      });
      return result;
    }
    return '';
  } catch {
    return value;
  }
};
