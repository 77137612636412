import React from 'react';
import styled from 'styled-components';

import { CherryDialogTitle, ContinueButton, DialogContainer, SubTitle } from 'pages/desktop/Settings/components/Dialog';

interface Props {
  handleClose: () => void;
  getMerchantsLeads: () => void;
}

export const Success = ({ handleClose, getMerchantsLeads }: Props) => {
  const handleCloseHandler = () => {
    handleClose();
    getMerchantsLeads();
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleCloseHandler}>
        Location Request Submitted
      </CherryDialogTitle>
      <SubTitle>
        Your request to add a new location has been submitted for review by the Cherry underwriting team.
      </SubTitle>
      <SubTitle>
        This may take 1-3 business days. Your account representative has been notified and will reach out regarding next
        steps, such as scheduling a training session for the new location.
      </SubTitle>

      <ButtonContainer>
        <ContinueButton onClick={handleCloseHandler} data-testid="submit">
          Okay
        </ContinueButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
