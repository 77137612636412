import { Icon } from 'lib/components/mobile';
import { PreApprovalStepEnums } from 'lib/types';
import React from 'react';
import styled from 'styled-components';
import PreApprovalButtons from './PreApprovalButtons';
import { PreApprovalResult } from './type';

interface Props {
  onClose: () => void;
  preApprovalResult: PreApprovalResult | undefined;
  setActiveStep: (step: PreApprovalStepEnums) => void;
  setConfetti: (active: boolean) => void;
}

export const PreApprovalDenial = ({ onClose, preApprovalResult, setActiveStep, setConfetti }: Props) => {
  return (
    <DenialContainer>
      <StyledSubtitle>We don’t have enough info to pre-approve {preApprovalResult?.firstName}.</StyledSubtitle>

      <StyledSubDescription>
        <Icon src={'info_circle_dark'} />
        <div>
          <div>
            <b>Ask {preApprovalResult?.firstName} to Apply</b>
          </div>
          <div>
            This is not a denial. {preApprovalResult?.firstName} must complete an application to view their result.
          </div>
        </div>
      </StyledSubDescription>
      <PreApprovalButtons
        isNewASuccessScreen={false}
        onClose={onClose}
        setConfetti={setConfetti}
        setActiveStep={setActiveStep}
        preApprovalResult={preApprovalResult}
        screenName="DENIAL"
      />
    </DenialContainer>
  );
};

const StyledSubtitle = styled.div`
  width: 95%;
  padding: 14px;
  border-radius: 8px;
  background: #f2f4f5;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props?.theme?.main?.textColor};

  img {
    margin-left: 16px;
  }
`;

const DenialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSubDescription = styled.span`
  width: 100%;
  margin-top: 0;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;
