import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useSentry } from './useSentry';
import useStore from './useStore';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { GET_MERCHANT_CONTACTS } from 'lib/graphql/queries';
import { ContactList } from 'lib/types/Merchant';

const useMerchantsContactAccountList = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();
  const { organization, merchants = [] } = useStore();
  const { id } = organization || {};
  const [contactList, setContactList] = useState<ContactList | []>([]);

  const getMerchantsContactList = async () => {
    try {
      if (id) {
        const input: {
          organizationId: number;
          search?: { key: string; value: number; operator: GenericSearchOperators }[];
        } = {
          organizationId: id,
        };

        if (merchants?.length === 1) {
          input.search = [
            {
              key: 'merchantId',
              value: merchants[0]?.id,
              operator: GenericSearchOperators.EQUAL,
            },
          ];
        }

        const { data } = await client.query({
          query: GET_MERCHANT_CONTACTS,
          variables: {
            input,
          },
        });

        setContactList(data?.getMerchantContacts?.contents || []);
      }
    } catch (error) {
      captureException(error, { message: 'getMerchantsContactList Error' });
    }
  };

  useEffect(() => {
    getMerchantsContactList?.();
  }, [id, merchants]);

  const accountList = contactList?.[0]?.accounts;

  const getAccountOfType = (type: string) => {
    const accounts = accountList?.filter((data) => data?.type === type);
    return accounts?.length > 0 && accounts?.[0]?.name !== null ? accounts[0] : null;
  };

  return { accountList, getAccountOfType };
};

export default useMerchantsContactAccountList;
