import { LinearProgress } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { theme } from 'config/theme';
import dayjs from 'dayjs';
import { Icon } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export enum MilestoneState {
  IN_PROGRESS,
  SUCCESS,
  LOCKED,
}

export interface MilestoneStepProps {
  children?: ReactNode;
  currentSubProgress?: number;
  maxSubProgress?: number;
  rewardAmount?: number;
  stepNumber?: number;
  stepState?: MilestoneState;
  text?: string;
  rewardGranted?: number;
  hasReward: boolean | null;
}

export const MilestoneStep = ({
  children,
  currentSubProgress = 0,
  maxSubProgress,
  rewardAmount,
  stepState,
  stepNumber,
  text,
  rewardGranted,
  hasReward,
}: MilestoneStepProps) => {
  const { organization } = useStore();
  const { activatedAt } = organization || {};

  const getTitleIconName = () => {
    switch (stepState) {
      case MilestoneState.SUCCESS:
        return 'circle-check-active';

      case MilestoneState.LOCKED:
        return 'circle-check-grey';

      default:
        return 'circle-check-outline';
    }
  };

  const isLocked = () => stepState === MilestoneState.LOCKED;

  const PREFIX = 'LinearProgress';
  const classes = {
    root: `${PREFIX}-root`,
    bar: `${PREFIX}-cta`,
  };

  const Root = MuiStyled(LinearProgress)(() => ({
    [`& .${classes.root}`]: {
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.main.midnightBlue10,
    },
    [`& .${classes.bar}`]: {
      borderRadius: 5,
      background: theme.main.green,
    },
  }));

  const getSuccessStepText = () => {
    let successText = (
      <LastStepSuccessText>
        {hasReward ? 'You earned $10! Keep going!' : 'Starting is the hardest part! Keep going!'}
      </LastStepSuccessText>
    );
    if (stepNumber === 2) {
      successText = (
        <LastStepSuccessText>
          {hasReward ? 'Great work! On to the next level...' : 'You’re on a roll. Nothing can stop you now!'}
        </LastStepSuccessText>
      );
    } else if (stepNumber === 3) {
      successText = hasReward ? (
        <LastStepSuccessContainer>
          <LastStepSuccessText>You completed all three steps! We’re impressed.</LastStepSuccessText>
          <LastStepSuccessText>Keep an eye on your inbox for your ${rewardGranted} welcome gift!*</LastStepSuccessText>
          <LastStepInfoText>
            *Reward will be sent within 7 business days of {dayjs(activatedAt).add(30, 'day').format('MMM DD, YYYY')}.
          </LastStepInfoText>
        </LastStepSuccessContainer>
      ) : (
        <LastStepSuccessContainer>
          <LastStepSuccessText>You completed all three steps! We’re impressed.</LastStepSuccessText>
          <LastStepSuccessText>
            You’re now a <b>Cherry Expert</b> and ready to take on the world. Be on the lookout for your certification
            to arrive!
          </LastStepSuccessText>
        </LastStepSuccessContainer>
      );
    }

    return successText;
  };

  const getSubProgressPercentage = () => {
    return maxSubProgress !== undefined ? (currentSubProgress / maxSubProgress) * 100 : 0;
  };

  return (
    <Container>
      <TitleContainer>
        <Icon src={getTitleIconName()} />
        <Title faded={isLocked()}>Step {stepNumber}</Title>
      </TitleContainer>

      {rewardAmount !== undefined && (
        <>
          {stepState === MilestoneState.IN_PROGRESS && (
            <ChipContainer>
              <Icon src="gift" width={12} />
              <ChipText state={stepState}>${rewardAmount} Reward in-progress</ChipText>
            </ChipContainer>
          )}

          {stepState === MilestoneState.SUCCESS && (
            <ChipContainer success={true}>
              <Icon src="gift-active" width={12} />
              <ChipText state={stepState}>${rewardAmount} Total Reward Earned</ChipText>
            </ChipContainer>
          )}

          {stepState === MilestoneState.LOCKED && (
            <ChipContainer>
              <Icon src="lock" width={12} />
              <ChipText state={stepState}>${rewardAmount} Reward Locked</ChipText>
            </ChipContainer>
          )}
        </>
      )}

      {stepState !== MilestoneState.SUCCESS ? (
        <ContentContainer faded={isLocked()}>{children || text}</ContentContainer>
      ) : (
        getSuccessStepText()
      )}

      {maxSubProgress !== undefined && stepState === MilestoneState.IN_PROGRESS && (
        <SubProgressContainer>
          <Root value={getSubProgressPercentage()} variant="determinate" classes={classes} />
          <SubProgressContent>
            Checkouts:{' '}
            <SubProgressDetail>
              {currentSubProgress}/{maxSubProgress}
            </SubProgressDetail>
          </SubProgressContent>
        </SubProgressContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const SubProgressContainer = styled.div`
  margin-top: 8px;
`;

const SubProgressContent = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
`;

const SubProgressDetail = styled.span`
  color: ${theme.main.green};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div<{ faded?: boolean }>`
  font-size: 18px;
  font-weight: 700;
  margin-left: 16px;
  color: ${({ faded }) => (faded ? theme.main.midnightBlue40 : theme.main.black)};
`;

const ChipContainer = styled.div<{
  success?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background: ${({ success }) => (success ? theme.main.green10 : theme.main.midnightBlue10)};
  border-radius: 16px;
  width: fit-content;
  height: 24px;
  padding: 0 16px;
`;

const ChipText = styled.div<{
  state?: MilestoneState;
}>`
  color: ${({ state }) => {
    switch (state) {
      case MilestoneState.SUCCESS:
        return theme.main.green;

      case MilestoneState.LOCKED:
        return theme.main.midnightBlue50;

      default:
        return theme.main.black;
    }
  }};
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  margin-left: 6px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const ContentContainer = styled.div<{ faded?: boolean }>`
  color: ${({ faded }) => (faded ? theme.main.midnightBlue40 : theme.main.black)};
  font-size: 14px;
`;

const LastStepSuccessText = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 12px;
`;

const LastStepSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LastStepInfoText = styled.div`
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
