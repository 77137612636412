import React from 'react';
import styled from 'styled-components';

import {
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  RefundVoidPricingInfo,
  Title,
} from 'lib/components/mobile';
import useAgent from 'lib/hooks/useAgent';
import useStore from 'lib/hooks/useStore';
import { usePartialRefund } from 'lib/services';
import CurrencyUtil from 'lib/utils/currency';
import { useNavigate } from 'react-router-dom';

const MobileTransactionFullRefundReview = () => {
  const { transactionInProcess } = useStore();
  const navigate = useNavigate();
  const { fullyRefundLoan, loading } = usePartialRefund({ transaction: transactionInProcess });
  const { isAgent } = useAgent();

  const onBackClick = () => {
    navigate('/transactions');
  };
  return (
    <Container pageTitle="Full Refund">
      <DetailContainer>
        <Title>
          Are you sure you want to refund {transactionInProcess.borrowerName}{' '}
          {CurrencyUtil.toCurrencyString(transactionInProcess?.amount, true)}?
        </Title>
        <RefundVoidPricingInfo transactionInProcess={transactionInProcess} amount={transactionInProcess.amount} />
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={fullyRefundLoan} disabled={loading || isAgent} loading={loading}>
              Issue Refund
            </Button>
            <Button secondary={true} onClick={onBackClick}>
              Cancel
            </Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionFullRefundReview;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;
