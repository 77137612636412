import { DashCard, DashComponent } from 'lib/components';
import { MARKETING_MATERIAL_TYPES } from 'lib/constants';
import { Row, SubHeader } from 'lib/styles';
import DigitalItem from 'pages/desktop/OtherResourcesTemplates/components/DigitalItem';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export interface MarketingMaterialItem {
  id: number;
  title: string;
  description: string;
  subInfo?: string;
  pdfUrl?: string;
  image: string;
  documentType: MARKETING_MATERIAL_TYPES | undefined;
}

interface DigitalFilesProps {
  marketingMaterialList: MarketingMaterialItem[];
}

const DigitalFiles = ({ marketingMaterialList }: DigitalFilesProps) => {
  const marketingMaterials = useMemo(() => {
    const materials = marketingMaterialList.map(({ id, image, title, description, pdfUrl }) => (
      <DigitalItem id={id} key={title} image={image} title={title} description={description} pdf={pdfUrl} />
    ));

    materials.push(
      <DigitalItem
        qrCode={true}
        title="Your QR Code"
        description="Patients scan your unique QR code to pre-qualify. Download a digital copy to add it to your sign-in forms, posters, treatment packages, and social media content."
      />,
    );

    return materials;
  }, [marketingMaterialList]);

  return (
    <section>
      <DashComponent dashHeader="Download Digital Files" />
      <DashCard>
        <SectionTitle>Download Digital Artwork</SectionTitle>
        <Row fontSize="14px">
          If you would prefer to print files yourself, or if you want to share files digitally, you can download
          electronic versions of Cherry marketing supplies.
        </Row>
        <ItemsContainer>{marketingMaterials}</ItemsContainer>
      </DashCard>
    </section>
  );
};

const SectionTitle = styled(SubHeader)`
  margin-bottom: 8px;
  font-size: 16px;
`;

const ItemsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default DigitalFiles;
