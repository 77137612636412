import axios, { AxiosError, AxiosInstance } from 'axios';
import type { AxiosRequestConfig } from 'axios';
import axiosRetry, { isIdempotentRequestError, isNetworkError } from 'axios-retry';
import jwtDecode from 'jwt-decode';

import { API_HOST } from 'config';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';

// import logger from 'config/logger';
// import { AlertHelper } from 'lib/components/alert';
// import Auth from 'lib/services/auth';
// import { Flow } from 'lib/services/flow';
// import NavigationService from 'lib/services/navigation';

let instance: AxiosInstance;

console.log('------INITIALIZING AXIOS------');
console.log(`--API: ${API_HOST}--`);

instance = axios.create();
instance.defaults.baseURL = API_HOST;
instance.defaults.timeout = 60000;

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount: number) => {
    return retryCount * 1000;
  },
  retryCondition: (error: AxiosError<any>) => {
    return isNetworkError(error) && isIdempotentRequestError(error);
  },
});

instance.interceptors.request.use(
  (requestConfig: any) => {
    // requestConfig.headers["User-Agent"] = APP_USER_AGENT;
    requestConfig.headers = requestConfig.headers || {};

    const token = StorageService.getUserToken();
    if (requestConfig.url !== '/auth/refresh') {
      if (token && token !== null && token !== '') {
        if (!isExpiredToken(token)) {
          requestConfig.headers.Authorization = `Bearer ${token}`;
        } else {
          return resetTokenAndReattemptRequest(requestConfig);
        }
      }
    }

    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResponse = error.response;

    if (errorResponse) {
      if (
        (isUnauthorizedError(errorResponse) && !isTokenExpiredError(errorResponse.data)) ||
        (isAlreadyFetchingAccessToken && isServerError(errorResponse))
      ) {
        StorageService.clearUserData();
        console.log('===NAVIGATE SERVICE===', NavigateService.navigate);
        NavigateService.navigate('/login');
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      if (error.__CANCEL__) {
        return new Promise(() => {
          // return empty block to avoid promise resolution
        });
      } else {
        return Promise.reject(error);
      }
    }

    // If the error is due to other reasons, we just throw it back to axios
  },
);

let subscribers: any[] = [];
let isAlreadyFetchingAccessToken = false;

const resetTokenAndReattemptRequest = async (
  requestConfig: AxiosRequestConfig,
  requestType: 'req' | 'res' = 'req',
): Promise<AxiosRequestConfig> => {
  console.log('--- WARNING: JWT Timeout - Refreshing! ---');
  try {
    const { refreshToken: resetToken = '', ...rest } = StorageService.getUserData();
    if (!resetToken) {
      const reason = `Couldn't find refresh token!`;
      return Promise.reject(reason);
    }

    const retryOriginalRequest = new Promise<AxiosRequestConfig>(requestResolverBuilder(requestType)(requestConfig));

    if (!isAlreadyFetchingAccessToken) {
      /* If there is no previous call to refresh the Auth token,
      make the request. Update the value to the check so that no
      other call can be made concurrently.*/
      isAlreadyFetchingAccessToken = true;
      const response = await instance.post(`/auth/refresh`, {
        token: resetToken,
      });
      if (!response.data) {
        return Promise.reject(response);
      }
      const { token, refreshToken } = response.data;
      StorageService.setUserData({
        ...rest,
        token,
        refreshToken,
      });
      StorageService.setAuthData({ authToken: token, refreshToken });
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(token);
    }

    return retryOriginalRequest;
  } catch (err) {
    // make sure we don't lock any upcoming request in case of a refresh error
    isAlreadyFetchingAccessToken = false;
    StorageService.clearUserData();
    NavigateService.navigate('/login');
    return Promise.reject(err);
  }
};

const requestResolverBuilder = (type: 'req' | 'res') => (requestConfig: any) => (resolve: any) => {
  /* We need to add the request retry to the queue
  since there another request that already attempt to
  refresh the token */
  addSubscriber((authToken: string) => {
    requestConfig.headers.Authorization = 'Bearer ' + authToken;
    resolve(type === 'req' ? requestConfig : axios(requestConfig));
  });
};

const addSubscriber = (cb: (authToken: string) => void) => subscribers.push(cb);

const onAccessTokenFetched = (authToken: string) => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((cb) => cb(authToken));
  subscribers = [];
};

const isExpiredToken = (token: string = '') => {
  try {
    /* in case the call succeeds at the end of timeout,
    remove the amount of timeout from our current time
    to avoid 401 from server */
    const tokenExpiration = (jwtDecode(token) as any).exp - 40;
    const currentTime = Date.now().valueOf() / 1000;

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};

const isUnauthorizedError = ({ status }: { status: number }) => status === 401;
const isTokenExpiredError = ({ code }: { code: string }) => code === 'auth.token.expired';
const isServerError = ({ status }: { status: number }) => status >= 500 && status < 600;

export default instance;
