import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { TableLoader } from 'lib/components';
import { CherryDatePicker } from 'lib/components/CherryDatePicker';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import ProcessContractModal from 'lib/modals/ProcessContractModal';
import { useApplicationFilter } from 'lib/services';
import { DataTableStyle } from 'lib/utils';
import { DentalCheckoutModal } from '../DentalDashboard/views/DentalCheckoutModal';
import { ApplicationHorizontalFilter } from './ApplicationHorizontalFilter';
import './ApplicationList.css';

import { ExtendApprovalModal } from './ExtendApprovalModal';
import { OutboundMessageModal } from './OutboundMessageModal';
import { TakeHomeApprovalModal } from './TakeHomeApproval/TakeHomeApprovalModal';
import { TextReminderModal } from './TextReminderModal';

interface ExpandedProps {
  approval: any[];
}

interface GridProps {
  scrollOffset: number;
  showRangePicker?: boolean;
  filterWidth?: number;
}

export const ApplicationList = () => {
  const { trackPage } = useSegment();
  const { takeHomeApprovalModalOpen, setGenerateTakeHomeConfirmationOpen } = useStore();

  const {
    updatedLoan,
    loanUpdated,
    setLoanUpdated,
    setExpandableOpened,
    setSelectedItem,
    tableColumns,
    approvals,
    loading,
    paginationLoading,
    pageChange,
    rowsCountChange,
    total,
    onTableSort,
    selectedModalItem,
    handleRowClicked,
    gridRef,
    scrollOffset,
    updateApplicationRequest,
    filterHeight,
    showSchedule,
    onScheduleDateSelected,
    toggleDatePicker,
    dataTableRef,
    outboundMessages,
    textReminderData,
    extendApproval,
    closeOutboundMessageModal,
    closeTextReminderModal,
    closeExtendApprovalModal,
    isCheckoutModalOpened,
    toggleIsCheckoutModalOpened,
    refreshTable,
  } = useApplicationFilter();

  useEffect(() => {
    trackPage('applicationsPage');
  }, []);

  const onCloseGenerateTakeHomeModal = () => {
    setGenerateTakeHomeConfirmationOpen(true);
  };

  const ExpandedComponent = React.useMemo(
    () => ({ approval }: ExpandedProps) => {
      return (
        <ProcessContractModal
          selectedModalItem={approval}
          isLoanUpdated={updatedLoan}
          loanUpdated={loanUpdated}
          setLoanUpdated={setLoanUpdated}
          setExpandableOpened={setExpandableOpened}
          setSelectedItem={setSelectedItem}
          selectedAppointment={null}
        />
      );
    },
    [updatedLoan],
  );

  const closeCheckoutModal = () => {
    refreshTable();
    toggleIsCheckoutModalOpened();
  };

  const getExpandableRowsComponent = () => <ExpandedComponent approval={selectedModalItem} />;

  return (
    <LoanListWrapper>
      <GridContainer container={true} ref={gridRef}>
        <ControlGrid scrollOffset={scrollOffset} item={true} sm={3} />

        <RightGrid
          item={true}
          filterHeight={filterHeight}
          sm={11}
          md={12}
          id="applications--listing"
          ref={dataTableRef}
        >
          <ApplicationHorizontalFilter />
          <TableRow>
            <TableColumn>
              <DataTable
                className="data-table"
                customStyles={DataTableStyle}
                pointerOnHover={true}
                keyField="applicationId"
                noHeader={true}
                columns={tableColumns}
                data={approvals}
                pagination={true}
                paginationServer={true}
                progressPending={loading || paginationLoading}
                progressComponent={<TableLoader count={4} />}
                onChangePage={pageChange}
                onChangeRowsPerPage={rowsCountChange}
                paginationTotalRows={total}
                onSort={onTableSort}
                expandableRows={true}
                expandableRowsComponent={getExpandableRowsComponent}
                onRowClicked={handleRowClicked}
                expandOnRowClicked={true}
              />
            </TableColumn>
          </TableRow>
        </RightGrid>
      </GridContainer>

      <CherryDatePicker
        show={showSchedule}
        onDateSelected={onScheduleDateSelected}
        customClassName={'schedule--picker'}
        confirmText={'Schedule'}
        toggleDatePicker={toggleDatePicker}
      />

      <DentalCheckoutModal
        selectedItemData={selectedModalItem}
        open={isCheckoutModalOpened}
        onClose={closeCheckoutModal}
        onSubmit={toggleIsCheckoutModalOpened}
      />

      <OutboundMessageModal
        onClose={closeOutboundMessageModal}
        open={Boolean(outboundMessages)}
        messages={outboundMessages}
      />

      <TextReminderModal
        onClose={closeTextReminderModal}
        open={Boolean(textReminderData)}
        reminderData={textReminderData}
      />

      <ExtendApprovalModal
        onClose={closeExtendApprovalModal}
        updateLoanRequest={updateApplicationRequest}
        open={Boolean(extendApproval)}
        reminderData={extendApproval}
      />

      <TakeHomeApprovalModal
        onClose={onCloseGenerateTakeHomeModal}
        open={takeHomeApprovalModalOpen && selectedModalItem}
        balanceAvailable={selectedModalItem?.balanceAvailable}
        selectedFirstName={selectedModalItem?.borrower?.firstName}
        selectedLastName={selectedModalItem?.borrower?.lastName}
        selectedPhone={selectedModalItem?.borrower?.phone}
        selectedApplicationId={selectedModalItem?.id}
      />
    </LoanListWrapper>
  );
};

const ControlGrid = styled(Grid)<GridProps>`
  padding: 0px;
  padding-right: 16px;
  display: none;
`;

const RightGrid = styled(Grid)<{ filterHeight: string | number }>`
  margin: 0;
  padding: 0;
  min-height: ${(props) => `${props.filterHeight}px`};
`;

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  .data-table {
    overflow: inherit;
  }
`;

interface TableColumnProps {
  flex?: number;
}

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow > div:first-child {
    display: none !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    > div:first-child {
      display: none;
    }

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const LoanListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  div[data-tag='___react-data-table-allow-propagation___'] {
    overflow: visible !important;
  }
  margin-bottom: 150px;
`;

const GridContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
