import { gql } from '@apollo/client/core';

export const CREATE_POINT_OF_SALE_SMS_LINK = gql`
  mutation CreatePointOfSaleSmsLink($input: CreatePointOfSaleLinkInput) {
    createPointOfSaleSmsLink(input: $input) {
      id
      organizationId
      applicationId
      firstName
      lastName
      phone
      treatmentAmount
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_POINT_OF_SALE_STATUS = gql`
  mutation UpdatePointOfSaleStatus($input: UpdatePointOfSaleStatusInput) {
    updatePointOfSaleStatus(input: $input) {
      id
      organizationId
      applicationId
      firstName
      lastName
      phone
      treatmentAmount
      status
      createdAt
      updatedAt
    }
  }
`;
