import { TrashIcon } from 'lib/components';
import { formatDateWithTime } from 'lib/utils';
import React, { useMemo } from 'react';

export interface ApiKeyTableItem {
  id: string;
  name: string;
  createdAt: string;
  last4: string;
  lastAccessedAt: string;
  status: string;
  updatedAt: string;
}

export interface GenerateApiKeyResponse {
  apiKey: ApiKeyTableItem;
  secret: string;
  success: string;
  code: string;
  message: string;
}

export const useApiListTableColumns = () => {
  const getActionItemsRow = (row) => {
    return (
      <div style={{ cursor: 'pointer' }} className="delete-key-button" onClick={row.deleteSelectedItem}>
        <TrashIcon color={'#EC3360'} width={18} height={18} />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Key Name',
        selector: (row) => row.name,
        minWidth: '180px',
        maxWidth: '200px',
        ignoreRowClick: true,
      },
      {
        name: 'Last Digits',
        selector: (row) => row.last4,
        minWidth: '180px',
        maxWidth: '200px',
      },
      {
        name: 'Last Accessed',
        selector: (row) => row.lastAccessedAt,
        cell: (row) => (row.lastAccessedAt ? formatDateWithTime(row.lastAccessedAt) : 'Never'),
        minWidth: '180px',
        maxWidth: '200px',
      },
      {
        name: '',
        cell: (row) => getActionItemsRow(row),
        minWidth: '30px',
        right: true,
      },
    ],
    [],
  );

  return { tableColumns };
};
