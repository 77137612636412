// tslint:disable jsx-no-lambda
import React from 'react';
import styled from 'styled-components';
import images from 'assets/images';
import { Card } from 'lib/components';
import { ButtonPrimary } from 'lib/components';
import { useNavigate } from 'react-router-dom';

interface Props {
  isFullHeight?: boolean;
}

export interface FormData {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  ssn?: string;
  phone?: string;
  email?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  individualAnnualIncome?: string;
  monthlyHousingCost?: string;
  estimatedTreatmentCost?: string;
}
export interface IAutoComplete {
  id: string | number;
  label: string;
  street: string;
  city: string;
  state: string | number;
  zip: string | number;
}

export interface ISelectedAddress {
  street: string;
  city: string;
  state: string;
  zip: string | number;
}

export interface IAddress {
  city: string;
  state: string;
  zipcode: string;
  streetLine: string;
}

export const CompleteApplication = ({ isFullHeight = false }: Props) => {
  const navigate = useNavigate();

  const handleNavigateApplyWithPatient = () => {
    navigate('/apply-with-patient');
  };

  return (
    <CompleteApplicationCard isFullHeight={isFullHeight}>
      <CardTitleContainer>
        <CardIcon src={images?.manualApplication} />
        <CardTextContainer>
          <CardTitle>Complete Application</CardTitle>
          <CardText>Fill out application with patient</CardText>
        </CardTextContainer>
      </CardTitleContainer>
      <StyledButton onClick={handleNavigateApplyWithPatient} text="Apply with Patient" />
    </CompleteApplicationCard>
  );
};

const CompleteApplicationCard = styled(Card)<{ isFullHeight: boolean }>`
  padding: 20px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  align-items: center;
  justify-content: center;
  ${(props) => (props.isFullHeight ? '' : 'height: auto;')};
  width: 100%;
  margin-top: ${(props) => (props.isFullHeight ? '0' : '14px')};
`;

const CardTitle = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: ${(props) => props.theme.main.textColor};
`;

const CardIcon = styled.img`
  width: 43px;
  height: 47px;
`;

const CardText = styled.p`
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.main.textColor};
`;

const CardTitleContainer = styled.div`
  display: flex;
  margin-bottom: 14px;
  align-items: center;
`;

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 16px;
`;

const StyledButton = styled(ButtonPrimary)`
  width: 100%;
  max-width: 259px;
`;
