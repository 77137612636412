import { gql } from '@apollo/client/core';

export const CREATE_LINK_TREE_LINK = gql`
  mutation CreateLinkTreeLink($input: CreateLinkTreeLinkRequest) {
    createLinkTreeLink(input: $input) {
      success
    }
  }
`;

export const UPDATE_LINK_TREE_LINKS = gql`
  mutation UpdateLinkTreeLinks($input: UpdateLinkTreeLinksRequest) {
    updateLinkTreeLinks(input: $input) {
      success
    }
  }
`;

export const UPDATE_LINK_TREE_COLORS = gql`
  mutation UpdateLinkTreeColors($input: UpdateLinkTreeColorsRequest) {
    updateLinkTreeColors(input: $input) {
      success
    }
  }
`;

export const UPDATE_LINK_TREE_PROFILE_IMAGE = gql`
  mutation UpdateLinkTreeProfileImage($input: UpdateLinkTreeProfileImageRequest) {
    updateLinkTreeProfileImage(input: $input) {
      success
    }
  }
`;

export const CREATE_MINIMUM_APPROVAL = gql`
  mutation createMinimumApproval($input: CreateMinimumApprovalRequest) {
    createMinimumApproval(input: $input) {
      id
      amount
      isUpdatable
      updatableAt
    }
  }
`;

export const CREATE_MINIMUM_TRANSACTION_AMOUNT = gql`
  mutation CreateMinimumTransactionsAmount($input: CreateMinimumTransactionsAmountRequest) {
    createMinimumTransactionsAmount(input: $input) {
      isUpdatable
      minimumPromoTransactions {
        term
        amount
      }
      updatableAt
    }
  }
`;

export const UPDATE_MINIMUM_TRANSACTION_AMOUNT = gql`
  mutation UpdateMinimumTransactionsAmount($input: UpdateMinimumTransactionsAmountRequest) {
    updateMinimumTransactionsAmount(input: $input) {
      isUpdatable
      minimumPromoTransactions {
        term
        amount
      }
      updatableAt
    }
  }
`;

export const UPDATE_MINIMUM_APPROVAL = gql`
  mutation updateMinimumApproval($input: UpdateMinimumApprovalRequest) {
    updateMinimumApproval(input: $input) {
      id
      amount
      isUpdatable
      updatableAt
    }
  }
`;

export const SEND_BULK_SMS = gql`
  mutation sendBulkSms($input: SendBulkSmsRequest) {
    sendBulkSms(input: $input) {
      success
      message
    }
  }
`;

export const SEND_ESTIMATE_EMAIL = gql`
  mutation sendEstimateEmail($input: SendEstimateEmailRequest) {
    sendEstimateEmail(input: $input) {
      success
      message
    }
  }
`;

export const SEND_ESTIMATE_SMS = gql`
  mutation sendEstimateSms($input: SendEstimateSmsRequest) {
    sendEstimateSms(input: $input) {
      success
      message
    }
  }
`;

export const SEND_TAKE_HOME_EMAIL = gql`
  mutation sendTakeHomeEmail($input: SendTakeHomeEmailRequest) {
    sendTakeHomeEmail(input: $input) {
      success
      message
    }
  }
`;

export const SEND_TAKE_HOME_SMS = gql`
  mutation sendTakeHomeSms($input: SendTakeHomeSmsRequest) {
    sendTakeHomeSms(input: $input) {
      success
      message
    }
  }
`;

export const CREATE_CHECKOUT_WITH_PATIENT_LINK = gql`
  mutation createCheckoutWithPatientLink($input: CreateCheckoutWithPatientLinkRequest) {
    createCheckoutWithPatientLink(input: $input) {
      success
      link
    }
  }
`;

export const SEND_CHECKOUT_WITH_PATIENT_CONFIRMATION_LINK = gql`
  mutation sendCheckoutWithPatientConfirmationLink($input: SendCheckoutWithPatientConfirmationLinkRequest) {
    sendCheckoutWithPatientConfirmationLink(input: $input) {
      success
    }
  }
`;

export const SEND_SELF_CHECKOUT_LINK = gql`
  mutation sendSelfCheckoutLink($input: SendCheckoutWithPatientConfirmationLinkRequest) {
    sendSelfCheckoutLink(input: $input) {
      success
    }
  }
`;
