import { useEffect, useRef, useState } from 'react';
import {
  LocationWrapper,
  PdfDisclaimer,
  PdfDivider,
  PdfFooter,
  PdfFooterLeftColumn,
  PdfHeader,
  PdfInnerWrapper,
  PdfMinPaymentTitle,
  PdfPageWrapper,
  PdfPlanTableContainer,
  PdfSubtitle,
  PdfSummary,
  PdfTableAndQrWrapper,
  PdfTableDivider,
  PdfTableWrapper,
  PdfText,
  PdfTitle,
  QRBox,
  QRText,
  QRTitle,
  QRWrapper,
  TableSkeleton,
  ZeroAprCellWrapper,
} from '../styles';

import { formatAmount, formatPhoneNumber } from '@frontend/cherry-library';
import { ReactComponent as DisclaimerIcon } from 'assets/images/apr.svg';
import { ReactComponent as CherryLogo } from 'assets/images/chery-logo-pdf.svg';
import { PdfProps } from '../types';
import { checkIfEmpty, isSafari, QR_CODE_CONFIG, SAFARI_STYLE_MIN_ELIGIBLE_WINDOW_WIDTH } from '../utils';

import { APP_PREQUAL_URL } from 'config';
import dayjs from 'dayjs';
import QRCode from 'easyqrcodejs';
import useStore from 'lib/hooks/useStore';

export const TreatmentPlanPdf = ({ patient, plan, isTableLoading, isModal = false }: PdfProps) => {
  const { organization } = useStore();
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const BASE_URL = APP_PREQUAL_URL?.substring(8);
  const [safariStyle, setSafariStyle] = useState({});

  useEffect(() => {
    if (isSafari() && window.innerWidth >= SAFARI_STYLE_MIN_ELIGIBLE_WINDOW_WIDTH && !isModal) {
      setSafariStyle({
        fontSize: '8px',
      });
    }
  }, []);

  const TableSkeletonBox = (
    <tr>
      <td>
        <TableSkeleton isPdf={true} />
      </td>
      <td>
        <TableSkeleton isPdf={true} />
      </td>
      <td>
        <TableSkeleton isPdf={true} />
      </td>
    </tr>
  );
  useEffect(() => {
    if (qrCodeRef.current?.childNodes?.length === 0) {
      // tslint:disable-next-line no-unused-declaration
      const qr = new QRCode(qrCodeRef.current, {
        text: `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=merchant&utm_medium=qr_code_estimate&utm_campaign=v1`,
        ...QR_CODE_CONFIG,
      });
    }
  }, []);

  const getMerchantAddress = () => {
    if (patient?.location?.id) {
      return (
        <>
          {patient?.location?.address?.address1}
          <br />
          {patient.location.address.city?.name}, {patient.location.address.city.state?.code},
          {patient.location.address.zip}
        </>
      );
    } else {
      return '2 Embarcadero Center, San Francisco, CA, 94111';
    }
  };

  return (
    <PdfPageWrapper>
      <PdfInnerWrapper
        id="payment-estimator-print-pdf"
        className={isModal ? 'pdf-wrapper-modal' : 'pdf-wrapper'}
        style={safariStyle}
      >
        <div>
          <PdfTitle>
            Treatment Plan for {checkIfEmpty(patient?.name) || 'Jane'} {checkIfEmpty(patient?.surname) || 'Doe'}
          </PdfTitle>
          <PdfHeader>
            <PdfSummary>
              Prepared for {checkIfEmpty(patient?.name) || 'Jane'} {checkIfEmpty(patient?.surname) || 'Doe'} on{' '}
              {dayjs().format('MMMM D, YYYY')}.
            </PdfSummary>
            <LocationWrapper>
              <PdfSummary>
                <b>{patient?.location?.name || 'Cherry Practice'}</b>
              </PdfSummary>
              <div>
                <PdfSummary>{getMerchantAddress()}</PdfSummary>
                <PdfSummary> {formatPhoneNumber(patient?.locationPhone || '') || '(888)-839-7171'}</PdfSummary>
              </div>
            </LocationWrapper>
          </PdfHeader>
          <PdfDivider />
          <PdfSubtitle>Consultation Summary</PdfSubtitle>
          <PdfSummary isConsultation={true}>{patient?.notes || 'No summary'}</PdfSummary>
          <PdfDivider />

          <PdfSubtitle>Monthly Payment Options with Cherry</PdfSubtitle>
          <PdfTableAndQrWrapper>
            <PdfTableWrapper>
              <div>
                <PdfText>Total Treatment Cost</PdfText>
                <PdfMinPaymentTitle>{formatAmount(patient?.amount || 0)}</PdfMinPaymentTitle>
              </div>

              <div>
                <PdfText>Payment Plans as low as</PdfText>
                {isTableLoading ? (
                  <TableSkeleton isPdf={true} isLowestOption={true} />
                ) : (
                  <PdfMinPaymentTitle>
                    {plan ? formatAmount(plan[plan?.length - 1]?.paymentAmount) : '0.0'} / month
                  </PdfMinPaymentTitle>
                )}
                <PdfText>For qualifying patients</PdfText>
              </div>

              <div>
                <PdfPlanTableContainer>
                  <tr>
                    <th>Plan Length</th>
                    <th>Monthly Payment</th>
                    <th>Total Finance Charge</th>
                  </tr>
                  {isTableLoading ? (
                    <>
                      {TableSkeletonBox}
                      {TableSkeletonBox}
                      {TableSkeletonBox}
                    </>
                  ) : (
                    plan?.map((option, index) => (
                      <tr key={index}>
                        <td>{option?.term} Months</td>
                        <td>{formatAmount(option?.paymentAmount)}</td>
                        <td>
                          {option?.apr === 0 ? (
                            <ZeroAprCellWrapper>
                              <b>{`${formatAmount(option?.financeCharge)} (${option?.apr}% APR)`}</b>
                              <DisclaimerIcon className="pdf-zero-apr-icon" />
                            </ZeroAprCellWrapper>
                          ) : (
                            `${formatAmount(option?.financeCharge)} (${option?.apr}% APR)`
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </PdfPlanTableContainer>
                <PdfTableDivider />
                <PdfText>A down payment equal to the monthly payment is due at the time of purchase.</PdfText>
              </div>
            </PdfTableWrapper>
            <QRWrapper>
              <QRTitle>Affordable payment plans with Cherry!</QRTitle>
              <QRText>
                To see if you qualify, scan the QR code or visit <br />
                <b>
                  {BASE_URL}/ <br />
                  {organization?.slug}
                </b>
              </QRText>
              <QRBox ref={qrCodeRef} />
            </QRWrapper>
          </PdfTableAndQrWrapper>
        </div>

        <PdfFooter>
          <PdfFooterLeftColumn>
            <div>
              <PdfDisclaimer>
                Disclaimer: This is an example only. Exact terms and APR depend on credit score and other factors.
              </PdfDisclaimer>
              <PdfDisclaimer>
                <>{<DisclaimerIcon className="pdf-zero-apr-icon" />}</>0% APR and other promotional rates subject to
                eligibility.
              </PdfDisclaimer>
            </div>
            <div>
              <PdfText>
                Payment options through Cherry Technologies, Inc. are issued by the following lending partners:
              </PdfText>
              <PdfText>
                https://withcherry.com/lending-partners. Iowa only: Borrowers are subject to Iowa state specific
                underwriting criteria. APR for all Iowa borrowers is capped at 20.99%.
              </PdfText>
            </div>
          </PdfFooterLeftColumn>

          <CherryLogo className="pdf-cherry-logo" />
        </PdfFooter>
      </PdfInnerWrapper>
    </PdfPageWrapper>
  );
};
