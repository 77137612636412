import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material';
import { ButtonPrimary } from 'lib/components';
import { DialogTitle } from 'pages/desktop/Settings/components/Dialog';
import React from 'react';
import styled from 'styled-components';

interface GenericFileModalProps extends MuiDialogProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  buttonLeftText?: string | null;
  onButtonLeftClick: (params?: unknown) => void;
  mainButtonText: string;
  onMainButtonClick: (params?: unknown) => void;
  loading?: boolean;
}

const GenericDialogModel = ({
  title,
  children,
  buttonLeftText,
  onButtonLeftClick,
  mainButtonText,
  onMainButtonClick,
  loading = false,
  open,
  ...rest
}: GenericFileModalProps) => {
  const isLeftButtonVisible = !!buttonLeftText && !!onButtonLeftClick;

  return (
    <Dialog open={open} onClose={onButtonLeftClick} aria-labelledby="customized-dialog-title" {...rest}>
      <DialogTitleModal id="customized-dialog-title" onClose={onButtonLeftClick}>
        {title}
      </DialogTitleModal>
      <Content>
        {children}
        <ButtonContainer>
          {isLeftButtonVisible ? (
            <OutlinedButton onClick={onButtonLeftClick} disabled={loading}>
              {buttonLeftText}
            </OutlinedButton>
          ) : null}
          <Button onClick={onMainButtonClick} text={mainButtonText} disabled={loading} />
        </ButtonContainer>
      </Content>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 480px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
`;

const DialogTitleModal = styled(DialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
`;

const Button = styled(ButtonPrimary)`
  width: 100%;
  height: fit-content;
  border-radius: 4px !important;
`;

const OutlinedButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  background: transparent;
  border: 1px solid ${(props) => props.theme.main.sealGray};
  border-radius: 4px;
  color: ${(props) => props.theme.main.sealGray};
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 24px !important;
`;

export default GenericDialogModel;
