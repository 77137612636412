import { gql } from '@apollo/client/core';

export const FETCH_SALES_REPORT = gql`
  query getSalesReport($input: SalesReportInput) {
    getSalesReport(input: $input) {
      data {
        contracts
        grossSales
        netSales
        refunds
        fees
      }
      success
      code
      message
    }
  }
`;

export const FETCH_TOTAL_SALES_REPORT = gql`
  query getTotalSalesReport($input: ReportInput) {
    getTotalSalesReport(input: $input) {
      data {
        contracts
        grossSales
        netSales
        refunds
        fees
      }
      success
      code
      message
    }
  }
`;

export const FETCH_APPROVALS_REPORT = gql`
  query getApprovalsReport($input: ApprovalsReportInput) {
    getApprovalsReport(input: $input) {
      data {
        outstanding
        rate
      }
      success
      code
      message
    }
  }
`;

export const FETCH_TOTAL_APPROVALS_REPORT = gql`
  query getTotalApprovalsReport($input: ReportInput) {
    getTotalApprovalsReport(input: $input) {
      data {
        outstanding
        rate
      }
      success
      code
      message
    }
  }
`;

export const FETCH_SUMMARY_CHART_DATA = gql`
  query GetChartSummary($input: ReportInput) {
    getTotalApprovalsSummary(input: $input) {
      totalApprovals
      totalApprovalAmount
      timeInterval
      error_message
      error_code
      contents {
        name
        data
      }
      avgTotalAmount
    }
    getTotalExpiredApprovalsSummary(input: $input) {
      avgExpiredApprovalAmount
      contents {
        name
        data
      }
      error_code
      error_message
      expiredApprovals
      expiredRate
      timeInterval
      totalExpiredApprovals
    }
    getTotalRepeatTransactions(input: $input) {
      totalRepeatTransactions
      timeInterval
      repeatRate
      error_message
      error_code
      contents {
        repeatedTransactionCount
        nonRepeatedTransactionCount
        name
      }
      avgRepeatTransactionAmount
      totalRepeatTransactionsAmount
    }
    getTotalTransactionAmounts(input: $input) {
      totalTransactions
      totalTransactionAmount
      timeInterval
      error_message
      error_code
      contents {
        data
        name
      }
      avgTransactionAmount
    }
  }
`;

export const FETCH_APPROVALS_APPLICATIONS = gql`
  query GetTotalApplications($applicationInput: ReportInput, $approvalInput: ReportInput) {
    getTotalApplications(input: $applicationInput) {
      totalIncompleteApplications
      totalApplications
      timeInterval
      incompletionRate
      error_code
      contents {
        completeApplications
        incompleteApplications
        name
      }
      error_message
      approvalRate
    }
    getTotalApprovalsSummary(input: $approvalInput) {
      totalApprovals
      totalApprovalAmount
      timeInterval
      error_code
      error_message
      avgTotalAmount
      contents {
        name
        data
      }
    }
  }
`;

export const FETCH_EXPIRED_APPROVALS = gql`
  query GetTotalExpiringSoonApprovalsSummary($expiredSoonInput: ReportInput, $totalExpiredInput: ReportInput) {
    getTotalExpiringSoonApprovalsSummary(input: $expiredSoonInput) {
      totalApprovalExpiringSoon
      timeInterval
      error_message
      error_code
      contents {
        name
        data1
        data2
      }
      avgAmountOfApprovalsExpiringSoon
      approvalExpiringSoon
    }
    getTotalExpiredApprovalsSummary(input: $totalExpiredInput) {
      avgExpiredApprovalAmount
      contents {
        name
        data
      }
      error_message
      error_code
      expiredApprovals
      expiredRate
      timeInterval
      totalExpiredApprovals
    }
  }
`;

export const FETCH_TRANSACTIONS_CONVERSATIONS = gql`
  query GetTotalTransactionAmounts($transactionInput: ReportInput, $conversationInput: ReportInput) {
    getTotalTransactionAmounts(input: $transactionInput) {
      avgTransactionAmount
      contents {
        name
        data
      }
      error_code
      error_message
      timeInterval
      totalTransactionAmount
      totalTransactions
    }
    getTransactionsConversationRates(input: $conversationInput) {
      avgApprovalAmount
      avgRemainingApprovalBalance
      avgTransactionAmount
      contents {
        transactionCount
        name
        applicationCount
      }
      error_code
      error_message
      timeInterval
      transactionConversionRate
    }
  }
`;

export const FETCH_PATIENT_BREAKDOWN = gql`
  query GetTotalRepeatTransactions($totalRepeatInput: ReportInput, $repeatDistributionInput: ReportInput) {
    getTotalRepeatTransactions(input: $totalRepeatInput) {
      totalRepeatTransactions
      timeInterval
      error_message
      repeatRate
      contents {
        repeatedTransactionCount
        nonRepeatedTransactionCount
        name
      }
      error_code
      avgRepeatTransactionAmount
      totalRepeatTransactionsAmount
    }
    getRepeatTransactionDistribution(input: $repeatDistributionInput) {
      repeatPaymentDistributions {
        contents {
          name
          data
        }
      }
      paymentPlanDistributions {
        contents {
          data
          name
        }
      }
    }
  }
`;

// export const DASHBOARD_REPORTS = gql`
//   query getDashboardReports($salesInput: SalesReportInput, $approvalsInput: ApprovalsReportInput) {
//     getSalesReport(input: $salesInput) {
//       data {
//         contracts
//         grossSales
//         netSales
//       }
//       success
//       code
//       message
//     }
//     getApprovalsReport(input: $approvalsInput) {
//       data {
//         outstanding
//         rate
//       }
//       success
//       code
//       message
//     }
//   }
// `;
