import { DialogProps as MuiDialogProps } from '@mui/material';
import GenericFileModal from 'pages/desktop/DocumentRequests/components/GenericFileModal/GenericFileModal';
import React from 'react';
import styled from 'styled-components';

enum ErrorTypes {
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
  ATTACHMENT_FAILED = 'ATTACHMENT_FAILED',
  ATTACHMENT_LIMIT_REACHED = 'ATTACHMENT_LIMIT_REACHED',
}

export type ErrorType = 'FILE_TOO_LARGE' | 'UNSUPPORTED_FILE_TYPE' | 'ATTACHMENT_FAILED' | 'ATTACHMENT_LIMIT_REACHED';

interface ErrorModalProps extends MuiDialogProps {
  type: ErrorType;
  onCloseModal: () => void;
}

export const ERROR_MODAL_CONTENT = {
  [ErrorTypes.FILE_TOO_LARGE]: {
    title: 'File Too Large',
    text: ['The file you uploaded is too large.', 'Please upload another file less than 20MB.'],
  },
  [ErrorTypes.UNSUPPORTED_FILE_TYPE]: {
    title: 'Unsupported File Type',
    text: ['Unsupported file type, please try again.', 'We support JPG, PNG, PDF or HEIC files'],
  },
  [ErrorTypes.ATTACHMENT_FAILED]: {
    title: 'Attachment Failed',
    text: ['The file upload failed, please try again.'],
  },
  [ErrorTypes.ATTACHMENT_LIMIT_REACHED]: {
    title: 'Attachment Limit Reached',
    text: ['You have uploaded the maximum number of files for this request.', 'Please submit your documents.'],
  },
};

const ErrorModal = ({ type, onCloseModal, ...rest }: ErrorModalProps) => {
  return (
    <GenericFileModal
      {...rest}
      title={ERROR_MODAL_CONTENT[type].title}
      mainButtonText="Okay"
      onMainButtonClick={onCloseModal}
      onClose={onCloseModal}
    >
      {ERROR_MODAL_CONTENT[type].text.map((row, index) => (
        <Text key={`error-modal-text-${index}`}>{row}</Text>
      ))}
    </GenericFileModal>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export default ErrorModal;
