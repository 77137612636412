import { ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
`;

export const SecondaryContinueButton = styled(ContinueButton)`
  font-weight: 600;
  width: 300px;

  margin-left: 16px;
`;

export const BackButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.white};
  color: ${(props) => props.theme.main.midnightBlue};
  border: 1px solid ${(props) => props.theme.main.midnightBlue};
  width: 75px;
`;

export const SecondaryBackButton = styled(BackButton)`
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
