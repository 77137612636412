import { Dialog, Grid } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { SEND_BULK_MEMBERSHIP_SMS } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { BulkSmsEnums } from 'lib/types';
import { arrayToCsv, BulkSmsCSVHeaders, downloadBlobByContentType, formatPhoneNumber } from 'lib/utils';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { useAlert } from 'react-alert';

const requiredColumnNumber = 1;

interface CSVRowData {
  rowNumber: number;
  phoneNumber: string;
  valid?: boolean;
}

export const BulkSmsModal = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState<BulkSmsEnums>(BulkSmsEnums.ADD_RECIPIENTS);
  const [rowList, setRowList] = useState<CSVRowData[]>([]);

  const resetOnClose = () => {
    handleClose();
    setActiveStep(BulkSmsEnums.ADD_RECIPIENTS);
  };

  switch (activeStep) {
    case BulkSmsEnums.ADD_RECIPIENTS:
      return (
        <AddRecipients resetOnClose={resetOnClose} open={open} setRowList={setRowList} setActiveStep={setActiveStep} />
      );
    case BulkSmsEnums.CSV_UPLOAD_SUCCESS:
      return (
        <CsvUploadSuccess open={open} resetOnClose={resetOnClose} rowList={rowList} setActiveStep={setActiveStep} />
      );
    case BulkSmsEnums.BULK_SMS_SUCCESS:
      return <BulkSmsSuccess resetOnClose={resetOnClose} open={open} rowList={rowList} />;
  }
};

const AddRecipients = ({ resetOnClose, open, setRowList, setActiveStep }) => {
  const alert = useAlert();
  const fileName = `bulk-recipients-example.csv`;

  const downloadCSV = () => {
    let csv = null;
    const parsedCustomerPhones: any = [['9874506565'], ['9874503333'], ['9504401122']];
    csv = arrayToCsv([BulkSmsCSVHeaders, ...parsedCustomerPhones]);
    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
  };

  const uploadCSV = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (ev) => {
      const target = ev.target as HTMLInputElement;
      const files = target.files;

      if (files && files.length > 0) {
        const file = files.item(0);
        const reader = new FileReader();
        reader.readAsText(file as Blob);
        reader.onload = () => {
          try {
            const csv = reader.result as string;
            const obj = csv
              .split(/\r?\n/)
              .filter((elm) => elm !== '')
              .map((elm) => elm.split(','));

            const array: CSVRowData[] = [];
            for (let i = 1; i < obj.length; i++) {
              const elm = obj[i];
              const rowNumber = i + 1;

              if (elm.length !== requiredColumnNumber) {
                throw new Error(
                  `Invalid csv. Row ${rowNumber} has ${elm.length} column but instead it should have ${requiredColumnNumber} column.`,
                );
              }

              const phoneNumber = elm[0];
              const formatted = formatPhoneNumber(phoneNumber);
              const valid = /\((\d{3})\) (\d{3})-(\d{4})\./.test(formatted.concat('.'));

              array.push({
                rowNumber,
                phoneNumber: formatted,
                valid,
              });
            }

            setRowList(array);
            setActiveStep(BulkSmsEnums.CSV_UPLOAD_SUCCESS);
          } catch (err) {
            alert.error((err as Error).toString());
          }
        };
      }
    };
    input.click();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={resetOnClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Add Recipients
        </CherryDialogTitle>
        <SubTitle>Upload a CSV file with recipient phone numbers.</SubTitle>
        <SubTitle>
          Your file should have <b>one column with phone numbers only.</b>
        </SubTitle>
        <Text>Example CSV File:</Text>
        <Link onClick={downloadCSV}>bulk-recipients-example.csv</Link>

        <Grid container={true} spacing={1}>
          <ConfirmButton disabled={false} onClick={uploadCSV}>
            Upload CSV File
          </ConfirmButton>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const CsvUploadSuccess = ({ resetOnClose, open, rowList, setActiveStep }) => {
  const alert = useAlert();
  const { organization } = useStore();
  const [loading, setLoading] = useState(false);
  const [sendBulkSms] = useMutation(SEND_BULK_MEMBERSHIP_SMS);

  const getValidRowCount = () => rowList.reduce((val, elm) => (elm.valid ? ++val : val), 0);
  const allRowsValid = () => getValidRowCount() === rowList.length;

  const sendSms = async () => {
    setLoading(true);
    const phones = rowList.filter((elm) => elm.valid).map((elm) => elm.phoneNumber.replace(/[^\d]/g, ''));

    try {
      const { data } = await sendBulkSms({
        variables: {
          input: {
            organizationId: organization?.id,
            phones,
          },
        },
      });
      if (data.sendBulkMembershipsCustomerSms.success) {
        setActiveStep(BulkSmsEnums.BULK_SMS_SUCCESS);
      } else {
        alert.error(data?.sendBulkMembershipsCustomerSms?.message);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" aria-labelledby="customized-dialog-title" open={open} onClose={resetOnClose}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          {getValidRowCount()} out of {rowList.length} were Successfully Added
        </CherryDialogTitle>
        <SubTitle>
          {getValidRowCount()} patient's phone numbers successfully added. They will each receive a membership sign-up
          link via text message.
          {!allRowsValid() && (
            <>
              <br />
              <br />
              We were not able to add <b>{rowList.length - getValidRowCount()} phone numbers</b>. Please review the CSV
              file and submit again.
              <br />
              <br />
              <ul>
                {rowList
                  .filter((elm) => !elm.valid)
                  .map((elm, i) => (
                    <li key={i}>
                      Row {elm.rowNumber}, <b>invalid phone format</b>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <CancelButton disabled={false} onClick={resetOnClose}>
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} xs={6}>
            <ConfirmButton disabled={loading} onClick={sendSms} loading={loading}>
              {`Send ${getValidRowCount()} Sign-Up Links`}
            </ConfirmButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const BulkSmsSuccess = ({ open, resetOnClose, rowList }) => {
  const getValidRowCount = () => rowList.reduce((val, elm) => (elm.valid ? ++val : val), 0);

  return (
    <Dialog maxWidth="sm" onClose={resetOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Success
        </CherryDialogTitle>
        <SubTitle>
          <b>{getValidRowCount()}</b> patients were sent a membership sign-up link.
        </SubTitle>
        <Grid container={true} spacing={1}>
          <ConfirmButton disabled={false} onClick={resetOnClose}>
            Okay
          </ConfirmButton>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const ConfirmButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;

const CancelButton = styled(BackButton)`
  border-color: 1px solid ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.green};
`;

const Link = styled.a`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: center;
  margin: auto;
`;

const Text = styled(SubTitle)`
  margin-bottom: 0px;
`;
