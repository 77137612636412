import { Button } from '@frontend/cherry-library';
import { DAILY_FREQUENCY_ENUM } from 'lib/constants';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../mobile';

interface Props {
  id: string;
  children?: React.ReactNode;
  chartTitle?: string;
  mainStatistic?: {
    header: string;
    description: string | JSX.Element;
  };
  onTimeBreakSelected?: (timeBreak: DAILY_FREQUENCY_ENUM) => void;
  onDownloadClick?: () => void;
  statistics?: {
    header: string;
    description: string | JSX.Element;
  }[];
}

export const DetailChartContainer = ({
  id,
  children,
  mainStatistic,
  statistics,
  chartTitle,
  onTimeBreakSelected,
  onDownloadClick,
}: Props) => {
  const [selectedTime, setSelectedTime] = useState<DAILY_FREQUENCY_ENUM>(DAILY_FREQUENCY_ENUM.DAILY);

  const onTimeRangeSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setSelectedTime(timeBreak);
    onTimeBreakSelected && onTimeBreakSelected(timeBreak);
  };

  return (
    <ChartContainer key={id}>
      <ChartHeader>
        <Header>
          {chartTitle && <ChartTitle>{chartTitle}</ChartTitle>}
          <MainStatistic>
            <MainStatisticHeader>{mainStatistic?.header}</MainStatisticHeader>
            <MainStatisticDescription>{mainStatistic?.description}</MainStatisticDescription>
          </MainStatistic>

          <TimeBreakContainer>
            {onTimeBreakSelected && (
              <TimeBox>
                <TimeBreak
                  border={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onTimeRangeSelected(DAILY_FREQUENCY_ENUM.DAILY)}
                  isSelected={selectedTime === DAILY_FREQUENCY_ENUM.DAILY}
                >
                  Day
                </TimeBreak>
                <TimeBreak
                  border={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onTimeRangeSelected(DAILY_FREQUENCY_ENUM.WEEKLY)}
                  isSelected={selectedTime === DAILY_FREQUENCY_ENUM.WEEKLY}
                >
                  Week
                </TimeBreak>
                <TimeBreak
                  border={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onTimeRangeSelected(DAILY_FREQUENCY_ENUM.MONTHLY)}
                  isSelected={selectedTime === DAILY_FREQUENCY_ENUM.MONTHLY}
                >
                  Month
                </TimeBreak>
                <TimeBreak
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onTimeRangeSelected(DAILY_FREQUENCY_ENUM.YEARLY)}
                  isSelected={selectedTime === DAILY_FREQUENCY_ENUM.YEARLY}
                >
                  Year
                </TimeBreak>
              </TimeBox>
            )}
            {onDownloadClick && (
              <Button variant="secondary" size="small" fullWidth={true} onClick={onDownloadClick}>
                <span>Download &nbsp;</span>
                <Icon src={'download'} width={10} height={15} hover={true} />
              </Button>
            )}
          </TimeBreakContainer>
        </Header>
        <StatisticsContainer>
          {statistics?.map((statistic, index) => (
            <Statistic key={`${statistic.header} - ${index + 10}`}>
              <StatisticHeader key={statistic.header}>{statistic.header}</StatisticHeader>
              <StatisticDescription key={`${statistic.header} - ${index}`}>
                {statistic.description}
              </StatisticDescription>
            </Statistic>
          ))}
        </StatisticsContainer>
      </ChartHeader>
      <Chart>{children}</Chart>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  padding: 16px 0px;
  width: 100%;
  margin-top: 24px;
  gap: 16px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const ChartHeader = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const MainStatistic = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainStatisticHeader = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const MainStatisticDescription = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const TimeBreakContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media print {
    display: none;
  }
`;
const TimeBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.main.midnightBlue}`};
  border-radius: 2px;
`;
const TimeBreak = styled.div<{ border?: boolean; isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  background-color: ${(props) => (props.isSelected ? props.theme.main.midnightBlue : props.theme.main.white)};
  color: ${(props) => (props.isSelected ? props.theme.main.white : props.theme.main.midnightBlue)};
  border-right: ${(props) => (props.border ? `1px solid ${props.theme.main.midnightBlue}` : 0)};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: -webkit-fill-available;
`;

const StatisticHeader = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const StatisticDescription = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const Chart = styled.div`
  margin-top: 18px;
`;

const ChartTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
`;
