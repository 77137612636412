import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Dropdown } from '@frontend/cherry-library';
import { SelectChangeEvent } from '@mui/material';
import { CherryRangePicker, DropDownWithRadioButton, InputIcon, InputWithIcon, PickerWrapper } from 'lib/components';
import { Icon } from 'lib/components/mobile/Icon';
import useStore from 'lib/hooks/useStore';
import { DropDownContainer } from '../../../pages';
import { useOrganizationGroupTreeRadioGroup } from '../../hooks';

interface Props {
  title: string;
  children: React.ReactNode;
  onDateRangeChange: (selectedData: any) => void;
  onLocationChange: (selectedLocation: any) => void;
  organizationGroupSelectionHandler: (selectedLocation: any) => void;
  selectedOrganizationGroup: any;
  selectedMerchant: string;
}

export const ReportingPageLayout = ({
  title,
  children,
  onDateRangeChange,
  onLocationChange,
  organizationGroupSelectionHandler,
  selectedOrganizationGroup,
  selectedMerchant,
}: Props) => {
  const [filteredStringRange, setFilteredStringRange] = useState<string>('Last 30 Days');
  const [showRangePicker, setShowRangePicker] = useState(false);
  const { merchants = [], isAdminReporting } = useStore();
  const navigate = useNavigate();

  const { organizationGroupTree } = useOrganizationGroupTreeRadioGroup();

  useEffect(() => {
    if (organizationGroupTree && organizationGroupTree?.length && isAdminReporting) {
      organizationGroupSelectionHandler &&
        organizationGroupSelectionHandler({
          value: 'selectAll',
        });
    }
  }, [organizationGroupTree]);

  const locationOptions: any = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: `<span>${merchant?.name}</span>`,
    };
  });

  const toggleRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };

  const handleRangeSelection = (range: { selection: { startDate: Date; endDate: Date } }) => {
    onDateRangeChange({
      from: dayjs(range.selection.startDate).startOf('day').toDate().toISOString(),
      to: dayjs(range.selection.endDate).endOf('day').toDate().toISOString(),
    });
  };

  const onTextStringUpdated = (inputDateRangeText: string) => {
    setFilteredStringRange(inputDateRangeText);
  };

  const locationChange = (event: SelectChangeEvent<unknown>) => {
    onLocationChange(event?.target?.value as string);
  };

  const printPage = () => {
    window.print();
  };

  const navigateBack = () => {
    navigate('/reporting');
  };

  return (
    <Container>
      <PageFeatures>
        <BackToSummaryContainer onClick={navigateBack}>
          <Icon src={'left-arrow'} width={16} hover={true} />
          <BackToSummarySpan>Back to Summary</BackToSummarySpan>
        </BackToSummaryContainer>
      </PageFeatures>
      <Header>{title}</Header>
      <FeatureContainer>
        <FeatureContainer>
          {isAdminReporting && (
            <DropDownContainer>
              <DropDownWithRadioButton
                selectAllLabel="All Accounts"
                options={organizationGroupTree}
                placeholder="All Accounts"
                checkedAllPlaceholder="All Accounts"
                iconName="location-preference"
                checkedPlaceholder="Location"
                hoveredMode={false}
                textColor="#0E202F"
                onChange={organizationGroupSelectionHandler}
                checkedItems={selectedOrganizationGroup}
                preventCloseOnClick={true}
                searchable={false}
                selectAll={true}
              />
            </DropDownContainer>
          )}
          <CustomizePickerWrapper>
            <InputWithIcon
              onClick={toggleRangePicker}
              defaultValue={filteredStringRange}
              caret={'transparent'}
              margin={'-4px 20px 0px 0px'}
              textAlign={'right'}
              cursor={'pointer'}
              width={filteredStringRange.length < 15 ? '140px' : '200px'}
              value={filteredStringRange}
            />
            <CustomizeInputIcon
              onClick={toggleRangePicker}
              icon={faChevronDown}
              right={'20px'}
              margin={'-4px 0 0 0'}
              color={'green'}
              cursor={'pointer'}
            />
            <CherryRangePicker
              show={showRangePicker}
              onRangeSelected={handleRangeSelection}
              defaultSelectRange={{
                startDate: dayjs().subtract(1, 'month'),
                endDate: dayjs(),
              }}
              updateTextString={onTextStringUpdated}
              toggleRangePicker={toggleRangePicker}
              marginLeft={filteredStringRange.length < 15 ? '403px' : '345px'}
              marginTop={'420px'}
            />
          </CustomizePickerWrapper>
        </FeatureContainer>

        {merchants?.length && merchants?.length > 1 && !isAdminReporting && (
          <DropDownContainer>
            <Dropdown
              options={[
                {
                  value: 'All',
                  label: 'All Stores',
                },
                ...locationOptions,
              ]}
              onChange={locationChange}
              value={selectedMerchant}
            />
          </DropDownContainer>
        )}

        <Button onClick={printPage}>
          Print Page &nbsp;
          <Icon src={'print'} width={18} height={18} hover={true} />
        </Button>
      </FeatureContainer>
      {children}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 150px;
  padding: 0 20px;
  padding-bottom: 100px;
`;

const PageFeatures = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const FeatureContainer = styled.div`
  width: auto;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
`;

const CustomizeInputIcon = styled(InputIcon)`
  position: inherit;
`;

const CustomizePickerWrapper = styled(PickerWrapper)`
  z-index: 4;
  width: fit-content;
  height: 45px;
  border: 1px solid #dadada;
  border-radius: 2px;
  background-color: white;
  padding: 0px 19px 0px 0px;
`;

const BackToSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const BackToSummarySpan = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.green};
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #0e202f;
  margin-bottom: 24px;
`;
