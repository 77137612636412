import { Container, ContentContainer, Title } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import MobileDocumentRequestList from 'pages/mobile/MobileDocumentRequests/views/MobileDocumentRequestList/MobileDocumentRequestList';
import React from 'react';

const MobileDocumentRequests = () => {
  const { isSnackbarVisible } = useSnackbar();
  return (
    <Container pageTitle="Document Requests">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Document Requests</Title>
        <MobileDocumentRequestList />
      </ContentContainer>
    </Container>
  );
};

export default MobileDocumentRequests;
