import { useMutation } from '@apollo/client';
import { emailPattern } from 'lib/utils';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_MUTATION } from '../graphql/mutations';
import { ForgotPasswordAnalyticsEventNames, useAnalytics } from '../hooks';
import { useSegment } from 'lib/hooks/useSegment';

export const useForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const { trackEvent } = useAnalytics({ isScreen: true });
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION);
  const alert = useAlert();
  const navigate = useNavigate();
  const { trackIdentify, trackSegmentEvent, applicationName } = useSegment();

  const inputChange = (nativeEvent: any) => {
    const emailValidity = emailPattern.test(nativeEvent?.target?.value);
    setEmailValid(emailValidity);
    setEmail(nativeEvent.target.value);
  };

  const sendLink = async () => {
    try {
      setLoading(true);
      trackSegmentEvent('Password Reset', {
        application: applicationName,
        stage: 'Send link',
      });
      trackIdentify({ email });
      trackEvent({
        action: ForgotPasswordAnalyticsEventNames.SEND_LINK,
      });
      const {
        data: { forgotPassword },
      } = await forgotPasswordMutation({ variables: { input: { email } } });

      if (forgotPassword?.code) {
        setSuccess(true);
        setLoading(false);
        trackEvent({
          action: ForgotPasswordAnalyticsEventNames.SUCCESS,
        });
      }
    } catch (e: unknown) {
      let errorMessage = 'An unexpected error occurred';

      if (e instanceof Error) {
        errorMessage = e?.message;
      }

      if (typeof e === 'object' && e !== null && 'response' in e) {
        const axiosError = e as { response: { data?: { message?: string } } };
        errorMessage = axiosError.response?.data?.message ?? errorMessage;
      }

      alert.error(errorMessage);
      trackEvent({
        action: ForgotPasswordAnalyticsEventNames.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const emailOnBlur = () => {
    trackEvent({
      action: ForgotPasswordAnalyticsEventNames.EMAIL_BLUR,
    });
  };

  const logNavigateToAnalytics = () => {
    navigate('/login');
    trackEvent({
      action: ForgotPasswordAnalyticsEventNames.CLICK_TO_LOGIN,
    });
  };

  return { email, emailOnBlur, logNavigateToAnalytics, loading, sendLink, inputChange, success, emailValid };
};
