import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonContainer, Container, ContentContainer, Subtitle, Title } from 'lib/components/mobile';
import { ExpandableInfoText } from 'lib/components/mobile/ExpandableInfoText';
import { ExpandableWithTitle } from 'lib/components/mobile/ExpandableWithTitle';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { useMobileApplicationListTable } from 'lib/tables/MobileApplicationListTable';
import CurrencyUtil from 'lib/utils/currency';

const MobileApplicationDetail = () => {
  const navigate = useNavigate();
  const { applicationInProcess, locations } = useStore();
  const { getExpiresText } = useMobileApplicationListTable();
  const { isSnackbarVisible } = useSnackbar();

  const navigateToApplicationUpdate = () => navigate('/application/update');
  const goBack = () => navigate('/dashboard');

  const findPreferedLocation = () => {
    const merchantId = applicationInProcess?.activeLoan?.merchantId || applicationInProcess?.merchantId;
    const findLocation = locations?.find((opt) => opt?.id === merchantId);
    if (findLocation) {
      return findLocation.name;
    }
    return '';
  };

  return (
    <Container pageTitle="Application Details" backUrl="/dashboard">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Checkout in Progress</Title>
        <Subtitle>
          A checkout link has been sent to the customer. They need to pick a payment plan and make a down payment.
        </Subtitle>
        <ExpandableWithTitle title="Pending Payment Plan" expanded={true}>
          <>
            <ExpandableInfoText name={'Name'} value={applicationInProcess?.borrowerName} />
            <ExpandableInfoText name={'Phone'} value={applicationInProcess?.phone} />
            <ExpandableInfoText name={'Sent'} value={dayjs().format('MMM DD, YYYY hh:MM A')} />
            <ExpandableInfoText
              name={'Amount'}
              value={CurrencyUtil.toCurrencyString(applicationInProcess?.amount, true)}
            />
            <ExpandableInfoText name={'Expires'} value={getExpiresText(applicationInProcess?.startedAt)} />
            <ExpandableInfoText name={'Location'} value={findPreferedLocation()} />
          </>
        </ExpandableWithTitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={navigateToApplicationUpdate}>Edit Or Cancel Plan</Button>
        <Button secondary={true} onClick={goBack}>
          Back
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MobileApplicationDetail;
