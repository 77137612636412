import { Button } from '@frontend/cherry-library';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface DialogProps extends MuiDialogProps {
  title: string;
  cancelButtonlabel?: string;
  submitButtonLabel?: string;
  isSubmitDisabled?: boolean;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const MaterialModal = ({
  title,
  children,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  cancelButtonlabel,
  submitButtonLabel,
  hideCancelButton,
  ...rest
}: DialogProps) => {
  const isCancelButtonVisible = !!onCancel && !hideCancelButton;

  return (
    <Dialog {...rest} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <Content>{children}</Content>
      <DialogActions>
        {isCancelButtonVisible ? (
          <Button variant="secondary" fullWidth={true} onClick={onCancel}>
            {cancelButtonlabel || 'Cancel'}
          </Button>
        ) : null}
        {onSubmit ? (
          <Button fullWidth={true} onClick={onSubmit} disabled={isSubmitDisabled} data-testid="submit-button">
            {submitButtonLabel || 'Submit'}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 480px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;
  margin-bottom: 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const DialogActions = styled(MuiDialogActions)`
  margin-top: 24px;
  padding: 0 !important;

  & > button {
    flex: 1;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default MaterialModal;
