import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import { Communication } from './Communication';
import useStore from 'lib/hooks/useStore';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const BusinessInformation = () => {
  const permission = usePermission();
  const { isAdminReporting } = useStore();
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_BUSINESS_INFORMATION_PAGE_LOAD`);
  }, []);

  return permission(FeatureNames.COMMUNICATION, ScreenNames.SETTINGS) && !isAdminReporting ? (
    <Communication />
  ) : (
    <UnauthorizedSettings title="Communication Settings" />
  );
};

export default BusinessInformation;
