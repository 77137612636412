import { Grid } from '@mui/material';
import { theme } from 'config/theme';
import React from 'react';
import styled from 'styled-components';

import { Container, ContentContainer, GreenBox, Subtitle, Title } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { formatPhoneNumber } from 'lib/utils';

const MobileSettings = () => {
  const { isSnackbarVisible } = useSnackbar();
  const { user } = useStore();
  const { firstName, lastName, email, phone } = user || {};

  const onMarketingResourcesClick = () => {
    window.open('https://withcherry.com/sharing-cherry', '_blank');
  };

  return (
    <Container pageTitle="Settings">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Account Details</Title>
        <Subtitle>Contact support at (888) 839-7171 if you need to update any account details.</Subtitle>
        <BoxContainer>
          <GreenBox bgColor={theme.main.green}>
            <Grid container={true} alignItems="center">
              <Grid xs={3}>
                <FieldName>Name</FieldName>
              </Grid>
              <Grid xs={9}>
                <FieldText>
                  {firstName} {lastName}
                </FieldText>
              </Grid>
            </Grid>
            <Grid container={true} alignItems="center">
              <Grid xs={3}>
                <FieldName>Email</FieldName>
              </Grid>
              <Grid xs={9}>
                <FieldText>{email}</FieldText>
              </Grid>
            </Grid>
            <Grid container={true} alignItems="center">
              <Grid xs={3}>
                <FieldName>Phone</FieldName>
              </Grid>
              <Grid xs={9}>
                <FieldText>{formatPhoneNumber(phone) || '-'}</FieldText>
              </Grid>
            </Grid>
          </GreenBox>
          <Header>Marketing Resources</Header>
          <Description>Marketing resources, including social media posts, are available here:</Description>
          <SlideModalButton onClick={onMarketingResourcesClick}>Marketing Resources</SlideModalButton>
          <Header>Looking for More?</Header>
          <Description>
            The following features are not currently supported in the mobile experience. Please use a desktop browser to
            access:
          </Description>
          <LookingMoreUl>
            <LookingMoreLi>Training videos</LookingMoreLi>
            <LookingMoreLi>Referrals</LookingMoreLi>
            <LookingMoreLi>Monthly statements</LookingMoreLi>
            <LookingMoreLi>Bank information</LookingMoreLi>
            <LookingMoreLi>Your pricing plan</LookingMoreLi>
            <LookingMoreLi>API keys</LookingMoreLi>
            <LookingMoreLi>User management</LookingMoreLi>
          </LookingMoreUl>
        </BoxContainer>
      </ContentContainer>
    </Container>
  );
};

export default MobileSettings;

const FieldName = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
  margin-top: 0;
  margin-bottom: 8px;
`;

const FieldText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  margin-top: 0;
  margin-bottom: 8px;
`;

const BoxContainer = styled.div`
  margin-top: 16px;
`;

const Header = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
  display: block;
  margin-top: 40px;
`;

const Description = styled(Header)`
  margin-top: 8px;
  font-weight: 400;
`;

const LookingMoreUl = styled.ul`
  margin-top: 8px;
  padding-left: 24px;
  padding-bottom: 72px;
`;

const LookingMoreLi = styled.li`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
`;

const SlideModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.main.green};
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => props.theme.main.green};
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;
