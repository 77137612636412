import { CustomerPlansCSVItem } from 'lib/types';
import { firstCharFormatter } from 'lib/utils';
import { CustomerPlans } from 'pages/desktop/Membership/type';

export const useMembershipSearch = () => {
  const mapCustomerPlanToTableData = (data: CustomerPlans[]) => {
    const customerPlanList: CustomerPlansCSVItem[] = [];

    if (data) {
      data.map((customerPlan: CustomerPlans) => {
        const item: CustomerPlansCSVItem = {
          planId: customerPlan.id,
          name: `${customerPlan?.customer?.firstName} ${customerPlan?.customer?.lastName}`,
          planType: customerPlan?.planName,
          memberSince: customerPlan?.createdAt,
          paymentStatus: firstCharFormatter(customerPlan?.status),
        };
        customerPlanList.push(item);
      });
    }
    return customerPlanList;
  };

  return { mapCustomerPlanToTableData };
};
