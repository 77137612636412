import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { Input, TrashIcon } from 'lib/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  isEditMode: boolean;
  onInputChange: (name: string, index: number, value: string) => void;
  onDeleteLink: (index: number) => void;
  onChangeSortOrder: (index: number, direction: number) => void;
}

export const useLinkTreeTableColumns = ({ isEditMode, onInputChange, onDeleteLink, onChangeSortOrder }: Props) => {
  const getActionItemsRow = (row, i: number) => {
    if (i === 0) return null;
    const handleDeleteLink = () => {
      onDeleteLink(i);
    };

    return (
      <div style={{ cursor: 'pointer' }} className="delete-key-button" onClick={handleDeleteLink}>
        <TrashIcon color={'#EC3360'} width={18} height={18} />
      </div>
    );
  };

  const getSortActionItems = (row, i: number) => {
    if (i === 0) return null;

    const handleIncrementSortOrder = () => {
      onChangeSortOrder(i, -1);
    };

    const handleDecrementSortOrder = () => {
      onChangeSortOrder(i, 1);
    };

    return (
      <Grid direction="column">
        <div style={{ cursor: 'pointer' }} onClick={handleIncrementSortOrder}>
          <FontAwesomeIcon icon={faChevronUp} color="#879097" />
        </div>
        <div style={{ cursor: 'pointer' }} onClick={handleDecrementSortOrder}>
          <FontAwesomeIcon icon={faChevronDown} color="#879097" />
        </div>
      </Grid>
    );
  };

  const handleInputChange = (fieldName: string, index: number) => (e) => {
    onInputChange(fieldName, index, e.target.value);
  };

  const tableColumns = useMemo(
    () => [
      ...(isEditMode
        ? [
            {
              id: 'sort',
              name: '',
              cell: getSortActionItems,
              width: '50px',
            },
          ]
        : []),
      {
        id: 'title',
        name: 'Link Name',
        selector: (row) => row.title,
        width: '232px',
        cell: (row, index: number) =>
          isEditMode && index !== 0 ? (
            <StyledInput defaultValue={row.title} onChange={handleInputChange('title', index)} />
          ) : (
            row.title
          ),
      },
      {
        id: 'link',
        name: 'URL',
        selector: (row) => row.link,
        cell: (row, index: number) =>
          isEditMode && index !== 0 ? (
            <StyledInput defaultValue={row.link} onChange={handleInputChange('link', index)} />
          ) : (
            row.link
          ),
      },
      ...(isEditMode
        ? [
            {
              id: 'delete',
              name: '',
              cell: getActionItemsRow,
              width: '50px',
              right: true,
            },
          ]
        : []),
    ],
    [isEditMode],
  );

  return { tableColumns };
};

const StyledInput = styled(Input)`
  margin-bottom: 0;
  padding: 8px 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;
