import { Grid } from '@mui/material';
import { set } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, DashCard, Loading } from 'lib/components';
import { Title } from 'lib/components/PageHeader';
import { Container, Row } from 'lib/styles';

import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { CREATE_SHOP_PRODUCT } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { useAlert } from 'react-alert';
import FulfillmentOptions from '../components/FulfillmentOptions';
import MoreMedia from '../components/MoreMedia';
import OfferingInfo from '../components/OfferingInfo';
import { formatPayload } from '../utils';

const CreateProduct = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { organization } = useStore();

  const [createProduct, { loading }] = useMutation(CREATE_SHOP_PRODUCT);

  const [productData, setProductData] = useState<any>({ status: 'DRAFT' });

  const handleSubmit = async () => {
    try {
      await createProduct({
        variables: {
          input: {
            organizationId: organization?.id,
            product: formatPayload(productData),
          },
        },
      });
      handleBackToShopEditorClick();
    } catch (error) {
      alert.error("Couldn't create offering");
    }
  };

  const handleBackToShopEditorClick = () => {
    navigate('/shop/editor');
  };

  const handleChange = (key: string, value: any) => {
    setProductData((prevState: any) => {
      const newState = cloneDeep(prevState);
      set(newState, key, value);
      return newState;
    });
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Loading size={25} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Row marginBottom="42px">
        <ButtonPrimary text="Back to Shop Editor" onClick={handleBackToShopEditorClick} />
      </Row>
      <DashCard>
        <Row marginBottom="40px">
          <Grid container={true} justifyContent="space-between" alignItems="center">
            <Grid item={true}>
              <Title>Add Offering</Title>
            </Grid>
            <Grid item={true}>
              <CancelButton text="Cancel" onClick={handleBackToShopEditorClick} />
              <ButtonPrimary text="Add Offering" onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Row>

        <Row marginBottom="40px">
          <OfferingInfo productData={productData} onChange={handleChange} />
        </Row>

        {productData?.type && (
          <>
            <Row marginBottom="40px">
              <MoreMedia productData={productData} onChange={handleChange} />
            </Row>

            <Row marginBottom="40px">
              <FulfillmentOptions productData={productData} onChange={handleChange} />
            </Row>
          </>
        )}
      </DashCard>
    </Container>
  );
};

const CancelButton = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #879097 !important;
  color: #879097 !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;

export default CreateProduct;
