import { useApolloClient, useMutation } from '@apollo/client';
import images from 'assets/images';
import QRCode from 'easyqrcodejs';
import {
  ButtonPrimary,
  DashCard,
  DashComponent,
  DownloadIcon,
  DropDown,
  ExpandIcon,
  Input,
  SecondaryButton,
} from 'lib/components';
import {
  AlertDialog,
  CherryDeclineButton,
  CherryDialogActions,
  CherryDialogContent,
  CherryDialogContentText,
  CherryDialogTitle,
  CustomActivateButton,
} from 'lib/components/Dialogue';
import { SEND_MARKETING_MATERIALS } from 'lib/graphql/mutations';
import { FETCH_ORGANIZATION_MERCHANTS } from 'lib/graphql/queries';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import { downloadBlobFile } from 'lib/utils';
import { downloadBase64 } from 'lib/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { MarketingMaterialModal } from './index';

export const MarketingMaterials = ({ marketingMaterialList, title }) => {
  const { trackEvent } = useAnalytics();
  const { organization, merchants } = useStore();
  const alert = useAlert();
  const [qrImage, setQrImage] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [downloadMaterialsText, setDownloadMaterialsText] = useState<any>('Download Materials');
  const [openMaterialsPost, setOpenMaterialsPost] = useState<boolean>(false);
  const [updatedValues, setUpdatedValues] = useState<any>(null);
  const [selectedMerchantValue, setSelectedMerchantValue] = useState<string>((merchants && merchants[0].name) || '');
  const [organizationMerchants, setOrganizationMerchants] = useState<any>([]);
  const client = useApolloClient();

  const [sendMarketingMaterials] = useMutation(SEND_MARKETING_MATERIALS);

  const qrcode = useRef<any>('');

  const PREQUAL_URL = process.env.REACT_APP_PREQUAL_URL;

  const setMerchantsWithAddress = useCallback(async () => {
    const {
      data: { fetchOrganizationMerchants },
    } = await client.query({
      query: FETCH_ORGANIZATION_MERCHANTS,
      fetchPolicy: 'no-cache',
      variables: { input: { idOrganization: organization?.id } },
    });
    if (fetchOrganizationMerchants?.data) setOrganizationMerchants(fetchOrganizationMerchants?.data || []);
  }, []);

  useEffect(() => {
    setMerchantsWithAddress();
  }, [organization]);

  const defaultSelectedMerchantAddress = organizationMerchants[0]?.address || [];
  const [selectedMerchantAddress, setSelectedMerchantAddress] = useState<any>(defaultSelectedMerchantAddress || []);

  useEffect(() => {
    const marketingQr = new QRCode(qrcode.current, {
      text: `${PREQUAL_URL}/${organization?.slug}`,
      logo: images?.cherryIconDark,
      version: 5,
      width: 200,
      height: 200,
      onRenderingEnd: onQrRendered,
      autoColor: true,
      PO: '#00c37d',
      PI: '#0e1f2e',
      PO_TL: '#00c37d',
      PI_TL: '#00c37d',
      PO_TR: '#0e1f2e',
      PI_TR: '#00c37d',
      PO_BL: '#00c37d',
      PI_BL: '#0e1f2e',
    });
    console.log(marketingQr);
  }, []);

  useEffect(() => {
    if (checkedItems.length > 0) {
      setDownloadMaterialsText('Request a Marketing Box (' + Number(checkedItems.length) + ')');
    } else {
      setDownloadMaterialsText('Request a Marketing Box');
    }
  }, [checkedItems]);

  useEffect(() => {
    if (pdfUrl !== '' && pdfUrl !== undefined) {
      setOpen(true);
      setPdfUrl(pdfUrl);
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (updatedValues !== null) postMarketingDocuments();
  }, [updatedValues]);

  const onRequestMaterialClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    downloadBlobFile(e.target.dataset.name, e.target.dataset.url);
    trackEvent({
      action: MarketingAnalyticsEventNames.SINGLE_MATERIAL_DOWNLOAD,
      label: e.target.dataset.url,
    });
  };

  const onQrDownloadClick = useCallback(() => {
    downloadBase64(organization?.slug, qrImage);
    trackEvent({
      action: MarketingAnalyticsEventNames.CTA_BUTTONS,
      label: 'QR_CODE',
    });
  }, [qrImage]);

  const onQrRendered = (event: any, image: string) => {
    setQrImage(image);
  };

  const onExpandClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.dataset.url) {
      setPdfUrl(e.target.dataset.url);
    } else {
      setPdfUrl(e.target.parentNode.dataset.url);
    }
    trackEvent({
      action: MarketingAnalyticsEventNames.SINGLE_MATERIAL_IMAGE,
      label: e.target.parentNode.dataset.url,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setPdfUrl('');
  };

  const onSubmitMaterialsClicked = () => {
    setOpenMaterialsPost(false);
    postMarketingDocuments();
    clearMaterialStates();
  };

  const disagreeClicked = () => {
    setOpenMaterialsPost(false);
  };

  const clearMaterialStates = () => {
    setSelectedMerchantAddress(defaultSelectedMerchantAddress);
    setCheckedItems([]);
  };

  const onMaterialsDownloadClick = () => {
    setOpenMaterialsPost(true);
    const docs: any = [];
    marketingMaterialList.map((node) => {
      docs.push(node.title);
    });
    setSelectedDocuments(docs);

    trackEvent({
      action: MarketingAnalyticsEventNames.REQUEST_MATERIALS_CLICK,
    });
  };

  const postMarketingDocuments = useCallback(async () => {
    try {
      const documents = {
        documents: selectedDocuments,
        address1: updatedValues?.address1,
        city: updatedValues?.city,
        zip: updatedValues?.zip,
        state: updatedValues?.state,
      };
      const { data } = await sendMarketingMaterials({
        variables: {
          input: {
            organizationId: organization?.id,
            ...documents,
          },
        },
      });

      if (data.sendMarketingMaterials) {
        setOpenMaterialsPost(false);
        clearMaterialStates();
        alert.success('The request has been submitted');
      }
    } catch (e) {
      alert.error('An error occurred');
    }
  }, [selectedDocuments && updatedValues]);

  const onMerchantSelect = useCallback(
    async (option) => {
      setSelectedMerchantAddress(option.address);
      setSelectedMerchantValue(option.value);
    },
    [selectedMerchantAddress],
  );

  const MaterialsContent = ({ updateValues, defaultMerchant }) => {
    const [newAddress, setNewAddress] = useState<string>('');
    const [newCity, setNewCity] = useState<string>('');
    const [newState, setNewState] = useState<string>('');
    const [newZip, setNewZip] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(false);

    const defaultAddress = defaultMerchant.length === 0 ? defaultSelectedMerchantAddress : defaultMerchant;
    const requestNewAddress = () => {
      setEditMode(true);
      trackEvent({
        action: MarketingAnalyticsEventNames.REQUEST_MATERIALS_EDIT_ADDRESS,
      });
    };

    const fieldOnChange = (nativeEvent: any) => {
      const currentTarget = nativeEvent.currentTarget;
      if (currentTarget.name === 'address') {
        setNewAddress(currentTarget.value);
      } else if (currentTarget.name === 'city') {
        setNewCity(currentTarget.value);
      } else if (currentTarget.name === 'zip') {
        setNewZip(currentTarget.value);
      } else {
        setNewState(currentTarget.value);
      }
    };
    const agreeClicked = useCallback(async () => {
      if (!editMode) {
        const mappedValues = {
          address1: defaultAddress?.address1,
          city: defaultAddress?.city?.name,
          zip: defaultAddress?.zip,
          state: defaultAddress?.city.state.code,
        };
        setUpdatedValues(mappedValues);
      } else {
        const newMappedValues = {
          address1: newAddress,
          city: newCity,
          zip: newZip,
          state: newState,
        };
        setUpdatedValues(newMappedValues);
      }
    }, [selectedMerchantAddress, editMode, newAddress, newCity, newZip, newState]);

    return (
      <MaterialRequestContainer>
        <CherryDialogTitle>
          <b>{editMode ? 'Edit Delivery Address' : 'Request a Marketing Box'}</b>{' '}
        </CherryDialogTitle>
        <CherryDialogContent style={{ overflow: 'unset' }}>
          <CherryDialogContentText>
            <CenterAligner style={{ margin: '24px 0', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              {!editMode
                ? 'Materials should arrive in 8-10 business days and will be sent to the address we have on file:'
                : 'This will NOT update the default address for your account. Please contact support if you need to change your account info.'}
            </CenterAligner>
            {!editMode ? (
              <>
                <BorderContainer>
                  <DropDown
                    options={organizationMerchants.map((node) => {
                      return {
                        value: node.id,
                        label: node.name,
                        address: node.address,
                      };
                    })}
                    defaultValue={selectedMerchantValue}
                    onChange={onMerchantSelect}
                    hoveredMode={false}
                    showAllItem={true}
                  />
                </BorderContainer>
                <AddressContainer>
                  <Row> {defaultAddress?.address1}</Row>
                  <Row>
                    {defaultAddress?.city?.name}, {defaultAddress?.city?.state?.code} {defaultAddress?.zip}
                  </Row>
                </AddressContainer>
                <RequestMaterialLink onClick={requestNewAddress}>Need to edit this address?</RequestMaterialLink>
              </>
            ) : (
              <div>
                <Row>
                  <Input name="address" type="text" onChange={fieldOnChange} placeholder="Address" />
                </Row>
                <Row>
                  <Input name="zip" type="text" onChange={fieldOnChange} placeholder="Zip Code" />
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Input name="city" onChange={fieldOnChange} placeholder={'City'} style={{ width: '68%' }} />
                  <Input name="state" onChange={fieldOnChange} placeholder={'State'} style={{ width: '28%' }} />
                </Row>
              </div>
            )}
          </CherryDialogContentText>
        </CherryDialogContent>
        <CherryDialogActions>
          <CherryDeclineButton variant={'contained'} onClick={disagreeClicked}>
            {' '}
            Cancel
          </CherryDeclineButton>
          <CustomActivateButton onClick={agreeClicked} text={'Submit Request'} />
        </CherryDialogActions>
      </MaterialRequestContainer>
    );
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <SubHeader size={'16px'} style={{ marginBottom: '8px' }}>
          Marketing Materials
        </SubHeader>
        <Row fontSize={'14px'} marginBottom={'16px'}>
          Request a marketing materials box and we’ll ship them to you! Shipping typically takes 8-10 business days.
        </Row>

        <EmailSnippetList checkedItems={checkedItems}>
          {marketingMaterialList.map((item) => {
            return (
              <Snippet key={item.id} data-value={item.id}>
                <ImageContainer>
                  <AbscoluteHover id={'cherry--overlay'} onClick={onExpandClicked} data-url={item.pdfUrl}>
                    <CustomExpandIcon data-url={item.pdfUrl} onClick={onExpandClicked} />
                  </AbscoluteHover>
                  <MaterialImage url={item.image} />
                </ImageContainer>
                <Flex data-value={item.id} style={{ width: '80%', marginTop: '12px' }}>
                  <Row data-value={item.id} style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {' '}
                    {item.title}{' '}
                  </Row>
                  <Row data-value={item.id} style={{ fontSize: '14px', marginTop: '4px' }}>
                    {' '}
                    {item.description}
                  </Row>
                  <Row data-value={item.id} style={{ fontSize: '14px', marginTop: '4px' }}>
                    {' '}
                    <SubInfo data-value={item.id}>{item.subInfo} </SubInfo>
                  </Row>
                </Flex>
                <Flex
                  style={{ position: 'relative', zIndex: 10, width: '20%', display: 'flex', alignItems: 'center' }}
                  className={'download'}
                >
                  <CustomSecondaryButton
                    variant={'contained'}
                    data-url={item.pdfUrl}
                    onClick={onRequestMaterialClick}
                    data-item={item.description}
                    data-name={item.title}
                    data-testid={'downloadButton'}
                  >
                    <DownloadIcon color={'#00c37c'} /> &nbsp;Download PDF{' '}
                  </CustomSecondaryButton>
                </Flex>
              </Snippet>
            );
          })}
        </EmailSnippetList>
        <MarketingMaterialModal fullModal={true} closeModal={closeModal} pdfUrl={pdfUrl} show={open} />
        <DownloadButton text={downloadMaterialsText} onClick={onMaterialsDownloadClick} />
        <AlertDialog
          open={openMaterialsPost}
          agreeClicked={onSubmitMaterialsClicked}
          onDisagreeClicked={disagreeClicked}
          showActions={false}
          children={<MaterialsContent updateValues={setUpdatedValues} defaultMerchant={selectedMerchantAddress} />}
          agreeText={'Submit Request'}
          declineText={'Cancel'}
        />
        <SubHeader> QR Code Materials</SubHeader>
        <Row fontSize={'14px'} marginBottom={'8px'}>
          Patients scan your unique QR code to pre-qualify. Download a digital copy for print needs and add it to other
          collateral, like sign-in forms, treatment packages, and social media content.
        </Row>
        <Row style={{ margin: '8px 0px' }}>
          <div ref={qrcode} />
        </Row>
        <DownloadButtonQR
          data-testid="qrCodeButton"
          id={'qrCode'}
          startIcon={<DownloadIcon color={'#FFFFFF'} />}
          text={`Download`}
          onClick={onQrDownloadClick}
        />
      </DashCard>
    </Column>
  );
};

const RequestMaterialLink = styled.a<{ to?: any }>`
  cursor: pointer;
  margin: 24px 0;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  color: #00c37c;
  font-family: 'Open Sans';
`;

const AddressContainer = styled.div`
  flex-direction: column;
  display: flex;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BorderContainer = styled.div`
  border: 1px solid rgb(218, 218, 218);
  display: inline-block;
  width: 100%;
  margin: 24px 0;
`;

const MaterialRequestContainer = styled.div`
  margin: 16px;
  min-width: 480px;
  min-height: 400px;
`;

const CustomExpandIcon = styled(ExpandIcon)<{ onClick: any }>`
  display: inline-block;
  position: absolute;
  z-index: 4;
  position: fixed;
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover #cherry--overlay {
    background-color: ${(props) => props.theme.main.green};
    opacity: 0.4;
    display: block;
    transition: 0.5s;
    svg {
      transition: 0.9;
      z-index: -1;
    }
  }
`;
const AbscoluteHover = styled.div`
  display: none;
  position: absolute;
  margin: 0 20px;
  width: 80px;
  height: 80px;
  transition: 0.5s;
  svg {
    position: absolute;
    z-index: -1;
    left: 40%;
    top: 38%;
    background-color: #056e48;
    padding: 8px;
    border-radius: 16px;
    transition: 0.9;
  }
`;

const CenterAligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const SubInfo = styled.span`
  color: #868f97;
  font-size: 10px;
`;
const MaterialImage = styled.div<{ url: string }>`
  width: 80px;
  height: 80px;
  border: 0.5px solid #dadada;
  margin: 0 20px;
  cursor: pointer;
  background: url(' ${(props) => props.url} ');
  background-size: cover;
`;

const Flex = styled.div``;

const EmailSnippetList = styled.ul<{ checkedItems?: any }>`
  padding: 0px;

  > li:last-child {
    border-bottom: 1px solid #dadada;
  }
`;
const Snippet = styled.li<{ checked?: boolean }>`
  list-style: none;
  border-top: 1px solid #dadada;
  display: flex;
  padding: 8px 0px;
  min-height: 110px;
  cursor: pointer;

  background-color: ${(props) => (props.checked ? '#00c37c30' : '#fff')};
`;

const DownloadButton = styled(ButtonPrimary)<{ disabled?: boolean }>`
  margin: 30px 0 !important;
  max-width: 275px;
  margin-top: 10px;
  background-color: ${(props) => (props.disabled ? '#DADADA !important' : '#00c37c !important')};
  border-color: ${(props) => (props.disabled ? '#DADADA !important' : '#00c37c !important')};
`;

const DownloadButtonQR = styled(ButtonPrimary)`
  margin: 30px 0 !important;
  max-width: 150px;
  border: 1px solid #00c37c !important;
`;

const CustomSecondaryButton = styled(SecondaryButton)`
  font-size: 12px;
  color: #00c37c;
  border: none;
  display: flex;
  font-weight: 400;
  margin-right: 8px;
`;
