import { Grid } from '@mui/material';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { ButtonPrimary, DashCard, Loading, TrashIcon } from 'lib/components';
import { Title } from 'lib/components/PageHeader';
import client from 'lib/graphql/client';
import { DELETE_SHOP_PRODUCT, PATCH_SHOP_PRODUCT } from 'lib/graphql/mutations';
import { GET_SHOP_PRODUCT_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Container, Row } from 'lib/styles';
import { useAlert } from 'react-alert';
import FulfillmentOptions from '../components/FulfillmentOptions';
import MoreMedia from '../components/MoreMedia';
import OfferingInfo from '../components/OfferingInfo';
import RemoveOfferingModal from '../components/RemoveOfferingModal';
import { formatPayload } from '../utils';

const ProductDetail = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const { organization } = useStore();
  const [patchProduct, { loading: patchLoading }] = useMutation(PATCH_SHOP_PRODUCT);
  const [deleteProduct, { loading: deleteLoading }] = useMutation(DELETE_SHOP_PRODUCT);

  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<any>({});
  const [isRemoveOfferingModalOpen, setIsRemoveOfferingModalOpen] = useState(false);

  useEffect(() => {
    getProductDetail();
  }, []);

  const getProductDetail = async () => {
    try {
      setLoading(true);
      const { data: { fetchShopProductDetail } = {} } = await client.query({
        query: GET_SHOP_PRODUCT_DETAIL,
        variables: {
          input: {
            productId: id,
            organizationId: organization?.id,
          },
        },
      });
      setLoading(false);
      if (fetchShopProductDetail.data.id) {
        setProductData(fetchShopProductDetail.data);
      }
    } catch (err) {
      alert.error("Couldn't fetch offering detail");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await patchProduct({
        variables: {
          input: {
            organizationId: organization?.id,
            productId: id,
            product: formatPayload(productData),
          },
        },
      });
      handleBackToShopEditorClick();
    } catch (error) {
      alert.error("Couldn't update offering");
    }
  };

  const handleBackToShopEditorClick = () => {
    navigate('/shop/editor');
  };

  const handleChange = (key: string, value: any) => {
    setProductData((prevState: any) => {
      const newState = cloneDeep(prevState);
      set(newState, key, value);
      return newState;
    });
  };

  const handleRemoveOfferingConfirm = async () => {
    try {
      await deleteProduct({
        variables: {
          input: {
            organizationId: organization?.id,
            productId: id,
          },
        },
      });
      handleBackToShopEditorClick();
    } catch (error) {
      alert.error("Couldn't delete offering");
    }
  };

  const handleCloseRemoveOfferingModal = () => {
    setIsRemoveOfferingModalOpen(false);
  };

  const handleRemoveOfferingClick = () => {
    setIsRemoveOfferingModalOpen(true);
  };

  if (loading || patchLoading || deleteLoading) {
    return (
      <LoadingContainer>
        <Loading size={25} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Row marginBottom="42px">
        <ButtonPrimary text="Back to Shop Editor" onClick={handleBackToShopEditorClick} />
      </Row>
      <DashCard>
        <Row marginBottom="40px">
          <Grid container={true} justifyContent="space-between" alignItems="center">
            <Grid item={true}>
              <Title>{productData?.title}</Title>
            </Grid>
            <Grid item={true}>
              <CancelButton text="Cancel" onClick={handleBackToShopEditorClick} />
              <ButtonPrimary text="Save Changes" onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Row>

        <Row marginBottom="40px">
          <OfferingInfo productData={productData} onChange={handleChange} />
        </Row>

        {productData?.type && (
          <>
            <Row marginBottom="40px">
              <MoreMedia productData={productData} onChange={handleChange} />
            </Row>

            <Row marginBottom="60px">
              <FulfillmentOptions productData={productData} onChange={handleChange} />
            </Row>

            <Row marginBottom="40px">
              <Grid container={true} justifyContent="center">
                <DeleteButton
                  text="Delete Offering"
                  onClick={handleRemoveOfferingClick}
                  endIcon={<TrashIcon color="#EC3360" width={24} height={24} />}
                />
              </Grid>
            </Row>
          </>
        )}

        <RemoveOfferingModal
          open={isRemoveOfferingModalOpen}
          onClose={handleCloseRemoveOfferingModal}
          onSubmit={handleRemoveOfferingConfirm}
        />
      </DashCard>
    </Container>
  );
};

const CancelButton = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #879097 !important;
  color: #879097 !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const DeleteButton = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid ${(props) => props.theme.main.red} !important;
  color: ${(props) => props.theme.main.red} !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;

export default ProductDetail;
