import { useMutation } from '@apollo/client';
import { Button as CherryButton, Switch } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { UserManagementStepEnums } from 'lib/types';

import {
  ADD_MERCHANT_USER,
  ADD_ORGANIZATION_USER,
  CREATE_USER,
  DELETE_MERCHANT_USER,
  DELETE_ORGANIZATION_USER,
} from 'lib/graphql/mutations';

import { useSegment } from 'lib/hooks/useSegment';
import { CherryDialogTitle, DialogContainer, DialogContent, SubTitle } from '../Dialog';

export const LocationAccess = ({
  setActiveStep,
  handleClose,
  selectedRole,
  userForCreation,
  selectedOrganizationMember,
  organizationUsers = [],
  merchantUsers = [],
}) => {
  const alert = useAlert();
  const { organization, merchants = [] } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const [addOrganizationUser] = useMutation(ADD_ORGANIZATION_USER);
  const [removeOrganizationUser] = useMutation(DELETE_ORGANIZATION_USER);
  const [addMerchantUser] = useMutation(ADD_MERCHANT_USER);
  const [deleteMerchantUser] = useMutation(DELETE_MERCHANT_USER);
  const [createUser] = useMutation(CREATE_USER);

  const [selectedMember, setSelectedMember] = useState(selectedOrganizationMember);
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedOrganizationMember?.merchantIds);
  const [loading, setLoading] = useState(false);

  const [controlledUser, setControlledUser] = useState(
    userForCreation.email ? userForCreation : selectedOrganizationMember.user,
  );

  const idOrganization = organization?.id || 0;
  const userId = selectedMember?.user?.id;

  useEffect(() => {
    if (selectedMember?.user) {
      // IF EDIT MODE
      const { id, merchantIds } = selectedMember;
      if (id) {
        setSelectedIds(merchantIds);
      }
    } else {
      // UPDATE MODE
      const allUsers: any = [...organizationUsers, ...merchantUsers];
      const isUserExist = allUsers?.find((us) => us?.user?.email === controlledUser?.email);
      if (isUserExist) {
        const { id, merchantIds } = isUserExist;
        if (id) {
          setSelectedIds(merchantIds);
        }
        setSelectedMember(isUserExist);
      }
    }
  }, []);

  const selectedAll = merchants && selectedIds?.length === merchants?.length;

  const handleClick = (childs) => (e) => {
    const { id, checked } = e?.target;
    if (checked) {
      setSelectedIds([...(selectedIds || []), id.toString()]);
    } else {
      const selectedItems = selectedIds?.filter((item) => item !== id.toString());
      setSelectedIds(selectedItems);
    }
  };

  const goBack = () => {
    setActiveStep(UserManagementStepEnums.ASSIGN_ROLE);
  };

  const addOrganizationUserRequest = async (user) => {
    try {
      const idUser = user ? user.id : userId;

      const { data } = await addOrganizationUser({
        variables: { input: { organizationId: idOrganization, userId: idUser, role: selectedRole } },
      });
      setLoading(false);
      if (data.addOrganizationUser.success) {
        alert.success(selectedMember?.user ? 'User Updated.' : 'User Created.');
        return handleClose();
      } else {
        alert.error(data?.addOrganizationUser?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const updateOrganizationUserRequest = async () => {
    try {
      const { data } = await removeOrganizationUser({
        variables: { input: { userId, organizationId: idOrganization } },
      });

      if (data?.removeOrganizationUser?.success) {
        addOrganizationUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (error) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const addMerchantUserAndMerchantGroupUserRequest = async (user) => {
    try {
      const idUser = user ? user.id : userId;

      const {
        data: { addMerchantUser: addMerchantUserResult },
      } = await addMerchantUser({
        variables: {
          input: {
            idUser,
            idMerchants: selectedIds,
            role: selectedRole,
          },
        },
      });
      setLoading(false);
      if (addMerchantUserResult.success) {
        alert.success(selectedMember?.user ? 'User Updated.' : 'User Created.');

        return handleClose();
      }
      alert.error(addMerchantUserResult?.message);
    } catch (err) {
      alert.error('An error occurred');
      setLoading(false);
    }
  };

  const updateMerchantUserRequest = async () => {
    try {
      const {
        data: { deleteMerchantUser: deleteMerchantUserResult },
      } = await deleteMerchantUser({
        variables: { input: { userId, idMerchants: selectedMember?.merchantIds } },
      });
      if (deleteMerchantUserResult.success) {
        addMerchantUserAndMerchantGroupUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const updateUserOrganizationToMerchant = async () => {
    try {
      const { data } = await removeOrganizationUser({
        variables: { input: { userId, organizationId: idOrganization } },
      });

      if (data?.removeOrganizationUser?.success) {
        addMerchantUserAndMerchantGroupUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const updateUserMerchantToOrganization = async () => {
    try {
      const {
        data: { deleteMerchantUser: deleteMerchantUserResult },
      } = await deleteMerchantUser({
        variables: { input: { userId, idMerchants: selectedMember?.merchantIds } },
      });
      if (deleteMerchantUserResult?.success) {
        addOrganizationUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const decideTeamMemberType = (activeUser) => {
    if (selectedMember?.organization) {
      // ORGANIZATION USER
      if (selectedAll && selectedMember?.role === selectedRole) {
        setLoading(false);
        handleClose();
      } else if (selectedAll && selectedMember?.role !== selectedRole) {
        updateOrganizationUserRequest();
      } else if (!selectedAll) {
        updateUserOrganizationToMerchant();
      }
    } else if (selectedMember?.merchant) {
      // MERCHANT USER
      if (selectedAll) {
        updateUserMerchantToOrganization();
      } else if (
        selectedMember?.role === selectedRole &&
        JSON.stringify(selectedMember?.merchantIds?.sort()) === JSON.stringify(selectedIds?.sort())
      ) {
        setLoading(false);
        handleClose();
      } else {
        updateMerchantUserRequest();
      }
    } else {
      if (selectedAll) {
        addOrganizationUserRequest(activeUser);
      } else {
        addMerchantUserAndMerchantGroupUserRequest(activeUser);
      }
    }
  };

  const createTeamMember = async () => {
    try {
      setLoading(true);
      const { email, firstName, lastName, employeeId } = controlledUser;
      const params = { email, firstName, lastName, employeeId };

      if (!selectedMember?.user) {
        const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
        trackSegmentEvent('Created new user', {
          application: applicationName,
          organizationId: organization?.id,
          merchantId: idMerchant,
          newUserRole: selectedRole,
          screenName: 'Location Access',
        });
      }

      const { data } = await createUser({ variables: { input: { ...params } } });
      if (data?.createUserRequest?.error) {
        setLoading(false);
        alert.error(data?.createUserRequest?.message);
      } else {
        setControlledUser(data?.createUserRequest.user);
        decideTeamMemberType(data?.createUserRequest.user);
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };
  const handleSelectAll = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setSelectedIds(merchants!.map((merchant) => merchant?.id));
    } else {
      setSelectedIds([]);
    }
  };

  const renderSwitchList = ({ merchantNodes, disabled = false, isChecked = false }) => (
    <ul>
      {merchantNodes?.map(({ childs, id, name }) => {
        return (
          <li key={`MERCHANT-${id}`}>
            <Locations>
              <SwitchText>
                <Name>{name}</Name>
                <Address>{merchants?.find((m) => m.id.toString() === id.toString())?.address?.address1}</Address>
              </SwitchText>
              <Switch
                id={id.toString()}
                disabled={disabled}
                name={name}
                handleClick={handleClick(childs)}
                isChecked={isChecked || selectedIds?.includes(id.toString())}
              />
            </Locations>
            {renderSwitchList({
              merchantNodes: childs,
            })}
          </li>
        );
      })}
    </ul>
  );
  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Location Access
      </CherryDialogTitle>
      <SubTitle>
        Which locations should
        {` ${controlledUser?.firstName} ${controlledUser?.lastName} `} have access to?
      </SubTitle>
      <DialogContent>
        <LocationContainer>
          <SelectAllLocation>
            <TextContainer>Select All Locations</TextContainer>
            <Switch
              name="selectAll"
              id="selectAll"
              handleClick={handleSelectAll}
              isChecked={selectedIds?.length === merchants?.length}
            />
          </SelectAllLocation>
          {renderSwitchList({ merchantNodes: merchants })}
        </LocationContainer>
      </DialogContent>

      <Grid container={true} spacing={3}>
        <Grid item={true} xs={5}>
          <CherryButton fullWidth={true} variant="secondary" disabled={false} onClick={goBack}>
            Back
          </CherryButton>
        </Grid>
        <Grid item={true} xs={7}>
          <CherryButton
            fullWidth={true}
            variant="primary"
            disabled={!selectedIds || selectedIds?.length < 1 || loading}
            loading={loading}
            onClick={createTeamMember}
            data-testid="location-continue-button"
          >
            {selectedMember?.user ? 'Update Team Member' : 'Create Team Member'}
          </CherryButton>
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ul {
    list-style-type: none;
    padding-inline-start: 24px;
  }

  > ul {
    padding: 0;
  }
`;

const Locations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;

const SwitchText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Address = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const SelectAllLocation = styled(Locations)`
  margin-bottom: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  > div:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;
