import Fade from '@mui/material/Fade';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { Header, Navbar, Sidebar } from 'lib/components/mobile';

interface Props {
  children: React.ReactNode;
  type?: string;
  backUrl?: string;
  pageTitle: string;
}

export const Container = ({ children, type, backUrl = '', pageTitle }: Props) => {
  const location = useLocation();
  const [sidebarStatus, setSidebarStatus] = useState(false);

  return (
    <Fade in={true} timeout={750}>
      <Main>
        <InnerContainer type={type}>
          <Header pathname={location.pathname} pageTitle={pageTitle} backUrl={backUrl} />
          <Sidebar status={sidebarStatus} setSidebarStatus={setSidebarStatus} />
          <ChildrenContainer type={type}>{children}</ChildrenContainer>
          <Navbar setSidebarStatus={setSidebarStatus} />
        </InnerContainer>
      </Main>
    </Fade>
  );
};

const Main = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  justify-content: center;
  text-align: center;
  background-color: #f0f2f4;
  position: fixed;
  height: 100%;
  width: 100%;
`;

const InnerContainer = styled.div<{ type?: string }>`
  background-color: ${theme.main.white};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin-top: 0px;
    margin-bottom: 0px;
    height: ${(props) => (props.type === 'agreement' ? 'none' : '100%')};
    min-height: ${(props) => (props.type === 'agreement' ? 'none' : '100%')};
  }
`;

const ChildrenContainer = styled.div<{ type?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: ${(props) => (props.type === 'agreement' ? 'auto' : 'calc((100% - 110px))')};
  }
`;
