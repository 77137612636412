import { gql } from '@apollo/client/core';

export const GET_MARKETING_MATERIAL_HISTORY = gql`
  query getMarketingMaterialHistory($input: GetMarketingMaterialHistoryInput) {
    getMarketingMaterialHistory(input: $input) {
      marketingItemsModelList {
        userName
        orderTime
        documentType
        quantity
      }
      limit
      page
      totalRecordCount
      totalPageCount
    }
  }
`;
