import MuiBox from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';

export const CardContent = styled.div`
  margin: 0 24px 24px;
  display: grid;
  gap: 20px;

  &.mt {
    margin-top: 20px;
  }
`;

export const CardTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  &.large {
    font-size: 20px;
  }
`;

export const CardDescription = styled.div`
  font-size: 14px;
  line-height: 19px;
`;

export const CardLogo = styled.div``;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
  margin-bottom: 28px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: end;
`;

export const ActionButton = styled.div`
  color: #9fa6ac;
  padding: 4px;
  margin: 4px;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
`;

export const DarkButton = styled.div`
  width: fit-content;
  border: 1px solid #000;
  padding: 8px 12px;
  background-color: #fff;
  margin-right: 16px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #000;
    color: #fff;
  }
`;

export const Box = ({ children, onSubmit }: { children?: React.ReactNode; onSubmit?: () => void }) => {
  return (
    <Container
      component="form"
      onSubmit={onSubmit}
      sx={{
        '& .MuiTextField-root': { mb: 1, width: '100%' },
      }}
    >
      {children}
    </Container>
  );
};

const Container = styled(MuiBox)`
  width: 100% !important;
`;
