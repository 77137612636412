import { APP_ENV } from 'config';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

interface Parameters {
  isScreen?: boolean;
}

export enum LoginAnalyticsEventNames {
  USER = 'USER',
  EMAIL_BLUR = 'EMAIL_BLUR',
  PASS_BLUR = 'PASS_BLUR',
  ERROR = 'ERROR',
  FP_CLICK = 'FP_CLICK',
}

export enum ForgotPasswordAnalyticsEventNames {
  SEND_LINK = 'SEND_LINK',
  EMAIL_BLUR = 'EMAIL_BLUR',
  CLICK_TO_LOGIN = 'CLICK_TO_LOGIN',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum OrganizationSelectAnalyticsEventNames {
  SELECT_ORG = 'SELECT_ORG',
  CONTINUE = 'CONTINUE',
  CLICK_TO_LOGIN = 'CLICK_TO_LOGIN',
}

export enum GeneralAnalyticsEventNames {
  CHAT_CLICKED = 'CHAT_CLICKED',
  MENU_EXPAND = 'MENU_EXPAND',
  MENU_DASHBOARD = 'MENU_DASHBOARD',
  MENU_SALES = 'MENU_SALES',
  MENU_TRANSACTIONS = 'MENU_TRANSACTIONS',
  MENU_TRAINING = 'MENU_TRAINING',
  MENU_STATEMENTS = 'MENU_STATEMENTS',
  MENU_HELP = 'MENU_HELP',
  MENU_REFERRAL = 'MENU_REFERRAL',
  MENU_MARKETING = 'MENU_MARKETING',
  MENU_SETTINGS = 'MENU_SETTINGS',
  MENU_ASSOCIATES = 'MENU_ASSOCIATES',
  MENU_LOGOUT = 'MENU_LOGOUT',
  STORE_TOGGLE = 'STORE_TOGGLE',
  HEADER_LOGO_CLICK = 'HEADER_LOGO_CLICK',
  MENU_REPORTING = 'MENU_REPORTING',
  MENU_ORGANIZATION_GROUP_STRUCTURE = 'MENU_ORGANIZATION_GROUP_STRUCTURE',
  MENU_MARKETING_SOCIAL_MEDIA = 'MENU_MARKETING_SOCIAL_MEDIA',
  MENU_MARKETING_EMAIL_TEMPLATES = 'MENU_MARKETING_EMAIL_TEMPLATES',
  MENU_MARKETING_TEXT_MESSAGES = 'MENU_MARKETING_TEXT_MESSAGES',
  MENU_MARKETING_WEB_RESOURCES = 'MENU_MARKETING_WEB_RESOURCES',
  MENU_MARKETING_OTHER_RESOURCES = 'MENU_MARKETING_OTHER_RESOURCES',
  MENU_DOCUMENT_REQUESTS = 'MENU_DOCUMENT_REQUESTS',
  MENU_MEMBERSHIP = 'MENU_MEMBERSHIP',
  MENU_PLAN_BUILDER = 'MENU_PLAN_BUILDER',
  MENU_MEMBERSHIP_SETTINGS = 'MENU_MEMBERSHIP_SETTINGS',
  MENU_SHOP = 'MENU_SHOP',
  MENU_SHOP_EDITOR = 'MENU_SHOP_EDITOR',
  MENU_SHOP_ORDERS = 'MENU_SHOP_ORDERS',
  MENU_SHOP_EMAILS = 'MENU_SHOP_EMAILS',
  MENU_SHOP_SETTINGS = 'MENU_SHOP_SETTINGS',
  MENU_POINT_OF_SALE = 'MENU_POINT_OF_SALE',
  MENU_POINT_OF_SALE_SETTINGS = 'MENU_POINT_OF_SALE_SETTINGS',
}

export enum DashboardAnalyticsEventNames {
  SEARCH_CONTRACTS = 'SEARCH_CONTRACTS',
  REFRESH_DATA = 'REFRESH_DATA',
  FILTERS_ON_CLICK = 'FILTERS_ON_CLICK',
  RESET_FILTERS_CLICK = 'RESET_FILTERS_CLICK',
  SEND_APP_BLUR = 'SEND_APP_BLUR',
  SEND_APP_LINK = 'SEND_APP_LINK',
  SEND_APP_CHECKOUT_LINK = 'SEND_APP_CHECKOUT_LINK',
  SEND_APP_LINK_ERR = 'SEND_APP_LINK_ERR',
  SEND_APP_CHECKOUT_LINK_ERR = 'SEND_APP_CHECKOUT_LINK_ERR',
  SALE_CLICK = 'SALE_CLICK',
  SALE_DROP_SELECTED = 'SALE_DROP_SELECTED',
  APPROVAL_FILTER = 'APPROVAL_FILTER',
  APPROVAL_FILTER_SELECTED = 'APPROVAL_FILTER_SELECTED',
  ONLY_PENDING = 'ONLY_PENDING',
  PAGINATION_USED = 'PAGINATION_USED',

  CHECKOUT_STORE_CHANGE = 'CHECKOUT_STORE_CHANGE',
  CHECKOUT_CHECKOUT_CLICK = 'CHECKOUT_CHECKOUT_CLICK',
  CHECKOUT_CANCEL_CLICK = 'CHECKOUT_CANCEL_CLICK',
  CHECKOUT_WAITINGFOR_CUSTOMER_CLICK = 'CHECKOUT_WAITINGFOR_CUSTOMER_CLICK',
  CHECKOUT_SUCCESS_CLOSE_CLICK = 'CHECKOUT_SUCCESS_CLOSE_CLICK',
  CHECKOUT_ISSUE_ANOTHER_CLICK = 'CHECKOUT_ISSUE_ANOTHER_CLICK',

  VIEW_TRANSACTION_CLICK = 'VIEW_TRANSACTION_CLICK',

  PAYMENT_ESTIMATOR_BLUR = 'PAYMENT_ESTIMATOR_BLUR',
  PAYMENT_ESTIMATOR_SLIDER_TERM_CHANGE = 'PAYMENT_ESTIMATOR_SLIDER_TERM_CHANGE',
  PAYMENT_ESTIMATOR_CREDIT_SCORE_CHANGE = 'PAYMENT_ESTIMATOR_CREDIT_SCORE_CHANGE',
}

export enum SalesAnalyticsEventNames {
  DATE_CLICK = 'DATE_CLICK',
  DATE_SELECTED = 'DATE_SELECTED',
  OVERALL = 'OVERALL',
  ASSOCIATE = 'ASSOCIATE',
  STORES = 'STORES',
}

export enum ReportingAnalyticsEventNames {
  APPROVALS_DOWNLOAD_CLICK = 'APPROVALS_DOWNLOAD_CLICK',
  APPLICATION_DOWNLOAD_CLICK = 'APPLICATION_DOWNLOAD_CLICK',
  EXPIRED_APPROVALS_DOWNLOAD_CLICK = 'EXPIRED_APPROVALS_DOWNLOAD_CLICK',
  TRANSACTION_DOWNLOAD_CLICK = 'TRANSACTION_DOWNLOAD_CLICK',
  TRANSACTION_CONVERSION_DOWNLOAD_CLICK = 'TRANSACTION_CONVERSION_DOWNLOAD_CLICK',
  PAYMENT_PLAN_DOWNLOAD = 'PAYMENT_PLAN_DOWNLOAD',
  REPEAT_PATIENTS_DOWNLOAD_CLICK = 'REPEAT_PATIENTS_DOWNLOAD_CLICK',
  REPEAT_PATIENTS_DISTRIBUTION_DOWNLOAD_CLICK = 'REPEAT_PATIENTS_DISTRIBUTION_DOWNLOAD_CLICK',
}

export enum AssociatesAnalyticsEventNames {
  STORE_DROP = 'STORE_DROP',
  NAME_BLUR = 'NAME_BLUR',
  PHONE_BLUR = 'PHONE_BLUR',
  EMAIL_BLUR = 'EMAIL_BLUR',
  ADD_ASSOCIATE = 'ADD_ASSOCIATE',
  TRASH_CLICK = 'TRASH_CLICK',
  MODAL_APPROVE = 'MODAL_APPROVE',
  MODAL_DENY = 'MODAL_DENY',
  DOWN_LINK = 'DOWN_LINK',
  PAGINATE_TABLE = 'PAGINATE_TABLE',
  DOC_LINK = 'DOC_LINK',
}

export enum TransactionsAnalyticsEventNames {
  SEARCH_BLUR = 'SEARCH_BLUR',
  DATE_DROP_CLICK = 'DATE_DROP_CLICK',
  DATE_SELECTED = 'DATE_SELECTED',
  ROWS_CHANGE = 'ROWS_CHANGE',
  SORT_CHANGE = 'SORT_CHANGE',
  DOWNLOAD_CLICK = 'DOWNLOAD_CLICK',
}

export enum TrainingAnalyticsEventNames {
  CTA_BUTTONS = 'CTA_BUTTONS',
  VIDEO_CLICK = 'VIDEO_CLICK',
  FAQ_CLICK = 'FAQ_CLICK',
  GUIDE_CLICK = 'GUIDE_CLICK',
}

export enum MarketingAnalyticsEventNames {
  CTA_BUTTONS = 'CTA_BUTTONS',
  COPY_CLIPBOARD = 'COPY_CLIPBOARD',
  SINGLE_MATERIAL_DOWNLOAD = 'SINGLE_MATERIAL_DOWNLOAD,',
  SINGLE_MATERIAL_IMAGE = 'SINGLE_MATERIAL_IMAGE',
  REQUEST_MATERIALS_CLICK = 'REQUEST_MATERIALS_CLICK',
  REQUEST_MATERIALS_EDIT_ADDRESS = 'REQUEST_MATERIALS_EDIT_ADDRESS',
  DOWNLOAD_QR_CODE = 'DOWNLOAD_QR_CODE',
}

export enum SettingsAnalyticsEventNames {}

interface TrackArgs {
  category?: string;
  action: string;
  label?: string;
  value?: number;
}

export const useAnalytics = ({ isScreen = false }: Parameters = {}) => {
  useEffect(() => {
    if (isScreen) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const trackEvent = (eventParams: TrackArgs) => {
    ReactGA.event({
      ...eventParams,
      category: APP_ENV || '',
    });
  };

  return { trackEvent };
};
