import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { RoxFetcherResult } from 'rox-browser';
import Rox from 'rox-browser';

import { CLOUDBEES_APIKEY } from 'config';
import { Flags } from 'lib/hooks/FeatureManagement/Flags';

interface ContextState {
  flags: typeof Flags;
}

const initialState = {
  flags: {
    ...Flags,
  },
};

const FlagsContext = createContext<ContextState | undefined>(undefined);

export const FeatureFlagsContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState(initialState);

  const roxSetup = useCallback(async () => {
    try {
      console.log('Initializing SDK');
      await Rox.setup(CLOUDBEES_APIKEY!, {
        configurationFetchedHandler: (fetcherResult: RoxFetcherResult) => {
          if (fetcherResult.hasChanges) {
            setState({ flags: { ...Flags } });
          }
        },
      });
      console.log('Rox was initialized');

      setTimeout(async () => {
        // When the environment has never been initialized before, because some edge conditions, SSE may not be started properly
        // Forcing the fetch makes sure it will be
        Rox.fetch();
      }, 20000);
    } catch (err) {
      console.error('Rox initialization failed', err);
    }
  }, []);

  useEffect(() => {
    roxSetup();
  }, [roxSetup]);

  return <FlagsContext.Provider value={state}>{children}</FlagsContext.Provider>;
};

export function useFlags() {
  const state = useContext(FlagsContext);
  if (!state) throw new Error('useFlags can only be used inside a FlagsContextProvider');

  return state;
}
