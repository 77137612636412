import { Button as CherryButton, EmailInput } from '@frontend/cherry-library';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { useForgotPassword } from 'lib/services';
import { useSegment } from 'lib/hooks/useSegment';
import { AuthLayout, Card, LinkItem } from 'lib/components';
import images from 'assets/images';

const ForgotPassword = () => {
  const { forgotPassRenderFirstTime, setForgotPassRenderFirstTime } = useStore();
  const { success, inputChange, loading, emailOnBlur, email, logNavigateToAnalytics, sendLink } = useForgotPassword();
  const { trackIdentify, trackPage } = useSegment();

  useEffect(() => {
    trackIdentify();
    trackLandingPage();
  }, []);

  const trackLandingPage = () => {
    if (!forgotPassRenderFirstTime) {
      setForgotPassRenderFirstTime(true);
      trackPage('passwordResetPage');
    }
  };

  return (
    <AuthLayout>
      <CardWrapper>
        <CherryLogo src={images.cherryLogo.default} />
        <ContentWrapper>
          {success ? (
            <SuccessInfo>
              If a matching account was found an email was sent to {email} to allow you to reset your password!
            </SuccessInfo>
          ) : (
            <>
              <Title>Please enter your email</Title>
              <Subhead>You will be sent a link to reset your password</Subhead>
              <EmailInput onChange={inputChange} label="Email" inputSize="small" onBlur={emailOnBlur} margin="normal" />
              <CherryButton
                fullWidth={true}
                variant="primary"
                loading={loading}
                disabled={!email || loading}
                onClick={sendLink}
                size="small"
              >
                Send Link
              </CherryButton>
            </>
          )}
          <LinkItem onClick={logNavigateToAnalytics} to="/login">
            Click Here to Login
          </LinkItem>
        </ContentWrapper>
      </CardWrapper>
    </AuthLayout>
  );
};

export default ForgotPassword;

const CardWrapper = styled(Card)`
  width: 500px;
  min-height: 400px;
  max-height: 450px;
  align-items: center;
  justify-content: center;
`;

const CherryLogo = styled.img`
  width: 200px;
  height: auto;
`;

const Title = styled.h3`
  margin: 20px auto 5px;
`;

const Subhead = styled.p`
  font-style: italic;
  font-size: 12px;
  margin: 5px auto 20px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

const SuccessInfo = styled.h4`
  text-align: center;
  margin: 20px auto 5px;
`;
