import React from 'react';
import styled from 'styled-components';

import { ContactInfoForm, MembershipInfoForm, PaymentInfoForm } from 'pages/desktop/Membership/components/MemberDetail';
import { ContactInfo, MemberShipInfo, PaymentInfo, PaymentMethod } from 'pages/desktop/Membership/type';

interface Props {
  onContactInfoHandler: (key: string, value: any) => void;
  onMemberInfoHandler: (key: string, value: any) => void;
  setPaymentInfoHandler: (key: string, value: any) => void;
  paymentMethodLoadingHandler: (status: boolean) => void;
  contactInfo: ContactInfo;
  memberInfo: MemberShipInfo;
  paymentInfo: PaymentInfo;
  customerPaymentData: PaymentMethod | undefined;
}

export const EditMemberInfo = ({
  onContactInfoHandler,
  onMemberInfoHandler,
  setPaymentInfoHandler,
  contactInfo,
  memberInfo,
  paymentInfo,
  customerPaymentData,
  paymentMethodLoadingHandler,
}: Props) => {
  return (
    <Container>
      <ContactInfoForm onChangeHandler={onContactInfoHandler} contactInfo={contactInfo} />
      <MembershipInfoForm onChangeHandler={onMemberInfoHandler} memberInfo={memberInfo} />
      <PaymentInfoForm
        onChangeHandler={setPaymentInfoHandler}
        paymentMethodInfo={customerPaymentData}
        paymentMethodLoadingHandler={paymentMethodLoadingHandler}
      />
    </Container>
  );
};

const Container = styled.div``;
