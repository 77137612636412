import React, { useState } from 'react';
import { Body, Button, Heading, formatPhoneNumber, theme } from '@frontend/cherry-library';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/images/warning_outline.svg';
import useMerchantsContactAccountList from 'lib/hooks/useMerchantsContactAccountList';
import { useTranslation } from 'react-i18next';

interface SplashScreenProps {
  handleCloseSplashScreen: () => void;
  children: React.ReactNode;
  initialVisibility: boolean;
}

export const SplashScreen = ({ handleCloseSplashScreen, children, initialVisibility }: SplashScreenProps) => {
  const { t: translate } = useTranslation();
  const { getAccountOfType } = useMerchantsContactAccountList();
  const [isSplashVisible, setSplashVisible] = useState(initialVisibility);

  const closeSplashScreen = () => {
    setSplashVisible(false);
    if (handleCloseSplashScreen) {
      handleCloseSplashScreen();
    }
  };

  const account = getAccountOfType('ONBOARDING') || getAccountOfType('ACCOUNT');

  return (
    <>
      {isSplashVisible ? (
        <StyledSplashScreen>
          <StyledSplashContent>
            <Heading level="3" text={translate('demoSplashScreen.mode')} />
            <AttentionIcon height={64} width={64} />
            <Heading level="4" text={translate('demoSplashScreen.welcome')} />
            <BodyWrapper>
              <Body>
                {translate('demoSplashScreen.info')} <b>{translate('demoSplashScreen.testing')}</b>
              </Body>
            </BodyWrapper>
            <Heading level="4" text={translate('demoSplashScreen.live')} />
            <BodyWrapper>
              <Body>{translate('demoSplashScreen.contact')}</Body>
            </BodyWrapper>
            {account && (
              <BodyWrapper>
                <Body>
                  <b>{account?.name}</b>
                </Body>
                <Body>
                  <LinkText href={`tel:${account?.phone}`}>{formatPhoneNumber(account?.phone)}</LinkText>
                </Body>
                <Body>
                  <LinkText href={`mailto:${account?.email}`}>{account?.email}</LinkText>
                </Body>
              </BodyWrapper>
            )}
            <StyledSplashButton variant="secondary" onClick={closeSplashScreen}>
              {translate('demoSplashScreen.continue')}
            </StyledSplashButton>
          </StyledSplashContent>
        </StyledSplashScreen>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

const StyledSplashScreen = styled.div`
  position: fixed;
  top: 92px;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.main.alertWarning};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 916px) {
    left: unset;
  }
`;

const StyledSplashContent = styled.div`
  text-align: center;
  font-size: 24px;
  max-width: 40%;

  @media (max-width: 480px) {
    max-width: 84%;
  }
`;

const AttentionIcon = styled(Icon)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledSplashButton = styled(Button)`
  display: inline !important;
  background: unset !important;
  margin-top: 24px;
`;

const BodyWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const LinkText = styled.a`
  color: ${(props) => props.theme.main.black};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`;
