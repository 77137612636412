import { useEffect } from 'react';

export const useLastClicked = () => {
  useEffect(() => {
    document.addEventListener('click', (e) => {
      e = e || window.event;
      const target = e.target || e.srcElement;
      window['lastClickedElm'] = target;
    });
  }, []);

  const isLastClickedParent = (elmId: string) => {
    // check if the popup is open
    if (document.getElementsByClassName('MuiPopover-root popover-container').length) {
      return true;
    }

    return document.getElementById(elmId)?.contains(window?.['lastClickedElm']);
  };

  return { isLastClickedParent };
};
