export const DataTableStyle = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      color: '#000000', // '#00C37C',
    },
  },
  cells: {
    style: {},
  },
};

export const PatientTrackerCheckoutReadyDataTableStyle = {
  headCells: {
    style: {
      color: '#000000', // '#00C37C',
    },
  },
  cells: {
    style: {},
  },
};

export const MobileDataTableStyle = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      color: '#0E202F', // '#00C37C',
      paddingLeft: '0px',
      fontWeight: 600,
    },
  },
  headRow: {
    style: {
      minHeight: '28px',
    },
  },
  cells: {
    style: {
      paddingLeft: '0px',
      paddingRight: '8px',
    },
  },
};

export const apiKeyTable = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {},
  },
};

export const linkHistoryTableSettings = {
  rows: {
    style: {
      padding: '12px 0',
    },
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      width: '580px',
    },
  },
};

export const settingsTableStyle = {
  rows: {
    style: {
      padding: '12px 0',
    },
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      width: '780px',
    },
  },
};

export const membershipTableStyle = {
  rows: {
    style: {
      padding: '12px 0',
    },
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      width: '880px',
    },
  },
};

export const membershipBenefitTableStyle = {
  rows: {
    style: {
      padding: '12px 0',
    },
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      width: '1100px',
    },
  },
};

export const estimateHistoryTableStyle = {
  rows: {
    style: {
      padding: '12px 0',
    },
  },
  headCells: {
    style: {
      color: '#0E202F',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      width: '580px',
    },
  },
};
