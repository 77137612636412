import { useMutation } from '@apollo/client';
import { ChangeEventHandler, FocusEventHandler, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';

import { phonePattern, sanitizePhoneNumber } from '@frontend/cherry-library';
import { isEmail } from 'class-validator';
import {
  SEND_APPLICATION_LINK,
  SEND_APPLICATION_LINK_EMAIL,
  SEND_APPLY_CHECKOUT_LINK,
  SEND_BULK_SMS,
} from 'lib/graphql/mutations';
import { DashboardAnalyticsEventNames, FeatureNames, useAnalytics, useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { browserIsMobile } from 'lib/utils';
import { SyntheticInputEvent } from 'react-number-format/types/types';

export const useSendApplicationLink = (isCombinedField = false) => {
  const { merchants, organization } = useStore();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const [sendApplyCheckoutLink] = useMutation(SEND_APPLY_CHECKOUT_LINK);
  const [sendOrganizationLinkEmail] = useMutation(SEND_APPLICATION_LINK_EMAIL);
  const [sendBulkSms] = useMutation(SEND_BULK_SMS);
  const { merchantLogin, merchantLogout } = useMerchantLogin();
  const alert = useAlert();
  const { features } = useStore();

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
  const [applicationSending, setApplicationSending] = useState<boolean>(false);
  const [applicationSent, setApplicationSent] = useState(false);
  const [phoneFormatValid, setPhoneFormatValid] = useState<boolean>(true);
  const [type, setType] = useState<'phone' | 'email'>('phone');
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isApplyCheckoutModalVisible, setIsApplyCheckoutModalVisible] = useState(false);

  const [confettiActive, setConfettiActive] = useState<boolean>(false);
  const phoneRegex = useMemo(() => phonePattern, []);

  const onPhoneChange = (event) => {
    const rawPhoneValue = event.target.value;

    if (!rawPhoneValue.includes('_')) {
      if (phoneRegex.test(rawPhoneValue)) {
        setPhoneFormatValid(true);
      } else {
        setPhoneFormatValid(false);
      }
    }

    if (phoneRegex.test(rawPhoneValue)) {
      setPhoneNumberValid(true);
      setPhoneFormatValid(true);
    } else {
      setPhoneNumberValid(false);
    }
    const parsedPhoneInput = sanitizePhoneNumber(rawPhoneValue);
    setPhoneNumber(parsedPhoneInput);
  };

  const sendBulkApplication = async (phones: string[]) => {
    try {
      const idMerchant = merchants?.[0]?.id || 0;
      const organizationId = organization?.id || 0;

      await merchantLogin(idMerchant);

      const { data } = await sendBulkSms({
        variables: {
          input: {
            organizationId,
            phones,
          },
        },
      });
      if (!data?.sendBulkSms?.success) {
        throw new Error(data?.sendBulkSms?.message);
      }
    } catch (error) {
      merchantLogout();

      throw error;
    }
  };

  const handleSendApplicationLinkSuccess = () => {
    if (browserIsMobile() || isCombinedField) {
      alert.success('Success: Application Sent!');
    }
    setApplicationSending(false);
    setApplicationSent(true);

    setTimeout(() => {
      setConfettiActive(true);
    }, 300);

    setTimeout(() => {
      setApplicationSent(false);
      setPhoneNumberValid(false);
      setValid(false);
      setEmail('');
      setPhoneNumber('');
      setConfettiActive(false);
    }, 3000);
  };

  const sendApplicationLink = async (sendLink, sendLinkVariables) => {
    try {
      setApplicationSending(true);
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK,
      });
      const idMerchant = merchants?.[0]?.id || 0;

      await merchantLogin(idMerchant);

      const { data } = await sendLink(sendLinkVariables);

      if (data?.sendOrganizationLink?.success || data?.sendOrganizationLinkEmail?.success) {
        handleSendApplicationLinkSuccess();
      } else {
        alert.error('An error occurred. Please try again.');
        setApplicationSending(false);
      }

      trackSegmentEvent('Application Sent', {
        application: applicationName,
        was_success: data?.sendOrganizationLink?.success,
      });

      merchantLogout();
    } catch (e) {
      merchantLogout();
      setApplicationSending(false);
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK_ERR,
      });
    }
  };

  const sendApplyCheckout = async ({
    merchantId,
    treatmentAmount,
  }: {
    merchantId: number;
    treatmentAmount: number;
  }) => {
    try {
      setApplicationSending(true);
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_CHECKOUT_LINK,
      });
      const organizationId = organization?.id || 0;

      const { data } = await sendApplyCheckoutLink({
        variables: { input: { organizationId, phone: phoneNumber, merchantId, treatmentAmount } },
      });

      if (data?.sendApplyCheckoutLink?.success) {
        if (browserIsMobile()) {
          alert.success('Success: Application Sent!');
        }
        setApplicationSending(false);
        setApplicationSent(true);

        setTimeout(() => {
          setConfettiActive(true);
        }, 300);

        setTimeout(() => {
          setApplicationSent(false);
          setPhoneNumberValid(false);
          setPhoneNumber('');
          setConfettiActive(false);
        }, 3000);
      } else {
        alert.error('An error occurred. Please try again.');
        setApplicationSending(false);
      }
    } catch (e) {
      setApplicationSending(false);
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_CHECKOUT_LINK_ERR,
      });
    }
  };

  const sendApplication = async () => {
    const idOrganization = organization?.id || 0;
    if (type === 'email') {
      await sendApplicationLink(sendOrganizationLinkEmail, { variables: { input: { idOrganization, email } } });
    } else {
      await sendApplicationLink(sendOrganizationLink, { variables: { input: { idOrganization, phone: phoneNumber } } });
    }
  };

  const logInputBlur = (event: SyntheticInputEvent) => {
    const rawPhoneValue = event.target.value;

    if (!rawPhoneValue.includes('_') && phoneRegex.test(rawPhoneValue)) {
      setPhoneFormatValid(true);
    } else {
      setPhoneFormatValid(false);
    }

    trackEvent({
      action: DashboardAnalyticsEventNames.SEND_APP_BLUR,
    });
  };

  const moveApplicationSendView = () => {
    setConfettiActive(false);
    setApplicationSent(false);
  };

  const toggleSms = () => {
    setType('phone');

    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.TEXT_TAB_CLICKED');
  };

  const toggleEmail = () => {
    setType('email');

    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.EMAIL_TAB_CLICKED');
  };

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const data = e.target?.value;
    const isValid = isEmail(data);

    setEmail(data);
    setValid(isValid);
    setShowError(data === '' ? false : !isValid);
  };

  const onCombinedFieldChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const data = e.target?.value;
    if (phoneRegex.test(data)) {
      setType('phone');
      onPhoneChange(e);
    } else if (isEmail(data)) {
      setType('email');
      onEmailChange(e);
    } else {
      setPhoneNumberValid(false);
      setPhoneFormatValid(false);
      setValid(false);
      setShowError(data === '' ? false : true);
    }
  };

  const blurHandler: FocusEventHandler<HTMLInputElement> = (event) => {
    onEmailChange(event);
  };

  const handleSendClick = () => {
    if (type === 'phone') {
      trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.TEXT_LINK_SUBMIT', { phoneNumber });
    } else {
      trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.EMAIL_LINK_SUBMIT', { email });
    }

    if (features?.includes(FeatureNames.APPLY_CHECKOUT_COMBO) && phoneNumber) {
      trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_VIEWED', { phone: phoneNumber });
      setIsApplyCheckoutModalVisible(true);
      return;
    }
    sendApplication();
  };

  const handleApplyCheckoutModalClose = () => {
    setIsApplyCheckoutModalVisible(false);
  };

  return {
    applicationSent,
    applicationSending,
    moveApplicationSendView,
    logInputBlur,
    sendApplication,
    sendBulkApplication,
    phoneFormatValid,
    phoneNumber,
    onPhoneChange,
    phoneNumberValid,
    confettiActive,
    sendApplyCheckout,
    type,
    email,
    valid,
    showError,
    toggleSms,
    toggleEmail,
    onEmailChange,
    blurHandler,
    onCombinedFieldChange,
    isApplyCheckoutModalVisible,
    setIsApplyCheckoutModalVisible,
    handleApplyCheckoutModalClose,
    handleSendClick,
  };
};
