// tslint:disable: radix
import { useMutation } from '@apollo/client';
import { Dialog, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Input } from 'lib/components';
import { PATCH_CUSTOMER_BENEFIT_USAGE } from 'lib/graphql/mutations';
import { BenefitUsageType } from 'pages/desktop/Membership/type';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';

interface Props {
  open: boolean;
  handleClose: any;
  modalData: BenefitUsageType | null;
}

export const MarkBenefitModal = ({ open, handleClose, modalData }: Props) => {
  const [patchCustomerBenefitUsage, { loading }] = useMutation(PATCH_CUSTOMER_BENEFIT_USAGE);
  const [quantity, setQuantity] = useState<string>('');
  const [quantityRemaining, setQuantityRemaining] = useState<number>(modalData?.usage || modalData?.quantity || 0);
  const isUnlimited = modalData?.frequency === 'UNLIMITED';

  useEffect(() => {
    if (open) {
      if (modalData?.usage && modalData?.quantity) {
        setQuantityRemaining(modalData?.quantity - modalData?.usage);
      } else if (modalData?.quantity) {
        setQuantityRemaining(modalData?.quantity);
      } else {
        setQuantityRemaining(0);
      }
    }
  }, [open]);

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isUnlimited) {
      setQuantity(value);
      return;
    }
    if (value) {
      if (modalData?.usage && modalData?.quantity) {
        if (modalData?.quantity >= parseInt(value) + modalData?.usage) {
          setQuantity(value.replace(/[^\d.]/gi, ''));
          setQuantityRemaining(modalData?.quantity - (parseInt(value) + modalData?.usage));
        }
      } else if (modalData?.quantity) {
        if (modalData?.quantity >= parseInt(value)) {
          setQuantity(value.replace(/[^\d.]/gi, ''));
          setQuantityRemaining(modalData?.quantity - parseInt(value));
        }
      } else {
        setQuantity(value.replace(/[^\d.]/gi, ''));
      }
    } else {
      const tempQuantityRemaining = modalData?.usage ? modalData?.quantity - modalData?.usage : modalData?.quantity;
      setQuantity('');
      setQuantityRemaining(tempQuantityRemaining || 0);
    }
  };

  const confirmBenefitUsage = async () => {
    const { data } = await patchCustomerBenefitUsage({
      variables: {
        input: {
          planId: modalData?.planId,
          benefitId: modalData?.id,
          used: parseInt(quantity),
        },
      },
    });
    if (data?.patchCustomerBenefitUsages?.success) {
      setQuantity('');
      // @ts-ignore
      handleClose(true);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Mark benefit usage?
        </CherryDialogTitle>
        {isUnlimited && (
          <SubTitle>
            This is an unlimited benefit. Enter the dollar value of the discount (i.e. if the service costs $1,000 and
            they got 10% off, enter $100).
          </SubTitle>
        )}
        <Row>
          <Column>
            <FormLabel>{isUnlimited ? 'Enter the value of the discount' : 'Enter the quantity used'}</FormLabel>
            <Input
              name="quantity"
              type="text"
              onChange={onQuantityChange}
              placeholder={isUnlimited ? 'Example: $100' : 'Example: 2'}
              value={quantity}
            />
          </Column>
        </Row>

        <SubTitle>{!isUnlimited && `${quantityRemaining} will remain.`}</SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <CancelButton disabled={false} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} xs={6}>
            <RemoveButton disabled={!quantity || loading} onClick={confirmBenefitUsage}>
              Confirm
            </RemoveButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto 10px;
`;

export const FormLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
  margin-bottom: 0px;
  margin-top: 20px;
`;

const RemoveButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;

const CancelButton = styled(BackButton)`
  border-color: 1px solid ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.green};
`;
