import {
  Box,
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  Icon,
  Subtitle,
  TextField,
  Title,
} from 'lib/components/mobile';
import { useSegment } from 'lib/hooks/useSegment';
import { useForgotPassword } from 'lib/services';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const MobileForgotPassword = () => {
  const {
    email,
    emailOnBlur,
    inputChange,
    loading,
    logNavigateToAnalytics,
    sendLink,
    success,
    emailValid,
  } = useForgotPassword();
  const { trackIdentify, trackPage } = useSegment();

  useEffect(() => {
    trackIdentify();
    trackPage('passwordResetPage')
  }, [])

  return (
    <Container pageTitle={'Password Reset'}>
      <ContentContainer>
        <Icon src={'cherry-logo'} />
        {success ? (
          <>
            <Title m={'10px 0px 8px 0px'}>Success!</Title>
            <Subtitle m={'0px 0px 24px 0px'}>
              A password reset link has been sent to <b>{email}</b>. Please click the link to create a new password.
            </Subtitle>
          </>
        ) : (
          <>
            <Title m={'10px 0px 8px 0px'}>Enter your email</Title>
            <Subtitle m={'0px 0px 24px 0px'}>You will be sent a link reset your password</Subtitle>
            <Box>
              <TextField
                id="email-input"
                variant="filled"
                data-testid="email"
                label="Email"
                type="email"
                value={email}
                onChange={inputChange}
                onBlur={emailOnBlur}
              />
            </Box>
          </>
        )}
      </ContentContainer>

      <ButtonContainer>
        {!success ? (
          <>
            <Button loading={loading} disabled={!email && !emailValid} type="submit" onClick={sendLink}>
              Send Link
            </Button>
            <ButtonText onClick={logNavigateToAnalytics}>Return to Login</ButtonText>
          </>
        ) : (
          <>
            <Button loading={loading} type="submit" onClick={logNavigateToAnalytics}>
              Return to Login
            </Button>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default MobileForgotPassword;

const ButtonText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
`;
