import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Rox from 'rox-browser';
import styled, { createGlobalStyle } from 'styled-components';

import { Loading } from 'lib/components';
import {
  FETCH_AUTH_INFO,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_DETAIL,
  FETCH_ORGANIZATION_MERCHANTS,
  FETCH_ORGANIZATION_MERCHANT_GROUPS,
  GET_MERCHANT_DETAIL,
} from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { MOBILE_MENU_ITEMS } from 'lib/utils';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const MobileAuthChecker = () => {
  const location = useLocation();
  const client = useApolloClient();
  const navigate = useNavigate();
  const query = useQuery();
  const { trackIdentify, trackGroup } = useSegment();

  const {
    setUser,
    organization,
    isComingFromLogin,
    setSelectedOrganization,
    setLocations,
    setFeatures,
    setOrganizationMerchants,
    setPermissions,
    setRole,
    setHasBankingIssue,
    setIsComingFromLogin,
    showChat,
    setShowChat,
    setOrganizationMerchantGroups,
  } = useStore();
  const [loading, setLoading] = useState(true);

  const userPermission = useRef('');

  const { token: hasToken } = StorageService.getAuthData();
  NavigateService.initNavigate(navigate);

  const getInitialRequests = async () => {
    try {
      if (hasToken) {
        let decodedToken: any = 'PARTNER';
        const { token, refreshToken } = StorageService.getAuthData();
        if (!token && !refreshToken) {
          const userData = StorageService.getUserData();
          StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
          decodedToken = jwtDecode((userData.token as any) || '');
        } else {
          decodedToken = jwtDecode((token as any) || '');
        }

        if (decodedToken?.type === 'PARTNER') {
          const {
            data: { fetchUserOrganizations },
          } = await client.query({ query: FETCH_ORGANIZATION, variables: { input: {} } });

          if (fetchUserOrganizations?.data) {
            const findOrganization = fetchUserOrganizations?.data?.find((org) => org.id === organization?.id);
            userPermission.current = findOrganization?.permissions;
            setPermissions(findOrganization?.permissions || {});
            setRole(findOrganization?.roles || '');
          }
        }

        const {
          data: { fetchOrganizationDetail },
        } = await client.query({
          query: FETCH_ORGANIZATION_DETAIL,
          variables: { input: { idOrganization: organization?.id } },
        });

        if (fetchOrganizationDetail?.organization) {
          setSelectedOrganization({
            ...fetchOrganizationDetail.organization,
            idOrganization: fetchOrganizationDetail.organization?.id,
          });
          setLocations(fetchOrganizationDetail?.locations);
          Rox.setCustomNumberProperty('organization_id', Number(fetchOrganizationDetail?.organization?.id));
          Rox.setCustomStringProperty('organization_id_str', fetchOrganizationDetail?.organization?.id?.toString());

          const activeFeatures = fetchOrganizationDetail?.features
            ?.filter((item) => {
              if (item.status === 'ACTIVE') {
                return item.type;
              }
            })
            .map((item) => {
              return item.type;
            });
          setFeatures(activeFeatures || []);

          const {
            data: { fetchOrganizationMerchants },
          } = await client.query({
            query: FETCH_ORGANIZATION_MERCHANTS,
            fetchPolicy: 'no-cache',
            variables: { input: { idOrganization: organization?.id } },
          });

          const {
            data: { fetchOrganizationMerchantGroups },
          } = await client.query({
            query: FETCH_ORGANIZATION_MERCHANT_GROUPS,
            fetchPolicy: 'no-cache',
            variables: { input: { organizationId: organization?.id } },
          });

          setOrganizationMerchantGroups(fetchOrganizationMerchantGroups || []);

          if (fetchOrganizationMerchants?.data) {
            setOrganizationMerchants(fetchOrganizationMerchants?.data || []);

            // IF USER HAS BANKING ISSUE CHECK START
            //
            // START
            const permissions = fetchOrganizationMerchants?.data?.[0]?.permissions;
            if (permissions?.['SETTINGS']?.includes('BANK_INFORMATION')) {
              try {
                const merchantIds = fetchOrganizationMerchants?.data?.map((merchant) => merchant?.id);
                const {
                  data: { getMerchantDetail },
                } = await client.query({
                  query: GET_MERCHANT_DETAIL,
                  variables: {
                    input: {
                      merchantIds,
                    },
                  },
                });

                if (getMerchantDetail?.success) {
                  const mechantHasBankIssue = getMerchantDetail.data.find((m) => m?.accountType === 'ERROR');
                  if (mechantHasBankIssue) {
                    setHasBankingIssue(true);
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }
            // IF USER HAS BANKING ISSUE CHECK
            //
            // END

            if (decodedToken?.type !== 'PARTNER') {
              userPermission.current = fetchOrganizationMerchants?.data?.[0]?.permissions || {};
              setPermissions(fetchOrganizationMerchants?.data?.[0]?.permissions || {});
              setRole(fetchOrganizationMerchants?.data?.[0]?.roles || {});
            }
          }
        }

        const {
          data: { getUserAuthInfo },
        } = await client.query({ query: FETCH_AUTH_INFO });
        if (getUserAuthInfo?.user) {
          const { role, merchants } = useStore.getState();
          const storedUseData = StorageService.getUserData();

          StorageService.setUserData({
            ...storedUseData,
            ...getUserAuthInfo,
            userType: getUserAuthInfo.tokenType === 'MERCHANT_AUTH' ? 'MERCHANT_USER' : '',
          });
          setUser({
            ...getUserAuthInfo.user,
            userType: getUserAuthInfo.tokenType === 'MERCHANT_AUTH' ? 'MERCHANT_USER' : '',
          });
          Sentry.setUser({ email: getUserAuthInfo.user.email, id: getUserAuthInfo.user.id });

          if (isComingFromLogin) {
            trackGroup(`org-${organization?.id}`, {
              industry: organization?.industry,
              userRole: role?.[0],
            });
            setIsComingFromLogin(false);
          }
          trackIdentify(getUserAuthInfo.user, organization, merchants, role);
        }

        // PERMISSION ROUTE CONTROL
        let routeLink: any = location.pathname;
        const allowedRouteNames = Object.keys(userPermission?.current || {})?.reverse();

        if (location.pathname === '/') {
          const entryPageName = allowedRouteNames?.[0];
          routeLink = MOBILE_MENU_ITEMS?.find((item) => item?.level === entryPageName)?.pathname;
        } else {
          const activeRoutePath: any = MOBILE_MENU_ITEMS.find((menu) => location.pathname.includes(menu.pathname));
          const findHasPermission = allowedRouteNames.find((routeName: string) => routeName === activeRoutePath?.level);
          if (findHasPermission) {
            if (activeRoutePath.children) {
              routeLink =
                activeRoutePath?.children?.find((child) => child.pathname === location.pathname)?.pathname ||
                '/dashboard';
            } else {
              routeLink = activeRoutePath.pathname;
            }
          } else {
            const entryPageName = allowedRouteNames?.[0];
            routeLink = MOBILE_MENU_ITEMS?.find((item) => item.level === entryPageName)?.pathname;
          }
        }

        if (query.get('type') === 'test') {
          // do nothing
        } else {
          routeLink
            ? navigate(routeLink, { state: location?.state })
            : navigate('/dashboard', { state: location?.state });
        }

        setLoading(false);
        return;
      } else {
        StorageService.clearUserData();
        navigate('/login');
        setLoading(false);
        return;
      }
    } catch (err) {
      StorageService.clearUserData();
      navigate('/login');
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialRequests();
  }, []);

  const closeChat = () => {
    setShowChat(false);
  };

  return (
    <>
      <Outlet />
      {showChat ? (
        <CloseChatButton onClick={closeChat}>
          <Icon src={'/icon/close-white.svg'} width={24} height={24} />
        </CloseChatButton>
      ) : null}
      {loading && (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      )}
      <GlobalStyle showChat={showChat} />
    </>
  );
};

const GlobalStyle = createGlobalStyle<{ showChat: boolean }>`
  body {
    @media (max-width: 680px) {
      .chat-iframe-wrap {
        width: ${(props) => (props.showChat ? '100%' : '0')} !important;
        height: 100% !important;
        position: fixed;
        left: 0px !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        box-shadow: unset !important;
        iframe {
          width: ${(props) => (props.showChat ? '100%' : '0')} !important;
        }
      }
      .button-iframe-wrap {
        display: none !important;
      }
    }
  }
`;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;

const CloseChatButton = styled.div`
  position: absolute;
  z-index: 99999999999;
  left: calc(100vw - 30px) !important;
  top: calc(100vh - 683px) !important;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    left: unset;
    right: 20px !important;
    top: 5px !important;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;
