import { useApolloClient, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';

import { VOID_LOAN } from 'lib/graphql/mutations/Refund';
import { FETCH_LOANS } from 'lib/graphql/queries';
import { FetchLoanListSearch } from 'lib/graphql/searches';
import { TransactionsAnalyticsEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useDebounce } from 'lib/hooks/useDebounced';
import { useLoanSearch } from 'lib/hooks/useLoanSearch';
import { useMerchantLogin } from 'lib/hooks/useMerchantLogin';
import useStore from 'lib/hooks/useStore';
import { usePartialRefund } from 'lib/services';
import { TransactionTableItem, useTransactionsTable } from 'lib/tables';
import { Merchant } from 'lib/types';
import { DEFAULT_ALL_TIMESPAN } from 'lib/utils';
import { browserIsMobile } from 'lib/utils/BrowserIsMobile';
import { calculateDateRange } from 'lib/utils/DatePickerUtil';

const loanStatusData = [
  { label: 'Initiated', value: 'INITIATED' },
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Voided', value: 'VOIDED' },
  { label: 'Refunded', value: 'REFUNDED' },
];
const defaultDisbursmentTypes = ['AWAITING_FUNDING', 'FUNDED', 'REFUNDED'];

interface CheckItems {
  value: string;
  label: string;
}

export const useTransactions = () => {
  const client = useApolloClient();
  const alert = useAlert();
  const location: any = useLocation();
  const { trackEvent } = useAnalytics({ isScreen: true });
  const navigate = useNavigate();

  const filteredDisplayId = location?.state?.displayId || '';

  const { refundPartiallyLoan, refundCancelLoan, refundFullyLoan } = usePartialRefund({});

  const [voidLoan] = useMutation(VOID_LOAN);

  const { mapTransactionToTableData } = useLoanSearch();
  const { merchantLogout, merchantLogin } = useMerchantLogin();

  const { organization, selectedMerchantForTransactions, merchants: storedMerchants } = useStore();

  const [transactions, setTransactions] = useState<TransactionTableItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState<string>(filteredDisplayId);
  const [sortColumn, setSortColumn] = useState<string>('createdAt');
  const [sortDirection, setSortDirection] = useState<string>('DESC');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [disbursementTypes, setDisbursementTypes] = useState<string[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogType, setOpenDialogType] = useState('ineligible');

  const [expandableOpened, setExpandableOpened] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const [checkedItems, setCheckedItems] = useState<CheckItems[]>([]);

  const debouncedValue = useDebounce(searchInput, 500);
  const dataTableRef = useRef<any>();

  const [selectedDateTransactions, setSelectedDateTransactions] = useState({ ...DEFAULT_ALL_TIMESPAN });

  useEffect(() => {
    if (organization) {
      setLoading(true);
      getTransactions().then(() => {
        document.querySelector(`#column-${sortColumn}`)?.classList.add('sorted');
      });
    }
  }, [
    selectedMerchantForTransactions,
    sortColumn,
    sortDirection,
    debouncedValue,
    selectedFilters,
    selectedDateTransactions.from,
    selectedDateTransactions.to,
  ]);

  useEffect(() => {
    setDisbursementTypes(selectedFilters);
  }, [selectedFilters, total]);

  const merchantLocation = (row: any) => {
    if (row?.merchantId) {
      const findMerchantName = storedMerchants?.find(
        (merchant: Merchant) => merchant?.id === row?.merchantId.toString(),
      );
      return findMerchantName?.name || '';
    }
    return '';
  };

  const merchantColumn =
    storedMerchants && storedMerchants?.length > 1
      ? [
          {
            name: 'Location',
            selector: 'location',
            cell: merchantLocation,
          },
        ]
      : [];

  const { tableColumns } = useTransactionsTable(expandableOpened, selectedItem, merchantColumn);

  const openAlertDialog = (value: string) => {
    setOpenDialogType(value);
    setOpenDialog(true);
  };

  const closeAlertDialog = () => {
    setOpenDialog(false);
  };

  const getTransactions = async () => {
    setLoading(true);
    try {
      const merchantIds = storedMerchants?.map((merchant) => merchant.id);
      const merchants = selectedMerchantForTransactions?.id || merchantIds?.join(',') || '';

      const { data: transactionData } = await client.query({
        query: FETCH_LOANS,
        variables: {
          input: {
            limit: rowsPerPage,
            offset: 0,
            orderCriteriaItems: [
              {
                sort: sortDirection,
                fieldName: sortColumn || 'createdAt',
              },
            ],
            searchCriteriaItems: FetchLoanListSearch(
              selectedDateTransactions,
              merchants,
              selectedFilters,
              defaultDisbursmentTypes,
              searchInput,
            ),
          },
        },
      });

      const transactionsResponse =
        transactionData?.fetchLoans?.contents?.filter((item) => !['CANCELLED', 'EXPIRED'].includes(item?.status)) || [];
      setTotal(transactionData?.fetchLoans?.total);
      const mappedData = mapTransactionToTableData(transactionsResponse, openAlertDialog);

      setTransactions(mappedData);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const pageChange = (page, totalRows) => {
    if (page * rowsPerPage >= transactions?.length) paginateTable(page);
  };

  const rowsCountChange = (perPage, page) => {
    trackEvent({
      action: TransactionsAnalyticsEventNames.ROWS_CHANGE,
      value: perPage,
    });
    setRowsPerPage(perPage);
    if (perPage * page >= transactions?.length) paginateTable(page, perPage);
  };

  const paginateTable = (page: number, perPage?: number, mobileApp = false) => {
    setPaginationLoading(true);
    const merchantIds = storedMerchants?.map((merchant) => merchant.id);
    const merchants = `${selectedMerchantForTransactions?.id || merchantIds?.join(',') || ''}`;
    client
      .query({
        query: FETCH_LOANS,
        variables: {
          input: {
            limit: perPage || rowsPerPage,
            offset: page - 1,
            orderCriteriaItems: [
              {
                sort: sortDirection,
                fieldName: sortColumn || 'createdAt',
              },
            ],
            searchCriteriaItems: FetchLoanListSearch(
              selectedDateTransactions,
              merchants,
              selectedFilters,
              defaultDisbursmentTypes,
              searchInput,
            ),
          },
        },
      })
      .then(async ({ data }) => {
        const transactionsResponse =
          data?.fetchLoans?.contents?.filter((item) => !['CANCELLED', 'EXPIRED'].includes(item?.status)) || [];
        // TODO total problem
        setTotal(data?.fetchLoans?.total);
        const mappedData = mapTransactionToTableData(transactionsResponse, openAlertDialog);
        const newTransactionList = mobileApp ? [...transactions, ...mappedData] : mappedData;
        setTransactions(newTransactionList);
        setPaginationLoading(false);
        setLoading(false);
      })
      .catch((e) => {
        setPaginationLoading(false);
        setLoading(false);
      });
  };

  const searchInputOnChange = (nativeEvent: any) => {
    const currentTarget = nativeEvent.currentTarget;
    setSearchInput(currentTarget.value);
  };

  const onTableSort = (column, direction) => {
    trackEvent({
      action: TransactionsAnalyticsEventNames.SORT_CHANGE,
      label: column.selectorString,
    });
    setLoading(true);
    setSortColumn(column.selectorString);
    setSortDirection(direction?.toUpperCase());
  };

  const searchInputBlur = () => {
    trackEvent({
      action: TransactionsAnalyticsEventNames.SEARCH_BLUR,
    });
  };

  const onDateChangeTransactions = (date, value) => {
    const dateObject = {
      from: dayjs(date.selection.startDate).startOf('day').toDate().toISOString(),
      to: dayjs(date.selection.endDate).endOf('day').toDate().toISOString(),
    };
    trackEvent({
      action: TransactionsAnalyticsEventNames.DATE_SELECTED,
      label: value,
    });

    setSelectedDateTransactions(dateObject);
  };

  const onMobileDateDropdownChange = (event) => {
    const { value } = event.target;
    const date = calculateDateRange(value);
    trackEvent({
      action: TransactionsAnalyticsEventNames.DATE_SELECTED,
      label: value,
    });
    setSelectedDateTransactions(date);
  };

  const fullRefundLoan = async (data) => {
    const refundLoanData = await refundFullyLoan(data);

    if (refundLoanData?.refundLoan?.success) {
      alert.success('Refund issued.');
    } else {
      alert.error('Something went wrong');
    }
    const selectedRow: any = document.getElementById(`row-${data.item.id}`);
    const expandableButton = selectedRow?.firstChild?.children?.[0];
    expandableButton.click();
    getTransactions();
  };

  const partiallyRefundLoan = async (data) => {
    const partialRefundLoanData = await refundPartiallyLoan(data);

    if (partialRefundLoanData?.partialRefundLoan?.success) {
      alert.success('Refund issued.');
    } else {
      alert.error('Something went wrong');
    }
    const selectedRow: any = document.getElementById(`row-${data.item.id}`);
    const expandableButton = selectedRow?.firstChild?.children?.[0];
    expandableButton.click();
    getTransactions();
  };

  const refundApproveButtonHandler = async (data) => {
    if (data.refundType === 'full') {
      await fullRefundLoan(data);
    } else if (data.refundType === 'partial') {
      await partiallyRefundLoan(data);
    }
  };

  const refundCancelButtonHandler = async (data) => {
    const cancelRefundLoanData = await refundCancelLoan(data);

    if (cancelRefundLoanData?.cancelRefundLoan?.success) {
      alert.success('Refund has been cancelled');
    } else {
      alert.error('Something went wrong');
    }
    if (browserIsMobile()) {
      navigate(`/transactions`);
      return;
    }
    const selectedRow: any = document.getElementById(`row-${data.id}`);
    const expandableButton = selectedRow?.firstChild?.children?.[0];
    expandableButton.click();
    getTransactions();
  };

  const voidTransactionHandler = async (data) => {
    await merchantLogin(data?.merchantId);
    const { data: voidLoanData } = await voidLoan({
      variables: {
        input: {
          loanId: data.id,
        },
      },
    });
    merchantLogout();
    if (voidLoanData?.voidLoan?.success) {
      alert.success('Contract has been voided');
    } else {
      alert.error('Something went wrong');
    }
    const selectedRow: any = document.getElementById(`row-${data.id}`);
    const expandableButton = selectedRow?.firstChild?.children?.[0];
    expandableButton.click();
    getTransactions();
  };

  const onDisbursementTypeSelected = (data: any) => {
    const findItems = loanStatusData.filter((status) => data.includes(status.value));
    setSelectedFilters(data);
    setCheckedItems(findItems);
  };

  return {
    setSelectedItem,
    refundApproveButtonHandler,
    refundCancelButtonHandler,
    voidTransactionHandler,
    fullRefundLoan,
    setExpandableOpened,
    openDialogType,
    closeAlertDialog,
    total,
    sortColumn,
    sortDirection,
    searchInput,
    setSearchInput,
    disbursementTypes,
    selectedDateTransactions,
    searchInputOnChange,
    searchInputBlur,
    onDisbursementTypeSelected,
    onDateChangeTransactions,
    checkedItems,
    dataTableRef,
    tableColumns,
    transactions,
    pageChange,
    rowsCountChange,
    loading,
    paginateTable,
    paginationLoading,
    onTableSort,
    openDialog,
    openAlertDialog,
    rowsPerPage,
    onMobileDateDropdownChange,
    fetchTransactions: getTransactions,
  };
};
