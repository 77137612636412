import { FormControl, Grid, RadioGroup, TextField } from '@mui/material';
import client from 'lib/graphql/client';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { Radio } from '@frontend/cherry-library';
import { DropDown } from 'lib/components';
import { DatepickerIcon } from 'lib/components/mobile/SidebarIcons';
import { GET_MEMBERSHIP_PLANS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { formatDate, formatTextCapitalize } from 'lib/utils';
import { MemberShipInfo } from 'pages/desktop/Membership/type';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';

interface Props {
  onChangeHandler: (key, value) => void;
  memberInfo: MemberShipInfo;
}

enum PAYMENT_SCHEDULE {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  MONTHLY_FLEX = 'MONTHLY_FLEX',
}

const PAYMENT_SCHEDULE_TO_INPUT_LABEL = {
  [PAYMENT_SCHEDULE.MONTHLY]: 'Monthly Price',
  [PAYMENT_SCHEDULE.YEARLY]: 'Yearly Price',
  [PAYMENT_SCHEDULE.MONTHLY_FLEX]: 'All Subsequent Monthly Payments',
};

export const MembershipInfoForm = ({ onChangeHandler, memberInfo }: Props) => {
  const { membershipPlans, setMembershipPlans, organization } = useStore();

  const [planTypes, setPlanTypes] = useState<any[]>([]);
  // const [showDatepicker, setShowDatepicker] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const [selectedSchedule, setSelectedSchedule] = useState<string | undefined>(memberInfo?.paymentSchedulePeriod);

  useEffect(() => {
    if (membershipPlans.length < 1 && organization?.id) {
      getMembershipPlans();
    } else {
      const planTypesOptions = membershipPlans
        ?.filter((plan) => plan.status === 'LIVE')
        .map((plan) => ({
          label: plan.name,
          value: plan.id,
        }));
      setPlanTypes(planTypesOptions);
      initEditForm(membershipPlans);
    }
  }, []);

  const getMembershipPlans = async () => {
    const { data: { fetchOrganizationsMembershipPlans } = {} } = await client.query({
      query: GET_MEMBERSHIP_PLANS,
      variables: {
        input: {
          organizationId: organization?.id,
        },
      },
    });
    if (fetchOrganizationsMembershipPlans?.contents?.length > 0) {
      const planTypesOptions = fetchOrganizationsMembershipPlans.contents.map((plan) => ({
        label: plan.name,
        value: plan.id,
      }));
      setPlanTypes(planTypesOptions);
      setMembershipPlans(fetchOrganizationsMembershipPlans.contents);
      initEditForm(fetchOrganizationsMembershipPlans.contents);
    }
  };

  const initEditForm = (data) => {
    if (memberInfo?.planType) {
      const findPlan = data?.find((plan) => plan.id === memberInfo.planType);
      if (findPlan) {
        setSelectedPlan(findPlan);
      }
    }
  };

  const handleCurrencyChange = (amount: string | undefined, key: string) => {
    onChangeHandler(key, amount);
  };

  const onRadioButtonChange = (event) => {
    setSelectedSchedule(event.target.value);
    if (selectedPlan) {
      const findPrice = selectedPlan?.prices?.find((price) => price.period === event.target.value);
      onChangeHandler('paymentSchedule', findPrice?.id);
    }
  };

  const handlePlanTypeChange = (planType: { label: string; value: string }) => {
    const findPlan = membershipPlans.find((plan) => plan.id === planType.value);
    if (findPlan) {
      setSelectedPlan(findPlan);
      onChangeHandler('planType', planType.value);
    }
  };

  useEffect(() => {
    if (!selectedPlan) return;

    let tempSchedule = selectedSchedule;

    if (selectedPlan?.prices?.length === 1) {
      setSelectedSchedule(selectedPlan?.prices[0].period);
      tempSchedule = selectedPlan?.prices[0].period;
    }

    if (tempSchedule) {
      const findPrice = selectedPlan?.prices?.find((price) => price.period === tempSchedule);
      onChangeHandler('paymentSchedule', findPrice?.id);
    }
  }, [selectedPlan]);

  // const onDateChange = (value) => {
  //   if (value) {
  //     setMemberSince(dayjs(value).format('YYYY-MM-DD'));
  //   }
  //   onChangeHandler('memberSince', value);
  // };

  // const toggleDatePicker = () => {
  //   setShowDatepicker(!showDatepicker);
  // };

  const findPlanPrice = (key: string | undefined, field: string = 'price') => {
    const findPrice = selectedPlan?.prices?.find((price) => price.period === key);
    if (findPrice) {
      return findPrice?.[field]?.toFixed(2);
      // return (key === PAYMENT_SCHEDULE.YEARLY ? findPrice?.price / 12 : findPrice?.price)?.toFixed(2);
    }
    return;
  };

  return (
    <Container>
      <Label>Membership Details</Label>
      <Grid container={true} spacing={1}>
        {planTypes?.length > 0 && (
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Plan Type</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={planTypes}
                  placeholder="Plan Type"
                  placeholderColor={'#DADADA'}
                  onChange={handlePlanTypeChange}
                  hoveredMode={false}
                  defaultValue={memberInfo?.planType}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
        )}
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Payment Status</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Payment Status"
              id="payment-status"
              disabled={true}
              value={selectedPlan?.status}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined">
            <FormLabel>Payment Schedule</FormLabel>
            <CustomRadioGroup row={true} aria-label="single-filter" name="singleFilter" value={selectedSchedule}>
              {selectedPlan?.prices?.map((price) => (
                <Radio
                  label={formatTextCapitalize(price.period)}
                  value={price.period}
                  key={price.id}
                  onChange={onRadioButtonChange}
                  checked={selectedSchedule === price.period}
                />
              ))}
            </CustomRadioGroup>
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <DateContainer>
            {' '}
            {/* onClick={toggleDatePicker} */}
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Member Since</FormLabel>
              <StyledTextField
                size="small"
                type="text"
                placeholder="Member Since"
                disabled={true}
                aria-readonly="true"
                inputProps={{
                  'data-inputkey': 'memberSince',
                }}
                id="membership-member-since"
                data-testid="membership-member-since"
                variant="outlined"
                value={formatDate(memberInfo.memberSince || selectedPlan?.createdAt)}
              />
            </FormControl>
            <IconContainer>
              <DatepickerIcon />
            </IconContainer>
            {/* <Datepicker show={showDatepicker} onDateSelected={onDateChange} toggleRangePicker={toggleDatePicker} /> */}
          </DateContainer>
        </Grid>
        {selectedSchedule === PAYMENT_SCHEDULE.MONTHLY_FLEX && (
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>First Monthly Payment (One-Time)</FormLabel>
              <StyledCurrencyInput
                data-testid="membership-form-first-payment"
                id="membership-form-first-payment"
                placeholder="First Monthly Payment (One-Time)"
                name="firstPayment"
                className={`form-control`}
                value={findPlanPrice(selectedSchedule, 'firstPayment')}
                // @ts-ignore
                onValueChange={handleCurrencyChange}
                prefix="$"
                decimalScale={2}
                step={1}
                disabled={true}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined">
            <FormLabel>{selectedSchedule && PAYMENT_SCHEDULE_TO_INPUT_LABEL[selectedSchedule]}</FormLabel>
            <StyledCurrencyInput
              data-testid="membership-form-monthly-price"
              id="membership-form-monthly-price"
              placeholder={selectedSchedule && PAYMENT_SCHEDULE_TO_INPUT_LABEL[selectedSchedule]}
              name="monthlyPrice"
              className={`form-control`}
              value={findPlanPrice(selectedSchedule)}
              // @ts-ignore
              onChange={handleCurrencyChange}
              prefix="$"
              precision={2}
              step={1}
              disabled={true}
            />
          </FormControl>
        </Grid>
        {/* <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined">
            <FormLabel>Payments to Date</FormLabel>
            <StyledCurrencyInput
              data-testid="membership-form-payments-to-date"
              id="membership-form-payments-to-date"
              placeholder="Payments to Date"
              name="paymentsToDate"
              className={`form-control`}
              // value={memberInfo?.address1}
              // @ts-ignore
              onChange={handleCurrencyChange}
              prefix="$"
              precision={2}
              step={1}
              disabled={true}
            />
          </FormControl>
        </Grid> */}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  display: block;
  margin-bottom: 8px;
`;

const DateContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 38px;
  right: 12px;
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const StyledTextField = styled(TextField)`
  cursor: pointer;
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
    ::disabled {
      cursor: pointer;
    }
  }
  .MuiInputBase-input {
    cursor: pointer !important;
  }
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';
  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;

const CustomRadioGroup = styled(RadioGroup)`
  padding-bottom: ${(props) => props.theme.constants.m};
`;
