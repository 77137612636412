const API_HOST = process.env.REACT_APP_API_URL;
const GQL_URL = process.env.REACT_APP_GQL_URL;
const APP_ENV: string | undefined = process.env.REACT_APP_ENV;
const APP_PREQUAL_URL: string | undefined = process.env.REACT_APP_PREQUAL_URL;
const APP_SHOP_URL: string | undefined = process.env.REACT_APP_SHOP_URL;
const APP_USER_AGENT = 'MerchantDashboard';
const APP_STATIC_URL: any = process.env.REACT_APP_STATIC_URL;
const CLOUDBEES_APIKEY = process.env.REACT_APP_CLOUDBEES_APIKEY;
const LINK_TREE_URL: string | undefined = process.env.REACT_APP_LINK_TREE_URL;
const SMARTY_KEY: string | undefined = process.env.REACT_APP_SMARTY_CLIENT_KEY;
const SANDBOX_URL: string | unknown = process.env.REACT_APP_SANDBOX_URL;

export {
  API_HOST,
  APP_USER_AGENT,
  APP_ENV,
  APP_PREQUAL_URL,
  GQL_URL,
  APP_STATIC_URL,
  CLOUDBEES_APIKEY,
  LINK_TREE_URL,
  APP_SHOP_URL,
  SMARTY_KEY,
  SANDBOX_URL,
};
