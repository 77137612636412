import { Button as CherryButton } from '@frontend/cherry-library';
import images from 'assets/images';
import { APP_PREQUAL_URL } from 'config';
import QRCode from 'easyqrcodejs';
import { ExpandIcon } from 'lib/components';
import { MARKETING_MATERIAL_IDS } from 'lib/constants';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import Server from 'lib/services/Server';
import { Row } from 'lib/styles';
import { downloadBase64, downloadBlobFile } from 'lib/utils';
import { MarketingMaterialModal } from 'pages/desktop/OtherResourcesTemplates/views';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

interface DigitalItemProps {
  id?: number;
  qrCode?: boolean;
  image?: string;
  title: string;
  description: string;
  pdf?: string;
}

const QR_CODE_CONFIG = {
  logo: images?.cherryIconDark,
  version: 5,
  width: 200,
  height: 200,
  autoColor: true,
  PO: '#00c37d',
  PI: '#0e1f2e',
  PO_TL: '#00c37d',
  PI_TL: '#00c37d',
  PO_TR: '#0e1f2e',
  PI_TR: '#00c37d',
  PO_BL: '#00c37d',
  PI_BL: '#0e1f2e',
};

const DigitalItem = ({ id, image, title, description, pdf, qrCode }: DigitalItemProps) => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const [qrImage, setQrImage] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [isMarketingModalVisible, setIsMarketingModalVisible] = useState<boolean>(false);
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const shouldDisplayImage = useMemo(() => (pdf || pdf === '') && image, [pdf, image]);
  const isQrCard = useMemo(() => id === MARKETING_MATERIAL_IDS.QR_CARD, [id]);

  useEffect(() => {
    if (pdfUrl !== '' && pdfUrl !== undefined) {
      setIsMarketingModalVisible(true);
      setPdfUrl(pdfUrl);
    }
  }, [pdfUrl]);

  const onQrRendered = useCallback((_, qrImg: string) => {
    setQrImage(qrImg);
  }, []);

  const initializeQrCode = useCallback(() => {
    const qr = new QRCode(qrCodeRef.current, {
      text: `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=merchant&utm_medium=qr_code_digital&utm_campaign=v1`,
      onRenderingEnd: onQrRendered,
      ...QR_CODE_CONFIG,
    });
    console.log(qr); // NOTE: logging 'qr' to bypass 'no-unsed-expression' rule
  }, [onQrRendered, organization]);

  const handleQrCodeDownload = useCallback(() => {
    downloadBase64(organization?.slug, qrImage);
    trackEvent({
      action: MarketingAnalyticsEventNames.CTA_BUTTONS,
      label: 'QR_CODE',
    });
  }, [qrImage]);

  useEffect(() => {
    if (qrCode) {
      initializeQrCode();
    }
  }, [qrCode, initializeQrCode]);

  const handleQrCardDownload = useCallback(async () => {
    const organizationId = organization?.id;

    if (organizationId) {
      const response = await Server.SendMarketingMaterial.downloadQrCard(organizationId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'qr_card.pdf');
      document.body.appendChild(link);
      link.click();
    }
  }, [organization?.id]);

  const onDownloadButtonClick = useCallback(() => {
    let marketingMaterialsType = '';

    if (qrCode) {
      marketingMaterialsType = 'Download QR code';
      handleQrCodeDownload();
    } else if (pdf) {
      marketingMaterialsType = title;
      onMaterialDownloadClick();
    } else if (isQrCard) {
      marketingMaterialsType = 'Qr card';
      handleQrCardDownload();
    }

    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Marketing Materials Downloaded', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      marketingMaterialsType,
    });
  }, [id, qrCode, pdf, handleQrCodeDownload, handleQrCardDownload]);

  const onExpandClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.dataset.url) {
      setPdfUrl(e.target.dataset.url);
    } else {
      setPdfUrl(e.target.parentNode.dataset.url);
    }

    trackEvent({
      action: MarketingAnalyticsEventNames.SINGLE_MATERIAL_IMAGE,
      label: e.target.parentNode.dataset.url,
    });
  };

  const closeModal = () => {
    setIsMarketingModalVisible(false);
    setPdfUrl('');
  };

  const onMaterialDownloadClick = () => {
    downloadBlobFile(title, pdf);
    trackEvent({
      action: MarketingAnalyticsEventNames.SINGLE_MATERIAL_DOWNLOAD,
      label: pdf,
    });
  };

  return (
    <>
      <ItemContainer>
        {qrCode ? <QrCode ref={qrCodeRef} /> : null}
        {shouldDisplayImage ? (
          <ImageContainer>
            {!isQrCard ? (
              <AbsoluteHover id={'cherry--overlay'} onClick={onExpandClicked} data-url={pdf}>
                <CustomExpandIcon data-url={pdf} onClick={onExpandClicked} />
              </AbsoluteHover>
            ) : null}
            {image && <MaterialImage url={image} />}
          </ImageContainer>
        ) : null}
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>

        <DownloadButtonContainer>
          <CherryButton
            size="small"
            onClick={onDownloadButtonClick}
            data-testid="download-digital-item"
            data-url={pdfUrl}
            data-item={description}
            data-name={title}
          >
            Download
          </CherryButton>
        </DownloadButtonContainer>
      </ItemContainer>
      <MarketingMaterialModal fullModal={true} closeModal={closeModal} pdfUrl={pdfUrl} show={isMarketingModalVisible} />
    </>
  );
};

const QrCode = styled.div`
  width: 80px;
  height: 80px;

  canvas {
    width: inherit;
    height: inherit;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover #cherry--overlay {
    background-color: ${(props) => props.theme.main.green};
    opacity: 0.4;
    display: block;
    transition: 0.5s;
    svg {
      transition: 0.9;
      z-index: -1;
    }
  }
`;

const AbsoluteHover = styled.div`
  display: none;
  position: absolute;
  margin: 0 20px;
  width: 80px;
  height: 80px;
  transition: 0.5s;
  cursor: pointer;

  svg {
    position: absolute;
    z-index: -1;
    left: 40%;
    top: 38%;
    background-color: ${(props) => props.theme.main.aquadazzle};
    padding: 8px;
    border-radius: 16px;
    transition: 0.9;
  }
`;

const CustomExpandIcon = styled(ExpandIcon)<{ onClick: (event) => void }>`
  display: inline-block;
  position: absolute;
  z-index: 4;
  position: fixed;
`;

const MaterialImage = styled.div<{ url: string }>`
  width: 80px;
  height: 80px;
  border: 0.5px solid ${(props) => props.theme.main.neutralGray};
  background: url(' ${(props) => props.url} ');
  background-size: cover;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled(Row)`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const Description = styled(Row)`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const DownloadButtonContainer = styled.div`
  width: 100px;
  height: 32px;
`;

export default DigitalItem;
