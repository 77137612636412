import React from 'react';
import styled from 'styled-components';

import { Button, ButtonContainer, Container, ContentContainer, Title } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import { useNavigate } from 'react-router-dom';

const MobileTransactionPartialRefundSuccess = () => {
  const { transactionInProcess } = useStore();
  const navigate = useNavigate();

  const onOkayClicked = () => {
    navigate('/transactions');
  };

  return (
    <Container pageTitle="Void Transaction">
      <DetailContainer>
        <Title>Success!</Title>
        <Description>This transaction was voided.</Description>
        <Description>
          Funds have been returned to {transactionInProcess?.borrowerName}, and no fees have been charged to your
          account.
        </Description>
        <Description>Transaction details have been updated to reflect this void.</Description>
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={onOkayClicked}>Okay</Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionPartialRefundSuccess;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
`;
