import { FormControl, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { Radio } from '@frontend/cherry-library';
import ModalV2 from 'lib/components/ModalV2/ModalV2';
import styled from 'styled-components';

enum METHODS {
  IN_OFFICE = 'IN_OFFICE',
  SHIPPED = 'SHIPPED',
}

type FulfillmentMethod = METHODS.IN_OFFICE | METHODS.SHIPPED;

interface Fulfillment {
  method: FulfillmentMethod;
  displayName: string;
  description: string;
  shippingPrice?: number;
}

const initialFulfillmentValues = {
  method: METHODS.IN_OFFICE,
  displayName: '',
  description: '',
};

interface Props {
  open: boolean;
  isEditMode: boolean;
  onClose: () => void;
  selectedRowIndex: number;
  productData: any;
  onProductDataChange: (key: string, value: any) => void;
}

const FulfillmentFormModal = ({
  open,
  isEditMode,
  onClose,
  selectedRowIndex,
  productData,
  onProductDataChange,
}: Props) => {
  const [fulfillmentFormData, setFulfillmentFormData] = useState<Fulfillment>(initialFulfillmentValues);

  useEffect(() => {
    if (open && isEditMode && selectedRowIndex >= 0) {
      setFulfillmentFormData(productData?.fulfillmentOptions?.[selectedRowIndex]);
    }
  }, [open]);

  const resetFulfillmentForm = () => {
    setFulfillmentFormData(initialFulfillmentValues);
  };

  const handleSubmit = () => {
    if (isEditMode) {
      onProductDataChange(
        'fulfillmentOptions',
        (productData?.fulfillmentOptions || []).map((item, idx) => {
          if (idx === selectedRowIndex) {
            return fulfillmentFormData;
          }
          return item;
        }),
      );
    } else {
      onProductDataChange('fulfillmentOptions', [...(productData?.fulfillmentOptions || []), fulfillmentFormData]);
    }

    handleClose();
  };

  const handleClose = () => {
    resetFulfillmentForm();
    onClose();
  };

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    setFulfillmentFormData((prev: Fulfillment) => ({ ...prev, [key]: value }));
  };

  const onCurrencyChangeHandler = (value: string | undefined, name: string | undefined) => {
    setFulfillmentFormData((prev: Fulfillment) => ({ ...prev, [name!]: value }));
  };

  const onMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const method = e.target.value as FulfillmentMethod;

    if (method === METHODS.IN_OFFICE) {
      delete fulfillmentFormData.shippingPrice;
    }

    setFulfillmentFormData((prev: Fulfillment) => ({ ...prev, method }));
  };
  return (
    <ModalV2
      open={open}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      title="Add Fulfillment Option"
      submitButtonLabel={isEditMode ? 'Edit Fulfillment Option' : 'Add Fulfillment Option'}
      width={600}
    >
      <FormControl fullWidth={true} variant="outlined">
        <FormLabel>Method</FormLabel>
        <CustomRadioGroup aria-label="single-filter" name="singleFilter" value={fulfillmentFormData?.method}>
          <Radio
            label={'In-Office'}
            value={METHODS.IN_OFFICE}
            data-testid="shop-product-add-fulfillment-method-radio-in-office"
            onChange={onMethodChange}
            checked={fulfillmentFormData?.method === METHODS.IN_OFFICE}
          />
          <Radio
            label={'Shipped'}
            value={METHODS.SHIPPED}
            data-testid="shop-product-add-fulfillment-method-radio-shipped"
            onChange={onMethodChange}
            checked={fulfillmentFormData?.method === METHODS.SHIPPED}
          />
        </CustomRadioGroup>
      </FormControl>
      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Display Name</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Display Name"
          inputProps={{
            'data-inputkey': 'displayName',
          }}
          id="shop-product-add-fulfillment-display-name"
          data-testid="shop-product-add-fulfillment-display-name"
          onChange={handleChange}
          value={fulfillmentFormData?.displayName}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Description</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Description"
          inputProps={{
            'data-inputkey': 'description',
          }}
          id="shop-product-add-fulfillment-description"
          data-testid="shop-product-add-fulfillment-description"
          onChange={handleChange}
          value={fulfillmentFormData?.description}
          variant="outlined"
        />
      </FormControl>
      {fulfillmentFormData.method === METHODS.SHIPPED && (
        <FormControl fullWidth={true} variant="outlined" margin="dense">
          <FormLabel>Shipping Price</FormLabel>
          <StyledCurrencyInput
            data-testid="shop-product-add-fulfillment-shipping-price"
            id="shop-product-add-fulfillment-shipping-price"
            placeholder="Shipping Price"
            name="shippingPrice"
            className={`form-control`}
            value={fulfillmentFormData?.shippingPrice}
            onValueChange={onCurrencyChangeHandler}
            prefix="$"
            decimalScale={2}
            step={1}
          />
        </FormControl>
      )}
    </ModalV2>
  );
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const CustomRadioGroup = styled(RadioGroup)`
  padding-bottom: ${(props) => props.theme.constants.m};
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';

  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;

export default FulfillmentFormModal;
