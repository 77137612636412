import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  Title,
  TransactionExpandableInfo,
} from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';

interface ContainerProps {
  isOpen?: boolean;
}

const MobileTransactionFullRefund = () => {
  const { transactionInProcess } = useStore();
  const navigate = useNavigate();

  const onContinueClick = () => {
    navigate(`/transactions/detail/full-refund/review`);
  };

  const onPartialRefundClick = () => {
    navigate(`/transactions/detail/partial-refund`);
  };

  const onCancelClick = () => {
    navigate('/transactions');
  };

  return (
    <Container pageTitle="Full Refund">
      <DetailContainer>
        <Title>Issue a Full Refund</Title>
        <TransactionDescription>
          A full refund cancels the borrower’s contract. Cherry will reimburse your processing fees, and the borrower
          receives a credit for any remaining balance owed.
        </TransactionDescription>
        <TransactionExpandableInfo transactionInProcess={transactionInProcess} expanded={true} />
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={onContinueClick} disabled={false}>
              Continue
            </Button>
            <Button secondary={true} onClick={onCancelClick}>
              Cancel
            </Button>
          </ButtonContainer>
          <BottomTextContainer onClick={onPartialRefundClick}>Need to issue a Partial Refund?</BottomTextContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionFullRefund;

const TransactionDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const FooterContainer = styled.div<ContainerProps>`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 250px;
`;

const BottomTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 40px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.midnightBlue};
  background-color: ${(props) => props.theme.main.white};
`;
