import { Button, Heading } from '@frontend/cherry-library';
import { ReactComponent as BackIcon } from 'assets/images/short_left.svg';
import { Prompt } from 'lib/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Application from './Components/Application/Application';
import Approval from './Components/Approval';
import Denial from './Components/Denial';
import IncorrectInfo from './Components/IncorrectInfo';
import Loading from './Components/Loading';
import NeedMoreInfo from './Components/NeedMoreInfo';
import { BackButtonWrapper, PageWrapper } from './styles';

type ApplicationStep = 'application' | 'loading' | 'approval' | 'denial' | 'moreInfo' | 'incorrect';

const ApplyWithPatientPage = () => {
  const navigate = useNavigate();

  const [applicationStep, setApplicationStep] = useState<ApplicationStep>('application');
  const [isExistingBorrower, setIsExistingBorrower] = useState<boolean>(false);

  const getPageTitle = (step: ApplicationStep) => {
    if (step === 'application' || 'loading') {
      return 'Apply with Patient';
    } else if (step === 'incorrect') {
      return 'Application Not Submitted';
    } else {
      return 'Application Complete';
    }
  };

  const getApplicationStep = (step: ApplicationStep) => {
    switch (step) {
      case 'application':
        return <Application setStep={setApplicationStep} setAlreadyExistingBorrower={setIsExistingBorrower} />;
      case 'loading':
        return <Loading setStep={setApplicationStep} />;
      case 'approval':
        return <Approval />;
      case 'denial':
        return <Denial />;
      case 'incorrect':
        return <IncorrectInfo setStep={setApplicationStep} />;
      case 'moreInfo':
        return <NeedMoreInfo />;
      default:
        return <Approval />;
    }
  };
  const goBackToDashboard = () => {
    navigate('/dashboard');
  };

  const handleConfirmNavigateAway = async () => {
    navigate('/dashboard');
  };

  return (
    <PageWrapper>
      <BackButtonWrapper>
        {applicationStep === 'application' && (
          <Button
            leftIcon={BackIcon}
            onClick={goBackToDashboard}
            id="apply-with-patient-back-button"
            variant="secondary"
          >
            Back to Dashboard
          </Button>
        )}
      </BackButtonWrapper>
      <Heading text={getPageTitle(applicationStep)} level="2" />
      {getApplicationStep(applicationStep)}
      <Prompt
        cancelText={applicationStep === 'loading' ? 'Back' : 'Back to Form'}
        shouldBlockNavigation={
          (applicationStep === 'application' || applicationStep === 'loading') && !isExistingBorrower
        }
        onConfirmNavigation={handleConfirmNavigateAway}
        content={
          applicationStep === 'loading'
            ? 'If you close this page, the patient’s application will not be submitted. Are you sure?'
            : 'If you close this page, the information you’ve entered will be lost. Are you sure?'
        }
      />
    </PageWrapper>
  );
};
export default ApplyWithPatientPage;
