import dayjs from 'dayjs';
import { FixedDecimalFormat } from 'lib/components';
import { FormatAmount } from 'lib/utils';
import React from 'react';
import { ProductCalculation } from '../PlanCard/types';
import { InfoText, Title } from './styles';

interface Props {
  plan: ProductCalculation | undefined;
}

const PlanInformation = ({ plan }: Props) => {
  return (
    <>
      <Title>
        <FixedDecimalFormat amount={plan?.installmentAmount} prefix={'$'} /> per Month for {plan?.product.term} Months
      </Title>
      <InfoText>
        {plan?.product?.term} payments starting {dayjs(plan?.paymentDates?.[0]).format('MM/DD/YYYY')}
      </InfoText>
      <InfoText>
        {plan && FormatAmount(Number(Number(plan.financeCharge / plan.product?.term).toFixed(2)))} average monthly
        interest charge
      </InfoText>
      <InfoText>
        {plan?.promo
          ? `${plan?.product?.promoApr}% APR (${
              plan?.product?.fallbackApr || plan?.product?.apr
            }% if payments are made late)`
          : `${plan?.product?.apr}% APR`}
      </InfoText>
      <InfoText>
        <FixedDecimalFormat amount={plan?.financeCharge} prefix="$" /> total finance charge
      </InfoText>
    </>
  );
};

export default PlanInformation;
