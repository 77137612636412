import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { ButtonPrimary, TrashIcon } from 'lib/components';
import { UPLOAD_SHOP_FILE } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { getBase64, settingsTableStyle } from 'lib/utils';
import React, { useRef } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

interface Props {
  productData: any;
  onChange: (key: string, value: any) => void;
}

const MoreMedia = ({ productData, onChange }: Props) => {
  const alert = useAlert();
  const { organization } = useStore();
  const [uploadFile, { loading }] = useMutation(UPLOAD_SHOP_FILE);

  const imageInput = useRef<any>(null);

  const handleImageSelection = async (e) => {
    if (!e.target.files?.[0]) {
      alert.info('Attachment failed, please try again later.');
    }

    const file = e.target.files?.[0];

    if (file?.size > 2 * 1024 * 1024) {
      alert.info('File too large, must be less than 2MB.');
      imageInput.current.value = '';
    }

    try {
      const result = await getBase64(file);
      const previewUrl = URL.createObjectURL(file);
      const { data: { uploadShopFile } = {} } = await uploadFile({
        variables: {
          input: {
            organizationId: organization?.id,
            file: {
              name: file.name,
              mime: file?.type,
              data: result as string,
            },
          },
        },
      });

      if (!uploadShopFile.success) {
        throw new Error();
      }

      onChange('files', [
        ...(productData?.files ?? []),
        {
          key: uploadShopFile?.data?.fileKey,
          link: previewUrl,
          name: file.name,
          mime: file?.type,
          data: result as string,
        },
      ]);
    } catch (error) {
      alert.info('Attachment failed, please try again later.');
    }
  };

  const handleUploadImageClick = () => {
    imageInput?.current?.click();
  };

  const handleRemoveMedia = (index) => () => {
    onChange(
      'files',
      productData?.files?.filter((_e, idx) => idx !== index),
    );
  };

  const tableColumns = [
    {
      name: 'Preview',
      selector: (row) => row.link,
      cell: (row) => {
        return <img width={60} height={40} src={row.link} alt="product" />;
      },
      width: '150px',
    },
    {
      name: 'File Name',
      selector: (row) => row.name,
      minWidth: '350px',
      cell: (row) => row.name || row.key.match(/[^\/]*$/)?.[0],
    },
    {
      name: 'Type',
      selector: (row) => row.mime,
      cell: (row) => row.mime || row.key.match(/[^.]*$/),
    },
    {
      name: '',
      selector: (row) => row.name,
      cell: (row, index) => (
        <RemoveButton onClick={handleRemoveMedia(index)}>
          <TrashIcon color="#EC3360" width={24} height={24} />
        </RemoveButton>
      ),
      minWidth: '200px',
      right: true,
    },
  ];
  return (
    <>
      <Grid container={true} justifyContent="space-between" alignItems="center">
        <Grid item={true}>
          <SectionTitle>More Images and Videos (Optional)</SectionTitle>
        </Grid>
        <Grid item={true}>
          <ButtonSecondary
            text={
              <>
                <input
                  type="file"
                  accept="image/*, video/*"
                  onChange={handleImageSelection}
                  hidden={true}
                  ref={imageInput}
                />
                Add Media
              </>
            }
            onClick={handleUploadImageClick}
            loading={loading}
          />
        </Grid>
      </Grid>

      <CustomDataTable
        noHeader={true}
        data={productData?.files}
        customStyles={settingsTableStyle}
        columns={tableColumns}
      />
    </>
  );
};

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 16px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }

  .rdt_TableRow {
    align-items: center;
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }
`;

const ButtonSecondary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 195, 125, 1) !important;
  color: rgba(0, 195, 125, 1) !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const RemoveButton = styled.div`
  z-index: 10000000000;
  cursor: pointer;
  font-size: 14px;
  color: rgba(236, 51, 96, 1);
`;

const SectionTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

export default MoreMedia;
