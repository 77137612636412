import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ButtonPrimary } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Column } from 'lib/styles';
import { Onboarding } from './Onboarding';
import { Practice } from './Practice';
import { Sales } from './Sales';
import useMerchantsContactAccountList from 'lib/hooks/useMerchantsContactAccountList';

export const CherryTeam = () => {
  const { organization } = useStore();
  const { activatedAt, isDemo } = organization || {};

  const currentDate = dayjs();
  const organizationActivatedDate: Date = new Date(activatedAt!);
  const daysSinceActivation = currentDate.diff(dayjs(organizationActivatedDate), 'day');

  const { accountList, getAccountOfType } = useMerchantsContactAccountList();

  const getSalesComponent = () => {
    const account = getAccountOfType('ACCOUNT');
    return account ? <Sales accountList={accountList} /> : null;
  };

  const getOnboardingComponent = () => {
    const account = getAccountOfType('ONBOARDING');
    return account ? <Onboarding accountList={accountList} /> : null;
  };

  const getPracticeComponent = () => {
    const account = getAccountOfType('PRACTICE');
    return account ? <Practice accountList={accountList} /> : null;
  };

  // SALES & ONBOARDING
  if (!!isDemo || (!!activatedAt && daysSinceActivation <= 30)) {
    return (
      <Container>
        {getSalesComponent()}
        {getOnboardingComponent()}
        {getPracticeComponent()}
      </Container>
    );
  }

  // SALES & PRACTICE DEVELOPMENT
  if (!isDemo && !!activatedAt && daysSinceActivation > 30 && daysSinceActivation <= 60) {
    return (
      <Container>
        {getSalesComponent()}
        {getPracticeComponent()}
      </Container>
    );
  }

  if (!isDemo && !!activatedAt && daysSinceActivation > 60) {
    return <Container>{getPracticeComponent()}</Container>;
  }

  return null;
};

const Container = styled.div``;

export const SupportTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.midBlue};
`;

export const BorderLine = styled.div`
  border-top: 1px solid #dadada;
  margin: 18px 0 24px;
`;

export const ProfileIcon = styled.img``;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

export const ContentDescription = styled.span<{ bold?: boolean; fontSize?: string }>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: 16px;
  color: ${(props) => props.theme.main.textColor};
  margin-bottom: 3px;
`;

export const ContentLink = styled.a`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.main.textColor};
  margin-bottom: 3px;
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 200px;
`;

export const StyledColumn = styled(Column)`
  align-items: flex-end;
  flex: inherit;
`;
