import { Button, theme as cherryTheme } from '@frontend/cherry-library';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

import { ButtonPrimary, SecondaryButton } from 'lib/components';
import { Container } from 'lib/styles';

export const GreenBox = styled.div`
  background-color: ${({ theme }) => theme.main.green10};
  color: ${({ theme }) => theme.main.green};
  padding: 4.85px 16px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.2px;
  border-radius: 20px;
  width: fit-content;
`;

export const StepHeader = styled.div`
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StyledContainer = styled(Container)`
  padding-bottom: 48px;
`;
export const StyledAccordion = styled(Accordion)`
  padding: 24px;
  margin-bottom: 24px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04), 0px 6px 9px 0px rgba(0, 0, 0, 0.07) !important;

  .MuiAccordionSummary-content.Mui-expanded {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.main.neutralGray};
  }

  &:last-child {
    margin-bottom: 48px;
  }
`;

export const RedOutlineButton = styled(Button).attrs({ variant: 'secondary', fullWidth: true })`
  color: ${cherryTheme.main.cherryRed} !important;
  border: 1px solid ${cherryTheme.main.cherryRed} !important;
`;

export const BlackOutlineButton = styled(SecondaryButton)`
  display: flex;
  padding: 15px 31px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.main.midnightBlue} !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  height: auto;
  width: auto;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.main.midnightBlue} !important;
`;

export const GreenFillButton = styled(ButtonPrimary)`
  padding: 15px 16px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
