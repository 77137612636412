import { Switch } from '@frontend/cherry-library';
import { FormControl, Grid, TextField } from '@mui/material';
import { DropDown, FixedDecimalFormat } from 'lib/components';
import { Row } from 'lib/styles';
import { isEqual } from 'lodash';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

interface Props {
  isCreate: boolean;
  data: any;
  handleChange: any;
}

const PAYMENT_SCHEDULE_TO_LIST = {
  MONTHLY: ['MONTHLY'],
  YEARLY: ['YEARLY'],
  CUSTOM_WEEKLY: ['CUSTOM_WEEKLY'],
  MONTHLY_FLEX: ['MONTHLY_FLEX'],
  MONTHLY_OR_YEARLY: ['MONTHLY', 'YEARLY'],
  MONTHLY_FLEX_OR_YEARLY: ['MONTHLY_FLEX', 'YEARLY'],
};

const paymentSchedules = [
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'MONTHLY_FLEX',
    label: 'Monthly Flex',
  },
  {
    value: 'YEARLY',
    label: 'Yearly',
  },
  {
    value: 'CUSTOM_WEEKLY',
    label: 'Custom Weekly',
  },
  {
    value: 'MONTHLY_OR_YEARLY',
    label: 'Monthly or Yearly',
  },
  {
    value: 'MONTHLY_FLEX_OR_YEARLY',
    label: 'Monthly Flex or Yearly',
  },
];

const yearlyAutoRenewOptions = [
  {
    value: 'YES',
    label: 'Yes (Every 12 Months)',
  },
  {
    value: 'NO',
    label: 'No (End After 12 Months)',
  },
];

const monthlyAutoRenewOptions = [
  ...Array.from({ length: 12 }).map((_, index) => ({
    value: `${index + 1}`,
    label: `Every ${index + 1} Month${index > 0 ? 's' : ''}`,
  })),
];

export const PlanPricing = ({ isCreate, data, handleChange }: Props) => {
  const getPaymentSchedule = () => {
    if (!data) return '';

    const prices = data.prices?.map((priceItem) => priceItem.period);

    for (const [paymentScheduleValue, list] of Object.entries(PAYMENT_SCHEDULE_TO_LIST)) {
      if (isEqual(list.sort(), prices?.sort())) {
        return paymentScheduleValue;
      }
    }

    return '';
  };

  const [paymentSchedule, setPaymentSchedule] = useState(getPaymentSchedule);

  useEffect(() => {
    setPaymentSchedule(getPaymentSchedule());
  }, [data]);

  const handlePriceItemChange = (fieldKey: string, value: any, name: string, isNumber: boolean) => {
    try {
      const prices = [...(data?.prices || [])];
      const findItem = prices?.find((price) => price?.period === name);
      const findIndex = prices?.findIndex((price) => price?.period === name);

      const copyFindItem = { ...findItem };

      if (!value && typeof value !== 'boolean') {
        delete copyFindItem.id;
        copyFindItem[fieldKey] = isNumber ? 0 : undefined;
        prices[findIndex] = copyFindItem;
      } else {
        if (copyFindItem?.period) {
          delete copyFindItem.id;
          copyFindItem[fieldKey] = isNumber ? parseFloat(value) : value;
          prices[findIndex] = copyFindItem;
        } else {
          prices.push({
            period: name,
            [fieldKey]: isNumber ? parseFloat(value) : value,
            planId: data.id,
          });
        }
      }

      handleChange('prices', prices);
    } catch (err) {
      console.warn('err', err);
    }
  };

  const handleCurrencyChange = (value: string | undefined, name?: string | undefined) => {
    handlePriceItemChange('price', value, name!, true);
  };

  const handleFirstPaymentCurrencyChange = (value: string | undefined, name?: string | undefined) => {
    handlePriceItemChange('firstPayment', value, name!, true);
  };

  const findPriceItem = (type: string) => {
    if (data) {
      return data?.prices?.find((price) => price?.period === type);
    }
    return '';
  };

  const handlePaymentScheduleChange = ({ value }) => {
    setPaymentSchedule(value);
    const prices: any[] = [];

    PAYMENT_SCHEDULE_TO_LIST[value].forEach((paymentScheduleValue: string) => {
      const existingItem = data?.prices?.find((price) => price.period === paymentScheduleValue);
      if (existingItem) {
        prices.push(existingItem);
      } else {
        prices.push({
          period: paymentScheduleValue,
          price: 0,
          planId: data.id,
          ...(paymentScheduleValue === 'CUSTOM_WEEKLY' ? { billingTerm: 1, paymentFrequency: 1, autoRenew: true } : {}),
          ...(paymentScheduleValue === 'MONTHLY_FLEX' ? { firstPayment: 0, billingTerm: 6, autoRenew: true } : {}),
          ...(paymentScheduleValue === 'MONTHLY' ? { billingTerm: 6, autoRenew: true } : {}),
          ...(paymentScheduleValue === 'YEARLY' ? { autoRenew: true } : {}),
        });
      }
    });

    handleChange('prices', prices);
  };

  const handleYearlyAutoRenewChange = ({ value }) => {
    handlePriceItemChange('autoRenew', value === 'YES', 'YEARLY', false);
  };

  const handleAutoRenewToggle = (paymentScheduleParam: string) => (e) => {
    handlePriceItemChange('autoRenew', e.target.checked, paymentScheduleParam, false);
  };

  const handleBillingTermChange = (paymentScheduleParam: string) => ({ value }) => {
    handlePriceItemChange('billingTerm', value, paymentScheduleParam, true);
  };

  const handleWeeklyPaymentFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePriceItemChange('paymentFrequency', e.target.value, 'CUSTOM_WEEKLY', true);
  };

  const handleWeeklyBillingTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePriceItemChange('billingTerm', e.target.value, 'CUSTOM_WEEKLY', true);
  };

  if (!isCreate && !data?.prices) return null;

  return (
    <>
      <SubTitle>Pricing</SubTitle>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>Available Payment Schedules</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={paymentSchedules}
                  placeholder="Plan Type"
                  placeholderColor={'#DADADA'}
                  defaultValue={getPaymentSchedule()}
                  onChange={handlePaymentScheduleChange}
                  hoveredMode={false}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
        </Grid>

        {PAYMENT_SCHEDULE_TO_LIST[paymentSchedule]?.includes('YEARLY') && (
          <>
            <PricingTitle>Yearly Settings</PricingTitle>
            <Grid container={true} item={true} xs={12} spacing={1}>
              <Grid item={true} xs={6}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Yearly Payment</FormLabel>
                  <StyledCurrencyInput
                    data-testid="membership-form-monthly-price"
                    id="membership-form-monthly-price"
                    placeholder="Yearly Price"
                    name="YEARLY"
                    className={`form-control`}
                    value={findPriceItem('YEARLY')?.price}
                    onValueChange={handleCurrencyChange}
                    prefix="$"
                    decimalScale={2}
                    step={1}
                    allowNegativeValue={false}
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={6}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Yearly Auto Renew?</FormLabel>
                  <DropDownBorder style={{ width: '100%' }}>
                    <DropDown
                      maxHeight={'235'}
                      ignoreInitialFirstItem={true}
                      options={yearlyAutoRenewOptions}
                      placeholder="Yearly Auto Renew?"
                      placeholderColor={'#DADADA'}
                      defaultValue={findPriceItem('YEARLY')?.autoRenew ? 'YES' : 'NO' || 'YES'}
                      onChange={handleYearlyAutoRenewChange}
                      hoveredMode={false}
                    />
                  </DropDownBorder>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {PAYMENT_SCHEDULE_TO_LIST[paymentSchedule]?.includes('CUSTOM_WEEKLY') && (
          <>
            <PricingTitle>Custom Weekly Settings</PricingTitle>
            <Grid container={true} item={true} xs={12} spacing={1}>
              <Grid item={true} xs={6}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Payment Amount</FormLabel>
                  <StyledCurrencyInput
                    placeholder="Payment Amount"
                    name="CUSTOM_WEEKLY"
                    className={`form-control`}
                    value={findPriceItem('CUSTOM_WEEKLY')?.price}
                    onValueChange={handleCurrencyChange}
                    prefix="$"
                    decimalScale={2}
                    step={1}
                    allowNegativeValue={false}
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Payment Frequency</FormLabel>
                  <Row alignItems="center" style={{ gap: '8px' }}>
                    <span>Every</span>
                    <StyledTextField
                      type="number"
                      value={findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency}
                      onChange={handleWeeklyPaymentFrequencyChange}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                    />
                    <span>Weeks</span>
                  </Row>
                </FormControl>
              </Grid>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Billing Term</FormLabel>
                  <Row alignItems="center" style={{ gap: '8px' }}>
                    <StyledTextField
                      type="number"
                      value={findPriceItem('CUSTOM_WEEKLY')?.billingTerm}
                      onChange={handleWeeklyBillingTermChange}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                    />
                    <span>Payments</span>
                  </Row>
                </FormControl>
              </Grid>
              <Grid item={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Auto Renew?</FormLabel>
                  <Switch
                    handleClick={handleAutoRenewToggle('CUSTOM_WEEKLY')}
                    isChecked={findPriceItem('CUSTOM_WEEKLY')?.autoRenew}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <PricingTitle>What does this mean?</PricingTitle>
            <Row>
              <ul>
                <li>
                  Patient pays you{' '}
                  {<FixedDecimalFormat amount={findPriceItem('CUSTOM_WEEKLY')?.price} prefix={'$'} decimalScale={0} />}{' '}
                  every {findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency} week(s).
                </li>
                <li>
                  Total plan length is {findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency} week(s) x{' '}
                  {findPriceItem('CUSTOM_WEEKLY')?.billingTerm} payment(s) ={' '}
                  {findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency * findPriceItem('CUSTOM_WEEKLY')?.billingTerm}{' '}
                  weeks.
                </li>
                <li>
                  {findPriceItem('CUSTOM_WEEKLY')?.autoRenew
                    ? `Plan auto-renews every ${
                        findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency * findPriceItem('CUSTOM_WEEKLY')?.billingTerm
                      } weeks.`
                    : `Plan ends after ${
                        findPriceItem('CUSTOM_WEEKLY')?.paymentFrequency * findPriceItem('CUSTOM_WEEKLY')?.billingTerm
                      } weeks`}
                </li>
              </ul>
            </Row>
          </>
        )}

        {PAYMENT_SCHEDULE_TO_LIST[paymentSchedule]?.includes('MONTHLY') && (
          <>
            <PricingTitle>Monthly Settings</PricingTitle>
            <Grid container={true} item={true} xs={12} spacing={1}>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Monthly Payment</FormLabel>
                  <StyledCurrencyInput
                    data-testid="membership-form-monthly-price"
                    id="membership-form-monthly-price"
                    placeholder="Montly Price"
                    name="MONTHLY"
                    className={`form-control`}
                    value={findPriceItem('MONTHLY')?.price}
                    onValueChange={handleCurrencyChange}
                    prefix="$"
                    decimalScale={2}
                    step={1}
                    allowNegativeValue={false}
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Billing Term</FormLabel>
                  <DropDownBorder style={{ width: '100%' }}>
                    <DropDown
                      maxHeight={'235'}
                      ignoreInitialFirstItem={true}
                      options={monthlyAutoRenewOptions}
                      placeholder="Billing Term"
                      placeholderColor={'#DADADA'}
                      defaultValue={findPriceItem('MONTHLY')?.billingTerm?.toString() || '6'}
                      onChange={handleBillingTermChange('MONTHLY')}
                      hoveredMode={false}
                    />
                  </DropDownBorder>
                </FormControl>
              </Grid>
              <Grid item={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Auto Renew?</FormLabel>
                  <Switch
                    handleClick={handleAutoRenewToggle('MONTHLY')}
                    isChecked={findPriceItem('MONTHLY')?.autoRenew}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {PAYMENT_SCHEDULE_TO_LIST[paymentSchedule]?.includes('MONTHLY_FLEX') && (
          <>
            <PricingTitle>Monthly Settings</PricingTitle>
            <Grid container={true} item={true} xs={12} spacing={1}>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>First Monthly Payment (One-Time)</FormLabel>
                  <StyledCurrencyInput
                    data-testid="membership-form-monthly-price"
                    id="membership-form-monthly-price"
                    placeholder="First Monthly Payment (One-Time)"
                    name="MONTHLY_FLEX"
                    className={`form-control`}
                    value={findPriceItem('MONTHLY_FLEX')?.firstPayment}
                    onValueChange={handleFirstPaymentCurrencyChange}
                    prefix="$"
                    decimalScale={2}
                    step={1}
                    allowNegativeValue={false}
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>All Subsequent Monthly Payments</FormLabel>
                  <StyledCurrencyInput
                    data-testid="membership-form-monthly-price"
                    id="membership-form-monthly-price"
                    placeholder="All Subsequent Monthly Payments"
                    name="MONTHLY_FLEX"
                    className={`form-control`}
                    value={findPriceItem('MONTHLY_FLEX')?.price}
                    onValueChange={handleCurrencyChange}
                    prefix="$"
                    decimalScale={2}
                    step={1}
                    allowNegativeValue={false}
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Billing Term</FormLabel>
                  <DropDownBorder style={{ width: '100%' }}>
                    <DropDown
                      maxHeight={'235'}
                      ignoreInitialFirstItem={true}
                      options={monthlyAutoRenewOptions}
                      placeholder="Billing Term"
                      placeholderColor={'#DADADA'}
                      defaultValue={findPriceItem('MONTHLY_FLEX')?.billingTerm?.toString() || '6'}
                      onChange={handleBillingTermChange('MONTHLY_FLEX')}
                      hoveredMode={false}
                    />
                  </DropDownBorder>
                </FormControl>
              </Grid>
              <Grid item={true}>
                <FormControl fullWidth={true} variant="outlined">
                  <FormLabel>Auto Renew?</FormLabel>
                  <Switch
                    handleClick={handleAutoRenewToggle('MONTHLY_FLEX')}
                    isChecked={findPriceItem('MONTHLY_FLEX')?.autoRenew}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const StyledTextField = styled(TextField)`
  width: 70px;
`;

const PricingTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
  margin-top: 40px;
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';
  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;
