import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

import { DropDown, FixedDecimalFormat } from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { clone, DropdownItemValue, formatDate, getExpiresText, LastActivityOptions } from 'lib/utils';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { theme } from 'config/theme';
import {
  Approved,
  CheckoutInProgress,
  Denied,
  Expired,
  Incomplete,
  TransactionComplete,
} from 'lib/components/ApplicationStatutes';
import { PopoverContainer } from 'lib/components/ApplicationStatutes/components';
import { calculateAppliedOn } from 'lib/utils/GetAppliedDate';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ExpiresInWrapperProps {
  flagged: boolean;
}

export const useApplicationListTableColumns = (expandableOpened: boolean, selectedItem: any, column: any) => {
  const permission = usePermission();
  const { features = [] } = useStore();
  const hasSelfCheckoutFeature = features?.includes(FeatureNames.SELF_CHECKOUT);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState<string | null>(null);

  const getActionItems = (approval) => {
    const approvalActionOptions = clone(LastActivityOptions);
    if (approval?.scheduled && approval?.scheduled === DropdownItemValue.SCHEDULED) {
      approvalActionOptions[1].label = `Scheduled ${formatDate(approval?.scheduledAt)}`;
    }
    return approvalActionOptions;
  };

  const getPurchaseRow = (row) => {
    const checkoutButtonVisible = !(expandableOpened && selectedItem?.id === row.applicationId) && row.startedAt > 0;

    const isApproved = row?.status === 'APPROVED';
    const hasTreatmentPlanChannel = row?.activeLoan?.channel === 'TREATMENT_PLAN';

    if (row.startedAt < 0) {
      return <Expired row={row} />;
    } else if (row.status === 'INCOME_VERIFICATION' || row.status === 'INITIALIZED') {
      return <Incomplete row={row} />;
    } else if (
      isApproved &&
      row?.activeLoan?.count > 0 &&
      !['INITIATED', 'DRAFTED', 'REVIEW'].includes(row?.activeLoan?.status)
    ) {
      return <TransactionComplete row={row} expandableOpened={expandableOpened} selectedItem={selectedItem} />;
    } else if (row.status === 'DENIED') {
      return <Denied row={row} />;
    } else if ((isApproved && !row.selfCheckout && hasSelfCheckoutFeature) || (isApproved && hasTreatmentPlanChannel)) {
      return !permission(FeatureNames.CHECKOUT, ScreenNames.DASHBOARD) ? null : checkoutButtonVisible ? (
        <Approved row={row} />
      ) : null;
    } else if (row.amount && hasSelfCheckoutFeature) {
      return <CheckoutInProgress row={row} expandableOpened={expandableOpened} selectedItem={selectedItem} />;
    }
  };

  const getCTARow = (row) => {
    const hasTreatmentPlanChannel = row?.activeLoan?.channel === 'TREATMENT_PLAN';

    if (['IV_REQUIRED', 'PP_REQUIRED', 'PP_ONGOING', 'PP_COMPLETE']?.includes(row?.type)) {
      return;
    }
    if (row?.selfCheckout && hasSelfCheckoutFeature && !hasTreatmentPlanChannel) {
      return (
        <Flex>
          <FixedDecimalFormat amount={row?.amount} prefix={'$'} />
          <SmallText>Purchase Pending</SmallText>
        </Flex>
      );
    } else if (row?.selfCheckout && hasSelfCheckoutFeature && hasTreatmentPlanChannel) {
      return (
        <Flex>
          <CheckoutText>Treatment Plan Sent</CheckoutText>
        </Flex>
      );
    } else if (row?.readyForCheckout) {
      return (
        <Flex>
          <CheckoutText>Ready For Checkout</CheckoutText>
        </Flex>
      );
    } else {
      return (
        <DropdownContainer>
          {!permission(FeatureNames.SET_STATUS, ScreenNames.DASHBOARD) ? (
            ''
          ) : (
            <DropDown
              hoveredMode={false}
              id={`drop-${row.applicationId}`}
              options={getActionItems(row)}
              textColor={'#0E202F'}
              onChange={row.onApprovalStatusChange}
              defaultValue={row.scheduled}
              showAllItem={true}
              fontSize="12px"
            />
          )}
        </DropdownContainer>
      );
    }
  };

  const handlePopoverOpen = (event) => {
    setPopoverId(event.currentTarget.id);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverOpen(false);
  };

  const isCheckedOutForAWhile = (row) => {
    return (
      row.scheduled === DropdownItemValue.NEW &&
      row.status === 'APPROVED' &&
      !row.selfCheckout &&
      !row?.activeLoan?.count &&
      hasSelfCheckoutFeature &&
      dayjs().subtract(5, 'days').isBefore(dayjs(row.createdAt))
    );
  };

  const getDeclinedExpiresText = (row) => {
    const dateDiff = dayjs(dayjs().startOf('day')).diff(dayjs(row.createdAt).startOf('day'), 'day');

    const eligibleForReapply = dateDiff < 30;

    if (eligibleForReapply) {
      return `Can reapply in ${30 - dateDiff} days`;
    } else {
      return 'Eligible to reapply';
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'borrowerName',
      minWidth: '120px',
      maxWidth: '160px',
      cell: (row) => (
        <>
          {isCheckedOutForAWhile(row) && (
            <Indicator
              id={`indicator_${row.applicationId}`}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {popoverOpen && popoverId === `indicator_${row.applicationId}` && (
                <PopoverContainer ternary={true} left={65}>
                  <PopoverContent>
                    <PopoverText>
                      You have a new application! The best time to follow up is during the first 24 hours.
                    </PopoverText>
                  </PopoverContent>
                  <DownCircle />
                </PopoverContainer>
              )}
              <VerticalText>NEW</VerticalText>
            </Indicator>
          )}

          <TextContainer>
            <Text>{row.borrowerName}</Text>
            <SmallText>{row.phone}</SmallText>
          </TextContainer>
        </>
      ),
      ignoreRowClick: true,
    },
    {
      name: 'Application Date',
      selector: 'createdAt',
      sortable: true,
      cell: (row) => {
        return (
          <AppliedOnContainer
            id={`id_${row.applicationId}`}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {calculateAppliedOn(row.createdAt)}
            <br />
            <ExpiresInWrapper flagged={row.startedAt < 3}>
              {row.status === 'DENIED' ? (
                <>{getDeclinedExpiresText(row)}</>
              ) : (
                <>
                  {row.startedAt < 3 && <StyledFontAwesomeIcon icon={faExclamationTriangle} />}
                  {getExpiresText(row.startedAt)}
                </>
              )}
            </ExpiresInWrapper>
            {popoverOpen && popoverId === `id_${row.applicationId}` && (
              <Popover>
                <PopoverContent>
                  <PopoverText>Applied at {dayjs(row.createdAt).format('hh:mm A')}</PopoverText>
                </PopoverContent>
                <DownCircle />
              </Popover>
            )}
          </AppliedOnContainer>
        );
      },
      maxWidth: '120px',
      minWidth: '120px',
      ignoreRowClick: true,
    },
    {
      name: 'Remaining Balance',
      selector: 'balanceAvailable',
      sortable: true,
      cell: (row) => {
        return row.status === 'INCOME_VERIFICATION' || row.status === 'DENIED' ? (
          <>n/a</>
        ) : (
          <RemainingBalanceContainer>
            <RemainingBalanceTop>
              <FixedDecimalFormat
                data-tag={row.status !== 'APPROVED' && 'allowRowEvents'}
                amount={row.eligible}
                prefix={'$'}
              />
            </RemainingBalanceTop>

            {row?.status === 'APPROVED' && row?.activeLoan?.count > 0 && row?.activeLoan?.status !== 'INITIATED' && (
              <RemainingBalance>Remaining Balance</RemainingBalance>
            )}
            {row?.creditLineIncrease === 'ELIGIBLE' && (
              <CreditLineIncreaseText>May request an increase</CreditLineIncreaseText>
            )}
          </RemainingBalanceContainer>
        );
      },
      minWidth: '155px',
      maxWidth: '155px',
      ignoreRowClick: true,
    },
    ...column,
    {
      name: 'Last Activity',
      selector: 'scheduled',
      cell: (row) => {
        return getCTARow(row);
      },
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: 'amount',
      right: true,
      left: false,
      width: '221px',
      cell: (row) => getPurchaseRow(row),
      ignoreRowClick: true,
    },
  ];

  return columns;
};

const VerticalText = styled.div`
  width: max-content;
  transform: rotate(-90deg) translateY(-60%) translateX(35%);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 9px;
  color: rgb(255, 255, 255);
`;

const Indicator = styled.div`
  background-color: ${theme.main.green};
  width: 18px;
  height: 100%;
  position: absolute;
  margin-left: -16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: default;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #f5365c;
  margin: 0 5px;
`;

const ExpiresInWrapper = styled.div<ExpiresInWrapperProps>`
  color: ${(props) => (props.flagged ? '#f5365c' : props.theme.main.textColor)};
  font-size: 10px;
`;

const Text = styled.p`
  margin: 0;
  line-height: 16px;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
`;

const CheckoutText = styled(SmallText)`
  font-size: 12px;
`;

const TextContainer = styled.div`
  padding: 15px 8px;
  margin: 4px 0;
`;

const AppliedOnContainer = styled.div`
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownContainer = styled.div`
  .dropdown-right {
    min-width: 100px;
    padding-left: 0 !important;
    padding-right: 10px;
    svg {
      color: ${(props) => props.theme.main.green};
      margin-left: 10px;
    }
  }
  .dropdown-left {
    padding-left: -100px !important;

    .dropdown-content {
      left: -100px;
    }
  }
`;

const Popover = styled.div<{ secondary?: boolean; ternary?: boolean; left?: number }>`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: ${(props) => (props.secondary || props.ternary ? '240px' : '150px')};
  z-index: 10;
  left: ${(props) => (props.secondary ? '-125px' : props.ternary ? `-${props.left}px` : '-35px')};
  top: ${(props) => (props.secondary ? '-110px' : props.ternary ? '-80px' : '-60px')};
  min-width: 155px;
`;

const DownCircle = styled.div<{ secondary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00c37d;
  position: absolute;
  left: ${(props) => (props.secondary ? '200px' : '65px')};
`;

const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
  display: flex;
  justify-content: center;
`;

const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

const RemainingBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemainingBalanceTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CreditLineIncreaseText = styled.span`
  font-size: 10px !important;
  line-height: 14px !important;
  font-style: italic;
`;

const RemainingBalance = styled.span`
  font-size: 10px !important;
  line-height: 14px !important;
`;
