import React from 'react';

import { Button as CherryButton } from '@frontend/cherry-library';
import { DashCard, DashComponent, PageHeader } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Column, Container, Row } from 'lib/styles';

const EmailTemplates = () => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const { isSnackbarVisible } = useSnackbar();

  const openModal = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Viewed Email Templates', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
    });
    window.open('https://withcherry.com/sharing-cherry/email-templates/', '_blank');
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Marketing" />

      <Row>
        <Column>
          <DashComponent dashHeader={'Email Templates'} />
          <DashCard>
            <Row fontSize={'14px'}>
              Email marketing is one of the easiest, cheapest, and most effective ways to reach your clients. Don't have
              time to draft an email blast to your clients? We have templates that you can freely use.
            </Row>
            <br />
            <Row>
              <CherryButton variant="primary" data-testid="scheduleMeetingButton" onClick={openModal}>
                Email Templates →
              </CherryButton>
            </Row>
          </DashCard>
        </Column>
      </Row>
    </Container>
  );
};

export default EmailTemplates;
