import { useMutation } from '@apollo/client';
import { Button, Dropdown, EmailInput, formatPhoneNumber, CurrencyInput, emailPattern } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { SecondaryCancelLoanButton, TertiaryButton } from 'lib/components';
import {
  Center,
  DropDownBorder,
  FormLabel,
  TextLink,
  TextSecondary,
} from 'lib/components/ExpandableComponents/Checkout/Common';
import ModalV2, { MODAL_STATUS } from 'lib/components/ModalV2/ModalV2';
import { CREATE_LOAN, PATCH_LOAN } from 'lib/graphql/mutations';
import { useMerchantLogin } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle-outline.svg';
import { useSegment } from 'lib/hooks/useSegment';
import { SEND_OPTION_TO_CHANNEL } from '../DentalCheckoutModal';
import { useCheckoutWithPatient } from 'lib/services/useCheckoutWithPatient';

export interface TreatmentPlan {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  consultationSummary: string;
  treatmentCost: number | string;
  sendText: boolean;
  sendEmail: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: (values: TreatmentPlan) => void;
  selectedItemData: any;
  isFromCheckoutWithPatient?: boolean;
  onEditLoan?: (id: number | string, amount: number | string) => void;
  onCancelLoan?: (id: number | string) => void;
}

enum ModalStep {
  EDIT_CANCEL = 'EDIT_CANCEL',
  SUCCESS = 'SUCCESS',
  CANCEL_OPENED = 'CANCEL_OPENED',
  CANCEL_SUCCESS = 'CANCEL_SUCCESS',
  LOADING = 'LOADING',
}

export const DentalEditCancelPlanModal = ({
  open,
  onClose,
  selectedItemData,
  isFromCheckoutWithPatient = false,
  onEditLoan,
  onCancelLoan,
}: Props) => {
  const { merchants, organization } = useStore();
  const { merchantLogout, merchantLogin } = useMerchantLogin();
  const alert = useAlert();
  const { segmentEventHandler } = useSegment();

  const [createLoan] = useMutation(CREATE_LOAN);
  const [patchLoan] = useMutation(PATCH_LOAN);

  const { handleStartCheckoutWithPatient } = useCheckoutWithPatient();
  const minPurchaseAmount = 200;

  const pendingLoan =
    selectedItemData?.loans?.find((loan) => ['INITIATED', 'REVIEW', 'DRAFTED'].includes(loan?.status)) || {};

  const [selectedMerchantId, setSelectedMerchantId] = useState(pendingLoan?.merchantId);
  const [selectedCheckoutMethod, setSelectedCheckoutMethod] = useState<SEND_OPTION_TO_CHANNEL>(
    SEND_OPTION_TO_CHANNEL.SMS,
  );
  const [email, setEmail] = useState('');

  const defaultValue = pendingLoan?.purchaseAmount;
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalStep, setModalStep] = useState(ModalStep.EDIT_CANCEL);
  const [modalStatus, setModalStatus] = useState<MODAL_STATUS | undefined>();

  const options = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: merchant.name,
    };
  });

  const checkoutMethodOptions = [
    { value: SEND_OPTION_TO_CHANNEL.SMS, label: `Text to ${formatPhoneNumber(selectedItemData?.customer?.phone)}` },
    { value: SEND_OPTION_TO_CHANNEL.EMAIL, label: 'Email' },
    { value: SEND_OPTION_TO_CHANNEL.CHECKOUT_WITH_PATIENT, label: 'Process on this device' },
  ];

  useEffect(() => {
    setAmount(defaultValue);
    findPreferedLocation();
  }, [selectedItemData]);

  const findPreferedLocation = () => {
    const merchantId = pendingLoan?.merchantId || selectedItemData?.application?.merchantId;

    const findLocation = options?.find((opt) => opt?.value === merchantId);
    if (findLocation) {
      onMerchantSelect(findLocation);
      setSelectedMerchantId(findLocation.value);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    await cancelPlan(false);
    await createLoanFromApplication();
  };

  const handleClose = () => {
    setModalStep(ModalStep.EDIT_CANCEL);
    setModalStatus(undefined);
    setSelectedCheckoutMethod(SEND_OPTION_TO_CHANNEL.SMS);
    setEmail('');
    onClose();
  };

  const isAmountValid = () => {
    if (
      !amount ||
      Number(amount) > selectedItemData?.application?.balanceAvailable ||
      Number(amount) < (selectedItemData?.application?.menu?.minPurchase || minPurchaseAmount)
    ) {
      return false;
    }
    return true;
  };

  const isEmailValid = () => {
    return email ? emailPattern?.test(email) : true;
  };

  const isUpdateValid = () => {
    return (
      isAmountValid() && (selectedCheckoutMethod === SEND_OPTION_TO_CHANNEL.EMAIL ? email && isEmailValid() : true)
    );
  };

  const validateAmount = (inputAmount: number, hideBanner?: boolean) => {
    if (inputAmount && Number(inputAmount) > selectedItemData?.application?.balanceAvailable) {
      const errorMessage = `Purchase amount cannot be greater than ${CurrencyUtil.toCurrencyString(
        selectedItemData?.application?.balanceAvailable,
        true,
      )}`;
      if (!hideBanner) {
        alert.error(errorMessage);
      }
      return { message: errorMessage, isValid: false };
    } else if (
      inputAmount &&
      Number(inputAmount) < (selectedItemData?.application?.menu?.minPurchase || minPurchaseAmount)
    ) {
      const errorMessage = `Minimum purchase amount is ${CurrencyUtil.toCurrencyString(
        selectedItemData?.application?.menu?.minPurchase || minPurchaseAmount,
        true,
      )}`;
      if (!hideBanner) {
        alert.error(errorMessage);
      }
      return { message: errorMessage, isValid: false };
    } else {
      return { message: '', isValid: true };
    }
  };

  const cancelPlan = async (shouldCallOnCancelLoan = true) => {
    setLoading(true);
    setModalStatus(MODAL_STATUS.LOADING);
    try {
      selectedMerchantId && (await merchantLogin(selectedMerchantId));

      const { data } = await patchLoan({
        variables: {
          input: {
            loanId: pendingLoan?.id,
            status: 'CANCELLED',
          },
        },
      });
      merchantLogout();

      if (data.patchLoan.id) {
        if (modalStep === ModalStep.CANCEL_OPENED) {
          setLoading(false);
          setModalStatus(MODAL_STATUS.SUCCESS);
        }
        setLoading(false);

        if (onCancelLoan && shouldCallOnCancelLoan) onCancelLoan(data.patchLoan.id);
      } else {
        throw Error();
      }
    } catch (e: any) {
      setLoading(false);
      merchantLogout();
      handleClose();
      if (e?.response?.data?.message === 'loan.status.invalid') {
        alert.error('The information for this contract was no longer current, your page was refreshed');
        return;
      } else {
        alert.error(`An error occurred please try again`);
        return;
      }
    }
  };

  const loginAndCreateLoan = async () => {
    await merchantLogin(selectedMerchantId || 0);
    const { data } = await createLoan({
      variables: {
        input: {
          merchantId: selectedMerchantId,
          applicationId: selectedItemData.applicationId,
          purchaseAmount: Number(amount),
          selfCheckout: true,
          channel: isFromCheckoutWithPatient ? 'CHECKOUT_WITH_PATIENT' : selectedCheckoutMethod,
        },
      },
    });
    merchantLogout();
    const loanId = data.createLoan?.data?.id;
    if (selectedCheckoutMethod === SEND_OPTION_TO_CHANNEL.CHECKOUT_WITH_PATIENT) {
      const status = await handleStartCheckoutWithPatient(loanId, organization?.id, amount);
      if (!status) {
        setLoading(false);
        alert.error(`An error occurred during creating the checking out on device, please try again`);
        handleClose();
      }
    } else {
      if (loanId) {
        setModalStep(ModalStep.SUCCESS);
        setModalStatus(MODAL_STATUS.SUCCESS);
        setLoading(false);

        if (onEditLoan) {
          onEditLoan(loanId, amount);
        }
      } else {
        setLoading(false);
        alert.error(`An error occurred during creating the checkout link, please try again`);
        handleClose();
        return;
      }
    }
  };

  const validateAmountAndCreateLoan = async () => {
    if (validateAmount(amount)?.isValid) {
      loginAndCreateLoan();
    } else {
      setLoading(false);
    }
  };

  const createLoanFromApplication = async (e?: SyntheticEvent) => {
    try {
      if (selectedMerchantId) {
        setLoading(true);
        setModalStatus(MODAL_STATUS.LOADING);
        validateAmountAndCreateLoan();
      }
    } catch (err) {
      merchantLogout();
      setLoading(false);
      alert.error(`An error occurred please try again`);
      handleClose();
      return;
    }
  };

  const handleCancelPlan = () => {
    cancelPlan(true);
  };

  const isFormValid = () => {
    return amount && !loading;
  };

  const onMerchantSelect = async (option) => {
    setSelectedMerchantId(option?.target?.value);
  };

  const onCheckoutMethodSelect = (event) => {
    const value = event?.target?.value;
    setSelectedCheckoutMethod(value);
  };

  const onEmailChange = (event) => {
    setEmail(event?.target?.value);
  };

  const onAmountChange = (event) => {
    setAmount(event?.target?.value);
  };

  const toggleCancelOpened = () => setModalStep(ModalStep.CANCEL_OPENED);

  const successContent = () => {
    if (modalStep === ModalStep.CANCEL_OPENED) {
      return `${selectedItemData?.customer?.firstName} ${selectedItemData?.customer?.lastName}'s plan has been canceled and approval has been reinstated. Any links sent previously have been
          deactivated`;
    } else {
      return `${selectedItemData?.customer?.firstName} ${selectedItemData?.customer?.lastName} has received an updated link on their phone. You’ll get notified once they’ve chosen a plan and
          entered payment info.`;
    }
  };

  const getTitle = () => {
    if (modalStep === ModalStep.CANCEL_OPENED) {
      return `Are you sure you want to cancel this ${isFromCheckoutWithPatient ? 'purchase' : 'payment plan'}?`;
    } else {
      return isFromCheckoutWithPatient ? 'Edit or Cancel Purchase' : 'Edit/Cancel Plan';
    }
  };

  const getCustomFooter = () => {
    if (modalStatus !== undefined) {
      return;
    }

    const getEditCancelModalButtonText = () => {
      if (isFromCheckoutWithPatient) {
        return 'Update Purchase';
      } else if (selectedCheckoutMethod === 'CHECKOUT_WITH_PATIENT') {
        return 'Continue';
      } else {
        return 'Update and Send';
      }
    };

    if (modalStep === ModalStep.EDIT_CANCEL) {
      return (
        <>
          <GridWithSpacing container={true} spacing={1}>
            <Grid item={true} xs={4}>
              <Button fullWidth={true} variant="secondary" onClick={handleClose} data-tag="checkout">
                Nevermind
              </Button>
            </Grid>
            <Grid item={true} xs={8}>
              <Button
                fullWidth={true}
                data-testid="checkout-action-button"
                disabled={!isUpdateValid()}
                onClick={handleSubmit}
              >
                {getEditCancelModalButtonText()}
              </Button>
            </Grid>
          </GridWithSpacing>
          <GridWithSpacing container={true} justifyContent="center">
            <TextLink data-testid="checkout-cancel-link" onClick={toggleCancelOpened}>
              {isFromCheckoutWithPatient ? 'Cancel Purchase' : 'Cancel this plan instead'}
            </TextLink>
          </GridWithSpacing>
        </>
      );
    } else if (modalStep === ModalStep.CANCEL_OPENED) {
      return (
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4}>
            <div>
              <SecondaryCancelLoanButton
                onClick={handleClose}
                variant="contained"
                data-tag="confirm-cancel"
                color="#ec3360"
              >
                Close
              </SecondaryCancelLoanButton>
            </div>
          </Grid>
          <Grid item={true} xs={8}>
            <TertiaryButton data-testid="checkout-cancel-link-apply" onClick={handleCancelPlan} variant="filled">
              Yes, cancel this plan
            </TertiaryButton>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <ModalV2
      open={open}
      isSubmitDisabled={modalStep === ModalStep.CANCEL_OPENED ? false : !isFormValid()}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      status={modalStatus}
      title={getTitle()}
      submitButtonLabel={isFromCheckoutWithPatient ? 'Update Purchase' : 'Update and Send'}
      width={670}
      showClose={true}
      cancelButtonlabel="Nevermind"
      successContent={successContent()}
      customFooter={getCustomFooter()}
      allowOverflow={true}
      blur={selectedCheckoutMethod === SEND_OPTION_TO_CHANNEL.CHECKOUT_WITH_PATIENT}
    >
      {modalStep === ModalStep.EDIT_CANCEL ? (
        <>
          <BorrowerName>
            {selectedItemData?.customer?.firstName} {selectedItemData?.customer?.lastName}
          </BorrowerName>
          <AvaliableAmountText>
            {CurrencyUtil.toCurrencyString(selectedItemData?.application?.balanceAvailable)} available
          </AvaliableAmountText>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <FormLabel>Purchase Amount (including tax)</FormLabel>
              <CurrencyInput
                value={amount}
                onChange={onAmountChange}
                fullWidth={true}
                errorText={validateAmount(amount, true)?.message}
                error={!isAmountValid()}
              />
            </Grid>
            <FormLabel>Checkout Location</FormLabel>
            <Dropdown
              data-testid="edit-cancel-modal-checkout-location-dropdown"
              options={options || []}
              onChange={onMerchantSelect}
              onClick={segmentEventHandler('PRACTICE_PORTAL.EDIT_CANCEL_MODAL.CHECKOUT_CLICKED')}
              defaultValue={selectedMerchantId}
              value={selectedMerchantId}
            />

            {!isFromCheckoutWithPatient && (
              <>
                <FormLabel>Checkout Method</FormLabel>
                <Dropdown
                  data-testid="edit-cancel-modal-checkout-method-dropdown"
                  options={checkoutMethodOptions}
                  onChange={onCheckoutMethodSelect}
                  onClick={segmentEventHandler('PRACTICE_PORTAL.EDIT_CANCEL_MODAL.CHECKOUT_METHOD_CLICKED', {
                    data: { checkoutMethod: selectedCheckoutMethod },
                  })}
                  defaultValue={SEND_OPTION_TO_CHANNEL.SMS}
                  value={selectedCheckoutMethod}
                />
              </>
            )}
            {selectedCheckoutMethod === 'CHECKOUT_WITH_PATIENT' && (
              <CheckoutWithPatientDisclaimer>
                <InfoIcon />
                <span>
                  The patient must be shown all screens on your device while completing the checkout. Only use this
                  option if the patient is here with you in person.
                </span>
              </CheckoutWithPatientDisclaimer>
            )}
            {selectedCheckoutMethod === SEND_OPTION_TO_CHANNEL.EMAIL && (
              <EmailInputContainer>
                <EmailInput
                  fullWidth={true}
                  hideIcon={true}
                  value={email}
                  onChange={onEmailChange}
                  title="Patient Email"
                  defaultValue={selectedItemData?.customer?.email}
                  error={!isEmailValid()}
                  errorText="Enter a valid email address"
                />
              </EmailInputContainer>
            )}
          </Grid>
        </>
      ) : null}
      {modalStep === ModalStep.CANCEL_OPENED ? (
        <AutoHeightCenter>
          <TextSecondary>
            {selectedItemData?.customer?.firstName} {selectedItemData?.customer?.lastName}'s approval will be reinstated
            and you can issue a new payment plan at a later time.
          </TextSecondary>
        </AutoHeightCenter>
      ) : null}
    </ModalV2>
  );
};

const BorrowerName = styled.span`
  color: var(--Midnight-Blue, #0e202f);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const AvaliableAmountText = styled.span`
  color: var(--Midnight-Blue, #0e202f);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 24px;
`;

const GridWithSpacing = styled(Grid)`
  margin-top: 16px !important;
`;

const AutoHeightCenter = styled(Center)`
  height: auto;
`;

const CheckoutWithPatientDisclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 24px;

  svg {
    height: 32px;
    width: 32px;
  }
`;

const EmailInputContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;
