import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 8px;
  margin-top: 24px;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  margin-bottom: 24px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 24px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    padding: 34px 0 24px;
  }
`;

export const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

export const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  background: ${(props) => props.theme.main.aliceBlue};
  border-radius: 4px;
  margin-top: 28px;
  padding: 16px;
  text-align: center;
`;

export const ContentTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.green};
  margin-bottom: 8px;
`;

export const ContentText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: ${(props) => props.theme.main.textColor};
  margin-bottom: 4px;
`;

export const ContentSubText = styled.span<{ margin?: string; fontSize?: string; lineHeight?: string }>`
  font-style: italic;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize || '12px'};
  line-height: ${(props) => props.lineHeight || '16px'};
`;

export const ErrorContainer = styled.div`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
`;

export const LoadingContainer = styled.div.attrs(() => ({
  'data-testid': 'down-payment-loading',
}))`
  text-align: center;
  margin: 0 auto;
`;
