import { FormControl, Grid, RadioGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Radio } from '@frontend/cherry-library';
import { LocationManagementStepEnums } from 'lib/types';
import { accountNumberPattern, routingNumberPattern } from 'lib/utils';
import { CherryDialogTitle, DialogContainer, DialogContent, SubTitle } from 'pages/desktop/Settings/components/Dialog';

enum ACCOUNT_TYPES {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

enum ACCOUNT_CLASSIFICATION {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}
interface Bank {
  accountNickname: string;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  accountClassification: string;
}

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onChangeHandler: (key, value) => void;
  onChangeBankingInfo: (key, value) => void;
}

export const AddBank = ({ handleClose, onStepChange, onChangeHandler, onChangeBankingInfo }: Props) => {
  const initialData = {
    accountNickname: '',
    accountName: '',
    routingNumber: '',
    accountNumber: '',
    accountType: '',
    accountClassification: '',
  };

  const [state, setState] = useState<Bank>(initialData);

  const [isValidAccountNumber, setIsValidAccountNumber] = useState<boolean>(true);
  const [isValidRoutingNumber, setIsValidRoutingNumber] = useState<boolean>(true);

  const validAccountNumber = (value: string) => {
    return accountNumberPattern.test(value);
  };
  const validRoutingNumber = (value: string) => {
    return routingNumberPattern.test(value);
  };

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    onChangeHandler(key, value);
    if (key === 'routingNumber') {
      setIsValidRoutingNumber(validRoutingNumber(value));
    }
    if (key === 'accountNumber') {
      setIsValidAccountNumber(validAccountNumber(value));
    }

    setState((prevState: Bank) => {
      return { ...prevState, [key]: value };
    });
  };

  const allPropertiesNotEmpty = (obj) => {
    return Object.entries(obj).every(([key, value]) => value) && isValidAccountNumber && isValidRoutingNumber;
  };

  const onChangeType = (event) => {
    onChangeHandler('accountType', event?.target?.value);
    setState((prevState: Bank) => {
      return { ...prevState, accountType: event?.target?.value };
    });
  };

  const onChangeClassification = (event) => {
    onChangeHandler('accountClassification', event?.target?.value);
    setState((prevState: Bank) => {
      return { ...prevState, accountClassification: event?.target?.value };
    });
  };

  const onContinueHandler = () => {
    onStepChange(LocationManagementStepEnums.REVIEW);
    onChangeBankingInfo('accountClassification', state.accountClassification);
    onChangeBankingInfo('accountName', state.accountName);
    onChangeBankingInfo('accountNickname', state.accountNickname);
    onChangeBankingInfo('accountNumber', state.accountNumber);
    onChangeBankingInfo('accountType', state.accountType);
    onChangeBankingInfo('routingNumber', state.routingNumber);
  };

  const onBackHandler = () => {
    onStepChange(LocationManagementStepEnums.SELECT_BANK);
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Connect New Bank
      </CherryDialogTitle>
      <SubTitle>
        Connecting a new bank will make it available for use at other locations in your Cherry account.
      </SubTitle>
      <SubTitle>
        Your credentials are securely stored using the same 256-bit encryption as financial institutions.
      </SubTitle>
      <DialogContent>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormTitle hasError={false}>Nickname</FormTitle>
              <StyledTextField
                size="small"
                type="text"
                inputProps={{
                  'data-inputkey': 'accountNickname',
                }}
                placeholder="Nickname"
                id="location-management-nickname"
                data-testid="location-management-nickname"
                onChange={handleChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormTitle hasError={false}>Account Name</FormTitle>
              <StyledTextField
                size="small"
                type="text"
                inputProps={{
                  'data-inputkey': 'accountName',
                }}
                placeholder="Body and Beauty"
                id="location-management-accountName"
                data-testid="location-management-accountName"
                onChange={handleChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormTitle hasError={!isValidRoutingNumber}>Routing Number</FormTitle>
              <StyledTextField
                size="small"
                type="text"
                inputProps={{
                  'data-inputkey': 'routingNumber',
                }}
                id="location-management-routingNumber"
                data-testid="location-management-routingNumber"
                onChange={handleChange}
                placeholder="031101279"
                value={state?.routingNumber}
                error={!isValidRoutingNumber}
                helperText={!isValidRoutingNumber && 'Please enter a valid routing number'}
                required={true}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormTitle hasError={!isValidAccountNumber}>Account Number</FormTitle>
              <StyledTextField
                size="small"
                type="text"
                inputProps={{
                  'data-inputkey': 'accountNumber',
                }}
                id="location-management-accountNumber"
                data-testid="location-management-accountNumber"
                onChange={handleChange}
                placeholder="1111111119999"
                value={state?.accountNumber}
                error={!isValidAccountNumber}
                helperText={!isValidAccountNumber && 'Please enter a valid account number'}
                required={true}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} spacing={2}>
            <RadioTitle>Account Type</RadioTitle>
            <CustomRadioGroup aria-label="single-filter" name="singleFilter">
              <Radio
                label={'Checking'}
                value={ACCOUNT_TYPES.CHECKING}
                data-testid="account-type-checking-radio"
                onChange={onChangeType}
                checked={state?.accountType === ACCOUNT_TYPES.CHECKING}
              />
              <Radio
                label={'Savings'}
                value={ACCOUNT_TYPES.SAVINGS}
                data-testid="account-type-savings-radio"
                onChange={onChangeType}
                checked={state?.accountType === ACCOUNT_TYPES.SAVINGS}
              />
            </CustomRadioGroup>
          </Grid>
          <Grid item={true} xs={12} spacing={2}>
            <RadioTitle>Is this a personal or business account?</RadioTitle>
            <CustomRadioGroup aria-label="single-filter" name="singleFilter">
              <Radio
                label={'Personal'}
                value={ACCOUNT_CLASSIFICATION.PERSONAL}
                data-testid="account-classification-personal-radio"
                onChange={onChangeClassification}
                checked={state?.accountClassification === ACCOUNT_CLASSIFICATION.PERSONAL}
              />
              <Radio
                label={'Business'}
                value={ACCOUNT_CLASSIFICATION.BUSINESS}
                data-testid="account-classification-business-radio"
                onChange={onChangeClassification}
                checked={state?.accountClassification === ACCOUNT_CLASSIFICATION.BUSINESS}
              />
            </CustomRadioGroup>
          </Grid>
        </Grid>
      </DialogContent>

      <ButtonContainer>
        <Button fullWidth={true} variant="secondary" onClick={onBackHandler}>
          Cancel
        </Button>
        <ButtonGap />
        <Button
          fullWidth={true}
          onClick={onContinueHandler}
          disabled={!allPropertiesNotEmpty(state)}
          data-testid="submit"
        >
          Continue
        </Button>
      </ButtonContainer>
    </DialogContainer>
  );
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const FormTitle = styled.label<{ hasError?: boolean }>`
  margin: 4px 0 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.hasError ? props.theme.main.errorColor : props.theme.main.textColor)} !important;
  font-weight: 400;
`;

const CustomRadioGroup = styled(RadioGroup)``;

const RadioTitle = styled.div`
  margin: 4px 0 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #0e202f;
`;
