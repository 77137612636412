import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, TableDeleteIcon, TableLoader, UserPlusIcon } from 'lib/components';
import { CheckoutButton } from 'lib/components/ApplicationStatutes/components';
import { membershipBenefitTableStyle } from 'lib/utils';
import { AddEditBenefitModal } from './AddEditBenefitModal';

interface TableColumnProps {
  flex?: number;
}

const EditBenefitCell = () => (
  <CheckoutButton data-testid="checkout-button" data-tag="allowRowEvents" className="btn--checkout">
    Edit Benefit
  </CheckoutButton>
);

const DetailCell = () => (
  <DeleteBenefitContainer>
    <TableDeleteIcon />
  </DeleteBenefitContainer>
);

export const PlanBenefits = () => {
  const navigate = useNavigate();

  const [openBenefitModal, setOpenBenefitModal] = useState<boolean>(false);

  const goBack = () => {
    navigate('/membership/plan/builder');
  };

  const data = [
    {
      id: 1,
      title: 'Electric Toothbrush',
      type: 'Gift',
      quantity: '1',
      frequency: 'One Time',
      lastUse: '2023-06-22:10:22',
      usage: '1',
    },
    {
      id: 2,
      title: 'Full Dental Imaging',
      type: 'Included Services',
      quantity: '1',
      frequency: 'Yearly',
      lastUse: '2023-06-22:10:22',
      usage: '1',
    },
    {
      id: 3,
      title: 'Dental Exam and Cleaning',
      type: 'Bonus',
      quantity: '3',
      frequency: 'Unlimited',
      lastUse: '2023-06-22:10:22',
      usage: '0',
    },
  ];

  const tableColumns = [
    {
      name: 'Benefit Title',
      selector: (row) => row.title,
      minWidth: '230px',
      maxWidth: '230px',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
    },
    {
      name: 'Frequency',
      selector: (row) => row.frequency,
    },

    {
      name: '',
      selector: (row) => row.editBenefit,
      right: true,
      left: false,
      width: '221px',
      cell: EditBenefitCell,
      ignoreRowClick: true,
    },
    {
      name: '',
      selector: (row) => row.detail,
      right: true,
      left: false,
      width: '221px',
      cell: DetailCell,
      ignoreRowClick: true,
    },
  ];

  const addPlanHandler = () => {
    setOpenBenefitModal(true);
  };

  const hideBenefitModal = () => setOpenBenefitModal(false);

  return (
    <>
      <Header>
        <SubTitle>Plan Benefits</SubTitle>
        <ButtonPrimary
          data-testid="addBenefit"
          onClick={addPlanHandler}
          startIcon={<UserPlusIcon />}
          text={'Add a Benefit'}
        />
      </Header>

      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            noHeader={true}
            customStyles={membershipBenefitTableStyle}
            columns={tableColumns}
            data={data}
            pagination={true}
            progressPending={false}
            progressComponent={<TableLoader count={4} />}
          />
        </TableColumn>
      </TableRow>

      <ButtonContainer>
        <SavePlanContainer>
          <CancelButton onClick={goBack}>Cancel</CancelButton>
          <ButtonPrimary data-testid="saveMember" disabled={false} text={'Save Changes'} />
        </SavePlanContainer>

        <ButtonPrimary data-testid="deletePlan" disabled={false} text={'Delete Plan'} />
      </ButtonContainer>
      <AddEditBenefitModal open={openBenefitModal} handleClose={hideBenefitModal} data={null} />
    </>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
`;

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`;

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(135, 144, 151, 0.5);

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableHeadRow div {
    font-size: 12px;
    margin-bottom: -10px;
  }
  .rdt_TableRow {
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0e202f;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const DeleteBenefitContainer = styled.div`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const SavePlanContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  border: 1px solid #0e202f;
  border-radius: 2px;
  color: #0e202f;
  margin-right: 12px;
  border-radius: 4px;
`;
