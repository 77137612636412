import React from 'react';
import styled from 'styled-components';

import {
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  SlideModal,
  Title,
  TransactionExpandableInfo,
} from 'lib/components/mobile';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useVoid } from 'lib/services';

const MobileTransactionVoidDetail = () => {
  const { transactionInProcess } = useStore();
  const permission = usePermission();
  const { openDownloadMenu, onVoidClick, closeModal, slideModalVariables, navigateBack } = useVoid({
    transactionInProcess,
  });

  return (
    <Container pageTitle="Transaction Details">
      <DetailContainer>
        <Title>Disbursement Initiated</Title>
        <TransactionDescription>
          {transactionInProcess?.borrowerName} has completed their transaction, but funds have not yet been disbursed to
          your bank account on file.
        </TransactionDescription>

        <TransactionExpandableInfo
          transactionInProcess={transactionInProcess}
          expanded={true}
          isDownloadBtnVisible={true}
          onDownloadButtonClick={openDownloadMenu}
        />
        <FooterContainer>
          <ButtonContainer>
            {permission(FeatureNames.VOID_TRANSACTIONS, ScreenNames.TRANSACTIONS) && (
              <Button secondary={true} onClick={onVoidClick}>
                Void Transaction
              </Button>
            )}
            <Button secondary={true} onClick={navigateBack}>
              Back
            </Button>
          </ButtonContainer>
        </FooterContainer>
        <SlideModal {...slideModalVariables} show={slideModalVariables.visibility} hideModal={closeModal} />
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionVoidDetail;

const TransactionDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;
