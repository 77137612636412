import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ButtonPrimary, FixedDecimalFormat } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { FeatureNames } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

interface ItemProps {
  borrower: {
    firstName: string;
    lastName: string;
  };
  balanceAvailable: number;
  id: number | string;
  selfCheckout: boolean;
  activeLoan: ActiveLoan;
  merchantId?: number;
  borrowerId?: number;
}

interface ActiveLoan {
  channel: string;
}

interface SliderProps {
  total: number;
  items: ItemProps[];
  createTreatmentPlan: (item) => void;
  openCheckoutModal: (item) => void;
}

const Slider = ({ items, total, createTreatmentPlan, openCheckoutModal }: SliderProps) => {
  const { features = [] } = useStore();
  const hasSelfCheckoutFeature = features?.includes(FeatureNames.SELF_CHECKOUT);
  const { trackSegmentEvent, applicationName } = useSegment();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items?.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items?.length - 1 ? 0 : prevIndex + 1));
  };

  const handleCreateTreatmentPlan = () => {
    createTreatmentPlan(items?.[currentIndex]);
  };

  const handleCheckoutModal = () => {
    openCheckoutModal(items?.[currentIndex]);

    trackSegmentEvent('Take Home Approval Checkout Modal Opened', {
      application: applicationName,
      component: 'Take Home Approval',
      channel: items?.[currentIndex]?.activeLoan?.channel,
      borrowerId: items?.[currentIndex]?.borrowerId,
      applicationId: items?.[currentIndex]?.id,
      merchantId: items?.[currentIndex]?.merchantId,
      balanceAvailable: items?.[currentIndex]?.balanceAvailable,
    });
  };

  return (
    <MainSlider isSmall={total > 1}>
      {items?.map((item, index) => {
        const hasTreatmentPlanChannel = item?.activeLoan?.channel === 'TREATMENT_PLAN';
        const hasCheckoutButton = !(item?.selfCheckout && hasSelfCheckoutFeature && !hasTreatmentPlanChannel);
        return (
          <SliderContainer key={index} isActive={currentIndex === index} data-index={`${currentIndex} ${index}`}>
            <SliderTop>
              <SliderContent>
                <SliderTitle>{`${item?.borrower?.firstName} ${item?.borrower?.lastName}`}</SliderTitle>
                <SliderRow>
                  <SliderText>Available Balance:</SliderText>
                  {item?.balanceAvailable > 1 ? (
                    <SliderTextBalance>
                      <FixedDecimalFormat amount={item?.balanceAvailable} prefix="$" decimalScale={0} />
                    </SliderTextBalance>
                  ) : null}
                </SliderRow>
              </SliderContent>
              <ButtonContainer>
                {hasCheckoutButton ? <StyledButtonPrimary text="Checkout" onClick={handleCheckoutModal} /> : null}
                {hasCheckoutButton ? (
                  <ButtonSecondary
                    data-testid="create-treatment-plan-secondary"
                    text="Create Treatment Plan"
                    onClick={handleCreateTreatmentPlan}
                  />
                ) : (
                  <StyledButtonPrimary
                    data-testid="create-treatment-plan-primary"
                    text="Create Treatment Plan"
                    onClick={handleCreateTreatmentPlan}
                  />
                )}
              </ButtonContainer>
            </SliderTop>
          </SliderContainer>
        );
      })}
      {total > 1 ? (
        <ArrowContainer>
          {currentIndex === 0 ? (
            <Icon src={'circle_prev_disable'} width={24} height={24} />
          ) : (
            <Icon src={'circle_prev'} width={24} height={24} onClick={goToPrevSlide} hover={true} />
          )}
          <ArrowText>
            <b>{currentIndex + 1}</b> of{' '}
            <b>
              {total} patient{total > 1 ? 's' : null}
            </b>
          </ArrowText>
          {currentIndex === total - 1 ? (
            <Icon src={'circle_next_disable'} width={24} height={24} />
          ) : (
            <Icon src={'circle_next'} width={24} height={24} onClick={goToNextSlide} hover={true} />
          )}
        </ArrowContainer>
      ) : null}
    </MainSlider>
  );
};

export default React.memo(Slider);

const ButtonSecondary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: ${(props) => props.theme.main.white} !important;
  color: ${(props) => props.theme.main.midnightBlue50} !important;
  border: 1px solid ${(props) => props.theme.main.sealGray} !important;
  border-radius: 4px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    font-size: 14px !important;
  }

  @media (max-width: 1090px) {
    padding: 4px !important;
  }

  &:active,
  &:focus {
    background-color: ${(props) => props.theme.main.white} !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const StyledButtonPrimary = styled(ButtonPrimary)`
  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    font-size: 14px !important;
  }
`;

const SliderContent = styled.div`
  max-width: 50%;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderTextCss = css`
  color: ${(props) => props.theme.main.midnightBlue};
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 3px;
`;

const Container = styled.div`
  ${flexCenter};
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;

  button {
    border-radius: 4px !important;
    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

const ArrowContainer = styled(Container)`
  button {
    border-radius: 4px !important;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

const ArrowText = styled.div`
  padding: 0 16px;
  font-size: 16px;
`;

const MainSlider = styled.div<{ isSmall: boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: ${(props) => (props.isSmall ? 'auto' : '88%')};
`;

const SliderContainer = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: all 2s linear;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background-color: ${(props) => props.theme.main.white};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  padding: 0 24px;
`;

const SliderTop = styled(Container)`
  justify-content: space-between;
  padding: 8px 8px 8px 24px;
  width: calc(100% - 32px);
  background-color: ${(props) => props.theme.main.green10};
  border-radius: 8px;
  margin: 8px 0 8px;
  gap: 16px;
`;

const SliderRow = styled.div`
  ${flexCenter};
  flex-direction: row;
  justify-content: flex-start;
`;

const SliderText = styled.div`
  ${SliderTextCss};
`;

const SliderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: ${(props) => props.theme.main.midnightBlue};
  margin-bottom: 12px;
`;

const SliderTextBalance = styled.div`
  ${SliderTextCss};
  font-size: 18px;
  font-weight: 600;
`;
