import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { SEND_EXTEND_APPROVAL_MESSAGE } from 'lib/graphql/mutations';

import { CherryDialogTitle, DialogContainer } from 'pages/desktop/Settings/components/Dialog';
import { Button } from '@frontend/cherry-library';
import { useSentry } from 'lib/hooks';
import { useTranslation } from 'react-i18next';
import { useSegment } from 'lib/hooks/useSegment';

interface Application {
  approvalExtended: boolean;
  expireAt: string;
}
interface ReminderData {
  id: string;
  applicationId: number;
  customer: Borrower;
  application: Application;
}
interface Borrower {
  firstName: string;
  lastName: string;
  phone: string;
}
interface Props {
  open: boolean;
  reminderData: ReminderData | null;
  onClose: () => void;
  updateLoanRequest?: () => void;
}

const AlreadyExtendedModal = ({ open, onClose, firstName, expireAt, translate }) => {
  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} open={open}>
      <DialogContainer>
        <CherryDialogTitle id="already-extended-modal" onClose={onClose}>
          {translate('extendApproval.alreadyExtended.title')}
        </CherryDialogTitle>
        <DialogContent>
          <Content>
            {translate('extendApproval.alreadyExtended.description', {
              firstName,
              expireAt: dayjs(expireAt).format('MMM DD, YYYY'),
            })}
          </Content>
        </DialogContent>
        <ButtonContainer>
          <Button fullWidth={true} variant="primary" onClick={onClose}>
            {translate('buttons.okay')}
          </Button>
        </ButtonContainer>
      </DialogContainer>
    </Dialog>
  );
};

const SendExtensionLinkModal = ({
  open,
  onClose,
  firstName,
  expireAt,
  sendExtendApprovalMessageHandler,
  loading,
  translate,
}) => {
  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} open={open}>
      <DialogContainer>
        <CherryDialogTitle id="send-extension-link-modal" onClose={onClose}>
          {translate('extendApproval.title')}
        </CherryDialogTitle>
        <DialogContent>
          <Content>
            {translate('extendApproval.description', {
              firstName,
              initialExpireAt: dayjs(expireAt).format('MMM DD, YYYY'),
              extendedExpireAt: dayjs(expireAt).add(30, 'day').format('MMM DD, YYYY'),
            })}
          </Content>
        </DialogContent>
        <ButtonContainer>
          <Button
            loading={loading}
            disabled={loading}
            fullWidth={true}
            variant="primary"
            onClick={sendExtendApprovalMessageHandler}
          >
            {translate('extendApproval.button')}
          </Button>
        </ButtonContainer>
      </DialogContainer>
    </Dialog>
  );
};

export const ExtendApprovalModal = ({ onClose, open, reminderData }: Props) => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const { customer, applicationId, application } = reminderData || {};
  const { firstName } = customer || {};
  const isAlreadyExtended = application?.approvalExtended;
  const expireAt = application?.expireAt;

  const [sendExtendApprovalMessage, { loading }] = useMutation(SEND_EXTEND_APPROVAL_MESSAGE);

  const { captureException } = useSentry();

  const sendExtendApprovalMessageHandler = async () => {
    try {
      const { data } = await sendExtendApprovalMessage({
        variables: { input: { applicationId: applicationId } },
      });
      if (data?.sendExtendApprovalMessage?.success) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.EXTEND_APPROVAL.SUCCESS');
        alert.success(translate('extendApproval.success'));
        onClose();
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.EXTEND_APPROVAL.ERROR');
        alert.error(translate('extendApproval.error'));
        onClose();
      }
    } catch (err) {
      captureException(err);
      alert.error(translate('error.wentWrong'));
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      if (isAlreadyExtended) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.EXTENSION_LINK_ALREADY_EXTENDED_LOAD');
      } else {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.EXTENSION_LINK_CONFIRM?_LOAD');
      }
    }
  }, [open]);

  if (isAlreadyExtended) {
    return (
      <AlreadyExtendedModal
        open={open}
        onClose={onClose}
        firstName={firstName}
        expireAt={expireAt}
        translate={translate}
      />
    );
  } else {
    return (
      <SendExtensionLinkModal
        open={open}
        onClose={onClose}
        firstName={firstName}
        expireAt={expireAt}
        sendExtendApprovalMessageHandler={sendExtendApprovalMessageHandler}
        loading={loading}
        translate={translate}
      />
    );
  }
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;
const Content = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
`;
const ButtonContainer = styled.div`
  margin-top: 24px;
`;
