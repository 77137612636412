import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DashCard, PageHeader } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { useCheckoutWithPatient } from 'lib/services';
import { Container } from 'lib/styles';

import images from 'assets/images';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import { useGetLoanFile } from 'lib/services/useGetLoanFile';
import PatientInformation from 'pages/desktop/CheckoutWithPatient/PatientInformation/PatientInformation';
import { ProductCalculation } from 'pages/desktop/CheckoutWithPatient/PlanCard/types';
import PlanInformation from 'pages/desktop/CheckoutWithPatient/PlanInformation';
import {
  BackToMainPageButton,
  BoldText,
  CheckoutSummary,
  DownloadAgreementSection,
  DownloadText,
  LoadingContainer,
  LoadingSpinner,
  Row,
  StyledDivider,
  SuccessPageContainer,
  WaveIcon,
} from './styles';

const CheckoutWithPatientSuccess = () => {
  const { getBorrower, getLoan } = useCheckoutWithPatient();
  const { getProductCalculationV2 } = useProductCalculation();
  const { getLoanFile } = useGetLoanFile();
  const navigate = useNavigate();
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();

  const [loanDetail, setLoanDetail] = useState<any>();
  const [borrowerDetail, setBorrowerDetail] = useState<any>();
  const [currentPlan, setCurrentPlan] = useState<ProductCalculation>();

  const segmentEventData = {
    loanId: loanDetail?.id,
    borrowerId: borrowerDetail?.id,
    channel: loanDetail?.channel,
  };

  const fetchInitialData = async () => {
    if (id) {
      const loan = await getLoan(Number(id));
      setLoanDetail(loan);

      const borrower = await getBorrower(loan?.borrowerId);
      setBorrowerDetail(borrower);
      setSelectedPlanDetail(loan);
    }
  };

  const setSelectedPlanDetail = async (loan) => {
    const productCalculationsRes = await getProductCalculationV2(loan?.applicationId, Number(loan?.purchaseAmount));
    const currentLoanProduct = productCalculationsRes?.find((p) => Number(p?.product?.id) === loan?.productId);
    setCurrentPlan(currentLoanProduct);
  };

  useEffect(() => {
    fetchInitialData();
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_SUCCESSFUL_PAGE_LOAD', segmentEventData);
  }, []);

  const downloadAgreement = async () => {
    getLoanFile(loanDetail.id);
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_SUCCESSFUL.DOWNLOAD_TRANSACTION_CLICKED', segmentEventData);
  };

  const navigateToDashboard = async () => {
    navigate('/dashboard', { state: { force: true } });
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_SUCCESSFUL.RETURN_HOME_CLICKED', segmentEventData);
  };

  const Loading = () => (
    <LoadingContainer>
      <LoadingSpinner size={16} />
    </LoadingContainer>
  );

  return (
    <Container>
      <PageHeader title="Checkout Successful" />
      <DashCard>
        <SuccessPageContainer>
          <CheckoutSummary>
            <>
              <Row>
                <BoldText>Transaction Complete</BoldText> <Icon src={'circle_check'} />
              </Row>
              {borrowerDetail && loanDetail ? (
                <PatientInformation borrower={borrowerDetail} totalPurchase={loanDetail?.purchaseAmount} />
              ) : (
                <Loading />
              )}

              <StyledDivider />
              {currentPlan ? <PlanInformation plan={currentPlan} /> : <Loading />}
              <StyledDivider />
              <DownloadAgreementSection onClick={downloadAgreement}>
                <Icon src={'download'} width={24} height={24} hover={true} />
                <DownloadText>Download Patient Loan Agreement</DownloadText>
              </DownloadAgreementSection>
            </>
          </CheckoutSummary>
          <WaveIcon src={images.successCherry} />
        </SuccessPageContainer>
      </DashCard>
      <BackToMainPageButton onClick={navigateToDashboard}>Return to Applications Dashboard</BackToMainPageButton>
    </Container>
  );
};

export default CheckoutWithPatientSuccess;
