import { Dialog, FormControl, Grid, TextField } from '@mui/material';
import { DropDown } from 'lib/components';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RemoveButton, CancelButton, StyledTextField, FormLabel } from 'pages/desktop/MembershipPlanDetail/components';

interface Props {
  open: boolean;
  handleClose: () => void;
  data: any | null;
  benefitChangeHandler: any;
}

export const AddEditBenefitModal = ({ open, handleClose, data, benefitChangeHandler }: Props) => {
  const [benefitData, setBenefitData] = useState<any>(null);

  useEffect(() => {
    setBenefitData(data);
  }, [data]);

  useEffect(() => {
    if (!open) {
      setBenefitData({});
    } else {
      setBenefitData(data);
    }
  }, [open]);

  const planType = [
    {
      value: 'INCLUDED_SERVICE',
      label: 'Included Service',
    },
    {
      value: 'DISCOUNT',
      label: 'Discount',
    },
    {
      value: 'BONUS',
      label: 'Bonus',
    },
    {
      value: 'GIFT',
      label: 'Gift',
    },
  ];

  const frequencyType = [
    {
      value: 'ONE_TIME',
      label: 'One Time',
    },
    {
      value: 'YEARLY',
      label: 'Yearly',
    },
    {
      value: 'UNLIMITED',
      label: 'Unlimited',
    },
    {
      value: 'MONTHLY',
      label: 'Monthly',
    },
    {
      value: 'WEEKLY',
      label: 'Weekly',
    },
  ];

  const handleBenefitChange = (e: any) => {
    setBenefitData((prevState: any) => {
      return { ...prevState, type: e.value };
    });
  };

  const handleFrequencyChange = (e) => {
    setBenefitData((prevState: any) => {
      return { ...prevState, frequency: e.value };
    });
  };

  const handleQuantityChange = (e) => {
    const value = e?.currentTarget?.value;
    setBenefitData((prevState: any) => {
      return {
        ...prevState,
        quantity: value ? parseInt(value, 10) : value,
      };
    });
  };

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent?.target?.dataset?.inputkey;
    const currentTarget = nativeEvent?.currentTarget;
    const value = currentTarget?.value;

    setBenefitData((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const confirmBenefitUsage = () => {
    benefitChangeHandler(benefitData);
    handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add / Edit Benefit?
        </CherryDialogTitle>
        <SubTitle>Add an included service, discount, bonus, or gift.</SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Benefit Title</FormLabel>
              <StyledTextField
                size="small"
                type="text"
                placeholder="Benefit Title"
                inputProps={{
                  'data-inputkey': 'name',
                }}
                id="plan-benefit-title"
                data-testid="plan-benefit-title"
                onChange={handleChange}
                value={benefitData?.name}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Benefit Type</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={planType}
                  placeholder="Benefit Type"
                  placeholderColor={'#DADADA'}
                  onChange={handleBenefitChange}
                  hoveredMode={false}
                  defaultValue={benefitData?.type}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Frequency</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={frequencyType}
                  placeholder="Frequency"
                  placeholderColor={'#DADADA'}
                  onChange={handleFrequencyChange}
                  hoveredMode={false}
                  defaultValue={benefitData?.frequency}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Quantity (corresponds to frequency)</FormLabel>
              <StyledTextField
                size="small"
                type="text"
                placeholder="Quantity (corresponds to frequency)"
                inputProps={{
                  'data-inputkey': 'quantity',
                }}
                id="plan-quantity"
                data-testid="plan-quantity"
                onChange={handleQuantityChange}
                value={benefitData?.quantity}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <CancelButton disabled={false} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} xs={6}>
            <RemoveButton disabled={false} onClick={confirmBenefitUsage}>
              Save
            </RemoveButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};
