import { Heading } from '@frontend/cherry-library';
import { FixedHighlight, MenuContainer, MenuItem, MenuWrapper, StickyMenu } from '../styles';
import { useCallback } from 'react';
import { MenuProps, ItemProps } from '../utils';

const SettingsMenuItem = ({ menuId, title, activePage, redirect, showFixedHighlight }: ItemProps) => {
  const handleClick = useCallback(() => {
    redirect(menuId);
  }, [menuId, redirect]);

  return (
    <MenuItem id={`settings-menu-item-${menuId}`} key={menuId} onClick={handleClick} isActive={menuId === activePage}>
      <FixedHighlight show={menuId === activePage && showFixedHighlight} />
      <span>{title}</span>
    </MenuItem>
  );
};

const SettingsMenu = ({ activePage, redirect, pages, showFixedHighlight }: MenuProps) => {
  return (
    <MenuContainer>
      <Heading text="Settings" level="2" />
      <StickyMenu>
        <MenuWrapper>
          <SettingsMenuItem
            redirect={redirect}
            activePage={activePage}
            title="All Settings"
            menuId="all"
            showFixedHighlight={showFixedHighlight}
          />
          {pages?.map((page) => {
            const subPagesVisible = page?.subPages?.some((subPage) => subPage?.isAvailable);
            return (
              subPagesVisible && (
                <SettingsMenuItem
                  redirect={redirect}
                  activePage={activePage}
                  key={page?.title}
                  title={page?.title}
                  menuId={page?.id}
                  showFixedHighlight={showFixedHighlight}
                />
              )
            );
          })}
        </MenuWrapper>
      </StickyMenu>
    </MenuContainer>
  );
};

export default SettingsMenu;
