import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashCard, DownloadIcon } from 'lib/components';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import { Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

export const HowToGuides = ({ user, organization, merchants }) => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();

  const guides = [
    {
      id: 1,
      title: 'Quick tips for talking about Cherry',
      fileName: 'Cherry+Quick+Talking+Points.pdf',
    },
    {
      id: 2,
      title: 'How to check out a patient',
      fileName: 'Practice+Portal+-+Patient+Checkout.pdf',
    },
    {
      id: 3,
      title: 'How to use the Payment Estimator',
      fileName: 'How+to+use+the+Payment+Estimator.pdf',
    },
    {
      id: 4,
      title: 'Help your patients request an increased approval amount',
      fileName: 'Requesting+A+Higher+Approval+Amount.pdf',
    },
    {
      id: 5,
      title: 'Add and manage multiple users in your Cherry Practice Portal',
      fileName: 'Practice+Portal+-+Adding+Users.pdf',
    },
    {
      id: 6,
      title: 'How to add your Cherry Link to an Instagram story',
      fileName: 'How+to+Add+Cherry+Link+to+Instagram.pdf',
    },
    {
      id: 7,
      title: 'Apply & Checkout with Patient Compliance Guidelines',
      fileName: 'With+Patient+Suite+Guidelines.pdf',
    },
  ];

  const handleGuideClick = (item) => {
    const { fileName } = item;
    const pdfUrl = `https://files.withcherry.com/partner/${fileName}`;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();

    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Clicked Training How-To Guide', {
      application: applicationName,
      organizationId: organization.id,
      merchantId: idMerchant,
      fileName,
    });
    trackEvent({
      action: TrainingAnalyticsEventNames.GUIDE_CLICK,
      label: pdfUrl,
    });
  };

  return (
    <DashCard>
      <Row fontSize={'14px'}>Browse our library of How-To Guides to learn more on various topics.</Row>
      <GuidesContainer>
        {guides.map((item) => {
          return (
            // tslint:disable-next-line: jsx-no-lambda
            <Guide key={item.id} onClick={() => handleGuideClick(item)}>
              <FontAwesomeIcon icon={faFilePdf} color="#0E202F" size="1x" />
              {item.title}
              <DownloadIcon color={'#00c37d'} />
            </Guide>
          );
        })}
      </GuidesContainer>
    </DashCard>
  );
};

const GuidesContainer = styled.div`
  margin-top: 16px;
`;

const Guide = styled.div`
  padding: 16px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #dadada;
  box-shadow: 0px 0.75129px 1.12694px 0px rgba(0, 0, 0, 0.04), 0px 6px 9px 0px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    width: 24px !important;
    height: 24px;
  }

  svg:first-of-type {
    margin-right: 16px;
  }

  svg:last-of-type {
    margin-left: auto;
  }
`;
