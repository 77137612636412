import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { DetailChartContainer, FixedDecimalFormat, ReportingPageLayout } from 'lib/components';
import { PieChart, StackBarChart } from 'lib/components/Charts';
import { DAILY_FREQUENCY_ENUM } from 'lib/constants';
import { FETCH_PATIENT_BREAKDOWN } from 'lib/graphql/queries';
import { ReportingAnalyticsEventNames, useAnalytics, useOrganizationGroupTreeRadioGroup } from 'lib/hooks';
import { useDownloadChartData } from 'lib/hooks/useDownloadChartData';
import useStore from 'lib/hooks/useStore';
import { DropdownOption } from 'lib/types';
import styled from 'styled-components';

export const PatientBreakdown = () => {
  const client = useApolloClient();
  const { organization, selectedOrganizationGroup } = useStore();
  const { trackEvent } = useAnalytics();
  const { downloadChartCSV } = useDownloadChartData();
  const [totalRepeatPatient, setTotalRepeatPatient] = useState<any>();
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>('All');
  const [totalRepeatPatientTimeBreak, setTotalRepeatPatientTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(
    DAILY_FREQUENCY_ENUM.DAILY,
  );
  const [patientDistribution, setPatientDistribution] = useState<any>();
  const [paymentPlan, setPaymentPlan] = useState<any>();
  const { findNodeAndReturnArrays, organizationGroupTree } = useOrganizationGroupTreeRadioGroup();
  const [location, setLocation] = useState<DropdownOption>({ value: 'selectAll', label: 'All Accounts' });
  const [organizationIds, setOrganizationIds] = useState<string | null>(`${organization?.id}`);

  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().endOf('day').toISOString(),
  });

  useEffect(() => {
    fetchSummaryChartData();
  }, [filterSelectedDateValue, totalRepeatPatientTimeBreak, selectedMerchant, organizationIds]);

  const fetchSummaryChartData = async () => {
    const { data } = await client.query({
      query: FETCH_PATIENT_BREAKDOWN,
      variables: {
        totalRepeatInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: totalRepeatPatientTimeBreak,
        },
        repeatDistributionInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
        },
      },
    });
    setTotalRepeatPatient(data?.getTotalRepeatTransactions);
    setPatientDistribution(data?.getRepeatTransactionDistribution?.repeatPaymentDistributions);
    setPaymentPlan(data?.getRepeatTransactionDistribution?.paymentPlanDistributions);
  };

  const onTotalRepeatPatientTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setTotalRepeatPatientTimeBreak(timeBreak);
  };

  const onTotalRepeatPatientDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.REPEAT_PATIENTS_DOWNLOAD_CLICK,
    });
    downloadChartCSV(
      totalRepeatPatient?.contents,
      ['name', 'repeatedTransactionCount', 'nonRepeatedTransactionCount'],
      'Date,Repeat Patients Transactions,New Patients Transactions',
    );
  };

  const onPaymentPlanDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.PAYMENT_PLAN_DOWNLOAD,
    });
    downloadChartCSV(paymentPlan?.contents, ['name', 'data'], 'Date,Data');
  };
  const onRepeatPationDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.REPEAT_PATIENTS_DISTRIBUTION_DOWNLOAD_CLICK,
    });
    downloadChartCSV(patientDistribution?.contents, ['name', 'data'], 'Date,Data');
  };

  const onSelectedDateChange = (selectedDate: any) => {
    setFilterSelectedDateValue(selectedDate);
  };

  const organizationGroupSelectionHandler = (data) => {
    setLocation(data);
    if (data?.value === 'selectAll') {
      setOrganizationIds(String(organization?.id));
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setLocation({ value: 'selectAll', label: 'All Accounts' });
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    } else {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree[0],
        data.value,
        data.type,
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  };

  return (
    <ReportingPageLayout
      title={'Patient Breakdown'}
      onDateRangeChange={onSelectedDateChange}
      onLocationChange={setSelectedMerchant}
      organizationGroupSelectionHandler={organizationGroupSelectionHandler}
      selectedOrganizationGroup={location}
      selectedMerchant={selectedMerchant as string}
    >
      <DetailChartContainer
        id={'patient-breakdown'}
        mainStatistic={{
          header: 'Total Repeat Transactions',
          description: <FixedDecimalFormat amount={totalRepeatPatient?.totalRepeatTransactionsAmount} prefix="$" />,
        }}
        onTimeBreakSelected={onTotalRepeatPatientTimeBreakSelected}
        onDownloadClick={onTotalRepeatPatientDownloadClicked}
        statistics={[
          {
            header: 'Repeat Rate',
            description: <FixedDecimalFormat amount={totalRepeatPatient?.repeatRate * 100} suffix="%" />,
          },
          {
            header: 'Total Repeat Transactions',
            description: totalRepeatPatient?.totalRepeatTransactions,
          },
          {
            header: 'Avg Repeat Transaction Amount',
            description: <FixedDecimalFormat amount={totalRepeatPatient?.avgRepeatTransactionAmount} prefix="$" />,
          },
        ]}
      >
        <StackBarChart
          data={totalRepeatPatient?.contents}
          height={480}
          firstDataLabel={'repeatedTransactionCount'}
          secondDataLabel={'nonRepeatedTransactionCount'}
          firstToolTipLabel={'Repeat Patients Transactions'}
          secondToolTipLabel={'New Patients Transactions'}
          chartType="detail"
          labelTitle="Transactions"
        />
      </DetailChartContainer>
      <PieChartContainer>
        <DetailChartContainer
          chartTitle="Repeat Patient Distribution"
          id={'repeat-patient-distribution'}
          onDownloadClick={onRepeatPationDownloadClicked}
        >
          <PieChart data={patientDistribution?.contents} height={480} />
        </DetailChartContainer>
        <DetailChartContainer
          chartTitle="Payment Plan Distribution"
          id={'repeat-patient-distribution'}
          onDownloadClick={onPaymentPlanDownloadClicked}
        >
          <PieChart data={paymentPlan?.contents} height={480} />
        </DetailChartContainer>
      </PieChartContainer>
    </ReportingPageLayout>
  );
};

const PieChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
