import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { BaseSyntheticEvent, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { DropDown, InputWithIcon } from 'lib/components';
import { MIN_ALLOWED_REFUND_AMOUNT } from 'lib/constants';
import { CREATE_LOAN } from 'lib/graphql/mutations';
import { useSentry } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { browserIsMobile, clearMaskFormat, emailPattern, MIN_AMOUNT, phonePattern } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';
import { BackButton, ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import { BorderContainer } from 'pages/desktop/Transactions/CommonStyles';

const ADDITIONAL_NOTE_MAX_LENGTH = 1200;

export interface FormProps {
  selectedLocation?: { label: string; value: string };
  patientName: string;
  patientSurname: string;
  patientPhone: string | null;
  patientEmail: string | null;
  additionalNotes: string | null;
  treatmentCoordinator: string | null;
  treatmentType: string | null;
  treatmentDate: string | null;
  amount: number;
  loanId: string | number;
}

interface InitialFormData {
  selectedLocation?: { label: string; value: string };
  patientName: string;
  patientSurname: string;
  patientPhone: string | null;
  patientEmail: string | null;
  additionalNotes: string | null;
  treatmentCoordinator: string | null;
  treatmentType: string | null;
  treatmentDate: string | null;
  amount: number;
}

interface Props {
  onClose: () => void;
  proceedToPreviewStep: (values: FormProps) => void;
  initialFormData: InitialFormData | null;
  balanceAvailable: number;
  selectedFirstName: string;
  selectedLastName: string;
  selectedPhone: string;
  selectedApplicationId: string;
}

interface Location {
  label: string;
  value: string;
}

interface TextAreaProps {
  error: boolean;
}

export const GenerateTreatmentPlanForm = ({
  onClose,
  proceedToPreviewStep,
  initialFormData,
  balanceAvailable,
  selectedFirstName,
  selectedLastName,
  selectedPhone,
  selectedApplicationId,
}: Props) => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const [createLoan, { loading: createLoanLoading }] = useMutation(CREATE_LOAN);
  const { merchants, user } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const minDate = dayjs().subtract(1, 'day');
  const initialLocation =
    merchants?.length === 1
      ? {
          label: merchants?.[0]?.name,
          value: merchants?.[0]?.id,
          emailLabel: `${merchants?.[0]?.name} - ${merchants?.[0]?.address?.city?.name} `,
        }
      : initialFormData?.selectedLocation;

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(initialLocation);
  const [patientName, setPatientName] = useState<string>(initialFormData?.patientName || selectedFirstName || '');
  const [patientSurname, setPatientSurname] = useState<string>(
    initialFormData?.patientSurname || selectedLastName || '',
  );
  const [patientPhone, setPatientPhone] = useState<string>(initialFormData?.patientPhone || selectedPhone || '');
  const [patientEmail, setPatientEmail] = useState<string>(initialFormData?.patientEmail || '');
  const [additionalNotes, setAdditionalNotes] = useState<string>(initialFormData?.additionalNotes || '');
  const [treatmentCoordinator, setTreatmentCoordinator] = useState<string>(initialFormData?.treatmentCoordinator || '');
  const [treatmentType, setTreatmentType] = useState<string>(initialFormData?.treatmentType || '');
  const [treatmentDate, setTreatmentDate] = useState<string>(initialFormData?.treatmentDate || '');

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);

  const additionalNoteError = additionalNotes?.length === ADDITIONAL_NOTE_MAX_LENGTH;

  const [amount, setAmount] = useState<number | null | undefined>(initialFormData?.amount);
  const [validPurchaseAmountRange, setValidPurchaseAmountRange] = useState<boolean>(true);

  const isSubmitDisabled = useMemo(() => !(selectedLocation && patientName && patientPhone), [
    selectedLocation,
    patientName,
    patientPhone,
  ]);

  const locationOptions = merchants?.map((location) => ({
    value: location?.id,
    label: `<b><span>${location?.name}</span> </b><br/>  ${location?.address?.address1} - ${location?.address.city?.name} `,
    emailLabel: `${location?.name} - ${location?.address.city?.name} `,
  }));

  const preventNumericInput = (input: string) => input?.replace(/[0-9]/g, '');

  const handleTooltipClose = () => {
    setPopoverOpen(false);
  };

  const handlePatientNameChange = (e: BaseSyntheticEvent) => {
    setPatientName(preventNumericInput(e.target.value));
  };

  const handlePatientSurnameChange = (e: BaseSyntheticEvent) => {
    setPatientSurname(preventNumericInput(e.target.value));
  };

  const handleTooltipOpen = () => {
    setPopoverOpen(true);
  };

  const handlePatientPhoneChange = (event) => {
    const rawPhoneValue = event.target.value;
    const parsedPhoneInput = clearMaskFormat(rawPhoneValue);

    setIsPhoneValid(!parsedPhoneInput || phonePattern?.test(rawPhoneValue));
    setPatientPhone(parsedPhoneInput);
  };

  const handlePatientEmailChange = (nativeEvent: BaseSyntheticEvent) => {
    const currentTarget = nativeEvent?.currentTarget;
    const value = currentTarget?.value;

    setIsEmailValid(!value || emailPattern?.test(value));
    setPatientEmail(value);
  };

  const handleAdditionalNotesChange = (e: BaseSyntheticEvent) => {
    setAdditionalNotes(e?.target?.value);
  };

  const handleTreatmentCoordinatorChange = (e: BaseSyntheticEvent) => {
    setTreatmentCoordinator(preventNumericInput(e?.target?.value));
  };

  const handleTreatmentTypeChange = (e: BaseSyntheticEvent) => {
    setTreatmentType(preventNumericInput(e?.target?.value));
  };

  const handleTreatmentDateChange = (value) => {
    if (!value) {
      setIsDateValid(true);
      return;
    }

    const parsedValue = dayjs(value);
    if (parsedValue.isValid() && (parsedValue.isSame(minDate) || parsedValue.isAfter(minDate))) {
      setIsDateValid(true);
      setTreatmentDate(parsedValue.format('YYYY-MM-DD'));
    } else {
      setIsDateValid(false);
    }
  };

  const [isFocusPicker, setIsFocusPicker] = useState(false);
  const handleTreatmentDateFocus = () => {
    setIsFocusPicker(true);
  };

  const handleTreatmentDateBlur = () => {
    setIsFocusPicker(false);
  };

  const handleApprovalBalanceChange = (e) => {
    e?.preventDefault();
  };

  const onPurchaseAmountChange = (nativeEvent) => {
    try {
      const value: string = browserIsMobile() ? CurrencyUtil.rawValue(nativeEvent) : nativeEvent;
      const isPurchaseAmountValid: boolean = CurrencyUtil.valueValidator(value, MIN_AMOUNT, balanceAvailable);
      if (isPurchaseAmountValid) {
        setValidPurchaseAmountRange(true);
        value && setAmount(parseFloat(value));
      } else {
        setValidPurchaseAmountRange(false);
      }
    } catch (error) {
      captureException(error, { message: 'Amount input converted wrongly' });
    }
  };

  const onPurchaseAmountBlurred = (nativeEvent) => {
    const value = nativeEvent?.toString();
    const isAmountValid = CurrencyUtil.valueValidator(value, MIN_ALLOWED_REFUND_AMOUNT, balanceAvailable);
    if (isAmountValid) setAmount(parseFloat(value));
  };

  const saveAndContinue = async () => {
    try {
      const { data } = await createLoan({
        variables: {
          input: {
            merchantId: selectedLocation?.value,
            applicationId: selectedApplicationId,
            purchaseAmount: Number(amount),
            selfCheckout: true,
            channel: 'TREATMENT_PLAN',
          },
        },
      });

      if (data?.createLoan?.data?.id) {
        trackSegmentEvent('Treatment Plan Created', {
          application: applicationName,
          component: 'Treatment Plan',
          userId: `u-${user?.id}`,
          merchantId: selectedLocation?.value,
          purchaseAmount: Number(amount),
          applicationId: selectedApplicationId,
          loanId: data?.createLoan?.data?.id,
        });
      } else {
        alert.error(`${data?.createLoan?.message}`);
      }

      proceedToPreviewStep({
        selectedLocation,
        patientName,
        patientSurname,
        patientPhone,
        patientEmail,
        additionalNotes,
        treatmentCoordinator,
        treatmentType,
        treatmentDate,
        amount: amount ?? 0,
        loanId: data?.createLoan?.data?.id,
      });
    } catch (error) {
      alert.error('Sorry an error occurred');
      captureException(error, { message: 'Create Loan Error', page: 'Treatment Plan Form' });
      onClose();
    }
  };

  return (
    <>
      <DialogContent>
        <Content>
          Print a customized treatment plan that your patient can take home. <br />
          Add information to personalize the estimate before sharing.
        </Content>

        <InputContainer>
          <BorderContainer>
            <InputWithIcon
              disabled={true}
              className="cohere-block"
              type="text"
              onChange={handlePatientNameChange}
              value={patientName}
              textAlign={'left'}
              border={false}
              placeholder="Patient Name"
              paddingLeft={'13px'}
            />
          </BorderContainer>
          <BorderContainer>
            <InputWithIcon
              className="cohere-block"
              type="text"
              onChange={handlePatientSurnameChange}
              value={patientSurname}
              disabled={!!patientSurname}
              textAlign={'left'}
              border={false}
              placeholder="Patient Surname"
              paddingLeft={'13px'}
            />
          </BorderContainer>
        </InputContainer>

        <InputContainer>
          <BorderContainer hasError={!isPhoneValid}>
            <CustomInputMask
              disabled={true}
              data-testid="application-phone-input"
              mask="(999) 999-9999"
              value={patientPhone}
              onChange={handlePatientPhoneChange}
            >
              {(inputProps) => (
                <PhoneInput
                  disabled={true}
                  {...inputProps}
                  inputProps={{ ...inputProps, inputMode: 'numeric' }}
                  placeholder="Phone (optional)"
                  type="tel"
                />
              )}
            </CustomInputMask>
          </BorderContainer>
          <BorderContainer hasError={!isEmailValid}>
            <InputWithIcon
              className="cohore-block"
              type="email"
              onChange={handlePatientEmailChange}
              value={patientEmail}
              textAlign={'left'}
              border={false}
              placeholder="Email (optional)"
              paddingLeft={'13px'}
            />
          </BorderContainer>
        </InputContainer>

        <DropDownBorder>
          <DropDown
            ignoreInitialFirstItem={true}
            placeholder="Please select a location"
            options={locationOptions}
            defaultValue={initialLocation?.value}
            onChange={setSelectedLocation}
            hoveredMode={false}
          />
        </DropDownBorder>

        <GrayContainer>
          <FormLabel className={'valid'}>Approval Balance</FormLabel>
          <BalanceContainer>
            <AbsoluteIconWrapper className={'disabled'}>$</AbsoluteIconWrapper>
            <CustomCurrencyInput
              disabled={true}
              className={'valid'}
              onChange={handleApprovalBalanceChange}
              allowNegativeValue={false}
              value={balanceAvailable}
            />
            <PopoverWrapper onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
              <img src={'/info_circle_outline.svg'} alt="info" />
              {popoverOpen && (
                <Popover>
                  <DownCircle />
                  <PopoverContent>
                    <PopoverText>
                      This is the unique amount your patient was approved for in their application.
                    </PopoverText>
                  </PopoverContent>
                </Popover>
              )}
            </PopoverWrapper>
          </BalanceContainer>
        </GrayContainer>

        <GrayContainer>
          <FormLabel className={validPurchaseAmountRange ? 'valid' : 'error'}>Total Treatment Cost</FormLabel>
          <BalanceContainer>
            <AbsoluteIconWrapper className={'valid'}>$</AbsoluteIconWrapper>
            <CustomCurrencyInput
              className={validPurchaseAmountRange ? 'valid' : 'error'}
              onValueChange={onPurchaseAmountChange}
              formatValueOnBlur={true}
              onBlur={onPurchaseAmountBlurred}
              allowNegativeValue={false}
            />
          </BalanceContainer>
          <Gap />
        </GrayContainer>

        <Gap />
        <FieldsContainer>
          <TextAreaLabel>Add notes to the treatment estimate (optional)</TextAreaLabel>
          <TextArea
            rows={10}
            className="cohere-block"
            error={additionalNoteError}
            onChange={handleAdditionalNotesChange}
            value={additionalNotes}
            maxLength={ADDITIONAL_NOTE_MAX_LENGTH}
          />
          <TextAreaCounterContainer>
            <CounterText error={additionalNoteError}>
              {additionalNoteError && 'max characters '}
              {additionalNotes?.length}/{ADDITIONAL_NOTE_MAX_LENGTH.toString()}
            </CounterText>
          </TextAreaCounterContainer>
          <Gap />
          <BorderContainer>
            <InputWithIcon
              className="cohere-block"
              type="text"
              onChange={handleTreatmentCoordinatorChange}
              value={treatmentCoordinator}
              textAlign={'left'}
              border={false}
              placeholder="Treatment Coordinator (optional)"
              paddingLeft={'13px'}
            />
          </BorderContainer>
          <Gap />
          <BorderContainer>
            <InputWithIcon
              className="cohere-block"
              type="text"
              onChange={handleTreatmentTypeChange}
              value={treatmentType}
              textAlign={'left'}
              border={false}
              placeholder="Treatment Type (optional)"
              paddingLeft={'13px'}
            />
          </BorderContainer>
          <Gap />
          <StyledBorderContainer className="datepicker--treatment-plan" hasError={!isDateValid}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={!isDateValid || treatmentDate || isFocusPicker ? '' : 'Estimated Treatment Date (optional)'}
                disablePast={true}
                minDate={minDate}
                value={treatmentDate}
                onChange={handleTreatmentDateChange}
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={(params: any) => (
                  <StyledDatePicker
                    onBlur={handleTreatmentDateBlur}
                    onFocus={handleTreatmentDateFocus}
                    {...params}
                    readOnly={true}
                  />
                )}
              />
            </LocalizationProvider>
          </StyledBorderContainer>
          <Gap />
        </FieldsContainer>
      </DialogContent>
      <ButtonContainer>
        <BackButton onClick={onClose}>Cancel</BackButton>
        <ButtonGap />
        <ContinueButton
          disabled={
            isSubmitDisabled ||
            (patientEmail && !isEmailValid) ||
            !isDateValid ||
            !isPhoneValid ||
            !amount ||
            (amount && amount < MIN_AMOUNT) ||
            !validPurchaseAmountRange ||
            createLoanLoading
          }
          loading={createLoanLoading}
          onClick={saveAndContinue}
        >
          Create Plan
        </ContinueButton>
      </ButtonContainer>
    </>
  );
};

const StyledBorderContainer = styled(BorderContainer)`
  font-family: 'Open Sans', sans-serif;
  padding: 0 16px;

  & > .MuiInputBase-root {
    font-family: 'Open Sans', sans-serif !important;

    ::placeholder {
      font-family: 'Open Sans', sans-serif !important;
      color: red;
    }
  }

  & > .MuiInputBase-input {
    font-family: 'Open Sans', sans-serif !important;

    ::placeholder {
      font-family: 'Open Sans', sans-serif !important;
    }
  }

  & > .MuiFormControl-root {
    width: 100%;
    padding: 0;
  }

  & > .MuiTextField-root {
    width: 99%;
    padding: 0;

    label {
      top: -16px;
      color: #837f7f;
      font-size: 15px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
    }
  }

  & > .MuiInput-underline {
    :before,
    :after {
      border: none !important;
      border-bottom: none !important;
    }
  }
`;

const StyledDatePicker = styled(TextField)`
  .MuiInputBase-root {
    font-family: 'Open Sans', sans-serif !important;
  }

  & > .Mui-selected {
    background: red !important;
  }

  label + .MuiInput-formControl {
    padding: 0 0;
    margin-top: 0;
  }

  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const CustomInputMask = styled(InputMask)`
  padding-left: 50px;
  font-size: 14px;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
`;

const PhoneInput = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10.5px 14px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
  }
`;

const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main.textColor};
  text-align: left;
  margin-bottom: 5px;
`;

const PopoverWrapper = styled.div`
  position: relative;
  margin-top: 12px;
  margin-left: 8px;
`;

const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
  display: flex;
  justify-content: center;
`;

const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

const Popover = styled.div<{ secondary?: boolean; ternary?: boolean; left?: number }>`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: 210px;
  z-index: 10;
  left: -221px;
  top: -27px;
`;

const DownCircle = styled.div<{ secondary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #00c37d;
  position: absolute;
  right: calc(100% - 221px);
  bottom: calc(40%);
  transform: rotate(90deg);
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-top: 12.5px;
  margin-left: 14px;
  font-size: 14px;

  &.error {
    color: #ff0000 !important;
  }

  &.disabled {
    color: #879097 !important;
  }
`;

const CustomCurrencyInput = styled(CurrencyInput)`
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dadada;
  height: 40px;
  padding-left: 24px;
  font-size: 14px;
  color: #0e202f;
  outline: none;
  width: 96%;
  font-family: 'Open Sans', sans-serif;

  &.error {
    border: 1px solid #ff0000 !important;
  }

  &:disabled {
    color: #879097;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;

  > &.error {
    color: #ff0000 !important;
    border: 1px solid #ff0000 !important;
  }
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

const Content = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0e202f;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

export const DropDownBorder = styled.div`
  width: 100%;
  height: 50px;
  margin: 0 0 16px;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 3px;

  li {
    font-size: 14px;
  }

  svg {
    color: #00c37c;
  }
`;

const GrayContainer = styled.div`
  background: #f2f4f5;
  width: calc(100% - 32px);
  padding: 16px 16px 1.5px;
  position: relative;
  gap: 8px;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans', sans-serif;
`;

const FieldsContainer = styled.div`
  width: 100%;
  margin-top: 0;
`;

const Gap = styled.div`
  margin-top: 16px;
`;

export const TextArea = styled.textarea<TextAreaProps>`
  resize: vertical;
  width: 100%;
  min-height: 70px;
  height: 70px;
  border-radius: 2px;
  border: 1px solid ${(props) => (props.error ? props.theme.main.red : '#dadada')};
  padding: 10px 15px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
`;

const TextAreaLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
`;

const TextAreaCounterContainer = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;

const CounterText = styled.span<TextAreaProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.error ? props.theme.main.red : props.theme.main.black)};
`;
