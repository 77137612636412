import { gql } from '@apollo/client/core';

export const GET_AUTH_INIT = gql`
  mutation getUserAuthInit($input: AuthInitInput!) {
    getUserAuthInit(input: $input) {
      code
      success
      message
      token
      refreshToken
      user {
        email
        firstName
        id
        lastName
        phone
        roles
      }
      merchant {
        id
        bankingInfo {
          accountName
          accountNumber
        }
        name
        phone
        address {
          street
        }
      }
      organization {
        active
        flow
        id
        isDemo
        name
        outcomeThreshold
        roles
        slug
        website
        phone
      }
      organizationGroup {
        id
        name
        organizations {
          id
          name
          slug
          merchants {
            id
            name
            address {
              address1
              zip
              city {
                name
                county
                idAddressCity
                state {
                  country {
                    name
                    code
                    idAddressCountry
                  }
                  name
                  code
                  idAddressCountry
                }
              }
              idAddress
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BORROWER = gql`
  mutation updateBorrower($input: UpdateBorrowerInput) {
    updateBorrower(input: $input) {
      success
      message
      error
      data {
        id
        firstName
        lastName
        phone
        email
        address {
          city
          id
          createdAt
          updatedAt
          status
          street
          zip
          state {
            code
            name
          }
        }
        dob
        ssn
        idChecked
        phoneStatus
        balanceUsed
      }
    }
  }
`;

export const POST_APPLICATION = gql`
  mutation postApplication($input: PostApplicationRequest) {
    postApplication(input: $input) {
      balanceAvailable
      borrowerId
      demo
      expireAt
      id
      ivAllow {
        paystub
      }
      maxEligible
      organizationId
      partner
      purchaseAmount
      selfCheckout
      status
      validity
    }
  }
`;

export const POST_APPLICATION_FLOW = gql`
  mutation postApplicationFlow($input: ApplicationInput) {
    postApplicationFlow(input: $input) {
      success
    }
  }
`;

export const SEND_MAGIC_LINK = gql`
  mutation sendMagicLink($input: MagicLinkRequest) {
    sendMagicLink(input: $input) {
      success
      message
      code
    }
  }
`;

export const START_OTP_FLOW = gql`
  mutation startOtpFlow($input: StartOtpFlowInput) {
    startOtpFlow(input: $input) {
      success
      message
      error
    }
  }
`;

export const SUBMIT_OTP = gql`
  mutation submitOtp($input: SubmitOtpInput) {
    submitOtp(input: $input) {
      success
      message
      error
      authorization {
        token
      }
      borrower {
        id
      }
    }
  }
`;

export const PATCH_BORROWER_IDENTITY_SSN = gql`
  mutation patchBorrowerIdentitySsn($input: PatchBorrowerIdentitySsnInput) {
    patchBorrowerIdentitySsn(input: $input) {
      success
    }
  }
`;

export const PATCH_LOAN_DETAIL = gql`
  mutation patchLoanDetail($input: PatchLoanRequest) {
    patchLoan(input: $input) {
      id
    }
  }
`;

export const SAVE_DOWN_PAYMENT = gql`
  mutation saveDownPayment($input: SaveDownPaymentInput) {
    saveDownPayment(input: $input) {
      applicationId
      menuId
      moduleString
      term
      promoTerm
      apr
      promoApr
      fallbackApr
      maxEligible
      minEligible
      creditLineMaxEligible
      mdf
      promoMdf
      downPaymentType
      downPayment
      chosenDownPaymentAmount
      periodLength
      tierId
      parentId
      id
      createdAt
      status
    }
  }
`;

export const PATCH_MERCHANT_PREFERENCE = gql`
  mutation patchMerchantPreference($input: PatchMerchantPreferenceRequest) {
    patchMerchantPreference(input: $input) {
      id
      merchantId
    }
  }
`;
