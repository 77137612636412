import { Button as CherryButton } from '@frontend/cherry-library';
import Popper from 'lib/components/Popper';
import { Row } from 'lib/styles';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

interface MarketingSupplyItemProps {
  id: number;
  image: string;
  title: string;
  description: string;
  popularItem?: boolean;
  orderButtonText: string;
  onOrderButtonClick: (id: number) => void;
  disabled?: boolean;
  organizationMaterialValidDate?: string;
}

const MarketingSupplyItem = ({
  id,
  image,
  title,
  description,
  popularItem = false,
  orderButtonText,
  onOrderButtonClick,
  disabled = false,
  organizationMaterialValidDate,
}: MarketingSupplyItemProps) => {
  const orderButtonRef = useRef<HTMLDivElement>(null);

  const [showDateTooltip, setShowDateTooltip] = useState<boolean>(false);

  const handleOrderButtonClick = useCallback(() => {
    onOrderButtonClick(id);
  }, [id, onOrderButtonClick]);

  const handleOrderButtonMouseEnter = useCallback(() => {
    if (disabled && !showDateTooltip) {
      setShowDateTooltip(true);
    }
  }, [disabled, showDateTooltip]);

  const handleOrderButtonMouseLeave = useCallback(() => {
    if (disabled && showDateTooltip) {
      setShowDateTooltip(false);
    }
  }, [disabled, showDateTooltip]);

  return (
    <ItemContainer>
      <Image src={image} />
      <Content>
        {popularItem ? <PopularItemTag>Popular Item</PopularItemTag> : null}
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
      <OrderButtonContainer
        ref={orderButtonRef}
        onMouseEnter={handleOrderButtonMouseEnter}
        onMouseLeave={handleOrderButtonMouseLeave}
      >
        <CherryButton
          variant="primary"
          onClick={handleOrderButtonClick}
          disabled={disabled}
          data-testid="order-marketing-supply-button"
        >
          {orderButtonText}
        </CherryButton>
      </OrderButtonContainer>
      {orderButtonRef?.current ? (
        <Popper anchorEl={orderButtonRef?.current} id="" open={showDateTooltip} placement="top-start">
          This is included in the kit we send to new practices. You can re-order replacement materials starting on{' '}
          {organizationMaterialValidDate}
        </Popper>
      ) : null}
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 80px;
`;

const PopularItemTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  width: 73px;
  background: ${(props) => props.theme.main.softMint};
  border-radius: 16px;
  text-align: center;
  font-size: 8px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.green};
  margin-bottom: 4px;
`;

const Title = styled(Row)`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const Description = styled(Row)`
  font-size: 14px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const OrderButtonContainer = styled.div``;

export default MarketingSupplyItem;
