import { ArrowRightIcon, ButtonPrimary, DashCard } from 'lib/components';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

export const SocialMediaTemplates = ({ socialMediaItems }) => {
  const { trackEvent } = useAnalytics();
  const onEmailButtonClicked = () => {
    window.open(socialMediaItems, '_blank');
    trackEvent({
      action: TrainingAnalyticsEventNames.CTA_BUTTONS,
      label: 'VIEW_TEMPLATES',
    });
  };

  return (
    <DashCard>
      <Row style={{ fontSize: '14px' }}>
        Social media is a fantastic place to let your customers know about the financing options available to them next
        time they visit your business. We've created a library of social media templates ready to share with your
        network.
      </Row>
      <EmailButton endIcon={<ArrowRightIcon />} onClick={onEmailButtonClicked} text={'View Templates'} />
    </DashCard>
  );
};

const EmailButton = styled(ButtonPrimary)`
  margin: 30px 0 0 0 !important;
  max-width: 190px;
`;
