import { Grid } from '@mui/material';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FeatureNames } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import { TierName } from 'lib/utils';

import { Button as CherryButton } from '@frontend/cherry-library';
import { ButtonPrimary, DashCard, DashComponent } from 'lib/components';
import { MainTier } from './MainTier';

enum ApprovalBoostTypes {
  ENABLED = 'ENABLED',
  NOT_ENABLED = 'NOT ENABLED',
}

export const PricingPlan = () => {
  const navigate = useNavigate();
  const { features, organization, organizationTier, merchants } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const selectedPlan: number = organization?.promoPeriod || 0;
  const organizationType =
    organization?.menuCode &&
    (organization?.menuCode?.toLowerCase()?.indexOf('_cs') >= 0 ||
      organization?.menuCode?.toLowerCase()?.indexOf('_inj') >= 0)
      ? 'CS-INJ'
      : 'HL';

  const approvalBoostFaqUrl = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    const approvalBoostFaqLink = 'https://files.withcherry.com/partner/approval-boost-faq.pdf';

    trackSegmentEvent('Downloaded Pricing', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      menuName: organizationType,
    });
    window.open(approvalBoostFaqLink, '_blank');
  };

  const approvalBoostUrl = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    const approvalBoostLink = 'https://files.withcherry.com/partner/approval-boost-pricing.pdf';

    trackSegmentEvent('Downloaded Pricing', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
      menuName: organizationType,
    });
    window.open(approvalBoostLink, '_blank');
  };

  if (selectedPlan === 0) {
    return null;
  }

  const goToHelp = () => {
    navigate(`/help`);
  };

  const cherryAccessStatus = features?.includes(FeatureNames.APPROVAL_BOOST)
    ? ApprovalBoostTypes.ENABLED
    : ApprovalBoostTypes.NOT_ENABLED;

  return (
    <Column margin="0" id="settings-pricing-tier-component">
      <DashComponent dashHeader={'Pricing Tier'} />
      <DashCard>
        <Grid container={true} spacing={3} direction={'row'}>
          {organizationTier?.hasTier && (
            <Grid item={true}>
              <Row fontSize={'14px'} fontWeight={'700'} marginBottom="8px">
                Current Pricing Tier:{' '}
                <ColorText>&nbsp;{TierName(organizationTier?.organizationTier?.tierId)}</ColorText>
              </Row>

              <Row fontSize={'14px'}>
                All new Cherry practices start at our Bronze tier. You have a chance to earn your way to lower practice
                fees if your transaction volume with Cherry meets certain volume thresholds.
              </Row>

              <MainTier organizationTier={organizationTier} />
            </Grid>
          )}

          {cherryAccessStatus === ApprovalBoostTypes.ENABLED ? (
            <>
              {<Border />}
              <Grid item={true}>
                <CherryAccessContainer>
                  <Choice>
                    <Row fontSize={'14px'}>
                      <CherryAccessTitle>
                        Approval Boost:
                        <ColorText color={cherryAccessStatus === ApprovalBoostTypes.ENABLED ? 'green' : 'red'}>
                          &nbsp;{` ${cherryAccessStatus}`}
                        </ColorText>
                      </CherryAccessTitle>
                      <CherryAccessDesc>
                        Approval Boost allows you to approve more patients who may be in worse financial condition or
                        have lower credit scores. These transactions have a higher fee of 14.9%.
                      </CherryAccessDesc>
                      <Row>
                        To disable Approval Boost, contact our&nbsp;
                        <ColorText cursor={true} underline={true} onClick={goToHelp}>
                          Cherry Representative
                        </ColorText>
                        .
                      </Row>

                      <CAButtonContainer>
                        <DownloadButton
                          onClick={approvalBoostFaqUrl}
                          data-testid="button-cherry-choice-faq"
                          text={'Approval Boost FAQ'}
                        />
                        <StyledCherryButton data-testid="button-cherry-pricing" onClick={approvalBoostUrl}>
                          Approval Boost Pricing
                        </StyledCherryButton>
                      </CAButtonContainer>
                    </Row>
                  </Choice>
                </CherryAccessContainer>
              </Grid>
            </>
          ) : null}
        </Grid>
      </DashCard>
    </Column>
  );
};

const DownloadButton = styled(ButtonPrimary)`
  color: #879097 !important;
  border-color: #879097 !important;
  background-color: #ffffff !important;
  margin-right: 16px !important;
  border-radius: 4px !important;
  &:hover,
  &:focus {
    background-color: #879097 !important;
    color: #ffffff !important;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ColorText = styled.span<{ underline?: boolean; cursor?: boolean; color?: string }>`
  color: ${(props) => props.theme.main?.[props?.color || 'green']};
  text-decoration: ${(props) => (props?.underline ? 'underline' : 'none')};
  cursor: ${(props) => (props?.cursor ? 'pointer' : 'default')};
`;

const CherryAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Choice = styled.div`
  display: flex;
  flex: 1;
`;

const CherryAccessTitle = styled.span`
  font-weight: 700;
  margin-bottom: 8px;
`;

const CherryAccessDesc = styled.span`
  margin-bottom: 12px;
`;

const CAButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const StyledCherryButton = styled(CherryButton)`
  width: 200px;
`;
