export const TierName = (tierId: string | undefined) => {
  switch (tierId) {
    case '1':
      return 'Bronze';
    case '2':
      return 'Silver';
    case '3':
      return 'Gold';
    default:
      return '';
  }
};
