import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DashboardIcon,
  DocumentRequestsIcon,
  HelpIcon,
  MarketingIcon,
  MembershipIcon,
  OrganizationGroupStructureIcon,
  PlanBuilderIcon,
  ReferralIcon,
  ReportingIcon,
  SalesIcon,
  SettingsIcon,
  ShopEditorIcon,
  StatementsIcon,
  TrainingIcon,
} from 'lib/components';
import { GeneralAnalyticsEventNames } from 'lib/hooks';
import { DropdownOption } from 'lib/types';
import { StatusFilter } from 'lib/types/Merchant';
import React from 'react';

dayjs.extend(utc);

export const AVAILABLE_URLS = [
  { id: 1, pathname: '/dashboard', level: 'DASHBOARD' },
  { id: 2, pathname: '/statements', level: 'STATEMENTS' },
  { id: 3, pathname: '/transactions', level: 'TRANSACTIONS' },
  {
    id: 4,
    pathname: '/settings',
    level: 'SETTINGS',
    children: [
      { id: 1, pathname: '/settings/account-details', level: 'SETTINGS' },
      { id: 2, pathname: '/settings/pricing-settings', level: 'SETTINGS' },
      { id: 3, pathname: '/settings/user-management', level: 'SETTINGS' },
      { id: 4, pathname: '/settings/bank-settings', level: 'SETTINGS' },
      { id: 5, pathname: '/settings/business-information', level: 'SETTINGS' },
      { id: 6, pathname: '/settings/practice-finder', level: 'SETTINGS' },
      { id: 7, pathname: '/settings/location-management', level: 'SETTINGS' },
      { id: 8, pathname: '/settings/api-keys', level: 'SETTINGS' },
    ],
  },
  { id: 5, pathname: '/training', level: 'TRAINING' },
  {
    id: 6,
    pathname: '/marketing',
    level: 'MARKETING',
    children: [
      { id: 1, pathname: '/marketing/email-templates', level: 'MARKETING' },
      { id: 2, pathname: '/marketing/social-media', level: 'MARKETING' },
      { id: 3, pathname: '/marketing/text-messages', level: 'MARKETING' },
      { id: 4, pathname: '/marketing/web-resources', level: 'MARKETING' },
      { id: 5, pathname: '/marketing/other-resources', level: 'MARKETING' },
    ],
  },
  { id: 7, pathname: '/referral', level: 'REFERRAL' },
  {
    id: 9,
    pathname: '/reporting',
    level: 'REPORTING',
    children: [
      { id: 1, pathname: '/reporting/approvals-applications', level: 'REPORTING' },
      { id: 2, pathname: '/reporting/expired-approvals', level: 'REPORTING' },
      { id: 3, pathname: '/reporting/transactions', level: 'REPORTING' },
      { id: 4, pathname: '/reporting/patient-breakdown', level: 'REPORTING' },
    ],
  },
  { id: 10, pathname: '/help', level: 'REFERRAL' },
  { id: 11, pathname: '/document-requests', level: 'DASHBOARD' },
  { id: 16, pathname: '/apply-with-patient', level: 'DASHBOARD' },
  { id: 17, pathname: '/payment-estimator', level: 'DASHBOARD' },
];

export const MENU_ITEMS = [
  {
    id: 1,
    name: 'Applications',
    pathname: '/dashboard',
    dataEvent: GeneralAnalyticsEventNames.MENU_DASHBOARD,
    segmentEvent: 'PRACTICE_PORTAL.APPLICATIONS_PAGE_LOAD',
    level: 'DASHBOARD',
    icon: (props) => <DashboardIcon {...props} />,
  },

  {
    id: 2,
    name: 'Transactions',
    pathname: '/transactions',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    segmentEvent: 'PRACTICE_PORTAL.TRANSACTIONS_PAGE_LOAD',
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 3,
    name: 'Training',
    pathname: '/training',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRAINING,
    segmentEvent: 'PRACTICE_PORTAL.TRAINING_PAGE_LOAD',
    level: 'TRAINING',
    icon: (props) => <TrainingIcon {...props} />,
  },
  {
    id: 4,
    name: 'Marketing',
    pathname: '/marketing',
    dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING,
    level: 'MARKETING',
    icon: (props) => <MarketingIcon {...props} />,
    children: [
      {
        id: 1,
        name: 'Social Media Assets',
        segmentName: 'Marketing Social Media Assets',
        pathname: '/marketing/social-media',
        level: 'MARKETING',
        dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING_SOCIAL_MEDIA,
        segmentEvent: 'PRACTICE_PORTAL.MARKETING_SOCIAL_PAGE_LOAD',
      },
      {
        id: 2,
        name: 'Email Templates',
        segmentName: 'Marketing Email Templates',
        pathname: '/marketing/email-templates',
        level: 'MARKETING',
        dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING_EMAIL_TEMPLATES,
        segmentEvent: 'PRACTICE_PORTAL.MARKETING_EMAIL_PAGE_LOAD',
      },
      {
        id: 3,
        name: 'Text Messages',
        segmentName: 'Marketing Text Messages',
        pathname: '/marketing/text-messages',
        level: 'MARKETING',
        dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING_TEXT_MESSAGES,
        segmentEvent: 'PRACTICE_PORTAL.MARKETING_TEXT_PAGE_LOAD',
      },
      {
        id: 4,
        name: 'Web Resources',
        segmentName: 'Marketing Web Resources',
        pathname: '/marketing/web-resources',
        level: 'MARKETING',
        dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING_WEB_RESOURCES,
        segmentEvent: 'PRACTICE_PORTAL.MARKETING_WEB_PAGE_LOAD',
      },
      {
        id: 5,
        name: 'Other Resources',
        segmentName: 'Marketing Other Resources',
        pathname: '/marketing/other-resources',
        level: 'MARKETING',
        dataEvent: GeneralAnalyticsEventNames.MENU_MARKETING_OTHER_RESOURCES,
        segmentEvent: 'PRACTICE_PORTAL.MARKETING_OTHER_PAGE_LOAD',
      },
    ],
  },
  {
    id: 5,
    name: 'Referral',
    pathname: '/referral',
    dataEvent: GeneralAnalyticsEventNames.MENU_REFERRAL,
    segmentEvent: 'PRACTICE_PORTAL.REFERRAL_PAGE_LOAD',
    level: 'REFERRAL',
    icon: (props) => <ReferralIcon {...props} />,
  },
  {
    id: 9,
    name: 'Reporting',
    pathname: '/reporting',
    dataEvent: GeneralAnalyticsEventNames.MENU_REPORTING,
    segmentEvent: 'PRACTICE_PORTAL.REPORTING_PAGE_LOAD',
    level: 'REPORTING',
    icon: (props) => <ReportingIcon {...props} />,
  },
  {
    id: 6,
    name: 'Statements',
    pathname: '/statements',
    dataEvent: GeneralAnalyticsEventNames.MENU_STATEMENTS,
    segmentEvent: 'PRACTICE_PORTAL.STATEMENTS_PAGE_LOAD',
    level: 'STATEMENTS',
    icon: (props) => <StatementsIcon {...props} />,
  },
  {
    id: 7,
    name: 'Help & Support',
    pathname: '/help',
    dataEvent: GeneralAnalyticsEventNames.MENU_HELP,
    segmentEvent: 'PRACTICE_PORTAL.HELP_PAGE_LOAD',
    level: 'REFERRAL',
    icon: (props) => <HelpIcon {...props} />,
  },
  {
    id: 22,
    name: 'Document Requests',
    pathname: '/document-requests',
    dataEvent: GeneralAnalyticsEventNames.MENU_SETTINGS,
    segmentEvent: 'PRACTICE_PORTAL.DOC_REQUESTS_PAGE_LOAD',
    level: 'DASHBOARD',
    icon: (props) => <DocumentRequestsIcon {...props} />,
  },
  {
    id: 8,
    name: 'Settings',
    pathname: '/settings',
    dataEvent: GeneralAnalyticsEventNames.MENU_SETTINGS,
    segmentEvent: 'PRACTICE_PORTAL.SETTINGS_PAGE_LOAD',
    level: 'SETTINGS',
    icon: (props) => <SettingsIcon {...props} />,
  },
];

export const MEMBERSHIP_MENU_ITEMS = [
  {
    id: 1,
    name: 'Memberships Home',
    pathname: '/membership/home',
    dataEvent: GeneralAnalyticsEventNames.MENU_MEMBERSHIP,
    level: 'MEMBERSHIP',
    icon: (props) => <MembershipIcon {...props} />,
  },
  {
    id: 2,
    name: 'Plan Builder',
    pathname: '/membership/plan/builder',
    dataEvent: GeneralAnalyticsEventNames.MENU_PLAN_BUILDER,
    level: 'MEMBERSHIP',
    icon: (props) => <PlanBuilderIcon {...props} />,
  },
  {
    id: 3,
    name: 'Settings',
    pathname: '/membership/settings',
    dataEvent: GeneralAnalyticsEventNames.MENU_MEMBERSHIP_SETTINGS,
    level: 'MEMBERSHIP',
    icon: (props) => <SettingsIcon {...props} />,
  },
];

export const SHOP_MENU_ITEMS = [
  // {
  //   id: 1,
  //   name: 'Shop Home',
  //   pathname: '/shop/home',
  //   dataEvent: GeneralAnalyticsEventNames.MENU_SHOP,
  //   level: 'SHOP',
  //   icon: (props) => <MembershipIcon {...props} />,
  // },
  {
    id: 2,
    name: 'Shop Editor',
    pathname: '/shop/editor',
    dataEvent: GeneralAnalyticsEventNames.MENU_SHOP_EDITOR,
    level: 'SHOP',
    icon: (props) => <ShopEditorIcon {...props} />,
  },
  // {
  //   id: 3,
  //   name: 'Shop Orders',
  //   pathname: '/shop/orders',
  //   dataEvent: GeneralAnalyticsEventNames.MENU_SHOP_ORDERS,
  //   level: 'SHOP',
  //   icon: (props) => <ShopOrdersIcon {...props} />,
  // },
  // {
  //   id: 4,
  //   name: 'Shop Emails',
  //   pathname: '/shop/emails',
  //   dataEvent: GeneralAnalyticsEventNames.MENU_SHOP_EMAILS,
  //   level: 'SHOP',
  //   icon: (props) => <ShopEmailsIcon {...props} />,
  // },
  // {
  //   id: 5,
  //   name: 'Settings',
  //   pathname: '/shop/settings',
  //   dataEvent: GeneralAnalyticsEventNames.MENU_SHOP_SETTINGS,
  //   level: 'SHOP',
  //   icon: (props) => <SettingsIcon {...props} />,
  // },
];

export const POINT_OF_SALE_MENU_ITEMS = [
  {
    id: 1,
    name: 'Point of Sale Home',
    pathname: '/point-of-sale/home',
    dataEvent: GeneralAnalyticsEventNames.MENU_POINT_OF_SALE,
    level: 'POINT_OF_SALE',
    icon: (props) => <MembershipIcon {...props} />,
  },
  {
    id: 2,
    name: 'Settings',
    pathname: '/point-of-sale/settings',
    dataEvent: GeneralAnalyticsEventNames.MENU_POINT_OF_SALE_SETTINGS,
    level: 'POINT_OF_SALE',
    icon: (props) => <SettingsIcon {...props} />,
  },
];

export const ADMIN_REPORTING_MENU_ITEMS = [
  {
    id: 1,
    name: 'Reporting',
    pathname: '/reporting',
    dataEvent: GeneralAnalyticsEventNames.MENU_REPORTING,
    level: 'REPORTING',
    icon: (props) => <ReportingIcon {...props} />,
  },
  {
    id: 2,
    name: 'Organization Structure',
    pathname: '/organization-group-structure',
    dataEvent: GeneralAnalyticsEventNames.MENU_ORGANIZATION_GROUP_STRUCTURE,
    level: 'ORGANIZATION_GROUP_STRUCTURE',
    icon: (props) => <OrganizationGroupStructureIcon {...props} />,
  },
  {
    id: 3,
    name: 'Statements',
    pathname: '/statements',
    dataEvent: GeneralAnalyticsEventNames.MENU_STATEMENTS,
    level: 'STATEMENTS',
    icon: (props) => <StatementsIcon {...props} />,
  },
  {
    id: 4,
    name: 'Help & Support',
    pathname: '/help',
    dataEvent: GeneralAnalyticsEventNames.MENU_HELP,
    level: 'REFERRAL',
    icon: (props) => <HelpIcon {...props} />,
  },
  {
    id: 5,
    name: 'Settings',
    pathname: '/settings',
    dataEvent: GeneralAnalyticsEventNames.MENU_SETTINGS,
    level: 'SETTINGS',
    icon: (props) => <SettingsIcon {...props} />,
  },
];

export const MOBILE_MENU_ITEMS = [
  {
    id: 1,
    name: 'Applications',
    pathname: '/dashboard',
    dataEvent: GeneralAnalyticsEventNames.MENU_DASHBOARD,
    level: 'DASHBOARD',
    icon: (props) => <DashboardIcon {...props} />,
  },
  {
    id: 2,
    name: 'Application Checkout',
    pathname: '/application/checkout',
    dataEvent: GeneralAnalyticsEventNames.MENU_SALES,
    level: 'DASHBOARD',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 3,
    name: 'Application Detail',
    pathname: '/application/detail',
    dataEvent: GeneralAnalyticsEventNames.MENU_SALES,
    icon: (props) => <SalesIcon {...props} />,
    level: 'DASHBOARD',
  },
  {
    id: 4,
    name: 'Application Cancel',
    pathname: '/application/cancel',
    dataEvent: GeneralAnalyticsEventNames.MENU_SALES,
    icon: (props) => <SalesIcon {...props} />,
    level: 'DASHBOARD',
  },
  {
    id: 5,
    name: 'Application Transaction Complete',
    pathname: '/application/checkout-finished',
    dataEvent: GeneralAnalyticsEventNames.MENU_SALES,
    icon: (props) => <SalesIcon {...props} />,
    level: 'DASHBOARD',
  },
  {
    id: 6,
    name: 'Application Incomplete',
    pathname: '/application/pending-income',
    dataEvent: GeneralAnalyticsEventNames.MENU_SALES,
    icon: (props) => <SalesIcon {...props} />,
    level: 'DASHBOARD',
  },
  {
    id: 7,
    name: 'Transactions',
    pathname: '/transactions',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 8,
    name: 'Transactions',
    pathname: '/transactions',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 9,
    name: 'Transactions Detail',
    pathname: '/transactions/detail',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 10,
    name: 'Transactions Partial Refund',
    pathname: '/transactions/partial-refund',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 11,
    name: 'Transactions Partial Refund Review',
    pathname: '/transactions/partial-refund/review',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 12,
    name: 'Transactions Partial Refund Success',
    pathname: '/transactions/partial-refund/success',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 13,
    name: 'Transactions Full Refund',
    pathname: '/transactions/full-refund',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 14,
    name: 'Transactions Full Refund Review',
    pathname: '/transactions/full-refund/review',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 15,
    name: 'Transactions Full Refund Success',
    pathname: '/transactions/full-refund/success',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 16,
    name: 'Transactions Void',
    pathname: '/transactions/void',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 17,
    name: 'Transactions Void Review',
    pathname: '/transactions/void/review',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 18,
    name: 'Transactions Void Success',
    pathname: '/transactions/void/success',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'TRANSACTIONS',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 19,
    name: 'Share Your Link',
    pathname: '/share-your-link',
    dataEvent: GeneralAnalyticsEventNames.MENU_TRANSACTIONS,
    level: 'MARKETING',
    icon: (props) => <SalesIcon {...props} />,
  },
  {
    id: 20,
    name: 'Help & Support',
    pathname: '/help',
    dataEvent: GeneralAnalyticsEventNames.MENU_HELP,
    level: 'REFERRAL',
    icon: (props) => <HelpIcon {...props} />,
  },
  {
    id: 21,
    name: 'Settings',
    pathname: '/settings',
    dataEvent: GeneralAnalyticsEventNames.MENU_SETTINGS,
    level: 'SETTINGS',
    icon: (props) => <SettingsIcon {...props} />,
  },
  {
    id: 23,
    name: 'Document Requests',
    pathname: '/document-requests',
    dataEvent: GeneralAnalyticsEventNames.MENU_SETTINGS,
    level: 'DASHBOARD',
    icon: (props) => <DocumentRequestsIcon {...props} />,
  },
];

export const PAYMENT_SLIDER_ITEMS = [
  {
    value: 1,
    scaledValue: 3,
    label: '3',
  },
  {
    value: 2,
    scaledValue: 6,
    label: '6',
  },
  {
    value: 3,
    scaledValue: 12,
    label: '12',
  },
  {
    value: 4,
    scaledValue: 18,
    label: '18',
  },
  {
    value: 5,
    scaledValue: 24,
    label: '24',
  },
];

export const PAYMENT_PLAN_MONTHS = [
  {
    id: 1,
    text: 'OKAY',
    value: '520 - 659',
    defaultSelected: false,
  },
  {
    id: 2,
    text: 'GOOD',
    value: '660 - 699',
    defaultSelected: false,
  },
  {
    id: 3,
    text: 'GREAT',
    value: '700+',
    defaultSelected: true,
  },
];

export enum DropdownItemValue {
  ALL = 'ALL',
  NEW = 'NEW',
  READY_FOR_CHECKOUT = 'READY_FOR_CHECKOUT',
  DENIED = 'DENIED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  EXPIRED = 'EXPIRED',
  CHECKED_OUT = 'CHECKED_OUT',
  CHECKOUT_IN_PROGRESS = 'CHECKOUT_IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  GIVEN_TREATMENT_ESTIMATE = 'GIVEN_TREATMENT_ESTIMATE',
  FOLLOW_UP = 'FOLLOW_UP',
  INTERESTED = 'INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  LEFT_VM = 'LEFT_VM',
  NO_VM = 'NO_VM',
  INVALID_NUMBER = 'INVALID_NUMBER',
  TRANSACTION_COMPLETE = 'TRANSACTION_COMPLETE',
  PLAN_SENT = 'PLAN_SENT',
  SEND_APPLICATION = 'SEND_APPLICATION',
  TREATMENT_SCHEDULED = 'TREATMENT_SCHEDULED',
  TREATMENT_IN_PROGRESS = 'TREATMENT_IN_PROGRESS',
  TREATMENT_COMPLETED = 'TREATMENT_COMPLETED',
  PATIENT_DECLINED = 'PATIENT_DECLINED',
  UNRESPONSIVE = 'UNRESPONSIVE',
  PATIENT_CREATED = 'PATIENT_CREATED',
  APPLICATION_NOT_SENT = 'APPLICATION_NOT_SENT',
  APPLICATION_NOT_STARTED = 'APPLICATION_NOT_STARTED',
  APPLICATION_STARTED = 'APPLICATION_STARTED',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  FINANCED = 'FINANCED',
  UNDER_CHERRY_REVIEW = 'UNDER_CHERRY_REVIEW',
  CREATE_TREATMENT_PLAN = 'CREATE_TREATMENT_PLAN',
}

export enum ApplicationStageDropdownItemValue {
  APPROVED = 'APPROVED',
  INCOME_VERIFICATION = 'INCOME_VERIFICATION',
  EXPIRED = 'EXPIRED',
  READY_FOR_CHECKOUT = 'READY_FOR_CHECKOUT',
}

export enum AppointmentDropdownItemValue {
  ALL_STATUS = 'ALL_STATUS',
  APPLICATION_NOT_STARTED = 'APPLICATION_NOT_STARTED',
  APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
  APPLICATION_EXPIRED = 'APPLICATION_EXPIRED',
  APPROVED = 'APPROVED',
}

export enum DenialStatuses {
  TEXT_REMINDER = 'TEXT_REMINDER',
  EDIT_OR_CANCEL = 'EDIT_CANCEL_PLAN',
}

export enum CheckoutStatuses {
  VIEW_TRANSACTION = 'VIEW_TRANSACTION',
  ISSUE_ANOTHER_PLAN = 'ISSUE_ANOTHER_PLAN',
  SEND_APPLICATION_LINK = 'SEND_APPLICATION_LINK',
}

export enum OutboundMessagesStatuses {
  TEXT_REMINDER = 'TEXT_REMINDER',
  OUTBOUND_MESSAGES = 'OUTBOUND_MESSAGES',
}

export enum ExtendApprovalStatuses {
  SEND_EXTENSION_LINK = 'SEND_EXTENSION_LINK',
}

export const LastActivityOptions: DropdownOption[] = [
  { value: DropdownItemValue.NEW, label: 'New Application' },
  { value: DropdownItemValue.SCHEDULED, label: 'Appointment Scheduled' },
  { value: DropdownItemValue.GIVEN_TREATMENT_ESTIMATE, label: 'Given Treatment Estimate' },
  { value: DropdownItemValue.FOLLOW_UP, label: 'Need to Follow Up' },
  { value: DropdownItemValue.INTERESTED, label: 'Interested, Call Back' },
  { value: DropdownItemValue.NOT_INTERESTED, label: 'Not Interested' },
  { value: DropdownItemValue.LEFT_VM, label: 'Left Voicemail' },
  { value: DropdownItemValue.NO_VM, label: 'Called, No Voicemail' },
  { value: DropdownItemValue.INVALID_NUMBER, label: 'Invalid Phone Number' },
];

export const TreatmentStageOptions: DropdownOption[] = [
  { value: DropdownItemValue.PATIENT_CREATED, label: 'Patient Created' },
  { value: DropdownItemValue.PLAN_SENT, label: 'Plan Sent' },
  { value: DropdownItemValue.TREATMENT_SCHEDULED, label: 'Treatment Scheduled' },
  { value: DropdownItemValue.TREATMENT_IN_PROGRESS, label: 'Treatment in Progress' },
  { value: DropdownItemValue.TREATMENT_COMPLETED, label: 'Treatment Completed' },
  { value: DropdownItemValue.PATIENT_DECLINED, label: 'Patient Declined' },
  { value: DropdownItemValue.UNRESPONSIVE, label: 'Unresponsive' },
];

export const TreatmentFinancingOptions: DropdownOption[] = [
  { value: DropdownItemValue.APPLICATION_NOT_STARTED, label: 'Application Not Started' },
  { value: DropdownItemValue.APPLICATION_STARTED, label: 'Application Started' },
  { value: DropdownItemValue.APPROVED, label: 'Approved' },
  { value: DropdownItemValue.NOT_APPROVED, label: 'Not Approved' },
  { value: DropdownItemValue.FINANCED, label: 'Financed' },
  { value: DropdownItemValue.UNDER_CHERRY_REVIEW, label: 'Under Cherry Review' },
];

export const AppointmentStatusOptions: DropdownOption[] = [
  { value: AppointmentDropdownItemValue.APPLICATION_NOT_STARTED, label: 'Application Not Started', key: 'status' },
  { value: AppointmentDropdownItemValue.APPLICATION_IN_PROGRESS, label: 'Application In-Progress', key: 'status' },
  { value: AppointmentDropdownItemValue.APPROVED, label: 'Approved', key: 'status' },
  { value: AppointmentDropdownItemValue.APPLICATION_EXPIRED, label: 'Application Expired', key: 'status' },
];

export const initialStatusData: AppointmentStatusFilter = {
  NOT_STARTED: { checked: true, disabled: false },
  IN_PROGRESS: { checked: true, disabled: false },
  APPROVED: { checked: true, disabled: false },
  EXPIRED: { checked: true, disabled: false },
};
export interface AppointmentStatusFilter {
  NOT_STARTED: StatusFilter;
  IN_PROGRESS: StatusFilter;
  APPROVED: StatusFilter;
  EXPIRED: StatusFilter;
}
export const ApplicationStageFilterCheckbox = [
  { id: 1, name: 'APPROVED', label: 'Approved', stateName: 'APPROVED' },
  { id: 1, name: 'INCOME_VERIFICATION', label: 'Incomplete', stateName: 'INCOME_VERIFICATION' },
];

export const QuickViewRadioButtonData = [
  { value: 'ALL', label: 'All Applicants' },
  { value: 'READY_FOR_CHECKOUT', label: 'Ready for Checkout' },
  { value: 'EXPIRING_SOON', label: 'Expiring Soon' },
  { value: 'EXPIRED', label: 'Expired' },
];

export interface ApplicationStatusFilterOption {
  value: string;
  label: string;
  stateName?: string;
}
export const ApplicationStatusFilterOptions: ApplicationStatusFilterOption[] = [
  // All
  { value: 'ALL', label: 'All Applications' },

  // Application Stages (Stages must have 'stateName')
  { value: 'APPROVED', label: 'Approved', stateName: 'APPROVED' },
  { value: 'INCOME_VERIFICATION', label: 'Incomplete', stateName: 'INCOME_VERIFICATION' },

  // Quick Views
  { value: 'CHECKOUT_IN_PROGRESS', label: 'Checkout in Progress' },
  { value: 'READY_FOR_CHECKOUT', label: 'Ready for Checkout' },
  { value: 'EXPIRING_SOON', label: 'Expiring Soon' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'DENIED', label: 'Not Approved', stateName: 'DENIED' },
];

export const outbondMessagesOption = [
  // { value: OutboundMessagesStatuses.TEXT_REMINDER, label: 'Send Text Reminder', className: 'edit-or-cancel' },
  { value: OutboundMessagesStatuses.OUTBOUND_MESSAGES, label: 'View Outbound Messages', className: 'edit-or-cancel' },
];

export const defaultDropdownItems = [
  { value: DropdownItemValue.ALL, label: 'All Applicants', defaultChecked: true },
  { value: DropdownItemValue.READY_FOR_CHECKOUT, label: 'Ready for Checkout', defaultChecked: false },
  { value: DropdownItemValue.EXPIRING_SOON, label: 'Expiring Soon', defaultChecked: false },
  { value: DropdownItemValue.EXPIRED, label: 'Expired', defaultChecked: false },
];

export const extendApprovalOption = [
  {
    value: ExtendApprovalStatuses.SEND_EXTENSION_LINK,
    label: 'Send Approval Extension Link',
    className: 'edit-or-cancel',
  },
];

export const createTreatmentPlanOption = [
  {
    value: 'CREATE_TREATMENT_PLAN',
    label: 'Create Treatment Plan',
    className: 'edit-or-cancel',
  },
];

export const denialCheckoutOption = [
  // { value: DenialStatuses.TEXT_REMINDER, label: 'Text Reminder' },
  { value: DenialStatuses.EDIT_OR_CANCEL, label: 'Edit or Cancel Plan', className: 'edit-or-cancel' },
];

export const checkoutFinishedItems = [
  { value: CheckoutStatuses.VIEW_TRANSACTION, label: 'View Transaction', className: 'edit-or-cancel' },
  { value: CheckoutStatuses.ISSUE_ANOTHER_PLAN, label: 'Issue Another Plan', className: 'edit-or-cancel' },
];

export const deniedApplicationActions = [
  { value: CheckoutStatuses.SEND_APPLICATION_LINK, label: 'Send Application Link', className: 'edit-or-cancel' },
];

export const voidTransactionItem = [
  { value: CheckoutStatuses.VIEW_TRANSACTION, label: 'View Transaction', className: 'edit-or-cancel' },
];

export const MIN_REFUND_AMOUNT = 50;
export const MIN_ELIGIBLE_DAY = 60;
export const MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT = 500;
export const MIN_AMOUNT = 200;

export const MIN_CHECKOUT_ISSUE_MINUTES = 10;
export const cherryFoundingDate = '2019-06-01';

export const DEFAULT_ALL_TIMESPAN = {
  from: dayjs(cherryFoundingDate).utc().startOf('day').toISOString(),
  to: dayjs().utc().endOf('day').toISOString(),
};
export const DEFAULT_TIMESPAN_TEXT = 'All Time';

export const TransactionsCSVHeaders = [
  'Contract ID',
  'Transaction Date',
  'Customer Name',
  'Phone Number',
  'Gross Sales',
  'Total Refunds',
  'Fees',
  'Net Sales',
  'Disbursement Status',
  'Term',
  'Location',
];

export const CustomerPlansCSVHeaders = ['Plan ID', 'Name', 'Plan Type', 'Member Since', 'Payment Status'];
export const BulkSmsCSVHeaders = ['Phone Number'];

export const appointmentDateRanges = [
  {
    label: 'Last Week',
    range: () => ({
      startDate: dayjs(dayjs().startOf('week')).subtract(6, 'day').toDate(),
      endDate: dayjs().startOf('week').toDate(),
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: dayjs().subtract(1, 'day').startOf('day').toDate(),
      endDate: dayjs().subtract(1, 'day').startOf('day').toDate(),
    }),
  },
  {
    label: 'Today',
    range: () => ({
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().startOf('day').toDate(),
    }),
  },
  {
    label: 'Tomorrow',
    range: () => ({
      startDate: dayjs().add(1, 'day').startOf('day').toDate(),
      endDate: dayjs().add(1, 'day').startOf('day').toDate(),
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().day(7).startOf('day').toDate(),
    }),
  },
  {
    label: 'Next Week',
    range: () => ({
      startDate: dayjs(dayjs().endOf('week')).add(2, 'day').startOf('day').toDate(),
      endDate: dayjs(dayjs().endOf('week')).add(8, 'day').startOf('day').toDate(),
    }),
  },
];
