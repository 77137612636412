import { theme } from '@frontend/cherry-library';

import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  h2 {
    margin-bottom: 24px !important;
  }

  .MuiFilledInput-root {
    ::before {
      border-bottom: 0 !important;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  button {
    width: fit-content;
    margin-bottom: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ColumnBox = styled.div`
  padding: 24px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
`;

export const RowBox = styled.div`
  padding: 24px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LoadingBox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: var(--Midnight-Blue-5, #f2f4f5);
  align-items: center;
`;

export const BodyWrapper = styled.div`
  margin-bottom: 24px;
`;
export const TitleWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
`;
export const CheckoutBlockWrapper = styled.div`
  margin-top: 40px;
`;
export const CheckoutTextWrapper = styled.div`
  margin-bottom: 16px;
`;
export const ButtonContainer = styled.div`
  margin: auto;
`;

export const ApplicationContainer = styled.div<{ maxWidth: number }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.maxWidth}px) {
    flex-direction: column;
  }
  padding: 24px;
  border-radius: 4px;
  background: ${theme.main.white};
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 600px;
`;

export const InnerFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dropdown-content {
    overflow-x: auto;
  }
`;

export const SplitRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

export const ApplicationButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;

  button {
    width: auto;
  }
`;

// Compliance and Fair Lending Info Box Styling
export const ComplianceInfoContainer = styled.div<{ maxWidth: number }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Midnight-Blue-50, #879097);
  background: var(--Midnight-Blue-5, #f2f4f5);
  height: fit-content;
  margin-top: 40px;

  @media screen and (max-width: ${(props) => props.maxWidth}px) {
    margin-top: 0px;
  }
`;

export const ComplianceInfoTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
  align-items: center;
`;

export const ComplianceList = styled.ul`
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 16px;

  li {
    font-size: 12px;
  }
  p {
    display: contents;
  }
`;

export const DropDownBorder = styled.div`
  width: 100%;
  border: 1px solid #56636d;
  box-sizing: border-box;
  height: 60px;
  border-radius: 4px;
  li {
    font-size: 14px;

    div:first-child {
      padding: 30px 14px;
    }
  }

  svg {
    color: #56636d;
  }
`;

export const ColumnButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ExistingBorrowerModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  margin-bottom: -24px;
`;

export const BoxContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
