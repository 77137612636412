import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Rox from 'rox-browser';

import { FETCH_ORGANIZATION_GROUP_USERS, FETCH_ORGANIZATION_MERCHANTS } from '../graphql/queries';
import { OrganizationSelectAnalyticsEventNames, useAnalytics } from '../hooks';
import useStore from '../hooks/useStore';
import NavigateService from './Navigate';

export const useOrganizationSelection = () => {
  const client = useApolloClient();
  const [organizationId, setOrganizationId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(true);
  const [isUserOrganizationGroupUser, setIsUserOrganizationGroupUser] = useState(false);
  const [userOrganizationGroups, setUserOrganizationGroups] = useState<any[]>([]);
  const { trackEvent } = useAnalytics();

  const navigate = useNavigate();
  const location: any = useLocation();
  NavigateService.initNavigate(navigate);
  const {
    setOrganizationMerchants,
    setSelectedOrganization,
    user,
    setIsAdminReporting,
    setSelectedOrganizationGroup,
  } = useStore();

  const getUserName = () => {
    return user.firstName || '';
  };

  const organizations = location?.state?.organizations;

  const onOrganizationSelect = (nativeEvent: any) => {
    setOrganizationId(nativeEvent.target.value);

    if (nativeEvent.target.value.includes('ORG_USER')) {
      const { organizationGroupName } = nativeEvent.target.options[nativeEvent.target.selectedIndex].dataset;
      setSelectedOrganizationGroup({ id: nativeEvent?.target?.value?.split('-')?.[1], name: organizationGroupName });
    }
    trackEvent({
      action: OrganizationSelectAnalyticsEventNames.SELECT_ORG,
    });
  };

  const getUserOrganizationGroups = async () => {
    setScreenLoading(true);
    const {
      data: { fetchOrganizationGroupUsers },
    } = await client.query({
      query: FETCH_ORGANIZATION_GROUP_USERS,
      variables: {
        input: {
          search: [
            {
              key: 'userId',
              value: user?.id,
            },
          ],
        },
      },
    });
    if (fetchOrganizationGroupUsers?.contents?.length > 0) {
      setIsUserOrganizationGroupUser(true);
      setUserOrganizationGroups(fetchOrganizationGroupUsers?.contents);
    }
    setScreenLoading(false);
  };

  useEffect(() => {
    getUserOrganizationGroups();
  }, []);

  const getOrganizationMerchantList = async (idOrganization: unknown) => {
    const {
      data: { fetchOrganizationMerchants },
    } = await client.query({
      query: FETCH_ORGANIZATION_MERCHANTS,
      fetchPolicy: 'no-cache',
      variables: { input: { idOrganization } },
    });
    setOrganizationMerchants(fetchOrganizationMerchants?.data || []);
  };

  const selectOrganization = async () => {
    setLoading(true);
    let selectedOrganization;
    if (organizationId?.includes('ORG_USER')) {
      setIsAdminReporting(true);
      selectedOrganization = organizations[0];

      setSelectedOrganization(selectedOrganization);
      Rox.setCustomNumberProperty('organization_id', Number(selectedOrganization?.id));
      Rox.setCustomStringProperty('organization_id_str', selectedOrganization?.id?.toString());

      await getOrganizationMerchantList(selectedOrganization?.id);
      trackEvent({
        action: OrganizationSelectAnalyticsEventNames.CONTINUE,
      });
      navigate('/reporting');
      setLoading(false);
    } else {
      selectedOrganization = organizations.find((item) => Number(item.id) === Number(organizationId));
      setSelectedOrganization(selectedOrganization);
      Rox.setCustomNumberProperty('organization_id', Number(selectedOrganization?.id));
      Rox.setCustomStringProperty('organization_id_str', selectedOrganization?.id?.toString());

      await getOrganizationMerchantList(organizationId);
      trackEvent({
        action: OrganizationSelectAnalyticsEventNames.CONTINUE,
      });
      navigate('/dashboard');
      setLoading(false);
    }
  };

  const logOnClickEvent = () => {
    trackEvent({
      action: OrganizationSelectAnalyticsEventNames.CLICK_TO_LOGIN,
    });
  };

  return {
    logOnClickEvent,
    selectOrganization,
    onOrganizationSelect,
    getUserName,
    organizationId,
    organizations,
    loading,
    screenLoading,
    isUserOrganizationGroupUser,
    userOrganizationGroups,
  };
};
