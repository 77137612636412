import { ReactComponent as KeyIcon } from 'assets/images/api-key.svg';
import { ReactComponent as BankIcon } from 'assets/images/bank.svg';
import { ReactComponent as IdCardIcon } from 'assets/images/id-card.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle-outline.svg';
import { ReactComponent as LocationIcon } from 'assets/images/location-pin.svg';
import { ReactComponent as PaymentIcon } from 'assets/images/payment.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as UserGroupIcon } from 'assets/images/user-group.svg';
import { useEffect, useState } from 'react';
import AccountDetails from './views/AccountDetails';
import AddALocation from './views/AddALocation';
import ApiKeys from './views/ApiKeys';
import BankSettings from './views/BankSettings';
import BusinessInformation from './views/BusinessInformation';
import PracticeFinderSettings from './views/PracticeFinderSettings';
import PricingSettings from './views/PricingSettings';
import useStore from 'lib/hooks/useStore';
import { FeatureNames } from 'lib/hooks';
import { useTranslation } from 'react-i18next';
import UserManagementSettings from './views/UserManagementSettings';

export const COLUMN_LAYOUT_THRESHOLD = 1200;

export const useViewport = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { screenWidth };
};

export const useSettingsPages = () => {
  const { organization, features } = useStore();
  const { t: translate } = useTranslation();

  const SETTINGS_PAGES: SettingsPage[] = [
    {
      title: translate('settings.pages.accountDetails.title'),
      id: 'account',
      description: translate('settings.pages.accountDetails.description'),
      icon: <IdCardIcon />,
      navigation: '/settings/account-details',
      subPages: [
        {
          title: translate('settings.pages.accountDetails.subPages.accountDetails.title'),
          keywords: ['account', 'details', 'name', 'email', 'phone', 'user', 'ids', 'locations'],
          navigation: '/settings/account-details',
          isAvailable: true,
        },
      ],
      component: <AccountDetails />,
    },
    {
      title: translate('settings.pages.pricingSettings.title'),
      id: 'pricing',
      description: translate('settings.pages.pricingSettings.description'),
      icon: <PaymentIcon />,
      navigation: '/settings/pricing-settings',
      subPages: [
        {
          title: translate('settings.pages.pricingSettings.subPages.practicePricing.title'),
          keywords: [
            'pricing',
            'settings',
            'prices',
            'term',
            'reduced aprs',
            'promotional',
            'fee',
            'approvals',
            'practices',
            'subprime credit patients',
            'minimum transactions',
            'standard',
            'offers',
            'offering',
            'tier',
            'plan change',
            'current',
            'bronze/silver/gold',
            'volume',
            'sales',
          ],
          navigation: '/settings/pricing-settings#practice-pricing',
          isAvailable: true,
        },
        {
          title: translate('settings.pages.pricingSettings.subPages.minimumApprovalAmount.title'),
          keywords: [
            'pricing',
            'settings',
            'prices',
            'term',
            'reduced aprs',
            'promotional',
            'fee',
            'approvals',
            'practices',
            'subprime credit patients',
            'minimum transactions',
            'standard',
            'offers',
            'offering',
            'tier',
            'plan change',
            'current',
            'bronze/silver/gold',
            'volume',
            'sales',
          ],
          navigation: '/settings/pricing-settings#minimum-approval-amount',
          isAvailable: features?.includes(FeatureNames?.MINIMUM_APPROVAL_AMOUNT),
        },
        {
          title: translate('settings.pages.pricingSettings.subPages.pricingTier.title'),
          keywords: [
            'pricing',
            'settings',
            'prices',
            'term',
            'reduced aprs',
            'promotional',
            'fee',
            'practices',
            'subprime credit patients',
            'minimum transactions',
            'standard',
            'offers',
            'offering',
            'tier',
            'plan change',
            'current',
            'bronze/silver/gold',
            'volume',
            'sales',
          ],
          navigation: '/settings/pricing-settings#pricing-tier',
          isAvailable: true,
        },
      ],
      component: <PricingSettings />,
    },

    {
      title: translate('settings.pages.userManagement.title'),
      id: 'user',
      description: translate('settings.pages.userManagement.description'),
      icon: <UserGroupIcon />,
      navigation: '/settings/user-management',
      subPages: [
        {
          title: translate('settings.pages.userManagement.subPages.userManagement.title'),
          keywords: [
            'users',
            'management',
            'team',
            'members',
            'access',
            'permissions',
            'employee',
            'manager',
            'sales',
            'person',
            'marketer',
            'accountant',
            'admins',
          ],
          navigation: '/settings/user-management',
          isAvailable: true,
        },
      ],
      component: <UserManagementSettings />,
    },
    {
      title: translate('settings.pages.bankSettings.title'),
      id: 'bank',
      description: translate('settings.pages.bankSettings.description'),
      icon: <BankIcon />,
      navigation: '/settings/bank-settings',
      subPages: [
        {
          title: translate('settings.pages.bankSettings.subPages.bankInformation.title'),
          keywords: [
            'banks',
            'banking',
            'settings',
            'accounts',
            'deposits',
            'ach',
            'transfers',
            'funds',
            'infos',
            'informations',
            'funding',
            'disbursement',
          ],
          navigation: '/settings/bank-settings',
          isAvailable: true,
        },
      ],
      component: <BankSettings />,
    },
    {
      title: translate('settings.pages.businessInformation.title'),
      id: 'business',
      description: translate('settings.pages.businessInformation.description'),
      icon: <InfoIcon width={40} height={40} />,
      navigation: '/settings/business-information',
      subPages: [
        {
          title: translate('settings.pages.businessInformation.subPages.communicationSettings.title'),
          keywords: [
            'business',
            'informations',
            'infos',
            'contact',
            'communications',
            'website url',
            'locations',
            'display name',
            'text messages',
            'practice finder',
            'provider',
            'search results',
            'phone',
          ],
          navigation: '/settings/business-information',
          isAvailable: true,
        },
      ],
      component: <BusinessInformation />,
    },
    {
      title: translate('settings.pages.practiceFinder.title'),
      id: 'finder',
      description: translate('settings.pages.practiceFinder.description'),
      icon: <SearchIcon width={40} height={40} />,
      navigation: '/settings/practice-finder',
      subPages: [
        {
          title: translate('settings.pages.practiceFinder.subPages.providerFinder.title'),
          keywords: [
            'practices',
            'finder',
            'settings',
            'treatments and services',
            'locations',
            'providers',
            'contact informations/infos',
            'marketing',
          ],
          navigation: '/settings/practice-finder',
          isAvailable: !organization?.isDemo && features?.includes(FeatureNames?.PROVIDER_FINDER),
        },
      ],
      component: <PracticeFinderSettings />,
    },
    {
      title: translate('settings.pages.addALocation.title'),
      id: 'location',
      description: translate('settings.pages.addALocation.description'),
      icon: <LocationIcon />,
      navigation: '/settings/location-management',
      subPages: [
        {
          title: translate('settings.pages.addALocation.subPages.locationManagement.title'),
          keywords: [
            'add',
            'locations',
            'management',
            'active',
            'passive',
            'multiple',
            'single',
            'address',
            'point of contact',
            'status',
            'review',
            'new',
          ],
          navigation: '/settings/location-management',
          isAvailable: true,
        },
      ],
      component: <AddALocation />,
    },
    {
      title: translate('settings.pages.apiKeys.title'),
      id: 'api',
      description: translate('settings.pages.apiKeys.description'),
      icon: <KeyIcon />,
      navigation: '/settings/api-keys',
      subPages: [
        {
          title: translate('settings.pages.apiKeys.subPages.apiKeys.title'),
          keywords: [
            'api keys',
            'cherry api',
            'data account',
            'last digits',
            'access',
            'generate',
            'codes',
            'aesthetic',
            'record',
            'integration',
          ],
          navigation: '/settings/api-keys',
          isAvailable: features?.includes(FeatureNames?.PARTNER_API_ACCESS),
        },
      ],
      component: <ApiKeys />,
    },
  ];

  const SETTINGS_DROPDOWN_OPTIONS = [
    { value: 'account', label: translate('settings.pages.accountDetails.title'), isAvailable: true },
    { value: 'pricing', label: translate('settings.pages.pricingSettings.title'), isAvailable: true },
    { value: 'user', label: translate('settings.pages.userManagement.title'), isAvailable: true },
    { value: 'bank', label: translate('settings.pages.bankSettings.title'), isAvailable: true },
    { value: 'business', label: translate('settings.pages.businessInformation.title'), isAvailable: true },
    {
      value: 'finder',
      label: translate('settings.pages.practiceFinder.title'),
      isAvailable: !organization?.isDemo && features?.includes(FeatureNames?.PROVIDER_FINDER),
    },
    { value: 'location', label: translate('settings.pages.addALocation.title'), isAvailable: true },
    {
      value: 'api',
      label: translate('settings.pages.apiKeys.title'),
      isAvailable: features?.includes(FeatureNames?.PARTNER_API_ACCESS),
    },
  ];

  return { SETTINGS_PAGES, SETTINGS_DROPDOWN_OPTIONS };
};

export interface SubPage {
  title: string;
  keywords: string[];
  navigation: string;
  isAvailable: boolean;
}

export interface SettingsPage {
  title: string;
  id: string;
  description: string;
  icon: React.ReactNode;
  navigation: string;
  subPages: SubPage[];
  component: React.ReactNode;
}

export interface ItemProps {
  menuId: string;
  title: string;
  activePage: string;
  redirect: (menuId: string) => void;
  showFixedHighlight: boolean;
}
export interface MenuProps {
  activePage: string;
  redirect: (menuId: string) => void;
  pages: SettingsPage[];
  showFixedHighlight: boolean;
}
