import images from 'assets/images';
import { ApplicationSwitchButton, ButtonPrimary, SendLinkIcon, TierBadge } from 'lib/components';
import { Notification } from 'lib/components/Notification';
import { GeneralAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { TierName } from 'lib/utils';
import SendLinkModal from 'pages/desktop/PointOfSale/components/SendLinkModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface HeaderProps {
  isSidebarOpen: boolean;
  isSnackbarVisible: boolean;
}
interface ContainerProps {
  isSidebarOpen: boolean;
  isDemo: boolean;
}

export const PointOfSaleHeader = ({ isSidebarOpen, isSnackbarVisible }: HeaderProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { organization, organizationTier, isAdminReporting, selectedOrganizationGroup } = useStore();

  const [isSendLinkModalVisible, setIsSendLinkModalVisible] = useState(false);

  const logLogoClicked = () => {
    trackEvent({
      action: GeneralAnalyticsEventNames.HEADER_LOGO_CLICK,
    });
    navigate(isAdminReporting ? '/reporting' : '/dashboard');
  };

  const handleSendLink = () => {
    setIsSendLinkModalVisible(true);
  };

  const handleSendLinkModalClose = () => {
    setIsSendLinkModalVisible(false);
  };

  return (
    <Container isSidebarOpen={isSidebarOpen} isDemo={isSnackbarVisible}>
      <SendLinkModal open={isSendLinkModalVisible} onClose={handleSendLinkModalClose} />
      <TitleRow>
        <CherryLogo src={images.cherryLogo.default} onClick={logLogoClicked} />
      </TitleRow>
      <ActionsPaneContainer>
        <ButtonPrimary text="Send Link" onClick={handleSendLink} endIcon={<SendLinkIcon />} />
        <Notification isTierBadgeVisible={!!organizationTier?.hasTier} />
        <MerchantLabelContainer>
          <MerchantLabel>{isAdminReporting ? selectedOrganizationGroup?.name : organization?.name}</MerchantLabel>
        </MerchantLabelContainer>
        {organizationTier?.hasTier && (
          <TierContainer>
            <TierBadge type={TierName(organizationTier?.organizationTier?.tierId)?.toLowerCase()} />
          </TierContainer>
        )}
        <ApplicationSwitchButton />
      </ActionsPaneContainer>
    </Container>
  );
};

const MerchantLabelContainer = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid #dadada;
  color: #000000;
  margin-left: 12px;
  border-radius: 5px;
  justify-content: center;
`;

const MerchantLabel = styled.span`
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: ${(props) => props.theme.main.white};
  position: fixed;
  // width: calc(100% - ${(props) => (props.isSidebarOpen ? 250 : 80)}px);
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: width 0.4s;
  transition-timing-function: ease-in-out;
  top: ${(props) => (props.isDemo ? 42 : 0)}px;
  height: 92px;
  box-shadow: 0 0 5px #ddd;
  p {
    color: #000000;
  }
  @media print {
    display: none;
  }
`;

const ActionsPaneContainer = styled.div`
  display: flex;
  flex-direction: row;
  * > {
    margin: 10px;
  }
  height: 40px;

  @media print {
    display: none;
  }
`;

const CherryLogo = styled.img`
  width: 145px;
  height: auto;
`;

const TitleRow = styled.div`
  display: inline-flex;
  align-items: center;
  color: #000000;
  cursor: pointer;

  svg {
    margin-right: 12px;
    fill: ${(props) => props.theme.main.darkBlue};
  }
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;
