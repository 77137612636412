import React from 'react';

import { DropDown } from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import useStore from 'lib/hooks/useStore';
import { Appointment } from 'lib/types';

import { CheckoutButton, DropdownContainer } from './components';
import { getCreateTreatmentPlanItems, getOutboundMessagesItems } from './utils';

interface Props {
  row: any;
  appointmentPage?: boolean;
  handleClick?: (row: Appointment) => void;
  checkoutAgain?: boolean;
  editPlan?: boolean;
}

interface OptionProps {
  value: string;
  label: string;
  className: string;
}

export const Approved = ({ row, appointmentPage, handleClick, checkoutAgain, editPlan }: Props) => {
  const permission = usePermission();
  const { features } = useStore();
  const { flags } = useFlags();

  const title = editPlan ? 'Edit Plan' : checkoutAgain ? 'Checkout Again' : 'Checkout';
  const NewDropDown = DropDown;

  const shouldDisplayPatientTracker = features?.includes(FeatureNames.PATIENT_TRACKER);
  const isTakeHomeApprovalEnabled = false;

  const outboundMessagesItems = getOutboundMessagesItems(row);
  const createTreatmentPlanItems = getCreateTreatmentPlanItems(row);

  const options: OptionProps[] = [];

  if (outboundMessagesItems?.length > 0) {
    options.push(...outboundMessagesItems);
  }

  if (!shouldDisplayPatientTracker && isTakeHomeApprovalEnabled && createTreatmentPlanItems?.length > 0) {
    options.push(...createTreatmentPlanItems);
  }

  return (
    <>
      <CheckoutButton
        data-testid="checkout-button"
        className={appointmentPage ? '' : 'btn--checkout'}
        light={true}
        appointmentPage={appointmentPage}
        onClick={appointmentPage ? () => handleClick!(row) : row.openCheckoutModal}
      >
        {title}
      </CheckoutButton>
      {!permission(FeatureNames.OUTBOUND_MESSAGE, ScreenNames.DASHBOARD) ? null : getOutboundMessagesItems(row)
          ?.length > 0 ? (
        <DropdownContainer>
          <NewDropDown
            data-testid="checkout-option"
            id={`drop-${row.applicationId}`}
            options={options}
            textColor={'#0E202F'}
            onChange={row.onChangeReminderChange}
            defaultValue={row.scheduled}
            showAllItem={true}
            hoveredMode={false}
            customSelector={true}
          />
        </DropdownContainer>
      ) : null}
    </>
  );
};
