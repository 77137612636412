import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Navigate from 'lib/services/Navigate';
import Storage from 'lib/services/Storage';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { ApplicationListSearch } from 'lib/types/Merchant';
import { cherryFoundingDate } from 'lib/utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const ApplicationSearch = (search: ApplicationListSearch) => {
  if (!search.organizationId) {
    Storage.clearUserData();
    Navigate.navigate('/login');
    return;
  }

  return [
    {
      key: 'demo',
      value: String(search?.demo),
    },
    {
      key: 'validity',
      value: 'ACTIVE',
    },
    {
      key: 'organizationId',
      value: search?.organizationId,
      operator: GenericSearchOperators.EQUAL,
    },
  ];
};

export const FetchApplicationListSearch = (
  search: ApplicationListSearch,
  mobilePagination: boolean,
  searchKey?: string,
) => {
  if (!search.organizationId) {
    Storage.clearUserData();
    Navigate.navigate('/login');
    return;
  }

  return [
    {
      key: 'demo',
      value: String(search?.demo),
    },
    {
      key: 'validity',
      value: 'ACTIVE',
    },
    {
      key: 'organizationId',
      value: search?.organizationId,
      operator: GenericSearchOperators.EQUAL,
    },
    ...(search.readyForCheckout
      ? [
          {
            key: 'readyForCheckout',
            value: 'true',
          },
        ]
      : []),
    ...(search.selfCheckout
      ? [
          {
            key: 'selfCheckout',
            value: 'true',
          },
        ]
      : []),
    ...(search.merchantIds && search.merchantIds.length > 0
      ? [
          {
            key: 'raw',
            value: `merchantId±{${search.merchantIds?.map((loc) => loc.value).join(',') || ''}}`,
          },
        ]
      : []),
    ...(search.referralSource
      ? [
          {
            key: 'referralSource',
            value: search.referralSource,
            operator: GenericSearchOperators.EQUAL,
          },
        ]
      : []),

    ...(search.scheduleStatus && search.scheduleStatus.length > 0
      ? [
          {
            key: 'raw',
            value: `scheduleStatus±{${search.scheduleStatus?.map((status) => status).join(',') || ''}}`,
          },
        ]
      : []),
    ...(search.status && search.status.length > 0 && !mobilePagination
      ? [
          search.status.includes('DENIED')
            ? {
                key: 'statusRaw',
                value: `status±{${search.status?.map((status) => status).join(',')}}`,
              }
            : {
                key: 'statusRaw',
                value: `status±{${search.status?.map((status) => status).join(',') || ''},INITIALIZED,PREAPPROVED${
                  searchKey ? ',DENIED' : ''
                }}`,
              },
        ]
      : [
          {
            key: 'statusRaw',
            value: `status±{APPROVED,INCOME_VERIFICATION,INITIALIZED,PREAPPROVED${searchKey ? ',DENIED' : ''}}`,
          },
        ]),
    ...(search.createdAt && search.createdAt.length > 0
      ? search.createdAt
      : [
          {
            key: 'createdAt',
            value: dayjs(cherryFoundingDate).startOf('day').toISOString() || '',
            operator: GenericSearchOperators.BIGGER_THAN,
          },
          {
            key: 'createdAt',
            value: dayjs().endOf('day').add(1, 'day').toISOString() || '',
            operator: GenericSearchOperators.LOWER_THAN,
          },
        ]),
    ...search.expireAt,
  ];
};
