import styled from 'styled-components';

export const Title = styled.span<{ m?: any; p?: any; align?: string; display?: string }>`
  font-family: 'Open Sans', sans-serif;
  margin: ${(props) => props.m};
  text-align: ${(props) => (props?.align ? props?.align : 'left')};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  display: ${(props) => props.display};
`;
