import { LinearProgress } from '@mui/material';
import React, { Suspense, useRef } from 'react';
import styled from 'styled-components';

import { FixedDecimalFormat, Loading, TierBadge } from 'lib/components';
import { formatDate } from 'lib/utils';
import { theme } from '@frontend/cherry-library';

const Accordion = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.Accordion })),
);

const AccordionSection = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.AccordionSection })),
);

const getInfoTextPosition = (progress) => {
  let position = `calc(5% - 95%)`;

  if (progress > 89) {
    position = '80%';
  } else if (progress > 5) {
    position = `calc(${progress}% - ${100 - progress}% - 2px)`;
  }

  return position;
};

export const MainTier = ({ organizationTier }) => {
  const activeSectionRef = useRef<any>('');

  const { menuTiers, tierDetail } = organizationTier || {};
  const { currentVolume, nexTierUpdate, volumeRequiredToUpgrade } = tierDetail || {};
  const { tierId } = organizationTier?.organizationTier || {};

  const getMenuTier = (label: string) => {
    return menuTiers?.find((tier) => tier.label === label);
  };

  const faqList = [
    {
      id: '1',
      title: 'How do pricing tiers work?',
      description: `
      <ul>
        <li>Tiers Are Based on Sales Volume - You have a chance to earn your way to lower practice fees if your transaction volume with Cherry meets certain volume thresholds. The higher your transaction volume, the lower your fees.</li>
        <li>Your Initial Tier is Set After 60 Days - Your initial tier is based on your sales volume within the first 60 days offering Cherry. By default, all practices start at Bronze, and this tier will remain active until the next assessment period.</li>
        <li>Tiers “Reset” January 1st and July 1st - Your tier is re-assessed every January 1st and July 1st, so you’ll always have a chance to enjoy a discounted rate in the next period.</li>
      </ul>
    `,
    },
  ];

  const handleRowClicked = (e) => {
    e.target.parentNode.parentNode.parentNode.lastChild.click();
  };

  const calculateProgressLevel = (min, max, value) => {
    let progressLevel = 0;

    const progress = (value / (max + min)) * 100;

    if (progress) {
      if (progress > 100) {
        progressLevel = 100;
      } else {
        progressLevel = Math.round(progress);
      }
    }

    return progressLevel;
  };

  const hasNotAchievedTier = (label: string) => currentVolume < getMenuTier(label)?.minimumVolume;

  const tierInfo = () => {
    switch (tierId) {
      case '1':
        return {
          type: 'bronze',
          color: '#AC836E',
          infoComponent: (
            <>
              <FixedDecimalFormat amount={volumeRequiredToUpgrade} prefix={'$'} /> &nbsp;more to reach Silver
            </>
          ),
          progressLevel: calculateProgressLevel(
            getMenuTier('Bronze')?.maximumVolume,
            getMenuTier('Gold')?.minimumVolume,
            currentVolume,
          ),
        };
      case '2':
        return {
          type: 'silver',
          color: '#b1c2d0',
          infoComponent: (
            <>
              <FixedDecimalFormat amount={volumeRequiredToUpgrade} prefix={'$'} /> &nbsp;more to reach Gold
            </>
          ),
          maintainInfoComponent: hasNotAchievedTier('Silver') ? (
            <>
              <FixedDecimalFormat amount={getMenuTier('Silver')?.minimumVolume - currentVolume} prefix={'$'} /> &nbsp;to
              maintain Silver
            </>
          ) : (
            `Congrats, you’ve locked in ${hasNotAchievedTier('Gold') ? 'Silver' : 'Gold'} for the next cycle!`
          ),
          progressLevel: calculateProgressLevel(
            getMenuTier('Silver')?.minimumVolume,
            getMenuTier('Gold')?.minimumVolume,
            currentVolume,
          ),
        };
      case '3':
        return {
          type: 'gold',
          color: '#cd8b00',
          infoComponent: <>Gold Tier Unlocked!</>,
          maintainInfoComponent: hasNotAchievedTier('Gold') ? (
            <>
              <FixedDecimalFormat amount={getMenuTier('Gold')?.minimumVolume - currentVolume} prefix={'$'} /> &nbsp;to
              maintain Gold
            </>
          ) : (
            `Congrats, you’ve locked in Gold for the next cycle!`
          ),
          progressLevel: calculateProgressLevel(
            getMenuTier('Silver')?.minimumVolume,
            getMenuTier('Gold')?.minimumVolume,
            currentVolume,
          ),
        };
      default:
        return {};
    }
  };

  return (
    <Container>
      <HoverInfoContainer progress={tierInfo().progressLevel}>
        <HoverTitle>
          Sales <FixedDecimalFormat amount={currentVolume} prefix={'$'} />
        </HoverTitle>
        <HoverSubtitle>{tierInfo().infoComponent}</HoverSubtitle>
        <HoverSubtitle>{tierInfo().maintainInfoComponent}</HoverSubtitle>
        <HoverSubtitle>Current cycle ends {formatDate(nexTierUpdate)}</HoverSubtitle>
      </HoverInfoContainer>

      <ProgressHoverContainer>
        <HoverContainer progress={tierInfo().progressLevel}>
          <ProgressHoverCursor />
        </HoverContainer>
      </ProgressHoverContainer>

      <StyledLinearProgress
        variant="determinate"
        value={tierInfo().progressLevel || 70}
        gradientColor={tierInfo().color}
      />

      <TierLabelContainer>
        <LabelContainer flex={1} active={tierInfo().type === 'bronze'}>
          <TierBadge type="bronze" mb="8px" />
          <LabelTitle>
            Under <FixedDecimalFormat amount={getMenuTier('Bronze').maximumVolume} prefix={'$'} />
          </LabelTitle>
          <LabelTitle bold={true}>Standard Fees</LabelTitle>
        </LabelContainer>
        <LabelContainer flex={1} active={tierInfo().type === 'silver'}>
          <Border />
          <TierBadge type="silver" mb="8px" />
          <LabelTitle>
            Over <FixedDecimalFormat amount={getMenuTier('Silver').minimumVolume} prefix={'$'} />
          </LabelTitle>
          <LabelTitle bold={true}>Avg 1.17% Discount</LabelTitle>
        </LabelContainer>
        <LabelContainer flex={1} active={tierInfo().type === 'gold'}>
          <Border />
          <TierBadge type="gold" mb="8px" />
          <LabelTitle>
            Over <FixedDecimalFormat amount={getMenuTier('Silver').maximumVolume} prefix={'$'} />
          </LabelTitle>
          <LabelTitle bold={true}>Avg 1.86% Discount</LabelTitle>
        </LabelContainer>
      </TierLabelContainer>

      <TooltipText>
        Note: Total sales are calculated daily. The number shown will not reflect all transactions in real-time.
        Discounts are relative to bronze plan.
      </TooltipText>

      <FaqContainer>
        <Suspense fallback={<Loading fixed={true} size={40} />}>
          <CherryFaq id="faq--accordion" multiple={true}>
            {faqList.map((item) => {
              return (
                <CherryFaqSection
                  ref={activeSectionRef}
                  key={item.id}
                  data-testid="faq-container"
                  label={
                    <FaqHeader data-id={item.id} data-testid="faq-header" onClick={handleRowClicked}>
                      {item.title}
                    </FaqHeader>
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </CherryFaqSection>
              );
            })}
          </CherryFaq>
        </Suspense>
      </FaqContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
  position: relative;
`;

const ProgressHoverContainer = styled.div``;

const HoverContainer = styled.div<{ progress: number | undefined }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
  left: ${(props) => `calc(${props.progress}% - 2px)`};
  margin-top: -4px;
`;

const HoverInfoContainer = styled.div<{ progress: number | undefined }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  margin-left: ${(props) => getInfoTextPosition(props.progress)};
`;

const HoverTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e202f;
`;

const HoverSubtitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #0e202f;
`;

const ProgressHoverCursor = styled.div`
  height: 10px;
  background: #0e202f;
  border: 2px solid #0e202f;
`;

const TierLabelContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const LabelContainer = styled.div<{ flex: number; active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
  justify-content: flex-start;
  align-items: flex-start;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

const LabelTitle = styled.span<{ bold?: boolean }>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 'bold' : '400')};
  font-size: 10px;
  line-height: 14px;
  padding-left: 5px;
  margin-top: 2px;
`;

const Border = styled.div`
  position: absolute;
  height: 36px;
  border-left: 1px dashed gray;
  top: -7px;
  left: -5px;
  border-left: 1px dashed #0e202f;
`;

const TooltipText = styled.span`
  padding-top: 24px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;

const FaqContainer = styled.div`
  margin-left: -15px;
`;

const CherryFaq = styled(Accordion)`
  padding: 12px 0px 0px;
  &: [data-id= 'accordion-section-summary'] {
    padding: 0px;
  }
  svg {
    fill: ${(props) => props.theme.main.green};
    margin-right: 16px;
  }
`;

const CherryFaqSection = styled(AccordionSection)`
  button:focus {
    box-shadow: 0 0 0 0;
  }
`;

const FaqHeader = styled.span`
  font-weight: 700;
  font-size: 14px;
  display: block;
  width: 100%;
  cursor: pointer;
`;

const StyledLinearProgress = styled(LinearProgress)<{ value: number; gradientColor?: string }>`
  & .MuiLinearProgress-bar {
    height: 10px;
    border-radius: 5px;
    background-color: #e7e9ea;
  }

  &.MuiLinearProgress-root {
    border-radius: 5px;
    background: ${theme.main.warmGray};
    height: 8px;
  }
`;
