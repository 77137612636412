import { useMutation } from '@apollo/client';
import { emailPattern, PhoneNumberInput, phonePattern, TextInput } from '@frontend/cherry-library';
import { FormControl, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ADD_ORGANIZATION_USER, CREATE_USER } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { LocationManagementStepEnums } from 'lib/types';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContentInherit,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onChangeHandler: (key, value) => void;
}

export const CreateUser = ({ handleClose, onStepChange, onChangeHandler }: Props) => {
  const alert = useAlert();
  const { organization, setNewUserId } = useStore();
  const [addOrganizationUser] = useMutation(ADD_ORGANIZATION_USER);
  const [createUser] = useMutation(CREATE_USER);

  const [loading, setLoading] = useState(false);

  const {
    getValues,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'onChange',
  });

  const isSubmitDisabled =
    Object.keys(touchedFields)?.length === 0 ||
    Object.keys(errors)?.length > 0 ||
    !(
      Object.keys(getValues())?.every((key) => ['firstName', 'lastName', 'email', 'phone'].includes(key)) &&
      Object.values(getValues())?.every((value) => value?.length > 0)
    );

  const createUserHandler = async () => {
    try {
      const state = getValues();
      setLoading(true);
      const { data } = await createUser({ variables: { input: { ...state } } });
      if (data?.createUserRequest?.error) {
        setLoading(false);
        alert.error(data?.createUserRequest?.message);
      } else {
        onChangeHandler('poc', { ...state, role: 'ADMIN' });
        await userRoleHandler(data.createUserRequest?.user);
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const userRoleHandler = async (user) => {
    try {
      const { data } = await addOrganizationUser({
        variables: { input: { organizationId: organization?.id, userId: user.id, role: 'ADMIN' } },
      });
      if (data.addOrganizationUser.success) {
        setLoading(false);
        onChangeHandler('id', user.id);
        setNewUserId(user.id);
        onStepChange(LocationManagementStepEnums.LOCATION_ADDRESS);
      } else {
        setLoading(false);
        alert.error(data?.addOrganizationUser?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const onContinueHandler = async () => {
    await createUserHandler();
  };

  const onBackHandler = () => {
    onStepChange(LocationManagementStepEnums.POINT_OF_CONTACT);
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Create New User
      </CherryDialogTitle>
      <SubTitle>Tell us about this user.</SubTitle>
      <DialogContentInherit>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a first name',
                  },
                }}
                // tslint:disable-next-line: jsx-no-lambda
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextInput
                    inputSize="small"
                    type="text"
                    label="First Name"
                    showPlaceholderAnimation={false}
                    inputProps={{
                      'data-inputkey': 'firstName',
                    }}
                    id="location-management-first-name"
                    data-testid="location-management-first-name"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a last name',
                  },
                }}
                // tslint:disable-next-line: jsx-no-lambda
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextInput
                    inputSize="small"
                    type="text"
                    label="Last Name"
                    showPlaceholderAnimation={false}
                    inputProps={{
                      'data-inputkey': 'lastName',
                    }}
                    id="location-management-last-name"
                    data-testid="location-management-last-name"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a valid email address',
                  },
                  pattern: { value: emailPattern, message: 'Please enter a valid email address' },
                }}
                // tslint:disable-next-line: jsx-no-lambda
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <TextInput
                    inputSize="small"
                    type="text"
                    label="Email"
                    showPlaceholderAnimation={false}
                    inputProps={{
                      'data-inputkey': 'email',
                    }}
                    id="location-management-email-name"
                    data-testid="location-management-email-name"
                    required={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={!!error}
                    errorText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a valid phone number',
                  },
                  pattern: { value: phonePattern, message: 'Please enter a valid phone number' },
                }}
                // tslint:disable-next-line: jsx-no-lambda
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <PhoneNumberInput
                    inputSize="small"
                    label="Phone"
                    data-inputkey="phone"
                    showPlaceholderAnimation={false}
                    id="location-management-phone-mask"
                    data-testid="application-phone-input"
                    inputProps={{
                      'data-inputkey': 'phone',
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={!!error}
                    errorText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="small"
                type="text"
                label="Administrator"
                showPlaceholderAnimation={false}
                disabled={true}
                inputProps={{
                  'data-inputkey': 'role',
                }}
                id="location-management-role"
                data-testid="location-management-role"
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContentInherit>

      <ButtonContainer>
        <BackButton onClick={onBackHandler}>Back</BackButton>
        <ButtonGap />
        <ContinueButton onClick={onContinueHandler} disabled={isSubmitDisabled || loading} loading={loading}>
          Continue
        </ContinueButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;
