import { useMutation } from '@apollo/client';
import { Switch } from '@frontend/cherry-library';
import { FormControl, Grid, TextField } from '@mui/material';
import { ButtonPrimary, DropDown, TrashIcon } from 'lib/components';
import client from 'lib/graphql/client';
import { UPLOAD_SHOP_FILE } from 'lib/graphql/mutations';
import { GET_MEMBERSHIP_PLANS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Row } from 'lib/styles';
import { getBase64 } from 'lib/utils';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

enum PAYMENT_SCHEDULES {
  ONCE_UP_FRONT = 'ONCE_UPFRONT',
  MONTHLY = 'MONTHLY',
  FREE = 'FREE',
}

const PAYMENT_SCHEDULE_OPTIONS = [
  {
    value: PAYMENT_SCHEDULES.ONCE_UP_FRONT,
    label: 'Once Up-Front',
  },
  {
    value: PAYMENT_SCHEDULES.MONTHLY,
    label: 'Monthly',
  },
  {
    value: PAYMENT_SCHEDULES.FREE,
    label: 'Free',
  },
];

const OFFERING_TYPES = [
  {
    value: 'RETAIL',
    label: 'Retail',
    description: 'Let patients buy and pay for products online',
  },
  {
    value: 'LEAD',
    label: 'Lead',
    description: 'Collect patient interest and pre-qualify for a Cherry payment plan',
  },
  {
    value: 'MEMBERSHIP',
    label: 'Membership',
    description: 'Charge patient a recurring fee to access benefits',
  },
];

interface Props {
  productData: any;
  onChange: (key: string, value: any) => void;
}

const OfferingInfo = ({ productData, onChange }: Props) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { organization } = useStore();

  const [uploadFile, { loading: uploadLoading }] = useMutation(UPLOAD_SHOP_FILE);

  const offeringImageInput = useRef<any>(null);
  const [membershipPlanOptions, setMembershipPlanOptions] = useState<any[]>([]);
  const [isPlanOptionsLoading, setIsPlanOptionsLoading] = useState(false);

  useEffect(() => {
    getMembershipPlans();
  }, []);

  const navigateToMembershipPlans = () => {
    navigate('/membership/home');
  };

  const onChangeHandler = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    onChange(key, value);
  };

  const onCurrencyChangeHandler = (value, name) => {
    onChange(name, value);
  };

  const handleOfferingTypeChange = ({ value }) => {
    onChange('type', value);
  };

  useEffect(() => {
    if (productData?.type === 'LEAD') {
      onChange('paymentSchedule', PAYMENT_SCHEDULES.FREE);
    }
  }, [productData?.type]);

  const handlePaymentScheduleChange = ({ value }) => {
    onChange('paymentSchedule', value);
  };

  const handleMembershipPlanChange = ({ value }) => {
    onChange('membershipPlanId', value);
  };

  const handleOfferingImageSelection = async (e) => {
    if (!e.target.files?.[0]) {
      alert.info('Attachment failed, please try again later.');
    }

    const file = e.target.files?.[0];

    if (file?.size > 2 * 1024 * 1024) {
      alert.info('File too large, must be less than 2MB.');
      offeringImageInput.current.value = '';
    }

    try {
      const result = await getBase64(file);
      const { data: { uploadShopFile } = {} } = await uploadFile({
        variables: {
          input: {
            organizationId: organization?.id,
            file: {
              name: file.name,
              mime: file?.type,
              data: result as string,
            },
          },
        },
      });

      if (!uploadShopFile.success) {
        throw new Error();
      }

      onChange('offeringImage', { name: file.name, mime: file?.type, data: result as string });
      const previewUrl = URL.createObjectURL(file);
      onChange('image', { key: uploadShopFile?.data?.fileKey, link: previewUrl });
    } catch (error) {
      alert.info('Attachment failed, please try again later.');
    }
  };

  const handleUploadImageClick = () => {
    offeringImageInput?.current?.click();
  };

  const handleRemoveOfferingImage = () => {
    offeringImageInput.current.value = '';
    onChange('image', null);
  };

  const switchHandler = (e) => {
    onChange('status', e.target.checked ? 'LIVE' : 'DRAFT');
  };

  const getMembershipPlans = async () => {
    try {
      setIsPlanOptionsLoading(true);
      const { data: { fetchOrganizationsMembershipPlans } = {} } = await client.query({
        query: GET_MEMBERSHIP_PLANS,
        variables: {
          input: {
            pagination: {
              limit: 20,
              offset: 0,
              order: 'DESC',
              orderBy: 'createdAt',
            },
            organizationId: organization?.id,
          },
        },
      });

      if (fetchOrganizationsMembershipPlans.contents.length > 0) {
        setMembershipPlanOptions(
          fetchOrganizationsMembershipPlans.contents?.map((membershipPlan) => ({
            value: membershipPlan.id,
            label: membershipPlan.name,
          })),
        );
      }
    } catch (err) {
      alert.error("Couldn't fetch membership plans");
    } finally {
      setIsPlanOptionsLoading(false);
    }
  };

  const renderPriceInputs = () => {
    if (productData?.type === 'LEAD') {
      return (
        <>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>Monthly Payment (Optional; For As Low As Pricing)</FormLabel>
              <StyledCurrencyInput
                data-testid="shop-product-monthly-payment"
                id="shop-product-monthly-payment"
                placeholder="Monthly Payment"
                name="monthlyPayment"
                className={`form-control`}
                value={productData?.monthlyPayment}
                onValueChange={onCurrencyChangeHandler}
                prefix="$"
                decimalScale={2}
                step={1}
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>Number of Months (Optional; For As Low As Pricing)</FormLabel>
              <StyledTextField
                size="small"
                type="number"
                placeholder="Number of Months"
                inputProps={{
                  'data-inputkey': 'numberOfMonths',
                }}
                id="shop-product-offering-number-of-monthly-payments"
                data-testid="shop-product-offering-number-of-monthly-payments"
                onChange={onChangeHandler}
                value={productData?.numberOfMonths}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>APR (Optional; For As Low As Pricing)</FormLabel>
              <StyledCurrencyInput
                data-testid="shop-product-monthly-payment"
                id="shop-product-monthly-payment"
                placeholder="APR"
                name="apr"
                className={`form-control`}
                value={productData?.apr}
                onValueChange={onCurrencyChangeHandler}
                prefix="%"
                decimalScale={2}
                step={1}
              />
            </FormControl>
          </Grid>
        </>
      );
    }
    switch (productData?.paymentSchedule) {
      case PAYMENT_SCHEDULES.ONCE_UP_FRONT:
        return (
          <>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true} variant="outlined">
                <FormLabel>Price</FormLabel>
                <StyledCurrencyInput
                  data-testid="shop-product-price"
                  id="shop-product-price"
                  placeholder="Price"
                  name="price"
                  className={`form-control`}
                  value={productData?.price}
                  onValueChange={onCurrencyChangeHandler}
                  prefix="$"
                  decimalScale={2}
                  step={1}
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={4}>
              <FormControl fullWidth={true} variant="outlined">
                <FormLabel>"Compare At" Price</FormLabel>
                <StyledCurrencyInput
                  data-testid="shop-product-compare-at-price"
                  id="shop-product-compare-at-price"
                  placeholder='"Compare At" Price'
                  name="compareAtPrice"
                  className={`form-control`}
                  value={productData?.compareAtPrice}
                  onValueChange={onCurrencyChangeHandler}
                  prefix="$"
                  decimalScale={2}
                  step={1}
                />
              </FormControl>
            </Grid>
          </>
        );
      case PAYMENT_SCHEDULES.MONTHLY:
        return (
          <>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true} variant="outlined">
                <FormLabel>Monthly Payment</FormLabel>
                <StyledCurrencyInput
                  data-testid="shop-product-monthly-payment"
                  id="shop-product-monthly-payment"
                  placeholder="Monthly Payment"
                  name="monthlyPayment"
                  className={`form-control`}
                  value={productData?.monthlyPayment}
                  onValueChange={onCurrencyChangeHandler}
                  prefix="$"
                  decimalScale={2}
                  step={1}
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={4}>
              <FormControl fullWidth={true} variant="outlined">
                <FormLabel>Number of Monthly Payments</FormLabel>
                <StyledTextField
                  size="small"
                  type="number"
                  placeholder="Number of Monthly Payments"
                  inputProps={{
                    'data-inputkey': 'numberOfMonths',
                  }}
                  id="shop-product-offering-number-of-monthly-payments"
                  data-testid="shop-product-offering-number-of-monthly-payments"
                  onChange={onChangeHandler}
                  value={productData?.numberOfMonths}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </>
        );
      case PAYMENT_SCHEDULES.FREE:
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container={true} justifyContent="space-between" alignItems="center">
        <Grid item={true}>
          <SectionTitle>Offering Info</SectionTitle>
        </Grid>
        <Grid item={true}>
          <Grid container={true}>
            <SwitchLabel>Draft</SwitchLabel>
            <Switch id={'1'} name={'switch'} handleClick={switchHandler} isChecked={productData?.status === 'LIVE'} />
            <SwitchLabel>Live</SwitchLabel>
          </Grid>
        </Grid>
      </Grid>

      <Grid container={true} spacing={2} alignItems="center">
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Offering Title</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Offering Title"
              inputProps={{
                'data-inputkey': 'title',
              }}
              id="shop-product-offering-title"
              data-testid="shop-product-offering-title"
              onChange={onChangeHandler}
              value={productData?.title}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Offering Type</FormLabel>
            <DropDownBorder style={{ width: '100%' }}>
              <DropDown
                maxHeight={'235'}
                ignoreInitialFirstItem={true}
                options={OFFERING_TYPES}
                placeholder="Select a type"
                placeholderColor={'#DADADA'}
                defaultValue={productData?.type}
                onChange={handleOfferingTypeChange}
                hoveredMode={false}
                isOptionsBold={true}
              />
            </DropDownBorder>
          </FormControl>
        </Grid>

        {productData?.type && (
          <>
            {productData?.type !== 'MEMBERSHIP' ? (
              <>
                <Grid item={true} xs={productData?.type === 'LEAD' ? 6 : 4}>
                  <FormControl fullWidth={true} variant="outlined" margin="dense">
                    <FormLabel>Payment Schedule</FormLabel>
                    <DropDownBorder style={{ width: '100%' }}>
                      <DropDown
                        maxHeight={'235'}
                        ignoreInitialFirstItem={true}
                        options={PAYMENT_SCHEDULE_OPTIONS}
                        placeholder="Select a payment schedule"
                        placeholderColor={'#DADADA'}
                        defaultValue={productData?.paymentSchedule}
                        onChange={handlePaymentScheduleChange}
                        hoveredMode={false}
                        disabled={productData?.type === 'LEAD'}
                      />
                    </DropDownBorder>
                  </FormControl>
                </Grid>

                {renderPriceInputs()}
              </>
            ) : (
              <Grid container={true} item={true} alignItems="flex-end" spacing={2}>
                <Grid item={true} xs={6}>
                  <FormControl fullWidth={true} variant="outlined" margin="dense">
                    <FormLabel>Plan Name</FormLabel>
                    {!isPlanOptionsLoading ? (
                      <DropDownBorder style={{ width: '100%' }}>
                        <DropDown
                          maxHeight={'235'}
                          ignoreInitialFirstItem={true}
                          options={membershipPlanOptions}
                          placeholder="Plan Name"
                          placeholderColor={'#DADADA'}
                          onChange={handleMembershipPlanChange}
                          defaultValue={productData?.membershipPlanId?.toString()}
                          hoveredMode={false}
                        />
                      </DropDownBorder>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item={true}>
                  <FormControl fullWidth={true} variant="outlined" margin="dense">
                    <FormLabel>{'  '}</FormLabel>
                    <ButtonPrimary text="Edit Plans" onClick={navigateToMembershipPlans} />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container={true} item={true} alignItems="flex-start" spacing={2}>
              <Grid item={true} xs={6}>
                <FormControl fullWidth={true} variant="outlined" margin="dense">
                  <FormLabel>Label</FormLabel>
                  <StyledTextField
                    type="text"
                    placeholder="Label"
                    inputProps={{
                      'data-inputkey': 'labels[0]',
                    }}
                    id="shop-product-offering-label"
                    data-testid="shop-product-offering-label"
                    onChange={onChangeHandler}
                    value={productData?.labels?.[0]}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item={true} xs={6} alignContent="flex-start">
                <FormControl fullWidth={true} variant="outlined" margin="dense">
                  <FormLabel>Offering Image</FormLabel>
                  <Row>
                    <div style={{ width: 'min-content', marginRight: '24px' }}>
                      <ButtonPrimary
                        text={
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleOfferingImageSelection}
                              hidden={true}
                              ref={offeringImageInput}
                            />
                            Upload
                          </>
                        }
                        onClick={handleUploadImageClick}
                        loading={uploadLoading}
                      />
                    </div>
                    {productData?.image?.link && (
                      <Row style={{ width: 'unset' }} alignItems="flex-end">
                        <div>
                          <img src={productData?.image?.link} alt="Offering Image" width={200} height={150} />
                          <OfferingImageName>{productData?.offeringImage?.name}</OfferingImageName>
                        </div>
                        <RemoveButton onClick={handleRemoveOfferingImage}>
                          Remove
                          <TrashIcon color="red" width={18} height={18} />
                        </RemoveButton>
                      </Row>
                    )}
                  </Row>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item={true} xs={12}>
              <FormControl fullWidth={true} variant="outlined" margin="dense">
                <FormLabel>Detailed Description</FormLabel>
                <StyledTextField
                  type="text"
                  multiline={true}
                  minRows={4}
                  placeholder="Description"
                  inputProps={{
                    'data-inputkey': 'description',
                  }}
                  id="shop-product-offering-description"
                  data-testid="shop-product-offering-description"
                  onChange={onChangeHandler}
                  value={productData?.description}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const RemoveButton = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(236, 51, 96, 1);
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const SwitchLabel = styled.span`
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin: 0 6px;
`;

const OfferingImageName = styled.div`
  font-family: Open Sans;
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-top: 8px;
`;

const SectionTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';
  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

export default OfferingInfo;
