import styled from 'styled-components';

export const IframeWrapper = styled.div`
  position: relative;
  width: 568px;
  height: 310px;
`;

export const OutlinedButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.main.midnightBlue};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background-color: ${(props) => props.theme.main.white};
  margin-top: 0;
  font-family: 'Open Sans';
`;
