import { FormControl } from '@mui/material';
import { DropDown } from 'lib/components';
import ModalV2 from 'lib/components/ModalV2/ModalV2';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { DropdownItem } from 'pages/desktop/Membership/type';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

interface Props {
  visible: boolean;
  onCancel: () => void;
  sendApplication: () => void;
  sendApplyCheckout: ({ merchantId, treatmentAmount }: { merchantId: number; treatmentAmount: number }) => void;
  phone: string | undefined;
}

const ApplyCheckoutModal = ({ visible, onCancel, sendApplication, sendApplyCheckout, phone }: Props) => {
  const { organization, merchants } = useStore();
  const { trackSegmentEvent } = useSegment();

  const [treatmentAmount, setTreatmentAmount] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<any>(merchants!.length === 1 ? merchants![0].id : undefined);

  const isLocationSelectVisible = treatmentAmount && merchants!.length > 1;

  useEffect(() => {
    if (isLocationSelectVisible) {
      trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_LOCATION_VIEWED', { phone });
    }
  }, [isLocationSelectVisible]);

  const treatmentCostInputRef = useCallback((node) => {
    if (node) {
      node.children[1]?.focus();
    }
  }, []);

  const handleTreatmentAmountChange = (value: string | undefined) => {
    setTreatmentAmount(value);
  };

  const handleTreatmentCostFocus = () => {
    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_TREATMENT_COST_SELECTED', { phone });
  };

  const handleTreatmentCostBlur = (e) => {
    if (!treatmentAmount) return;

    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_TREATMENT_COST_FILLED', {
      phone,
      treatmentCost: e.target.value,
    });
  };

  const locationOptions: { value: string; label: string }[] | undefined = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: `<span>${organization?.name}</span> - <span>${merchant?.name}</span>`,
    };
  });

  const locationChange = (item: DropdownItem) => {
    setSelectedLocation(item.value);
    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_LOCATION_FILLED', {
      phone,
      location: {
        id: item.value,
        name: item.label.replaceAll(/<span>|<\/span>/gi, ''),
      },
    });
  };

  const handleLocationSelectFocus = () => {
    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_LOCATION_SELECTED', {
      phone,
    });
  };

  const handleCancel = () => {
    setTreatmentAmount(undefined);
    if (merchants!.length > 1) {
      setSelectedLocation(undefined);
    }

    onCancel();
  };

  const handleSubmit = () => {
    const selectedLocationOption = locationOptions?.find((o) => o.value === selectedLocation);

    trackSegmentEvent('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBO_MODAL_SUBMITTED', {
      phone,
      treatmentCost: treatmentAmount,
      location: {
        id: selectedLocationOption?.value,
        name: selectedLocationOption?.label.replaceAll(/<span>|<\/span>/gi, ''),
      },
    });

    if (treatmentAmount) {
      sendApplyCheckout({
        treatmentAmount: Number(treatmentAmount),
        merchantId: selectedLocation,
      });
    } else {
      sendApplication();
    }
    handleCancel();
  };

  const isSubmitDisabled = () => {
    const hasSingleLocation = locationOptions?.length === 1;
    const hasValid = (treatmentAmount && selectedLocation) || (!treatmentAmount && !selectedLocation);

    const shouldDisableSubmit = !(hasSingleLocation || hasValid);

    return shouldDisableSubmit;
  };

  return (
    <ModalV2
      title="Do you know the treatment cost?"
      open={visible}
      showClose={true}
      onCancel={handleCancel}
      hideCancelButton={true}
      submitButtonLabel="Send Link Now"
      isSubmitDisabled={isSubmitDisabled()}
      onSubmit={handleSubmit}
    >
      <div className="mb-5">
        Including cost lets patients <b>apply and checkout</b> at the same time!
      </div>
      <FormControl fullWidth={true} variant="outlined" margin="dense" ref={treatmentCostInputRef}>
        <FormLabel>Optional: Treatment cost (including tax)</FormLabel>
        <StyledCurrencyInput
          placeholder="Treatment Cost"
          name="treatmentCost"
          className={`form-control`}
          value={treatmentAmount}
          onValueChange={handleTreatmentAmountChange}
          prefix="$"
          decimalScale={2}
          step={1}
          allowNegativeValue={false}
          onFocus={handleTreatmentCostFocus}
          onBlur={handleTreatmentCostBlur}
        />
      </FormControl>

      {isLocationSelectVisible && (
        <div className="mt-4">
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Checkout location (since you have multiple offices)</FormLabel>
            <StyledDropDownBorder>
              <DropDown
                options={[
                  {
                    value: '',
                    label: 'Select a Location',
                    canSelect: false,
                  },
                  ...locationOptions!,
                ]}
                defaultValue={selectedLocation}
                onChange={locationChange}
                hoveredMode={false}
                onExpand={handleLocationSelectFocus}
              />
            </StyledDropDownBorder>
          </FormControl>
        </div>
      )}
    </ModalV2>
  );
};

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  display: flex;
  gap: 8px;
`;

const StyledDropDownBorder = styled(DropDownBorder)`
  width: 100% !important;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';

  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;

export default ApplyCheckoutModal;
