import { SearchAPIOperators } from 'lib/types/SearchApi';

export const FetchLoanListSearch = (
  selectedDate,
  merchants,
  selectedDisbursmentTypes,
  defaultDisbursmentTypes,
  searchInput,
) => {
  const searchCriteriaItems = [
    {
      fieldName: 'merchantId',
      fieldValue: merchants,
      searchOperation: SearchAPIOperators.IN,
      logicOperator: 'AND',
    },
    {
      fieldName: 'fundedAt',
      fieldValue: selectedDate.from,
      searchOperation: SearchAPIOperators.GREATER_THAN,
      logicOperator: 'AND',
    },
    {
      fieldName: 'fundedAt',
      fieldValue: selectedDate.to,
      searchOperation: SearchAPIOperators.LESS_THAN,
      logicOperator: 'AND',
    },
  ];

  if (selectedDisbursmentTypes?.length > 0) {
    searchCriteriaItems.push(
      ...selectedDisbursmentTypes.map((status) => ({
        fieldName: 'status',
        fieldValue: status,
        searchOperation: SearchAPIOperators.CONTAINS,
        logicOperator: 'AND',
      })),
    );
  } else {
    searchCriteriaItems.push({
      fieldName: 'status',
      fieldValue: defaultDisbursmentTypes?.join(','),
      searchOperation: SearchAPIOperators.IN,
      logicOperator: 'AND',
    });
  }

  if (searchInput) {
    searchCriteriaItems.push(
      ...[
        {
          fieldName: 'contractId',
          fieldValue: searchInput,
          searchOperation: SearchAPIOperators.CONTAINS,
          logicOperator: 'OR',
        },
        {
          fieldName: 'borrowerName',
          fieldValue: searchInput,
          searchOperation: SearchAPIOperators.CONTAINS,
          logicOperator: 'OR',
        },
        {
          fieldName: 'borrowerPhone',
          fieldValue: searchInput,
          searchOperation: SearchAPIOperators.CONTAINS,
          logicOperator: 'OR',
        },
      ],
    );
  }

  return searchCriteriaItems;
};
