import { gql } from '@apollo/client/core';

export const CREATE_MEMBERSHIP_PLAN = gql`
  mutation createMembershipsPlan($input: CreateMembershipsPlanInput) {
    createMembershipsPlan(input: $input) {
      id
      name
    }
  }
`;

export const PATCH_MEMBERSHIP_PLAN = gql`
  mutation patchMembershipsPlan($input: PatchMembershipsPlanInput) {
    patchMembershipsPlan(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_MEMBERSHIP_PLAN = gql`
  mutation deleteMembershipsPlan($input: DeleteMembershipsPlanInput) {
    deleteMembershipsPlan(input: $input) {
      success
    }
  }
`;

export const PATCH_CUSTOMER_BENEFIT_USAGE = gql`
  mutation patchCustomerBenefitUsages($input: PatchCustomerBenefitsUsagesInput) {
    patchCustomerBenefitUsages(input: $input) {
      success
    }
  }
`;

export const CREATE_CUSTOMER_MEMBERSHIP_PLAN = gql`
  mutation createCustomerMembershipsPlan($input: CreateCustomerMembershipsPlanInput) {
    createCustomerMembershipsPlan(input: $input) {
      id
      planName
      message
      customer {
        firstName
        lastName
      }
    }
  }
`;

export const PATCH_CUSTOMER_MEMBERSHIP_PLAN = gql`
  mutation patchCustomerMembershipsPlan($input: PatchCustomerMembershipsPlanInput) {
    patchCustomerMembershipsPlan(input: $input) {
      id
      planName
      message
      customer {
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_MEMBERSHIP_CUSTOMER = gql`
  mutation createMembershipCustomer($input: CreateMembershipCustomerInput) {
    createMembershipCustomer(input: $input) {
      id
      firstName
      lastName
      phone
      email
      message
    }
  }
`;

export const PATCH_MEMBERSHIP_CUSTOMER = gql`
  mutation patchMembershipCustomer($input: PatchMembershipCustomerInput) {
    patchMembershipCustomer(input: $input) {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

export const PATCH_SUBSCRIPTIONS_CUSTOMER = gql`
  mutation patchMembershipCustomer($input: PatchMembershipCustomerInput) {
    patchMembershipCustomer(input: $input) {
      id
      firstName
      lastName
      phone
      email
      createdAt
    }
  }
`;

export const CREATE_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD = gql`
  mutation createMembershipsCustomerPaymentMethod($input: CreateMembershipsCustomerPaymentMethodInput) {
    createMembershipsCustomerPaymentMethod(input: $input) {
      id
      customerId
      status
      last4
      network
      type
      expireMonth
      expireYear
      createdAt
      updatedAt
    }
  }
`;

export const PATCH_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD = gql`
  mutation patchMembershipsCustomerPaymentMethod($input: PatchMembershipsCustomerPaymentMethodInput) {
    patchMembershipsCustomerPaymentMethod(input: $input) {
      id
      customerId
      status
      last4
      network
      type
      expireMonth
      expireYear
      createdAt
      updatedAt
    }
  }
`;

export const SEND_MEMBERSHIP_CUSTOMER_SMS = gql`
  mutation sendMembershipsCustomerSms($input: SendMembershipCustomerSmsInput) {
    sendMembershipsCustomerSms(input: $input) {
      success
      message
      error
    }
  }
`;

export const PATCH_WIDGET_SETTINGS = gql`
  mutation patchWidgetSettings($input: PatchWidgetSettingsInput) {
    patchWidgetSettings(input: $input) {
      id
      organizationId
      color
      font
    }
  }
`;

export const PATCH_ORGANIZATION_ACCOUNT_INFORMATION = gql`
  mutation patchOrganizationAccountInformation($input: PatchOrganizationAccountInformationInput) {
    patchOrganizationAccountInformation(input: $input) {
      success
    }
  }
`;

export const SEND_BULK_MEMBERSHIP_SMS = gql`
  mutation sendBulkMembershipsCustomerSms($input: SendBulkMembershipCustomerSmsInput) {
    sendBulkMembershipsCustomerSms(input: $input) {
      success
      message
    }
  }
`;
