import { useMutation } from '@apollo/client';
import { GET_ORGANIZATION_USERS } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { useAlert } from 'react-alert';
import { useEffect, useState } from 'react';
import { FETCH_ORGANIZATION_MERCHANT_USERS } from 'lib/graphql/queries';
import client from 'lib/graphql/client';
import { PocUser } from 'lib/types';
import dayjs from 'dayjs';

export const useUserManagement = () => {
  const alert = useAlert();
  const { organization, merchants, setOrganizationMerchants, setPocUsers } = useStore();

  const idOrganization = organization?.id || 0;

  const [inlinePocUsers, setInlinePocUsers] = useState<PocUser[]>([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [isPocLoading, setIsPocLoading] = useState(false);

  let _merchantUsers: any[] = [];

  const [getOrganizationUsers] = useMutation(GET_ORGANIZATION_USERS);

  // FETCH ORGANIZATION USER
  const fetchOrganizationUsers = async () => {
    try {
      const { data } = await getOrganizationUsers({ variables: { input: { idOrganization } } });

      if (data.fetchOrganizationUsers.success) {
        data?.fetchOrganizationUsers?.data.forEach((orgUser) => {
          orgUser.location = merchants?.length;
          orgUser.merchantIds = merchants?.map((merch) => merch.id) || [];
        });

        setOrganizationData(data?.fetchOrganizationUsers?.data);
      } else {
        setOrganizationData([]);
      }
    } catch (error) {
      alert.error('An error occurred');
    }
  };

  // FETCH MERCHANT USERS
  const fetchMerchantUsers = async () => {
    try {
      const { data: { fetchOrganizationMerchants } = {} } = await client.query({
        query: FETCH_ORGANIZATION_MERCHANT_USERS,
        fetchPolicy: 'no-cache',
        variables: { input: { idOrganization: organization?.id } },
      });
      setOrganizationMerchants(fetchOrganizationMerchants?.data || []);
    } catch (error) {
      alert.error('An error occurred');
    }
  };

  const findUniqueUsers = (data) => {
    if (data) {
      const uniqueUsers: PocUser[] = [];
      data.forEach((datum) => {
        const findUserIsExist = uniqueUsers?.find((unique: PocUser) => unique?.user?.email === datum?.user?.email);
        if (findUserIsExist) {
          findUserIsExist.merchantIds = datum.merchantIds;
        } else {
          uniqueUsers.push(datum);
        }
      });
      return uniqueUsers;
    }
    return [];
  };

  const traverseAndFillIds = (commonUserMerchants, item, merchantIds) => {
    const merchantUser = _merchantUsers?.find((u) => u?.user?.email === item?.user?.email);
    if (!merchantUser) return;
    const merchantsOfUser = commonUserMerchants?.map((commonMerchant) => commonMerchant.merchant.id);
    merchantIds.push(...merchantsOfUser);
  };

  const findUniqueUserMerchants = (data) => {
    if (data) {
      const uniqueData: any = [];
      data.forEach((item: any) => {
        const commonUserMerchants = data.filter((it) => it?.user?.email === item?.user?.email);

        const merchantIds: string[] = [];
        traverseAndFillIds(commonUserMerchants, item, merchantIds);

        item.location = commonUserMerchants?.length;
        item.email = item?.user?.email;
        item.merchantIds = merchantIds;
        item.merchantGroupIds = [];
        if (!uniqueData.find((unique: any) => unique.email === item?.user?.email)) {
          uniqueData.push(item);
        }
      });

      return uniqueData;
    }
    return [];
  };

  useEffect(() => {
    setIsPocLoading(true);
    fetchOrganizationUsers();
    fetchMerchantUsers();
  }, []);

  useEffect(() => {
    if (merchants && merchants.length > 0) {
      merchants?.forEach((merchant) => {
        if (merchant?.merchantUsers) _merchantUsers = [..._merchantUsers, ...merchant?.merchantUsers];
      });
    }
    setMerchantData(findUniqueUserMerchants(_merchantUsers));
  }, [merchants]);

  useEffect(() => {
    const finalData = [...organizationData, ...merchantData];
    const uniquesUsers = findUniqueUsers(finalData);
    const sortUsersByDate = (users) => {
      return users.sort((prev, next) => dayjs(next?.createdAt).diff(dayjs(prev?.createdAt)));
    };

    const sortedArray = sortUsersByDate(uniquesUsers);
    setIsPocLoading(false);
    setPocUsers(sortedArray);
    setInlinePocUsers(sortedArray);
  }, [organizationData, merchantData]);

  return { inlinePocUsers, isPocLoading };
};
