import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';

import images from 'assets/images';
import { FETCH_BANNER } from 'lib/graphql/queries';
import StorageService from 'lib/services/Storage';

interface BannerProps {
  showForPractice: boolean;
  practiceText: string;
}

export const Banner = () => {
  const client = useApolloClient();
  const { token } = StorageService.getAuthData();

  const [banner, setBanner] = useState<BannerProps | null>(null);
  const [showBanner, setShowBanner] = useState(false);

  const getBanner = async () => {
    try {
      const {
        data: { fetchBanner },
      } = await client.query({
        query: FETCH_BANNER,
        variables: {},
      });

      if (fetchBanner?.length > 0) {
        setBanner(fetchBanner?.[0]);
        setShowBanner(fetchBanner?.[0]?.showForPractice);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const hideBanner = () => setShowBanner(false);

  useEffect(() => {
    if (token) {
      getBanner?.();
    }
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <Container>
      <CloseIcon src={images.close.default} onClick={hideBanner} />
      <HtmlContent dangerouslySetInnerHTML={{ __html: banner?.practiceText || '' }} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 100;
  left: 0;
  height: 90px;
  text-align: center;
  background-color: #e6cb72;
  padding: 10px 0;
  font-weight: bold;
  width: 100%;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    padding: 12px 24px;
    width: calc(100% - 48px);
  }

  p,
  h4 {
    padding: 0;
    margin: 0;
    font-size: 14px;

    @media (max-width: ${(props) => props.theme.size.mobileXl}) {
      font-size: 12px;
    }
  }

  p {
    font-weight: 500;
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 18px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    top: 34px;
    right: 12px;
  }
`;

const HtmlContent = styled.div`
  padding: 12px 0;
  width: 100%;

  > p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    padding: 12px 0;
  }
`;
