import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import React from 'react';
import {
  BorderLine,
  ContentContainer,
  ContentDescription,
  ContentLink,
  InfoContainer,
  ProfileIcon,
  StyledButtonPrimary,
  StyledColumn,
  SupportTitle,
} from './CherryTeam';
import { Accounts } from 'lib/types/Merchant';

export const Practice = ({ accountList }: { accountList: Accounts[] }) => {
  const { merchants = [] } = useStore();
  const getAccountsByType = (type: string) => accountList?.filter((data) => data?.type === type);

  const handleBookAppointment = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <SupportTitle>Practice Development</SupportTitle>
      <Row fontSize={'14px'}>
        Questions about promoting Cherry to your patients and growing your Cherry usage should be directed to your
        Practice Development Representative. These representatives are assigned based on geographic location.
      </Row>

      {getAccountsByType('PRACTICE')?.map((account, index) => {
        return (
          <Row key={`practice-${index}`}>
            <Column>
              <Row noWrap={true} alignItems={'flex-start'}>
                <InfoContainer>
                  <ProfileIcon src={'user_circle.svg'} />
                  <ContentContainer>
                    {account?.name ? <ContentDescription>{account?.name}</ContentDescription> : null}
                    {account?.phone ? <ContentLink href={`tel:${account?.phone}`}>{account?.phone}</ContentLink> : null}
                    {account?.email ? (
                      <ContentLink href={`mailto:${account?.email}`}>{account?.email}</ContentLink>
                    ) : null}
                  </ContentContainer>
                </InfoContainer>
                <InfoContainer>
                  <ContentContainer>
                    <ContentDescription bold={true} fontSize={'12px'}>
                      Locations:
                    </ContentDescription>
                    {merchants?.map((merchant, merchantIndex) => {
                      return (
                        <ContentDescription key={`cherry-team-merchant-${merchantIndex}`} fontSize={'12px'}>
                          {merchant?.name} - {merchant?.address?.city?.name}
                        </ContentDescription>
                      );
                    })}
                  </ContentContainer>
                </InfoContainer>
              </Row>
            </Column>
            {account?.bookingLink ? (
              <StyledColumn>
                <StyledButtonPrimary
                  data-testid="scheduleMeetingButton"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleBookAppointment(account?.bookingLink)}
                  text={'Book Appointment'}
                />
              </StyledColumn>
            ) : null}
          </Row>
        );
      })}

      <BorderLine />
    </>
  );
};
