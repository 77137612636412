import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import styled from 'styled-components';

import React from 'react';

interface Props {
  refreshTable: () => void;
  loading: boolean;
  applicationTab: boolean;
  syncStatus: SyncStatus;
}

interface SyncStatus {
  syncedAt: string;
  syncedBy: string;
}

const SyncButtons = ({ refreshTable, loading, applicationTab, syncStatus }: Props) => {
  return (
    <ButtonContainer>
      <ButtonSecondary refreshing={loading} onClick={refreshTable}>
        <CenterStuff>
          <RefreshIconContainer loading={loading}>
            <FaIcon icon={faSync} loading={loading} />
          </RefreshIconContainer>

          {!loading ? (!applicationTab ? 'Sync Appointments' : 'Sync Applications') : 'Refreshing'}
          {!loading && !applicationTab && syncStatus?.syncedAt && (
            <LastSynced>
              Last sync: {dayjs(syncStatus.syncedAt).format('MMM DD, YYYY')} at{' '}
              {dayjs(syncStatus.syncedAt).format('hh:mm A')}
            </LastSynced>
          )}
        </CenterStuff>
      </ButtonSecondary>
    </ButtonContainer>
  );
};

export default SyncButtons;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonSecondary = styled.div<{ refreshing: boolean }>`
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const CenterStuff = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RefreshIconContainer = styled.div<{ loading: boolean }>`
  padding: ${(props) => props.theme.constants.s} 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const FaIcon = styled(FontAwesomeIcon)<{ loading: boolean; color?: string }>`
  color: ${(props) => props.theme.main.green};
  animation-name: ${(props) => (props.loading ? 'spin' : '')};
  animation-duration: 687ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const LastSynced = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.main.sealGray};
  margin-left: 8px;
  padding-top: 2px;
`;
