import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonContainer, Container, ContentContainer, Subtitle, Title } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';

export const MobilePreApprovalInfo = () => {
  const navigate = useNavigate();
  const { isSnackbarVisible } = useSnackbar();

  const goBack = () => navigate('/dashboard');

  return (
    <Container pageTitle="Application Details" backUrl="/dashboard">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Pre-Approved</Title>
        <Subtitle m={'0 0 8px 0'}>
          This customer has been pre-approved. They'll need to complete a full application before you can check them
          out.
        </Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button secondary={true} onClick={goBack}>
          Back
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MobilePreApprovalInfo;
