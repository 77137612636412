import { useApolloClient } from '@apollo/client';
import { GET_MEMBERSHIPS_SUMMARY_METRICS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface SummaryMetrics {
  activeMembers: number;
  revenueMonthToDate: number;
  revenueYearToDate: number;
}

export const InfoCards = () => {
  const client = useApolloClient();
  const { organization } = useStore();
  const [summary, setSummary] = useState<SummaryMetrics>();

  const fetchSummaryMetrics = async () => {
    const {
      data: { getMembershipsSummaryMetrics },
    } = await client.query({
      query: GET_MEMBERSHIPS_SUMMARY_METRICS,
      variables: {
        input: {
          organizationId: organization?.id,
        },
      },
    });

    setSummary(getMembershipsSummaryMetrics);
  };

  useEffect(() => {
    fetchSummaryMetrics();
  }, []);

  return (
    <Container>
      <Card>
        <Title>{summary?.activeMembers || 0}</Title>
        <Description>Shop Views</Description>
      </Card>
      <Card>
        <Title>{CurrencyUtil.toCurrencyString(summary?.revenueMonthToDate || 0)}</Title>
        <Description>Revenue</Description>
      </Card>
      <Card>
        <Title>{summary?.activeMembers || 0}</Title>
        <Description>Leads Generated</Description>
      </Card>
      <Card>
        <Title>{CurrencyUtil.toCurrencyString(summary?.revenueYearToDate || 0)}</Title>
        <Description>Cherry Pre-Qualified</Description>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  gap: 12px;
  width: 100%;
  flex: 1;
`;

const Card = styled.div`
  display: flex;
  background: #ffffff;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.07), 1px 0.751293px 2.12694px rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  min-height: 111px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #0e202f;
  margin-bottom: 8px;
`;
const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
`;
