import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { useApplicationFilter } from 'lib/services';

interface GridProps {
  scrollOffset: number;
  showRangePicker?: boolean;
  filterWidth?: number;
}

export const ApplicationFilter = () => {
  const { filterRef, filterWidth, intervalLoading, refreshTable, scrollOffset } = useApplicationFilter();

  return (
    <>
      <LeftGrid
        ref={filterRef}
        scrollOffset={scrollOffset}
        filterWidth={filterWidth}
        showRangePicker={false}
        item={true}
        id="applications--filtering"
      >
        <ButtonSecondary onClick={refreshTable} refreshing={intervalLoading}>
          <CenterStuff>
            <RefreshIconContainer loading={intervalLoading}>
              <FaIcon icon={faSync} loading={intervalLoading} />
            </RefreshIconContainer>
            {!intervalLoading ? 'Sync Applications' : 'Refreshing'}
          </CenterStuff>
        </ButtonSecondary>
      </LeftGrid>
    </>
  );
};

const LeftGrid = styled(Grid)<GridProps>`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonSecondary = styled.div<{ refreshing: boolean }>`
  width: 560px;
  align-items: center;
  padding: 3px 0;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => (!props.refreshing ? '#00c37d' : '#FFFFFF')};
  margin-top: -45px;
  margin-left: 200px;
  cursor: pointer;
  text-align: center;
`;

const CenterStuff = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RefreshIconContainer = styled.div<{ loading: boolean }>`
  padding: ${(props) => props.theme.constants.s} 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const FaIcon = styled(FontAwesomeIcon)<{ loading: boolean; color?: string }>`
  color: ${(props) => (!props.loading ? props?.color ?? props.theme.main.green : '#FFFFFF')};
  animation-name: ${(props) => (props.loading ? 'spin' : '')};
  animation-duration: 687ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
