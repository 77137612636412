import { useCheckoutWithPatient } from 'lib/services';
import { FormatAmount } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { calculatePaymentFeeAmount } from '../SetupPayment/utils';
import { Container, Text, Title } from './styles';
import { PaymentMethodFees, PaymentMethodSummaryProps } from './types';

const PaymentMethodSummary = ({ downPaymentMethod, recurringPaymentMethod, loan }: PaymentMethodSummaryProps) => {
  const { getPaymentMethodFees } = useCheckoutWithPatient();

  const [paymentMethodFees, setPaymentMethodFees] = useState<PaymentMethodFees>();

  useEffect(() => {
    handlePaymentMethodFees();
  }, []);

  const handlePaymentMethodFees = async () => {
    const res = await getPaymentMethodFees();
    setPaymentMethodFees(res);
  };

  const cardTitleDownPaymentMethod =
    downPaymentMethod?.type === 'CARD'
      ? `${downPaymentMethod?.storedCard?.network} - ${downPaymentMethod?.storedCard?.type}`
      : `${downPaymentMethod?.achAccount?.bankName}`;

  const cardNumberDownPaymentMethod =
    downPaymentMethod?.type === 'CARD'
      ? downPaymentMethod?.storedCard?.last4
      : downPaymentMethod?.achAccount?.accountNumber?.substring(
          downPaymentMethod?.achAccount?.accountNumber?.length - 4,
        );

  const cardTitleInstallmentMethod =
    recurringPaymentMethod?.type === 'CARD'
      ? `${recurringPaymentMethod?.storedCard?.network} - ${recurringPaymentMethod?.storedCard?.type}`
      : recurringPaymentMethod?.type === 'ACH'
      ? `${recurringPaymentMethod?.achAccount?.bankName}`
      : loan?.autoPay
      ? 'Checks'
      : '';

  const cardNumberInstallmentMethod =
    recurringPaymentMethod?.type === 'CARD'
      ? recurringPaymentMethod?.storedCard?.last4
      : recurringPaymentMethod?.type === 'ACH'
      ? recurringPaymentMethod?.achAccount?.accountNumber?.substring(
          recurringPaymentMethod?.achAccount?.accountNumber?.length - 4,
        )
      : loan?.autoPay
      ? recurringPaymentMethod?.rccAccount?.accountNumber?.substring(
          recurringPaymentMethod?.rccAccount?.accountNumber?.length - 4,
        )
      : '';

  if (!loan || !loan.downPaymentAmount || !loan.installmentAmount) {
    return null;
  }

  const downPaymentFee = paymentMethodFees?.[downPaymentMethod?.storedCard?.type || '']?.percent || 0;
  const installmentPaymentFee = paymentMethodFees?.[recurringPaymentMethod?.storedCard?.type || '']?.percent || 0;

  const downPaymentFeeAmount =
    downPaymentMethod?.type === 'CARD' && downPaymentFee > 0
      ? calculatePaymentFeeAmount(paymentMethodFees, downPaymentMethod?.storedCard?.type, loan.downPaymentAmount)
      : 0;

  const installmentFeeAmount =
    recurringPaymentMethod?.type === 'CARD' && installmentPaymentFee > 0
      ? calculatePaymentFeeAmount(paymentMethodFees, recurringPaymentMethod?.storedCard?.type, loan.installmentAmount)
      : 0;

  return (
    <Container>
      <div>
        <Title>Today’s Down Payment: {FormatAmount(loan.downPaymentAmount + downPaymentFeeAmount)}</Title>
        <Text>{cardTitleDownPaymentMethod}</Text>
        <Text>************ {cardNumberDownPaymentMethod}</Text>
      </div>
      <div>
        <Title>Future Monthly Payments: {FormatAmount(loan.installmentAmount + installmentFeeAmount)}/mo</Title>
        <Text>{cardTitleInstallmentMethod}</Text>
        <Text>************ {cardNumberInstallmentMethod}</Text>
      </div>
    </Container>
  );
};

export default PaymentMethodSummary;
