import images from 'assets/images';
import { useSegment } from 'lib/hooks/useSegment';
import { useSendApplicationLink } from 'lib/services/useSendApplicationLink';
import { CardSubtitle, CardTitle, StyledCard, StyledPopoverWrapper } from 'pages/desktop/Dashboard/DentalDashboard';
import ApplicationLinkHistory from 'pages/desktop/Dashboard/views/ApplicationLinkHistory/ApplicationLinkHistory';
import ApplyCheckoutModal from 'pages/desktop/Dashboard/views/ApplyCheckoutModal';
import { useState } from 'react';
import styled from 'styled-components';
import { SendApplicationLinkFormContainer } from './SendApplicationLinkFormContainer';

export const SendApplicationLinkCard = () => {
  const [isApplicationSentHistoryModalOpen, setIsApplicationSentHistoryModalOpen] = useState(false);

  const { trackSegmentEvent, applicationName, segmentEventHandler } = useSegment();
  const {
    sendApplication,
    applicationSending,
    applicationSent,
    phoneNumber,
    logInputBlur,
    onPhoneChange,
    sendApplyCheckout,
    type,
    email,
    onEmailChange,
    onCombinedFieldChange,
    blurHandler,
    isApplyCheckoutModalVisible,
    handleApplyCheckoutModalClose,
    handleSendClick,
  } = useSendApplicationLink(true);

  const openApplicationSentHistoyModal = () => {
    trackSegmentEvent('View Sent Application Links', {
      application: applicationName,
      component: 'Text Application',
    });

    setIsApplicationSentHistoryModalOpen(true);
  };

  const closeApplicationSentHistoyModal = () => {
    setIsApplicationSentHistoryModalOpen(false);
  };

  return (
    <StyledCard>
      <StyledPopoverWrapper>
        <HistoryIcon src={images.historyIcon.default} alt="historyIcon" onClick={openApplicationSentHistoyModal} />
        <ApplicationLinkHistory
          visible={isApplicationSentHistoryModalOpen}
          onClose={closeApplicationSentHistoyModal}
          tab={type}
        />
      </StyledPopoverWrapper>
      <CardTitle>Send Application Link</CardTitle>
      <CardSubtitle>Text or email an application link to a patient</CardSubtitle>
      <SendApplicationLinkFormContainer
        isFullHeight={false}
        isCombinedField={true}
        handleSendClick={handleSendClick}
        segmentEventHandler={segmentEventHandler}
        type={type}
        phoneNumber={phoneNumber}
        onPhoneChange={onPhoneChange}
        email={email}
        onEmailChange={onEmailChange}
        onCombinedFieldChange={onCombinedFieldChange}
        logInputBlur={logInputBlur}
        blurHandler={blurHandler}
        applicationSending={applicationSending}
        applicationSent={applicationSent}
      />
      <ApplyCheckoutModal
        visible={isApplyCheckoutModalVisible}
        onCancel={handleApplyCheckoutModalClose}
        sendApplication={sendApplication}
        sendApplyCheckout={sendApplyCheckout}
        phone={phoneNumber}
      />
    </StyledCard>
  );
};

export const HistoryIcon = styled.img`
  position: absolute;
  top: -4px;
  right: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;
