import images from 'assets/images';
import { Card } from 'lib/components';
import { ButtonPrimary } from 'lib/components';
import { SendApplicationLinkFormContainer } from 'lib/components/SendApplicationLink/SendApplicationLinkFormContainer';
import { confettiColors } from 'lib/constants';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useSegment } from 'lib/hooks/useSegment';
import { BusEvent, useEventBus, useSendApplicationLink } from 'lib/services';
import ApplicationLinkHistory from 'pages/desktop/Dashboard/views/ApplicationLinkHistory/ApplicationLinkHistory';
import { SendBulkSmsModal } from 'pages/desktop/Dashboard/views/SendBulkSmsModal';
import { ButtonContainer } from 'pages/desktop/Transactions/CommonStyles';
import React, { useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import ApplyCheckoutModal from './ApplyCheckoutModal';

export const SendApplicationLink = ({ isFullHeight }: { isFullHeight: boolean }) => {
  const { flags } = useFlags();
  const { trackSegmentEvent, applicationName, segmentEventHandler } = useSegment();
  const { emitEvent } = useEventBus();

  const {
    applicationSent,
    sendApplication,
    moveApplicationSendView,
    phoneNumber,
    logInputBlur,
    onPhoneChange,
    confettiActive,
    sendApplyCheckout,
    type,
    email,
    toggleSms,
    toggleEmail,
    onEmailChange,
    onCombinedFieldChange,
    blurHandler,
    isApplyCheckoutModalVisible,
    handleApplyCheckoutModalClose,
    handleSendClick,
  } = useSendApplicationLink();

  const [isApplicationSentHistoryModalOpen, setIsApplicationSentHistoryModalOpen] = useState(false);

  const isBulkApplicationSmsActive = false;

  const openApplicationSentHistoyModal = () => {
    trackSegmentEvent('View Sent Application Links', {
      application: applicationName,
      component: 'Text Application',
    });

    setIsApplicationSentHistoryModalOpen(true);
  };

  const closeApplicationSentHistoyModal = () => {
    setIsApplicationSentHistoryModalOpen(false);
  };

  const onClickBulkSend = () => {
    trackSegmentEvent('Start Bulk Send Applications', {
      application: applicationName,
    });

    emitEvent(BusEvent.OPEN_SEND_SMS_MODAL);
  };

  return (
    <SendApplicationCard isFullHeight={isFullHeight}>
      {!applicationSent ? (
        <>
          <SectionContainer isFullHeight={isFullHeight}>
            {isFullHeight && <SectionIcon src={images.textApplicationIcon.default} />}
            <SectionTitleContainer>
              <SectionTitle>Send Application Link</SectionTitle>
              {isFullHeight && <SectionText>Send application link to patient</SectionText>}
              <ToggleContainer type={type}>
                <ToggleItem onClick={toggleSms}>TEXT</ToggleItem>
                <ToggleItem onClick={toggleEmail}>EMAIL</ToggleItem>
              </ToggleContainer>
            </SectionTitleContainer>
          </SectionContainer>
          <SendApplicationLinkFormContainer
            isFullHeight={isFullHeight}
            handleSendClick={handleSendClick}
            segmentEventHandler={segmentEventHandler}
            type={type}
            phoneNumber={phoneNumber}
            onPhoneChange={onPhoneChange}
            email={email}
            onEmailChange={onEmailChange}
            onCombinedFieldChange={onCombinedFieldChange}
            logInputBlur={logInputBlur}
            blurHandler={blurHandler}
          />
        </>
      ) : (
        <>
          {isFullHeight && <ApplicationSent src={images?.applicationSent} />}
          <SectionTitle style={{ marginBottom: '16px' }}>Application Sent!</SectionTitle>
          {applicationSent && confettiActive ? (
            <Confetti
              width={window?.innerWidth}
              height={window?.innerHeight}
              numberOfPieces={300}
              colors={confettiColors}
              recycle={false}
            />
          ) : null}

          <ButtonPrimary text="Send Another Link" onClick={moveApplicationSendView} />
        </>
      )}

      <>
        <GreenText onClick={openApplicationSentHistoyModal}>View Sent Application Links</GreenText>
        <ApplicationLinkHistory
          visible={isApplicationSentHistoryModalOpen}
          onClose={closeApplicationSentHistoyModal}
          tab={type}
        />
      </>

      {isBulkApplicationSmsActive && (
        <>
          <SendSmsButton margin={true} onClick={onClickBulkSend}>
            Bulk Send Application
          </SendSmsButton>

          <SendBulkSmsModal />
        </>
      )}

      <ApplyCheckoutModal
        visible={isApplyCheckoutModalVisible}
        onCancel={handleApplyCheckoutModalClose}
        sendApplication={sendApplication}
        sendApplyCheckout={sendApplyCheckout}
        phone={phoneNumber}
      />
    </SendApplicationCard>
  );
};

const SendSmsButton = styled(ButtonContainer)`
  width: 100%;
  display: flex;
  margin: 12px auto 24px;
  max-width: 259px;
`;

const ApplicationSent = styled.img`
  width: 80px;
  height: auto;
`;

const SendApplicationCard = styled(Card)<{ isFullHeight: boolean }>`
  padding: '20px';
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  align-items: center;
  justify-content: center;
  ${(props) => (props.isFullHeight ? '' : 'height: auto;')};
  width: 100% !important;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: ${(props) => props.theme.main.textColor};
`;

const SectionIcon = styled.img`
  width: 43px;
  height: auto;
`;

const SectionText = styled.p`
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.main.textColor};
`;

const SectionContainer = styled.div<{ isFullHeight: boolean }>`
  display: flex;
  width: 100%;
  ${(props) => props?.isFullHeight && 'flex-direction: column; align-items: center;'}
`;

const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GreenText = styled.div`
  margin-top: 8px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
`;

const ToggleContainer = styled.div<{ type: string | null }>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 8px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    border-bottom: 4px solid #00c37d;

    transition: left 0.2s;
    ${(props) => props.type === 'email' && 'left: 50%'}
    ${(props) => props.type === 'phone' && 'left: 0'}
  }
`;

const ToggleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 8px;
  color: #0e202f;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
`;
