import { useApolloClient, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { PATCH_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { FETCH_TREATMENT_PLANS } from 'lib/graphql/queries/PatientTracker';
import { TreatmentPlans } from 'lib/graphql/searches/patientTrackerSearch';
import { useDebounce } from 'lib/hooks/useDebounced';
import { usePagination } from 'lib/hooks/usePagination';
import { BusEvent, useEventBus } from 'lib/services';
import { Merchant } from 'lib/types';
import React, { useEffect, useRef, useState } from 'react';
import { useSegment } from './useSegment';
import useStore from './useStore';
import { useAlert } from 'react-alert';
import { useSentry } from './useSentry';
import { SEND_APPLICATION_LINK, SEND_SELF_CHECKOUT_LINK } from 'lib/graphql/mutations';
import { isNumber } from 'class-validator';

interface PatientTracker {
  organizationId: number | undefined;
  pagination: {
    limit?: number;
    offset?: number;
    order?: string;
    orderBy?: string;
  };
}

interface StageOption {
  value: string;
  label: string;
}

interface ResendCheckoutLinkModalData {
  show: boolean;
  success: boolean;
  notSupported: boolean;
  patient: { firstName: string; lastName: string };
  isEmail: boolean;
}

export enum STATUSES {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const SMS_NOT_SUPPORTED_CHANNELS = ['PRACTICE_PORTAL_EMAIL', 'CHECKOUT_WITH_PATIENT'];
export enum APPLICATION_STATUSES {
  APPROVED = 'APPROVED',
  ALL_APPLICATION = 'ALL_APPLICATIONS',
  EXPIRING_SOON = 'EXPIRING_SOON',
  PRE_APPROVED = 'PRE_APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  EXPIRED = 'EXPIRED',
  NOT_APPROVED = 'NOT_APPROVED',
}

export const STAGE_OPTIONS = [
  {
    label: 'Patient Created',
    value: 'PATIENT_CREATED',
  },
  {
    label: 'Plan Sent',
    value: 'PLAN_SENT',
  },
  {
    label: 'Treatment Scheduled',
    value: 'TREATMENT_SCHEDULED',
  },
  {
    label: 'Treatment in Progress',
    value: 'TREATMENT_IN_PROGRESS',
  },
  {
    label: 'Treatment Complete',
    value: 'TREATMENT_COMPLETED',
  },
  {
    label: 'Patient Declined',
    value: 'PATIENT_DECLINED',
  },
  {
    label: 'Unresponsive',
    value: 'UNRESPONSIVE',
  },
];

export const APPROVED_PATIENT_STATUSES = ['APPROVED', 'FINANCED'];
export const STATUS_OPTIONS = [
  {
    label: 'All Applications',
    value: APPLICATION_STATUSES.ALL_APPLICATION,
  },
  {
    label: 'Approved',
    value: APPLICATION_STATUSES.APPROVED,
  },
  {
    label: 'Expiring Soon',
    value: APPLICATION_STATUSES.EXPIRING_SOON,
  },
  {
    label: 'Pre-Approved',
    value: APPLICATION_STATUSES.PRE_APPROVED,
  },
  {
    label: 'In Progress',
    value: APPLICATION_STATUSES.IN_PROGRESS,
  },
  {
    label: 'Application Not Started',
    value: APPLICATION_STATUSES.NOT_STARTED,
  },
  {
    label: 'Expired',
    value: APPLICATION_STATUSES.EXPIRED,
  },
  {
    label: 'Not Approved',
    value: APPLICATION_STATUSES.NOT_APPROVED,
  },
];

export const usePatientTracker = ({ triggerRefetch }: { triggerRefetch?: boolean }) => {
  const client = useApolloClient();
  const alert = useAlert();
  const { trackSegmentEvent } = useSegment();
  const { organization, locations } = useStore();
  const { onEvent } = useEventBus();
  const { captureException } = useSentry();
  const { rowsPerPage, setRowsPerPage, paginationLoading, setPaginationLoading, setPage, page } = usePagination({});
  const {
    rowsPerPage: checkoutReadyRowsPerPage,
    setRowsPerPage: setCheckoutReadyRowsPerPage,
    paginationLoading: checkoutReadyPaginationLoading,
    setPage: setCheckoutReadyPage,
    page: checkoutReadyPage,
  } = usePagination({ defaultRowsPerPage: 5 });
  const [patchTreatment] = useMutation(PATCH_TREATMENT_PLAN);
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const [sendSelfCheckoutLink] = useMutation(SEND_SELF_CHECKOUT_LINK);

  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [checkoutReadyData, setCheckoutReadyData] = useState<any[]>([]);
  const [checkoutReadyTotal, setCheckoutReadyTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [checkoutReadyTableLoading, setCheckoutTableLoading] = useState(true);
  const [isCheckoutModalOpened, setIsCheckoutModalOpened] = useState(false);
  const [isEditCancelPlanModalOpened, setIsEditCancelPlanModalOpened] = useState(false);
  const [checkoutItem, setCheckoutItem] = useState();
  const [resendCheckoutLinkModal, setResendCheckoutLinkModal] = useState<ResendCheckoutLinkModalData>({
    show: false,
    success: false,
    notSupported: false,
    patient: { firstName: '', lastName: '' },
    isEmail: false,
  });

  const [searchKey, setSearchKey] = useState('');
  const debouncedSearchKey = useDebounce(searchKey, 200);
  const [selectedStages, setSelectedStages] = useState<StageOption[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>(APPLICATION_STATUSES.ALL_APPLICATION);
  const [selectedLocations, setSelectedLocations] = useState<StageOption[]>([]);
  const [isShowArchived, setIsShowArchived] = useState(false);
  const [patientTrackerReqObj, setPatientTrackerReqObj] = useState<PatientTracker>({
    organizationId: organization?.id,
    pagination: {
      limit: rowsPerPage,
      offset: page - 1,
      order: 'DESC',
      orderBy: 'createdAt',
    },
  });
  const [checkoutReadyPatientTrackerReqObj, setCheckoutReadyPatientTrackerReqObj] = useState<PatientTracker>({
    organizationId: organization?.id,
    pagination: {
      limit: checkoutReadyRowsPerPage,
      offset: checkoutReadyPage - 1,
      order: 'DESC',
      orderBy: 'application.createdAt',
    },
  });
  const checkoutReadyTableStatuses: string[] = [
    APPLICATION_STATUSES.ALL_APPLICATION,
    APPLICATION_STATUSES.APPROVED,
    APPLICATION_STATUSES.EXPIRING_SOON,
  ];
  const allPatientTableStatuses: string[] = [
    APPLICATION_STATUSES.ALL_APPLICATION,
    APPLICATION_STATUSES.PRE_APPROVED,
    APPLICATION_STATUSES.IN_PROGRESS,
    APPLICATION_STATUSES.NOT_APPROVED,
    APPLICATION_STATUSES.NOT_STARTED,
    APPLICATION_STATUSES.EXPIRED,
  ];
  const [extendApprovalPatient, setExtendApprovalPatient] = useState(null);

  const locationOptions = locations?.map((merchant: Merchant) => {
    return { label: merchant.name, value: merchant.id?.toString() };
  });

  const searchInputRef = useRef<any>();
  const fetchTreatments = async () => {
    try {
      setTableLoading(true);

      if (allPatientTableStatuses.includes(selectedStatus)) {
        const {
          data: { fetchTreatmentPlans },
        } = await client.query({
          query: FETCH_TREATMENT_PLANS,
          variables: {
            input: {
              limit: patientTrackerReqObj.pagination.limit,
              offset: patientTrackerReqObj.pagination.offset,
              orderCriteriaItems: [
                {
                  fieldName: patientTrackerReqObj.pagination.orderBy || 'application.createdAt',
                  sort: patientTrackerReqObj.pagination.order,
                },
              ],
              searchCriteriaItems: TreatmentPlans(
                false,
                organization?.id,
                debouncedSearchKey,
                selectedStages,
                selectedLocations,
                isShowArchived,
                selectedStatus,
              ),
              searchType: 'RAW_SEARCH',
            },
          },
        });

        if (fetchTreatmentPlans?.contents) {
          const mappedData = mapDataToTable(fetchTreatmentPlans?.contents);
          setData(mappedData);
          setTotal(fetchTreatmentPlans?.total);
        }
      } else {
        setData([]);
      }

      setTableLoading(false);
    } catch (e) {
      setTableLoading(false);
    }
  };

  const fetchCheckoutReadyTreatments = async () => {
    try {
      setCheckoutTableLoading(true);
      if (checkoutReadyTableStatuses.includes(selectedStatus)) {
        const {
          data: { fetchTreatmentPlans },
        } = await client.query({
          query: FETCH_TREATMENT_PLANS,
          variables: {
            input: {
              limit: checkoutReadyPatientTrackerReqObj.pagination.limit,
              offset: checkoutReadyPatientTrackerReqObj.pagination.offset,
              orderCriteriaItems: [
                {
                  fieldName: checkoutReadyPatientTrackerReqObj.pagination.orderBy || 'application.createdAt',
                  sort: checkoutReadyPatientTrackerReqObj.pagination.order,
                },
              ],
              searchCriteriaItems: TreatmentPlans(
                true,
                organization?.id,
                debouncedSearchKey,
                selectedStages,
                selectedLocations,
                isShowArchived,
                selectedStatus,
              ),
            },
          },
        });

        if (fetchTreatmentPlans?.contents) {
          const mappedData = mapDataToTable(fetchTreatmentPlans?.contents, true);
          setCheckoutReadyData(mappedData);
          setCheckoutReadyTotal(fetchTreatmentPlans?.total);
        }
      } else {
        setCheckoutReadyData([]);
      }
      setCheckoutTableLoading(false);
    } catch (e) {
      setCheckoutTableLoading(false);
    }
  };

  const getMostRecentFundedLoan = (tableData) => {
    const filteredLoans = tableData?.filter((loan) => ['AWAITING_FUNDING', 'FUNDED'].includes(loan?.status));
    const mostRecentLoan = filteredLoans?.sort(
      (a, b) => dayjs().diff(dayjs(a?.fundedAt)) - dayjs().diff(dayjs(b?.fundedAt)),
    )[0];

    return mostRecentLoan;
  };

  const closeExtendApprovalModal = () => {
    setExtendApprovalPatient(null);
  };

  const mapDataToTable = (tableData: any[], isCheckoutReady = false) => {
    const mappedDataTable = tableData.map((d) => {
      const onStageChange = async (val) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,
              stage: val.value,
            },
          },
        });

        findAndSet(d?.id, val.value, 'stage');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };

      const onFinancingChange = async (val) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,
              financingStatus: val.value,
            },
          },
        });

        findAndSet(d?.id, val?.value, 'financingStatus');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };

      const onNoteChange = async (val) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,
              note: val,
            },
          },
        });

        findAndSet(d?.id, val, 'note');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };
      const changeTreatmentAmount = async (value) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,

              treatmentCost: value,
            },
          },
        });

        findAndSet(d?.id, value, 'treatmentCost');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };

      const changePaidAmount = async (value) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,
              paidAmount: value,
            },
          },
        });

        findAndSet(d?.id, value, 'paidAmount');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };

      const changeStatus = async (value: string) => {
        const {
          data: { patchTreatmentPlan },
        } = await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: d?.id,
              status: value,
            },
          },
        });

        findAndSet(d?.id, value, 'status');

        if (!patchTreatmentPlan?.id) {
          isCheckoutReady ? await fetchCheckoutReadyTreatments() : await fetchTreatments();
        }
      };

      const openCheckoutModal = () => {
        setIsCheckoutModalOpened(true);
        setCheckoutItem(d);
      };

      const openEditCancelPlan = () => {
        setIsEditCancelPlanModalOpened(true);
        setCheckoutItem(d);
      };

      const sendApplicationLink = async () => {
        try {
          const { data } = await sendOrganizationLink({
            variables: { input: { idOrganization: organization?.id, phone: d?.customer.phone } },
          });

          if (data?.sendOrganizationLink?.success) {
            if (d?.financingStatus === 'APPLICATION_NOT_SENT') {
              await d?.onFinancingChange({ value: 'APPLICATION_NOT_STARTED' });
            }
            trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.TEXT_APPLICATION.SUCCESS');
            alert.success('Sent over application link successfully');
          } else {
            trackSegmentEvent('PRACTICE_PORTAL.ALL_OTHER_PATIENTS.TEXT_APPLICATION_ERROR');
            alert.error('An error occurred. Please try again.');
          }
        } catch (error) {
          const message = (error as Error)?.message;
          alert.error(message);
        }
      };

      const expirationDate = d?.application?.expireAt;
      const isApproved = APPROVED_PATIENT_STATUSES?.includes(d?.financingStatus);
      const isApprovalExpired =
        expirationDate && isApproved && expirationDate < dayjs().utc().endOf('day').toISOString();
      const isApplicationApprovedAndAvailable = isApproved && !isApprovalExpired;
      const isApplicationExpired = d?.application?.expireAt < dayjs().utc().endOf('day').toISOString();

      const calculateDaysUntilExpiration = (expireAt) => {
        // Adjusted for Hawaii to UTC -> subtract 6 hours
        if (isApplicationApprovedAndAvailable) {
          const adjustedExpireAt = dayjs(expireAt).utc().subtract(6, 'hour');
          const todayUTC = dayjs().utc().endOf('day');
          const daysUntilExpiration = adjustedExpireAt.diff(todayUTC, 'day');

          return daysUntilExpiration;
        }
      };

      const openExtendApprovalModal = () => {
        setExtendApprovalPatient(d);
      };

      const hasPendingLoan = d?.loans?.find((loan) => ['INITIATED', 'REVIEW', 'DRAFTED']?.includes(loan.status));
      const hasCompletedLoan = getMostRecentFundedLoan(d?.loans);
      const daysUntilExpiration = calculateDaysUntilExpiration(d?.application?.expireAt);
      const isExpiringSoon = isNumber(daysUntilExpiration) && daysUntilExpiration <= 5 && daysUntilExpiration >= 0;

      const resendCheckoutLink = async () => {
        const patient = d?.customer;
        setCheckoutItem(d);
        const isActiveLoanChannelNotSupported = SMS_NOT_SUPPORTED_CHANNELS?.includes(hasPendingLoan?.channel);
        const isEmail = hasPendingLoan?.channel === 'PRACTICE_PORTAL_EMAIL';
        try {
          if (isActiveLoanChannelNotSupported) {
            // active loan channel is not supported
            setResendCheckoutLinkModal({
              show: true,
              success: false,
              notSupported: true,
              patient: { firstName: patient?.firstName, lastName: patient?.lastName },
              isEmail: isEmail,
            });
          } else {
            const { data } = await sendSelfCheckoutLink({
              variables: {
                input: {
                  loanId: hasPendingLoan?.id,
                  organizationId: organization?.id,
                },
              },
            });
            const sendLinkStatus = data?.sendSelfCheckoutLink?.success;
            if (sendLinkStatus) {
              setResendCheckoutLinkModal({
                show: true,
                success: true,
                notSupported: false,
                patient: { firstName: patient?.firstName, lastName: patient?.lastName },
                isEmail: isEmail,
              });
            } else {
              alert.error('Failed to resend checkout link');
            }
          }
        } catch (error) {
          alert.error('Failed to resend checkout link');
          captureException(error, { message: 'resendCheckoutLink Error', patientRow: d });
        }
      };

      return {
        ...d,
        onStageChange,
        onFinancingChange,
        onNoteChange,
        changeTreatmentAmount,
        changePaidAmount,
        changeStatus,
        openCheckoutModal,
        hasPendingLoan,
        hasCompletedLoan,
        openEditCancelPlan,
        resendCheckoutLink,
        isApprovalExpired,
        sendApplicationLink,
        daysUntilExpiration,
        isExpiringSoon,
        openExtendApprovalModal,
        extendApprovalPatient,
        isApplicationApprovedAndAvailable,
        isApplicationExpired,
      };
    });

    const findAndSet = (id, value: string, type: string) => {
      const tableDataWithNewReference = [...mappedDataTable];
      const fieldChangedTableData = tableDataWithNewReference
        .map((i) => {
          const treatmentPlanItem = Object.assign({}, i);
          if (treatmentPlanItem.id === id) {
            if (type === 'financingStatus') {
              treatmentPlanItem.financingStatus = value;
            } else if (type === 'stage') {
              treatmentPlanItem.stage = value;
            } else if (type === 'note') {
              treatmentPlanItem.note = value;
            } else if (type === 'treatmentCost') {
              treatmentPlanItem.treatmentCost = value;
            } else if (type === 'paidAmount') {
              treatmentPlanItem.paidAmount = value;
            } else if (type === 'status') {
              treatmentPlanItem.status = value;
            }
          }
          return treatmentPlanItem;
        })
        .filter((treatmentPlan) => isShowArchived || treatmentPlan.status === 'ACTIVE');

      const mappedTableDataForNewReference = mapDataToTable(fieldChangedTableData, isCheckoutReady);
      if (isCheckoutReady) setCheckoutReadyData(mappedTableDataForNewReference);
      else setData(mappedTableDataForNewReference);
    };

    return mappedDataTable;
  };

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
    paginateTable(pageNumber);
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
    paginateTable(pageNumber, perPage);
  };

  const paginateTable = async (pageNumber: number, perPage?: number) => {
    setPaginationLoading(true);
    setPatientTrackerReqObj((prevPatientTrackerReqObj) => ({
      ...prevPatientTrackerReqObj,
      pagination: {
        ...prevPatientTrackerReqObj.pagination,
        limit: perPage || rowsPerPage,
        offset: pageNumber - 1,
      },
    }));
  };

  const onTableSort = (column, direction) => {
    setTableLoading(true);
    setPatientTrackerReqObj({
      organizationId: organization?.id,
      pagination: {
        ...patientTrackerReqObj.pagination,
        order: direction.toUpperCase(),
        orderBy: column.selectorString || 'createdAt',
      },
    });
  };

  const checkoutReadyPageChange = (pageNumber: number) => {
    setCheckoutReadyPage(pageNumber);
    checkoutReadyPaginateTable(pageNumber);
  };

  const checkoutReadyRowsCountChange = (perPage: number, pageNumber: number) => {
    setCheckoutReadyRowsPerPage(perPage);
    checkoutReadyPaginateTable(pageNumber, perPage);
  };

  const checkoutReadyPaginateTable = async (pageNumber: number, perPage?: number) => {
    setPaginationLoading(true);
    setCheckoutReadyPatientTrackerReqObj((prevCheckoutReadyPatientTrackerReqObj) => ({
      ...prevCheckoutReadyPatientTrackerReqObj,
      pagination: {
        ...prevCheckoutReadyPatientTrackerReqObj.pagination,
        limit: perPage || checkoutReadyRowsPerPage,
        offset: pageNumber - 1,
      },
    }));
  };

  const onCheckoutReadyTableSort = (column, direction) => {
    switch (column.name) {
      case 'PATIENT':
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SORT_BY_PATIENT_CLICKED');
        break;

      case 'CREATED':
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SORT_BY_CREATED_CLICKED');
        break;

      case 'FINANCING':
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SORT_BY_FINANCING_CLICKED');
        break;

      case 'TREATMENT STAGE':
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SORT_BY_TREATMENT_STAGE_CLICKED');
        break;

      case 'Notes':
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SORT_BY_NOTES_CLICKED');
        break;
    }

    setTableLoading(true);
    setCheckoutReadyPatientTrackerReqObj({
      organizationId: organization?.id,
      pagination: {
        ...checkoutReadyPatientTrackerReqObj.pagination,
        order: direction.toUpperCase(),
        orderBy: column.selectorString,
      },
    });
  };

  useEffect(() => {
    fetchTreatments();
    fetchCheckoutReadyTreatments();
  }, [
    debouncedSearchKey,
    patientTrackerReqObj,
    checkoutReadyPatientTrackerReqObj,
    selectedStages,
    selectedLocations,
    isShowArchived,
    selectedStatus,
    triggerRefetch,
  ]);

  useEffect(() => {
    onEvent(BusEvent.FETCH_TREATMENT_PLANS, () => {
      fetchTreatments();
      fetchCheckoutReadyTreatments();
    });
    onEvent(BusEvent.APPLY_INITIAL_FILTER, (initialFilter) => {
      setSearchKey(initialFilter);

      if (searchInputRef?.current?.outerHTML?.includes('input')) {
        searchInputRef.current.value = initialFilter;
      }
    });
  }, []);

  const onSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (nativeEvent) => {
    setIsShowArchived(true);
    const currentTarget = nativeEvent?.currentTarget;
    if (currentTarget?.value?.length > 2 || currentTarget?.value === '') {
      setSearchKey(currentTarget.value);
      setSelectedStatus(APPLICATION_STATUSES.ALL_APPLICATION);
    }
  };

  const onSelectedStagesChange = (stages: string[]) => {
    const findStages = STAGE_OPTIONS.filter((stage) => stages.includes(stage.value));
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.STAGES_SELECTION_CLICKED', { selectedStages: findStages });
    setSelectedStages(findStages);
  };

  const onSelectedStatusChange = (status: string) => {
    resetFilters();
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.STATUS_SELECTION_CLICKED', { selectedStatus: status });
    setSelectedStatus(status);
  };

  const onSelectedLocationsChange = (locationsValue: string[]) => {
    const findLocations = locationOptions!.filter((location) => locationsValue.includes(location.value));
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.LOCATIONS_SELECTION_CLICKED', {
      selectedLocations: findLocations,
    });
    setSelectedLocations(findLocations);
  };

  const onShowArchivedToggle = (e) => {
    trackSegmentEvent(`PRACTICE_PORTAL.TABLE_FILTERS.SHOW_ARCHIVED_TOGGLE_${e.target.checked ? 'ON' : 'OFF'}_CLICKED`);
    setIsShowArchived(e.target.checked);
  };

  const resetFilters = () => {
    trackSegmentEvent('PRACTICE_PORTAL.TABLE_FILTERS.CLEAR_ALL_CLICKED');
    setSelectedStages([]);
    setSelectedStatus(APPLICATION_STATUSES.ALL_APPLICATION);
    setSelectedLocations([]);
    setSearchKey('');
    searchInputRef.current.value = '';
  };

  const toggleIsCheckoutModalOpened = async () => {
    setIsCheckoutModalOpened(!isCheckoutModalOpened);
    await fetchCheckoutReadyTreatments();
  };

  const toggleIsEditCancelPlanModalOpened = async () => {
    setIsEditCancelPlanModalOpened(!isEditCancelPlanModalOpened);
    await fetchCheckoutReadyTreatments();
  };

  return {
    page,
    total,
    data,
    tableLoading,
    onSearchInputChange,
    searchInputRef,
    onSelectedStatusChange,
    selectedStatus,
    paginateTable,
    paginationLoading,
    pageChange,
    onTableSort,
    rowsCountChange,
    fetchTreatments,
    selectedStages,
    onSelectedStagesChange,
    selectedLocations,
    onSelectedLocationsChange,
    resetFilters,
    isShowArchived,
    onShowArchivedToggle,
    isCheckoutModalOpened,
    toggleIsCheckoutModalOpened,
    checkoutItem,
    checkoutReadyData,
    checkoutReadyTotal,
    checkoutReadyPageChange,
    checkoutReadyRowsCountChange,
    onCheckoutReadyTableSort,
    checkoutReadyPaginationLoading,
    checkoutReadyTableLoading,
    isEditCancelPlanModalOpened,
    toggleIsEditCancelPlanModalOpened,
    fetchCheckoutReadyTreatments,
    resendCheckoutLinkModal,
    setResendCheckoutLinkModal,
    extendApprovalPatient,
    closeExtendApprovalModal,
  };
};
