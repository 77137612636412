import React from 'react';

export const useDebounceCallback = () => {
  const ref = React.useRef<number>();

  const debounce = (callback: () => void, ms: number): void => {
    if (ref.current !== undefined) {
      clearTimeout(ref.current);
    }

    const timeout = setTimeout(() => {
      callback();
    }, ms);

    ref.current = (timeout as unknown) as number;
  };

  return debounce;
};
