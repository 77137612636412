import React, { useState } from 'react';

import { PopoverText } from 'lib/components/ApplicationStatutes/components';
import { Icon } from 'lib/components/mobile';
import styled from 'styled-components';

interface Props {
  row: any;
  iconName: string;
  m: string;
  children: React.ReactNode | React.ReactNode[];
  ternary?: boolean;
  width?: string;
}

const AppointmentPopOver = ({ row, children, iconName, m, ternary, width }: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState<string | null>(null);

  const handlePopoverOpen = (event) => {
    setPopoverId(event.currentTarget.id);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverOpen(false);
  };
  return (
    <Indicator id={`indicator_${row.applicationId}`} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <IconContainer>
        <Icon src={iconName} width={15} height={15} m={m} />
      </IconContainer>
      {popoverOpen && popoverId === `indicator_${row.applicationId}` && (
        <PopoverContainer ternary={ternary} width={width}>
          <PopoverContent>
            <PopoverText>{children}</PopoverText>
          </PopoverContent>
          <DownCircle ternary={ternary} />
        </PopoverContainer>
      )}
    </Indicator>
  );
};

export default AppointmentPopOver;
const Indicator = styled.div`
  position: relative;
  z-index: 4;
`;
const DownCircle = styled.div<{ ternary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00c37d;
  position: absolute;
  left: ${(props) => (props.ternary ? '300px' : '65px')};
`;
const PopoverContent = styled.div`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.main.green};
  display: flex;
  justify-content: center;
`;
const PopoverContainer = styled.div<{ ternary?: boolean; width?: string }>`
  position: absolute;
  background: ${(props) => props.theme.main.green};
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: ${(props) => props.width};
  z-index: 10;
  left: ${(props) => (props.ternary ? `-300px` : '-50px')};
  top: ${(props) => (props.ternary ? '-55px' : '-65px')};
`;

const IconContainer = styled.span<{ ternary?: boolean }>`
  color: ${(props) => props.theme.main.midnighBlue};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  width: max-content;
`;
