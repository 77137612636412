import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Text = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;
