import { gql } from '@apollo/client/core';

export const FETCH_MERCHANT_GROUP_DETAIL = gql`
  query fetchMerchantsGroupDetail($input: MerchantsGroupDetailInput) {
    fetchMerchantsGroupDetail(input: $input) {
      id
      parentId
      organizationId
      name
      account {
        accountNickname
        accountName
        accountNumber
        routingNumber
        accountType
        accountClassification
        accountStatus
        accountDescription
        giactLogId
        accountId
        counterpartyId
      }
      tree {
        id
        name
        type
        childs
      }
    }
  }
`;

export const FETCH_MERCHANT_GROUPS_DETAIL = gql`
  query fetchMerchantGroupsDetail($input: MerchantGroupsDetailInput) {
    fetchMerchantGroupsDetail(input: $input) {
      contents {
        id
        parentId
        organizationId
        name
        account {
          accountNickname
          accountName
          accountNumber
          routingNumber
          accountType
          accountClassification
          accountStatus
          accountDescription
          giactLogId
          accountId
          counterpartyId
        }
        tree {
          id
          name
          type
          childs
        }
      }
    }
  }
`;
