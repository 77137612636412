import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowRightIcon, ButtonPrimary, CherryLink, DashCard, DashComponent, SecondaryButton } from 'lib/components';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import { handleCopyToClipboard } from 'lib/utils';
import React from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

export const MessagingTemplates = ({ messagingPdfUrl, emailSnippets, title }) => {
  const PREQUAL_URL = process.env.REACT_APP_PREQUAL_URL;
  const { trackEvent } = useAnalytics();
  const alert = useAlert();
  const { organization } = useStore();

  const onEmailButtonClicked = () => {
    window.open(messagingPdfUrl.PDFURL, '_blank');
    trackEvent({
      action: MarketingAnalyticsEventNames.CTA_BUTTONS,
      label: 'EMAIL_TEMPLATES',
    });
  };

  const handleCopy = (e) => {
    navigator.clipboard.writeText(e.target.dataset.item);
    handleCopyToClipboard(e, `${e.target.dataset.item} ${PREQUAL_URL}/${organization?.slug}`);
    alert.success('Snippet was copied to your clipboard');
    trackEvent({
      action: MarketingAnalyticsEventNames.COPY_CLIPBOARD,
      label: e.target.dataset?.item?.toString(),
    });
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <Row style={{ fontSize: '14px' }}>
          Emails and texts are a great way to connect with customers and remind them that you’re offering Cherry
          payments. Check out our easy to use email templates, or copy and paste text message snippets.
        </Row>
        <EmailButton
          endIcon={<ArrowRightIcon />}
          onClick={onEmailButtonClicked}
          text={'Email Templates'}
          data-testid="emailTemplateButton"
        />

        <SubHeader size={'16px'}>Email/Text Confirmation Snippets</SubHeader>
        <EmailSnippetList>
          {emailSnippets.map((item) => {
            return (
              <Snippet key={item.id}>
                <Flex>
                  <StyledTitleRow> {item.title} </StyledTitleRow>
                  <StyledDescRow>
                    {item.description}
                    <CherryLink href={`${PREQUAL_URL}/${organization?.slug}`} target="_blank">
                      {PREQUAL_URL}/{organization?.slug}
                    </CherryLink>
                  </StyledDescRow>
                </Flex>
                <ButtonContainer>
                  <CustomSecondaryButton
                    variant={'contained'}
                    onClick={handleCopy}
                    data-item={item.description}
                    data-testid="copyButton"
                  >
                    <FontAwesomeIcon icon={faClone} /> &nbsp;Copy
                  </CustomSecondaryButton>
                </ButtonContainer>
              </Snippet>
            );
          })}
        </EmailSnippetList>
      </DashCard>
    </Column>
  );
};

const Flex = styled.div`
  width: 90%;
`;

const StyledTitleRow = styled(Row)`
  fontsize: 14px;
  fontweight: bold;
`;
const StyledDescRow = styled(Row)`
  fontsize: 14px;
  margintop: 8px;
`;

const ButtonContainer = styled.div`
  width: 10%; 
  display: fle'; 
  alignItems: center;
`;

const EmailSnippetList = styled.ul`
  padding: 0px;

  > li:last-child {
    border-bottom: 1px solid #dadada;
  }
`;
const Snippet = styled.li`
  list-style: none;
  border-top: 1px solid #dadada;
  display: flex;
  padding: 16px 0px;
`;

const EmailButton = styled(ButtonPrimary)`
  margin: 30px 0 !important;
  max-width: 190px;
`;

const CustomSecondaryButton = styled(SecondaryButton)`
  font-size: 12px;
  color: #00c37c;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;
