import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Flex } from 'lib/components/ApplicationStatutes/components';
import { Icon } from 'lib/components/mobile';
import { useOnClickOutside } from 'lib/hooks';
import { theme } from '@frontend/cherry-library';

interface Option {
  label: string;
  value?: string;
  canSelect?: boolean;
  color?: string;
  className?: string;
  disabled?: boolean;
  description?: string;
  [key: string]: any;
}

interface Props {
  options: Option[] | undefined;
  textColor?: string;
  placeholderColor?: string;
  placeholder?: string;
  defaultValue?: string;
  iconName?: string;
  id?: string;
  showAllItem?: boolean;
  customSelector?: boolean;
  isExpiringSoon?: boolean;
  placeholderComponent?: any;
  optionsComponent?: any;
  onChange?: (item: any) => void;
  onClick?: () => void;
  onFocus?: () => void;
  hoveredMode?: boolean;
  preventCloseOnClick?: boolean;
  hasCustomFont?: boolean;
  maxHeight?: string;
  ignoreInitialFirstItem?: boolean;
  fontSize?: string;
  forcePlaceholder?: boolean;
  isOptionsBold?: boolean;
  disabled?: boolean;
  bypassSchedule?: boolean;
  onExpand?: () => void;
  sortAlphabetically?: boolean;
}

export const DropDown = React.forwardRef((props: Props, ref) => {
  const {
    options,
    textColor = '#000',
    placeholderColor = '#000',
    placeholder = 'Select an option',
    onChange,
    onClick,
    onFocus,
    defaultValue,
    iconName,
    id,
    showAllItem = false,
    customSelector,
    isExpiringSoon = false,
    placeholderComponent = null,
    optionsComponent = null,
    hoveredMode = true,
    preventCloseOnClick = false,
    hasCustomFont = false,
    maxHeight = '325',
    ignoreInitialFirstItem = false,
    fontSize = '16px',
    forcePlaceholder,
    isOptionsBold = false,
    disabled = false,
    bypassSchedule = false,
    sortAlphabetically,
    onExpand,
  } = props;

  if (sortAlphabetically) {
    options?.sort((a, b) => (a.label > b.label ? 0 : -1));
  }

  const defaultOption = options?.find((option) => option.value === defaultValue);
  const initialValue = ignoreInitialFirstItem ? defaultOption : defaultOption || options?.[0];

  const [selectedItem, setSelectedItem] = useState<Option | undefined>(initialValue);
  const [expand, setExpand] = useState<boolean>(false);
  const clickOutsideRef: any = useRef();

  useEffect(() => {
    if (forcePlaceholder) {
      setSelectedItem(undefined);
    }
  }, [forcePlaceholder]);

  useEffect(() => {
    const option = options?.find((optionObj) => optionObj.value === defaultValue);
    if (defaultValue && option) {
      setSelectedItem(option);
    }
  }, [defaultValue]);

  const closeDropdown = () => (expand ? setExpand(false) : '');
  useOnClickOutside(clickOutsideRef, closeDropdown);

  const onDropdownClick = () => {
    onClick?.();
    if (!hoveredMode) {
      if (!expand && onExpand) {
        onExpand();
      }
      setExpand((prev) => !prev);
    }
  };
  const onHoverToggle = () => {
    if (hoveredMode) setExpand(!expand);
  };

  const onDenialClicked = (selectedEvent: any) => {
    const activeElement = selectedEvent.target.parentNode.parentNode.parentNode.parentNode.parentNode;
    document.querySelectorAll('.row--active').forEach((e) => e.classList.remove('row--active'));
    activeElement.firstChild.children[0].click();
    if (activeElement.parentNode.querySelector('div.rdt_ExpanderRow')) {
      activeElement.parentNode
        .querySelector('div.rdt_ExpanderRow')
        ?.previousElementSibling.firstChild.children[0].click();
    } else {
      activeElement.classList.remove('row--active');
    }
  };

  return useMemo(
    () => (
      <DropDownLi
        ref={clickOutsideRef}
        key={id}
        id={id}
        onClick={onDropdownClick}
        onFocus={onFocus}
        onMouseEnter={onHoverToggle}
        onMouseLeave={onHoverToggle}
        disabled={disabled}
      >
        {placeholderComponent ? (
          placeholderComponent
        ) : (
          <Dropbtn className={customSelector ? 'dropdown-left' : 'dropdown-right'}>
            {!!iconName && (
              <Flex>
                <Icon src={iconName} hover={true} width={16} height={16} m={'1px 6px 0px 0px'} />
              </Flex>
            )}

            {customSelector ? (
              <IconContainer>
                {isExpiringSoon ? <ExpiringIcon /> : null}
                <FontAwesomeIcon icon={faEllipsisH} color="#DADADA" size={'1x'} />
              </IconContainer>
            ) : (
              <Span
                onClick={onDropdownClick}
                color={selectedItem?.label ? textColor : placeholderColor}
                hasCustomFont={hasCustomFont}
                customFont={selectedItem?.value}
                fontSize={fontSize}
                dangerouslySetInnerHTML={{ __html: selectedItem?.label || placeholder }}
              />
            )}
            {options && options?.length > 1 && !customSelector && (
              <FontAwesomeIcon icon={faChevronDown} color={'#00c37d'} size="sm" />
            )}
          </Dropbtn>
        )}
        <DropDownContent
          className={customSelector ? 'dropdown-content-left' : 'dropdown-content'}
          show={expand}
          hoveredMode={hoveredMode}
          maxHeight={maxHeight}
          data-testid="drowdop-option-container"
        >
          {options &&
            options.map((option: Option, index: number) => {
              const dropOption = JSON.parse(JSON.stringify(option));
              if (dropOption?.label?.includes('Scheduled') && !bypassSchedule) {
                dropOption.label = 'Schedule';
              }
              const selectDropItem = (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (option.value === 'EDIT_CANCEL_PLAN') {
                  onDenialClicked(e);
                }

                if (!preventCloseOnClick) {
                  setExpand(!expand);
                }

                setSelectedItem(dropOption);
                onChange && onChange(dropOption);
              };

              if (dropOption.canSelect === false) {
                return null;
              }

              if (dropOption?.value === selectedItem?.value) {
                return (
                  <SubA
                    color={option?.color || textColor}
                    bold={isOptionsBold}
                    customFont={option?.value}
                    hasCustomFont={hasCustomFont}
                    backgroundColor="transparent"
                    key={`dropdown-${index}`}
                    onClick={selectDropItem}
                    dangerouslySetInnerHTML={{ __html: dropOption.label }}
                    disabled={option?.disabled}
                    description={option?.description}
                  />
                );
              }

              return (
                (showAllItem || dropOption?.value !== selectedItem?.value) && (
                  <SubA
                    disabled={option.disabled}
                    className={option.className || ''}
                    color={option?.color || textColor}
                    bold={isOptionsBold}
                    customFont={option?.value}
                    hasCustomFont={hasCustomFont}
                    backgroundColor="transparent"
                    key={`dropdown-${index}`}
                    onClick={selectDropItem}
                    data-testid={`dropdown-option-${option?.className}`}
                    dangerouslySetInnerHTML={{ __html: dropOption.label }}
                    description={option?.description}
                  />
                )
              );
            })}
          {optionsComponent && optionsComponent}
        </DropDownContent>
      </DropDownLi>
    ),
    [selectedItem, expand, options, placeholder, disabled],
  );
});

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
  height: 40px;
  display: inline-flex;
  color: black;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
`;

const IconContainer = styled.div`
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 7px 10px;
`;

interface DropDownContentProps {
  show?: boolean;
  hoveredMode?: boolean;
  maxHeight?: string;
  disabled?: boolean;
}

const DropDownContent = styled.div<DropDownContentProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  left: 0;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  max-height: ${(props) => `${props.maxHeight}px`};
  overflow-x: scroll;

  &.dropdown-content-left {
    left: -100px;
  }
`;

const DropDownLi = styled(StyledLi)<DropDownContentProps>`
  display: inline-block;
  align-items: center;
  position: relative;
  width: 100%;

  ${(props) =>
    props.hoveredMode
      ? `&:hover ${DropDownContent} {
      display: block;
      cursor: pointer;
    }`
      : ''}

  ${(props) =>
    props.disabled && {
      'pointer-events': 'none',
      cursor: 'unset',
      'background-color': 'rgba(239, 239, 239, 0.3)',
      color: 'rgb(84, 84, 84)',
    }};
`;

const SubA = styled.a<{
  disabled?: boolean;
  color: string;
  customFont?: string;
  hasCustomFont?: boolean;
  backgroundColor?: string;
  bold?: boolean;
  description?: string;
}>`
  color: ${(props) => props.color};
  font-family: ${(props) => props.customFont && props.hasCustomFont && `'${props.customFont}', 'sans-serif'`};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: ${(props) => props.backgroundColor};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};

  &:hover {
    background-color: #f1f1f1;
  }

  ${(props) =>
    props.disabled && {
      'pointer-events': 'none',
      cursor: 'unset',
      color: '#dadada',
    }};

  ${(props) =>
    props.description &&
    `
  :after {
    content: '${props.description}';
    display: block;
    font-weight: normal;
  }
  `};
`;

const Span = styled.span<{ color: string; customFont?: string; hasCustomFont?: boolean; fontSize?: string }>`
  margin-right: auto;
  font-family: ${(props) => props.customFont && props.hasCustomFont && `'${props.customFont}', 'sans-serif'`};
  color: ${(props) => props.color};
  text-align: left;
  font-size: ${(props) => props.fontSize};
`;

const ExpiringIcon = styled.span`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${theme.main.cherryRed};
  display: block;
  position: absolute;
  top: 0px;
  right: -4px;
`;
