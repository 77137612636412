import React from 'react';

import { DropDown } from 'lib/components';

import { DropdownContainer, Gap } from './components';
import Popover from './components/Popover';
import { getDenialItems } from './utils';

export const CheckoutInProgress = ({ row, expandableOpened, selectedItem }) => {
  const NewDropDown = DropDown;

  return (
    <>
      <button data-tag="allowRowEvents" style={{ display: 'none' }} onClick={row.openProcessContractModal}>
        .
      </button>

      <Popover
        row={row}
        ternary={true}
        left={125}
        backgroundColor="#E6F1F9"
        type="inprogress"
        label="Checkout in Progress"
        iconName="warning"
        hoverText="A checkout link has been sent to the patient. They need to pick a payment plan and make a down payment."
      />

      {!(expandableOpened && selectedItem.id === row.applicationId) ? (
        <DropdownContainer>
          <NewDropDown
            data-testid="checkout-option"
            id={`drop-${row.applicationId}`}
            options={getDenialItems()}
            textColor={'#0E202F'}
            onChange={row.onDenialStatusChange}
            defaultValue={row.scheduled}
            showAllItem={true}
            hoveredMode={false}
            customSelector={true}
          />
        </DropdownContainer>
      ) : (
        <Gap />
      )}
      <div />
    </>
  );
};
