import { theme } from 'config/theme';
import { Icon } from 'lib/components/mobile';
import React from 'react';
import styled from 'styled-components';

const ReviewStepInfo = () => {
  return (
    <InfoContainer>
      <Icon src={'info_circle_outline'} />
      <ReviewText>Complete other steps before reviewing and confirming the purchase.</ReviewText>
    </InfoContainer>
  );
};

const ReviewText = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${theme.main.midnightBlue50};
  background: ${theme.main.midnightBlue10};
  padding: 24px;
`;

export default ReviewStepInfo;
