import { Checkbox } from '@frontend/cherry-library';
import { FormControl, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface Props {
  data: any;
  handleChange: any;
}

export const PlanBasics = ({ data, handleChange }: Props) => {
  const onChangeHandler = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    handleChange(key, value);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('recommended', event.target.checked);
  };

  return (
    <>
      <SubTitle>Plan Basics</SubTitle>
      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Plan Name</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Plan Name"
          inputProps={{
            'data-inputkey': 'name',
          }}
          id="membership-plan-plan-name"
          data-testid="membership-plan-plan-name"
          onChange={onChangeHandler}
          value={data?.name}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Plan Description</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Plan Description"
          inputProps={{
            'data-inputkey': 'description',
          }}
          id="membership-plan-plan-name"
          data-testid="membership-plan-plan-description"
          multiline={true}
          maxRows={4}
          onChange={onChangeHandler}
          value={data?.description}
          variant="outlined"
        />
      </FormControl>

      <FormControl margin="normal">
        <Checkbox
          defaultChecked={data.recommended}
          value={data.recommended}
          checked={data.recommended}
          onChange={handleChangeCheckbox}
          label="“Recommended Plan” Badge"
        />
      </FormControl>
    </>
  );
};

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
  margin-top: 40px;
`;
