import { Loading, WarningIcon } from 'lib/components';
import { ButtonPrimary } from 'lib/components';
import { Container, ContentContainer, SlideModal, Title } from 'lib/components/mobile';
import { DocumentRequest } from 'lib/hooks';
import useDocumentRequest from 'lib/hooks/useDocumentRequest';
import useSnackbar from 'lib/hooks/useSnackbar';
import { convertNewlinesToBr, getBase64 } from 'lib/utils';
import { ErrorType, ERROR_MODAL_CONTENT } from 'pages/desktop/DocumentRequests/views/ErrorModal/ErrorModal';
import FileList from 'pages/desktop/DocumentRequests/views/FileList/FileList';
import { File } from 'pages/desktop/DocumentRequests/views/FileUploadModal/FileUploadModal';
import React, { useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface LocationState {
  state: {
    documentRequest: DocumentRequest;
  };
}

interface ModalConfig {
  open: boolean;
}

interface ErrorModalConfig extends ModalConfig {
  type: ErrorType | null;
}

interface RemoveFileModalConfig extends ModalConfig {
  removedFileIndex: number | null;
}

interface ConfirmSubmissionModalConfig extends ModalConfig {
  requiredNumberOfDocs: number;
}

const defaultErrorModalState = { open: false, type: null };
const defaultRemoveFileModalState = { open: false, removedFileIndex: null };

const MobileFileUploadPage = () => {
  const alert = useAlert();
  const location = useLocation() as LocationState;
  const { uploadFilesForDocumentRequest, submitFilesToDocumentRequest } = useDocumentRequest();
  const navigate = useNavigate();
  const { isSnackbarVisible } = useSnackbar();

  const {
    documentRequest: {
      documentRequest: {
        documentTitle,
        externalNote,
        numberOfDocs,
        id,
        merchantId,
        organizationId,
        relations,
        userType,
      },
    },
  } = location?.state;

  const fileInput = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorModal, setErrorModal] = useState<ErrorModalConfig>(defaultErrorModalState);
  const [removeFileModal, setRemoveFileModal] = useState<RemoveFileModalConfig>(defaultRemoveFileModalState);
  const [confirmSubmissionModalConfig, setConfirmSubmissionModalConfig] = useState<ConfirmSubmissionModalConfig>({
    open: false,
    requiredNumberOfDocs: numberOfDocs,
  });

  const isFileListVisible = fileList?.length > 0;

  const resetFileInput = () => {
    if (fileInput?.current) {
      fileInput.current.value = '';
    }
  };

  const resetErrorModalState = () => {
    setErrorModal(defaultErrorModalState);
  };

  const resetRemovFileModal = () => {
    setRemoveFileModal(defaultRemoveFileModalState);
  };

  const addFile = async ({ name = Date.now().toString() + '.jpg', mime = 'image/jpeg', data, size }) => {
    try {
      const newFiles = [...fileList, { fileName: name, data, mime, size }];
      setFileList(newFiles);
      resetFileInput();
      resetErrorModalState();
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_FAILED',
      });
    }
  };

  const openRemoveFileDialog = (index) => {
    if (Number(index) >= 0) {
      setRemoveFileModal({
        open: true,
        removedFileIndex: index,
      });
    }
  };

  const onSelectFile = (event) => {
    if (event?.target?.files?.[0]) {
      const file = event.target.files[0];

      // 20MB check
      if (file?.size > 20000000) {
        setErrorModal({
          open: true,
          type: 'FILE_TOO_LARGE',
        });
        resetFileInput();
      } else {
        getBase64(file)
          .then((result) => {
            const { type } = file || {};

            if (
              type === 'application/pdf' ||
              type === 'image/jpeg' ||
              type === 'image/jpg' ||
              type === 'image/png' ||
              type === 'image/heic'
            ) {
              addFile({ name: file.name, mime: file?.type, data: result as string, size: file?.size });
            } else {
              setErrorModal({
                open: true,
                type: 'UNSUPPORTED_FILE_TYPE',
              });
            }
          })
          .catch((err) => {
            alert.info('Attachment failed, please try again later.');
          });
      }
    } else {
      alert.info('Attachment failed, please try again later.');
    }
  };

  const removeFile = () => {
    const index = removeFileModal.removedFileIndex;

    if (Number(index) >= 0) {
      const newFiles = [...fileList];
      if (typeof index === 'number') {
        newFiles.splice(index, 1);
      }

      setFileList(newFiles);
      setRemoveFileModal(defaultRemoveFileModalState);
      resetErrorModalState();
    }
  };

  const selectFile = () => {
    if (fileList.length === numberOfDocs) {
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_LIMIT_REACHED',
      });
    } else {
      fileInput?.current?.click();
      resetErrorModalState();
    }
  };

  const handleAddMoreDocuments = () => {
    selectFile();
    setConfirmSubmissionModalConfig({
      open: false,
      requiredNumberOfDocs: numberOfDocs,
    });
  };

  const handleSubmitAnyway = () => {
    setConfirmSubmissionModalConfig({
      open: false,
      requiredNumberOfDocs: numberOfDocs,
    });
    uploadFiles();
  };

  const uploadFiles = async () => {
    try {
      setLoading(true);

      const userId = userType === 'MERCHANT' ? merchantId : organizationId;

      if (!!userId) {
        const response = await uploadFilesForDocumentRequest(fileList, id, userId, userType, relations);
        setLoading(false);

        if (response) {
          submitFiles();
          resetErrorModalState();
        } else {
          setErrorModal({
            open: true,
            type: 'ATTACHMENT_FAILED',
          });
        }
      } else {
        setErrorModal({
          open: true,
          type: 'ATTACHMENT_FAILED',
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'ATTACHMENT_FAILED',
      });
    }
  };

  const submitFiles = async () => {
    setLoading(true);
    submitFilesToDocumentRequest(id);
    setLoading(false);
    resetErrorModalState();
    navigate('/document-requests');
  };

  const handleSubmit = () => {
    if (fileList?.length === numberOfDocs) {
      uploadFiles();
    } else {
      openConfirmSubmissionModal();
    }
  };

  const openConfirmSubmissionModal = () => {
    setConfirmSubmissionModalConfig({
      open: true,
      requiredNumberOfDocs: numberOfDocs,
    });
  };

  const closeConfirmSubmissionModal = () => {
    setConfirmSubmissionModalConfig({ open: false, requiredNumberOfDocs: numberOfDocs });
  };

  const getFormattedExternalNote = { __html: convertNewlinesToBr(externalNote) || '' };

  return (
    <>
      <Container pageTitle="Upload Documents">
        {errorModal?.type && errorModal.open ? (
          <ErrorBadge>
            <WarningIconContainer>
              <WarningIcon />
            </WarningIconContainer>
            <div>{ERROR_MODAL_CONTENT[errorModal.type].text}</div>
          </ErrorBadge>
        ) : null}
        <ContentContainer isSnackbarVisible={isSnackbarVisible}>
          <Title m={'0 0 8px 0'}>{documentTitle}</Title>
          {externalNote ? <Label dangerouslySetInnerHTML={getFormattedExternalNote} /> : null}
          {isFileListVisible ? <FileList fileList={fileList} onRemove={openRemoveFileDialog} /> : null}
          {loading ? <LoadingSpinner size={10} /> : null}
          {isFileListVisible && fileList.length < numberOfDocs ? (
            <Footnote>Please provide at least {numberOfDocs} documents.</Footnote>
          ) : null}
          <ActionButtonsContainer>
            <OutlinedButton onClick={selectFile} disabled={loading}>
              {isFileListVisible ? 'Upload Another Document' : 'Upload Document'}
            </OutlinedButton>
            <Button onClick={handleSubmit} text="Submit" disabled={loading || fileList?.length === 0} />
          </ActionButtonsContainer>

          <MobileInput
            accept="image/*,.pdf,.heic"
            type="file"
            data-testid="upload"
            onChange={onSelectFile}
            ref={fileInput}
          />
        </ContentContainer>
      </Container>
      <SlideModal
        hideModal={closeConfirmSubmissionModal}
        show={confirmSubmissionModalConfig.open}
        title="Confirm Submission"
        description={`${confirmSubmissionModalConfig.requiredNumberOfDocs} documents were requested. You have only attached ${fileList?.length}. Are you sure you want to submit?`}
        firstButton={{
          label: 'Add More Files',
          onClick: handleAddMoreDocuments,
          primary: false,
        }}
        secondButton={{
          label: 'Submit Anyway',
          onClick: handleSubmitAnyway,
          primary: true,
        }}
      />
      <SlideModal
        hideModal={resetRemovFileModal}
        show={removeFileModal.open}
        title="Are You Sure"
        description="Are you sure you want to remove this file? This action cannot be undone."
        firstButton={{
          label: 'Go Back',
          onClick: resetRemovFileModal,
          primary: false,
        }}
        secondButton={{
          label: 'Yes, Remove File',
          onClick: removeFile,
          primary: true,
        }}
      />
    </>
  );
};

const WarningIconContainer = styled.div`
  width: 30px;
`;

const ErrorBadge = styled.div`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${(props) => props.theme.main.warning};
  font-size: 14px;
  font-weight: 400;
`;

const Label = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
`;

const LoadingSpinner = styled(Loading)`
  margin: 16px auto;
`;

const MobileInput = styled.input`
  display: none;
`;

const Button = styled(ButtonPrimary)`
  width: 100%;
  height: fit-content;
  border-radius: 4px !important;
`;

const OutlinedButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  background: transparent;
  border: 1px solid ${(props) => props.theme.main.sealGray};
  border-radius: 4px;
  color: ${(props) => props.theme.main.sealGray};
  font-weight: 600;
  cursor: pointer;
  width: 100%;
`;

const ActionButtonsContainer = styled.div`
  margin-top: 32px;

  button {
    padding: 16px !important;
    font-size: 18px !important;
    margin: 4px 0;
  }
`;

const Footnote = styled.div`
  margin-top: 24px;
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

export default MobileFileUploadPage;
