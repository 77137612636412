import { gql } from '@apollo/client/core';

export const SEND_MARKETING_MATERIALS = gql`
  mutation sendMarketingMaterials($input: MarketingMaterialsRequest) {
    sendMarketingMaterials(input: $input) {
      code
      message
      success
    }
  }
`;

export const ORDER_MARKETING_MATERIAL = gql`
  mutation orderMarketingMaterial($input: OrderMarketingMaterialRequest) {
    orderMarketingMaterial(input: $input) {
      success
    }
  }
`;
