import { ButtonPrimary, DashCard } from 'lib/components';
import { AlertDialog, CherryDialogContent, CherryDialogContentText, CherryDialogTitle } from 'lib/components/Dialogue';
import StorageService from 'lib/services/Storage';
import { Row } from 'lib/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const ResetOnboarding = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleAgreeClicked = () => {
    setOpen(!open);
    StorageService.setOnboardingStatus(false);
    navigate('/dashboard');
  };

  const handleDisagreeClick = () => setOpen(!open);

  const handleDialogOpen = () => setOpen(!open);

  const AlertContent = () => {
    return (
      <>
        <CherryDialogTitle id="alert-dialog-title">Relaunch Walkthrough</CherryDialogTitle>
        <CherryDialogContent>
          <CherryDialogContentText id="alert-dialog-description">
            Are you sure you want to relaunch the Guided Walkthrough?
          </CherryDialogContentText>
        </CherryDialogContent>
      </>
    );
  };

  return (
    <DashCard>
      <AlertDialog
        open={open}
        agreeClicked={handleAgreeClicked}
        onDisagreeClicked={handleDisagreeClick}
        children={<AlertContent />}
        agreeText={'Launch'}
        declineText={'Cancel'}
      />
      <Row style={{ fontSize: '14px' }}>
        Re-watch the guided tutorial shown on your first login. This highlights and explains features in your partner
        portal.
      </Row>
      <EmailButton onClick={handleDialogOpen} text={'Start Walkthrough'} />
    </DashCard>
  );
};

const EmailButton = styled(ButtonPrimary)`
  margin: 30px 0 0 0 !important;
  max-width: 190px;
`;
