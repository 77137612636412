import dayjs from 'dayjs';
import { ApplicationStageFilter, ApplicationStatusFilter } from 'lib/types/Merchant';

export const initialStatusData: ApplicationStatusFilter = {
  SCHEDULED: { checked: true, disabled: false },
  FOLLOW_UP: { checked: true, disabled: false },
  GIVEN_TREATMENT_ESTIMATE: { checked: true, disabled: false },
  INTERESTED: { checked: true, disabled: false },
  NOT_INTERESTED: { checked: true, disabled: false },
  LEFT_VM: { checked: true, disabled: false },
  NO_VM: { checked: true, disabled: false },
  INVALID_NUMBER: { checked: true, disabled: false },
  NEW: { checked: true, disabled: false },
};

export const initialStageData: ApplicationStageFilter = {
  APPROVED: { checked: true, disabled: false },
  INCOME_VERIFICATION: { checked: true, disabled: false },
};

export const readyForCheckoutStageCheckboxData: ApplicationStageFilter = {
  APPROVED: { checked: true, disabled: false },
  INCOME_VERIFICATION: { checked: false, disabled: false },
};

export const applicationDefaultDateSpan = {
  from: dayjs().subtract(3, 'year').toISOString(),
  to: dayjs().toISOString(),
};
