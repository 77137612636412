import { useApolloClient } from '@apollo/client';
import { SUBMIT_INFORMATION_TO_DOCUMENT_REQUEST, UPDATE_FILE_FOR_DOCUMENT_REQUEST } from 'lib/graphql/mutations';
import {
  GET_DOCUMENT_REQUEST_FILES,
  GET_DOCUMENT_REQUEST_LIST,
  GET_UPLOADED_FILE_FOR_REQUEST,
} from 'lib/graphql/queries';
import { File } from 'pages/desktop/DocumentRequests/views/FileUploadModal/FileUploadModal';
import { useState } from 'react';

export interface DocumentRequest {
  documentRequest: {
    id: string;
    documentTitle: string;
    numberOfDocs: number;
    userType: string;
    externalNote: string;
    organizationId: number;
    merchantId: number;
    userId: number;
    documentRequestType: string;
    status: string;
    updatedAt: string;
    createdAt: string;
    relations: Array<{ id: number; type: string }>;
  };
  externalUserName: string;
  email: string;
  phone: string;
}

interface UploadFileDocumentResponse {
  data: { updateFileForDocumentRequest: { success: boolean } };
}

const useDocumentRequest = () => {
  const client = useApolloClient();

  const [documentRequestList, setDocumentRequestList] = useState<DocumentRequest[]>([]);

  const getAllDocumentRequests = async () => {
    const { data } = await client.query({
      query: GET_DOCUMENT_REQUEST_LIST,
    });
    const list = (data?.getDocumentRequestList?.contents || []).filter(
      ({ documentRequest }) => documentRequest.status !== 'CANCELLED' && documentRequest.status !== 'EXPIRED',
    );

    if (list.length > 0) {
      setDocumentRequestList(list);
    }
  };

  const fetchFileList = async (documentRequestId) => {
    const { data } = await client.query({
      query: GET_DOCUMENT_REQUEST_FILES,
      variables: {
        input: {
          documentRequestId,
          pagination: {
            offset: 0,
            limit: 9999,
          },
          search: [
            {
              key: 'relationsType',
              value: 'DOCUMENT_REQUEST_ID',
            },
            {
              key: 'relationsId',
              value: documentRequestId,
            },
          ],
        },
      },
    });

    return data?.getDocumentRequestFiles?.contents;
  };

  const getFilForPreview = async (documentRequestId, fileId) => {
    const response = await client.query({
      query: GET_UPLOADED_FILE_FOR_REQUEST,
      variables: {
        input: {
          documentRequestId,
          id: fileId,
        },
      },
    });

    return response?.data?.getUploadedFile;
  };

  const uploadFilesForDocumentRequest = async (
    fileList: File[],
    documentRequestId: string,
    userId: number,
    userType: string,
    relations: Array<{ id: number; type: string }>,
  ) => {
    try {
      const serviceCalls: Promise<unknown>[] = [];

      fileList.forEach(({ fileName, data, mime }) => {
        serviceCalls.push(
          client.mutate({
            mutation: UPDATE_FILE_FOR_DOCUMENT_REQUEST,
            variables: {
              input: {
                file: {
                  name: fileName,
                  data,
                  mime,
                },
                relations: [
                  { id: documentRequestId, type: 'DOCUMENT_REQUEST_ID' },
                  { id: userId, type: userType },
                  ...relations,
                ],
              },
            },
          }),
        );
      });

      const responses = (await Promise.all(serviceCalls)) as UploadFileDocumentResponse[];

      if (responses?.every((response) => !!response?.data?.updateFileForDocumentRequest?.success)) {
        return true;
      } else {
        throw new Error('ATTACHMENT_FAILED');
      }
    } catch (error) {
      throw new Error('ATTACHMENT_FAILED');
    }
  };

  const submitFilesToDocumentRequest = async (documentRequestId: string) => {
    const response = await client.mutate({
      mutation: SUBMIT_INFORMATION_TO_DOCUMENT_REQUEST,
      variables: {
        input: {
          documentRequestId,
        },
      },
    });

    return response;
  };

  const getStatus = (status: string) => {
    if (status === 'SUBMITTED') {
      return 'submitted';
    }

    return 'needed';
  };

  return {
    documentRequestList,
    getAllDocumentRequests,
    fetchFileList,
    getFilForPreview,
    uploadFilesForDocumentRequest,
    submitFilesToDocumentRequest,
    getStatus,
  };
};

export default useDocumentRequest;
