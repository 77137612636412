import create from 'zustand';

import { User } from 'lib/types/Auth';
import { configurePersist } from 'zustand-persist';

import dayjs from 'dayjs';
import { Merchant, MerchantGroup, Organization, OrganizationTierResponse, PocUser } from 'lib/types';
import { Permissions } from 'lib/types/Merchant';
import {
  ApprovedApplicationResponse,
  FormData,
  PostApplyWithPatientConsentResponse,
} from 'pages/desktop/ApplyWithPatient/types';
import { PatientInformation } from 'pages/desktop/Dashboard/views/PreApproval/type';
import { FeatureNames } from './usePermission';

const { persist } = configurePersist({
  storage: localStorage,
});

interface Store {
  organization: Organization | null;
  merchants: Merchant[] | null;
  merchantGroups: MerchantGroup[] | null;
  locations: Merchant[] | null;
  hasBankingIssue: boolean;
  selectedMerchant: Merchant | null;
  selectedMerchantForTransactions: Merchant | null;
  organizationTier: OrganizationTierResponse | null;
  user: User;
  features: FeatureNames[];
  permissions: Permissions;
  role: string[];
  refundIssueType: string;
  setRefundIssueType: (refundIssueType: string) => void;
  setSelectedOrganization: (organizationData: Organization | any) => void;
  setHasBankingIssue: (hasBankingIssue: boolean) => void;
  setOrganizationMerchants: (organizationMerchants: Merchant[]) => void;
  setOrganizationMerchantGroups: (organizationMerchants: MerchantGroup[]) => void;
  setOrganizationTier: (organizationTier: OrganizationTierResponse) => void;
  setSelectedMerchant: (merchant: Merchant | null) => void;
  setSelectedMerchantForTransactions: (merchant: Merchant | null) => void;
  setLocations: (locations: Merchant[] | null) => void;
  setUser: (user: User) => void;
  setFeatures: (features: FeatureNames[]) => void;
  setPermissions: (permissions: Permissions) => void;
  setRole: (role: string[]) => void;
  clearStore: () => void;
  applicationInProcess: any;
  setApplicationInProcess: (applicationInProcess: any) => void;
  transactionInProcess: any;
  setTransactionInProcess: (transactionInProcess: any) => void;
  pocUsers: PocUser[];
  setPocUsers: (pocUsers: PocUser[]) => void;
  newUserId: string;
  setNewUserId: (newUserId: string) => void;
  isComingFromLogin: boolean;
  setIsComingFromLogin: (isComingFromLogin: boolean) => void;
  loginRenderFirstTime: boolean;
  setLoginRenderFirstTime: (isComingFromLogin: boolean) => void;
  forgotPassRenderFirstTime: boolean;
  setForgotPassRenderFirstTime: (isComingFromLogin: boolean) => void;
  showChat: boolean;
  setShowChat: (showChat: boolean) => void;
  membershipPlans: any;
  setMembershipPlans: (plans: any) => void;
  activateGuestMode: boolean;
  setActivateGuestMode: (isActive: boolean) => void;
  isAdminReporting: boolean;
  setIsAdminReporting: (isAdminReporting: boolean) => void;
  scheduleReminder: boolean;
  setScheduleReminder: (scheduleReminder: boolean) => void;
  selectedOrganizationGroup: { id: number; name: string };
  setSelectedOrganizationGroup: (selectedOrganizationGroup: { id: number; name: string }) => void;
  appointmentDate: { startAt: string; endAt: string };
  setAppointmentDate: (appointmentDate: { startAt: string; endAt: string }) => void;
  sliderRefresh: boolean;
  setSliderRefresh: (sliderRefresh: boolean) => void;
  takeHomeApprovalModalOpen: boolean;
  setTakeHomeApprovalModalOpen: (takeHomeApprovalModalOpen: boolean) => void;
  generateEstimateConfirmationOpen: boolean;
  setGenerateTakeHomeConfirmationOpen: (generateEstimateConfirmationOpen: boolean) => void;
  patientInfo: PatientInformation | null;
  setPatientInfo: (patientInfo: PatientInformation | null) => void;
  ssnErrorMessage: string | null;
  setSsnErrorMessage: (ssnErrorMessage: string | null) => void;
  applyWithPatientFormData: PostApplyWithPatientConsentResponse | null;
  setApplyWithPatientFormData: (applyWithPatientFormData: PostApplyWithPatientConsentResponse | null) => void;
  approvedApplication: ApprovedApplicationResponse | null;
  setApprovedApplication: (approvedApplication: ApprovedApplicationResponse | null) => void;
  submittedApplyWithPatientData: FormData | null;
  setSubmittedApplyWithPatientData: (submittedApplyWithPatientData: FormData | null) => void;
  selectedMerchantId: string;
  setSelectedMerchantId: (selectedMerchantId: string | undefined) => void;
}

const useStore = create<Store>(
  persist(
    {
      key: 'state',
      allowlist: [
        'organization',
        'merchants',
        'user',
        'features',
        'applicationInProcess',
        'transactionInProcess',
        'isAdminReporting',
        'selectedOrganizationGroup',
        'scheduleReminder',
      ],
    },
    (set: any) => ({
      organization: null,
      merchants: null,
      merchantGroups: null,
      locations: null,
      organizationTier: null,
      selectedMerchant: null,
      selectedMerchantForTransactions: 'all',
      user: null,
      features: [],
      permissions: {},
      role: '',
      refundIssueType: '',
      applicationInProcess: null,
      transactionInProcess: null,
      pocUsers: null,
      isComingFromLogin: false,
      loginRenderFirstTime: false,
      forgotPassRenderFirstTime: false,
      showChat: false,
      appointmentDate: { startAt: dayjs().toDate().toISOString(), endAt: dayjs().day(7).toDate().toISOString() },
      membershipPlans: [],
      activateGuestMode: false,
      setActivateGuestMode: (isActive: boolean) => set(() => ({ activateGuestMode: isActive })),
      isAdminReporting: false,
      scheduleReminder: false,
      selectedOrganizationGroup: null,
      setSelectedOrganization: (organizationData: Organization) => set(() => ({ organization: organizationData })),
      setOrganizationTier: (organizationTier: OrganizationTierResponse) => set(() => ({ organizationTier })),
      setLocations: (locations: Merchant) => set(() => ({ locations })),
      setOrganizationMerchants: (organizationMerchants: Merchant[]) =>
        set(() => ({ merchants: organizationMerchants })),
      setOrganizationMerchantGroups: (organizationMerchantGroups: MerchantGroup[]) =>
        set(() => ({ merchantGroups: organizationMerchantGroups })),
      setSelectedMerchant: (merchant: Merchant | null) => set(() => ({ selectedMerchant: merchant })),
      setSelectedMerchantForTransactions: (merchant: Merchant | null) =>
        set(() => ({ selectedMerchantForTransactions: merchant })),
      setUser: (user: User) => set(() => ({ user })),
      setRefundIssueType: (refundIssueType: string) => set(() => ({ refundIssueType })),
      setFeatures: (features) => set(() => ({ features })),
      setPermissions: (permissions) => set(() => ({ permissions })),
      setRole: (role) => set(() => ({ role })),
      setApplicationInProcess: (applicationInProcess) => set(() => ({ applicationInProcess })),
      setTransactionInProcess: (transactionInProcess) => set(() => ({ transactionInProcess })),
      setPocUsers: (pocUsers) => set(() => ({ pocUsers })),
      patientInfo: null,
      setPatientInfo: (patientInfo) => set(() => ({ patientInfo })),
      ssnErrorMessage: null,
      setSsnErrorMessage: (ssnErrorMessage) => set(() => ({ ssnErrorMessage })),
      setNewUserId: (newUserId) => set(() => ({ newUserId })),
      setIsComingFromLogin: (isComingFromLogin) => set(() => ({ isComingFromLogin })),
      setLoginRenderFirstTime: (loginRenderFirstTime) => set(() => ({ loginRenderFirstTime })),
      setForgotPassRenderFirstTime: (forgotPassRenderFirstTime) => set(() => ({ forgotPassRenderFirstTime })),
      setShowChat: (showChat: boolean) => set(() => ({ showChat })),
      setAppointmentDate: (appointmentDate: { startAt: string; endAt: string }) => set(() => ({ appointmentDate })),
      setMembershipPlans: (membershipPlans: boolean) => set(() => ({ membershipPlans })),
      setIsAdminReporting: (isAdminReporting: boolean) => set(() => ({ isAdminReporting })),
      setScheduleReminder: (scheduleReminder: boolean) => set(() => ({ scheduleReminder })),
      setSelectedOrganizationGroup: (selectedOrganizationGroup: { id: number }) =>
        set(() => ({ selectedOrganizationGroup })),
      sliderRefresh: false,
      setSliderRefresh: (sliderRefresh: boolean) => set(() => ({ sliderRefresh })),
      takeHomeApprovalModalOpen: false,
      setTakeHomeApprovalModalOpen: (takeHomeApprovalModalOpen: boolean) => set(() => ({ takeHomeApprovalModalOpen })),
      generateEstimateConfirmationOpen: false,
      setGenerateTakeHomeConfirmationOpen: (generateEstimateConfirmationOpen: boolean) =>
        set(() => ({ generateEstimateConfirmationOpen })),
      applyWithPatientFormData: {},
      setApplyWithPatientFormData: (applyWithPatientFormData: PostApplyWithPatientConsentResponse) =>
        set(() => ({ applyWithPatientFormData })),
      approvedApplication: {},
      setApprovedApplication: (approvedApplication: ApprovedApplicationResponse) =>
        set(() => ({ approvedApplication })),
      submittedApplyWithPatientData: {},
      setSubmittedApplyWithPatientData: (submittedApplyWithPatientData: FormData) =>
        set(() => ({ submittedApplyWithPatientData })),
      selectedMerchantId: '',
      setSelectedMerchantId: (selectedMerchantId: string | undefined) => set(() => ({ selectedMerchantId })),

      clearStore: () =>
        set(() => ({
          organization: null,
          merchants: null,
          selectedMerchant: null,
          organizationTier: null,
          isAdminReporting: false,
          scheduleReminder: false,
        })),
    }),
  ),
);

export default useStore;
