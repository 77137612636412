export const getExpiresText = (dateDiff: number): string => {
  let expiresInText = '';

  if (dateDiff === 0) {
    expiresInText = 'Expires Today';
  } else if (dateDiff === 1) {
    expiresInText = 'Expires 1 day';
  } else if (dateDiff > 1) {
    expiresInText = `Expires ${dateDiff} days`;
  } else if (dateDiff < 0) {
    expiresInText = `Expired`;
  }

  return expiresInText;
};
