export default {
  applicationSent: require('./application-sent.png'),
  cherryIconDark: require('./cherry-icon-dark.png'),
  cherryLogo: require('./cherry-logo-dark.svg'),
  cherryLogoDark: require('./cherry-logo-dark.png'),
  playButton: require('./play-button.png'),
  circleCheck: require('./circle-check.svg'),
  historyIcon: require('./history.svg'),
  infoCircleOutline: require('./info-circle-outline.svg'),
  infoCircle: require('./info-circle.svg'),
  cherryLogoOutline: require('./cherry-logo-outline.svg'),
  cherrySymbolDark: require('./cherry-symbol-dark.png'),
  chevronLeft: require('./chevron-left.svg'),
  close: require('./close.svg'),
  lucideConstruction: require('./lucide_construction.svg'),
  mailIcon: require('./mail.png'),
  manualApplication: require('./manual-application.png'),
  partyIcon: require('./party.png'),
  phoneIcon: require('./phone.png'),
  pointOfSaleLogo: require('./point-of-sale.png'),
  leaderboardLogo: require('./leaderboard.png'),
  preApproval: require('./pre-approval.svg'),
  bankInformation: require('./bank-info.svg'),
  strokeIcon: require('./stroke.png'),
  zeroApr: require('./zeroapr.svg'),
  textApplicationIcon: require('./text-application-icon.svg'),
  waveHi: require('./wave-hi.png'),
  successCherry: require('./success-cherry.gif'),
};
