import React from 'react';

import { Button, ButtonContainer, Container, ContentContainer, Subtitle, Title } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';

export const MobileApplicationPendingIncome = () => {
  const navigate = useNavigate();
  const { isSnackbarVisible } = useSnackbar();

  const back = () => {
    navigate('/dashboard');
  };

  return (
    <Container pageTitle={'Application Details'}>
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Pending Income Verification</Title>
        <Subtitle m={'0 0 8px 0'}>
          We’ve asked this customer to verify their income so we can finish their application. Their status will update
          automatically when they complete this step.
        </Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button secondary={true} type="submit" onClick={back}>
          Back
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MobileApplicationPendingIncome;
