import { Column, Row } from 'lib/styles';
import React from 'react';
import {
  BorderLine,
  ContentContainer,
  ContentDescription,
  ContentLink,
  InfoContainer,
  ProfileIcon,
  StyledButtonPrimary,
  StyledColumn,
  SupportTitle,
} from './CherryTeam';
import { Accounts } from 'lib/types/Merchant';

export const Sales = ({ accountList }: { accountList: Accounts[] }) => {
  const getAccountsByType = (type: string) => accountList?.filter((data) => data?.type === type);

  const handleBookAppointment = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <SupportTitle>Sales</SupportTitle>
      <Row fontSize={'14px'}>
        Questions about your pricing plan, business strategy, or managing the locations in your account should be
        directed to your Sales Representative.
      </Row>

      {getAccountsByType('ACCOUNT')?.map((account, index) => {
        return (
          <Row key={`account-${index}`}>
            <Column>
              <InfoContainer>
                <ProfileIcon src={'user_circle.svg'} />
                <ContentContainer>
                  {account?.name ? <ContentDescription>{account?.name}</ContentDescription> : null}
                  {account?.phone ? <ContentLink href={`tel:${account?.phone}`}>{account?.phone}</ContentLink> : null}
                  {account?.email ? (
                    <ContentLink href={`mailto:${account?.email}`}>{account?.email}</ContentLink>
                  ) : null}
                </ContentContainer>
              </InfoContainer>
            </Column>
            {account?.bookingLink ? (
              <StyledColumn>
                <StyledButtonPrimary
                  data-testid="scheduleMeetingButton"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleBookAppointment(account?.bookingLink)}
                  text={'Book Appointment'}
                />
              </StyledColumn>
            ) : null}
          </Row>
        );
      })}

      <BorderLine />
    </>
  );
};
