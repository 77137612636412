export const colors = {
  green: '#00c37d',
  darkGreen: '#00AD6F',
  cherryRed: '#EC3360',
  midBlue: '#0E202F',
  white: '#ffffff',
  black: '#000000',
  neutralGray: '#DADADA',
  coolGray: '#E8E8EA',
  warmGray: '#EAE8E8',
  midnightBlue: '#868F97',
};

export const typography = {
  type: {
    primary: '"Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  weight: {
    regular: '400',
    bold: '700',
    extraBold: '800',
    black: '900',
  },
  size: {
    xs3: 8,
    s1: 10,
    s2: 14,
    s3: 16,
    m1: 20,
    m2: 24,
    l1: 32,
    l2: 40,
    l3: 48,
    xl1: 64,
  },
} as const;
