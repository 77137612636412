import { formatDate, formatPhoneNumber, formatTextCapitalize } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

import { FixedDecimalFormat } from 'lib/components';
import { firstCharFormatter } from 'lib/utils';
import { PaymentMethod } from 'pages/desktop/Membership/type';

interface Props {
  data: any;
  paymentData: PaymentMethod | undefined;
}

export const ReadMemberInfo = ({ data, paymentData }: Props) => {
  const { customer, plan, status, period } = data || {};
  const { type, network, last4 } = paymentData || {};

  const findPaymentSchedule = () => {
    return data?.plan?.prices?.find((price) => price?.period?.toLowerCase() === data?.period?.toLowerCase());
  };

  const mailtoHandler = () => {
    window.open(`mailto:${customer?.email}`, '_blank');
  };

  return (
    <Container>
      <ContentItemContainer>
        <ContentItem>
          <ContentItemTitle>Contact Info</ContentItemTitle>
        </ContentItem>
        <ContentItem>
          <ContentItemText>First Name: {customer?.firstName}</ContentItemText>
          <ContentItemText>Phone: {formatPhoneNumber(customer?.phone)}</ContentItemText>
        </ContentItem>
        <ContentItem>
          <ContentItemText>Last Name: {customer?.lastName}</ContentItemText>
          <ContentItemText>
            Email: <ContentItemLink onClick={mailtoHandler}>{customer?.email}</ContentItemLink>
          </ContentItemText>
        </ContentItem>
      </ContentItemContainer>
      <ContentItemContainer>
        <ContentItem>
          <ContentItemTitle>Membership Details</ContentItemTitle>
        </ContentItem>
        <ContentItem>
          <ContentItemText>Plan Type: {plan?.name}</ContentItemText>
          <ContentItemText>Payment Status: {firstCharFormatter(status)}</ContentItemText>
        </ContentItem>
        <ContentItem>
          <ContentItemText>Payment Schedule: {formatTextCapitalize(period)}</ContentItemText>
          <ContentItemText>Member Since: {formatDate(data?.createdAt)}</ContentItemText>
        </ContentItem>
        <ContentItem>
          {findPaymentSchedule()?.period === 'MONTHLY_FLEX' && (
            <ContentItemText>
              First Monthly Payment (One-Time):{' '}
              <FixedDecimalFormat amount={findPaymentSchedule()?.firstPayment} prefix={'$'} />
            </ContentItemText>
          )}
          <ContentItemText>
            {findPaymentSchedule()?.period === 'MONTHLY_FLEX' ? 'All Subsequent Monthly Payments:' : 'Price:'}{' '}
            <FixedDecimalFormat amount={findPaymentSchedule()?.price} prefix={'$'} />
          </ContentItemText>
          {/* <ContentItemText>Payments to Date: $1,259.00</ContentItemText> */}
        </ContentItem>
      </ContentItemContainer>
      <ContentItemContainer>
        <ContentItem>
          <ContentItemTitle>Member Payment Info</ContentItemTitle>
        </ContentItem>
        <ContentItem>
          <ContentItemText>Payment Type: {firstCharFormatter(type)}</ContentItemText>
        </ContentItem>
        <ContentItem>
          <ContentItemText>
            Payment Method: {network?.toUpperCase()} ****{last4}
          </ContentItemText>
        </ContentItem>
      </ContentItemContainer>
    </Container>
  );
};

const Container = styled.div``;

const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const ContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ContentItemTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
`;
const ContentItemText = styled.span<{ color?: boolean }>`
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const ContentItemLink = styled(ContentItemText)`
  color: #00c37d;
  text-decoration: underline;
  cursor: pointer;
`;
