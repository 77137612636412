import { Popover } from '@mui/material';
import { FixedDecimalFormat } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { calculateMerchantFee } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

interface TableProps {
  item: any;
  refundAmount: number;
}

export const RefundTable = ({ item, refundAmount }: TableProps) => {
  const { organization } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverText, setPopoverText] = React.useState('');

  const refundFee: any = organization?.refundFee;

  const purchaseFeeBefore = item?.amount;
  const purchaseFeeChange = refundAmount;
  const purchaseFeeAfter = item?.amount - refundAmount;

  const merchantFeeBefore = item?.merchantRevenue;
  const merchantFeeChange = calculateMerchantFee(refundAmount, item);
  const merchantFeeAfter = item?.merchantRevenue - merchantFeeChange;

  const netSalesFeeBefore = item?.amount - item?.merchantRevenue;
  const netSalesFeeChange = refundAmount - merchantFeeChange + refundFee;
  const netSalesFeeAfter = purchaseFeeAfter - merchantFeeAfter - refundFee;

  const handlePurchaseClick = (event: any) => {
    setPopoverText(`${item?.borrowerName} will receive a $${refundAmount.toFixed(2)} credit.`);
    setAnchorEl(event.currentTarget);
  };

  const handleRefundClick = (event: any) => {
    setPopoverText(`We will reimburse you $${merchantFeeChange.toFixed(2)} in merchant fees.`);
    setAnchorEl(event.currentTarget);
  };

  const handleDisbursementClick = (event: any) => {
    setPopoverText(
      `You will be charged $${netSalesFeeChange.toFixed(2)}, which includes a $${refundFee.toFixed(2)} refund fee.`,
    );
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverText('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container>
      <LabelContainer>
        <Label hidden={true}>.</Label>
        <Label>Purchase Amount</Label>
        <Label>Merchant Fee</Label>
        {refundFee === 0 ? '' : <Label>Refund Fee</Label>}
        <Label>Net Sales</Label>
      </LabelContainer>

      <TableContainer>
        <HeaderItem>
          <HeaderLabel>Before</HeaderLabel>
          <HeaderLabel>Change</HeaderLabel>
          <HeaderLabel flex={true}>After</HeaderLabel>
        </HeaderItem>

        <TableItem>
          <Text>
            <FixedDecimalFormat amount={purchaseFeeBefore} prefix={'$'} />
          </Text>
          <ChangeItem>
            <ChangeText>
              {purchaseFeeChange > 0 ? '-' : ''}
              <FixedDecimalFormat amount={purchaseFeeChange} prefix={'$'} />
            </ChangeText>
            <Icon src={'/info_circle.svg'} onClick={handlePurchaseClick} />
          </ChangeItem>
          <Text flex={true}>
            <FixedDecimalFormat amount={purchaseFeeAfter} prefix={'$'} />
          </Text>
        </TableItem>

        <TableItem>
          <Text>
            -<FixedDecimalFormat amount={merchantFeeBefore} prefix={'$'} />
          </Text>
          <ChangeItem>
            <ChangeText>
              +<FixedDecimalFormat amount={merchantFeeChange} prefix={'$'} />
            </ChangeText>
            <Icon src={'/info_circle.svg'} onClick={handleRefundClick} />
          </ChangeItem>
          <Text flex={true}>
            {merchantFeeAfter > 0 ? '-' : ''}
            <FixedDecimalFormat amount={Math.abs(merchantFeeAfter)} prefix={'$'} />
          </Text>
        </TableItem>

        {refundFee === 0 ? (
          ''
        ) : (
          <>
            <TableItem>
              <Text>n/a</Text>
              <ChangeItem>
                <ChangeText>
                  -<FixedDecimalFormat amount={refundFee} prefix={'$'} />
                </ChangeText>
                <IconPlaceholder />
              </ChangeItem>
              <Text flex={true}>
                -<FixedDecimalFormat amount={refundFee} prefix={'$'} />
              </Text>
            </TableItem>
          </>
        )}
        <TableItem>
          <Text>
            <FixedDecimalFormat amount={netSalesFeeBefore} prefix={'$'} />
          </Text>
          <ChangeItem>
            <ChangeText>
              {netSalesFeeChange > 0 ? '-' : ''}
              <FixedDecimalFormat amount={netSalesFeeChange} prefix={'$'} />
            </ChangeText>
            <Icon src={'/info_circle.svg'} onClick={handleDisbursementClick} />
          </ChangeItem>
          <Text flex={true}>
            <FixedDecimalFormat amount={netSalesFeeAfter} prefix={'$'} />
          </Text>
        </TableItem>
      </TableContainer>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        marginThreshold={50}
        className="popover-container"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverContent>
          <PopoverText>{popoverText}</PopoverText>
        </PopoverContent>
      </StyledPopover>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px 16px 0px;
  margin-bottom: 10px;
`;
const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: end;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom: 1px solid #868f97;
`;

const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding: 0px 8px;
  &:last-child {
    border-bottom: 0px;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #868f97;
  padding: 0px 8px;
  flex: 1;
`;

const Label = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px 0px;
`;

const ChangeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 75px;
`;

const ChangeText = styled(Label)`
  color: #0e202f;
  font-weight: bold;
  display: flex;
  align-self: stretch;
  min-width: 50px;
`;

const HeaderLabel = styled.span<{ hidden?: boolean; flex?: boolean }>`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px 0px;
  flex: 1;
  display: flex;
  justify-content: ${(props) => (props.flex ? 'flex-end' : 'flex-start')};
  color: ${(props) => (props.hidden ? 'white' : '#868f97')};
  &:nth-child(2) {
    padding-left: 20px;
  }
`;

const Text = styled(Label)<{ bold?: boolean; flex?: boolean }>`
  color: #0e202f;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  flex: 2;
  display: flex;
  justify-content: ${(props) => (props.flex ? 'flex-end' : 'flex-start')};
  align-self: stretch;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

const IconPlaceholder = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    margin-left: 20px !important;
  }
`;

const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
  max-width: 200px;
`;

const PopoverText = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: white;
`;
