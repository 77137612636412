import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Confetti from 'react-confetti';
import styled from 'styled-components';

import { theme } from 'config/theme';
import dayjs from 'dayjs';
import { Icon } from 'lib/components/mobile';
import { confettiColors } from 'lib/constants';
import client from 'lib/graphql/client';
import { PATCH_MILESTONE_CAMPAIGN, UPDATE_MILESTONE_NOTIFICATION_STATUS } from 'lib/graphql/mutations/Milestones';
import { FETCH_MILESTONES } from 'lib/graphql/queries/Milestones';
import useStore from 'lib/hooks/useStore';

import { Grid } from '@mui/material';
import {
  Card,
  CardContent,
  CardDescription,
  CardLogo,
  CardTitle,
  DarkButton,
} from 'pages/desktop/Dashboard/views/CommonComponents';
import { MilestoneState, MilestoneStep } from 'pages/desktop/Dashboard/views/Widgets/Milestones';
import MilestoneCongratsModal from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneCongratsModal';
import MilestoneDetailModal from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneDetailModal';
import { MilestoneProgressBar } from 'pages/desktop/Dashboard/views/Widgets/Milestones/MilestoneProgressBar';

const STEP_3_ORDER_INDEX = 2;
const MAX_COUNT_STEP_3 = 5;

enum RewardOption {
  CASH = 'CASH',
  GIFT_CARD = 'GIFT_CARD',
  POINT = 'POINT',
}

export enum RewardNotification {
  IDLE = 'IDLE',
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  STEP_3 = 'STEP_3',
  DISMISSED = 'DISMISSED',
}

enum RewardStatus {
  COMPLETED = 'COMPLETED',
  HALF_COMPLETED = 'HALF_COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

enum StateStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

interface Campaign {
  id: number;
  name: string;
  description: string;
  rewardOption: RewardOption;
  startAt: string;
  endAt: string;
  details: {
    id: number;
    name: string;
    description: string;
    campaignId: number;
    orderIndex: number;
    rewardAmount: number;
    state: {
      status: StateStatus;
      cumulativeRewardAmount: number;
    };
  }[];
}

export interface Milestone {
  id: number;
  endAt: string;
  merchantUserId: number;
  rewardNotification: RewardNotification;
  rewardProgression: number;
  rewardStatus: RewardStatus;
  rewardGranted: number;
  campaign: Campaign;
}

export const MilestoneRewardsWidget = ({ horizontal = false }) => {
  const { features, organization } = useStore();
  const { activatedAt } = organization || {};
  const [confettiActive, setConfettiActive] = useState<boolean>(false);

  const alert = useAlert();
  const [milestone, setMilestone] = useState<Milestone | null>(null);

  const [hasMilestonesEnabled, setHasMilestonesEnabled] = useState<boolean>(false);
  const [hasReward, setHasReward] = useState<boolean | null>(null);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [displayCongratsModal, setDisplayCongratsModal] = useState(false);

  useEffect(() => {
    controlRewardStatus();
    fetchMilestoneStatus();
  }, []);

  useEffect(() => {
    controlRewardStatus();
  }, [features]);

  const controlRewardStatus = () => {
    const hasMilestones = features.find((i) => i === 'MILESTONE');
    if (hasMilestones) {
      setHasMilestonesEnabled(true);
      const hasMilestonesReward = features.find((i) => i === 'MILESTONE_REWARD');
      if (hasMilestonesReward) {
        setHasReward(true);
      } else {
        setHasReward(false);
      }
    } else {
      setHasMilestonesEnabled(false);
    }
  };

  const showCompletionText = false;

  const getStepState = (stateStatus: StateStatus): MilestoneState => {
    switch (stateStatus) {
      case StateStatus.COMPLETED:
        return MilestoneState.SUCCESS;
      case StateStatus.IN_PROGRESS:
        return MilestoneState.IN_PROGRESS;
      case StateStatus.NOT_STARTED:
      default:
        return MilestoneState.LOCKED;
    }
  };

  const getStepDescription = (orderIndex) => {
    const NO_REWARD_DESCRIPTION = {
      0: 'Get your first patient approval by texting an application link to them!',
      1: 'Checkout your first patient!',
      2: 'Checkout 5+ patients to earn your Cherry Expert certification!',
    };

    const REWARD_DESCRIPTION = {
      0: 'Get your first patient approval by texting an application link to earn this reward.',
      1: 'Checkout a patient to earn a total reward of $20!',
      2: 'Checkout 5 patients to earn a total reward of $50!',
    };

    if (hasReward) {
      return REWARD_DESCRIPTION[orderIndex];
    } else {
      return NO_REWARD_DESCRIPTION[orderIndex];
    }
  };

  const setCongratsNotificationAsSeen = async () => {
    try {
      const {
        data: {
          updateMilestoneNotificationStatus: { success },
        },
      } = await client.mutate({
        mutation: UPDATE_MILESTONE_NOTIFICATION_STATUS,
        variables: {
          input: {
            organizationId: organization?.id,
            campaignId: milestone?.campaign?.id,
          },
        },
      });
      if (!success) {
        alert.error('Milestones Notification Update error');
      }
    } catch {
      alert.error('Milestones Notification Update error');
    }
  };

  const patchMilestoneCampaign = async () => {
    try {
      const {
        data: {
          patchMilestoneCampaign: { success },
        },
      } = await client.mutate({
        mutation: PATCH_MILESTONE_CAMPAIGN,
        variables: {
          input: {
            organizationId: organization?.id,
            campaignId: milestone?.campaign?.id,
            type: 'DISMISSED',
          },
        },
      });
      if (!success) {
        alert.error('Milestones Update error');
      }

      return success;
    } catch {
      alert.error('Milestones Notification Update error');
    }
  };

  const fetchMilestoneStatus = async () => {
    try {
      const {
        data: {
          fetchMilestones: { data },
        },
      } = await client.query({
        query: FETCH_MILESTONES,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });

      // We need to sort the details of each campaign in order to see the steps correctly
      const sortedMilestones = JSON.parse(JSON.stringify(data)) as Milestone[];
      sortedMilestones.forEach((milestoneObj) => {
        milestoneObj.campaign.details.sort((a, b) => a.orderIndex - b.orderIndex);
      });

      setMilestone(sortedMilestones[0]);
      if (sortedMilestones[0]?.rewardNotification !== RewardNotification.IDLE) {
        openCongratsModal();
      }
    } catch (e) {
      alert.error('Fetch Milestones error');
    }
  };

  const openMilestonesDetailModal = async () => {
    if (milestone?.rewardStatus !== RewardStatus.COMPLETED) {
      setShowDetailModal(true);
    } else {
      setMilestone(null);
      await patchMilestoneCampaign();
    }
  };

  const closeMilestonesDetailModal = () => {
    setShowDetailModal(false);
  };

  const openCongratsModal = () => {
    setDisplayCongratsModal(true);
  };

  const closeCongratsModal = () => {
    setDisplayCongratsModal(false);
    setCongratsNotificationAsSeen();

    setConfettiActive(true);

    setTimeout(() => {
      setConfettiActive(false);
    }, 3000);
  };

  const handleCongratsModalDetailButton = () => {
    closeCongratsModal();
    openMilestonesDetailModal();
  };

  const TOTAL_DAYS = 30;
  const endDate = dayjs(activatedAt).add(TOTAL_DAYS, 'day');
  const daysLeft = endDate.diff(dayjs(), 'days');
  const getDaysLeftPercentage = () => (daysLeft / TOTAL_DAYS) * 100;

  return hasMilestonesEnabled && milestone && daysLeft > 0 && milestone.rewardNotification !== 'DISMISSED' ? (
    <Card>
      {confettiActive ? (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          colors={confettiColors}
          recycle={false}
        />
      ) : null}

      <Grid container={true}>
        <Grid item={true} xs={horizontal ? 11 : 12}>
          <CardContent className="mt">
            {!horizontal && (
              <CardLogo>
                <Icon src="wave-hello" />
              </CardLogo>
            )}

            <CardTitle className="large">Finish Setting Up Your Account!</CardTitle>
            <CardDescription>
              {hasReward
                ? 'Achieve the milestones below within your practice’s first 30 days with Cherry to earn rewards.'
                : 'Achieve the milestones below within your practice’s first 30 days to unlock your full potential!'}
            </CardDescription>
            {hasReward && !horizontal && (
              <Section horizontal={horizontal}>
                <RewardResultContainer>
                  <RewardTitle>Your Total Reward:</RewardTitle>
                  <RewardAmount>${milestone?.rewardGranted}</RewardAmount>
                  <MilestoneProgressBar
                    value={getDaysLeftPercentage()}
                    daysLeft={daysLeft}
                    width={'100px'}
                    subtitleFontSize="10px"
                    progressBottomSubtitle1="days left"
                  />
                </RewardResultContainer>
              </Section>
            )}
            {!horizontal && (
              <Section horizontal={horizontal}>
                <DarkButton onClick={openMilestonesDetailModal}>Learn More</DarkButton>
              </Section>
            )}
            {!horizontal && <GreyLine />}
            <Grid container={true}>
              {milestone?.campaign?.details.map((campaign, j) => (
                <Grid key={j} item={true} xs={horizontal ? 4 : 12}>
                  <MilestoneStep
                    hasReward={hasReward}
                    rewardGranted={milestone?.rewardGranted}
                    stepNumber={campaign.orderIndex + 1}
                    stepState={getStepState(campaign.state.status)}
                    rewardAmount={hasReward && !horizontal ? campaign.state.cumulativeRewardAmount : undefined}
                    text={getStepDescription(campaign.orderIndex)}
                    currentSubProgress={
                      campaign.orderIndex === STEP_3_ORDER_INDEX ? milestone.rewardProgression - 1 : undefined
                    }
                    maxSubProgress={campaign.orderIndex === STEP_3_ORDER_INDEX ? MAX_COUNT_STEP_3 : undefined}
                  />
                </Grid>
              ))}
            </Grid>
            {showCompletionText && (
              <Section horizontal={horizontal}>
                <CompletionText>
                  You’re now a <b>Cherry Expert</b> and ready to take on the world. Be on the lookout for your
                  certification to arrive!
                </CompletionText>
              </Section>
            )}
          </CardContent>
        </Grid>
        {horizontal && (
          <Grid item={true} xs={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
            <DarkButton onClick={openMilestonesDetailModal}>
              {milestone?.rewardStatus === RewardStatus.COMPLETED ? 'Dismiss' : 'Learn More'}
            </DarkButton>
          </Grid>
        )}
      </Grid>
      {hasReward !== null && (
        <>
          <MilestoneDetailModal
            visible={showDetailModal}
            onClose={closeMilestonesDetailModal}
            progressBarValue={getDaysLeftPercentage()}
            progressBarDaysLeft={daysLeft}
            hasReward={hasReward}
            milestoneDetail={milestone}
            horizontal={horizontal}
          />
          <MilestoneCongratsModal
            activatedAt={activatedAt}
            hasReward={hasReward}
            completedStep={milestone?.rewardNotification}
            visible={displayCongratsModal}
            onClose={closeCongratsModal}
            handleDetailButton={handleCongratsModalDetailButton}
          />
        </>
      )}
    </Card>
  ) : null;
};

const CompletionText = styled.div`
  font-size: 14px;
`;

const RewardResultContainer = styled.div`
  margin-top: 4px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid ${theme.main.green};
  border-radius: 8px;
  background-color: ${theme.main.green5};
  box-shadow: 0px 6px 9px 0px #00000012;
`;

const RewardTitle = styled.div``;

const RewardAmount = styled.div`
  color: ${theme.main.green};
`;

const GreyLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.main.midnightBlue10};
`;

const Section = styled.div<{ horizontal: boolean }>`
  ${(props) =>
    props.horizontal &&
    `
    flex-direction: row;
    align-items: center;
    justify-content: space-between
  `};
`;
