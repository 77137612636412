import { gql } from '@apollo/client/core';

export const FETCH_TREATMENT_LIST = gql`
  query fetchTreatmentList($input: FetchTreatmentList) {
    fetchTreatmentList(input: $input) {
      total
      contents {
        id
        organizationId
        customer {
          firstName
          lastName
          phone
          email
        }
        treatmentCost
        stage
        status
        financingStatus
        note
        createdAt
        updatedAt
        consultationSummary
        applicationId
        preapprovalId
        application {
          borrowerId
          merchantId
          menu {
            minPurchase
          }
          status
          balanceAvailable
          creditLineIncrease
          activeLoan {
            displayId
          }
          createdAt
          expireAt
        }
        loans {
          status
          id
          purchaseAmount
        }
        preApproval {
          minimumAmount
          maximumAmount
        }
        paymentOptionsMerchantId
      }
    }
  }
`;

export const FETCH_TREATMENT_PLANS = gql`
  query fetchTreatmentPlans($input: SearchInput) {
    fetchTreatmentPlans(input: $input) {
      total
      contents {
        id
        organizationId
        customer {
          firstName
          lastName
          phone
          email
        }
        treatmentCost
        stage
        status
        financingStatus
        note
        createdAt
        updatedAt
        consultationSummary
        applicationId
        preapprovalId
        application {
          borrowerId
          merchantId
          menu {
            minPurchase
          }
          status
          balanceAvailable
          creditLineIncrease
          activeLoan {
            displayId
          }
          createdAt
          expireAt
          approvalExtended
        }
        loans {
          status
          id
          purchaseAmount
          merchantId
          fundedAt
          channel
        }
        preApproval {
          minimumAmount
          maximumAmount
          status
        }
        paymentOptionsMerchantId
      }
    }
  }
`;
