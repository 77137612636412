import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { TableLoader } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import useMerchantDetail from 'lib/hooks/useMerchantDetail';
import { Row } from 'lib/styles';

const LocationRow = ({ row, alert }) => {
  const handleCopyID = () => {
    navigator?.clipboard?.writeText(row?.code).then(() => {
      alert.success('ID copied to clipboard: ' + row?.code);
    });
  };

  const addressLine = `${row?.address.street ? row?.address.street + ' - ' : ''} ${
    row?.address.city ? row?.address.city : ''
  }`;

  return (
    <StyledBorderedGrid border={'false'} fontWeight={400} item={true} xs={12}>
      <Grid item={true}>
        <Row fontSize={'16px'} fontWeight="700" color="#0E202F">
          {row?.name}
        </Row>
        <Row fontSize={'14px'} fontWeight="400" color="#0E202F">
          {addressLine}
        </Row>
        <Row fontSize={'14px'} fontWeight="400" color="#0E202F" marginTop="8px">
          Location ID:&nbsp; <strong>{` ${row?.code} `}</strong>
          <CopyContainer onClick={handleCopyID}>
            <Icon src={'copy-fill'} width={16} height={17} hover={true} />
            Copy ID
          </CopyContainer>
        </Row>
      </Grid>
    </StyledBorderedGrid>
  );
};

const LocationsList = () => {
  const { loading, locations, locationExists } = useMerchantDetail();
  const alert = useAlert();

  const tableColumns = useMemo(
    () => [
      {
        id: 'code',
        cell: (row, index: number) => <LocationRow row={row} alert={alert} />,
      },
    ],
    [alert],
  );

  if (!locationExists) {
    return null;
  }

  return (
    <LocationContainer>
      <BorderedGrid width={'100%'} border={'false'} fontWeight={700} item={true} xs={6}>
        Locations
      </BorderedGrid>

      <StyledBorderedGrid border={'false'} fontWeight={700} item={true} xs={6}>
        <Grid width={'100%'} container={true} spacing={0} data-testid={'location-list-table'} id="location-list-table">
          <CustomDataTable
            noHeader={true}
            noTableHead={true}
            columns={tableColumns}
            data={locations}
            progressPending={loading}
            progressComponent={<TableLoader count={4} />}
            pagination={true}
            paginationPerPage={3}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationRowsPerPageOptions={[3]}
          />
        </Grid>
      </StyledBorderedGrid>
    </LocationContainer>
  );
};

export default React.memo(LocationsList);

interface BorderedGridProps {
  fontWeight: number;
  border: string;
}

const BorderedGrid = styled(Grid)<BorderedGridProps>`
  font-weight: ${(props) => props.fontWeight};
`;

const StyledBorderedGrid = styled(BorderedGrid)`
  padding: 0;
  border: 0;
`;

const LocationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const CopyContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin: 0 8px;
  color: ${(props) => props.theme.main.green};
  font-weight: 600;
  cursor: pointer;

  > img {
    margin-right: 8px;
    margin-left: 18px;
  }
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;

  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
    padding: 18px 0;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
`;
