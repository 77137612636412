import { useApolloClient, useMutation } from '@apollo/client';
import { Application_Events, Appointment_Events } from 'lib/constants';
import { CREATE_LOAN, PATCH_LOAN, SEND_CHECKOUT_EMAIL } from 'lib/graphql/mutations';
import { FETCH_MENU_DETAIL } from 'lib/graphql/queries';
import { DashboardAnalyticsEventNames, useAnalytics, useMerchantLogin, useSentry } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import CurrencyUtil from 'lib/utils/currency';
import { SEND_OPTIONS } from 'pages/desktop/Dashboard/DentalDashboard/views/CheckoutOptions/CheckoutOptions';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import useStore from '../hooks/useStore';
import { Appointment, StepEnums } from '../types';
import { browserIsMobile } from '../utils';

interface UseApplicationCreateParams {
  selectedModalItem?: any;
  setExpandableOpened?: (val: boolean) => void;
  setSelectedItem?: (val: any) => void;
  isLoanUpdated?: any;
  loanUpdated?: any;
  setLoanUpdated?: (val: any) => void;
  selectedAppointment?: Appointment;
}

export const useApplicationCreate = ({
  selectedModalItem,
  setExpandableOpened,
  setSelectedItem,
  isLoanUpdated,
  loanUpdated,
  setLoanUpdated,
  selectedAppointment,
}: UseApplicationCreateParams) => {
  const client = useApolloClient();
  const alert = useAlert();
  const { merchants, user, setSliderRefresh, organization } = useStore();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { captureException } = useSentry();

  const { merchantLogout, merchantLogin } = useMerchantLogin();
  const [createLoan] = useMutation(CREATE_LOAN);
  const [patchLoan] = useMutation(PATCH_LOAN);
  const [sendCheckoutLinkWithEmail] = useMutation(SEND_CHECKOUT_EMAIL);

  const [selectedSendOption, setSendOption] = useState(SEND_OPTIONS.SMS);
  const [email, setEmail] = useState('');

  const setSelectedSendOption = (value) => {
    setSendOption(value);
  };

  const defaultSelectedMerchant = merchants && merchants[0]?.id;
  const { id } = selectedModalItem || {};

  const [mobileLoanCreateSuccess, setMobileLoanCreateSuccess] = useState(false);
  const [mobileLoanUpdateSuccess, setMobileLoanUpdateSuccess] = useState(false);
  const [selectedMerchantId, setSelectedMerchantId] = useState<number | null>(defaultSelectedMerchant);
  const [amount, setAmount] = useState<any>();
  const [updatedLoan, setUpdatedLoan] = useState<any>();
  const [clickedButton, setClickedButton] = useState<string>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [menu, setMenu] = useState<any>();
  const [defaultAmountValue, setDefaultAmountValue] = useState<any>();
  const [loanId, setLoanId] = useState<number>(0);
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [activeStep, setActiveStep] = useState(StepEnums.LOADING);
  const [loading, setLoading] = useState(false);
  const [isAnythingChanged, setIsAnythingChanged] = useState(false);

  const cancelLoanRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setExpandableOpened && setExpandableOpened(true);
    setSelectedItem && setSelectedItem(selectedModalItem);
    return () => {
      setExpandableOpened && setExpandableOpened(false);
      setSelectedItem && setSelectedItem({});
      merchantLogout();
    };
  }, []);

  useEffect(() => {
    if (selectedModalItem) {
      getApplicationMenu();
    }
  }, [id, loanId]);

  useEffect(() => {
    if (selectedModalItem?.selfCheckout === true) {
      setDefaultAmountValue(selectedModalItem?.purchaseAmount || selectedModalItem?.amount);
      if (selectedModalItem?.activeLoan?.channel === 'TREATMENT_PLAN') {
        setIsEditMode(false);
      } else {
        setIsEditMode(true);
      }
      setAmount(selectedModalItem?.purchaseAmount || selectedModalItem?.amount);
    } else setDefaultAmountValue(selectedModalItem?.amount || '');
  }, [isEditMode]);

  const getApplicationMenu = async () => {
    setBorrowerName(selectedModalItem?.borrower?.firstName);
    clearStates();
    if (browserIsMobile() || isLoanUpdated === StepEnums.CHECKOUT_FORM) {
      setActiveStep(isLoanUpdated);
      setUpdatedLoan(selectedModalItem);
      const {
        data: { getMenu },
      } = await client.query({
        query: FETCH_MENU_DETAIL,
        variables: { input: { id: selectedModalItem.menuId } },
      });
      setMenu(getMenu);
    } else setActiveStep(isLoanUpdated);
  };

  const clearStates = () => {
    setClickedButton(undefined);
  };

  const options = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: merchant.name,
    };
  });

  const onAmountChange = (value) => {
    setIsAnythingChanged(true);
    const newAmount = browserIsMobile() ? CurrencyUtil.rawValue(value) : value;
    setAmount(newAmount);
  };

  const validateAmount = (inputAmount: number) => {
    if (inputAmount && Number(inputAmount) > (selectedModalItem?.balanceAvailable || selectedModalItem.maxEligible)) {
      alert.error(
        `Purchase amount cannot be greater than ${CurrencyUtil.toCurrencyString(
          updatedLoan?.balanceAvailable || updatedLoan.maxEligible,
          true,
        )}`,
      );
      setActiveStep(StepEnums.CHECKOUT_FORM);
      return;
    } else if (inputAmount && Number(inputAmount) < menu?.minPurchase) {
      alert.error(`Minimum purchase amount is ${CurrencyUtil.toCurrencyString(menu?.minPurchase, true)}`);
      setActiveStep(StepEnums.CHECKOUT_FORM);
      return;
    } else {
      return true;
    }
  };

  const callSegmentEvents = () => {
    selectedAppointment
      ? trackSegmentEvent(Appointment_Events.SENT_CHECKOUT, {
          application: applicationName,
          component: Appointment_Events.COMPONENT,
          userId: user?.id ? `u-${user?.id}` : undefined,
          applicationId: selectedModalItem.applicationId || selectedModalItem?.id,
          borrowerId: selectedModalItem.borrowerId,
          purchaseAmount: amount,
          merchantId: selectedMerchantId,
          targetPhone: selectedAppointment?.customer?.phone,
        })
      : trackSegmentEvent(Application_Events.CHECKOUT_APPLICATION, {
          application: applicationName,
          applicationId: selectedModalItem.applicationId || selectedModalItem?.id,
          borrowerId: selectedModalItem.borrowerId,
          merchantId: selectedMerchantId,
          checkoutType: 'Another Checkout',
        });
  };

  const sendCheckoutLinkEmail = async (idLoan) => {
    try {
      const { data } = await sendCheckoutLinkWithEmail({
        variables: {
          input: {
            idOrganization: organization?.id,
            loanId: idLoan,
            email,
          },
        },
      });

      if (!data?.sendCheckoutEmail?.success) {
        alert.error('Something went wrong while sending the email');
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
      alert.error('Something went wrong while sending the email');
    }
  };

  const createLoanFromApplication = async (e?: SyntheticEvent) => {
    try {
      if (selectedMerchantId) {
        setActiveStep(StepEnums.LOADING);
        setLoading(true);
        if (validateAmount(amount)) {
          callSegmentEvents();
          await merchantLogin(selectedMerchantId);
          const { data } = await createLoan({
            variables: {
              input: {
                merchantId: selectedMerchantId,
                applicationId: selectedModalItem.applicationId || selectedModalItem?.id,
                purchaseAmount: Number(amount),
                selfCheckout: true,
                channel: selectedSendOption === SEND_OPTIONS.SMS ? 'WPQ' : 'PRACTICE_PORTAL_EMAIL',
              },
            },
          });
          merchantLogout();
          if (data.createLoan) {
            setLoanId(data?.createLoan?.id);
            if (selectedSendOption === SEND_OPTIONS.EMAIL) {
              sendCheckoutLinkEmail(data?.createLoan?.data?.id);
            }
            setSliderRefresh(true);
            if (e === undefined) {
              loanUpdated && loanUpdated(StepEnums.CHECKOUT_EDIT_SUCCESS);
              setActiveStep && setActiveStep(StepEnums.CHECKOUT_EDIT_SUCCESS);
            } else {
              loanUpdated && loanUpdated(StepEnums.CHECKOUT_SUCCESS);
              setActiveStep && setActiveStep(StepEnums.CHECKOUT_SUCCESS);
            }
            setMobileLoanCreateSuccess(true);
            setMobileLoanUpdateSuccess(true);
            setLoading(false);
          } else {
            setMobileLoanCreateSuccess(false);
            setMobileLoanUpdateSuccess(false);
            setLoading(false);
            alert.error(`An error occurred during creating the checkout link, please try again`);
            closeExpanded();
            return;
          }
        } else {
          setMobileLoanCreateSuccess(false);
          setLoading(false);
        }
      }
    } catch (err) {
      captureException(err, { message: 'Create Loan Error' });
      merchantLogout();
      setMobileLoanCreateSuccess(false);
      setMobileLoanUpdateSuccess(false);
      setLoading(false);
      alert.error(`An error occurred please try again`);
      closeExpanded();
      return;
    }
  };

  const cancelLoan = async (type: string) => {
    if (selectedMerchantId) {
      setActiveStep(StepEnums.LOADING);
      try {
        await merchantLogin(selectedMerchantId);

        const { data } = await patchLoan({
          variables: {
            input: {
              loanId: selectedModalItem.activeLoanId,
              status: 'CANCELLED',
            },
          },
        });
        merchantLogout();
        if (data.patchLoan.id) {
          if (type !== 'EDIT') {
            loanUpdated(StepEnums.CHECKOUT_SUCCESS_CANCEL);
            setActiveStep(StepEnums.CHECKOUT_SUCCESS_CANCEL);
            setSliderRefresh(true);
          }
        } else {
          throw Error();
        }
      } catch (e: any) {
        merchantLogout();
        if (e?.response?.data?.message === 'loan.status.invalid') {
          alert.error('The information for this contract was no longer current, your page was refreshed');
          return;
        } else {
          alert.error(`An error occurred please try again`);
          closeExpanded();
          return;
        }
      }
    }
  };

  const onMerchantSelect = async (option) => {
    setIsAnythingChanged(true);
    const merchantId = browserIsMobile() ? option?.target?.value : option.value;
    trackEvent({
      action: DashboardAnalyticsEventNames.CHECKOUT_STORE_CHANGE,
    });
    setSelectedMerchantId(merchantId);
  };

  const editModeChange = (editMode) => {
    setIsEditMode(editMode);
  };

  const decideAndCloseExpanded = (value) => {
    const selectedModal = selectedAppointment ? selectedAppointment?.id : selectedModalItem.id;
    const selectedRow: any = document.getElementById(`row-${selectedModal}`);
    selectedRow?.firstChild.children[0].click();

    document.querySelectorAll('.row--active').forEach((event) => event.classList.remove('row--active'));
    setLoanUpdated && setLoanUpdated(value);
  };

  const closeExpanded = () => {
    trackSegmentEvent('Checkout Cancelled', {
      application: applicationName,
      applicationId: selectedModalItem.applicationId || selectedModalItem?.id,
      borrowerId: selectedModalItem.borrowerId,
      merchantId: selectedMerchantId,
      checkoutType: 'Another Checkout',
    });
    trackEvent({
      action: DashboardAnalyticsEventNames.CHECKOUT_SUCCESS_CLOSE_CLICK,
    });
    decideAndCloseExpanded(false);
  };

  const closeAndUpdateExpanded = () => {
    trackEvent({
      action: DashboardAnalyticsEventNames.CHECKOUT_SUCCESS_CLOSE_CLICK,
    });
    decideAndCloseExpanded(true);
  };

  const cancelStart = useCallback(async (e) => {
    setActiveStep(StepEnums.CHECKOUT_CANCEL);
  }, []);

  const processEditApplication = async (e) => {
    try {
      if (validateAmount(amount)) {
        setLoading(true);
        await cancelLoan('EDIT');
        createLoanFromApplication();
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred. Please try again.');
      closeExpanded();
      return;
    }
  };

  const processCancelRequest = async () => {
    trackSegmentEvent('Checkout Cancel', {
      application: applicationName,
      applicationId: selectedModalItem?.applicationId || selectedModalItem?.id,
      borrowerId: selectedModalItem?.borrowerId,
      merchantId: selectedMerchantId,
      type: 'Link cancelled',
    });
    await cancelLoan('CANCEL_CONFIRMED');
  };

  const continueToCheckoutForm = useCallback(async () => {
    setActiveStep(StepEnums.CHECKOUT_FORM);
    editModeChange(false);
  }, []);

  return {
    createLoanFromApplication,
    cancelLoan,
    processCancelRequest,
    processEditApplication,
    closeExpanded,
    closeAndUpdateExpanded,
    continueToCheckoutForm,
    onAmountChange,
    onMerchantSelect,
    options,
    editModeChange,
    cancelStart,
    amount,
    updatedLoan,
    clickedButton,
    isEditMode,
    menu,
    defaultAmountValue,
    setDefaultAmountValue,
    borrowerName,
    activeStep,
    cancelLoanRef,
    merchants,
    mobileLoanCreateSuccess,
    mobileLoanUpdateSuccess,
    loading,
    isAnythingChanged,
    selectedSendOption,
    setSelectedSendOption,
    setEmail,
  };
};
