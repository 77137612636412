import { useMutation } from '@apollo/client';
import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { SEND_TEXT_REMINDERS } from 'lib/graphql/mutations';

import { Loading } from 'lib/components';
import { Appointment_Events } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Appointment } from 'lib/types';
import { formatPhoneNumber } from 'lib/utils';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
} from 'pages/desktop/Settings/components/Dialog';

interface OutboundMessages {
  applicationStatus: string;
  createdAt: string;
  id: string;
  message: string;
  type: string;
}
interface ReminderData {
  message: string;
  applicationId: number;
  outboundMessages: OutboundMessages[];
  borrower: Borrower;
}
interface Borrower {
  firstName: string;
  lastName: string;
  phone: string;
}
interface Props {
  open: boolean;
  reminderData: ReminderData | null;
  onClose: () => void;
  appointment?: Appointment;
}

enum MessageStatus {
  READY = 'READY',
  SENT = 'SENT',
  SCHEDULED = 'SCHEDULED',
  NON_BUSINESS = 'NON_BUSINESS',
}

export const TextReminderModal = ({ onClose, open, reminderData, appointment }: Props) => {
  const alert = useAlert();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { user } = useStore();

  const [sendTextReminders, { loading }] = useMutation(SEND_TEXT_REMINDERS);
  const [messageSent, setMessageSent] = useState<MessageStatus>(MessageStatus.READY);

  const sendTextReminderRequest: any = async () => {
    const currentHour = dayjs(dayjs()).tz('America/Los_Angeles').get('h');

    try {
      const { applicationId, borrower } = reminderData || {};
      const { firstName, phone } = borrower || {};
      const { data } = await sendTextReminders({ variables: { input: { applicationId } } });
      if (data?.sendTextReminders?.success) {
        if (currentHour < 9 || currentHour > 17) {
          setMessageSent(MessageStatus.NON_BUSINESS);
        } else {
          alert.success(`A text reminder was sent to ${firstName} at ${formatPhoneNumber(phone)}`);
          onClose();
        }
      } else if (data?.sendTextReminders?.message === 'application.reminder.cannot_be_sent_same_day.validation') {
        setMessageSent(MessageStatus.SENT);
      }
      if (appointment) {
        trackSegmentEvent(Appointment_Events.SENT_REMINDER, {
          application: applicationName,
          component: Appointment_Events.COMPONENT,
          userId: user?.id ? `u-${user?.id}` : undefined,
          was_success: data?.sendTextReminders?.success,
          is_existing_borrower: applicationId ? true : false,
          borrowerId: appointment?.application?.borrowerId,
          targetPhone: appointment?.customer?.phone,
        });
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (!open) {
      setMessageSent(MessageStatus.READY);
    }
  }, [open]);

  const ModalContent = () => {
    if (messageSent === MessageStatus.SENT) {
      const { outboundMessages } = reminderData || {};
      const textReminderDate = outboundMessages?.[0]?.createdAt;
      const textReminderHour = dayjs(textReminderDate)?.format('hh:mm A');
      return (
        <>
          <CherryDialogTitle id="customized-dialog-title" onClose={onClose}>
            Reminder Recently Sent
          </CherryDialogTitle>
          <DialogContent>
            <Content>
              A text reminder was sent to this patient at {textReminderHour}. Please wait at least 24 hours before
              sending another reminder.
            </Content>
          </DialogContent>
          <ButtonContainer>
            <ContinueButton onClick={onClose}>Okay</ContinueButton>
          </ButtonContainer>
        </>
      );
    } else if (messageSent === MessageStatus.READY && reminderData) {
      return (
        <>
          <CherryDialogTitle id="customized-dialog-title" onClose={onClose}>
            Text Reminder
          </CherryDialogTitle>

          {reminderData?.message === 'application.reminder_execution.unprocessable' ? (
            <>
              <DialogContent>
                <Content>We are not able to process sending reminder.</Content>
              </DialogContent>
              <ButtonContainer>
                <BackButton onClick={onClose}>Cancel</BackButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <DialogContent>
                <ContentTitle>Message to Send:</ContentTitle>
                {reminderData?.message === 'loading' ? (
                  <LoadingContainer>
                    <Loading />
                  </LoadingContainer>
                ) : (
                  <Content>{reminderData?.message}</Content>
                )}
              </DialogContent>

              <ButtonContainer>
                <BackButton onClick={onClose}>Cancel</BackButton>
                <ButtonGap />
                <ContinueButton loading={loading} disabled={loading} onClick={sendTextReminderRequest}>
                  Send Reminder
                </ContinueButton>
              </ButtonContainer>
            </>
          )}
        </>
      );
    } else if (messageSent === MessageStatus.NON_BUSINESS) {
      return (
        <>
          <CherryDialogTitle id="customized-dialog-title" onClose={onClose}>
            Non-Business Hours
          </CherryDialogTitle>
          <DialogContent>
            <Content>
              Text reminder messages are only available during business hours from 9am to 5pm Pacific Time. Your
              reminder message has been scheduled to be sent out at the next eligible time.
            </Content>
          </DialogContent>
          <ButtonContainer>
            <ContinueButton onClick={onClose}>Okay</ContinueButton>
          </ButtonContainer>
        </>
      );
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>{ModalContent()}</DialogContainer>
    </Dialog>
  );
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

const ContentTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e202f;
  margin-bottom: 8px;
`;
const Content = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0e202f;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;
