import { Button as CherryButton, EmailInput, PhoneNumberInput } from '@frontend/cherry-library';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import client from 'lib/graphql/client';
import { GET_MERCHANT_DETAIL, GET_PAYMENT_ESTIMATOR_CALCULATION } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';

import { useMutation } from '@apollo/client';
import { APP_PREQUAL_URL } from 'config';
import { SEND_ESTIMATE_EMAIL, SEND_ESTIMATE_SMS } from 'lib/graphql/mutations';
import { useSegment } from 'lib/hooks/useSegment';
import { PaymentEstimateModalInitialData } from 'pages/desktop/Dashboard/Dashboard';
import { SecondaryBackButton, SecondaryContinueButton } from 'pages/desktop/Settings/components/Dialog';
import { MerchantDetail } from 'pages/desktop/Settings/components/LocationManagement/type';

import { useSendApplicationLink } from 'lib/services';
import { SinglePageEstimatePDF } from 'pages/desktop/Dashboard/views/SinglePageEstimatePDF/SinglePageEstimatePDF';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import SendToPatient from 'pages/desktop/Dashboard/views/GenerateEstimate/SendToPatient';

export interface PreviewData extends PaymentEstimateModalInitialData {
  patientName?: string;
  treatmentCoordinator?: string;
  additionalNotes?: string;
  selectedLocation?: any;
  sendToPatientDetails?: { emailAddress?: string; phoneNumber?: string };
  data?: any;
}

interface Props {
  goBackToFormStep?: () => void;
  previewData: PreviewData;
  hideSendPatientSection?: boolean;
  hideGoBackButton?: boolean;
  isShowPrintButtonTop?: boolean;
  onClickBack?: () => void;
  onClickSms?: (phone: string) => void;
  onClickEmail?: (email: string) => void;
}

interface PaymentOptionsData {
  apr: number;
  financeCharge: number;
  paymentAmount: number;
  term: number;
}

export enum PDF_PAGE {
  FIRST = 0,
  SECOND = 1,
}

const isChrome = !!window['chrome'];

export const EstimatePreview = ({
  goBackToFormStep,
  previewData,
  hideSendPatientSection = false,
  isShowPrintButtonTop = false,
  onClickBack,
  onClickEmail,
  onClickSms,
}: Props) => {
  const {
    applicationSending,
    phoneFormatValid,
    phoneNumber,
    logInputBlur,
    onPhoneChange,
    phoneNumberValid,
    valid,
    showError,
    email,
    onEmailChange,
  } = useSendApplicationLink();

  const { merchants, organization, user } = useStore();
  const { trackSegmentEvent, applicationName, segmentEventHandler } = useSegment();

  const [sendEstimateEmail] = useMutation(SEND_ESTIMATE_EMAIL);
  const [sendEstimateSms] = useMutation(SEND_ESTIMATE_SMS);
  const [merchantDetail, setMerchantDetail] = useState<MerchantDetail | null>(null);
  const [paymentOptionsTableData, setPaymentOptionsTableData] = useState<PaymentOptionsData[] | null>(null);

  const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState<boolean>(false);

  const [shouldSendSms, setShouldSendSms] = useState<boolean>(!!previewData?.sendToPatientDetails?.phoneNumber);
  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(!!previewData?.sendToPatientDetails?.emailAddress);
  const [shouldPrint, setShouldPrint] = useState<boolean>(true);

  const alert = useAlert();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: `@media all {
      .pagebreak {
        display: none;
        }
    }
    
    @media print {
      html,
      body {
        height: ${isChrome ? 'initial!important;' : '100%'};
        overflow: initial!important;
        zoom: 112%;
        -webkit-print-color-adjust: exact;
      }
    }

    @media page {
      size: A4;
    }
    
    @media print {
      .pagebreak {
          margin-top: 32px;
          display: block;
          page-break-before: always;
        }
    }`,
    content: () => componentRef.current,
    onAfterPrint: () => {
      trackSegmentEvent('Estimate Printed', {
        application: applicationName,
        component: 'Payment Estimator',
        userId: `u-${user?.id}`,
      });

      setPdfGenerationInProgress(false);
    },
    onBeforePrint: () => {
      trackSegmentEvent('PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_PRINT_CLICKED', {
        ...previewData.data,
      });
      setPdfGenerationInProgress(true);
    },
  });

  useEffect(() => {
    fetchMerchantDetail();
    const isApplicationExpired =
      previewData?.data?.data?.application?.expireAt < dayjs().utc().endOf('day').toISOString();
    const isApprovedPatient = previewData?.data?.data?.application?.status === 'APPROVED' && !isApplicationExpired;

    if (!isApprovedPatient) {
      fetchAllPaymentOptions();
    }
  }, []);

  const onClickSendSms = async (phone?: string) => {
    try {
      trackSegmentEvent('Texted Quote', {
        application: applicationName,
        component: 'Estimate Generator',
        userId: `u-${user?.id}`,
        phoneNumber: phone,
      });

      const { data } = await sendEstimateSms({
        variables: {
          input: {
            organizationId: organization?.idOrganization,
            data: {
              phone,
              merchantId: Number(previewData?.selectedLocation?.value),
              amount: previewData?.amount,
              creditGradeType: previewData?.creditScore?.text,
              term: previewData?.planLength,
              patientName: previewData?.patientName,
              coordinatorName: previewData?.treatmentCoordinator,
              consultationSummary: previewData?.additionalNotes,
            },
          },
        },
      });

      if (!data?.sendEstimateSms?.success) {
        alert.error(data?.sendEstimateSms?.message);
      } else {
        alert.success(`Estimate successfully sent to ${previewData.patientName} via SMS`);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const onClickSendEmail = async (emailToSent?: string) => {
    try {
      trackSegmentEvent('Emailed Quote', {
        application: applicationName,
        component: 'Estimate Generator',
        userId: `u-${user?.id}`,
        emailAddress: emailToSent,
      });

      let applyLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice&utm_medium=email_estimate`;
      const merchantId = previewData?.selectedLocation?.value;
      if (merchantId) {
        applyLink += '&m=' + merchantId;
      }

      const { data } = await sendEstimateEmail({
        variables: {
          input: {
            organizationId: organization?.idOrganization,
            data: {
              email: emailToSent,
              amount: previewData?.amount,
              merchantName: previewData?.selectedLocation?.emailLabel,
              financialSituation: previewData?.creditScore?.text,
              paymentAmount: previewData?.paymentCalculation?.paymentAmount,
              term: previewData?.planLength,
              financeCharge: previewData?.paymentCalculation?.financeCharge,
              apr: previewData?.paymentCalculation?.apr,
              patientName: previewData?.patientName,
              coordinatorName: previewData?.treatmentCoordinator,
              consultationSummary: previewData?.additionalNotes,
              applyLink,
            },
          },
        },
      });

      if (!data?.sendEstimateEmail?.success) {
        alert.error(data?.sendEstimateEmail?.message);
      } else {
        alert.success(`Estimate successfully sent to ${previewData.patientName}! via email`);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleMultiSelectContinue = () => {
    trackSegmentEvent('Estimate Share Button Clicked', {
      application: applicationName,
      component: 'Payment Estimator',
      userId: `u-${user?.id}`,
    });

    if (shouldSendEmail) {
      onClickSendEmail(previewData.sendToPatientDetails?.emailAddress);
    }

    if (shouldSendSms) {
      onClickSendSms(previewData.sendToPatientDetails?.phoneNumber);
    }

    if (shouldPrint) {
      handlePrint();
    }
  };

  const fetchAllPaymentOptions = async () => {
    try {
      const { data } = await client.query({
        query: GET_PAYMENT_ESTIMATOR_CALCULATION,
        variables: {
          input: {
            amount: Number(previewData.amount),
            organizationId: organization?.idOrganization,
          },
        },
      });
      setPaymentOptionsTableData(data?.getPaymentEstimatorCalculation);
    } catch {
      setPaymentOptionsTableData([]);
    }
  };

  const fetchMerchantDetail = async () => {
    try {
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds: previewData.selectedLocation?.value,
          },
        },
      });

      if (getMerchantDetail?.data?.[0]) {
        setMerchantDetail(getMerchantDetail.data[0]);
      }
    } catch (error) {
      alert.error('Merchant detail fetch error');
    }
  };

  const handleBack = () => {
    trackSegmentEvent('Estimate Preview Back Clicked', {
      application: applicationName,
      component: 'Payment Estimator',
      userId: `u-${user?.id}`,
    });

    if (goBackToFormStep) {
      goBackToFormStep();
    }
  };

  const getDocumentData = () => {
    const selectedLocationDetail = merchants?.find((location) => location.id === previewData.selectedLocation?.value);

    return {
      ...previewData,
      selectedLocation: {
        name: selectedLocationDetail?.name,
        address: selectedLocationDetail?.address.address1,
        city: selectedLocationDetail?.address.city.name,
        state: selectedLocationDetail?.address.city.state?.code,
        zip: selectedLocationDetail?.address.zip,
        phone: merchantDetail?.phone,
      },
    };
  };

  const Print = (
    <Hidden>
      <PrintContent id="print-data" ref={componentRef}>
        <SinglePageEstimatePDF
          page={PDF_PAGE.FIRST}
          previewData={getDocumentData()}
          paymentOptionsTableData={paymentOptionsTableData}
        />
      </PrintContent>
    </Hidden>
  );

  const PrintPdfButton = (
    <ButtonContainer>
      <CherryButton onClick={handlePrint}>{'Print'}</CherryButton>
    </ButtonContainer>
  );

  const sendToPatientDetails = {
    ...previewData?.sendToPatientDetails,
    shouldSendSms,
    shouldSendEmail,
    shouldPrint,
    setShouldSendSms,
    setShouldSendEmail,
    setShouldPrint,
  };

  useEffect(() => {
    const phone = previewData?.data?.phone;
    const previewDataEmail = previewData?.data?.email;
    if (phone) {
      const tempEvent = { target: { value: phone } };
      onPhoneChange(tempEvent);
    }

    if (previewDataEmail) {
      const tempEvent: any = { target: { value: previewDataEmail } };
      onEmailChange(tempEvent);
    }
  }, [previewData]);

  const handleEditPaymentOptionsClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_EDIT_CLICKED');
    onClickBack?.();
  };

  const handlePhoneNumberClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_TEXT_OPTIONS_SUBMITTED', {
      ...previewData,
    });
    onClickSms?.(phoneNumber ?? '');
  };

  const handleSentEmailSubmit = () => {
    trackSegmentEvent('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_EMAIL_OPTIONS_SUBMITTED', {
      ...previewData,
    });
    onClickEmail?.(email);
  };

  return (
    <>
      <Title>{getDocumentData().patientName}’s monthly payment options are ready. Share:</Title>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6}>
          <EditButtonContainer>
            <CherryButton fullWidth={true} variant="secondary" size="small" onClick={handleEditPaymentOptionsClick}>
              Edit Payment Options
            </CherryButton>
          </EditButtonContainer>
          <DialogContent>
            <SinglePageEstimatePDF
              page={PDF_PAGE.FIRST}
              previewData={getDocumentData()}
              paymentOptionsTableData={paymentOptionsTableData}
            />
          </DialogContent>
          {Print}
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <Row>
            <FormContainer>
              <PhoneNumberInput
                inputSize="small"
                name="phone"
                error={!phoneFormatValid}
                value={phoneNumber}
                onChange={onPhoneChange}
                onClick={segmentEventHandler('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_TEXT_OPTIONS_SELECTED')}
                onBlur={segmentEventHandler('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_TEXT_OPTIONS_FILLED', {
                  withInput: true,
                  isFilledEvent: true,
                  cb: logInputBlur,
                })}
                inputProps={{
                  inputMode: 'numeric',
                  'data-testid': 'application-phone-input',
                }}
                actionButton={{
                  text: 'Text',
                  onClick: handlePhoneNumberClick,
                  disabled: !phoneNumberValid || !Boolean(phoneNumber) || applicationSending,
                }}
              />
            </FormContainer>
            <FormContainer>
              <EmailInput
                inputSize="small"
                name="email"
                error={showError}
                value={email}
                defaultValue={email}
                onClick={segmentEventHandler(
                  'PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_EMAIL_OPTIONS_SELECTED',
                )}
                onChange={onEmailChange}
                onBlur={segmentEventHandler('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_SUCCESS_EMAIL_OPTIONS_FILLED', {
                  withInput: true,
                  cb: onEmailChange,
                })}
                inputProps={{
                  'data-testid': 'email-input',
                }}
                actionButton={{
                  text: 'Email',
                  onClick: handleSentEmailSubmit,
                  disabled: !Boolean(email) || !valid || applicationSending,
                }}
              />
            </FormContainer>
          </Row>
          {isShowPrintButtonTop ? PrintPdfButton : null}
          {!hideSendPatientSection ? (
            <>
              <SendToPatient sendToPatientDetails={sendToPatientDetails} />
              <PreviewButtonContainer>
                <SecondaryBackButton onClick={handleBack}>Back</SecondaryBackButton>
                <ButtonGap />
                <SecondaryContinueButton
                  loading={pdfGenerationInProgress}
                  disabled={!shouldPrint && !shouldSendEmail && !shouldSendSms}
                  onClick={handleMultiSelectContinue}
                >
                  Share Plan
                </SecondaryContinueButton>
              </PreviewButtonContainer>
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: -100px 0 -90px;
  transform: scale(0.7);
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  button {
    width: 100%;
  }
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const Hidden = styled.div`
  height: 0;
  overflow: hidden;
`;

const PrintContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${isChrome ? '24px' : '0'};

  margin-top: ${isChrome ? '24px' : '0'};

  height: 100%;
  width: 100%;
`;

const PreviewButtonContainer = styled(ButtonContainer)`
  margin-left: 48px;
  margin-right: 48px;
`;

const FormContainer = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (!props.isFullHeight ? '8px' : '32px')};
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: grid;
`;

const Title = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin: 8px auto 14px;
`;

const EditButtonContainer = styled.div`
  margin: 8px 16px;
`;
