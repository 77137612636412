import { gql } from '@apollo/client/core';

export const UPDATE_MILESTONE_NOTIFICATION_STATUS = gql`
  mutation updateMilestoneNotificationStatus($input: UpdateMilestoneNotificationStatusRequest) {
    updateMilestoneNotificationStatus(input: $input) {
      success
    }
  }
`;

export const PATCH_MILESTONE_CAMPAIGN = gql`
  mutation patchMilestoneCampaign($input: PatchMilestoneCampaignRequest) {
    patchMilestoneCampaign(input: $input) {
      success
    }
  }
`;

export const UPDATE_MILESTONE_PROGRESS_ON_APPLY_WITH_PATIENT = gql`
  mutation updateMilestoneProgressOnApplyWithPatient($input: UpdateMilestoneOnApplyWithPatientRequest) {
    updateMilestoneProgressOnApplyWithPatient(input: $input) {
      success
    }
  }
`;
