import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Calendar } from 'react-date-range';
import styled from 'styled-components';

interface Props {
  show: boolean;
  defaultValue?: Date;
  onDateSelected: (date: Date) => void;
  toggleRangePicker: () => void;
  marginLeft?: string;
  marginTop?: string;
}

interface AbsolutePickerWrapper {
  show?: boolean;
  marginLeft?: string;
  marginTop?: string;
}

interface DatePickerBottomActionsWrapper {
  align: string;
}

export const Datepicker = ({
  show,
  defaultValue = dayjs().toDate(),
  onDateSelected,
  toggleRangePicker,
  marginLeft,
  marginTop,
}: Props) => {
  const datePickerRef = useRef(null);
  const wrapperRef = useRef(null);

  const [date, setDate] = useState<Date>(defaultValue);

  const onDateChange = (e: any) => {
    setDate(e);
    onDateSelected(e);
    toggleRangePicker();
  };

  const onDisableOverlay = () => {
    toggleRangePicker();
  };

  const preventHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <OverlayWrapper show={show} onClick={onDisableOverlay} />
      <AbsolutePickerWrapper
        show={show}
        ref={wrapperRef}
        marginLeft={marginLeft}
        marginTop={marginTop}
        onClick={preventHandler}
      >
        <Calendar
          ref={datePickerRef}
          className={'date--picker'}
          color={'#00c37d'}
          onChange={onDateChange}
          minDate={new Date()}
          date={date}
        />
      </AbsolutePickerWrapper>
    </>
  );
};

const OverlayWrapper = styled.div<AbsolutePickerWrapper>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff60;
  cursor: pointer;
`;

const AbsolutePickerWrapper = styled.div<AbsolutePickerWrapper>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  z-index: 12;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);

  .rdrInputRanges {
    display: none;
  }
`;

const DatePickerBottomActionsWrapper = styled.div<DatePickerBottomActionsWrapper>`
  position: relative;
  top: 85px;
  margin: 0 20px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  text-align: ${(props) => props.align};
`;
