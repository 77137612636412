import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { DetailChartContainer, FixedDecimalFormat, ReportingPageLayout } from 'lib/components';
import { LineChart, StackBarChart } from 'lib/components/Charts';
import { DAILY_FREQUENCY_ENUM } from 'lib/constants';
import { FETCH_APPROVALS_APPLICATIONS } from 'lib/graphql/queries';
import { ReportingAnalyticsEventNames, useAnalytics, useOrganizationGroupTreeRadioGroup } from 'lib/hooks';
import { useDownloadChartData } from 'lib/hooks/useDownloadChartData';
import useStore from 'lib/hooks/useStore';
import { DropdownOption } from 'lib/types';

export const ApprovalApplications = () => {
  const { trackEvent } = useAnalytics();
  const client = useApolloClient();
  const { organization, selectedOrganizationGroup } = useStore();
  const { downloadChartCSV } = useDownloadChartData();
  const [approvals, setApprovals] = useState<any>();
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>('All');
  const [approvalsTimeBreak, setApprovalsTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(DAILY_FREQUENCY_ENUM.DAILY);
  const [applications, setApplications] = useState<any>();
  const [applicationsTimeBreak, setApplicationsTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(DAILY_FREQUENCY_ENUM.DAILY);
  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().endOf('day').toISOString(),
  });
  const { findNodeAndReturnArrays, organizationGroupTree } = useOrganizationGroupTreeRadioGroup();
  const [location, setLocation] = useState<DropdownOption>({ value: 'selectAll', label: 'All Accounts' });
  const [organizationIds, setOrganizationIds] = useState<string | null>(`${organization?.id}`);

  useEffect(() => {
    fetchSummaryChartData();
  }, [filterSelectedDateValue, approvalsTimeBreak, applicationsTimeBreak, selectedMerchant, organizationIds]);

  const fetchSummaryChartData = async () => {
    const { data } = await client.query({
      query: FETCH_APPROVALS_APPLICATIONS,
      variables: {
        approvalInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: approvalsTimeBreak,
        },
        applicationInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: applicationsTimeBreak,
        },
      },
    });
    setApplications(data?.getTotalApplications);
    setApprovals(data?.getTotalApprovalsSummary);
  };

  const onApprovalTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setApprovalsTimeBreak(timeBreak);
  };

  const onApprovalDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.APPROVALS_DOWNLOAD_CLICK,
    });
    downloadChartCSV(approvals?.contents, ['name', 'data'], 'Date,Application Amount');
  };

  const onApplicationsTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setApplicationsTimeBreak(timeBreak);
  };

  const onApplicationsDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.APPLICATION_DOWNLOAD_CLICK,
    });
    downloadChartCSV(
      applications?.contents,
      ['name', 'completeApplications', 'incompleteApplications'],
      'Date,Completed Applications,Incomplete Applications',
    );
  };

  const onSelectedDateChange = (selectedDate: any) => {
    setFilterSelectedDateValue(selectedDate);
  };

  const organizationGroupSelectionHandler = (data) => {
    setLocation(data);
    if (data?.value === 'selectAll') {
      setOrganizationIds(String(organization?.id));
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setLocation({ value: 'selectAll', label: 'All Accounts' });
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    } else {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree[0],
        data.value,
        data.type,
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  };

  return (
    <ReportingPageLayout
      title={'Approvals & Applications'}
      onDateRangeChange={onSelectedDateChange}
      onLocationChange={setSelectedMerchant}
      organizationGroupSelectionHandler={organizationGroupSelectionHandler}
      selectedOrganizationGroup={location}
      selectedMerchant={selectedMerchant as string}
    >
      <DetailChartContainer
        id={'Approvals'}
        mainStatistic={{
          header: 'Total Approval Amount',
          description: <FixedDecimalFormat amount={approvals?.totalApprovalAmount} prefix="$" />,
        }}
        onTimeBreakSelected={onApprovalTimeBreakSelected}
        onDownloadClick={onApprovalDownloadClicked}
        statistics={[
          {
            header: 'Total Approvals',
            description: approvals?.totalApprovals,
          },
          {
            header: 'Avg Approval Amount',
            description: <FixedDecimalFormat amount={approvals?.avgTotalAmount} prefix="$" />,
          },
        ]}
      >
        <LineChart
          data={approvals?.contents}
          height={480}
          toolTipLabel={'Approval Amount'}
          dataType="money"
          labelTitle={'Approval Amount'}
        />
      </DetailChartContainer>
      <DetailChartContainer
        id={'Total Applications'}
        mainStatistic={{ header: 'Total Applications', description: applications?.totalApplications }}
        onTimeBreakSelected={onApplicationsTimeBreakSelected}
        onDownloadClick={onApplicationsDownloadClicked}
        statistics={[
          {
            header: 'Completed Applications',
            description: <FixedDecimalFormat amount={applications?.approvalRate * 100} suffix="%" />,
          },
          {
            header: 'Total Incomplete Applications',
            description: applications?.totalIncompleteApplications,
          },
          {
            header: 'Incompletion Rate',
            description: <FixedDecimalFormat amount={applications?.incompletionRate * 100} suffix="%" />,
          },
        ]}
      >
        <StackBarChart
          data={applications?.contents}
          height={480}
          firstDataLabel={'completeApplications'}
          secondDataLabel={'incompleteApplications'}
          firstToolTipLabel={'Completed Applications'}
          secondToolTipLabel={'Incomplete Applications'}
          chartType="detail"
          labelTitle={'Applications'}
        />
      </DetailChartContainer>
    </ReportingPageLayout>
  );
};
