import { FormControl, Grid, RadioGroup } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, Radio, TextInput } from '@frontend/cherry-library';
import images from 'assets/images';
import Popper from 'lib/components/Popper';
import { accountNumberPattern, routingNumberPattern } from 'lib/utils';
import {
  ACCOUNT_CLASSIFICATION,
  ACCOUNT_TYPES,
  ICON_REF_NAMES,
  InfoFormState,
  routingNumberDoubleCheckList,
  TOOLTIP_CONTENT,
} from 'pages/desktop/Settings/components/BankInformation/constants';

interface TooltipConfig {
  visible: boolean;
  anchorEl: HTMLImageElement | null;
  hoveredFieldName: string | null;
}

interface InfoFormProps {
  state: InfoFormState;
  buttonDisable: boolean | undefined;
  handleFormClose: () => void;
  onFormSubmit: (state: InfoFormState) => void;
  isButtonDisabled: (state: InfoFormState, validRoutingNumber: boolean, validAccountNumber: boolean) => void;
}

const InfoForm = ({ state, buttonDisable, handleFormClose, onFormSubmit, isButtonDisabled }: InfoFormProps) => {
  const [formState, setState] = useState<InfoFormState>({
    accountNickname: null,
    accountName: null,
    routingNumber: null,
    accountNumber: null,
    accountType: ACCOUNT_TYPES.CHECKING,
    accountClassification: ACCOUNT_CLASSIFICATION.PERSONAL,
  });
  const [tooltip, setTooltip] = useState<TooltipConfig>({
    visible: false,
    anchorEl: null,
    hoveredFieldName: null,
  });
  const [isValidAccountNumber, setIsValidAccountNumber] = useState<boolean>(true);
  const [isValidRoutingNumber, setIsValidRoutingNumber] = useState<boolean>(true);
  const [isInDoubleCheckList, setIsInDoubleCheckList] = useState(false);

  const nicknameIconRef = useRef<HTMLImageElement>(null);
  const accountNameIconRef = useRef<HTMLImageElement>(null);
  const routingNumberIconRef = useRef<HTMLImageElement>(null);
  const accountNumberIconRef = useRef<HTMLImageElement>(null);

  const iconRefs = {
    [ICON_REF_NAMES.NICKNAME]: nicknameIconRef,
    [ICON_REF_NAMES.ACCOUNT_NAME]: accountNameIconRef,
    [ICON_REF_NAMES.ROUTING_NUMBER]: routingNumberIconRef,
    [ICON_REF_NAMES.ACCOUNT_NUMBER]: accountNumberIconRef,
  };

  useEffect(() => {
    const { accountNumber, routingNumber } = state;
    setState(state);

    if (!!accountNumber && routingNumber) {
      setIsValidAccountNumber(validAccountNumber(accountNumber));
      setIsValidRoutingNumber(validRoutingNumber(routingNumber));
    }
  }, [state]);

  useEffect(() => {
    isButtonDisabled(formState, !!isValidRoutingNumber, !!isValidAccountNumber);
  }, [formState, isValidRoutingNumber, isValidAccountNumber]);

  const checker = useCallback(() => {
    if (formState?.routingNumber && routingNumberDoubleCheckList.indexOf(formState.routingNumber) > -1) {
      setIsInDoubleCheckList(true);
    } else {
      setIsInDoubleCheckList(false);
    }
  }, [formState.routingNumber]);

  useEffect(() => {
    checker();
  }, [checker]);

  const handleOrderButtonMouseEnter = (event) => {
    if (iconRefs[event?.nativeEvent?.relatedTarget?.id]) {
      setTooltip({
        visible: true,
        anchorEl: iconRefs[event.nativeEvent.relatedTarget.id]?.current,
        hoveredFieldName: event.nativeEvent.relatedTarget.id,
      });
    }
  };

  const handleOrderButtonMouseLeave = () => {
    setTooltip({
      visible: false,
      anchorEl: null,
      hoveredFieldName: null,
    });
  };

  const handleNickNameChange = (nativeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;
    setState((prevState) => {
      return { ...prevState, accountNickname: value };
    });
  };

  const handleAccountNameChange = (nativeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;
    setState((prevState) => {
      return { ...prevState, accountName: value };
    });
  };

  const handleRoutingNumberChange = (nativeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    setIsValidRoutingNumber(validRoutingNumber(value));
    setState((prevState) => {
      return { ...prevState, routingNumber: value };
    });
  };

  const handleAccountNumberChange = (nativeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    setIsValidAccountNumber(validAccountNumber(value));
    setState((prevState) => {
      return { ...prevState, accountNumber: value };
    });
  };

  const onChangeType = (event) => {
    setState((prevState) => {
      return { ...prevState, accountType: event?.target?.value };
    });
  };

  const onChangeClassification = (event) => {
    setState((prevState) => {
      return { ...prevState, accountClassification: event?.target?.value };
    });
  };

  const validAccountNumber = (value: string) => {
    return accountNumberPattern.test(value);
  };

  const validRoutingNumber = (value: string) => {
    return routingNumberPattern.test(value);
  };

  const onContinue = () => {
    onFormSubmit(formState);
  };

  return (
    <>
      <VoidedCheckTitle>Example Check:</VoidedCheckTitle>
      <VoidedCheckImage src={images.bankInformation.default} />
      <FormContainer>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12} spacing={2}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlHeader>
                <FormTitle hasError={false}>Nickname</FormTitle>
                <img
                  alt="nickname-icon"
                  id={ICON_REF_NAMES.NICKNAME}
                  src={images.infoCircle.default}
                  ref={nicknameIconRef}
                  onMouseEnter={handleOrderButtonMouseEnter}
                  onMouseLeave={handleOrderButtonMouseLeave}
                />
              </FormControlHeader>
              <TextInput
                inputSize="small"
                type="text"
                id="bank-information-nickname"
                data-testid="bank-information-nickname"
                onChange={handleNickNameChange}
                value={formState?.accountNickname}
                label="Ex: Cherry Technologies Checking"
                showPlaceholderAnimation={false}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} spacing={2}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlHeader>
                <FormTitle hasError={!!isInDoubleCheckList}>Account Name</FormTitle>
                <img
                  alt="account-name-icon"
                  id={ICON_REF_NAMES.ACCOUNT_NAME}
                  src={images.infoCircle.default}
                  ref={accountNameIconRef}
                  onMouseEnter={handleOrderButtonMouseEnter}
                  onMouseLeave={handleOrderButtonMouseLeave}
                />
              </FormControlHeader>
              <TextInput
                inputSize="small"
                type="text"
                id="bank-information-account-name"
                data-testid="bank-information-account-name"
                onChange={handleAccountNameChange}
                value={formState?.accountName}
                required={true}
                error={!!isInDoubleCheckList}
                label="Ex: Cherry Technologies, Inc."
                showPlaceholderAnimation={false}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} spacing={2}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlHeader>
                <FormTitle hasError={!isValidRoutingNumber}>Routing Number</FormTitle>
                <img
                  alt="routing-no-icon"
                  id={ICON_REF_NAMES.ROUTING_NUMBER}
                  src={images.infoCircle.default}
                  ref={routingNumberIconRef}
                  onMouseEnter={handleOrderButtonMouseEnter}
                  onMouseLeave={handleOrderButtonMouseLeave}
                />
              </FormControlHeader>
              <TextInput
                inputSize="small"
                type="text"
                id="bank-information-routing-number"
                data-testid="bank-information-routing-number"
                onChange={handleRoutingNumberChange}
                value={formState?.routingNumber}
                error={!isValidRoutingNumber}
                errorText="Please enter a valid routing number"
                required={true}
                label="Ex: 123456789"
                showPlaceholderAnimation={false}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} spacing={2}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlHeader>
                <FormTitle hasError={!isValidAccountNumber}>Account Number</FormTitle>
                <img
                  alt="account-no-icon"
                  id={ICON_REF_NAMES.ACCOUNT_NUMBER}
                  src={images.infoCircle.default}
                  ref={accountNumberIconRef}
                  onMouseEnter={handleOrderButtonMouseEnter}
                  onMouseLeave={handleOrderButtonMouseLeave}
                />
              </FormControlHeader>
              <TextInput
                inputSize="small"
                type="text"
                id="bank-information-account-number"
                data-testid="bank-information-account-number"
                onChange={handleAccountNumberChange}
                value={formState?.accountNumber}
                error={!isValidAccountNumber}
                errorText="Please enter a valid account number"
                required={true}
                label="Ex: 00011122233"
                showPlaceholderAnimation={false}
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={12} spacing={2}>
            <RadioTitle>Account Type</RadioTitle>
            <CustomRadioGroup aria-label="single-filter" name="singleFilter" value={formState?.accountType}>
              <Radio
                onChange={onChangeType}
                label={'Checking'}
                value={ACCOUNT_TYPES.CHECKING}
                data-testid="account-type-checking-radio"
                checked={formState?.accountType === ACCOUNT_TYPES.CHECKING}
              />
              <Radio
                label={'Savings'}
                data-testid="account-type-savings-radio"
                value={ACCOUNT_TYPES.SAVINGS}
                onChange={onChangeType}
                checked={formState?.accountType === ACCOUNT_TYPES.SAVINGS}
              />
            </CustomRadioGroup>
          </Grid>
        </Grid>

        <Grid item={true} xs={12} spacing={2}>
          <RadioTitle>Is this a personal or business account?</RadioTitle>
          <CustomRadioGroup aria-label="single-filter" name="singleFilter" value={formState?.accountClassification}>
            <Radio
              label={'Personal'}
              value={ACCOUNT_CLASSIFICATION.PERSONAL}
              data-testid="account-classification-personal-radio"
              onChange={onChangeClassification}
              checked={formState?.accountClassification === ACCOUNT_CLASSIFICATION.PERSONAL}
            />
            <Radio
              label={'Business'}
              value={ACCOUNT_CLASSIFICATION.BUSINESS}
              data-testid="account-classification-business-radio"
              onChange={onChangeClassification}
              checked={formState?.accountClassification === ACCOUNT_CLASSIFICATION.BUSINESS}
            />
          </CustomRadioGroup>
        </Grid>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <Button fullWidth={true} variant="secondary" disabled={false} onClick={handleFormClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <Button fullWidth={true} disabled={buttonDisable} onClick={onContinue}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
      <Tooltip anchorEl={tooltip.anchorEl} open={tooltip.visible} id="" placement="right">
        <PopperContent>
          <PopperTitle>
            {tooltip.hoveredFieldName && <div>{TOOLTIP_CONTENT[tooltip.hoveredFieldName].title}</div>}
          </PopperTitle>
          <PopperText>
            {tooltip.hoveredFieldName && (
              <div>
                {TOOLTIP_CONTENT[tooltip.hoveredFieldName].content?.map((content) => {
                  if (typeof content === 'string') {
                    return <p key={`${tooltip.hoveredFieldName}-tooltip-content`}>{content}</p>;
                  } else if (typeof content === 'object' && content.type === 'bulletList') {
                    return (
                      <ul>
                        {content.items?.map((item, index) => (
                          <li key={`${tooltip.hoveredFieldName}-${index}-tooltip-content`}>{item}</li>
                        ))}
                      </ul>
                    );
                  }
                })}
              </div>
            )}
          </PopperText>
        </PopperContent>
      </Tooltip>
    </>
  );
};

export default InfoForm;

const VoidedCheckTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

const VoidedCheckImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 350px;
`;

const FormContainer = styled.div`
  .MuiFormHelperText-contained {
    margin-left: 0;
  }
`;

const FormControlHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  img {
    width: 16px;
    height: 16px;
  }
`;

const FormTitle = styled.label<{ hasError?: boolean }>`
  margin: 4px 0 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.hasError ? props.theme.main.errorColor : props.theme.main.textColor)} !important;
  font-weight: 400;
`;

const RadioTitle = styled.div`
  margin: 8px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
const CustomRadioGroup = styled(RadioGroup)`
  padding-bottom: ${(props) => props.theme.constants.m};

  .MuiFormControlLabel-root:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.main.green};
  }

  .MuiTypography-body1 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif !important;
  }
`;

const Tooltip = styled(Popper)`
  max-width: 380px;
`;

const PopperContent = styled.div`
  text-align: left;
  width: fit-content;
  padding: 8px;

  ul {
    padding: 0 0 0 20px;
  }
`;

const PopperTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const PopperText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
