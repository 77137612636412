import images from 'assets/images';

import DownPayment from 'pages/desktop/CheckoutWithPatient/DownPayment';

import { useMutation } from '@apollo/client';
import { PATCH_LOAN } from 'lib/graphql/mutations';
import { usePaymentMethods } from 'pages/desktop/CheckoutWithPatient/PaymentMethodContext/PaymentMethodContext';
import RecurringPayment from 'pages/desktop/CheckoutWithPatient/RecurringPayment';
import RecurringPaymentTypeSelect from 'pages/desktop/CheckoutWithPatient/RecurringPaymentTypeSelect';
import {
  Container,
  PopperContent,
  RecurringPaymentTypeSelectContainer,
  Title,
} from 'pages/desktop/CheckoutWithPatient/SetupPayment/styles';
import {
  PaymentMethod,
  RecurringPaymentType,
  SetupPaymentProps,
} from 'pages/desktop/CheckoutWithPatient/SetupPayment/types';

import Popper from 'lib/components/Popper';
import { useSegment } from 'lib/hooks/useSegment';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import PaymentMethodSummary from '../PaymentMethodSummary';
import Section from '../Section';

const SETUP_PAYMENT_STEP_INDEX = 1;

const SetupPayment = ({ borrowerId, applicationId, loan, currentStep, setStep }: SetupPaymentProps) => {
  const alert = useAlert();
  const {
    paymentMethods,
    handlePaymentMethods,
    isPatientInReviewMode,
    downPaymentMethod,
    recurringPaymentMethod,
    setDownPaymentMethod,
    setRecurringPaymentMethod,
  } = usePaymentMethods();
  const [patchLoan] = useMutation(PATCH_LOAN);
  const { trackSegmentEvent } = useSegment();
  const { id } = useParams();

  const recurringPaymentInfoIconRef = useRef<HTMLImageElement>(null);

  const [recurringPaymentType, setRecurringPaymentType] = useState<RecurringPaymentType | null>(null);
  const [preselectedRecurringType, setPreselectedRecurringType] = useState<RecurringPaymentType | null>(null);
  const [showPaymentMethodList, setShowPaymentMethodList] = useState(false);
  const [showSummaryView, setShowSummaryView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRecurringPaymentInfoTooltip, setShowRecurringPaymentInfoTooltip] = useState<boolean>(false);
  const [useSameMethodDisabled, setUseSameMethodDisabled] = useState(false);

  useEffect(() => {
    if (borrowerId) {
      handlePaymentMethods(borrowerId);
    }
  }, [handlePaymentMethods, borrowerId]);

  useEffect(() => {
    if (preselectedRecurringType) {
      setRecurringPaymentType(preselectedRecurringType);
    }
  }, [preselectedRecurringType]);

  useEffect(() => {
    if (recurringPaymentType === RecurringPaymentType.DownPaymentMethod && downPaymentMethod) {
      setRecurringPaymentMethod(downPaymentMethod);
      setShowPaymentMethodList(false);
    } else if (recurringPaymentType === RecurringPaymentType.Other) {
      setShowPaymentMethodList(true);
    }
  }, [recurringPaymentType, downPaymentMethod]);

  useEffect(() => {
    if (currentStep === SETUP_PAYMENT_STEP_INDEX) {
      setShowSummaryView(false);
    }
  }, [currentStep]);

  const handleContinue = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_2_CONTINUE_CLICKED', {
      loanId: id,
      borrowerId,
    });
    if (downPaymentMethod && recurringPaymentMethod && loan) {
      setLoading(true);
      try {
        const { data } = await patchLoan({
          variables: {
            input: {
              loanId: loan.id,
              downPaymentPaymentMethodId: downPaymentMethod.id,
              installmentPaymentMethodId: recurringPaymentMethod.id,
            },
          },
        });

        if (data?.patchLoan?.id) {
          setShowSummaryView(true);
          setStep(SETUP_PAYMENT_STEP_INDEX + 1);
        } else {
          alert.error('Error updating payment method');
        }
      } catch (err) {
        alert.error('');
      } finally {
        setLoading(false);
      }
    }
  };

  const closeSummaryView = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_2_EDIT_CLICKED', {
      loanId: id,
      borrowerId,
    });
    setShowSummaryView(false);
    setStep(SETUP_PAYMENT_STEP_INDEX);
  };

  const stepBack = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_2_BACK_CLICKED', {
      loanId: id,
      borrowerId,
    });
    setStep(SETUP_PAYMENT_STEP_INDEX - 1);
  };

  const handleRecurringPaymentInfoIconMouseEnter = () => {
    if (!showRecurringPaymentInfoTooltip) {
      trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RECURRING_PAYMENT_RESTRICTION_TOOLTIP_HOVER_VIEWED', {
        loanId: id,
        borrowerId,
      });
      setShowRecurringPaymentInfoTooltip(true);
    }
  };

  const handleRecurringPaymentInfoIconMouseLeave = () => {
    if (showRecurringPaymentInfoTooltip) {
      setShowRecurringPaymentInfoTooltip(false);
    }
  };

  const handleRecurringPaymentTypeSelect = (type: RecurringPaymentType) => {
    if (type !== recurringPaymentType) {
      if (type === RecurringPaymentType.DownPaymentMethod) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RECURRING_PAYMENT_USE_SAME_METHOD_CLICKED', {
          loanId: id,
          borrowerId,
          preSelected: preselectedRecurringType === RecurringPaymentType.DownPaymentMethod,
        });
      } else if (type === RecurringPaymentType.Other) {
        trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RECURRING_PAYMENT_USE_DIFFERENT_METHOD_CLICKED', {
          loanId: id,
          borrowerId,
          preSelected: preselectedRecurringType === RecurringPaymentType.Other,
        });
      }

      setRecurringPaymentType(type);
    }
  };

  useEffect(() => {
    const isDownPaymentMethodCreditCard =
      downPaymentMethod?.type === 'CARD' && downPaymentMethod.storedCard?.type === 'CREDIT';
    const isDownPaymentMethodDebit =
      downPaymentMethod?.type === 'CARD' && downPaymentMethod.storedCard?.type === 'DEBIT';
    const userHasAchMethod = paymentMethods?.filter((paymentMethod) => paymentMethod?.type === 'ACH')?.length > 0;
    const userHasCardOtherThanCredit =
      paymentMethods?.filter((card) => card?.storedCard?.type !== 'CREDIT')?.length > 0;

    const isSameMethodDisabled = isDownPaymentMethodCreditCard && userHasCardOtherThanCredit;

    const isUseSameMethodPreselected =
      !isSameMethodDisabled ||
      (isDownPaymentMethodCreditCard && !userHasCardOtherThanCredit) ||
      (isDownPaymentMethodDebit && !userHasAchMethod);

    const isUseDifferentMethodPreselected = isSameMethodDisabled || userHasAchMethod;

    if (isSameMethodDisabled || isUseDifferentMethodPreselected) {
      setPreselectedRecurringType(RecurringPaymentType.Other);
    } else if (isUseSameMethodPreselected) {
      setPreselectedRecurringType(RecurringPaymentType.DownPaymentMethod);
    }
    setUseSameMethodDisabled(isSameMethodDisabled);
  }, [downPaymentMethod, paymentMethods]);

  const onDownPaymentMethodSelect = (paymentMethod: PaymentMethod) => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.DOWN_PAYMENT_PAYMENT_METHOD_CLICKED', {
      loanId: id,
      borrowerId,
    });
    setDownPaymentMethod(paymentMethod);
  };

  const onRecurringPaymentMethodSelect = (paymentMethod: PaymentMethod) => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.RECURRING_PAYMENT_PAYMENT_METHOD_CLICKED', {
      loanId: id,
      borrowerId,
    });
    setRecurringPaymentMethod(paymentMethod);
  };

  const renderAccordionContent = () => {
    if (!borrowerId || !applicationId) {
      return null;
    }

    return (
      <Container>
        <div>
          <Title>Payment method for downpayment</Title>
          <DownPayment
            borrowerId={borrowerId}
            applicationId={applicationId}
            selectedDownPaymentMethod={downPaymentMethod}
            onMethodSelect={onDownPaymentMethodSelect}
          />
        </div>
        {downPaymentMethod && (
          <div>
            <Title>
              <span>Payment method for future monthly payments</span>
              <img
                src={images.infoCircle.default}
                ref={recurringPaymentInfoIconRef}
                onMouseEnter={handleRecurringPaymentInfoIconMouseEnter}
                onMouseLeave={handleRecurringPaymentInfoIconMouseLeave}
              />
              {recurringPaymentInfoIconRef?.current ? (
                <Popper
                  anchorEl={recurringPaymentInfoIconRef?.current}
                  id=""
                  open={showRecurringPaymentInfoTooltip}
                  placement="right"
                >
                  <PopperContent>
                    Credit cards cannot be used for future recurring payments if the patient has a debit card or bank
                    account saved.
                  </PopperContent>
                </Popper>
              ) : null}
            </Title>
            <RecurringPaymentTypeSelectContainer>
              <RecurringPaymentTypeSelect
                onPaymentSelection={handleRecurringPaymentTypeSelect}
                selectedValue={recurringPaymentType}
                isDownPaymentMethodDisabled={useSameMethodDisabled}
              />
            </RecurringPaymentTypeSelectContainer>
            {showPaymentMethodList && (
              <RecurringPayment
                borrowerId={borrowerId}
                applicationId={applicationId}
                selectedRecurringPaymentMethod={recurringPaymentMethod}
                onMethodSelect={onRecurringPaymentMethodSelect}
              />
            )}
          </div>
        )}
      </Container>
    );
  };

  return (
    <Section
      title="Step 2: Set Up Payment"
      isExpanded={currentStep === SETUP_PAYMENT_STEP_INDEX || showSummaryView}
      showEditButton={showSummaryView}
      isEditButtonDisabled={isPatientInReviewMode}
      isContinueButtonDisabled={!downPaymentMethod || !recurringPaymentMethod}
      loading={!borrowerId || !applicationId || loading}
      onEdit={closeSummaryView}
      onBack={stepBack}
      onContinue={handleContinue}
    >
      {showSummaryView && downPaymentMethod && recurringPaymentMethod && loan ? (
        <PaymentMethodSummary
          downPaymentMethod={downPaymentMethod}
          recurringPaymentMethod={recurringPaymentMethod}
          loan={loan}
        />
      ) : (
        renderAccordionContent()
      )}
    </Section>
  );
};

export default SetupPayment;
