import jwtDecode from 'jwt-decode';
import { Loading } from 'lib/components';
import client from 'lib/graphql/client';
import { FETCH_ORGANIZATION, FETCH_ORGANIZATION_MERCHANTS } from 'lib/graphql/queries';
import { useGetExchangeToken } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import React, { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Oauth = () => {
  const alert = useAlert();
  const { id } = useParams();
  const navigate = useNavigate();
  const [getExchangeToken, { data: exchangeData }] = useGetExchangeToken();
  const {
    setUser,
    setSelectedOrganization,
    setOrganizationMerchants,
    setSelectedMerchant,
    setActivateGuestMode,
  } = useStore();

  const checkIdExist = useCallback(() => {
    if (id) {
      getExchangeToken({ variables: { input: { token: id } } });
    } else {
      navigate(`/`);
    }
  }, [id]);

  useEffect(() => {
    checkIdExist();
  }, [checkIdExist]);

  useEffect(() => {
    initializeExchangeToken();
  }, [exchangeData]);

  const initializeExchangeToken = () => {
    try {
      if (exchangeData?.getExchangeToken) {
        if (exchangeData?.getExchangeToken?.status !== 'EXPIRED') {
          const {
            token,
            type,
            data: { organizationId, merchantId },
          } = exchangeData?.getExchangeToken;
          const { user } = (jwtDecode((token as any) || '') as any) || {};

          if (!type?.includes('SECURE_DOCUMENT')) {
            setActivateGuestMode(true);
          }

          StorageService.setUserData({ refreshToken: '', token, user });
          StorageService.setAuthData({ authToken: token, refreshToken: '' });
          setUser(user);
          handleLoginFlow(organizationId, merchantId, type);
        } else {
          alert.error('Your token is expired. Please start over.');
          navigate('/');
        }
      }
    } catch (err) {
      alert.error('Your token is expired. Please start over.');
      navigate('/');
    }
  };

  const handleLoginFlow = async (organizationId, merchantId, type) => {
    try {
      if (!merchantId) {
        const {
          data: { fetchUserOrganizations },
        } = await client.query({ query: FETCH_ORGANIZATION, variables: { input: {} } });

        if (fetchUserOrganizations?.data) {
          const findOrganization = fetchUserOrganizations?.data?.find((org) => org.id === String(organizationId));
          setSelectedOrganization(findOrganization);
        }
      } else {
        setSelectedOrganization({ id: organizationId });

        const {
          data: { fetchOrganizationMerchants },
        } = await client.query({
          query: FETCH_ORGANIZATION_MERCHANTS,
          variables: { input: { idOrganization: organizationId } },
        });
        const merchants = fetchOrganizationMerchants.data;
        if (merchants?.length > 0) {
          setOrganizationMerchants(merchants);
          setSelectedMerchant(merchants[0]);
        }
      }

      if (type.includes('SECURE_DOCUMENT')) {
        navigate('/document-requests');
      } else {
        navigate('/');
      }
    } catch (err) {
      StorageService.clearUserData();
      navigate('/login');
    }
  };

  return (
    <CenterLoading>
      <Loading size={40} />
    </CenterLoading>
  );
};

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;

export default Oauth;
