import { PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Container } from 'lib/styles';
import DocumentRequestList from 'pages/desktop/DocumentRequests/views/DocumentRequestList/DocumentRequestList';
import React from 'react';

const DocumentRequests = () => {
  const { isSnackbarVisible } = useSnackbar();

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Document Requests" />
      <DocumentRequestList />
    </Container>
  );
};

export default DocumentRequests;
