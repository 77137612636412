import * as Sentry from '@sentry/react';

export const initChatWidget = () => {
  const serverHost = 'https://home-c68.nice-incontact.com';
  const busNo = 4606496;
  const poc = 'db0ab727-4e5c-4ddf-86b8-df64d3e7a0ed';

  try {
    const hasChatWidget = document.getElementById('chat-div-wrap');
    if (!hasChatWidget) {
      // @ts-ignore
      window.icPatronChat.init({
        serverHost,
        bus_no: busNo,
        poc,
      });
    }
  } catch (err) {
    Sentry.captureException(err, { level: 'error', extra: { errorContext: 'openChat => ChatWidgetButton.tsx' } });
  }
};
