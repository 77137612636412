import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_ORGANIZATION_GROUP_TREE } from '../graphql/queries';
import useStore from './useStore';

interface NodeType {
  id: string;
  type: any;
  name: string;
  childs?: NodeType[];
}

interface RenderTree {
  id: string;
  name: string;
  type: string;
  childs?: readonly RenderTree[];
}

export const useOrganizationGroupTreeRadioGroup = () => {
  const { organization, isAdminReporting, selectedOrganizationGroup } = useStore();
  const client = useApolloClient();
  const [organizationGroupTree, setOrganizationGroupTree] = useState<RenderTree[]>([]);

  const fetchOrganizationGroupTree = async () => {
    const input = {
      [`${isAdminReporting ? 'organizationGroupId' : 'organizationId'}`]: isAdminReporting
        ? selectedOrganizationGroup?.id
        : organization?.id,
    };
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_GROUP_TREE,
      variables: {
        input,
      },
    });

    const organizationGroupTreeStructure = data?.fetchOrganizationGroupTree;
    setOrganizationGroupTree([organizationGroupTreeStructure]);
  };

  useEffect(() => {
    fetchOrganizationGroupTree();
  }, []);

  function findNodeAndReturnArrays(root: NodeType, id: string | number, type: string): [string[], string[]] {
    const organizationIds: string[] = [];
    const merchantIds: string[] = [];

    function traverseNode(node): boolean {
      // tslint:disable-next-line: triple-equals
      if (node?.id == id && node?.type === type) {
        collectChilds(node);
        return true;
      }
      for (const child of node?.childs || []) {
        if (traverseNode(child)) return true;
      }
      return false;
    }

    function collectChilds(node: NodeType) {
      for (const child of node?.childs || []) {
        if (child.type === 'ORGANIZATION') organizationIds.push(child.id);
        if (child.type === 'MERCHANT') merchantIds.push(child.id);
        collectChilds(child);
      }
    }

    if (type === 'ORGANIZATION' && organizationIds.length === 0) {
      organizationIds.push(String(id));
    }

    if (type === 'MERCHANT' && merchantIds.length === 0) {
      merchantIds.push(String(id));
    }

    traverseNode(root);
    return [organizationIds, merchantIds];
  }

  return { findNodeAndReturnArrays, organizationGroupTree };
};
