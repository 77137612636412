import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

interface Props {
  title: string;
  placement?: any;
  hasArrow: boolean;
  icon: any;
  iconSize: any;
  iconColor: any;
  key: any;
}

const PREFIX = 'Tooltip';
const classes = {
  tooltip: `${PREFIX}-tooltip`,
  arrow: `${PREFIX}-arrow`,
};

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`&.${classes.tooltip}`]: {
    backgroundColor: '#00c37d',
    color: 'white',
    fontWeight: 400,
    maxWidth: 200,
    fontSize: '12px',
    padding: '8px',
  },
  [`& .${classes.arrow}`]: {
    color: '#00c37d',
  },
}));

export const CherryTooltip = ({ title, placement = 'top', icon, hasArrow, iconSize, key, iconColor }: Props) => {
  return (
    <HtmlTooltip title={title} placement={placement} arrow={hasArrow} key={key}>
      <IconButton>
        <FontAwesomeIcon icon={icon} size={iconSize} color={iconColor} />
      </IconButton>
    </HtmlTooltip>
  );
};
