import { Grid } from '@mui/material';
import { Subtitle } from 'lib/components/mobile';
import { InfoFormState } from 'pages/desktop/Settings/components/BankInformation/constants';
import { BackButton, ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import React from 'react';
import styled from 'styled-components';

interface SummaryTableProps {
  state: InfoFormState;
  buttonDisable: boolean | undefined;
  setModalStatusToEdit: () => void;
  handlePatchMerchant: () => Promise<void>;
}

const SummaryTable = ({ state, setModalStatusToEdit, buttonDisable, handlePatchMerchant }: SummaryTableProps) => {
  return (
    <>
      <SummaryContainer>
        <SummaryTitle>Banking Information</SummaryTitle>
        <SummaryRow>
          <SummaryTableColumnName>Nickname:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.accountNickname}</SummaryTableColumnValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryTableColumnName>Bank Account Name:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.accountName}</SummaryTableColumnValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryTableColumnName>Routing Number:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.routingNumber}</SummaryTableColumnValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryTableColumnName>Account Number:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.accountNumber}</SummaryTableColumnValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryTableColumnName>Bank Account Type:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.accountType}</SummaryTableColumnValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryTableColumnName>Bank Account Classification:</SummaryTableColumnName>
          <SummaryTableColumnValue>{state.accountClassification}</SummaryTableColumnValue>
        </SummaryRow>
      </SummaryContainer>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={6}>
          <CancelButton disabled={false} onClick={setModalStatusToEdit}>
            Cancel
          </CancelButton>
        </Grid>
        <Grid item={true} xs={6}>
          <ConfirmButton disabled={buttonDisable} onClick={handlePatchMerchant}>
            Submit Updates
          </ConfirmButton>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryTable;

const SummaryContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.main.sealGray};
  background: ${(props) => props.theme.main.aliceBlue};
  padding: 16px;
  margin-bottom: 24px;
`;

const SummaryTitle = styled(Subtitle)`
  font-weight: 700;
  margin: 0 0 16px 0;
  text-align: left;
`;

const SummaryRow = styled.div`
  display: flex;
  font-size: 14px;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const SummaryTableColumnName = styled.div`
  flex: 1;
`;

const SummaryTableColumnValue = styled.div`
  flex: 1.5;
`;

const CancelButton = styled(BackButton)`
  margin: 0;
  padding: 9px 11px;
  font-weight: 600;
`;

const ConfirmButton = styled(ContinueButton)`
  width: 100%;
  margin: 0;
  padding: 9px 11px;
  font-weight: 600;
`;
