import { Button as CherryButton } from '@frontend/cherry-library';
import { DashCard, DashComponent } from 'lib/components';
import { Column } from 'lib/styles';
import { Row } from 'lib/styles';
import React from 'react';

interface Props {
  title: string;
  requestUrl: string;
  description: string;
  buttonText: string;
}

export const ScheduleMeeting = ({ title, requestUrl, description, buttonText }: Props) => {
  const openModal = () => {
    window.open(requestUrl, '_blank');
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <Row fontSize={'14px'}>{description}</Row>
        <br />
        <Row>
          <CherryButton data-testid="scheduleMeetingButton" onClick={openModal}>
            {buttonText}
          </CherryButton>
        </Row>
      </DashCard>
    </Column>
  );
};
