import { gql } from '@apollo/client/core';

export const GET_MEMBERSHIP_PLANS = gql`
  query fetchOrganizationsMembershipPlans($input: FetchOrganizationMembershipsPlansInput) {
    fetchOrganizationsMembershipPlans(input: $input) {
      total
      contents {
        id
        name
        organizationId
        description
        detailedDescription
        memberCount
        status
        recommended
        prices {
          id
          period
          price
          planId
          firstPayment
          autoRenew
          billingTerm
          paymentFrequency
        }
        benefits {
          id
          name
          type
          frequency
          quantity
          planId
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const GET_MEMBERSHIP_PLAN_DETAIL = gql`
  query fetchMembershipsPlanDetail($input: FetchMembershipsPlanDetailInput) {
    fetchMembershipsPlanDetail(input: $input) {
      id
      name
      organizationId
      description
      detailedDescription
      status
      recommended
      prices {
        id
        period
        price
        planId
        firstPayment
        autoRenew
        billingTerm
        paymentFrequency
      }
      benefits {
        id
        name
        type
        frequency
        quantity
        planId
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_CUSTOMER_MEMBERSHIP_PLANS = gql`
  query fetchCustomerMembershipsPlans($input: FetchCustomerMembershipsPlanInput) {
    fetchCustomerMembershipsPlans(input: $input) {
      error
      status
      total
      contents {
        id
        customer {
          id
          firstName
          lastName
          email
          phone
        }
        planName
        planId
        organizationId
        status
        period
        createdAt
      }
    }
  }
`;

export const GET_CUSTOMER_MEMBERSHIP_PLAN_DETAIL = gql`
  query fetchCustomerMembershipsPlanDetail($input: FetchCustomerMembershipsPlanDetailInput) {
    fetchCustomerMembershipsPlanDetail(input: $input) {
      id
      customer {
        id
        firstName
        lastName
        phone
        email
      }
      plan {
        name
        prices {
          id
          period
          price
          planId
          firstPayment
          autoRenew
          billingTerm
          paymentFrequency
        }
        createdAt
        updatedAt
      }
      planId
      paymentMethodId
      organizationId
      status
      period
      createdAt
      customerBenefits {
        id
        name
        type
        frequency
        quantity
        usage
        updatedAt
      }
    }
  }
`;

export const GET_ORGANIZATION_ACCOUNT_INFORMATION = gql`
  query fetchOrganizationAccountInformation($input: FetchOrganizationAccountInformationInput) {
    fetchOrganizationAccountInformation(input: $input) {
      organizationAccountId
      cherryPublicKey
      applyFee
      id
    }
  }
`;

export const GET_MEMBERSHIPS_CUSTOMER_PAYMENT_METHOD = gql`
  query fetchMembershipsCustomerPaymentMethod($input: FetchMembershipsCustomerPaymentMethodInput) {
    fetchMembershipsCustomerPaymentMethod(input: $input) {
      id
      customerId
      status
      last4
      network
      type
      expireMonth
      expireYear
      createdAt
      updatedAt
    }
  }
`;

export const GET_MEMBERSHIPS_TOTAL_METRICS = gql`
  query getMembershipsTotalMetrics($input: ReportInput) {
    getMembershipsTotalMetrics(input: $input) {
      contents {
        planName
        currentSize
        contents {
          name
          data
        }
      }
    }
  }
`;

export const GET_MEMBERSHIPS_SUMMARY_METRICS = gql`
  query getMembershipsSummaryMetrics($input: ReportInput) {
    getMembershipsSummaryMetrics(input: $input) {
      activeMembers
      revenueMonthToDate
      revenueYearToDate
    }
  }
`;

export const GET_MEMBERSHIPS_REVENUE_GENERATED = gql`
  query getMembershipsRevenueGenerated($input: ReportInput) {
    getMembershipsRevenueGenerated(input: $input) {
      contents {
        planName
        contents {
          name
          data
        }
      }
    }
  }
`;

export const GET_WIDGET_SETTINGS = gql`
  query getWidgetSettings($input: WidgetSettingsInput) {
    getWidgetSettings(input: $input) {
      id
      organizationId
      color
      secondaryColor
      font
    }
  }
`;

export const GET_MEMBERSHIP_CUSTOMERS = gql`
  query fetchMembershipCustomers($input: FetchMembershipCustomersInput) {
    fetchMembershipCustomers(input: $input) {
      total
      contents {
        firstName
        lastName
        id
      }
    }
  }
`;
