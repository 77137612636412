import { gql } from '@apollo/client/core';

export const GET_LINK_TREE_LINKS = gql`
  query GetLinkTreeLinks($input: GetLinkTreeLinksRequest) {
    getLinkTreeLinks(input: $input) {
      id
      organizationId
      buttonColor
      backgroundColor
      profileImageUrl
      backgroundImageUrl
      backgroundType
      organizationLinks {
        id
        title
        link
      }
      success
    }
  }
`;

export const FETCH_ORGANIZATION_TRANSACTION_MINIMUM_AMOUNT = gql`
  query fetchOrganizationMinimumTransactionAmount($input: FetchOrganizationTransactionSettingRequest) {
    fetchOrganizationMinimumTransactionAmount(input: $input) {
      isUpdatable
      minimumPromoTransactions {
        isEnabled
        id
        amount
        term
      }
      updatableAt
    }
  }
`;

export const FETCH_ORGANIZATION_MINIMUM_APPROVAL_AMOUNT = gql`
  query fetchOrganizationMinimumApprovalAmount($input: FetchOrganizationTransactionSettingRequest) {
    fetchOrganizationMinimumApprovalAmount(input: $input) {
      amount
      amountCeiling
      id
      isUpdatable
      updatableAt
    }
  }
`;

export const FETCH_ORGANIZATION_PROMO_LIST = gql`
  query GetOrganizationPromos($input: FetchOrganizationTransactionSettingRequest) {
    getOrganizationPromos(input: $input) {
      promoOptions {
        promoTerm
        promoMdf
      }
    }
  }
`;

export const FETCH_ORGANIZATION_MDF_LIST = gql`
  query GetOrganizationMdf($input: FetchOrganizationTransactionSettingRequest) {
    getOrganizationMdf(input: $input) {
      productMdfList {
        mdf
        promoMdf
        promoTerm
        term
      }
    }
  }
`;

export const GET_APPLICATION_LINK_SENT_HISTORY = gql`
  query getOrganizationLinkSentHistory($input: OrganizationLinkHistoryInput) {
    getOrganizationLinkSentHistory(input: $input) {
      total
      contents {
        status
        sendAt
        borrowerId
        borrowerPhone
        borrowerEmail
        borrowerFirstName
        borrowerLastName
      }
    }
  }
`;

export const GET_APPLICATION_LINK_SENT_EMAIL_HISTORY = gql`
  query getOrganizationLinkEmailSentHistory($input: OrganizationLinkEmailHistoryInput) {
    getOrganizationLinkEmailSentHistory(input: $input) {
      total
      contents {
        status
        sendAt
        borrowerId
        borrowerPhone
        borrowerEmail
        borrowerFirstName
        borrowerLastName
      }
    }
  }
`;

export const FETCH_ORGANIZATION_GROUP_TREE = gql`
  query fetchOrganizationGroupTree($input: FetchOrganizationGroupTree) {
    fetchOrganizationGroupTree(input: $input) {
      id
      name
      slug
      type
      childs
    }
  }
`;

export const FETCH_ORGANIZATION_GROUP_USERS = gql`
  query fetchOrganizationGroupUsers($input: GroupSearchInput) {
    fetchOrganizationGroupUsers(input: $input) {
      total
      contents {
        id
        organizationGroupId
        userId
        createdAt
        userInfo {
          firstName
          lastName
          email
        }
        organizationGroupInfo {
          name
          id
        }
        role
      }
    }
  }
`;

export const FETCH_BANNER = gql`
  query fetchBanner {
    fetchBanner {
      showForPractice
      practiceText
    }
  }
`;
