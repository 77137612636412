import axios from 'axios';
import { APP_STATIC_URL } from 'config';
import { Loading, PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Container, Row } from 'lib/styles';
import React, { useEffect, useState } from 'react';
import { MarketingMaterials } from './views';

const OtherResources = () => {
  const { isSnackbarVisible } = useSnackbar();

  const [staticFile, setStaticFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchStaticFile = async () => {
      const result = await axios.get(APP_STATIC_URL);
      setStaticFile(result.data);
      setLoading(false);
    };

    fetchStaticFile();
  }, []);

  if (loading) {
    return <Loading fixed={true} size={40} />;
  }

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Marketing" />
      {staticFile?.MARKETING_ITEMS ? (
        <Row>
          <MarketingMaterials title="Order Marketing Supplies" marketingItems={staticFile.MARKETING_ITEMS} />
        </Row>
      ) : null}
    </Container>
  );
};

export default OtherResources;
