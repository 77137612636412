import { februaryDayControl } from './FebruaryDayControl';
import { lawfulAgeControl } from './LawfulAgeControl';

export const calculateMerchantFee = (amount, item) => {
  const merchantFee = item?.promoUsed ? item?.product?.promoMdf : item?.product?.mdf;
  return Math.round((merchantFee / 100) * amount * 1e2) / 1e2;
};

export const emailPattern = /^[a-zA-Z0-9&_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const websitePattern = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const phonePattern = /^\(?([2-9][0-8][0-9])\)? ?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;

export const ssnPattern = /^(?!(000|666|9))\d{3}\-(?!00)\d{2}\-(?!0000)\d{4}$/;
export const numericPattern = /^([0-9]|#|\_|\*|\-|\ )+$/;

export const ITINNumberRegex = /(9\d{2})([ \-]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ \-]?)(\d{4})/;

export const validateBirthDate = (value: string) => {
  if (!value || value.length !== 10 || (value.length === 10 && !februaryDayControl(value))) {
    return 'Please enter a valid date.';
  } else if (value.length === 10 && !lawfulAgeControl(value)) {
    return 'You must be at least 18 years old to apply.';
  } else {
    return !!value.trim();
  }
};

export const routingNumberPattern = /^\d{9}$/;
export const accountNumberPattern = /^\d{4,17}$/;
