import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkItem = styled(Link)`
  font-size: 14px;
  color: #0e202f;
  font-style: italic;
  text-decoration: underline;
  text-align: center;
  margin-top: 32px;
`;

export const CherryLink = styled.a`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
`;
