import dayjs from 'dayjs';
import React, { Suspense, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { Loading, Modal, PrimaryButton } from 'lib/components';
import { cherryDate } from 'lib/constants';

const DateTimePicker = React.lazy(() =>
  import('react-rainbow-components').then((module) => ({ default: module.DateTimePicker })),
);

interface SelectOption {
  value: string;
  label: string;
}

interface Date {
  from: string;
  to: string;
}

interface Props {
  options?: SelectOption[];
  onChange: (option: Date, type: string, label?: string) => void;
  onClick?: () => void;
  view?: string;
}

const defaultDropdownItems = [
  { value: 'today', label: 'Today' },
  { value: 'lastWeek', label: 'Last Week' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'lastYear', label: 'Last Year' },
  { value: 'thisWeek', label: 'This Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'thisYear', label: 'This Year' },
  { value: 'allTime', label: 'All Time' },
  { value: 'customRange', label: 'Custom Range' },
];

export const DatePickerDropdown = ({ onChange, options = defaultDropdownItems, onClick, view }: Props) => {
  const selectOptions = options;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('thisMonth');

  const [customStartDate, setCustomStartDate] = useState<any>(null);
  const [customEndDate, setCustomEndDate] = useState<any>(null);

  const onTimeSelected = (option: any) => {
    const now = dayjs();
    setSelectedOption(option.value);
    if (option.value === 'customRange') {
      setIsModalOpen(true);
    } else {
      let date: Date = {
        from: now.toISOString(),
        to: now.toISOString(),
      };
      switch (option.value) {
        case 'today':
          date = {
            from: dayjs(now).startOf('day').toISOString(),
            to: now.toISOString(),
          };
          break;
        case 'lastWeek':
          const weekStart = dayjs().startOf('week');
          date = {
            from: dayjs(weekStart).subtract(1, 'week').toISOString(),
            to: weekStart.toISOString(),
          };
          break;
        case 'lastMonth':
          const monthStart = dayjs().startOf('month');
          date = {
            from: dayjs(monthStart).subtract(1, 'month').toISOString(),
            to: monthStart.toISOString(),
          };
          break;
        case 'lastYear':
          const yearStart = dayjs().startOf('year');
          date = {
            from: dayjs(yearStart).subtract(1, 'year').toISOString(),
            to: yearStart.toISOString(),
          };
          break;
        case 'thisWeek':
          date = {
            from: dayjs().startOf('week').toISOString(),
            to: now.toISOString(),
          };
          break;
        case 'thisMonth':
          date = {
            from: dayjs().startOf('month').toISOString(),
            to: now.toISOString(),
          };
          break;
        case 'thisYear':
          date = {
            from: dayjs().startOf('year').toISOString(),
            to: now.toISOString(),
          };
          break;
        case 'allTime':
          date = {
            from: dayjs(cherryDate).toISOString(),
            to: dayjs().toISOString(),
          };
          break;
        default:
          break;
      }
      onChange(date, option.value, option.label);
    }
  };

  const closeModal = () => {
    setCustomEndDate(null);
    setCustomStartDate(null);
    setIsModalOpen(false);
  };

  const applyCustomRange = () => {
    const date = {
      from: dayjs(customStartDate).toISOString(),
      to: dayjs(customEndDate).toISOString(),
    };

    const label = `${dayjs(customStartDate).format('MM-DD-YYYY HH:MM A')} to ${dayjs(customEndDate).format(
      'MM-DD-YYYY HH:MM A',
    )}`;
    onChange(date, 'customRange', label);
    setIsModalOpen(false);
  };

  if (selectedOption === 'customRange' && customStartDate && customEndDate) {
    selectOptions[selectOptions.length - 1].label = `Custom: ${dayjs(customStartDate).format(
      'MM-DD-YYYY HH:MM A',
    )} to ${dayjs(customEndDate).format('MM-DD-YYYY HH:MM A')}`;
  }

  const onStartDateChange = (value) => setCustomStartDate(value);

  const onEndDateChange = (value) => setCustomEndDate(value);

  const defaultValue = selectOptions.filter((item) => item.value === selectedOption);

  return (
    <Container onClick={onClick}>
      <CustomSelect defaultValue={defaultValue[0]} options={selectOptions} onChange={onTimeSelected} />
      <Modal open={isModalOpen} closeOnDocumentClick={false} title="Custom Date Range">
        <PickerWrapper>
          <PickerItem>
            <PickerTitle>Start Date / Time:</PickerTitle>
            <Suspense fallback={<Loading fixed={true} size={40} />}>
              <DateTimePicker
                maxDate={new Date()}
                value={customStartDate}
                onChange={onStartDateChange}
                formatStyle="large"
                locale={'en-US'}
              />
            </Suspense>
          </PickerItem>
          <PickerItem>
            <PickerTitle>End Date / Time:</PickerTitle>
            <Suspense fallback={<Loading fixed={true} size={40} />}>
              <DateTimePicker
                maxDate={new Date()}
                value={customEndDate}
                onChange={onEndDateChange}
                formatStyle="large"
                locale={'en-US'}
              />
            </Suspense>
          </PickerItem>
        </PickerWrapper>
        <ActionsWrapper>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <ApplyButton disabled={!customStartDate || !customEndDate} onClick={applyCustomRange}>
            Okay
          </ApplyButton>
        </ActionsWrapper>
      </Modal>
    </Container>
  );
};

const CustomSelect = styled(Select)`
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  z-index: 2;

  div {
    border: none;

    svg {
      color: ${(props) => props.theme.main.green};
    }
  }
`;

const Container = styled.div`
  width: 100%;
`;

const PickerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PickerItem = styled.div`
  margin-bottom: 16px;
  width: 75%;
`;

const PickerTitle = styled.p`
  color: ${(props) => props.theme.main.textColor};
  margin: 0 0 5px 0;
`;

const ActionsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 48px;
  align-items: center;
  justify-content: flex-end;
`;

const ApplyButton = styled(PrimaryButton)`
  width: 120px;
`;

const CancelButton = styled(PrimaryButton)`
  background-color: #c4c4c4;
  width: 120px;
  margin-right: 10px;
`;
