import { Radio } from '@frontend/cherry-library';
import { CustomRadioGroup } from 'pages/desktop/CheckoutWithPatient/RecurringPaymentTypeSelect/styles';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RecurringPaymentType } from '../SetupPayment/types';
import { RecurringPaymentTypeSelectProps } from './types';

const RecurringPaymentTypeSelect = ({
  selectedValue,
  onPaymentSelection,
  isDownPaymentMethodDisabled,
}: RecurringPaymentTypeSelectProps) => {
  const { control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      recurringPaymentType: selectedValue,
    },
  });

  const handleChange = (e) => {
    const value = e?.target?.value || '';
    onPaymentSelection(value);
  };

  useEffect(() => {
    setValue('recurringPaymentType', selectedValue);
  }, [selectedValue, setValue]);

  return (
    <Controller
      name="recurringPaymentType"
      control={control}
      rules={{ required: true }}
      // tslint:disable-next-line: jsx-no-lambda
      render={({ field: { name, value } }) => (
        <CustomRadioGroup name={name} value={value}>
          <Radio
            value={RecurringPaymentType.DownPaymentMethod}
            checked={value === RecurringPaymentType.DownPaymentMethod}
            label="Use the same method as above"
            disabled={isDownPaymentMethodDisabled}
            onChange={handleChange}
          />
          <Radio
            value={RecurringPaymentType.Other}
            checked={value === RecurringPaymentType.Other}
            label="Use a different method"
            onChange={handleChange}
          />
        </CustomRadioGroup>
      )}
    />
  );
};

export default RecurringPaymentTypeSelect;
