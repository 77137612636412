import { Button as CherryButton } from '@frontend/cherry-library';
import { DashCard } from 'lib/components';
import { AlertDialog } from 'lib/components/Dialogue';
import StorageService from 'lib/services/Storage';
import { Row } from 'lib/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Walkthrough } from './index';

export const ResetOnboarding = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleAgreeClicked = () => {
    setOpen(!open);
    StorageService.setOnboardingStatus(false);
    navigate('/dashboard');
  };

  const handleDisagreeClick = () => setOpen(!open);
  const handleDialogOpen = () => setOpen(!open);

  return (
    <DashCard>
      <AlertDialog
        open={open}
        agreeClicked={handleAgreeClicked}
        onDisagreeClicked={handleDisagreeClick}
        children={<Walkthrough />}
        agreeText={'Launch'}
        declineText={'Cancel'}
      />
      <Row style={{ fontSize: '14px' }}>
        Re-watch the guided tutorial shown on your first login. This highlights and explains features in your partner
        portal.
      </Row>
      <ButtonContainer>
        <CherryButton variant="primary" onClick={handleDialogOpen}>
          Start Walkthrough
        </CherryButton>
      </ButtonContainer>
    </DashCard>
  );
};

const ButtonContainer = styled.div`
  margin-top: 24px;
  max-width: 190px;
`;
