import React from 'react';
import { Icons } from './constants';
import { Container, ContentContainer, PreferredContainer, StyledIcon, Subtitle, Title } from './styles';
import { IconName, PaymentMethodCardProps } from './types';

const PaymentMethodCard = ({
  paymentMethod,
  selectedPaymentMethod,
  selectPaymentMethod,
  isPreferred = false,
}: PaymentMethodCardProps) => {
  const { id, type, achAccount, storedCard } = paymentMethod || {};

  const cardNumber =
    type === 'CARD' ? storedCard?.last4 : achAccount?.accountNumber?.substring(achAccount?.accountNumber?.length - 4);

  const cardTitle = type === 'CARD' ? `${storedCard?.network} - ${storedCard?.type}` : `${achAccount?.bankName}`;

  const IconNames = () => {
    const isActive = selectedPaymentMethod?.id === id;
    const findIcon: IconName | undefined = Icons?.find((ic) => ic?.type === type);

    if (isActive) {
      return `active_${findIcon?.name}`;
    } else {
      return findIcon?.name || '';
    }
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  return (
    <Container
      data-testid="payment-method-card"
      onClick={selectPaymentMethodHandler}
      isActive={Number(selectedPaymentMethod?.id) === Number(id)}
    >
      <StyledIcon src={IconNames()} width={20} height={20} />
      <ContentContainer>
        <Title isActive={Number(selectedPaymentMethod?.id) === Number(id)}>{cardTitle}</Title>
        <Subtitle isActive={Number(selectedPaymentMethod?.id) === Number(id)}>************ {cardNumber}</Subtitle>
      </ContentContainer>
      {isPreferred && <PreferredContainer>PREFERRED</PreferredContainer>}
    </Container>
  );
};

export default PaymentMethodCard;
