import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  RefundHistory,
  SlideModal,
  Title,
  TransactionExpandableInfo,
} from 'lib/components/mobile';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useTransactionDetail } from 'lib/services';

const MobileTransactionDetail = () => {
  const navigate = useNavigate();
  const permission = usePermission();

  const { transactionInProcess } = useStore();
  const {
    onDownloadTransactionClick,
    slideModalVariables,
    onRefundClick,
    closeModal,
    detailVariables,
  } = useTransactionDetail({
    transactionInProcess,
  });

  const onBackClick = () => {
    navigate('/transactions');
  };

  return (
    <Container pageTitle="Transaction Details">
      <DetailContainer>
        <Title>{detailVariables.title}</Title>
        <TransactionDescription>{detailVariables.description}</TransactionDescription>

        <TransactionExpandableInfo
          transactionInProcess={transactionInProcess}
          isDownloadBtnVisible={true}
          expanded={true}
          onDownloadButtonClick={onDownloadTransactionClick}
        />
        <FooterContainer>
          <ButtonContainer>
            {detailVariables.firstButton && permission(FeatureNames.ISSUE_REFUND, ScreenNames.TRANSACTIONS) && (
              <Button secondary={true} onClick={detailVariables?.firstButtonClick || onRefundClick}>
                {detailVariables.firstButton}
              </Button>
            )}
            <Button secondary={true} onClick={onBackClick}>
              Back
            </Button>
          </ButtonContainer>
        </FooterContainer>
        {transactionInProcess?.refunds?.length > 0 && (
          <RefundHistory refunds={transactionInProcess?.refunds} funds={transactionInProcess?.fund} />
        )}
        <SlideModal {...slideModalVariables} show={slideModalVariables.visibility} hideModal={closeModal} />
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionDetail;

const TransactionDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;
