import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  AmountField,
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  Subtitle,
  TextField,
  Title,
} from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { useApplicationCreate } from 'lib/services/useApplicationCreate';
import { Merchant } from 'lib/types';

const MobileApplicationUpdate = () => {
  const { applicationInProcess, locations } = useStore();
  const {
    merchants,
    onMerchantSelect,
    processEditApplication,
    mobileLoanUpdateSuccess,
    onAmountChange,
    loading,
    isAnythingChanged,
  } = useApplicationCreate({ selectedModalItem: applicationInProcess });
  const navigate = useNavigate();
  const { isSnackbarVisible } = useSnackbar();

  const [defaultValue, setDefaultValue] = useState('');
  const [inputLoading, setInputLoading] = useState(true);

  const goBack = () => navigate('/dashboard');
  const navigateToCancel = () => navigate('/application/cancel');

  useEffect(() => {
    findPreferedLocation();
  }, []);

  const findPreferedLocation = () => {
    const merchantId = applicationInProcess?.activeLoan?.merchantId || applicationInProcess?.merchantId;
    const findLocation = locations?.find((opt) => opt?.id === merchantId);
    if (findLocation) {
      const option = { target: { value: findLocation.id } };
      onMerchantSelect(option);
      setDefaultValue(findLocation.id);
    } else {
      const option = { target: { value: merchants?.[0].id } };
      onMerchantSelect(option);
      setDefaultValue(locations?.[0]?.id);
    }
    setInputLoading(false);
  };

  return (
    <Container pageTitle="Application Details" backUrl="/dashboard">
      {mobileLoanUpdateSuccess ? (
        <>
          <ContentContainer isSnackbarVisible={isSnackbarVisible}>
            <Title m={'0 0 8px 0'}>Plan Updated</Title>
            <Subtitle m={'0 0 8px 0'}>
              {applicationInProcess?.firstName} has received an updated link on their phone. You’ll get notified once
              they’ve chosen a plan.
            </Subtitle>
          </ContentContainer>
          <ButtonContainer>
            <Button onClick={goBack}>Okay</Button>
          </ButtonContainer>
        </>
      ) : (
        <>
          <ContentContainer isSnackbarVisible={isSnackbarVisible}>
            <Title m={'0 0 8px 0'}>Edit Payment Plan</Title>
            <Subtitle m={'0 0 16px 0'}>
              Update the approval amount for {applicationInProcess?.firstName}. They’ll receive a new link on their
              phone.
            </Subtitle>
            <AmountField
              placeholder="Purchase Amount (including tax)"
              onAccept={onAmountChange}
              max={applicationInProcess.balanceAvailable}
              value={applicationInProcess?.amount.toFixed(2)}
            />
            {!inputLoading ? (
              <TextField
                style={{ textAlign: 'left' }}
                id="filled-basic"
                variant="filled"
                data-testid="industry"
                select={true}
                onChange={onMerchantSelect}
                label="Checkout Location"
                defaultValue={defaultValue}
              >
                {merchants?.map((option: Merchant, index: number) => (
                  <MenuItem
                    data-testid="industryItem"
                    key={index}
                    value={option?.id}
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </ContentContainer>
          <ButtonContainer>
            <Button
              onClick={processEditApplication}
              disabled={!applicationInProcess?.amount.toFixed(2) || loading || !isAnythingChanged}
              loading={loading}
            >
              Update and Send
            </Button>
            <Button secondary={true} onClick={goBack}>
              Cancel
            </Button>
            <CancelLink onClick={navigateToCancel} data-testid="mobile-cancel-checkout-button">
              Cancel this plan instead
            </CancelLink>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default MobileApplicationUpdate;

const CancelLink = styled.div`
  color: ${(props) => props.theme.main.red};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 40px;
  text-decoration: underline;
`;
