import dayjs, { Dayjs } from 'dayjs';
import { Appointment, GroupedAppointments } from 'lib/types';
import { clone } from 'lib/utils';
import { formatDateWithType } from './DateUtil';

export const groupedByCategory = (
  dateVariable: string,
  arrayOfObjects: Appointment[],
  loadMore: boolean,
  groupedAppointments: GroupedAppointments[],
  start?: string,
  end?: string,
) => {
  const times = arrayOfObjects.reduce((acc, obj) => {
    const dateKey = formatDateWithType(obj[dateVariable], 'dddd MMMM D, YYYY');
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(obj);
    return acc;
  }, {});
  const allTimes = start === '' ? {} : getAllDates(dayjs(start), dayjs(end));
  const mergedObject = Object.assign({}, allTimes, times);
  const groupArrays = Object.keys(mergedObject).map((date) => {
    return {
      date,
      appointments: times[date] || [],
      isExpanded: true,
    };
  });
  const cloneGrouppedList = clone(groupArrays);

  if (loadMore) {
    const consecutiveCount = getConsecutiveCount(groupArrays);

    const newList = cloneGrouppedList.slice(0, cloneGrouppedList.length - consecutiveCount);

    return mapExpandedArray(newList, groupedAppointments);
  } else {
    return mapExpandedArray(cloneGrouppedList, groupedAppointments);
  }
};

export const getAllDates = (fromDate: Dayjs, endDate: Dayjs) => {
  const datesList = {};
  let currentDate = fromDate;
  while (!dayjs(currentDate).isSame(endDate.add(1, 'day'), 'day')) {
    const date = formatDateWithType(currentDate.toISOString(), 'dddd MMMM D, YYYY');
    datesList[date] = [];
    currentDate = dayjs(currentDate).add(1, 'day');
  }
  return datesList;
};

export const getConsecutiveCount = (arr) => {
  let count = 0;
  const cloneArray = clone(arr);

  const reversedArr = cloneArray.reverse();
  reversedArr.find((app) => {
    if (app.appointments.length === 0) {
      count += 1;
    } else {
      return true;
    }
  });
  return count;
};

export const mapExpandedArray = (datesArray, groupedAppointments) => {
  const updatedList = datesArray.map((datedAppointment) => {
    const matchedDate = groupedAppointments?.find((appointment) => appointment.date === datedAppointment.date);
    if (matchedDate) {
      return { ...datedAppointment, isExpanded: matchedDate.isExpanded };
    }
    return datedAppointment;
  });
  return updatedList;
};
