import React from 'react';

import { DropDown } from 'lib/components';
import { DropdownContainer, Gap } from './components';
import { getCheckoutFinishedItems } from './utils';

import Popover from './components/Popover';

export const TransactionComplete = ({ row, expandableOpened, selectedItem }) => {
  const NewDropDown = DropDown;

  return (
    <>
      <button data-tag="allowRowEvents" style={{ display: 'none' }} onClick={row.openProcessContractModal}>
        .
      </button>

      <Popover
        row={row}
        type="approved"
        secondary={true}
        backgroundColor="#E6F9F2"
        label="Transaction Complete"
        iconName="info_circle_outline"
        hoverText="Patient has completed their purchase and successfully signed up for a payment plan. You can issue another payment plan if they have a remaining balance."
      />

      {!(expandableOpened && selectedItem?.id === row.applicationId) ? (
        <DropdownContainer>
          <NewDropDown
            id={`drop-${row.applicationId}`}
            options={getCheckoutFinishedItems(row)}
            textColor={'#0E202F'}
            onChange={row.onDenialStatusChange}
            defaultValue={row.scheduled}
            showAllItem={true}
            hoveredMode={false}
            customSelector={true}
          />
        </DropdownContainer>
      ) : (
        <Gap />
      )}
      <div />
    </>
  );
};
