import { theme } from '@frontend/cherry-library';
import { DownCircle, Popover, PopoverContent, PopoverText, PopoverWrapper } from 'pages/desktop/Dashboard/views';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  .payment-estimator-header {
    margin-bottom: 24px !important;
  }

  .MuiFilledInput-root {
    ::before {
      border-bottom: 0 !important;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  button {
    width: fit-content;
    margin-bottom: 16px;
  }
`;

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 24px;

  padding: 24px;
  border-radius: 4px;
  background: ${theme.main.white};
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-width: 480px;
  max-width: 644px;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const PaymentOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TableContainer = styled.div<{ isAvailable: boolean; hasError: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;

  border-radius: 8px;
  border: 2px solid ${(props) => (props.hasError ? theme.main.cherryRed : theme.main.kellyGreen)};
  background: ${(props) => (props.hasError ? theme.main.cherryRed5 : theme.main.kellyGreen5)};
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LowestOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const TableFooter = styled.span`
  padding-top: 10px;
  border-top: 1px solid ${theme.main.midnightBlue20};
  font-size: 10px;
`;

export const TableErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: ${theme.main.cherryRed};
`;

export const PlanTableContainer = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 12px;
  margin-bottom: -4px;

  tr {
    height: 16px;
  }
  th {
    border-bottom: 1px solid ${theme.main.midnightBlue20};
    padding-right: 24px;
    padding-bottom: 5px;
    text-align: left;
  }
  td {
    text-align: left;
    padding-right: 24px;
    padding-bottom: 8px;
  }
`;

export const ShareEstimateContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 8px;
  background: ${theme.main.midnightBlue5};
`;

export const PatientInfoForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const SplitRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 1420px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: row;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StyledPopover = styled(Popover)`
  top: -105px;
  right: -24px;
  left: unset;
  bottom: auto;
  width: 264px;
  min-height: 89px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  width: 100%;
`;

export const StyledDownCircle = styled(DownCircle)`
  top: unset;
  right: 12px;
  bottom: -12px;
  transform: rotate(180deg);
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid ${(props) => props?.theme?.main?.green};
`;

export const StyledPopoverText = styled(PopoverText)`
  font-size: 14px;
`;

export const StyledPopoverContent = styled(PopoverContent)`
  padding: 16px;
`;

export const StyledPreApprovalPopoverContent = styled(PopoverContent)`
  padding: 16px;
  display: flex;
  flex-direction: row;
`;

export const TableSkeleton = styled.div<{ isPdf?: boolean; isLowestOption?: boolean }>`
  margin-top: ${(props) => (props.isLowestOption ? '5px' : '10px')};
  margin-bottom: ${(props) => (props.isLowestOption ? '5px' : '0px')};

  border-radius: 4px;
  width: ${(props) => (props.isLowestOption ? '30%' : '100%')};
  height: ${(props) => (props.isLowestOption ? '1em' : props.isPdf ? '1.6em' : '16px')};
  background: ${(props) =>
    props.isPdf
      ? 'linear-gradient(to right, #e7e9ea, #f2f4f5, #e7e9ea)'
      : 'linear-gradient(to right, #b3edd8, #e5f9f2, #b3edd8)'};

  background-size: 200%;
  background-position: left;
  animation: load 2s ease-out 0s infinite normal;

  @keyframes load {
    0%,
    100% {
      background-position: right;
    }
    50% {
      background-position: left;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Pdf = styled.div<{ isModal: boolean }>`
  width: 100%;
  cursor: ${(props) => (props.isModal ? 'default' : 'zoom-in')};
`;

export const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 80px;
  flex-basis: 400px;
  flex-grow: 0;
  gap: 24px;
  align-items: center;
  min-width: 280px;
  max-width: 400px;

  height: 100%;
  position: sticky;
  top: 100px;

  @media screen and (max-width: 1800px) {
    padding: 16px 40px;
  }
  @media screen and (max-width: 1500px) {
    padding: 16px 24px;
  }
  @media screen and (max-width: 1350px) {
    padding: 16px 8px;
  }
  @media screen and (max-width: 1200px) {
    padding: 16px 0px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const StickyPdf = styled.div`
  position: sticky;
  top: 100px;
`;

export const PdfPageWrapper = styled.div`
  aspect-ratio: 4/5;
  height: 100%;
  width: 100%;
  background-color: ${theme.main.white};
  box-shadow: 0px 0px 71.024px 0px rgba(0, 0, 0, 0.08), 0px 30.596px 40.638px 0px rgba(0, 0, 0, 0.07),
    0px 18.571px 24.667px 0px rgba(0, 0, 0, 0.06), 0px 11.19px 14.863px 0px rgba(0, 0, 0, 0.05),
    0px 6.231px 8.277px 0px rgba(0, 0, 0, 0.04), 0px 2.68px 3.56px 0px rgba(0, 0, 0, 0.03);
`;

export const PdfInnerWrapper = styled.div`
  padding: 1.8em;
  font-family: 'Open Sans';
  color: ${theme.main.midnightBlue};
  height: calc(100% - 4.4em);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pdf-zero-apr-icon {
    width: 1.2em;
    height: 1.2em;
  }

  @media print {
    margin: -24px;
  }
`;

export const PdfTitle = styled.div`
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.8em;
`;

export const PdfHeader = styled.div`
  font-size: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PdfDivider = styled.div`
  width: 100%;
  height: 0.8em;
  border-bottom: 1px solid ${theme.main.midnightBlue20};
  margin-bottom: 0.8em;
`;

export const PdfSubtitle = styled.div`
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 0.6em;
  text-transform: uppercase;
`;

export const PdfSummary = styled.div<{ isConsultation?: boolean }>`
  font-size: 0.7em;
  word-wrap: break-word;
  margin: ${(props) => props.isConsultation && '1.75em 0'};
`;

export const PdfTableWrapper = styled.div`
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 0.6em;
`;

export const PdfText = styled.div`
  font-size: 0.6em;
`;

export const PdfMinPaymentTitle = styled.div`
  font-size: 1.3em;
  font-weight: 700;
`;

export const PdfPlanTableContainer = styled.table`
  font-size: 0.7em;
  width: 100%;
  border-spacing: 0px;
  margin-top: 1.2em;
  margin-bottom: -6px;

  tr {
    height: 1.6em;
  }
  th {
    border-bottom: 1px solid ${theme.main.midnightBlue20};
    padding-right: 2.4;
    padding-bottom: 5px;
    text-align: left;
  }
  td {
    text-align: left;
    padding-right: 12px;
    padding-bottom: 3x;
    padding-top: 3px;
  }
`;

export const PdfTableAndQrWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2.4em;
  margin-bottom: 1.8em;
`;

export const PdfTableDivider = styled.div`
  width: 100%;
  height: 1em;
  border-bottom: 1px solid ${theme.main.midnightBlue20};
  margin-bottom: 0.5em;
`;

export const PdfDisclaimer = styled.div`
  font-weight: 700;
  font-size: 0.7em;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 2px;

  @media print {
    font-size: 11px;
    .pdf-zero-apr-icon {
      width: 14px;
      height: 14px;
    }
  }
`;

export const PdfFooter = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 2.4em;
  align-items: center;

  @media print {
    position: absolute;
    bottom: 0;
    gap: 10px;

    .pdf-cherry-logo {
      width: 160px;
      height: 40px;
    }
  }
`;

export const ZeroAprCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

export const PdfFooterLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const QRWrapper = styled.div`
  border-radius: 6px;
  border: 1.5px solid ${theme.main.kellyGreen};
  background: ${theme.main.kellyGreen5};
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
  justify-content: center;
  padding: 1.2em;
  max-width: 35em;

  @media print {
    box-shadow: unset;
  }
`;

export const QRText = styled.div`
  font-size: 0.7em;
  text-align: center;

  b {
    word-break: break-all;
  }
`;

export const QRTitle = styled.div`
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
`;

export const QRBox = styled.div`
  height: 8em;
  width: 8em;
  background-color: white;

  canvas {
    height: 100%;
    width: 100%;
  }
`;

export const ExpandPdfModal = styled.div`
  margin-bottom: 24px;
  padding: 0 32px;
`;

export const PdfModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -20px;
`;
