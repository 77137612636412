import { useApolloClient } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { DropDown } from 'lib/components';
import { Loading } from 'lib/components';
import { GET_MERCHANT_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { LocationManagementStepEnums } from 'lib/types';
import { maskAccountNumber } from 'lib/utils';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContentInherit,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import { CreateMerchant, MerchantDetail } from './type';

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onChangeBankingInfo: (key, value) => void;
  merchant: CreateMerchant;
}

export const SelectBank = ({ handleClose, onStepChange, onChangeBankingInfo, merchant }: Props) => {
  const alert = useAlert();
  const client = useApolloClient();

  const { merchants: storedMerchants = [] } = useStore();

  const [merchantDetailList, setMerchantDetailList] = useState<MerchantDetail[]>([]);
  const [options, setOptions] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMerchantDetail();
  }, []);

  const DropdownTextComponent = (title: string, subtitle: string) => {
    return `<span style="font-size: 14px; color: #0E202F;">${title}</span> <br/>  <span style="font-size: 12px;">${subtitle} </span>`;
  };

  const fetchMerchantDetail = async () => {
    try {
      setLoading(true);
      const ids = storedMerchants?.map((merch) => merch.id);
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds: ids,
          },
        },
      });

      setLoading(false);

      if (!!getMerchantDetail?.data?.length) {
        const successBankData = getMerchantDetail?.data?.filter(
          (merchantData) => merchantData?.bankingInfo?.accountStatus === 'SUCCESS',
        );
        setMerchantDetailList(successBankData);
        if (merchant?.bankingInfo?.accountName) {
          const findSelectedBank = successBankData?.find(
            (bank) => bank?.bankingInfo?.accountName === merchant?.bankingInfo?.accountName,
          );
          setSelectedBank(findSelectedBank?.bankingInfo?.accountId);
        }
        const _options = successBankData?.map((merchantObj) => {
          const title = merchantObj?.bankingInfo?.accountNickname || merchantObj?.name;
          const subTitle = `${merchantObj?.bankingInfo?.accountName} ${maskAccountNumber(
            merchantObj?.bankingInfo?.accountNumber!,
            '*',
          )}`;
          return {
            value: merchantObj?.bankingInfo?.accountId,
            label: DropdownTextComponent(title, subTitle),
            className: 'bank-item',
          };
        });
        setOptions(_options);
      } else {
        setMerchantDetailList([]);
      }
    } catch (error) {
      setLoading(false);
      alert.error('Merchant detail fetch error');
    }
  };

  const onContinueHandler = () => {
    onStepChange(LocationManagementStepEnums.REVIEW);
  };

  const onAddUserHandler = () => {
    onStepChange(LocationManagementStepEnums.ADD_BANK);
  };

  const onBackHandler = () => {
    onStepChange(LocationManagementStepEnums.LOCATION_ADDRESS);
  };

  const bankChangeHandler = (e) => {
    const merchantInfo = merchantDetailList.find(
      (merchantDetail) => merchantDetail?.bankingInfo?.accountId === e.value,
    );
    if (merchantInfo) {
      const { accountClassification, accountName, accountNickname, accountNumber, accountType, routingNumber } =
        merchantInfo?.bankingInfo || {};
      onChangeBankingInfo('accountClassification', accountClassification);
      onChangeBankingInfo('accountName', accountName);
      onChangeBankingInfo('accountNickname', accountNickname);
      onChangeBankingInfo('accountNumber', accountNumber);
      onChangeBankingInfo('accountType', accountType);
      onChangeBankingInfo('routingNumber', routingNumber);
    }
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Select Bank
      </CherryDialogTitle>
      <SubTitle>Add banking information for this location.</SubTitle>
      <DialogContentInherit>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            {!loading ? (
              <DropDownBorder style={{ width: '100%', height: '50px', padding: '3px' }}>
                <DropDown
                  maxHeight={'265'}
                  ignoreInitialFirstItem={true}
                  placeholder="Select a Bank"
                  defaultValue={selectedBank}
                  options={options}
                  onChange={bankChangeHandler}
                  hoveredMode={false}
                />
              </DropDownBorder>
            ) : (
              <LoadingContainer>
                <Loading size={20} />
              </LoadingContainer>
            )}
          </Grid>
          <Grid item={true} xs={12}>
            <SubTitle>Don't see your bank in the dropdown?</SubTitle>
            <LinkButton onClick={onAddUserHandler} data-testid="connect-new-bank">
              Connect New Bank
            </LinkButton>
          </Grid>
        </Grid>
      </DialogContentInherit>

      <ButtonContainer>
        <BackButton onClick={onBackHandler}>Back</BackButton>
        <ButtonGap />
        <ContinueButton onClick={onContinueHandler} disabled={!merchant?.bankingInfo?.accountName} data-testid="submit">
          Continue
        </ContinueButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const LinkButton = styled.span`
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #00c37d;
  justify-content: center;
  display: flex;
  margin-top: -16px;
`;
