import dayjs from 'dayjs';
import React from 'react';

import { Button, ExpandableInfoText, ExpandableWithTitle, Icon } from 'lib/components/mobile';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { formatPhoneNumber } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';

interface TransactionExpandableInfoProps {
  transactionInProcess: any;
  isDownloadBtnVisible?: boolean;
  onDownloadButtonClick?: () => void;
  expanded?: boolean;
}

export const TransactionExpandableInfo = ({
  transactionInProcess,
  isDownloadBtnVisible = false,
  expanded,
  onDownloadButtonClick,
}: TransactionExpandableInfoProps) => {
  const permission = usePermission();
  const { locations, merchants } = useStore();

  const findPreferedLocation = () => {
    const findLocation = locations?.find((opt) => Number(opt.id) === transactionInProcess?.merchantId);
    if (findLocation) {
      return { id: findLocation.id, name: findLocation.name };
    }
    return { id: merchants?.[0]?.id, name: merchants?.[0]?.name };
  };

  return (
    <ExpandableWithTitle title={'Transaction Details'} expanded={expanded}>
      <>
        <ExpandableInfoText name={'Name'} value={transactionInProcess?.borrowerName} />
        <ExpandableInfoText name={'Phone'} value={formatPhoneNumber(transactionInProcess?.phone)} />
        <ExpandableInfoText name={'Location'} value={findPreferedLocation()?.name} />
        <ExpandableInfoText
          name={'Contract ID'}
          value={transactionInProcess?.displayId}
          testId="mobile-transaction-displayId"
        />
        <ExpandableInfoText
          name={'Date'}
          value={dayjs(transactionInProcess?.fundedAt).format('MMM DD, YYYY hh:MM A')}
        />
        <ExpandableInfoText
          name={'Gross Sales'}
          value={CurrencyUtil.toCurrencyString(transactionInProcess?.purchaseAmount, true)}
        />
        <ExpandableInfoText
          name={'Merchant Fee'}
          value={CurrencyUtil.toCurrencyString(transactionInProcess?.merchantRevenue, true)}
        />
        <ExpandableInfoText
          name={'Net Sales'}
          value={CurrencyUtil.toCurrencyString(transactionInProcess?.merchantFund, true)}
        />
        <ExpandableInfoText name={'Disbursement'} value={transactionInProcess?.disbursement} />
        {isDownloadBtnVisible && permission(FeatureNames.DOWNLOAD_CONTRACT, ScreenNames.TRANSACTIONS) && (
          <Button
            secondary={true}
            alignItems={'center'}
            justifyContent={'space-between'}
            onClick={onDownloadButtonClick}
          >
            Download Transaction
            <Icon src={'download'} width={10} height={15} />
          </Button>
        )}
      </>
    </ExpandableWithTitle>
  );
};
