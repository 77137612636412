import { gql } from '@apollo/client/core';

export const GET_APPLICATION_FLOW = gql`
  query getApplicationFlow($input: ApplicationInput) {
    getApplicationFlow(input: $input) {
      id
      borrowerId
      applicationId
      status
      missingInfos
      createdAt
      updatedAt
      hasTreatmentQuestions
    }
  }
`;

export const GET_APPLICATION = gql`
  query getApplication($input: ApplicationInput) {
    getApplication(input: $input) {
      activeLoanId
      balanceAvailable
      borrowerId
      channel
      createdAt
      demo
      displayId
      expireAt
      id
      merchantId
      ipAddress
      isValidForLoan
      ivAllow {
        paystub
      }
      maxEligible
      organizationId
      partner
      purchaseAmount
      reason
      mickeyMouse
      riskScore
      selfCheckout
      status
      validity
      creditLineIncrease
      flowType
      menu {
        maxPurchase
      }
    }
  }
`;

export const GET_USER_AUTH_INFO = gql`
  query getUserAuthInfo {
    getUserAuthInfo {
      code
      features
      merchant {
        id
        name
      }
      message
      organization {
        id
        name
        slug
        isDemo
        website
        phone
      }
      success
      tokenType
      user {
        id
        email
        firstName
        lastName
        roles
        prequal
      }
      locations {
        id
        name
        address {
          zip
          address1
          city {
            name
            state {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_BORROWER_IDENTITY = gql`
  query getBorrowerIdentity($input: GetBorrowerIdentityInput) {
    getBorrowerIdentity(input: $input) {
      borrower {
        phone
        firstName
        lastName
        dob
        address {
          street
          city
          stateCode
          stateName
          zip
        }
        email
        last4
      }
      source
      proveLogId
    }
  }
`;

export const GET_BORROWER_DETAIL = gql`
  query getBorrowerDetail($input: GetBorrowerDetailInput) {
    getBorrowerDetail(input: $input) {
      success
      code
      message
      data {
        email
        dob
        createdAt
        address {
          city
          id
          createdAt
          updatedAt
          status
          street
          zip
          state {
            code
            name
          }
        }
        phone
        status
        firstName
        lastName
        id
      }
    }
  }
`;

export const GET_LOAN = gql`
  query getLoan($input: LoanInput) {
    getLoan(input: $input) {
      amount
      applicationId
      autoPay
      borrowerId
      channel
      createdAt
      createdBy
      demo
      displayId
      downPaymentAmount
      downPaymentPaymentMethodId
      financeCharge
      fundedAt
      grossAmount
      id
      installmentAmount
      installmentPaymentMethodId
      merchantFund
      merchantId
      merchantRevenue
      partner
      productId
      purchaseAmount
      selfCheckout
      status
      subStatus
      totalAmount
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods($input: GetPaymentMethodsInput) {
    getPaymentMethods(input: $input) {
      contents {
        firstName
        lastName
        zip
        type
        achAccount {
          accountNumber
          accountType
          bankName
          createdAt
          id
        }
        id
        createdAt
        storedCard {
          borrowerId
          expireMonth
          expireYear
          last4
          network
          type
          status
          id
          createdAt
        }
        rccAccount {
          accountNumber
          routingNumber
          bankName
          createdAt
          id
        }
      }
      total
    }
  }
`;

export const GET_PAYMENT_METHOD_FEES = gql`
  query getPaymentFees {
    getPaymentFees {
      DEBIT {
        fixed
        percent
      }
      CREDIT {
        fixed
        percent
      }
    }
  }
`;
