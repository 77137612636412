import { ApolloLink, Observable } from '@apollo/client';

import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';

const connections: { [key: string]: any } = {};

export const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // Set x-CSRF token (not related to abort use case)
      const context = operation.getContext();
      /** Final touch to cleanup */

      const { token } = StorageService.getAuthData();

      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        },
      });

      const cleanUp = () => {
        connectionHandle?.unsubscribe();
        delete connections[context.requestTrackerId];
      };

      if (context.requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...context?.fetchOptions,
          },
        });

        if (connections[context.requestTrackerId]) {
          // If a controller exists, that means this operation should be aborted.
          connections[context.requestTrackerId]?.abort();
        }

        connections[context.requestTrackerId] = controller;
      }

      if (
        (token === 'undefined' || !Boolean(token)) &&
        !['userLogin', 'forgotPassword', 'getExchangeToken'].includes(operation?.operationName)
      ) {
        cleanUp();
        StorageService.clearUserData();
        NavigateService.navigate('/');
      }

      return connectionHandle;
    }),
);
