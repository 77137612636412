import { gql } from '@apollo/client/core';

export const GET_ORGANIZATION_USERS = gql`
  mutation fetchOrganizationUsers($input: FetchOrganizationUserInput) {
    fetchOrganizationUsers(input: $input) {
      data {
        id
        organization {
          id
          name
          slug
        }
        user {
          id
          email
          firstName
          lastName
          phone
          roles
          employeeId
        }
        userId
        role
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      success
      code
      message
    }
  }
`;

export const CREATE_ORGANIZATION_GROUP_USER = gql`
  mutation createOrganizationGroupUser($input: CreateOrganizationGroupUserInput) {
    createOrganizationGroupUser(input: $input) {
      message
      status
      error
    }
  }
`;

export const DELETE_ORGANIZATION_GROUP_USER = gql`
  mutation DeleteOrganizationGroupUser($input: DeleteOrganizationGroupUserInput) {
    deleteOrganizationGroupUser(input: $input) {
      success
    }
  }
`;

export const ADD_ORGANIZATION_USER = gql`
  mutation addOrganizationUser($input: AddOrganizationUserInput) {
    addOrganizationUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const DELETE_ORGANIZATION_USER = gql`
  mutation removeOrganizationUser($input: DeleteOrganizationUserInput) {
    removeOrganizationUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const ADD_MERCHANT_USER = gql`
  mutation addMerchantUser($input: AddMerchantUserInput) {
    addMerchantUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const DELETE_MERCHANT_USER = gql`
  mutation deleteMerchantUser($input: DeleteMerchantUserInput) {
    deleteMerchantUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const ADD_MERCHANT_GROUP_USER = gql`
  mutation createMerchantGroupsUser($input: CreateMerchantGroupsUserInput) {
    createMerchantGroupsUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const DELETE_MERCHANT_GROUP_USER = gql`
  mutation deleteMerchantGroupsUser($input: DeleteMerchantGroupsUserInput) {
    deleteMerchantGroupsUser(input: $input) {
      code
      message
      success
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUserRequest($input: UserInput) {
    createUserRequest(input: $input) {
      status
      message
      error
      user {
        id
        email
        firstName
        lastName
        employeeId
        phone
        roles
      }
    }
  }
`;
