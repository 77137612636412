import { Loading } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import AddCard from 'pages/desktop/CheckoutWithPatient/AddCard';
import PaymentMethodCard from 'pages/desktop/CheckoutWithPatient/PaymentMethodCard';
import { usePaymentMethods } from 'pages/desktop/CheckoutWithPatient/PaymentMethodContext/PaymentMethodContext';
import { OutlinedButton } from 'pages/desktop/CheckoutWithPatient/SetupPayment/styles';
import { PaymentViewState } from 'pages/desktop/CheckoutWithPatient/SetupPayment/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DownPaymentProps } from './types';

const DownPayment = ({ borrowerId, applicationId, selectedDownPaymentMethod, onMethodSelect }: DownPaymentProps) => {
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();
  const {
    paymentMethods,
    handlePaymentMethods,
    loading,
    cardList,
    debitCardMethodContents,
    creditCardMethodContents,
  } = usePaymentMethods();

  const [paymentViewState, setPaymentViewState] = useState<PaymentViewState | null>(null);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      if (cardList?.length > 0) {
        setPaymentViewState(PaymentViewState.PaymentList);
      } else {
        setPaymentViewState(PaymentViewState.AddPaymentMethod);
      }
    } else {
      setPaymentViewState(PaymentViewState.AddPaymentMethod);
    }
  }, [paymentMethods, cardList]);

  const openAddPaymentMethodView = () => {
    setPaymentViewState(PaymentViewState.AddPaymentMethod);
  };

  const openPaymentMethodListView = () => {
    setPaymentViewState(PaymentViewState.PaymentList);
  };

  const onCardConnect = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.DOWN_PAYMENT_ADD_PAYMENT_METHOD_CLICKED', {
      loanId: id,
      borrowerId,
    });
    await handlePaymentMethods(borrowerId);
    setPaymentViewState(PaymentViewState.PaymentList);
  };

  if (loading) {
    return <Loading size={20} />;
  }

  if (paymentViewState === PaymentViewState.PaymentList) {
    return (
      <>
        {debitCardMethodContents()?.map((paymentMethod, index) => (
          <PaymentMethodCard
            key={index}
            paymentMethod={paymentMethod}
            selectPaymentMethod={onMethodSelect}
            selectedPaymentMethod={selectedDownPaymentMethod}
            isPreferred={true}
          />
        ))}

        {creditCardMethodContents()?.map(
          (paymentMethod, index) =>
            paymentMethod?.storedCard?.type !== 'PREPAID' && (
              <PaymentMethodCard
                key={index}
                paymentMethod={paymentMethod}
                selectPaymentMethod={onMethodSelect}
                selectedPaymentMethod={selectedDownPaymentMethod}
              />
            ),
        )}
        <OutlinedButton onClick={openAddPaymentMethodView}>Add Payment Method</OutlinedButton>
      </>
    );
  }

  if (paymentViewState === PaymentViewState.AddPaymentMethod) {
    return <AddCard id={borrowerId || applicationId} onCancel={openPaymentMethodListView} onConnect={onCardConnect} />;
  }

  return null;
};

export default DownPayment;
