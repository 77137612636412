import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { APP_STATIC_URL } from 'config';
import { Loading } from 'lib/components';
import { Button, Container, ContentContainer, Icon, Title } from 'lib/components/mobile';
import { FaqListComponent } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';

const MobileHelpSupport = () => {
  const { isSnackbarVisible } = useSnackbar();
  const { setShowChat } = useStore();
  const [staticFile, setStaticFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchStaticFile = async () => {
      const result = await axios.get(APP_STATIC_URL);
      setStaticFile(result.data);
      setLoading(false);
    };

    fetchStaticFile();
  }, []);

  const openChantWidget = () => {
    setShowChat(true);
  };

  return (
    <Container pageTitle="Help & Support">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title>Who To Contact</Title>
        <SubTitle>Support Hours</SubTitle>
        <InfoText>Monday through Friday 9AM – 9PM EST</InfoText>
        <InfoText>Saturday 9AM – 6PM EST</InfoText>
        <InfoText>Sunday Closed</InfoText>
        <SubTitle>Merchant Support</SubTitle>
        <InfoTextWithIcon>
          <Icon m={'0 10px 0 0'} width={20} height={20} src={'active-phone'} />
          (888) 839-7171
        </InfoTextWithIcon>
        <InfoTextWithIcon>
          <Icon m={'0 10px 0 0'} width={20} height={20} src={'active-mail'} />
          support@withcherry.com
        </InfoTextWithIcon>
        <InfoTextWithIcon>
          <Button onClick={openChantWidget}>Chat Online</Button>
        </InfoTextWithIcon>

        <Title m={'24px 0px 0px'}>Frequently Asked Questions</Title>
        {loading ? (
          <Grid alignItems={'center'} justifyContent={'center'}>
            <Loading />
          </Grid>
        ) : (
          <FaqListComponent faqList={staticFile?.FAQ || []} />
        )}
      </ContentContainer>
    </Container>
  );
};

export default MobileHelpSupport;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
  margin-top: 24px;
`;

const InfoText = styled(SubTitle)`
  font-weight: 400;
  margin-top: 0;
`;

const InfoTextWithIcon = styled(InfoText)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;
