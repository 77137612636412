import { Grid } from '@mui/material';
import { ButtonPrimary, EditIcon, FixedDecimalFormat, TrashIcon } from 'lib/components';
import { formatTextCapitalize, settingsTableStyle } from 'lib/utils';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import FulfillmentFormModal from './FulfillmentFormModal';
import RemoveFulfillmentModal from './RemoveFulfillmentModal';

interface Props {
  productData: any;
  onChange: (key: string, value: any) => void;
}

const FulfillmentOptions = ({ productData, onChange }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFulfillmentModalOpen, setIsFulfillmentModalOpen] = useState(false);
  const [isRemoveFulfillmentModalOpen, setIsRemoveFulfillmentModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const handleAddFulfillmentClick = () => {
    setIsEditMode(false);
    setIsFulfillmentModalOpen(true);
  };

  const handleClose = () => {
    setIsFulfillmentModalOpen(false);
  };

  const handleCloseRemoveFulfillmentModal = () => {
    setIsRemoveFulfillmentModalOpen(false);
  };

  const handleRemoveFulfillmentConfirm = () => {
    onChange(
      'fulfillmentOptions',
      productData?.fulfillmentOptions?.filter((_e, idx) => idx !== selectedRowIndex),
    );
  };

  const handleRemoveFulfillment = (index) => () => {
    setSelectedRowIndex(index);
    setIsRemoveFulfillmentModalOpen(true);
  };

  const handleEditFulfillment = (index) => () => {
    setIsEditMode(true);
    setSelectedRowIndex(index);
    setIsFulfillmentModalOpen(true);
  };

  const tableColumns = [
    {
      name: 'Method',
      selector: (row) => row.method,
      cell: (row) => formatTextCapitalize(row.method),
      width: '200px',
    },
    {
      name: 'Display Name',
      selector: (row) => row.displayName,
    },
    {
      name: 'Description / Instructions',
      selector: (row) => row.description,
    },
    {
      name: 'Shipping Price',
      selector: (row) => row.shippingPrice,
      cell: (row) => (row.shippingPrice ? <FixedDecimalFormat amount={row.shippingPrice} prefix="$" /> : 'n/a'),
    },
    {
      name: '',
      selector: (row) => row.name,
      cell: (_, index) => (
        <>
          <ActionButton onClick={handleEditFulfillment(index)}>
            <EditIcon />
          </ActionButton>
          <ActionButton onClick={handleRemoveFulfillment(index)}>
            <TrashIcon color="#EC3360" width={24} height={24} />
          </ActionButton>
        </>
      ),
      minWidth: '200px',
      right: true,
    },
  ];

  return (
    <>
      <Grid container={true} justifyContent="space-between" alignItems="center">
        <Grid item={true}>
          <SectionTitle>Fulfillment Options</SectionTitle>
        </Grid>
        <Grid item={true}>
          <ButtonSecondary text="Add Fulfillment" onClick={handleAddFulfillmentClick} />
        </Grid>
      </Grid>

      <FulfillmentFormModal
        open={isFulfillmentModalOpen}
        onClose={handleClose}
        selectedRowIndex={selectedRowIndex}
        isEditMode={isEditMode}
        productData={productData}
        onProductDataChange={onChange}
      />

      <RemoveFulfillmentModal
        open={isRemoveFulfillmentModalOpen}
        onClose={handleCloseRemoveFulfillmentModal}
        onSubmit={handleRemoveFulfillmentConfirm}
      />

      <CustomDataTable
        noHeader={true}
        data={productData?.fulfillmentOptions}
        customStyles={settingsTableStyle}
        columns={tableColumns}
      />
    </>
  );
};

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 16px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }

  .rdt_TableRow {
    align-items: center;
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }
`;

const ButtonSecondary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 195, 125, 1) !important;
  color: rgba(0, 195, 125, 1) !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

const ActionButton = styled.div`
  z-index: 10000000000;
  cursor: pointer;
  font-size: 14px;
  :first-child {
    margin-right: 36px;
  }
`;

const SectionTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

export default FulfillmentOptions;
