import { useMutation } from '@apollo/client';
import { MERCHANT_LOGIN } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

export const useMerchantLogin = () => {
  const { user } = useStore();
  const [login] = useMutation(MERCHANT_LOGIN);

  const merchantLogin = async (idMerchant: number) => {
    const userData = StorageService.getUserData();

    const userType = userData.userType;
    if (userType && userType === 'MERCHANT_USER') {
      const {
        data: { merchantUserLogin: response },
      } = await login({ variables: { input: { idMerchant } } });

      StorageService.setAuthData({ authToken: response.token, refreshToken: response.refreshToken });
    }
  };

  const merchantLogout = () => {
    if (user?.userType === 'MERCHANT_USER') {
      const storedUser = StorageService.getUserData();

      StorageService.setUserData({
        ...storedUser,
        token: storedUser.token,
        refreshToken: storedUser.refreshToken,
        userType: user.userType,
      });
      StorageService.setAuthData({ authToken: storedUser.token, refreshToken: storedUser.refreshToken });
    }
  };
  return { merchantLogin, merchantLogout };
};
