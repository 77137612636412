import { Grid } from '@mui/material';
import React from 'react';

import { RefundStepEnums } from 'lib/types';
import {
  ApplyButton,
  CancelButton,
  Container,
  RefundContainer,
  RefundTitle,
  Text,
  TextButton,
  TextButtonColor,
  TextButtonContainer,
} from './Common';

const IssueRefund = ({ item, setActiveStep }) => {
  const servicingBalance = item?.servicingBalance;

  const refundButtonHandler = () => {
    setActiveStep(RefundStepEnums.ISSUE_REFUND_APPROVE, { refundType: 'full', amount: servicingBalance });
  };

  const goToPartialRefund = () => {
    setActiveStep(RefundStepEnums.ISSUE_PARTIAL_REFUND, { refundType: 'partial', amount: servicingBalance });
  };

  return (
    <Container>
      <RefundContainer>
        <RefundTitle>Issue Full Refund</RefundTitle>
        <Text>
          A full refund cancels the borrower’s contract. Cherry will reimburse your processing fees, and the borrower
          receives a credit for any remaining balance owed.
        </Text>

        <Grid container={true} spacing={2}>
          <Grid item={true} style={{ flex: 1 }}>
            <CancelButton data-testid="refund-cancel-button" onClick={item?.toggleExpandable} data-tag="confirm-cancel">
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} style={{ flex: 1 }}>
            <ApplyButton data-testid="refund-apply-button" onClick={refundButtonHandler}>
              Continue
            </ApplyButton>
          </Grid>
        </Grid>
        <TextButtonContainer>
          <TextButton>
            Need to issue a <TextButtonColor onClick={goToPartialRefund}>Partial Refund?</TextButtonColor>
          </TextButton>
        </TextButtonContainer>
      </RefundContainer>
    </Container>
  );
};

export default IssueRefund;
