import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { ButtonPrimary, DashCard, DashComponent } from 'lib/components';
import { Column, Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

const staticReferralUrl = 'https://refer.withcherry.com/v2/1/register';

export const ReferralLink = ({}) => {
  const onReferFriendClick = () => window.open(staticReferralUrl, '_blank');

  return (
    <Column>
      <DashComponent dashHeader={'Referral Link'} />
      <DashCard>
        <Grid container={true} spacing={3} direction={'row'}>
          <Grid item={true}>
            <Row fontSize={'14px'}>
              Have friends or business partners who would enjoy using Cherry? We’ve partnered with Referral Rock to
              reward spreading the word. It’s a win win!
            </Row>
          </Grid>
          <Grid item={true}>
            <CustomButtonPrimary
              data-testid="referralButton"
              text="Refer a Friend"
              endIcon={<ReferLinkIcon icon={faShareAlt} />}
              onClick={onReferFriendClick}
            />
          </Grid>
        </Grid>
      </DashCard>
    </Column>
  );
};

const CustomButtonPrimary = styled(ButtonPrimary)`
  width: 200px;
  text-transform: unset !important;
`;

const ReferLinkIcon = styled(FontAwesomeIcon)`
  width: 14px !important;
  height: 14px !important;
`;
