import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';

import { GET_EXCHANGE_TOKEN } from 'lib/graphql/mutations';

export const useGetExchangeToken = () => {
  const alert = useAlert();

  return useMutation(GET_EXCHANGE_TOKEN, {
    fetchPolicy: 'network-only',
    onError: (error: any) => alert.error(error),
  });
};
