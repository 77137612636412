import { gql } from '@apollo/client/core';

export const PUT_MERCHANT_DETAIL = gql`
  mutation putMerchantDetail($input: PutMerchantDetailRequest) {
    putMerchantDetail(input: $input) {
      success
    }
  }
`;

export const UPDATE_MERCHANT_COMMUNICATION_SETTINGS = gql`
  mutation updateMerchantCommunicationSettings($input: UpdateMerchantCommunicationSettings) {
    updateMerchantCommunicationSettings(input: $input) {
      merchantId
      displayName
      phone
      website
      success
    }
  }
`;

export const UPDATE_MERCHANT_FINDER_SERVICES = gql`
  mutation updateMerchantFinderServices($input: UpdateMerchantFinderServiceRequest) {
    updateMerchantFinderServices(input: $input) {
      success
    }
  }
`;
