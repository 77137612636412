import { useApolloClient } from '@apollo/client';
import { GET_FILE } from 'lib/graphql/queries';
import Server from 'lib/services/Server';
import { textReplacer } from 'lib/utils';

export const useFileDownload = () => {
  const client = useApolloClient();

  const downloadFileGQL = async (fileId: number) => {
    const {
      data: { getFile },
    } = await client.query({ query: GET_FILE, variables: { input: { fileId } } });
    const { data, mime: fileMime, name } = getFile?.data;
    const a = document.createElement('a');
    a.href = `data:image/png;base64,${data}`;
    const mime = fileMime.split('/')[1];
    a.download = `${name}.${mime}`;
    a.click();
  };

  return { downloadFile: downloadFileGQL };
};

export const downloadFile = async (fileId: number) => {
  const response = await Server.FileService.getFile(fileId);
  const { data, mime: fileMime, name } = response.data;
  const a = document.createElement('a');
  a.href = `data:image/png;base64,${data}`;
  const mime = fileMime.split('/')[1];
  a.download = `${name}.${mime}`;
  a.click();
};

export const downloadBase64 = (fileName: any, data: any): void => {
  const downloadLink = document.createElement('a');
  downloadLink.href = data;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadBlobFile = (fileName: any, data: any): void => {
  const documentName = textReplacer(fileName, ' ', '_').toLocaleLowerCase();
  const httpClient = new XMLHttpRequest();
  const URLToPDF = data.toString();
  httpClient.open('GET', URLToPDF, true);
  httpClient.responseType = 'blob';
  httpClient.onload = () => {
    const file = new Blob([httpClient.response], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `${documentName}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
  };
  httpClient.send();
};

export const downloadBlobByContentType = (content, filename, contentType) => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const arrayToCsv = (data: any) => {
  return data
    .map((row) =>
      row
        .map(String)
        .map((v) => v?.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(','),
    )
    .join('\r\n');
};

export const arrayToCsvForMultiline = (header: any, data: any, csvHeader: string) => {
  let csv = `${csvHeader}\n`;

  data.forEach((row) => {
    csv += row.join(',');
    csv += '\n';
  });
  return csv;
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = (reader.result as string)?.replace('data:', '')?.replace(/^.+,/, '');
      resolve(baseURL);
    };
  });
};
