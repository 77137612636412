import { Button } from '@frontend/cherry-library';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogTitle as MuiDialogTitle } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface GenericFileModalProps extends MuiDialogProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  buttonLeftText?: string | null;
  onButtonLeftClick?: (params?: unknown) => void | null;
  mainButtonText: string;
  onMainButtonClick: (params?: unknown) => void;
  loading?: boolean;
}

const GenericFileModal = ({
  title,
  children,
  buttonLeftText,
  onButtonLeftClick,
  mainButtonText,
  onMainButtonClick,
  loading = false,
  ...rest
}: GenericFileModalProps) => {
  const isLeftButtonVisible = !!buttonLeftText && !!onButtonLeftClick;

  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <Content>
        {children}
        <ButtonContainer>
          {isLeftButtonVisible ? (
            <Button fullWidth={true} variant="secondary" onClick={onButtonLeftClick} disabled={loading}>
              {buttonLeftText}
            </Button>
          ) : null}
          <Button fullWidth={true} onClick={onMainButtonClick} disabled={loading}>
            {mainButtonText}
          </Button>
        </ButtonContainer>
      </Content>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 480px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;
  margin-bottom: 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 24px !important;

  & > button {
    width: 50%;
  }
`;

export default GenericFileModal;
