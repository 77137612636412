import { useApolloClient } from '@apollo/client';
import { TreeView } from '@mui/lab';
import { DashCard, Loading, Title } from 'lib/components';
import { Button } from 'lib/components/mobile';
import { FETCH_ORGANIZATION_GROUP_TREE } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column } from 'lib/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right.svg';
import { ReactComponent as ExpandMoreIcon } from 'assets/images/green-chevron-down.svg';
import { TierName } from 'lib/utils';
import { StyledTreeItem } from './components/StyledTreeItem';

interface RenderTree {
  id: string;
  name: string;
  type: string;
  childs?: readonly RenderTree[];
}

enum BadgeText {
  bronze = 'Bronze Tier',
  silver = 'Silver Tier',
  gold = 'Gold Tier',
}

const OrganizationGroupStructure = () => {
  const { organization, organizationTier, setShowChat, selectedOrganizationGroup } = useStore();
  const client = useApolloClient();

  const [organizationGroupStructure, setOrganizationGroupStructure] = useState<RenderTree>();
  const [loading, setLoading] = useState(false);

  const openChatWidget = () => {
    setShowChat(true);

    // @ts-ignore
    window.icPatronChat?.show();
  };

  useEffect(() => {
    const fetchOrganizationGroupStructure = async () => {
      setLoading(true);
      const {
        data: { fetchOrganizationGroupTree },
      } = await client.query({
        query: FETCH_ORGANIZATION_GROUP_TREE,
        variables: {
          input: {
            organizationGroupId: selectedOrganizationGroup?.id || organization?.id,
            name: organization?.name,
            slug: organization?.slug,
          },
        },
      });
      setLoading(false);
      setOrganizationGroupStructure(fetchOrganizationGroupTree);
    };

    fetchOrganizationGroupStructure();
  }, []);

  // @ts-ignore
  const renderTree = (nodes: RenderTree) => (
    <StyledTreeItem key={nodes.id} nodeId={`${nodes.type}-${nodes.id}`} labelText={nodes.name} type={nodes.type}>
      {Array.isArray(nodes.childs) ? nodes.childs.map((node) => renderTree(node)) : null}
    </StyledTreeItem>
  );

  const defaultExpandedItems = () => {
    const ids: string[] = [];

    if (organizationGroupStructure) {
      ids.push(`${organizationGroupStructure.type}-${organizationGroupStructure.id}`);

      const mapIds = (node) => {
        if (Array.isArray(node.childs)) {
          ids.push(`${node.type}-${node?.id}`);
          node.childs.map((n) => mapIds(n));
        }
      };

      mapIds(organizationGroupStructure);
    }

    return ids;
  };

  return (
    <Container>
      <Title>Organization Structure</Title>
      <Row>
        <Column>
          <DashCard>
            <ContentTitle>Your Organization Structure</ContentTitle>
            <p>If you need to make any organizational changes, please contact Cherry’s support team.</p>
            {organizationTier?.hasTier && (
              <HasTierText>
                <b>Current Pricing Tier:</b>{' '}
                <TierText>{BadgeText[TierName(organizationTier?.organizationTier?.tierId)?.toLowerCase()]}</TierText>
              </HasTierText>
            )}
            <p>All individual locations and groupings roll up into your top-level pricing tier.</p>
            <Button block={false} onClick={openChatWidget}>
              Contact Support
            </Button>
            {loading ? (
              <Loading size={15} />
            ) : organizationGroupStructure ? (
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={defaultExpandedItems()}
              >
                {organizationGroupStructure && renderTree(organizationGroupStructure)}
              </TreeView>
            ) : null}
          </DashCard>
        </Column>
      </Row>
    </Container>
  );
};

export default OrganizationGroupStructure;

const Container = styled.div`
  margin-top: 150px;
  padding: 0 20px;
  padding-bottom: 150px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ContentTitle = styled.h4`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

const HasTierText = styled.p`
  font-size: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => props.theme?.main.midnightBlue};
`;

const TierText = styled.span`
  font-size: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 4px;
  color: ${(props) => props?.theme.main.green};
`;
