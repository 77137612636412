import { FormControl, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';

import { useMutation } from '@apollo/client';
import ModalV2, { ModalStatus, MODAL_STATUS } from 'lib/components/ModalV2/ModalV2';
import { CREATE_POINT_OF_SALE_SMS_LINK } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { clearMaskFormat, formatPhoneNumber } from 'lib/utils';
import styled from 'styled-components';
import { PointOfSaleLink } from './PointOfSaleLinks';

const initialLinkValues = {
  firstName: '',
  lastName: '',
  treatmentAmount: '',
  phone: '',
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const SendLinkModal = ({ open, onClose }: Props) => {
  const { organization } = useStore();
  const [linkFormData, setLinkFormData] = useState<PointOfSaleLink>(initialLinkValues);
  const [modalStatus, setModalStatus] = useState<ModalStatus>();

  const [createSmsLink, { loading }] = useMutation(CREATE_POINT_OF_SALE_SMS_LINK, {
    onCompleted: () => {
      setModalStatus(MODAL_STATUS.SUCCESS);
    },
    onError: () => {
      setModalStatus(MODAL_STATUS.ERROR);
    },
  });

  const resetForm = () => {
    setLinkFormData(initialLinkValues);
  };

  const handleSubmit = async () => {
    await createSmsLink({
      variables: {
        input: {
          organizationId: organization?.id,
          firstName: linkFormData.firstName,
          lastName: linkFormData.lastName,
          phone: linkFormData.phone,
          treatmentAmount: parseFloat(linkFormData.treatmentAmount),
        },
      },
    });
  };

  const handleClose = () => {
    resetForm();
    setModalStatus(undefined);
    onClose();
  };

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    setLinkFormData((prev: PointOfSaleLink) => ({ ...prev, [key]: value }));
  };

  const onCurrencyChangeHandler = (value: string | undefined, name: string | undefined) => {
    setLinkFormData((prev: PointOfSaleLink) => ({ ...prev, [name!]: value }));
  };

  const onPhoneChange = (event) => {
    const rawPhoneValue = event.target.value;

    const parsedPhoneInput = clearMaskFormat(rawPhoneValue);
    setLinkFormData((prev: PointOfSaleLink) => ({ ...prev, phone: parsedPhoneInput }));
  };

  return (
    <ModalV2
      open={open}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      title="Send Point of Sale Link"
      submitButtonLabel="Send Link"
      width={480}
      status={loading ? MODAL_STATUS.LOADING : modalStatus}
      successContent={`A link was sent to ${linkFormData.firstName} ${linkFormData.lastName} at ${formatPhoneNumber(
        linkFormData.phone,
      )}.`}
      errorContent={
        <Body>
          We weren’t able to send a link.
          <p>Please double check the patient’s information and try again.</p>
        </Body>
      }
    >
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>First Name</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Patient's First Name"
              inputProps={{
                'data-inputkey': 'firstName',
              }}
              onChange={handleChange}
              value={linkFormData?.firstName}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Last Name</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Patient's Last Name"
              inputProps={{
                'data-inputkey': 'lastName',
              }}
              onChange={handleChange}
              value={linkFormData?.lastName}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Patient Mobile Phone</FormLabel>
        <CustomInputMask
          data-testid="application-phone-input"
          className={'valid'}
          mask="(999) 999-9999"
          value={linkFormData.phone}
          onChange={onPhoneChange}
        >
          {(inputProps) => (
            <PhoneInput
              {...inputProps}
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              placeholder="(___) ___-____"
              type="tel"
            />
          )}
        </CustomInputMask>
      </FormControl>

      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Total Treatment Cost</FormLabel>
        <StyledCurrencyInput
          placeholder="Total Treatment Cost"
          name="treatmentAmount"
          className={`form-control`}
          value={linkFormData?.treatmentAmount}
          onValueChange={onCurrencyChangeHandler}
          prefix="$"
          decimalScale={2}
          step={1}
          allowNegativeValue={false}
        />
      </FormControl>
    </ModalV2>
  );
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const CustomInputMask = styled(InputMask)`
  padding-left: 50px;
  font-size: 14px;
  flex: 1;

  &.error {
    border: 1px solid #ff0000;
  }
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';

  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;

const PhoneInput = styled.input`
  width: 100%;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #ccc;
  border-right: none;
  padding: 8px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

const Body = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

export default SendLinkModal;
