import { useMutation } from '@apollo/client';
import { PATCH_LOAN } from 'lib/graphql/mutations';
import { useSegment } from 'lib/hooks/useSegment';
import { useCheckoutWithPatient } from 'lib/services';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import CustomizePlan from '../CustomizePlan';
import { usePaymentMethods } from '../PaymentMethodContext/PaymentMethodContext';
import PlanCard from '../PlanCard';
import { ProductCalculation } from '../PlanCard/types';
import PlanInformation from '../PlanInformation';
import Section from '../Section';
import { Loan } from '../types';
import { PlansContainer } from './styles';

interface Props {
  loan: Loan;
  setLoan: (loan: any) => void;
  loanId: string | undefined;
  productCalculations: ProductCalculation[];
  setProductCalculations: (productCalculation: React.SetStateAction<ProductCalculation[] | undefined>) => void;
  setStep: (step: number) => void;
  currentStep: number;
  isCheckoutProcessing: boolean;
}

const SELECT_PLAN_STEP_INDEX = 0;

const PlanSelectStep = ({
  loan,
  setLoan,
  loanId,
  productCalculations,
  setProductCalculations,
  setStep,
  currentStep,
}: Props) => {
  const alert = useAlert();
  const { trackSegmentEvent } = useSegment();
  const [patchLoan] = useMutation(PATCH_LOAN);
  const { getLoan } = useCheckoutWithPatient();
  const { isPatientInReviewMode } = usePaymentMethods();

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isChangeDownPaymentVisible, setIsChangeDownPaymentVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  const currentLoanProduct = productCalculations?.find((p) => Number(p.product?.id) === loan?.productId);

  const isContinueButtonDisabled = selectedIndex === undefined || selectedIndex < 0;

  useEffect(() => {
    if (currentStep === SELECT_PLAN_STEP_INDEX) {
      setIsEditMode(true);
    }
  }, [currentStep]);

  const handleSelectPlan = (index: number) => () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.SELECT_PLAN_CLICKED', {
      loanId,
      planId: productCalculations[index].product?.id,
      borrowerId: loan?.borrowerId,
    });
    setSelectedIndex(index);
  };

  const handleChangeDownPaymentClick = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.CUSTOM_DOWN_PAYMENT_CLICKED', {
      loanId,
      borrowerId: loan?.borrowerId,
      planId: productCalculations[selectedIndex!]?.product?.id,
    });
    setIsChangeDownPaymentVisible(true);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setStep(SELECT_PLAN_STEP_INDEX);
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_1_EDIT_CLICKED', {
      loanId,
      borrowerId: loan?.borrowerId,
    });
  };

  const handleContinueClick = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_1_CONTINUE_CLICKED', {
      loanId,
      borrowerId: loan?.borrowerId,
      planId: productCalculations[selectedIndex!]?.product?.id,
    });

    if (!loanId) return;

    setLoading(true);

    try {
      const { data } = await patchLoan({
        variables: {
          input: {
            loanId,
            productId: productCalculations[selectedIndex!]?.product?.id,
          },
        },
      });

      const loanRes = await getLoan(Number(loanId));

      if (loanRes) {
        setLoan(loanRes);
      }
      if (data?.patchLoan?.id) {
        setStep(SELECT_PLAN_STEP_INDEX + 1);
        setIsEditMode(false);
      } else {
        alert.error('Error updating plan');
      }
    } catch (error) {
      alert.error('Error selecting plan');
    } finally {
      setLoading(false);
    }
  };

  const handleGoChangeDownPaymentToPlans = () => {
    setIsChangeDownPaymentVisible(false);
  };

  const handleSaveDownPayment = (data: ProductCalculation[]) => {
    setProductCalculations(data);
    setSelectedIndex((prev) => prev! + 1);
  };

  const renderContent = () => {
    if (!isEditMode) return <PlanInformation plan={currentLoanProduct} />;

    if (isChangeDownPaymentVisible)
      return (
        <CustomizePlan
          loan={loan}
          plan={productCalculations?.[selectedIndex!]}
          onBackClick={handleGoChangeDownPaymentToPlans}
          onSaveDownPayment={handleSaveDownPayment}
        />
      );

    return (
      <PlansContainer>
        {productCalculations?.map((data, index) => (
          <PlanCard
            key={`productCalc-${index}`}
            onSelect={handleSelectPlan(index)}
            active={selectedIndex === index}
            data={data}
            isCheckoutStep={true}
            onChangeDownPayment={handleChangeDownPaymentClick}
          />
        ))}
      </PlansContainer>
    );
  };

  return (
    <Section
      title="Step 1: Select a Plan"
      isExpanded={currentStep === SELECT_PLAN_STEP_INDEX || !isEditMode}
      showEditButton={!isEditMode}
      isEditButtonDisabled={isPatientInReviewMode}
      isContinueButtonDisabled={isContinueButtonDisabled}
      hideActionButtons={isChangeDownPaymentVisible}
      loading={loading}
      onEdit={handleEditClick}
      onContinue={handleContinueClick}
    >
      {renderContent()}
    </Section>
  );
};

export default PlanSelectStep;
