import { gql } from '@apollo/client/core';

export const REFUND_LOAN = gql`
  mutation refundLoan($input: RefundLoanRequest) {
    refundLoan(input: $input) {
      code
      message
      success
    }
  }
`;

export const PARTIAL_REFUND_LOAN = gql`
  mutation partialRefundLoan($input: PartialRefundLoanRequest) {
    partialRefundLoan(input: $input) {
      code
      message
      success
    }
  }
`;

export const CANCEL_REFUND_LOAN = gql`
  mutation cancelRefundLoan($input: CancelRefundLoanRequest) {
    cancelRefundLoan(input: $input) {
      code
      message
      success
    }
  }
`;

export const VOID_LOAN = gql`
  mutation voidLoan($input: VoidLoanRequest) {
    voidLoan(input: $input) {
      code
      message
      success
    }
  }
`;
