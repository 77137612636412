import { theme } from '@frontend/cherry-library';
import { Box as MuiBox } from '@mui/material';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';

// TO-DO: Move this circular loading bar to somewhere else
export default function CustomMuiCircularProgress(props: CircularProgressProps) {
  return (
    <MuiBox sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.main.kellyGreen50,
        }}
        size={64}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink={true}
        sx={{
          color: theme.main.kellyGreen,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
            strokeDasharray: '40px, 200px',
          },
        }}
        size={64}
        thickness={4}
        {...props}
      />
    </MuiBox>
  );
}
