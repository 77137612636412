import { theme } from '@frontend/cherry-library';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  size: 'small' | 'medium';
  label: string;
  background: string;
  tooltipBackground: string;
  tooltipText: React.ReactNode;
  icon: React.ReactNode;
}

export const ApplicationStatus = ({ size, label, background, tooltipBackground, tooltipText, icon }: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleTooltipShow = () => {
    setShow(!show);
  };

  return (
    <PopoverWrapper onMouseEnter={handleTooltipShow} onMouseLeave={handleTooltipShow}>
      <Chip background={background}>
        {label} {icon}
      </Chip>
      {show && (
        <Popover size={size} background={tooltipBackground}>
          <DownCircle size={size} background={tooltipBackground} />
          <PopoverContent background={tooltipBackground}>
            <PopoverText>{tooltipText}</PopoverText>
          </PopoverContent>
        </Popover>
      )}
    </PopoverWrapper>
  );
};

const PopoverWrapper = styled.div`
  cursor: default;
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;

const PopoverContent = styled.div<{ background: string }>`
  padding: 16px;
  background-color: ${(props) => props.background};
  display: flex;
  justify-content: center;
`;

const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.main.white};
  b {
    font-size: 16px;
  }
`;

const Popover = styled.div<{ background: string; size: string }>`
  position: absolute;
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.background};
  border-radius: 4px;
  width: 400px;
  z-index: 10;
  left: -230px;
  top: -115px;
  top: ${(props) => (props.size === 'small' ? '-145px' : '-130px')};
`;

const DownCircle = styled.div<{ background: string; size: string }>`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${(props) => props.background};
  position: absolute;
  right: calc(100% - 375px);
  bottom: ${(props) => (props.size === 'small' ? 'calc(-10%)' : 'calc(-11%)')};
  transform: rotate(180deg);
`;

const Chip = styled.div<{ background: string }>`
  border-radius: 24px;
  background: ${(props) => props.background};
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 36px;
  display: flex;
  gap: 4px;
  padding: 0 10px;
`;
