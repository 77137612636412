import { Grid } from '@mui/material';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { FixedDecimalFormat } from 'lib/components';
import { usePartialRefund } from 'lib/services';
import {
  ApplyButton,
  CancelButton,
  Container,
  RefundContainer,
  RefundTitle,
  Text,
  TextButton,
  TextButtonColor,
  TextButtonContainer,
} from './Common';

const IssuePartialRefund = ({ item, setActiveStep }) => {
  const {
    goToFullRefund,
    onPurchaseAmountBlurred,
    onPurchaseAmountChange,
    validPurchaseAmountRange,
    servicingBalance,
    refundButtonHandler,
    purchaseAmountErrorText,
    amount,
  } = usePartialRefund({ transaction: item, setActiveStep });

  return (
    <Container>
      <RefundContainer>
        <RefundTitle>Issue Partial Refund</RefundTitle>
        <Text>
          A partial refund issues a partial credit to the borrower, not to exceed the full remaining balance of{' '}
          <b>
            <FixedDecimalFormat amount={servicingBalance} prefix={'$'} />
          </b>
          . We will prorate and reimburse your processing fees. Partial refunds can be performed multiple times and only
          up to the full remaining balance.
        </Text>
        <InputContainer>
          <FormLabel className={validPurchaseAmountRange ? 'valid' : 'error'}>
            Enter Amount up to: <FixedDecimalFormat amount={servicingBalance} prefix={'$'} />
          </FormLabel>
          <AbsoluteIconWrapper className={validPurchaseAmountRange ? 'valid' : 'error'}>$</AbsoluteIconWrapper>
          <CustomCurrencyInput
            data-testid="partial-refund-input"
            className={validPurchaseAmountRange ? 'valid' : 'error'}
            onValueChange={onPurchaseAmountChange}
            formatValueOnBlur={true}
            onBlur={onPurchaseAmountBlurred}
            allowNegativeValue={false}
          />
          {!validPurchaseAmountRange ? <CustomErrorText>{purchaseAmountErrorText}</CustomErrorText> : ''}
        </InputContainer>

        <Grid container={true} spacing={2}>
          <Grid item={true} style={{ flex: 1 }}>
            <CancelButton data-testid="refund-cancel-button" onClick={item?.toggleExpandable} data-tag="confirm-cancel">
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} style={{ flex: 1 }}>
            <ApplyButton
              data-testid="refund-apply-button"
              disabled={amount < 1 || !validPurchaseAmountRange}
              onClick={refundButtonHandler}
            >
              Continue
            </ApplyButton>
          </Grid>
        </Grid>
        <TextButtonContainer>
          <TextButton>
            Need to issue a <TextButtonColor onClick={goToFullRefund}>Full Refund?</TextButtonColor>
          </TextButton>
        </TextButtonContainer>
      </RefundContainer>
    </Container>
  );
};

const InputContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
  .error {
    color: #ff0000;
  }
`;

const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main.textColor};
  text-align: left;
  margin-bottom: 5px;
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-top: 12px;
  margin-left: 15px;
  font-size: 14px;
`;

const CustomCurrencyInput = styled(CurrencyInput)`
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dadada;
  height: 40px;
  font-size: 14px;
  color: #0e202f;
  outline: none;
  width: 91%;
  padding-left: 25px;
  &.error {
    border: 1px solid #ff0000 !important;
  }
`;

const CustomErrorText = styled.div`
  color: #ec3360;
  font-size: 10px;
  margin-top: 4px;
`;

export default IssuePartialRefund;
