import { gql } from '@apollo/client/core';

export const FETCH_USER_NOTIFICATION_LIST = gql`
  query getUserNotificationsList($input: UserNotificationListInput) {
    getUserNotificationsList(input: $input) {
      total
      contents {
        id
        title
        content
        isImportantBadge
        status
        sentAt
        statistics {
          hasRead
          hasSeen
          hasClicked
        }
        redirection {
          label
          url
          isOpenNewTab
        }
      }
    }
  }
`;

export const FETCH_USER_NOTIFICATION_LIST_STATISTICS = gql`
  query GetUserNotificationStatistics {
    getUserNotificationStatistics {
      unSeenCount
      unReadCount
    }
  }
`;

export const MUTATION_USER_NOTIFICATION = gql`
  mutation updateUserNotificationActions($input: UpdateUserNotificationActionsInput) {
    updateUserNotificationActions(input: $input) {
      success
      code
      message
    }
  }
`;
