import { Button, theme } from '@frontend/cherry-library';
import { Dialog, Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { CherryDialogTitle, DialogContainer, SubTitle } from '../Dialog';

export const UserManagementRemoveDialog = ({ selectedOrganizationMember, open, handleClose, handleConfirm }) => {
  return (
    <Dialog maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Remove Team Member
        </CherryDialogTitle>
        <SubTitle>
          Are you sure you want to remove
          <strong>
            {` ${selectedOrganizationMember?.user?.firstName} ${selectedOrganizationMember?.user?.lastName} `}
          </strong>{' '}
          from your organization? They will no longer have access to Cherry Partner Portal.
        </SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <Button variant="secondary" fullWidth={true} disabled={false} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <RemoveButton
              fullWidth={true}
              disabled={false}
              onClick={handleConfirm}
              data-testid="user-dialog-remove-RemoveButton"
            >
              Yes, Remove
            </RemoveButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const RemoveButton = styled(Button)`
  background-color: ${theme.main.cherryRed} !important;
  border-color: ${theme.main.cherryRed} !important;
`;
