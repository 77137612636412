import { useMutation } from '@apollo/client';
import client from 'lib/graphql/client';
import {
  CREATE_CHECKOUT_WITH_PATIENT_LINK,
  PATCH_LOAN_DETAIL,
  SEND_CHECKOUT_WITH_PATIENT_CONFIRMATION_LINK,
} from 'lib/graphql/mutations';
import {
  GET_APPLICATION,
  GET_BORROWER_DETAIL,
  GET_DOWN_PAYMENT_AMOUNT_CALCULATE,
  GET_LOAN,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHOD_FEES,
} from 'lib/graphql/queries';
import { PaymentMethodSearch } from 'lib/graphql/searches/paymentMethodsSearch';
import { useGetExchangeToken } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

interface PatchLoanForCheckoutRequest {
  downPaymentPaymentMethodId: number;
  installmentPaymentMethodId: number;
  autoPay: boolean;
  productId: number;
}

export const useCheckoutWithPatient = () => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [patchLoan] = useMutation(PATCH_LOAN_DETAIL);
  const [sendCheckoutWithPatientConfirmationLink] = useMutation(SEND_CHECKOUT_WITH_PATIENT_CONFIRMATION_LINK);
  const [createCheckoutWithPatientLink] = useMutation(CREATE_CHECKOUT_WITH_PATIENT_LINK);
  const { organization } = useStore();

  const [getExchangeToken] = useGetExchangeToken();

  const getBorrower = async (borrowerId: string) => {
    try {
      const { data } = await client.query({
        query: GET_BORROWER_DETAIL,
        variables: {
          input: {
            id: borrowerId,
          },
        },
      });

      return data?.getBorrowerDetail?.data;
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message || 'Borrower error occured');
    }
  };

  const getApplication = async (applicationId: string) => {
    try {
      const { data } = await client.query({
        query: GET_APPLICATION,
        variables: {
          input: {
            applicationId,
          },
        },
      });
      if (data?.getApplication?.id) {
        return data?.getApplication;
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message || 'Application error occured');
    }
  };

  const getLoan = async (loanId: number) => {
    try {
      const { data } = await client.query({
        query: GET_LOAN,
        variables: {
          input: {
            loanId,
          },
        },
      });

      if (data?.getLoan?.id) {
        return data?.getLoan;
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message || 'Loan error occured');
    }
  };

  const setTokenForCheckout = async (checkoutToken: string) => {
    try {
      const { data } = await getExchangeToken({ variables: { input: { token: checkoutToken } } });
      StorageService.setWPQAuthData({ wpqToken: data?.getExchangeToken?.token });

      return { success: true };
    } catch {
      alert.error('Token error');
    }
  };

  const getPaymentMethods = async (borrowerId) => {
    const { data } = await client.query({
      query: GET_PAYMENT_METHODS,
      variables: {
        input: {
          borrowerId,
          search: PaymentMethodSearch,
        },
      },
    });

    if (data?.getPaymentMethods?.contents) {
      return data?.getPaymentMethods;
    } else {
      alert.error('Payment methods fetch error');
      return [];
    }
  };

  const patchLoanForCheckout = async (loanId, checkoutData: PatchLoanForCheckoutRequest) => {
    try {
      const { data } = await patchLoan({
        variables: {
          input: {
            loanId,
            ...checkoutData,
          },
        },
      });
      if (data?.patchLoan?.id) {
        alert.success(`Loan successfully updated`);
      } else {
        throw Error();
      }
    } catch (e: any) {
      if (e?.response?.data?.message === 'loan.status.invalid') {
        alert.error('The information for this contract was no longer current, your page was refreshed');
        return;
      } else {
        alert.error(`An error occurred please try again`);
        return;
      }
    }
  };

  const getDownPaymentCalculation = async (
    applicationId: number,
    productId: number,
    amount: number,
    grossAmount: number,
  ) => {
    try {
      const { data } = await client.query({
        query: GET_DOWN_PAYMENT_AMOUNT_CALCULATE,
        variables: {
          input: {
            applicationId,
            productId,
            amount,
            grossAmount,
          },
        },
      });

      if (!data?.getDownPaymentAmountCalculate?.variableDownPayment) {
        return [];
      }

      return data?.getDownPaymentAmountCalculate;
    } catch (error) {
      alert.error('Error calculationg new payment plan');
    }
  };

  const sendCheckoutConfirmationLink = async (loanId) => {
    try {
      const { data } = await sendCheckoutWithPatientConfirmationLink({
        variables: {
          input: {
            loanId,
            organizationId: organization?.id,
          },
        },
      });
      if (data?.sendCheckoutWithPatientConfirmationLink?.success) {
        alert.success(`Loan Agreement Sent`);
        return true;
      } else {
        throw Error();
      }
    } catch (e: any) {
      alert.error(`An error occurred please try again`);
      return false;
    }
  };

  const getPaymentMethodFees = async () => {
    try {
      const { data } = await client.query({
        query: GET_PAYMENT_METHOD_FEES,
      });

      if (data?.getPaymentFees) {
        return data?.getPaymentFees;
      }
      return [];
    } catch (error) {
      alert.error('Error while getting payment fees');
    }
  };

  const handleStartCheckoutWithPatient = async (loanId, organizationId, amount) => {
    try {
      const { data } = await createCheckoutWithPatientLink({
        variables: {
          input: {
            organizationId: organizationId,
            loanId,
          },
        },
      });
      if (data?.createCheckoutWithPatientLink?.success) {
        navigate(`/checkout/${loanId}`, {
          state: { amount, checkoutToken: data.createCheckoutWithPatientLink.link.split('/').pop() },
        });
        return true;
      } else {
        alert.error('Error processing the checkout on device');
        return false;
      }
    } catch (error) {
      alert.error('Something went wrong');
      return false;
    }
  };

  return {
    getBorrower,
    getApplication,
    getLoan,
    setTokenForCheckout,
    getPaymentMethods,
    patchLoanForCheckout,
    getDownPaymentCalculation,
    sendCheckoutConfirmationLink,
    getPaymentMethodFees,
    handleStartCheckoutWithPatient,
  };
};
