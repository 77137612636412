import { ChatIcon, DashCard, Loading } from 'lib/components';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { Row } from 'lib/styles';
import React, { Suspense, useRef } from 'react';
import styled from 'styled-components';
import { FaqHeader, CherryFaqSection, CherryFaq } from 'pages/desktop/Help/views/CheatsheetFaq';

export const CheatSheetFaq = ({ faqList }) => {
  const { trackEvent } = useAnalytics();
  const activeSectionRef = useRef<any>('');

  const onChatClicked = (e) => {
    const chatButton = document.querySelector('#chat--widget') as HTMLElement;
    chatButton.click();
    trackEvent({
      action: TrainingAnalyticsEventNames.CTA_BUTTONS,
      label: 'CHAT',
    });
  };

  const handleRowClicked = (e) => {
    e.target.parentNode.parentNode.parentNode.lastChild.click();
    trackEvent({
      action: TrainingAnalyticsEventNames.FAQ_CLICK,
      label: e.target.dataset.id,
    });
  };
  return (
    <DashCard>
      <Suspense fallback={<Loading fixed={true} size={40} />}>
        <CherryFaq id="faq--accordion" multiple={true}>
          {faqList.map((item) => {
            return (
              <CherryFaqSection
                ref={activeSectionRef}
                key={item.id}
                data-testid="faq-container"
                label={
                  <FaqHeader data-id={item.id} data-testid="faq-header" onClick={handleRowClicked}>
                    {item.title}
                  </FaqHeader>
                }
              >
                {' '}
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </CherryFaqSection>
            );
          })}
        </CherryFaq>
      </Suspense>
      <Row style={{ fontSize: '14px', marginBottom: '24px' }}>
        Can’t find what you’re looking for? Hit us up on chat!
      </Row>
      <Row>
        <ChatButton onClick={onChatClicked}>
          {' '}
          <ChatIcon />
          &nbsp; Chat Now
        </ChatButton>
      </Row>
    </DashCard>
  );
};

const ChatButton = styled.button`
  background-color: ${(props) => props.theme.main.green};
  text-transform: capitalize;
  border: 1px solid #00c37d;
  border-radius: 2px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Open Sans' !important;
  &:focus,
  &:hover {
    background-color: #00a569 !important;
  }
`;
