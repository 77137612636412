import { useMutation } from '@apollo/client';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import jwtDecode from 'jwt-decode';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { Button as CherryButton, Dropdown } from '@frontend/cherry-library';
import StorageService from 'lib/services/Storage';
import { UserManagementStepEnums } from 'lib/types';
import { roleList, UserAccessEnums } from 'lib/utils';

import {
  ADD_MERCHANT_USER,
  ADD_ORGANIZATION_USER,
  CREATE_ORGANIZATION_GROUP_USER,
  CREATE_USER,
  DELETE_MERCHANT_USER,
  DELETE_ORGANIZATION_GROUP_USER,
  DELETE_ORGANIZATION_USER,
} from 'lib/graphql/mutations';

import { useSegment } from 'lib/hooks/useSegment';
import { CherryDialogTitle, DialogContainer, DialogContent } from '../Dialog';

const ROLE_TYPES = [
  { value: 'MANAGER', label: 'Manager' },
  { value: 'SALES_MANAGER', label: 'Sales Manager' },
  { value: 'SALES', label: 'Salesperson' },
  { value: 'MARKETER', label: 'Marketer' },
  { value: 'ACCOUNTANT', label: 'Accountant' },
];

export const AssignRole = ({
  setActiveStep,
  setSelectedRole,
  handleClose,
  selectedOrganizationMember,
  userForCreation,
  organizationUsers,
  merchantUsers,
}) => {
  const alert = useAlert();
  const { organization, merchants = [], user, isAdminReporting, selectedOrganizationGroup } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const [createOrganizationGroupUser] = useMutation(CREATE_ORGANIZATION_GROUP_USER);
  const [deleteOrganizationGroupUser] = useMutation(DELETE_ORGANIZATION_GROUP_USER);
  const [addOrganizationUser] = useMutation(ADD_ORGANIZATION_USER);
  const [removeOrganizationUser] = useMutation(DELETE_ORGANIZATION_USER);
  const [addMerchantUser] = useMutation(ADD_MERCHANT_USER);
  const [deleteMerchantUser] = useMutation(DELETE_MERCHANT_USER);
  const [createUser] = useMutation(CREATE_USER);

  const { token } = StorageService.getAuthData();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const [activeRole, setActiveRole] = useState(selectedOrganizationMember?.role || 'ADMIN');

  const [controlledUser, setControlledUser] = useState(
    userForCreation.email ? userForCreation : selectedOrganizationMember.user,
  );
  const [roleTypes, setRoleTypes] = useState(ROLE_TYPES);

  const idOrganization = organization?.id || 0;

  useEffect(() => {
    const allUsers: any = [...organizationUsers, ...merchantUsers];
    const isUserExist = allUsers?.find((us) => us?.user?.email === user?.email);

    if (isUserExist?.role === 'ADMIN') {
      setRoleTypes([{ value: 'ADMIN', label: 'Administrator' }, ...roleTypes]);
    } else {
      setActiveRole('MANAGER');
    }
    setReady(true);
  }, []);

  const createUserRequest = async (type) => {
    try {
      const { email, firstName, lastName } = controlledUser;
      const params = { email, firstName, lastName };

      const { data } = await createUser({ variables: { input: { ...params } } });
      if (data?.createUserRequest?.error) {
        setLoading(false);
        alert.error('Create user failed');
      } else {
        setControlledUser(data?.createUserRequest?.user);
        if (type === 'organization') {
          if (selectedOrganizationMember.user) {
            updateOrganizationUserRequest();
            updateMerchantUserRequest(false);
            return;
          }
          addOrganizationUserRequest(data?.createUserRequest?.user);
          addMerchantUserRequest(data?.createUserRequest?.user, false);
        } else if (type === 'organizationGroup') {
          if (selectedOrganizationMember.user) {
            updateOrganizationGroupUserRequest();
            return;
          }
          addOrganizationGroupUserRequest(data?.createUserRequest?.user);
        } else {
          if (selectedOrganizationMember.user) {
            return updateMerchantUserRequest();
          }
          addMerchantUserRequest(data?.createUserRequest?.user);
        }
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const createUserRequestHandler = () => {
    try {
      const decodedToken: any = jwtDecode(token as any);
      if (isAdminReporting) {
        createUserRequest('organizationGroup');
      } else if (decodedToken?.type === 'PARTNER') {
        createUserRequest('organization');
      } else {
        createUserRequest('merchant');
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const addOrganizationGroupUserRequest = async (usr) => {
    try {
      const idUser = usr ? usr.id : selectedOrganizationMember?.userId;

      const { data } = await createOrganizationGroupUser({
        variables: { input: { organizationGroupId: selectedOrganizationGroup?.id, userId: idUser, role: activeRole } },
      });
      setLoading(false);
      if (data.createOrganizationGroupUser) {
        alert.success(selectedOrganizationMember?.user ? 'User Updated.' : 'User Created.');
        return handleClose();
      } else {
        setLoading(false);
        alert.error(data?.createOrganizationGroupUser?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const addOrganizationUserRequest = async (usr) => {
    try {
      const idUser = usr ? usr.id : selectedOrganizationMember?.user?.id;

      const { data } = await addOrganizationUser({
        variables: { input: { organizationId: idOrganization, userId: idUser, role: activeRole } },
      });
      setLoading(false);
      if (data.addOrganizationUser.success) {
        alert.success(selectedOrganizationMember?.user ? 'User Updated.' : 'User Created.');
        return handleClose();
      } else {
        setLoading(false);
        alert.error(data?.addOrganizationUser?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const updateOrganizationGroupUserRequest = async () => {
    try {
      const organizationUserId = selectedOrganizationMember?.id;

      const { data } = await deleteOrganizationGroupUser({
        variables: { input: { organizationUserId } },
      });

      if (data?.deleteOrganizationGroupUser?.success) {
        addOrganizationGroupUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (error) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const updateOrganizationUserRequest = async () => {
    try {
      const userId = selectedOrganizationMember?.user?.id;

      const { data } = await removeOrganizationUser({
        variables: { input: { userId, organizationId: idOrganization } },
      });

      if (data?.removeOrganizationUser?.success) {
        addOrganizationUserRequest(null);
      } else {
        setLoading(false);
        alert.error('An error occurred');
      }
    } catch (error) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const addMerchantUserRequest = async (usr, showAlert = true) => {
    try {
      const idUser = usr ? usr.id : selectedOrganizationMember?.user?.id;

      const { data } = await addMerchantUser({
        variables: { input: { idUser, idMerchants: [merchants?.[0]?.id], role: activeRole } },
      });
      setLoading(false);
      if (data.addMerchantUser.success) {
        showAlert && alert.success(selectedOrganizationMember?.user ? 'User Updated.' : 'User Created.');
        return handleClose();
      }
      alert.error(data?.addMerchantUser?.message);
    } catch (err) {
      alert.error('An error occurred');
      setLoading(false);
    }
  };

  const updateMerchantUserRequest = async (showAlert = true) => {
    try {
      const userId = selectedOrganizationMember?.user?.id;

      const { data } = await deleteMerchantUser({
        variables: { input: { userId, idMerchants: [merchants?.[0]?.id] } },
      });
      if (data.deleteMerchantUser.success) {
        addMerchantUserRequest(null, showAlert);
      } else {
        setLoading(false);
        alert.error(data.deleteMerchantUser?.message);
      }
    } catch (err) {
      setLoading(false);
      alert.error('An error occurred');
    }
  };

  const onRoleDropdownChange = (event) => {
    setActiveRole(event?.target?.value);
  };

  const backHandler = () => {
    setActiveStep(UserManagementStepEnums.ADD_TEAM_MEMBER);
  };

  const stepHandler = async () => {
    if (isAdminReporting || merchants?.length === 1) {
      const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
      trackSegmentEvent('Created new user', {
        application: applicationName,
        organizationId: organization?.id,
        merchantId: idMerchant,
        newUserRole: activeRole,
        screenName: 'Assign Role',
      });
      setLoading(true);
      createUserRequestHandler();
    } else {
      setSelectedRole(activeRole);
      setActiveStep(UserManagementStepEnums.LOCATION_ACCESS);
    }
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Assign Role
      </CherryDialogTitle>
      <SubTitle>
        Below are the features available to each role. Assign a role for{' '}
        {`${controlledUser?.firstName} ${controlledUser?.lastName}`}.
      </SubTitle>
      {ready && (
        <DialogContent>
          <Grid container={true} spacing={1}>
            <BorderContainer>
              <Dropdown
                value={activeRole}
                defaultValue={activeRole}
                options={roleTypes}
                onChange={onRoleDropdownChange}
              />
            </BorderContainer>
          </Grid>
          <Container>
            <LabelContainer>
              <Label hidden={true}>.</Label>
              {roleTypes.map((role) => (
                <Label key={role.value}>{role.label}</Label>
              ))}
            </LabelContainer>

            <TableContainer>
              <HeaderItem>
                {UserAccessEnums.map((header) => (
                  <HeaderLabel key={header.key}>{header.label}</HeaderLabel>
                ))}
              </HeaderItem>

              {roleTypes.map((roleType) => (
                <TableItem key={roleType.value} activeRow={activeRole === roleType.value}>
                  {UserAccessEnums.map((roles) => {
                    const roleValues = roleList?.find((role) => role.name === roleType.value).values;
                    if (roleValues.includes(roles.key)) {
                      return (
                        <IconContainer key={roles.key}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color={activeRole === roleType.value ? '#00C37C' : '#DADADA'}
                          />
                        </IconContainer>
                      );
                    }
                    return <IconContainer key={roles.key} />;
                  })}
                </TableItem>
              ))}
            </TableContainer>
          </Container>
        </DialogContent>
      )}

      <ButtonContainer>
        <CherryButton fullWidth={true} variant="secondary" onClick={backHandler}>
          Back
        </CherryButton>
        {/* <BackButton >Back</BackButton> */}
        <ButtonGap />
        <CherryButton
          variant="primary"
          fullWidth={true}
          loading={loading}
          disabled={loading}
          onClick={stepHandler}
          data-testid="role-continue-button"
        >
          Continue
        </CherryButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 16px 0;
  margin-bottom: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: end;
  align-self: stretch;
`;

const Label = styled.span`
  display: flex;
  flex: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding-top: 22px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom: 1px solid #868f97;
`;

const TableItem = styled.div<{ activeRow: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding: 0 8px;
  background-color: ${(props) => (props.activeRow ? '#E5F9F2' : '')};

  &:last-child {
    border-bottom: 0;
  }
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #868f97;
  padding: 0 8px;
  flex: 1;
`;

const HeaderLabel = styled.span`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px 5px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e202f;
  margin: 24px 0 -4px 0;
`;

const BorderContainer = styled.div`
  width: 240px;
  margin: 24px auto;
  display: flex;
  justify-content: center;
`;

export const DropDownBorder = styled.div`
  width: 240px;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  li {
    font-size: 14px;
  }

  svg {
    color: #00c37c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;
