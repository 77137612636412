import { gql } from '@apollo/client/core';

export const CREATE_DOCUMENT_REQUEST = gql`
  mutation createDocumentRequest($input: CreateDocumentRequestInput) {
    createDocumentRequest(input: $input) {
      success
    }
  }
`;

export const UPDATE_FILE_FOR_DOCUMENT_REQUEST = gql`
  mutation updateFileForDocumentRequest($input: UpdateFileForDocumentRequestInput) {
    updateFileForDocumentRequest(input: $input) {
      success
    }
  }
`;

export const SUBMIT_INFORMATION_TO_DOCUMENT_REQUEST = gql`
  mutation submitInformationToDocumentRequest($input: SubmitInformationToDocumentRequestInput) {
    submitInformationToDocumentRequest(input: $input) {
      success
    }
  }
`;
