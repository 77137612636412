import { Button } from '@frontend/cherry-library';
import { DashCard, DashComponent, DownloadIcon } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

export const WebInstructions = ({ title }) => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();

  const pdfUrl = `https://files.withcherry.com/partner/web-widget-self-serve-guide.pdf`;
  const handleDownloadClick = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Downloaded Website Guide', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
    });
    window.open(pdfUrl, '_blank');
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard transparent={'transparent'}>
        <Flex>
          <Flex>
            <GuideImage src={'/self-serve-web-preview.png'} />
          </Flex>
          <Flex direction={true}>
            <Row style={{ fontSize: '14px', lineHeight: '19px' }}>Use this guide to add Cherry to your website.</Row>
            <GetGuideButton onClick={handleDownloadClick} data-testid="downloadWebInstructionsButton">
              Download &nbsp;
              <DownloadIcon color={'#FFFFFF'} />
            </GetGuideButton>
          </Flex>
        </Flex>
      </DashCard>
    </Column>
  );
};

const GuideImage = styled.img`
  width: 208px;
  height: 260px;
  box-shadow: 0 6px 9px rgb(0 0 0 / 1%);
  margin-right: 24px;
`;

const Flex = styled.div<{ direction?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? 'column' : 'row')};
`;

const GetGuideButton = styled(Button)`
  margin-top: 24px !important;
  width: 140px !important;
`;
