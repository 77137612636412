import { Grid } from '@mui/material';
import React from 'react';

import { FixedDecimalFormat } from 'lib/components';
import useAgent from 'lib/hooks/useAgent';
import { RefundStepEnums } from 'lib/types';
import { ApplyButton, CancelButton, Container, RefundContainer, RefundTitle } from './Common';
import { RefundTable } from './RefundTable';

const IssueRefundApprove = ({ refundApproveButtonHandler, item, refundType, amount, setActiveStep }) => {
  const { isAgent } = useAgent();
  const isFullRefund = refundType === 'full';
  const refundAmount = isFullRefund ? item?.amount : amount;

  const fullRefundHandler = () => {
    setActiveStep(RefundStepEnums.LOADING, { refundType, amount: refundAmount });
    refundApproveButtonHandler({ item, refundType, amount: refundAmount });
  };

  return (
    <Container>
      <RefundContainer secondary={true}>
        <RefundTitle secondary={true}>
          Are you sure you want to refund {item?.borrowerName} <FixedDecimalFormat amount={refundAmount} prefix={'$'} />
          ?
        </RefundTitle>
        <RefundTable item={item} refundAmount={refundAmount} />
        <Grid
          container={true}
          spacing={2}
          style={{
            width: '300px',
            marginLeft: '140px',
            marginBottom: '20px',
          }}
        >
          <Grid item={true} style={{ flex: 1 }}>
            <CancelButton
              data-testid="refund-approve-cancel-button"
              onClick={item?.toggleExpandable}
              data-tag="confirm-cancel"
            >
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} style={{ flex: 1 }}>
            <ApplyButton data-testid="refund-approve-apply-button" onClick={fullRefundHandler} disabled={isAgent}>
              Issue Refund
            </ApplyButton>
          </Grid>
        </Grid>
      </RefundContainer>
    </Container>
  );
};

export default IssueRefundApprove;
