import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box,
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  Icon,
  Subtitle,
  TextField,
  Title,
} from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import { useOrganizationSelection } from 'lib/services';
import { Organization } from 'lib/types';

const MobileOrganizationSelection = () => {
  const navigate = useNavigate();
  const { isSnackbarVisible } = useSnackbar();

  const {
    selectOrganization,
    organizations,
    onOrganizationSelect,
    loading,
    organizationId,
  } = useOrganizationSelection();

  const returnToLogin = () => {
    navigate('/login');
  };

  return (
    <Container pageTitle={'Practice Portal Login'}>
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Icon src={'cherry-logo'} />
        <Title m={'10px 0px 8px 0px'}>Welcome back!</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          Your account is associated one or more organizations. Please select the one you would like to access in this
          session.
        </Subtitle>
        <Box>
          <TextField
            style={{ textAlign: 'left' }}
            id="filled-basic"
            variant="filled"
            data-testid="industry"
            select={true}
            onChange={onOrganizationSelect}
            label="Select an organization"
          >
            {organizations?.map((option: Organization, index: number) => (
              <MenuItem
                data-testid="industryItem"
                key={index}
                value={option?.id}
                style={{ width: '100%', textAlign: 'left' }}
              >
                {option?.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={selectOrganization} disabled={!organizationId || loading} loading={loading}>
          Continue
        </Button>
        <ButtonText onClick={returnToLogin}>Return to Login</ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default MobileOrganizationSelection;

const ButtonText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
`;
