import { Dialog, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@frontend/cherry-library';
import dayjs from 'dayjs';
import client from 'lib/graphql/client';
import { GET_CUSTOMER_MEMBERSHIP_PLANS } from 'lib/graphql/queries';
import { MembershipPlans } from 'lib/graphql/searches/membershipSearch';
import { useMembershipSearch } from 'lib/hooks/useMembershipSearch';
import useStore from 'lib/hooks/useStore';
import { CsvDownloadEnums, CustomerPlansCSVItem } from 'lib/types';
import {
  arrayToCsv,
  customerPlansCsvDownloadParser,
  CustomerPlansCSVHeaders,
  downloadBlobByContentType,
  MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT,
} from 'lib/utils';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { useAlert } from 'react-alert';

export const MembershipCsvModal = ({ open, handleClose, totalTransactionCount, searchInput, searchKey }) => {
  const [activeStep, setActiveStep] = useState<CsvDownloadEnums>(CsvDownloadEnums.CONFIRM_DOWNLOAD);
  const [allTransactions, setAllTransactions] = useState<CustomerPlansCSVItem[]>([]);
  const { organization } = useStore();
  const alert = useAlert();
  const { mapCustomerPlanToTableData } = useMembershipSearch();

  useEffect(() => {
    if (totalTransactionCount < MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT) {
      setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
    } else {
      setActiveStep(CsvDownloadEnums.EXCEED_LIMIT);
    }
  }, [totalTransactionCount]);

  const moveDownload = async () => {
    setActiveStep(CsvDownloadEnums.LOADING);

    try {
      const { data: { fetchCustomerMembershipsPlans } = {} } = await client.query({
        query: GET_CUSTOMER_MEMBERSHIP_PLANS,
        variables: {
          input: {
            pagination: {
              limit: totalTransactionCount,
              offset: 0,
              order: 'ASC',
              orderBy: 'createdAt',
            },
            search: MembershipPlans(organization, searchInput, searchKey),
          },
        },
      });
      const transactionsCsvs = fetchCustomerMembershipsPlans?.contents || [];
      const mappedAllTransactions = mapCustomerPlanToTableData(transactionsCsvs);
      setAllTransactions(mappedAllTransactions);
      setActiveStep(CsvDownloadEnums.FILE_READY);
    } catch (e) {
      alert.error('An Error Occurred! Please try again.');
      setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
    }
  };

  const resetOnClose = () => {
    handleClose();
    setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
  };

  switch (activeStep) {
    case CsvDownloadEnums.CONFIRM_DOWNLOAD:
      return <ConfirmDownload handleClose={handleClose} moveDownload={moveDownload} open={open} />;
    case CsvDownloadEnums.FILE_READY:
      return <FileReady open={open} allTransactions={allTransactions} resetOnClose={resetOnClose} />;
    case CsvDownloadEnums.EXCEED_LIMIT:
      return <ExceededLimit resetOnClose={resetOnClose} open={open} totalTransactionCount={totalTransactionCount} />;
    case CsvDownloadEnums.LOADING:
      return <Loading open={open} resetOnClose={resetOnClose} />;
  }
};

const FileReady = ({ resetOnClose, open, allTransactions }) => {
  const fileName = `cherry_customer_plans${dayjs().format('DDMMYYYY')}.csv`;

  const downloadCSV = () => {
    let csv = null;
    const parsedCustomerPlans: any[] = allTransactions?.map((o) => customerPlansCsvDownloadParser(o) || []);

    csv = arrayToCsv([CustomerPlansCSVHeaders, ...parsedCustomerPlans]);

    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
    resetOnClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={resetOnClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Customer Plans
        </CherryDialogTitle>
        <SubTitle>Your file is ready!</SubTitle>
        <Link onClick={downloadCSV}>{fileName}</Link>

        <Grid container={true} spacing={1}>
          <Button fullWidth={true} disabled={false} onClick={downloadCSV}>
            Download File
          </Button>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const ConfirmDownload = ({ moveDownload, handleClose, open }) => {
  return (
    <Dialog maxWidth="sm" aria-labelledby="customized-dialog-title" open={open} onClose={handleClose}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Download Customer Plans
        </CherryDialogTitle>
        <SubTitle>We'll prepare a CSV file for customer plans.</SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <CancelButton disabled={false} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} xs={6}>
            <RemoveButton disabled={false} onClick={moveDownload}>
              Prepare File
            </RemoveButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const ExceededLimit = ({ open, resetOnClose, totalTransactionCount }) => {
  return (
    <Dialog maxWidth="sm" onClose={resetOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Limit Exceeded
        </CherryDialogTitle>
        <SubTitle>
          <b>{totalTransactionCount}</b> transactions are included in this request.
          <br />
          <b> The limit is {MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT}.</b> Please select smaller time frame.
        </SubTitle>
        <Grid container={true} spacing={1}>
          <RemoveButton disabled={false} onClick={resetOnClose}>
            Okay
          </RemoveButton>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const Loading = ({ open, resetOnClose }) => {
  return (
    <Dialog maxWidth="sm" onClose={resetOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Customer Plans
        </CherryDialogTitle>
        <CenterFlex>
          <Stack> Preparing your file...</Stack>
          <LinearProgress color="primary" />
        </CenterFlex>
      </DialogContainer>
    </Dialog>
  );
};

const RemoveButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;

const CancelButton = styled(BackButton)`
  border-color: 1px solid ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.green};
`;

const Link = styled.a`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: center;
  margin: auto;
`;

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  flex-direction: column;

  div {
    padding-top: 4px;
    border-radius: 8px;
    width: 100%;
  }

  .MuiLinearProgress-indeterminate {
    background-color: #dadada;
  }
  .MuiLinearProgress-barColorPrimary,
  .MuiLinearProgress-bar1Indeterminate {
    background-color: ${(props) => props.theme.main.green};
  }
`;

const Stack = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;
