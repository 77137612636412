import React, { useState } from 'react';

import styled from 'styled-components';

import { formatDate, formatDateWithType, formatPhoneNumber, getExpiresText } from 'lib/utils';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FixedDecimalFormat } from 'lib/components';
import { Approved } from 'lib/components/ApplicationStatutes';
import AppointmentPopOver from 'lib/components/AppointmentTableComponents/AppointmentPopover';
import { SendButtons } from 'lib/components/AppointmentTableComponents/SendButtons';
import { Appointment_Events, SCHEDULE_VIEW_STATUS } from 'lib/constants';
import client from 'lib/graphql/client';
import { SEND_APPLICATION_LINK, SYNC_APPOINTMENTS } from 'lib/graphql/mutations';
import { GET_APPLICATION_LATEST_REMINDER } from 'lib/graphql/queries';
import { FETCH_APPOINTMENT_LIST } from 'lib/graphql/queries/Appointments';
import { FetchAppointmentListSearch } from 'lib/graphql/searches/fetchAppointmentListSearch';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import { usePagination } from 'lib/hooks/usePagination';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Appointment, Borrower, GroupedAppointments } from 'lib/types';
import { OutboundMessages } from 'lib/types/Merchant';
import { groupedByCategory } from 'lib/utils/GroupByCategory';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);
interface FailModal {
  failModalOpen: boolean;
  failModalInvalid: boolean;
}

export interface AppointmentFilterProp {
  dateFilter?: { from: string; to: string };
  selectedStatus?: { label: string; value: string }[];
  selectedLocation?: { label: string; value: string }[];
  offset: number;
}
interface SyncStatus {
  syncedAt: string;
  syncedBy: string;
}
const DEFAULT_FILTER = {
  selectedLocation: undefined,
  selectedStatus: undefined,
  dateFilter: {
    from: dayjs().toDate().toISOString(),
    to: dayjs().day(7).toDate().toISOString(),
  },
  offset: 0,
};
export const useAppointmentList = () => {
  const { trackSegmentEvent, applicationName } = useSegment();

  const { locations, organization, user, appointmentDate } = useStore();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState<Appointment>();
  const [expandedRow, setExpandedRow] = useState<Appointment | null>();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [openResponseModal, setOpenResponseModal] = useState<boolean>(false);
  const [failModal, setFailModal] = useState<FailModal>({ failModalOpen: false, failModalInvalid: false });
  const [syncAppointments, { loading: syncStatusLoading }] = useMutation(SYNC_APPOINTMENTS);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>({ syncedAt: '', syncedBy: '' });
  const [selectedFilters, setSelectedFilters] = useState<AppointmentFilterProp>(DEFAULT_FILTER);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [groupedAppointments, setGroupedAppointments] = useState<GroupedAppointments[]>();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState<number>(0);
  const { rowsPerPage, setRowsPerPage, setPage } = usePagination({});
  const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState(false);

  const [isModalSendReminder, setisModalSendReminder] = useState<boolean>(false);
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const [textReminderData, setTextReminderData] = useState<{
    message: string;
    applicationId: number;
    outboundMessages: OutboundMessages[];
    borrower: Borrower;
  } | null>(null);
  const permission = usePermission();
  const alert = useAlert();
  const filterDeselect = selectedFilters.selectedStatus?.length === 0 || selectedFilters.selectedLocation?.length === 0;
  const closeTextReminderModal = () => {
    setTextReminderData(null);
    setisModalSendReminder(false);
  };

  const refreshAppointments = async () => {
    try {
      const { data } = await syncAppointments({
        variables: {
          input: { organizationId: organization?.id, startAt: appointmentDate?.startAt, endAt: appointmentDate?.endAt },
        },
      });
      setSyncStatus(data?.syncAppointments);
    } catch (e) {
      alert.error('An error occurred.');
    }
  };
  const searchWithKey = (searchKey) => {
    if (searchKey && /^\(\d{3}/.test(searchKey)) {
      return searchKey?.replace(/[\(\)\s\-]/g, '')?.trim();
    }
    return searchKey;
  };
  const getAppointments = async ({
    limit,
    offset,
    searchKey,
  }: {
    limit?: number;
    offset?: number;
    searchKey?: string;
  }) => {
    try {
      setLoading(true);
      if (filterDeselect) {
        setGroupedAppointments(
          groupedByCategory(
            'startAt',
            [],
            false,
            groupedAppointments || [],
            selectedFilters.dateFilter?.from,
            selectedFilters.dateFilter?.to,
          ),
        );
        setAppointments([]);
        setLoading(false);
      } else {
        const { data } = await client.query({
          query: FETCH_APPOINTMENT_LIST,
          variables: {
            input: {
              pagination: {
                limit: 100,
                offset,
                order: 'ASC',
                orderBy: 'startAt',
              },
              search: FetchAppointmentListSearch(searchKey, selectedFilters, organization?.id),
              ...(searchKey && { searchKey: searchWithKey(searchKey) }),
            },
          },
        });
        if (data) {
          const response = data?.fetchAppointmentList;
          setTotal(response?.total);
          const mappedList = response?.contents || [];
          const appointmentsArray = offset === 0 ? mappedList : [...mappedList, ...appointments];
          const loadMore = appointmentsArray.length < response?.total;
          setGroupedAppointments(
            groupedByCategory(
              'startAt',
              appointmentsArray,
              loadMore,
              groupedAppointments || [],
              selectedFilters.dateFilter?.from,
              selectedFilters.dateFilter?.to,
            ),
          );
          setAppointments(appointmentsArray || []);
          setLoading(false);
          return response?.contents || [];
        }
      }
    } catch (e) {
      alert.error('An error occurred fetching appointment. ');
    }
  };

  const getButtons = (row) => {
    const status = getStatusNames(row);
    if (status === SCHEDULE_VIEW_STATUS.NOT_STARTED || status === SCHEDULE_VIEW_STATUS.EXPIRED) {
      return <SendButtons row={row} handleClick={handleOpenModal} isSendReminder={false} />;
    } else if (status === SCHEDULE_VIEW_STATUS.IN_PROGRESS) {
      return <SendButtons row={row} handleClick={handleOpenModal} isSendReminder={true} />;
    } else if (status === SCHEDULE_VIEW_STATUS.APPROVED) {
      const checkoutAgain = row.application?.activeLoans?.length > 0;
      const editPlan = row.application?.purchaseAmount && row.application?.selfCheckout;

      return permission(FeatureNames.CHECKOUT, ScreenNames.DASHBOARD) ? (
        <Approved
          row={row}
          appointmentPage={true}
          handleClick={handleClick}
          checkoutAgain={checkoutAgain}
          editPlan={editPlan}
        />
      ) : null;
    }
  };

  const openCheckoutModal = () => {
    setIsCheckoutModalVisible(true);
  };

  const handleClick = (row: Appointment) => {
    trackSegmentEvent(Appointment_Events.INITIATED_CHECKOUT, {
      application: applicationName,
      component: Appointment_Events.COMPONENT,
      userId: user?.id ? `u-${user?.id}` : undefined,
      targetPhone: row?.customer?.phone,
    });
    openCheckoutModal();
    setSelectedRow(row);
    // setExpandedRow(row);
    return row;
  };
  const cancelClicked = () => {
    setModalOpen(false);
    setOpenResponseModal(false);
  };
  const sendReminder = () => {
    setisModalSendReminder(true);
  };
  const sendApplication = async () => {
    setModalOpen(false);
    const idOrganization = selectedRow?.organizationId;
    const phone = selectedRow?.customer?.phone;
    if (phone?.length !== 10) {
      setOpenResponseModal(true);
      setFailModal({ failModalOpen: true, failModalInvalid: true });
    } else {
      const { data } = (await sendOrganizationLink({ variables: { input: { idOrganization, phone } } })) || {};

      if (data?.sendOrganizationLink?.success) {
        setOpenResponseModal(true);
        setFailModal({ ...failModal, failModalOpen: false });
      }

      trackSegmentEvent(Appointment_Events.SENT_APPLICATION, {
        application: applicationName,
        component: Appointment_Events.COMPONENT,
        userId: user?.id ? `u-${user?.id}` : undefined,
        was_success: data?.sendOrganizationLink?.success,
        is_existing_borrower: selectedRow?.applicationId ? true : false,
        ...(selectedRow?.application?.borrowerId && { borrowerId: selectedRow?.application?.borrowerId }),
        targetPhone: phone,
      });
    }
  };
  const getPreviewApplicationReview = async (approval) => {
    setTextReminderData({
      message: 'loading',
      applicationId: approval.id,
      outboundMessages: approval.outboundMessages,
      borrower: approval.customer,
    });
    const { data } = await client.query({
      query: GET_APPLICATION_LATEST_REMINDER,
      variables: {
        input: {
          applicationId: approval.id,
        },
      },
    });
    if (data?.getApplicationLatestReminder?.message) {
      setTextReminderData({
        message: data.getApplicationLatestReminder.message,
        applicationId: approval.id,
        outboundMessages: approval?.outboundMessages,
        borrower: approval.borrower,
      });
    }
  };

  const handleOpenModal = (row, isSendReminder) => {
    const phone = row?.customer?.phone;

    const eventName = isSendReminder ? Appointment_Events.INITIATED_REMINDER : Appointment_Events.INITIATED_APPLICATION;
    trackSegmentEvent(eventName, {
      application: applicationName,
      component: Appointment_Events.COMPONENT,
      userId: user?.id ? `u-${user?.id}` : undefined,
      targetPhone: phone,
    });
    setExpandedRow(null);
    setSelectedRow(row);
    if (!phone) {
      setOpenResponseModal(true);
      setFailModal({ failModalOpen: true, failModalInvalid: false });
    } else {
      setModalOpen(!isSendReminder);
      setisModalSendReminder(isSendReminder);
      if (isSendReminder) {
        onChangeReminderChange(row);
      }
    }
  };

  const onChangeReminderChange = async (row) => {
    await getPreviewApplicationReview(row?.application);
  };
  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
    getAppointments({ limit: rowsPerPage, offset: pageNumber - 1 });
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
    getAppointments({ limit: rowsPerPage || perPage, offset: pageNumber - 1 });
  };
  const navigateTransaction = (item) => () => {
    navigate(`/transactions/${item?.displayId}`);
  };

  const getStatusNames = (row) => {
    const dateDiff = dayjs(dayjs(row?.application?.expireAt).startOf('day')).diff(dayjs().startOf('day'), 'day');
    if (row.applicationId === null) {
      return SCHEDULE_VIEW_STATUS.NOT_STARTED;
    } else if (
      row.application?.status !== 'APPROVED' &&
      row.application?.status !== 'DENIED' &&
      row.application?.validity === 'ACTIVE' &&
      dateDiff > 0
    ) {
      return SCHEDULE_VIEW_STATUS.IN_PROGRESS;
    } else if (row.application?.status === 'APPROVED' && row.application?.validity === 'ACTIVE' && dateDiff > 0) {
      return SCHEDULE_VIEW_STATUS.APPROVED;
    } else if (
      (row.application?.status === 'APPROVED' || row.application?.status === 'DENIED') &&
      row.application?.validity === 'ACTIVE' &&
      dateDiff < 0
    ) {
      return SCHEDULE_VIEW_STATUS.EXPIRED;
    }
  };

  const getStatus = (row) => {
    const status = getStatusNames(row);
    const dateDiff = dayjs(dayjs(row?.application?.expireAt).startOf('day')).diff(dayjs().startOf('day'), 'day');
    if (row.applicationId === null) {
      return (
        <StatusContainer>
          <StatusText>{SCHEDULE_VIEW_STATUS.NOT_STARTED}</StatusText>
          <AppointmentPopOver iconName={'info_circle_outline'} row={row} m={'0 0 0 5px'} ternary={true} width={'405px'}>
            <Text>Patient hasn’t started an application. Send them an application link.</Text>
          </AppointmentPopOver>
        </StatusContainer>
      );
    } else if (status === SCHEDULE_VIEW_STATUS.IN_PROGRESS) {
      return <Text>{status}</Text>;
    } else if (status === SCHEDULE_VIEW_STATUS.APPROVED) {
      const pending = row.application?.purchaseAmount && row.application?.selfCheckout;

      return (
        <StatusContainer>
          <BalanceText>
            {pending ? null : status}
            <FixedDecimalFormat data-tag="allowRowEvents" amount={row.application?.balanceAvailable} prefix={'$'} />
            <SmallText>{pending ? SCHEDULE_VIEW_STATUS.PENDING : getExpiresText(dateDiff)}</SmallText>
          </BalanceText>
          {row?.application?.activeLoans?.length > 0 && (
            <AppointmentPopOver iconName={'receipt'} row={row} m={'9px 0 0 15px'} ternary={false} width={'140px'}>
              <TransactionContainer>
                <TransactionText> Transactions</TransactionText>
                {row?.application?.activeLoans?.map((item) => {
                  return (
                    <Transaction key={item.displayId} onClick={navigateTransaction(item)}>
                      {`$${item.purchaseAmount}    ${formatDateWithType(item.fundedAt, 'MMM D, YYYY')}`}
                    </Transaction>
                  );
                })}
              </TransactionContainer>
            </AppointmentPopOver>
          )}
        </StatusContainer>
      );
    } else if (status === SCHEDULE_VIEW_STATUS.EXPIRED) {
      return (
        <StatusContainer>
          <StatusText>{SCHEDULE_VIEW_STATUS.EXPIRED}</StatusText>
          <AppointmentPopOver iconName={'info_circle_outline'} row={row} m={'0 0 0 5px'} ternary={true} width={'365px'}>
            <Text>The patient's application expired. Send another application.</Text>
          </AppointmentPopOver>
        </StatusContainer>
      );
    }
  };

  const getMerchantName = (row) => {
    const findMerchant = locations?.find((m) => m.id === row.merchantId);

    return findMerchant?.name || '';
  };

  const columns = [
    {
      name: 'Appointment',
      selector: 'startAt',
      sortable: false,
      minWidth: '120px',
      cell: (row) => (
        <TextContainer>
          <Text>
            {formatDateWithType(row.startAt, 'h:mm A')} - {formatDateWithType(row.endAt, 'h:mm A')}{' '}
          </Text>
          <SmallText>
            {' '}
            {formatDateWithType(row.startAt, 'dddd')} {formatDate(row.startAt)}
          </SmallText>
        </TextContainer>
      ),
      ignoreRowClick: true,
    },
    {
      name: 'Patient',
      selector: 'createdAt',
      sortable: false,
      cell: (row) => {
        const { firstName, lastName } = row.customer;
        if (firstName && lastName) {
          return (
            <PatientTextContainer>
              <Text>
                {row.customer?.firstName} {row.customer?.lastName}
              </Text>
              {row.customer?.phone && <SmallText>{formatPhoneNumber(row.customer?.phone)}</SmallText>}
            </PatientTextContainer>
          );
        } else {
          return (
            <PatientTextContainer>
              <Text>No Patient Information</Text>
            </PatientTextContainer>
          );
        }
      },

      ignoreRowClick: true,
    },
    // @ts-ignore
    locations?.length > 1
      ? {
          name: 'Location',
          selector: 'location',
          sortable: false,
          cell: (row) => <LocationText>{getMerchantName(row)}</LocationText>,
          ignoreRowClick: true,
        }
      : { width: '0px' },
    {
      name: 'Cherry Status',
      selector: 'balanceAvailable',
      sortable: false,
      cell: (row) => getStatus(row),
      ignoreRowClick: true,
    },

    {
      name: '',
      selector: 'amount',
      right: true,
      left: false,
      width: '221px',
      cell: (row) => getButtons(row),
      ignoreRowClick: true,
    },
  ];

  return {
    columns,
    handleClick,
    selectedRow,
    isModalOpen,
    cancelClicked,
    sendReminder,
    isModalSendReminder,
    sendApplication,
    openResponseModal,
    setOpenResponseModal,
    failModal,
    setFailModal,
    textReminderData,
    closeTextReminderModal,
    syncStatus,
    refreshAppointments,
    syncStatusLoading,
    selectedFilters,
    setSelectedFilters,
    getAppointments,
    appointments,
    loading,
    total,
    pageChange,
    rowsCountChange,
    groupedAppointments,
    setGroupedAppointments,
    expandedRow,
    isCheckoutModalVisible,
    setIsCheckoutModalVisible,
  };
};

const Text = styled.p`
  margin: 0;
  line-height: 16.34px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;
const LocationText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
`;

const TextContainer = styled.div`
  padding: 15px 8px;
  margin: 4px 0;
`;
const PatientTextContainer = styled.div`
  padding: 0;
`;

const TransactionText = styled.div`
  font-weight: 700;
  display: flex;
  white-space: nowrap;
`;
const TransactionContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`;

const StatusContainer = styled.div`
  display: flex;
`;
const StatusText = styled.div`
  display: flex;
`;
const BalanceText = styled.div`
  white-space: nowrap;
`;

const Transaction = styled.div`
  display: flex;
  text-decoration: underline;
  cursor: pointer;
`;
