import { ButtonPrimary, LocationPinIcon, WarningOutlineIcon } from 'lib/components';
import { EditIcon } from 'lib/components/mobile/SidebarIcons';
import useAgent from 'lib/hooks/useAgent';
import { MerchantGroup } from 'lib/types';
import { maskAccountNumber } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

enum ACCOUNT_STATUS {
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  REVIEW = 'REVIEW',
}

export const useBankInformationTableColumns = ({ handleSelected }) => {
  const { isAgent } = useAgent();

  const getMerchantsFromTree = (tree: MerchantGroup) => {
    let result: MerchantGroup[] = [];
    if (tree.type === 'MERCHANT') {
      result = [...result, tree];
    }
    if (tree.childs.length) {
      tree.childs.forEach((child) => {
        result = [...result, ...getMerchantsFromTree(child)];
      });
    }
    return result;
  };

  const getAccountData = (row) => (
    <SmallText>
      {row?.bankingInfo?.accountNickname && <BigText>{row?.bankingInfo?.accountNickname}</BigText>}
      {row?.bankingInfo?.accountStatus === ACCOUNT_STATUS.ERROR ? (
        <WarningText>Banking Issue</WarningText>
      ) : (
        <>
          <div>{row?.bankingInfo?.accountName ?? ''}</div>
          <div>{maskAccountNumber(row?.bankingInfo?.accountNumber ?? '', '*')}</div>
        </>
      )}
    </SmallText>
  );

  const getAccountStatusIcon = (row) => {
    if (
      row?.bankingInfo.accountStatus === ACCOUNT_STATUS.ERROR ||
      row?.bankingInfo.accountStatus === ACCOUNT_STATUS.FAILED
    ) {
      return <WarningOutlineIcon />;
    }
    return <LocationPinIcon />;
  };

  const handleClick = (row) => () => {
    handleSelected(row);
  };

  const getStatusAction = (row) => {
    switch (row?.bankingInfo?.accountStatus) {
      case ACCOUNT_STATUS.REVIEW:
        return 'In Review';
      case ACCOUNT_STATUS.SUCCESS:
      case null:
        return <StyledEditIcon data-id={row?.id} onClick={handleClick(row)} />;
      case ACCOUNT_STATUS.FAILED:
      case ACCOUNT_STATUS.ERROR:
        return (
          <ButtonPrimary
            data-testid={`fixIssueButton-${row?.id}`}
            text="Fix Issue"
            data-id={row?.id}
            // @ts-ignore
            onClick={handleClick(row)}
            disabled={isAgent}
          />
        );
      default:
        break;
    }
  };

  const tableColumns = [
    {
      name: '',
      selector: (row) => row.account,
      cell: getAccountStatusIcon,
      width: '50px',
    },
    {
      name: 'Location',
      selector: (row) => row.name,
      cell: (row) => (
        <BoldBigText>
          {row.name}
          {row.tree &&
            getMerchantsFromTree(row.tree).map((merchant) => (
              <SmallText key={merchant.name}>{merchant.name}</SmallText>
            ))}
        </BoldBigText>
      ),
      minWidth: '350px',
      ignoreRowClick: true,
    },
    {
      name: 'Banking Info',
      selector: (row) => row.account,
      cell: (row) => getAccountData(row),
      minWidth: '180px',
      style: {
        alignItems: 'start',
      },
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: getStatusAction,
      right: true,
    },
  ];

  return { tableColumns };
};

const StyledEditIcon = styled(EditIcon)`
  float: right;
  cursor: pointer;
`;

const BoldBigText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
`;

const BigText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
`;

const SmallText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
`;

const WarningText = styled.div`
  color: #ec3360;
`;
