import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { MAX_ALLOWED_REFUND_AMOUNT, MIN_ALLOWED_REFUND_AMOUNT } from 'lib/constants';
import { CANCEL_REFUND_LOAN, PARTIAL_REFUND_LOAN, REFUND_LOAN } from 'lib/graphql/mutations/Refund';
import { useMerchantLogin, useSentry } from 'lib/hooks';
import { RefundStepEnums } from 'lib/types';
import { browserIsMobile, MIN_REFUND_AMOUNT } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
interface UsePartialRefundParams {
  transaction?: any;
  setActiveStep?: (type: string, p: { refundType: string; amount: number }) => void;
}

enum StatusVariablesEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SENT = 'SENT',
  HOLD = 'HOLD',
  FUNDED = 'FUNDED',
}

enum DirectionType {
  MERCHANT_CREDIT = 'MERCHANT_CREDIT',
  MERCHANT_DEBIT = 'MERCHANT_DEBIT',
}

export const usePartialRefund = ({ transaction, setActiveStep }: UsePartialRefundParams) => {
  const [refundLoan] = useMutation(REFUND_LOAN);
  const [partialRefundLoan] = useMutation(PARTIAL_REFUND_LOAN);
  const [cancelRefundLoan] = useMutation(CANCEL_REFUND_LOAN);
  const alert = useAlert();
  const { captureException } = useSentry();
  const { merchantLogin, merchantLogout } = useMerchantLogin();
  const navigate = useNavigate();

  const [amount, setAmount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [validPurchaseAmountRange, setValidPurchaseAmountRange] = useState<boolean>(false);
  const [purchaseAmountErrorText, setPurchaseAmountErrorText] = useState<string>('');

  const { servicingBalance } = transaction || {};

  const refundButtonHandler = () => {
    setActiveStep && setActiveStep(RefundStepEnums.ISSUE_REFUND_APPROVE, { refundType: 'partial', amount });
  };

  const onPurchaseAmountChange = (nativeEvent: any) => {
    try {
      const value: any = browserIsMobile() ? CurrencyUtil.rawValue(nativeEvent) : nativeEvent;
      const isPurchaseAmountValid = CurrencyUtil.valueValidator(value, MIN_REFUND_AMOUNT, servicingBalance);
      if (isPurchaseAmountValid) {
        setValidPurchaseAmountRange(true);
        setPurchaseAmountErrorText('');
        value && setAmount(parseFloat(value));
      } else {
        setValidPurchaseAmountRange(false);
        setPurchaseAmountErrorText(
          `Refund amount must be between $${MIN_REFUND_AMOUNT} - ${CurrencyUtil.toCurrencyString(
            servicingBalance,
            true,
          )}.`,
        );
      }
    } catch (error) {
      captureException(error, { message: 'Amount input convert wrongly' });
    }
  };

  const checkRefundEligibility = (row) => {
    const { fund } = row;
    const merchantDebitFunds = fund?.filter((f) => f.directionType === DirectionType.MERCHANT_DEBIT);
    const isFundNotHolded = fund?.[0]?.status !== StatusVariablesEnum.HOLD;
    const findCompletedFund = merchantDebitFunds?.[0]?.status === StatusVariablesEnum.COMPLETED;
    const findCancelledFund = merchantDebitFunds?.[0]?.status === StatusVariablesEnum.CANCELLED;
    const findFundProcessing =
      merchantDebitFunds?.[0]?.status !== StatusVariablesEnum.COMPLETED &&
      merchantDebitFunds?.[0]?.status !== StatusVariablesEnum.CANCELLED;
    const initialRefundProcessing = merchantDebitFunds?.[0]?.status === StatusVariablesEnum.APPROVED;

    const isFundProcessing =
      merchantDebitFunds?.length > 0 && !findCompletedFund && !findCancelledFund; /*|| findFundProcessing */

    const initialFundProcessing =
      fund?.length === 1 &&
      fund?.[0]?.directionType === DirectionType.MERCHANT_CREDIT &&
      fund?.[0]?.status !== StatusVariablesEnum.COMPLETED &&
      [
        StatusVariablesEnum.PROCESSING,
        StatusVariablesEnum.SENT,
        StatusVariablesEnum.APPROVED,
        StatusVariablesEnum.FAILED,
      ].includes(fund?.[0]?.status);

    return {
      merchantDebitFunds,
      findCompletedFund,
      findCancelledFund,
      findFundProcessing,
      initialRefundProcessing,
      isFundProcessing,
      initialFundProcessing,
      isFundNotHolded,
    };
  };

  const onPurchaseAmountBlurred = (nativeEvent: any) => {
    const value = nativeEvent;
    const isAmountValid = CurrencyUtil.valueValidator(value, MIN_ALLOWED_REFUND_AMOUNT, MAX_ALLOWED_REFUND_AMOUNT);
    if (isAmountValid) setAmount(parseFloat(value));
  };

  const goToFullRefund = () => {
    setActiveStep && setActiveStep(RefundStepEnums.ISSUE_REFUND_APPROVE, { refundType: 'full', amount });
  };

  const refundFullyLoan = async (data) => {
    await merchantLogin(data?.item?.merchantId);
    const { data: refundLoanData } = await refundLoan({
      variables: {
        input: {
          loanId: data.item.id,
        },
      },
    });

    merchantLogout();

    return refundLoanData;
  };

  const refundPartiallyLoan = async (data) => {
    await merchantLogin(data?.item?.merchantId);

    const { data: partialRefundLoanData } = await partialRefundLoan({
      variables: {
        input: {
          loanId: data.item.id,
          amount: Number(data.amount),
        },
      },
    });

    merchantLogout();

    return partialRefundLoanData;
  };

  const refundCancelLoan = async (data) => {
    try {
      await merchantLogin(data?.merchantId);
      const { data: cancelRefundLoanData } = await cancelRefundLoan({
        variables: {
          input: {
            loanId: data.fund?.[0]?.id,
            status: 'CANCELLED',
          },
        },
      });
      merchantLogout();

      return cancelRefundLoanData;
    } catch (err) {
      alert.error('Some error occurred');
    }
  };

  const partiallyRefundLoan = async () => {
    try {
      setLoading(true);
      const partialRefundLoanData = await refundPartiallyLoan({
        item: transaction,
        amount: transaction?.partialRefundAmount,
      });
      setLoading(false);
      if (partialRefundLoanData?.partialRefundLoan?.success) {
        navigate(`/transactions/detail/partial-refund/success`);
      } else {
        alert.error('Some error occurred');
      }
    } catch (err) {
      setLoading(false);
      alert.error('Some error occurred');
    }
  };

  const fullyRefundLoan = async () => {
    try {
      setLoading(true);
      const refundLoanData = await refundFullyLoan({ item: transaction, amount: transaction?.purchaseAmount });
      setLoading(false);
      if (refundLoanData?.refundLoan?.success) {
        navigate(`/transactions/detail/full-refund/success`);
      } else {
        alert.error('Some error occurred');
      }
    } catch (err) {
      setLoading(false);
      alert.error('Some error occurred');
    }
  };

  const chechRefundStatus = (transactionObj) => {
    const {
      plans,
      fundStatus,
      status,
      subStatus,
      fund,
      fundedAt,
      servicingBalance: transactionServicingBalance,
    } = transactionObj;
    const refundAllowBalance = plans?.[0]?.balance;
    const partialRefundDisabled: boolean =
      !(fundStatus !== StatusVariablesEnum.COMPLETED || fundStatus !== StatusVariablesEnum.HOLD) ||
      transactionServicingBalance < MIN_REFUND_AMOUNT;
    const refundAllowed: boolean =
      (status === StatusVariablesEnum.FUNDED &&
        subStatus === StatusVariablesEnum.COMPLETED &&
        fund?.[0]?.status !== StatusVariablesEnum.HOLD) ||
      (fund?.length === 1 &&
        fund?.[0]?.directionType === DirectionType.MERCHANT_CREDIT &&
        fund?.[0]?.status !== StatusVariablesEnum.COMPLETED);
    const cancelAllowed: boolean = fund?.[0]?.status === StatusVariablesEnum.HOLD;
    const refundAllowedDay = dayjs().diff(fundedAt, 'day');
    return {
      refundAllowBalance,
      partialRefundDisabled,
      refundAllowed,
      cancelAllowed,
      refundAllowedDay,
    };
  };

  return {
    checkRefundEligibility,
    goToFullRefund,
    onPurchaseAmountBlurred,
    onPurchaseAmountChange,
    validPurchaseAmountRange,
    servicingBalance,
    refundButtonHandler,
    purchaseAmountErrorText,
    amount,
    loading,
    refundPartiallyLoan,
    refundCancelLoan,
    refundFullyLoan,
    partiallyRefundLoan,
    fullyRefundLoan,
    chechRefundStatus,
  };
};
