import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AttentionCircleOutlineIcon, PrimaryButton } from 'lib/components';
import { formatDateWithTime } from 'lib/utils';

interface Props {
  notificationInformation: Notification;
  setAsRead: (data: Notification) => void;
  setAsClicked: (data: Notification) => void;
}

interface Statistics {
  hasRead: boolean;
  hasSeen: boolean;
  hasClicked: boolean;
}

interface Redirection {
  label: string;
  url: string;
  isOpenNewTab: boolean;
}

interface Notification {
  id: string;
  title: string;
  content: string;
  isImportantBadge: boolean;
  status: string;
  sentAt: string;
  statistics: Statistics;
  redirection?: Redirection;
}

interface TitleProps {
  hasRead: boolean;
}

export const NotificationItem = ({ notificationInformation, setAsRead, setAsClicked }: Props) => {
  const [notification, setNotification] = useState<Notification>(notificationInformation);
  const [collapsed, setCollapsed] = useState(false);

  const onNotificationClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    const { statistics } = notification || {};

    e.preventDefault();
    e.stopPropagation();
    if (!statistics.hasRead && setAsRead) {
      setAsRead(notificationInformation);
      setNotification({
        ...notification,
        statistics: {
          ...statistics,
          hasRead: true,
        },
      });
    }
    setCollapsed(!collapsed);
  };

  const onButtonClickHandler = () => {
    const { statistics, redirection } = notification || {};
    const redirectType = redirection?.isOpenNewTab ? '_blank' : '';

    if (!statistics.hasClicked && setAsClicked) {
      setAsClicked(notificationInformation);
      setNotification({
        ...notification,
        statistics: {
          ...statistics,
          hasClicked: true,
        },
      });
    }
    window.open(redirection?.url, redirectType);
  };

  return (
    <>
      <NotificationContainer>
        <NotificationHeaderContainer onClick={onNotificationClicked}>
          <div>
            <NotificationTitle hasRead={notification?.statistics?.hasRead}>{notification?.title}</NotificationTitle>
            <NotificationSubTitle>
              {!notification?.statistics?.hasRead && <NotificationStatus />}
              <span>{formatDateWithTime(notification?.sentAt || '')}</span>
            </NotificationSubTitle>
          </div>
          <NotificationIconContainter>
            {notification?.isImportantBadge && (
              <NotificationImportantIcon>
                <AttentionCircleOutlineIcon fill={!notification?.statistics?.hasRead ? '#C91D1D' : undefined} />
              </NotificationImportantIcon>
            )}
            <NotificationCollapseButton icon={collapsed ? faAngleUp : faAngleDown} />
          </NotificationIconContainter>
        </NotificationHeaderContainer>
        <Collapse in={collapsed} timeout={5} unmountOnExit={true} key={notification?.id}>
          <NotificationDescription>{notification?.content}</NotificationDescription>
          {notification?.redirection?.label && (
            <NotificationButtonContainer>
              <NotificationButton onClick={onButtonClickHandler}>{notification?.redirection?.label}</NotificationButton>
            </NotificationButtonContainer>
          )}
        </Collapse>
      </NotificationContainer>
      <NotificationBottomBorder />
    </>
  );
};

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
  padding: 16px 16px 16px 16px;
`;

const NotificationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const NotificationTitle = styled.div<TitleProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.hasRead ? '#879097' : '#0e202f')};
`;

const NotificationStatus = styled.div`
  height: 8px;
  width: 8px;
  background-color: #00c37d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

const NotificationSubTitle = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #879097;
  display: flex;
  align-items: center;
`;

const NotificationImportantIcon = styled.div`
  margin-right: 10px;
`;

const NotificationDescription = styled.div`
  max-width: 300px;
  margin-top: 8px;
  font-weight: 400;
  color: '#DEDE';
  font-size: 12px;
  line-height: 14px;
  color: #0e202f;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const NotificationCollapseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const NotificationButtonContainer = styled.div`
  margin-top: 16px;
`;

const NotificationButton = styled(PrimaryButton)`
  width: fit-content;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
`;

const NotificationBottomBorder = styled.div`
  border-bottom: 1px solid #dadada;
  margin: 0px 8px;
`;

const NotificationIconContainter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 10%;
`;
