import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary } from 'lib/components';
import {
  CREATE_CUSTOMER_MEMBERSHIP_PLAN,
  CREATE_MEMBERSHIP_CUSTOMER,
  CREATE_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD,
} from 'lib/graphql/mutations';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Column, Container } from 'lib/styles';
import { ContactInfoForm, MembershipInfoForm, PaymentInfoForm } from 'pages/desktop/Membership/components/MemberDetail';
import { ContactInfo, MemberShipInfo, PaymentInfo } from 'pages/desktop/Membership/type';
import { useAlert } from 'react-alert';
import { CreateMemberSuccessModal } from './CreateMemberSuccessModal';

const initialContactData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

export const initialMembershipData = {
  planType: '',
  paymentSchedule: '',
};

export const initialPaymentData = {
  payment: {
    card: {
      exp_year: undefined,
      last4: '',
      brand: '',
      exp_month: undefined,
    },
    type: '',
    id: '',
  },
  address: {
    name: '',
    address: {
      postal_code: '',
      city: '',
      line1: '',
      line2: '',
      state: '',
    },
  },
};

export const CreateMemberInfo = () => {
  const navigate = useNavigate();
  const { organization } = useStore();
  const alert = useAlert();
  const { isSnackbarVisible } = useSnackbar();

  const [createMembershipCustomer, { loading: customerLoading }] = useMutation(CREATE_MEMBERSHIP_CUSTOMER);
  const [createMembershipCustomerPaymentMethod, { loading: customerPaymentMethodLoading }] = useMutation(
    CREATE_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD,
  );
  const [createCustomerMembershipPlan, { loading }] = useMutation(CREATE_CUSTOMER_MEMBERSHIP_PLAN);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState<boolean>(false);

  const [contactInfo, setContactInfo] = useState<ContactInfo>(initialContactData);
  const [memberInfo, setMemberInfo] = useState<MemberShipInfo>(initialMembershipData);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(initialPaymentData);

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [successModalData, setSuccessModalData] = useState<any>({});
  const hideSuccessModal = () => {
    navigate(`/membership/${successModalData.id}`);
    setSuccessModalData(null);
    setShowSuccessModal(false);
  };

  const checkProperties = (obj) => {
    let state = true;
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === undefined) {
        state = false;
        break;
      }
    }
    return state;
  };

  const addMemberHandler = async () => {
    createMembershipCustomerHandler();
  };

  const createMembershipPlanHandler = async (paymentResponseInfo, customerResponseInfo) => {
    const { data } = await createCustomerMembershipPlan({
      variables: {
        input: {
          organizationId: organization?.id,
          planId: memberInfo?.planType,
          planPriceId: memberInfo?.paymentSchedule,
          customerId: customerResponseInfo?.id,
          paymentMethodId: paymentResponseInfo?.id,
        },
      },
    });
    if (data?.createCustomerMembershipsPlan?.id) {
      setSuccessModalData(data?.createCustomerMembershipsPlan);
      setShowSuccessModal(true);
    } else {
      alert.error(`Error: ${data?.createCustomerMembershipsPlan?.message}`);
    }
  };

  const createMembershipCustomerHandler = async () => {
    const { data } = await createMembershipCustomer({
      variables: {
        input: contactInfo,
      },
    });
    if (data.createMembershipCustomer?.id) {
      createCustomerPaymentMethodHandler(data.createMembershipCustomer);
    } else {
      alert.error(`Error: ${data?.createMembershipCustomer?.message}`);
    }
  };

  const createCustomerPaymentMethodHandler = async (customerInfo: any) => {
    const paymentMethod = paymentInfo?.payment;
    const cardInfo = paymentMethod?.card;
    const expireYear = Number(String(cardInfo?.exp_year).slice(-2));
    const params = {
      customerId: customerInfo?.id,
      organizationId: organization?.id,
      providerId: paymentMethod?.id,
      last4: cardInfo?.last4,
      network: cardInfo?.brand,
      type: paymentMethod?.type,
      expireMonth: cardInfo?.exp_month,
      expireYear,
    };
    const { data } = await createMembershipCustomerPaymentMethod({
      variables: {
        input: params,
      },
    });
    if (data?.createMembershipsCustomerPaymentMethod?.id) {
      createMembershipPlanHandler(data?.createMembershipsCustomerPaymentMethod, customerInfo);
    } else {
      alert.error(`Error: ${data?.createMembershipsCustomerPaymentMethod?.message}`);
    }
  };

  const setContactInfoHandler = (key: string, value: any) => {
    setContactInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const setMemberInfoHandler = (key: string, value: any) => {
    setMemberInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const setPaymentInfoHandler = (key: string, value: any) => {
    setPaymentInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const backHandler = () => {
    navigate(-1);
  };

  const paymentMethodLoadingHandler = (status: boolean) => {
    setPaymentMethodLoading(status);
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <Column maxWidth="1160px">
        <InnerContainer>
          <HeaderTitle>Add Member</HeaderTitle>
          <ContactInfoForm onChangeHandler={setContactInfoHandler} contactInfo={contactInfo} />
          <MembershipInfoForm onChangeHandler={setMemberInfoHandler} memberInfo={memberInfo} />
          <PaymentInfoForm
            onChangeHandler={setPaymentInfoHandler}
            paymentMethodLoadingHandler={paymentMethodLoadingHandler}
          />

          <ButtonContainer>
            <CancelButton onClick={backHandler}>Cancel</CancelButton>
            <ButtonPrimary
              data-testid="saveMember"
              onClick={addMemberHandler}
              disabled={
                loading ||
                customerLoading ||
                customerPaymentMethodLoading ||
                paymentMethodLoading ||
                !checkProperties(contactInfo) ||
                !checkProperties(memberInfo) ||
                !checkProperties(paymentInfo?.payment)
              }
              loading={loading || customerLoading || customerPaymentMethodLoading || paymentMethodLoading}
              text={'Add Member'}
            />
          </ButtonContainer>
        </InnerContainer>
      </Column>
      <CreateMemberSuccessModal open={showSuccessModal} handleClose={hideSuccessModal} modalData={successModalData} />
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  width: 100%;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0e202f;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  border: 1px solid #0e202f;
  border-radius: 2px;
  color: #0e202f;
  margin-right: 12px;
  border-radius: 4px;
`;
