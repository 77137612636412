import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { TableLoader } from 'lib/components';
import { CheckoutButton } from 'lib/components/ApplicationStatutes/components';
import client from 'lib/graphql/client';
import { GET_CUSTOMER_MEMBERSHIP_PLANS } from 'lib/graphql/queries';
import { MembershipPlans } from 'lib/graphql/searches/membershipSearch';
import useStore from 'lib/hooks/useStore';
import { firstCharFormatter, formatDate, formatPhoneNumber } from 'lib/utils';
import { CustomerPlans, DropdownItem } from '../../type';
import { PlanMembersHeader } from './PlanMembersHeader';

interface TableColumnProps {
  flex?: number;
}

interface ActiveTextContainerProps {
  flagged: boolean;
}

export const PlanMembers = () => {
  const navigate = useNavigate();
  const { organization } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [customerMembershipPlan, setCustomerMembershipPlan] = useState<CustomerPlans[]>([]);
  const [customerMembershipPlanTotal, setCustomerMembershipPlanTotal] = useState<number>(0);
  const [customerMembersPlanTypes, setCustomerMembersPlanTypes] = useState<DropdownItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState('');

  useEffect(() => {
    getCustomerMembershipPlanTypes();
  }, []);

  useEffect(() => {
    getCustomerMembershipPlans();
  }, [order, orderBy, page, rowsPerPage]);

  const searchHandler = (value: string, key?: string) => {
    getCustomerMembershipPlans(value, key);
  };

  const filterUniques = (data: DropdownItem[]) => {
    return [...new Map(data?.map((item) => [item['value'], item])).values()];
  };

  const getCustomerMembershipPlanTypes = async () => {
    const { data: { fetchCustomerMembershipsPlans } = {} } = await client.query({
      query: GET_CUSTOMER_MEMBERSHIP_PLANS,
      variables: {
        input: {
          pagination: {
            limit: 500,
            offset: 0,
            order: 'desc',
            orderBy: 'createdAt',
          },
          search: MembershipPlans(organization),
        },
      },
    });
    if (fetchCustomerMembershipsPlans?.contents?.length > 0) {
      const emptyPlanType = [
        {
          label: 'All Types',
          value: 'all',
        },
      ];
      const formattedPlanTypes = fetchCustomerMembershipsPlans.contents.map((datum) => ({
        label: datum.planName,
        value: datum.planName,
      }));
      const unique: DropdownItem[] = filterUniques(formattedPlanTypes);
      setCustomerMembersPlanTypes([...emptyPlanType, ...unique]);
    }
  };

  const getCustomerMembershipPlans = async (value?: string, filter?: string) => {
    const inputValue = value || searchInput;
    const filterValue = filter || selectedFilter;
    try {
      setLoading(true);
      const { data: { fetchCustomerMembershipsPlans } = {} } = await client.query({
        query: GET_CUSTOMER_MEMBERSHIP_PLANS,
        variables: {
          input: {
            pagination: {
              limit: rowsPerPage,
              offset: page - 1,
              order: order || 'desc',
              orderBy: orderBy || 'createdAt',
            },
            search: MembershipPlans(organization, inputValue, filterValue),
          },
        },
      });
      setLoading(false);
      if (fetchCustomerMembershipsPlans.contents.length > 0) {
        setCustomerMembershipPlanTotal(fetchCustomerMembershipsPlans.total);
        setCustomerMembershipPlan(fetchCustomerMembershipsPlans?.contents);
        return;
      }
      setCustomerMembershipPlan([]);
      setCustomerMembershipPlanTotal(0);
    } catch (err) {
      setLoading(false);
    }
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
  };

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onDetailClick = (row: { id: string }) => {
    navigate(`/membership/${row.id}`);
  };

  const onTableSort = (column, direction) => {
    setLoading(true);
    setOrderBy(column?.selector);
    setOrder(direction);
  };

  const tableColumns = [
    {
      name: 'Name',
      selector: (row) => row.customer,
      cell: (row) => (
        <NameContainer>
          <NameText>
            {row?.customer?.firstName} {row?.customer?.lastName}
          </NameText>
          <NameText>{formatPhoneNumber(row?.customer?.phone)}</NameText>
        </NameContainer>
      ),
      ignoreRowClick: true,
      minWidth: '230px',
      maxWidth: '230px',
    },
    {
      name: 'Plan Type',
      selector: (row) => row.planName,
      sortable: true,
    },
    {
      name: 'Member Since',
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => formatDate(row.createdAt),
    },
    {
      name: 'Payment Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <ActiveTextContainer flagged={row.status !== 'ACTIVE'}>
          {row.status !== 'ACTIVE' ? <StyledFontAwesomeIcon icon={faExclamationTriangle} /> : null}
          {firstCharFormatter(row.status)}
        </ActiveTextContainer>
      ),
    },
    {
      name: '',
      selector: (row) => row.detail,
      right: true,
      left: false,
      width: '221px',
      cell: (row) => (
        <CheckoutButton
          data-testid="checkout-button"
          data-tag="allowRowEvents"
          className="btn--checkout"
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => onDetailClick(row)}
        >
          Member Details
        </CheckoutButton>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <Container>
      <Title>Current Plan Members</Title>

      <PlanMembersHeader
        searchHandler={searchHandler}
        total={customerMembershipPlanTotal}
        planTypes={customerMembersPlanTypes}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />

      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            noHeader={true}
            columns={tableColumns}
            data={customerMembershipPlan}
            pagination={true}
            paginationServer={true}
            onChangePage={pageChange}
            onChangeRowsPerPage={rowsCountChange}
            paginationTotalRows={customerMembershipPlanTotal}
            progressPending={loading}
            onSort={onTableSort}
            progressComponent={<TableLoader count={4} />}
          />
        </TableColumn>
      </TableRow>
    </Container>
  );
};

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableHeadRow div {
    font-size: 12px;
    margin-bottom: -10px;
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 40px 0px;
`;

const Title = styled.span`
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  margin-bottom: 16px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0e202f;
`;

const ActiveTextContainer = styled.div<ActiveTextContainerProps>`
  color: ${(props) => (props.flagged ? '#f5365c' : props.theme.main.textColor)};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #f5365c;
  margin: 0px 5px;
`;
