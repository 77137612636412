import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { FixedDecimalFormat } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import React from 'react';
import {
  ButtonContainer,
  Container,
  ContentBorder,
  ContentContainer,
  ContentItemContainer,
  ContentSubText,
  ContentText,
  ContentTitle,
  FooterInfo,
  Header,
  Shadow,
} from './styles';
import { ProductCalculation } from './types';

interface PlanCardProps {
  data: ProductCalculation;
  onSelect: () => void;
  active: boolean;
  onChangeDownPayment?: () => void;
  isCheckoutStep?: boolean;
}

const PlanCard = ({ data, onSelect, active, onChangeDownPayment, isCheckoutStep = true }: PlanCardProps) => {
  const { flags } = useFlags();

  const customHeaderText = data?.product?.parentId ? 'Custom ' : '';
  const hasCustomDownPaymentButton = !!data?.variableDownPayment?.hasOptionToModify && !data?.product?.parentId;
  const isDownPaymentEnabledOnCloudBees = flags?.FEATURES?.isDownPaymentEnabled?.isEnabled();

  const handleClickCard = () => {
    onSelect();
  };

  return (
    <Container onClick={handleClickCard} data-testid="plan-card">
      <Shadow>
        <Header isActive={active}>
          {customHeaderText} {data?.product?.term}-Month Plan
        </Header>
        <ContentContainer isActive={active} isCheckoutStep={isCheckoutStep}>
          <ContentItemContainer>
            <ContentTitle isNotActive={!active}>{isCheckoutStep ? 'Due Today' : 'Due At Checkout'}</ContentTitle>
            <ContentText>
              <FixedDecimalFormat amount={data?.downPaymentAmount} prefix={'$'} />
            </ContentText>
            {hasCustomDownPaymentButton && isDownPaymentEnabledOnCloudBees ? (
              <ButtonContainer isDisabled={!active} onClick={onChangeDownPayment}>
                Change Down Payment
              </ButtonContainer>
            ) : null}
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentBorder />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentTitle isNotActive={!active}>{isCheckoutStep ? 'Due Later' : 'Monthly Payment'}</ContentTitle>
            <ContentText>
              {' '}
              <FixedDecimalFormat amount={data?.installmentAmount} prefix={'$'} />
              /mo
            </ContentText>

            {isCheckoutStep ? (
              <ContentSubText>
                <b>{data?.product?.term} payments</b> starting {dayjs(data?.paymentDates?.[0]).format('MM/DD/YYYY')}
                <br />
                <b>
                  <FixedDecimalFormat amount={data?.financeCharge} prefix={'$'} />
                </b>{' '}
                total finance charge
                <br />
                <b>{data?.promo ? data?.product?.promoApr : data?.product?.apr}%</b> APR
              </ContentSubText>
            ) : (
              <ContentSubText>
                <b>for {data?.product?.term} months</b>
                <br />
                <br />
                <b>{data?.promo ? data?.product?.promoApr : data?.product?.apr}%</b> APR
              </ContentSubText>
            )}

            <ButtonContainer isActive={active} isDisabled={false}>
              {active ? (
                <>
                  <FontAwesomeIcon icon={faCheck} />
                  Selected
                </>
              ) : (
                'Select Plan'
              )}
            </ButtonContainer>

            {data?.promo && data?.product?.promoApr !== null ? (
              <FooterInfo>
                <Icon width={20} height={20} src={'info_circle_outline'} />
                <span>
                  APR increases to <b>{data?.product?.fallbackApr || data?.product?.apr}%</b> if payments are made late.
                </span>
              </FooterInfo>
            ) : null}
          </ContentItemContainer>
        </ContentContainer>
      </Shadow>
    </Container>
  );
};

export default PlanCard;
