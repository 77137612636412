import React from 'react';

import { SCHEDULE_VIEW_BUTTONS } from 'lib/constants';
import styled from 'styled-components';

export const SendButtons = ({ row, handleClick, isSendReminder }) => {
  const handleButtonClick = () => {
    handleClick(row, isSendReminder);
  };
  const title = isSendReminder ? SCHEDULE_VIEW_BUTTONS.REMINDER : SCHEDULE_VIEW_BUTTONS.APPLICATION;
  return <StyledButton onClick={handleButtonClick}>{title}</StyledButton>;
};
export const StyledButton = styled.button`
  background-color: ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.white};
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  outline: none;
`;
