import { useMutation } from '@apollo/client';
import { EditButtonIcon } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import {
  PATCH_CUSTOMER_MEMBERSHIP_PLAN,
  PATCH_MEMBERSHIP_CUSTOMER,
  PATCH_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD,
  PATCH_SUBSCRIPTIONS_CUSTOMER,
} from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import {
  EditMemberInfo,
  ReadMemberInfo,
  initialMembershipData,
  initialPaymentData,
} from 'pages/desktop/Membership/components/MemberDetail';
import { ContactInfo, MemberShipInfo, PaymentInfo, PaymentMethod } from 'pages/desktop/Membership/type';

const initialContactData = {
  id: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

interface Props {
  customerPlanData: any;
  customerPaymentData: PaymentMethod | undefined;
  fetchPlanDetail: () => void;
}

export const MemberInfo = ({ customerPlanData, customerPaymentData, fetchPlanDetail }: Props) => {
  const { organization } = useStore();
  const alert = useAlert();

  const [contactInfo, setContactInfo] = useState<ContactInfo>(initialContactData);
  const [memberInfo, setMemberInfo] = useState<MemberShipInfo>(initialMembershipData);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(initialPaymentData);

  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

  const [patchCustomer] = useMutation(PATCH_SUBSCRIPTIONS_CUSTOMER);
  const [patchMembershipCustomer, { loading: customerLoading }] = useMutation(PATCH_MEMBERSHIP_CUSTOMER);
  const [patchMembershipCustomerPaymentMethod] = useMutation(PATCH_MEMBERSHIP_CUSTOMER_PAYMENT_METHOD);
  const [patchCustomerMembershipPlan] = useMutation(PATCH_CUSTOMER_MEMBERSHIP_PLAN);

  useEffect(() => {
    if (customerPlanData) {
      setContactInfo(customerPlanData.customer);
      setMemberInfo({
        planType: customerPlanData.planId,
        paymentSchedule: '',
        paymentSchedulePeriod: customerPlanData.period,
        memberSince: customerPlanData.plan?.createdAt,
      });
    }
  }, [customerPlanData]);

  const setContactInfoHandler = (key: string, value: any) => {
    setContactInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const setMemberInfoHandler = (key: string, value: any) => {
    setMemberInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const setPaymentInfoHandler = (key: string, value: any) => {
    setPaymentInfo((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const editModeHandler = () => {
    setIsEdit(!isEdit);
  };

  const saveHandler = () => {
    patchMembershipCustomerHandler();
  };

  const flushEditForm = () => {
    setLoading(false);
    setIsEdit(!isEdit);
    fetchPlanDetail();
  };

  const patchMembershipCustomerHandler = async () => {
    try {
      setLoading(true);
      const params = {
        customerId: contactInfo?.id,
        ...contactInfo,
      };
      delete params.id;
      const { data } = await patchMembershipCustomer({
        variables: {
          input: params,
        },
      });
      if (data?.patchMembershipCustomer?.id) {
        if (
          paymentInfo?.payment?.card?.last4 &&
          paymentInfo?.payment?.card?.exp_month &&
          paymentInfo?.payment?.card?.exp_year
        ) {
          await patchCustomerPaymentMethodHandler(data.patchMembershipCustomer);
        } else if (memberInfo.planType || memberInfo.paymentSchedule) {
          const paymentData = { id: customerPlanData?.paymentMethodId };
          await patchMembershipPlanHandler(paymentData, data.patchMembershipCustomer);
        } else {
          flushEditForm();
        }
      } else {
        flushEditForm();
        alert.error(`Error: ${data?.patchMembershipCustomer?.message}`);
      }
    } catch (e) {
      flushEditForm();
    }
  };

  const patchCustomerPaymentMethodHandler = async (customerInfo: any) => {
    try {
      const paymentMethod = paymentInfo?.payment;
      const cardInfo = paymentMethod?.card;
      const expireYear = Number(String(cardInfo?.exp_year).slice(-2));
      const params = {
        customerId: customerInfo?.id,
        paymentMethodId: customerPlanData?.paymentMethodId,
        organizationId: organization?.id,
        providerId: paymentMethod?.id,
        last4: cardInfo?.last4,
        network: cardInfo?.brand,
        type: paymentMethod?.type,
        expireMonth: cardInfo?.exp_month,
        expireYear,
      };
      const { data } = await patchMembershipCustomerPaymentMethod({
        variables: {
          input: params,
        },
      });
      if (data?.patchMembershipsCustomerPaymentMethod?.id) {
        await patchMembershipPlanHandler(data?.patchMembershipsCustomerPaymentMethod, customerInfo);
        if (paymentInfo?.address?.name && paymentInfo.address.address.city) {
          await patchCustomerAddressHandler(customerInfo);
        } else {
          flushEditForm();
        }
      } else {
        flushEditForm();
        alert.error(`Error: ${data?.createMembershipsCustomerPaymentMethod?.message}`);
      }
    } catch (e) {
      flushEditForm();
    }
  };

  const patchMembershipPlanHandler = async (paymentResponseInfo, customerResponseInfo) => {
    try {
      const { data } = await patchCustomerMembershipPlan({
        variables: {
          input: {
            customerPlanId: customerPlanData?.id,
            organizationId: organization?.id,
            planId: memberInfo?.planType,
            planPriceId: memberInfo?.paymentSchedule || customerPlanData?.planPriceId,
            customerId: customerResponseInfo?.id,
            paymentMethodId: paymentResponseInfo?.id,
          },
        },
      });
      if (data?.patchCustomerMembershipsPlan?.id) {
        flushEditForm();
      } else {
        flushEditForm();
        alert.error(`Error: ${data?.patchCustomerMembershipsPlan?.message}`);
      }
    } catch (e) {
      flushEditForm();
    }
  };

  const patchCustomerAddressHandler = async (customerInfo) => {
    try {
      const billingMethod = paymentInfo?.address;
      const billingDetails = billingMethod?.address;
      await patchCustomer({
        variables: {
          input: {
            customerId: customerInfo?.id,
            address: {
              zipCode: billingDetails?.postal_code,
              // @ts-ignore
              street: billingDetails?.line1 + billingDetails?.line2,
              city: billingDetails?.city,
              state: billingDetails?.state,
            },
            firstName: billingMethod?.name?.split(' ')[0],
            lastName: billingMethod?.name?.split(' ')[1],
          },
        },
      });
      flushEditForm();
    } catch (err) {
      flushEditForm();
      console.warn(err);
    }
  };

  const paymentMethodLoadingHandler = (status: boolean) => {
    setPaymentMethodLoading(status);
  };

  return (
    <Container>
      <InfoHeader>
        <HeaderTitle>
          {customerPlanData?.customer?.firstName} {customerPlanData?.customer?.lastName}
        </HeaderTitle>
        {isEdit ? (
          <ButtonContainer>
            <HeaderButton onClick={editModeHandler}>Cancel</HeaderButton>
            <HeaderPrimaryButton onClick={saveHandler} loading={customerLoading || isLoading || paymentMethodLoading}>
              Save Changes
            </HeaderPrimaryButton>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <HeaderButton onClick={editModeHandler}>
              Edit Member
              <EditButtonIcon />
            </HeaderButton>
          </ButtonContainer>
        )}
      </InfoHeader>

      {isEdit ? (
        <EditMemberInfo
          contactInfo={contactInfo}
          memberInfo={memberInfo}
          paymentInfo={paymentInfo}
          customerPaymentData={customerPaymentData}
          onContactInfoHandler={setContactInfoHandler}
          onMemberInfoHandler={setMemberInfoHandler}
          setPaymentInfoHandler={setPaymentInfoHandler}
          paymentMethodLoadingHandler={paymentMethodLoadingHandler}
        />
      ) : (
        <ReadMemberInfo data={customerPlanData} paymentData={customerPaymentData} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
  svg {
    margin-left: 5px;
  }
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #0e202f;
`;

const HeaderButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid #00c37d;
  border-radius: 4px;
  color: #00c37d;
`;

const HeaderPrimaryButton = styled(HeaderButton)<{ loading: boolean }>`
  background-color: ${(props) => (props.loading ? 'gray' : '#00c37d')};
  border: ${(props) => (props.loading ? '1px solid gray' : '#1px solid #00c37d')};
  color: #ffffff;
  margin-left: 12px;
`;
