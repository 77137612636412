import { gql } from '@apollo/client/core';

export const GET_USER = gql`
  query GetUserDetail($input: UserDetailInput) {
    getUserDetail(input: $input) {
      user {
        id
        status
        firstName
        lastName
        phone
        email
        employeeId
        createdAt
        updatedAt
        roles
      }
      status
      message
      error
    }
  }
`;
