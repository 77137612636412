import { Popper as MuiPopper, PopperProps as MuiPopperProps } from '@mui/material';
import styled from 'styled-components';

const Popper = ({ children, ...rest }: MuiPopperProps) => {
  return (
    <StyledMuiPopper
      {...rest}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
        },
      ]}
    >
      {children as React.ReactNode}
      <span className="arrow" />
    </StyledMuiPopper>
  );
};

const StyledMuiPopper = styled(MuiPopper)`
  background-color: ${(props) => props.theme.main.green};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  max-width: 232px;
  text-align: center;
  color: ${(props) => props.theme.main.white};
  font-size: 12px;
  z-index: 9999;

  &[data-popper-placement*='top'] {
    margin-bottom: 20px !important;

    .arrow {
      bottom: -10px;
      left: 50%;
      transform: translate(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: ${(props) => props.theme.main.green} transparent transparent transparent;
      position: absolute;
    }
  }

  &[data-popper-placement*='right'] {
    margin-left: 20px !important;

    .arrow {
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent ${(props) => props.theme.main.green} transparent transparent;
      position: absolute;
    }
  }

  &[data-popper-placement*='bottom'] {
    margin-top: 20px !important;

    .arrow {
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent ${(props) => props.theme.main.green} transparent;
      position: absolute;
    }
  }

  &[data-popper-placement*='left'] {
    margin-right: 20px !important;

    .arrow {
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent ${(props) => props.theme.main.green};
      position: absolute;
    }
  }
`;

export default Popper;
