// @ts-nocheck
import React from 'react';

import { AlertComponentPropsWithStyle } from 'react-alert';
import styled from 'styled-components';

import { Icon } from 'lib/components/mobile';

export const AlertTemplate = ({ style, message, options, close }: AlertComponentPropsWithStyle) => {
  return (
    <Container status={options.type}>
      {options.type === 'info' && (
        <IconWrapper>
          <Icon src={'warning'} />
        </IconWrapper>
      )}
      <AlertInformation>
        <AlertTextDescription status={options.type} dangerouslySetInnerHTML={{ __html: message }} />
      </AlertInformation>
    </Container>
  );
};

const alertBackground = {
  info: '#e6cb72',
  success: '#00C37D',
  error: '#C91D1D',
};

interface ContainerProps {
  status: 'info' | 'success' | 'error' | undefined;
}
const AlertTextDescription = styled.span<ContainerProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.status === 'info' ? 'black' : 'white')};
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;

const AlertInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => (props.status ? alertBackground[props.status] : 'white')};
  padding: 10px 25px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 600px;
  min-width: 600px;
  top: -20px;
  box-sizing: border-box;
  margin-top: 20px;
  color: #0e202f;
  z-index: 9999999999;
  pointer-events: all;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 100%;
    min-width: 100%;
    left: 0px;
    right: 0px;
    position: absolute;
    top: -20px;
  }
`;
