import styled from 'styled-components';

export const DownPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  max-width: 220px;
`;

export const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
`;

export const ButtonContainer = styled.div<{ disabled: boolean }>`
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
  margin-top: 3.5px;
  cursor: pointer;

  > img {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled && {
      pointerEvents: 'none',
      cursor: 'none',
    }}
`;
