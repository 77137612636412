import React from 'react';
import styled from 'styled-components';

export const FacebookIcon = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"
        fill="#0E202F"
      />
    </svg>
  </IconWrapper>
);

export const TwitterIcon = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.995 6.68799C20.8914 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.118 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51764C12.2933 5.43277 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.4669 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78738 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z"
        fill="#0E202F"
      />
    </svg>
  </IconWrapper>
);

export const LinkedInIcon = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 21H9V9H13V11C13.8526 9.91525 15.1456 9.26857 16.525 9.237C19.0056 9.25077 21.0072 11.2694 21 13.75V21H17V14.25C16.84 13.1326 15.8818 12.3036 14.753 12.306C14.2593 12.3216 13.7932 12.5378 13.4624 12.9046C13.1316 13.2715 12.9646 13.7573 13 14.25V21ZM7 21H3V9H7V21ZM5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7Z"
        fill="#0E202F"
      />
    </svg>
  </IconWrapper>
);

export const WhatsappIcon = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5823 13.985C16.3328 13.8608 15.1095 13.2625 14.8817 13.1792C14.6539 13.0967 14.4881 13.0558 14.3215 13.3042C14.1557 13.5508 13.6793 14.1092 13.5344 14.2742C13.3887 14.44 13.2438 14.46 12.9952 14.3367C12.7465 14.2117 11.9443 13.9508 10.9939 13.1075C10.2545 12.4509 9.75464 11.64 9.60978 11.3917C9.46492 11.1442 9.59387 11.01 9.71863 10.8867C9.83084 10.7759 9.96732 10.5975 10.0921 10.4534C10.2169 10.3084 10.2579 10.205 10.3408 10.0392C10.4245 9.87419 10.3826 9.73002 10.3198 9.60585C10.2579 9.48169 9.7605 8.26252 9.55284 7.76669C9.35104 7.28419 9.14589 7.35003 8.99349 7.34169C8.8478 7.33503 8.682 7.33336 8.51621 7.33336C8.35041 7.33336 8.08079 7.39503 7.85303 7.64336C7.62444 7.89086 6.98219 8.49002 6.98219 9.70919C6.98219 10.9275 7.87313 12.105 7.99789 12.2709C8.12266 12.4359 9.75213 14.9375 12.2482 16.01C12.8428 16.265 13.3058 16.4175 13.6667 16.5308C14.2629 16.72 14.8055 16.6933 15.2342 16.6292C15.7115 16.5583 16.7063 16.03 16.9139 15.4517C17.1208 14.8733 17.1207 14.3775 17.0588 14.2742C16.9968 14.1708 16.831 14.1092 16.5815 13.985H16.5823ZM12.0423 20.1542H12.0389C10.5563 20.1544 9.10099 19.7578 7.8254 19.0058L7.52396 18.8275L4.39062 19.6458L5.22712 16.6058L5.03035 16.2942C4.20149 14.9811 3.76286 13.4615 3.76512 11.9109C3.76679 7.36919 7.47958 3.6742 12.0456 3.6742C14.2562 3.6742 16.3345 4.53253 17.897 6.08919C18.6676 6.85301 19.2785 7.76133 19.6941 8.7616C20.1098 9.76188 20.322 10.8343 20.3186 11.9167C20.3169 16.4583 16.6041 20.1542 12.0423 20.1542ZM19.086 4.9067C18.1634 3.98247 17.0657 3.24965 15.8564 2.7507C14.6472 2.25175 13.3505 1.9966 12.0414 2.00003C6.55347 2.00003 2.08541 6.44586 2.08373 11.91C2.08119 13.649 2.53956 15.3578 3.4126 16.8642L2 22L7.27861 20.6217C8.73884 21.4134 10.3752 21.8283 12.0381 21.8283H12.0423C17.5302 21.8283 21.9983 17.3825 22 11.9175C22.004 10.6152 21.7485 9.32511 21.2484 8.12172C20.7482 6.91833 20.0132 5.82559 19.086 4.9067Z"
        fill="#0E202F"
      />
    </svg>
  </IconWrapper>
);

export const MailIcon = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 20H4C2.89543 20 2 19.1046 2 18V5.913C2.04661 4.84255 2.92853 3.99899 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20ZM4 7.868V18H20V7.868L12 13.2L4 7.868ZM4.8 6L12 10.8L19.2 6H4.8Z"
        fill="#0E202F"
      />
    </svg>
  </IconWrapper>
);

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding-right: 18px;

  &path {
    fill: #ff0000;
  }
`;
