import { theme } from 'config/theme';
import React from 'react';
import { LineChart as Chart, Line, CartesianGrid, XAxis, Tooltip, YAxis, ResponsiveContainer, Label } from 'recharts';
import styled from 'styled-components';
import { FixedDecimalFormat } from '../FixedDecimalFormat';

interface Props {
  data: { name: string; data: number | string }[];
  height?: number | string;
  secondLineData?: { name: string; data: number | string }[];
  toolTipLabel?: string;
  dataType?: string;
  lineColor?: string;
  chartType?: string;
  labelTitle?: string;
}

const CustomTooltip = ({ active, payload, label, toolTipLabel, dataType, color }) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <p>{`${label}`}</p>
        <ToolTipSpan color={color}>
          {toolTipLabel || payload?.[0]?.dataKey} :{' '}
          {dataType === 'money' ? <FixedDecimalFormat amount={payload[0].value} prefix="$" /> : payload[0].value}
        </ToolTipSpan>
      </ToolTipContainer>
    );
  }
  return null;
};

const DataFormater = (number) => {
  if (number > 1000000) {
    return '$' + (number / 1000000).toString() + 'M';
  } else if (number > 1000) {
    return '$' + (number / 1000).toString() + 'K';
  } else {
    return '$' + number.toString();
  }
};

export const LineChart = ({
  data = [],
  height = 270,
  secondLineData,
  toolTipLabel,
  dataType,
  lineColor,
  labelTitle,
}: Props) => {
  const color = lineColor || '#00C37D';

  return (
    <ResponsiveContainer width={'99%'} height={height}>
      <Chart data={data} margin={{ right: 40, left: labelTitle ? 20 : 0, top: 10 }}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" stroke="#0E202F" fontSize={12} />
        <YAxis stroke="#0E202F" fontSize={12} tickFormatter={DataFormater}>
          {Boolean(labelTitle) && (
            <Label value={labelTitle} angle={-90} fill={theme.main.midnightBlue} position={'insideLeft'} />
          )}
        </YAxis>
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
              toolTipLabel={toolTipLabel}
              dataType={dataType}
              color={color}
            />
          )}
        />
        <Line
          type="linear"
          dataKey="data"
          animationDuration={800}
          stroke={color}
          dot={{ fill: color }}
          strokeWidth={2}
          isAnimationActive={false}
        />
        {secondLineData && (
          <Line type="linear" dataKey="secondLineData" stroke="#55a1e5" dot={{ fill: color }} strokeWidth={2} />
        )}
      </Chart>
    </ResponsiveContainer>
  );
};

const ToolTipContainer = styled.div`
  margin: 0px;
  padding: 1px 10px 10px 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

const ToolTipSpan = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;
