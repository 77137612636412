import React from 'react';
import styled from 'styled-components';

const EmptyListMessage = () => {
  return (
    <EmptyListMessageContainer>
      <Message>You have no outstanding document requests at this time.</Message>
    </EmptyListMessageContainer>
  );
};

const EmptyListMessageContainer = styled.div`
  width: 800px;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 0.7512931227684021px 1.1269396543502808px 0px rgba(0, 0, 0, 0.04), 0px 6px 9px 0px rgba(0, 0, 0, 0.07);
`;

const Message = styled.span`
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 14px;
  font-weight: 400;
`;

export default EmptyListMessage;
