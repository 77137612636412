import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  BankingIssue,
  Header,
  MembershipHeader,
  MembershipShopSidebar,
  Sidebar,
  PointOfSaleHeader,
  ShopHeader,
  SplashScreen,
} from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import useChatButton from 'lib/hooks/useChatButton';

interface Props {
  children: React.ReactNode;
}

export enum APPS {
  MAIN = 'MAIN',
  MEMBERSHIP = 'MEMBERSHIP',
  SHOP = 'SHOP',
  POINT_OF_SALE = 'POINT_OF_SALE',
}

export const MainLayout = ({ children }: Props) => {
  const location = useLocation();
  const { activeModesText, isSnackbarVisible, isDemonstrationMode, showSnackbar, hideSnackbar } = useSnackbar();
  const { hasBankingIssue } = useStore();
  const permission = usePermission();
  const { showChatButton, hideChatButton } = useChatButton();

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isDemonstrationMode) {
      hideSnackbar();
      hideChatButton();
    }
  }, [isDemonstrationMode, hideSnackbar]);

  const getCurrentApp = () => {
    if (location.pathname.includes('membership')) {
      return APPS.MEMBERSHIP;
    }
    if (location.pathname.includes('shop')) {
      return APPS.SHOP;
    }
    if (location.pathname.includes('point-of-sale')) {
      return APPS.POINT_OF_SALE;
    }

    return APPS.MAIN;
  };

  const getHeader = () => {
    switch (getCurrentApp()) {
      case APPS.MEMBERSHIP:
        return <MembershipHeader isSidebarOpen={isSidebarOpen} isSnackbarVisible={isSnackbarVisible} />;
      case APPS.MAIN:
        return <Header isSidebarOpen={isSidebarOpen} isSnackbarVisible={isSnackbarVisible} />;
      case APPS.SHOP:
        return <ShopHeader isSidebarOpen={isSidebarOpen} isSnackbarVisible={isSnackbarVisible} />;
      case APPS.POINT_OF_SALE:
        return <PointOfSaleHeader isSidebarOpen={isSidebarOpen} isSnackbarVisible={isSnackbarVisible} />;
    }
  };

  const getSidebar = () => {
    switch (getCurrentApp()) {
      case APPS.MEMBERSHIP:
        return (
          <MembershipShopSidebar
            opened={isSidebarOpen}
            toggleFunc={toggleSidebar}
            isShop={false}
            isSnackbarVisible={isSnackbarVisible}
          />
        );
      case APPS.MAIN:
      case APPS.POINT_OF_SALE:
        return (
          <Sidebar
            opened={isSidebarOpen}
            toggleFunc={toggleSidebar}
            forceOpenSidebar={forceOpenSidebar}
            isSnackbarVisible={isSnackbarVisible}
          />
        );
      case APPS.SHOP:
        return (
          <MembershipShopSidebar
            opened={isSidebarOpen}
            toggleFunc={toggleSidebar}
            isShop={true}
            isSnackbarVisible={isSnackbarVisible}
          />
        );
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const forceOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSplashScreen = () => {
    showSnackbar();
    showChatButton();
  };

  return (
    <>
      {isSnackbarVisible && <DemoContainer>{activeModesText}</DemoContainer>}
      <Wrapper>
        {getHeader()}
        {getSidebar()}
        <Content isSidebarOpen={isSidebarOpen}>
          {permission(FeatureNames.BANK_INFORMATION, ScreenNames.SETTINGS) && hasBankingIssue && <BankingIssue />}
          <SplashScreen handleCloseSplashScreen={handleCloseSplashScreen} initialVisibility={isDemonstrationMode}>
            <MainContainer>{children}</MainContainer>
          </SplashScreen>
        </Content>
      </Wrapper>
    </>
  );
};

interface ContentProps {
  isSidebarOpen: boolean;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const MainContainer = styled.div`
  padding: 0px 10px;
`;

const Content = styled.div<ContentProps>`
  flex: 1;
  transition: margin-left 0.4s;
  transition-timing-function: ease-in-out;
  height: 100vh;
  margin-left: ${(props) => (props.isSidebarOpen ? 235 : 80)}px;
  @media print {
    margin-left: 0;
  }
`;

const DemoContainer = styled.div`
  text-align: center;
  background-color: #e6cb72;
  padding: 10px 0;
  font-weight: bold;
  position: fixed;
  z-index: 9999999;
  width: 100%;
`;
