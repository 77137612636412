import {
  EmailInput,
  emailPattern,
  emailPhoneCombinedRegex,
  MaskInput,
  numericPattern,
  PhoneNumberInput,
  phonePattern,
  sanitizePhoneNumber,
  TextInput,
} from '@frontend/cherry-library';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

export const SendApplicationLinkFormContainer = ({
  isFullHeight,
  isCombinedField = false,
  handleSendClick,
  segmentEventHandler,
  type,
  phoneNumber,
  onPhoneChange,
  email,
  onEmailChange,
  onCombinedFieldChange,
  logInputBlur,
  blurHandler,
  applicationSending = false,
  applicationSent = false,
}) => {
  const [combinedInputValue, setCombinedInputValue] = useState(phoneNumber || email);
  const [mask, setMask] = useState('');

  const { control } = useForm({
    mode: 'onTouched',
  });

  const getInputComponent = () => {
    if (combinedInputValue !== undefined && combinedInputValue !== null) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      return phonePattern.test(sanitizePhoneNumber(combinedInputValue)) ? (MaskInput as any) : null;
    }
    return null;
  };

  const handleCombinedInputMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedPhoneInput = sanitizePhoneNumber(event.target.value);
    if (numericPattern.test(parsedPhoneInput)) {
      setMask('(000) 000-0000*');
      setCombinedInputValue(parsedPhoneInput);
    } else {
      setMask('');
      setCombinedInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (!applicationSent) {
      return;
    }
    setCombinedInputValue('');
  }, [applicationSent]);

  return (
    <FormContainer isFullHeight={isFullHeight}>
      {!isCombinedField && type === 'phone' && (
        <Controller
          name="phone"
          control={control}
          defaultValue={phoneNumber}
          rules={{
            pattern: {
              value: phonePattern,
              message: 'Please enter a valid US mobile phone number',
            },
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <PhoneNumberInput
              onChange={(e) => {
                onChange(e);
                onPhoneChange(e);
              }}
              value={value}
              error={!!error}
              errorText={error?.message}
              onClick={segmentEventHandler(
                'PRACTICE_PORTAL.SEND_APPLICATION_LINK.TEXT_LINK_PHONE_NUMBER_FIELD_CLICKED',
              )}
              onBlur={() => {
                onBlur();
                segmentEventHandler('PRACTICE_PORTAL.SEND_APPLICATION_LINK.TEXT_LINK_PHONE_NUMBER_FIELD_FILLED', {
                  withInput: true,
                  isFilledEvent: true,
                  cb: logInputBlur,
                });
              }}
              actionButton={{
                text: 'Send',
                onClick: handleSendClick,
                disabled: !!error?.message || applicationSending,
              }}
              inputProps={{
                'data-testid': 'application-phone-input',
              }}
            />
          )}
        />
      )}
      {!isCombinedField && type === 'email' && (
        <Controller
          name="email"
          control={control}
          defaultValue={email}
          rules={{
            pattern: {
              value: emailPattern,
              message: 'Please enter a valid email address',
            },
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EmailInput
              name="email"
              data-testid="email-input"
              error={!!error}
              errorText={error?.message}
              value={value}
              onClick={segmentEventHandler('PRACTICE_PORTAL.SEND_APPLICATION_LINK.EMAIL_LINK_EMAIL_FIELD_CLICKED')}
              onChange={(e) => {
                onChange(e);
                onEmailChange(e as React.ChangeEvent<HTMLInputElement>);
              }}
              onBlur={() => {
                onBlur();
                segmentEventHandler('PRACTICE_PORTAL.SEND_APPLICATION_LINK.EMAIL_LINK_EMAIL_FIELD_FILLED', {
                  withInput: true,
                  isFilledEvent: true,
                  cb: blurHandler,
                });
              }}
              actionButton={{
                text: 'Send',
                onClick: handleSendClick,
                disabled: !!error?.message || applicationSending,
              }}
              inputProps={{
                'data-testid': 'email-input',
              }}
            />
          )}
        />
      )}
      {isCombinedField && (
        <Controller
          name="phone-or-email"
          control={control}
          defaultValue={combinedInputValue ?? ''}
          rules={{
            pattern: {
              value: emailPhoneCombinedRegex,
              message: 'Enter a valid U.S. phone number or email.',
            },
          }}
          // tslint:disable-next-line: jsx-no-lambda
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextInput
              name="phone-or-email"
              data-testid="phone-or-email-input"
              label="Phone or Email"
              error={!!error}
              errorText={error?.message}
              value={value}
              onClick={segmentEventHandler('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBINED_FIELD_CLICKED')}
              onChange={(e) => {
                onChange(e);
                handleCombinedInputMask(e as React.ChangeEvent<HTMLInputElement>);
                onCombinedFieldChange(e as React.ChangeEvent<HTMLInputElement>);
              }}
              onBlur={() => {
                onBlur();
                segmentEventHandler('PRACTICE_PORTAL.SEND_APPLICATION_LINK.COMBINED_FIELD_FILLED', {
                  withInput: true,
                  isFilledEvent: true,
                  cb: blurHandler,
                });
              }}
              actionButton={{
                text: 'Send',
                onClick: handleSendClick,
                disabled: !!error?.message || applicationSending,
              }}
              inputComponent={getInputComponent()}
              inputProps={{
                'data-testid': 'phone-or-email-input',
                mask,
                value: combinedInputValue,
              }}
            />
          )}
        />
      )}
    </FormContainer>
  );
};

const FormContainer = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (!props.isFullHeight ? '8px' : '32px')};
  position: relative;
  width: 100%;
`;
