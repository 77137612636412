import { Body, CurrencyInput, formatAmount, Heading } from '@frontend/cherry-library';
import { ReactComponent as CalculatorIcon } from 'assets/images/calculator.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle-outline.svg';
import { ReactComponent as InfoRedIcon } from 'assets/images/info-circle-red.svg';
import { usePaymentEstimator } from 'lib/hooks/usePaymentEstimator';
import { useSegment } from 'lib/hooks/useSegment';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DescriptionWrapper,
  HeadingContainer,
  LowestOption,
  PaymentOptionsContainer,
  PlanTableContainer,
  StyledDownCircle,
  StyledPopover,
  StyledPopoverContent,
  StyledPopoverText,
  StyledPopoverWrapper,
  TableContainer,
  TableErrorContainer,
  TableFooter,
  TableHeader,
  TableSkeleton,
} from '../styles';
import { usePaymentEstimatorPage } from '../usePaymentEstimatorPage';

const EstimateTable = ({
  createdPlan,
  setCreatedPlan,
  setPurchaseAmount,
  purchaseAmount,
  estimationLoading,
  setEstimationLoading,
}) => {
  const { generateEstimate } = usePaymentEstimatorPage();
  const {
    maxEligibleAmount,
    onPurchaseAmountChange,
    purchaseAmountErrorMsg,
    validPurchaseAmountRange,
    MIN_ELIGIBLE_AMOUNT,
  } = usePaymentEstimator();
  const { segmentEventHandler } = useSegment();

  const purchaseAmountRef = useRef(purchaseAmount);

  const [lowestAmount, setLowestAmount] = useState<number>();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const fetchPaymentEstimation = async (val: number) => {
    setEstimationLoading(true);
    const resp = await generateEstimate(val);
    if (resp?.length > 0) {
      setCreatedPlan(resp);
      setLowestAmount(resp[resp?.length - 1]?.paymentAmount);
    }

    setEstimationLoading(false);
  };

  const debouncedChangeHandler = useCallback(
    (val) => {
      const handler = setTimeout(async () => {
        if (val > maxEligibleAmount || val < MIN_ELIGIBLE_AMOUNT) {
          setLowestAmount(0);
          setCreatedPlan([]);
          segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.PURCHASE_INPUT.AMOUNT_ERROR.OCCURED`);
        } else if (val === purchaseAmountRef.current) {
          fetchPaymentEstimation(val);
        }
      }, 1000);

      return () => clearTimeout(handler);
    },
    [generateEstimate],
  );

  const handleAmountChange = (e) => {
    const newVal = e?.target?.value;

    if (newVal <= 1000000) {
      setPurchaseAmount(newVal);
      purchaseAmountRef.current = newVal;
      debouncedChangeHandler(newVal);

      onPurchaseAmountChange(e);
    }
  };

  const handleTooltipVisibility = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  useEffect(() => {
    if (purchaseAmount) {
      fetchPaymentEstimation(purchaseAmount);
    }
  }, []);

  const TableSkeletonBox = (
    <tr>
      <td>
        <TableSkeleton />
      </td>
      <td>
        <TableSkeleton />
      </td>
      <td>
        <TableSkeleton />
      </td>
    </tr>
  );

  return (
    <PaymentOptionsContainer>
      <DescriptionWrapper>
        <HeadingContainer>
          <CalculatorIcon width={24} height={24} id="payment-estimator-calculator-icon" />
          <Heading level="4" text="Generate Estimate" />
        </HeadingContainer>

        <Body>Preview an estimate of your patient’s monthly payment options.</Body>
      </DescriptionWrapper>

      <CurrencyInput
        label="Purchase Amount"
        inputProps={{
          'data-testid': 'purchaseAmount',
          'data-neuro-label': 'purchaseAmount',
        }}
        value={purchaseAmount}
        onFocus={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.PURCHASE_INPUT_SELECTED`)}
        onChange={handleAmountChange}
        onBlur={segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.PURCHASE_INPUT_FILLED`, {
          withInput: true,
          isFilledEvent: true,
        })}
        error={!validPurchaseAmountRange}
        errorText={purchaseAmountErrorMsg || 'Enter a lower amount'}
        autoComplete="off"
      />

      <TableContainer hasError={!validPurchaseAmountRange} isAvailable={createdPlan?.length > 0}>
        {validPurchaseAmountRange ? (
          <>
            <TableHeader>
              <Body>As low as:</Body>
              <div>
                <StyledPopoverWrapper onMouseEnter={handleTooltipVisibility} onMouseLeave={handleTooltipVisibility}>
                  <InfoIcon width={25} height={25} id="as-low-as-tooltip-icon" />
                  {isTooltipOpen && (
                    <StyledPopover>
                      <StyledPopoverContent>
                        <StyledDownCircle />
                        <StyledPopoverText>
                          A down payment equal to the monthly payment is due at the time of purchase.
                        </StyledPopoverText>
                      </StyledPopoverContent>
                    </StyledPopover>
                  )}
                </StyledPopoverWrapper>
              </div>
            </TableHeader>
            {estimationLoading ? (
              <TableSkeleton style={{ width: '30%' }} />
            ) : (
              <LowestOption>
                <Heading level="2" text={formatAmount(lowestAmount || 0)} />
                <Body size="x-large">/ month</Body>
              </LowestOption>
            )}
            <PlanTableContainer>
              <tr>
                <th>Plan Length</th>
                <th>Monthly Payment</th>
                <th>Total Finance Charge</th>
              </tr>
              {estimationLoading ? (
                <>
                  {TableSkeletonBox}
                  {TableSkeletonBox}
                  {TableSkeletonBox}
                </>
              ) : (
                createdPlan?.map((option, index) => (
                  <tr key={index}>
                    <td>{option?.term} Months</td>
                    <td>{formatAmount(option?.paymentAmount)}</td>
                    <td>
                      {formatAmount(option?.financeCharge)} ({option?.apr}% APR)
                    </td>
                  </tr>
                ))
              )}
            </PlanTableContainer>
            <TableFooter>
              This is an example only. Patient's exact terms and APR are determined by many factors, including credit
              score.
            </TableFooter>
          </>
        ) : (
          <TableErrorContainer>
            <InfoRedIcon width={24} height={24} />
            {`Amount must be between ${formatAmount(MIN_ELIGIBLE_AMOUNT)} - ${formatAmount(maxEligibleAmount)}`}
          </TableErrorContainer>
        )}
      </TableContainer>
    </PaymentOptionsContainer>
  );
};

export default EstimateTable;
