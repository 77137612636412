import React from 'react';
import styled from 'styled-components';

interface StyledIconProps {
  m?: string;
  p?: string;
  left?: string;
  right?: string;
  width?: number;
  height?: number;
  hover?: boolean;
  startTransformAnimation?: boolean;
  withTransformAnimation?: boolean;
}

interface IconProps extends StyledIconProps {
  src: string;
  onClick?: () => void;
}

export const Icon = ({ src, onClick, ...rest }: IconProps) => (
  <StyledIcon src={`/icon/${src}.svg`} onClick={onClick} {...rest} />
);

const StyledIcon = styled.img<StyledIconProps>`
  cursor: ${(props) => (props.hover ? 'pointer' : 'inherit')};
  width: ${(props) => `${props.width ?? 30}px`};
  height: ${(props) => `${props.height ?? 30}px`};
  margin: ${(props) => props.m ?? 'unset'};
  padding: ${(props) => props.p ?? 'unset'};
  position: ${(props) => (props?.left || props?.right ? 'absolute' : 'initial')};
  left: ${(props) => props.left ?? 'unset'};
  right: ${(props) => props.right ?? 'unset'};
  ${(props) =>
    props?.withTransformAnimation ? `transform: rotate(${props?.startTransformAnimation ? '135deg' : '0deg'});` : ''}
`;
