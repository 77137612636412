import React from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import {
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  RefundVoidPricingInfo,
  Title,
} from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import { useVoid } from 'lib/services';
import { useNavigate } from 'react-router-dom';

const MobileTransactionVoidReview = () => {
  const { transactionInProcess } = useStore();
  const navigate = useNavigate();
  const { voidTransaction } = useVoid({ transactionInProcess });

  const onBackClick = () => {
    navigate('/transactions');
  };
  return (
    <Container pageTitle="Void Transaction">
      <DetailContainer>
        <Title>Are you sure you want to void this transaction?</Title>
        <Description>
          You can void a transaction within the first <b>2 hours</b> of a completed transaction. This is an easy way to
          correct mistakes, such as an incorrect purchase amount.
        </Description>
        <RefundVoidPricingInfo
          transactionInProcess={transactionInProcess}
          amount={transactionInProcess?.amount}
          type="VOID"
        />
        <FooterContainer>
          <ButtonContainer>
            <Button color={theme.main.red} onClick={voidTransaction}>
              Yes, Void Transaction
            </Button>
            <Button secondary={true} onClick={onBackClick}>
              Cancel
            </Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionVoidReview;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
