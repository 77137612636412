import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { BellIcon, Loading } from 'lib/components';
import client from 'lib/graphql/client';
import {
  FETCH_USER_NOTIFICATION_LIST,
  FETCH_USER_NOTIFICATION_LIST_STATISTICS,
  MUTATION_USER_NOTIFICATION,
} from 'lib/graphql/queries';
import { useOnClickOutside } from 'lib/hooks';
import StorageService from 'lib/services/Storage';
import { NotificationItem } from './components/NotificationItem';
import { NotificationBase, UserNotificationList, UserNotificationStatistics } from './type';

interface NotificationProps {
  isTierBadgeVisible: boolean;
}
export const Notification = (props: NotificationProps) => {
  const alert = useAlert();

  const defaultUserNotificationStatistics = {
    unReadCount: 0,
    unSeenCount: 0,
  };

  const defaultUserNotificationList = {
    total: 0,
    contents: [],
  };

  const { pathname } = useLocation();

  const notificationDropdownRef = useRef<HTMLDivElement | null>(null);
  const notificationOffSet = useRef<number>(0);
  const notificationDiv = useRef<HTMLDivElement | null>(null);
  const notificationList = useRef<NotificationBase[] | null>(null);
  const notificationTotal = useRef<number>(0);

  const loader = useRef<HTMLDivElement | null>(null);

  const [currentPathname, setCurrentPathname] = useState('');
  const [notificationDropdownVisible, setNotificationDropdownVisible] = useState(false);
  const [userNotificationStatistics, setUserNotificationStatistics] = useState<UserNotificationStatistics>(
    defaultUserNotificationStatistics,
  );
  const [userNotificationList, setUserNotificationList] = useState<UserNotificationList>(defaultUserNotificationList);
  const [notificationLoader, setNotificationLoader] = useState(false);

  const closeNotificationDropdown = () => {
    setNotificationDropdownVisible(false);
  };
  useOnClickOutside(notificationDropdownRef, closeNotificationDropdown);

  useEffect(() => {
    setCurrentPathname(pathname);
  }, []);

  useEffect(() => {
    if (pathname !== currentPathname) {
      const { token } = StorageService.getAuthData();
      setCurrentPathname(pathname);
      if (token) {
        fetchUserNotificationListStatistics();
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (notificationDropdownVisible) {
      const option = {
        root: null,
        rootMargin: '20px',
        threshold: 0,
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader.current) observer.observe(loader.current);
    }
  }, [notificationDropdownVisible]);

  useEffect(() => {
    if (notificationList.current && notificationList?.current?.length > 5) {
      calculateNotificationListForMarkAsSeen();
    }
  }, [notificationList.current]);

  const calculateNotificationListForMarkAsSeen = () => {
    const seenNotificationArray: string[] = [];
    notificationList.current?.forEach((element) => {
      if (!element.statistics?.hasSeen) {
        seenNotificationArray.push(element.id);
      }
    });
    if (seenNotificationArray.length > 0) {
      mutationNotificationList('SEEN', seenNotificationArray);
    }
  };

  const setAsRead = (notification) => {
    if (!notification?.statistics?.hasRead) {
      mutationNotificationList('READ', [notification?.id]);
    }
  };

  const setAsClicked = (notification) => {
    if (!notification?.statistics?.hasClicked) {
      mutationNotificationList('CLICKED', [notification?.id]);
    }
  };

  const toggleNotificationDropdown = async () => {
    setNotificationDropdownVisible(!notificationDropdownVisible);
    if (!notificationDropdownVisible) {
      notificationList.current = [];
      await fetchUserNotificationList(0);
      calculateNotificationListForMarkAsSeen();
    }
  };

  const mutationNotificationList = async (markType: string, idList: string[]) => {
    try {
      await client.mutate({
        mutation: MUTATION_USER_NOTIFICATION,
        variables: {
          input: {
            markType,
            notificationIds: idList,
          },
        },
      });
      fetchUserNotificationListStatistics();
    } catch (e: any) {
      alert.error('An error occurred');
    }
  };

  const fetchUserNotificationList = async (offSet) => {
    setNotificationLoader(true);
    try {
      const { data: { getUserNotificationsList } = {} } = await client.query({
        query: FETCH_USER_NOTIFICATION_LIST,
        variables: {
          input: {
            pagination: {
              limit: 5,
              offset: offSet,
              order: 'DESC',
              orderBy: 'createdAt',
            },
          },
        },
      });
      if (getUserNotificationsList.total > 0) {
        const existList = notificationList.current || [];
        const newList = [...existList, ...getUserNotificationsList.contents];
        const newNotificationList = {
          ...getUserNotificationsList,
          contents: newList,
        };
        notificationList.current = newList;
        notificationTotal.current = getUserNotificationsList.total;
        setUserNotificationList(newNotificationList);
      }
    } catch (e) {
      alert.error('An error occurred.');
    } finally {
      setNotificationLoader(false);
    }
  };

  const fetchUserNotificationListStatistics = useCallback(async () => {
    try {
      const { data: { getUserNotificationStatistics } = {} } = await client.query({
        query: FETCH_USER_NOTIFICATION_LIST_STATISTICS,
      });
      if (getUserNotificationStatistics) {
        setUserNotificationStatistics(getUserNotificationStatistics);
      }
    } catch (e) {
      alert.error('An error occurred.');
    }
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (notificationList?.current && notificationTotal?.current > notificationList?.current?.length) {
        notificationOffSet.current = notificationOffSet?.current + 1;
        fetchUserNotificationList(notificationOffSet?.current);
      }
    }
  }, []);

  return (
    <NotificationListContainer ref={notificationDropdownRef}>
      <DropDownLi>
        <Dropbtn onClick={toggleNotificationDropdown}>
          {userNotificationStatistics?.unSeenCount > 0 ? (
            <>
              {' '}
              <IconWCountContainer>
                <span className="count">{userNotificationStatistics?.unReadCount}</span>
                <BellIcon />
              </IconWCountContainer>{' '}
            </>
          ) : (
            <BellIcon />
          )}
        </Dropbtn>
        {notificationDropdownVisible && (
          <NotificationDropDownContent isTierBadgeVisible={props.isTierBadgeVisible} ref={notificationDiv}>
            <NotificationSpanContainer>
              <span>
                Notifications
                {userNotificationStatistics?.unReadCount > 0 && (
                  <span>({userNotificationStatistics?.unReadCount})</span>
                )}
              </span>
            </NotificationSpanContainer>
            {userNotificationList.contents?.map((content, index) => {
              return (
                <div id={content.id} key={`${index}_${content.id}`}>
                  <NotificationItem
                    notificationInformation={content}
                    key={`${index}_${content.id}`}
                    setAsRead={setAsRead}
                    setAsClicked={setAsClicked}
                  />
                </div>
              );
            })}
            {notificationLoader && (
              <NotificationLoaderContainer>
                <Loading size={20} />
              </NotificationLoaderContainer>
            )}
            <div ref={loader} />
          </NotificationDropDownContent>
        )}
        {notificationDropdownVisible && <UpArrow isTierBadgeVisible={props.isTierBadgeVisible} />}
      </DropDownLi>
    </NotificationListContainer>
  );
};

const IconWCountContainer = styled.div`
  position: relative;
  .count {
    background-color: ${(props) => props.theme.main.red};
    color: #ffffff;
    border-radius: 20px;
    position: absolute;
    right: 5px;
    font-weight: 700;
    display: block;
    z-index: 2;
    width: 16px;
    font-size: 9px;
    height: 12px;
    left: 8px;
  }
  svg,
  .count {
    animation: bellshake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backface-visibility: hidden;
    transform-origin: top right;
    animation-iteration-count: infinite;
  }
  @keyframes bellshake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(5deg);
    }
    30% {
      transform: rotate(-5deg);
    }
    45% {
      transform: rotate(4deg);
    }
    60% {
      transform: rotate(-4deg);
    }
    75% {
      transform: rotate(2deg);
    }
    85% {
      transform: rotate(-2deg);
    }
    92% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
  display: inline-flex;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  align-items: center;
  color: ${(props) => props.theme.main.black};
`;

const DropDownLi = styled(StyledLi)`
  display: inline-block;
  color: ${(props) => props.theme.main.black};
`;

const NotificationListContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.main.neutralGray}`};
  color: ${(props) => props.theme.main.black};
  margin-left: 12px;
  border-radius: 5px;
  padding: 0px 10px;
  position: relative;
`;

const NotificationDropDownContent = styled.div<{ isTierBadgeVisible: boolean }>`
  width: 327px;
  max-height: 290px;
  height: fit-content;
  position: absolute;
  overflow-y: scroll;
  background: ${(props) => props.theme.main.white};
  margin-top: 30px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 6px 6px;
  padding: 0px 0px 16px 0px;
  left: 0;
  transform: translate(-40%);
  &::-webkit-scrollbar {
    display: none;
  }
  &: {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const UpArrow = styled.div<{ isTierBadgeVisible: boolean }>`
  position: absolute;
  right: 2px;
  width: 0;
  height: 0;
  border-left: 32px solid transparent;
  border-right: 32px solid transparent;
  border-bottom: 50px solid white;
`;

const NotificationSpanContainer = styled.div`
  margin: 16px 0px 8px 0px;
  display: flex;
  justify-content: center;
  span {
    color: ${(props) => props.theme.main.black};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0e202f;
  }
`;

const NotificationLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
