import { isEmail } from 'class-validator';
import { Ripples } from 'lib/components';
import React, { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { EmailInputIcon, Loading } from 'lib/components';

export interface EmailInputProps {
  onBlur?: (value?: string) => void;
  onClickSend?: (value?: string) => void;
  loading?: boolean;
  defaultValue?: string;
}

export const EmailInput = ({ onBlur, onClickSend, loading, defaultValue }: EmailInputProps) => {
  const [valid, setValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState('');

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const data = event.target?.value;
    const isValid = isEmail(data);

    setValue(data);
    setValid(isValid);
    setShowError(data === '' ? false : !isValid);
  };

  useEffect(() => {
    const data = defaultValue;
    if (data) {
      const isValid = isEmail(data);

      setValue(data);
      setValid(isValid);
      setShowError(data === '' ? false : !isValid);
    }
  }, [defaultValue]);

  const blurHandler: FocusEventHandler<HTMLInputElement> = (event) => {
    onChange(event);
    onBlur?.(value);
  };

  const sendHandler = () => {
    onClickSend?.(value);
  };

  return (
    <Wrapper>
      <FormContainer>
        <CustomInputMask
          data-testid="email-input"
          className={showError ? 'error' : 'valid'}
          defaultValue={value}
          onChange={onChange}
          onBlur={blurHandler}
          onInput={onChange}
        >
          {(inputProps) => <Input {...inputProps} placeholder="first.last@mail.com" type="email" />}
        </CustomInputMask>
        <AbsoluteIconWrapper>
          <EmailInputIcon />
        </AbsoluteIconWrapper>

        <Ripples>
          <SendButton data-testid="submit" disabled={!valid || loading} onClick={sendHandler}>
            {loading ? <CustomLoading /> : 'Send Email'}
          </SendButton>
        </Ripples>
      </FormContainer>

      <ErrorContainer>{showError ? 'Please enter a valid email address' : ''}</ErrorContainer>
    </Wrapper>
  );
};

const CustomLoading = styled(Loading)`
  width: 40px;
`;

const Wrapper = styled.div`
  display: grid;
  margin-left: 24px;
  margin-top: 12px;
  font-family: 'Open Sans', sans-serif;
`;

const ErrorContainer = styled.div`
  position: relative;
  font-size: 10px;
  margin-top: 4px;
  color: #ec3360;
`;

const FormContainer = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (!props.isFullHeight ? '8px' : '32px')};
  position: relative;
  width: 328px;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #ccc;
  border-right: none;
  padding: 8px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

const SendButton = styled.button`
  white-space: nowrap;
  background-color: ${(props) => props.theme.main.green};
  color: #fff;
  border-radius: 0px 2px 2px 0px;
  border: none;
  width: 100%;
  font-size: 16px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    background-color: #c4c4c4;
  }
`;

const AbsoluteIconWrapper = styled.div`
  position: absolute;
  margin-left: -88%;
`;

const CustomInputMask = styled(InputMask)`
  padding-left: 30px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;

  &.error {
    border: 1px solid #ff0000;
  }
`;
