import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';

import { APP_STATIC_URL } from 'config';
import { ButtonPrimary, DashCard, PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Column, Container, Row } from 'lib/styles';
import { FacebookIcon, LinkedInIcon, MailIcon, TwitterIcon, WhatsappIcon } from './SidebarIcons';

const Referral = () => {
  const alert = useAlert();

  const [staticFile, setStaticFile] = useState<any>();
  const { isSnackbarVisible } = useSnackbar();

  const [referralData] = useState<any>({});
  const [reportData] = useState<any>({});

  useEffect(() => {
    const fetchStaticFile = async () => {
      const result = await axios.get(APP_STATIC_URL);
      setStaticFile(result.data);
    };
    fetchStaticFile();
  }, []);

  const onCodeCopied = () => {
    navigator.clipboard.writeText(`https://refer.withcherry.com/l/${referralData.referralCode}`);
    alert.success('Referral Link copied to clipboard.');
  };

  const getCodeHandler = () => {
    window.open('https://refer.withcherry.com/v2/1/register', '_blank');
  };

  const title = 'Cherry Referral Program';

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Referrals" marginBottom="16px" />

      <Column>
        <DashCard>
          <ContainerRow>
            <ContainerItem>
              <Title>Refer & Earn Up To $1,000</Title>
              <Text>If you have a friend at another practice, you can earn up to $1,000 when you refer them.</Text>
              <Title>Your Referral Code</Title>
              {!referralData.referralCode ? (
                <CustomButtonPrimary data-testid="referralButton" text="Get Referral Code" onClick={getCodeHandler} />
              ) : (
                <CodeContainer>
                  <Link href={`https://refer.withcherry.com/l/${referralData.referralCode}`}>
                    {`https://refer.withcherry.com/l/${referralData.referralCode}`}
                  </Link>
                  <Row>
                    <ButtonContainer>
                      <Row>
                        <CustomButtonPrimary text="Copy Code" onClick={onCodeCopied} />
                      </Row>
                      <Row>
                        <Flex>
                          <ShareText>Quick Share</ShareText>
                          <FacebookShareButton
                            url={referralData.facebookShareUrl || referralData.webShareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={referralData.twitterShareUrl || referralData.webShareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={referralData.webShareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedInIcon />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            url={referralData.whatsAppShareUrl || referralData.webShareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon />
                          </WhatsappShareButton>
                          <EmailShareButton
                            url={referralData.emailShareUrl || referralData.webShareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                          >
                            <MailIcon />
                          </EmailShareButton>
                        </Flex>
                      </Row>
                    </ButtonContainer>
                  </Row>
                </CodeContainer>
              )}
            </ContainerItem>
            <ContainerItem>
              <Cover src="referral.png" />
            </ContainerItem>
          </ContainerRow>
        </DashCard>
      </Column>
      {referralData.referralCode && (
        <Column>
          <DashCard>
            <Title>Referral Status</Title>
            <Text>Number of Referrals: {reportData?.referralsCount}</Text>
            <Text>
              Referral Pending: {reportData?.referralsPending} ({`$${reportData?.rewardsPendingAmount}`})
            </Text>
            <Text>
              Referrals Approved: {reportData?.referralsApproved} ({`$${reportData?.rewardsIssuedAmount}`})
            </Text>
          </DashCard>
        </Column>
      )}
      <Column>
        <DashCard>
          <Title>How does the referral program work?</Title>
          <Text>
            Share your referral code. When your referral transacts within their first 30 days with Cherry, you receive
            up to $1,000. Their transaction total determines your payout.
          </Text>
          <ContainerRow>
            <ReferralPayoutList>
              {staticFile?.REFERRAL?.PAYOUT?.map((data, index) => (
                <ReferralPayoutListItem key={index}>
                  <ReferralPayoutListItemDescription>{data.value}</ReferralPayoutListItemDescription>{' '}
                  <ReferralPayoutListItemDescription>{data.amount}</ReferralPayoutListItemDescription>
                </ReferralPayoutListItem>
              ))}
            </ReferralPayoutList>
          </ContainerRow>
        </DashCard>
      </Column>
    </Container>
  );
};

export default Referral;

const ReferralPayoutList = styled.ul`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;

  li:not(:first-child) {
    border-bottom: 1px solid #dadada;
  }
  li:first-child {
    font-weight: 700;
  }
  li:first-child,
  li:last-child {
    border-bottom: 1px solid #879097;
  }
`;

const ReferralPayoutListItem = styled.li`
  justify-content: space-between;
  display: flex;
  padding: 16px 0;
  align-items: center;

  span:last-child {
    min-width: 150px;
    text-align: center;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const ReferralPayoutListItemDescription = styled.span`
  position: relative;
  font-size: 14px;
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: flex-start;
`;

const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cover = styled.img`
  height: 180px;
  width: auto;
  margin-left: 10px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 16px;
`;

const CodeContainer = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  flex-direction: column;
`;

const Link = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #00c37d;
`;

const CustomButtonPrimary = styled(ButtonPrimary)`
  width: 200px;
  margin-bottom: 16px !important;
  text-transform: unset !important;
`;

const ShareText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  margin-left: 16px;
  margin-right: 18px;
`;
