import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { DropDown } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { LocationManagementStepEnums, PocUser } from 'lib/types';
import {
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContentInherit,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';

import { CreateMerchant } from './type';

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onChangeHandler: (key, value) => void;
  merchant: CreateMerchant;
}

export const PointOfContact = ({ handleClose, onStepChange, onChangeHandler, merchant }: Props) => {
  const { pocUsers } = useStore();

  useEffect(() => {
    const adminPocUsers = pocUsers?.filter((item) => item.role === 'ADMIN');
    if (adminPocUsers?.length > 0) {
      const { user, role } = adminPocUsers[0];
      const userObject = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role,
      };
      onChangeHandler('poc', userObject);
      onChangeHandler('id', user.id);
    }
  }, []);

  const options = pocUsers
    ?.filter((item) => item.role === 'ADMIN')
    .map((userData: PocUser) => {
      const { user, id } = userData;
      return {
        value: id,
        label: `<span>${user.firstName} ${user.lastName}</span>`,
      };
    });

  const onContinueHandler = () => {
    if (merchant?.poc?.phone) {
      onStepChange(LocationManagementStepEnums.LOCATION_ADDRESS);
    } else {
      onStepChange(LocationManagementStepEnums.ADD_PHONE_NUMBER);
    }
  };

  const onAddUserHandler = () => {
    onStepChange(LocationManagementStepEnums.ADD_USER);
  };

  const pocChangeHandler = (e) => {
    const findUser = pocUsers?.find((poc) => poc.id === e.value || poc.user.id === e.value || poc.id === e.value);
    if (findUser) {
      const { user, role } = findUser;
      const userObject = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role,
      };
      onChangeHandler('poc', userObject);
      onChangeHandler('id', user.id);
    }
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Point of Contact
      </CherryDialogTitle>
      <SubTitle>
        Tell us about the point of contact for this location. This should be the owner or manager of the physical
        location. Choose an existing user, or create a new one.
      </SubTitle>
      <DialogContentInherit>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <DropDownBorder style={{ width: '100%', height: '50px', padding: '3px' }}>
              <DropDown maxHeight={'265'} options={options} onChange={pocChangeHandler} hoveredMode={false} />
            </DropDownBorder>
          </Grid>
        </Grid>
      </DialogContentInherit>

      <LinkButton onClick={onAddUserHandler}>Create New User</LinkButton>
      <ContinueButton onClick={onContinueHandler} disabled={!merchant.id} data-testid="submit">
        Continue
      </ContinueButton>
    </DialogContainer>
  );
};

const LinkButton = styled.span`
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #00c37d;
  justify-content: center;
  display: flex;
  margin-top: 16px;
`;
