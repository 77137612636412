import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'lib/components/mobile';
import { useCheckoutWithPatient } from 'lib/services';
import { Application } from 'lib/types';
import { formatPhoneNumber } from 'lib/utils';

import { useSegment } from 'lib/hooks/useSegment';
import { Borrower, Loan } from 'pages/desktop/CheckoutWithPatient/types';
import { usePaymentMethods } from '../PaymentMethodContext/PaymentMethodContext';
import Section from '../Section';
import CheckoutStatusTracker from './CheckoutStatusTracker';
import ReviewStepInfo from './ReviewStepInfo';
import {
  ButtonContainer,
  ErrorContainer,
  ErrorText,
  ReturnHomeButton,
  ReviewStepContainer,
  ReviewText,
  SecondaryBackButton,
  SecondaryContinueButton,
} from './styles';

interface Props {
  checkoutData: {
    borrower?: Borrower;
    loan?: Loan;
    applicationDetail?: Application;
  };
  currentStep: number;
  setStep: (stepIndex: number) => void;
  onSuccess: () => void;
  onError: () => void;
  onCheckoutLinkSent: React.Dispatch<React.SetStateAction<boolean>>;
}

const REVIEW_STEP_INDEX = 2;

const ReviewStep = ({ checkoutData, currentStep, setStep, onCheckoutLinkSent, onError, onSuccess }: Props) => {
  const { borrower, loan } = checkoutData || {};
  const navigate = useNavigate();
  const { trackSegmentEvent } = useSegment();
  const { setIsPatientInReviewMode } = usePaymentMethods();

  const segmentEventData = { borrowerId: borrower?.id, loanId: loan?.id };
  const { sendCheckoutConfirmationLink } = useCheckoutWithPatient();
  const [shouldPollStatus, setShouldPollStatus] = useState<boolean>(false);
  const [checkoutError, setCheckoutError] = useState<boolean>(false);
  const [isPrevSectionsInEditState, setIsPrevSectionsInEditState] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isFormReadyForSubmit =
    !isPrevSectionsInEditState &&
    loan?.productId &&
    loan?.downPaymentPaymentMethodId &&
    loan?.installmentPaymentMethodId;

  const manageReviewStepExpand = (isOpen: boolean) => {
    setIsExpanded(isOpen);
  };
  useEffect(() => {
    if (currentStep === REVIEW_STEP_INDEX) {
      setIsPrevSectionsInEditState(false);
      manageReviewStepExpand(true);
    } else {
      setIsPrevSectionsInEditState(true);
      manageReviewStepExpand(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (shouldPollStatus) {
      setIsPatientInReviewMode(true);
    } else {
      setIsPatientInReviewMode(false);
    }
  }, [shouldPollStatus]);

  const navigateToDashboard = () => {
    navigate('/dashboard', { state: { force: true } });
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.LOAN_ERROR_VOIDED_BACK_TO_HOME_CLICKED', segmentEventData);
  };

  const handleCheckoutSubmit = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.TEXT_LINK_TO_PATIENT_CLICKED', segmentEventData);
    sendConfirmationLink();
  };

  const sendConfirmationLink = async () => {
    const sendLinkStatus = await sendCheckoutConfirmationLink(checkoutData?.loan?.id);
    if (sendLinkStatus) {
      onCheckoutLinkSent(true);
      setShouldPollStatus(true);
      setCheckoutError(false);
    } else {
      handleErrors(true);
    }
  };

  const handleErrors = (error) => {
    setCheckoutError(error);
    onError();
  };

  const handleBack = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.STEP_3_BACK_CLICKED', segmentEventData);
    setStep(REVIEW_STEP_INDEX - 1);
    setIsPrevSectionsInEditState(true);
    manageReviewStepExpand(false);
  };

  return (
    <Section title="Step 3: Review and Confirm" isExpanded={isExpanded} setIsExpanded={manageReviewStepExpand}>
      <ReviewStepContainer>
        {!checkoutError ? (
          <>
            {shouldPollStatus && loan && borrower ? (
              <CheckoutStatusTracker
                onSuccess={onSuccess}
                borrowerId={borrower?.id}
                phone={formatPhoneNumber(borrower?.phone)}
                resendConfirmationLink={sendConfirmationLink}
                loanId={loan?.id}
                handleCheckoutErrors={handleErrors}
                error={checkoutError}
              />
            ) : (
              <>
                {isFormReadyForSubmit ? (
                  <>
                    <ReviewText>
                      We’ll text the loan agreement to{' '}
                      <b>
                        {borrower?.firstName} {borrower?.lastName}{' '}
                      </b>
                      at <b>{formatPhoneNumber(borrower?.phone)}</b>. Once sent, you can’t edit plan or payment method,
                      but you can always cancel.
                    </ReviewText>
                    <ButtonContainer>
                      <SecondaryBackButton className="mt-2" disabled={false} onClick={handleBack}>
                        Back
                      </SecondaryBackButton>
                      <SecondaryContinueButton className="mt-2" onClick={handleCheckoutSubmit}>
                        Text Patient Link to Sign & Confirm
                      </SecondaryContinueButton>
                    </ButtonContainer>
                  </>
                ) : (
                  <ReviewStepInfo />
                )}
              </>
            )}
          </>
        ) : (
          <ErrorContainer>
            <Icon src="warning_outline" width={64} height={64} />
            <ErrorText>Something went wrong. Please try again.</ErrorText>
            <ReturnHomeButton className="mt-2" disabled={false} onClick={navigateToDashboard}>
              Return to Applications Home
            </ReturnHomeButton>
          </ErrorContainer>
        )}
      </ReviewStepContainer>
    </Section>
  );
};

export default ReviewStep;
