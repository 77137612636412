import styled from 'styled-components';

export const Container = styled.div<{ useElegantTheme?: boolean }>`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  max-width: ${(props) => (props?.useElegantTheme ? 260 : 240)}px;
  min-width: ${(props) => (props?.useElegantTheme ? 220 : 200)}px;
  padding-bottom: 24px;
`;

export const Shadow = styled.div`
  box-shadow: 6px 24px 18px -16px rgb(121 131 136 / 75%);
  border-radius: 0 0 8px 8px;
`;

export const Header = styled.div<{ isActive: boolean; useElegantTheme?: boolean }>`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => (props.isActive ? props.theme.main.green : '#56636d')};

  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${(props) => props?.useElegantTheme && 'font-family: Graphik'}
`;

export const ContentContainer = styled.div<{ isActive: boolean; isCheckoutStep: boolean; useElegantTheme?: boolean }>`
  padding: 12px 16px 4px;
  text-align: left;

  border-left: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};
  border-right: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};

  border-bottom: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};

  border-radius: 0 0 8px 8px;

  ${(props) =>
    !props.isCheckoutStep && {
      background: `${props.theme.main.white}`,
      textAlign: 'left',
    }}
`;

export const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const ContentTitle = styled.span<{ useElegantTheme?: boolean; isNotActive?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: ${(props) => props.theme.main[props.isNotActive ? 'midnightBlue70' : 'green']};

  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  text-transform: uppercase;
  letter-spacing: ${(props) => props.useElegantTheme && '1px'};
`;

export const ContentText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Petersburg'};
  font-weight: ${(props) => (props.useElegantTheme ? 400 : 700)};
`;

export const ContentSubText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  font-style: ${(props) => (props.useElegantTheme ? 'none' : 'italic')};
`;

export const ContentBorder = styled.div<{ isAlle?: boolean }>`
  border: ${(props) => (props.isAlle ? '1px solid #E6E6E6' : `1px dashed ${props.theme.main.grayColor}`)};
`;

export const ButtonContainer = styled.div.attrs(() => ({
  'data-testid': 'change-down-payment-btn',
}))<{ isDisabled: boolean; isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #879097;
  border: 1px solid #879097;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;
  width: auto;
  margin: 8px 0 0;
  text-align: center;

  ${(props) =>
    props.isDisabled && {
      pointerEvents: 'none',
      borderColor: props.theme.main.neutralGray,
      color: props.theme.main.neutralGray,
    }}

  ${(props) =>
    props.isActive && {
      background: props.theme.main.green,
      borderColor: props.theme.main.green,
      color: props.theme.main.white,
    }}
`;

export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: 4px;

  color: #56636d;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.06px;
`;
