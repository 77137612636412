import { useMutation } from '@apollo/client';
import { APP_PREQUAL_URL } from 'config';
import client from 'lib/graphql/client';
import { SEND_ESTIMATE_EMAIL, SEND_ESTIMATE_SMS } from 'lib/graphql/mutations';
import { CREATE_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { GET_MERCHANT_DETAIL, GET_PAYMENT_ESTIMATOR_CALCULATION } from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { PaymentOptions, ShareEstimatePatientProps } from './types';
import { unformatPhone } from './utils';

export const usePaymentEstimatorPage = () => {
  const { organization } = useStore();
  const alert = useAlert();
  const { segmentEventHandler } = useSegment();

  const [sendSMSinProgress, setSendSMSinProgress] = useState(false);
  const [sendEmailInProgress, setEmailInProgress] = useState(false);
  const [selectedMerchantPhone, setSelectedMerchantPhone] = useState('');
  const [isSendSuccessfull, setIsSendSuccessfull] = useState(false);

  const [sendEstimateEmail] = useMutation(SEND_ESTIMATE_EMAIL);
  const [sendEstimateSms] = useMutation(SEND_ESTIMATE_SMS);
  const [createTreatmentPlan] = useMutation(CREATE_TREATMENT_PLAN);

  const applyLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice&utm_medium=email_estimate`;

  const generateTreatmentPlan = async (shareData: ShareEstimatePatientProps) => {
    try {
      const { data } = await createTreatmentPlan({
        variables: {
          input: {
            organizationId: organization?.id,
            customerFirstName: shareData?.firstName,
            customerLastName: shareData?.lastName,
            customerPhone: unformatPhone(shareData?.phone) || undefined,
            customerEmail: shareData?.email || undefined,
            treatmentCost: Number(shareData.treatmentCost),
            stage: 'PLAN_SENT',
            consultationSummary: shareData.notes ?? '',
            paymentOptionsMerchantId: shareData.paymentOptionsMerchantId || undefined,
          },
        },
      });

      if (!data?.createTreatmentPlan?.id) {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.TREATMENT_PLAN_CREATED`);
        alert.error('Error occured while creating treatment plan');
      } else {
        return data;
      }
    } catch (error) {
      const message = (error as Error)?.message || 'Error occured while creating treatment plan';
      alert.error(message);
    }
  };

  const sendPlanViaSMS = async (
    patientData: ShareEstimatePatientProps,
    planData: PaymentOptions,
    selectedLocation: string,
  ) => {
    try {
      setSendSMSinProgress(true);
      const { data } = await sendEstimateSms({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              amount: planData?.paymentAmount,
              consultationSummary: patientData?.notes,
              coordinatorName: '-',
              creditGradeType: 'GREAT',
              merchantId: Number(selectedLocation),
              patientName: `${patientData?.firstName} ${patientData?.lastName}`,
              phone: unformatPhone(patientData?.phone),
              term: planData?.term,
            },
          },
        },
      });
      if (data?.sendEstimateSms?.success) {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SEND_PLAN_VIA_SMS.SUCCES`);
        alert.success('Plan sent successfully');
        setIsSendSuccessfull(true);
      } else {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SEND_PLAN_VIA_SMS.ERROR`);
        alert.error('Error occured while sending plan');
      }
    } catch (error) {
      const message = (error as Error)?.message || 'Error occured while sending plan';
      alert.error(message);
    }
    setSendSMSinProgress(false);
  };

  const sendPlanViaEmail = async (
    patientData: ShareEstimatePatientProps,
    planData: PaymentOptions,
    selectedLocation: string,
  ) => {
    try {
      setEmailInProgress(true);
      const { data } = await sendEstimateEmail({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              amount: Number(patientData?.treatmentCost),
              applyLink,
              apr: planData?.apr,
              coordinatorName: '-',
              email: patientData?.email,
              financeCharge: planData?.financeCharge,
              financialSituation: 'GREAT',
              merchantName: selectedLocation,
              patientName: `${patientData?.firstName} ${patientData?.lastName}`,
              paymentAmount: planData?.paymentAmount,
              term: planData?.term,
              consultationSummary: patientData?.notes,
            },
          },
        },
      });
      if (data?.sendEstimateEmail?.success) {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SEND_PLAN_VIA_EMAIL.SUCCESS`);
        alert.success('Plan sent successfully');
        setIsSendSuccessfull(true);
      } else {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.SEND_PLAN_VIA_EMAIL.ERROR`);
        alert.error('Error occured while sending plan');
      }
    } catch (error) {
      const message = (error as Error)?.message || 'Error occured while sending plan';
      alert.error(message);
    }
    setEmailInProgress(false);
  };

  const generateEstimate = async (purchaseAmount: number) => {
    try {
      const { data } = await client.query({
        query: GET_PAYMENT_ESTIMATOR_CALCULATION,
        variables: {
          input: {
            amount: Number(purchaseAmount),
            organizationId: organization?.id,
          },
        },
      });
      if (data?.getPaymentEstimatorCalculation?.length > 0) {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.GET_PAYMENT_ESTIMATOR_CALCULATION.SUCCESS`, {
          data: { amount: purchaseAmount, organizationID: organization?.id },
        });

        return data?.getPaymentEstimatorCalculation;
      } else {
        segmentEventHandler(`PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.GET_PAYMENT_ESTIMATOR_CALCULATION.ERROR`, {
          data: { amount: purchaseAmount, organizationID: organization?.id },
        });
        alert.error('Error occured while estimating');
      }
    } catch (error) {
      const message = (error as Error)?.message || 'Error occured while estimating';
      alert.error(message);
    }
  };

  const fetchMerchantPhone = async (id) => {
    const {
      data: { getMerchantDetail },
    } = await client.query({
      query: GET_MERCHANT_DETAIL,
      variables: {
        input: {
          merchantIds: id,
        },
      },
    });
    setSelectedMerchantPhone(getMerchantDetail?.data[0]?.phone);
  };
  return {
    generateEstimate,
    generateTreatmentPlan,
    sendPlanViaSMS,
    sendPlanViaEmail,
    sendSMSinProgress,
    sendEmailInProgress,
    fetchMerchantPhone,
    selectedMerchantPhone,
    isSendSuccessfull,
  };
};
