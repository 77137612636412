import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { theme } from 'config/theme';
import { formatDate, formatPhoneNumber } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';

import { ReactComponent as ApprovedPdfIcon } from 'assets/images/approved-pdf-icon.svg';
import { APP_PREQUAL_URL } from 'config';
import dayjs from 'dayjs';
import QRCode from 'easyqrcodejs';
import { Loading } from 'lib/components';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import useStore from 'lib/hooks/useStore';
import { PreviewData } from 'pages/desktop/Dashboard/views/GenerateEstimate/GenerateEstimatePreview';

interface Props {
  page: number;
  previewData: PreviewData;
  paymentOptionsTableData: PaymentOptionsData[] | null;
}

interface PaymentOptionsData {
  apr: number;
  financeCharge: number;
  paymentAmount: number;
  term: number;
}

interface DataTable {
  id: number;
  planLength: string;
  monthlyPayment: string;
  totalInterest: string;
  apr: number;
  lowestMonthlyPayment: number;
}

const QR_CODE_CONFIG = {
  logo: images?.cherrySymbolDark,
  version: 5,
  width: 92,
  height: 92,
  autoColor: true,
  PO: '#00c37d',
  PI: '#0e1f2e',
  PO_TL: '#00c37d',
  PI_TL: '#00c37d',
  PO_TR: '#0e1f2e',
  PI_TR: '#00c37d',
  PO_BL: '#00c37d',
  PI_BL: '#0e1f2e',
};

const paymentTableColumns = [
  {
    name: 'Plan Length',
    selector: 'planLength',
  },
  {
    name: 'Monthly Payment',
    selector: 'monthlyPayment',
  },
  {
    name: 'Total Finance Charge',
    selector: 'totalInterest',
  },
];

const BASE_URL = APP_PREQUAL_URL?.substring(8);

export const SinglePageEstimatePDF = ({ page, previewData, paymentOptionsTableData }: Props) => {
  const { organization, merchants } = useStore();
  const [qrImage, setQrImage] = useState<string>('');
  const [approvedPatientTable, setApprovedPatientTable] = useState<DataTable[]>();

  const qrCodeRef = useRef<HTMLDivElement>(null);
  const treatmentPlan = previewData?.data?.data || previewData?.data;
  const isApplicationExpired = treatmentPlan?.application?.expireAt < dayjs().utc().endOf('day').toISOString();
  const lowestPrice = paymentOptionsTableData
    ? paymentOptionsTableData[paymentOptionsTableData?.length - 1]?.paymentAmount
    : 0;
  const isApprovedPatient = treatmentPlan?.application?.status === 'APPROVED' && !isApplicationExpired;
  const isPreApprovedPatient = treatmentPlan?.application?.status === 'PREAPPROVED' && !isApplicationExpired;

  const { getProductCalculation } = useProductCalculation();

  const getApprovedPatientPaymentOptions = async () => {
    const resp = await getProductCalculation(treatmentPlan?.applicationId, Number(previewData?.amount));

    const table = resp?.map((plan, index) => {
      return {
        id: index,
        planLength: `${plan?.product?.term} Months`,
        monthlyPayment: CurrencyUtil.toCurrencyString(plan?.installmentAmount, false),
        totalInterest: `${CurrencyUtil.toCurrencyString(plan?.financeCharge, false)} ( ${
          plan?.promo ? plan?.product?.promoApr : plan?.product?.apr
        }% APR )`,
        apr: plan?.promo ? plan?.product?.promoApr : plan?.product?.apr,
        lowestMonthlyPayment: resp[resp?.length - 1]?.installmentAmount,
      };
    });

    setApprovedPatientTable(table);
  };

  const getPaymentOptionsTableData = () =>
    paymentOptionsTableData?.map((column, index) => {
      return {
        id: index,
        planLength: `${column.term} Months`,
        monthlyPayment: CurrencyUtil.toCurrencyString(column.paymentAmount, false),
        totalInterest: `${CurrencyUtil.toCurrencyString(column?.financeCharge, false)} ( ${column?.apr}% APR )`,
        apr: column?.apr,
      };
    }) || [];

  const onQrRendered = useCallback((_, qrImg: string) => {
    setQrImage(qrImg);
  }, []);

  useEffect(() => {
    // tslint:disable-next-line no-unused-declaration
    const pdfQr = new QRCode(qrCodeRef.current, {
      text: `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=merchant&utm_medium=qr_code_estimate&utm_campaign=v1`,
      onRenderingEnd: onQrRendered,
      ...QR_CODE_CONFIG,
    });

    if (isApprovedPatient) {
      getApprovedPatientPaymentOptions();
    }
  }, []);

  const createPageContent = () => {
    const { patientName, selectedLocation, additionalNotes, amount } = previewData || {};

    const merchantObj = merchants?.[0];
    const location =
      selectedLocation ||
      (merchantObj && {
        name: merchantObj.name,
        address: merchantObj.address.address1,
        city: merchantObj.address.city.name,
        state: merchantObj.address.city.state?.code,
        zip: merchantObj.address.zip,
      });
    const tableData = approvedPatientTable || getPaymentOptionsTableData();

    return (
      <Page id="first-page-pdf">
        <Content>
          <Title>Monthly Payment Options</Title>
          <Row>
            <PatientNameContainer>
              <Text>
                Prepared for <CapitalizeText className="cohere-block">{patientName}</CapitalizeText> on{' '}
                {formatDate(new Date().toDateString())}.
              </Text>
              {isApprovedPatient && (
                <ApprovedAmount>
                  available balance: {CurrencyUtil.toCurrencyString(treatmentPlan?.application?.balanceAvailable)}
                </ApprovedAmount>
              )}
              {isPreApprovedPatient && (
                <InfoContainer>
                  <Chip>
                    Pre-Approved: {CurrencyUtil.toCurrencyString(treatmentPlan?.preApproval?.minimumAmount || 0, false)}{' '}
                    -{CurrencyUtil.toCurrencyString(treatmentPlan?.preApproval?.maximumAmount || 0, false)}
                  </Chip>
                  <Note>Exact APR will be calculated when you apply</Note>
                </InfoContainer>
              )}
            </PatientNameContainer>
            {location?.name && (
              <Column>
                <BoldText>{location.name}</BoldText>
                <Text>{location.address}</Text>
                <Text>{`${location.city} ${location.state} ${location.zip}`}</Text>
                <Text>{formatPhoneNumber(location.phone)}</Text>
              </Column>
            )}
          </Row>
          <Border />
          <Subtitle>Consultation Summary</Subtitle>
          <Gap />
          <AdditionalTextContainer className="cohere-block">
            <AdditionalText longText={additionalNotes ? additionalNotes.length > 490 : false}>
              {additionalNotes?.replace(/(^[ \t]*\n)/gm, '') || 'No summary.'}
            </AdditionalText>
          </AdditionalTextContainer>
          <Border />
          <Subtitle>Monthly Payment Options with Cherry</Subtitle>
          <Row>
            <PaymentColumn>
              <AmountTitle>Total Treatment Cost</AmountTitle>
              <AmountTextXL> {CurrencyUtil.toCurrencyString(amount, false)}</AmountTextXL>
              <SmallGap />
              <AmountTitle>
                {isApprovedPatient ? 'Your Payment Plan (Lowest Monthly Payment)' : 'Payment Plans as low as'}
              </AmountTitle>
              <AmountTextXL>
                {CurrencyUtil.toCurrencyString(
                  approvedPatientTable ? approvedPatientTable[0]?.lowestMonthlyPayment : lowestPrice,
                  false,
                )}
                <span id="month"> / month</span>
              </AmountTextXL>
              <EstimationText>For qualifying patients</EstimationText>
              <Row>
                {paymentTableColumns.map((tableHeader) => (
                  <TableHeaderText key={tableHeader?.selector}>{tableHeader.name}</TableHeaderText>
                ))}
              </Row>
              <TableBorder />
              {tableData?.length > 0 ? (
                tableData.map((tableRow) => (
                  <Row key={tableRow.id}>
                    <TableRowText>{tableRow.planLength}</TableRowText>
                    <TableRowText>{tableRow.monthlyPayment}</TableRowText>
                    <TableRowText bold={tableRow.apr === 0}>
                      {tableRow.totalInterest} {tableRow.apr === 0 && <ZeroAprIcon src={images.zeroApr.default} />}
                    </TableRowText>
                  </Row>
                ))
              ) : (
                <LoadingContainer>
                  <LoadingSpinner size={16} />
                </LoadingContainer>
              )}
              <TableBorder />
              <DownPaymentText>
                A down payment equal to the monthly payment is due at the time of purchase.
              </DownPaymentText>
            </PaymentColumn>
            <Column>
              <QRContainer>
                {isApprovedPatient ? (
                  <>
                    <QRHeaderText>Ready to use Cherry?</QRHeaderText>
                    <SlugTextExplanation>
                      Tell your practice you're <br /> ready to pay with Cherry
                    </SlugTextExplanation>
                    <ApprovedPdfIcon />
                  </>
                ) : (
                  <>
                    <QRHeaderText>
                      Affordable payment <br /> plans with Cherry!
                    </QRHeaderText>
                    <SlugTextExplanation>
                      <br />
                      Scan the QR code or visit
                      <br />
                      <SlugText>
                        {BASE_URL}/
                        <br />
                        {organization?.slug}
                      </SlugText>
                    </SlugTextExplanation>
                    <QRRow>{qrImage && <StyledQRImage src={qrImage} />}</QRRow>
                  </>
                )}
              </QRContainer>
            </Column>
          </Row>
        </Content>
        <SmallText>
          <Bold>
            {!isApprovedPatient &&
              'Disclaimer: This is an example only. Exact terms and APR depend on credit score and other factors.'}
            <br />
            <Row>
              <ZeroAprIcon src={images.zeroApr.default} />
              {isApprovedPatient
                ? '0% APR Payment Option'
                : '0% APR and other promotional rates subject to eligibility.'}
            </Row>
          </Bold>
        </SmallText>
        <Footer>
          <FooterContent>
            <DisclaimerTextParagraph>
              Payment options through Cherry Technologies, Inc. are issued by the following lending partners:
              https://withcherry.com/lending-partners. Iowa only: Borrowers are subject to Iowa state specific
              underwriting criteria. APR for all Iowa borrowers is capped at 20.99%.
            </DisclaimerTextParagraph>

            <CherryLogo src={images?.cherryLogoDark} />
          </FooterContent>
        </Footer>
      </Page>
    );
  };

  const pageContent = createPageContent();
  return (
    <PDFContainer>
      {pageContent}
      <Hidden>
        {<div ref={qrCodeRef} />}
        {pageContent}
      </Hidden>
    </PDFContainer>
  );
};

const InfoContainer = styled.div`
  margin-top: 4px;
`;

const Chip = styled.div`
  width: fit-content;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #e6f9f2;
  color: #00c37d;
  font-family: Open Sans;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const Note = styled.div`
  color: #0e202f;
  font-family: Open Sans;
  font-size: 8px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
`;

const Hidden = styled.div`
  height: 0;
  overflow: hidden;
`;

const PDFContainer = styled.div`
  width: 480px;
  height: 720px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;

  color: ${(props) => props.theme.main.black};

  margin-bottom: 17px;
`;

const PatientNameContainer = styled.div`
  max-width: 225px;
  margin-right: 45px;
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;

  text-transform: uppercase;
  color: ${(props) => props.theme.main.black};

  margin-bottom: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Text = styled.span`
  font-size: 8px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props) => props.theme.main.black};
`;

const BoldText = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
`;

const Border = styled.div`
  border: 0.788158px solid #e7e9ea;
  margin: 24px 0 18px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountTextXL = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const Gap = styled.div`
  margin-bottom: 16px;
`;

const SmallGap = styled.div`
  margin-bottom: 8px;
`;

const TableBorder = styled.div`
  width: 100%;
  height: 1px;
  background: #e7e9ea;
  margin: 4px 0;
`;
const TableRowText = styled.div<{ bold?: boolean }>`
  display: flex;
  color: var(--Midnight-Blue, #0e202f);
  font-family: 'Open Sans';
  font-size: 9px;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  line-height: normal;
  white-space: nowrap;
  width: 33%;
  margin-bottom: 4px;

  &:nth-child(1) {
    width: 28%;
  }
`;

const CherryLogo = styled.img`
  width: 90px;
  height: auto;
`;

const Footer = styled.div`
  display: block;

  height: 140px;
`;
const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 24px 24px 0;
  border-radius: 8px;
  border: 2px solid ${theme.main.midnightBlue10};

  @media print {
    border: none;
    zoom: 112%;
    padding: 0;
    margin: -45px -30px;
  }
`;

const Content = styled.div`
  height: 650px;
  margin-top: 6px;
  margin-bottom: 16px;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DisclaimerTextParagraph = styled.span`
  font-size: 8px;
  line-height: 12px;

  margin-top: 8px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const SmallText = styled.span`
  font-family: 'Open Sans';
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 150% */
  color: ${(props) => props.theme.main.black};
`;

const TableHeaderText = styled.span`
  font-family: 'Open Sans';
  font-size: 8px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  width: 33%;

  &:nth-child(1) {
    width: 28%;
  }
`;

const EstimationText = styled.span`
  font-size: 8px;
  font-weight: 400;
  line-height: 11px;
  color: ${(props) => props.theme.main.black};
  margin-top: 2px;
  margin-bottom: 8px;
`;

const AmountTitle = styled.span`
  font-size: 8px;
  font-weight: 400;
  line-height: 11px;
  color: ${(props) => props.theme.main.black};
  margin-bottom: 2px;
`;

const QRContainer = styled.div`
  display: flex;
  width: 180px;
  height: 250px;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  margin-left: 24px;
  border-radius: 8px;
  border: 2px solid var(--Kelly-Green, #00c37d);
  background: var(--kelly-green-5, #f2fffb);
`;

const QRRow = styled.div`
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  box-shadow: 0px 3.03556px 4.03196px 0px rgba(0, 0, 0, 0.03), 0px 7.05791px 9.37463px 0px rgba(0, 0, 0, 0.04),
    0px 12.67437px 16.83467px 0px rgba(0, 0, 0, 0.05), 0px 21.03477px 27.93931px 0px rgba(0, 0, 0, 0.06),
    0px 34.65407px 46.02907px 0px rgba(0, 0, 0, 0.07), 0px 60.56527px 80.44547px 0px rgba(0, 0, 0, 0.08),
    0px 131px 174px 0px rgba(0, 0, 0, 0.11);

  @media print {
    box-shadow: none;
  }
`;

const QRHeaderText = styled.span`
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ZeroAprIcon = styled.img`
  width: 12px;
  height: 12px;
`;
const PaymentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const AdditionalTextContainer = styled.div`
  max-height: 200px;
  overflow: hidden;
  word-wrap: break-word;
`;

const AdditionalText = styled.span<{ longText?: boolean }>`
  font-size: ${(props) => (props.longText ? '7px' : '10px')};
  font-weight: 400;
  line-height: ${(props) => (props.longText ? '15px' : '5px')};
  color: ${(props) => props.theme.main.black};
  white-space: break-spaces;
`;

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const StyledQRImage = styled.img`
  width: 92px;
  height: 92px;
`;

const SlugText = styled.div`
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 160px;
  overflow-wrap: break-word;
`;

const SlugTextExplanation = styled.div`
  text-align: center;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DownPaymentText = styled.span`
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  color: ${(props) => props.theme.main.black};
`;

const LoadingSpinner = styled(Loading)`
  margin: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  min-height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ApprovedAmount = styled(Chip)`
  margin-top: 8px;
`;
