import React from 'react';
import styled from 'styled-components';

const EmptyListMessage = () => {
  return <Message>You have no document requests.</Message>;
};

const Message = styled.span`
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 16px;
  font-weight: 400;
`;

export default EmptyListMessage;
