import { useApolloClient, useMutation } from '@apollo/client';
import { Button as CherryButton, Dropdown, PhoneNumberInput, TextInput } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { DashCard, DashComponent, Loading } from 'lib/components';
import { UPDATE_MERCHANT_COMMUNICATION_SETTINGS } from 'lib/graphql/mutations';
import { GET_MERCHANT_COMMUNICATION_SETTINGS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import { clearMaskFormat, websitePattern } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

interface FormValues {
  contactName: string;
  contactPhone: string;
  contactWeb: string;
}

interface Merchant {
  merchantId: string;
  displayName: string;
  phone: string;
  website: string;
}

const INITIAL_FORM_VALUES: FormValues = {
  contactName: '',
  contactPhone: '',
  contactWeb: '',
};

export const Communication = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const { merchants } = useStore();
  const [updateMerchantCommunicationSettings] = useMutation(UPDATE_MERCHANT_COMMUNICATION_SETTINGS);
  const [selectedMerchantId, setSelectedMerchantId] = useState<string>(merchants?.[0]?.id);
  const [merchantFinderInformation, setMerchantFinderInformation] = useState<Merchant>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [websiteHint, setWebsiteHint] = useState<string>('');
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_FORM_VALUES);

  const options =
    merchants?.map((merchant) => {
      return {
        value: merchant.id,
        label: `<b><span>${merchant?.name}</span> </b><br/>  ${merchant?.address?.address1} - ${merchant?.address.city?.name} `,
      };
    }) || [];

  useEffect(() => {
    fetchMerchantDetail();
  }, [merchants, selectedMerchantId]);

  useEffect(() => {
    const { contactName, contactPhone, contactWeb } = formValues;

    if (contactName && contactPhone && contactWeb) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValues]);

  const fetchMerchantDetail = async () => {
    setLoading(true);

    try {
      if (selectedMerchantId) {
        const { data } = await client.query({
          query: GET_MERCHANT_COMMUNICATION_SETTINGS,
          variables: {
            input: {
              merchantId: selectedMerchantId,
            },
          },
        });

        const merchantDetailInfo = data?.getMerchantCommunicationSettings;
        setMerchantFinderInformation(merchantDetailInfo);

        setFormValues({
          contactName: merchantDetailInfo?.displayName || '',
          contactPhone: merchantDetailInfo?.phone || '',
          contactWeb: merchantDetailInfo?.website || '',
        });
        setLoading(false);
      } else {
        throw new Error('Merchant ID not found!');
      }
    } catch (error: any) {
      alert.error(error?.message);
      setLoading(false);
    }
  };

  const onSaveChangesClicked = async () => {
    const { contactName, contactPhone, contactWeb } = formValues;

    const object = {
      displayName: contactName,
      phone: clearMaskFormat(contactPhone),
      website: contactWeb,
    };

    const { data } = await updateMerchantCommunicationSettings({
      variables: {
        input: {
          merchantId: String(merchantFinderInformation?.merchantId || selectedMerchantId),
          ...object,
        },
      },
    });

    if (data?.updateMerchantCommunicationSettings?.success) {
      alert.success('Communication settings has been updated successfully!');
    } else {
      alert.error('An error occurred!');
    }
  };

  const onChange = (e) => {
    setSelectedMerchantId(e?.target?.value);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target || {};

    if (event && name) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const onWebsiteBlur = (nativeEvent) => {
    if (websitePattern.test(nativeEvent?.currentTarget?.value)) {
      setFormValues({
        ...formValues,
        contactWeb: nativeEvent?.currentTarget?.value,
      });
      setWebsiteHint('');
    } else {
      setWebsiteHint('Please use a website with valid format, ex: www.yourwebsite.com');
    }
  };

  return (
    <Column margin="0">
      <DashComponent dashHeader={'Communication Settings'} />
      <DashCard>
        <Grid item={true} style={{ padding: '12px 0' }}>
          <Row fontSize={'14px'}>This information will be displayed in all patient-facing scenarios.</Row>
          <Row fontSize={'14px'}>
            <ul>
              <li>Booking appointments after approvals</li>
              <li>Text message communications</li>
              <li>Practice Finder search results</li>
            </ul>
          </Row>
          <Row fontSize={'14px'}>
            If no information is provided, the contact information for the account will be displayed instead.
          </Row>
        </Grid>
        <Row>
          <Grid container={true}>
            <Grid item={true} xs={7} style={{ margin: '16px 0' }}>
              <Dropdown
                options={options}
                onChange={onChange}
                value={selectedMerchantId}
                defaultValue={selectedMerchantId}
              />
            </Grid>
          </Grid>
        </Row>

        <Row>
          {loading ? (
            <LoadingContainer>
              <Loading size={16} />
            </LoadingContainer>
          ) : (
            <Grid container={true}>
              <Grid item={true} xs={7} style={{ margin: '16px 0' }}>
                <SubHeader size={'14px'} marginBottom="8px">
                  Contact Information
                </SubHeader>
                <TextInput
                  name="contactName"
                  defaultValue={formValues.contactName}
                  type="text"
                  onChange={onInputChange}
                  label="Display Name"
                  inputSize="medium"
                  margin="dense"
                />
                <PhoneNumberInput
                  data-testid="application-phone-input"
                  name="contactPhone"
                  value={formValues.contactPhone}
                  onChange={onInputChange}
                  label="Phone"
                  inputSize="medium"
                  margin="dense"
                  hideIcon={true}
                />
                <TextInput
                  name="contactWeb"
                  type="text"
                  value={formValues.contactWeb}
                  onBlur={onWebsiteBlur}
                  onChange={onInputChange}
                  label="Website"
                  error={Boolean(websiteHint)}
                  errorText={websiteHint}
                  inputSize="medium"
                  margin="dense"
                />
              </Grid>
            </Grid>
          )}
        </Row>

        <Grid item={true}>
          <CherryButton
            variant="primary"
            data-testid="requestPlanChangeButton"
            disabled={disabled}
            onClick={onSaveChangesClicked}
          >
            Save Changes
          </CherryButton>
        </Grid>
      </DashCard>
    </Column>
  );
};

const LoadingContainer = styled.div`
  min-height: 284px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
