import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { ButtonPrimary, SecondaryButton } from 'lib/components';
import { DownCircle, Popover, PopoverContent, PopoverText, PopoverWrapper } from 'pages/desktop/Dashboard/views';
import styled from 'styled-components';

export const Accordion = styled(MuiAccordion)`
  padding: 24px;
  margin-bottom: 24px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04), 0px 6px 9px 0px rgba(0, 0, 0, 0.07) !important;

  .MuiAccordionSummary-content.Mui-expanded {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.main.neutralGray};
  }

  &:last-child {
    margin-bottom: 48px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  button:disabled {
    opacity: 0.35;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)``;

export const StepHeader = styled.div`
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const BlackOutlineButton = styled(SecondaryButton)`
  display: flex;
  padding: 15px 31px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.main.midnightBlue} !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  height: auto;
  width: auto;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.main.midnightBlue} !important;
`;

export const GreenFillButton = styled(ButtonPrimary)`
  padding: 15px 16px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
`;

export const StyledPopover = styled(Popover)`
  top: 0;
  right: 132px;
  left: unset;
  bottom: auto;
  width: 320px;
  min-height: 98px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  width: 100%;
`;

export const StyledDownCircle = styled(DownCircle)`
  top: unset;
  right: -24px;
  /* transform: rotate(180deg); */
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid ${(props) => props?.theme?.main?.green};
`;

export const StyledPopoverText = styled(PopoverText)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
export const StyledPopoverExplanation = styled(PopoverText)`
  margin-top: 8px;
`;

export const StyledPopoverContent = styled(PopoverContent)`
  padding: 16px;
`;
