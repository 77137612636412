import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { DetailChartContainer, FixedDecimalFormat, ReportingPageLayout } from 'lib/components';
import { LineChart, StackBarChart } from 'lib/components/Charts';
import { DAILY_FREQUENCY_ENUM } from 'lib/constants';
import { FETCH_TRANSACTIONS_CONVERSATIONS } from 'lib/graphql/queries';
import { ReportingAnalyticsEventNames, useAnalytics, useOrganizationGroupTreeRadioGroup } from 'lib/hooks';
import { useDownloadChartData } from 'lib/hooks/useDownloadChartData';
import useStore from 'lib/hooks/useStore';
import { DropdownOption } from 'lib/types';

export const TransactionsConversion = () => {
  const client = useApolloClient();
  const { organization, selectedOrganizationGroup } = useStore();
  const { trackEvent } = useAnalytics();
  const { downloadChartCSV } = useDownloadChartData();
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>('All');
  const [transactions, setTransactions] = useState<any>();
  const [transactionsTimeBreak, setTransactionsTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(DAILY_FREQUENCY_ENUM.DAILY);
  const [conversion, setConversion] = useState<any>();
  const [conversionTimeBreak, setConversionTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(DAILY_FREQUENCY_ENUM.DAILY);
  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().endOf('day').toISOString(),
  });
  const { findNodeAndReturnArrays, organizationGroupTree } = useOrganizationGroupTreeRadioGroup();
  const [location, setLocation] = useState<DropdownOption>({ value: 'selectAll', label: 'All Accounts' });
  const [organizationIds, setOrganizationIds] = useState<string | null>(`${organization?.id}`);

  useEffect(() => {
    fetchSummaryChartData();
  }, [filterSelectedDateValue, transactionsTimeBreak, conversionTimeBreak, selectedMerchant, organizationIds]);

  const fetchSummaryChartData = async () => {
    const { data } = await client.query({
      query: FETCH_TRANSACTIONS_CONVERSATIONS,
      variables: {
        transactionInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: transactionsTimeBreak,
        },
        conversationInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: conversionTimeBreak,
        },
      },
    });
    setTransactions(data?.getTotalTransactionAmounts);
    setConversion(data?.getTransactionsConversationRates);
  };

  const onTransactionsTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setTransactionsTimeBreak(timeBreak);
  };

  const onTransactionsDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.TRANSACTION_DOWNLOAD_CLICK,
    });
    downloadChartCSV(transactions?.contents, ['name', 'data'], 'Date,Transaction Amount');
  };

  const onConversionTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setConversionTimeBreak(timeBreak);
  };

  const onConversionDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.TRANSACTION_CONVERSION_DOWNLOAD_CLICK,
    });
    downloadChartCSV(
      conversion?.contents,
      ['name', 'applicationCount', 'transactionCount'],
      'Date,Applications,Transactions',
    );
  };

  const onSelectedDateChange = (selectedDate: any) => {
    setFilterSelectedDateValue(selectedDate);
  };

  const organizationGroupSelectionHandler = (data) => {
    setLocation(data);
    if (data?.value === 'selectAll') {
      setOrganizationIds(String(organization?.id));
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setLocation({ value: 'selectAll', label: 'All Accounts' });
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    } else {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree[0],
        data.value,
        data.type,
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  };

  return (
    <ReportingPageLayout
      title={'Transactions'}
      onDateRangeChange={onSelectedDateChange}
      onLocationChange={setSelectedMerchant}
      organizationGroupSelectionHandler={organizationGroupSelectionHandler}
      selectedOrganizationGroup={location}
      selectedMerchant={selectedMerchant as string}
    >
      <DetailChartContainer
        id={'transactions'}
        mainStatistic={{
          header: 'Total Transactions Amount',
          description: <FixedDecimalFormat amount={transactions?.totalTransactionAmount} prefix="$" />,
        }}
        onTimeBreakSelected={onTransactionsTimeBreakSelected}
        onDownloadClick={onTransactionsDownloadClicked}
        statistics={[
          {
            header: 'Total Transactions',
            description: transactions?.totalTransactions,
          },
          {
            header: 'Avg Transactions Amount',
            description: <FixedDecimalFormat amount={transactions?.avgTransactionAmount} prefix="$" />,
          },
        ]}
      >
        <LineChart
          data={transactions?.contents}
          height={480}
          toolTipLabel={'Transaction Amount'}
          dataType="money"
          labelTitle="Transaction Amount"
        />
      </DetailChartContainer>
      <DetailChartContainer
        id={'conversion'}
        mainStatistic={{
          header: 'Approval Conversion Rate',
          description: <FixedDecimalFormat amount={conversion?.transactionConversionRate * 100} suffix="%" />,
        }}
        onTimeBreakSelected={onConversionTimeBreakSelected}
        onDownloadClick={onConversionDownloadClicked}
        statistics={[
          {
            header: 'Avg Approval Amount',
            description: <FixedDecimalFormat amount={conversion?.avgApprovalAmount} prefix="$" />,
          },
          {
            header: 'Avg Transaction Amount',
            description: <FixedDecimalFormat amount={conversion?.avgTransactionAmount} prefix="$" />,
          },
          {
            header: 'Avg Remaining Approval Balance',
            description: <FixedDecimalFormat amount={conversion?.avgRemainingApprovalBalance} prefix="$" />,
          },
        ]}
      >
        <StackBarChart
          data={conversion?.contents}
          height={480}
          firstDataLabel={'transactionCount'}
          secondDataLabel={'applicationCount'}
          firstToolTipLabel={'Transactions'}
          secondToolTipLabel={'Applications'}
          chartType="detail"
        />
      </DetailChartContainer>
    </ReportingPageLayout>
  );
};
