import dayjs from 'dayjs';
import { arrayToCsvForMultiline, chartDataParser, downloadBlobByContentType } from '../utils';

export const useDownloadChartData = () => {
  const downloadChartCSV = async (dataArray: any, dataMapper: any, chartHeader?: any) => {
    const csvFinal = chartDataParser(dataArray, dataMapper);
    const csv = arrayToCsvForMultiline(dataMapper, csvFinal, chartHeader);
    const fileName = `cherry_chart_${dayjs().format('MM_DD_YYYY_HH_mm')}`;
    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
  };

  return { downloadChartCSV };
};
