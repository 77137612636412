import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MIN_ELIGIBLE_DAY } from 'lib/utils';
import { useDownloadTransaction } from 'lib/hooks';
import { usePartialRefund } from './useRefund';

interface ModalVariables {
  visibility: boolean;
  title: string;
  description?: string;
  firstButton?: ButtonProps;
  secondButton?: ButtonProps;
}
interface DetailVariables {
  title: string;
  description: string;
  firstButton: string | null;
  firstButtonClick?: () => void;
  transactionType?: string;
}

interface ButtonProps {
  label?: string;
  icon?: string;
  onClick?: () => void;
  primary?: boolean;
}

enum ModalVariablesEnum {
  REFUND = 'REFUND',
  NO_REFUND = 'NO_REFUND',
  DOWNLOAD_TRANSACTION = 'DOWNLOAD_TRANSACTION',
  TRANSACTION_PROCESSING = 'TRANSACTION_PROCESSING',
  PROCESSING = 'PROCESSING',
}

enum StatusVariablesEnum {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  VOIDED = 'VOIDED',
  REFUNDED = 'REFUNDED',
}

export const useTransactionDetail = ({ transactionInProcess }) => {
  const [slideModalVariables, setModalVariables] = useState<ModalVariables>({} as ModalVariables);
  const [detailVariables, setDetailVariables] = useState<DetailVariables>({} as DetailVariables);
  const navigate = useNavigate();
  const { downloadTransactionCSV } = useDownloadTransaction();
  const { chechRefundStatus, checkRefundEligibility } = usePartialRefund({ transaction: transactionInProcess });
  const { refundAllowedDay, cancelAllowed } = chechRefundStatus(transactionInProcess);

  useEffect(() => {
    const {
      findFundProcessing,
      initialRefundProcessing,
      isFundProcessing,
      initialFundProcessing,
      isFundNotHolded,
    } = checkRefundEligibility(transactionInProcess);
    if (refundAllowedDay > MIN_ELIGIBLE_DAY) {
      decideModalVariables(ModalVariablesEnum.NO_REFUND);
    } else if (initialFundProcessing || (isFundNotHolded && isFundProcessing && findFundProcessing)) {
      if (!initialRefundProcessing && (findFundProcessing || initialFundProcessing)) {
        decideModalVariables(ModalVariablesEnum.TRANSACTION_PROCESSING);
      } else {
        decideModalVariables(ModalVariablesEnum.PROCESSING);
      }
    } else {
      decideModalVariables(ModalVariablesEnum.REFUND);
    }
    decideTransactionDetail();
  }, []);

  const closeModal = () => {
    setModalVariables({
      ...slideModalVariables,
      visibility: false,
    });
  };

  const onRefundClick = () => {
    setModalVariables({
      ...slideModalVariables,
      visibility: true,
    });
  };

  const onCancelRefundClick = () => {
    navigate(`/transactions/detail/cancel-refund`);
  };

  const setRefundCancel = () => {
    setDetailVariables({
      title: 'Refund Processing',
      description: 'A refund has been initiated. We are waiting on funds from your bank account on file.',
      firstButton: 'Cancel Refund',
      firstButtonClick: onCancelRefundClick,
    });
  };

  const decideTransactionDetail = () => {
    switch (transactionInProcess?.disbursement?.toUpperCase()) {
      case StatusVariablesEnum.VOIDED:
        setDetailVariables({
          title: 'Transaction Voided',
          description:
            'This transaction was voided. Funds have been returned to Joanna, and no fees have been charged.',
          firstButton: null,
        });
        break;

      case StatusVariablesEnum.REFUNDED:
        setDetailVariables({
          title: 'Transaction Refunded',
          description: `A full refund was issued to ${transactionInProcess?.borrowerName} after the transaction was completed. Funds have been disbursed to your bank account on file.`,
          firstButton: null,
        });
        break;

      case StatusVariablesEnum.COMPLETED:
        if (cancelAllowed) {
          setRefundCancel();
          break;
        }
        setDetailVariables({
          title: transactionInProcess?.refunds?.length > 0 ? 'Transaction Refunded' : 'Transaction Completed',
          description:
            transactionInProcess?.refunds?.length > 0
              ? `A partial refund was issued to ${transactionInProcess?.borrowerName} after the transaction was completed. Funds have been disbursed to your bank account on file, minus any amount refunded.`
              : `${transactionInProcess?.borrowerName} has completed their transaction and funds have been disbursed to your bank account on file.`,
          firstButton: transactionInProcess?.refunds?.length > 0 ? 'Issue Another Refund' : 'Issue Refund',
          transactionType: transactionInProcess?.transactionType,
        });
        break;

      case StatusVariablesEnum.PROCESSING:
        setDetailVariables({
          title: 'Transaction Processing',
          description: `${transactionInProcess?.borrowerName} has completed her transaction. Funds are on their way to your bank account on file.`,
          firstButton: 'Issue Refund',
        });
        break;
    }
  };

  const decideModalVariables = (type: ModalVariablesEnum) => {
    switch (type) {
      case ModalVariablesEnum.REFUND:
        setModalVariables({
          visibility: false,
          title: 'Issue Refund',
          firstButton: {
            label: 'Partial Refund',
            onClick: () => {
              navigate(`/transactions/detail/partial-refund`);
            },
          },
          secondButton: {
            label: 'Full Refund',
            onClick: () => {
              navigate(`/transactions/detail/full-refund`);
            },
          },
        });
        break;

      case ModalVariablesEnum.NO_REFUND:
        setModalVariables({
          visibility: false,
          title: 'Refund Ineligible',
          description: 'This transaction is past the 60-day refund window.',
          firstButton: {
            label: 'Okay',
            primary: true,
          },
        });
        break;
      case ModalVariablesEnum.TRANSACTION_PROCESSING:
        setModalVariables({
          visibility: false,
          title: 'Transaction Processing',
          description:
            'This transaction has not processed and is not eligible for a refund. Please allow 24 hours for funds to clear before issuing a refund.',
          firstButton: {
            label: 'Okay',
            primary: true,
          },
        });
        break;

      case ModalVariablesEnum.PROCESSING:
        setModalVariables({
          visibility: false,
          title: 'Refund is in Progress',
          description:
            'This refund has not processed yet.  Please allow 24 hours for funds to clear before issuing a refund.This transaction is past the 60-day refund window.',
          firstButton: {
            label: 'Okay',
            primary: true,
          },
        });
        break;

      case ModalVariablesEnum.DOWNLOAD_TRANSACTION:
        setModalVariables({
          visibility: true,
          title: 'Download Transaction',
          firstButton: {
            label: 'Download',
            icon: 'download',
            onClick: () => downloadTransactionCSV(transactionInProcess),
          },
        });
        break;
    }
  };

  const onDownloadTransactionClick = () => {
    decideModalVariables(ModalVariablesEnum.DOWNLOAD_TRANSACTION);
  };

  return {
    decideModalVariables,
    onRefundClick,
    slideModalVariables,
    closeModal,
    onDownloadTransactionClick,
    detailVariables,
  };
};
