import { useApolloClient, useMutation } from '@apollo/client';
import { Button, CurrencyInput, Icon, Switch } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { FormControl } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle.svg';
import { theme } from 'config/theme';
import { DashCard, DashComponent, FixedDecimalFormat, Loading } from 'lib/components';
import { AlertDialog, CherryDialogContent, CherryDialogContentText, CherryDialogTitle } from 'lib/components/Dialogue';
import { CREATE_MINIMUM_TRANSACTION_AMOUNT, UPDATE_MINIMUM_TRANSACTION_AMOUNT } from 'lib/graphql/mutations';
import {
  FETCH_MENU_DETAIL_BY_CODE,
  FETCH_ORGANIZATION_MDF_LIST,
  FETCH_ORGANIZATION_TRANSACTION_MINIMUM_AMOUNT,
} from 'lib/graphql/queries';
import { FeatureNames } from 'lib/hooks';
import useAgent from 'lib/hooks/useAgent';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import { TierName } from 'lib/utils';
import { StyledPopoverContent, StyledPopoverText, StyledPopoverWrapper } from 'pages/desktop/Dashboard/DentalDashboard';
import { Popover } from 'pages/desktop/Dashboard/views';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

interface TransactionMdf {
  term: number | string;
  amount: number;
  isEnabled: boolean;
  promoMdf?: number;
  mdf?: number;
  promoTerm: number;
}

interface Promo {
  promoMdf: number;
  mdf: number;
  promoTerm: number;
  term: number;
}

export const MinimumDentalFirstLookTransactionAmountLimit = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const { features, organization, organizationTier } = useStore();
  const { isAgent } = useAgent();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [mdfList, setMdfList] = useState([]);
  const [createMinimumTransactionAmount, { loading: createTransactionLoading }] = useMutation(
    CREATE_MINIMUM_TRANSACTION_AMOUNT,
  );
  const [updateMinimumTransactionAmount, { loading: updateTransactionLoading }] = useMutation(
    UPDATE_MINIMUM_TRANSACTION_AMOUNT,
  );
  const [organizationMdfs, setOrganizationMdfs] = useState<TransactionMdf[]>([]);
  const [maxEligibleAmount, setMaxEligibleAmount] = useState<number>(0);
  const [isTransactionLocked, setIsTransactionLocked] = useState<boolean>(false);
  const [isEditTransactionMinimum, setIsEditTransactionMinimum] = useState<boolean>(false);
  const [isMinTransactionAmountCreatable, setIsMinTransactionAmountCreatable] = useState(false);

  const { trackSegmentEvent } = useSegment();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleTooltipToggle = () => {
    setPopoverOpen(!popoverOpen);

    if (popoverOpen) {
      trackSegmentEvent('PRACTICE_PORTAL.SETTINGS.PRIME_AND_AVERAGE_CREDIT_PATIENTS.INFO_TOOLTIP_HOVER_VIEWED');
    }
  };

  const onAgreeClicked = async () => {
    if (isEditTransactionMinimum) {
      const existPromoPeriods = organizationMdfs
        .filter((item) => item.promoMdf && item.term !== 3)
        .map((item) => {
          return {
            amount: item.amount,
            term: item.term,
            isEnabled: item.isEnabled,
          };
        });
      if (isMinTransactionAmountCreatable) {
        postMinimumTransactionAmount?.(existPromoPeriods);
      } else {
        patchMinimumTransactionAmount?.(existPromoPeriods);
      }
    } else {
      setOpen(false);
    }
  };

  const onDisagreeClicked = async () => {
    setOpen(false);
  };

  const handleEditMode = () => {
    setIsEditTransactionMinimum(true);
  };

  const handleSave = () => {
    setOpen(true);
  };

  const onTransactionAmountChange = (event) => {
    const latestMinimumAmounts = organizationMdfs.map((item) => {
      if (item.term === Number(event.target.name)) {
        return {
          ...item,
          amount: Number(event.target.value),
        };
      }
      return item;
    });
    setOrganizationMdfs(latestMinimumAmounts);
  };

  const onTransactionSwithChange = (event) => {
    const latestMinimumAmounts = organizationMdfs.map((item) => {
      if (item.term === Number(event.target.name)) {
        return {
          ...item,
          isEnabled: event.target.checked,
        };
      }
      return item;
    });
    setOrganizationMdfs(latestMinimumAmounts);
  };

  const getOrganizationTransactionMinimumAmount = async (list?: []) => {
    const organizationMdf = list || mdfList;
    setLoading(true);
    try {
      const {
        data: { fetchOrganizationMinimumTransactionAmount },
      } = await client.query({
        query: FETCH_ORGANIZATION_TRANSACTION_MINIMUM_AMOUNT,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });
      if (fetchOrganizationMinimumTransactionAmount) {
        const { isUpdatable, minimumPromoTransactions } = fetchOrganizationMinimumTransactionAmount || {};
        const minimumTransactionAmounts = organizationMdf?.map((mdf: Promo) => {
          const isRecordExistForPromoTerm = minimumPromoTransactions?.find((item) => item.term === mdf.term);
          return {
            amount: isRecordExistForPromoTerm ? isRecordExistForPromoTerm.amount : null,
            term: mdf.term,
            promoTerm: mdf.promoTerm,
            isEnabled: isRecordExistForPromoTerm ? isRecordExistForPromoTerm.isEnabled : null,
            promoMdf: mdf.promoMdf,
            mdf: mdf.mdf,
          };
        });
        setOrganizationMdfs(minimumTransactionAmounts);
        setIsTransactionLocked(isUpdatable !== null ? !isUpdatable : false);
        setIsMinTransactionAmountCreatable(minimumPromoTransactions?.length === 0);
      } else {
        throw new Error();
      }
    } catch (e) {
      alert.error('Organization Transaction Setting Fetching Error');
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplicationMenuByCode?.();
    getOrganizationMdfList?.();
  }, []);

  const getOrganizationMdfList = async () => {
    setLoading(true);
    try {
      const {
        data: { getOrganizationMdf },
      } = await client.query({
        query: FETCH_ORGANIZATION_MDF_LIST,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });
      if (getOrganizationMdf.productMdfList?.length > 0) {
        setMdfList(getOrganizationMdf.productMdfList);
        getOrganizationTransactionMinimumAmount?.(getOrganizationMdf.productMdfList);
      } else {
        throw new Error();
      }
    } catch (e) {
      alert.error('Organization Transaction Setting Fetching Error');
    }
    setLoading(false);
  };

  const handleMinimumTransactionAmountCancel = () => {
    getOrganizationTransactionMinimumAmount?.();
    setIsEditTransactionMinimum(false);
  };

  const postMinimumTransactionAmount = async (createList) => {
    if (createList) {
      try {
        const { data } = await createMinimumTransactionAmount({
          variables: {
            input: {
              organizationId: organization?.id,
              minimumPromoTransactions: createList,
            },
          },
        });
        if (data?.createMinimumTransactionsAmount?.minimumPromoTransactions) {
          onDisagreeClicked?.();
          handleMinimumTransactionAmountCancel?.();
        } else {
          throw new Error();
        }
      } catch (error) {
        onDisagreeClicked?.();
        handleMinimumTransactionAmountCancel?.();
        alert.error('Minimum Transaction Amount Create Error');
      }
    }
  };

  const getApplicationMenuByCode = async () => {
    const {
      data: { getMenuByCode },
    } = await client.query({
      query: FETCH_MENU_DETAIL_BY_CODE,
      variables: { input: { code: organization?.menuCode } },
    });
    setMaxEligibleAmount(getMenuByCode?.maxPurchase);
  };

  const getStandartAPR = () => {
    const tierId = organizationTier?.organizationTier?.tierId;
    const tierName = TierName(tierId)?.toLowerCase();

    switch (tierName) {
      case 'gold':
        return '7.9%';
      case 'silver':
        return '8.9%';
      default:
        return '9.9%';
    }
  };

  const patchMinimumTransactionAmount = async (updateList) => {
    if (updateList) {
      try {
        const { data } = await updateMinimumTransactionAmount({
          variables: {
            input: {
              organizationId: organization?.id,
              minimumPromoTransactions: updateList,
            },
          },
        });
        if (data?.updateMinimumTransactionsAmount?.minimumPromoTransactions) {
          onDisagreeClicked?.();
          handleMinimumTransactionAmountCancel?.();
        } else {
          throw new Error();
        }
      } catch (error) {
        onDisagreeClicked?.();
        handleMinimumTransactionAmountCancel?.();
        alert.error('Minimum Transaction Amount Update Error');
      }
    }
  };

  const isMinApprovalFeatureActive = features?.includes(FeatureNames.MINIMUM_PROMO_TRANSACTIONS);
  if (!isMinApprovalFeatureActive) {
    return null;
  }

  const ConfirmText = () => {
    return (
      <>
        <CherryDialogTitle id="approval-amount-alert-dialog-title">Save Changes</CherryDialogTitle>
        <DialogCloseIconContainer onClick={onDisagreeClicked}>
          <Icon src={CloseIcon} color={theme.main.midnightBlue} />
        </DialogCloseIconContainer>
        <CherryDialogContent>
          <CherryDialogContentText id="approval-amount-alert-dialog-description">
            <DialogContentContainer>
              <TextContainer>Pricing changes apply to all of your locations.</TextContainer>
              <TextContainer>
                Changes will be effective immediately for new applications, but they will not retroactively change any
                existing applications.
              </TextContainer>
            </DialogContentContainer>
          </CherryDialogContentText>
        </CherryDialogContent>
      </>
    );
  };

  return (
    <Column margin="0" id="settings-practice-pricing-component">
      <DashComponent dashHeader={'Practice Pricing'} />
      <DashCard>
        {loading ? <Loading size={50} /> : null}

        {!loading && (
          <Grid container={true} spacing={3} direction={'row'}>
            <Grid item={true} xs={12} md={12}>
              <Grid item={true} style={{ width: '100%' }}>
                <TitleContainer>
                  <Title>
                    <Row fontSize={'18px'} fontWeight="700" color="#0E202F">
                      Prime and Average Credit Patients
                    </Row>

                    <StyledPopoverWrapper onMouseEnter={handleTooltipToggle} onMouseLeave={handleTooltipToggle}>
                      <TitleBadge>
                        <span>PROUDLY OFFERING TRUE 0% APR</span>
                        <Icon src={InfoIcon} width={16} height={16} />
                      </TitleBadge>
                      {popoverOpen && (
                        <StyledPopover>
                          <StyledLeftArrow />
                          <StyledPopoverContent>
                            <StyledPopoverText>
                              Cherry proudly offers <b>true 0% APR financing.</b> Many other providers offer
                              <i>deferred 0% APR,</i> which results in patients owing retroactively charged interest if
                              they don’t pay off their full balance during the promo period. With Cherry, you can be
                              confident that 0% APR offers really mean 0%.
                            </StyledPopoverText>
                          </StyledPopoverContent>
                        </StyledPopover>
                      )}
                    </StyledPopoverWrapper>

                    <Row fontSize={'14px'} marginTop="8px">
                      <LimitAmountSpan>Approvals up to</LimitAmountSpan>
                      <FixedDecimalFormat amount={maxEligibleAmount || 0} prefix={'$'} fixedDecimalScale={false} /> .
                    </Row>
                  </Title>
                  <ButtonContainer>
                    {isEditTransactionMinimum ? (
                      <EditButtonsContainer>
                        <Button
                          disabled={false}
                          fullWidth={true}
                          data-testid="save-changed"
                          size="small"
                          onClick={handleSave}
                        >
                          Save Changes
                        </Button>
                        <Button
                          variant="secondary"
                          fullWidth={true}
                          data-testid="cancel"
                          size="small"
                          onClick={handleMinimumTransactionAmountCancel}
                        >
                          Cancel
                        </Button>
                      </EditButtonsContainer>
                    ) : (
                      <Button
                        fullWidth={true}
                        disabled={
                          loading ||
                          createTransactionLoading ||
                          updateTransactionLoading ||
                          (!isAgent && isTransactionLocked)
                        }
                        data-testid="edit-minimum-transactions"
                        onClick={handleEditMode}
                      >
                        Edit Pricing
                      </Button>
                    )}
                  </ButtonContainer>
                </TitleContainer>
                <TableContainer>
                  {!loading && (
                    <GreenZone>
                      <span>Promotional APR Settings</span>
                    </GreenZone>
                  )}
                </TableContainer>
                <HeadContainer>
                  <Head>
                    <span>Term </span>
                    <span>Length</span>
                  </Head>
                  <Head>
                    <span>Promotional</span>
                    <span>APR Offered?</span>
                  </Head>
                  <Head>
                    <span> Minimum </span>
                    <span>Transaction</span>
                  </Head>
                  <Head>
                    <span>Practice Fee</span>
                    <ColumnSubtitle>(Promotional APR)</ColumnSubtitle>
                  </Head>
                  <Head>
                    <span>Practice Fee</span>
                    <ColumnSubtitle>(Standard APR)</ColumnSubtitle>
                  </Head>
                </HeadContainer>
                {organizationMdfs?.map((mdf: TransactionMdf, index) => {
                  const isRowActive = mdf?.term === 3 || mdf?.isEnabled;
                  return (
                    <ListContainer key={'test-' + index}>
                      <TermItem>
                        <span>{mdf.term} Months</span>
                      </TermItem>

                      <AprItem>
                        <span>
                          {mdf?.term === 3 ? (
                            'Yes'
                          ) : mdf.isEnabled === null ? (
                            'No'
                          ) : (
                            <Switch
                              data-testid={`apr-switch-${mdf?.term}`}
                              id={'minimum-transaction-amount-switch'}
                              name={String(mdf?.term)}
                              handleClick={onTransactionSwithChange}
                              isChecked={mdf.isEnabled}
                              disabled={!isEditTransactionMinimum}
                            />
                          )}
                        </span>
                      </AprItem>

                      <AmountItem isEdit={isEditTransactionMinimum}>
                        {mdf?.term === 3 || mdf.isEnabled === null ? (
                          <span>Not Available</span>
                        ) : mdf.isEnabled === false ? (
                          <>
                            <span>Available </span>
                            <span>if Promo APR enabled</span>
                          </>
                        ) : (
                          <FormControl fullWidth={true} data-testid={`minimum-transaction-input-${index}`}>
                            <CurrencyInput
                              id={`minimum-transaction-amount-input-${mdf.amount}`}
                              onChange={onTransactionAmountChange}
                              name={String(mdf.term)}
                              required={true}
                              disabled={!isEditTransactionMinimum}
                              defaultValue={mdf?.amount || 0}
                              label=""
                            />
                          </FormControl>
                        )}
                      </AmountItem>

                      <PracticeFeeItem isActive={isRowActive}>
                        {mdf?.promoMdf ? <div>{mdf?.promoMdf}%</div> : <span>Not Available</span>}
                        {mdf?.term !== 3 && !!mdf?.amount && mdf?.isEnabled && (
                          <>
                            <span>transactions</span>
                            <span>
                              at or above{' '}
                              <FixedDecimalFormat amount={mdf?.amount || 0} prefix={'$'} fixedDecimalScale={false} />
                            </span>
                          </>
                        )}
                        {!isRowActive && mdf?.isEnabled !== null && <span>if Promo APR enabled</span>}
                      </PracticeFeeItem>

                      <PracticeFeeItem isActive={!isRowActive || !!(isRowActive && mdf?.amount)}>
                        {mdf?.mdf && mdf?.term !== 3 ? <div>{mdf?.mdf}%</div> : <span>Not Available</span>}
                        {mdf?.term !== 3 && !!mdf?.amount && mdf?.isEnabled && (
                          <span>
                            transactions below{' '}
                            <FixedDecimalFormat amount={mdf?.amount || 0} prefix={'$'} fixedDecimalScale={false} />
                          </span>
                        )}
                        {!mdf?.amount && mdf?.isEnabled && mdf?.term !== 3 && <span>if Promo APR disabled</span>}
                      </PracticeFeeItem>
                    </ListContainer>
                  );
                })}
              </Grid>
              <ApprovalRateContainer>
                <InformationSection>
                  <InformationTitle>Subprime Credit Patients</InformationTitle>
                </InformationSection>
                <FeeSection>
                  <FeeTitle>Practice Fee</FeeTitle>
                  <Fee>{getStandartAPR()}</Fee>
                </FeeSection>
              </ApprovalRateContainer>
            </Grid>
          </Grid>
        )}
      </DashCard>

      <AlertDialog
        maxWidth={'sm'}
        open={open}
        agreeClicked={onAgreeClicked}
        onDisagreeClicked={onDisagreeClicked}
        children={<ConfirmText />}
        agreeText={'Save Changes'}
        declineText={'Cancel'}
        isLoading={createTransactionLoading || updateTransactionLoading}
      />
    </Column>
  );
};

const HeadContainer = styled.div`
  display: flex;
  height: 38px;
  align-items: flex-end;
  gap: 37px;
  align-self: stretch;
  border-bottom: 1px solid #dadada;
  background-color: ${theme.main.aliceBlue};
  padding: 8px;
  margin-top: 34px;
  z-index: 1;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
`;

const Head = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColumnSubtitle = styled.span`
  font-size: 12px;
`;

const ListContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding: 16px 0px;
  display: flex;
  border-bottom: 1px solid #dadada;
  z-index: 1;
  position: relative;
  height: 50px;
  max-height: 50px;
`;

const ListItem = styled.div`
  font-weight: normal;
  color: #0e202f;
  font-size: 14px;
  display: flex;
  width: 50%;
  text-align: center;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

const TermItem = styled(ListItem)`
  font-weight: 600;
`;

const AprItem = styled(ListItem)`
  font-weight: 400;
`;

const AmountItem = styled(ListItem)<{ isEdit?: boolean }>`
  span {
    font-size: 12px;
    font-style: italic;
    color: ${theme.main.midnightBlue50};
  }
  display: flex;
  flex-direction: column;
  input {
    font-family: 'Open Sans';
  }
  .MuiInputBase-root {
    background-color: ${(props) => (props.isEdit ? 'white' : '')};
  }
`;

const PracticeFeeItem = styled(ListItem)<{ isActive: boolean }>`
  span {
    font-size: 12px;
    font-style: italic;
    width: 110px;
    font-weight: ${(props) => (props.isActive ? '600' : '400')};
  }
  flex-direction: column;
  font-weight: 600;
  color: ${(props) => (props.isActive ? theme.main.midnightBlue : theme.main.midnightBlue50)};
`;

const DialogContentContainer = styled.div`
  margin-top: 24px;
`;

const DialogCloseIconContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 26px;
  cursor: pointer;
`;

const TextContainer = styled.div`
  text-align: center;
  padding-bottom: 24px;
`;

const TitleBadge = styled.div`
  display: flex;
  padding: 4px 4px 4px 8px;
  border-radius: 16px;
  background-color: ${theme.main.green10};
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  width: fit-content;
  span {
    color: ${theme.main.green};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
  }
`;

const StyledLeftArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${(props) => props?.theme?.main?.green};
  position: absolute;
  right: calc(100% - 220px);
  bottom: calc(40%);
  transform: rotate(90deg);
  top: 24px;
  left: -20px;
  transform: rotate(-90deg);
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid ${(props) => props?.theme?.main?.green};
`;

const StyledPopover = styled(Popover)`
  top: -16px;
  left: 265px;
  bottom: auto;
  width: 372px;
  min-height: 98px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

const GreenZone = styled.div`
  span {
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.72px;
    color: ${theme.main.green};
    text-transform: uppercase;
  }
  position: absolute;
  display: flex;
  width: 60%;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #80e1be;
  background-color: ${theme.main.green5};
  height: 518px;
  padding-top: 8px;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div``;

const ApprovalRateContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: ${theme.main.aliceBlue};
  margin-top: 32px;
`;

const FeeSection = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.main.midnightBlue};
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const FeeTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  padding: 0px 8px;
`;

const Fee = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const InformationTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const InformationSection = styled.div`
  display: flex;
  padding-left: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  color: ${theme.main.midnightBlue};
`;

const LimitAmountSpan = styled.span`
  margin-right: 4px;
`;

const EditButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
