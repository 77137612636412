import MuiBox from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  onSubmit?: () => any;
  bgColor?: string;
}

export const GreenBox = ({ children, onSubmit, bgColor }: Props) => {
  return (
    <Container
      component="form"
      bgColor={bgColor}
      onSubmit={onSubmit}
      sx={{
        '& .MuiTextField-root': { mb: 1, width: '100%' },
      }}
    >
      {children}
    </Container>
  );
};

const Container = styled(MuiBox)<{ bgColor?: string }>`
  width: 100% !important;
  background-color: #e6f9f2;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;
