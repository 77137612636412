import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { Button } from '@frontend/cherry-library';
import { ButtonPrimary, SecondaryButton } from 'lib/components';

interface Props {
  open: boolean;
  agreeClicked: (value: any) => void;
  onDisagreeClicked: (value: any) => void;
  showActions?: boolean;
  children: React.ReactNode;
  agreeText?: string;
  declineText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isLoading?: boolean;
}

export const AlertDialog = ({
  open,
  agreeClicked,
  onDisagreeClicked,
  children,
  agreeText = 'OK',
  declineText = 'Cancel',
  maxWidth = 'md',
  showActions = true,
  isLoading = false,
}: Props) => {
  const handleAgree = () => {
    agreeClicked('');
  };

  const handleDisagree = () => {
    onDisagreeClicked('');
  };

  return (
    <div>
      <CherryDialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth || 'md'}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {children}
        {showActions ? (
          <ButtonContainer>
            <Button data-testid="alert-disagree-button" variant="secondary" onClick={handleDisagree}>
              {declineText}
            </Button>
            <Button disabled={isLoading} data-testid="alert-agree-button" onClick={handleAgree}>
              {isLoading ? 'Loading...' : agreeText}
            </Button>
          </ButtonContainer>
        ) : (
          ''
        )}
      </CherryDialog>
    </div>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  box-sizing: border-box;

  button {
    flex: 1;
  }
`;

export const CherryDialog = styled(Dialog)`
  min-height: 440px !important;
  #alert-dialog-title {
    padding: 16px;
    font-family: Open-sans, sans-serif;
  }
  .MuiPaper-root {
    box-shadow: 0px 131px 174px 0px rgba(0, 0, 0, 0.11), 0px 60.565px 80.445px 0px rgba(0, 0, 0, 0.08),
      0px 34.654px 46.029px 0px rgba(0, 0, 0, 0.07), 0px 21.035px 27.939px 0px rgba(0, 0, 0, 0.06),
      0px 12.674px 16.835px 0px rgba(0, 0, 0, 0.05), 0px 7.058px 9.375px 0px rgba(0, 0, 0, 0.04),
      0px 3.036px 4.032px 0px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 24px;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogContentText-root {
    margin-bottom: 0;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const CherryDialogActions = styled(DialogActions)`
  margin: 16px;
  :>button  {
    width: 50%;
  }
`;

export const CherryDialogTitle = styled.h4`
  border-bottom: 1px solid #dadada;
  color: #0e202f;
  margin: 0;
  padding-bottom: 24px;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CustomActivateButton = styled(ButtonPrimary)`
  height: 40px;
  background-color: ${(props) => (props.disabled ? '#DADADA !important' : '#00C37C !important')};
  border: ${(props) => (props.disabled ? '#DADADA !important' : '#00C37C !important')};
  text-transform: capitalize !important;
  width: 50% !important;
`;

export const CherryDialogContent = styled(DialogContent)`
  p {
    font-size: 14px;
    color: #0e202f;
    font-weight: 400;
  }
`;

export const CherryDialogContentText = styled(DialogContentText)``;

export const CherryDeclineButton = styled(SecondaryButton)`
  border: 1px solid #00c37c;
  color: #00c37c;
  font-weight: 600;
  font-size: 16px;
  width: 50% !important;
`;
