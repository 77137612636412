import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { CherryRangePicker, DropDownWithCheckbox, InputIcon, InputWithIcon, TableLoader } from 'lib/components';
import { AppointmentStatusOptions, DataTableStyle, formatPhoneNumber } from 'lib/utils';

import './ApplicationList.css';

import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'lib/components/mobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateNames } from 'lib/constants';
import { useOnClickOutside } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import ProcessContractModal from 'lib/modals/ProcessContractModal';
import { useApplicationFilter } from 'lib/services';
import { useAppointmentList } from 'lib/tables/AppointmentTable';
import { Application, Appointment, DropdownOption, Merchant } from 'lib/types';
import { DentalCheckoutModal } from '../DentalDashboard/views/DentalCheckoutModal';
import ResponseModal from './Appoinments/ResponseModal';
import SendConfirmModal from './Appoinments/SendConfirmModal';
import { TextReminderModal } from './TextReminderModal';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ExpandedProps {
  approval?: Application;
  selectedAppointment?: Appointment;
}
export interface AppointmentFilterProp {
  dateFilter?: { from: string; to: string };
  selectedStatus?: { label: string; value: string }[];
  selectedLocation?: { label: string; value: string }[];
}
interface RangeSelection {
  selection: { startDate: string; endDate: string; key: string };
}
interface DateProps {
  width?: string;
}
const WIDTH_PARAMS = {
  default: '130px',
  dateRange: '210px',
};

export const AppointmentList = ({ appointmentSync }) => {
  const {
    columns,
    handleClick,
    selectedRow,
    isModalOpen,
    cancelClicked,
    isModalSendReminder,
    sendApplication,
    openResponseModal,
    failModal,
    textReminderData,
    closeTextReminderModal,
    selectedFilters,
    setSelectedFilters,
    getAppointments,
    loading,
    total,
    pageChange,
    rowsCountChange,
    groupedAppointments,
    setGroupedAppointments,
    appointments,
    expandedRow,
    isCheckoutModalVisible,
    setIsCheckoutModalVisible,
  } = useAppointmentList();
  const { merchants, setAppointmentDate } = useStore();

  const [search, setSearch] = useState<string>();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [width, setWidth] = useState<string>(WIDTH_PARAMS.default);

  const [filteredStringRange, setFilteredStringRange] = useState(`This Week`);
  const [location, setLocation] = useState<DropdownOption[]>([]);
  const thisWeekRange = {
    from: dayjs().toDate().toISOString(),
    to: dayjs().day(7).toDate().toISOString(),
  };
  const searchRange = { to: '', from: '' };
  const clickOutsideRef = useRef(null);
  const closeDropdown = () => (showRangePicker ? setShowRangePicker(false) : '');
  useOnClickOutside(clickOutsideRef, closeDropdown);

  const showLocationDropdown = (merchants?.length ?? 0) > 1;
  const showLoadMore = appointments.length < total && appointments.length !== 0;
  const { trackPage } = useSegment();

  useEffect(() => {
    getAppointments({});
    trackPage('scheduleView', { category: 'applications' });
  }, []);

  useEffect(() => {
    getAppointments({ searchKey: search, offset: selectedFilters.offset });
  }, [selectedFilters, search, appointmentSync]);

  useEffect(() => {
    if (merchants?.length) {
      const formattedLocations: DropdownOption[] = merchants?.map((merchant: Merchant) => {
        return { label: merchant.name, value: merchant.id?.toString() };
      });
      setLocation(formattedLocations);
    }
  }, [merchants]);

  const onSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (nativeEvent) => {
    const currentTarget = nativeEvent?.currentTarget;
    if (currentTarget?.value?.length > 2 || currentTarget?.value === '') {
      const searchInput = currentTarget.value === '';
      setSearch(currentTarget.value);
      const dateFilter = searchInput ? thisWeekRange : searchRange;
      setSelectedFilters({ ...selectedFilters, dateFilter });
      setFilteredStringRange(searchInput ? DateNames.THIS_WEEK : DateNames.ALL_TIME);
    }
  };
  const toggleRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };
  const onTextStringUpdated = (inputDateRangeText: string) => {
    setFilteredStringRange(inputDateRangeText);

    setWidth(inputDateRangeText.length > 15 ? WIDTH_PARAMS.dateRange : WIDTH_PARAMS.default);
  };
  const handleRangeSelection = (range: RangeSelection) => {
    const fromDate = dayjs(range.selection.startDate).startOf('day').toDate().toISOString();
    const toDate = dayjs(range.selection.endDate).endOf('day').toDate().toISOString();
    setAppointmentDate({ startAt: fromDate, endAt: toDate });
    setSelectedFilters({
      ...selectedFilters,
      offset: 0,
      dateFilter: {
        from: fromDate,
        to: toDate,
      },
    });
  };
  const loadMoreData = () => {
    setSelectedFilters({ ...selectedFilters, offset: selectedFilters.offset + 1 });
  };
  const { setSelectedItem, setExpandableOpened, setLoanUpdated, loanUpdated, updatedLoan } = useApplicationFilter();

  const ExpandedComponent = React.useMemo(
    () => ({ approval, selectedAppointment }: ExpandedProps) => {
      return (
        <ProcessContractModal
          selectedModalItem={approval}
          isLoanUpdated={updatedLoan}
          loanUpdated={loanUpdated}
          setLoanUpdated={setLoanUpdated}
          setExpandableOpened={setExpandableOpened}
          setSelectedItem={setSelectedItem}
          selectedAppointment={selectedAppointment}
        />
      );
    },
    [],
  );

  const locationSelectionHandler = (data) => {
    const findItems = location?.filter((loc) => data.includes(loc.value));
    setSelectedFilters({
      ...selectedFilters,
      offset: 0,
      selectedLocation: findItems.length === 0 ? data : findItems,
    });
  };
  const statusSelectionHandler = (data) => {
    const findItems = AppointmentStatusOptions?.filter((status) => data.includes(status.value));
    setSelectedFilters({
      ...selectedFilters,
      offset: 0,
      selectedStatus: findItems.length === 0 ? data : findItems,
    });
  };

  const handleToggleButton = (item) => {
    const updatedAppointments = groupedAppointments?.map((app) =>
      app.date === item.date ? { ...item, isExpanded: !item.isExpanded } : app,
    );
    setGroupedAppointments(updatedAppointments);
  };

  const handleRowExpanded = (row: Appointment) => {
    return row.id === expandedRow?.id;
  };

  const closeCheckoutModal = () => {
    setIsCheckoutModalVisible(false);
  };

  return (
    <>
      <LoanListWrapper>
        <GridContainer container={true} spacing={3} key="GridContainer">
          <RightGrid item={true} sm={11} id="appointment--listing">
            <Container>
              <SearchWrapper>
                <InputIcon icon={faSearch} margin="14px 10px" />
                <InputWithIcon
                  onChange={onSearchInputChange}
                  placeholder="Search by Customer Name, Phone"
                  textAlign="left"
                  border={true}
                  width={showLocationDropdown ? '60%' : '80%'}
                />
                <DropDownContainer>
                  <DropDownWithCheckbox
                    selectAllLabel="All Status"
                    options={AppointmentStatusOptions}
                    placeholder="All Status"
                    checkedAllPlaceholder="All Status"
                    checkedPlaceholder="Status"
                    textColor="#0E202F"
                    onChange={statusSelectionHandler}
                    checkedItems={selectedFilters.selectedStatus || AppointmentStatusOptions}
                    searchable={false}
                    selectAll={true}
                    hoveredMode={false}
                    preventCloseOnClick={true}
                  />
                </DropDownContainer>
                {merchants && merchants?.length > 1 ? (
                  <DropDownContainer>
                    <DropDownWithCheckbox
                      searchablePlaceholder="Search By Location Name"
                      selectAllLabel="All Locations"
                      options={location}
                      placeholder="Locations"
                      checkedAllPlaceholder="All Locations"
                      checkedPlaceholder="Location"
                      hoveredMode={false}
                      textColor="#0E202F"
                      onChange={locationSelectionHandler}
                      checkedItems={selectedFilters.selectedLocation || location}
                      searchable={true}
                      selectAll={true}
                      preventCloseOnClick={true}
                    />
                  </DropDownContainer>
                ) : null}
              </SearchWrapper>
            </Container>
            <DateInput ref={clickOutsideRef} width={width}>
              <InputWithIcon
                style={{ color: '#0E202F', paddingLeft: 32 }}
                onClick={toggleRangePicker}
                defaultValue={filteredStringRange}
                caret="transparent"
                margin="0px 0px 0px 0px"
                textAlign="left"
                cursor="pointer"
                value={filteredStringRange}
              />
              <Icon
                onClick={toggleRangePicker}
                src="application-date"
                left="10px"
                hover={true}
                width={16}
                height={16}
                m="0px 6px 0px 0px"
              />
              <CherryRangePicker
                show={showRangePicker}
                onRangeSelected={handleRangeSelection}
                updateTextString={onTextStringUpdated}
                toggleRangePicker={toggleRangePicker}
                marginLeft={width === WIDTH_PARAMS.default ? '445px' : '370px'}
                marginTop={'450px'}
                appointments={true}
                defaultSelectRange={{
                  startDate: dayjs(),
                  endDate: dayjs().day(7),
                }}
              />
            </DateInput>

            {groupedAppointments?.map((item) => {
              return (
                <>
                  {groupedAppointments.length !== 1 && (
                    <DateTitleContainer>
                      <DateTitle>{item?.date}</DateTitle>
                      <ToggleButton onClick={handleToggleButton.bind(null, item)} isExpanded={item?.isExpanded}>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </ToggleButton>
                    </DateTitleContainer>
                  )}
                  {item?.isExpanded && (
                    <TableRow>
                      <TableColumn>
                        <CustomDataTable
                          customStyles={DataTableStyle}
                          pointerOnHover={true}
                          keyField="id"
                          noHeader={true}
                          columns={columns}
                          data={item?.appointments}
                          pagination={false}
                          paginationServer={true}
                          progressPending={loading}
                          progressComponent={<TableLoader count={4} />}
                          onChangePage={pageChange}
                          onChangeRowsPerPage={rowsCountChange}
                          paginationTotalRows={total}
                          expandableRows={true}
                          onRowClicked={handleClick}
                          expandableRowsComponent={
                            <ExpandedComponent approval={selectedRow?.application} selectedAppointment={selectedRow} />
                          }
                          expandableRowExpanded={handleRowExpanded}
                          expandOnRowClicked={true}
                          style={{ overflow: 'inherit' }}
                          noDataComponent={
                            <div style={{ padding: '20px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                              No Appointments
                            </div>
                          }
                        />
                      </TableColumn>
                    </TableRow>
                  )}
                </>
              );
            })}
          </RightGrid>

          {showLoadMore && (
            <ButtonContainer>
              <LoadMoreButton onClick={loadMoreData}> Load More Appointments</LoadMoreButton>
            </ButtonContainer>
          )}
        </GridContainer>
      </LoanListWrapper>
      {isModalOpen && !isModalSendReminder && (
        <SendConfirmModal
          open={isModalOpen}
          sendReminderModal={isModalSendReminder}
          cancelClicked={cancelClicked}
          sendApplication={sendApplication}
          patientName={`${selectedRow?.customer?.firstName} ${selectedRow?.customer?.lastName}`}
          patientPhone={formatPhoneNumber(selectedRow?.customer?.phone)}
        />
      )}
      {isModalSendReminder && (
        <TextReminderModal
          onClose={closeTextReminderModal}
          open={Boolean(isModalSendReminder)}
          reminderData={textReminderData}
          appointment={selectedRow}
        />
      )}
      {openResponseModal && (
        <ResponseModal
          open={openResponseModal}
          cancelClicked={cancelClicked}
          failModal={failModal}
          patientName={`${selectedRow?.customer?.firstName} ${selectedRow?.customer?.lastName}`}
          patientPhone={formatPhoneNumber(selectedRow?.customer?.phone)}
        />
      )}

      <DentalCheckoutModal
        open={isCheckoutModalVisible}
        onClose={closeCheckoutModal}
        onSubmit={closeCheckoutModal}
        selectedItemData={{
          ...selectedRow,
          firstName: selectedRow?.customer?.firstName,
          lastName: selectedRow?.customer?.lastName,
          availableAmount: selectedRow?.application?.balanceAvailable,
          phone: selectedRow?.customer?.phone,
        }}
      />
    </>
  );
};

const RightGrid = styled(Grid)`
  margin: 0;
  padding: 0;
`;
const GridContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const TableRow = styled.div`
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

interface TableColumnProps {
  flex?: number;
}

const CustomDataTable = styled<any>(DataTable)`
  display: flex;

  > div:first-child {
    display: flex;
  }
`;

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow > div:first-child {
    display: none !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    > div:first-child {
      display: none;
    }

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const LoanListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  div[data-tag='___react-data-table-allow-propagation___'] {
    overflow: visible !important;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 8px;
`;

const Container = styled.div`
  margin-top: 27px;
  display: flex;
`;
const DateInput = styled.div<DateProps>`
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  border-radius: 4px;
  position: relative;
  border: 1px solid #56636d;
  width: ${(props) => (props.width ? props.width : '155px')};

  > input {
    background: transparent;
    white-space: nowrap;
  }
  > input + svg {
    margin-left: 355px !important;
  }
`;
export const DropDownContainer = styled.div`
  height: 42px;
  width: 20%;
  margin-bottom: 21px;
  background-color: white;
  border: 1px solid #dadada;
  box-sizing: border-box;
  margin-left: 3px;
  z-index: 5;
  .dropdown-right {
    // height: 46px;

    span {
      font-size: 14px;
    }
  }
  .dropdown-content {
    width: 240px;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;
const ToggleButton = styled.button<{ isExpanded: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  transform: ${(props) => (props.isExpanded ? `rotate(180deg)` : `rotate(0)`)};
  transition: all 0.3s;
`;
const LoadMoreButton = styled.button`
  background: transparent;
  color: ${(props) => props.theme.main.midnightBlue50};
  border: 1px solid ${(props) => props.theme.main.midnightBlue50};
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 9px 16px 9px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 100px;
`;
const DateTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DateTitle = styled.h2<{ marginBottom?: string }>`
  font-size: 16px;
  line-height: 33px;
  margin-top: 0px;
  font-weight: 700;
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => props.theme.main.textColor};
`;
