import { Button, Dropdown } from '@frontend/cherry-library';
import { ReactComponent as BackIcon } from 'assets/images/short_left.svg';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActiveItemHighlight, PageWrapper, SettingsSectionsWrapper, SettingsSubPagesWrapper } from './styles';
import { COLUMN_LAYOUT_THRESHOLD, useSettingsPages, useViewport } from './utils';
import SettingsMenu from './components/SettingsMenu';

const SettingsSections = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('');
  const [showFixedHighlight, setShowFixedHighlight] = useState(false);

  const [highlightPositionY, setHighlightPositionY] = useState<number | undefined>(undefined);
  const { SETTINGS_PAGES, SETTINGS_DROPDOWN_OPTIONS } = useSettingsPages();

  const { screenWidth } = useViewport();

  const removeHighlight = () => {
    const elem = document.getElementById('settings-navigation-bar-highlight');
    if (elem) {
      elem.style.opacity = '0';
    }
  };

  const bringHighlightBack = () => {
    const elem = document.getElementById('settings-navigation-bar-highlight');
    if (elem) {
      elem.style.opacity = '1';
    }
  };
  const moveHighlight = useCallback(
    (menuId: string) => {
      const selectedMenuPosition = document.getElementById(`settings-menu-item-${menuId}`)?.getBoundingClientRect();
      setHighlightPositionY(selectedMenuPosition?.top);
    },
    [setHighlightPositionY],
  );

  const redirect = useCallback(
    (pageId: string) => {
      const selectedPageNav = SETTINGS_PAGES?.find((page) => page?.id === pageId)?.navigation || '/settings';
      setShowFixedHighlight(false);
      bringHighlightBack();

      setActivePage(pageId);
      navigate(selectedPageNav);
      moveHighlight(pageId);
    },
    [setActivePage, setShowFixedHighlight, bringHighlightBack, moveHighlight, navigate],
  );

  const getCurrentPathname = () => {
    return window.location.pathname;
  };

  const getCurrentHash = () => {
    const hash = window.location.hash;
    return hash.replace('#', '');
  };

  const handleSettingsDropdownChange = (e) => {
    const selectedPage = e?.target?.value;
    redirect(selectedPage);
  };

  const handleGoBack = () => {
    navigate('/settings');
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);

    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const currentURL = getCurrentPathname();

    setTimeout(() => {
      const currentHash = getCurrentHash();
      if (currentHash) {
        scrollToElement(`settings-${currentHash}-component`);
      }
    }, 1500);

    if (currentURL) {
      const currentPage = SETTINGS_PAGES?.find((page) => page?.navigation === currentURL);
      const subPagesVisible = currentPage?.subPages?.some((subPage) => subPage?.isAvailable);

      if (currentPage && subPagesVisible) {
        setActivePage(currentPage.id);
        moveHighlight(currentPage.id);
      } else {
        navigate('/settings');
      }

      setTimeout(() => {
        removeHighlight();
        setShowFixedHighlight(true);
      }, 400);
    }
  }, [getCurrentPathname]);

  return (
    <PageWrapper>
      <SettingsSectionsWrapper>
        {screenWidth > COLUMN_LAYOUT_THRESHOLD ? (
          <>
            <ActiveItemHighlight id="settings-navigation-bar-highlight" positionY={highlightPositionY} />
            <SettingsMenu
              pages={SETTINGS_PAGES}
              redirect={redirect}
              activePage={activePage}
              showFixedHighlight={showFixedHighlight}
            />
          </>
        ) : (
          <>
            <Button leftIcon={BackIcon} onClick={handleGoBack} id="settings-back-button" variant="secondary">
              Back to All Settings
            </Button>
            <Dropdown
              id="settings-launch-page-navigation-dropdown"
              className="settings-launch-page-navigation-dropdown"
              options={SETTINGS_DROPDOWN_OPTIONS?.filter((option) => option?.isAvailable)}
              label="Settings"
              onChange={handleSettingsDropdownChange}
              defaultValue={activePage}
              value={activePage}
              size="large"
            />
          </>
        )}
        <SettingsSubPagesWrapper>
          {SETTINGS_PAGES?.find((page) => page?.id === activePage)?.component}
        </SettingsSubPagesWrapper>
      </SettingsSectionsWrapper>
    </PageWrapper>
  );
};

export default SettingsSections;
