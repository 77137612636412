// import FileUploadModal from 'pages/desktop/DocumentRequests/views/FileUploadModal/FileUploadModal';
import useDocumentRequest from 'lib/hooks/useDocumentRequest';
import DocumentRequestItem from 'pages/desktop/DocumentRequests/views/DocumentRequestItem/DocumentRequestItem';
import MobileEmptyListMessage from 'pages/mobile/MobileDocumentRequests/views/MobileEmptyListMessage/MobileEmptyListMessage';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const DocumentRequestList = () => {
  const navigate = useNavigate();

  const { documentRequestList, getAllDocumentRequests, getStatus } = useDocumentRequest();

  useEffect(() => {
    getAllDocumentRequests();
  }, []);

  const handleUploadDocument = (id: string) => {
    const selectedDocumentRequest = documentRequestList.find(
      (documentRequest) => documentRequest.documentRequest.id === id,
    );

    if (selectedDocumentRequest) {
      navigate('/document-requests/file-upload', {
        state: {
          documentRequest: selectedDocumentRequest,
        },
      });
    }
  };

  return (
    <>
      <List>
        {documentRequestList?.length === 0 ? <MobileEmptyListMessage /> : null}
        {documentRequestList?.map((documentRequest, index) => {
          const status = getStatus(documentRequest.documentRequest.status);

          return (
            <DocumentRequestItem
              isMobile={true}
              key={index}
              documentRequest={{
                documentTitle: documentRequest.documentRequest.documentTitle,
                requestDate: documentRequest.documentRequest.createdAt,
                externalNote: documentRequest.documentRequest.externalNote,
                submittedBy: documentRequest.externalUserName,
                submitDate: documentRequest.documentRequest.updatedAt,
                userId: documentRequest.documentRequest.userId,
                id: documentRequest.documentRequest.id,
              }}
              status={status}
              uploadDocument={handleUploadDocument}
            />
          );
        })}
      </List>
    </>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 100px;
`;

export default DocumentRequestList;
