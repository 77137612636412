import { faLock, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { Loading } from 'lib/components';
import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  BlackOutlineButton,
  StepHeader,
  StyledDownCircle,
  StyledPopover,
  StyledPopoverContent,
  StyledPopoverExplanation,
  StyledPopoverText,
  StyledPopoverWrapper,
} from './styles';
import { SectionProps } from './types';

const Section = ({
  children,
  title,
  isExpanded,
  showEditButton,
  isEditButtonDisabled,
  isContinueButtonDisabled,
  hideActionButtons,
  loading,
  onEdit,
  onBack,
  onContinue,
  setIsExpanded,
}: SectionProps) => {
  const onEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onEdit?.();
  };

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const handleTooltipToggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleOnClick = () => {
    if (setIsExpanded) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Accordion expanded={isExpanded} defaultExpanded={true}>
      <AccordionSummary onClick={handleOnClick}>
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true}>
            <StepHeader>{title}</StepHeader>
          </Grid>
          <Grid item={true}>
            {showEditButton && onEditClick && (
              <>
                <StyledPopoverWrapper onMouseEnter={handleTooltipToggle} onMouseLeave={handleTooltipToggle}>
                  {isEditButtonDisabled && popoverOpen && (
                    <StyledPopover>
                      <StyledDownCircle />
                      <StyledPopoverContent>
                        <StyledPopoverText>
                          <b>Loan Agreement Pending</b>
                          <StyledPopoverExplanation>
                            We’re waiting for the patient to sign their loan agreement. Changes cannot be made to this
                            purchase. Cancel the purchase to start over.
                          </StyledPopoverExplanation>
                        </StyledPopoverText>
                      </StyledPopoverContent>
                    </StyledPopover>
                  )}
                  <BlackOutlineButton variant="outlined" onClick={onEditClick} disabled={isEditButtonDisabled}>
                    Edit
                    <FontAwesomeIcon icon={isEditButtonDisabled ? faLock : faPen} size="xs" />
                  </BlackOutlineButton>
                </StyledPopoverWrapper>
              </>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Grid container={true} justifyContent="center">
            <Loading size={24} />
          </Grid>
        ) : (
          children
        )}
        {hideActionButtons
          ? null
          : !loading &&
            !showEditButton && (
              <Grid container={true} spacing={1}>
                {onBack && (
                  <Grid item={true}>
                    <Button variant="secondary" onClick={onBack}>
                      Back
                    </Button>
                  </Grid>
                )}
                {onContinue && (
                  <Grid item={true}>
                    <Button disabled={isContinueButtonDisabled} onClick={onContinue}>
                      Continue
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
