import { Column, Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  marginBottom?: string;
}

export const PageHeader = ({ title, marginBottom = '40px' }: Props) => {
  return (
    <Row>
      <Column>
        <Title marginBottom={marginBottom}>{title}</Title>
      </Column>
    </Row>
  );
};

export const Title = styled.h2<{ marginBottom?: string }>`
  font-size: 28px;
  line-height: 33px;
  margin-top: 0px;
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => props.theme.main.textColor};
`;
