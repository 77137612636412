import useStore from 'lib/hooks/useStore';
import { ApiList } from './ApiList';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const ApiKeys = () => {
  const { organization } = useStore();
  const permission = usePermission();

  const orgId = organization?.id || 0;
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_API_KEYS_PAGE_LOAD`);
  }, []);

  return permission(FeatureNames.GENERATE_API_KEY, ScreenNames.SETTINGS) ? (
    <ApiList organizationId={orgId} />
  ) : (
    <UnauthorizedSettings title="API Keys" />
  );
};

export default ApiKeys;
