import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { FixedDecimalFormat } from 'lib/components';
import { Denied } from 'lib/components/ApplicationStatutes';
import { Button } from 'lib/components/mobile';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { getExpiresText } from 'lib/utils';

export const useMobileApplicationListTable = () => {
  const { features = [], setApplicationInProcess } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const navigate = useNavigate();
  const permission = usePermission();
  const hasSelfCheckoutFeature = features?.includes(FeatureNames.SELF_CHECKOUT);

  const getCTARow = (row) => {
    const navigateToApplicationCheckout = () => {
      trackSegmentEvent('Checkout Initiated', {
        application: applicationName,
        applicationId: row?.applicationId,
        borrowerId: row?.borrowerId,
        checkoutType: 'Another Checkout',
      });
      setApplicationInProcess(row);
      navigate(`/application/checkout`);
    };

    const navigateToApplicationPendingIncome = () => {
      setApplicationInProcess(row);
      navigate(`/application/pending-income`);
    };

    const navigateToApplicationUpdate = () => {
      setApplicationInProcess(row);
      navigate(`/application/detail`);
    };

    const navigateToApplicationCheckoutFinished = () => {
      setApplicationInProcess(row);
      navigate(`/application/checkout-finished`);
    };
    const navigateToPreApprovalInfo = () => {
      setApplicationInProcess(row);
      navigate(`/application/pre-approval`);
    };

    const hasTreatmentPlanChannel = row?.activeLoan?.channel === 'TREATMENT_PLAN';
    if (row?.status === 'PREAPPROVED') {
      return (
        <Flex onClick={navigateToPreApprovalInfo}>
          <StatusText>
            Pre-Approved <RightCaretIcon />
          </StatusText>
        </Flex>
      );
    } else if (
      row.status === 'APPROVED' &&
      row?.activeLoan?.count > 0 &&
      row?.activeLoan?.status !== 'INITIATED' &&
      row?.activeLoan?.status !== 'DRAFTED'
    ) {
      return (
        <Flex onClick={navigateToApplicationCheckoutFinished}>
          <StatusText>
            Transaction Complete <RightCaretIcon />
          </StatusText>
        </Flex>
      );
    } else if (row.status === 'APPROVED' && row?.activeLoan?.count > 0 && row?.activeLoan?.status === 'DRAFTED') {
      return (
        <Flex onClick={navigateToApplicationUpdate} data-testid="checkout-in-progress-button">
          <StatusText>
            Checkout in Progress
            <RightCaretIcon />
          </StatusText>
        </Flex>
      );
    } else if (row.selfCheckout && hasSelfCheckoutFeature && !hasTreatmentPlanChannel) {
      return (
        <Flex onClick={navigateToApplicationUpdate} data-testid="checkout-in-progress-button">
          <StatusText>
            Checkout in Progress
            <RightCaretIcon />
          </StatusText>
        </Flex>
      );
    } else if (row.status === 'DENIED') {
      return <Denied row={row} />;
    } else {
      if (['INCOME_VERIFICATION'].includes(row.status)) {
        return (
          <Flex onClick={navigateToApplicationPendingIncome}>
            <StatusText>
              Income Verification <RightCaretIcon />
            </StatusText>
          </Flex>
        );
      }

      return permission(FeatureNames.CHECKOUT, ScreenNames.DASHBOARD) ? (
        <CheckoutButtonContainer>
          <Button onClick={navigateToApplicationCheckout} endIcon="right-caret">
            Checkout
          </Button>
        </CheckoutButtonContainer>
      ) : null;
    }
  };

  const getBalanceColumn = (row) => {
    if (row.status === 'INCOME_VERIFICATION') {
      return (
        <Flex>
          <span>n/a</span>
          <ExpiresInWrapper flagged={row.startedAt < 3}>
            {row.startedAt < 3 && (
              <FontAwesomeIcon icon={faExclamationTriangle} color="#f5365c" style={{ margin: '0px 5px' }} />
            )}
            {getExpiresText(row.startedAt)}
          </ExpiresInWrapper>
        </Flex>
      );
    } else if (row.selfCheckout && hasSelfCheckoutFeature) {
      return (
        <Flex>
          <FixedDecimalFormat data-tag="allowRowEvents" amount={row.amount} prefix="$" />
          <ExpiresInWrapper flagged={row.startedAt < 3}>Pending</ExpiresInWrapper>
        </Flex>
      );
    } else {
      return (
        <Flex>
          <FixedDecimalFormat data-tag="allowRowEvents" amount={row.eligible} prefix="$" />
          <ExpiresInWrapper flagged={row.startedAt < 3}>
            {row.startedAt < 3 && (
              <FontAwesomeIcon icon={faExclamationTriangle} color="#f5365c" style={{ margin: '0px 5px' }} />
            )}
            {getExpiresText(row.startedAt)}
          </ExpiresInWrapper>
        </Flex>
      );
    }
  };

  const columns = [
    {
      name: 'APPLICANT',
      selector: (row) => row.borrowerName,
      cell: (row) => (
        <TextContainer>
          <Text>{row.borrowerName}</Text>
          <SmallText>{row.phone}</SmallText>
        </TextContainer>
      ),
      ignoreRowClick: true,
    },
    {
      name: 'BALANCE',
      selector: (row) => row.balance,
      sortable: false,
      cell: (row) => getBalanceColumn(row),
      ignoreRowClick: true,
    },
    {
      name: 'STATUS',
      selector: (row) => row.scheduled,
      cell: (row) => getCTARow(row),
      width: '160px',
      ignoreRowClick: true,
      style: {
        paddingRight: '24px',
      },
    },
  ];

  return { columns, getExpiresText };
};

const RightCaretIcon = () => {
  return (
    <RightCaretSvg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.47539 4.50001L1.46806 0.493347L0.525391 1.43601L3.59206 4.50268L0.525391 7.56468L1.46806 8.50735L5.47539 4.50001Z"
        fill={theme.main.green}
      />
    </RightCaretSvg>
  );
};

const RightCaretSvg = styled.svg`
  margin-left: 4px;
`;

interface ExpiresInWrapperProps {
  flagged: boolean;
}

const TextContainer = styled.div`
  margin: 4px 0;
`;

const ExpiresInWrapper = styled.div<ExpiresInWrapperProps>`
  color: ${(props) => (props.flagged ? '#f5365c' : props.theme.main.textColor)};
`;

const Text = styled.p`
  margin: 0;
  line-height: 16px;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
`;

const StatusText = styled(SmallText)`
  font-size: 14px;
  line-height: 16px;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CheckoutButtonContainer = styled.div`
  width: 100%;
  button {
    padding: 9px 8px !important;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    align-items: center;
    justify-content: space-between;
  }
`;
