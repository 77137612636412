import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import QRCode from 'easyqrcodejs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { APP_SHOP_URL } from 'config';
import { ButtonPrimary, DashCard, DropDown } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Column, Row, SubHeader } from 'lib/styles';
import { downloadBase64 } from 'lib/utils';

interface DropdownOption {
  label: string;
  value: string;
}

export const QrCodeSettings = () => {
  const { organization } = useStore();

  const [qrImage, setQrImage] = useState<string>('');
  const [qrCode, setQRCode] = useState<any>(null);

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const QrOptions = [
    { label: 'Print - use on physical items (i.e. flier, poster)', value: 'print' },
    { label: 'Digital - use online (i.e. email, social media)', value: 'digital' },
  ];

  const QR_CODE_CONFIG = {
    logo: images?.cherryIconDark,
    version: 5,
    width: 400,
    height: 400,
    autoColor: true,
    PO: '#00c37d',
    PI: '#0e1f2e',
    PO_TL: '#00c37d',
    PI_TL: '#00c37d',
    PO_TR: '#0e1f2e',
    PI_TR: '#00c37d',
    PO_BL: '#00c37d',
    PI_BL: '#0e1f2e',
  };

  const onQrRendered = useCallback((_, qrImg: string) => {
    setQrImage(qrImg);
  }, []);

  const initializeQrCode = (value) => {
    const sources = value === 'print' ? 'qr_print' : 'qr_digital';
    const qr = new QRCode(qrCodeRef.current, {
      text: `${APP_SHOP_URL}/memberships/${organization?.slug}?utm_source=${sources}&utm_medium=signup&utm_campaign=v1`,
      onRenderingEnd: onQrRendered,
      ...QR_CODE_CONFIG,
    });
    setQRCode(qr);
  };

  useEffect(() => {
    initializeQrCode('print');
  }, []);

  const onDropdownChange = (option: DropdownOption) => {
    if (qrCode) {
      qrCode.clear();
    }

    initializeQrCode(option.value);
  };

  const downloadQrCode = () => {
    downloadBase64(organization?.slug, qrImage);
  };

  return (
    <Column>
      <DashCard>
        <SubHeader size={'16px'} style={{ marginBottom: '8px' }}>
          QR Codes
        </SubHeader>

        <Row style={{ fontSize: '14px', paddingBottom: '24px' }}>
          Patients can scan your QR code to visit your membership sign-up page.
        </Row>

        <DropdownLabel>What type of QR code do you need?</DropdownLabel>
        <Row>
          <Grid container={true} xs={5} style={{ marginLeft: '2px', marginBottom: '24px' }}>
            <DropdownWrapper>
              <DropDown
                data-testid="qr-option"
                id={`qr-dropdown`}
                options={QrOptions}
                textColor={'#0E202F'}
                onChange={onDropdownChange}
                showAllItem={true}
                hoveredMode={false}
              />
            </DropdownWrapper>
          </Grid>
        </Row>

        <QrCode ref={qrCodeRef} />

        <Row style={{ marginTop: '24px' }}>
          <ButtonPrimary
            onClick={downloadQrCode}
            text={'Download'}
            data-testid="download-qr"
            endIcon={<FontAwesomeIcon style={{ width: '14px' }} icon={faDownload} />}
          />
        </Row>
      </DashCard>
    </Column>
  );
};

const QrCode = styled.div`
  width: 160px;
  height: 160px;

  canvas {
    width: inherit;
    height: inherit;
  }
`;

const DropdownLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #0e202f;
`;

const DropdownWrapper = styled.div`
  border: 1px solid #dadada !important;
  display: inline-block;
  width: 100%;

  * {
    font-size: 12px !important;
  }
`;
