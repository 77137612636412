import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useApolloClient } from '@apollo/client';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '@frontend/cherry-library';
import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import {
  CherryRangePicker,
  DropDownWithRadioButton,
  InputIcon,
  InputWithIcon,
  PickerWrapper,
  SummaryChartContainer,
} from 'lib/components';
import { LineChart, StackBarChart } from 'lib/components/Charts';
import { CHART_ENUM } from 'lib/constants';
import {
  FETCH_APPROVALS_REPORT,
  FETCH_SALES_REPORT,
  FETCH_SUMMARY_CHART_DATA,
  FETCH_TOTAL_APPROVALS_REPORT,
  FETCH_TOTAL_SALES_REPORT,
} from 'lib/graphql/queries';
import { useOrganizationGroupTreeRadioGroup } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { DropdownOption } from 'lib/types';
import { QuickStats } from 'pages/desktop/Reporting/views';
import { useNavigate } from 'react-router-dom';

interface SalesReport {
  approvalRate?: number;
  averageSale?: number;
  contracts?: number;
  grossSales?: number;
  netSales?: number;
}

interface ApprovalRate {
  rate: number;
  outstanding: number;
}

export const Reporting = () => {
  const navigate = useNavigate();
  const { organization, merchants = [], isAdminReporting, selectedOrganizationGroup } = useStore();
  const client = useApolloClient();
  const idOrganization = organization?.id || 0;
  const [approvalRate, setApprovalRate] = useState<ApprovalRate>();
  const [salesLoading, setSalesLoading] = useState(true);
  const [salesReport, setSalesReport] = useState<SalesReport>({});
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>('All');
  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().toISOString(),
  });
  const [filteredStringRange, setFilteredStringRange] = useState<string>('Last 30 Days');
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [chartDatas, setChartDatas] = useState<any>({});
  const [location, setLocation] = useState<DropdownOption>({ value: 'selectAll', label: 'All Accounts' });
  const [organizationIds, setOrganizationIds] = useState<string | null>(`${organization?.id}`);
  const { findNodeAndReturnArrays, organizationGroupTree } = useOrganizationGroupTreeRadioGroup();

  const locationOptions: any = merchants?.map((merchant) => {
    return {
      value: merchant.id,
      label: `<span>${merchant?.name}</span>`,
    };
  });

  useEffect(() => {
    if (organizationGroupTree && organizationGroupTree?.length && selectedOrganizationGroup?.id) {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  }, [organizationGroupTree]);

  useEffect(() => {
    if (filterSelectedDateValue?.from && filterSelectedDateValue?.to) {
      setSalesLoading(true);
      fetchSaleReport();
      fetchApprovalReport();
      fetchSummaryChartData();
    }
  }, [selectedMerchant, organization, filterSelectedDateValue, organizationIds]);

  useEffect(() => {
    fetchApprovalReport();
  }, [organization, filterSelectedDateValue]);

  const onDetailClick = (value: CHART_ENUM) => {
    switch (value) {
      case CHART_ENUM.APPROVAL_APPLICATION:
        navigate('/reporting/approvals-applications');
        break;
      case CHART_ENUM.EXPIRED_APPLICATION:
        navigate('/reporting/expired-approvals');
        break;
      case CHART_ENUM.TRANSACTIONS:
        navigate('/reporting/transactions');
        break;
      case CHART_ENUM.PATIENT_BREAKDOWN:
        navigate('/reporting/patient-breakdown');
        break;
    }
  };

  const fetchSummaryChartData = async () => {
    const { data } = await client.query({
      query: FETCH_SUMMARY_CHART_DATA,
      variables: {
        input: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
        },
      },
    });
    setChartDatas({ ...data });
  };

  const fetchSaleReport = async () => {
    let salesReportData;
    if (isAdminReporting) {
      const { data } = await client.query({
        query: FETCH_TOTAL_SALES_REPORT,
        variables: {
          input: {
            organizationIds,
            startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
            endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
            merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          },
        },
      });

      salesReportData = data?.getTotalSalesReport?.data;
    } else {
      const { data } = await client.query({
        query: FETCH_SALES_REPORT,
        variables: {
          input: {
            organizationId: idOrganization,
            startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
            endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
            merchantId: selectedMerchant === 'All' ? null : selectedMerchant,
          },
        },
      });

      salesReportData = data?.getSalesReport?.data;
    }

    setSalesReport(salesReportData);
    setSalesLoading(false);
  };

  const fetchApprovalReport = async () => {
    let approvalsReportData;
    if (isAdminReporting) {
      const { data } = await client.query({
        query: FETCH_TOTAL_APPROVALS_REPORT,
        variables: {
          input: {
            organizationIds,
            startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
            endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          },
        },
      });

      approvalsReportData = data?.getTotalApprovalsReport?.data;
    } else {
      const { data } = await client.query({
        query: FETCH_APPROVALS_REPORT,
        variables: {
          input: {
            organizationId: idOrganization,
          },
        },
      });

      approvalsReportData = data?.getApprovalsReport?.data;
    }

    setApprovalRate(approvalsReportData);
  };

  const locationChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedMerchant(event?.target?.value as string);
  };

  const toggleRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };

  const handleRangeSelection = (range: any) => {
    setFilterSelectedDateValue({
      from: dayjs(range.selection.startDate).startOf('day').toDate().toISOString(),
      to: dayjs(range.selection.endDate).endOf('day').toDate().toISOString(),
    });
  };

  const onTextStringUpdated = (inputDateRangeText: any) => {
    setFilteredStringRange(inputDateRangeText);
  };

  interface NodeType {
    id: string;
    type: any;
    name: string;
    childs?: NodeType[];
  }

  const organizationGroupSelectionHandler = (data) => {
    setLocation(data);
    if (data?.value === 'selectAll') {
      setOrganizationIds(String(organization?.id));
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setLocation({ value: 'selectAll', label: 'All Accounts' });
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    } else {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree[0],
        data.value,
        data.type,
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  };

  return (
    <Container>
      <Header>
        <Title>Reporting</Title>
      </Header>
      <FeatureContainer>
        {isAdminReporting && (
          <DropDownContainer>
            <DropDownWithRadioButton
              selectAllLabel="All Accounts"
              options={organizationGroupTree}
              placeholder="All Accounts"
              checkedAllPlaceholder="All Accounts"
              iconName="location-preference"
              checkedPlaceholder="Location"
              hoveredMode={false}
              textColor="#0E202F"
              onChange={organizationGroupSelectionHandler}
              checkedItems={location}
              preventCloseOnClick={true}
              searchable={false}
              selectAll={true}
            />
          </DropDownContainer>
        )}

        <CustomizePickerWrapper>
          <InputWithIcon
            onClick={toggleRangePicker}
            defaultValue={filteredStringRange}
            caret={'transparent'}
            margin={'-4px 20px 0px 0px'}
            textAlign={'right'}
            cursor={'pointer'}
            width={filteredStringRange.length < 15 ? '140px' : '200px'}
            value={filteredStringRange}
          />
          <CustomizeInputIcon
            onClick={toggleRangePicker}
            icon={faChevronDown}
            right={'20px'}
            margin={'-4px 0 0 0 '}
            color={'green'}
            cursor={'pointer'}
          />
          <CherryRangePicker
            show={showRangePicker}
            onRangeSelected={handleRangeSelection}
            defaultSelectRange={{
              startDate: dayjs().subtract(1, 'month'),
              endDate: dayjs(),
            }}
            updateTextString={onTextStringUpdated}
            toggleRangePicker={toggleRangePicker}
            marginLeft={'404px'}
            marginTop={'430px'}
          />
        </CustomizePickerWrapper>

        {!isAdminReporting && merchants?.length && merchants?.length > 1 && (
          <DropDownContainer>
            <Dropdown
              options={[
                {
                  value: 'All',
                  label: 'All Stores',
                },
                ...locationOptions,
              ]}
              onChange={locationChange}
              value={selectedMerchant as string}
            />
          </DropDownContainer>
        )}
      </FeatureContainer>
      <QuickStats salesInfo={salesReport} approvalInfo={approvalRate} loading={salesLoading} />
      <SummaryContainer>
        <RowContainer>
          <SummaryChartContainer
            header="Approvals & Applications"
            id={CHART_ENUM.APPROVAL_APPLICATION}
            onDetailClick={onDetailClick}
          >
            <LineChart
              data={chartDatas.getTotalApprovalsSummary?.contents}
              toolTipLabel={'Approval Amount'}
              dataType="money"
            />
          </SummaryChartContainer>
          <SummaryChartContainer
            header="Expired Approvals"
            id={CHART_ENUM.EXPIRED_APPLICATION}
            onDetailClick={onDetailClick}
          >
            <LineChart
              data={chartDatas.getTotalExpiredApprovalsSummary?.contents}
              toolTipLabel={'Expired Approvals'}
              lineColor="#ec3360"
              dataType="money"
            />
          </SummaryChartContainer>
        </RowContainer>
        <RowContainer m={'24px 0px 0px'}>
          <SummaryChartContainer header="Transactions" id={CHART_ENUM.TRANSACTIONS} onDetailClick={onDetailClick}>
            <LineChart
              data={chartDatas.getTotalTransactionAmounts?.contents}
              toolTipLabel={'Transaction Amount'}
              dataType="money"
            />
          </SummaryChartContainer>
          <SummaryChartContainer
            header="Patient Breakdown"
            id={CHART_ENUM.PATIENT_BREAKDOWN}
            onDetailClick={onDetailClick}
          >
            <StackBarChart
              data={chartDatas.getTotalRepeatTransactions?.contents}
              firstDataLabel={'repeatedTransactionCount'}
              secondDataLabel={'nonRepeatedTransactionCount'}
              firstToolTipLabel={'Repeat Patient Transactions'}
              secondToolTipLabel={'New Patient Transactions'}
            />
          </SummaryChartContainer>
        </RowContainer>
      </SummaryContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 150px;
  padding: 0 20px;
  padding-bottom: 150px;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`;

const RowContainer = styled.div<{ m?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: ${(props) => props.m};
`;

const FeatureContainer = styled.div`
  width: auto;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const CustomizeInputIcon = styled(InputIcon)`
  position: inherit;
`;

const CustomizePickerWrapper = styled(PickerWrapper)`
  z-index: 4;
  width: fit-content;
  height: 45px;
  border: 1px solid #dadada;
  border-radius: 2px;
  background-color: white;
  padding: 0px 19px 0px 0px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #0e202f;
  margin-bottom: 21px;
`;

export const DropDownContainer = styled.div`
  height: 45px;
  width: 280px;
  background-color: white;
  box-sizing: border-box;

  .dropdown-right {
    // height: 46px;

    span {
      font-size: 14px;
    }
  }
  .dropdown-content {
    // width: 80%;
  }
  li {
    font-size: 14px;
  }
  svg {
    color: #00c37c;
  }
`;
