import { gql } from '@apollo/client/core';

export const FETCH_MENU_DETAIL = gql`
  query getMenu($input: MenuRequest) {
    getMenu(input: $input) {
      minPurchase
    }
  }
`;

export const FETCH_MENU_DETAIL_BY_CODE = gql`
  query getMenuByCode($input: MenuByCodeRequest) {
    getMenuByCode(input: $input) {
      maxPurchase
    }
  }
`;
