import { Button, theme as cherryTheme } from '@frontend/cherry-library';
import styled from 'styled-components';

interface ColumnProps {
  flex?: number;
  margin?: number;
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin: auto 10px;
`;

export const Center = styled.div`
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 460px;
`;

export const ResultContainer = styled.div`
  margin-top: 60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 320px;
  text-align: center;
`;

export const LoadingText = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.main.grayColor};
  text-align: center;
  margin-bottom: 32px;
`;

export const TextSecondary = styled.p`
  color: #868f97;
  font-size: 14px;
  text-align: center;
`;

export const TextH5 = styled.h5`
  font-size: 16px;
  color: #0e202f;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const TextH4 = styled.h4`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #0e202f;
  text-align: center;
  font-weight: normal;
  margin-top: 16px;
`;

export const DataContainer = styled.div`
  margin-bottom: 32px;
  width: 340px;
`;

export const BottomButtons = styled.div`
  margin-bottom: 20px;
  display: flex;
  width: 340px;
  div {
    margin: 0px;
    padding: 0px;
  }
  button {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .btn--cancel {
    margin-right: 10px !important;
    width: 90%;
  }
`;

export const DropDownBorder = styled.div`
  border: 1px solid;
  width: 320px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 2px;
  li {
    font-size: 14px;
  }

  svg {
    color: #00c37c;
  }
`;

export const FormLabel = styled.p`
  font-size: 14px;
  color: #0e202f;
  margin-bottom: 4px;
`;

export const LoanActionButton = styled.button<{ fullWidth?: boolean }>`
  background-color: ${(props) => props.theme.main.green};
  border: none;
  border-radius: 2px;
  color: #fff;
  padding: 8px 16px;
  height: 40px;
  cursor: pointer;
  // width: 67%;
  ${(props) => (props?.fullWidth ? 'width: 100%;' : 'margin: 0 auto;')}
  font-weight: bold;

  &:focus,
  &:hover {
    outline: none;
    background-color: #00a569;
  }

  &:disabled {
    background-color: ${(props) => props.theme.main.borderColor};
  }
`;

export const SecondaryLoanActionButton = styled(LoanActionButton)`
  font-weight: 600;
  font-size: 16px;
  font-family: 'Open Sans' !important;
`;

export const TextLink = styled(Button).attrs({ variant: 'tertiary' })<{ mb?: number }>`
  color: ${cherryTheme.main.cherryRed} !important;
  text-decoration: underline;
  margin-bottom: ${(props) => props.mb || 0}px;

  &:hover {
    background-color: ${cherryTheme.main.cherryRed5} !important;
  }
`;
