import dayjs from 'dayjs';
import { APPLICATION_STATUSES } from 'lib/hooks';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { SearchAPIOperators, SearchCriteriaItem } from 'lib/types/SearchApi';

const createSearchInputItem = (searchInput: string | undefined) => {
  if (!searchInput) return undefined;

  let fieldName: string;
  let fieldValue = searchInput;

  const searchPhone = searchInput?.replace(/\D/g, '');
  const containsLetters = /[a-zA-Z]/g.test(searchInput);
  if (searchInput.includes('@')) {
    fieldName = 'customer.email';
  } else if (containsLetters) {
    fieldName = 'customer.name';
  } else {
    fieldName = 'customer.phone';
    fieldValue = searchPhone;
  }

  return {
    fieldName,
    fieldValue,
    searchOperation: SearchAPIOperators.CONTAINS,
  };
};

const checkoutReadySearchCriteriaItems: SearchCriteriaItem[] = [
  {
    fieldName: 'application.status',
    fieldValue: 'APPROVED',
    searchOperation: SearchAPIOperators.IN,
  },
  {
    fieldName: 'application.validity',
    fieldValue: 'ACTIVE',
    searchOperation: SearchAPIOperators.EQUALITY,
  },
  {
    fieldName: 'application.expireAt',
    fieldValue: dayjs().utc().toISOString(),
    searchOperation: SearchAPIOperators.GREATER_THAN,
  },
];

const setExpireAtCondition = (selectedStatus) => {
  switch (selectedStatus) {
    case APPLICATION_STATUSES.EXPIRING_SOON:
      return [
        {
          fieldName: 'application.expireAt',
          fieldValue: dayjs().utc().add(5, 'day').toISOString(),
          searchOperation: SearchAPIOperators.LESS_THAN,
        },
        {
          fieldName: 'application.expireAt',
          fieldValue: dayjs().utc().toISOString(),
          searchOperation: SearchAPIOperators.GREATER_THAN,
        },
      ];
    case APPLICATION_STATUSES.EXPIRED:
      return [
        {
          fieldName: 'application.expireAt',
          fieldValue: dayjs().utc().toISOString(),
          searchOperation: SearchAPIOperators.LESS_THAN,
        },
      ];
    case APPLICATION_STATUSES.NOT_STARTED:
    case APPLICATION_STATUSES.ALL_APPLICATION:
      return [];
    default:
      return [
        {
          fieldName: 'application.expireAt',
          fieldValue: dayjs().utc().toISOString(),
          searchOperation: SearchAPIOperators.GREATER_THAN,
        },
      ];
  }
};

export const TreatmentPlans = (
  isCheckoutReady: boolean,
  organizationId: number | undefined,
  searchValue: string,
  selectedStages: any[],
  selectedLocations: any[],
  isShowArchived: boolean,
  selectedStatus?: string,
) => {
  const searchCriteriaItems: SearchCriteriaItem[] = [
    {
      fieldName: 'organizationId',
      fieldValue: organizationId,
      searchOperation: SearchAPIOperators.EQUALITY,
    },
  ];

  const searchInputItem = createSearchInputItem(searchValue);
  if (searchInputItem) {
    searchCriteriaItems.push(searchInputItem);
  }

  if (selectedStages?.length > 0) {
    searchCriteriaItems.push({
      fieldName: 'stage',
      fieldValue: selectedStages?.map((stage) => stage.value)?.join(','),
      searchOperation: SearchAPIOperators.IN,
    });
  }

  if (selectedLocations?.length > 0) {
    searchCriteriaItems.push({
      fieldName: 'application.merchantId',
      fieldValue: selectedLocations?.map((stage) => stage.value)?.join(','),
      searchOperation: SearchAPIOperators.IN,
    });
  }

  if (!isShowArchived) {
    searchCriteriaItems.push({
      fieldName: 'status',
      fieldValue: 'ACTIVE',
      searchOperation: SearchAPIOperators.EQUALITY,
    });
  } else {
    searchCriteriaItems.push({
      fieldName: 'status',
      fieldValue: 'ACTIVE,ARCHIVED',
      searchOperation: SearchAPIOperators.IN,
    });
  }

  const expireDateCriteria = setExpireAtCondition(selectedStatus);
  searchCriteriaItems.push(...expireDateCriteria);

  if (isCheckoutReady) {
    searchCriteriaItems.push(...checkoutReadySearchCriteriaItems);
  } else {
    switch (selectedStatus) {
      case APPLICATION_STATUSES.PRE_APPROVED:
        searchCriteriaItems.push({
          fieldName: 'application.status',
          fieldValue: 'PREAPPROVED',
          searchOperation: SearchAPIOperators.EQUALITY,
        });
        break;
      case APPLICATION_STATUSES.IN_PROGRESS:
        searchCriteriaItems.push({
          fieldName: 'application.status',
          fieldValue:
            'REVIEW,AWAITING_APPROVAL_BOOST,AWAITING_DEFAULT_HIGH_LINE,AWAITING_DOCS,AWAITING_FRAUD,AWAITING_KYC,FROZEN,INCOME_VERIFICATION,INITIALIZED',
          searchOperation: SearchAPIOperators.IN,
        });
        break;
      case APPLICATION_STATUSES.NOT_APPROVED:
        searchCriteriaItems.push({
          fieldName: 'application.status',
          fieldValue: 'DENIED',
          searchOperation: SearchAPIOperators.EQUALITY,
        });
        break;
      case APPLICATION_STATUSES.NOT_STARTED:
        searchCriteriaItems.push({
          fieldName: 'application.id',
          fieldValue: null,
          searchOperation: SearchAPIOperators.EQUALITY,
        });
        break;
      default:
        break;
    }
  }

  return searchCriteriaItems;
};

export const CheckoutReadyTreatmentPlans = (
  searchValue: string,
  selectedStages: any[],
  selectedLocations: any[],
  isShowArchived: boolean,
) => {
  const createRawSearch = (searchInput: string | undefined) => {
    if (searchInput) {
      let searchString = '( ';
      if (searchInput.includes('@')) {
        searchString = searchString + `customerEmail:*{${searchInput}}*`;
      } else if (isNaN(Number(searchInput))) {
        const nameParts = searchInput.split(' ');
        if (nameParts.length > 1) {
          searchString =
            searchString + `customerFirstName:*{${nameParts[0]}}* AND customerLastName:*{${nameParts[1]}}*`;
        } else {
          searchString = searchString + `customerFirstName:*{${searchInput}}* OR customerLastName:*{${searchInput}}*`;
        }

        const searchPhone = searchInput?.replace(/\D/g, '');
        searchString = searchString + ` OR customerPhone:*{${searchPhone === '' ? searchInput : searchPhone}}*`;
      } else {
        searchString = searchString + `customerPhone:*{${searchInput}}*`;
      }
      searchString = searchString + ' )';
      return searchString;
    } else {
      return undefined;
    }
  };

  const searchCriteriaItems: any[] = [
    {
      key: 'applicationStatus',
      value: 'APPROVED',
      operator: GenericSearchOperators.IN,
    },
    {
      key: 'applicationExpireAt',
      value: dayjs().utc().endOf('day').toISOString(),
      operator: GenericSearchOperators.BIGGER_THAN,
    },
    {
      key: 'applicationValidity',
      value: 'ACTIVE',
    },
  ];

  if (searchValue) {
    searchCriteriaItems.push({
      key: 'raw',
      value: createRawSearch(searchValue),
      operator: GenericSearchOperators.EQUAL,
    });
  }

  if (selectedStages?.length > 0) {
    searchCriteriaItems.push({
      key: 'stage',
      value: selectedStages?.map((stage) => stage.value)?.join(','),
      operator: GenericSearchOperators.IN,
    });
  }

  if (selectedLocations?.length > 0) {
    searchCriteriaItems.push({
      key: 'applicationMerchantId',
      value: selectedLocations?.map((stage) => stage.value)?.join(','),
      operator: GenericSearchOperators.IN,
    });
  }

  if (!isShowArchived) {
    searchCriteriaItems.push({
      key: 'status',
      value: 'ACTIVE',
      operator: GenericSearchOperators.EQUAL,
    });
  } else {
    searchCriteriaItems.push({
      key: 'status',
      value: 'ACTIVE,ARCHIVED',
      operator: GenericSearchOperators.IN,
    });
  }

  return searchCriteriaItems;
};
