import { CherryDialogContent, CherryDialogContentText, CherryDialogTitle } from 'lib/components/Dialogue';
import React from 'react';

export const Walkthrough = () => {
  return (
    <>
      <CherryDialogTitle id="alert-dialog-title">Relaunch Walkthrough</CherryDialogTitle>
      <CherryDialogContent>
        <CherryDialogContentText id="alert-dialog-description">
          Are you sure you want to relaunch the Guided Walkthrough?
        </CherryDialogContentText>
      </CherryDialogContent>
    </>
  );
};
