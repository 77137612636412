import { Dialog, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@frontend/cherry-library';
import dayjs from 'dayjs';
import client from 'lib/graphql/client';
import { FETCH_LOANS } from 'lib/graphql/queries';
import { FetchLoanListSearch } from 'lib/graphql/searches';
import { useLoanSearch } from 'lib/hooks/useLoanSearch';
import useStore from 'lib/hooks/useStore';
import { TransactionTableItem } from 'lib/tables';
import { CsvDownloadEnums } from 'lib/types';
import {
  arrayToCsv,
  downloadBlobByContentType,
  MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT,
  transactionsCsvDownloadParser,
  TransactionsCSVHeaders,
} from 'lib/utils';
import { CherryDialogTitle, ContinueButton, DialogContainer, SubTitle } from 'pages/desktop/Settings/components/Dialog';
import { useAlert } from 'react-alert';

export const CsvModal = ({
  open,
  handleClose,
  selectedDate,
  totalTransactionCount,
  sortDirection,
  sortColumn,
  searchInput,
  rawStatuses,
  defaultDisbursmentTypes,
}) => {
  const [activeStep, setActiveStep] = useState<CsvDownloadEnums>(CsvDownloadEnums.CONFIRM_DOWNLOAD);
  const [allTransactions, setAllTransactions] = useState<TransactionTableItem[]>([]);
  const { selectedMerchant, merchants: storedMerchants } = useStore();
  const { mapTransactionToTableData } = useLoanSearch();
  const alert = useAlert();

  useEffect(() => {
    if (totalTransactionCount < MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT) {
      setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
    } else {
      setActiveStep(CsvDownloadEnums.EXCEED_LIMIT);
    }
  }, [totalTransactionCount]);

  const moveDownload = async () => {
    setActiveStep(CsvDownloadEnums.LOADING);
    const merchantIds = storedMerchants?.map((merchant) => merchant.id);
    const merchants = `${selectedMerchant?.id || merchantIds?.join(',') || ''}`;

    try {
      const { data: transactionData } = await client.query({
        query: FETCH_LOANS,
        variables: {
          input: {
            limit: totalTransactionCount,
            offset: 0,
            orderCriteriaItems: [
              {
                sort: sortDirection,
                fieldName: sortColumn,
              },
            ],
            searchCriteriaItems: FetchLoanListSearch(
              selectedDate,
              merchants,
              rawStatuses,
              defaultDisbursmentTypes,
              searchInput,
            ),
          },
        },
      });

      const transactionsCsvs =
        transactionData?.fetchLoans?.contents?.filter((item) => !['CANCELLED', 'EXPIRED'].includes(item?.status)) || [];
      const mappedAllTransactions = mapTransactionToTableData(transactionsCsvs, true);
      setAllTransactions(mappedAllTransactions);
      setActiveStep(CsvDownloadEnums.FILE_READY);
    } catch (e) {
      alert.error('An Error Occurred! Please try again.');
      setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
    }
  };

  const resetOnClose = () => {
    handleClose();
    setActiveStep(CsvDownloadEnums.CONFIRM_DOWNLOAD);
  };

  switch (activeStep) {
    case CsvDownloadEnums.CONFIRM_DOWNLOAD:
      return (
        <ConfirmDownload
          handleClose={handleClose}
          moveDownload={moveDownload}
          totalTransactionCount={totalTransactionCount}
          selectedDate={selectedDate}
          open={open}
        />
      );
    case CsvDownloadEnums.FILE_READY:
      return (
        <FileReady
          selectedDate={selectedDate}
          open={open}
          allTransactions={allTransactions}
          resetOnClose={resetOnClose}
        />
      );
    case CsvDownloadEnums.EXCEED_LIMIT:
      return <ExceededLimit resetOnClose={resetOnClose} open={open} totalTransactionCount={totalTransactionCount} />;
    case CsvDownloadEnums.LOADING:
      return <Loading open={open} resetOnClose={resetOnClose} />;
  }
};

const FileReady = ({ resetOnClose, open, selectedDate, allTransactions }) => {
  const { merchants: storedMerchants } = useStore();
  const fileName = `cherry_transactions${dayjs(selectedDate.from).format('DDMMYYYY')}_${dayjs(selectedDate.to).format(
    'DDMMYYYY',
  )}.csv`;

  const downloadCSV = () => {
    let csv = null;
    const parsedTransactions: any[] = allTransactions?.map(
      (o) => transactionsCsvDownloadParser(o, storedMerchants) || [],
    );

    if (storedMerchants && storedMerchants?.length > 1) {
      csv = arrayToCsv([TransactionsCSVHeaders, ...parsedTransactions]);
    } else {
      const removedLocationCsvHeader = TransactionsCSVHeaders.filter(
        (element, index) => index < TransactionsCSVHeaders.length - 1,
      );
      csv = arrayToCsv([removedLocationCsvHeader, ...parsedTransactions]);
    }

    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
    resetOnClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={resetOnClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Transactions
        </CherryDialogTitle>
        <SubTitle>Your file is ready!</SubTitle>
        <Link onClick={downloadCSV}>{fileName}</Link>

        <Grid container={true} spacing={1} style={{ marginTop: '16px' }}>
          <Button fullWidth={true} disabled={false} onClick={downloadCSV}>
            Download File
          </Button>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const ConfirmDownload = ({ moveDownload, handleClose, open, selectedDate, totalTransactionCount }) => {
  return (
    <Dialog maxWidth="sm" aria-labelledby="customized-dialog-title" open={open} onClose={handleClose}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Download Transactions
        </CherryDialogTitle>
        <SubTitle>
          We'll prepare a CSV file for transactions between {dayjs(selectedDate.from).format('MM/DD/YYYY')} and{' '}
          {dayjs(selectedDate.to).format('MM/DD/YYYY')}.
        </SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <Button fullWidth={true} variant="secondary" disabled={false} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <Button fullWidth={true} disabled={false} onClick={moveDownload}>
              Prepare File
            </Button>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const ExceededLimit = ({ open, resetOnClose, totalTransactionCount }) => {
  return (
    <Dialog maxWidth="sm" onClose={resetOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Limit Exceeded
        </CherryDialogTitle>
        <SubTitle>
          <b>{totalTransactionCount}</b> transactions are included in this request.
          <br />
          <b> The limit is {MAX_TRANSACTIONS_CSV_DOWNLOAD_LIMIT}.</b> Please select smaller time frame.
        </SubTitle>
        <Grid container={true} spacing={1}>
          <RemoveButton disabled={false} onClick={resetOnClose}>
            Okay
          </RemoveButton>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

const Loading = ({ open, resetOnClose }) => {
  return (
    <Dialog maxWidth="sm" onClose={resetOnClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={resetOnClose}>
          Download Transactions
        </CherryDialogTitle>
        <CenterFlex>
          <Stack> Preparing your file...</Stack>
          <LinearProgress color="primary" />
        </CenterFlex>
      </DialogContainer>
    </Dialog>
  );
};

const RemoveButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;

const Link = styled.a`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: center;
  margin: auto;
`;

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  flex-direction: column;

  div {
    padding-top: 4px;
    border-radius: 8px;
    width: 100%;
  }

  .MuiLinearProgress-indeterminate {
    background-color: #dadada;
  }
  .MuiLinearProgress-barColorPrimary,
  .MuiLinearProgress-bar1Indeterminate {
    background-color: ${(props) => props.theme.main.green};
  }
`;

const Stack = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;
