export const cherryDate = '01/01/2019';

export const MIN_ALLOWED_REFUND_AMOUNT = 200;
export const MAX_ALLOWED_REFUND_AMOUNT = 10000;

export enum DAILY_FREQUENCY_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum CHART_ENUM {
  APPROVAL_APPLICATION = 'APPROVAL_APPLICATION',
  EXPIRED_APPLICATION = 'EXPIRED_APPLICATION',
  TRANSACTIONS = 'TRANSACTIONS',
  PATIENT_BREAKDOWN = 'PATIENT_BREAKDOWN',
  MEMBERSHIP_GROWTH = 'MEMBERSHIP_GROWTH',
  PLAN_REVENUE = 'PLAN_REVENUE',
}
export const APPLICATION_LINK_PLATFORMS = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'socialmedia',
    label: 'Social Media',
  },
  {
    value: 'website',
    label: 'Website',
  },
  {
    value: 'qrcode',
    label: 'QR Code',
  },
  {
    value: 'textmessage',
    label: 'Text Message',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export enum SCHEDULE_VIEW {
  Applications = 'Applications View',
  Appointments = 'Appointments View',
  View_Applications = 'View Applications',
  View_Appointments = 'View Appointments',
}

export enum SCHEDULE_VIEW_REMINDER {
  Title = '🗓️ New: Patient Appointments View',
  Content = 'You can now see when patients are scheduled to come in for their appointments. From this view you can send Cherry applications, reminders, and checkout links to your patients.',
  Button = 'Got It',
}

export enum Appointment_Events {
  INITIATED_APPLICATION = 'Send Application Initiated from Schedule View',
  INITIATED_REMINDER = 'Send Reminder Initiated from Schedule View',
  SENT_APPLICATION = 'Application Sent from Schedule View',
  SENT_REMINDER = 'Reminder Sent from Schedule View',
  INITIATED_CHECKOUT = 'Checkout Initiated from Schedule View',
  SENT_CHECKOUT = 'Checkout Link Sent from Schedule View',
  COMPONENT = 'Schedule View',
}

export enum Application_Events {
  CHECKOUT_APPLICATION = 'Checkout Link Sent',
}

export enum DateNames {
  THIS_WEEK = 'This Week',
  ALL_TIME = 'All Time',
}

export enum SCHEDULE_VIEW_STATUS {
  NOT_STARTED = 'Application Not Started',
  IN_PROGRESS = 'Application In-Progress',
  EXPIRED = 'Application Expired',
  APPROVED = 'Remaining Balance: ',
  PENDING = 'Purchase Pending',
}
export enum SCHEDULE_VIEW_BUTTONS {
  APPLICATION = 'Send Application',
  REMINDER = 'Send Reminder',
  CHECKOUT = 'Checkout',
}

export enum LOCATION_MANAGEMENT_STATUS_LIST {
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  ADDITIONAL_MATERIALS_REQUESTED = 'ADDITIONAL_MATERIALS_REQUESTED',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
}

export enum MARKETING_MATERIAL_REQUEST_REASON {
  OUT_OF_ITEM = 'OUT_OF_ITEM',
  DAMAGED = 'DAMAGED',
  NEVER_RECEIVED = 'NEVER_RECEIVED',
  OTHER = 'OTHER',
}

export enum MARKETING_MATERIAL_TYPES {
  BROCHURE = 'TRIFOLD_BROCHURE',
  ONE_PAGER = 'PATIENT_ONE_PAGER',
  QR_CARD = 'QR_CARD',
  QR_CODE = 'QR_CODE',
  FULL_KIT = 'FULL_KIT',
}

export const MARKETING_MATERIAL_IDS = {
  [MARKETING_MATERIAL_TYPES.BROCHURE]: 4001,
  [MARKETING_MATERIAL_TYPES.ONE_PAGER]: 4003,
  [MARKETING_MATERIAL_TYPES.QR_CARD]: 4004,
  [MARKETING_MATERIAL_TYPES.QR_CODE]: 4005,
  [MARKETING_MATERIAL_TYPES.FULL_KIT]: 4006,
};

export const MARKETING_MATERIAL_QUANTITIES = {
  [MARKETING_MATERIAL_TYPES.BROCHURE]: [50, 100, 150, 200, 250],
  [MARKETING_MATERIAL_TYPES.ONE_PAGER]: [50, 100, 150, 200, 250],
  [MARKETING_MATERIAL_TYPES.QR_CARD]: [1, 2, 3, 4, 5],
  [MARKETING_MATERIAL_TYPES.FULL_KIT]: [1],
};

export const MODE_TEXTS = {
  DEMO_MODE: 'Demonstration Mode',
  GUEST_MODE: 'Guest Mode',
};

export const APPOINTMENT_FAIL_MODAL = {
  INVALID_PHONE: 'Invalid Phone Number',
  MISSING_PHONE: 'Missing Phone Number',
  SUCCESS: 'Success!',
  INVALID_TEXT_SHORT: 'The patient’s phone number appears invalid.',
  MISSING_PHONE_SHORT: 'We don’t have a phone number for this patient.',
  FAIL_TEXT:
    'To send an application, please update their patient account in your practice management system, sync your appointments, and then try again.',
};

export const PATIENT_URL = 'https://patient.withcherry.com';
export const NOTE_LENGTH = 400;

export const DENTAL_INDUSTRIES = [
  'General Dentistry',
  'Dental',
  'Dental & Orthodontics',
  'Oral Surgery',
  'Orthodontics',
  'Periodontics',
];

export const isOrganizationDental = (organizationIndustry: string | undefined) => {
  return !!organizationIndustry && DENTAL_INDUSTRIES.includes(organizationIndustry);
};

export const US_STATES = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Delaware', value: 'DE' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];

export const confettiColors: string[] = ['#00bb71', '#1D291D', '#C5F1E1', '#B1BC74', '#00bb71'];
