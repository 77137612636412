import { Box } from '@mui/material';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view';
import React from 'react';
import styled from 'styled-components';

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  bgColorForDarkMode?: string;
  color?: string;
  colorForDarkMode?: string;
  labelInfo?: string;
  labelText: string;
  type: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.main.darkBlue,
  height: 'auto',
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  borderBottom: '1px solid #dadada',
  [`& .${treeItemClasses.content}`]: {
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: '#F2F4F5',
    },
  },
}));

const IconContainer = styled.div`
  margin-left: 12px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, type, labelInfo, labelText, colorForDarkMode, bgColorForDarkMode, ...other } = props;

  const getLabelIcon = (treeItemType: string) => {
    switch (treeItemType) {
      case 'ORGANIZATION_GROUP':
        return (
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.3334 7.50033C15.3334 11.5504 12.0502 14.8337 8.00008 14.8337C3.94999 14.8337 0.666748 11.5504 0.666748 7.50033C0.666748 3.45024 3.94999 0.166992 8.00008 0.166992C12.0502 0.166992 15.3334 3.45024 15.3334 7.50033ZM8.00008 13.8975C11.5331 13.8975 14.3972 11.0334 14.3972 7.50033C14.3972 3.96727 11.5331 1.10316 8.00008 1.10316C4.46703 1.10316 1.60292 3.96727 1.60292 7.50033C1.60292 11.0334 4.46703 13.8975 8.00008 13.8975Z"
              fill="black"
            />
          </svg>
        );
      case 'ORGANIZATION':
        return (
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.54537 13.287V5.22078C2.54534 5.09629 2.58359 4.97479 2.65493 4.8728C2.72627 4.77081 2.82725 4.69326 2.94416 4.65069L8.80537 2.51829C8.85116 2.50161 8.90029 2.49623 8.94859 2.5026C8.9969 2.50897 9.04295 2.5269 9.08286 2.55487C9.12277 2.58284 9.15535 2.62003 9.17783 2.66328C9.20032 2.70654 9.21205 2.75458 9.21204 2.80333V5.80725L13.0399 7.0839C13.1607 7.12414 13.2657 7.20139 13.3401 7.30469C13.4145 7.408 13.4545 7.53212 13.4545 7.65946V13.287H14.6666V14.5H1.33325V13.287H2.54537ZM3.75749 13.287H7.99992V4.10242L3.75749 5.64532V13.287ZM12.2423 13.287V8.09673L9.21204 7.08572V13.287H12.2423Z"
              fill="black"
            />
          </svg>
        );
      case 'MERCHANT_GROUP':
        return (
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.00048 10.4639H5.26973V7.73343H4.35947V11.8292C4.35947 12.0809 4.56286 12.2843 4.8146 12.2843H8.45561C8.70735 12.2843 8.91073 12.0809 8.91073 11.8292V7.73343H8.00048V10.4639ZM12.4749 6.57013L11.2617 4.74979C11.1764 4.62322 11.0342 4.54785 10.882 4.54785H5.11897C4.96678 4.54785 4.82456 4.62322 4.74064 4.74979L3.52745 6.57013C3.32549 6.87304 3.54167 7.27835 3.90577 7.27835H12.0966C12.4593 7.27835 12.6755 6.87304 12.4749 6.57013ZM10.7312 12.0567C10.7312 12.1819 10.8336 12.2843 10.9588 12.2843H11.4139C11.5391 12.2843 11.6415 12.1819 11.6415 12.0567V7.73343H10.7312V12.0567Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.3334 8.50033C15.3334 12.5504 12.0502 15.8337 8.00008 15.8337C3.94999 15.8337 0.666748 12.5504 0.666748 8.50033C0.666748 4.45024 3.94999 1.16699 8.00008 1.16699C12.0502 1.16699 15.3334 4.45024 15.3334 8.50033ZM8.00008 14.8975C11.5331 14.8975 14.3972 12.0334 14.3972 8.50033C14.3972 4.96727 11.5331 2.10316 8.00008 2.10316C4.46703 2.10316 1.60292 4.96727 1.60292 8.50033C1.60292 12.0334 4.46703 14.8975 8.00008 14.8975Z"
              fill="black"
            />
          </svg>
        );
      case 'MERCHANT':
        return (
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99948 11.8337H3.9991V7.83366H2.66564V13.8337C2.66564 14.2024 2.96359 14.5003 3.33237 14.5003H8.66621C9.035 14.5003 9.33294 14.2024 9.33294 13.8337V7.83366H7.99948V11.8337ZM14.5543 6.12949L12.777 3.46283C12.652 3.27741 12.4437 3.16699 12.2207 3.16699H3.77825C3.55531 3.16699 3.34696 3.27741 3.22403 3.46283L1.44678 6.12949C1.15092 6.57324 1.46761 7.16699 2.001 7.16699H14.0001C14.5314 7.16699 14.848 6.57324 14.5543 6.12949ZM11.9999 14.167C11.9999 14.3503 12.1499 14.5003 12.3332 14.5003H13C13.1833 14.5003 13.3333 14.3503 13.3333 14.167V7.83366H11.9999V14.167Z"
              fill="black"
            />
          </svg>
        );
    }
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pr: 0,
          }}
        >
          <IconContainer>{getLabelIcon(type)}</IconContainer>
          <p>{labelText}</p>
        </Box>
      }
      {...other}
    />
  );
}
