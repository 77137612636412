import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { RefundStepEnums } from 'lib/types';
import CancelRefund from './CancelRefund';
import IssuePartailRefund from './IssuePartialRefund';
import IssueRefund from './IssueRefund';
import IssueRefundApprove from './IssueRefundApprove';
import VoidTransaction from './VoidTransaction';

export const Refund = ({
  refundApproveButtonHandler,
  refundCancelButtonHandler,
  voidTransactionHandler,
  fullRefundLoan,
  item,
  setExpandableOpened,
}) => {
  const { refundIssueType, setRefundIssueType } = useStore();

  const [activeStep, setActiveStep] = useState(refundIssueType);
  const [refundType, setRefundType] = useState('full');
  const [amount, setAmount] = useState(0);

  const voidTransactionAllowed: boolean =
    item?.status === 'AWAITING_FUNDING' ||
    (item?.status === 'FUNDED' && (item?.subStatus === 'HOLD' || item?.subStatus === 'FAILED'));

  useEffect(() => {
    setExpandableOpened(true);
    if (
      (item?.status === 'REFUNDED' && item?.subStatus === 'PROCESSING') ||
      (item?.status === 'FUNDED' && item?.fundStatus === 'HOLD')
    ) {
      setActiveStep(RefundStepEnums.CANCEL_REFUND);
    } else if (voidTransactionAllowed) {
      setActiveStep(RefundStepEnums.VOID_TRANSACTION);
    } else if (item?.status === 'FUNDED' && ['COMPLETED'].includes(item?.subStatus) && item?.fundStatus !== 'HOLD') {
      // setActiveStep(RefundStepEnums.ISSUE_REFUND);
    }

    return () => {
      setExpandableOpened(false);
    };
  }, []);

  const onStepChange = (step: RefundStepEnums, data: { refundType: string; amount: number }) => {
    setActiveStep(step);
    setRefundIssueType(step);
    setRefundType(data.refundType);
    setAmount(data.amount);
  };

  const renderConditionalComponents = () => {
    switch (activeStep) {
      case RefundStepEnums.LOADING:
        return (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        );
      case RefundStepEnums.ISSUE_REFUND:
        return <IssueRefund item={item} setActiveStep={onStepChange} />;
      case RefundStepEnums.ISSUE_PARTIAL_REFUND:
        return <IssuePartailRefund item={item} setActiveStep={onStepChange} />;
      case RefundStepEnums.ISSUE_REFUND_APPROVE:
        return (
          <IssueRefundApprove
            refundApproveButtonHandler={refundApproveButtonHandler}
            item={item}
            refundType={refundType}
            amount={amount}
            setActiveStep={onStepChange}
          />
        );
      case RefundStepEnums.CANCEL_REFUND:
        return <CancelRefund cancelRefundButtonHandler={refundCancelButtonHandler} item={item} />;
      case RefundStepEnums.VOID_TRANSACTION:
        return <VoidTransaction voidTransactionHandler={voidTransactionHandler} item={item} />;
      default:
        return <IssueRefund item={item} setActiveStep={onStepChange} />;
    }
  };

  return (
    <>
      <HorizontalLine /> {renderConditionalComponents()}
    </>
  );
};

const HorizontalLine = styled.hr`
  background: #dadada;
  outline: none;
  box-shadow: 0 0 0 0;
  width: 98%;
  opacity: 30%;
  margin: 0px;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
