import React from 'react';
import styled from 'styled-components';

import { Button } from '@mui/material';

import { Loading } from 'lib/components';

interface PrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

interface ButtonPrimaryProps {
  loading?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  text: string | React.ReactNode;
  onClick?: () => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  btnVariant?: React.ReactNode;
  fullWidth?: boolean;
}

export const PrimaryButton = ({ loading, children, ...rest }: PrimaryButtonProps) => {
  return <CustomButton {...rest}>{loading ? <Loading size={10} /> : children}</CustomButton>;
};

export const ButtonPrimary = ({
  loading,
  id,
  children,
  text,
  startIcon,
  btnVariant,
  endIcon,
  disabled,
  ...rest
}: ButtonPrimaryProps) => {
  return (
    <MaterialButton
      {...rest}
      id={id}
      startIcon={startIcon}
      disableElevation={true}
      disabled={disabled}
      endIcon={endIcon}
      variant={'contained'}
    >
      {loading ? <Loading size={14} /> : text}
    </MaterialButton>
  );
};

const CustomButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #00c37d;
  border: none;
  outline: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px -2px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  &:disabled {
    background-color: #c4c4c4 !important;
  }
`;
interface MaterialButtonProps {
  textTransform?: boolean;
}

const MaterialButton = styled(Button)<MaterialButtonProps>`
  background-color: ${(props) => (props.disabled ? '#c4c4c4' : props.theme.main.green)} !important;
  text-transform: capitalize !important;
  border: ${(props) => `1px solid ${props.disabled ? '#c4c4c4' : '#00c37d'}`} !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif !important;
  ${(props) => props.fullWidth && 'width: 100%;'}
  &:focus,
  &:hover {
    background-color: #00a569 !important;
  }
`;

interface SecondaryButtonProps {
  variant: string;
  color?: string;
}

export const CancelLoanButton = styled.button<SecondaryButtonProps>`
  background-color: white;
  border: 1px solid ${(props) => (props.color ? props.color : props.theme.main.green)};
  border-radius: 2px;
  color: ${(props) => (props.color ? props.color : props.theme.main.green)};
  padding: 8px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  height: 40px;
  width: 100%;
  font-family: 'Open Sans', sans-serif !important;
`;

export const SecondaryCancelLoanButton = styled(CancelLoanButton)`
  font-weight: 600;
  font-size: 16px;
`;

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  background-color: ${(props) => (props.variant === 'filled' ? '#EC3360' : '#FFFFFF')};
  border: 1px solid #ec3360;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;
  font-weight: 400;
  outline: none;
  height: 40px;
  color: #ffffff;
  width: 100%;
  font-family: 'Open Sans', sans-serif !important;
`;

export const TertiaryButton = styled(SecondaryButton)`
  font-weight: 600;
  font-size: 16px;
`;

export const ButtonSecondary = styled(ButtonPrimary)<{
  isNewSecondary?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
}>`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid ${(props) => (props.isNewSecondary ? props.theme.main.green : '#879097')} !important;
  color: ${(props) => (props.isNewSecondary ? props.theme.main.green : '#879097')} !important;
  border-radius: 4px !important;
  ${(props) => !props.noMargin && 'margin-left: 16px !important;'}
  ${(props) => props.fullWidth && 'width: 100%;'}
  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;
