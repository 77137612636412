import jwtDecode from 'jwt-decode';
import StorageService from 'lib/services/Storage';
import Storage from 'lib/services/Storage';
import { isExpiredToken } from 'lib/utils';
import { useEffect, useState } from 'react';

const useAgent = () => {
  const [isAgent, setIsAgent] = useState<boolean>();
  const { refreshToken } = Storage.getAuthData();

  const isRefreshTokenEmpty = !refreshToken || refreshToken === '';
  const token = StorageService.getUserToken();
  const user = !isExpiredToken(token) && token ? (jwtDecode((token as any) || '') as any)?.user : null;

  useEffect(() => {
    const isCherryAgent = isRefreshTokenEmpty && user?.roles?.length > 0;
    setIsAgent(isCherryAgent);
  }, [user]);

  return { isAgent };
};

export default useAgent;
