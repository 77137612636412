export const createRawSearch = (searchInput: string | undefined) => {
  if (searchInput) {
    let searchString = '( ';
    if (searchInput.includes('@')) {
      searchString = searchString + `email:*{${searchInput}}*`;
    } else if (isNaN(Number(searchInput))) {
      const nameParts = searchInput.split(' ');
      if (nameParts.length > 1) {
        searchString = searchString + `firstName:*{${nameParts[0]}}* AND lastName:*{${nameParts[1]}}*`;
      } else {
        searchString = searchString + `firstName:*{${searchInput}}* OR lastName:*{${searchInput}}*`;
      }
    } else {
      searchString = searchString + `phone:*{${searchInput}}*`;
    }
    searchString = searchString + ' )';
    return searchString;
  } else {
    return undefined;
  }
};
