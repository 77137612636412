import { theme } from '@frontend/cherry-library';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 40px;
`;

export const SearchWrapper = styled.div`
  width: 480px;
  margin-right: auto;
  margin-left: auto;
`;

export const SettingBox = styled.div`
  border-radius: 8px;
  background: ${theme.main.white};
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
  user-select: none;
  cursor: pointer;
  align-items: center;

  &:hover {
    box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
      0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
      0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
  }
`;

export const SettingBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SettingBoxTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
export const SettingBoxText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const DropdownContainer = styled.div`
  background: ${theme.main.white};
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
  position: fixed;
  width: 480px;
  max-height: 400px;
  overflow-y: auto;
`;

export const Option = styled.div`
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px solid ${theme.main.midnightBlue20};

  :last-child {
    border-bottom: unset;
  }
`;

export const OptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 24px 24px 24px 24px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.main.midnightBlue10};
  }

  svg {
    height: 24px;
    width: 24px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
export const SubOptionsWrapper = styled.div``;

export const SubOption = styled.div`
  padding: 16px 24px 16px 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${theme.main.midnightBlue10};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 280px;
  min-width: 200px;
`;

export const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.isActive ? theme.main.midnightBlue : theme.main.midnightBlue50)};
  font-size: 16px;
  font-weight: ${(props) => props.isActive && '700'};
  cursor: pointer;
  width: fit-content;

  color: transparent;
  background-image: linear-gradient(90deg, ${theme.main.midnightBlue} 50%, ${theme.main.midnightBlue50} 50%);
  background-position: ${(props) => (props.isActive ? 0 : '100%')};
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  height: 33px;

  &:hover {
    font-weight: 700;
    background-position: 0;
    transition: background-position 0.5s ease;
  }

  span {
    margin-left: 8px;
  }
`;

export const StickyMenu = styled.div`
  position: sticky;
  top: 100px;
`;

export const SettingsSectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .settings-launch-page-navigation-dropdown {
    max-width: 480px;
    width: 100%;
  }
`;

export const SettingsSubPagesWrapper = styled.div`
  width: 100%;
`;

export const MenuWrapper = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ActiveItemHighlight = styled.div<{ positionY?: number }>`
  height: 33px;
  width: 5px;
  background-color: ${theme.main.kellyGreen};
  position: fixed;
  top: ${(props) => (props.positionY ? `${props.positionY - 6}px` : 'unset')};
  transition: top 0.4s ease;
`;
export const FixedHighlight = styled.div<{ show: boolean }>`
  height: 33px;
  width: 5px;
  background-color: ${theme.main.kellyGreen};
  margin-left: -8px;
  margin-top: -6px;
  opacity: ${(props) => (props.show ? '1' : '0')};
`;

export const SettingSubPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccountDetailsTable = styled.div`
  min-width: 250px;
  max-width: 650px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: ${theme.main.white};
  box-shadow: rgba(0, 0, 0, 0.07) 0px 6px 9px, rgba(0, 0, 0, 0.035) 0px 0.751293px 1.12694px;
  border-radius: 4px;
  padding: 24px;
`;

export const AccountDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.main.midnightBlue10};
  gap: 16px;

  &:last-child {
    border-bottom: unset;
    padding-bottom: 0;
  }
`;

export const AccountDetailsTitle = styled.div`
  font-weight: 700;
`;

export const AccountDetailsText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 752px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
  aspect-ratio: 2.2;
  margin-bottom: 40px;

  border-radius: 8px;
  background: ${theme.main.white};
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
`;

export const UnauthorizedLockIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnauthorizedText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 8px;

  border-radius: 4px;
  border: 1px solid ${theme.main.midnightBlue50};
  background: ${theme.main.midnightBlue5};

  svg {
    min-height: 16px;
    min-width: 16px;
  }

  svg path {
    fill: ${theme.main.midnightBlue};
  }
`;

export const UnauthorizedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
