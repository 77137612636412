import React from 'react';

import { DropDown } from 'lib/components';
import { DropdownContainer, Gap } from './components';
import { getDeniedApplicationActions } from './utils';

import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { SEND_APPLICATION_LINK } from 'lib/graphql/mutations';
import { useMerchantLogin } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useAlert } from 'react-alert';
import Popover from './components/Popover';

export const Denied = ({ row }) => {
  const NewDropDown = DropDown;
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const { organization, merchants } = useStore();
  const { merchantLogin } = useMerchantLogin();
  const alert = useAlert();

  const dateDiff = dayjs(dayjs().startOf('day')).diff(dayjs(row.createdAt).startOf('day'), 'day');

  const eligibleForReapply = dateDiff < 30;

  const onContinueHandler = async () => {
    try {
      const idOrganization = organization?.id || 0;
      const idMerchant = (merchants && merchants?.[0]?.id) || 0;
      await merchantLogin(idMerchant);

      await sendOrganizationLink({
        variables: { input: { idOrganization, phone: row?.phoneNumberRaw } },
      });
      alert.success(`Application link sent to ${row?.phone} successfully`);
    } catch (error) {
      alert.error('An error occurred, please try again later');
    }
  };

  return (
    <>
      <Popover
        row={row}
        ternary={true}
        left={125}
        type="notapproved"
        backgroundColor="#E7E9EA"
        label="Not Approved"
        iconName="info_circle_outline"
        hoverText="Patients can view the reason for our decision from their patient portal (patient.withcherry.com). Typically, patients can re-apply after 30 days."
      />

      {!eligibleForReapply ? (
        <DropdownContainer>
          <NewDropDown
            id={`drop-${row.applicationId}`}
            options={getDeniedApplicationActions(row)}
            textColor="#0E202F"
            onChange={onContinueHandler}
            defaultValue={row.scheduled}
            showAllItem={true}
            hoveredMode={false}
            customSelector={true}
          />
        </DropdownContainer>
      ) : (
        <Gap />
      )}
      <div />
    </>
  );
};
