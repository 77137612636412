import { UnauthorizedContainer, UnauthorizedText, UnauthorizedWrapper } from '../styles';
import { ReactComponent as LockIcon } from 'assets/images/large-lock-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info-circle.svg';
import { Heading } from '@frontend/cherry-library';

interface Props {
  title: string;
  id?: string;
}

const UnauthorizedSettings = ({ title, id }: Props) => {
  return (
    <UnauthorizedWrapper id={id}>
      <Heading text={title} level="4" />
      <UnauthorizedContainer>
        <LockIcon />
        <UnauthorizedText>
          <InfoIcon width={16} height={16} />
          You don't have permission to view this setting. Please contact your organization's admin for access.
        </UnauthorizedText>
      </UnauthorizedContainer>
    </UnauthorizedWrapper>
  );
};

export default UnauthorizedSettings;
