import { useMutation } from '@apollo/client';
import client from 'lib/graphql/client';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BackIcon, ButtonPrimary, DashCard, Loading } from 'lib/components';
import { PATCH_MEMBERSHIP_PLAN } from 'lib/graphql/mutations';
import { GET_MEMBERSHIP_PLAN_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column, Container } from 'lib/styles';

import { Switch } from '@frontend/cherry-library';
import useSnackbar from 'lib/hooks/useSnackbar';
import { PlanBasics, PlanBenefits, PlanPricing } from '../components';

const PlanDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { organization } = useStore();
  const [patchMembershipPlan, { loading: patchLoading }] = useMutation(PATCH_MEMBERSHIP_PLAN);
  const { isSnackbarVisible } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [planDetail, setPlanDetail] = useState<any>({});
  const [switchValue, setSwitchValue] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getMembershipPlanDetail();
    } else {
      navigate(-1);
    }
  }, []);

  const getMembershipPlanDetail = async () => {
    try {
      setLoading(true);
      const { data: { fetchMembershipsPlanDetail } = {} } = await client.query({
        query: GET_MEMBERSHIP_PLAN_DETAIL,
        variables: {
          input: {
            planId: id,
            organizationId: organization?.id,
          },
        },
      });
      setLoading(false);
      if (fetchMembershipsPlanDetail.id) {
        setSwitchValue(fetchMembershipsPlanDetail.status === 'LIVE' ? true : false);
        setPlanDetail(fetchMembershipsPlanDetail);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate('/membership/plan/builder');
  };

  const handleChange = (key, value) => {
    setPlanDetail((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const switchHandler = (e) => {
    setSwitchValue(e.target.checked);
  };

  const savePlanHandler = async () => {
    const { data } = await patchMembershipPlan({
      variables: {
        input: { ...planDetail, status: switchValue ? 'LIVE' : 'DRAFT' },
      },
    });
    if (data.patchMembershipsPlan.id) {
      // Succcess
      navigate('/membership/plan/builder');
    }
  };

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <Column maxWidth="1160px">
        <Header>
          <ButtonPrimary
            data-testid="backToHome"
            onClick={goBack}
            startIcon={<BackIcon color={'#FFFFFF'} />}
            text={'Back to Home'}
          />
        </Header>
        <DashCard>
          <HeaderContainer>
            <Title>{planDetail?.name}</Title>
            <SwitchContainer>
              <SwitchLabel>Draft</SwitchLabel>
              <Switch id={'1'} name={'switch'} handleClick={switchHandler} isChecked={switchValue} />
              <SwitchLabel>Live</SwitchLabel>
            </SwitchContainer>
          </HeaderContainer>

          {planDetail?.id ? <PlanBasics data={planDetail} handleChange={handleChange} /> : null}
          <PlanPricing isCreate={false} data={planDetail} handleChange={handleChange} />
          <PlanBenefits data={planDetail} handleChange={handleChange} />
          <ButtonContainer>
            <SavePlanContainer>
              <CancelButton onClick={goBack}>Cancel</CancelButton>
              <ButtonPrimary
                data-testid="saveMember"
                onClick={savePlanHandler}
                disabled={patchLoading}
                text={'Save Changes'}
              />
            </SavePlanContainer>
          </ButtonContainer>
        </DashCard>
      </Column>
      {loading && (
        <LoadingContainer>
          <Loading size={25} />
        </LoadingContainer>
      )}
    </Container>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.span`
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin: 0 6px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #0e202f;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const SavePlanContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 10px;
  border: 1px solid #0e202f;
  border-radius: 2px;
  color: #0e202f;
  margin-right: 12px;
  border-radius: 4px;
`;

// const DeletePlanButton = styled(ButtonPrimary)`
//   background-color: white !important;
//   border-color: ${(props) => props.theme.main.red} !important;
//   color: ${(props) => props.theme.main.red} !important;
// `;

const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;

export default PlanDetail;
