import axios from 'axios';
import { APP_STATIC_URL } from 'config';
import React, { useEffect, useState } from 'react';

import { Loading, PageHeader } from 'lib/components';
import useSnackbar from 'lib/hooks/useSnackbar';
import { Container, Row } from 'lib/styles';
import { MessagingTemplates } from './views';

const TextMessages = () => {
  const [staticFile, setStaticFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { isSnackbarVisible } = useSnackbar();

  useEffect(() => {
    const fetchStaticFile = async () => {
      const result = await axios.get(APP_STATIC_URL);
      setStaticFile(result.data);
      setLoading(false);
    };

    fetchStaticFile();
  }, []);

  if (loading) {
    return <Loading fixed={true} size={40} />;
  }

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Marketing" />

      <Row>
        <MessagingTemplates
          title={'Messaging Templates'}
          messagingPdfUrl={staticFile?.MESSAGING}
          emailSnippets={staticFile?.SNIPPETS || []}
        />
      </Row>
    </Container>
  );
};

export default TextMessages;
