import Rox from 'rox-browser';
enum RoxFlagFreezeLevel {
  UntilLaunch = 'untilLaunch',
}

export const Flags = {
  FEATURES: {
    isDownPaymentEnabled: new Rox.Flag(false, { freeze: RoxFlagFreezeLevel.UntilLaunch }),
  },
  SCREEN: {
    preApprovePatientNewApprovalScreen: new Rox.Flag(false, { freeze: RoxFlagFreezeLevel.UntilLaunch }),
  },
  DEMO: {
    sandboxNavigationEnabled: new Rox.Flag(false),
  },
};

// Register the feature flags
(Object.keys(Flags) as (keyof typeof Flags)[])?.forEach((namespace) => {
  Rox.register(namespace, Flags[namespace]);
});
