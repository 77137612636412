import { Dialog, MenuItem, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { Box, Icon, TextField } from 'lib/components/mobile';
import { CherryLinkIcon, HelpSupportIcon, HomeIcon } from 'lib/components/mobile/SidebarIcons';
import { CloseIcon, SettingsIcon } from 'lib/components/SidebarIcons';

import { DocumentRequestsIcon } from 'lib/components/SidebarIcons';
import useStore from 'lib/hooks/useStore';
import { useLogout } from 'lib/services';
import { Merchant } from 'lib/types';

const TransitionFunc = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

interface NavItemProp {
  active?: boolean;
}

interface Route {
  path: string;
  text: string;
  icon: (boolean) => JSX.Element;
  level: string;
}

interface Props {
  status: boolean;
  setSidebarStatus: (boolean) => void;
}

const routeArray: Route[] = [
  {
    path: '/dashboard',
    text: 'Home',
    icon: (props) => <HomeIcon active={props} />,
    level: 'DASHBOARD',
  },
  {
    path: '/share-your-link',
    text: 'Your Cherry Link',
    icon: (props) => <CherryLinkIcon active={props} />,
    level: 'MARKETING',
  },
  {
    path: '/help',
    text: 'Help & Support',
    icon: (props) => <HelpSupportIcon active={props} />,
    level: 'REFERRAL',
  },
  {
    text: 'Document Requests',
    path: '/document-requests',
    level: 'DASHBOARD',
    icon: (props) => <DocumentRequestsIcon {...props} />,
  },
  {
    path: '/settings',
    text: 'Settings',
    icon: (props) => <SettingsIcon active={props} />,
    level: 'SETTINGS',
  },
];

enum MerchantSelectDefaultEnum {
  SELECT_ALL = 'All',
}

export const Sidebar = ({ status, setSidebarStatus }: Props) => {
  const { merchants, setSelectedMerchant, permissions, selectedMerchant } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useLogout();
  const { pathname } = location;

  const handleClose = () => setSidebarStatus(false);

  const navItemClickHandler = (event): void => {
    const url = event.target.getAttribute('data-nav-url');
    if (url) {
      navigate(url);
    }
  };

  const signOutHandler = () => {
    logout();
  };

  const onMerchantSelect = (e) => {
    const value = e?.target?.value;
    if (value === MerchantSelectDefaultEnum.SELECT_ALL) {
      setSelectedMerchant(null);
    } else {
      const findSelectedMerchant = merchants?.find((merchant: Merchant) => merchant.id === value) || null;
      setSelectedMerchant(findSelectedMerchant);
    }
  };
  return (
    <Dialog
      className="menu-dialog"
      PaperProps={{ style: { backgroundColor: theme.main.midnightBlue } }}
      fullScreen={true}
      open={status}
      TransitionComponent={TransitionFunc}
      keepMounted={true}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      data-testid="hamburgerMenu"
    >
      <IconContainer data-testid="hamburgerCloseButton" onClick={handleClose}>
        <Icon src={'cherry-logo-white'} width={116} height={32} />
        <StyledIconButton color={theme.main.white} />
      </IconContainer>
      <BodyContainer>
        <MenuContainer>
          <MerchantsBox>
            <MerchantSelect
              style={{ textAlign: 'left' }}
              id="filled-basic"
              variant="filled"
              select={true}
              onChange={onMerchantSelect}
              label="Select an location"
              defaultValue={selectedMerchant?.id || MerchantSelectDefaultEnum.SELECT_ALL}
            >
              <MenuItem
                data-testid="industryItem"
                key={'select-all'}
                value={MerchantSelectDefaultEnum.SELECT_ALL}
                style={{ width: '100%', textAlign: 'left' }}
              >
                All Stores
              </MenuItem>
              {merchants?.map((option: Merchant, index: number) => (
                <MenuItem
                  data-testid="industryItem"
                  key={index}
                  value={option?.id}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </MerchantSelect>
          </MerchantsBox>
          <LinkContainer>
            {routeArray.map((navItem, index) => {
              if (Object.keys(permissions).includes(navItem.level)) {
                return (
                  <NavItem
                    key={`${navItem}-${index}`}
                    active={pathname === navItem.path}
                    data-nav-url={navItem.path}
                    onClick={navItemClickHandler}
                  >
                    {navItem.icon(pathname === navItem.path)}
                    <NavText>{navItem.text}</NavText>
                  </NavItem>
                );
              } else {
                return null;
              }
            })}
            <SignOut data-testid="logout" onClick={signOutHandler}>
              <Icon width={16} height={16} src={'log_out'} />
              <SignOutText>Sign Out</SignOutText>
            </SignOut>
          </LinkContainer>
        </MenuContainer>
      </BodyContainer>
    </Dialog>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
  align-items: center;
`;

const StyledIconButton = styled(CloseIcon)`
  cursor: pointer;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px 0px 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuContainer = styled.div`
  text-align: center;

  form {
    background-color: transparent !important;
  }
`;

const LinkContainer = styled.div`
  margin: 25px -24px 40px -24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const SignOut = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  text-decoration: none;
  color: ${(props) => props.theme.main.white};
  padding: 18px 24px;

  &:hover {
    text-decoration: underline;
  }
`;

const SignOutText = styled.div`
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  margin-left: 16px;
`;

const NavItem = styled.div<NavItemProp>`
  display: flex;
  justify-content: flex-start;
  height: 64px;
  padding: 18px 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${(props) => (props.active ? '#263644' : 'transparent')};
  span {
    color: ${(props) => (props.active ? props.theme.main.green : props.theme.main.white)};
  }
`;

const NavText = styled.span<NavItemProp>`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-left: 16px;
  pointer-events: none;
`;

const MerchantsBox = styled(Box)`
  background-color: transparent !important;
  box-sizing: border-box !important;
  padding: 0 24px !important;
`;

const MerchantSelect = styled(TextField)`
  margin: 0 !important;

  .MuiFilledInput-root {
    background-color: transparent !important;
    border-color: ${(props) => props.theme.main.borderColor};

    .MuiSelect-select {
      color: ${(props) => props.theme.main.white} !important;
      padding-top: 8px !important;
      font-family: 'Open Sans', sans-serif;
      margin-top: 16px;
    }

    svg {
      fill: ${(props) => props.theme.main.green} !important;
    }
  }
`;
