const MIN_ALLOWED_PURCHASE_AMOUNT = 200;
const MAX_ALLOWED_PURCHASE_AMOUNT = 5000;

const rawValue = (text: string) => {
  return text?.replace(/\$|,/g, '');
};

const valueValidator = (val: any, min: number, max: number) => {
  return val >= min && val <= max ? true : false;
};

const errorMessagesForDentist = (term: number, amount: number) => {
  if (term === 36 && amount < 3000) {
    return 'A minimum purchase amount of $3,000 is required for a 36 month plan.';
  } else if (term === 48 && amount < 4000) {
    return 'A minimum purchase amount of $4,000 is required for a 48 month plan.';
  } else if (term === 60 && amount < 5000) {
    return 'A minimum purchase amount of $5,000 is required for a 60 month plan.';
  } else {
    return null;
  }
};

const toCurrencyString = (num?: number, remainder?: boolean) => {
  if (num !== null || num !== undefined) {
    const fixed = num! % 1 !== 0 || remainder ? 2 : 0;
    return (
      // @ts-ignore
      (num < 0 ? '-$' : '$') +
        Math.abs(num ? num : 0)
          ?.toFixed(fixed)
          ?.toString()
          ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || ''
    );
  } else {
    return null;
  }
};

export const FormatAmount = (amount: number) => {
  if (amount && typeof amount === 'number') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amount);
  }
  return '$0.00';
};

const CurrencyUtil = {
  FormatAmount,
  rawValue,
  toCurrencyString,
  valueValidator,
  errorMessagesForDentist,
  MAX_ALLOWED_PURCHASE_AMOUNT,
  MIN_ALLOWED_PURCHASE_AMOUNT,
};

export default CurrencyUtil;
