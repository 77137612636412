import { Dialog as MuiDialog, DialogActions as MuiDialogActions, DialogTitle as MuiDialogTitle } from '@mui/material';
import { ButtonPrimary } from 'lib/components';
import React from 'react';
import styled from 'styled-components';

interface OrderConfirmedModalProps {
  visible: boolean;
  onClose: () => void;
}

const OrderConfirmedModal = ({ visible, onClose }: OrderConfirmedModalProps) => {
  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Order Confirmed</DialogTitle>
      <Content>
        <div>Your order was placed successfully!</div>
        <div>We've emailed you a confirmation, and you can view this order in your order history.</div>
      </Content>
      <DialogActions>
        <CloseButton onClick={onClose} text="Okay" />
      </DialogActions>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 480px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  flex: 1;
  text-align: center;
  font-size: 14px;
  margin: 24px 0;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const DialogActions = styled(MuiDialogActions)`
  & > button {
    flex: 1;
  }
`;

const CloseButton = styled(ButtonPrimary)`
  border-radius: 4px !important;
`;

export default OrderConfirmedModal;
