import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import images from 'assets/images';
import { DashCard } from 'lib/components';
import { TrainingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import { Row } from 'lib/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProductVideoModal } from './index';

export const ProductVideos = ({ videoList, user, organization, merchants }) => {
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, applicationName } = useSegment();
  const [open, setOpen] = useState<boolean>(false);
  const [activeVimeoId, setActiveVimeoId] = useState<string>('');

  const handleVideoClicked = (e) => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    setOpen(true);
    const findVideo = videoList.find((node) => node.id.toString() === e.target.id);
    const videoId = findVideo?.onClickResponse.toString() || '';

    setActiveVimeoId(videoId);
    trackSegmentEvent('Viewed Training Video', {
      application: applicationName,
      organizationId: organization.id,
      merchantId: idMerchant,
      videoName: findVideo?.title,
    });
    trackEvent({
      action: TrainingAnalyticsEventNames.VIDEO_CLICK,
      label: activeVimeoId,
    });
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <DashCard transparent={'transparent'}>
        <Row>
          <VideoContainer>
            {videoList.map((item) => {
              return (
                <CherryCard key={item.id} onClick={handleVideoClicked} id={item.id.toString()} data-testid="video-item">
                  <CardActionArea id={item.id.toString()}>
                    <AbsoluteDiv id={item.id.toString()}>
                      <CustomPlayIcon id={item.id.toString()} src={images.playButton} />
                    </AbsoluteDiv>
                    <CardMedia
                      style={{ filter: 'brightness(80%)' }}
                      component="img"
                      height="160"
                      id={item.id.toString()}
                      image={item?.helperimage.toString()}
                      alt={item.title}
                    />
                    <CardContent style={{ minHeight: '170px' }} id={item.id.toString()}>
                      <CherryCardHeader onClick={handleVideoClicked} id={item.id.toString()}>
                        {item.title}
                      </CherryCardHeader>
                      <CherryCardDescription id={item.id.toString()}>{item.description} </CherryCardDescription>
                    </CardContent>
                  </CardActionArea>
                </CherryCard>
              );
            })}
          </VideoContainer>
        </Row>
      </DashCard>
      <ProductVideoModal fullModal={true} closeModal={closeModal} vimeoId={activeVimeoId} show={open} />
    </>
  );
};

const CherryCard = styled(Card)`
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035) !important;
  border-radius: 4px !important;
  // width: 32.5%;
  margin: 5px;

  &:hover {
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013) !important;
  }
`;

const AbsoluteDiv = styled.div<{ id?: string }>`
  position: absolute !important;
  z-index: 1;
  margin-top: 25% !important;
  margin-left: 45% !important;
`;

const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 5px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`;

const CherryCardHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans';
`;

const CherryCardDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px 0;
  font-family: 'Open Sans';
`;

const CustomPlayIcon = styled.img`
  width: 33px;
  height: 33px;
`;
