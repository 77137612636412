import { useApolloClient } from '@apollo/client';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { GET_MERCHANT_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';

interface MerchantState {
  code: string;
  name: string;
}

interface MerchantAddress {
  street: string;
  zip: string;
  city: string;
  state: MerchantState;
}

interface MerchantBankingInfo {
  accountName: string;
  accountNumber: string;
  accountNickname: string | null;
  routingNumber: string;
  accountType: string;
  accountClassification: string;
  accountStatus: string | null;
  accountId: string;
}

interface UserManagementType {
  phone: string | null;
}

interface MerchantWithPoc {
  name: string;
  code: string;
  address: MerchantAddress;
  id: string;
  bankingInfo: MerchantBankingInfo;
  phone: string;
  poc: UserManagementType;
}

const useMerchantDetail = () => {
  const client = useApolloClient();
  const alert = useAlert();

  const { merchants } = useStore();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<MerchantWithPoc[]>([]);
  const [error, setError] = useState(null);
  const [locationExists, setLocationExists] = useState(true);

  const fetchMerchantDetail = useCallback(async () => {
    if (!merchants || merchants.length === 0) return;

    try {
      setLoading(true);
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds: merchants.map((merchant) => merchant?.id),
          },
        },
      });

      if (getMerchantDetail?.data?.length) {
        setLocationExists(true);
        setLocations([...getMerchantDetail.data]);
      } else {
        setLocationExists(false);
        setLocations([]);
      }
    } catch (error) {
      setLocationExists(false);
      setError(error as SetStateAction<null>);
      alert.error('Merchant detail fetch error');
    } finally {
      setLoading(false);
    }
  }, [merchants]);

  useEffect(() => {
    fetchMerchantDetail?.();
  }, []);

  return { loading, locations, error, locationExists };
};

export default useMerchantDetail;
