import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { Button, Dropdown, Icon as CherryIcon, TextInput } from '@frontend/cherry-library';
import { Box, SelectChangeEvent } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { CherryRangePicker, InputIcon, InputWithIcon } from 'lib/components';
import { GeneralAnalyticsEventNames, TransactionsAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { DropdownOption, Merchant } from 'lib/types';
import { BorderContainer, DropDownContainer, StyledPickerWrapper, TableRow } from '../CommonStyles';
import { CsvModal } from './CsvModal';

interface SelectedDateTransactions {
  from: string;
  to: string;
}

interface Props {
  total: number;
  sortColumn: string;
  sortDirection: string;
  searchInput: string;
  disbursementTypes: string[];
  selectedDateTransactions: SelectedDateTransactions;
  checkedItems: any;
  searchInputOnChange: (value: any) => void;
  searchInputBlur: () => void;
  onDisbursementTypeSelected: (data: any) => void;
  onDateChangeTransactions: (date: any, value: any) => void;
}

const loanStatusData = [
  { label: 'Initiated', value: 'INITIATED' },
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Voided', value: 'VOIDED' },
  { label: 'Refunded', value: 'REFUNDED' },
];
const defaultDisbursmentTypes = ['AWAITING_FUNDING', 'FUNDED', 'REFUNDED'];

export const TableSearchHeader = ({
  total,
  sortColumn,
  sortDirection,
  searchInput,
  disbursementTypes,
  selectedDateTransactions,
  checkedItems,
  searchInputOnChange,
  searchInputBlur,
  onDisbursementTypeSelected,
  onDateChangeTransactions,
}: Props) => {
  const permission = usePermission();
  const alert = useAlert();
  const { trackEvent } = useAnalytics();
  const { merchants, setSelectedMerchantForTransactions } = useStore();

  const [csvModal, setCsvModal] = useState(false);
  const [showRangePickerTransactions, setShowRangePickerTransactions] = useState(false);
  const [filteredStringRangeTransactions, setFilteredStringRangeTransactions] = useState<string>('All Time');
  const [location, setLocation] = useState<DropdownOption[]>([]);
  const [selectedMerchantLocation, setSelectedMerchantLocation] = useState<string>('all');

  useEffect(() => {
    if (merchants?.length) {
      const formattedLocations: DropdownOption[] = merchants.map((merchant: Merchant) => {
        return { label: merchant.name, value: merchant.id?.toString() };
      });
      setLocation([{ label: 'All Stores', value: 'all' }, ...formattedLocations]);
    }
  }, [merchants]);

  const handleClose = () => {
    setCsvModal(false);
  };

  const openCsvModal = async () => {
    if (total === 0) {
      alert.error('No records found. Please change your time frame');
      return;
    } else {
      setCsvModal(true);
    }
  };

  const onDateClick = () => {
    trackEvent({
      action: TransactionsAnalyticsEventNames.DATE_DROP_CLICK,
    });
  };

  const toggleRangePickerTransactions = () => {
    setShowRangePickerTransactions(!showRangePickerTransactions);
  };

  const onTextStringUpdatedTransactions = (inputDateRangeText: string) => {
    setFilteredStringRangeTransactions(inputDateRangeText);
  };

  const selectLocationHandler = (event: SelectChangeEvent<unknown>) => {
    const selectedMerchant = event?.target?.value as string;
    trackEvent({ action: GeneralAnalyticsEventNames.STORE_TOGGLE });
    const foundMerchant = merchants?.find((merchant: Merchant) => merchant.id === selectedMerchant);
    if (selectedMerchant && foundMerchant) {
      setSelectedMerchantForTransactions(foundMerchant);
      setSelectedMerchantLocation(foundMerchant?.id);
    } else {
      setSelectedMerchantForTransactions(null);
    }
  };

  const handleDisbursementTypeSelected = (event: SelectChangeEvent<unknown>) => {
    const selections = (event?.target?.value as string[]) || [];
    onDisbursementTypeSelected(selections);
  };

  return (
    <TableRow>
      <BorderContainer>
        <TextInput
          onChange={searchInputOnChange}
          onBlur={searchInputBlur}
          value={searchInput}
          label="Search by Customer Name, Phone or Contract ID"
          leftElement={<CherryIcon src={SearchIcon} />}
        />
      </BorderContainer>
      {location?.length > 2 ? (
        <DropDownContainer>
          <Dropdown
            options={location}
            onChange={selectLocationHandler}
            defaultValue="all"
            value={selectedMerchantLocation}
          />
        </DropDownContainer>
      ) : null}

      <StyledPickerWrapper onClick={onDateClick}>
        <InputWithIcon
          onClick={toggleRangePickerTransactions}
          value={filteredStringRangeTransactions}
          caret={'transparent'}
          margin={'-4px 20px 0px 0px'}
          textAlign={'left'}
          cursor={'pointer'}
          style={{ fontSize: '16px', paddingLeft: '14px' }}
        />
        <InputIcon
          onClick={toggleRangePickerTransactions}
          icon={faChevronDown}
          right={'14px'}
          margin={'-4px 0 0 0 '}
          color={'green'}
          cursor={'pointer'}
          width={'10.7px !important'}
        />
        <CherryRangePicker
          show={showRangePickerTransactions}
          onRangeSelected={onDateChangeTransactions}
          updateTextString={onTextStringUpdatedTransactions}
          toggleRangePicker={toggleRangePickerTransactions}
          marginTop="435px"
        />
      </StyledPickerWrapper>

      <DropDownContainer>
        <Dropdown
          multiple={true}
          options={loanStatusData}
          label="Status"
          onChange={handleDisbursementTypeSelected}
          value={checkedItems?.map((item) => item?.value)}
        />
      </DropDownContainer>

      {permission(FeatureNames.DOWNLOAD_CSV, ScreenNames.TRANSACTIONS) && (
        <>
          <Box m="0 0 0 8px">
            <Button data-tag="allowRowEvents" onClick={openCsvModal}>
              Download CSV
            </Button>
          </Box>
          <CsvModal
            open={csvModal}
            selectedDate={selectedDateTransactions}
            handleClose={handleClose}
            totalTransactionCount={total}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            searchInput={searchInput}
            rawStatuses={disbursementTypes}
            defaultDisbursmentTypes={defaultDisbursmentTypes}
          />
        </>
      )}
    </TableRow>
  );
};
