import { gql } from '@apollo/client/core';

export const PATCH_SHOP_PRODUCT = gql`
  mutation PatchShopProduct($input: PatchShopProductInput) {
    patchShopProduct(input: $input) {
      success
    }
  }
`;

export const CREATE_SHOP_PRODUCT = gql`
  mutation CreateShopProduct($input: CreateShopProductInput) {
    createShopProduct(input: $input) {
      success
    }
  }
`;

export const DELETE_SHOP_PRODUCT = gql`
  mutation DeleteShopProduct($input: DeleteShopProductInput) {
    deleteShopProduct(input: $input) {
      success
    }
  }
`;

export const UPLOAD_SHOP_FILE = gql`
  mutation UploadShopFileProduct($input: UploadShopFileInput) {
    uploadShopFile(input: $input) {
      success
      data {
        fileKey
      }
    }
  }
`;
