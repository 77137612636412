import { Dialog } from '@mui/material';
import images from 'assets/images';
import { confettiColors } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { PreApprovalStepEnums } from 'lib/types';
import { CherryDialogTitle, DialogContainer } from 'pages/desktop/Settings/components/Dialog';
import React, { useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { PreApprovalApplicationAlreadyExist } from './PreApprovalApplicationAlreadyExist';
import { PreApprovalDenial } from './PreApprovalDenial';
import { PreApprovalInformation } from './PreApprovalInformation';
import { PreApprovalLoading } from './PreApprovalLoading';
import { PreApprovalSuccess } from './PreApprovalSuccess';
import { PreApprovalResult } from './type';

interface Props {
  open: boolean;
  onClose: () => void;
  messages?: any;
}

export const PreApprovalModal = ({ onClose, open }: Props) => {
  const { trackSegmentEvent, applicationName } = useSegment();

  const { setPatientInfo, setSsnErrorMessage, patientInfo } = useStore();
  const [activeStep, setActiveStep] = useState<PreApprovalStepEnums>(PreApprovalStepEnums.INFORMATION);
  const [confetti, setConfetti] = useState<boolean>(false);
  const [preApprovalResult, setPreApprovalResult] = useState<PreApprovalResult>();
  const [phone, setPhone] = useState<string | null>(null);
  const [openSSNModal, setOpenSSNModal] = useState<boolean>(false);

  const syncButtonCR = document.getElementById('sync-button-checkout-ready');
  const syncButtonAOP = document.getElementById('sync-button-all-other-patients');

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      if (syncButtonCR !== null) {
        syncButtonCR.click();
      }

      if (syncButtonAOP !== null) {
        syncButtonAOP.click();
      }

      setPatientInfo(null);
      setSsnErrorMessage(null);
      setActiveStep(PreApprovalStepEnums.INFORMATION);
      setOpenSSNModal(false);
    }, 500);

    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_CANCEL_BUTTON_CLICKED', {
      application: applicationName,
    });
  };

  const renderConditionalStep = (step: PreApprovalStepEnums) => {
    switch (step) {
      case PreApprovalStepEnums.INFORMATION:
        return (
          <PreApprovalInformation
            setOpenSSNModal={setOpenSSNModal}
            openSSNModal={openSSNModal}
            onClose={onModalClose}
            setActiveStep={setActiveStep}
            setPreApprovalResult={setPreApprovalResult}
            setPhone={setPhone}
            patientInfo={patientInfo ?? null}
          />
        );
      case PreApprovalStepEnums.LOADING:
        trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.LOADING_SCREEN_VIEWED', {
          application: applicationName,
        });
        return <PreApprovalLoading />;
      case PreApprovalStepEnums.SUCCESS:
        return (
          <PreApprovalSuccess
            onClose={onModalClose}
            preApprovalResult={preApprovalResult}
            setActiveStep={setActiveStep}
            setConfetti={setConfetti}
          />
        );
      case PreApprovalStepEnums.DENIAL:
        return (
          <PreApprovalDenial
            onClose={onModalClose}
            preApprovalResult={preApprovalResult}
            setActiveStep={setActiveStep}
            setConfetti={setConfetti}
          />
        );
      case PreApprovalStepEnums.APPLICATION_ALREADY_EXIST:
        return (
          <PreApprovalApplicationAlreadyExist onClose={onModalClose} setActiveStep={setActiveStep} phone={phone!} />
        );
      case PreApprovalStepEnums.SENT:
        return (
          <Container>
            <ApplicationSent src={images?.applicationSent} />
            <SectionTitle style={{ marginBottom: '16px' }}>Application Sent!</SectionTitle>
            {confetti ? (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={300}
                colors={confettiColors}
                recycle={false}
              />
            ) : null}
          </Container>
        );
    }
  };

  const params = {
    ...(!openSSNModal && { onClose: onModalClose }),
  };

  return (
    <StyledDialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={onClose} {...params}>
          Pre-Approve Patient
        </CherryDialogTitle>
        {renderConditionalStep(activeStep)}
      </DialogContainer>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: auto;
  }
`;

const SectionTitle = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: ${(props) => props.theme.main.textColor};
`;

const ApplicationSent = styled.img`
  width: 120px;
  height: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
`;
