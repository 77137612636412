import { useApolloClient, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import dayjs from 'dayjs';
import {
  PATCH_MERCHANT_PREFERENCE,
  POST_APPLICATION,
  POST_APPLICATION_FLOW,
  SEND_MAGIC_LINK,
  UPDATE_BORROWER,
} from 'lib/graphql/mutations/WPQApplicationOperations';
import { GET_APPLICATION, GET_APPLICATION_FLOW } from 'lib/graphql/queries/WPQApplicationOperations';
import { FeatureNames } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { ApplicationFlowStatuses, PostApplyWithPatientConsentResponse } from './types';

export const APPLICATION_ALREADY_EXIST = 'application.already_exist';

export const poBoxRegex = new RegExp(/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box\d*)\b/i);
export const prohibitedStreetRegex = /general\s*delivery|general-?delivery/i;

interface Props {
  applicationFormData: PostApplyWithPatientConsentResponse;
}

export const useCreateApplication = ({ applicationFormData }: Props) => {
  const client = useApolloClient();
  const alert = useAlert();
  const {
    organization,
    setApprovedApplication,
    submittedApplyWithPatientData,
    features,
    selectedMerchantId,
  } = useStore();

  const [currentApplication, setCurrentApplication] = useState({ id: '', borrowerId: '' });
  const [currentApplicationFlow, setCurrentApplicationFlow] = useState({ borrowerId: '', applicationId: '' });
  const [currentBorrower, setCurrentBorrower] = useState({ id: '' });
  const [createdApplicationStatus, setCreatedApplicationStatus] = useState({
    status: '',
    borrowerId: '',
    applicationId: '',
  });
  const [createApplicationError, setCreateApplicationError] = useState(false);
  const [ITINSupportedError, setITINSupportedError] = useState(false);
  const SSN_INVALID_ERROR = 'ssn invalid';

  const getErrorMessage = (error) => {
    let errorMessage = 'An error occurred';

    if (error) {
      if (error === SSN_INVALID_ERROR) {
        errorMessage = 'Invalid SSN, please check and try again.';
      } else {
        errorMessage = error;
      }
    }

    return errorMessage;
  };
  const resetError = () => {
    setCreateApplicationError(false);
  };

  const onError = (errorMessage?: string) => {
    setCreateApplicationError(true);
    alert.info(getErrorMessage(errorMessage));
  };

  const currentApplicationId = currentApplication?.id || currentApplicationFlow?.applicationId;
  const currentBorrowerId = currentApplication?.borrowerId || currentApplicationFlow?.borrowerId || currentBorrower?.id;

  const [postApplication] = useMutation(POST_APPLICATION);
  const [postApplicationFlow] = useMutation(POST_APPLICATION_FLOW);
  const [updateBorrower] = useMutation(UPDATE_BORROWER);
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const [patchLocationPreference] = useMutation(PATCH_MERCHANT_PREFERENCE);
  const isLast4ssnEnabled =
    organization?.bankPartner === 'LEAD' || features?.includes(FeatureNames.RETAIL_INSTALLMENT_CONTRACT);

  const clearState = () => {
    setCurrentApplication({ id: '', borrowerId: '' });
    setCurrentApplicationFlow({ borrowerId: '', applicationId: '' });
    setCurrentBorrower({ id: '' });
    setCreatedApplicationStatus({ status: '', borrowerId: '', applicationId: '' });
    setCreateApplicationError(false);
  };

  const handleHighlineFlow = async (applicationId) => {
    const { housingPayment, statedIncome } = applicationFormData || {};

    const highlineParams = {
      statedInfos: [
        {
          type: 'STATED_INCOME',
          value: statedIncome,
        },
        {
          type: 'HOUSING_PAYMENT',
          value: housingPayment,
        },
      ],
    };

    try {
      const { data } = await postApplicationFlow({
        variables: {
          input: {
            applicationId,
            ...highlineParams,
          },
        },
      });

      if (data?.postApplicationFlow?.success) {
        getUpdatedApplicationStatus({ id: applicationId });
      } else {
        onError(data?.postApplicationFlow?.message);
      }

      return data?.postApplicationFlow;
    } catch (err) {
      onError();
      captureException('Apply with Patient Highline Error');
    }
  };
  const createApplication = async (borrowerId) => {
    try {
      const channel = 'WPQ';
      const params = {
        borrowerId: borrowerId || currentBorrowerId,
        organizationId: organization?.id,
        channel,
        referralSource: 'practice',
        referralMedium: 'apply_with_patient',
      };

      const { data } = await postApplication({
        variables: {
          input: params,
        },
      });

      if (data?.postApplication?.id) {
        setCurrentApplication(data?.postApplication);
        await getUpdatedStatus(data?.postApplication);
      } else {
        onError(data?.postApplication?.message);
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.error?.message === APPLICATION_ALREADY_EXIST) {
        await getUpdatedStatus(currentApplication);
      } else {
        captureException('Apply with Patient Create Application Error');
      }
      onError();
    }
  };

  const createApplicationFlow = async (applicationId) => {
    const { requestedAmount, statedIncome, housingPayment, email } = applicationFormData || {};

    const statedInfos: {
      type?: string | undefined;
      value?: string | undefined;
    }[] = [
      {
        ...(requestedAmount && {
          type: 'REQUESTED_AMOUNT',
          value: requestedAmount,
        }),
      },
      {
        ...(statedIncome && {
          type: 'STATED_INCOME',
          value: statedIncome,
        }),
      },
      {
        ...(housingPayment && {
          type: 'HOUSING_PAYMENT',
          value: housingPayment,
        }),
      },
      {
        ...(email && {
          type: 'EMAIL',
          value: email,
        }),
      },
    ];

    try {
      const { data } = await postApplicationFlow({
        variables: {
          input: {
            applicationId,
            statedInfos: statedInfos?.filter((info) => info.type && info.value),
          },
        },
      });

      if (data?.postApplicationFlow?.success) {
        setCurrentApplicationFlow(data?.postApplicationFlow);
      } else {
        onError(data?.postApplicationFlow?.message);
      }

      return data?.postApplicationFlow;
    } catch (err) {
      onError();
      captureException('Apply with Patient Create Application Flow Error');
    }
  };

  const getApplication = async (applicationId?: string) => {
    try {
      if (applicationId) {
        const { data } = await client.query({
          query: GET_APPLICATION,
          variables: {
            input: {
              applicationId,
            },
          },
        });
        if (data?.getApplication?.id) {
          setCurrentApplication(data?.getApplication);
          if (data?.getApplication?.status === 'APPROVED') {
            setApprovedApplication(data?.getApplication);
          }
        } else {
          onError(data?.getApplication?.message);
        }

        return data?.getApplication;
      }
    } catch (err) {
      onError();
      captureException('Apply with Patient Get Application Error');
    }
  };

  const getApplicationFlow = async (applicationId: string) => {
    try {
      if (applicationId) {
        const { data } = await client.query({
          query: GET_APPLICATION_FLOW,
          variables: {
            input: {
              applicationId,
            },
          },
        });

        if (data.getApplicationFlow?.id) {
          setCurrentApplicationFlow(data?.postApplicationFlow);
        } else {
          onError(data?.getApplication?.message);
        }

        return data?.getApplicationFlow;
      }
    } catch (err) {
      onError();
      captureException('Apply with Patient Get Application Flow Error');
    }
  };

  const updateLocationPreference = async (applicationId) => {
    await patchLocationPreference({
      variables: { input: { applicationId, merchantId: selectedMerchantId } },
    });
  };

  const getUpdatedApplicationStatus = async (application?: any, customTimeout = 2000) => {
    const resp = await getApplication(application?.id || currentApplicationId);

    if (resp) {
      checkEligibilityStatus(customTimeout, resp);
    }
  };

  const getUpdatedStatus = async (application) => {
    return checkEligibilityStatus(2000, application);
  };

  const patchPersonalInfos = async (borrowerId, applicationId) => {
    try {
      const { address, dob, firstName, lastName, last4ssn, email } = applicationFormData || {};
      const request = {
        street: address?.streetAddress,
        city: address?.city,
        dob: dayjs(dob).format('MM/DD/YYYY'),
        email,
        firstName,
        lastName,
        state: address?.state,
        zip: address?.zip,
        ssn: isLast4ssnEnabled ? last4ssn : submittedApplyWithPatientData?.last4ssn,
      };
      const updateInfosResponse = await updateApplicationPersonalInfos(request, borrowerId);

      if (updateInfosResponse?.success) {
        setCurrentBorrower(updateInfosResponse?.borrower);
        updateLocationPreference(applicationId);
        const res = await createApplicationFlow(applicationId);

        if (res?.success) {
          getUpdatedApplicationStatus({ id: applicationId });
        }
      } else {
        onError(updateInfosResponse?.message);
      }
    } catch (err) {
      onError();
      alert.error('An error occurred while creating application, please try again later');
      captureException('Application Create Error');
    }
  };

  const checkEligibilityStatus = async (customTimeout = 2000, applicationData) => {
    const application = applicationData || currentApplication;
    const respStatus = await getApplicationFlow(application?.id || currentApplicationId);
    if (respStatus?.status) {
      switch (respStatus?.status) {
        case ApplicationFlowStatuses.MISSING_INFO:
          setTimeout(() => {
            patchPersonalInfos(respStatus?.borrowerId, respStatus?.applicationId);
          }, 4000);
          break;
        case ApplicationFlowStatuses.PROCESSING:
          setTimeout(() => {
            getUpdatedApplicationStatus(applicationData);
          }, 4000);
          break;
        case ApplicationFlowStatuses.AWAITING_DEFAULT_HIGH_LINE:
          setTimeout(() => {
            handleHighlineFlow(respStatus?.applicationId);
          }, 4000);
          break;
        case ApplicationFlowStatuses.MISSING_REQUESTED_AMOUNT:
          setTimeout(() => {
            createApplicationWithDHL(respStatus?.applicationId);
          }, 4000);
          break;
        case ApplicationFlowStatuses.AWAITING_APPROVAL_BOOST:
        case ApplicationFlowStatuses.ADDRESS_VERIFY_MINOR:
        case ApplicationFlowStatuses.ADDRESS_VERIFY_MAJOR:
        case ApplicationFlowStatuses.SSN_REQUIRED_1:
        case ApplicationFlowStatuses.SSN_REQUIRED_2:
        case ApplicationFlowStatuses.TREATMENT_QUESTIONS:
        case ApplicationFlowStatuses.AWAITING_ADDITIONAL_INFO:
        case ApplicationFlowStatuses.MISSING_EMAIL:
        case ApplicationFlowStatuses.SSN_CONFLICT:
        case ApplicationFlowStatuses.ID_CONFLICT:
        case ApplicationFlowStatuses.SSN_VERIFICATION:
        case ApplicationFlowStatuses.FROZEN:
        case ApplicationFlowStatuses.ID_REVIEW:
        case ApplicationFlowStatuses.EID_REVIEW:
        case ApplicationFlowStatuses.REVIEW:
        case ApplicationFlowStatuses.APPROVED:
        case ApplicationFlowStatuses.COMPLETE_DEMO:
        case ApplicationFlowStatuses.DENIED:
        case ApplicationFlowStatuses.BLACKLIST:
        case ApplicationFlowStatuses.INCOME_VERIFICATION:
          setTimeout(() => {
            finishCallback(respStatus);
          }, 4000);
          break;
        case ApplicationFlowStatuses.ITIN_NOT_SUPPORTED:
          setTimeout(() => {
            if (ITINSupportedError) {
              patchPersonalInfos(respStatus?.borrowerId, respStatus?.applicationId);
              setITINSupportedError(false);
            } else {
              onError(
                'We are working on launching ITIN support in your area soon. Stay tuned!An error occurred while creating application, please try again later',
              );
              setITINSupportedError(true);
            }
          }, 2000);
          break;
        default:
          setTimeout(() => {
            getUpdatedApplicationStatus(applicationData);
          }, customTimeout);
          break;
      }
    } else {
      setTimeout(() => {
        getUpdatedApplicationStatus(applicationData);
      }, customTimeout);
    }
  };

  const updateApplicationPersonalInfos = async (formData, borrowerId) => {
    if (poBoxRegex.test(formData?.street)) {
      alert.info('Your address cannot be a postal box, please enter a street address and try again.');
    } else if (prohibitedStreetRegex.test(formData?.street)) {
      alert.info('Your address cannot be a general delivery, please enter a street address and try again.');
    } else {
      try {
        if (borrowerId) {
          const { data } = await updateBorrower({
            variables: {
              input: {
                id: borrowerId,
                address: {
                  city: formData?.city,
                  zip: formData?.zip,
                  stateCode: formData?.state,
                  street: formData?.street,
                },
                dob: formData?.dob,
                email: formData?.email,
                firstName: formData?.firstName,
                lastName: formData?.lastName,
                source: 'PARTNER_API',
                ssn: !isLast4ssnEnabled ? formData?.ssn : null,
                last4: isLast4ssnEnabled ? formData?.ssn : null,
              },
            },
          });
          if (data?.updateBorrower?.success) {
            return data?.updateBorrower;
          } else {
            if (data?.updateBorrower?.message === 'borrower.kyc_status.invalid') {
              await getUpdatedStatus(currentApplication);
            }
            onError('An error occurred while updating the information');
          }
        } else {
          onError();
        }
      } catch (err) {
        onError();
        captureException('Apply with Patient Patch Personal Infos Error');
      }
    }
  };

  const finishCallback = (data?) => {
    getApplication(data?.applicationId);
    data && setCreatedApplicationStatus(data);
  };

  const createApplicationWithDHL = async (applicationId) => {
    const res = await createApplicationFlow(applicationId);

    if (res?.success) {
      await getUpdatedApplicationStatus({ id: applicationId });
    }
  };

  const triggerMagicLinkSMS = async (borrowerId, applicationId) => {
    try {
      const { data } = await sendMagicLink({
        variables: {
          input: {
            borrowerId,
            applicationId,
            organizationId: Number(organization?.id),
          },
        },
      });

      if (data?.sendMagicLink?.success) {
        setCurrentApplicationFlow(data?.postApplicationFlow);
      } else {
        onError(data?.sendMagicLink?.message);
      }

      return data?.sendMagicLink;
    } catch (err) {
      onError();
      captureException('Apply with Patient Create Application Flow Error');
    }
  };

  return {
    createApplication,
    handleHighlineFlow,
    triggerMagicLinkSMS,
    resetError,
    clearState,
    createdApplicationStatus,
    createApplicationError,
  };
};
