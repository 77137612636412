import React from 'react';

import styled from 'styled-components';

interface Props {
  children: any;
}

export const AuthLayout = ({ children }: Props) => {
  const newDate = new Date();

  return (
    <BackgroundWrapper>
      {children}
      <Footer>
        <Info>© {newDate.getFullYear()} Cherry LLC.</Info>
        <Info>Have a question? (888) 839-7171</Info>
      </Footer>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.main?.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const Info = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  margin: 5px auto;
`;
