import React from 'react';

import { ModalV2 } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  open: boolean;
  handleClose: () => void;
  segmentEventData: {
    loanId: number;
    borrowerId: number;
  };
}

const KeepWaitingModal = ({ open, handleClose, segmentEventData }: Props) => {
  const navigate = useNavigate();
  const { trackSegmentEvent } = useSegment();

  const handleGoHome = () => {
    trackSegmentEvent(
      'PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.PATIENT_STILL_REVIEWING_MODAL_GO_HOME_CLICKED',
      segmentEventData,
    );
    navigate('/dashboard', { state: { force: true } });
    handleClose();
  };

  return (
    <ModalV2
      open={open}
      onSubmit={handleGoHome}
      onCancel={handleClose}
      title="Patient still reviewing?"
      submitButtonLabel="Go Home"
      cancelButtonlabel="Keep Waiting"
      width={480}
      showClose={true}
    >
      <TextContainer>
        <Text>If the patient needs more time to review, you can go back home</Text>
        <Text>and we’ll send a notification letting you know if they finish.</Text>
        <Gap />
        <Text>
          <b>Note:</b> The purchase has not been completed yet.
        </Text>
      </TextContainer>
    </ModalV2>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`;

const Gap = styled.div`
  margin-top: 16px;
`;

export default KeepWaitingModal;
