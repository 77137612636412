import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FixedDecimalFormat } from 'lib/components';
import {
  AmountField,
  Button,
  ButtonContainer,
  Container,
  ContentContainer,
  ExpandableInfoText,
  ExpandableWithTitle,
  Subtitle,
  TextField,
  Title,
} from 'lib/components/mobile';
import useAgent from 'lib/hooks/useAgent';
import { useSegment } from 'lib/hooks/useSegment';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { useApplicationCreate } from 'lib/services';
import { useMobileApplicationListTable } from 'lib/tables/MobileApplicationListTable';
import { Merchant } from 'lib/types';
import CurrencyUtil from 'lib/utils/currency';

export const MobileApplicationCheckout = () => {
  const { applicationInProcess, locations, merchants } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { getExpiresText } = useMobileApplicationListTable();
  const navigate = useNavigate();
  const {
    onMerchantSelect,
    mobileLoanCreateSuccess,
    onAmountChange,
    createLoanFromApplication,
    amount,
    loading,
  } = useApplicationCreate({ selectedModalItem: applicationInProcess });
  const { isAgent } = useAgent();
  const { isSnackbarVisible } = useSnackbar();

  const [defaultValue, setDefaultValue] = useState('');
  const [inputLoading, setInputLoading] = useState(true);

  const cancel = () => {
    trackSegmentEvent('Checkout Cancelled', {
      application: applicationName,
      applicationId: applicationInProcess.applicationId,
      borrowerId: applicationInProcess.borrowerId,
      purchaseAmount: '',
      merchantId: defaultValue,
      type: 'Link not sent',
    });
    navigate('/dashboard');
  };

  useEffect(() => {
    findMerchantSelection();
  }, []);

  const findPreferedLocation = () => {
    const findLocation = locations?.find((opt) => opt.id === applicationInProcess?.merchantId);
    if (findLocation) {
      return findLocation.name;
    }
    return '';
  };

  const findMerchantSelection = () => {
    const findMerchant = merchants?.find((opt) => opt.id === applicationInProcess?.merchantId);
    if (findMerchant) {
      const option = { target: { value: findMerchant.id } };
      onMerchantSelect(option);
      setDefaultValue(findMerchant.id);
    } else {
      const option = { target: { value: merchants?.[0].id } };
      onMerchantSelect(option);
      setDefaultValue(merchants?.[0].id);
    }
    setInputLoading(false);
  };

  return (
    <Container pageTitle="Customer Checkout">
      {mobileLoanCreateSuccess ? (
        <ContentContainer isSnackbarVisible={isSnackbarVisible}>
          <Title m={'0 0 8px 0'}>Checkout Link Sent</Title>
          <Subtitle m={'0 0 8px 0'}>
            {applicationInProcess?.firstName} has received a link on their phone to checkout for{' '}
            <b>{CurrencyUtil.toCurrencyString(Number(amount), true)}</b>. They have <b>24 hours</b> to complete their
            checkout. You’ll get notified once they’ve chosen a plan.
          </Subtitle>
        </ContentContainer>
      ) : (
        <ContentContainer isSnackbarVisible={isSnackbarVisible}>
          <Title m={'0 0 8px 0'}>Process Payment Plan</Title>
          <Subtitle>
            Send {applicationInProcess?.firstName} a link so they can choose and confirm their payment plan.
          </Subtitle>

          <ExpandableWithTitle title={applicationInProcess?.borrowerName}>
            <>
              <ExpandableInfoText name={'Phone'} value={applicationInProcess?.phone} />
              <ExpandableInfoText
                name={'Applied'}
                value={dayjs(applicationInProcess?.createdAt).format('MMM DD, YYYY hh:MM A')}
              />
              <ExpandableInfoText name={'Expires'} value={getExpiresText(applicationInProcess?.startedAt)} />
              <ExpandableInfoText name={'Location Preference'} value={findPreferedLocation()} />
              <ExpandableInfoText
                name={'Remaining Balance'}
                value={CurrencyUtil.toCurrencyString(applicationInProcess?.balanceAvailable, true)}
              />
              <ExpandableInfoText
                name={'Original Approval'}
                value={CurrencyUtil.toCurrencyString(applicationInProcess?.maxEligible, true)}
              />
            </>
          </ExpandableWithTitle>
          <AmountField
            placeholder="Purchase Amount (including tax)"
            onAccept={onAmountChange}
            max={applicationInProcess.balanceAvailable}
          />
          {!inputLoading ? (
            <TextField
              style={{ textAlign: 'left' }}
              id="filled-basic"
              variant="filled"
              data-testid="industry"
              select={true}
              onChange={onMerchantSelect}
              label="Checkout Location"
              defaultValue={defaultValue}
            >
              {merchants?.map((option: Merchant, index: number) => (
                <MenuItem
                  data-testid="industryItem"
                  key={index}
                  value={option?.id}
                  style={{ width: '100%', textAlign: 'left', fontSize: '14px' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </ContentContainer>
      )}
      {mobileLoanCreateSuccess ? (
        <ButtonContainer>
          <Button onClick={cancel}>Okay</Button>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button onClick={createLoanFromApplication} disabled={!amount || isAgent} loading={loading}>
            Checkout {amount && <SpacingForPrice amount={amount} prefix={'$'} />}
          </Button>
          <Button secondary={true} onClick={cancel}>
            Cancel
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default MobileApplicationCheckout;

const SpacingForPrice = styled(FixedDecimalFormat)`
  padding-left: 8px;
`;
