import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { VOID_LOAN } from '../graphql/mutations/Refund';
import { useDownloadTransaction, useMerchantLogin } from '../hooks';

interface ButtonProps {
  label?: string;
  icon?: string;
  onClick?: () => void;
  primary?: boolean;
}
interface ModalVariables {
  visibility: boolean;
  title: string;
  description?: string;
  firstButton?: ButtonProps;
  secondButton?: ButtonProps;
}

export const useVoid = ({ transactionInProcess }: { transactionInProcess?: any }) => {
  const [voidLoan] = useMutation(VOID_LOAN);
  const navigate = useNavigate();
  const alert = useAlert();
  const { downloadTransactionCSV } = useDownloadTransaction();

  const { merchantLogin, merchantLogout } = useMerchantLogin();

  const [slideModalVariables, setModalVariables] = useState<ModalVariables>({} as ModalVariables);

  const closeModal = () => {
    setModalVariables({
      ...slideModalVariables,
      visibility: false,
    });
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const openDownloadMenu = () => {
    setModalVariables({
      visibility: true,
      title: 'Download Transaction',
      firstButton: {
        label: 'Download',
        icon: 'download',
        onClick: () => {
          downloadTransactionCSV(transactionInProcess);
        },
      },
    });
  };

  const onVoidClick = () => {
    navigate(`/transactions/detail/void/review`);
  };

  const voidTransactionHandler = async (data) => {
    await merchantLogin(data?.merchantId);
    const { data: voidLoanData } = await voidLoan({
      variables: {
        input: {
          loanId: data?.id,
        },
      },
    });
    merchantLogout();

    return voidLoanData;
  };

  const voidTransaction = async () => {
    const voidLoanData = await voidTransactionHandler(transactionInProcess);

    if (voidLoanData?.voidLoan?.success) {
      navigate(`/transactions/detail/void/success`);
    } else {
      alert.error('Something wrong');
    }
  };

  const voidTransactionAllowed = (row) => {
    return (
      (row?.status === 'AWAITING_FUNDING' && row?.status !== 'VOIDED') ||
      (row?.status === 'FUNDED' && (row?.subStatus === 'HOLD' || row?.subStatus === 'FAILED'))
    );
  };

  return {
    voidTransactionHandler,
    voidTransaction,
    onVoidClick,
    openDownloadMenu,
    closeModal,
    slideModalVariables,
    voidTransactionAllowed,
    navigateBack,
  };
};
