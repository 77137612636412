import { SCHEDULE_VIEW_REMINDER } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import React, { useState } from 'react';

import styled from 'styled-components';

const AppointmentReminderPopover = () => {
  const [popoverOpen, setPopoverOpen] = useState(true);
  const { scheduleReminder, setScheduleReminder } = useStore();

  const handleClick = () => {
    setPopoverOpen(false);
    setScheduleReminder(true);
  };
  return (
    <>
      {!scheduleReminder && popoverOpen && (
        <Indicator id={`indicator_schedule_reminder`}>
          <PopoverContainer>
            <PopoverContent>
              <PopoverTitle> {SCHEDULE_VIEW_REMINDER.Title}</PopoverTitle>
              <PopoverText>{SCHEDULE_VIEW_REMINDER.Content}</PopoverText>
              <Button onClick={handleClick}>{SCHEDULE_VIEW_REMINDER.Button}</Button>
            </PopoverContent>
            <DownCircle />
          </PopoverContainer>
        </Indicator>
      )}
    </>
  );
};

export default AppointmentReminderPopover;
const Indicator = styled.div`
  position: relative;
  z-index: 7;
`;

const PopoverTitle = styled.div`
  color: #ffffff;

  padding: 8px;

  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
const DownCircle = styled.div<{ ternary?: boolean }>`
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #0e202f;

  transform: rotate(90deg);
  position: absolute;
  right: 373px;
  top: 74px;
`;
const PopoverContent = styled.div`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.main.textColor};
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
`;
const PopoverContainer = styled.div<{ ternary?: boolean; width?: string }>`
  position: absolute;
  background: ${(props) => props.theme.main.textColor};

  border-radius: 4px;
  width: ${(props) => props.width};
  left: 20px;
  top: -80px;
  width: 380px;
  height: 186px;
`;

const Button = styled.div`
  background-color: ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.white};

  cursor: pointer;
  font-weight: bold;

  border-radius: 4px;
  width: 65px;
  margin: 8px;
  padding: 8px;
  text-align: center;
`;

export const PopoverText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px;

  color: #ffffff;
`;
