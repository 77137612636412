import { gql } from '@apollo/client/core';

export const GET_MERCHANT_DETAIL = gql`
  query getMerchantDetail($input: MerchantDetailInput) {
    getMerchantDetail(input: $input) {
      count
      success
      data {
        name
        code
        address {
          street
          zip
          city
          state {
            code
            name
          }
        }
        id
        bankingInfo {
          accountName
          accountNumber
          accountNickname
          routingNumber
          accountType
          accountClassification
          accountStatus
          accountId
        }
        phone
        poc {
          phone
        }
      }
    }
  }
`;

export const GET_MERCHANT_CONTACTS = gql`
  query getMerchantContacts($input: MerchantContactsInput) {
    getMerchantContacts(input: $input) {
      total
      contents {
        accounts {
          name
          email
          phone
          type
          bookingLink
        }
      }
    }
  }
`;

export const GET_MERCHANT_LEADS = gql`
  query getMerchantLeads($input: GetMerchantLeadsInput) {
    getMerchantLeads(input: $input) {
      total
      contents {
        id
        status
        userId
        organizationId
        name
        phone
        address {
          street
          city
          state
          zip
        }
        bankInfo {
          accountName
        }
        createdAt
        updatedAt
      }
    }
  }
`;
