import client from 'lib/graphql/client';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, DashCard, FixedDecimalFormat, TableLoader } from 'lib/components';
import { GET_MEMBERSHIP_PLANS } from 'lib/graphql/queries';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Container } from 'lib/styles';
import { firstCharFormatter, formatTextCapitalize, settingsTableStyle } from 'lib/utils';

const PlanBuilder = () => {
  const navigate = useNavigate();
  const { organization } = useStore();
  const { isSnackbarVisible } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState([]);

  useEffect(() => {
    if (organization?.id) {
      getMembershipPlans();
    }
  }, []);

  const handleDetailClick = (row) => () => {
    navigate(`/membership/plan/${row.id}`);
  };

  const createPlanHandler = () => {
    navigate(`/membership/plan/create`);
  };

  const getMembershipPlans = async () => {
    try {
      setLoading(true);
      const { data: { fetchOrganizationsMembershipPlans } = {} } = await client.query({
        query: GET_MEMBERSHIP_PLANS,
        variables: {
          input: {
            pagination: {
              limit: 20,
              offset: 0,
              order: 'DESC',
              orderBy: 'createdAt',
            },
            organizationId: organization?.id,
          },
        },
      });
      setLoading(false);
      if (fetchOrganizationsMembershipPlans.contents.length > 0) {
        setPlanData(fetchOrganizationsMembershipPlans.contents);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getPeriodLabel = (period: string, paymentFrequency: number) => {
    if (period === 'CUSTOM_WEEKLY') return paymentFrequency === 1 ? 'we' : `${paymentFrequency} weeks`;
    if (period === 'YEARLY') return 'yr';

    return 'mo';
  };

  const tableColumns = [
    {
      name: 'Plan Name',
      selector: (row) => row.name,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      wrap: true,
      minWidth: '250px',
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      minWidth: '230px',
      maxWidth: '230px',
      cell: (row) => (
        <PriceContainer>
          {row.prices?.map((prc, index) => (
            <PriceItem key={index}>
              <FixedDecimalFormat key={index} amount={prc.price} prefix="$" />
              {' / '}
              {getPeriodLabel(prc.period, prc.paymentFrequency)}
            </PriceItem>
          ))}
        </PriceContainer>
      ),
    },
    {
      name: 'Pricing Model',
      selector: (row) => row.price,
      cell: (row) => (
        <PriceContainer>
          {row.prices?.map((prc, index) => (
            <PriceItem key={index}>{formatTextCapitalize(prc.period)}</PriceItem>
          ))}
        </PriceContainer>
      ),
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => <>{firstCharFormatter(row.status)}</>,
    },
    {
      name: 'Members',
      selector: (row) => row.memberCount,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: (row) => <CustomButtonPrimary text="Plan Details" onClick={handleDetailClick(row)} />,
      minWidth: '200px',
      right: true,
    },
  ];

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <Header>
        <Title>Plan Builder</Title>
        <HeaderButtons>
          <ButtonPrimary text="Create New Plan" onClick={createPlanHandler} />
        </HeaderButtons>
      </Header>
      <DashCard>
        <SubTitle>Your Plans</SubTitle>
        <CustomDataTable
          noHeader={true}
          customStyles={settingsTableStyle}
          columns={tableColumns}
          data={planData}
          progressPending={loading}
          progressComponent={<TableLoader count={4} />}
        />
      </DashCard>
    </Container>
  );
};

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 40px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }

  .rdt_TableRow {
    align-items: center;
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #0e202f;
  margin-bottom: 21px;
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: break-spaces;
`;

const PriceItem = styled.div`
  display: flex;
`;

const HeaderButtons = styled.div`
  width: auto;
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
`;

const CustomButtonPrimary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #879097 !important;
  color: #879097 !important;
  border-radius: 4px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

export default PlanBuilder;
