import { Dialog, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { DropDown } from 'lib/components';
import { BenefitUsageType } from 'pages/desktop/Membership/type';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';

interface Props {
  open: boolean;
  handleClose: () => void;
  data: BenefitUsageType | null;
}

export const AddEditBenefitModal = ({ open, handleClose }: Props) => {
  const planType = [
    {
      value: 'elite',
      label: 'Elite',
    },
    {
      value: 'elite',
      label: 'Elite2',
    },
  ];

  const confirmBenefitUsage = () => {
    // Api Call
    handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      keepMounted={false}
    >
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add / Edit Benefit?
        </CherryDialogTitle>
        <SubTitle>Add an included service, discount, bonus, or gift.</SubTitle>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Benefit Title</FormLabel>
              <StyledTextField
                size="small"
                type="text"
                placeholder="Benefit Title"
                inputProps={{
                  'data-inputkey': 'benefitTitle',
                }}
                id="plan-benefit-title"
                data-testid="plan-benefit-title"
                value={''}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Benefit Type</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={planType}
                  placeholder="Benefit Type"
                  placeholderColor={'#DADADA'}
                  hoveredMode={false}
                  defaultValue={''}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Frequency</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={planType}
                  placeholder="Frequency"
                  placeholderColor={'#DADADA'}
                  hoveredMode={false}
                  defaultValue={''}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Quantity (corresponds to frequency)</FormLabel>
              <StyledTextField
                size="small"
                type="text"
                placeholder="Quantity (corresponds to frequency)"
                inputProps={{
                  'data-inputkey': 'quantity',
                }}
                id="plan-quantity"
                data-testid="plan-quantity"
                value={''}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container={true} spacing={1}>
          <Grid item={true} xs={6}>
            <CancelButton disabled={false} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Grid>
          <Grid item={true} xs={6}>
            <RemoveButton disabled={false} onClick={confirmBenefitUsage}>
              Save
            </RemoveButton>
          </Grid>
        </Grid>
      </DialogContainer>
    </Dialog>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto 10px;
`;

export const RemoveButton = styled(ContinueButton)`
  background-color: ${(props) => props.theme.main.green};
  border-color: 1px solid ${(props) => props.theme.main.green};
`;

export const CancelButton = styled(BackButton)`
  border-color: 1px solid ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.green};
`;

export const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;
