import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button as CherryButton, EmailInput, PasswordInput } from '@frontend/cherry-library';
import images from 'assets/images';
import { Card, LinkItem } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useLogin } from 'lib/services';

const Login = () => {
  const { loginRenderFirstTime, setLoginRenderFirstTime } = useStore();
  const {
    loginUser,
    loading,
    fieldOnChange,
    isButtonEnabled,
    inputOnBlur,
    trackForgotPasswordClick,
    email,
    password,
  } = useLogin();
  const { trackIdentify, trackPage, trackSegmentEvent, applicationName } = useSegment();
  const newDate = new Date();

  useEffect(() => {
    trackIdentify();
    trackLandingPage();
  }, []);

  const trackLandingPage = () => {
    if (!loginRenderFirstTime) {
      setLoginRenderFirstTime(true);
      trackPage('landingPage');
    }
  };

  const onForgotPasswordClicked = () => {
    trackSegmentEvent('Password Reset', {
      application: applicationName,
      stage: 'Begin reset',
    });
    trackForgotPasswordClick();
  };

  const handlePasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      loginUser();
    }
  };

  return (
    <BackgroundWrapper>
      <CardWrapper>
        <CherryLogo src={images.cherryLogo.default} />
        <Title>Sign into your partner portal</Title>
        <Subhead>Please enter your email and password</Subhead>
        <FormContainer>
          <EmailInput
            onBlur={inputOnBlur}
            name="email"
            data-testid="email"
            onChange={fieldOnChange}
            label="Email"
            inputSize="small"
            hideIcon={true}
            autoFocus={true}
            value={email}
          />
          <PasswordInput
            onBlur={inputOnBlur}
            name="password"
            data-testid="password"
            onChange={fieldOnChange}
            onKeyDown={handlePasswordKeyDown}
            label="Password"
            inputSize="small"
            value={password}
          />
          <CherryButton
            fullWidth={true}
            variant="primary"
            loading={loading}
            disabled={isButtonEnabled}
            onClick={loginUser}
            data-testid="submit"
            size="small"
          >
            Login
          </CherryButton>
        </FormContainer>
        <LinkItem to="/forgot-password" onClick={onForgotPasswordClicked}>
          Forgot Password?
        </LinkItem>
      </CardWrapper>
      <Footer>
        <Info> © {newDate.getFullYear()} Cherry LLC.</Info>
        <Info>Have a question? (888) 839-7171</Info>
      </Footer>
    </BackgroundWrapper>
  );
};

export default Login;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.main?.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const CardWrapper = styled(Card)`
  width: 500px;
  min-height: 450px;
  max-height: calc(50%);
  align-items: center;
  justify-content: center;
`;

const CherryLogo = styled.img`
  width: 200px;
  height: auto;
`;

const Title = styled.h3`
  margin: 20px auto 5px;
`;

const Subhead = styled.p`
  font-style: italic;
  font-size: 12px;
  margin: 5px auto 20px;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const Info = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  margin: 5px auto;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
