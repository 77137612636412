import { DashComponent } from 'lib/components';
import { Column } from 'lib/styles';
import React, { useMemo, useState } from 'react';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMaterialTypeById } from 'lib/utils';
import OrderHistoryModal from 'pages/desktop/OtherResourcesTemplates/components/OrderHistoryModal';
import DigitalFiles from 'pages/desktop/OtherResourcesTemplates/views/DigitalFiles';
import MarketingSupplies from 'pages/desktop/OtherResourcesTemplates/views/MarketingSupplies';
import styled from 'styled-components';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

export const MarketingMaterials = ({ marketingItems, title }) => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, merchants } = useStore();
  const [isOrderHistoryModalVisible, setIsOrderHistoryModalVisible] = useState<boolean>(false);

  const openOrderHistoryModal = () => {
    const idMerchant = merchants?.length === 1 ? merchants[0].id : null;
    trackSegmentEvent('Viewed Marketing Order History', {
      application: applicationName,
      organizationId: organization?.id,
      merchantId: idMerchant,
    });
    setIsOrderHistoryModalVisible(true);
  };

  const closeOrderHistoryModal = () => {
    setIsOrderHistoryModalVisible(false);
  };

  const marketingSupplies = useMemo(() => {
    return marketingItems.SUPPLIES.map((marketingItem) => ({
      ...marketingItem,
      documentType: getMaterialTypeById(marketingItem.id),
    }));
  }, [marketingItems.SUPPLIES]);

  const digitalFiles = useMemo(() => {
    return marketingItems.DIGITAL.map((digitalItem) => ({
      ...digitalItem,
      documentType: getMaterialTypeById(digitalItem.id),
    }));
  }, [marketingItems.DIGITAL]);

  return (
    <>
      <Column>
        <Head>
          <DashComponent dashHeader={title} />
          <OrderHistoryButton onClick={openOrderHistoryModal} data-testid="see-order-history">
            <FontAwesomeIcon icon={faCalendarAlt} color="#0E202F" size="1x" />
            See Order History
          </OrderHistoryButton>
        </Head>
        <MarketingSupplies marketingMaterialList={marketingSupplies} />
        <DigitalFiles marketingMaterialList={digitalFiles} />
      </Column>
      {isOrderHistoryModalVisible ? (
        <OrderHistoryModal visible={isOrderHistoryModalVisible} onClose={closeOrderHistoryModal} />
      ) : null}
    </>
  );
};

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OrderHistoryButton = styled.button`
  background: none;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.midnightBlue};
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }
`;
