import { gql } from '@apollo/client/core';

export const FETCH_LOAN_LIST = gql`
  query fetchLoanList($input: LoanListingRequest) {
    fetchLoanList(input: $input) {
      success
      code
      message
      contents {
        amount
        applicationId
        borrowerId
        createdAt
        createdBy
        demo
        displayId
        dispute
        doNotCall
        doNotReport
        downPaymentAmount
        downPaymentPaymentMethodId
        financeCharge
        transactionType
        fundedAt
        grossAmount
        id
        installmentAmount
        installmentPaymentMethodId
        merchantFund
        merchantId
        merchantRevenue
        originalClosedAt
        productId
        promoUsed
        product {
          id
          mdf
          promoMdf
          term
        }
        purchaseAmount
        refundAmount
        selfCheckout
        status
        subStatus
        successfulContact
        totalAmount
        updatedAt
        updatedBy
        borrower {
          firstName
          lastName
          phone
        }
        funds {
          id
          directionType
          amount
          event
          type
          status
          updatedAt
          createdAt
        }
        plans {
          balance
        }
        refunds {
          id
          loanId
          fundId
          amount
          merchantFund
          type
          merchantRevenue
          status
          refundFee
        }
        loanRebate {
          tierId
          feeRebate
          discount
          rebateAppliedAt
          id
          createdAt
          updatedAt
        }
        tierLabel
      }
      total
    }
  }
`;

export const FETCH_LOANS = gql`
  query fetchLoans($input: SearchInput) {
    fetchLoans(input: $input) {
      success
      contents {
        amount
        applicationId
        borrowerId
        borrowerName
        borrowerPhone
        createdAt
        createdBy
        demo
        contractId
        dispute
        doNotCall
        doNotReport
        downPaymentAmount
        downPaymentPaymentMethodId
        financeCharge
        transactionType
        fundedAt
        grossAmount
        id
        installmentAmount
        installmentPaymentMethodId
        merchantFund
        merchantId
        merchantRevenue
        originalClosedAt
        productId
        promoUsed
        product {
          id
          mdf
          promoMdf
          term
        }
        purchaseAmount
        refundAmount
        selfCheckout
        status
        subStatus
        successfulContact
        totalAmount
        updatedAt
        updatedBy
        funds {
          id
          directionType
          amount
          event
          type
          status
          updatedAt
          createdAt
        }
        plans {
          balance
        }
        refunds {
          id
          loanId
          fundId
          amount
          merchantFund
          type
          merchantRevenue
          status
          refundFee
        }
        loanRebate {
          tierId
          feeRebate
          discount
          rebateAppliedAt
          id
          createdAt
          updatedAt
        }
        tierLabel
      }
      total
    }
  }
`;
