import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { FixedDecimalFormat, Loading } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { SAVE_DOWN_PAYMENT } from 'lib/graphql/mutations';
import { useDebounce } from 'lib/hooks';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import { useCheckoutWithPatient } from 'lib/services';
import CurrencyUtil from 'lib/utils/currency';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { ProductCalculation } from '../PlanCard/types';
import { BlackOutlineButton, GreenFillButton } from '../styles';
import { Loan } from '../types';
import CustomizeDownPayment from './components/CustomizeDownPayment';
import { DownPaymentError } from './constants';
import {
  ContentItemContainer,
  ContentSubText,
  ContentText,
  ContentTitle,
  Description,
  ErrorContainer,
  InfoContainer,
  InfoText,
  LoadingContainer,
  Title,
} from './styles';

interface Props {
  loan: Loan;
  plan: ProductCalculation;
  onBackClick: () => void;
  onSaveDownPayment: (productCalculations: ProductCalculation[]) => void;
}

const CustomizePlan = ({ loan, plan, onBackClick, onSaveDownPayment }: Props) => {
  const alert = useAlert();
  const { trackSegmentEvent } = useSegment();
  const { getProductCalculation } = useProductCalculation();
  const { getDownPaymentCalculation } = useCheckoutWithPatient();

  const [selectedDownPaymentPlan, setSelectedDownPaymentPlan] = useState<ProductCalculation>();
  const [calculationLoading, setCalculationLoading] = useState(false);

  const [amount, setAmount] = useState<number>(plan?.downPaymentAmount);
  const [error, setError] = useState<string>('');

  const [saveDownPayment, { loading }] = useMutation(SAVE_DOWN_PAYMENT);

  const debouncedValue = useDebounce(amount, 750);

  const goBack = () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.CUSTOM_DOWN_PAYMENT_CANCEL_CLICKED', {
      loanId: loan.id,
      borrowerId: loan.borrowerId,
      planId: plan.id,
    });

    onBackClick();
  };

  useEffect(() => {
    if (amount) {
      calculateAmount(amount);
    }
  }, []);

  const minEligible = selectedDownPaymentPlan?.variableDownPayment?.minEligible!;
  const maxEligible = selectedDownPaymentPlan?.variableDownPayment?.maxEligible!;
  const changeRange = selectedDownPaymentPlan?.variableDownPayment?.changeRange!;

  const calculateAmount = async (enteredAmount: number) => {
    if (!enteredAmount || !plan?.product?.id) return;

    setCalculationLoading(true);

    const res = await getDownPaymentCalculation(
      plan?.product?.applicationId!,
      plan?.product?.id,
      enteredAmount,
      plan?.grossAmount,
    );

    setSelectedDownPaymentPlan(res);
    setCalculationLoading(false);
  };

  const handleClickDecrease = () => {
    setError('');
    const value = amount - changeRange < minEligible ? minEligible : amount - changeRange;

    if (!isNaN(value)) {
      setAmount(value);
      setTimeout(() => {
        calculateAmount(value);
      }, 500);
    }
  };

  const handleClickIncrease = React.useCallback(() => {
    setError('');
    const value = amount + changeRange > maxEligible ? maxEligible : amount + changeRange;

    if (!isNaN(value)) {
      setAmount(value);
      setTimeout(() => {
        calculateAmount(value);
      }, 500);
    }
  }, [amount, changeRange]);

  const handleSaveDownPayment = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.CUSTOM_DOWN_PAYMENT_SAVE_CLICKED', {
      loanId: loan.id,
      borrowerId: loan.borrowerId,
      planId: plan.id,
    });
    const formattedMaxAmount = CurrencyUtil.FormatAmount(maxEligible);
    const formattedMinAmount = CurrencyUtil.FormatAmount(minEligible);
    if (!!amount) {
      if (amount > maxEligible) {
        setError(`${DownPaymentError.Maximum} ${formattedMaxAmount}`);
      } else if (amount < minEligible) {
        setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
      } else {
        setError('');
        try {
          const { data } = await saveDownPayment({
            variables: {
              input: {
                applicationId: selectedDownPaymentPlan?.product?.applicationId,
                loanId: loan?.id,
                parentId: selectedDownPaymentPlan?.product?.parentId
                  ? selectedDownPaymentPlan?.product?.parentId
                  : selectedDownPaymentPlan?.product?.id,
                amount: selectedDownPaymentPlan?.grossAmount,
                chosenDownPaymentAmount: selectedDownPaymentPlan?.downPaymentAmount,
              },
            },
          });

          if (data?.saveDownPayment?.applicationId) {
            try {
              const purchaseAmount = loan?.purchaseAmount;
              const applicationId = data?.saveDownPayment?.applicationId;

              const response = await getProductCalculation(applicationId, purchaseAmount);
              if (response && response.length > 0) {
                onSaveDownPayment(response);
                onBackClick();
              } else {
                throw new Error('Customized choose plan - no response');
              }
            } catch (err) {
              alert.info('Down payment error. Please try again later.');
            }
          } else {
            alert.info('Down payment error. Please try again later.');
          }
        } catch (err) {
          alert.info('Down payment error. Please try again later.');
        }
      }
    } else {
      setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (amount) {
        handleSaveDownPayment();
      }
    }
  };

  const handleClickReset = () => {
    setError('');
    const value = plan?.downPaymentAmount;

    setAmount(value);

    setTimeout(() => {
      calculateAmount?.(value);
    }, 500);
  };

  const handleCustomDownPaymentChange = (e) => {
    setError('');
    const value = parseFloat(e?.target?.value);

    if (Boolean(value)) {
      setAmount(value);

      const formattedMaxAmount = CurrencyUtil.FormatAmount(maxEligible);
      const formattedMinAmount = CurrencyUtil.FormatAmount(minEligible);
      if (value > maxEligible) {
        setError(`${DownPaymentError.Maximum} ${formattedMaxAmount}`);
      } else if (value < minEligible) {
        setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
      }
    } else {
      setError(DownPaymentError.Default);
    }
  };

  useEffect(() => {
    const value = parseFloat(debouncedValue);

    if (!value || value < minEligible || value > maxEligible) {
      return;
    }

    calculateAmount(value);
  }, [debouncedValue]);

  return (
    <Grid container={true} direction="column" sm={5}>
      <Title>Customize your down payment</Title>
      <Description>
        Modify your down payment to change your loan amount. Use the buttons or enter an amount between{' '}
        <FixedDecimalFormat amount={minEligible} prefix="$" /> and{' '}
        <FixedDecimalFormat amount={maxEligible} prefix="$" />.
      </Description>
      <CustomizeDownPayment
        onChange={handleCustomDownPaymentChange}
        amount={amount}
        checkKeyDown={checkKeyDown}
        handleClickIncrease={handleClickIncrease}
        handleClickDecrease={handleClickDecrease}
        handleClickReset={handleClickReset}
        error={error}
        minEligible={minEligible}
        maxEligible={maxEligible}
        loading={calculationLoading}
      />
      <ContentItemContainer>
        <ContentTitle>Due Later</ContentTitle>
        {error || calculationLoading ? (
          error ? (
            <ErrorContainer>{DownPaymentError.Default}</ErrorContainer>
          ) : (
            <LoadingContainer>
              <Loading size={40} />
            </LoadingContainer>
          )
        ) : (
          <>
            {selectedDownPaymentPlan ? (
              <>
                <ContentText>
                  <FixedDecimalFormat amount={selectedDownPaymentPlan?.payments?.[0]?.amount} prefix="$" />
                  /mo
                </ContentText>
                <ContentSubText margin="2px 0 0" fontSize="12px" lineHeight="16px">
                  <b>{selectedDownPaymentPlan?.product?.term} payments</b> starting{' '}
                  {dayjs(selectedDownPaymentPlan?.paymentDates?.[0]).format('MM/DD/YYYY')}
                  {' at '}
                  <b>
                    {selectedDownPaymentPlan?.promo
                      ? selectedDownPaymentPlan?.product?.promoApr
                      : selectedDownPaymentPlan?.product?.apr}
                    %
                  </b>{' '}
                  APR
                </ContentSubText>
                <ContentSubText margin="8px 0 0" fontSize="14px" lineHeight="19px">
                  <b>
                    <FixedDecimalFormat amount={Number(selectedDownPaymentPlan?.financeCharge)} prefix="$" />
                  </b>{' '}
                  total finance charge
                </ContentSubText>
              </>
            ) : null}
          </>
        )}
      </ContentItemContainer>
      {selectedDownPaymentPlan && selectedDownPaymentPlan?.product?.promoApr !== null ? (
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            APR increases to{' '}
            <b>{selectedDownPaymentPlan?.product?.fallbackApr || selectedDownPaymentPlan?.product?.apr}%</b> permanently
            if payment is not received within 5 days of any due date.
          </InfoText>
        </InfoContainer>
      ) : null}
      <Grid container={true} spacing={1}>
        <Grid item={true}>
          <BlackOutlineButton variant="outlined" onClick={goBack}>
            Cancel
          </BlackOutlineButton>
        </Grid>
        <Grid item={true}>
          <GreenFillButton
            text="Save Down Payment"
            disabled={!!error || calculationLoading || loading}
            loading={calculationLoading || loading}
            onClick={handleSaveDownPayment}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomizePlan);
