import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { BankInformation } from './BankInformation';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const BankSettings = () => {
  const permission = usePermission();
  const { user, merchants: storedMerchants = [], isAdminReporting } = useStore();
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_BANK_SETTINGS_PAGE_LOAD`);
  }, []);

  return permission(FeatureNames.BANK_INFORMATION, ScreenNames.SETTINGS) &&
    user?.userType !== 'MERCHANT_USER' &&
    storedMerchants &&
    storedMerchants?.length > 0 &&
    !isAdminReporting ? (
    <BankInformation />
  ) : (
    <UnauthorizedSettings title="Bank Information" />
  );
};

export default BankSettings;
