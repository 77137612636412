import styled from '@emotion/styled';
import React from 'react';

interface ExpandableInfoTextProps {
  name: string;
  value: string | null | undefined;
  labelColor?: string;
  testId?: string;
}
interface LabelProps {
  textColor?: string;
}
export const ExpandableInfoText = ({ name, value, labelColor, testId }: ExpandableInfoTextProps) => {
  return (
    <ExpandableInfoContainer>
      <ExpandableInfoName textColor={labelColor}>{name}</ExpandableInfoName>
      <ExpandableInfoValue data-testid={testId}>{value}</ExpandableInfoValue>
    </ExpandableInfoContainer>
  );
};

const ExpandableInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: -moz-pre-wrap !important;
  white-space: -webkit-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
`;

const ExpandableInfoName = styled.p<LabelProps>`
  width: 50%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.textColor ? props.textColor : '#0e202f')};
  margin: 0 0 12px 0;
`;

const ExpandableInfoValue = styled.p`
  width: 50%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
  margin: 0 0 12px 0;
`;
