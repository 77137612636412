import dayjs from 'dayjs';

import { cherryFoundingDate } from './Items';

export const calculateDateRange = (option) => {
  const now = dayjs();
  let date;

  switch (option) {
    case 'today':
      date = {
        from: dayjs(now).startOf('day').toISOString(),
        to: now.toISOString(),
      };
      break;
    case 'yesterday':
      const today = dayjs(now).startOf('day');
      date = {
        from: dayjs(today).subtract(1, 'day').toISOString(),
        to: today.toISOString(),
      };
      break;
    case 'lastWeek':
      const weekStart = dayjs().startOf('week');
      date = {
        from: dayjs(weekStart).subtract(1, 'week').toISOString(),
        to: weekStart.toISOString(),
      };
      break;
    case 'lastMonth':
      const monthStart = dayjs().startOf('month');
      date = {
        from: dayjs(monthStart).subtract(1, 'month').toISOString(),
        to: monthStart.toISOString(),
      };
      break;
    case 'lastYear':
      const yearStart = dayjs().startOf('year');
      date = {
        from: dayjs(yearStart).subtract(1, 'year').toISOString(),
        to: yearStart.toISOString(),
      };
      break;
    case 'thisWeek':
      date = {
        from: dayjs().startOf('week').toISOString(),
        to: now.toISOString(),
      };
      break;
    case 'thisMonth':
      date = {
        from: dayjs().startOf('month').toISOString(),
        to: now.toISOString(),
      };
      break;
    case 'thisYear':
      date = {
        from: dayjs().startOf('year').toISOString(),
        to: now.toISOString(),
      };
      break;
    case 'allTime':
      date = {
        from: dayjs('01/01/2019').toISOString(),
        to: dayjs().toISOString(),
      };
      break;
    default:
      break;
  }

  return date;
};

export const calculateDateRangeFromTwoDates = (ranges: any) => {
  const beginDate = dayjs(ranges.selection.startDate);
  const endDate = dayjs(ranges.selection.endDate);
  const today = dayjs();
  let text = `${dayjs(ranges.selection.startDate).format('MM/DD/YYYY')} - ${dayjs(ranges.selection.endDate).format(
    'MM/DD/YYYY',
  )}`;
  let specificTimeSpan = true;
  const last30Days = dayjs(today).subtract(1, 'month');
  const lastMonthBegin = dayjs(today).subtract(1, 'month').startOf('month');
  const lastMonthEnd = dayjs(lastMonthBegin).endOf('month');
  const thisMonthBegin = dayjs(today).startOf('month');
  const thisMonthEnd = dayjs(thisMonthBegin).endOf('month');
  const lastWeekBegin = dayjs(today).subtract(1, 'week').startOf('week');
  const lastWeekEnd = dayjs(lastWeekBegin).endOf('week');
  const thisWeekBegin = dayjs(today).startOf('week');
  const thisWeekEnd = dayjs(thisWeekBegin).endOf('week');
  const yesterdayBegin = dayjs(today).subtract(1, 'day').startOf('day');
  const yesterdayEnd = dayjs(yesterdayBegin).endOf('day');
  const todayBegin = dayjs(today).startOf('day');
  const todayEnd = dayjs(todayBegin).endOf('day');
  const thisYearBegin = dayjs(today).startOf('year');
  if (dayjs(lastMonthBegin).isSame(beginDate) && dayjs(lastMonthEnd).isSame(endDate)) {
    text = 'Last Month';
  } else if (
    dayjs(last30Days).format('YYYY-MM-DD') === dayjs(beginDate).format('YYYY-MM-DD') &&
    dayjs(today).format('YYYY-MM-DD') === dayjs(endDate).format('YYYY-MM-DD')
  ) {
    text = 'Last 30 Days';
  } else if (dayjs(lastWeekBegin).isSame(beginDate) && dayjs(lastWeekEnd).isSame(endDate)) {
    text = 'Last Week';
  } else if (dayjs(yesterdayBegin).isSame(beginDate) && dayjs(yesterdayEnd).isSame(endDate)) {
    text = 'Yesterday';
  } else if (dayjs(thisMonthBegin).isSame(beginDate) && dayjs(thisMonthEnd).isSame(endDate)) {
    text = 'This Month';
  } else if (dayjs(thisWeekBegin).isSame(beginDate) && dayjs(thisWeekEnd).isSame(endDate)) {
    text = 'This Week';
  } else if (dayjs(todayBegin).isSame(beginDate) && dayjs(todayEnd).isSame(endDate)) {
    text = 'Today';
  } else if (dayjs(thisYearBegin).isSame(beginDate) && dayjs(todayEnd).isSame(endDate)) {
    text = 'Year To Date';
  } else if (
    dayjs(cherryFoundingDate).format('YYYY-MM-DD') === dayjs(beginDate).format('YYYY-MM-DD') &&
    dayjs(today).format('YYYY-MM-DD') === dayjs(endDate).format('YYYY-MM-DD')
  ) {
    text = 'All Time';
  } else {
    specificTimeSpan = false;
  }

  return {
    text,
    specificTimeSpan,
  };
};

export const calculateAppointmentsRangeText = (ranges: any) => {
  const beginDate = dayjs(ranges.selection.startDate);
  const endDate = dayjs(ranges.selection.endDate);
  let text = `${dayjs(ranges.selection.startDate).format('MM/DD/YYYY')} - ${dayjs(ranges.selection.endDate).format(
    'MM/DD/YYYY',
  )}`;
  let specificTimeSpan = true;

  const lastWeekBegin = dayjs(dayjs().startOf('week')).subtract(6, 'day').toDate();
  const lastWeekEnd = dayjs().startOf('week').toDate();

  const yesterdayBegin = dayjs().subtract(1, 'day').startOf('day').toDate();
  const yesterdayEnd = dayjs().subtract(1, 'day').startOf('day').toDate();

  const thisWeekBegin = dayjs().startOf('day').toDate();
  const thisWeekEnd = dayjs().day(7).startOf('day').toDate();

  const nextWeekBegin = dayjs(dayjs().endOf('week')).add(2, 'day').startOf('day').toDate();
  const nextWeekEnd = dayjs(dayjs().endOf('week')).add(8, 'day').startOf('day').toDate();

  const today = dayjs().startOf('day').toDate();

  const tomorrow = dayjs().add(1, 'day').startOf('day').toDate();

  if (dayjs(lastWeekBegin).isSame(beginDate) && dayjs(lastWeekEnd).isSame(endDate)) {
    text = 'Last Week';
  } else if (dayjs(yesterdayBegin).isSame(beginDate) && dayjs(yesterdayEnd).isSame(endDate)) {
    text = 'Yesterday';
  } else if (dayjs(tomorrow).isSame(beginDate) && dayjs(tomorrow).isSame(endDate)) {
    text = 'Tomorrow';
  } else if (dayjs(thisWeekBegin).isSame(beginDate) && dayjs(thisWeekEnd).isSame(endDate)) {
    text = 'This Week';
  } else if (dayjs(today).isSame(beginDate) && dayjs(today).isSame(endDate)) {
    text = 'Today';
  } else if (dayjs(nextWeekBegin).isSame(beginDate) && dayjs(nextWeekEnd).isSame(endDate)) {
    text = 'Next Week';
  } else {
    specificTimeSpan = false;
  }

  return {
    text,
    specificTimeSpan,
  };
};
