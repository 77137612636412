import { Button } from '@frontend/cherry-library';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  Grid,
  GridSize,
} from '@mui/material';
import { CloseIcon, ErrorOutlineIcon, Loading, SuccessIcon } from 'lib/components';
import React from 'react';
import styled from 'styled-components';

type ModalTypes = 'primary' | 'warning';

export type ModalStatus = MODAL_STATUS.ERROR | MODAL_STATUS.SUCCESS | MODAL_STATUS.LOADING;

export enum MODAL_STATUS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
}

interface DialogProps extends MuiDialogProps {
  title: string;
  cancelButtonlabel?: string;
  submitButtonLabel?: string;
  isSubmitDisabled?: boolean;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  type?: ModalTypes;
  width?: number;
  status?: ModalStatus;
  errorContent?: React.ReactNode;
  successContent?: React.ReactNode;
  showClose?: boolean;
  customFooter?: React.ReactNode;
  cancelButtonCol?: GridSize;
  submitButtonCol?: GridSize;
  blur?: boolean;
  allowOverflow?: boolean;
}

export const ModalV2 = ({
  title,
  children,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  cancelButtonlabel,
  submitButtonLabel,
  hideCancelButton,
  type = 'primary',
  status,
  errorContent,
  successContent,
  width = 480,
  showClose = false,
  customFooter,
  cancelButtonCol,
  submitButtonCol,
  blur = false,
  allowOverflow = false,
  ...rest
}: DialogProps) => {
  const isCancelButtonVisible = !status && !!onCancel && !hideCancelButton;
  const isSubmitButtonVisible = status !== MODAL_STATUS.LOADING && !!onSubmit;

  const getTitle = () => {
    if (status === MODAL_STATUS.SUCCESS) return 'Success!';
    if (status === MODAL_STATUS.ERROR) return 'Something went wrong';

    return title;
  };

  const getContent = () => {
    if (status === MODAL_STATUS.SUCCESS)
      return (
        <StatusContainer>
          <SuccessIcon />
          {successContent}
        </StatusContainer>
      );
    if (status === MODAL_STATUS.ERROR)
      return (
        <StatusContainer>
          <ErrorOutlineIcon />
          {errorContent}
        </StatusContainer>
      );

    if (status === MODAL_STATUS.LOADING)
      return (
        <StatusContainer>
          <Loading size={20} />
        </StatusContainer>
      );

    return children;
  };

  return (
    <Dialog {...rest} width={width} blur={blur} allowOverflow={allowOverflow} onClose={onCancel}>
      <DialogTitle>
        {getTitle()}
        {showClose && (
          <Close onClick={onCancel}>
            <CloseIcon />
          </Close>
        )}
      </DialogTitle>
      <Content>{getContent()}</Content>
      {customFooter ? (
        customFooter
      ) : (
        <DialogActions>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={cancelButtonCol || 6}>
              {isCancelButtonVisible ? (
                <Button variant="secondary" fullWidth={true} onClick={onCancel!}>
                  {cancelButtonlabel || 'Cancel'}
                </Button>
              ) : null}
            </Grid>
            <Grid item={true} xs={submitButtonCol || (isCancelButtonVisible ? 6 : 12)}>
              {isSubmitButtonVisible ? (
                <Button
                  fullWidth={true}
                  onClick={status ? onCancel! : onSubmit!}
                  disabled={isSubmitDisabled}
                  data-testid="submit-button"
                >
                  {status ? 'Okay' : submitButtonLabel || 'Submit'}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;

  svg {
    width: 80px;
    height: 80px;
  }
`;

const Dialog = styled(MuiDialog)<{ width: number; blur: boolean; allowOverflow: boolean }>`
  .MuiPaper-root {
    width: ${(props) => props.width}px;
    padding: 24px;
    overflow: ${(props) => (props.allowOverflow ? 'visible' : 'auto')};
    max-width: 100%;
  }

  ${(props) =>
    props.blur &&
    `
  .MuiDialog-container {
    backdrop-filter: blur(10px);
  }
 `}
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;
  margin-bottom: 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const DialogActions = styled(MuiDialogActions)`
  margin-top: 24px;
  padding: 0 !important;

  & > button {
    flex: 1;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
`;

export default ModalV2;
