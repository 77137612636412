import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart as Chart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { CherryRangePicker, SummaryChartContainer } from 'lib/components';
import { CHART_ENUM } from 'lib/constants';
import { GET_MEMBERSHIPS_REVENUE_GENERATED, GET_MEMBERSHIPS_TOTAL_METRICS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';

export const Charts = () => {
  const [chartDatas, setCharDatas] = useState<any>({});
  const client = useApolloClient();

  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().toISOString(),
  });
  const [filteredStringRange, setFilteredStringRange] = useState<string>('All Time');
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [selectedChart, setSelectedChart] = useState<CHART_ENUM>(CHART_ENUM.MEMBERSHIP_GROWTH);
  const { organization } = useStore();

  useEffect(() => {
    fetchChartData();
  }, [filterSelectedDateValue]);

  const onDetailClick = (value: CHART_ENUM) => {
    switch (value) {
      case CHART_ENUM.MEMBERSHIP_GROWTH:
        setSelectedChart(CHART_ENUM.MEMBERSHIP_GROWTH);
        toggleRangePicker();
        break;
      case CHART_ENUM.PLAN_REVENUE:
        setSelectedChart(CHART_ENUM.PLAN_REVENUE);
        toggleRangePicker();
        break;
    }
  };

  const fetchChartData = async () => {
    // API CALL
    const {
      data: { getMembershipsTotalMetrics },
    } = await client.query({
      query: GET_MEMBERSHIPS_TOTAL_METRICS,
      variables: {
        input: {
          organizationId: organization?.id,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: 'DAILY',
        },
      },
    });

    const {
      data: { getMembershipsRevenueGenerated },
    } = await client.query({
      query: GET_MEMBERSHIPS_REVENUE_GENERATED,
      variables: {
        input: {
          organizationId: organization?.id,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: 'DAILY',
        },
      },
    });

    setCharDatas({
      getMembershipsTotalMetrics,
      getMembershipsRevenueGenerated,
    });
  };

  const toggleRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };

  const handleRangeSelection = (range: any) => {
    setFilterSelectedDateValue({
      from: dayjs(range.selection.startDate).startOf('day').toDate().toISOString(),
      to: dayjs(range.selection.endDate).endOf('day').toDate().toISOString(),
    });
  };

  const onTextStringUpdated = (inputDateRangeText: any) => {
    setFilteredStringRange(inputDateRangeText);
  };

  function getColor() {
    return 'rgb(' + 255 * Math.random() + ',' + 255 * Math.random() + ',' + 255 * Math.random() + ')';
  }

  // @ts-ignore
  return (
    <Container>
      <RowContainer>
        <SummaryChartContainer
          header="Membership Growth"
          id={CHART_ENUM.MEMBERSHIP_GROWTH}
          buttonText={filteredStringRange}
          buttonIcon="calendar"
          onDetailClick={onDetailClick}
        >
          <ResponsiveContainer width="95%" minHeight={300}>
            <Chart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
              <YAxis dataKey="data" />
              <Tooltip />
              <Legend />
              {chartDatas?.getMembershipsTotalMetrics?.contents?.map((s) => (
                <Line dataKey="data" data={s.contents} name={s.planName} key={s.planName} stroke={getColor()} />
              ))}
            </Chart>
          </ResponsiveContainer>
        </SummaryChartContainer>
        <SummaryChartContainer
          header="Plan Revenue"
          id={CHART_ENUM.PLAN_REVENUE}
          buttonText={filteredStringRange}
          buttonIcon="calendar"
          onDetailClick={onDetailClick}
        >
          <ResponsiveContainer width="95%" minHeight={300}>
            <BarChart
              data={chartDatas?.getMembershipsRevenueGenerated?.contents}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
              <YAxis />
              <Tooltip />
              <Legend />
              {chartDatas?.getMembershipsRevenueGenerated?.contents?.map((s) => (
                <Bar
                  dataKey="data"
                  data={s.contents}
                  name={s.planName}
                  key={s.planName}
                  fill={getColor()}
                  barSize={30}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </SummaryChartContainer>
      </RowContainer>
      <CherryRangePicker
        show={showRangePicker}
        onRangeSelected={handleRangeSelection}
        defaultSelectRange={{
          startDate: dayjs().subtract(1, 'month'),
          endDate: dayjs(),
        }}
        updateTextString={onTextStringUpdated}
        toggleRangePicker={toggleRangePicker}
        marginLeft={selectedChart === CHART_ENUM.MEMBERSHIP_GROWTH ? '325px' : '782px'}
        marginTop={'50px'}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`;

const RowContainer = styled.div<{ m?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: ${(props) => props.m};
`;
