import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { GQL_URL } from 'config';
import jwtDecode from 'jwt-decode';
import StorageService from 'lib/services/Storage';
import NavigateService from '../services/Navigate';
import { cancelRequestLink } from './cancelRequest';
import { REFRESH_TOKEN } from './mutations';

let isAlreadyFetching = false;

const isExpiredToken = (token: string = '') => {
  try {
    /* in case the call succeeds at the end of timeout,
    remove the amount of timeout from our current time
    to avoid 401 from server */
    const decodedToken = jwtDecode(token) as any;
    const tokenExpiration = decodedToken.exp * 1000 - 10000;
    const currentTime = Date.now().valueOf();

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};

const resetTokenAndReattemptRequest = async () => {
  try {
    const { refreshToken: resetToken = '' } = StorageService.getAuthData();
    if (!resetToken) {
      const reason = `Couldn't find refresh token!`;
      return Promise.reject(reason);
    }

    if (!isAlreadyFetching) {
      isAlreadyFetching = true;
      console.log('--- WARNING: JWT Timeout - Refreshing! ---');
      /* If there is no previous call to refresh the Auth token,
          make the request. Update the value to the check so that no
          other call can be made concurrently.*/
      const { data: { refreshToken: refreshTokenRes = {} } = {} } = await client.mutate({
        mutation: REFRESH_TOKEN,
        variables: {
          input: {
            token: resetToken,
          },
        },
      });

      if (!refreshTokenRes?.token) {
        client.stop();
        StorageService.clearUserData();
        NavigateService.navigate('/login');
        return Promise.reject(refreshTokenRes);
      }
      const { token, refreshToken } = refreshTokenRes || {};
      StorageService.setAuthData({ authToken: token, refreshToken });
      isAlreadyFetching = false;
    } else {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          const { token } = StorageService.getAuthData();
          if (!isExpiredToken(token)) {
            clearInterval(intervalId);
            resolve('');
          }
        }, 500);
      });
    }
  } catch (err) {
    // make sure we don't lock any upcoming request in case of a refresh error
    client.stop();
    StorageService.clearUserData();
    NavigateService.navigate('/login');
    return Promise.reject(err);
  }
};

const httpLink = new HttpLink({
  uri: GQL_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) Sentry.captureException(`[Network error]: ${networkError}`, { extra: { error: networkError } });
});

const WPQ_OPERATIONS = [
  'getUserAuthInit',
  'startOtpFlow',
  'submitOtp',
  'updateBorrower',
  'borrowerSSNPatch',
  'postApplication',
  'postApplicationFlow',
  'getApplicationFlow',
  'getApplication',
  'getPaymentFees',
  'getPaymentMethods',
  'patchLoanDetail',
  'getDownPaymentAmountCalculate',
  'saveDownPayment',
  'patchMerchantPreference',
];

const authMiddleware = setContext(async (_, { headers, ...rest }) => {
  try {
    const { token } = StorageService.getAuthData();
    const { wpqToken } = StorageService.getWPQAuthData();

    let authToken = token ? `Bearer ${token}` : '';

    if (_.operationName && WPQ_OPERATIONS.includes(_.operationName)) {
      if (_.operationName === 'getUserAuthInit') {
        authToken = '';
      } else {
        authToken = wpqToken ? `Bearer ${wpqToken}` : '';
      }
    }

    if ((token !== 'undefined' && Boolean(token) && !isExpiredToken(token)) || _.operationName === 'refreshToken') {
      return {
        headers: {
          ...headers,
          Authorization: authToken,
        },
        ...rest,
      };
    } else {
      if (token) {
        await resetTokenAndReattemptRequest();
      }

      const { token: refreshedToken } = StorageService.getAuthData();

      return {
        headers: {
          ...headers,
          Authorization: Boolean(refreshedToken) ? `Bearer ${refreshedToken}` : '',
          'x-client-name': 'PartnerPortal',
        },
        ...rest,
      };
    }
  } catch (error: any) {
    if (error.code !== 'refresh.token.invalid') {
      Sentry.captureException(error, {
        level: 'error',
      });
    } else {
      client.stop();
      NavigateService.navigate('/login');
      StorageService.clearUserData();
    }
  }
});

const checkTokenValidLink = new ApolloLink((operation: any, forward: any) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((response: any) => {
    try {
      if (response?.data?.[operation.operationName]?.code === 'auth.token.invalid') {
        NavigateService.navigate('/login');
        StorageService.clearUserData();
        return response;
      } else if (response?.data?.[operation.operationName]?.code === 'refresh.token.invalid') {
        NavigateService.navigate('/login');
        StorageService.clearUserData();
        return response;
      } else {
        return response;
      }
    } catch (error) {
      Sentry.captureException(error, {
        level: 'error',
      });
    }
  });
});

const link = checkTokenValidLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, cancelRequestLink, errorLink, link]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
  name: 'partner-portal',
  queryDeduplication: false,
});

export default client;
