import React from 'react';
import styled from 'styled-components';

import {
  CheckoutCancel,
  CheckoutEditSuccess,
  CheckoutForm,
  CheckoutSuccess,
  CheckoutSuccessCancel,
  CurrencyInput,
  Loading,
} from 'lib/components';
import { CheckoutAnotherInfo } from 'lib/components/ExpandableComponents/Checkout/CheckoutAnotherInfo';
import { useApplicationCreate } from 'lib/services/useApplicationCreate';
import { StepEnums } from 'lib/types';
import { SEND_OPTIONS } from 'pages/desktop/Dashboard/DentalDashboard/views/CheckoutOptions/CheckoutOptions';

const ProcessContractModal = ({
  selectedModalItem,
  loanUpdated,
  setLoanUpdated,
  isLoanUpdated,
  setExpandableOpened,
  setSelectedItem,
  selectedAppointment,
}) => {
  const {
    createLoanFromApplication,
    processCancelRequest,
    processEditApplication,
    closeAndUpdateExpanded,
    closeExpanded,
    continueToCheckoutForm,
    onAmountChange,
    setDefaultAmountValue,
    onMerchantSelect,
    options,
    editModeChange,
    cancelStart,
    amount,
    updatedLoan,
    clickedButton,
    isEditMode,
    menu,
    defaultAmountValue,
    borrowerName,
    activeStep,
    cancelLoanRef,
    selectedSendOption = '',
    setSelectedSendOption,
    setEmail,
  } = useApplicationCreate({
    selectedModalItem,
    setSelectedItem,
    setExpandableOpened,
    setLoanUpdated,
    isLoanUpdated,
    loanUpdated,
    selectedAppointment,
  });

  const renderConditionalComponents = () => {
    switch (activeStep) {
      case StepEnums.LOADING:
        return (
          <Center>
            <Loading size={20} />
          </Center>
        );
      case StepEnums.CHECKOUT_SUCCESS:
        return (
          <CheckoutSuccess
            borrowerName={borrowerName}
            cancelLoanRef={cancelLoanRef}
            closeExpanded={closeAndUpdateExpanded}
            selectedSendOption={selectedSendOption || SEND_OPTIONS.SMS}
          />
        );
      case StepEnums.CHECKOUT_FORM:
        return (
          <CheckoutForm
            isEditMode={isEditMode}
            selectedModalItem={selectedModalItem}
            options={options}
            onMerchantSelect={onMerchantSelect}
            closeExpanded={closeExpanded}
            processEditApplication={processEditApplication}
            confirmStart={createLoanFromApplication}
            amount={amount}
            cancelStart={cancelStart}
            onAmountChange={setDefaultAmountValue}
            setSelectedSendOption={setSelectedSendOption}
            setEmail={setEmail}
            selectedSendOption={selectedSendOption}
            currencyInput={
              <CurrencyInput
                onChange={onAmountChange}
                defaultValue={defaultAmountValue}
                maxAmount={updatedLoan?.balanceAvailable}
                minAmount={menu?.minPurchase || 200}
                clickedButton={clickedButton}
                onEditModeChange={editModeChange}
                hasEditMode={true}
              />
            }
          />
        );
      case StepEnums.CHECKOUT_EDIT_SUCCESS:
        return (
          <CheckoutEditSuccess
            borrowerName={borrowerName}
            closeExpanded={closeAndUpdateExpanded}
            cancelLoanRef={cancelLoanRef}
          />
        );
      case StepEnums.CHECKOUT_CANCEL:
        return (
          <CheckoutCancel
            updatedLoan={updatedLoan}
            closeExpanded={closeExpanded}
            processCancelRequest={processCancelRequest}
          />
        );
      case StepEnums.CHECKOUT_SUCCESS_CANCEL:
        return (
          <CheckoutSuccessCancel
            borrowerName={borrowerName}
            cancelLoanRef={cancelLoanRef}
            closeExpanded={closeAndUpdateExpanded}
          />
        );
      case StepEnums.ISSUE_ANOTHER_PLAN:
        return (
          <CheckoutAnotherInfo
            borrowerName={borrowerName}
            cancelLoanRef={cancelLoanRef}
            closeExpanded={closeAndUpdateExpanded}
            nextStep={continueToCheckoutForm}
          />
        );

      default:
        return (
          <Center>
            <Loading size={20} />
          </Center>
        );
    }
  };

  return <>{renderConditionalComponents()}</>;
};

const Center = styled.div`
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 460px;
`;

export default React.memo(ProcessContractModal);
