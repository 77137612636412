import { gql } from '@apollo/client/core';

export const FETCH_POINT_OF_SALE_LIST = gql`
  query FetchPointOfSaleList($input: FetchPointOfSaleListInput) {
    fetchPointOfSaleList(input: $input) {
      total
      contents {
        id
        organizationId
        applicationId
        firstName
        lastName
        phone
        treatmentAmount
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_POINT_OF_SALE_SETTLEMENTS = gql`
  query FetchPointOfSaleSettlements($input: FetchPointOfSaleSettlementsInput) {
    fetchPointOfSaleSettlements(input: $input) {
      hasMore
      data {
        id
        amount
        arrivalDate
      }
    }
  }
`;
