import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonContainer, Container, ContentContainer, Title } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';

const MobileTransactionFullRefundSuccess = () => {
  const { transactionInProcess } = useStore();
  const navigate = useNavigate();

  const navigateToTransactionsList = () => navigate('/transactions');

  return (
    <Container pageTitle="Full Refund">
      <DetailContainer>
        <Title>Success!</Title>
        <Description>
          {transactionInProcess?.borrowerName} has received a partial refund of ${transactionInProcess?.purchaseAmount}.
        </Description>
        <Description>
          We will prorate and reimburse your processing fees. It may take up to 3-5 business days to show in your bank
          account.
        </Description>
        <Description>Transaction details have been updated to reflect this refund.</Description>
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={navigateToTransactionsList}>Okay</Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionFullRefundSuccess;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;

const Description = styled.p``;
