import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { LocationManagementStepEnums } from 'lib/types';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContent,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@frontend/cherry-library';
import { FormControl } from '@mui/material';
import { POST_MERCHANT_LEADS } from 'lib/graphql/mutations';
import { clearMaskFormat, formatPhoneNumber, ROLE_TYPES } from 'lib/utils';
import { CreateMerchant } from './type';

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  merchant: CreateMerchant;
}

export const Review = ({ handleClose, onStepChange, merchant }: Props) => {
  const [postMerchantLeads, { loading }] = useMutation(POST_MERCHANT_LEADS);
  const alert = useAlert();
  const [infoChecked, setInfoChecked] = useState(true);

  const onContinueHandler = async () => {
    try {
      const { idOrganization, id, locationPhone, address, bankingInfo } = merchant;

      const { city, state, zip, street, name } = address || {};
      const { accountName, accountType, accountClassification, accountNumber, routingNumber } = bankingInfo || {};

      const paramData = {
        userId: id,
        organizationId: idOrganization,
        name,
        phone: clearMaskFormat(locationPhone),
        address: {
          street,
          city,
          state: state?.code,
          zip,
        },
        bankInfo: {
          accountName,
          accountNumber,
          accountType,
          accountClassification,
          routingNumber,
        },
      };
      const { data } = await postMerchantLeads({
        variables: {
          input: paramData,
        },
      });
      if (data?.postMerchantLead?.id) {
        onStepChange(LocationManagementStepEnums.SUCCESS);
      } else {
        alert.error('Something happened, please try again later.');
      }
    } catch (err) {
      alert.error('Something happened, please try again later.');
    }
  };

  const onBackHandler = () => {
    onStepChange(LocationManagementStepEnums.SELECT_BANK);
  };

  const { poc } = merchant;

  const formatRoleName = (value: string) => {
    const findRole = ROLE_TYPES?.find((rt) => rt.value === value);
    if (findRole) {
      return findRole.label;
    }
    return value;
  };

  const handleChangeCheckbox = () => {
    setInfoChecked(!infoChecked);
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Review and Confirm
      </CherryDialogTitle>
      <AttentionContainer>
        <AttentionIconContainer>
          <FontAwesomeIcon icon={faExclamationTriangle} color={'#EC3360'} />
          <Bold>Are you sure?</Bold>
        </AttentionIconContainer>
        <InformationText>
          Submitting this request will create an <Bold>entirely new </Bold>merchant account under your organization. If
          you are only looking to update your practice details or bank account, please return to Settings and edit this
          in the
          <Bold> Account Details </Bold>or<Bold> Bank Information </Bold>sections.
        </InformationText>
        <FormControl>
          <Checkbox
            checked={infoChecked}
            onChange={handleChangeCheckbox}
            label="Yes, I want to create an entirely new account."
          />
        </FormControl>
      </AttentionContainer>
      <SubTitle>Please review the information submitted for this new location.</SubTitle>

      <DialogContent>
        <Column>
          <ColumnItem>
            <Title>Point of Contact </Title>
            <Text>
              {poc.firstName} {poc.lastName}
            </Text>
            <Text>{poc.email}</Text>
            <Text>{formatPhoneNumber(poc.phone)}</Text>
            <Text>Role: {formatRoleName(poc?.role || merchant?.role)}</Text>
          </ColumnItem>
          <ColumnItem>
            <Title>Physical Location</Title>
            <Text capitalize={true}>{merchant?.address?.name}</Text>
            <Text capitalize={true}>{merchant?.address?.street}</Text>
            <Text capitalize={true}>
              {merchant?.address?.city}, {merchant?.address?.state?.name}, {merchant?.address?.zip}
            </Text>
            <Text>{merchant?.locationPhone}</Text>
          </ColumnItem>
          <ColumnItem>
            <Title>Banking Information</Title>
            <Text>Nickname: {merchant?.bankingInfo?.accountNickname}</Text>
            <Text>Account Name: {merchant?.bankingInfo?.accountName}</Text>
            <Text>Account Number: {merchant?.bankingInfo?.accountNumber}</Text>
            <Text>Routing Number: {merchant?.bankingInfo?.routingNumber}</Text>
            <Text>Account Type: {merchant?.bankingInfo?.accountType}</Text>
            <Text>Account Classification: {merchant?.bankingInfo?.accountClassification}</Text>
          </ColumnItem>
        </Column>
      </DialogContent>

      <ButtonContainer>
        <BackButton onClick={onBackHandler}>Back</BackButton>
        <ButtonGap />
        <ContinueButton
          onClick={onContinueHandler}
          disabled={loading || !infoChecked}
          loading={loading}
          data-testid="submit"
        >
          Add Location
        </ContinueButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

const Text = styled.span<{ capitalize?: boolean }>`
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
`;
const InformationText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  margin-top: 12px;
`;
const Bold = styled.span`
  font-weight: 700;
`;

const AttentionContainer = styled.div`
  padding: 16px 16px 12px 16px;
  border-radius: 4px;
  border: 1px;
  gap: 8px;
  background: ${(props) => props.theme.main.softCherryRed};
  border: 1px solid ${(props) => props.theme.main.red};
  margin-top: 24px;
`;
const AttentionIconContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;
