import { DocumentRequest } from 'lib/hooks';
import useDocumentRequest from 'lib/hooks/useDocumentRequest';
import DocumentRequestItem from 'pages/desktop/DocumentRequests/views/DocumentRequestItem/DocumentRequestItem';
import EmptyListMessage from 'pages/desktop/DocumentRequests/views/EmptyListMessage/EmptyListMessage';
import FileUploadModal from 'pages/desktop/DocumentRequests/views/FileUploadModal/FileUploadModal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface FileUploadModalConfig {
  open: boolean;
  documentRequest: DocumentRequest | null;
}

const DocumentRequestList = () => {
  const { documentRequestList, getAllDocumentRequests, getStatus } = useDocumentRequest();

  const [fileUploadModalOpen, setFileUploadModalOpen] = useState<FileUploadModalConfig>({
    open: false,
    documentRequest: null,
  });

  useEffect(() => {
    getAllDocumentRequests();
  }, []);

  const handleUploadDocument = (documentRequestId: string) => {
    const selectedDocumentRequest = documentRequestList.find(
      (documentRequest) => documentRequest.documentRequest.id === documentRequestId,
    );

    if (selectedDocumentRequest) {
      setFileUploadModalOpen({
        open: true,
        documentRequest: selectedDocumentRequest,
      });
    }
  };

  const closeUploadModal = () => {
    setFileUploadModalOpen({
      open: false,
      documentRequest: null,
    });
    getAllDocumentRequests();
  };

  const { userId, documentTitle, externalNote, numberOfDocs, id, merchantId, organizationId, relations, userType } =
    fileUploadModalOpen?.documentRequest?.documentRequest || {};

  return (
    <>
      <List>
        {documentRequestList?.length === 0 ? <EmptyListMessage /> : null}
        {documentRequestList?.map(({ documentRequest, externalUserName }, index) => {
          const status = getStatus(documentRequest.status);

          return (
            <DocumentRequestItem
              isMobile={false}
              key={index}
              documentRequest={{
                documentTitle: documentRequest.documentTitle,
                requestDate: documentRequest.createdAt,
                externalNote: documentRequest.externalNote,
                submittedBy: externalUserName,
                submitDate: documentRequest.updatedAt,
                userId: documentRequest.userId,
                id: documentRequest.id,
              }}
              status={status}
              uploadDocument={handleUploadDocument}
            />
          );
        })}
      </List>
      {id && userId && documentTitle && numberOfDocs && relations ? (
        <FileUploadModal
          open={fileUploadModalOpen.open}
          userId={userId}
          documentTitle={documentTitle}
          externalNote={externalNote}
          onCloseModal={closeUploadModal}
          requiredNumberOfDocs={numberOfDocs}
          documentRequestId={id}
          idMerchant={merchantId}
          idOrganization={organizationId}
          relations={relations}
          userType={userType}
        />
      ) : null}
    </>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default DocumentRequestList;
