import { Body, Button, Heading, UrlLink } from '@frontend/cherry-library';
import { ReactComponent as DeclineIcon } from 'assets/images/decline-fail.svg';
import dayjs from 'dayjs';
import { PATIENT_URL } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { BodyWrapper, ColumnBox, TitleWrapper } from '../styles';

const Denial = () => {
  const navigate = useNavigate();
  const { applyWithPatientFormData } = useStore();
  const today = new Date();
  const thirtyDaysfromNow = new Date(new Date().setDate(today.getDate() + 30));

  const returnToDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <ColumnBox>
      <TitleWrapper>
        <DeclineIcon width={32} height={32} id="apply-with-patient-denial-icon" />
        <Heading
          text={`We were unable to approve ${applyWithPatientFormData?.firstName} ${applyWithPatientFormData?.lastName}`}
          level="3"
        />
      </TitleWrapper>
      <BodyWrapper>
        <Body>
          Unfortunately, we cannot approve {applyWithPatientFormData?.firstName} {applyWithPatientFormData?.lastName}’s
          application at this time. They can apply again after{' '}
          <b>{dayjs(thirtyDaysfromNow.toDateString()).format('MMMM D, YYYY')}.</b>
        </Body>
      </BodyWrapper>
      <BodyWrapper>
        <Body>
          We sent a letter to {applyWithPatientFormData?.firstName} {applyWithPatientFormData?.lastName} explaining the
          reasons for our decision. They can view this letter in their patient portal at{' '}
          <UrlLink href={PATIENT_URL} target="_blank">
            patient.withcherry.com
          </UrlLink>
          .
        </Body>
      </BodyWrapper>
      <Button id="apply-with-patient-denial-okay" onClick={returnToDashboard}>
        Okay
      </Button>
    </ColumnBox>
  );
};
export default Denial;
