import client from 'lib/graphql/client';
import { useAlert } from 'react-alert';

import { FETCH_MENU_DETAIL_BY_CODE, FETCH_TRANSACTION_LIMITS_FOR_PAYMENT_ESTIMATE } from 'lib/graphql/queries';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';
import { useEffect, useState } from 'react';

export const MIN_ELIGIBLE_AMOUNT = 200;

export const usePaymentEstimator = () => {
  const alert = useAlert();
  const { organization } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();

  const [treatmentAmount, setTreatmentAmount] = useState<number | null>(null);
  const [maxEligibleAmount, setMaxEligibleAmount] = useState<number>(0);
  const [validPurchaseAmountRange, setValidPurchaseAmountRange] = useState(true);
  const [purchaseAmountErrorMsg, setPurchaseAmountErrorMsg] = useState<string | null>(null);
  const [customMaxAmountAvailable, setCustomMaxAmountAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (organization?.menuCode) {
      getApplicationMenuByCode();
    }
  }, [organization]);

  const fetchTransactionLimits = async (amount) => {
    try {
      const { data } = await client.query({
        query: FETCH_TRANSACTION_LIMITS_FOR_PAYMENT_ESTIMATE,
        variables: {
          input: {
            amount,
            idOrganization: organization?.id,
          },
        },
      });

      return data?.fetchTransactionLimits;
    } catch {
      alert.error('An error occured');
    }
  };

  const setPreferredMaxAmount = (amount: number) => {
    if (amount === -1) {
      setCustomMaxAmountAvailable(false);
      getApplicationMenuByCode();
    } else {
      setCustomMaxAmountAvailable(true);
      setMaxEligibleAmount(amount);
    }
  };

  const getApplicationMenuByCode = async () => {
    const {
      data: { getMenuByCode },
    } = await client.query({
      query: FETCH_MENU_DETAIL_BY_CODE,
      variables: { input: { code: organization?.menuCode } },
    });
    setMaxEligibleAmount(getMenuByCode?.maxPurchase);
  };

  const setErrorMsg = (value) => {
    if (value < MIN_ELIGIBLE_AMOUNT) {
      setPurchaseAmountErrorMsg(`Amount must be greater than ${CurrencyUtil.toCurrencyString(MIN_ELIGIBLE_AMOUNT)}`);
    } else if (value > maxEligibleAmount) {
      setPurchaseAmountErrorMsg(
        `Amount cannot exceed ${
          customMaxAmountAvailable ? "patient's available balance of" : ''
        } ${CurrencyUtil.toCurrencyString(maxEligibleAmount)}`,
      );
    }
  };

  const onPurchaseAmountChange = (nativeEvent) => {
    const value = nativeEvent?.target?.value;

    const isPurchaseAmountValid = CurrencyUtil.valueValidator(value, MIN_ELIGIBLE_AMOUNT, maxEligibleAmount);
    setTreatmentAmount(value ? parseFloat(value) : null);

    if (isPurchaseAmountValid) {
      trackSegmentEvent('Calculator Changed Amount', {
        application: applicationName,
        component: 'Payment Estimator',
        purchaseAmount: value,
        simplified: true,
      });
      setValidPurchaseAmountRange(true);
      setErrorMsg(null);
    } else {
      setValidPurchaseAmountRange(false);
      setErrorMsg(value);
    }
  };

  return {
    setTreatmentAmount,
    treatmentAmount,
    maxEligibleAmount,
    setValidPurchaseAmountRange,
    validPurchaseAmountRange,
    purchaseAmountErrorMsg,
    fetchTransactionLimits,
    onPurchaseAmountChange,
    MIN_ELIGIBLE_AMOUNT,
    setPreferredMaxAmount,
  };
};
