import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { SettingSubPageContainer } from '../styles';
import { MinimumApprovalAmountLimit } from './MinimumApprovalAmountLimit';
import { MinimumDentalFirstLookTransactionAmountLimit } from './MinimumDentalFirstLookTransactionAmountLimit';
import { PricingPlan } from './PricingPlan';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const PricingSettings = () => {
  const permission = usePermission();
  const { user, features } = useStore();
  const { trackSegmentEvent } = useSegment();

  const isPracticePricingAuthorized =
    permission(FeatureNames.MINIMUM_PROMO_TRANSACTIONS, ScreenNames.SETTINGS) && user?.userType !== 'MERCHANT_USER';

  const isMinApprovalAuthorized =
    permission(FeatureNames.MINIMUM_APPROVAL_AMOUNT, ScreenNames.SETTINGS) && user?.userType !== 'MERCHANT_USER';

  const renderMinimumApprovalAmount = () => {
    if (!features?.includes(FeatureNames.MINIMUM_APPROVAL_AMOUNT)) {
      return null;
    }
    if (isMinApprovalAuthorized) {
      return <MinimumApprovalAmountLimit />;
    }
    return <UnauthorizedSettings id="settings-minimum-approval-amount-component" title="Minimum Approval Amount" />;
  };

  const isPricingTierAuthorized = permission(FeatureNames.PRICING_PLAN, ScreenNames.SETTINGS);

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_PRICING_SETTINGS_PAGE_LOAD`);
  }, []);

  return (
    <SettingSubPageContainer>
      {isPracticePricingAuthorized ? (
        <MinimumDentalFirstLookTransactionAmountLimit />
      ) : (
        <UnauthorizedSettings id="settings-practice-pricing-component" title="Practice Pricing" />
      )}

      {renderMinimumApprovalAmount()}

      {isPricingTierAuthorized ? (
        <PricingPlan />
      ) : (
        <UnauthorizedSettings id="settings-pricing-tier-component" title="Pricing Tier" />
      )}
    </SettingSubPageContainer>
  );
};

export default PricingSettings;
