// tslint:disable jsx-no-lambda

import { useApolloClient, useMutation } from '@apollo/client';
import {
  CurrencyInput,
  Dropdown,
  EmailInput,
  PhoneNumberInput,
  sanitizePhoneNumber,
  TextInput,
} from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { APP_PREQUAL_URL } from 'config';
import dayjs from 'dayjs';
import { CherryTextField, ModalV2, MODAL_STATUS } from 'lib/components';
import { SEND_ESTIMATE_EMAIL, SEND_ESTIMATE_SMS } from 'lib/graphql/mutations';
import { CREATE_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { CALCULATE_PAYMENT } from 'lib/graphql/queries';
import { FETCH_TREATMENT_PLANS } from 'lib/graphql/queries/PatientTracker';
import { usePaymentEstimator } from 'lib/hooks/usePaymentEstimator';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { emailPattern, phonePattern } from 'lib/utils';
import { PreviewData } from 'pages/desktop/Dashboard/views/GenerateEstimate/GenerateEstimatePreview';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { EstimatePreview } from './EstimatePreview';
import { BusEvent, useEventBus } from './useEventBus';

interface MonthlyPaymentOptionsFunctions {
  /**
   * Opens monthly payment options modal
   */
  openMonthlyPaymentOptions: (data?) => void;
}

const emptyFunction = () => 0;
export interface Location {
  label: string;
  value: string;
  emailLabel: string;
}

const MonthlyPaymentOptionsContext = createContext<MonthlyPaymentOptionsFunctions>({
  openMonthlyPaymentOptions: emptyFunction,
});

export const MonthlyPaymentOptionsProvider = ({ children, ...rest }) => {
  const alert = useAlert();
  const client = useApolloClient();
  const { onEvent } = useEventBus();
  const [sendEstimateEmail] = useMutation(SEND_ESTIMATE_EMAIL);
  const [sendEstimateSms] = useMutation(SEND_ESTIMATE_SMS);
  const [createTreatmentPlan] = useMutation(CREATE_TREATMENT_PLAN);

  const {
    onPurchaseAmountChange,
    purchaseAmountErrorMsg,
    validPurchaseAmountRange,
    setPreferredMaxAmount,
    setValidPurchaseAmountRange,
  } = usePaymentEstimator();

  const { organization, merchants } = useStore();
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const { control, setValue, reset, trigger, watch, formState } = useForm({
    mode: 'onChange',
  });
  const values = watch();
  const isPhoneValid = !!values?.phone && !formState.errors?.phone;

  const CONSULTATION_SUMMARY_CHAR_LIMIT = 400;

  const [openMonthlyPaymentOptionEventData, setOpenMonthlyPaymentOptionEventData] = useState<PreviewData>();
  const [showAdditionalData, setShowAdditionalData] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasCreatedTreatmentPlanBefore, setHasCreatedTreatmentPlanBefore] = useState(false);
  const [processing, setProccessing] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const phoneRef = useRef<any>();
  const isSummaryCharMax = values?.consultationSummary?.length === CONSULTATION_SUMMARY_CHAR_LIMIT;

  const locationOptions =
    merchants?.map((location) => ({
      value: location?.id,
      label: `<b><span>${location?.name}</span> </b><br/>  ${location?.address?.address1} - ${location?.address?.city?.name} `,
      emailLabel: `${location?.name} - ${location?.address?.city?.name} `,
    })) || [];

  const [selectedLocation, setSelectedLocation] = useState<Location>();

  useEffect(() => {
    if (!selectedLocation?.value && merchants?.length === 1) {
      const singleOption = {
        value: merchants[0]?.id,
        label: merchants[0]?.name,
        emailLabel: `${merchants[0]?.name} - ${merchants[0]?.address?.city?.name}`,
      };
      setSelectedLocation(singleOption);
    }
  }, [merchants]);

  const findLocationById = (locationKey: string) => {
    return locationOptions.find((locationOption) => locationOption.value === locationKey);
  };

  const handleSelectedLocationChange = (event: SelectChangeEvent<unknown>) => {
    const locationKey = event?.target?.value as string;
    const location = findLocationById(locationKey);
    setSelectedLocation(location);
  };

  const isSubmitDisabled = () => {
    return !formState.isValid || !selectedLocation?.value || !validPurchaseAmountRange;
  };

  const getModalTitle = () => {
    if (processing) {
      return 'Processing...';
    }

    if (!isMissingInfo) {
      return 'Preview Monthly Payment Options';
    }

    return 'Get Monthly Payment Options';
  };

  const getSubmitButtonLabel = () => {
    if (!isMissingInfo) {
      return 'Create Monthly Payment Options';
    }

    return 'Continue';
  };

  const getCustomFooter = () => {
    return !isMissingInfo ? <></> : undefined;
  };

  const focusPhone = () => {
    setTimeout(() => {
      phoneRef.current?.focus();
    }, 0);
  };

  const trim = (value: string) => !!value.trim();

  const getPreviewParams = (paymentCalculationData: any, previewValues: any) => {
    return {
      paymentCalculation: paymentCalculationData.calculatePayment,
      amount: Number(previewValues.treatmentCost),
      additionalNotes: previewValues.consultationSummary,
      patientName:
        (previewValues.firstName || previewValues.customer.firstName) +
        ' ' +
        (previewValues.lastName || previewValues.customer.lastName),
      selectedLocation: {
        value: selectedLocation?.value,
        emailLabel: selectedLocation?.emailLabel,
      },
      planLength: 12,
      creditScore: { text: 'GREAT', value: 'GREAT' },
      treatmentCoordinator: '-',
      data: previewValues,
    };
  };

  const fetchTreatmentPlan = async (phoneNumber: string) => {
    try {
      const {
        data: { fetchTreatmentPlans },
      } = await client.query({
        query: FETCH_TREATMENT_PLANS,
        variables: {
          input: {
            limit: 5,
            offset: 0,
            orderCriteriaItems: [{ fieldName: 'application.createdAt', sort: 'DESC' }],
            searchCriteriaItems: [
              { fieldName: 'organizationId', fieldValue: organization?.id, searchOperation: 'EQUALITY' },
              { fieldName: 'customer.phone', fieldValue: phoneNumber, searchOperation: 'CONTAINS' },
              { fieldName: 'status', fieldValue: 'ACTIVE', searchOperation: 'EQUALITY' },
            ],
          },
        },
      });

      if (fetchTreatmentPlans?.contents?.length) {
        const treatmentPlan = fetchTreatmentPlans.contents?.[0];
        const customer = treatmentPlan?.customer;

        treatmentPlan?.treatmentCost && setValue('treatmentCost', treatmentPlan.treatmentCost);
        treatmentPlan?.consultationSummary && setValue('consultationSummary', treatmentPlan.consultationSummary);
        customer?.firstName && setValue('firstName', customer.firstName);
        customer?.lastName && setValue('lastName', customer.lastName);
        customer?.email && setValue('email', customer.email);

        const location = findLocationById(treatmentPlan.paymentOptionsMerchantId);
        setSelectedLocation(location);

        trigger();
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  const sendEmail = async (email: string, paymentCalculationData: any, treatmentValues: any) => {
    try {
      const res = await client.query({
        query: CALCULATE_PAYMENT,
        variables: {
          input: {
            amount: Number(treatmentValues.treatmentCost),
            term: 12,
            creditGrade: 'GREAT',
            organizationId: organization?.idOrganization,
          },
        },
      });

      const previewParams = getPreviewParams(res.data, treatmentValues);

      let applyLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice&utm_medium=email_estimate`;
      const merchantId = previewParams?.selectedLocation?.value;
      if (merchantId) {
        applyLink += '&m=' + merchantId;
      }

      const { data } = await sendEstimateEmail({
        variables: {
          input: {
            organizationId: organization?.idOrganization,
            data: {
              email,
              amount: previewParams?.amount,
              merchantName: previewParams?.selectedLocation?.emailLabel,
              financialSituation: previewParams?.creditScore?.text,
              paymentAmount: previewParams?.paymentCalculation?.paymentAmount ?? previewParams?.amount,
              term: previewParams?.planLength,
              financeCharge: previewParams?.paymentCalculation?.financeCharge,
              apr: previewParams?.paymentCalculation?.apr,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes,
              applyLink,
            },
          },
        },
      });

      if (!data?.sendEstimateEmail?.success) {
        alert.error(data?.sendEstimateEmail?.message);
      } else {
        alert.success('Sent over email successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const sendSms = async (phone: string, paymentCalculationData: any, treatmentValues: any) => {
    try {
      const previewParams = getPreviewParams(paymentCalculationData, treatmentValues);

      const { data } = await sendEstimateSms({
        variables: {
          input: {
            organizationId: organization?.idOrganization,
            data: {
              phone,
              merchantId: Number(previewParams?.selectedLocation?.value),
              amount: previewParams?.amount,
              creditGradeType: previewParams?.creditScore?.text,
              term: previewParams?.planLength,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes ?? '',
            },
          },
        },
      });

      if (!data?.sendEstimateSms?.success) {
        alert.error(data?.sendEstimateSms?.message);
      } else {
        alert.success('Sent over text successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const createTreatmentPlanRequest = async () => {
    try {
      const { data } = await createTreatmentPlan({
        variables: {
          input: {
            organizationId: organization?.idOrganization,
            customerFirstName: values.firstName,
            customerLastName: values.lastName,
            customerPhone: sanitizePhoneNumber(values.phone) || undefined,
            customerEmail: values.email || undefined,
            treatmentCost: Number(values.treatmentCost),
            stage: 'PLAN_SENT',
            consultationSummary: values.consultationSummary ?? '',
            paymentOptionsMerchantId: selectedLocation?.value,
          },
        },
      });

      if (!data?.createTreatmentPlan?.id) {
        alert.error('Error when creating treatment plan');
      } else {
        setHasCreatedTreatmentPlanBefore(true);
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleCreateTreatmentSubmit = async (action: 'text' | 'email', value: string) => {
    setProccessing(true);
    try {
      if (action === 'text') {
        await sendSms(value, previewData?.data, values);
      }

      if (action === 'email') {
        await sendEmail(value, previewData?.data, values);
      }

      trackSegmentEvent('PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_SUCCESS_VIEWED', {
        data: previewData?.paymentCalculation,
      });
    } catch (error) {
      setOpen(false);
      alert.error('Something went wrong');
    } finally {
      setProccessing(false);
    }
  };

  const onSubmit = async () => {
    trackSegmentEvent(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_SUBMITTED`, { formData: values });
    if (!hasCreatedTreatmentPlanBefore) {
      setProccessing(true);

      try {
        await createTreatmentPlanRequest();
      } catch {
        setOpen(false);
        alert.error('Something went wrong');
      } finally {
        setProccessing(false);
      }
    }
    try {
      const { data } = await client.query({
        query: CALCULATE_PAYMENT,
        variables: {
          input: {
            amount: Number(values.treatmentCost),
            term: 12,
            creditGrade: 'GREAT',
            organizationId: organization?.idOrganization,
          },
        },
      });

      const tempPreviewData = getPreviewParams(data, { ...values, location: selectedLocation });
      const existingData = tempPreviewData?.data;
      setPreviewData({
        ...tempPreviewData,
        data: { ...existingData, data: openMonthlyPaymentOptionEventData },
      });
    } catch {
      setOpen(false);
      alert.error('Something went wrong');
    }
  };

  const onClose = () => {
    trackSegmentEvent(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_CANCEL_BUTTON_CLICKED`);

    setOpen(false);
    reset({
      treatmentCost: undefined,
      consultationSummary: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
    });
    setPreviewData(undefined);
    setShowAdditionalData(false);
    setHasCreatedTreatmentPlanBefore(false);
    setPreferredMaxAmount(-1);
    setValidPurchaseAmountRange(true);
  };

  const openMonthlyPaymentOptions = (data) => {
    setOpen(true);
    focusPhone();
  };

  const contextValue = {
    openMonthlyPaymentOptions,
  };

  useEffect(() => {
    onEvent(BusEvent.OPEN_MONTHLY_PAYMENT_OPTIONS, (data) => {
      const isApplicationExpired = data?.application?.expireAt < dayjs().utc().endOf('day').toISOString();
      const isApprovedPatient = data?.application?.status === 'APPROVED' && !isApplicationExpired;

      if (isApprovedPatient) {
        setPreferredMaxAmount(data?.application?.balanceAvailable);
      }
      setOpen(true);
      focusPhone();

      setOpenMonthlyPaymentOptionEventData(data);
      const treatmentPlan = data;
      const customer = treatmentPlan?.customer;

      treatmentPlan?.consultationSummary && setValue('consultationSummary', treatmentPlan.consultationSummary);
      treatmentPlan?.treatmentCost && setValue('treatmentCost', treatmentPlan.treatmentCost);

      const location = findLocationById(treatmentPlan.paymentOptionsMerchantId);
      setSelectedLocation(location);

      customer?.firstName && setValue('firstName', customer.firstName);
      customer?.lastName && setValue('lastName', customer.lastName);
      customer?.email && setValue('email', customer.email);
      customer?.phone && setValue('phone', customer.phone);

      if (treatmentPlan?.treatmentCost > 0 && customer?.phone) {
        const tempPreviewData = getPreviewParams(data, {
          consultationSummary: treatmentPlan?.consultationSummary,
          treatmentCost: treatmentPlan?.treatmentCost,
          firstName: customer?.firstName,
          lastName: customer?.lastName,
          email: customer?.email,
          phone: customer?.phone,
          data: treatmentPlan,
        });

        setPreviewData(tempPreviewData);
      }
    });
  }, []);

  useEffect(() => {
    if (isPhoneValid && phonePattern.test(values?.phone)) {
      fetchTreatmentPlan(values?.phone);
    }
  }, [isPhoneValid, values?.phone]);

  const isMissingInfo = !(previewData?.amount && previewData.patientName && previewData.selectedLocation?.value);

  return (
    <MonthlyPaymentOptionsContext.Provider value={contextValue} {...rest}>
      {children}

      <ModalV2
        open={open}
        isSubmitDisabled={isSubmitDisabled()}
        customFooter={getCustomFooter()}
        status={processing ? MODAL_STATUS.LOADING : undefined}
        title={getModalTitle()}
        submitButtonLabel={getSubmitButtonLabel()}
        width={750}
        showClose={true}
        cancelButtonCol={4}
        submitButtonCol={8}
        onSubmit={onSubmit}
        onCancel={onClose}
      >
        {isMissingInfo && (
          <>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6} md={6}>
                <Img src="monthly-options-graphic.png" alt="monthly options" />
              </Grid>
              <Grid item={true} xs={6} md={6}>
                <Body>
                  <p>Create monthly payment options to present alongside your treatment plans. </p>
                  <p>Add info to personalize options before sharing.</p>
                </Body>

                <FormWrapper>
                  <Dropdown
                    label={selectedLocation?.value ? '' : 'Please select a location'}
                    options={locationOptions}
                    defaultValue={selectedLocation?.value}
                    value={selectedLocation?.value}
                    onChange={handleSelectedLocationChange}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true, pattern: phonePattern }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <PhoneNumberInput
                        id="phone"
                        name="phone"
                        inputProps={{
                          'data-testid': 'phone',
                          'data-neuro-label': 'phone',
                        }}
                        label="Mobile Phone"
                        ref={phoneRef}
                        value={value ?? ''}
                        onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_PHONE_SELECTED`)}
                        onChange={onChange}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_PHONE_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        error={!!error}
                        errorText={error?.message}
                        hideIcon={true}
                      />
                    )}
                  />

                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true, validate: trim }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextInput
                        id="first-name"
                        name="firstName"
                        inputProps={{
                          'data-testid': 'firstName',
                          'data-neuro-label': 'firstName',
                        }}
                        type="text"
                        label="First Name"
                        value={value}
                        onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_FIRST_NAME_SELECTED`)}
                        onChange={onChange}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_FIRST_NAME_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true, validate: trim }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextInput
                        id="last-name"
                        name="lastName"
                        inputProps={{
                          'data-testid': 'lastName',
                          'data-neuro-label': 'lastName',
                        }}
                        type="text"
                        label="Last Name"
                        value={value}
                        onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_LAST_NAME_SELECTED`)}
                        onChange={onChange}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_LAST_NAME_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="treatmentCost"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <CurrencyInput
                        id="treatmentCost"
                        name="treatmentCost"
                        inputProps={{
                          'data-testid': 'treatmentCost',
                          'data-neuro-label': 'treatmentCost',
                        }}
                        type="tel"
                        label="Treatment Cost"
                        value={value}
                        onFocus={segmentEventHandler(
                          `PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_TREATMENT_COST_SELECTED`,
                        )}
                        onChange={(e) => {
                          onChange(e);
                          onPurchaseAmountChange(e);
                        }}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_TREATMENT_COST_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        errorText={purchaseAmountErrorMsg ?? undefined}
                        error={!validPurchaseAmountRange}
                      />
                    )}
                  />
                </FormWrapper>
              </Grid>
            </Grid>

            {!showAdditionalData && (
              <GreenText
                onClick={() => {
                  trackSegmentEvent('PRACTICE_PORTAL.GET_MONTHLY_OPTIONS.MODAL_ADD_NOTES_EMAIL_CLICKED');
                  setShowAdditionalData(true);
                }}
              >
                Add Notes / Email
              </GreenText>
            )}

            {showAdditionalData && (
              <FormWrapper>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: emailPattern,
                      message: 'Please enter a valid e-mail address (ex:name@mail.com)',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <EmailInput
                        id="email-input"
                        name="email"
                        inputProps={{
                          'data-testid': 'email',
                          'data-neuro-label': 'email',
                        }}
                        label="Email (Optional)"
                        type="email"
                        value={value}
                        onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_EMAIL_SELECTED`)}
                        onChange={onChange}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_EMAIL_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        error={!!error}
                        errorText={error?.message}
                        hideIcon={true}
                      />
                    );
                  }}
                />

                <Controller
                  name="consultationSummary"
                  control={control}
                  defaultValue=""
                  rules={{
                    maxLength: CONSULTATION_SUMMARY_CHAR_LIMIT,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <CherryTextField
                        variant="filled"
                        name="consultationSummary"
                        label="Consultation Summary (optional)"
                        type="textarea"
                        minRows={4}
                        multiline={true}
                        value={value}
                        onFocus={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_NOTES_SELECTED`)}
                        onChange={onChange}
                        onBlur={segmentEventHandler(`PRACTICE_PORTAL.GET_PAYMENT_OPTIONS.MODAL_NOTES_FILLED`, {
                          withInput: true,
                          isFilledEvent: true,
                        })}
                        error={!!error}
                        helperText={error ? error?.message : null}
                        inputProps={{ maxLength: CONSULTATION_SUMMARY_CHAR_LIMIT }}
                      />
                    );
                  }}
                />
                <CountText max={isSummaryCharMax}>
                  {isSummaryCharMax && 'max characters'} {values?.consultationSummary?.length ?? 0} /{' '}
                  {CONSULTATION_SUMMARY_CHAR_LIMIT}
                </CountText>
              </FormWrapper>
            )}
          </>
        )}

        {!isMissingInfo && (
          <EstimatePreview
            isShowPrintButtonTop={true}
            hideGoBackButton={true}
            hideSendPatientSection={true}
            previewData={previewData}
            onClickBack={() => {
              setPreviewData(undefined);
              setShowAdditionalData(false);
              focusPhone();
            }}
            onClickEmail={(val) => handleCreateTreatmentSubmit('email', val)}
            onClickSms={(val) => handleCreateTreatmentSubmit('text', val)}
          />
        )}
      </ModalV2>
    </MonthlyPaymentOptionsContext.Provider>
  );
};

export const usePaymentOptions = (): MonthlyPaymentOptionsFunctions => {
  return useContext(MonthlyPaymentOptionsContext);
};

const CountText = styled.div<{ max: boolean }>`
  color: ${(props) => props.max && '#D32F2F'};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const Body = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
`;

const Img = styled.img`
  width: 85%;
`;

const GreenText = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: #00c37d;
  text-decoration: underline;
  cursor: pointer;
  margin: 12px 0;
`;
