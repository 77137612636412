import useStore from 'lib/hooks/useStore';
import { LocationManagement } from './LocationManagement';
import { usePermission } from 'lib/hooks';
import UnauthorizedSettings from '../components/UnauthorizedSettings';
import { useSegment } from 'lib/hooks/useSegment';
import { useEffect } from 'react';

const AddALocation = () => {
  const { user } = useStore();
  const permission = usePermission();
  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_ADD_A_LOCATION_PAGE_LOAD`);
  }, []);

  return permission('EDIT_USER', 'SETTINGS') && user?.userType !== 'MERCHANT_USER' ? (
    <LocationManagement />
  ) : (
    <UnauthorizedSettings title="Location Management" />
  );
};
export default AddALocation;
