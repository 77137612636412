import { useMutation } from '@apollo/client';
import { DropDown, FixedDecimalFormat, TableLoader } from 'lib/components';
import { DropdownContainer } from 'lib/components/ApplicationStatutes/components';
import Popover from 'lib/components/ApplicationStatutes/components/Popover';
import client from 'lib/graphql/client';
import { UPDATE_POINT_OF_SALE_STATUS } from 'lib/graphql/mutations';
import { FETCH_POINT_OF_SALE_LIST } from 'lib/graphql/queries';
import { PointOfSaleLinksSearch } from 'lib/graphql/searches/pointOfSaleSearch';
import useStore from 'lib/hooks/useStore';
import { formatDate, formatPhoneNumber, formatTimeFromDate } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import CancelLinkModal from './CancelLinkModal';
import { PointOfSaleLinksHeader } from './PointOfSaleLinksHeader';

export interface PointOfSaleLink {
  id?: string | number;
  firstName: string;
  lastName: string;
  phone: string;
  treatmentAmount: string;
  status?: string;
  createdAt?: string;
  applicationId?: string | number;
}

const STATUS_PROPS = {
  SENT: {
    backgroundColor: 'rgba(249, 236, 214, 1)',
    type: 'sent',
    label: 'Link Sent',
    hoverText: 'A link has been sent to the patient. They still need to complete their checkout and payment.',
  },
  CANCELLED: {
    backgroundColor: 'rgba(253, 235, 239, 1)',
    type: 'cancelled',
    label: 'Canceled',
    hoverText: 'The link was canceled and is no longer active.',
  },
  ERROR: {
    backgroundColor: 'rgba(253, 235, 239, 1)',
    type: 'error',
    label: 'Error',
    hoverText: 'An error occurred',
  },
  COMPLETED: {
    backgroundColor: 'rgba(230, 249, 242, 1)',
    type: 'completed',
    label: 'Paid & Complete',
    hoverText: 'The patient has completed checkout and payment.',
  },
};

interface Props {
  shouldRefetch: boolean;
}

const PointOfSaleLinks = ({ shouldRefetch }: Props) => {
  const { organization } = useStore();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState<PointOfSaleLink>();

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [pointOfSaleLinks, setPointOfSaleLinks] = useState<PointOfSaleLink[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState('');

  const [updateStatus] = useMutation(UPDATE_POINT_OF_SALE_STATUS, {
    onCompleted: () => {
      getPointOfSaleLinks();
    },
  });
  // const [createSmsLink] = useMutation(CREATE_POINT_OF_SALE_SMS_LINK, {
  //   onCompleted: () => {
  //     getPointOfSaleLinks();
  //   },
  // });

  useEffect(() => {
    if (!shouldRefetch) return;
    getPointOfSaleLinks();
  }, [order, orderBy, page, rowsPerPage, shouldRefetch]);

  const getPointOfSaleLinks = async (value?: string, filter?: string) => {
    const inputValue = value || searchInput;
    const filterValue = filter || selectedFilter;
    try {
      setLoading(true);
      const { data: { fetchPointOfSaleList } = {} } = await client.query({
        query: FETCH_POINT_OF_SALE_LIST,
        variables: {
          input: {
            pagination: {
              limit: rowsPerPage,
              offset: page - 1,
              order: order || 'desc',
              orderBy: orderBy || 'createdAt',
            },
            search: PointOfSaleLinksSearch(organization, inputValue, filterValue),
            organizationId: organization?.id,
          },
        },
      });
      if (fetchPointOfSaleList.contents.length > 0) {
        setTotal(fetchPointOfSaleList.total);
        setPointOfSaleLinks(fetchPointOfSaleList?.contents);
        return;
      }
      setPointOfSaleLinks([]);
      setTotal(0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelLinkClick = (row: PointOfSaleLink) => () => {
    setIsCancelModalVisible(true);
    setSelectedLink(row);
  };

  const tableColumns = [
    {
      name: 'Patient',
      selector: (row) => row.firstName,
      ignoreRowClick: true,
      cell: (row) => (
        <NameContainer>
          <NameText>
            {row?.firstName} {row?.lastName}
          </NameText>
          <NameText>{formatPhoneNumber(row?.phone)}</NameText>
        </NameContainer>
      ),
      minWidth: '230px',
      maxWidth: '230px',
      sortable: true,
    },
    {
      name: 'Treatment Amount',
      selector: (row) => row.treatmentAmount,
      sortable: true,
      cell: (row) => <FixedDecimalFormat amount={row.treatmentAmount} prefix="$" />,
    },
    {
      name: 'Link Sent',
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => `${formatDate(row.createdAt)} at ${formatTimeFromDate(row.createdAt)}`,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <Popover row={row} ternary={true} left={165} iconName="info_circle_outline" {...STATUS_PROPS[row.status]} />
      ),
    },
    {
      name: '',
      selector: (row) => row.detail,
      right: true,
      left: false,
      width: '221px',
      cell: (row) => (
        <ActionButtons>
          <DropdownContainer>
            <DropDown
              options={[{ label: 'Cancel Link', value: 'CANCEL' }]}
              textColor={'#0E202F'}
              onChange={handleCancelLinkClick(row)}
              showAllItem={true}
              hoveredMode={false}
              customSelector={true}
            />
          </DropdownContainer>
        </ActionButtons>
      ),
      ignoreRowClick: true,
    },
  ];

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
  };

  const onTableSort = (column, direction) => {
    setLoading(true);
    setOrderBy(column?.selector);
    setOrder(direction);
  };

  const searchHandler = (value: string, key?: string) => {
    getPointOfSaleLinks(value, key);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalVisible(false);
  };

  const handleCancelLink = async () => {
    await updateStatus({
      variables: {
        input: {
          organizationId: organization?.id,
          pointOfSaleId: selectedLink?.id,
          applicationId: selectedLink?.applicationId,
          status: 'CANCELLED',
        },
      },
    });
  };

  return (
    <TableContainer>
      <Title>Point of Sale Links</Title>

      <CancelLinkModal open={isCancelModalVisible} onClose={handleCancelModalClose} onSubmit={handleCancelLink} />

      <PointOfSaleLinksHeader
        searchHandler={searchHandler}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />

      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            noHeader={true}
            columns={tableColumns}
            data={pointOfSaleLinks}
            pagination={true}
            paginationServer={true}
            onChangePage={pageChange}
            onChangeRowsPerPage={rowsCountChange}
            paginationTotalRows={total}
            progressPending={loading}
            onSort={onTableSort}
            sortServer={true}
            progressComponent={<TableLoader count={4} />}
          />
        </TableColumn>
      </TableRow>
    </TableContainer>
  );
};

const ActionButtons = styled.div`
  display: flex;
`;

const Title = styled.span`
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  margin-bottom: 16px;
`;

const TableRow = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 50px;

  .data-table {
    overflow: inherit;
  }
`;

interface TableColumnProps {
  flex?: number;
}

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow {
    padding: 15px 8px !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableHeadRow div {
    font-size: 12px;
    margin-bottom: -10px;
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    &:hover .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  margin: 40px 0px;
  padding-bottom: 32px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0e202f;
`;

export default PointOfSaleLinks;
