import React, { useState } from 'react';

import { Dialog as MuiDialog, DialogActions as MuiDialogActions } from '@mui/material';
import { CherryDialogTitle } from 'pages/desktop/Settings/components/Dialog';
import styled from 'styled-components';

import images from 'assets/images';
import { ButtonPrimary } from 'lib/components';
import { formatPhoneNumber } from 'lib/utils';

import ApplicationListHistoryTable, { HISTORY_TABS } from './ApplicationLinkHistoryTable';

interface ApplicationLinkHistoryProps {
  visible: boolean;
  onClose: () => void;
  tab: string;
}

const ApplicationLinkHistory = ({ visible, onClose, tab }: ApplicationLinkHistoryProps) => {
  const [displayResendSuccessView, setDisplayResendSuccessView] = useState(false);
  const [resendUserInfo, setResendUserInfo] = useState('');

  const handleApplicationResendSuccess = (data, sentType) => {
    if (sentType === HISTORY_TABS.SMS) {
      setResendUserInfo(formatPhoneNumber(data));
    } else {
      setResendUserInfo(data);
    }
    setDisplayResendSuccessView(true);
  };

  const returnToTableView = () => {
    setDisplayResendSuccessView(false);
  };

  return (
    <Dialog open={visible} onClose={onClose} successView={displayResendSuccessView}>
      <DialogTitle id="application-link-history-title" onClose={onClose}>
        {displayResendSuccessView ? 'Success!' : 'Sent Application Links'}
      </DialogTitle>
      <Content>
        {displayResendSuccessView ? (
          <>
            <ImgContainer>
              <img src={images.circleCheck.default} alt="check" />
            </ImgContainer>
            <CenteredText>{`Reminder successfully sent to ${resendUserInfo}`}</CenteredText>
          </>
        ) : (
          <ApplicationListHistoryTable
            onApplicationResendSuccess={handleApplicationResendSuccess}
            closeModal={onClose}
            tab={tab}
          />
        )}
      </Content>
      {displayResendSuccessView && (
        <DialogActions>
          <ActionButton onClick={returnToTableView} text="Okay" />
        </DialogActions>
      )}
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)<{ successView: boolean }>`
  .MuiPaper-root {
    width: ${(props) => (props.successView ? '480px' : '596px')};
    height: ${(props) => (props.successView ? '260px' : 'unset')};
    padding: 24px 32px ${(props) => (props.successView ? '24px' : '0')} 32px;
    overflow: ${(props) => (props.successView ? 'visible' : 'scroll')};
  }
  .MuiGrid-root {
    margin-left: 0;
  }
`;

const Content = styled.div`
  font-size: 14px;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const DialogTitle = styled(CherryDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredText = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

const ActionButton = styled(ButtonPrimary)`
  border-radius: 4px !important;
  font-size: 15px !important;
  width: 100%;
  &:hover,
  &:focus {
    outline: none;
    background-color: #00a569;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  & > button {
    width: 400px;
  }
  padding: 0 !important;
  margin: 0 auto;
`;

export default ApplicationLinkHistory;
