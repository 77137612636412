import { FixedDecimalFormat } from 'lib/components';
import { Icon } from 'lib/components/mobile';
import { confettiColors } from 'lib/constants';
import { PreApprovalStepEnums } from 'lib/types';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { BackButton, ContinueButton } from 'pages/desktop/Settings/components/Dialog';
import { SubDescription, Subtitle } from './CommonComponents';
import PreApprovalButtons from './PreApprovalButtons';
import { StyledSubDescription } from './PreApprovalDenial';
import { PreApprovalResult } from './type';

interface Props {
  onClose: () => void;
  setActiveStep: (step: PreApprovalStepEnums) => void;
  preApprovalResult: PreApprovalResult | undefined;
  setConfetti: (active: boolean) => void;
}

export const PreApprovalSuccess = ({ onClose, setActiveStep, preApprovalResult, setConfetti }: Props) => {
  const { flags } = useFlags();
  const [confettiActive, setConfettiActive] = useState(true);

  const isNewASuccessScreen = flags?.SCREEN?.preApprovePatientNewApprovalScreen?.isEnabled() || false;

  useEffect(() => {
    setTimeout(() => {
      setConfettiActive(false);
    }, 10000);
  }, []);

  return (
    <SuccessContainer>
      {confettiActive ? (
        <Confetti width={600} height={495} numberOfPieces={300} colors={confettiColors} recycle={false} />
      ) : null}

      {!isNewASuccessScreen ? (
        <Subtitle>
          {preApprovalResult?.firstName} is pre-approved
          <Icon src={'circle_check'} />
        </Subtitle>
      ) : (
        <SuccessContent>
          <Subtitle margin="0">{preApprovalResult?.firstName} is pre-approved</Subtitle>
          <Subtitle margin="8px 0 0 0" fontSize="16px" fontWeight={400}>
            Approval range:&nbsp;
            <FixedDecimalFormat decimalScale={0} amount={Number(preApprovalResult?.minimumAmount)} prefix="$" />
            &nbsp;-&nbsp;
            <FixedDecimalFormat decimalScale={0} amount={Number(preApprovalResult?.maximumAmount)} prefix="$" />
          </Subtitle>
        </SuccessContent>
      )}

      {!isNewASuccessScreen ? (
        <BackgroundContainer>
          <DescriptionContainer>
            <Description>
              <ApprovalTitle>Minimum Approval Amount:</ApprovalTitle>
              <ApprovalAmount>
                <FixedDecimalFormat decimalScale={0} amount={Number(preApprovalResult?.minimumAmount)} prefix="$" />
              </ApprovalAmount>
            </Description>
            <Border />
            <Description>
              <ApprovalTitle>Maximum Approval Up To:</ApprovalTitle>
              <ApprovalAmount>
                <FixedDecimalFormat decimalScale={0} amount={Number(preApprovalResult?.maximumAmount)} prefix="$" />
              </ApprovalAmount>
            </Description>
          </DescriptionContainer>
          <SubDescription>
            <Icon src={'info_circle_dark'} />
            {preApprovalResult?.firstName} must complete an application to view their exact approval.
          </SubDescription>
        </BackgroundContainer>
      ) : (
        <StyledSubDescription>
          <Icon src={'info_circle_dark'} />
          <div>
            <div>
              <b>Next Step: Ask {preApprovalResult?.firstName} to Apply</b>
            </div>
            <div>
              This is not yet an approval. {preApprovalResult?.firstName} must complete an application to see their
              exact approval amount and APRs.
            </div>
          </div>
        </StyledSubDescription>
      )}

      <PreApprovalButtons
        isNewASuccessScreen={isNewASuccessScreen}
        onClose={onClose}
        setConfetti={setConfetti}
        setActiveStep={setActiveStep}
        preApprovalResult={preApprovalResult}
        screenName="SUCCESS"
      />
    </SuccessContainer>
  );
};

const BackgroundContainer = styled.div`
  background-color: ${(props) => props?.theme?.main?.green5};
  border: 2px solid #b3edd8;
  border-radius: 8px;
  padding: 16px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  padding: 0 10px;
`;

const Border = styled.div`
  border-left: 1px solid ${(props) => props?.theme?.main?.sealGray};
  width: 1px;
  height: 71px;
  margin: 0 16px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ApprovalTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const ApprovalAmount = styled.span`
  font-size: 36px;
  font-weight: 700;
  color: #00c37d;
  margin-top: 8px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledContinueButton = styled(ContinueButton)`
  padding: 16px;
  font-weight: 600;
`;

export const StyledActionButton = styled(BackButton)`
  border-color: ${(props) => props?.theme?.main?.textColor};
  color: ${(props) => props?.theme?.main?.textColor};
`;

const SuccessContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme.main.aliceBlue};
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;
