import axios from 'axios';
import { APP_STATIC_URL } from 'config';
import { DashComponent, Loading, PageHeader, ScheduleMeeting } from 'lib/components';
import { FeatureNames, ScreenNames, usePermission } from 'lib/hooks';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { Column, Container, Row } from 'lib/styles';
import { ResetOnboarding } from 'pages/desktop/Marketing/views';
import React, { useEffect, useState } from 'react';
import { HowToGuides, ProductVideos } from './views';

const Training = () => {
  const permission = usePermission();
  const { organization, user, merchants } = useStore();
  const { isSnackbarVisible } = useSnackbar();
  const isDemo = organization?.isDemo;

  const [staticFile, setStaticFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchStaticFile = async () => {
      const result = await axios.get(APP_STATIC_URL);
      setStaticFile(result.data);
      setLoading(false);
    };

    fetchStaticFile();
  }, []);

  if (loading) {
    return <Loading fixed={true} size={40} />;
  }

  return (
    <Container isSnackbarVisible={isSnackbarVisible}>
      <PageHeader title="Training" />
      <Row>
        <Column>
          <DashComponent dashHeader={'How-To Guides'} />
          <HowToGuides user={user} organization={organization} merchants={merchants} />
        </Column>
      </Row>
      <Row>
        {!isDemo && (
          <ScheduleMeeting
            title={'Schedule Training'}
            requestUrl={'https://withcherry.com/training/'}
            description={'Book an appointment with our team to get refreshed on materials covered during onboarding.'}
            buttonText={'Schedule Training'}
          />
        )}
      </Row>
      {!permission(FeatureNames.PRODUCT_VIDEOS, ScreenNames.TRAINING) ? (
        ''
      ) : (
        <Row>
          <Column>
            <DashComponent dashHeader={'Product Videos'} />
            <ProductVideos
              videoList={staticFile?.VIDEO || []}
              user={user}
              organization={organization}
              merchants={merchants}
            />
          </Column>
        </Row>
      )}
      {!permission(FeatureNames.WALKTHROUGH, ScreenNames.TRAINING) ? (
        ''
      ) : (
        <Row>
          <Column>
            <DashComponent dashHeader={'Guided Walkthrough'} />
            <ResetOnboarding />
          </Column>
        </Row>
      )}
      {/* <Row>
        <Column>
          <DashComponent dashHeader={'Cheatsheet / FAQ'} />
          <CheatSheetFaq faqList={staticFile?.FAQ || []} />
        </Column>
      </Row> */}
    </Container>
  );
};

export default Training;
