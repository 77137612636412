import { EmailInput, PhoneNumberInput, TextInput } from '@frontend/cherry-library';
import { FormControl, Grid } from '@mui/material';
import ModalV2, { ModalStatus, MODAL_STATUS } from 'lib/components/ModalV2/ModalV2';
import { useSegment } from 'lib/hooks/useSegment';
import { clearMaskFormat, emailPattern, phonePattern } from 'lib/utils';
import { PreApprovalResult } from 'pages/desktop/Dashboard/views/PreApproval/type';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export interface TreatmentPlan {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  consultationSummary: string;
  treatmentCost: number | string;
  sendText: boolean;
  sendEmail: boolean;
}

const initialValues = {
  firstName: '',
  lastName: '',
  treatmentCost: '',
  phone: '',
  email: '',
  consultationSummary: '',
  sendText: false,
  sendEmail: false,
};

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: TreatmentPlan) => Promise<void>;
  initialAmount?: number;
  consultationSummaryCharLimit?: number;
  preApprovalResult?: PreApprovalResult;
}

const CreateTreatmentModal = ({
  open,
  onClose,
  onSubmit,
  initialAmount,
  consultationSummaryCharLimit,
  preApprovalResult,
}: Props) => {
  const { trackSegmentEvent, segmentEventHandler } = useSegment();

  const SEGMENT_FEATURE_NAME = 'ADD_PATIENT';

  const [formData, setFormData] = useState<TreatmentPlan>(initialValues);
  const [modalStatus, setModalStatus] = useState<ModalStatus>();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const firstNameInputRef = useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  const resetForm = () => {
    setFormData(initialValues);
  };

  useEffect(() => {
    if (initialAmount) {
      setFormData({ ...initialValues, treatmentCost: initialAmount?.toString() });
    }
  }, [initialAmount]);

  const handleSubmit = async () => {
    trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_SUBMITTED`, { formData });
    setModalStatus(MODAL_STATUS.LOADING);
    await onSubmit(formData);
    setModalStatus(undefined);
    resetForm();
  };

  const handleClose = () => {
    trackSegmentEvent(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_CANCEL_BUTTON_CLICKED`);
    resetForm();
    onClose();
  };

  useEffect(() => {
    setFormData((prev: TreatmentPlan) => ({
      ...prev,
      sendEmail: preApprovalResult?.isComingFrom === 'PRE_APPROVAL' ? false : isEmailValid && !!formData.email,
    }));
  }, [formData.email]);

  useEffect(() => {
    setFormData((prev: TreatmentPlan) => ({
      ...prev,
      sendText: preApprovalResult?.isComingFrom === 'PRE_APPROVAL' ? false : isPhoneValid && !!formData.phone,
    }));
  }, [formData.phone]);

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    const value = currentTarget.value;

    if (key === 'email') {
      setIsEmailValid(!value || emailPattern?.test(value));
    }

    setFormData((prev: TreatmentPlan) => ({ ...prev, [key]: value }));
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    const name = currentTarget.name;
    const checked = currentTarget.checked;

    handleCheckboxEvent(name, checked);
    setFormData((prev: TreatmentPlan) => ({ ...prev, [name!]: checked }));
  };

  const handleCheckboxEvent = (field, value) => {
    trackSegmentEvent(
      `PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_${field === 'sendText' ? 'TEXT' : 'EMAIL'}_TOGGLE_${
        value ? 'ON' : 'OFF'
      }_CLICKED`,
    );
  };

  const onCurrencyChangeHandler = (event) => {
    const { name, value } = event?.target;

    setFormData((prev: TreatmentPlan) => ({ ...prev, [name!]: value }));
  };

  const onPhoneChange = (event) => {
    const rawPhoneValue = event.target.value;

    const parsedPhoneInput = clearMaskFormat(rawPhoneValue);

    setIsPhoneValid(!parsedPhoneInput || phonePattern?.test(rawPhoneValue));
    setFormData((prev: TreatmentPlan) => ({ ...prev, phone: parsedPhoneInput }));
  };

  const isFormValid = () => {
    if (!isEmailValid || !isPhoneValid) return false;

    return !!formData.firstName && !!formData.lastName && !!formData.phone;
  };

  const getSubmitButtonLabel = () => {
    return 'Add Patient';
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      phone: preApprovalResult?.phone,
      firstName: preApprovalResult?.firstName ?? '',
      lastName: preApprovalResult?.lastName ?? '',
    }));
  }, [preApprovalResult]);

  return (
    <ModalV2
      open={open}
      isSubmitDisabled={!isFormValid()}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      status={modalStatus}
      title={'Add Patient'}
      submitButtonLabel={getSubmitButtonLabel()}
      width={670}
      showClose={true}
      cancelButtonCol={4}
      submitButtonCol={8}
    >
      <Body>
        <p>Add a patient to your tracker Dentalll ADD</p>
      </Body>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <TextInput
              inputRef={firstNameInputRef}
              type="text"
              label="First Name"
              inputProps={{
                'data-inputkey': 'firstName',
              }}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_FIRST_NAME_SELECTED`)}
              onChange={handleChange}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_FIRST_NAME_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              value={formData?.firstName}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <TextInput
              type="text"
              label="Last Name"
              inputProps={{
                'data-inputkey': 'lastName',
              }}
              onFocus={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_MODAL_LAST_NAME_SELECTED`)}
              onBlur={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_LAST_NAME_FILLED`, {
                withInput: true,
                isFilledEvent: true,
              })}
              onChange={handleChange}
              value={formData?.lastName}
            />
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth={true} variant="outlined" margin="dense" required={true}>
        <PhoneNumberInput
          inputProps={{
            'data-testid': 'application-phone-input',
            inputMode: 'numeric',
            'data-inputkey': 'phone',
          }}
          error={!isPhoneValid}
          name="phone"
          value={formData.phone}
          onFocus={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_PHONE_SELECTED`)}
          onBlur={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_PHONE_FILLED`, {
            withInput: true,
            isFilledEvent: true,
          })}
          onChange={onPhoneChange}
          hideIcon={true}
        />
      </FormControl>

      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <EmailInput
          type="email"
          label="Email (optional)"
          inputProps={{
            'data-inputkey': 'email',
          }}
          onFocus={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_EMAIL_SELECTED`)}
          onBlur={segmentEventHandler(`PRACTICE_PORTAL.${SEGMENT_FEATURE_NAME}.MODAL_EMAIL_FILLED`, {
            withInput: true,
            isFilledEvent: true,
          })}
          onChange={handleChange}
          value={formData?.email}
          error={!isEmailValid}
          hideIcon={true}
        />
      </FormControl>
    </ModalV2>
  );
};

const Body = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

export default CreateTreatmentModal;
