import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker, defaultStaticRanges } from 'react-date-range';
import styled from 'styled-components';

import { appointmentDateRanges, cherryFoundingDate } from 'lib/utils';
import { calculateAppointmentsRangeText, calculateDateRangeFromTwoDates } from 'lib/utils/DatePickerUtil';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRange {
  startDate?: any;
  endDate?: any;
}
interface Props {
  show: boolean;
  onRangeSelected: any;
  updateTextString: any;
  toggleRangePicker: any;
  marginLeft?: string;
  marginTop?: string;
  defaultSelectRange?: DateRange;
  appointments?: boolean;
}

export const CherryRangePicker = ({
  show,
  onRangeSelected,
  updateTextString,
  toggleRangePicker,
  marginLeft,
  marginTop,
  defaultSelectRange,
  appointments,
}: Props) => {
  const [filterStartDate, setFilterStartDate] = useState<Date>(
    dayjs(defaultSelectRange?.startDate || cherryFoundingDate).toDate(),
  );
  const [filterEndDate, setFilterEndDate] = useState<Date>(
    defaultSelectRange?.endDate ? dayjs(defaultSelectRange?.endDate).toDate() : dayjs().toDate(),
  );

  const filterSelectionRange = {
    startDate: filterStartDate,
    endDate: filterEndDate,
    key: 'selection',
  };

  const rangePickerRef = useRef(null);
  const wrapperRef = useRef(null);

  const staticRanges = createStaticRanges([
    ...(appointments
      ? appointmentDateRanges
      : [
          {
            label: 'All Time',
            range: () => ({
              startDate: dayjs(cherryFoundingDate).startOf('day').toDate(),
              endDate: dayjs().endOf('day').add(1, 'day').toDate(),
            }),
          },
          {
            label: 'Last 30 Days',
            range: () => ({
              startDate: dayjs().subtract(1, 'month').toDate(),
              endDate: dayjs().endOf('day').add(1, 'day').toDate(),
            }),
          },
          {
            label: 'Year To Date',
            range: () => ({
              startDate: dayjs().startOf('year').toDate(),
              endDate: dayjs().endOf('day').add(1, 'day').toDate(),
            }),
          },
          ...defaultStaticRanges,
        ]),
  ]);
  const onDateRangeChanged = (ranges: any) => {
    setFilterStartDate(ranges.selection.startDate);
    setFilterEndDate(ranges.selection.endDate);

    if (ranges.selection.startDate !== ranges.selection.endDate) {
      onRangeSelected(ranges);
      const result = appointments ? calculateAppointmentsRangeText(ranges) : calculateDateRangeFromTwoDates(ranges);
      updateTextString(result.text);

      if (result?.specificTimeSpan === true) toggleRangePicker();
    }
  };

  const onDisableOverlay = () => {
    toggleRangePicker();
  };

  return (
    <>
      <OverlayWrapper show={show} onClick={onDisableOverlay} />
      <AbsolutePickerWrapper show={show} ref={wrapperRef} marginLeft={marginLeft} marginTop={marginTop}>
        <DateRangePicker
          ref={rangePickerRef}
          id="picker--date"
          color={'#00c37d'}
          rangeColors={['#00c37d']}
          ranges={[filterSelectionRange]}
          staticRanges={staticRanges}
          inputRanges={[]}
          maxDate={appointments ? dayjs().add(2, 'year').toDate() : new Date()}
          footerContent={null}
          onChange={onDateRangeChanged}
          fixedHeight={true}
          weekStartsOn={appointments ? 1 : 0}
        />
      </AbsolutePickerWrapper>
    </>
  );
};

interface AbsolutePickerWrapper {
  show?: boolean;
  marginLeft?: string;
  marginTop?: string;
}

const OverlayWrapper = styled.div<AbsolutePickerWrapper>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff60;
  cursor: pointer;
`;

const AbsolutePickerWrapper = styled.div<AbsolutePickerWrapper>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  z-index: 12;
  margin-top: 400px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '400px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '-310px')};
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);

  .rdrInputRanges {
    display: none;
  }
`;

interface DatePickerBottomActionsWrapper {
  align: string;
}

const DatePickerBottomActionsWrapper = styled.div<DatePickerBottomActionsWrapper>`
  position: relative;
  top: 85px;
  margin: 0 20px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  text-align: ${(props) => props.align};
`;
