import { gql } from '@apollo/client/core';

export const LOGIN_MUTATION = gql`
  mutation userLogin($input: UserLoginRequest!) {
    userLogin(input: $input) {
      token
      refreshToken
      user {
        id
        email
        firstName
        lastName
        phone
      }
      organization {
        id
        name
        active
        outcomeThreshold
      }
      success
      code
      message
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      code
      message
    }
  }
`;

export const MERCHANT_LOGIN = gql`
  mutation merchantUserLogin($input: UserMerchantLoginInput) {
    merchantUserLogin(input: $input) {
      token
      refreshToken
      user {
        id
        email
        firstName
        lastName
      }
      merchant {
        createdAt
        id
        name
      }
      organization {
        id
        name
        active
        outcomeThreshold
      }
      success
      code
      message
    }
  }
`;
export const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput) {
    refreshToken(input: $input) {
      code
      message
      success
      token
      refreshToken
    }
  }
`;
