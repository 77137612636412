import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components/mobile';

interface ExpandableWithTitleProps {
  title: string;
  expanded?: boolean;
  children: React.ReactChild;
  color?: string;
}

interface ExpandableContainerProps {
  color?: string;
}

export const ExpandableWithTitle = ({ title, expanded, children, color }: ExpandableWithTitleProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  const toggleExpandable = () => {
    !expanded && setIsExpanded(!isExpanded);
  };

  return (
    <ExpandableContainer color={color}>
      <ExpandableTitleContainer onClick={toggleExpandable}>
        <ExpandableTitle>{title}</ExpandableTitle>
        {!expanded && (
          <Icon
            src={'plus'}
            width={10}
            height={10}
            withTransformAnimation={true}
            startTransformAnimation={isExpanded}
          />
        )}
      </ExpandableTitleContainer>
      {isExpanded && <ExpandableContentContainer>{children}</ExpandableContentContainer>}
    </ExpandableContainer>
  );
};

const ExpandableContainer = styled.div<ExpandableContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: ${(props) => (props.color ? props.color : '#e6f9f2')};
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const ExpandableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ExpandableTitle = styled.p`
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #0e202f;
`;

const ExpandableContentContainer = styled.div`
  width: 100%;
`;
