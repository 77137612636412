import React, { useState } from 'react';

import { Accordion, ContentContainer, Icon, Title } from 'lib/components/mobile';

interface FaqListProps {
  faqList: any[];
  title?: string;
  titleMargin?: string;
  iconSrc?: string;
}

export const FaqListComponent = ({ faqList, title, titleMargin, iconSrc }: FaqListProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentContainer noPadding={true} noMargin={true}>
      {iconSrc && <Icon src={iconSrc} />}
      {title && <Title m={titleMargin}>{title}</Title>}
      <Accordion data={faqList} onChange={handleChange} expanded={expanded} />
    </ContentContainer>
  );
};
