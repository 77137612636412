import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Input } from '@mui/material';
import { useOnClickOutside } from 'lib/hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components/mobile';
import { Truthy } from 'lib/types';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[] | undefined;
  textColor?: string;
  fontSize?: string;
  defaultValue?: string;
  id?: string;
  customSelector?: boolean;
  onChange?: (item: any) => void;
  hoveredMode?: boolean;
  preventCloseOnClick?: Truthy;
  checkedItems?: any[] | undefined;
  placeholder?: string;
  checkedPlaceholder?: string;
  checkedAllPlaceholder?: string;
  searchable?: Truthy;
  searchablePlaceholder?: string;
  selectAllLabel?: string;
  selectAll?: Truthy;
  minWidth?: string;
  iconName?: string;
  onExpand?: () => void;
}

interface DropDownContentProps {
  show?: boolean;
  hoveredMode?: boolean;
  minWidth?: string;
}

export const DropDownWithCheckbox = React.forwardRef((props: Props, ref) => {
  const {
    options,
    textColor = '#000',
    fontSize = '',
    onChange,
    id,
    customSelector,
    hoveredMode = true,
    placeholder,
    checkedPlaceholder = 'Selected',
    checkedAllPlaceholder = '',
    checkedItems = [],
    searchable,
    searchablePlaceholder = 'Filter',
    selectAll,
    selectAllLabel,
    minWidth,
    iconName = '',
    preventCloseOnClick,
    onExpand,
  } = props;
  const [expand, setExpand] = useState<boolean>(false);

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  const clickOutsideRef: any = useRef();
  const closeDropdown = () => (expand ? setExpand(false) : '');
  useOnClickOutside(clickOutsideRef, closeDropdown);

  useEffect(() => {
    setSelectedOptions(checkedItems);
  }, [checkedItems]);

  const onDropdownClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    if (!hoveredMode) {
      if (!expand && onExpand) {
        onExpand();
      }

      setExpand((prev) => !prev);
    }
  };

  const onHoverToggle = () => {
    if (hoveredMode) setExpand(!expand);
  };

  const handleDropdownSelect = (clickedOption) => {
    const value = clickedOption.value;
    const label = clickedOption.name;
    const checked = !!selectedOptions.find((selectedOption) => selectedOption.value === value);
    const selectedObject = { value, label };
    let selectedObjects: any = [];

    if (!checked) {
      selectedObjects = [...selectedOptions, selectedObject];
      setSelectedOptions(selectedObjects);
      const filteredValues = selectedObjects.map((select) => select.value);
      onChange && onChange(filteredValues);
    } else {
      selectedObjects = selectedOptions.filter((selectedOption) => selectedOption.value !== value);
      setSelectedOptions(selectedObjects);
      const filteredValues = selectedObjects.map((select) => select.value);
      onChange && onChange(filteredValues);
    }
  };

  const preventDropdownClose = (e) => {
    if (!!preventCloseOnClick) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePlaceholderText = () => {
    if (checkedItems?.length > 1) {
      if (checkedItems?.length === options?.length && checkedAllPlaceholder) {
        return checkedAllPlaceholder;
      } else {
        return `${checkedPlaceholder} (${checkedItems.length})`;
      }
    } else if (checkedItems?.length === 1) {
      return checkedItems[0].label;
    } else {
      return placeholder || '';
    }
  };

  const onSearchHandler = (e) => {
    const value = e?.target?.value;
    setSearchValue(value);
  };

  const selectAllHandler = () => {
    if (selectedOptions?.length === options?.length) {
      setSelectedOptions([]);
      onChange && onChange([]);
    } else {
      onChange && onChange(options);
      setSelectedOptions(options);
    }
  };

  return useMemo(
    () => (
      <DropDownLi
        ref={clickOutsideRef}
        key={id}
        id={id}
        onClick={onDropdownClick}
        onMouseEnter={onHoverToggle}
        onMouseLeave={onHoverToggle}
      >
        <Dropbtn className={customSelector ? 'dropdown-left' : 'dropdown-right'}>
          <Span onClick={onDropdownClick} color={textColor} fontSize={fontSize}>
            {iconName ? <Icon src={iconName} hover={true} width={16} height={16} m="1px 6px 0px 0px" /> : null}
            {handlePlaceholderText()}
          </Span>
          {options && options?.length > 1 && <FontAwesomeIcon icon={faChevronDown} color={'#00c37d'} size="sm" />}
        </Dropbtn>
        <DropDownContent
          className={customSelector ? 'dropdown-content-left' : 'dropdown-content'}
          show={expand}
          hoveredMode={hoveredMode}
          minWidth={minWidth}
          onClick={preventDropdownClose}
        >
          {!!searchable && (
            <CustomInput name="name" type="text" onChange={onSearchHandler} placeholder={searchablePlaceholder} />
          )}
          {!!selectAll && (
            <CheckboxContainer onClick={selectAllHandler}>
              <CherryCheckbox
                name={selectAllLabel}
                value={'selectAll'}
                checked={selectedOptions?.length === options?.length}
              />
              <TextContainer>
                {selectAllLabel} ({options?.length})
              </TextContainer>
            </CheckboxContainer>
          )}
          {options &&
            options
              .filter((opt) => opt.label.toLowerCase().includes(searchValue.toLowerCase()))
              .map((option: Option, index: number) => {
                const dropOption = JSON.parse(JSON.stringify(option));

                if (dropOption.canSelect === false) {
                  return null;
                }

                return (
                  <CheckboxContainer
                    key={dropOption.value}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => handleDropdownSelect({ value: dropOption.value, name: dropOption.label })}
                  >
                    <CherryCheckbox
                      name={dropOption.label}
                      value={dropOption.value}
                      checked={!!selectedOptions.find((selectedOption) => selectedOption.value === dropOption.value)}
                    />
                    <TextContainer>{dropOption.label}</TextContainer>
                  </CheckboxContainer>
                );
              })}
        </DropDownContent>
      </DropDownLi>
    ),

    [expand, options, checkedItems, searchValue],
  );
});

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
  height: 40px;
  display: inline-flex;
  color: black;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
`;

const CherryCheckbox = styled(Checkbox)`
  color: #00c37c !important;

  &:hover {
    background-color: #00c37c15 !important;
  }
`;

const DropDownContent = styled.div<DropDownContentProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: ${({ minWidth }) => minWidth || '160px'};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 24px;
  left: 0;
  cursor: pointer;
  border-radius: 4px;
  max-height: 325px;
  overflow-x: scroll;

  &.dropdown-content-left {
    left: -100px;
  }
`;

const DropDownLi = styled(StyledLi)<DropDownContentProps>`
  display: inline-block;
  position: relative;
  width: 100%;

  ${(props) =>
    props.hoveredMode
      ? `&:hover ${DropDownContent} {
      display: block;
      cursor: pointer;
    }`
      : ''}
`;

const Span = styled.span<{ color: string; fontSize: string }>`
  margin-right: 4px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${(props) => props.fontSize};
`;

const CustomInput = styled(Input)`
  width: 100%;
  height: 40px;
  font-family: 'Open Sans';
`;

const CheckboxContainer = styled.div`
  margin-left: -9px;
  display: flex;
`;

const TextContainer = styled.div`
  padding-top: 10px;
`;
