import React from 'react';

import { FixedDecimalFormat } from 'lib/components';
import { formatPhoneNumber } from 'lib/utils';

import { InfoText, SuccessTitle, Title } from './styles';

interface Props {
  availableBalance?: number;
  borrower?: any;
  totalPurchase?: number;
}

const PatientInformation = ({ borrower, availableBalance, totalPurchase }: Props) => {
  return (
    <div>
      {totalPurchase ? (
        <SuccessTitle>
          Purchase Total: <FixedDecimalFormat amount={totalPurchase} prefix={'$'} />
        </SuccessTitle>
      ) : (
        <Title>Patient Information</Title>
      )}

      <InfoText>First Name: {borrower?.firstName}</InfoText>
      <InfoText>Last Name: {borrower?.lastName}</InfoText>
      <InfoText>Phone: {formatPhoneNumber(borrower?.phone)}</InfoText>
      {availableBalance && (
        <InfoText>
          Available Balance: <FixedDecimalFormat amount={availableBalance} prefix={'$'} />
        </InfoText>
      )}
    </div>
  );
};

export default PatientInformation;
