import { Transition } from 'history';
import { useSegment } from 'lib/hooks/useSegment';
import React, { useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext, useNavigate } from 'react-router-dom';
import { ModalV2, MODAL_STATUS } from '../ModalV2';

interface Props {
  shouldBlockNavigation: boolean;
  title?: string;
  content?: string | React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirmNavigation?: (...args: any[]) => Promise<any>;
  segmentData?: { loanId?: string; borrowerId?: number };
}

export const Prompt = ({
  shouldBlockNavigation,
  onConfirmNavigation,
  title,
  content,
  confirmText,
  cancelText,
  segmentData,
}: Props) => {
  const { navigator } = useContext<any>(UNSAFE_NavigationContext);
  const navigate = useNavigate();
  const { trackSegmentEvent } = useSegment();

  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState<MODAL_STATUS>();
  const [blockedLocation, setBlockedLocation] = useState<Transition>();

  useEffect(() => {
    if (!shouldBlockNavigation) return;

    window.onbeforeunload = () => true;

    const unblock = navigator.block((tx: Transition) => {
      if ((tx.location.state as any)?.force && tx.action !== 'POP') {
        unblock();
        tx.retry();
        return true;
      }

      setVisible(true);
      setBlockedLocation(tx);

      return false;
    });

    return () => {
      unblock();
      window.onbeforeunload = null;
    };
  }, [shouldBlockNavigation, onConfirmNavigation, navigator]);

  const handleConfirmNavigation = async () => {
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.PREVENT_NAV_MODAL_YES_CLOSE_CLICKED', segmentData);

    if (onConfirmNavigation) {
      setStatus(MODAL_STATUS.LOADING);
      await onConfirmNavigation(blockedLocation);
      setStatus(undefined);
    }
    if (blockedLocation) navigate(blockedLocation.location.pathname, { state: { force: true } });
  };

  const handleCancelNavigation = () => {
    setVisible(false);
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_WITH_PATIENT.PREVENT_NAV_MODAL_X_CLICKED', segmentData);
  };

  return (
    <ModalV2
      open={visible}
      title={title ?? 'Are you sure?'}
      submitButtonLabel={confirmText ?? 'Yes, Close'}
      cancelButtonlabel={cancelText ?? 'Back to Editing'}
      onSubmit={handleConfirmNavigation}
      onCancel={handleCancelNavigation}
      status={status}
      type="warning"
    >
      <div className="text-center">
        {content ?? 'The information you’ve entered will be lost. Are you sure you want to close this window?'}
      </div>
    </ModalV2>
  );
};

export default Prompt;
