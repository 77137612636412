import dayjs from 'dayjs';
import useStore from 'lib/hooks/useStore';
import { arrayToCsv, downloadBlobByContentType, transactionsCsvDownloadParser, TransactionsCSVHeaders } from '../utils';
import { TransactionsAnalyticsEventNames, useAnalytics } from './useAnalytics';

export const useDownloadTransaction = () => {
  const { trackEvent } = useAnalytics({ isScreen: false });
  const { merchants } = useStore();

  const downloadTransactionCSV = async (row: any) => {
    const parsedData = transactionsCsvDownloadParser(row, merchants);
    const csv = arrayToCsv([TransactionsCSVHeaders, parsedData]);
    const fileName = `cherry_transaction_${row?.loanId}_${dayjs().format('MM_DD_YYYY_HH_mm')}`;

    downloadBlobByContentType(csv, fileName, 'text/csv;charset=utf-8;');
    trackEvent({
      action: TransactionsAnalyticsEventNames.DOWNLOAD_CLICK,
    });
  };

  return { downloadTransactionCSV };
};
