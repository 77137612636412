import React from 'react';

import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const TableLoader = ({ count = 3 }) => {
  const countArray: string[] = [];
  for (let i = 0; i < count; i++) {
    countArray.push('');
  }

  return (
    <ContentLoaderWrapper>
      <rect x="0" y="17" rx="4" ry="4" width="100%" height="24" />
      {countArray.map((item, index) => (
        <Rect key={`rect-${index}`} x="0" y={24 + (index + 1) * 24} rx="3" ry="3" height="20" />
      ))}
    </ContentLoaderWrapper>
  );
};

const ContentLoaderWrapper = styled(ContentLoader)`
  width: 100%;
`;

const Rect = styled.rect`
  width: 100%;
`;
