// bu tüm gelenlerin wordinglerini GQL burası ve diğeri aynı yapmalıyız 4

export enum ACCESS_LIST {
  MANAGE_ADMINS = 'MANAGE_ADMINS',
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_BANKING_INFO = 'MANAGE_BANKING_INFO',
  MANAGE_API_KEYS = 'MANAGE_API_KEYS',
  ISSUE_REFUND = 'ISSUE_REFUND',
  VOID_TRANSACTIONS = 'VOID_TRANSACTIONS',
  DOWNLOAD_CONTRACTS = 'DOWNLOAD_CONTRACTS',
  STATEMENT_ACCESS = 'STATEMENT_ACCESS',
  DASHBOARD_ACCESS = 'DASHBOARD_ACCESS',
  // NEW
  APPLICATION_LINK = 'APPLICATION_LINK',
  PRICING_PLAN = 'PRICING_PLAN',
  BANK_INFORMATION = 'BANK_INFORMATION',
  MARKETING_ACCESS = 'MARKETING_ACCESS',
}

export const ROLE_TYPES = [
  { value: 'ADMIN', label: 'Administrator' },
  { value: 'MANAGER', label: 'Manager' },
  { value: 'SALES_MANAGER', label: 'Sales Manager' },
  { value: 'SALES', label: 'Salesperson' },
  { value: 'MARKETER', label: 'Marketer' },
  { value: 'ACCOUNTANT', label: 'Accountant' },
];

export const LOCATION_ROLE_TYPES = [
  { value: 'ADMIN', label: 'Administrator' },
  { value: 'MANAGER', label: 'Manager' },
];

export const accessList = {
  DASHBOARD: ['TEXT_APPLICATION', 'CHECKOUT_BUTTON', 'SET_APPLICATION_STATUS', 'VIEW_QUICK_STATS'],
  TRANSACTION: ['ISSUE_REFUNDS', 'VOID_TRANSACTIONS', 'DOWNLOAD'],
  MARKETING: ['ACCESS_MARKETING_MATERIALS'],
  TRAINING: ['ACCESS_TRAINING_MODULES'],
  STATEMENTS: ['NO_VIEW_AND_DOWNLOAD_STATEMENTS'],
  SETTINGS: [
    'CREATE_EDIT_DELETE_USERS',
    'VIEW_BANKING_INFORMATIN',
    'EDIT_ADD_BANK_ACCOUNTS',
    'GENERATE_API_KEYS',
    'APPLICATION_LINK',
    'PRICING_PLAN',
    'BANK_INFORMATION',
  ],
};

export const roleList: any = [
  {
    name: 'ADMIN',
    values: [
      ACCESS_LIST.MANAGE_ADMINS,
      ACCESS_LIST.MANAGE_USERS,
      ACCESS_LIST.MANAGE_BANKING_INFO,
      ACCESS_LIST.MANAGE_API_KEYS,
      ACCESS_LIST.ISSUE_REFUND,
      ACCESS_LIST.VOID_TRANSACTIONS,
      ACCESS_LIST.DOWNLOAD_CONTRACTS,
      ACCESS_LIST.STATEMENT_ACCESS,
      ACCESS_LIST.DASHBOARD_ACCESS,
      ACCESS_LIST.BANK_INFORMATION,
      ACCESS_LIST.MARKETING_ACCESS,
    ],
  },
  {
    name: 'MANAGER',
    values: [
      ACCESS_LIST.MANAGE_USERS,
      ACCESS_LIST.ISSUE_REFUND,
      ACCESS_LIST.VOID_TRANSACTIONS,
      ACCESS_LIST.DOWNLOAD_CONTRACTS,
      ACCESS_LIST.STATEMENT_ACCESS,
      ACCESS_LIST.DASHBOARD_ACCESS,
      ACCESS_LIST.MARKETING_ACCESS,
    ],
  },
  {
    name: 'SALES_MANAGER',
    values: [
      ACCESS_LIST.ISSUE_REFUND,
      ACCESS_LIST.VOID_TRANSACTIONS,
      ACCESS_LIST.DASHBOARD_ACCESS,
      ACCESS_LIST.MARKETING_ACCESS,
    ],
  },
  { name: 'SALES', values: [ACCESS_LIST.DASHBOARD_ACCESS, ACCESS_LIST.MARKETING_ACCESS] },
  { name: 'MARKETER', values: [ACCESS_LIST.MARKETING_ACCESS] },
  { name: 'ACCOUNTANT', values: [ACCESS_LIST.DOWNLOAD_CONTRACTS, ACCESS_LIST.STATEMENT_ACCESS] },
];

export const UserAccessEnums = [
  { key: ACCESS_LIST.MANAGE_ADMINS, label: 'Manage Admins' },
  { key: ACCESS_LIST.MANAGE_USERS, label: 'Manage Users' },
  { key: ACCESS_LIST.MANAGE_BANKING_INFO, label: 'Manage Banking Info' },
  { key: ACCESS_LIST.MANAGE_API_KEYS, label: 'Manage API Keys' },
  { key: ACCESS_LIST.ISSUE_REFUND, label: 'Issue Refund' },
  { key: ACCESS_LIST.VOID_TRANSACTIONS, label: 'Void Transactions' },
  { key: ACCESS_LIST.DOWNLOAD_CONTRACTS, label: 'Download Transaction' },
  { key: ACCESS_LIST.STATEMENT_ACCESS, label: 'Statements Access' },
  { key: ACCESS_LIST.DASHBOARD_ACCESS, label: 'Checkout Access' },
  { key: ACCESS_LIST.MARKETING_ACCESS, label: 'Marketing & Training' },
];
