import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { CancelLoanButton } from 'lib/components';
import { BottomButtons, Column, LoadingText, LoanActionButton, TextH5 } from './Common';

export const CheckoutAnotherInfo = ({ borrowerName, closeExpanded, cancelLoanRef, nextStep }) => {
  return (
    <Center>
      <ResultContainer>
        <TextH5>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ margin: '0px 10px' }} />
          {borrowerName} recently checked out
        </TextH5>
        <LoadingText>
          {borrowerName} was checked out less than 10 minutes ago. Are you sure you want to initiate another transaction
          for {borrowerName}?
        </LoadingText>
      </ResultContainer>
      <>
        <StyledBottomButtons>
          <Column flex={1}>
            <CancelLoanButton variant={'filled'} onClick={closeExpanded} data-tag="checkout" className="btn--cancel">
              Cancel
            </CancelLoanButton>
          </Column>
          <Column flex={2}>
            <LoanActionButton data-testid="checkout-action-button" onClick={nextStep}>
              Proceed with Checkout
            </LoanActionButton>
          </Column>
        </StyledBottomButtons>
      </>
    </Center>
  );
};

export const Center = styled.div`
  display: flex !important;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 350px;
`;

export const ResultContainer = styled.div`
  margin-top: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 320px;
  text-align: center;
`;

export const StyledBottomButtons = styled(BottomButtons)`
  margin-left: 10px;
`;
