import React from 'react';
import { SMARTY_KEY } from 'config';
import SmartySDK from 'smartystreets-javascript-sdk';
import { useDebounceCallback } from './useDebounceCallback';
import { useSentry } from './useSentry';

export interface AutoCompleteAddress {
  id: string | number;
  label: string;
  street: string;
  city: string;
  state: string | number;
  zip: string | number;
}

export interface SmartyAddress {
  city: string;
  state: string;
  zipcode: string;
  streetLine: string;
}

const useAddressAutoComplete = () => {
  const { core } = SmartySDK;
  const {
    usAutocompletePro: { Lookup },
  } = SmartySDK;
  const smartyClientKey: string = SMARTY_KEY!;
  const credentials = new core.SharedCredentials(smartyClientKey);
  const clientBuilder = new core.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
  const smartyClient = clientBuilder.buildUsAutocompleteProClient();

  const debounce = useDebounceCallback();
  const { captureException } = useSentry();

  const getAddressSuggestions = async (
    lookup: SmartySDK.usAutocompletePro.Lookup,
    callback: (data: AutoCompleteAddress[]) => void,
  ) => {
    try {
      const results = await smartyClient.send(lookup);

      const _autoCompleteData: AutoCompleteAddress[] = [];
      results?.result?.forEach((address: SmartyAddress, index: number) => {
        const { city, state, zipcode, streetLine } = address || {};
        const addressLine = `${streetLine}, ${city}, ${state}, ${zipcode}`;
        const obj = {
          id: index,
          label: addressLine,
          street: streetLine,
          city,
          state,
          zip: zipcode,
        };
        _autoCompleteData.push(obj);
      });
      callback(_autoCompleteData);
    } catch (err) {
      captureException(err, { message: 'Error fetching address suggestions' });
      callback([]);
    }
  };

  const getSuggestions = (searchTerm: string, callback: (data: AutoCompleteAddress[]) => void) => {
    if (searchTerm?.length > 0) {
      debounce(() => {
        const lookup = new Lookup(searchTerm);
        lookup.maxResults = 10;
        lookup.preferRatio = 33;

        getAddressSuggestions(lookup, callback);
      }, 250);
    } else {
      callback([]);
    }
  };

  return { getSuggestions };
};

export default useAddressAutoComplete;
