import React, { useEffect } from 'react';
import styled from 'styled-components';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import images from 'assets/images';
import { AuthLayout, Card, LinkItem, Loading, PrimaryButton } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import { useOrganizationSelection } from 'lib/services';

const OrganizationSelect = () => {
  const {
    getUserName,
    onOrganizationSelect,
    logOnClickEvent,
    selectOrganization,
    organizationId,
    organizations,
    isUserOrganizationGroupUser,
    screenLoading,
    userOrganizationGroups,
  } = useOrganizationSelection();
  const { trackIdentify, trackPage } = useSegment();

  const createOrganizationListWithOrganizationGroup = () => {
    const organizationGroupMap = userOrganizationGroups.map((orgGroup) => ({
      id: `ORG_USER-${orgGroup?.organizationGroupId}`,
      name: `${orgGroup?.organizationGroupInfo?.name} - Admin & Reporting`,
    }));

    return [...organizationGroupMap, ...organizations];
  };

  const organizationList =
    organizations && isUserOrganizationGroupUser ? createOrganizationListWithOrganizationGroup() : organizations;

  useEffect(() => {
    trackPage('organizationSelect');
  }, []);

  const onSelectOrganizationHandler = () => {
    trackIdentify();
    selectOrganization();
  };

  return (
    <AuthLayout>
      <CardWrapper>
        <CherryLogo src={images.cherryLogo.default} />
        <Title>Welcome Back {getUserName()}!</Title>
        <Subhead>
          Your account is associated with several organizations. Please select the one you would like to access in this
          session.
        </Subhead>
        {!screenLoading ? (
          <>
            <SelectWrapper>
              <SelectIcon icon={faChevronDown} color="#ccc" />
              <Select data-testid="organization" onChange={onOrganizationSelect}>
                <option disabled={true} selected={true}>
                  Select organization
                </option>
                {organizationList?.map((organization: any) => (
                  <option
                    key={`${organization.name}`}
                    value={organization.id}
                    data-organization-group-id={organization?.organizationGroupId}
                    data-organization-group-name={organization?.name}
                  >
                    {organization.name}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
            <ContinueButton disabled={!organizationId} onClick={onSelectOrganizationHandler} data-testid="submit">
              Continue
            </ContinueButton>
            <LinkItem onClick={logOnClickEvent} to="/login">
              Click Here to Login
            </LinkItem>
          </>
        ) : (
          <Loading />
        )}
      </CardWrapper>
    </AuthLayout>
  );
};

export default OrganizationSelect;

const ContinueButton = styled(PrimaryButton)`
  &:active {
    opacity: 0.7;
  }
`;

const CardWrapper = styled(Card)`
  width: 500px;
  min-height: 450px;
  max-height: calc(50%);
  align-items: center;
  justify-content: center;
`;

const CherryLogo = styled.img`
  width: 200px;
  height: auto;
`;

const Title = styled.h3`
  margin: 20px auto 5px;
`;

const Subhead = styled.p`
  font-style: italic;
  font-size: 12px;
  margin: 5px auto 20px;
  text-align: center;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Select = styled.select`
  margin-bottom: 16px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

const SelectIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 15px;
`;
