import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { useApolloClient, useMutation } from '@apollo/client';
import { Icon as CherryIcon, Switch, TextInput } from '@frontend/cherry-library';
import { Dialog } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { APP_PREQUAL_URL } from 'config';
import { ButtonPrimary, ButtonSecondary, DropDownWithCheckbox, TableLoader } from 'lib/components';
import { SEND_ESTIMATE_EMAIL, SEND_ESTIMATE_SMS } from 'lib/graphql/mutations';
import { CREATE_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { STAGE_OPTIONS, usePatientTracker } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { Row } from 'lib/styles';
import { usePatientTrackerListTable } from 'lib/tables/PatientTrackerListTable';
import { DataTableStyle } from 'lib/utils';
import { DropDownContainer } from 'pages/desktop/Transactions/CommonStyles';
import { useAlert } from 'react-alert';
import CreateTreatmentModal, {
  TreatmentPlan,
} from '../../DentalDashboard/views/DentalPatientTracker/DentalCreateTreatmentModal';
import { useNavigate } from 'react-router-dom';

export const PatientTracker = () => {
  const { organization, merchants } = useStore();
  const alert = useAlert();

  const {
    total,
    data: tableData,
    tableLoading,
    onSearchInputChange,
    searchInputRef,
    onTableSort,
    pageChange,
    rowsCountChange,
    fetchTreatments,
    selectedStages,
    onSelectedStagesChange,
    resetFilters,
    onShowArchivedToggle,
    isShowArchived,
  } = usePatientTracker({});

  const [sendEstimateEmail] = useMutation(SEND_ESTIMATE_EMAIL);
  const [sendEstimateSms] = useMutation(SEND_ESTIMATE_SMS);
  const [createTreatmentPlan] = useMutation(CREATE_TREATMENT_PLAN);

  const [isCreateTreatmentModalVisible, setIsCreateTreatmentModalVisible] = useState(false);
  const [isAddPatient, setIsAddPatient] = useState(false);

  const navigate = useNavigate();

  const handleRowClicked = () => {
    // ts-ignore
    console.log('TEST');
  };

  const handleCreateTreatmentPlanClick = () => {
    setIsAddPatient(false);
    navigate('/payment-estimator');
  };

  const handleAddPatientClick = () => {
    setIsAddPatient(true);
    setIsCreateTreatmentModalVisible(true);
  };

  const handleCloseCreateTreatmentModal = () => {
    setIsCreateTreatmentModalVisible(false);
  };

  const getPreviewParams = (paymentCalculationData: any, values: any) => ({
    paymentCalculation: paymentCalculationData.calculatePayment,
    amount: Number(values.treatmentCost),
    additionalNotes: values.consultationSummary,
    patientName: (values.firstName || values.customer.firstName) + ' ' + (values.lastName || values.customer.lastName),
    selectedLocation: {
      value: merchants?.[0].id,
      emailLabel: `${merchants?.[0]?.name} - ${merchants?.[0]?.address.city?.name} `,
    },
    planLength: 12,
    creditScore: { text: 'GREAT', value: 'GREAT' },
    treatmentCoordinator: '-',
    data: values,
  });

  const sendEmail = async (email: string, paymentCalculationData: any, values: any) => {
    try {
      const previewParams = getPreviewParams(paymentCalculationData, values);

      let applyLink = `${APP_PREQUAL_URL}/${organization?.slug}?utm_source=practice&utm_medium=email_estimate`;
      const merchantId = previewParams?.selectedLocation?.value;
      if (merchantId) {
        applyLink += '&m=' + merchantId;
      }

      const { data } = await sendEstimateEmail({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              email,
              amount: previewParams?.amount,
              merchantName: previewParams?.selectedLocation?.emailLabel,
              financialSituation: previewParams?.creditScore?.text,
              paymentAmount: previewParams?.paymentCalculation?.paymentAmount,
              term: previewParams?.planLength,
              financeCharge: previewParams?.paymentCalculation?.financeCharge,
              apr: previewParams?.paymentCalculation?.apr,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes,
              applyLink,
            },
          },
        },
      });

      if (!data?.sendEstimateEmail?.success) {
        alert.error(data?.sendEstimateEmail?.message);
      } else {
        alert.success('Sent over email successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const sendSms = async (phone: string, paymentCalculationData: any, values: any) => {
    try {
      const previewParams = getPreviewParams(paymentCalculationData, values);

      const { data } = await sendEstimateSms({
        variables: {
          input: {
            organizationId: organization?.id,
            data: {
              phone,
              merchantId: Number(previewParams?.selectedLocation?.value),
              amount: previewParams?.amount,
              creditGradeType: previewParams?.creditScore?.text,
              term: previewParams?.planLength,
              patientName: previewParams?.patientName,
              coordinatorName: previewParams?.treatmentCoordinator,
              consultationSummary: previewParams?.additionalNotes,
            },
          },
        },
      });

      if (!data?.sendEstimateSms?.success) {
        alert.error(data?.sendEstimateSms?.message);
      } else {
        alert.success('Sent over text successfully');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const createTreatmentPlanRequest = async (values: TreatmentPlan) => {
    try {
      const { data } = await createTreatmentPlan({
        variables: {
          input: {
            organizationId: organization?.id,
            customerFirstName: values.firstName,
            customerLastName: values.lastName,
            customerPhone: values.phone || undefined,
            customerEmail: values.email || undefined,
            treatmentCost: Number(values.treatmentCost),
            stage: !isAddPatient && (values.sendEmail || values.sendText) ? 'PLAN_SENT' : 'PATIENT_CREATED',
            consultationSummary: values.consultationSummary,
          },
        },
      });

      if (!data?.createTreatmentPlan?.id) {
        alert.error(isAddPatient ? 'Error when adding patient' : 'Error when creating treatment plan');
      }
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);
    }
  };

  const handleAddPatientSubmit = async (values: TreatmentPlan) => {
    try {
      await createTreatmentPlanRequest(values);
      await fetchTreatments();
    } catch (error) {
      alert.error('Something went wrong');
    } finally {
      setIsCreateTreatmentModalVisible(false);
    }
  };

  const tableColumns = usePatientTrackerListTable({ sendEmail, sendSms });

  return (
    <div>
      <PatientTrackerHeader>Patient Tracker</PatientTrackerHeader>
      <PatientTrackerCTAArea>
        <SearchWrapper>
          <TextInput
            inputSize="small"
            leftElement={<CherryIcon src={SearchIcon} />}
            onChange={onSearchInputChange}
            ref={searchInputRef}
            label="Search patient name or phone"
          />
        </SearchWrapper>
        <ButtonPrimary text="Create Treatment Plan" onClick={handleCreateTreatmentPlanClick} />
        <ButtonSecondary text="Add Patient" onClick={handleAddPatientClick} />
        <CreateTreatmentModal
          open={isCreateTreatmentModalVisible}
          onClose={handleCloseCreateTreatmentModal}
          onSubmit={handleAddPatientSubmit}
          consultationSummaryCharLimit={800}
        />
      </PatientTrackerCTAArea>
      <PatientTrackerCTAArea>
        <DropDownContainer>
          <DropDownWithCheckbox
            options={STAGE_OPTIONS}
            placeholder={'All Stages'}
            hoveredMode={false}
            textColor={'#0E202F'}
            onChange={onSelectedStagesChange}
            checkedItems={selectedStages}
            preventCloseOnClick={true}
            fontSize={'16px'}
          />
        </DropDownContainer>
        <ClearFilterButton onClick={resetFilters}>Clear Filters</ClearFilterButton>
        <Switch handleClick={onShowArchivedToggle} isChecked={isShowArchived} />
        <SwitchLabel>Show Archived</SwitchLabel>
      </PatientTrackerCTAArea>
      <TableRow>
        <TableColumn>
          <DataTable
            className="data-table"
            customStyles={DataTableStyle}
            pointerOnHover={true}
            keyField="id"
            noHeader={true}
            columns={tableColumns}
            data={tableData}
            pagination={true}
            paginationServer={true}
            progressPending={tableLoading}
            progressComponent={<TableLoader count={4} />}
            onChangePage={pageChange}
            onChangeRowsPerPage={rowsCountChange}
            paginationTotalRows={total}
            onSort={onTableSort}
            sortServer={true}
            onRowClicked={handleRowClicked}
            expandOnRowClicked={true}
            conditionalRowStyles={[
              {
                when: (row) => row.status === 'ARCHIVED',
                style: {
                  backgroundColor: 'rgba(242, 244, 245, 1)',
                },
              },
            ]}
          />
        </TableColumn>
      </TableRow>
    </div>
  );
};

const SwitchLabel = styled.span`
  font-weight: 400px;
  font-size: 14px;
  line-height: 19px;
  color: black;
  margin-left: 6px;
`;

const ClearFilterButton = styled.div`
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  width: 120px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const PatientTrackerHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 12px;
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
`;

const TableRow = styled(Row)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;

  .data-table {
    overflow: inherit;
  }
`;

interface TableColumnProps {
  flex?: number;
}

const TableColumn = styled.div<TableColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin-left: 10px;
  width: 100%;
  box-sizing: border-box;

  .rdt_TableRow > div:first-child {
    display: none !important;
  }

  .rdt_TableRow.row--active {
    opacity: 1 !important;

    > div:last-child {
      opacity: 0;
    }
  }

  .rdt_TableCell {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  nav.rdt_Pagination,
  .rdt_TableHeadRow {
    background-color: #f8f9fe;
    border: none !important;

    > div:first-child {
      display: none;
    }

    .sorted {
      color: ${(props) => props.theme.main.green};
    }
  }
  .rdt_TableRow {
    box-shadow: 0px 6px 9px rgb(0 0 0 / 7%), 0px 0.751293px 1.12694px rgb(0 0 0 / 4%);
    border-radius: 4px;
    margin: 8px 0;
    cursor: initial;
    margin-bottom: 0px;
    &:hover {
      box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
        0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
        0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
    }

    .btn--checkout {
      background-color: ${(props) => props.theme.main.green} !important;
      color: #fff !important;
      border: none !important;
    }

    .row--active {
      opacity: 1;
    }
  }

  .rdt_Table {
    background-color: unset !important;
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .rdt_ExpanderRow {
    min-height: 300px;
    box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
      0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
      0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);

    top: 1px;
    transition: all 0.3s ease;
  }
`;

const PatientTrackerCTAArea = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 12px 0px;
  position: relative;
  margin-right: 16px;
`;
