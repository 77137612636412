import { gql } from '@apollo/client/core';

export const FETCH_APPLICATION_LIST = gql`
  query fetchApplicationList($input: ApplicationListingRequest) {
    fetchApplicationList(input: $input) {
      success
      message
      code
      contents {
        activeLoanId
        balanceAvailable
        borrowerId
        borrower {
          firstName
          lastName
          phone
        }
        channel
        createdAt
        demo
        displayId
        expireAt
        id
        merchantId
        maxEligible
        menuId
        organizationId
        partner
        referralSource
        approvalExtended
        productsManuallyEdited
        purchaseAmount
        scheduleStatus
        scheduledAt
        selfCheckout
        flowType
        status
        updatedAt
        readyForCheckout
        validity
        creditLineIncrease
        activeLoan {
          merchantId
          channel
          count
          latestAt
          displayId
          purchaseAmount
          merchantRevenue
          merchantFund
          amount
          subStatus
          refunds {
            amount
          }
          status
        }
      }
      total
    }
  }
`;

export const GET_APPLICATION_LATEST_REMINDER = gql`
  query getApplicationLatestReminder($input: ApplicationInput) {
    getApplicationLatestReminder(input: $input) {
      message
    }
  }
`;

export const GET_APPLY_WITH_PATIENT_CONSENT = gql`
  query GetApplyWithPatientConsent($input: ApplyWithPatientConsentId) {
    getApplyWithPatientConsent(input: $input) {
      organizationId
      merchantId
      firstName
      lastName
      dob
      email
      phone
      last4ssn
      statedIncome
      housingPayment
      requestedAmount
      address {
        aptUnitAddress
        streetAddress
        city
        state
        zip
      }
      status
      borrowerId
      id
    }
  }
`;

export const GET_APPLY_WITH_PATIENT_CONSUME = gql`
  query GetApplyWithPatientConsume($input: ApplyWithPatientConsentId) {
    getApplyWithPatientConsume(input: $input) {
      exchangeTokenId
    }
  }
`;
