import styled from 'styled-components';

// const alertBackground = {
//   info: '#11cdef',
//   success: '#2dce89',
//   error: '#f5365c',
// };

// interface ContainerProps {
//   status: 'info' | 'success' | 'error' | undefined;
// }

export const Column = styled.div<{ margin?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  // flex-basis: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
  flex: 1;
  margin: ${(props) => (props.margin ? props.margin : 'auto 10px')};
`;

interface RowProps {
  noWrap?: boolean;
  alignItems?: string;
  fontSize?: string;
  marginBottom?: string;
  marginTop?: string;
  fontWeight?: string;
  lineHeight?: string;
  color?: string;
  justifyContent?: string;
}

interface SubHeaderProps {
  size?: string;
  marginBottom?: string;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.noWrap ? 'noWrap' : 'wrap')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'unset')};
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'unset')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'unset')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'unset')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 'unset')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 'unset')};
  color: ${(props) => (props.color ? props.color : 'initial')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'unset')};
`;

export const Container = styled.div<{ isSnackbarVisible?: boolean }>`
  margin-top: 130px;
  padding: 0 25px;
`;

// export const SubHeader = styled.h3<{ size?: string }>`
export const SubHeader = styled.h3<SubHeaderProps>`
  font-size: ${(props) => (props.size ? props.size : '20px')};
  color: #0e202f;
  margin-bottom: ${(props) => props?.marginBottom ?? props.theme.constants.l};
`;
