import { Heading, TextInput } from '@frontend/cherry-library';
import { ReactComponent as ArrowIcon } from 'assets/images/green-right.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { useOnClickOutside } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownContainer,
  Option,
  OptionHeader,
  PageWrapper,
  SearchWrapper,
  SettingBox,
  SettingBoxText,
  SettingBoxTextWrapper,
  SettingBoxTitle,
  SettingsWrapper,
  SubOption,
  SubOptionsWrapper,
} from './styles';
import { useSettingsPages } from './utils';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const { segmentEventHandler, trackSegmentEvent } = useSegment();
  const navigate = useNavigate();
  const { SETTINGS_PAGES } = useSettingsPages();
  const { t: translate } = useTranslation();

  const clickOutsideRef: any = useRef();

  const [searchInput, setSearchInput] = useState<string>('');
  const [isSearchActive, setSearchActive] = useState<boolean>(false);

  const handleSearchOnChange = (e) => {
    setSearchInput(e?.target?.value);
  };

  const handleSearchFocus = () => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_LAUNCH_PAGE.SEARCH_SELECTED`);
    setSearchActive(true);
  };

  const hideDropdown = () => {
    setSearchActive(false);
  };

  useOnClickOutside(clickOutsideRef, hideDropdown);

  const NavigatorComponent = ({ route, children, isDropdown = false }) => {
    const handleClick = () => {
      if (isDropdown) {
        trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_LAUNCH_PAGE.SEARCHABLE_DROPDOWN.CLICKED`, {
          data: { route: route },
        });
      } else {
        trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_LAUNCH_PAGE.SETTINGS_TILES.CLICKED`, {
          data: { route: route },
        });
      }
      navigate(route);
    };

    return React.cloneElement(React.Children.only(children), { onClick: handleClick });
  };

  useEffect(() => {
    trackSegmentEvent(`PRACTICE_PORTAL.SETTINGS_LAUNCH_PAGE_LOAD`);
  }, []);

  return (
    <PageWrapper>
      <Heading text={translate('settings.dashboard.title')} level="2" />
      <SearchWrapper ref={clickOutsideRef}>
        <TextInput
          fullWidth={false}
          id="settings-launch-page-search"
          name="settings-launch-page-search"
          inputProps={{
            'data-testid': 'settings-launch-page-search',
            'data-neuro-label': 'settings-launch-page-search',
          }}
          type="text"
          label={translate('settings.dashboard.searchLabel')}
          value={searchInput}
          rightElement={<SearchIcon />}
          onFocus={handleSearchFocus}
          onChange={handleSearchOnChange}
          onBlur={segmentEventHandler(`PRACTICE_PORTAL.SETTINGS_LAUNCH_PAGE.SEARCH_FILLED`, {
            data: { input: searchInput },
          })}
          error={false}
          autoComplete="off"
        />
        {isSearchActive && (
          <DropdownContainer>
            {SETTINGS_PAGES?.map((page) => {
              const subPagesVisible = page?.subPages?.some((subPage) =>
                subPage?.keywords?.some(
                  (keyword) => keyword?.includes(searchInput?.toLowerCase()) && subPage?.isAvailable,
                ),
              );
              return (
                subPagesVisible && (
                  <Option>
                    <NavigatorComponent isDropdown={true} route={page?.navigation} key={page?.title}>
                      <OptionHeader>
                        {page?.icon}
                        <span>{page?.title}</span>
                      </OptionHeader>
                    </NavigatorComponent>

                    {page?.subPages?.map((subPage) => {
                      const isVisible = subPage?.keywords?.some(
                        (keyword) => keyword?.includes(searchInput?.toLowerCase()) && subPage?.isAvailable,
                      );
                      return (
                        isVisible && (
                          <SubOptionsWrapper>
                            <NavigatorComponent isDropdown={true} route={subPage?.navigation} key={subPage?.title}>
                              <SubOption>
                                {subPage?.title} <ArrowIcon />
                              </SubOption>
                            </NavigatorComponent>
                          </SubOptionsWrapper>
                        )
                      );
                    })}
                  </Option>
                )
              );
            })}
          </DropdownContainer>
        )}
      </SearchWrapper>
      <SettingsWrapper>
        {SETTINGS_PAGES?.map((page) => {
          const subPagesVisible = page?.subPages?.some((subPage) => subPage?.isAvailable);
          return (
            subPagesVisible && (
              <NavigatorComponent route={page?.navigation} key={page?.title}>
                <SettingBox key={page?.title}>
                  {page?.icon}
                  <SettingBoxTextWrapper>
                    <SettingBoxTitle>{page?.title}</SettingBoxTitle>
                    <SettingBoxText>{page?.description}</SettingBoxText>
                  </SettingBoxTextWrapper>
                </SettingBox>
              </NavigatorComponent>
            )
          );
        })}
      </SettingsWrapper>
    </PageWrapper>
  );
};

export default SettingsPage;
