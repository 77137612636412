export const queryElements = (selector: string): HTMLElement[] => {
  const elements = document.querySelectorAll(selector);
  return Array.from(elements) as HTMLElement[];
};

export const setDisplayStyle = (elements: HTMLElement[], display: string): void => {
  elements.forEach((element) => {
    element.style.display = display;
  });
};
