interface Transaction {
  status: 'AWAITING_FUNDING' | 'FUNDED' | 'REFUNDED' | 'VOIDED';
  subStatus?: 'FAILED' | 'HOLD' | 'COMPLETED' | 'CLOSED' | 'PROCESSING' | 'AWAITING_SUBMISSION';
}

export const getDisbursement = (transaction: Transaction): string => {
  const { status, subStatus } = transaction;

  if (status === 'AWAITING_FUNDING' || (status === 'FUNDED' && (subStatus === 'FAILED' || subStatus === 'HOLD'))) {
    return 'Initiated';
  }

  if (status === 'FUNDED' && (subStatus === 'COMPLETED' || subStatus === 'CLOSED')) {
    return 'Completed';
  }

  if (status === 'REFUNDED') {
    return 'Refunded';
  }

  if (status === 'VOIDED') {
    return 'Voided';
  }

  if (status === 'FUNDED' && (subStatus === 'PROCESSING' || subStatus === 'AWAITING_SUBMISSION')) {
    return 'Processing';
  }

  return 'N/A';
};
