import { gql } from '@apollo/client/core';

export const FETCH_PARTNER_API_KEYS = gql`
  query getApiKeys($input: ApiKeyListRequest) {
    getApiKeys(input: $input) {
      data {
        createdAt
        id
        last4
        lastAccessedAt
        name
        status
        updatedAt
      }
      success
      code
      message
    }
  }
`;

export const GENERATE_PARTNER_API_KEY = gql`
  mutation generateApiKey($input: GenerateApiKeyRequest) {
    generateApiKey(input: $input) {
      success
      code
      message
      apiKey {
        id
      }
      secret
    }
  }
`;

export const PATCH_PARTNER_API_KEY = gql`
  mutation patchApiKey($input: PatchApiKeyRequest) {
    patchApiKey(input: $input) {
      success
      code
      message
    }
  }
`;

export const DELETE_PARTNER_API_KEY = gql`
  mutation deleteApiKey($input: DeleteApiKeyRequest) {
    deleteApiKey(input: $input) {
      success
      code
      message
    }
  }
`;
