import { Grid } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import client from 'lib/graphql/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GET_ORGANIZATION_ACCOUNT_INFORMATION } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { firstCharFormatter } from 'lib/utils';
import { PaymentMethod } from 'pages/desktop/Membership/type';

interface CheckoutProps {
  onPaymentChange: (key, value) => void;
  paymentMethodLoadingHandler: (status: boolean) => void;
}

const CheckoutForm = ({ onPaymentChange, paymentMethodLoadingHandler }: CheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  if (!stripe || !elements) {
    return null;
  }

  const stripeCardElement = elements.getElement(CardElement);

  // const onAddressChangeHandler = async (event) => {
  //   if (event.complete) {
  //     // @ts-ignore
  //     const { value: addressPayload } = (await stripeAddressFormValues?.getValue()) || {};
  //     const addressInfo = addressPayload;
  //     onPaymentChange('address', addressInfo);
  //   }
  // };

  const onChangeHandler = async (event: any) => {
    if (event.complete) {
      paymentMethodLoadingHandler && paymentMethodLoadingHandler(true);
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeCardElement!,
      });

      const paymentMethod = payload?.paymentMethod;

      paymentMethodLoadingHandler && paymentMethodLoadingHandler(false);
      onPaymentChange('payment', paymentMethod);
    }
  };

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
  };

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={6}>
        <form style={{ width: '96%' }} onSubmit={payMoney}>
          <CardElement
            className="card"
            onChange={onChangeHandler}
            options={{
              style: {
                base: {
                  iconColor: '#000',
                  color: '#000',
                  fontWeight: '500',
                  fontFamily: 'Open Sans, Segoe UI, sans-serif',
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
                  ':-webkit-autofill': {
                    color: '#a2a2a2',
                  },
                  '::placeholder': {
                    color: '#a2a2a2',
                  },
                },
                invalid: {
                  iconColor: '#FFC7EE',
                  color: '#FFC7EE',
                },
              },
              value: {
                postalCode: '94103',
              },
            }}
          />
          {/* <button
            className="pay-button"
            // disabled={isPaymentLoading}
          >
            Pay
          </button> */}
        </form>
      </Grid>
    </Grid>
  );
};

interface Props {
  onChangeHandler: (key, value) => void;
  paymentMethodLoadingHandler: (status: boolean) => void;
  paymentMethodInfo?: PaymentMethod | undefined;
}

export const PaymentInfoForm = ({ onChangeHandler, paymentMethodInfo, paymentMethodLoadingHandler }: Props) => {
  const { organization } = useStore();
  const [stripePromise, setStripePromise] = useState<any>(null);

  useEffect(() => {
    getOrganizationAccountInformation();
  }, []);

  const getOrganizationAccountInformation = async () => {
    const { data: { fetchOrganizationAccountInformation } = {} } = await client.query({
      query: GET_ORGANIZATION_ACCOUNT_INFORMATION,
      variables: {
        input: {
          organizationId: organization?.id,
        },
      },
    });
    if (fetchOrganizationAccountInformation?.organizationAccountId) {
      setStripePromise(
        loadStripe(fetchOrganizationAccountInformation?.cherryPublicKey, {
          stripeAccount: fetchOrganizationAccountInformation?.organizationAccountId,
        }),
      );
    }
  };

  return (
    <Container>
      <Label>Member Payment Info</Label>
      {paymentMethodInfo?.id ? (
        <PaymentMethodContainer>
          <PaymentItemText>
            Payment Type: <b>{firstCharFormatter(paymentMethodInfo?.type)}</b>
          </PaymentItemText>
          <PaymentItemText>
            Payment Method:{' '}
            <b>
              {paymentMethodInfo?.network?.toUpperCase()} ****{paymentMethodInfo?.last4}
            </b>
          </PaymentItemText>
          <PaymentItemText>
            Payment Expiration:{' '}
            <b>
              {paymentMethodInfo?.expireMonth} / {paymentMethodInfo?.expireYear}
            </b>
          </PaymentItemText>
        </PaymentMethodContainer>
      ) : null}
      <Elements stripe={stripePromise}>
        <CheckoutForm onPaymentChange={onChangeHandler} paymentMethodLoadingHandler={paymentMethodLoadingHandler} />
      </Elements>
    </Container>
  );
};

const Container = styled.div``;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const PaymentItemText = styled.span`
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 8px;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  display: block;
  margin-bottom: 16px;
`;
