import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ConfirmationDialog } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useApplicationFilter } from 'lib/services';
import { Application } from 'lib/types';
import Slider from 'pages/desktop/Dashboard/views/TakeHomeApproval/Slider';
import { TakeHomeApprovalModal } from 'pages/desktop/Dashboard/views/TakeHomeApproval/TakeHomeApprovalModal';

import { DentalCheckoutModal } from '../../DentalDashboard/views/DentalCheckoutModal';

export const TakeHomeApproval = React.memo(() => {
  const { trackSegmentEvent, applicationName } = useSegment();
  const { getReadyToCheckoutApplications, refreshTable, setSelectedModalItem } = useApplicationFilter() || {};
  const {
    user,
    sliderRefresh,
    setSliderRefresh,
    takeHomeApprovalModalOpen,
    setTakeHomeApprovalModalOpen,
    setGenerateTakeHomeConfirmationOpen,
    generateEstimateConfirmationOpen,
  } = useStore();

  const [total, setTotal] = useState<number>(0);
  const [takeHomeData, setTakeHomeData] = useState<Application[] | []>([]);

  const [selectedBalanceAvailable, setSelectedBalanceAvailable] = useState<number>(0);
  const [selectedFirstName, setSelectedFirstName] = useState<string>('');
  const [selectedLastName, setSelectedLastName] = useState<string>('');
  const [selectedPhone, setSelectedPhone] = useState<string>('');
  const [selectedApplicationId, setSelectedApplicationId] = useState<string | number>('');

  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Application | null>(null);

  const openCheckoutModal = (item: Application) => {
    setCheckoutModalOpen(true);
    setSelectedItem(item);
  };

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
    refreshTable();
    fetchReadyToCheckoutApplications?.();
  };

  const closeGenerateTakeHomeConfirmationModal = () => {
    setGenerateTakeHomeConfirmationOpen(false);
  };

  const onConfirmClose = () => {
    closeTakeHomeApprovalModal();
    closeGenerateTakeHomeConfirmationModal();
    refreshTable();
    fetchReadyToCheckoutApplications?.();
  };

  const onCloseGenerateTakeHomeModal = () => {
    setGenerateTakeHomeConfirmationOpen(true);
    setSelectedModalItem(null);
  };

  const openTakeHomeApprovalModal = () => {
    setTakeHomeApprovalModalOpen(true);
  };

  const closeTakeHomeApprovalModal = () => {
    setTakeHomeApprovalModalOpen(false);
  };

  const createTreatmentPlan = (item: Application) => {
    setSelectedModalItem(null);

    const { balanceAvailable, borrower, id, borrowerId, merchantId } = item || {};
    trackSegmentEvent('Treatment plan modal', {
      application: applicationName,
      component: 'Take Home Approval',
      borrowerId,
      merchantId,
      applicationId: id,
      userId: user?.id ? `u-${user?.id}` : undefined,
      balanceAvailable,
      targetPhone: selectedPhone,
    });

    setSelectedBalanceAvailable(balanceAvailable);
    setSelectedFirstName(borrower?.firstName);
    setSelectedLastName(borrower?.lastName);
    setSelectedPhone(borrower?.phone);
    setSelectedApplicationId(id);
    openTakeHomeApprovalModal();
  };

  const fetchReadyToCheckoutApplications = () => {
    getReadyToCheckoutApplications?.().then((data: Application[]) => {
      setSliderRefresh(false);

      const filteredData = data?.filter(
        (application) =>
          application?.activeLoan?.count === 0 ||
          (application?.activeLoan?.count > 0 &&
            application?.activeLoan?.channel === 'TREATMENT_PLAN' &&
            application?.activeLoan?.status !== 'AWAITING_FUNDING'),
      );

      setTotal(filteredData?.length || 0);
      if (filteredData?.length > 0) {
        setTakeHomeData(filteredData || []);
      }
    });
  };

  useEffect(() => {
    setTimeout(fetchReadyToCheckoutApplications, 500);
  }, [sliderRefresh]);

  if (total === 0) {
    return null;
  }

  if (takeHomeData?.length === 0) {
    return null;
  }

  return (
    <TakeHomeGrid>
      <ConfirmationDialog
        open={generateEstimateConfirmationOpen}
        onClose={closeGenerateTakeHomeConfirmationModal}
        onConfirm={onConfirmClose}
        title="Are you sure?"
        content={
          <span>
            Closing this window cannot be undone. Your progress will not be saved. Are you sure you’re ready to cancel?
          </span>
        }
        closeButtonText="Back to Treatment Plan"
        confirmButtonText="Yes, Close Editor"
      />
      <Grid
        container={true}
        id="take-home-application"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item={true} xs={5} spacing={0}>
          <ReadForCheckoutContainer>
            <GuideImage src={'/take-home.png'} />
            <div>
              <TakeHomeTitle>
                You have <b>{total}</b> patient{`${total > 1 ? 's' : ''}`} ready for checkout.
              </TakeHomeTitle>
              <TakeHomeTitleMini>
                Send them a checkout link for their total treatment <Break /> cost, or print a treatment plan for them
                to take home.
              </TakeHomeTitleMini>
            </div>
          </ReadForCheckoutContainer>
        </Grid>
        <Grid item={true} xs={7} spacing={0} style={{}}>
          <Slider
            openCheckoutModal={openCheckoutModal}
            createTreatmentPlan={createTreatmentPlan}
            items={takeHomeData}
            total={total}
          />
        </Grid>
      </Grid>

      {selectedBalanceAvailable ? (
        <TakeHomeApprovalModal
          onClose={onCloseGenerateTakeHomeModal}
          open={takeHomeApprovalModalOpen}
          balanceAvailable={selectedBalanceAvailable}
          selectedFirstName={selectedFirstName}
          selectedLastName={selectedLastName}
          selectedPhone={selectedPhone}
          selectedApplicationId={selectedApplicationId?.toString()}
        />
      ) : null}
      <DentalCheckoutModal
        selectedItemData={{
          ...selectedItem,
          firstName: selectedItem?.borrower?.firstName,
          lastName: selectedItem?.borrower?.lastName,
          availableAmount: selectedItem?.balanceAvailable,
          applicationId: selectedItem?.id,
          phone: selectedItem?.borrower?.phone,
        }}
        onSubmit={closeCheckoutModal}
        onClose={closeCheckoutModal}
        open={checkoutModalOpen}
      />
    </TakeHomeGrid>
  );
});

const TakeHomeGrid = styled.div`
  background-color: ${(props) => props.theme.main.white};
  margin-bottom: 15px;
  border-radius: 8px;
  border: 2px solid #00c37d;
  box-shadow: 0 0.75129px 1.12694px 0 rgba(0, 195, 125, 0.04), 0 6px 9px 0 rgba(0, 195, 125, 0.07);
`;

const TakeHomeTitle = styled.div`
  color: #0e202f;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;

  > b {
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 1090px) {
    font-size: 16px;

    > b {
      font-size: 18px;
    }
  }
`;

const TakeHomeTitleMini = styled(TakeHomeTitle)`
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    font-size: 13px;
    flex-direction: initial;
  }
`;

const Break = styled.br`
  @media (max-width: 1600px) {
    display: none;
  }
`;

const GuideImage = styled.img`
  width: 174px;
  height: 110px;
  margin-right: 36px;

  @media (max-width: 1300px) {
    height: auto;
    width: 120px;
    margin-right: 18px;
  }
`;

const ReadForCheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;

  @media (max-width: 1090px) {
    padding: 12px;
  }
`;
