import axios from 'config/axios';

interface LoginRequest {
  email: string;
  password: string;
  scope: string;
}

interface SendPasswordResetLinkRequest {
  email: string;
}

interface LoanCreateRequestBody {
  merchantId: number;
  applicationId: number;
  purchaseAmount: number;
  selfCheckout: boolean;
}

const Server = {
  login: (data: LoginRequest) => axios.post('/auth/login', data),
  authInfo: () => axios.get('/auth/info'),
  sendPasswordResetLink: (data: SendPasswordResetLinkRequest) => axios.post('/auth/password-reset-request', data),
  organizationMerchants: (idOrganization: number) => axios.get(`/auth/organizations/${idOrganization}/merchants`),
  organizationDetail: (idOrganization: number = 0) => axios.get(`/partners/${idOrganization}`),
  organizations: () => axios.get(`/auth/organizations`),
  getTransactions: (queryParams = '') => axios.get(`/v1/loans?${queryParams}`),
  getSaleReport: (queryParams = '') => axios.get(`/v1/reports/sale?${queryParams}`),
  getApprovalReport: (queryParams = '') => axios.get(`/v1/reports/approval?${queryParams}`),
  getSales: (queryParams = '') => axios.get(`/v1/reports/extended-sale?${queryParams}`),
  getApprovals: (idOrganization: number, queryParams = '') =>
    axios.get(`/partners/${idOrganization}/approvals?${queryParams}`),
  getAssociates: (idMerchant: number) => axios.get(`/merchants/${idMerchant}/associates`),
  addAssociate: (idMerchant: number, data: any) => axios.post(`/merchants/${idMerchant}/associates`, data),
  deleteAssociate: (idMerchant: number, idMerchantAssociate: number) =>
    axios.delete(`/merchants/${idMerchant}/associates/${idMerchantAssociate}`),
  toggleLoanScheduled: (idBorrowerApproval: number, data: any) =>
    axios.patch(`/borrower-outcomes/${idBorrowerApproval}`, data),
  getActiveContracts: (query = '') => axios.get(`/v1/applications?${query}`),
  updateApprovalStatus: (idLoan: number, data: any) => axios.patch(`/borrower-outcomes/${idLoan}`, data),
  getLoans: (query = '') => axios.get(`/loans?${query}`),
  Contracts: {
    updateContracts: (idLoan: number, data: any) => axios.post(`/applications/${idLoan}`, data),
    cancelContract: (idLoan: number, data: any) => axios.post(`/applications/${idLoan}/cancel`, data),
    confirmContract: (idLoan: number) => axios.post(`/applications/${idLoan}/confirm`, {}),
    getContractDetail: (idLoan) => axios.get(`/applications/${idLoan}`),
    processPlan: (idLoan, data) => axios.post(`/loans/${idLoan}/self-checkout`, data),
    cancelSelfCheckoutContract: (idLoan) => axios.delete(`/loans/${idLoan}/self-checkout`),
  },
  Merchants: {
    getUserMerchants: () => axios.get(`/auth/merchants`),
    merchantLogin: (data) => axios.post(`/auth/merchant-login`, data),
    sendApplicationLink: (idMerchant: number, data: any) => axios.post(`/organizations/${idMerchant}/send-sms`, data),
  },
  Loan: {
    sendOptions: (idLoan: any) => axios.post(`/loans/${idLoan}/self-checkout-options`),
  },
  FileService: {
    getFiles: (queryParams = '') => axios.get(`/v1/files?${queryParams}`),
    getFile: (fileId: number) => axios.get(`/v1/files/${fileId}`),
  },
  ApplicationService: {
    getApplicationDetail: (applicationId: number) => axios.get(`/v1/applications/${applicationId}`),
    getApplications: (query = '') => axios.get(`/v1/applications?${query}`),
    patchApplication: (applicationId: number, data: any) => axios.patch(`/v1/applications/${applicationId}`, data),
  },
  LoanService: {
    createLoan: (data: LoanCreateRequestBody) => axios.post('/v1/loans', data),
    getLoans: (queryParams = '') => axios.get(`/v1/loans?${queryParams}`),
    cancelSelfCheckoutLoan: (loanId: number, data: any) => axios.patch(`/v1/loans/${loanId}`, data),
  },
  LegacyService: {
    getMerchants: (idOrganization: number, query = '') =>
      axios.get(`/auth/organizations/${idOrganization}/merchants?${query}`),
  },
  PaymentService: {
    getLoanFund: (loanId: number) => axios.get(`/v1/loans/${loanId}/funds`),
  },
  ProductService: {
    getMenu: (menuId: number) => axios.get(`/v1/menus/${menuId}`),
  },
  PaymentEstimator: {
    getResults: (organizationId: number, queryParams: string) =>
      axios.get(`/v1/organizations/${organizationId}/payment-calculations?${queryParams}`),
  },
  SettingsAPI: {
    fetchAPI: (organizationId: number) => axios.get(`/organizations/${organizationId}/api-keys`),
    generateNewKey: (organizationId: number, data: any) =>
      axios.post(`/organizations/${organizationId}/api-keys`, data),
    patchApiKey: (organizationId: number, accessKeyId: any, data: any) =>
      axios.patch(`/organizations/${organizationId}/api-keys/${accessKeyId}`, data),
    deleteApiKey: (organizationId: number, accessKeyId: string) =>
      axios.delete(`/organizations/${organizationId}/api-keys/${accessKeyId}`),
  },
  SendMarketingMaterial: {
    postMaterials: (organizationId: any, data: any) =>
      axios.post(`/partners/${organizationId}/marketing-documents`, data),
    downloadQrCard: (organizationId: number) =>
      axios.get(`/v1/marketing-items/${organizationId}/download?documentType=QR_CARD`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }),
  },
};

export default Server;
