import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

enum BadgeText {
  bronze = 'BRONZE TIER',
  silver = 'SILVER TIER',
  gold = 'GOLD TIER',
}

export const TierBadge = ({ type, mb }: { type: string; mb?: string }) => {
  const navigate = useNavigate();

  const handleBadgeClick = () => {
    navigate('/settings/pricing-settings#pricing-tier ', {
      state: {
        autoFocusToPricingTier: true,
      },
    });
  };
  return (
    <Badge type={type} mb={mb} onClick={handleBadgeClick}>
      {BadgeText[type]}
    </Badge>
  );
};

const Badge = styled.div<{ type: string; mb?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px 5px;
  gap: 4px;
  width: 91.4px;
  height: 23px;
  background: ${(props) =>
    props.type === 'bronze'
      ? `conic-gradient(
    from 85deg at 50% 102.6%,
    #a37c6c 172deg,
    #ac836e 136deg,
    #f1ccb4 284deg,
    #f7d9c6 299deg,
    #a37c6c 356deg,
    #ac836e 221deg
  )`
      : props.type === 'silver'
      ? `conic-gradient(
    from 84.98deg at 50% 102.6%,
    #b1c2d0 172deg,
    #c8d5de 136deg,
    #fafafd 284deg,
    #f0f3f6 299deg,
    #b1c2d0 356deg,
    #c8d5de 221deg
  )`
      : `conic-gradient(
    from 84.98deg at 50% 102.6%,
    #cd8b00 172deg,
    #c48500 136deg,
    #eab829 217deg,
    #fce469 299deg,
    #cd8b00 356deg,
    #c48500 221deg
  )`};
  box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #0e202f;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};

  cursor: pointer;
`;
