import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import { SEND_APPLICATION_LINK, SEND_APPLICATION_LINK_EMAIL } from 'lib/graphql/mutations';

import { Loading, SecondaryButton, TableLoader } from 'lib/components';
import { DashboardAnalyticsEventNames, useAnalytics, useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { BusEvent, useEventBus } from 'lib/services';
import { useApplicationLinkHistory } from 'lib/services/useApplicationLinkHistory';
import { formatDateWithType, formatPhoneNumber, linkHistoryTableSettings } from 'lib/utils';

enum STATUS {
  SENT = 'SENT',
  APPLIED = 'APPLIED',
}

const STATUS_TEXTS = {
  [STATUS.SENT]: 'Sent',
  [STATUS.APPLIED]: 'Applied',
};

export enum HISTORY_TABS {
  SMS = 'phone',
  EMAIL = 'email',
}

interface Props {
  onApplicationResendSuccess: (data: string, sentType) => void;
  closeModal: () => void;
  tab: string;
}

interface ApplicationHistory {
  borrowerPhone?: string;
  borrowerEmail?: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  status: string;
  sendAt: string;
}

const ApplicationListHistoryTable = ({ onApplicationResendSuccess, closeModal, tab }: Props) => {
  const { trackEvent } = useAnalytics();
  const { organization, merchants } = useStore();
  const { trackSegmentEvent, applicationName } = useSegment();
  const [sendOrganizationLink] = useMutation(SEND_APPLICATION_LINK);
  const [sendOrganizationLinkEmail] = useMutation(SEND_APPLICATION_LINK_EMAIL);
  const { merchantLogin, merchantLogout } = useMerchantLogin();

  const alert = useAlert();
  const { emitEvent } = useEventBus();
  const { getApplicationLinkHistory, getApplicationLinkEmailHistory } = useApplicationLinkHistory();

  const [selectedTab, setSelectedTab] = useState(tab || HISTORY_TABS.SMS);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableData, setTableData] = useState<[ApplicationHistory] | null>(null);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [remindApplicationLoading, setRemindApplicationLoading] = useState('');

  const setSelectedTabAsSMS = () => {
    fetchApplicationLinkHistory();
    setSelectedTab(HISTORY_TABS.SMS);
  };

  const setSelectedTabAsEmail = () => {
    setSelectedTab(HISTORY_TABS.EMAIL);
    fetchApplicationLinkEmailHistory();
  };

  useEffect(() => {
    if (tab === HISTORY_TABS.SMS) {
      fetchApplicationLinkHistory();
    } else {
      fetchApplicationLinkEmailHistory();
    }
  }, [page, rowsPerPage]);

  const fetchApplicationLinkHistory = async () => {
    setTableDataLoading(true);
    const data = await getApplicationLinkHistory({ rowsPerPage, page, idOrganization: organization?.id });

    if (data) {
      setTotal(data.total);
      setTableData(data.contents);
      setTableDataLoading(false);
    } else {
      setTableDataLoading(false);
      setTableData(null);
    }
  };

  const fetchApplicationLinkEmailHistory = async () => {
    setTableDataLoading(true);
    const data = await getApplicationLinkEmailHistory({
      rowsPerPage,
      page,
      idOrganization: organization?.id,
    });

    if (data) {
      setTotal(data.total);
      setTableData(data.contents);
      setTableDataLoading(false);
    } else {
      setTableDataLoading(false);
      setTableData(null);
    }
  };

  const pageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const rowsCountChange = (perPage: number, pageNumber: number) => {
    setRowsPerPage(perPage);
  };

  const remindApplication = async (phoneNumber: string, index) => {
    setRemindApplicationLoading(index);
    trackSegmentEvent('Remind Patient', {
      application: applicationName,
      component: 'Text Application',
    });

    try {
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK,
      });
      const idOrganization = organization?.id || 0;
      const idMerchant = merchants?.[0]?.id || 0;

      await merchantLogin(idMerchant);

      const { data } = await sendOrganizationLink({ variables: { input: { idOrganization, phone: phoneNumber } } });

      if (data?.sendOrganizationLink?.success) {
        setRemindApplicationLoading('');
        onApplicationResendSuccess(phoneNumber, HISTORY_TABS.SMS);

        trackSegmentEvent('Reminder Success', {
          application: applicationName,
          component: 'Text Application',
        });
      } else {
        alert.error('An error occurred. Please try again.');
        setRemindApplicationLoading('');
      }
      merchantLogout();
    } catch (e) {
      merchantLogout();

      setRemindApplicationLoading('');

      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK_ERR,
      });
    }
  };

  const remindApplicationEmail = async (email: string, index) => {
    setRemindApplicationLoading(index);
    trackSegmentEvent('Remind Patient Email', {
      application: applicationName,
      component: 'Text Application',
    });

    try {
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK,
      });
      const idMerchant = merchants?.[0]?.id || 0;
      const idOrganization = organization?.id || 0;

      await merchantLogin(idMerchant);

      const { data } = await sendOrganizationLinkEmail({ variables: { input: { idOrganization, email } } });

      if (data?.sendOrganizationLinkEmail?.success) {
        setRemindApplicationLoading('');
        onApplicationResendSuccess(email, HISTORY_TABS.EMAIL);

        trackSegmentEvent('Reminder Success Email', {
          application: applicationName,
          component: 'Text Application',
        });
      } else {
        alert.error('An error occurred. Please try again.');
        setRemindApplicationLoading('');
      }

      trackSegmentEvent('Application Sent', {
        application: applicationName,
        was_success: data?.sendOrganizationLink?.success,
      });

      merchantLogout();
    } catch (e) {
      merchantLogout();
      setRemindApplicationLoading('');
      alert.error('An error occurred. Please try again.');
      trackEvent({
        action: DashboardAnalyticsEventNames.SEND_APP_LINK_ERR,
      });
    }
  };

  const tableColumns = [
    {
      name: 'Sent To',
      selector: (row) => row.borrowerPhone,
      cell: (row) => (
        <SentToRow>
          <NameWrapper>
            {row?.borrowerFirstName || row?.borrowerLastName
              ? `${row?.borrowerFirstName} ${row?.borrowerLastName}`
              : 'No Name'}
          </NameWrapper>
          <PhoneWrapper>{row?.borrowerPhone ? formatPhoneNumber(row?.borrowerPhone) : row?.borrowerEmail}</PhoneWrapper>
        </SentToRow>
      ),
      minWidth: '180px',
      maxWidth: '200px',
      ignoreRowClick: true,
    },
    {
      name: 'Sent Date',
      selector: (row) => row.sendAt,
      cell: (row) => formatDateWithType(row?.sendAt, 'MMM DD, YYYY hh:mm A'),
      minWidth: '180px',
      maxWidth: '200px',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => STATUS_TEXTS[row?.status],
      minWidth: '150px',
      maxWidth: '200px',
    },
  ];

  const getActionButton = (phoneNumber, status, index) => {
    const handleRemindButtonClick = () => {
      remindApplication(phoneNumber, index);
    };

    const handleViewButtonClick = () => {
      trackSegmentEvent('View Patient', {
        application: applicationName,
        component: 'Text Application',
      });

      emitEvent(BusEvent.DASHBOARD_SET_APPLICATIONS_TAB);
      closeModal();

      setTimeout(() => {
        emitEvent(BusEvent.APPLY_INITIAL_FILTER, phoneNumber);
      }, 1000);
    };

    let actionButton = (
      <ViewButton variant="filled" onClick={handleViewButtonClick}>
        View
      </ViewButton>
    );

    if (status === STATUS.SENT) {
      actionButton = (
        <RemindButton variant="contained" onClick={handleRemindButtonClick}>
          {remindApplicationLoading === index ? <LoadingSpinner size={10} /> : 'Remind'}
        </RemindButton>
      );
    }

    return actionButton;
  };

  const getActionButtonForEmail = (email, status, index) => {
    const handleRemindButtonClick = () => {
      remindApplicationEmail(email, index);
    };

    const actionButton =
      status === STATUS.SENT ? (
        <RemindButton variant="contained" onClick={handleRemindButtonClick}>
          {remindApplicationLoading === index ? <LoadingSpinner size={10} /> : 'Remind'}
        </RemindButton>
      ) : null;

    return actionButton;
  };

  const actionsColumn: any = {
    name: '',
    cell: (row) =>
      selectedTab === HISTORY_TABS.SMS
        ? getActionButton(row?.borrowerPhone, row?.status, row?.index)
        : getActionButtonForEmail(row?.borrowerEmail, row?.status, row?.index),
    right: true,
  };

  tableColumns.push(actionsColumn);

  const mappedTableData = tableData
    ? tableData.map((tableRow, index) => ({
        ...tableRow,
        index,
      }))
    : [];
  return (
    <Grid container={true} spacing={3}>
      <Grid container={true}>
        <ToggleContainer type={selectedTab}>
          <ToggleItem onClick={setSelectedTabAsSMS}>TEXT</ToggleItem>
          <ToggleItem onClick={setSelectedTabAsEmail}>EMAIL</ToggleItem>
        </ToggleContainer>
        <CustomDataTable
          noHeader={true}
          columns={tableColumns}
          data={mappedTableData}
          pagination={true}
          paginationServer={true}
          onChangePage={pageChange}
          onChangeRowsPerPage={rowsCountChange}
          paginationTotalRows={total}
          progressPending={tableDataLoading}
          customStyles={linkHistoryTableSettings}
          progressComponent={<TableLoader count={10} />}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationListHistoryTable;

const PhoneWrapper = styled.span`
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 8px;
  font-weight: 400;
`;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }
`;

const ViewButton = styled(SecondaryButton)`
  width: 77px;
  background-color: #00c37d;
  border: 1px solid #00c37d;
  font-weight: 600;
  &:hover,
  &:focus {
    outline: none;
    background-color: #00a569;
  }
`;

const RemindButton = styled(SecondaryButton)`
  width: 77px;
  border: 1px solid #879097;
  color: #879097;
  font-weight: 600;

  &:hover {
    background-color: #879097 !important;
    color: #ffff;
  }
`;

const SentToRow = styled.span`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.span`
  font-size: 14px;
  line-height: 19.07px;
  padding-bottom: 8px;
  font-weight: 400;
`;

const LoadingSpinner = styled(Loading)`
  margin-left: 16px;
`;

const ToggleContainer = styled.div<{ type: string | null }>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 8px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    border-bottom: 4px solid #00c37d;

    transition: left 0.2s;
    ${(props) => props.type === 'email' && 'left: 50%'}
    ${(props) => props.type === 'phone' && 'left: 0'}
  }
`;

const ToggleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 8px;
  color: #0e202f;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
`;
