import { FormControl, Grid, InputAdornment, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownWithCheckbox, Loading, TableLoader } from 'lib/components';
import { Container, ContentContainer, Icon, TextField, Title } from 'lib/components/mobile';
import { SearchIcon } from 'lib/components/mobile/SidebarIcons';
import useSnackbar from 'lib/hooks/useSnackbar';
import { useTransactions } from 'lib/services';
import { useMobileTransactionsTable } from 'lib/tables/MobileTransactionsTable';
import { MobileDataTableStyle } from 'lib/utils';
import { DropDownContainer } from 'pages/desktop/Transactions/CommonStyles';

const timeArray = [
  { label: 'All Time', value: 'allTime' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'lastWeek' },
  { label: 'Last 30 Days', value: 'lastMonth' },
  { label: 'Year to Date', value: 'thisYear' },
];

const disbursementTypes = [
  { label: 'Initiated', value: 'INITIATED' },
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Voided', value: 'VOIDED' },
  { label: 'Refunded', value: 'REFUNDED' },
];

const MobileTransactions = () => {
  const {
    transactions,
    loading,
    paginationLoading,
    searchInputOnChange,
    onDisbursementTypeSelected,
    onMobileDateDropdownChange,
    checkedItems,
    paginateTable,
    total,
  } = useTransactions();
  const { columns, handleRowClicked } = useMobileTransactionsTable();
  const { isSnackbarVisible } = useSnackbar();

  const [page, setPage] = useState(1);

  const increasePageCount = async () => {
    if (total / 20 > page) {
      await paginateTable(page + 1, 20, true);
      setPage(page + 1);
    }
  };

  return (
    <Container pageTitle="Sales & Transactions">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <FilterContainer>
          <FormControlQuery variant="standard">
            <TextField
              style={{ marginBottom: '8px' }}
              placeholder="Search by Customer Name, Phone"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={searchInputOnChange}
            />
          </FormControlQuery>
          <TimeStatusContainer>
            <SelectControl>
              <TimeDropdown
                select={true}
                onChange={onMobileDateDropdownChange}
                defaultValue={'allTime'}
                InputProps={{
                  endAdornment: <ChevronDownIcon icon={faChevronDown} color={'#00c37d'} size="sm" />,
                }}
              >
                {timeArray?.map((option: any, index: number) => (
                  <MenuItem
                    data-testid="timeItem"
                    key={`${index}-${option.value}`}
                    value={option?.value}
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TimeDropdown>
            </SelectControl>
            <StatusDropdown>
              <DropDownWithCheckbox
                options={disbursementTypes}
                placeholder={'Status'}
                hoveredMode={false}
                textColor={'#0E202F'}
                onChange={onDisbursementTypeSelected}
                checkedItems={checkedItems}
                preventCloseOnClick={true}
              />
            </StatusDropdown>
          </TimeStatusContainer>
        </FilterContainer>
        <Title>Recent Transactions</Title>
        <Grid>
          <DataTable
            className="data-table"
            customStyles={MobileDataTableStyle}
            pointerOnHover={true}
            keyField={'loanId'}
            noHeader={true}
            columns={columns}
            data={transactions}
            pagination={false}
            paginationServer={false}
            progressComponent={<TableLoader count={4} />}
            onRowClicked={handleRowClicked}
            expandOnRowClicked={true}
          />
        </Grid>
        {(loading || paginationLoading) && (
          <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Loading />
          </Grid>
        )}
        {!loading && !paginationLoading && Boolean(total) && total > transactions?.length && (
          <Grid marginTop={3} marginBottom={12} alignItems={'center'} display={'flex'} justifyContent={'center'}>
            <ShowMoreButton onClick={increasePageCount}>
              Show More <Icon src={'down-caret'} width={6} height={6} m={'0 0 0 12px'} />
            </ShowMoreButton>
          </Grid>
        )}
      </ContentContainer>
    </Container>
  );
};

export default MobileTransactions;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const FormControlQuery = styled(FormControl)`
  width: 100%;
  fieldset {
    border: 1px solid #879097;
    border-radius: 4px;
  }
`;

const StatusDropdown = styled(DropDownContainer)`
  width: 46%;
  height: 40px;
  display: flex;
  border: 1px solid #879097;
  border-radius: 4px;
  color: ${(props) => props.theme.main.midnightBlue};
  svg {
    color: ${(props) => props.theme.main.midnightBlue};
  }
  .dropdown-right {
    justify-content: center;
    align-items: center;
    height: 40px !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .dropdown-content {
    width: -webkit-fill-available;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    padding: 4px 0px 8px 16px;
    margin-top: 8px;
  }
`;

const SelectControl = styled(StatusDropdown)`
  width: 46%;
  background: ${(props) => props.theme.main.white} !important;
  border: 1px solid #879097;
  border-radius: 4px;
  height: 40px;
  > div {
    width: 100%;
    > div {
      height: 40px;
      font-weight: 600;
    }
  }
  fieldset {
    border: 0;
  }
`;

const TimeStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TimeDropdown = styled(TextField)`
  margin-right: 10px;
  text-align: center;

  .MuiSvgIcon-root {
    display: none;
  }

  .MuiSelect-select {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    padding-right: 8px !important;
    text-align: right;
  }
`;

const ShowMoreButton = styled.button`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #00c37d;
  border: none;
  background-color: transparent;
`;

const ChevronDownIcon = styled(FontAwesomeIcon)`
  padding-right: 18px;
`;
