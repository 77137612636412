import { gql } from '@apollo/client/core';

export const FETCH_TRANSACTIONS = gql`
  query getContracts($input: ActiveContractsInput) {
    getContracts(input: $input) {
      code
      message
      success
      total
      data {
        borrower {
          firstName
          lastName
          phone
        }
        createdAt
        disbursement
        fundedAt
        idLoan
        loanId
        manualApprovalAmount
        merchant {
          name
        }
        plan {
          amount
          merchantFund
        }
      }
    }
  }
`;
