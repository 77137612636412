import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';

import { ApplicationSearch } from 'lib/graphql/searches';
import { FETCH_APPLICATION_LIST } from 'lib/graphql/queries';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { searchWithKey } from 'lib/utils';
import { Application, PreApprovalStepEnums } from 'lib/types';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { applicationDefaultDateSpan } from 'lib/constants/applications';

import { Icon } from 'lib/components/mobile';
import { FixedDecimalFormat, Loading } from 'lib/components';
import { StyledContinueButton } from 'pages/desktop/Dashboard/views/PreApproval/PreApprovalSuccess';
import { DentalCheckoutModal } from 'pages/desktop/Dashboard/DentalDashboard/views/DentalCheckoutModal';

interface Props {
  onClose: () => void;
  setActiveStep: (step: PreApprovalStepEnums) => void;
  phone: string;
}

enum STATUS {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  REVIEW = 'REVIEW',
}

export const PreApprovalApplicationAlreadyExist = ({ onClose, phone, setActiveStep }: Props) => {
  const alert = useAlert();
  const client = useApolloClient();
  const { organization } = useStore();

  const [applicationData, setApplicationData] = useState<Application | null>(null);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const filter = {
    demo: organization?.isDemo,
    validity: 'ACTIVE',
    organizationId: organization?.id,
    expireAt: [
      {
        key: 'expireAt',
        value: dayjs().utc().endOf('day').toISOString(),
        operator: GenericSearchOperators.BIGGER_THAN,
      },
    ],
    createdAt: [
      {
        key: 'createdAt',
        value: dayjs(applicationDefaultDateSpan?.from).startOf('day').toDate().toISOString() || '',
        operator: GenericSearchOperators.BIGGER_THAN,
      },
      {
        key: 'createdAt',
        value: dayjs(applicationDefaultDateSpan?.to).endOf('day').toDate().toISOString() || '',
        operator: GenericSearchOperators.LOWER_THAN,
      },
    ],
    status: ['APPROVED,INCOME_VERIFICATION'],
    merchantIds: undefined,
    scheduleStatus: undefined,
  };

  const getApplicationDetailWithPhone = async () => {
    const searchKey = phone;

    const params = {
      pagination: {
        limit: 1,
        offset: 0,
        order: 'DESC',
        orderBy: 'createdAt',
      },
      search: ApplicationSearch(filter),
      ...(searchKey && { searchKey: searchWithKey(searchKey) }),
    };

    if (organization) {
      try {
        const { data } = await client.query({
          query: FETCH_APPLICATION_LIST,
          variables: {
            input: params,
          },
        });

        setLoading(false);
        return data?.fetchApplicationList || null;
      } catch (err) {
        setLoading(false);
        alert.error('Something happened, please try again later.');
      }
    }
  };

  const getApplication = async () => {
    if (phone) {
      setLoading(true);
      const data = await getApplicationDetailWithPhone();
      setApplicationData(data?.contents?.[0] ?? null);
    }
  };

  useEffect(() => {
    if (!!phone) {
      getApplication?.();
    }
  }, [phone]);

  const handleClose = () => {
    onClose();
    setActiveStep(PreApprovalStepEnums.INFORMATION);
  };

  const openCheckoutModal = () => {
    setCheckoutModalOpen(true);
  };

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
  };

  if (!applicationData) {
    return null;
  }

  const firstName = applicationData?.borrower?.firstName;
  const renderTitle = () => {
    switch (applicationData?.status) {
      case STATUS.APPROVED:
        return <Subtitle>{`${firstName} has already applied and was approved for`}</Subtitle>;
      case STATUS.DENIED:
        return <StyledSubtitle>{`${firstName} has already applied with Cherry.`}</StyledSubtitle>;
      case STATUS.REVIEW:
        return <StyledSubtitle>{`${firstName} has an application currently under review.`}</StyledSubtitle>;
      default:
        return <StyledSubtitle>{`${firstName} has an application currently in progress.`}</StyledSubtitle>;
    }
  };

  const renderContent = () => {
    switch (applicationData?.status) {
      case STATUS.APPROVED:
        return (
          <SubDescription>
            <Icon src={'info_circle_dark'} />
            {`${firstName} is ready to checkout.`}
          </SubDescription>
        );
      case STATUS.DENIED:
        const date = dayjs(applicationData?.expireAt).format('MMM DD, YYYY');
        return (
          <SubDescription>
            <Icon src={'info_circle_dark'} />
            {`${firstName} can be pre-approved or apply again after ${date}.`}
          </SubDescription>
        );
      case STATUS.REVIEW:
        return (
          <SubDescription>
            <Icon src={'info_circle_dark'} />
            {`We will reach out to ${firstName} once we have made our decision.`}
          </SubDescription>
        );
      default:
        return (
          <SubDescription>
            <Icon src={'info_circle_dark'} />
            {`Remind the patient to complete their application.`}
          </SubDescription>
        );
    }
  };

  if (loading) {
    return (
      <DenialContainer>
        <Loading fixed={true} size={40} />
      </DenialContainer>
    );
  }

  return (
    <DenialContainer>
      {renderTitle?.()}
      {applicationData?.status === STATUS.APPROVED ? (
        <DescriptionContainer>
          <Description>
            <ApprovalAmount>
              <FixedDecimalFormat decimalScale={0} amount={Number(applicationData?.balanceAvailable)} prefix="$" />
            </ApprovalAmount>
          </Description>
        </DescriptionContainer>
      ) : null}
      {renderContent?.()}

      {applicationData?.status === STATUS.APPROVED ? (
        <StyledContinueButton onClick={openCheckoutModal}>Send Checkout Link</StyledContinueButton>
      ) : (
        <StyledContinueButton onClick={handleClose}>Close</StyledContinueButton>
      )}

      <DentalCheckoutModal
        selectedItemData={{
          ...applicationData,
          firstName: applicationData?.borrower?.firstName,
          lastName: applicationData?.borrower?.lastName,
          availableAmount: applicationData?.balanceAvailable,
          applicationId: applicationData?.id,
          phone: applicationData?.borrower?.phone,
        }}
        onSubmit={closeCheckoutModal}
        onClose={closeCheckoutModal}
        open={checkoutModalOpen}
      />
    </DenialContainer>
  );
};

const Subtitle = styled.div`
  width: 95%;
  font-size: 18px;
  border-radius: 8px;
  text-align: center;
  font-weight: 700;
  margin-top: 32px;
`;

const StyledSubtitle = styled(Subtitle)`
  font-size: 16px;
  background: ${theme.main.aliceBlue};
  padding: 16px;
`;

const DenialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 150px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props?.theme?.main?.green5};
  border: 2px solid #b3edd8;
  border-radius: 8px;
  padding: 16px 0;
  margin-top: 24px;
`;

const Description = styled.div`
  padding: 0 10px;
`;

const ApprovalAmount = styled.span`
  font-size: 36px;
  font-weight: 700;
  color: #00c37d;
  margin-top: 8px;
`;

export const SubDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;
