import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonContainer, Container, ContentContainer, Icon, Subtitle, Title } from 'lib/components/mobile';
import { FETCH_LOAN_LIST } from 'lib/graphql/queries';
import { useLoanSearch } from 'lib/hooks/useLoanSearch';
import { useSegment } from 'lib/hooks/useSegment';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { getDisbursement } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';

export const MobileApplicationCheckoutFinished = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { mapTransactionToTableData } = useLoanSearch();
  const { isSnackbarVisible } = useSnackbar();
  const { applicationInProcess, setTransactionInProcess, selectedMerchant, merchants: storedMerchants } = useStore();

  const navigateToApplicationUpdate = () => {
    trackSegmentEvent('Checkout Initiated', {
      application: applicationName,
      applicationId: applicationInProcess?.applicationId,
      borrowerId: applicationInProcess?.borrowerId,
      checkoutType: 'Another Checkout',
    });
    navigate('/application/checkout');
  };
  const goBack = () => navigate('/dashboard');

  const findTransactionObject = async () => {
    // Find the applications transaction object
    try {
      const merchantIds = storedMerchants?.map((merchant) => merchant.id);
      const raw = `merchantId±{${selectedMerchant?.id || merchantIds?.join(',') || ''}}`;

      const { data: transactionData } = await client.query({
        query: FETCH_LOAN_LIST,
        variables: {
          input: {
            pagination: {
              limit: 10,
              offset: 0,
              order: 'DESC',
              orderBy: 'createdAt',
            },
            searchKey: applicationInProcess?.activeLoan.displayId,
            search: [{ key: 'raw', value: raw }],
          },
        },
      });

      if (transactionData.fetchLoanList.contents.length > 0) {
        const mappedData = mapTransactionToTableData(transactionData?.fetchLoanList?.contents);
        setTransactionInProcess(mappedData[0]);
      }
    } catch (err) {
      console.error('Find Transaction Object Error', err);
    }
  };

  const navigateToTransactionDetail = () => {
    findTransactionObject();
    setTransactionInProcess({
      ...applicationInProcess?.activeLoan,
      amount: applicationInProcess?.activeLoan?.purchaseAmount,
      servicingBalance: applicationInProcess?.activeLoan?.amount,
      borrowerName: applicationInProcess?.borrowerName,
      phone: applicationInProcess?.phone,
      createdAt: applicationInProcess?.activeLoan?.latestAt,
      disbursement: getDisbursement(applicationInProcess?.activeLoan),
      loanId: applicationInProcess?.activeLoan?.displayId,
    });
    navigate('/transactions/detail');
  };

  return (
    <Container pageTitle="Application Details" backUrl="/dashboard">
      <ContentContainer isSnackbarVisible={isSnackbarVisible}>
        <Title m={'0 0 8px 0'}>Transaction Complete</Title>
        <Subtitle m={'0 0 8px 0'}>
          {applicationInProcess?.firstName} has completed her purchase and successfully signed up for a payment plan.
          You can view this completed transaction in the sales tab.
        </Subtitle>
        <Button secondary={true} onClick={navigateToTransactionDetail}>
          View Transaction
        </Button>
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              {applicationInProcess?.firstName} has a remaining balance of{' '}
              <b>{CurrencyUtil.toCurrencyString(applicationInProcess?.balanceAvailable, true)}</b>. If they want to make
              another purchase, you can issue another plan.
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={navigateToApplicationUpdate}>Issue Another Plan</Button>
        <Button secondary={true} onClick={goBack}>
          Back
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MobileApplicationCheckoutFinished;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  margin-top: 24px;
`;
