import { useMutation } from '@apollo/client';
import { Button, CurrencyInput as CherryCurrencyInput, Dropdown, toCurrencyString } from '@frontend/cherry-library';
import { Grid } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import ModalV2, { MODAL_STATUS } from 'lib/components/ModalV2/ModalV2';
import { CREATE_CHECKOUT_WITH_PATIENT_LINK, CREATE_LOAN, SEND_CHECKOUT_EMAIL } from 'lib/graphql/mutations';
import { PATCH_TREATMENT_PLAN } from 'lib/graphql/mutations/PatientTracker';
import { useMerchantLogin } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import CurrencyUtil from 'lib/utils/currency';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CheckoutOptions, { SEND_OPTIONS } from '../CheckoutOptions/CheckoutOptions';

export interface TreatmentPlan {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  consultationSummary: string;
  treatmentCost: number | string;
  sendText: boolean;
  sendEmail: boolean;
}

interface LoanData {
  createLoan: { data: { id: string; __typename: string }; code: string; message: string; success: boolean };
}

enum PAGES {
  AMOUNT = 'AMOUNT',
  SEND_OPTIONS = 'SEND_OPTIONS',
  CHECKOUT_WITH_PATIENT = 'CHECKOUT_WITH_PATIENT',
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: TreatmentPlan) => void;
  selectedItemData: any;
  isPatientTracker?: boolean;
}

export enum SEND_OPTION_TO_CHANNEL {
  SMS = 'WPQ',
  EMAIL = 'PRACTICE_PORTAL_EMAIL',
  CHECKOUT_WITH_PATIENT = 'CHECKOUT_WITH_PATIENT',
}

export const DentalCheckoutModal = ({ open, onClose, selectedItemData, isPatientTracker = false }: Props) => {
  const { segmentEventHandler, trackSegmentEvent } = useSegment();
  const { merchants, organization } = useStore();
  const [selectedMerchantId, setSelectedMerchantId] = useState(
    (merchants ?? [])?.length > 1 ? undefined : merchants?.[0]?.id,
  );
  const navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [modalStatus, setModalStatus] = useState<MODAL_STATUS | undefined>();

  const [loading, setLoading] = useState(false);
  const [createLoan] = useMutation(CREATE_LOAN);
  const alert = useAlert();
  const { merchantLogout, merchantLogin } = useMerchantLogin();

  const [page, setPage] = useState(PAGES.AMOUNT);
  const [selectedSendOption, setSelectedSendOption] = useState(SEND_OPTIONS.SMS);
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [amountError, setAmountError] = useState('');

  const [sendCheckoutLinkWithEmail] = useMutation(SEND_CHECKOUT_EMAIL);
  const [patchTreatment] = useMutation(PATCH_TREATMENT_PLAN);
  const [createCheckoutWithPatientLink] = useMutation(CREATE_CHECKOUT_WITH_PATIENT_LINK);

  useEffect(() => {
    findPreferedLocation();
  }, [selectedItemData]);

  const findPreferedLocation = () => {
    if (merchants?.length === 1) {
      setSelectedMerchantId(merchants?.[0]?.id);
    } else {
      const merchantId =
        selectedItemData?.activeLoan?.merchantId ||
        selectedItemData?.application?.merchantId ||
        selectedItemData?.merchantId;
      const findLocation = options?.find((opt) => opt?.value === merchantId);
      if (findLocation) {
        setSelectedMerchantId(findLocation.value);
      }
    }
    setLoading(false);
  };

  const handleStartCheckoutWithPatient = async (loanId) => {
    try {
      const { data } = await createCheckoutWithPatientLink({
        variables: {
          input: {
            organizationId: organization?.id,
            loanId,
          },
        },
      });
      if (data?.createCheckoutWithPatientLink?.success) {
        navigate(`/checkout/${loanId}`, {
          state: { amount, checkoutToken: data.createCheckoutWithPatientLink.link.split('/').pop() },
        });
        return;
      } else {
        setModalStatus(MODAL_STATUS.ERROR);
        alert.error('Something went wrong');
      }
    } catch (error) {
      alert.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const setAmountPage = () => {
    setPage(PAGES.AMOUNT);
  };

  const isCheckoutDisabled = () => {
    if (selectedSendOption === SEND_OPTIONS.EMAIL) {
      return !isEmailValid;
    } else {
      return !amount;
    }
  };

  const options =
    merchants?.map((merchant) => {
      return {
        value: merchant.id,
        label: merchant.name,
      };
    }) ?? [];

  const handleSubmit = async () => {
    const { note, stage, customer } = selectedItemData;

    let checkoutMethod: string;

    switch (selectedSendOption) {
      case SEND_OPTIONS.SMS:
        checkoutMethod = 'text';
        break;

      case SEND_OPTIONS.EMAIL:
        checkoutMethod = 'email';
        break;

      case SEND_OPTIONS.CHECKOUT_WITH_PATIENT:
        checkoutMethod = 'ondevice';
        break;

      default:
        checkoutMethod = 'unknown';
        break;
    }

    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT.CHECKOUT_SUBMITTED', {
      data: { location: selectedMerchantId, amount, note, stage, patient: customer, checkoutMethod },
    });

    await createLoanFromApplication();
  };

  const handleClose = () => {
    setAmount(undefined);
    setSelectedSendOption(SEND_OPTIONS.SMS);
    setPage(PAGES.AMOUNT);
    setModalStatus(undefined);
    setEmailAddress('');
    setSelectedMerchantId('');
    onClose();
  };

  const validateAmount = (inputAmount: number) => {
    if (inputAmount && Number(inputAmount) > selectedItemData?.availableAmount) {
      alert.error(
        `Purchase amount cannot be greater than ${CurrencyUtil.toCurrencyString(
          selectedItemData?.availableAmount,
          true,
        )}`,
      );
    } else if (inputAmount && Number(inputAmount) < (selectedItemData?.application?.menu?.minPurchase || 200)) {
      alert.error(
        `Minimum purchase amount is ${CurrencyUtil.toCurrencyString(
          selectedItemData?.application?.menu?.minPurchase || 200,
          true,
        )}`,
      );
    } else {
      return true;
    }
  };

  const sendCheckoutLinkEmail = async (idLoan) => {
    try {
      const { data } = await sendCheckoutLinkWithEmail({
        variables: {
          input: {
            idOrganization: organization?.id,
            loanId: idLoan,
            email: emailAddress,
          },
        },
      });

      if (!data?.sendCheckoutEmail?.success) {
        setLoading(false);
        alert.error('Something went wrong while sending the email');
      } else {
        setLoading(false);
        setModalStatus(MODAL_STATUS.SUCCESS);
      }
    } catch {
      setLoading(false);
      alert.error('Something went wrong while sending the email');
    }
  };

  const handleCheckoutLinkOptions = (data: LoanData) => {
    if (selectedSendOption === SEND_OPTIONS.EMAIL) {
      sendCheckoutLinkEmail(data?.createLoan?.data?.id);
    } else if (selectedSendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT) {
      handleStartCheckoutWithPatient(data?.createLoan?.data?.id);
    } else {
      setLoading(false);
      setModalStatus(MODAL_STATUS.SUCCESS);
    }
  };

  const createCheckoutLink = async (data: LoanData) => {
    if (data.createLoan) {
      if (isPatientTracker && selectedItemData.treatmentCost === 0) {
        await patchTreatment({
          variables: {
            input: {
              organizationId: organization?.id,
              treatmentPlanId: selectedItemData.id,
              treatmentCost: Number(amount),
            },
          },
        });
      }
      handleCheckoutLinkOptions(data);
    } else {
      setLoading(false);
      alert.error(`An error occurred during creating the checkout link, please try again`);
      handleClose();
    }
  };

  const merchantLoginAndCreateLoan = async () => {
    setLoading(true);
    setModalStatus(MODAL_STATUS.LOADING);

    await merchantLogin(selectedMerchantId || 0);
    const { data } = await createLoan({
      variables: {
        input: {
          merchantId: selectedMerchantId,
          applicationId: selectedItemData.applicationId,
          purchaseAmount: Number(amount),
          selfCheckout: true,
          channel: SEND_OPTION_TO_CHANNEL[selectedSendOption],
        },
      },
    });

    merchantLogout();
    createCheckoutLink(data);
  };

  const createLoanFromApplication = async (e?: SyntheticEvent) => {
    try {
      if (selectedMerchantId && amount) {
        if (validateAmount(amount)) {
          merchantLoginAndCreateLoan();
        } else {
          setLoading(false);
        }
      }
    } catch (err) {
      merchantLogout();
      setLoading(false);
      alert.error(`An error occurred please try again`);
      return;
    }
  };

  const isFormValid = () => {
    return amount && !loading;
  };

  const onMerchantSelect = async (event: SelectChangeEvent<unknown>) => {
    const value = event?.target?.value as string;
    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT.MODAL_LOCATION_SELECTION_CLICKED', { data: { value } });
    setSelectedMerchantId(value);
  };

  const onAmountChange = (event) => {
    const enteredAmount = event?.target?.value;
    setAmount(enteredAmount);
    if (enteredAmount <= CurrencyUtil.MIN_ALLOWED_PURCHASE_AMOUNT) {
      setAmountError(`Minimum purchase amount is ${toCurrencyString(CurrencyUtil.MIN_ALLOWED_PURCHASE_AMOUNT)}.`);
    } else if (enteredAmount > availableAmount) {
      setAmountError(
        `Purchase amount cannot exceed ${firstName}'s available balance of ${toCurrencyString(availableAmount)}.`,
      );
    }
  };

  const getSubmitButtonLabel = () =>
    page === PAGES.AMOUNT || selectedSendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT
      ? 'Continue'
      : `Checkout for ${CurrencyUtil.toCurrencyString(amount)}`;

  const handleSubmitClick = () => {
    if (page === PAGES.AMOUNT) {
      setPage(PAGES.SEND_OPTIONS);

      const { note, stage, customer } = selectedItemData;
      trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT.MODAL_CONTINUE_CLICKED', {
        data: { location: selectedMerchantId, amount, note, stage, patient: customer },
      });
      return;
    }
    handleSubmit();
  };

  const getCustomFooter = () => {
    if (modalStatus !== undefined) {
      return;
    }

    if (page === PAGES.CHECKOUT_WITH_PATIENT) return <></>;

    const isContinueDisabled = () =>
      !amount || !selectedMerchantId || Number(amount) > availableAmount || Number(amount) < 200;

    return (
      <GridWithSpacing container={true} spacing={1}>
        <Grid item={true} xs={4}>
          <Button
            variant="secondary"
            fullWidth={true}
            onClick={
              page === PAGES.AMOUNT
                ? segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_CLOSE_MODAL_X_CLICKED', { cb: handleClose })
                : segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_BACK_BUTTON_CLICKED', { cb: setAmountPage })
            }
            data-tag="checkout"
          >
            {page === PAGES.AMOUNT ? 'Close' : 'Back'}
          </Button>
        </Grid>
        <Grid item={true} xs={8}>
          <Button
            fullWidth={true}
            data-testid="checkout-action-button"
            disabled={page === PAGES.AMOUNT ? isContinueDisabled() : isCheckoutDisabled()}
            onClick={handleSubmitClick}
          >
            {getSubmitButtonLabel()}
          </Button>
        </Grid>
      </GridWithSpacing>
    );
  };

  const firstName = selectedItemData?.firstName || selectedItemData?.customer?.firstName;
  const lastName = selectedItemData?.lastName || selectedItemData?.customer?.lastName;
  const phone = selectedItemData?.phone || selectedItemData?.customer?.phone;
  const availableAmount = selectedItemData?.availableAmount || selectedItemData?.application?.balanceAvailable;

  const successContent =
    selectedSendOption === SEND_OPTIONS.SMS
      ? `${firstName} ${lastName} has received a link on their phone. You’ll get notified once they’ve chosen a plan.`
      : `${firstName} ${lastName} has received an email at  ${emailAddress} with a link to choose their plan. You’ll be notified when they’ve completed their checkout.`;

  return (
    <ModalV2
      open={open}
      isSubmitDisabled={!isFormValid()}
      onSubmit={handleSubmit}
      onCancel={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_CLOSE_MODAL_X_CLICKED', { cb: handleClose })}
      title="Checkout"
      width={670}
      showClose={true}
      status={modalStatus}
      customFooter={getCustomFooter()}
      successContent={successContent}
      blur={selectedSendOption === SEND_OPTIONS.CHECKOUT_WITH_PATIENT}
      allowOverflow={page === PAGES.AMOUNT}
    >
      {page === PAGES.AMOUNT && (
        <>
          <BorrowerName>
            {firstName} {lastName}
          </BorrowerName>
          <AvaliableAmountText>{CurrencyUtil.toCurrencyString(availableAmount)} available</AvaliableAmountText>
        </>
      )}
      {page === PAGES.SEND_OPTIONS && (
        <>
          <Grid item={true} xs={12}>
            <AvaliableAmountText>How would you like to complete the checkout? </AvaliableAmountText>
          </Grid>
          <Grid item={true} xs={12}>
            <CheckoutOptions
              selectedOption={selectedSendOption}
              phoneNumber={phone}
              emailValidationCallback={setIsEmailValid}
              handleEmailChange={setEmailAddress}
              emailAddress={emailAddress}
              setSelectedSendOption={setSelectedSendOption}
            />
          </Grid>
        </>
      )}

      <Grid container={true}>
        {page === PAGES.AMOUNT && (
          <>
            <Grid item={true} xs={12}>
              <CherryCurrencyInput
                name="amount"
                inputProps={{
                  'data-testid': 'currency-input',
                }}
                onBlur={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_PURCHASE_AMOUNT_FILLED', {
                  withInput: true,
                  isFilledEvent: true,
                })}
                onFocus={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_PURCHASE_AMOUNT_SELECTED')}
                onChange={onAmountChange}
                defaultValue={amount}
                label="Purchase Amount (including tax)"
                error={amount && amount !== 0 && (amount < 200 || amount > availableAmount)}
                errorText={amountError}
              />
            </Grid>
            <FormItemContainer>
              <Grid item={true} xs={12}>
                <Dropdown
                  data-testid="checkout-location-dropdown"
                  options={options}
                  onChange={onMerchantSelect}
                  onClick={segmentEventHandler('PRACTICE_PORTAL.CHECKOUT.MODAL_LOCATION_DROPDOWN_SELECTED')}
                  defaultValue={selectedMerchantId}
                  value={selectedMerchantId}
                  label="Checkout Location"
                />
              </Grid>
            </FormItemContainer>
          </>
        )}
      </Grid>

      {page === PAGES.CHECKOUT_WITH_PATIENT && (
        <Grid container={true} justifyContent="center">
          <StyledIframe />
        </Grid>
      )}
    </ModalV2>
  );
};

const BorrowerName = styled.span`
  color: var(--Midnight-Blue, #0e202f);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const AvaliableAmountText = styled.span`
  color: var(--Midnight-Blue, #0e202f);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 24px;
`;

const GridWithSpacing = styled(Grid)`
  margin-top: 16px !important;
`;

const FormItemContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const StyledIframe = styled.iframe`
  width: 500px;
  height: 900px;
  border: none;
`;
