import { PhoneNumberInput, TextInput } from '@frontend/cherry-library';
import { FormControl, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DropDown } from 'lib/components';
import { US_STATES } from 'lib/constants';
import { LocationManagementStepEnums } from 'lib/types';
import { clearMaskFormat } from 'lib/utils';
import {
  BackButton,
  CherryDialogTitle,
  ContinueButton,
  DialogContainer,
  DialogContentInherit,
  SubTitle,
} from 'pages/desktop/Settings/components/Dialog';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';
import { CreateMerchant, MerchantAddress } from './type';

interface State {
  name: string;
  value: string;
}

interface OptionState {
  label: string;
  value: string;
  color: string;
}

interface Props {
  handleClose: () => void;
  onStepChange: (value: LocationManagementStepEnums) => void;
  onAddressChange: (key, value) => void;
  onChangeHandler: (key, value) => void;
  merchant: CreateMerchant;
}

export const Address = ({ handleClose, onStepChange, onAddressChange, onChangeHandler, merchant }: Props) => {
  const initialData = {
    name: '',
    street: '',
    zip: '',
    city: '',
    state: {
      code: '',
      name: '',
    },
  };

  useEffect(() => {
    const _options = US_STATES?.map((state: State) => {
      return {
        value: state.value,
        label: state.name,
        color: 'black',
        className: 'address-item',
      };
    });

    setOptions(_options);
  }, []);

  const [address, setAddress] = useState<MerchantAddress>(merchant?.address || initialData);
  const [options, setOptions] = useState<OptionState[]>([]);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  useEffect(() => {
    if (merchant?.address?.name) {
      const { locationPhone } = merchant;
      const { city, state, zip, street, name } = merchant?.address || {};

      const savedAddress = {
        name,
        street,
        city,
        state,
        zip,
        locationPhone,
      };
      setAddress(savedAddress);
      setIsPhoneValid(true);
    }
  }, []);

  const allPropertiesNotEmpty = (obj) => {
    return Object.entries(obj).every(([key, value]) => value) && isPhoneValid && obj['state']['code'];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);

    if (event?.target) {
      const key = event.target.dataset?.inputkey || event.target.name;
      const { value } = event.target || {};

      if (key === 'locationPhone') {
        const formattedPhone = clearMaskFormat(value);
        setIsPhoneValid(formattedPhone?.length === 10);
        onChangeHandler(key, value);
      } else {
        onAddressChange(key, value);
        setAddress((prevState: MerchantAddress) => {
          return { ...prevState, [key]: value };
        });
      }
    }
  };

  const handleDropdownChange = (e) => {
    onAddressChange('state', { code: e.value, name: e.label });
    setAddress((prevState: MerchantAddress) => {
      return { ...prevState, state: { code: e.value, name: e.label } };
    });
  };

  const onContinueHandler = () => {
    onStepChange(LocationManagementStepEnums.SELECT_BANK);
  };

  const onBackHandler = () => {
    onStepChange(LocationManagementStepEnums.POINT_OF_CONTACT);
  };

  return (
    <DialogContainer>
      <CherryDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Location Details
      </CherryDialogTitle>
      <SubTitle>Add location details. This is where patients will be treated.</SubTitle>
      <DialogContentInherit>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="small"
                type="text"
                label="Location Name (ex: Cherry Medspa - Austin)"
                showPlaceholderAnimation={false}
                inputProps={{
                  'data-inputkey': 'name',
                }}
                id="location-management-first-name"
                data-testid="location-management-name"
                onChange={handleChange}
                value={address?.name || merchant?.address?.name}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="small"
                type="text"
                label="Street Address"
                showPlaceholderAnimation={false}
                inputProps={{
                  'data-inputkey': 'street',
                }}
                id="location-management-address1"
                data-testid="location-management-address1"
                onChange={handleChange}
                value={address?.street || merchant?.address?.street}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="small"
                type="text"
                label="City"
                showPlaceholderAnimation={false}
                inputProps={{
                  'data-inputkey': 'city',
                }}
                id="location-management-city"
                data-testid="location-management-city"
                onChange={handleChange}
                value={address?.city || merchant?.address?.city}
              />
            </FormControl>
          </Grid>
          {options.length > 0 ? (
            <Grid item={true} xs={12}>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={options}
                  placeholder="State"
                  placeholderColor={'#DADADA'}
                  onChange={handleDropdownChange}
                  hoveredMode={false}
                  defaultValue={address?.state?.code || merchant?.address?.state?.code}
                />
              </DropDownBorder>
            </Grid>
          ) : null}
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="small"
                type="text"
                label="Zip"
                showPlaceholderAnimation={false}
                inputProps={{
                  'data-inputkey': 'zip',
                  maxLength: 6,
                  minLength: 6,
                }}
                id="location-management-zip"
                data-testid="location-management-zip"
                onChange={handleChange}
                value={address?.zip || merchant?.address?.zip}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <PhoneNumberInput
                id="location-management-phone-mask"
                data-testid="location-management-phone"
                name="locationPhone"
                onChange={handleChange}
                value={merchant?.locationPhone}
                label="Phone"
                showPlaceholderAnimation={false}
                inputProps={{
                  'data-inputkey': 'locationPhone',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContentInherit>

      <ButtonContainer>
        <BackButton onClick={onBackHandler}>Back</BackButton>
        <ButtonGap />
        <ContinueButton onClick={onContinueHandler} disabled={!allPropertiesNotEmpty(address)} data-testid="submit">
          Continue
        </ContinueButton>
      </ButtonContainer>
    </DialogContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGap = styled.div`
  width: 24px;
`;
