import { ArrowRightIcon, ButtonPrimary, DashCard, DashComponent } from 'lib/components';
import { MarketingAnalyticsEventNames, useAnalytics } from 'lib/hooks';
import { Column, Row } from 'lib/styles';
import React from 'react';
import styled from 'styled-components';

export const MarketingGuide = ({ marketingGuideItem, title }) => {
  const { trackEvent } = useAnalytics();
  const handleGuideClick = () => {
    window.open(marketingGuideItem.PDFURL.toString(), '_blank');
    trackEvent({
      action: MarketingAnalyticsEventNames.CTA_BUTTONS,
      label: 'GET_THE_GUIDE',
    });
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard transparent={'transparent'}>
        <Flex>
          <Flex>
            <GuideImage src={marketingGuideItem?.helperimage?.toString()} />
          </Flex>
          <Flex direction={true}>
            <Row style={{ fontSize: '14px', lineHeight: '19px' }}>
              Our comprehensive self-serve marketing guide includes best practices for in-office setup, putting Cherry
              on your website, using social media, email/text messaging, patient forms, and review sites.
            </Row>
            <GetGuideButton
              endIcon={<ArrowRightIcon />}
              onClick={handleGuideClick}
              text={'Get the Guide'}
              data-testid="getGuideButton"
            />
          </Flex>
        </Flex>
      </DashCard>
    </Column>
  );
};

const GuideImage = styled.img`
  width: 208px;
  height: 260px;
  box-shadow: 0 6px 9px rgb(0 0 0 / 1%);
  margin-right: 24px;
`;

const Flex = styled.div<{ direction?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? 'column' : 'row')};
`;

const GetGuideButton = styled(ButtonPrimary)`
  margin-top: 24px !important;
  width: 180px !important;
`;
