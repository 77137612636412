import dayjs from 'dayjs';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { AppointmentDropdownItemValue } from 'lib/utils';

export const FetchAppointmentListSearch = (searchKey, selectedFilters, organizationId) => {
  const getStatusSearchString = () => {
    if (selectedFilters.selectedStatus.length > 0) {
      const statusArray = selectedFilters?.selectedStatus.map((status) => {
        if (status.value === AppointmentDropdownItemValue.APPLICATION_NOT_STARTED) {
          return '( applicationId:{null} )';
        } else if (status.value === AppointmentDropdownItemValue.APPLICATION_IN_PROGRESS) {
          return `( applicationStatus!±{APPROVED,DENIED} AND applicationValidity:{ACTIVE} AND applicationExpireAt>{${dayjs()
            .utc()
            .endOf('day')
            .toISOString()}} )`;
        } else if (status.value === AppointmentDropdownItemValue.APPROVED) {
          return `( applicationStatus:{APPROVED} AND applicationValidity:{ACTIVE} AND applicationExpireAt>{${dayjs()
            .utc()
            .endOf('day')
            .toISOString()}}* )`;
        } else if (status.value === AppointmentDropdownItemValue.APPLICATION_EXPIRED) {
          return `( applicationStatus±{APPROVED,DENIED} AND applicationValidity:{ACTIVE} AND applicationExpireAt<{${dayjs()
            .utc()
            .endOf('day')
            .toISOString()}} )`;
        }
      });
      return '( ' + statusArray.join(' OR ') + ' )';
    }
  };
  const searchPhone = searchKey?.replace(/\D/g, '');
  return [
    ...(organizationId
      ? [
          {
            key: 'raw',
            value: `( organizationId:{${organizationId}} )`,
            operator: GenericSearchOperators.EQUAL,
          },
        ]
      : []),
    ...(searchKey && searchKey !== ''
      ? [
          {
            key: 'raw',
            value: `( customerFirstName_customerLastName~{${searchKey}}* OR customerPhone~{${
              searchPhone === '' ? searchKey : searchPhone
            }}* )`,
            operator: GenericSearchOperators.EQUAL,
          },
        ]
      : []),
    ...(selectedFilters?.dateFilter?.from
      ? [
          {
            key: 'startAt',
            value: dayjs(selectedFilters?.dateFilter?.from).startOf('day').toISOString() || '',
            operator: GenericSearchOperators.EQUAL_OR_GREATER_THAN,
          },
        ]
      : []),
    ...(selectedFilters?.dateFilter?.to
      ? [
          {
            key: 'endAt',
            value: dayjs(selectedFilters?.dateFilter?.to).endOf('day').toISOString() || '',
            operator: GenericSearchOperators.EQUAL_OR_LESS_THAN,
          },
        ]
      : []),
    ...(selectedFilters?.selectedLocation?.length > 0
      ? [
          {
            key: 'raw',
            value: `merchantId±{${selectedFilters.selectedLocation?.map((loc) => loc.value).join(',') || ''}}`,
          },
        ]
      : []),
    ...(selectedFilters?.selectedStatus?.length > 0
      ? [
          {
            key: 'raw',
            value: getStatusSearchString(),
          },
        ]
      : []),
  ];
};
