// tslint:disable jsx-no-lambda
import { faEye, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import images from 'assets/images';
import { API_HOST } from 'config';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { hash } from 'lib/utils';
import CurrencyUtil from 'lib/utils/currency';
import { closeSnackbar, useSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export enum EventAction {
  // TREATMENT LIST EVENTS
  APPLICATION_CREATED = 'APPLICATION_CREATED',
  APPLICATION_STATUS_CHANGED = 'APPLICATION_STATUS_CHANGED',
  ARCHIVED = 'ARCHIVED',
  CHECKOUT_READY = 'CHECKOUT_READY',
  CREATED = 'CREATED',
  CREATED_WITH_CHECKOUT_READY = 'CREATED_WITH_CHECKOUT_READY',
  FIELD_UPDATED = 'FIELD_UPDATED',
  NOTE_UPDATED = 'NOTE_UPDATED',
  SOFT_DELETED = 'SOFT_DELETED',
  STAGE_UPDATED = 'STAGE_UPDATED',
  TREATMENT_COST_UPDATED = 'TREATMENT_COST_UPDATED',

  // TRANSACTIONS EVENTS
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED',
  TRANSACTION_PROCESSING = 'TRANSACTION_PROCESSING',
  TRANSACTION_COMPLETED = 'TRANSACTION_COMPLETED',
  TRANSACTION_VOIDED = 'TRANSACTION_VOIDED',
  TRANSACTION_REFUND_PROCESSING = 'TRANSACTION_REFUND_PROCESSING',
  TRANSACTION_REFUNDED = 'TRANSACTION_REFUNDED',
  TRANSACTION_REFUND_FAILED = 'TRANSACTION_REFUND_FAILED',
  TRANSACTION_CLOSED = 'TRANSACTION_CLOSED',

  // COMMON
  CHECKOUT_COMPLETED = 'CHECKOUT_COMPLETED',
}

export enum NotificationEvent {
  REFRESH_CHECKOUT_READY_PATIENTS_LIST,
  REFRESH_ALL_OTHER_PATIENTS_LIST,
  REFRESH_TRANSACTIONS,
}

interface EventData {
  action: EventAction;
  treatmentPlanId: number;
  organizationId: number;
  eventId: string;
  data: {
    customerFirstName?: string;
    customerLastName?: string;
    customerPhone?: string;
    applicationId?: number;
    applicationStatus?: string;
    amount?: number;
    balanceAvailable?: number;
    loanId: number;
    loanPurchaseAmount: number;
    loanDisplayId: string;
    loanStatus: string;
    loanSubStatus: string;
  };
}

type CallbackFunction = (data?: any) => void;

interface NotificationCenterFunctions {
  onBaseNotification: (eventName: EventAction, cb: CallbackFunction) => number;
  onNotification: (eventName: NotificationEvent, cb: CallbackFunction) => number;
  onNotifications: (eventNames: NotificationEvent[], cb: CallbackFunction) => void;

  /**
   * Removes the event with the given id.
   */
  removeNotification: (eventId: number) => void;
}

const emptyFunction = () => 0;

const HIDE_DURATION = 10000;
const LAST_NOTIFICATION_HASH_KEY = '@lastNotificationHash';

const NotificationCenterContext = createContext<NotificationCenterFunctions>({
  onBaseNotification: emptyFunction,
  onNotification: emptyFunction,
  onNotifications: emptyFunction,
  removeNotification: emptyFunction,
});

export const NotificationCenterProvider = ({ children }) => {
  const eventSourceRef = useRef<EventSource>();
  const timeoutRef = useRef<number>();
  const firstNotificationArrived = useRef<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const { trackSegmentEvent } = useSegment();
  const { organization } = useStore();
  const [eventList, setEventList] = useState<{ cb: CallbackFunction; eventName: EventAction | NotificationEvent }[]>(
    [],
  );
  const [isUserActive, setIsUserActive] = useState(true);
  const { flags } = useFlags();
  const navigate = useNavigate();

  const onNotification = (eventName: EventAction | NotificationEvent, cb: CallbackFunction): number => {
    const index = eventList.push({ eventName, cb });
    setEventList(eventList);
    return index;
  };

  const onNotifications = (eventNames: NotificationEvent[], cb: CallbackFunction) => {
    eventNames.forEach((ev) => {
      onNotification(ev, cb);
    });
  };

  const emitNotification = (eventName: EventAction | NotificationEvent, data?: any) => {
    const events = eventList.filter((elm) => elm.eventName === eventName);
    events.forEach((elm) => elm.cb?.(data));
  };

  const removeNotification = (eventId: number) => {
    eventList[eventId].cb = () => undefined;
    setEventList(eventList);
  };

  const redirectToCheckoutSuccessPage = (data) => {
    const loanId = data?.data?.loanId;
    navigate(`/checkout/${loanId}/success`);
  };

  const emitUnifiedNotification = (event: EventData, data?: any) => {
    emitNotification(event?.action, data);

    switch (event?.action) {
      case EventAction.ARCHIVED:
      case EventAction.CHECKOUT_READY:
      case EventAction.SOFT_DELETED:
        emitNotification(NotificationEvent.REFRESH_ALL_OTHER_PATIENTS_LIST, data);
        emitNotification(NotificationEvent.REFRESH_CHECKOUT_READY_PATIENTS_LIST, data);
        break;

      case EventAction.CREATED_WITH_CHECKOUT_READY:
        emitNotification(NotificationEvent.REFRESH_CHECKOUT_READY_PATIENTS_LIST, data);
        break;

      case EventAction.CREATED:
      case EventAction.APPLICATION_CREATED:
        emitNotification(NotificationEvent.REFRESH_ALL_OTHER_PATIENTS_LIST, data);
        break;

      case EventAction.CHECKOUT_COMPLETED:
        emitNotification(NotificationEvent.REFRESH_CHECKOUT_READY_PATIENTS_LIST, data);
        emitNotification(NotificationEvent.REFRESH_TRANSACTIONS, data);
        break;

      case EventAction.TRANSACTION_INITIATED:
      case EventAction.TRANSACTION_PROCESSING:
      case EventAction.TRANSACTION_COMPLETED:
      case EventAction.TRANSACTION_VOIDED:
      case EventAction.TRANSACTION_REFUND_PROCESSING:
      case EventAction.TRANSACTION_REFUNDED:
      case EventAction.TRANSACTION_REFUND_FAILED:
      case EventAction.TRANSACTION_CLOSED:
        emitNotification(NotificationEvent.REFRESH_TRANSACTIONS, data);
        break;

      default:
        break;
    }
  };

  const contextValue = {
    onNotification,
    onNotifications,
    onBaseNotification: onNotification,
    removeNotification,
  };

  const closeConnection = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = undefined;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  const startConnection = useCallback(() => {
    if (!organization?.id) {
      firstNotificationArrived.current = false;
    }

    if (!organization?.id || !isUserActive) {
      closeConnection();
      return;
    }

    let eventSource: EventSource;

    if (eventSourceRef.current) {
      eventSource = eventSourceRef.current;
    } else {
      eventSource = new EventSource(`${API_HOST}/v1/events/organizations/${organization?.id}`);
    }

    eventSource.addEventListener('TreatmentPlanTransactionModifiedEvent', (e: any) => {
      const msgString = e?.data ?? '';
      const msgHash = hash(msgString).toString();

      // Do not read the first notification
      if (!firstNotificationArrived.current) {
        firstNotificationArrived.current = true;
        localStorage.setItem(LAST_NOTIFICATION_HASH_KEY, msgHash);
        return;
      }

      const lastNotificationHash = localStorage.getItem(LAST_NOTIFICATION_HASH_KEY);

      // Even though the user refreshes the page, prevent the same notification from being shown
      if (lastNotificationHash === msgHash) {
        return;
      }

      localStorage.setItem(LAST_NOTIFICATION_HASH_KEY, msgHash);

      const data = JSON.parse(msgString) as EventData;

      emitUnifiedNotification(data);

      switch (data?.action) {
        case EventAction.APPLICATION_CREATED:
        case EventAction.ARCHIVED:
        case EventAction.CREATED:
        case EventAction.SOFT_DELETED:
          // Do not trigger toast notification
          break;

        case EventAction.CHECKOUT_READY:
        case EventAction.CREATED_WITH_CHECKOUT_READY:
        case EventAction.CHECKOUT_COMPLETED:
          const msg =
            data?.action === EventAction.CHECKOUT_COMPLETED
              ? `You have a new completed checkout of ${CurrencyUtil.toCurrencyString(
                  data?.data?.loanPurchaseAmount,
                )} for ${data?.data?.customerFirstName} ${data?.data?.customerLastName}!`
              : `You have a new application for ${data?.data?.customerFirstName} ${data?.data?.customerLastName} that is ready to checkout!`;
          enqueueSnackbar(msg, {
            variant: 'success',
            action: (snackbarId) => (
              <>
                <SyncButton
                  onClick={() => {
                    emitUnifiedNotification(data, { buttonClicked: true });
                    closeSnackbar(snackbarId);
                    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SSE_NOTIFICATION_SYNC_CLICKED');
                  }}
                >
                  <FaIcon icon={faSync} loading={false} /> Sync Now
                </SyncButton>
                {data?.action === EventAction.CHECKOUT_COMPLETED && (
                  <ViewCheckoutButton
                    onClick={() => {
                      redirectToCheckoutSuccessPage(data);
                      closeSnackbar(snackbarId);
                      trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SSE_NOTIFICATION_SYNC_CLICKED');
                    }}
                  >
                    <FaIcon icon={faEye} loading={false} /> View
                  </ViewCheckoutButton>
                )}
                <CloseIcon
                  src={images.close.default}
                  onClick={() => {
                    closeSnackbar(snackbarId);
                    trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SSE_NOTIFICATION_DISMISS_CLICKED');
                  }}
                />
              </>
            ),
            autoHideDuration: HIDE_DURATION,
          });
          trackSegmentEvent('PRACTICE_PORTAL.CHECKOUT_READY.SSE_NOTIFICATION_VIEWED');
          break;

        default:
          console.warn(`Unknown SSE (${data?.action}) emitted`);
          break;
      }
    });

    eventSource.addEventListener('open', (e) => {
      timeoutRef.current = setTimeout(() => {
        closeConnection();
        startConnection();
      }, 55 * 1000) as unknown as number;
    });

    eventSourceRef.current = eventSource;
  }, [organization?.id, isUserActive]);

  useEffect(() => {
    startConnection();
  }, [startConnection]);

  useEffect(() => {
    const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];
    let isVisible = true; // internal flag, defaults to true

    // get the correct attribute name
    function getHiddenPropertyName(prefix) {
      return prefix ? prefix + 'Hidden' : 'hidden';
    }

    // get the correct event name
    function getVisibilityEvent(prefix) {
      return (prefix ? prefix : '') + 'visibilitychange';
    }

    // get current browser vendor prefix
    function getBrowserPrefix() {
      for (const elm of browserPrefixes) {
        if (getHiddenPropertyName(elm) in document) {
          // return vendor prefix
          return elm;
        }
      }

      // no vendor prefix needed
      return null;
    }

    // bind and handle events
    const browserPrefix = getBrowserPrefix();
    const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
    const visibilityEventName = getVisibilityEvent(browserPrefix);

    function onVisible() {
      // prevent double execution
      if (isVisible) {
        return;
      }

      // change flag value
      isVisible = true;
      setIsUserActive(true);
    }

    function onHidden() {
      // prevent double execution
      if (!isVisible) {
        return;
      }

      // change flag value
      isVisible = false;
      setIsUserActive(false);
    }

    function handleVisibilityChange(forcedFlag) {
      // forcedFlag is a boolean when this event handler is triggered by a
      // focus or blur eventotherwise it's an Event object
      if (typeof forcedFlag === 'boolean') {
        if (forcedFlag) {
          return onVisible();
        }

        return onHidden();
      }

      if (document[hiddenPropertyName]) {
        return onHidden();
      }

      return onVisible();
    }

    document.addEventListener(visibilityEventName, handleVisibilityChange, false);

    // extra event listeners for better behaviour
    document.addEventListener(
      'focus',
      () => {
        handleVisibilityChange(true);
      },
      false,
    );

    document.addEventListener(
      'blur',
      () => {
        handleVisibilityChange(false);
      },
      false,
    );

    window.addEventListener(
      'focus',
      () => {
        handleVisibilityChange(true);
      },
      false,
    );

    window.addEventListener(
      'blur',
      () => {
        handleVisibilityChange(false);
      },
      false,
    );
  }, []);

  return <NotificationCenterContext.Provider value={contextValue}>{children}</NotificationCenterContext.Provider>;
};

export const useNotificationCenter = (): NotificationCenterFunctions => {
  return useContext(NotificationCenterContext);
};

const FaIcon = styled(FontAwesomeIcon)<{ loading?: boolean; color?: string }>`
  color: #fff;
  animation-name: ${(props) => (props.loading ? 'spin' : '')};
  animation-duration: 687ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 20px;
`;

const SyncButton = styled.div`
  color: #fff;
  cursor: pointer;
  font-weight: 600;
`;

const ViewCheckoutButton = styled.div`
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  margin-left: 4px;
`;
