import images from 'assets/images';
import React from 'react';
import styled from 'styled-components';

export const PreApprovalLoading = () => {
  return (
    <>
      <Subtitle>Running Pre-Approval Check...</Subtitle>
      <Subtitle>This could take up to 30 seconds</Subtitle>
      <Container>
        <SectionIcon src={images.cherryLogoOutline.default} />
      </Container>
    </>
  );
};

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #0e202f;
  margin: 24px 0px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

const SectionIcon = styled.img`
  width: 128px;
  height: auto;
  opacity: 0;
  animation: fadeInOut 3s infinite;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }
`;
