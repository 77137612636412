import React from 'react';
import { IMaskInput } from 'react-imask';
import styled from 'styled-components';

interface AmountFieldProps {
  max?: number;
  onAccept: (nativeEvent) => void;
  placeholder?: string;
  value?: string;
  overwrite?: boolean;
}

export const AmountField = ({ max, onAccept, placeholder, value, overwrite = true }: AmountFieldProps) => {
  return (
    <Container>
      <StyledInput
        data-testid="amountInput"
        mask={'$ num'}
        // @ts-ignore
        type="text"
        inputmode="decimal"
        pattern="[0-9.,]+"
        lazy={false}
        blocks={{
          num: {
            mask: Number,
            max,
            thousandsSeparator: ',',
            radix: '.',
            scale: 2,
            padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
            normalizeZeros: false,
            mapToRadix: [','],
          },
        }}
        onAccept={onAccept}
        overwrite={overwrite}
        defaultValue={value}
      />
      {placeholder ? <Label>{placeholder}</Label> : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
`;

const StyledInput = styled(IMaskInput)<{ placeholder?: string }>`
  border: 1px ${(props) => props.theme.main.cardBorderColor} solid;
  border-radius: 5px;
  padding: ${(props) => (props?.placeholder ? '24px 8px 8px 16px' : '8px 8px 8px 16px')};
  margin-bottom: 8px;
  font-size: 16px;
  width: 100%;
  outline: 0;
  height: 22px;
`;

const Label = styled.span`
  position: absolute;
  left: 18px;
  top: 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #56636d;
`;
