import { API_HOST } from 'config';
import { Loading } from 'lib/components';
import { FeatureNames, useSentry } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { IframeWrapper, OutlinedButton } from './styles';
import { AddCardProps } from './types';

const AddCard = ({ id, onCancel, onConnect }: AddCardProps) => {
  const { features, organization } = useStore();
  const alert = useAlert();
  const { captureException } = useSentry();

  const [loading, setLoading] = useState(true);

  const hasCreditCardPaymentActive = features?.includes(FeatureNames.CREDIT_CARD_DOWN_PAYMENT);

  useEffect(() => {
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const getIframeUrl = () => {
    if (id) {
      return `${API_HOST}/v1/borrowers/${id}/payment-methods/iframe?view=merchant&demo=${organization?.isDemo}`;
    }

    return '';
  };

  const receiveMessage = ({ origin, data }) => {
    if (origin === API_HOST) {
      const iFrameEvent = JSON.parse(data);
      switch (iFrameEvent.status) {
        case 'expired':
          break;
        case 'loaded':
          setLoading(false);
          break;
        case 'loading':
          break;
        case 'success':
          const { type, network } = iFrameEvent?.paymentMethod?.storedCard || {};
          if (!hasCreditCardPaymentActive && !(type === 'DEBIT' || network === 'AMEX')) {
            alert.info('You can only use Debit or AMEX cards for down payment');
          } else {
            onConnect();
          }
          break;
        case 'error':
          alert.info(iFrameEvent.message);
          if (!iFrameEvent?.message?.includes('Unprocessable')) {
            captureException('Add Card Iframe Error', {
              iframeEventData: iFrameEvent,
              id,
            });
          }
          break;
      }
    }
  };

  return (
    <div>
      {loading && <Loading size={20} />}
      <IframeWrapper>
        <iframe
          id="add-payment-method"
          data-testid="the-frame"
          title={'Connect Bank Account'}
          width="100%"
          height="100%"
          frameBorder="0"
          src={getIframeUrl()}
          data-hj-allow-iframe=""
        />
      </IframeWrapper>
      {onCancel && !loading && <OutlinedButton onClick={onCancel}>Cancel</OutlinedButton>}
    </div>
  );
};

export default AddCard;
