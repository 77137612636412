import dayjs from 'dayjs';
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDate = (date: string | undefined) => {
  if (date) {
    const newDate = new Date(date);
    return months[newDate.getMonth()] + ' ' + newDate.getDate() + ', ' + newDate.getFullYear();
  }
};
export const formatDateWithType = (date?: string, format?: string) => {
  if (date) {
    return dayjs(date).format(format);
  }
  return '';
};

export const formatDateWithTime = (date: string) => {
  const newDate = new Date(date);
  let hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesString = minutes < 10 ? '0' + minutes : minutes;
  const time = hours + ':' + minutesString + ' ' + ampm;
  return `${formatDate(date)} ${time}`;
};

export const formatTimeFromDate = (date: string) => {
  const newDate = new Date(date);
  let hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesString = minutes < 10 ? '0' + minutes : minutes;
  const time = hours + ':' + minutesString + ' ' + ampm;
  return time;
};

export const isWithin24Hours = (date: string) => {
  if (date) {
    const pastDate = dayjs(date);
    const currentDate = dayjs();

    return currentDate.diff(pastDate, 'hour') < 24;
  }
  return false;
};
