import React from 'react';
import { useNavigate } from 'react-router-dom';

import { theme } from 'config/theme';
import { Button, ButtonContainer, Container, ContentContainer, Subtitle, Title } from 'lib/components/mobile';
import useSnackbar from 'lib/hooks/useSnackbar';
import useStore from 'lib/hooks/useStore';
import { useLoanCancel } from 'lib/services';

export const MobileApplicationCancel = () => {
  const navigate = useNavigate();
  const { applicationInProcess } = useStore();
  const { success, cancelLoan, loading } = useLoanCancel({ applicationInProcess });
  const { isSnackbarVisible } = useSnackbar();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Container pageTitle={'Application Details'}>
      {!success ? (
        <>
          <ContentContainer isSnackbarVisible={isSnackbarVisible}>
            <Title m={'0 0 8px 0'}>Are you sure you want to cancel this payment plan?</Title>
            <Subtitle m={'0 0 8px 0'}>
              {applicationInProcess?.firstName}'s approval will be reinstated, and a new payment plan can be reissued.
            </Subtitle>
          </ContentContainer>
          <ButtonContainer>
            <Button color={theme.main.red} type="submit" onClick={cancelLoan} loading={loading}>
              Yes, cancel plan
            </Button>
            <Button secondary={true} type="submit" onClick={goToDashboard}>
              Cancel
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <>
          <ContentContainer isSnackbarVisible={isSnackbarVisible}>
            <Title m={'0 0 8px 0'}>Plan Canceled</Title>
            <Subtitle m={'0 0 8px 0'}>
              {applicationInProcess?.firstName}'s plan has been canceled and approval has been reinstated. Any links
              sent previously have been deactivated.
            </Subtitle>
          </ContentContainer>
          <ButtonContainer>
            <Button type="submit" onClick={goToDashboard}>
              Okay
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default MobileApplicationCancel;
