import { browserIsMobile, initChatWidget } from 'lib/utils';
import React, { useEffect } from 'react';
import { DesktopRoutes } from './DesktopRoutes';
import { MobileRoutes } from './MobileRoutes';

const Router = () => {
  const isMobile = browserIsMobile();

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return;
    } else {
      initChatWidget();
    }
  }, []);

  return <>{isMobile ? <MobileRoutes /> : <DesktopRoutes />}</>;
};

export default Router;
