import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { useApolloClient } from '@apollo/client';
import { DashCard, DropDown, Loading, PageHeader, TableLoader } from 'lib/components';
import { SecondaryButton } from 'lib/components/Button';
import { DownloadIcon } from 'lib/components/SidebarIcons';
import { FETCH_FILES, FETCH_ORGANIZATION_GROUP_TREE } from 'lib/graphql/queries';
import { AssociatesAnalyticsEventNames, FeatureNames, ScreenNames, useAnalytics } from 'lib/hooks';
import { usePermission } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { Column } from 'lib/styles';
import { DropdownOption } from 'lib/types';
import { formatDate, useFileDownload } from 'lib/utils';
import { DropDownBorder } from '../Settings/components/UserManagement/AssignRole';

const columns = [
  {
    name: 'Date',
    selector: (row) => row.date,
    width: '160px',
  },
  {
    name: 'Document Name',
    selector: (row) => row.name,
  },
  {
    name: '',
    selector: (row) => row.csvDownload,
    width: '155px',
  },
  {
    name: '',
    selector: (row) => row.download,
    width: '155px',
  },
];

const Statements = () => {
  const { trackEvent } = useAnalytics({ isScreen: true });
  const permission = usePermission();
  const client = useApolloClient();
  const { downloadFile } = useFileDownload();
  const { organization, isAdminReporting } = useStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [screenLoading, setScreenLoading] = useState(true);
  const [statements, setStatements] = useState<any[]>([]);
  const [organizationIds, setOrganizationIds] = useState<DropdownOption[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<DropdownOption>({
    value: String(organization?.id || 0),
    label: organization?.name || '',
  });

  const organizationId = organization?.id || 0;

  const getFiles = async (page = 0, perPage = 9999, selectedOrganizationId = selectedOrganization?.value) => {
    setLoading(true);
    const {
      data: { getFileList },
    } = await client.query({
      query: FETCH_FILES,
      variables: {
        input: {
          pagination: {
            offset: page,
            limit: perPage,
          },
          search: [
            {
              key: 'relationType',
              value: 'ORGANIZATION',
            },
            {
              key: 'type',
              value: 'STATEMENT',
            },
            {
              key: 'relationId',
              value: String(selectedOrganizationId || organizationId),
            },
          ],
        },
      },
    });

    return getFileList;
  };

  const fetchOrganizationGroupTree = async () => {
    const input = {
      [`${organization?.groupId ? 'organizationGroupId' : 'organizationId'}`]: organization?.groupId
        ? organization?.groupId
        : organization?.id,
    };
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_GROUP_TREE,
      variables: {
        input,
      },
    });

    const organizationGroupTreeStructure = data?.fetchOrganizationGroupTree;
    const [organizationIdList] = findNodeAndReturnArrays(
      organizationGroupTreeStructure,
      organizationGroupTreeStructure?.id,
    );
    setSelectedOrganization(organizationIdList[0]);
    setOrganizationIds(organizationIdList);
    setScreenLoading(false);
  };

  useEffect(() => {
    getFiles()
      .then((res) => {
        const statementData = res.contents;
        if (statementData?.length > 0) {
          const mappedData = mapStatementToTableData(statementData);
          setStatements(mappedData);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [organizationId]);

  useEffect(() => {
    if (isAdminReporting && organizationIds && selectedOrganization?.value) {
      getFiles(0, 9999, selectedOrganization?.value)
        .then((res) => {
          const statementData = res.contents;
          if (statementData?.length > 0) {
            const mappedData = mapStatementToTableData(statementData);
            setStatements(mappedData);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    fetchOrganizationGroupTree();
  }, []);

  const mapStatementToTableData = (data) => {
    const statementMap: any[] = [];

    data.map((statement: any) => {
      const downloadWithIcon = () => {
        downloadFile(statement?.id);
        trackEvent({
          action: AssociatesAnalyticsEventNames.DOWN_LINK,
        });
      };

      const downloadCsvWithIcon = () => {
        downloadFile(statement.csvId);
        trackEvent({
          action: AssociatesAnalyticsEventNames.DOWN_LINK,
        });
      };

      statementMap.push({
        date: formatDate(statement.createdAt),
        name: <div>{statement.name}</div>,
        download: (
          <CustomSecondaryButton variant={'contained'} onClick={downloadWithIcon} data-testid="downloadButton">
            <DownloadIcon color={'#00c37c'} /> &nbsp;Download PDF{' '}
          </CustomSecondaryButton>
        ),
        csvDownload: statement.csvId && (
          <CustomSecondaryButton variant={'contained'} onClick={downloadCsvWithIcon} data-testid="downloadButton">
            <DownloadIcon color={'#00c37c'} /> &nbsp;Download CSV{' '}
          </CustomSecondaryButton>
        ),
      });
    });

    return statementMap;
  };

  interface NodeType {
    id: string;
    type: any;
    name: string;
    childs?: NodeType[];
  }

  function findNodeAndReturnArrays(root: NodeType, id: string): [DropdownOption[]] {
    const organizationIdList: DropdownOption[] = [];

    function traverseNode(node): boolean {
      if (node?.id === id) {
        collectChilds(node);
        return true;
      }
      for (const child of node?.childs || []) {
        if (traverseNode(child)) return true;
      }
      return false;
    }

    function collectChilds(node: NodeType) {
      for (const child of node?.childs || []) {
        if (child?.type === 'ORGANIZATION') organizationIdList.push({ value: child?.id, label: child?.name });
        collectChilds(child);
      }
    }

    traverseNode(root);
    return [organizationIdList];
  }

  const organizationGroupSelectionHandler = (data) => {
    setSelectedOrganization(data);
  };

  return (
    <Container>
      <PageHeader title="Monthly Statements" />
      {screenLoading ? (
        <LoadingContainer>
          <Loading size={25} />
        </LoadingContainer>
      ) : (
        <>
          {!permission(FeatureNames.STATEMENTS, ScreenNames.STATEMENTS) ? (
            ''
          ) : (
            <Row>
              <Column maxWidth="1000px">
                {isAdminReporting && organizationIds && (
                  <Spacing>
                    <DropDownBorder
                      style={{ width: '280px', height: '47px', padding: '3px', backgroundColor: 'white' }}
                    >
                      <DropDown
                        options={organizationIds}
                        onChange={organizationGroupSelectionHandler}
                        hoveredMode={false}
                      />
                    </DropDownBorder>
                  </Spacing>
                )}
                <DashCard>
                  {loading ? (
                    <TableLoader count={4} />
                  ) : (
                    <CustomDataTable
                      noHeader={true}
                      columns={columns}
                      data={statements}
                      defaultSortFieldId="title"
                      fixedHeaderScrollHeight="300px"
                      pagination={true}
                    />
                  )}
                </DashCard>
              </Column>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Statements;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CustomDataTable = styled(DataTable)`
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }
`;

const Container = styled.div`
  margin-top: 150px;
  padding: 0 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const CustomSecondaryButton = styled(SecondaryButton)`
  font-size: 12px;
  color: #00c37c;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

const Spacing = styled.div`
  margin-bottom: 16px;
`;
