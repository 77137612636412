import { Grid } from '@mui/material';
import React from 'react';

import { CancelLoanButton, FixedDecimalFormat, SecondaryButton } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Container, RefundContainer, RefundTitle, Text } from './Common';

const CancelRefund = ({ item, cancelRefundButtonHandler }) => {
  const { organization } = useStore();
  const cancelRefundHandler = () => {
    cancelRefundButtonHandler(item);
  };
  return (
    <Container>
      <RefundContainer>
        <RefundTitle gap={true}>Are you sure you want to cancel this refund?</RefundTitle>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4}>
            <div>
              <CancelLoanButton
                onClick={item?.toggleExpandable}
                data-testid="dismiss-button"
                variant={'contained'}
                data-tag="confirm-cancel"
                color={'#ec3360'}
              >
                Close
              </CancelLoanButton>
            </div>
          </Grid>
          <Grid item={true} xs={8}>
            <SecondaryButton data-testid="cancel-button" onClick={cancelRefundHandler} variant="filled">
              Yes, cancel refund
            </SecondaryButton>
          </Grid>
          <Text>
            We will reimburse the{' '}
            <b>
              {' '}
              <FixedDecimalFormat amount={organization?.refundFee} prefix={'$'} />{' '}
            </b>{' '}
            refund fee originally charged to your account.
          </Text>
        </Grid>
      </RefundContainer>
    </Container>
  );
};

export default CancelRefund;
