import { useApolloClient, useMutation } from '@apollo/client';
import { faExternalLinkAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Radio } from '@frontend/cherry-library';
import { Divider, Grid, RadioGroup } from '@mui/material';
import { APP_ENV } from 'config';
import { CherryColorPicker, CopyIcon, DashCard, DashComponent, EditIcon, Loading, TableLoader } from 'lib/components';
import { UPDATE_LINK_TREE_COLORS, UPDATE_LINK_TREE_LINKS, UPDATE_LINK_TREE_PROFILE_IMAGE } from 'lib/graphql/mutations';
import { GET_LINK_TREE_LINKS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Column, SubHeader } from 'lib/styles';
import { useLinkTreeTableColumns } from 'lib/tables/LinkTreeTable';
import { getBase64 } from 'lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { AddLinkDialog } from '../components/AddLinkDialog';
export interface Link {
  id: number;
  title: string;
  link: string;
}
interface FileType {
  name: string;
  mime: string;
  data: string;
}

const defaultLinks = (slug: string | undefined) => [
  {
    id: -1,
    title: 'Cherry Payment Plans',
    link:
      (APP_ENV?.toLowerCase() === 'master' ? `https://pay.withcherry.com/` : `https://${APP_ENV}-pay.withcherry.com/`) +
      `${slug}?utm_source=cherrylinks`,
  },
];

export const LinkTree = ({ title }) => {
  const client = useApolloClient();
  const [updateLinks] = useMutation(UPDATE_LINK_TREE_LINKS);
  const [updateColors] = useMutation(UPDATE_LINK_TREE_COLORS);
  const [updateProfileImage, { loading: updateProfileImageLoading }] = useMutation(UPDATE_LINK_TREE_PROFILE_IMAGE, {
    onCompleted: ({ updateLinkTreeProfileImage }) => {
      if (updateLinkTreeProfileImage.success) {
        getLinkTreeConfig();
      } else {
        throw new Error();
      }
    },
    onError: () => {
      alert.error('Update Cherry Links profile image error');
    },
  });

  const { organization } = useStore();

  const alert = useAlert();

  const backgroundImageInput = useRef<any>(null);
  const profileImageInput = useRef<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [links, setLinks] = useState<Link[]>(defaultLinks(organization?.slug));
  const [linksBackup, setLinksBackup] = useState<Link[]>(defaultLinks(organization?.slug));
  const [backgroundColor, setBackgroundColor] = useState<string>('#E6F9F2');
  const [buttonColor, setButtonColor] = useState<string>('#00c37d');
  const [backgroundImage, setBackgroundImage] = useState<FileType>();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddLinkDialogOpen, setIsAddLinkDialogOpen] = useState<boolean>(false);
  const [backgroundType, setBackgroundType] = useState<string>();

  const linkTreeUrl =
    (APP_ENV?.toLowerCase() === 'master' ? `https://tree.withcherry.com/` : `https://${APP_ENV}-tree.withcherry.com/`) +
    organization?.slug;

  const getLinkTreeConfig = async () => {
    setLoading(true);
    try {
      const {
        data: { getLinkTreeLinks },
      } = await client.query({
        query: GET_LINK_TREE_LINKS,
        variables: {
          input: {
            organizationId: organization?.id,
          },
        },
      });
      if (getLinkTreeLinks.success) {
        const newLinks = [...defaultLinks(organization?.slug), ...getLinkTreeLinks.organizationLinks];
        setLinks(newLinks);
        setLinksBackup(newLinks);
        setBackgroundColor(getLinkTreeLinks.backgroundColor);
        setButtonColor(getLinkTreeLinks.buttonColor);
        setProfileImageUrl(getLinkTreeLinks.profileImageUrl);
        setBackgroundImageUrl(getLinkTreeLinks.backgroundImageUrl);
        setBackgroundType(getLinkTreeLinks.backgroundType);
      } else {
        throw new Error();
      }
    } catch (e) {
      alert.error('Fetch Cherry Links error');
    }
    setLoading(false);
  };

  useEffect(() => {
    getLinkTreeConfig();
  }, []);

  const handleInputChange = (name: string, i: number, value: string) => {
    setLinks((prevLinks) => {
      const tempLinks = [...prevLinks];
      tempLinks[i] = { ...tempLinks[i], [name]: value };
      return tempLinks;
    });
  };

  const handleDeleteLink = (i: number) => {
    setLinks((prevLinks) => {
      let tempLinks = [...prevLinks];
      tempLinks = tempLinks.filter((_, indexToDelete) => indexToDelete !== i);
      return tempLinks;
    });
  };

  const handleChangeSortOrder = (i: number, direction: number) => {
    if (i + direction < 1 || i + direction >= links.length) return;

    setLinks((prevLinks) => {
      const tempLinks = [...prevLinks];
      [tempLinks[i], tempLinks[i + direction]] = [tempLinks[i + direction], tempLinks[i]];
      return tempLinks;
    });
  };

  const handleBackgroundTypeChange = (e) => {
    setBackgroundType(e.target.value);
  };

  const handleBackgroundImageClick = () => {
    backgroundImageInput?.current?.click();
  };

  const handleBackgroundImageSelection = (e) => {
    if (!e.target.files?.[0]) {
      alert.info('Attachment failed, please try again later.');
    }

    const file = e.target.files?.[0];

    if (file?.size > 2 * 1024 * 1024) {
      alert.info('File too large, must be less than 2MB.');
      backgroundImageInput.current.value = '';
    }

    getBase64(file)
      .then((result) => {
        setBackgroundImage({ name: file.name, mime: file?.type, data: result as string });
        const previewUrl = URL.createObjectURL(file);
        setBackgroundImageUrl(previewUrl);
      })
      .catch((err) => {
        alert.info('Attachment failed, please try again later.');
      });
  };

  const handleProfileImageClick = () => {
    profileImageInput?.current?.click();
  };

  const handleProfileImageSelection = (e) => {
    if (!e.target.files?.[0]) {
      alert.info('Attachment failed, please try again later.');
    }

    const file = e.target.files?.[0];

    if (file?.size > 2 * 1024 * 1024) {
      alert.info('File too large, must be less than 2MB.');
      backgroundImageInput.current.value = '';
    }

    getBase64(file)
      .then((result) => {
        updateProfileImage({
          variables: {
            input: {
              organizationId: organization?.id,
              file: { name: file.name, mime: file?.type, data: result as string },
            },
          },
        });
      })
      .catch((err) => {
        alert.info('Attachment failed, please try again later.');
      });
  };

  const { tableColumns } = useLinkTreeTableColumns({
    isEditMode,
    onInputChange: handleInputChange,
    onDeleteLink: handleDeleteLink,
    onChangeSortOrder: handleChangeSortOrder,
  });

  const handlePreviewClick = () => {
    window.open(linkTreeUrl, '_blank');
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(linkTreeUrl);
    alert.success('Link is copied to your clipboard');
  };

  const handleEditLinksClick = () => {
    setIsEditMode(true);
  };

  const handleSaveLinks = async () => {
    setLoading(true);
    try {
      const {
        data: { updateLinkTreeLinks },
      } = await updateLinks({
        variables: {
          input: {
            organizationId: organization?.id,
            organizationLinks: links.filter((_, index) => index !== 0),
          },
        },
      });

      setIsEditMode(false);

      if (updateLinkTreeLinks.success) {
        await getLinkTreeConfig();
      } else {
        throw new Error();
      }
    } catch (error) {
      setLinks(linksBackup);
      alert.error('Update Cherry Links error');
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setLinks(linksBackup);
    setIsEditMode(false);
  };

  const handleSaveColors = async () => {
    if (backgroundType === 'IMAGE' && !backgroundImage && !backgroundImageUrl) {
      alert.error('You must upload a background image when background type is chosen as image');
      return;
    }
    setLoading(true);

    try {
      const {
        data: { updateLinkTreeColors },
      } = await updateColors({
        variables: {
          input: {
            organizationId: organization?.id,
            buttonColor,
            backgroundColor,
            backgroundType,
            backgroundImage,
          },
        },
      });

      if (updateLinkTreeColors.success) {
        await getLinkTreeConfig();
      } else {
        throw new Error();
      }
    } catch (e) {
      alert.error('Update Cherry Links colors error');
    }

    setLoading(false);
  };

  const openAddLinkDialog = () => {
    setIsAddLinkDialogOpen(true);
  };

  const closAddLinkDialog = () => {
    setIsAddLinkDialogOpen(false);
  };

  return (
    <Column>
      <DashComponent dashHeader={title} />
      <DashCard>
        <Grid container={true} direction="column" spacing={3}>
          <Grid item={true}>
            <StyledHeader size="16px">Brand Settings</StyledHeader>
            <StyledText>This ensures that your Cherry Links page fits in with your brand.</StyledText>
          </Grid>

          <Grid item={true} container={true} direction="row" spacing={5} wrap="nowrap">
            <Grid item={true}>
              <SettingsTypography>Choose a Button Color</SettingsTypography>
              <Grid container={true} direction="row" spacing={2} alignItems="center">
                <Grid item={true}>
                  <CherryColorPicker selectedColor={setButtonColor} defaultColor={buttonColor} />
                </Grid>
                <Grid item={true}>
                  <RoundedColor color={buttonColor} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true}>
              <SettingsTypography>Background Type</SettingsTypography>
              <RadioGroup value={backgroundType}>
                <Radio
                  value="COLOR"
                  label="Color"
                  size="small"
                  checked={backgroundType === 'COLOR'}
                  onChange={handleBackgroundTypeChange}
                />
                <Radio
                  value="IMAGE"
                  label="Image"
                  size="small"
                  checked={backgroundType === 'IMAGE'}
                  onChange={handleBackgroundTypeChange}
                />
              </RadioGroup>
            </Grid>
            {backgroundType === 'COLOR' && (
              <Grid item={true}>
                <SettingsTypography>Choose a Background Color</SettingsTypography>
                <Grid container={true} direction="row" spacing={2} alignItems="center">
                  <Grid item={true}>
                    <CherryColorPicker selectedColor={setBackgroundColor} defaultColor={backgroundColor} />
                  </Grid>
                  <Grid item={true}>
                    <RoundedColor color={backgroundColor} />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {backgroundType === 'IMAGE' && (
              <>
                <Grid item={true}>
                  <SettingsTypography>Choose a Background Image</SettingsTypography>
                  <Button onClick={handleBackgroundImageClick}>
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleBackgroundImageSelection}
                        hidden={true}
                        ref={backgroundImageInput}
                      />
                      Upload Photo
                    </>
                  </Button>
                </Grid>
                {backgroundImageUrl && (
                  <Grid item={true} style={{ flex: 1 }}>
                    <img src={backgroundImageUrl} alt="backgroundImage" style={{ width: '100%' }} />
                  </Grid>
                )}
              </>
            )}
          </Grid>

          <Grid item={true} container={true} direction="row">
            <Button data-testid="saveColorsButton" onClick={handleSaveColors} disabled={loading}>
              Save
            </Button>
          </Grid>

          <Grid item={true} container={true} direction="row" wrap="nowrap" spacing={2}>
            <Grid item={true}>
              <SettingsTypography>Add a logo or a profile image</SettingsTypography>
              <Button onClick={handleProfileImageClick}>
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageSelection}
                    hidden={true}
                    ref={profileImageInput}
                  />
                  Upload Photo
                </>
              </Button>
            </Grid>
            <Grid item={true}>
              <ProfileImageContainer>
                {updateProfileImageLoading || loading ? (
                  <Loading />
                ) : (
                  profileImageUrl && <ProfileImage src={profileImageUrl} alt="profileImage" />
                )}
              </ProfileImageContainer>
            </Grid>
          </Grid>

          <Grid item={true} container={true} direction="column">
            <SettingsTypography>Your Cherry Links url</SettingsTypography>
            <TextBox>
              <a href={linkTreeUrl} target="_blank">
                {linkTreeUrl}
              </a>
            </TextBox>
          </Grid>

          <Grid item={true} container={true} direction="row" style={{ gap: '16px' }}>
            <Button variant="secondary" data-testid="previewMyWidgetsButton" onClick={handlePreviewClick}>
              Preview &nbsp; <FontAwesomeIcon style={{ width: '14px' }} icon={faExternalLinkAlt} />
            </Button>
            <Button onClick={handleCopyLinkClick}>
              Copy My Link &nbsp; <CopyIcon fill="#fff" />
            </Button>
          </Grid>

          <StyledDivider />

          <Grid item={true}>
            <StyledHeader size="16px">Manage Your Links</StyledHeader>
            <StyledText>These links will appear on your Cherry Links page.</StyledText>
          </Grid>

          <Grid item={true} container={true} direction="row" style={{ gap: '16px' }}>
            {isEditMode ? (
              <>
                <Button variant="secondary" data-testid="cancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button data-testid="saveLinksButton" onClick={handleSaveLinks}>
                  Save Links
                </Button>
              </>
            ) : (
              <>
                <AddLinkDialog
                  open={isAddLinkDialogOpen}
                  handleClose={closAddLinkDialog}
                  onAddLinkFinish={getLinkTreeConfig}
                />
                <Button variant="secondary" data-testid="addLinkButton" onClick={openAddLinkDialog}>
                  Add Link &nbsp; <FontAwesomeIcon style={{ width: '14px' }} icon={faPlus} />
                </Button>
                <Button variant="secondary" data-testid="editLinksButton" onClick={handleEditLinksClick}>
                  Edit Links &nbsp; <EditIcon />
                </Button>
              </>
            )}
          </Grid>

          <Grid item={true}>
            <CustomDataTable
              noHeader={true}
              columns={tableColumns}
              data={links}
              progressPending={loading}
              progressComponent={<TableLoader count={4} />}
            />
          </Grid>
        </Grid>
      </DashCard>
    </Column>
  );
};

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
`;

const ProfileImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const CustomDataTable = styled(DataTable)`
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
    :first-child,
    :last-child {
      padding: 0;
    }
  }
  .rdt_TableRow > div {
    padding: 16px;
    :first-child,
    :last-child {
      padding: 16px 0;
    }
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }
`;

const StyledHeader = styled(SubHeader)`
  margin: 0;
  padding-bottom: 8px;
`;

const StyledText = styled.p`
  font-size: 14px;
  margin: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 12px;
`;

const TextBox = styled.div`
  background-color: #f2f4f5;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 19px;
  > a {
    color: #0e202f;
  }
`;

interface RoundedColorProps {
  color: string;
}

const RoundedColor = styled.div<RoundedColorProps>`
  width: 25px;
  background-color: ${(props) => props.color};
  height: 25px;
  border-radius: 25px;
`;

const SettingsTypography = styled.div`
  font-weight: 400;
  color: #0e202f;
  font-size: 12px;
  margin-bottom: 4px;
`;
