import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Rox from 'rox-browser';
import styled from 'styled-components';

import { Loading, MainLayout } from 'lib/components';
import {
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_DETAIL,
  FETCH_ORGANIZATION_MERCHANTS,
  FETCH_ORGANIZATION_MERCHANT_GROUPS,
  FETCH_ORGANIZATION_TIER_AND_AUTH,
  GET_MERCHANT_DETAIL,
} from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { MerchantGroup } from 'lib/types/Merchant';
import { Merchant, Permissions } from 'lib/types/Merchant';
import { OrganizationDetail, OrganizationMerchants } from 'lib/types/Organization';
import { AVAILABLE_URLS } from 'lib/utils';

enum DecodedTokenType {
  PARTNER = 'PARTNER',
}
interface DecodedToken {
  type?: DecodedTokenType;
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const AuthChecker = (): JSX.Element => {
  const location = useLocation();
  const client = useApolloClient();
  const navigate = useNavigate();
  const query = useQuery();
  const { captureException } = useSentry();
  NavigateService.initNavigate(navigate);
  const { trackIdentify, trackGroup } = useSegment();

  const {
    setUser,
    organization,
    user,
    isComingFromLogin,
    setSelectedOrganization,
    setLocations,
    setFeatures,
    setOrganizationMerchants,
    setOrganizationTier,
    setPermissions,
    setRole,
    setHasBankingIssue,
    setIsComingFromLogin,
  } = useStore();
  const [loading, setLoading] = useState(true);
  const userPermission = useRef<Permissions>({} as Permissions);
  const { token, refreshToken } = StorageService.getAuthData();

  useEffect(() => {
    getInitialRequests();
  }, []);

  const findRoutePath = (routes, pathname) => {
    for (const route of routes) {
      if (pathname === route?.pathname || (pathname?.includes(route?.pathname) && !route?.children)) {
        return route;
      }
      if (route?.children?.length > 0) {
        for (const child of route?.children) {
          if (pathname === child?.pathname) {
            return child;
          }
        }
      }
    }
    return '/dashboard';
  };

  const getInitialRequests = async () => {
    try {
      if (token) {
        const decodedToken = decodeToken();
        if (decodedToken.type === DecodedTokenType.PARTNER) {
          getUserOrganization();
        }

        const organizationDetail = await getOrganizationDetail();
        if (organizationDetail.organization) {
          setSelectedOrganization({
            ...organizationDetail.organization,
            idOrganization: organizationDetail.organization?.id,
          });
          setLocations(organizationDetail?.locations);
          Rox.setCustomNumberProperty('organization_id', Number(organizationDetail?.organization?.id));
          Rox.setCustomStringProperty('organization_id_str', organizationDetail?.organization?.id?.toString());

          setOrganizationActiveFeatures(organizationDetail.features);

          const organizationMerchants = await getOrganizationMerchants();
          const merchantList = organizationMerchants?.data || [];
          if (merchantList.length > 0) {
            setOrganizationMerchants(merchantList);
            const permissions = merchantList?.[0]?.permissions;
            if (permissions?.['SETTINGS']?.includes('BANK_INFORMATION')) {
              getMerchantDetails(merchantList);
            }
            if (decodedToken?.type !== 'PARTNER') {
              userPermission.current = merchantList?.[0]?.permissions || {};
              setPermissions(merchantList?.[0]?.permissions || {});
              setRole(merchantList?.[0]?.roles || {});
            }
          } else if (user?.userType === 'MERCHANT_USER' && organizationMerchants?.data?.length < 1) {
            navigateLogin();
          } else {
            setOrganizationMerchants([]);
          }
        } else if (organizationDetail?.code === 'unauthorized') {
          navigateLogin();
        }

        await getOrganizationAuthandTier();

        // PERMISSION ROUTE CONTROL
        let routeLink: any = '';
        const allowedRouteNames = Object.keys(userPermission?.current || {})?.reverse();

        if (location.pathname === '/') {
          const entryPageName = allowedRouteNames?.[0];
          routeLink = AVAILABLE_URLS?.find((item) => item?.level === entryPageName)?.pathname;
        } else {
          const activeRoutePath = findRoutePath(AVAILABLE_URLS, location?.pathname);
          const activeRouteMembershipPath: any = location.pathname.includes('membership');
          const activeRoutePointOfSalePath: boolean = location.pathname.includes('point-of-sale');

          const findHasPermission = allowedRouteNames.find((routeName: string) => routeName === activeRoutePath?.level);
          const findHasMembershipFeature = organizationDetail?.features?.find(
            (feature) => feature?.type === 'MEMBERSHIP' && feature?.status === 'ACTIVE',
          );

          if (findHasPermission && !activeRouteMembershipPath) {
            routeLink = activeRoutePath.pathname;
          } else if ((activeRouteMembershipPath && findHasMembershipFeature) || activeRoutePointOfSalePath) {
            routeLink = location?.pathname;
          } else {
            const entryPageName = allowedRouteNames?.[0];
            routeLink = AVAILABLE_URLS?.find((item) => item?.level === entryPageName)?.pathname;
          }
        }

        if (query.get('type') === 'test') {
          // do nothing
        } else {
          routeLink ? navigate(routeLink) : navigate('/dashboard');
        }
        setLoading(false);
        return;
      } else {
        navigateLogin();
      }
    } catch (error) {
      navigateLogin(error, { message: 'Auth Checker' });
    }
  };

  const navigateLogin = (err?: unknown, captureExceptionMessage?: { message: string }) => {
    StorageService.clearUserData();
    navigate('/login');
    setLoading(false);
    captureExceptionMessage && err && captureException(err, captureExceptionMessage);
  };

  const decodeToken = (): DecodedToken => {
    if (!token && !refreshToken) {
      const userData = StorageService.getUserData();
      StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
      return jwtDecode((userData.token as string) || '');
    } else {
      return jwtDecode((token as string) || '');
    }
  };

  const getUserOrganization = async () => {
    const {
      data: { fetchUserOrganizations },
    } = await client.query({ query: FETCH_ORGANIZATION, variables: { input: {} } });

    if (fetchUserOrganizations?.data) {
      const findOrganization = fetchUserOrganizations?.data?.find((org) => org.id === organization?.id);
      userPermission.current = findOrganization?.permissions;
      setPermissions(findOrganization?.permissions || {});
      setRole(findOrganization?.roles || '');
    }
  };

  const getOrganizationDetail = async (): Promise<OrganizationDetail> => {
    const {
      data: { fetchOrganizationDetail },
    } = await client.query({
      query: FETCH_ORGANIZATION_DETAIL,
      variables: { input: { idOrganization: organization?.id } },
    });

    return fetchOrganizationDetail;
  };

  const getMerchantDetails = async (merchants: Merchant[]): Promise<void> => {
    try {
      const merchantIds = merchants.map((merchant) => merchant?.id);
      const {
        data: { getMerchantDetail },
      } = await client.query({
        query: GET_MERCHANT_DETAIL,
        variables: {
          input: {
            merchantIds,
          },
        },
      });

      if (getMerchantDetail?.id) {
        const mechantHasBankIssue = getMerchantDetail?.find((m) => m?.bankingInfo?.accountType === 'ERROR');
        if (mechantHasBankIssue) {
          setHasBankingIssue(true);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const getOrganizationMerchants = async (): Promise<OrganizationMerchants> => {
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_MERCHANTS,
      fetchPolicy: 'no-cache',
      variables: { input: { idOrganization: organization?.id } },
    });

    return data?.fetchOrganizationMerchants;
  };

  const fetchOrganizationMerchantGroups = async (): Promise<MerchantGroup[]> => {
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_MERCHANT_GROUPS,
      fetchPolicy: 'no-cache',
      variables: { input: { organizationId: organization?.id } },
    });

    return data?.fetchOrganizationMerchantGroups;
  };

  const setOrganizationActiveFeatures = (features) => {
    const activeFeatures = features
      ?.filter((item) => {
        if (item.status === 'ACTIVE') {
          return item.type;
        }
      })
      .map((item) => {
        return item.type;
      });
    setFeatures(activeFeatures || []);
  };

  const getOrganizationAuthandTier = async (): Promise<void> => {
    const { data } = await client.query({
      query: FETCH_ORGANIZATION_TIER_AND_AUTH,
      variables: {
        input: { idOrganization: organization?.id },
      },
    });
    const { getOrganizationTier, getUserAuthInfo } = data;
    if (getOrganizationTier) {
      setOrganizationTier(getOrganizationTier);
    }

    if (getUserAuthInfo?.user) {
      const storedUseData = StorageService.getUserData();
      const { role, merchants } = useStore.getState();

      StorageService.setUserData({
        ...storedUseData,
        ...getUserAuthInfo,
        userType: getUserAuthInfo.tokenType === 'MERCHANT_AUTH' ? 'MERCHANT_USER' : '',
      });
      setUser({
        ...getUserAuthInfo.user,
        userType: getUserAuthInfo.tokenType === 'MERCHANT_AUTH' ? 'MERCHANT_USER' : '',
      });
      Sentry.setUser({
        email: getUserAuthInfo.user.email,
        id: getUserAuthInfo.user.id,
      });
      trackIdentify(getUserAuthInfo.user, organization, merchants, role);
      if (isComingFromLogin) {
        trackGroup(`org-${organization?.id}`, {
          industry: organization?.industry,
          userRole: role?.[0],
        });
        setIsComingFromLogin(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      ) : (
        <MainLayout>
          <Outlet />
        </MainLayout>
      )}
    </>
  );
};

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;
