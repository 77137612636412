import {
  checkoutFinishedItems,
  createTreatmentPlanOption,
  denialCheckoutOption,
  deniedApplicationActions,
  extendApprovalOption,
  outbondMessagesOption,
  voidTransactionItem,
} from 'lib/utils';
import { OptionType } from '../type';

export const getDenialItems = () => {
  return JSON.parse(JSON.stringify(denialCheckoutOption));
};

export const getCheckoutFinishedItems = (row) => {
  const optionData: OptionType[] = [];

  if (row.eligible >= 200) {
    optionData.push(...checkoutFinishedItems);
  } else {
    optionData.push(...voidTransactionItem);
  }

  if (!row.approvalExtended && row.hasExtendApproval && row.startedAt > 0) {
    optionData.push(...extendApprovalOption);
  }

  return JSON.parse(JSON.stringify(optionData));
};

export const getDeniedApplicationActions = (row) => {
  return JSON.parse(JSON.stringify(deniedApplicationActions));
};

export const getOutboundMessagesItems = (row) => {
  const optionData: OptionType[] = [];
  if (row?.outboundMessages?.length) {
    optionData.push(...outbondMessagesOption);
  }

  if (!row.approvalExtended && row.hasExtendApproval && row.startedAt > 0) {
    optionData.push(...extendApprovalOption);
  }

  return JSON.parse(JSON.stringify(optionData));
};

export const getCreateTreatmentPlanItems = (row) => {
  const optionData: OptionType[] = [];

  if (row?.activeLoan?.count === 0 || (row?.activeLoan?.count > 0 && row?.activeLoan?.channel === 'TREATMENT_PLAN')) {
    optionData.push(...createTreatmentPlanOption);
  }

  return JSON.parse(JSON.stringify(optionData));
};
