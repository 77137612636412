import { FormControl, Grid } from '@mui/material';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { DropDown } from 'lib/components';
import { DropDownBorder } from 'pages/desktop/Settings/components/UserManagement/AssignRole';

enum PAYMENT_SCHEDULES {
  MONTHLY = 'MONTHLY',
  MONTHLY_FLEX = 'MONTHLY_FLEX',
  YEARLY = 'YEARLY',
  MONTHLY_OR_YEARLY = 'MONTHLY_OR_YEARLY',
  MONTHLY_FLEX_OR_YEARLY = 'MONTHLY_FLEX_OR_YEARLY',
}

enum PAYMENT_SCHEDULE_LABELS {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  MONTHLY_OR_YEARLY = 'Monthly or Yearly',
}

const paymentScheduleOptions = Object.keys(PAYMENT_SCHEDULES).map((key) => ({
  value: PAYMENT_SCHEDULES[key],
  label: PAYMENT_SCHEDULE_LABELS[key],
}));

export const PlanPricing = () => {
  return (
    <>
      <SubTitle>Pricing</SubTitle>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined" margin="dense">
              <FormLabel>Available Payment Schedules</FormLabel>
              <DropDownBorder style={{ width: '100%' }}>
                <DropDown
                  maxHeight={'235'}
                  ignoreInitialFirstItem={true}
                  options={paymentScheduleOptions}
                  placeholder="Plan Type"
                  placeholderColor={'#DADADA'}
                  hoveredMode={false}
                  defaultValue={''}
                />
              </DropDownBorder>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>Yearly Payment</FormLabel>
              <StyledCurrencyInput
                data-testid="membership-form-monthly-price"
                id="membership-form-monthly-price"
                placeholder="Montly Price"
                name="monthly-price"
                className="form-control-yearly-price"
                value={''}
                prefix="$"
                decimalScale={2}
                step={1}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <FormLabel>Monthly Payment</FormLabel>
              <StyledCurrencyInput
                data-testid="membership-form-monthly-price"
                id="membership-form-monthly-price"
                placeholder="Montly Price"
                name="monthly-price"
                className="form-control-monthly-price"
                value={''}
                prefix="$"
                decimalScale={2}
                step={1}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
  margin-top: 40px;
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  font-family: 'Open Sans';
  padding: 8px 16px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  ::placeholder {
    color: #a2a2a2;
    opacity: 0.5;
    font-size: 15px;
    font-family: 'Open Sans';
  }
`;
