import { useMutation } from '@apollo/client';
import { Body, Button, Dropdown, PhoneNumberInput, SSNInput, TextInput } from '@frontend/cherry-library';
import { Box, FormControl, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { theme } from 'config/theme';
import { Icon, TextField, Title } from 'lib/components/mobile';
import { isOrganizationDental, US_STATES } from 'lib/constants';
import { POST_PREAPPROVAL } from 'lib/graphql/mutations';
import { useDebounceCallback } from 'lib/hooks';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { PreApprovalStepEnums } from 'lib/types';
import { clearMaskFormat, formatSSN, ITINNumberRegex } from 'lib/utils';
import React, { FC, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { OptionState, PatientInformation, PostPreApprovalBorrower, PreApprovalResult, State } from './type';
import useAddressAutoComplete from 'lib/hooks/useAddressAutoComplete';
import { Trans } from 'react-i18next';

interface Props {
  onClose: () => void;
  setActiveStep: (step: PreApprovalStepEnums) => void;
  setPhone: (phone: string) => void;
  setPreApprovalResult: (preApprovalResult: PreApprovalResult) => void;
  patientInfo: PatientInformation | null;
  openSSNModal: boolean;
  setOpenSSNModal: (openSSNModal: boolean) => void;
}

interface FieldKey {
  key: keyof PatientInformation;
  eventKey: string;
}

enum Fields {
  PHONE = 'phone',
  SSN = 'ssn',
}

type SSNFormItemPatientInformation = Pick<PatientInformation, 'ssn'>;
type ErrorMessageType = string | null;
interface SSNFormItemProps {
  handleFocusSSN: () => void;
  handleChange: (nativeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  patientInformation: SSNFormItemPatientInformation;
  ssnErrorMessage: ErrorMessageType;
  itinErrorMessage: ErrorMessageType;
}

const SSNFormItem: FC<SSNFormItemProps> = ({
  handleFocusSSN,
  handleChange,
  patientInformation,
  ssnErrorMessage,
  itinErrorMessage,
}) => {
  return (
    <CombineRow>
      <Grid item={true} xs={12}>
        <FormControl fullWidth={true} variant="outlined">
          <SSNInput
            inputSize="medium"
            id="location-management-ssn"
            label="Patient SSN (optional, but boosts pre-approval rate)"
            inputProps={{
              'data-inputkey': 'ssn',
              'data-testid': 'application-ssn',
            }}
            name="ssn"
            onFocus={handleFocusSSN}
            onChange={handleChange}
            defaultValue={patientInformation?.ssn}
            value={patientInformation?.ssn}
            error={!!ssnErrorMessage || !!itinErrorMessage}
          />
        </FormControl>
        {ssnErrorMessage ? <ErrorMessage>{ssnErrorMessage}</ErrorMessage> : null}
        {itinErrorMessage ? <ErrorMessage>{itinErrorMessage}</ErrorMessage> : null}
      </Grid>
    </CombineRow>
  );
};

export const PreApprovalInformation = ({
  onClose,
  setActiveStep,
  setPreApprovalResult,
  setPhone,
  patientInfo,
  openSSNModal,
  setOpenSSNModal,
}: Props) => {
  const debounce = useDebounceCallback();
  const alert = useAlert();
  const { trackSegmentEvent, applicationName } = useSegment();
  const { organization, setPatientInfo, ssnErrorMessage, setSsnErrorMessage } = useStore();
  const isDentalOrganization = isOrganizationDental(organization?.industry);
  const { getSuggestions } = useAddressAutoComplete();

  const initialData = {
    firstName: patientInfo?.firstName || '',
    lastName: patientInfo?.lastName || '',
    phone: patientInfo?.phone || '',
    ssn: patientInfo?.ssn || null,
    address: patientInfo?.address || '',
    apartment: patientInfo?.apartment || '',
    city: patientInfo?.city || '',
    state: patientInfo?.state || '',
    zipCode: patientInfo?.zipCode || '',
  };

  const [searchTerm, setSearchTerm] = useState<string>(initialData?.address ?? '');
  const [options, setOptions] = useState<OptionState[]>([]);
  const [autocompleteData, setAutocompleteData] = useState<any[]>([]);
  const [patientInformation, setPatientInformation] = useState<PatientInformation>(initialData);
  const [itinErrorMessage, setItinErrorMessage] = useState<string | null>(null);

  const [postPreApproval] = useMutation(POST_PREAPPROVAL);

  const handleChange = (nativeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const key =
      (nativeEvent?.target?.dataset?.inputkey as keyof PatientInformation) ||
      (nativeEvent?.target?.name as keyof PatientInformation);
    const currentTarget = nativeEvent?.currentTarget;
    const value = currentTarget?.value || nativeEvent?.target?.value;

    setPatientInformation((prevState: PatientInformation) => {
      return { ...prevState, [key]: value };
    });

    if (value?.length > 0) {
      if (key === Fields.PHONE || key === Fields.SSN) {
        setSsnErrorMessage(null);
      }

      if (key === Fields.SSN) {
        setItinErrorMessage(null);
      }
    }

    handlePatientInformationChange({ key, value });
  };

  const handleFormattedValue = (key: keyof PatientInformation, value: string | undefined): string => {
    switch (key) {
      case Fields.PHONE:
        return clearMaskFormat(value);
      case Fields.SSN:
        return formatSSN(value);
      default:
        return value || '';
    }
  };

  const isValidLength = (key: keyof PatientInformation, formattedValue: string): boolean => {
    switch (key) {
      case Fields.PHONE:
        return formattedValue.length === 10;
      case Fields.SSN:
        return formattedValue.length === 9;
      default:
        return formattedValue.length > 0;
    }
  };

  const handlePatientInformationChange = ({
    key,
    value,
  }: {
    key: keyof PatientInformation;
    value: string | undefined;
  }) => {
    if (value) {
      const formattedValue = handleFormattedValue(key, value);
      const validLength = isValidLength(key, formattedValue);

      if (validLength) {
        const fieldHandler = handleFilledField({ key, eventKey: key?.toUpperCase?.() });
        fieldHandler();
      }
    }
  };

  const handleFilledField = ({ key, eventKey }: FieldKey) => {
    return () => {
      debounce(() => {
        trackSegmentEvent(`PRACTICE_PORTAL.PRE_APPROVAL.MODAL_${eventKey}_FILLED`, {
          application: applicationName,
        });
      }, 300);
    };
  };

  const handleFilledAddress = () => {
    debounce(() => {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_STREET_ADDRESS_FILLED', {
        application: applicationName,
      });
    }, 300);
  };

  const handleFocusFirstName = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_FIRST_NAME_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusLastName = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_LAST_NAME_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusPhone = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_PHONE_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusSSN = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_SSN_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusAddress = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_STREET_ADDRESS_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusUnit = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_APT_UNIT_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusCity = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_CITY_SELECTED', {
      application: applicationName,
    });
  };

  const handleFocusState = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_STATE_SELECTED', {
      application: applicationName,
    });
  };

  const handleFilledState = () => {
    debounce(() => {
      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_STATE_FILLED', {
        application: applicationName,
      });
    }, 300);
  };

  const handleFocusZipCode = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_ZIP_CODE_SELECTED', {
      application: applicationName,
    });
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const _options = US_STATES?.map((state: State) => {
      return {
        value: state.value,
        label: state.name,
        color: 'black',
        className: 'address-item',
      };
    });

    setOptions(_options);
  }, []);

  const setAddressValues = (selectedAddress) => {
    const { street, city, state, zip } = selectedAddress;
    setSearchTerm(street);
    setPatientInformation((prevState: PatientInformation) => {
      return { ...prevState, address: street, zipCode: zip, city, state };
    });
  };

  const handleDropdownChange = (e) => {
    handleFilledState();

    setPatientInformation((prevState: PatientInformation) => {
      return { ...prevState, state: e?.target?.value };
    });
  };

  const allPropertiesNotEmpty = (obj) => {
    return Object.entries(obj).every(([key, value]) => {
      if (key === 'apartment' || key === 'ssn') {
        return true;
      } else {
        return value;
      }
    });
  };

  const setSearchString = (searchString: string) => {
    setPatientInformation((prevState: PatientInformation) => {
      return { ...prevState, address: searchString };
    });
    setSearchTerm(searchString);
    handleFilledAddress();
  };

  const mapBorrowerInfo = (info: PatientInformation): PostPreApprovalBorrower => ({
    firstName: info?.firstName,
    lastName: info?.lastName,
    ssn: formatSSN(info?.ssn)?.length > 0 ? info?.ssn : null,
    phone: clearMaskFormat(info?.phone),
    address: {
      state: info?.state,
      city: info?.city,
      streetAddress: info?.address,
      zip: info?.zipCode,
      addressLine2: info?.apartment,
    },
  });

  const continueWithSSN = () => {
    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.SSN_PROCEED_WITH_SSN_SUBMITTED', {
      application: applicationName,
    });

    onContinueHandler();
  };

  const continueWithoutSSN = () => {
    setOpenSSNModal(false);
    onContinueHandler();

    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.SSN_PROCEED_WITHOUT_SSN_SUBMITTED', {
      application: applicationName,
    });
  };

  const defaultContinue = () => {
    const borrowerInfo = mapBorrowerInfo(patientInformation);
    const hasSSN = formatSSN(patientInformation?.ssn)?.length === 9;

    trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.MODAL_SUBMITTED', {
      application: applicationName,
      borrower: { ...borrowerInfo, ssn: hasSSN },
      organizationId: organization?.id,
    });

    if (Boolean(patientInformation?.ssn) || !isDentalOrganization) {
      onContinueHandler();
    } else {
      setOpenSSNModal(true);

      trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.SSN_MODAL_VIEWED', {
        application: applicationName,
      });
    }
  };

  const onContinueHandler = async () => {
    const borrowerInfo = mapBorrowerInfo(patientInformation);
    const hasSSN = formatSSN(patientInformation?.ssn)?.length === 9;

    if (hasSSN) {
      const isITIN = ITINNumberRegex?.test(patientInformation.ssn!);

      if (patientInformation?.ssn?.charAt(0) === '9' && isITIN) {
        setItinErrorMessage(
          `We currently do not accept ITIN for pre-approval checks. Please have the patient apply to use their ITIN number.`,
        );
        return false;
      }
    }

    setOpenSSNModal(false);

    try {
      setSsnErrorMessage(null);
      setActiveStep(PreApprovalStepEnums.LOADING);
      setPhone(patientInformation?.phone?.replace(/[\(\)\s-]/g, ''));
      const { data } = await postPreApproval({
        variables: {
          input: {
            borrower: borrowerInfo,
            organizationId: organization?.id,
          },
        },
      });

      setPatientInfo(patientInformation);

      const preApprovalResponse = data?.postPreApproval;
      if (data?.postPreApproval?.id) {
        if (preApprovalResponse) {
          if (preApprovalResponse?.status) {
            setPatientInfo(patientInformation);
          }

          if (preApprovalResponse?.status === 'APPROVED') {
            setPreApprovalResult({
              firstName: preApprovalResponse?.borrowerFirstName,
              lastName: preApprovalResponse?.borrowerLastName,
              phone: patientInformation?.phone?.replace(/[\(\)\s-]/g, ''),
              minimumAmount: preApprovalResponse?.minimumAmount,
              maximumAmount: preApprovalResponse?.maximumAmount,
            });

            setActiveStep(PreApprovalStepEnums.SUCCESS);

            trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.PREAPPROVED_SCREEN_VIEWED', {
              application: applicationName,
              borrower: { ...borrowerInfo, ssn: hasSSN },
              organizationId: organization?.id,
            });
          } else if (preApprovalResponse?.status === 'DENIED') {
            trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.NOT_PREAPPROVED_SCREEN_VIEWED', {
              application: applicationName,
              borrower: { ...borrowerInfo, ssn: hasSSN },
              organizationId: organization?.id,
            });

            setPreApprovalResult({
              firstName: patientInformation?.firstName,
              lastName: patientInformation?.lastName,
              phone: patientInformation?.phone?.replace(/[\(\)\s-]/g, ''),
              minimumAmount: '',
              maximumAmount: '',
            });

            setActiveStep(PreApprovalStepEnums.DENIAL);
          } else {
            alert.error('An error occurred, please try again later');
            setActiveStep(PreApprovalStepEnums.INFORMATION);
          }
        }
      } else if (data?.postPreApproval?.message === 'application.already_exist') {
        setActiveStep(PreApprovalStepEnums.APPLICATION_ALREADY_EXIST);

        trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.NOT_PREAPPROVED_SCREEN_VIEWED', {
          application: applicationName,
          borrower: { ...borrowerInfo, ssn: hasSSN },
          organizationId: organization?.id,
        });
      } else if (data?.postPreApproval?.message === 'preapproval.phone_and_ssn_not_match.validation') {
        trackSegmentEvent('PRACTICE_PORTAL.PRE_APPROVAL.SSN_PHONE_MISMATCH_ERROR_VIEWED', {
          application: applicationName,
          borrower: { ...borrowerInfo, ssn: hasSSN },
          organizationId: organization?.id,
        });
        setActiveStep(PreApprovalStepEnums.INFORMATION);
        setOpenSSNModal(false);
        setSsnErrorMessage(
          'The phone number you entered does not match the associated SSN. Please double check this information.',
        );
      } else if (data?.postPreApproval?.status === '500') {
        alert.error(data?.postPreApproval?.error);
        setActiveStep(PreApprovalStepEnums.INFORMATION);
      }
    } catch (error) {
      setSsnErrorMessage(null);
      setItinErrorMessage(null);
      setActiveStep(PreApprovalStepEnums.INFORMATION);
      alert.error('An error occurred');
    }
  };

  if (openSSNModal) {
    return (
      <div>
        <Title align="center" display="block" m="24px 0 0 0">
          Do you know their Social Security Number (SSN)?
        </Title>
        <Subtitle m="24px 0">
          Including the patient’s SSN can improve accuracy of a pre-approval result by 50%.
        </Subtitle>

        <CombineRow>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <SSNInput
                name="ssn"
                onFocus={handleFocusSSN}
                onChange={handleChange}
                value={patientInformation?.ssn}
                id="location-management-ssn-again"
                label="Patient Social Security Number (SSN)"
                inputProps={{
                  'data-inputkey': 'ssn',
                  'data-testid': 'application-ssn-again',
                }}
              />
            </FormControl>
          </Grid>
          <ErrorMessage>{itinErrorMessage}</ErrorMessage>
        </CombineRow>

        <ButtonContainer direction="column" gap={8} margin="24px 0 0">
          <Button
            fullWidth={true}
            onClick={continueWithSSN}
            disabled={formatSSN(patientInformation?.ssn)?.length !== 9}
            data-testid="submit-with-ssn"
          >
            Submit with SSN
          </Button>
          <Button
            variant="secondary"
            fullWidth={true}
            onClick={continueWithoutSSN}
            disabled={formatSSN(patientInformation?.ssn)?.length > 0}
            data-testid="submit-without-ssn"
          >
            I don’t know SSN, submit without
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  return (
    <>
      <Subtitle>To check if a patient will be pre-approved with Cherry, enter their info below.</Subtitle>
      <Subtitle fontWeight={700} color={theme?.main?.green}>
        <Icon src={'lock-secure-active'} width={16} height={16} />
        This is not a hard credit check and does not apply on their behalf.
      </Subtitle>
      <Gap />
      <FormContainer>
        <CombineRow>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="medium"
                type="text"
                label="Patient’s First Name"
                inputProps={{
                  'data-inputkey': 'firstName',
                }}
                id="pre-approval"
                onChange={handleChange}
                defaultValue={patientInformation?.firstName}
                onFocus={handleFocusFirstName}
              />
            </FormControl>
          </Grid>

          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="medium"
                type="text"
                label="Patient’s Last Name"
                inputProps={{
                  'data-inputkey': 'lastName',
                }}
                id="pre-approval-last-name"
                onChange={handleChange}
                defaultValue={patientInformation?.lastName}
                onFocus={handleFocusLastName}
              />
            </FormControl>
          </Grid>
        </CombineRow>

        <CombineRow>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <PhoneNumberInput
                hideIcon={true}
                error={!!ssnErrorMessage}
                inputSize="medium"
                name="phone"
                onFocus={handleFocusPhone}
                onChange={handleChange}
                defaultValue={patientInformation?.phone}
                value={patientInformation?.phone}
                id="location-management-phone"
                label="Patient’s Phone Number"
                inputProps={{
                  'data-inputkey': 'phone',
                  'data-testid': 'application-phone-input',
                }}
              />
            </FormControl>
          </Grid>
        </CombineRow>
        {isDentalOrganization && (
          <SSNFormItem
            handleFocusSSN={handleFocusSSN}
            handleChange={handleChange}
            patientInformation={patientInformation}
            ssnErrorMessage={ssnErrorMessage}
            itinErrorMessage={itinErrorMessage}
          />
        )}
        <CombineRow>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={autocompleteData}
                value={searchTerm}
                // tslint:disable-next-line: jsx-no-lambda
                onChange={(event, newValue: any) => {
                  setAddressValues(newValue);
                }}
                onFocus={handleFocusAddress}
                id="disable-clearable"
                disableClearable={true}
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={(params) => {
                  delete params.inputProps.className;
                  return (
                    <AddressInput
                      {...params}
                      id="street-address"
                      variant="filled"
                      data-testid="street"
                      label="Patient’s Street Address"
                      InputLabelProps={{
                        style: {
                          color: theme.main.midnightBlue50,
                        },
                      }}
                      name="street"
                      onChange={(e) => {
                        getSuggestions(e?.target?.value, setAutocompleteData);
                        setSearchString(e?.target?.value);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </CombineRow>
        <CombineRow>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="medium"
                type="text"
                label="Apt / Unit (Optional)"
                inputProps={{
                  'data-inputkey': 'apartment',
                }}
                id="pre-approval-apartment"
                onChange={handleChange}
                onFocus={handleFocusUnit}
                value={patientInformation?.apartment}
              />
            </FormControl>
          </Grid>
        </CombineRow>
        <CombineRow>
          <Grid item={true} xs={6}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="medium"
                type="text"
                label="City"
                inputProps={{
                  'data-inputkey': 'city',
                }}
                id="pre-approval-city"
                onChange={handleChange}
                onFocus={handleFocusCity}
                value={patientInformation?.city}
              />
            </FormControl>
          </Grid>
          {options.length > 0 ? (
            <Grid item={true} xs={6}>
              <Dropdown
                options={options}
                label="State"
                onChange={handleDropdownChange}
                onClick={handleFocusState}
                defaultValue={patientInformation?.state}
                value={patientInformation?.state}
                size="medium"
              />
            </Grid>
          ) : null}
        </CombineRow>
        <CombineRow>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                inputSize="medium"
                type="text"
                label="Zip Code"
                inputProps={{
                  'data-inputkey': 'zipCode',
                }}
                id="pre-approval-zip-code"
                onChange={handleChange}
                onFocus={handleFocusZipCode}
                value={patientInformation?.zipCode}
              />
            </FormControl>
          </Grid>
        </CombineRow>
        {!isDentalOrganization && (
          <Box my={3}>
            <SSNInfoText>
              <Trans
                i18nKey="preApproval.ssnInfoText"
                components={{
                  b: <BoldText />,
                }}
              />
            </SSNInfoText>
            <SSNFormItem
              handleFocusSSN={handleFocusSSN}
              handleChange={handleChange}
              patientInformation={patientInformation}
              ssnErrorMessage={ssnErrorMessage}
              itinErrorMessage={itinErrorMessage}
            />
          </Box>
        )}
        <ButtonContainer>
          <Button variant="secondary" fullWidth={true} onClick={handleClose}>
            Cancel
          </Button>
          <ButtonGap />
          <Button
            fullWidth={true}
            onClick={defaultContinue}
            disabled={
              !!ssnErrorMessage ||
              !!itinErrorMessage ||
              !allPropertiesNotEmpty(patientInformation) ||
              clearMaskFormat(patientInformation?.phone).length !== 10 ||
              (formatSSN(patientInformation?.ssn)?.length > 0 && formatSSN(patientInformation?.ssn)?.length !== 9)
            }
            data-testid="submit"
          >
            Check for Pre-Approval
          </Button>
        </ButtonContainer>
      </FormContainer>
    </>
  );
};

const Subtitle = styled.div<{ color?: string; fontWeight?: number; m?: string }>`
  display: flex;
  margin: ${(props) => (props?.m ? props?.m : '16.5px 0')};
  font-size: 14px;
  font-weight: ${(props) => (props?.fontWeight ? props?.fontWeight : 400)};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props?.color ? props?.color : props?.theme?.main?.textColor)};
`;

const Gap = styled.div`
  height: 7.5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonGap = styled.div`
  width: 24px;
`;

const ButtonContainer = styled.div<{ direction?: string; gap?: number; margin?: string }>`
  display: flex;
  flex-direction: ${(props) => (props?.direction ? props?.direction : 'row')};
  gap: ${(props) => (props?.gap ? `${props.gap}px` : 0)};
  margin: ${(props) => props?.margin || 0};
`;

const StyledTextField = styled(TextField)`
  .MuiFilledInput-input {
    ::placeholder {
      color: #a2a2a2;
    }

    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
`;

const AddressInput = styled(StyledTextField)`
  .MuiFilledInput-root {
    border: 1px solid ${(props) => props?.theme?.main?.midnightBlue50} !important;
    height: 48px !important;
    padding-top: 0 !important;

    ::before {
      border-bottom: 0 !important;
    }

    input {
      font-size: 16px !important;
      font-weight: 400;
    }
  }

  .MuiFormLabel-filled {
    display: none !important;
  }
  .MuiInputLabel-root.Mui-focused {
    display: none !important;
  }

  .MuiFormLabel-focused {
    display: none !important;
  }
  .MuiInputLabel-root {
    font-size: 16px !important;
    top: -6px;
  }
`;

const CombineRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 8px;

  .MuiGrid-root {
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  color: ${(props) => props?.theme?.main?.red};
`;

const SSNInfoText = styled(Body)`
  margin-bottom: 8px !important;
`;

const BoldText = styled(Body)`
  font-weight: 700 !important;
  display: inline;
`;
