import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { CloseIcon } from 'lib/components';
import React, { useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styled from 'styled-components';

interface Props {
  show: boolean;
  onDateSelected: (isoDate: string) => void;
  toggleDatePicker: any;
  customClassName?: string;
  confirmText: string;
  denyText?: string;
}

export const CherryDatePicker = ({
  show,
  onDateSelected,
  toggleDatePicker,
  customClassName = 'date--picker',
  confirmText,
  denyText = 'Cancel',
}: Props) => {
  const [date, setDate] = useState<Date>(dayjs().toDate());
  const rangePickerRef = useRef(null);

  const onClearDateClick = () => setDate(dayjs().toDate());

  const onDateChange = (e: any) => setDate(e);

  const onOkayDateClick = () => {
    onDateSelected(dayjs(date).toISOString());
    resetCalendar();
  };

  const resetCalendar = () => {
    onClearDateClick();
    toggleDatePicker();
  };

  const FooterContent = () => {
    return (
      // TODO: Refactor
      <Grid container={true} style={{ width: '330px', marginLeft: '9px', justifyContent: 'space-evenly' }}>
        <div style={{ width: '160px' }}>
          <CalendarButton align={'left'} variant="contained" onClick={resetCalendar}>
            {denyText}
          </CalendarButton>
        </div>
        <div style={{ width: '10px', display: 'block' }} />
        <div style={{ width: '160px' }}>
          <CalendarButton align={'right'} onClick={onOkayDateClick}>
            {confirmText}
          </CalendarButton>
        </div>
      </Grid>
    );
  };

  const HeaderContent = () => {
    return (
      <Center>
        <CalendarHeader>
          Schedule Appointment
          <CloseWrapper onClick={resetCalendar}>
            <CloseIcon />
          </CloseWrapper>
        </CalendarHeader>
        <InfoText>
          Schedule a time for a customer to come in. Note: this does not integrate with your calendar.
        </InfoText>
      </Center>
    );
  };

  return (
    <>
      <OverlayWrapper show={show} onClick={resetCalendar} />
      <AbsolutePickerWrapper show={show}>
        <Flex>
          <HeaderContent />
          <Calendar
            ref={rangePickerRef}
            className={customClassName}
            color={'#00c37d'}
            onChange={onDateChange}
            minDate={new Date()}
            date={date}
          />
          <FooterContent />
        </Flex>
      </AbsolutePickerWrapper>
    </>
  );
};

interface AbsolutePickerWrapperInterface {
  show?: boolean;
}

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  margin-top: -26px;
  cursor: pointer;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const CalendarHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #0e202f;
  padding-bottom: 24px;
  border-bottom: 1px solid #dadada;
  width: 100%;
`;

const InfoText = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #868f97;
  max-width: 330px;
  margin: 24px 0;
`;

const OverlayWrapper = styled.div<AbsolutePickerWrapperInterface>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff60;
  cursor: pointer;
`;

const AbsolutePickerWrapper = styled.div<AbsolutePickerWrapperInterface>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1000;
  top: 10%;
  left: calc(100% - 60%);
  padding: 24px;
  box-shadow: 0px 131px 174px rgba(0, 0, 0, 0.11), 0px 60.5653px 80.4455px rgba(0, 0, 0, 0.0815843),
    0px 34.6541px 46.0291px rgba(0, 0, 0, 0.0689459), 0px 21.0348px 27.9393px rgba(0, 0, 0, 0.0593943),
    0px 12.6744px 16.8347px rgba(0, 0, 0, 0.0506057), 0px 7.05791px 9.37463px rgba(0, 0, 0, 0.0410541),
    0px 3.03556px 4.03196px rgba(0, 0, 0, 0.0284157);
  background: #ffffff;

  .rdrInputRanges {
    display: none;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: #00c37d;
  }

  .rdrDayNumber span {
    font-weight: 600;
  }
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    display: none;
  }
  .rdrDayHovered,
  .rdrDay .rdrSelected {
    background-color: #00c37d;
    .rdrDayNumber span {
      color: #ffffff;
      font-weight: 600;
    }
  }
  .rdrDay .rdrSelected {
    border-radius: 0px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .rdrWeekDays {
    color: #000000 !important;
    font-weight: 600;
  }
  .rdrDays button {
    border: 1px solid #dfdfdf;
    width: 45px !important;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DatePickerBottomActionsWrapper {
  align: string;
  variant?: string;
}

const CalendarButton = styled.button<DatePickerBottomActionsWrapper>`
  background-color: ${(props) => (props.variant === 'contained' ? '#FFFFFF' : props.theme.main.green)};
  color: ${(props) => (props.variant === 'contained' ? props.theme.main.green : '#FFFFFF')};
  border: ${(props) => `1px solid ${props.theme.main.green}`};
  // margin-left: ${(props) => (props.align !== 'left' ? '10px' : '0px')};
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  border-radius: 2px;
  text-align: center;
  padding: 10px 0;

  &:focus,
  &:hover {
    outline: none;
  }
`;
