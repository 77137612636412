import { Grid } from '@mui/material';
import { ButtonPrimary, DashCard, PageHeader, TableLoader } from 'lib/components';
import client from 'lib/graphql/client';
import { GET_SHOP_PRODUCTS } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import { Container, Row } from 'lib/styles';
import { formatTextCapitalize, settingsTableStyle } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

enum STATUS {
  LIVE = 'LIVE',
  DRAFT = 'DRAFT',
}

const ShopHome = () => {
  const { organization } = useStore();
  const alert = useAlert();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const { data: { fetchShopProducts } = {} } = await client.query({
        query: GET_SHOP_PRODUCTS,
        variables: {
          input: {
            pagination: {
              limit: 20,
              offset: 0,
              order: 'DESC',
              orderBy: 'createdAt',
            },
            organizationId: organization?.id,
          },
        },
      });

      if (fetchShopProducts.contents.length > 0) {
        setProductData(fetchShopProducts.contents);
      }
    } catch (err) {
      alert.error('An error occurred please try again');
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToProductDetail = (id) => () => {
    navigate(`/shop/editor/product/${id}`);
  };

  const tableColumns = [
    {
      name: 'Photo',
      selector: (row) => row.image,
      cell: (row) => {
        return <img width={96} height={64} src={row.image?.link} alt="product" />;
      },
      width: '150px',
    },
    {
      name: 'Offering Title',
      selector: (row) => row.title,
      minWidth: '350px',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      cell: (row) => formatTextCapitalize(row.type),
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => <Status status={row.status}>{formatTextCapitalize(row.status)}</Status>,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: (row) => <CustomButtonPrimary text="Edit" onClick={handleNavigateToProductDetail(row.id)} />,
      minWidth: '200px',
      right: true,
    },
  ];

  const handleNavigateCreateProduct = () => {
    navigate('/shop/editor/create');
  };

  return (
    <Container>
      <PageHeader title="Shop Editor" />

      <Row marginBottom="24px">
        <Grid container={true} justifyContent="space-between">
          <Grid item={true}>
            <SubTitle>Your Shop Offerings</SubTitle>
          </Grid>
          <Grid item={true}>
            <ButtonPrimary text="Add Offering" onClick={handleNavigateCreateProduct} />
          </Grid>
        </Grid>
      </Row>

      <DashCard>
        <CardTitle>All Offerings</CardTitle>
        <Description>
          Offerings are shown on your site in order, with the top of the table as highest priority.
        </Description>
        <CustomDataTable
          progressPending={loading}
          progressComponent={<TableLoader count={4} />}
          noHeader={true}
          data={productData}
          customStyles={settingsTableStyle}
          columns={tableColumns}
        />
      </DashCard>
    </Container>
  );
};

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 16px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div {
    font-weight: 700;
  }

  .rdt_TableRow {
    align-items: center;
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }
`;

const Status = styled.div<{ status }>`
  font-weight: 700;
  color: ${(props) => props.status === STATUS.LIVE && 'rgba(0, 195, 125, 1)'};
`;

const SubTitle = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

const CardTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const CustomButtonPrimary = styled(ButtonPrimary)`
  text-transform: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #879097 !important;
  color: #879097 !important;
  border-radius: 4px !important;
  margin-right: 16px !important;

  &:active,
  &:focus {
    background-color: #ffffff !important;
  }
  &:hover {
    background-color: #dadada50 !important;
  }
`;

export default ShopHome;
