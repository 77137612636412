import { Modal } from 'lib/components';
import React from 'react';
import styled from 'styled-components';

export const MarketingMaterialModal = ({ show, pdfUrl, closeModal, fullModal }) => {
  return (
    <CustomModal
      open={show}
      fullCustomModal={fullModal}
      closeOnDocumentClick={true}
      title=""
      titleAlignment="left"
      onClose={closeModal}
    >
      <iframe src={pdfUrl} style={{ width: '600px', height: '500px', border: 'none' }} />
    </CustomModal>
  );
};

const CustomModal = styled(Modal)<{ fullCustomModal?: boolean }>`
  width: 100% !important;
  height: 100vh !important;
  box-shadow: 0px 131px 174px rgb(0 0 0 / 11%), 0px 60.5653px 80.4455px rgb(0 0 0 / 8%),
    0px 34.6541px 46.0291px rgb(0 0 0 / 7%), 0px 21.0348px 27.9393px rgb(0 0 0 / 6%),
    0px 12.6744px 16.8347px rgb(0 0 0 / 5%), 0px 7.05791px 9.37463px rgb(0 0 0 / 4%),
    0px 3.03556px 4.03196px rgb(0 0 0 / 3%);
  margin: 20px;

  div {
    padding: 0px !important;
    margin: 0px !important;
  }
`;
