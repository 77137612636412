import { gql } from '@apollo/client/core';

export const GET_MERCHANT_FINDER_DETAIL = gql`
  query GetMerchantDetailRequest($input: GetMerchantDetailRequest) {
    getMerchantDetailRequest(input: $input) {
      id
      name
      organizationId
      merchantId
      slug
      flags
      contact {
        phone
        website
      }
      address {
        street
      }
      services {
        id
        name
      }
      industry {
        id
        name
      }
    }
  }
`;

export const GET_ALL_SERVICES = gql`
  query getAllServices($input: GetAllServicesRequest) {
    getAllServices(input: $input) {
      total
      contents {
        id
        name
        industryId
      }
    }
  }
`;

export const GET_MERCHANT_COMMUNICATION_SETTINGS = gql`
  query getMerchantCommunicationSettings($input: GetMerchantCommunicationSettings) {
    getMerchantCommunicationSettings(input: $input) {
      merchantId
      displayName
      phone
      website
    }
  }
`;
