import { useMutation } from '@apollo/client';
import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { PATCH_MERCHANT } from 'lib/graphql/mutations';
import { Column } from 'lib/styles';

import { ErrorOutlineIcon, FileFindIcon, LoadingAnimationIcon, SuccessIcon } from 'lib/components';

import { Button } from '@frontend/cherry-library';
import useAgent from 'lib/hooks/useAgent';
import { ACCOUNT_CLASSIFICATION, ACCOUNT_TYPES, InfoFormState, MODAL_STATUS } from './BankInformation/constants';
import InfoDialogSubtitle from './BankInformation/InfoDialogSubtitle';
import InfoForm from './BankInformation/InfoForm';
import SummaryTable from './BankInformation/SummaryTable';
import { CherryDialogTitle, DialogContainer, DialogContent, SubTitle } from './Dialog';

interface IProps {
  open: boolean;
  handleClose: () => void;
  fetchMerchantDetail: () => void;
  selectedMerchant: any;
}

export const BankInformationDialog = ({ open, handleClose, selectedMerchant, fetchMerchantDetail }: IProps) => {
  const alert = useAlert();
  const [patchMerchant, { loading: patchLoading }] = useMutation(PATCH_MERCHANT);
  const { isAgent } = useAgent();

  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [state, setState] = useState<InfoFormState>({
    accountNickname: null,
    accountName: null,
    routingNumber: null,
    accountNumber: null,
    accountType: ACCOUNT_TYPES.CHECKING,
    accountClassification: ACCOUNT_CLASSIFICATION.PERSONAL,
  });

  const [modalStatus, setModalStatus] = useState<MODAL_STATUS | null>(null);
  const [isInDoubleCheckList, setIsInDoubleCheckList] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const setMerchantDetail = async () => {
    try {
      if (selectedMerchant) {
        const {
          name,
          address,
          bankingInfo: {
            accountNickname,
            accountName,
            routingNumber,
            accountNumber,
            accountType,
            accountClassification,
          },
        } = selectedMerchant || {};

        setState((prevState) => {
          return {
            ...prevState,
            accountNickname,
            accountName,
            routingNumber,
            accountNumber,
            accountType,
            accountClassification,
          };
        });

        setSelectedName(`${name} ${address?.city?.name ? ' - ' + address?.city?.name : ''}`);
      } else {
        alert.info('Merchant detail not found');
      }
    } catch (error) {
      alert.error('Merchant detail fetch error');
    }
  };

  useEffect(() => {
    if (open) {
      setMerchantDetail();
    }
  }, [open]);

  const handlePatchMerchant = async () => {
    setIsInDoubleCheckList(false);
    if (selectedMerchant) {
      try {
        console.log(selectedMerchant, 'HELP');
        const merchantId = selectedMerchant.id;
        const {
          accountNickname,
          accountName,
          accountType,
          accountClassification,
          accountNumber,
          routingNumber,
        } = state;

        const { data } = await patchMerchant({
          variables: {
            input: {
              merchantId,
              accountNickname,
              accountName,
              accountType,
              accountClassification,
              accountNumber,
              routingNumber,
            },
          },
        });
        if (data?.patchMerchant?.id) {
          setModalStatus(data?.patchMerchant?.data?.bankingInfo?.accountStatus || MODAL_STATUS.SUCCESS);
          fetchMerchantDetail();
        } else {
          throw Error('An error occured');
        }
      } catch (e) {
        alert.error(`Update Error. ${e}`);
        return;
      }
    }
  };

  const handleFormClose = () => {
    handleClose();
    setTimeout(() => {
      setModalStatus(null);
    }, 200);
  };

  const handleFormSubmit = (formState: InfoFormState) => {
    setState(formState);
    setModalStatus(MODAL_STATUS.SUMMARY);
  };

  const setModalStatusToEdit = () => {
    setModalStatus(null);
  };

  const isButtonDisabled = (formState: InfoFormState, validRoutingNumber: boolean, validAccountNumber: boolean) => {
    const disabled =
      !formState?.accountName ||
      !formState?.routingNumber ||
      !formState.accountNumber ||
      !formState.accountType ||
      !formState.accountClassification ||
      !validRoutingNumber ||
      !validAccountNumber ||
      isAgent;

    setButtonDisabled(!!disabled);
  };

  return (
    <Dialog maxWidth="sm" onClose={handleFormClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContainer>
        <CherryDialogTitle id="customized-dialog-title" onClose={handleFormClose}>
          Update Bank
          {selectedName && <SelectedName>{selectedName}</SelectedName>}
        </CherryDialogTitle>

        {isInDoubleCheckList && (
          <RedTitle>
            Please double check to make sure your account name matches the account holder’s legal name instead of the
            business name.
          </RedTitle>
        )}

        <IconContainer>
          {modalStatus === MODAL_STATUS.SUCCESS && <SuccessIcon />}
          {modalStatus === MODAL_STATUS.FAILED && <ErrorOutlineIcon />}
          {modalStatus === MODAL_STATUS.REVIEW && <FileFindIcon />}
        </IconContainer>

        {!isInDoubleCheckList && <InfoDialogSubtitle modalStatus={modalStatus} patchLoading={patchLoading} />}

        <IconContainer>{patchLoading && <LoadingAnimationIcon />}</IconContainer>

        <DialogContent>
          {!patchLoading && !modalStatus && (
            <InfoForm
              state={state}
              handleFormClose={handleFormClose}
              buttonDisable={buttonDisabled}
              onFormSubmit={handleFormSubmit}
              isButtonDisabled={isButtonDisabled}
            />
          )}

          {!patchLoading && modalStatus === MODAL_STATUS.SUMMARY && (
            <SummaryTable
              state={state}
              setModalStatusToEdit={setModalStatusToEdit}
              buttonDisable={buttonDisabled}
              handlePatchMerchant={handlePatchMerchant}
            />
          )}

          {(modalStatus === MODAL_STATUS.SUCCESS ||
            modalStatus === MODAL_STATUS.FAILED ||
            modalStatus === MODAL_STATUS.REVIEW) && (
            <Column>
              <Button fullWidth={true} onClick={handleFormClose}>
                Okay
              </Button>
            </Column>
          )}
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
};

const SelectedName = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
`;

const IconContainer = styled.div`
  text-align: center;

  svg {
    width: 80px;
    height: 80px;
    margin-top: 24px;
    display: inline-block;
  }
`;

const RedTitle = styled(SubTitle)`
  color: ${(props) => props.theme.main.errorColor} !important;
`;
