import { CircularProgress, Dialog as MuiDialog, DialogActions as MuiDialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import images from 'assets/images';
import { BusEvent, useEventBus, useSendApplicationLink } from 'lib/services';

import { theme } from 'config/theme';
import { useSegment } from 'lib/hooks/useSegment';
import { formatPhoneNumber } from 'lib/utils';
import { CherryDialogTitle } from 'pages/desktop/Settings/components/Dialog';
import { ButtonContainer } from 'pages/desktop/Transactions/CommonStyles';
import { useAlert } from 'react-alert';

export interface SendBulkSmsModalProps {
  onClose?: () => void;
}

const requiredColumnNumber = 1;

interface CSVRowData {
  rowNumber: number;
  phoneNumber: string;
  valid?: boolean;
}

enum Step {
  UPLOAD,
  VALIDATION,
  RESULT,
}

export const SendBulkSmsModal = (props: SendBulkSmsModalProps) => {
  const alert = useAlert();
  const { onEvent } = useEventBus();
  const [step, setStep] = useState(Step.UPLOAD);
  const [rowList, setRowList] = useState<CSVRowData[]>([]);
  const { sendBulkApplication } = useSendApplicationLink();
  const { trackSegmentEvent, applicationName } = useSegment();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const cleanUpData = () => {
    setStep(Step.UPLOAD);
    setRowList([]);
  };

  const onClickClose = () => {
    setVisible(false);

    /** Wait for the closing animation to complete */
    setTimeout(() => {
      cleanUpData();
    }, 300);
  };

  const onClickCloseWithSuccess = () => {
    trackSegmentEvent('Success Bulk Send Close', {
      application: applicationName,
    });

    onClickClose();
  };

  const onClickBack = () => setStep(Step.UPLOAD);

  const downloadCsv = () => {
    trackSegmentEvent('Download Bulk Contacts Template', {
      application: applicationName,
    });

    window.open('/bulk-recipients-template.csv', '_blank');
  };

  const uploadCSV = () => {
    trackSegmentEvent('Upload Bulk Contacts', {
      application: applicationName,
    });

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (ev) => {
      const target = ev.target as HTMLInputElement;
      const files = target.files;

      if (files && files.length > 0) {
        const file = files.item(0);
        const reader = new FileReader();
        reader.readAsText(file as Blob);
        reader.onload = () => {
          try {
            const csv = reader.result as string;
            const obj = csv
              .split(/\r?\n/)
              .filter((elm) => elm !== '')
              .map((elm) => elm.split(','));

            const array: CSVRowData[] = [];
            for (let i = 1; i < obj.length; i++) {
              const elm = obj[i];
              const rowNumber = i + 1;

              if (elm.length !== requiredColumnNumber) {
                throw new Error(
                  `Invalid csv. Row ${rowNumber} has ${elm.length} column but instead it should have ${requiredColumnNumber} column.`,
                );
              }

              const phoneNumber = elm[0];
              const formatted = formatPhoneNumber(phoneNumber);
              const valid = /\((\d{3})\) (\d{3})-(\d{4})\./.test(formatted.concat('.'));

              array.push({
                rowNumber,
                phoneNumber: formatted,
                valid,
              });
            }

            setRowList(array);
            setStep(Step.VALIDATION);
          } catch (err) {
            alert.error((err as Error).toString());
          }
        };
      }
    };
    input.click();
  };

  const getValidRowCount = () => rowList.reduce((val, elm) => (elm.valid ? ++val : val), 0);

  const allRowsValid = () => getValidRowCount() === rowList.length;

  const sendSms = async () => {
    setLoading(true);
    const status = allRowsValid() ? 'fullUpload' : 'partialUpload';

    trackSegmentEvent('Send Bulk Applications', {
      application: applicationName,
      status,
    });

    const phones = rowList.filter((elm) => elm.valid).map((elm) => elm.phoneNumber.replace(/[^\d]/g, ''));

    try {
      await sendBulkApplication(phones);
      setStep(Step.RESULT);

      trackSegmentEvent('Bulk Applications Sent', {
        application: applicationName,
        status,
        was_success: true,
      });
    } catch (error) {
      const message = (error as Error)?.message;
      alert.error(message);

      trackSegmentEvent('Bulk Applications Sent', {
        application: applicationName,
        status,
        was_success: false,
        error: message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onEvent(BusEvent.OPEN_SEND_SMS_MODAL, () => {
      setVisible(true);
    });
  }, []);

  return (
    <Dialog open={visible}>
      {step === Step.UPLOAD && (
        <>
          <DialogTitle id="dialog-title" onClose={onClickClose}>
            Add Recipients
          </DialogTitle>
          <Content>
            <CenteredText>
              Upload a CSV file with recipient phone numbers. Your file should have <b>phone numbers only</b>.
            </CenteredText>

            <CenteredText>Example CSV File:</CenteredText>
            <GreenText onClick={downloadCsv}>bulk-recipients-template.csv</GreenText>
          </Content>

          <MuiDialogActions>
            <ActionButton onClick={uploadCSV}>Upload CSV File</ActionButton>
          </MuiDialogActions>
        </>
      )}
      {step === Step.VALIDATION && (
        <>
          <DialogTitle id="dialog-title" onClose={onClickClose}>
            {getValidRowCount()} out of {rowList.length} were Successfully Added
          </DialogTitle>
          <Content>
            <Text>
              <b>{getValidRowCount()}</b> patients’ phone numbers successfully added. They will each receive an
              application link via text message.
              {!allRowsValid() && (
                <>
                  <br />
                  <br />
                  However, we were not able to add <b>{rowList.length - getValidRowCount()} phone numbers</b>. Please
                  review the CSV file and submit again.
                  <br />
                  <br />
                  <ul>
                    {rowList
                      .filter((elm) => !elm.valid)
                      .map((elm, i) => (
                        <li key={i}>
                          Row {elm.rowNumber}, <b>invalid phone format</b>
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </Text>
          </Content>

          <MuiDialogActions>
            <ActionButton outlined={true} onClick={onClickBack}>
              Back
            </ActionButton>
            <ActionButton disabled={loading} onClick={sendSms}>
              {loading ? <CircularProgress color="inherit" /> : <>Send {getValidRowCount()} Applications</>}
            </ActionButton>
          </MuiDialogActions>
        </>
      )}
      {step === Step.RESULT && (
        <>
          <DialogTitle id="dialog-title" onClose={onClickClose}>
            Success!
          </DialogTitle>
          <Content>
            <ImgContainer>
              <img src={images.circleCheck.default} alt="check" />
            </ImgContainer>
            <CenteredText>{getValidRowCount()} patients successfully received an application link.</CenteredText>
          </Content>

          <MuiDialogActions>
            <ActionButton onClick={onClickCloseWithSuccess}>Okay</ActionButton>
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 561px;
    padding: 24px;
    overflow: visible;
  }
`;

const Content = styled.div`
  font-size: 14px;
  margin: 24px 0;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DialogTitle = styled(CherryDialogTitle)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.main.neutralGray};
  padding: 0 0 24px !important;

  .MuiTypography-h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    color: ${(props) => props.theme.main.midnightBlue};
  }
`;

const ActionButton = styled(ButtonContainer)<{ outlined?: boolean }>`
  width: 100%;
  display: flex;
  margin: 18px 0 0 0;
  font-size: 16px;
  ${(props) =>
    props.outlined
      ? `
    background-color: ${theme.main.white}; 
    color: ${theme.main.green};
    &:hover,
    &:focus {
      background-color: ${theme.main.green10}
    }
  `
      : ''}
`;

const Text = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

const GreenText = styled(CenteredText)`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
`;
