import styled from 'styled-components';

interface ButtonProps {
  color?: string;
  disabled?: boolean;
}

interface ContainerProps {
  secondary?: boolean;
}

interface TitleProps {
  gap?: boolean;
  secondary?: boolean;
}

export const Container = styled.div`
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RefundContainer = styled.div<ContainerProps>`
  display: flex !important;
  flex-direction: column;
  width: ${(props) => (props.secondary ? '450px' : '320px')};
  text-align: center;
`;

export const RefundTitle = styled.span<TitleProps>`
  font-size: 16px;
  color: #0e202f;
  text-align: center;
  line-height: 22px;
  font-weight: bold;
  padding: ${(props) => (props.secondary ? '0px 0px 0px 100px' : 'unset')};
  margin-top: ${(props) => (props.gap ? '70px' : '32px')};
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 8px;
  padding: 0px;
`;

export const CancelButton = styled.button<ButtonProps>`
  background-color: white;
  width: 100%;
  border: 1px solid ${(props) => (props.color ? props.color : props.theme.main.green)};
  border-radius: 2px;
  color: ${(props) => (props.color ? props.color : props.theme.main.green)};
  padding: 12px 0px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  font-size: 12px;
  font-family: 'Open Sans' !important;
`;

export const ApplyButton = styled.button<ButtonProps>`
  width: 100%;
  background-color: ${(props) => (props.disabled ? '#DADADA' : props.theme.main.green)};
  border: 1px solid ${(props) => (props.disabled ? '#DADADA' : props.theme.main.green)};
  border-radius: 2px;
  color: ${(props) => (props.disabled ? '#9FA6AC' : 'white')};
  padding: 12px 0px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  font-size: 12px;
  font-family: 'Open Sans' !important;
`;

export const TextButtonContainer = styled.div`
  padding: 16px 0px 40px;
`;

export const TextButton = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0e202f;
`;

export const TextButtonColor = styled.span`
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
`;
