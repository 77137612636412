import { DialogProps as MuiDialogProps } from '@mui/material';
import GenericFileModal from 'pages/desktop/DocumentRequests/components/GenericFileModal/GenericFileModal';
import React from 'react';
import styled from 'styled-components';

interface RemoveFileModalProps extends MuiDialogProps {
  onCloseModal: () => void;
  onRemove: (index: number) => void;
  fileIndex: number;
}

const RemoveFileModal = ({ onCloseModal, onRemove, fileIndex, ...rest }: RemoveFileModalProps) => {
  const removeFile = () => {
    onRemove(fileIndex);
  };

  return (
    <GenericFileModal
      {...rest}
      title="Are You Sure?"
      onClose={onCloseModal}
      buttonLeftText="Go Back"
      onButtonLeftClick={onCloseModal}
      mainButtonText="Yes, Remove File"
      onMainButtonClick={removeFile}
    >
      <Text>Are you sure you want to remove this file?</Text>
      <Text>This action cannot be undone.</Text>
    </GenericFileModal>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export default RemoveFileModal;
