import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { ExpandableInfoText, ExpandableWithTitle } from 'lib/components/mobile';
import CurrencyUtil from 'lib/utils/currency';

export const RefundHistory = ({ refunds, funds }) => {
  const refundListWithDate = refunds.map((item) => {
    return {
      ...item,
      date: funds.find((fund) => fund.id === item.fundId)?.['updatedAt'],
      refundStatus: funds.find((fund) => fund.id === item.fundId)?.['status'],
    };
  });
  refundListWithDate.reverse();

  return (
    <ExpandableWithTitle title={'Refund History'} expanded={true} color={'#f2f4f5'}>
      {refundListWithDate?.map((item, index) => (
        <>
          <ExpandableInfoText
            labelColor={'#879097'}
            name={`Refund #${refundListWithDate.length - index}`}
            value={CurrencyUtil.toCurrencyString(item?.amount, true)}
          />
          <ExpandableInfoText
            labelColor={'#879097'}
            name={'Date'}
            value={dayjs(item.date).format('MMM DD, YYYY hh:mm A')}
          />
          <ExpandableInfoText
            labelColor={'#879097'}
            name={'Status'}
            value={item?.refundStatus === 'COMPLETED' ? 'COMPLETED' : 'PROCESSING'}
          />
          {refundListWithDate.length > index + 1 && <Divider />}
        </>
      ))}
    </ExpandableWithTitle>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #879097;
  margin-top: 4px;
  margin-bottom: 16px;
`;
