import { Button, Heading } from '@frontend/cherry-library';
import { ReactComponent as BackIcon } from 'assets/images/short_left.svg';
import { useSegment } from 'lib/hooks/useSegment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EstimatePreview from './Components/EstimatePreview';
import EstimateTable from './Components/EstimateTable';
import ShareEstimate from './Components/ShareEstimate';
import { BackButtonWrapper, ContentLayout, FormContainer, PageWrapper } from './styles';
import { LocationState, PaymentOptions, PdfPatient } from './types';

const PaymentEstimatorPage = () => {
  const location = useLocation() as LocationState;
  const enteredAmount = location?.state?.amount;

  const [patientInfo, setPatientInfo] = useState<PdfPatient>();
  const [purchaseAmount, setPurchaseAmount] = useState<number | null>(enteredAmount);
  const [tableData, setTableData] = useState<PaymentOptions[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [isBottomBackButtonVisible, setBottomBackButtonVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const { segmentEventHandler } = useSegment();

  const goBackToDashboard = () => {
    segmentEventHandler('PRACTICE_PORTAL.PAYMENT_ESTIMATOR_PAGE.BACK_TO_DASHBOARD.CLICKED');
    navigate('/dashboard');
  };

  return (
    <PageWrapper>
      <BackButtonWrapper>
        <Button leftIcon={BackIcon} onClick={goBackToDashboard} id="payment-estimator-back-button" variant="secondary">
          Back to Dashboard
        </Button>
      </BackButtonWrapper>
      <Heading className="payment-estimator-header" text="Payment Estimator" level="2" />

      <ContentLayout>
        <FormContainer>
          <EstimateTable
            estimationLoading={tableLoading}
            setEstimationLoading={setTableLoading}
            createdPlan={tableData}
            setCreatedPlan={setTableData}
            purchaseAmount={purchaseAmount}
            setPurchaseAmount={setPurchaseAmount}
          />
          <ShareEstimate
            setEnteredPatientInfo={setPatientInfo}
            selectedPlan={tableData?.find((plan) => plan?.term === 12)}
            enteredAmount={purchaseAmount}
            setActionTookPlace={setBottomBackButtonVisible}
          />
          {isBottomBackButtonVisible && (
            <Button
              fullWidth={true}
              leftIcon={BackIcon}
              onClick={goBackToDashboard}
              id="payment-estimator-back-button"
              variant="secondary"
            >
              Back to Dashboard
            </Button>
          )}
        </FormContainer>

        <EstimatePreview isTableLoading={tableLoading} patient={patientInfo} plan={tableData} />
      </ContentLayout>
    </PageWrapper>
  );
};
export default PaymentEstimatorPage;
