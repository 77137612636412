import { Body, Button, Heading } from '@frontend/cherry-library';
import images from 'assets/images';
import dayjs from 'dayjs';
import { usePatientTracker } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';
import { DentalCheckoutModal } from 'pages/desktop/Dashboard/DentalDashboard/views/DentalCheckoutModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BodyWrapper,
  BoxContainerColumn,
  CheckoutBlockWrapper,
  CheckoutTextWrapper,
  ColumnBox,
  RowBox,
  TitleWrapper,
} from '../styles';

const Approval = () => {
  const { applyWithPatientFormData, organization, approvedApplication } = useStore();
  const navigate = useNavigate();
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [checkoutReadyBorrower, setCheckoutReadyBorrower] = useState();
  const { fetchCheckoutReadyTreatments, checkoutReadyData } = usePatientTracker({});
  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
  };

  const redirectToCheckout = async () => {
    await fetchCheckoutReadyTreatments();
    const borrower =
      checkoutReadyData?.find(
        (treatment) => treatment?.application?.borrowerId === applyWithPatientFormData?.borrowerId,
      ) || {};
    setCheckoutReadyBorrower(borrower);
    setCheckoutModalOpen(true);
  };
  const redirectToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <BoxContainerColumn>
      <DentalCheckoutModal
        selectedItemData={checkoutReadyBorrower}
        open={checkoutModalOpen}
        onClose={closeCheckoutModal}
        onSubmit={closeCheckoutModal}
        isPatientTracker={true}
      />
      <RowBox>
        <div>
          <Heading
            text={`${applyWithPatientFormData?.firstName} ${applyWithPatientFormData?.lastName} is approved for`}
            level="4"
          />

          <TitleWrapper>
            <Heading text={FormatAmount(approvedApplication?.balanceAvailable || 0)} level="2" />
          </TitleWrapper>

          <Body>
            This approval is exclusive to {organization?.name} and is valid until{' '}
            <Body fontWeight={700}>{dayjs(approvedApplication?.expireAt).format('MMMM D, YYYY')}</Body>
          </Body>

          <CheckoutBlockWrapper>
            <TitleWrapper>
              <Heading text="Ready to checkout?" level="4" />
            </TitleWrapper>
            <CheckoutTextWrapper>
              <Body>If your patient is ready to complete their purchase, you can process checkout now:</Body>
            </CheckoutTextWrapper>
            <Button size="large" onClick={redirectToCheckout}>
              Process Checkout Now
            </Button>
          </CheckoutBlockWrapper>
        </div>
        <img width={300} alt="apply-with-patient-success-gif" src={images.successCherry} />
      </RowBox>

      <ColumnBox>
        <TitleWrapper>
          <Heading text="Return to dashboard" level="4" />
        </TitleWrapper>
        <BodyWrapper>
          <Body>Return to your Practice Portal homepage.</Body>
        </BodyWrapper>
        <Button onClick={redirectToDashboard}>Return to Dashboard</Button>
      </ColumnBox>
    </BoxContainerColumn>
  );
};
export default Approval;
