import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { DetailChartContainer, FixedDecimalFormat, ReportingPageLayout } from 'lib/components';
import { LineChart } from 'lib/components/Charts';
import { DAILY_FREQUENCY_ENUM } from 'lib/constants';
import { FETCH_EXPIRED_APPROVALS } from 'lib/graphql/queries';
import { ReportingAnalyticsEventNames, useAnalytics, useOrganizationGroupTreeRadioGroup } from 'lib/hooks';
import { useDownloadChartData } from 'lib/hooks/useDownloadChartData';
import useStore from 'lib/hooks/useStore';
import { DropdownOption } from 'lib/types';

export const ExpiredApprovals = () => {
  const client = useApolloClient();
  const { trackEvent } = useAnalytics();
  const { organization, selectedOrganizationGroup } = useStore();
  const { downloadChartCSV } = useDownloadChartData();
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>('All');
  const [expiredApprovals, setExpiredApprovals] = useState<any>();
  const [expiredApprovalsTimeBreak, setExpiredApprovalsTimeBreak] = useState<DAILY_FREQUENCY_ENUM>(
    DAILY_FREQUENCY_ENUM.DAILY,
  );
  const [expiredSoon, setExpiredSoon] = useState<any>();
  const { findNodeAndReturnArrays, organizationGroupTree } = useOrganizationGroupTreeRadioGroup();
  const [location, setLocation] = useState<DropdownOption>({ value: 'selectAll', label: 'All Accounts' });
  const [organizationIds, setOrganizationIds] = useState<string | null>(`${organization?.id}`);

  const [filterSelectedDateValue, setFilterSelectedDateValue] = useState<any>({
    from: dayjs().subtract(1, 'month'),
    to: dayjs().utc().endOf('day').toISOString(),
  });

  useEffect(() => {
    fetchSummaryChartData();
  }, [filterSelectedDateValue, expiredApprovalsTimeBreak, selectedMerchant, organizationIds]);

  const fetchSummaryChartData = async () => {
    const { data } = await client.query({
      query: FETCH_EXPIRED_APPROVALS,
      variables: {
        totalExpiredInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
          timeBreak: expiredApprovalsTimeBreak,
        },
        expiredSoonInput: {
          organizationIds,
          merchantIds: selectedMerchant === 'All' ? null : selectedMerchant,
          startDate: dayjs(filterSelectedDateValue.from).format('YYYY-MM-DD'),
          endDate: dayjs(filterSelectedDateValue.to).format('YYYY-MM-DD'),
        },
      },
    });
    setExpiredApprovals(data?.getTotalExpiredApprovalsSummary);
    setExpiredSoon(data?.getTotalExpiringSoonApprovalsSummary);
  };

  const onExpiredTimeBreakSelected = (timeBreak: DAILY_FREQUENCY_ENUM) => {
    setExpiredApprovalsTimeBreak(timeBreak);
  };

  const onTotalExpiredApprovalDownloadClicked = () => {
    trackEvent({
      action: ReportingAnalyticsEventNames.EXPIRED_APPROVALS_DOWNLOAD_CLICK,
    });
    downloadChartCSV(expiredApprovals?.contents, ['name', 'data'], 'Date, Expired Approvals');
  };

  const onSelectedDateChange = (selectedDate: { from: string; to: string }) => {
    setFilterSelectedDateValue(selectedDate);
  };

  const organizationGroupSelectionHandler = (data) => {
    setLocation(data);
    if (data?.value === 'selectAll') {
      setOrganizationIds(String(organization?.id));
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree?.[0],
        selectedOrganizationGroup?.id,
        'ORGANIZATION_GROUP',
      );
      setLocation({ value: 'selectAll', label: 'All Accounts' });
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    } else {
      const [organizationIdList, merchantIds] = findNodeAndReturnArrays(
        // @ts-ignore
        organizationGroupTree[0],
        data.value,
        data.type,
      );
      setOrganizationIds(organizationIdList?.join(',') || '');
      setSelectedMerchant(merchantIds?.join(',') || '');
    }
  };

  return (
    <ReportingPageLayout
      title={'Expired Approvals'}
      onDateRangeChange={onSelectedDateChange}
      onLocationChange={setSelectedMerchant}
      organizationGroupSelectionHandler={organizationGroupSelectionHandler}
      selectedOrganizationGroup={location}
      selectedMerchant={selectedMerchant as string}
    >
      <DetailChartContainer
        id={'Total-expired'}
        mainStatistic={{
          header: 'Total Expired Approvals',
          description: <FixedDecimalFormat amount={expiredApprovals?.totalExpiredApprovals} prefix="$" />,
        }}
        onTimeBreakSelected={onExpiredTimeBreakSelected}
        onDownloadClick={onTotalExpiredApprovalDownloadClicked}
        statistics={[
          {
            header: 'Expired Rate',
            description: <FixedDecimalFormat amount={expiredApprovals?.expiredRate * 100} suffix="%" />,
          },
          {
            header: 'Expired Approvals',
            description: expiredApprovals?.expiredApprovals,
          },
          {
            header: 'Avg Expired Approval Amount',
            description: <FixedDecimalFormat amount={expiredApprovals?.avgExpiredApprovalAmount} prefix="$" />,
          },
        ]}
      >
        <LineChart
          data={expiredApprovals?.contents}
          height={480}
          toolTipLabel={'Expired Approvals'}
          lineColor="#ec3360"
          dataType="money"
          labelTitle="Expired Approval Amount"
        />
      </DetailChartContainer>
      <DetailChartContainer
        id={'Expiring-soon'}
        mainStatistic={{
          header: 'Total Approvals Expiring Soon',
          description: <FixedDecimalFormat amount={expiredSoon?.totalApprovalExpiringSoon} prefix="$" />,
        }}
        statistics={[
          {
            header: 'Approvals Expiring Soon',
            description: expiredSoon?.approvalExpiringSoon,
          },
          {
            header: 'Avg Amount of Approvals Expiring Soon',
            description: <FixedDecimalFormat amount={expiredSoon?.avgAmountOfApprovalsExpiringSoon} prefix="$" />,
          },
        ]}
      />
    </ReportingPageLayout>
  );
};
