import { useApolloClient } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

import { ButtonPrimary, DashCard, DashComponent, LocationIcon, TableLoader } from 'lib/components';
import { LOCATION_MANAGEMENT_STATUS_LIST } from 'lib/constants';
import { GET_MERCHANT_LEADS } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks';
import useStore from 'lib/hooks/useStore';
import { Column, Row } from 'lib/styles';
import { clearSpecialChars, settingsTableStyle } from 'lib/utils';

import { StepControlDialog } from '../components/LocationManagement/StepControlDialog';
import { useUserManagement } from '../useUserManagement';

export const LocationManagement = () => {
  const { captureException } = useSentry();
  const client = useApolloClient();
  const { organization } = useStore();
  const [openDialog, setOpenDialog] = useState(false);
  const [merchantLeads, setMerchantLeads] = useState([]);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState<string | null>(null);

  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const { inlinePocUsers, isPocLoading } = useUserManagement();

  const tableColumns = [
    {
      name: 'Location Name',
      selector: (row) => row.name,
      ignoreRowClick: true,
      minWidth: '230px',
      maxWidth: '230px',
    },
    {
      name: 'Address',
      selector: (row) => row.email,
      cell: (row) => (
        <AddressContainer>
          <AddressText>{row.address.street}</AddressText>
          <AddressText>
            {row.address.city}, {row.address.state} {row.address.zip}
          </AddressText>
        </AddressContainer>
      ),
      minWidth: '230px',
      maxWidth: '230px',
    },
    {
      name: 'Point of Contact',
      selector: (row) => row.poc,
      cell: (row) => findPocUser(row.userId),
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => statusComponent(row),
    },
  ];

  useEffect(() => {
    getMerchantsLeads();
  }, []);

  const getMerchantsLeads = async () => {
    const { idOrganization } = organization || {};
    try {
      if (organization?.id) {
        const { data } = await client.query({
          query: GET_MERCHANT_LEADS,
          variables: {
            input: {
              pagination: {
                offset: 0,
                limit: 99,
              },
              search: [
                {
                  key: 'organizationId',
                  value: idOrganization,
                },
              ],
            },
          },
        });

        if (data?.getMerchantLeads?.contents) {
          setMerchantLeads(data?.getMerchantLeads?.contents || []);
        }
      }
    } catch (error) {
      captureException(error, { message: 'getMerchantsContactList Error' });
    }
  };

  const findPocUser = (idUser) => {
    const foundUser = inlinePocUsers.find(
      (poc) => poc.user.id === idUser || poc.id === idUser || poc.user.id === idUser,
    );

    if (foundUser) {
      return `${foundUser.user?.firstName} ${foundUser.user?.lastName}`;
    }
    return '';
  };

  const handlePopoverOpen = (event) => {
    setPopoverId(event.currentTarget.id);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverOpen(false);
  };

  const getStatusText = (status: LOCATION_MANAGEMENT_STATUS_LIST) => {
    if (status === LOCATION_MANAGEMENT_STATUS_LIST.APPROVED) {
      return 'Active';
    }

    const statusText = status?.toLowerCase();

    return clearSpecialChars(statusText);
  };

  const statusComponent = (row) => {
    return (
      <>
        <StatusText>{getStatusText(row?.status)}</StatusText>

        {row.status === LOCATION_MANAGEMENT_STATUS_LIST.DENIED && (
          <IconContainer id={`id_${row.id}`} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <Icon src={'/info_circle_fill.svg'} />
            {popoverOpen && popoverId === `id_${row.id}` && (
              <Popover>
                <PopoverContent>
                  <PopoverText>
                    If you’d like Cherry to re-review your request, please reach out to your account representative.
                    Additional information may be required to approve this location.
                  </PopoverText>
                </PopoverContent>
                <DownCircle />
              </Popover>
            )}
          </IconContainer>
        )}

        {row.status === LOCATION_MANAGEMENT_STATUS_LIST.ADDITIONAL_MATERIALS_REQUESTED && (
          <IconContainer id={`id_${row.id}`} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <Icon src={'/info_circle_fill.svg'} />
            {popoverOpen && popoverId === `id_${row.id}` && (
              <Popover>
                <PopoverContent>
                  <PopoverText>
                    We need additional information to approve your new location request. Please reach out to your
                    account representative for more details.
                  </PopoverText>
                </PopoverContent>
                <DownCircle />
              </Popover>
            )}
          </IconContainer>
        )}
      </>
    );
  };

  return (
    <Column margin="0">
      <DashComponent dashHeader={'Location Management'} />
      <DashCard>
        <Row>
          <Row fontSize={'14px'}>If you operate multiple locations, you can manage them here.</Row>
          <Row fontSize={'14px'}>
            <b>Note:</b> &nbsp;New locations will be reviewed by the Cherry team before they can become active.
          </Row>
          <ButtonContainer>
            <CustomButtonPrimary
              text="Add Location"
              data-testid="add-location"
              startIcon={<LocationIcon />}
              disabled={false}
              onClick={handleOpenDialog}
            />
          </ButtonContainer>
        </Row>
        {inlinePocUsers?.length ? (
          <GridContainer container={true} spacing={3}>
            <Grid container={true}>
              <CustomDataTable
                noHeader={true}
                customStyles={settingsTableStyle}
                columns={tableColumns}
                data={merchantLeads || []}
                pagination={true}
                progressPending={isPocLoading}
                progressComponent={<TableLoader count={4} />}
              />
            </Grid>
          </GridContainer>
        ) : null}
      </DashCard>
      <StepControlDialog open={openDialog} handleClose={handleCloseDialog} getMerchantsLeads={getMerchantsLeads} />
    </Column>
  );
};

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddressText = styled.span``;

const CustomDataTable = styled(DataTable)`
  overflow: inherit;
  margin-top: 40px;
  .rdt_TableRow > div,
  .rdt_TableHeadRow div {
    font-size: 14px;
  }
  .rdt_TableHeadRow div,
  .rdt_TableRow > div:first-child {
    font-weight: 700;
  }

  &.div:has(.rdt_Pagination) {
    width: 100%;
  }

  .rdt_TableRow .rdt_TableCell:first-child div {
    white-space: pre-wrap;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const CustomButtonPrimary = styled(ButtonPrimary)<{ disabled: boolean }>`
  width: 230px;
  margin-top: 16px !important;
  margin-right: 16px !important;
  background-color: ${(props) => (props.disabled ? '#DADADA !important' : '#00C37C !important')};
  border: ${(props) => (props.disabled ? '#DADADA !important' : '#00C37C !important')};
`;

const IconContainer = styled.div`
  padding-top: 3px;
  position: relative;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  background: #00c37d;
  border: 1px solid #00c37d;
  border-radius: 4px;
  width: 300px;
  z-index: 10;
  right: -15px;
  top: -100px;
`;

const DownCircle = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00c37d;
  position: absolute;
  right: 17px;
`;

const PopoverContent = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.main.green};
`;

const PopoverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

const StatusText = styled.span`
  text-transform: capitalize;
`;

const GridContainer = styled(Grid)`
  margin-left: -12px !important;
`;
