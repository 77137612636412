import { gql } from '@apollo/client/core';

export const USER_MERCHANTS = gql`
  query getUserMerchants {
    getUserMerchants {
      data {
        id
        name
      }
      total
      success
      code
      message
    }
  }
`;

export const FETCH_AUTH_INFO = gql`
  query getUserAuthInfo {
    getUserAuthInfo {
      code
      features
      merchant {
        id
        name
      }
      message
      organization {
        id
        name
      }
      requiredConfirmations
      success
      tokenType
      user {
        id
        email
        firstName
        lastName
        roles
        phone
        prequal
      }
    }
  }
`;
