import { PaymentMethod } from '../SetupPayment/types';

export interface RecurringPaymentProps {
  borrowerId: number;
  applicationId: number;
  selectedRecurringPaymentMethod: PaymentMethod | null;
  onMethodSelect: (paymentMethod: PaymentMethod) => void;
}

export enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
  RCC = 'RCC',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
}

export enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}
