import images from 'assets/images';

export const preventNumericInput = (value: string) => value?.replace(/[0-9]/g, '');

export const trim = (value: string) => !!value?.trim();

export const unformatPhone = (value?: string) => value?.replace(/[^\d]/g, '');

export const QR_CODE_CONFIG = {
  logo: images?.cherrySymbolDark,
  version: 5,
  autoColor: true,
  PO: '#00c37d',
  PI: '#0e1f2e',
  PO_TL: '#00c37d',
  PI_TL: '#00c37d',
  PO_TR: '#0e1f2e',
  PI_TR: '#00c37d',
  PO_BL: '#00c37d',
  PI_BL: '#0e1f2e',
};

export const checkIfEmpty = (input) => {
  if (!input || input === '') {
    return null;
  }
  return input;
};

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const SAFARI_STYLE_MIN_ELIGIBLE_WINDOW_WIDTH = 1250;
