import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const PlanBasics = () => {
  return (
    <>
      <SubTitle>Plan Basics</SubTitle>
      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Plan Name</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Plan Name"
          inputProps={{
            'data-inputkey': 'planName',
          }}
          id="membership-plan-plan-name"
          data-testid="membership-plan-plan-name"
          value={''}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth={true} variant="outlined" margin="dense">
        <FormLabel>Plan Description</FormLabel>
        <StyledTextField
          size="small"
          type="text"
          placeholder="Plan Description"
          inputProps={{
            'data-inputkey': 'planDescription',
          }}
          id="membership-plan-plan-name"
          data-testid="membership-plan-plan-description"
          multiline={true}
          maxRows={4}
          value={''}
          variant="outlined"
        />
      </FormControl>

      <FormControl margin="normal">
        <FormControlLabel control={<CherryCheckbox />} label="“Recommended Plan” Badge" />
      </FormControl>
    </>
  );
};

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const CherryCheckbox = styled(Checkbox)`
  color: #00c37c !important;

  &:hover {
    background-color: #00c37c15 !important;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const SubTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
  margin-top: 40px;
`;
