import Cohere from 'cohere-js';
import { APP_ENV } from 'config';

const deleteAllCookies = () => {
  try {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  } catch (err) {
    console.warn(err);
  }
};

const getUserToken = () => {
  const userInfo = localStorage.getItem('@userInfo');
  const parsedInfo = Boolean(userInfo) ? JSON.parse(userInfo!) : {};
  return parsedInfo && parsedInfo.token;
};

const clearUserData = () => {
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
};

const setAuthData = ({ authToken, refreshToken }) => {
  localStorage.setItem('@authToken', authToken);
  localStorage.setItem('@refreshToken', refreshToken);
  localStorage.setItem('@onboardingStatus', 'true');
};

const getAuthData = () => {
  const token = localStorage.getItem('@authToken') || undefined;
  const refreshToken = localStorage.getItem('@refreshToken') || undefined;

  return { token, refreshToken };
};

const setWPQAuthData = ({ wpqToken }) => {
  localStorage.setItem('@wpqToken', wpqToken);
};

const getWPQAuthData = () => {
  const wpqToken = localStorage.getItem('@wpqToken') || undefined;

  return { wpqToken };
};

const setUserData = (data: any) => {
  localStorage.setItem('@userInfo', JSON.stringify(data));
  if (APP_ENV !== 'dev')
    Cohere.identify(data.user.id, {
      displayName: `${data.user.firstName} ${data.user.lastName}`,
      email: data.user.email,
    });
};

const setOnboardingStatus = (onboardingStatus: boolean = false) => {
  localStorage.setItem('@onboardingStatus', JSON.stringify(onboardingStatus));
};

const getOnboardingStatus = () => {
  return localStorage.getItem('@onboardingStatus');
};

const setPreapprovalTooltipClosed = (displayStatus: boolean = false) => {
  localStorage.setItem('@preapprovalTooltipDisplay', JSON.stringify(displayStatus));
};

const getPreapprovalTooltipClosed = () => {
  return localStorage.getItem('@preapprovalTooltipDisplay');
};

const setLeaderboardData = (
  leaderboardData: { fetched: boolean; organizationId?: string; loansGroup?: string } | {} = {},
) => {
  localStorage.setItem('@leaderboardData', JSON.stringify(leaderboardData));
};

const getLeaderboardData = () => {
  const leaderboardData = localStorage.getItem('@leaderboardData');
  const parsedInfo = leaderboardData ? JSON.parse(leaderboardData) : {};

  return parsedInfo;
};

const setApplicationGuideStatus = (applicationGuide: boolean = false) => {
  localStorage.setItem('@applicationGuideStatus', JSON.stringify(applicationGuide));
};

const getApplicationGuideStatus = () => {
  return localStorage.getItem('@applicationGuideStatus');
};

const getUserData = () => {
  const userInfo = localStorage.getItem('@userInfo');
  const parsedInfo = userInfo ? JSON.parse(userInfo) : {};

  return parsedInfo;
};

export default {
  getUserToken,
  clearUserData,
  setUserData,
  getUserData,
  setOnboardingStatus,
  getOnboardingStatus,
  setApplicationGuideStatus,
  getApplicationGuideStatus,
  setAuthData,
  getAuthData,
  setWPQAuthData,
  getWPQAuthData,
  setLeaderboardData,
  getLeaderboardData,
  setPreapprovalTooltipClosed,
  getPreapprovalTooltipClosed,
};
