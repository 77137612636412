import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 8px;
  margin-top: 24px;
`;

export const InfoText = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SuccessTitle = styled(Title)`
  font-size: 20px;
`;
