import { Dropdown, TextInput } from '@frontend/cherry-library';
import { FormControl, Grid, SelectChangeEvent } from '@mui/material';
import { US_STATES } from 'lib/constants';
import { Row } from 'lib/styles';
import MaterialModal from 'pages/desktop/OtherResourcesTemplates/components/MaterialModal';
import { Address } from 'pages/desktop/OtherResourcesTemplates/components/RequestMaterialModal';
import React, { useCallback, useEffect, useState } from 'react';

interface CustomAddress {
  label?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const STATE_CODES = US_STATES.map((state) => ({ value: state.value, label: state.value }));

interface AddCustomAddressModalProps {
  customAddress: Partial<Address> | null | undefined;
  visible: boolean;
  onSave: (address: CustomAddress) => void;
  onCancel: () => void;
}

const AddCustomAddressModal = ({ customAddress, visible, onSave, onCancel }: AddCustomAddressModalProps) => {
  const [address, setAddress] = useState<CustomAddress>({
    label: '',
    address1: '',
    address2: '',
    city: '',
    state: STATE_CODES[0].value,
    zip: '',
  });

  const isSubmitDisabled = !(address && address.address1 && address.city && address.state && address.zip);

  useEffect(() => {
    if (customAddress?.address) {
      setAddress({
        label: customAddress.label,
        address1: customAddress.address.address1,
        address2: customAddress.address.address2,
        city: customAddress.address.city?.name,
        state: customAddress.address.city?.state?.code,
        zip: customAddress.address.zip,
      });
    }
  }, [customAddress]);

  const handleSaveAddress = useCallback(() => {
    if (address) {
      onSave(address);
    }
  }, [onSave, address]);

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const setStateCode = (stateCode) => {
    setAddress({
      ...address,
      state: stateCode,
    });
  };

  const handleZipCode = (event) => {
    if (/[a-zA-Z]/g.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleStateCodeSelect = (event: SelectChangeEvent<unknown>) => {
    const stateCode = event?.target?.value;
    setStateCode(stateCode);
  };

  return (
    <MaterialModal
      open={visible}
      title="Use A Different Address"
      onCancel={onCancel}
      onSubmit={handleSaveAddress}
      submitButtonLabel="Use This Address"
      isSubmitDisabled={isSubmitDisabled}
    >
      <Row marginBottom="24px">
        This will NOT update the default address for your account. We’ll only use this address to deliver this supply
        order. Please contact support if you need to change your account info.
      </Row>
      <form>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                name="label"
                type="text"
                label="Company or Name (Optional)"
                onChange={handleChange}
                value={address.label}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput name="address1" type="text" label="Street" onChange={handleChange} value={address.address1} />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                name="address2"
                type="text"
                label="Apt/Unit (Optional)"
                onChange={handleChange}
                value={address.address2}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={8}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput name="city" type="text" label="City" onChange={handleChange} value={address.city} />
            </FormControl>
          </Grid>
          <Grid item={true} xs={4}>
            <Dropdown options={STATE_CODES} onChange={handleStateCodeSelect} value={address.state} />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <TextInput
                name="zip"
                label="Zip Code"
                onChange={handleChange}
                value={address.zip}
                inputProps={{
                  maxLength: 9,
                }}
                onKeyPress={handleZipCode}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </MaterialModal>
  );
};

export default AddCustomAddressModal;
