export interface FormData {
  firstName?: string;
  lastName?: string;
  dob?: string;
  last4ssn?: string;
  ssnAgain?: string;
  phone?: string;
  email?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  aptUnitAddress?: string;
  statedIncome?: string;
  housingPayment?: string;
  requestedAmount?: string;
  id?: string;
}
export interface IAutoComplete {
  id: string | number;
  label: string;
  street: string;
  city: string;
  state: string | number;
  zip: string | number;
}

export interface ISelectedAddress {
  street: string;
  city: string;
  state: string;
  zip: string | number;
}

export interface IAddress {
  city: string;
  state: string;
  zipcode: string;
  streetLine: string;
}
export interface Location {
  label: string;
  value: string;
  emailLabel: string;
}

export interface PostApplyWithPatientConsentResponse {
  organizationId?: string;
  merchantId?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  phone?: string;
  last4ssn?: string;
  statedIncome?: string;
  housingPayment?: string;
  requestedAmount?: string;
  address?: {
    aptUnitAddress?: string;
    streetAddress?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  status?: string;
  borrowerId?: string;
  id?: string;
  exchangeTokenId?: string;
}

export enum ApplicationFlowStatuses {
  MISSING_INFO = 'MISSING_INFO',
  MISSING_EMAIL = 'MISSING_EMAIL',
  MISSING_REQUESTED_AMOUNT = 'MISSING_REQUESTED_AMOUNT',
  TREATMENT_QUESTIONS = 'TREATMENT_QUESTIONS',
  AWAITING_DEFAULT_HIGH_LINE = 'AWAITING_DEFAULT_HIGH_LINE',
  AWAITING_APPROVAL_BOOST = 'AWAITING_APPROVAL_BOOST',
  PROCESSING = 'PROCESSING',
  ADDRESS_VERIFY_MINOR = 'ADDRESS_VERIFY_MINOR',
  ADDRESS_VERIFY_MAJOR = 'ADDRESS_VERIFY_MAJOR',
  SSN_REQUIRED_1 = 'SSN_REQUIRED_1',
  SSN_REQUIRED_2 = 'SSN_REQUIRED_2',
  SSN_CONFLICT = 'SSN_CONFLICT',
  ID_CONFLICT = 'ID_CONFLICT',
  SSN_VERIFICATION = 'SSN_VERIFICATION',
  FROZEN = 'FROZEN',
  ID_REVIEW = 'ID_REVIEW',
  EID_REVIEW = 'EID_REVIEW',
  INCOME_VERIFICATION = 'INCOME_VERIFICATION',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  COMPLETE_DEMO = 'COMPLETE_DEMO',
  DENIED = 'DENIED',
  BLACKLIST = 'BLACKLIST',
  OPTION = 'OPTION',
  EXPIRED = 'EXPIRED',
  AWAITING_ADDITIONAL_INFO = 'AWAITING_ADDITIONAL_INFO',
  ITIN_NOT_SUPPORTED = 'ITIN_NOT_SUPPORTED',
}

export interface ApprovedApplicationResponse {
  balanceAvailable?: number;
  borrowerId?: string;
  createdAt?: string;
  expireAt?: string;
  id?: string;
  merchantId?: string;
  maxEligible?: number;
  organizationId?: string;
  status: string;
}
