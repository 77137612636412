import { FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { useApolloClient } from '@apollo/client';
import { Input, Loading } from 'lib/components';
import { GET_MEMBERSHIP_CUSTOMERS } from 'lib/graphql/queries';
import { MembershipCustomers } from 'lib/graphql/searches/membershipSearch';
import { clearMaskFormat } from 'lib/utils';
import { ContactInfo } from 'pages/desktop/Membership/type';

interface Props {
  onChangeHandler: (key, value) => void;
  contactInfo: ContactInfo;
}

export const ContactInfoForm = ({ onChangeHandler, contactInfo }: Props) => {
  const client = useApolloClient();
  const [contact, setContact] = useState<ContactInfo | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contactInfo?.firstName) {
      setContact(contactInfo);
    }
  }, [contactInfo]);

  const searchCustomerWithPhone = async (phone: string) => {
    try {
      setLoading(true);
      const {
        data: { fetchMembershipCustomers },
      } = await client.query({
        query: GET_MEMBERSHIP_CUSTOMERS,
        variables: {
          input: {
            search: MembershipCustomers(phone),
          },
        },
      });

      if (fetchMembershipCustomers?.contents?.length > 0) {
        const existingCustomer = fetchMembershipCustomers?.contents[0];
        onChangeHandler('firstName', existingCustomer?.firstName);
        onChangeHandler('lastName', existingCustomer?.lastName);
      }
    } catch (error) {
      console.error('Error fetching customer with phone number');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactInfo?.phone?.length === 10) {
      searchCustomerWithPhone(contactInfo?.phone);
    }
  }, [contactInfo?.phone]);

  const handleChange = (nativeEvent: any) => {
    const key = nativeEvent.target.dataset.inputkey;
    const currentTarget = nativeEvent.currentTarget;
    let value = currentTarget.value;

    if (key === 'phone') {
      value = clearMaskFormat(value);
    }

    onChangeHandler(key, value);
    setContact((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  return (
    <Container>
      <Label>Contact Info</Label>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>First Name</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="First Name"
              inputProps={{
                'data-inputkey': 'firstName',
              }}
              id="location-management-first-name"
              data-testid="location-management-name"
              onChange={handleChange}
              value={contact?.firstName}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Last Name</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Last Name"
              inputProps={{
                'data-inputkey': 'lastName',
              }}
              id="location-management-address1"
              data-testid="location-management-address1"
              onChange={handleChange}
              value={contact?.lastName}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Email</FormLabel>
            <StyledTextField
              size="small"
              type="text"
              placeholder="Email"
              inputProps={{
                'data-inputkey': 'email',
              }}
              id="location-management-city"
              data-testid="location-management-city"
              onChange={handleChange}
              value={contact?.email}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item={true} xs={6}>
          <FormControl fullWidth={true} variant="outlined" margin="dense">
            <FormLabel>Phone {loading && <Loading size={10} />}</FormLabel>
            <CustomInputMask
              id="location-management-phone-mask"
              data-testid="location-management-phone"
              mask="(999) 999-9999"
              name="locationPhone"
              onChange={handleChange}
              value={contact?.phone}
            >
              {(inputProps) => (
                <CustomInput id="location-management-phone" {...inputProps} placeholder="Phone" data-inputkey="phone" />
              )}
            </CustomInputMask>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  display: block;
  margin-bottom: 8px;
`;

const FormLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  display: flex;
  gap: 8px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #a2a2a2;
    }
  }
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-family: 'Open Sans';
  border-color: ${(props) => props.error && '#EC3360'};
  margin-bottom: 0px;
  -webkit-font-smoothing: unset;
`;

const CustomInputMask = styled(InputMask)`
  font-size: 16px;
  margin-bottom: 0px;
  border-color: rgb(205 204 204);
  border-radius: 4px;
  padding: 8px 13px;
  color: black;
  &.error {
    border: 1px solid #ff0000;
  }
  ::placeholder {
    color: #d2d2d2;
  }
`;
