import React from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { FixedDecimalFormat } from 'lib/components';
import { Button, ButtonContainer, Container, ContentContainer, Title } from 'lib/components/mobile';
import useStore from 'lib/hooks/useStore';
import { useTransactions } from 'lib/services';
import { useNavigate } from 'react-router-dom';

const MobileTransactionCancelRefund = () => {
  const navigate = useNavigate();
  const { transactionInProcess, organization } = useStore();
  const { refundCancelButtonHandler } = useTransactions();

  const onBackClick = () => {
    navigate('/transactions');
  };

  const refundCancelClick = () => {
    refundCancelButtonHandler(transactionInProcess);
  };

  return (
    <Container pageTitle="Cancel Refund">
      <DetailContainer>
        <Title>Are you sure you want to cancel this refund?</Title>
        <Description>
          We will reimburse the{' '}
          <b>
            {' '}
            <FixedDecimalFormat amount={organization?.refundFee} prefix={'$'} />{' '}
          </b>{' '}
          refund fee originally charged to your account.
        </Description>
        <FooterContainer>
          <ButtonContainer>
            <Button color={theme.main.red} onClick={refundCancelClick}>
              Yes, Cancel Refund
            </Button>
            <Button secondary={true} onClick={onBackClick}>
              Cancel
            </Button>
          </ButtonContainer>
        </FooterContainer>
      </DetailContainer>
    </Container>
  );
};

export default MobileTransactionCancelRefund;

const DetailContainer = styled(ContentContainer)`
  padding-bottom: 170px;
`;

const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
