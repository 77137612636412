const ICON_REF_NAMES = {
  NICKNAME: 'nicknameIconRef',
  ACCOUNT_NAME: 'accountNameIconRef',
  ROUTING_NUMBER: 'routingNumberIconRef',
  ACCOUNT_NUMBER: 'accountNumberIconRef',
};

const TOOLTIP_CONTENT = {
  [ICON_REF_NAMES.NICKNAME]: {
    title: 'Nickname',
    content: ['Enter a nickname of your choice to easily identify this bank account.'],
  },
  [ICON_REF_NAMES.ACCOUNT_NAME]: {
    title: 'Account Name',
    content: [
      'Enter the name that is shown on your bank account. This is NOT the name of your banking institution.',
      'Typically you can find your bank account name on your checks or bank statements.',
      {
        type: 'bulletList',
        items: [
          'For business bank accounts, this is usually the legal name of the business.',
          'For personal bank accounts, this is usually your full name.',
        ],
      },
    ],
  },
  [ICON_REF_NAMES.ROUTING_NUMBER]: {
    title: 'Routing Number',
    content: [
      'Your ACH routing number is a 9-digit number used to identify a specific financial institution. Check with your bank directly for this number. If you have physical checks, it’s usually the first set of numbers shown.',
      'Please note that providing the incorrect routing number will lead to failed funding. Cherry sends funds via ACH only and does not support same-day funding.',
    ],
  },
  [ICON_REF_NAMES.ACCOUNT_NUMBER]: {
    title: 'Account Number',
    content: [
      'Your account number is a 5-to-17-digit number that is specific to your bank account. Check with your bank directly for this number. If you have physical checks, it’s usually found directly after the routing number.',
    ],
  },
};

enum ACCOUNT_TYPES {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

enum ACCOUNT_CLASSIFICATION {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

enum MODAL_STATUS {
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  REVIEW = 'REVIEW',
  SUMMARY = 'SUMMARY',
}

const additionalRoutingNumbers = [
  '103013376',
  '124303243',
  '125109019',
  '26015053',
  '026015053',
  '31101279',
  '103100195',
  '124085367',
  '124085396',
  '041215663',
  '041208777',
  '31101114',
  '211075086',
  '53112592',
  '124303162',
  '124303230',
];

const routingNumberDoubleCheckList = [
  '31101279',
  '103100195',
  '124085367',
  '124085396',
  '041215663',
  '041208777',
  '31101114',
  '53112592',
  '124303162',
  '124303230',
];

export interface InfoFormState {
  accountNickname: string | null;
  accountName: string | null;
  routingNumber: string | null;
  accountNumber: string | null;
  accountType: string;
  accountClassification: string;
}

export {
  ICON_REF_NAMES,
  TOOLTIP_CONTENT,
  ACCOUNT_TYPES,
  ACCOUNT_CLASSIFICATION,
  MODAL_STATUS,
  additionalRoutingNumbers,
  routingNumberDoubleCheckList,
};
