import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import { LocationManagementStepEnums } from 'lib/types';

import useStore from 'lib/hooks/useStore';
import { AddBank } from './AddBank';
import { AddPhoneNumber } from './AddPhoneNumber';
import { Address } from './Address';
import { CreateUser } from './CreateUser';
import { PointOfContact } from './PointOfContact';
import { Review } from './Review';
import { SelectBank } from './SelectBank';
import { Success } from './Success';
import { CreateMerchant } from './type';

interface Props {
  getMerchantsLeads: () => void;
  handleClose: () => void;
  open: boolean;
}

export const StepControlDialog = ({ getMerchantsLeads, handleClose, open }: Props) => {
  const initialMerchant = {
    name: '',
    email: '',
    address: {
      name: '',
      street: '',
      zip: '',
      city: '',
      state: {
        code: '',
        name: '',
      },
    },
    id: '',
    bankingInfo: {
      accountName: '',
      accountNumber: '',
      accountNickname: '',
      routingNumber: '',
      accountType: '',
      accountClassification: '',
      accountStatus: '',
      accountId: '',
    },
    phone: '',
    poc: {
      phone: '',
      email: '',
      firstName: '',
      lastName: '',
      role: '',
    },
    password: '',
    locationPhone: '',
    role: '',
    idOrganization: '',
    accountName: '',
  };

  const { organization } = useStore();

  const [activeStep, setActiveStep] = useState<LocationManagementStepEnums>(
    LocationManagementStepEnums.POINT_OF_CONTACT,
  );
  const [merchant, setMerchant] = useState<CreateMerchant>(initialMerchant);

  useEffect(() => {
    onChangeHandler('idOrganization', organization?.id);
    if (!open) {
      setTimeout(() => {
        setActiveStep(LocationManagementStepEnums.POINT_OF_CONTACT);
        setMerchant(initialMerchant);
      }, 350);
    }
  }, [open]);

  const onChangeHandler = (key: string, value: any) => {
    setMerchant((prevState: CreateMerchant) => {
      return { ...prevState, [key]: value };
    });
  };

  const onChangeBankingInfo = (key: string, value: any) => {
    setMerchant((prevState: CreateMerchant) => {
      const updatedBankingInfo = prevState.bankingInfo
        ? { ...prevState.bankingInfo }
        : { ...initialMerchant.bankingInfo };

      updatedBankingInfo[key] = value;
      return { ...prevState, bankingInfo: updatedBankingInfo };
    });
  };

  const onChangeAddressInfo = (key: string, value: any) => {
    setMerchant((prevState: CreateMerchant) => {
      const updatedAddressInfo = prevState.address ? { ...prevState.address } : { ...initialMerchant.address };

      updatedAddressInfo[key] = value;
      return { ...prevState, address: updatedAddressInfo };
    });
  };

  const renderConditionalStep = () => {
    switch (activeStep) {
      case LocationManagementStepEnums.LOADING:
        return (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        );
      case LocationManagementStepEnums.POINT_OF_CONTACT:
        return (
          <PointOfContact
            handleClose={handleClose}
            onStepChange={setActiveStep}
            onChangeHandler={onChangeHandler}
            merchant={merchant}
          />
        );
      case LocationManagementStepEnums.ADD_USER:
        return <CreateUser handleClose={handleClose} onStepChange={setActiveStep} onChangeHandler={onChangeHandler} />;

      case LocationManagementStepEnums.ADD_PHONE_NUMBER:
        return (
          <AddPhoneNumber
            handleClose={handleClose}
            onStepChange={setActiveStep}
            onChangeHandler={onChangeHandler}
            merchant={merchant}
          />
        );

      case LocationManagementStepEnums.LOCATION_ADDRESS:
        return (
          <Address
            handleClose={handleClose}
            onStepChange={setActiveStep}
            onAddressChange={onChangeAddressInfo}
            onChangeHandler={onChangeHandler}
            merchant={merchant}
          />
        );

      case LocationManagementStepEnums.SELECT_BANK:
        return (
          <SelectBank
            handleClose={handleClose}
            onStepChange={setActiveStep}
            onChangeBankingInfo={onChangeBankingInfo}
            merchant={merchant}
          />
        );

      case LocationManagementStepEnums.ADD_BANK:
        return (
          <AddBank
            handleClose={handleClose}
            onStepChange={setActiveStep}
            onChangeHandler={onChangeHandler}
            onChangeBankingInfo={onChangeBankingInfo}
          />
        );

      case LocationManagementStepEnums.REVIEW:
        return <Review handleClose={handleClose} onStepChange={setActiveStep} merchant={merchant} />;

      case LocationManagementStepEnums.SUCCESS:
        return <Success handleClose={handleClose} getMerchantsLeads={getMerchantsLeads} />;

      default:
        return (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        );
    }
  };

  return (
    <StyledDialog
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
      activeStep={activeStep}
    >
      {renderConditionalStep()}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<{ activeStep: LocationManagementStepEnums }>`
  .MuiPaper-root {
    overflow: ${(props) =>
      props.activeStep === LocationManagementStepEnums.ADD_BANK ||
      props.activeStep === LocationManagementStepEnums.REVIEW
        ? 'none'
        : 'inherit'};
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
