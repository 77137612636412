import { gql } from '@apollo/client/core';

export const GET_PRODUCT_CALCULATIONS = gql`
  query getProductCalculations($input: GetProductCalculationsInput) {
    getProductCalculations(input: $input) {
      errors
      message
      code
      success
      data {
        downPaymentAmount
        financeCharge
        installmentAmount
        grossAmount
        loanAmount
        merchantRevenue
        merchantFund
        paymentDates
        payments {
          fallback
          date
          amount
        }
        product {
          term
          status
          promoTerm
          promoMdf
          promoApr
          periodLength
          moduleString
          minEligible
          mdf
          maxEligible
          id
          parentId
          fallbackApr
          downPaymentType
          downPayment
          createdAt
          apr
          applicationId
          chosenDownPaymentAmount
        }
        promo
        promoApr
        totalAmount
        variableDownPayment {
          hasOptionToModify
          minEligible
          maxEligible
          changeRange
          operandRange
        }
      }
    }
  }
`;

export const GET_PRODUCT_CALCULATIONSV2 = gql`
  query getProductCalculationsV2($input: GetProductCalculationsInput) {
    getProductCalculationsV2(input: $input) {
      errors
      message
      code
      success
      data {
        downPaymentAmount
        financeCharge
        installmentAmount
        grossAmount
        loanAmount
        merchantRevenue
        merchantFund
        paymentDates
        payments {
          fallback
          date
          amount
        }
        product {
          term
          status
          promoTerm
          promoMdf
          promoApr
          periodLength
          moduleString
          minEligible
          mdf
          maxEligible
          id
          parentId
          fallbackApr
          downPaymentType
          downPayment
          createdAt
          apr
          applicationId
          chosenDownPaymentAmount
        }
        promo
        promoApr
        totalAmount
        variableDownPayment {
          hasOptionToModify
          minEligible
          maxEligible
          changeRange
          operandRange
        }
      }
    }
  }
`;

export const GET_DOWN_PAYMENT_AMOUNT_CALCULATE = gql`
  query getDownPaymentAmountCalculate($input: GetDownPaymentAmountCalculateInput) {
    getDownPaymentAmountCalculate(input: $input) {
      product {
        applicationId
        menuId
        moduleString
        term
        promoTerm
        apr
        promoApr
        fallbackApr
        maxEligible
        minEligible
        creditLineMaxEligible
        mdf
        promoMdf
        chosenDownPaymentAmount
        downPaymentType
        downPayment
        periodLength
        tierId
        id
        parentId
        createdAt
        status
      }
      promo
      financeCharge
      installmentAmount
      downPaymentAmount
      totalAmount
      loanAmount
      grossAmount
      merchantFund
      merchantRevenue
      variableDownPayment {
        hasOptionToModify
        minEligible
        maxEligible
        changeRange
        operandRange
      }
      paymentDates
      payments {
        fallback
        date
        amount
      }
    }
  }
`;
