import React, { useEffect, useState } from 'react';

import { DropDown, InputWithIcon } from 'lib/components';
import { useDebounce } from 'lib/hooks';
import { DropdownItem } from 'pages/desktop/Membership/type';
import { BorderContainer, DropDownContainer, TableRow } from 'pages/desktop/Transactions/CommonStyles';

interface Props {
  searchHandler: (searchInput: string, searchKey?: string) => void;
  setSearchInput: (searchInput: string) => void;
  searchInput: string;
  setSelectedFilter: (selectedFilter: string) => void;
  selectedFilter: string;
}

const STATUSES = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Sent',
    value: 'SENT',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Canceled',
    value: 'CANCELLED',
  },
];

export const PointOfSaleLinksHeader = ({
  searchHandler,
  setSearchInput,
  searchInput,
  setSelectedFilter,
  selectedFilter,
}: Props) => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const debouncedSearchInput = useDebounce(searchInput, 500);

  useEffect(() => {
    if (searchInput?.length > 2) {
      searchHandler(searchInput, selectedFilter);
      setIsFirstTime(false);
    } else if (selectedFilter) {
      searchHandler(searchInput, selectedFilter);
    } else if (searchInput.length === 0 && !isFirstTime) {
      searchHandler(searchInput);
    }
  }, [debouncedSearchInput, selectedFilter]);

  const searchInputOnChange = (nativeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = nativeEvent.currentTarget;
    setSearchInput(currentTarget.value);
  };

  const onStatusSelected = (data: DropdownItem) => {
    setSelectedFilter(data.value);
  };

  return (
    <TableRow>
      <BorderContainer>
        <InputWithIcon
          onChange={searchInputOnChange}
          value={searchInput}
          textAlign={'left'}
          border={false}
          placeholder="Search by name or phone number"
        />
      </BorderContainer>
      <DropDownContainer>
        <DropDown
          options={STATUSES}
          hoveredMode={false}
          textColor={'#0E202F'}
          placeholder="Status"
          showAllItem={true}
          onChange={onStatusSelected}
          defaultValue={'name'}
          preventCloseOnClick={false}
        />
      </DropDownContainer>
    </TableRow>
  );
};
